//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../templates.d.ts"  />
//// <reference path="./app.session.ts"  />
//// <reference path="../utils/utils.spinner.ts"  />
/// <reference path="../model/model.floor-plan.mark.ts"  />
/// <reference path="../model/model.floor-plan.ts"  />
//imports-end

module FloorPlan {
    let _$content, _$floorPlanContainer;
    let _floorPlan;

    function initVariables(): void {
        let location = Session.CurrentLocation;
        let floorPlanFound = !!location.Layout;

        _$content = Utils.GetContentContainer().empty();

        while (!floorPlanFound && (location = location.Parent)) {
            floorPlanFound = !!location.Layout;
        }

        _$content.html(Templates.FloorPlan({
            FloorPlanFound: floorPlanFound
        }));

        _$floorPlanContainer = _$content.find('.floor-plan-container');

        _floorPlan = new Model.FloorPlan({
            ID: 'room-view-floor-plan',
            Location: Session.CurrentLocation,
            $Container: _$floorPlanContainer,
            FloorPlanHeight: '1000px'
        });
    }

    export function Show(): void {
        Utils.Spinner.Show();

        Utils.SetMode(Enums.Mode.FloorPlan);

        initVariables();

        if (!(_floorPlan.$FloorPlan || []).length) {
            _$floorPlanContainer.remove();
        }

        Menu.ShowBackButton();
        Utils.Spinner.Hide();
    }
}