//imports-start
/// <reference path="./counter.ts"  />
//imports-end

module Model.Issues {
    export class Counters {
        Tasks: Counter;
        Notes: Counter;
        Disturbances: Counter;
        Issues: Counter;
        Custom: { [id: string]: Counter };
        Schedulings: number;

        constructor() {
            this.Tasks = new Counter();
            this.Notes = new Counter();
            this.Disturbances = new Counter();
            this.Issues = new Counter();
            this.Custom = {};
            this.Schedulings = 0;
        }
    }
}