module Utils.SVGEditor {

    export class TextDrawHandler extends DrawHandler {

        private isTextMoving: boolean;

        constructor(editor: SVGEditor, svg: SVGSVGElement, onChangeListener?: Function) {
            super(editor, svg, onChangeListener);
            this.isTextMoving = false;
        }

        public UnbindEvents(): void {
            const $textnodes = this.editor.$SvgContainer.find('svg text');

            for (const textnode of $textnodes || []) {
                if (!textnode.classList.contains('hidden')) {
                    textnode.removeAttribute('class');
                }

                textnode.removeAttribute('id');

                $(textnode)
                    .off('mouseover touchstart')
                    .off('mousedown touchstart')
                    .off('mouseout touchend');
            }
        }

        public bindEvents(): void {
            this.UnbindEvents();

            this.d3SvgElement
                .on('mousedown', (event: MouseEvent) => this.onImageClick(event))
                .on('touchstart', (event: TouchEvent) => this.onImageClick(event));

            const $textnodes = this.editor.$SvgContainer.find('svg text');
            for (const textnode of $textnodes || []) {
                if (!textnode.classList.length) {
                    textnode.setAttribute('class', 'text');
                }

                textnode.setAttribute('id', uuid());
                this.makeTextEditable($(textnode));
            }
        }

        private onImageClick(event: MouseEvent | TouchEvent): void {
            const coordinates = this.getPoint(event);

            if (!coordinates) {
                return;
            }

            Utils.InputWindow.Show(
                i18next.t('Draw.AddComment.MessageHeader'),
                null,
                {
                    Abort: { Fn: $.noop },
                    OK: {
                        Fn: (text: string) => {
                            if (!text) {
                                return;
                            }

                            const d3SvgGroup = this.d3SvgElement.select('g');
                            const color = new Model.Color(this.editor.$SelectedColor.data('color'));
                            const d3TextElement = d3SvgGroup.append('text');
                            const lines = text.split('\n');
                            let lineCorrection = 1;

                            d3TextElement
                                .data([[]])
                                .attr('x', coordinates.x)
                                .attr('y', coordinates.y)
                                .attr('id', uuid())
                                .attr('class', 'text')
                                .attr('font-family', 'sans-serif')
                                .attr('font-size', this.editor.$PixelSlider.val())
                                .attr('fill', color.getRGB());

                            lines.forEach(function(line) {
                                if (line.length < 1) {
                                    lineCorrection++;
                                    return;
                                }

                                d3TextElement
                                    .append('tspan')
                                    .data([[]])
                                    .attr('x', coordinates.x)
                                    .attr('dy', '{0}em'.format(lineCorrection * 1.2))
                                    .attr('data-lines', lineCorrection)
                                    .text(line);

                                lineCorrection = 1;
                            });

                            this.makeTextEditable($(d3TextElement.node()))

                            this.triggerChange();
                        }
                    }
                }, null, 'textarea', 99999);
        }

        private onTextClick(textfield: HTMLElement): void {
            Utils.InputWindow.Show(
                i18next.t('Draw.AddComment.MessageHeader'),
                null,
                {
                    Abort: { Fn: $.noop },
                    OK: {
                        Fn: (text: string) => {
                            this.onTextSaved(textfield, text);
                        }
                    }
                }, this.getTextFromTextField($(textfield)), 'textarea', 99999);
        }

        private onTextSaved(textfield: HTMLElement, text: string): void {
            const id = textfield.getAttribute('id');

            if (!id) {
                return;
            }

            const $previousTextField = this.editor.$SvgContainer.find('#' + id);

            if ($previousTextField.length) {
                $previousTextField.remove();
            }

            if (!text) {
                return;
            }

            const x = textfield.getAttribute('x');
            const y = textfield.getAttribute('y');

            const d3SvgGroup = this.d3SvgElement.select('g');
            const d3TextElement = d3SvgGroup.append('text');
            const lines = text.split('\n');
            let lineCorrection = 1;

            d3TextElement
                .data([[]])
                .attr('x', x)
                .attr('y', y)
                .attr('id', uuid())
                .attr('class', 'text')
                .attr('font-family', textfield.getAttribute('font-family'))
                .attr('font-size', textfield.getAttribute('font-size'))
                .attr('fill', textfield.getAttribute('fill'))

            lines.forEach(line => {
                if (line.length < 1) {
                    lineCorrection++;
                    return;
                }

                d3TextElement
                    .append('tspan')
                    .data([[]])
                    .attr('x', x)
                    .attr('dy', '{0}em'.format(lineCorrection * 1.2))
                    .attr('data-lines', lineCorrection)
                    .text(line);

                lineCorrection = 1;
            });

            this.makeTextEditable($(d3TextElement.node()));
            this.triggerChange();
        }

        private textHasBeenMoved(oldCoordinates: IPoint, newCoordinates: IPoint, minDiff: number): boolean {
            const diffX = oldCoordinates.x - newCoordinates.x;
            const diffY = oldCoordinates.y - newCoordinates.y;

            minDiff = minDiff || 0;

            return Math.abs(diffX) > minDiff && Math.abs(diffY) > minDiff;
        }

        private onMouseDownOnText(event: MouseEvent): void {
            const $text = $(event.currentTarget);
            const tspans = $text.find('tspan').toArray();
            const text = $text.get(0);
            let textMoved = false;
            const initialCoordinates = this.editor.GetOffset(event);

            event.preventDefault();

            if (this.isTextMoving) {
                return;
            }

            this.isTextMoving = true;

            this.editor.$SvgContainer
                .off('mousemove touchmove mouseup touchend touchcancel')
                .on('mousemove touchmove', (evt) => {
                    const rect = text.getBoundingClientRect();
                    const touchEvt = (evt.originalEvent.touches || [])[0];
                    const svgElement = this.d3SvgElement.node();
                    const transform = svgElement.getScreenCTM().inverse();
                    let pt = svgElement.createSVGPoint();
                    let displayPt = svgElement.createSVGPoint();

                    evt.preventDefault();
                    evt.stopPropagation();
                    evt.stopImmediatePropagation();

                    if (touchEvt) {
                        pt.x = touchEvt.pageX;
                        pt.y = touchEvt.pageY;
                    } else {
                        pt.x = evt.pageX;
                        pt.y = evt.pageY;
                    }

                    textMoved = this.textHasBeenMoved(initialCoordinates, pt, !!touchEvt ? 5 : 0);

                    if (!textMoved) {
                        return;
                    }

                    const offset = {
                        x: rect.width / 2 / pt.x,
                        y: rect.height / 2 / pt.y
                    };

                    displayPt.x = pt.x - pt.x * offset.x;
                    displayPt.y = pt.y - pt.y * offset.y;

                    pt = pt.matrixTransform(transform);
                    displayPt = displayPt.matrixTransform(transform);

                    text.setAttribute('x', displayPt.x);
                    text.setAttribute('y', displayPt.y);

                    for (const tspan of tspans) {
                        tspan.setAttribute('x', displayPt.x);
                    }
                });

            this.editor.$SvgContainer.one('mouseup touchend touchcancel', (evt: Event) => {
                evt.preventDefault();

                this.isTextMoving = false;

                this.editor.$SvgContainer.off('mousemove mouseup touchmove touchend touchcancel keyup');
                text.setAttribute('fill', text.getAttribute('data-fill-color'));

                if (!textMoved) {
                    this.onTextClick(text);
                    return;
                }

                this.triggerChange();
            });
        }

        private getTextFromTextField($textfield): string {
            const $tspans = $textfield.find('tspan');
            let texts: Array<string> = [];

            for (const tspan of $tspans) {
                const lines = tspan.getAttribute('data-lines');

                for (let line = 1; line < lines; line++) {
                    texts.push('\n');
                }

                texts.push($(tspan).text());
                texts.push('\n');
            }

            return texts.join();
        }

        private makeTextEditable($textfield): void {
            $textfield
                .off('mouseover touchstart mousedown mouseout touchend')
                .on('mouseover touchstart', (event: MouseEvent) => {
                    const target = <HTMLElement>event.currentTarget;
                    target.setAttribute('data-fill-color', target.getAttribute('fill'));
                    target.setAttribute('fill', 'red');

                    this.d3SvgElement
                        .on('mousedown', $.noop)
                        .on('touchstart', $.noop);
                })
                .on('mousedown touchstart', (event: MouseEvent) => this.onMouseDownOnText(event))
                .on('mouseout touchend', (event: MouseEvent) => {
                    const target = <HTMLElement>event.currentTarget;
                    target.setAttribute('fill', target.getAttribute('data-fill-color'));
                    this.bindEvents();
                });
        }
    }
}
