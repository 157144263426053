//imports-start
/// <reference path="../../utils/utils.date-time.ts"  />
/// <reference path="./raw-file.ts"  />
/// <reference path="./issue.ts"  />
/// <reference path="./resubmission-item.ts"  />
/// <reference path="./location-marker.ts"  />
//imports-end

module Model.Issues {
    export class RawIssue {
        OID: string;
        Type: number;
        Revision: number;
        CreatorOID: string;
        EditorOID: string;
        CreationTimestamp: string;
        ModificationTimestamp: string;
        AssignedElementOID: string;
        IsArchived: boolean;
        IsDeleted?: boolean;

        ID?: number;
        ParentID?: number;
        ParentOID?: string;
        PrecedingOID?: string;
        FollowerOID?: string;
        CustomID?: string;
        IsTemplate?: boolean;
        TemplateID?: string;
        Title?: string;
        Description?: string;
        RemindingTimestamp?: string;
        DeadlineTimestamp?: string;
        TerminationTimestamp?: string;
        StateOID?: string;
        StateRevisionOID?: string;
        PriorityOID?: string;
        PriorityRevisionOID?: string;
        AssignedActionOID?: string;
        AssignedElementRevisionOID?: string;
        AssignedFormOID?: string;
        AssignedFormRevisionOID?: string;
        AssignedMeasureOID?: string;
        AssignedMeasureRevisionOID?: string;
        AssignedSchedulingOID?: string;
        AssignedSchedulingRevisionOID?: string;
        AssignedRecorditemOID?: string;
        AssignedRecorditemID?: number;
        /** available since RestService API v14 */
        ResponsibilityAssignments?: ResponsibilityAssignments;
        /** @deprecated since RestService API v14 */
        Teams?: Array<string>;
        /** @deprecated since RestService API v14 */
        Users?: Array<string>;
        /** @deprecated since RestService API v14 */
        Contacts?: Array<string>;
        Classifications?: Array<string>;
        Keywords?: Array<string>;
        Files?: Array<RawFile>;
        TemporaryFilesMarkup: Array<string>;
        LocationMarkers?: Array<LocationMarker>;
        ChangeComment?: string;
        Ancestors?: Array<RawIssue>;
        Descendants?: Array<RawIssue>;
        Revisions?: Array<Issue>;
        Resubmissionitems?: Array<ResubmissionItem>;
        Recorditems?: Array<Model.Recorditem>;
        ParameterCount?: number;
        RequiredParameterCount?: number;
        CollectedParameterCount?: number;
        CollectedRequiredParameterCount?: number;
        AdditionalData?: any;
        EstimatedEffort?: number;
        IsTemporary: boolean;

        Comments?: Array<any>;

        constructor(issue?: Issue) {
            if (!issue) {
                return;
            }

            this.initProperties(issue);
        }

        private initProperties(issue: Issue): void {
            const me = this;

            this.OID = issue.OID;
            this.Type = issue.Type;
            this.Revision = issue.Revision;
            this.CreatorOID = issue.CreatorOID;
            this.EditorOID = issue.EditorOID;
            this.CreationTimestamp = Utils.DateTime.ToGMTString(issue.CreationTimestamp);
            this.ModificationTimestamp = Utils.DateTime.ToGMTString(issue.ModificationTimestamp);
            this.AssignedElementOID = issue.AssignedElementOID;

            this.ParentID = issue.ParentID;
            this.ParentOID = issue.ParentOID;
            this.PrecedingOID = issue.PrecedingOID;
            this.FollowerOID = issue.FollowerOID;
            this.CustomID = issue.CustomID;
            this.IsTemplate = issue.IsTemplate;
            this.TemplateID = issue.TemplateID;
            this.Title = issue.Title;
            this.Description = issue.Description;

            this.StateOID = issue.StateOID;
            this.StateRevisionOID = issue.StateRevisionOID;
            this.PriorityOID = issue.PriorityOID;
            this.PriorityRevisionOID = issue.PriorityRevisionOID;
            this.AssignedActionOID = issue.AssignedActionOID
            this.AssignedElementRevisionOID = issue.AssignedElementRevisionOID;
            this.AssignedFormOID = issue.AssignedFormOID;
            this.AssignedFormRevisionOID = issue.AssignedFormRevisionOID;
            this.AssignedMeasureOID = issue.AssignedMeasureOID;
            this.AssignedMeasureRevisionOID = issue.AssignedMeasureRevisionOID;
            this.AssignedSchedulingOID = issue.AssignedSchedulingOID;
            this.AssignedSchedulingRevisionOID = issue.AssignedSchedulingRevisionOID;
            this.AssignedRecorditemOID = issue.AssignedRecorditemOID;
            this.AssignedRecorditemID = issue.AssignedRecorditemID;
            this.Recorditems = Session.IsSmartDeviceApplication ?
                this.prepareRecorditems(issue.Recorditems) :
                issue.Recorditems;

            this.ChangeComment = issue.ChangeComment;
            this.AdditionalData = issue.AdditionalData;
            this.EstimatedEffort = issue.EstimatedEffort;
            this.IsTemporary = issue.IsTemporary;

            if ((issue.Comments || []).length) {
                this.Comments = [];

                issue.Comments.forEach((obj) => {
                    me.Comments.push(new Model.Comment(obj).GetRawEntity());
                });
            }

            if (issue.IsArchived) {
                this.IsArchived = true;
            }

            if (issue.IsDeleted) {
                this.IsDeleted = true;
            }

            if (+issue.ID) {
                this.ID = issue.ID || 0;
            }

            this.RemindingTimestamp = Utils.DateTime.ToGMTString(issue.RemindingTimestamp);
            this.DeadlineTimestamp = Utils.DateTime.ToGMTString(issue.DeadlineTimestamp);
            this.TerminationTimestamp = Utils.DateTime.ToGMTString(issue.TerminationTimestamp);

            if (issue.ResponsibilityAssignments) {
                this.ResponsibilityAssignments = issue.ResponsibilityAssignments;
            }

            if ((issue.Teams || []).length) {
                this.Teams = issue.Teams;
            }

            if ((issue.Users || []).length) {
                this.Users = issue.Users;
            }

            if ((issue.Contacts || []).length) {
                this.Contacts = issue.Contacts;
            }

            if ((issue.Classifications || []).length) {
                this.Classifications = issue.Classifications;
            }

            if ((issue.Keywords || []).length) {
                this.Keywords = issue.Keywords;
            }

            if ((issue.Files || []).length) {
                this.Files = [];
                issue.Files.sort(Utils.SortByPosition);
                issue.Files.forEach(f => this.Files.push(new RawFile(f)));
            }

            if ((issue.TemporaryFilesMarkup || []).length) {
                this.TemporaryFilesMarkup = issue.TemporaryFilesMarkup;
            }

            if ((issue.LocationMarkers || []).length) {
                this.LocationMarkers = issue.LocationMarkers;
            }

            if ((issue.Resubmissionitems || []).length) {
                this.Resubmissionitems = issue.Resubmissionitems;
            }

            if (issue.ParameterCount) {
                this.ParameterCount = issue.ParameterCount;
            }

            if (issue.RequiredParameterCount) {
                this.RequiredParameterCount = issue.RequiredParameterCount;
            }

            if (issue.CollectedParameterCount) {
                this.CollectedParameterCount = issue.CollectedParameterCount;
            }

            if (issue.CollectedRequiredParameterCount) {
                this.CollectedRequiredParameterCount = issue.CollectedRequiredParameterCount;
            }

            if ((issue.Ancestors || []).length) {
                this.Ancestors = [];
                issue.Ancestors.forEach(i => this.Ancestors.push(new RawIssue(i)));
            }

            if ((issue.Descendants || []).length) {
                this.Descendants = [];
                issue.Descendants.forEach(i => this.Descendants.push(new RawIssue(i)));
            }
        }

        private prepareRecorditems(recorditems: Array<Model.Recorditem>): Array<Model.Recorditem> {
            if (!(recorditems || []).length) {
                return [];
            }

            const preparedRecordItems = [];

            for (let i = 0; i < recorditems.length; i++) {
                const recorditem = new Model.Recorditem(recorditems[i]);

                preparedRecordItems.push(recorditem.GetRawEntity());
            }

            return preparedRecordItems;
        }
    }
}