module Model.SelectionWindow {
    export class Tab {
        public ID: string;
        public CSSClass: string;
        public Title: string;
        public CallbackFunction: Function;
        public ShowOnlyIfItemIsSelectable: boolean;

        constructor(id: string, cssClass: string, title: string, callbackFunction: Function, showOnlyIfItemIsSelectable: boolean = true) {
            if (!Utils.IsFunction(callbackFunction)) {
                throw new Error('callback is not a function');
            }

            this.ID = id;
            this.CSSClass = cssClass;
            this.Title = title;
            this.CallbackFunction = callbackFunction;
            this.ShowOnlyIfItemIsSelectable = showOnlyIfItemIsSelectable;
        }
    }
}