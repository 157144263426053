/// <reference path="workflow-base.ts"/>

module New.Checkpoints.Workflow {
    export class WorkflowStack {
        private readonly _stack: Array<WorkflowBase>;

        constructor() {
            this._stack = [];
        }

        public push(action: WorkflowBase): this {
            this._stack.push(action);

            return this;
        }

        public shift(): WorkflowBase {
            return this._stack.shift();
        }

        public isEmpty(): boolean {
            return this._stack.length === 0;
        }

        public process(): Deferred {
            if (!this._stack.length) {
                return $.Deferred().resolve().promise();
            }

            const deferred = $.Deferred();
            const me = this;
            const responses: Array<Response.ResponseBase> = [];

            (function exec(response?: Response.ResponseBase) {
                if (response) {
                    responses.push(response);
                }

                if (me.isEmpty()) {
                    deferred.resolve(responses);
                    return;
                }

                return me.shift().Execute().then(exec, exec);
            })();

            return deferred.promise();
        }
    }
}