module Utils.SVGEditor {

    export abstract class DrawHandler {
        public isUserDrawing: boolean = false;
        public d3SvgElement: any
        protected onChangeListeners: Function[];
        protected editor: SVGEditor;

        constructor(editor: SVGEditor, svg: SVGSVGElement, onChangeListener?: Function) {
            this.editor = editor;
            this.d3SvgElement = d3.select(svg);
            this.setOnChangeListener(onChangeListener);
        }

        public abstract bindEvents(): void;

        public UnbindEvents(): void {
            this.d3SvgElement
                .on('mousedown', null)
                .on('touchstart', null)
                .on('touchend', null)
                .on('touchleave', null)
                .on('mouseup', null)
                .on('mouseleave', null);
        }

        private setOnChangeListener(listener: Function): void {
            if (!listener) {
                return;
            }

            this.onChangeListeners = this.onChangeListeners || [];
            this.onChangeListeners.push(listener);
        }

        protected triggerChange(): void {
            if (!this.onChangeListeners || !this.onChangeListeners.length) {
                return;
            }

            for (const listener of this.onChangeListeners) {
                if (listener) {
                    listener();
                }
            }
        }

        protected getPoint(event: MouseEvent | TouchEvent): IPoint | null {
            const point = this.getPointByEvent(event);

            if (!point) {
                return null;
            }

            this.mutateByScaling(point);
            this.mutateYAxisOnIOS(point)
            this.mutateByRounding(point)

            return point;
        }

        private getPointByEvent(event: MouseEvent | TouchEvent): IPoint | null {
            if (event instanceof MouseEvent) {
                return this.getPointByMouseEvent(event);
            }

            if (event instanceof TouchEvent) {
                return this.getPointByTouchEvent(event);
            }

            return null;
        }

        private getPointByMouseEvent(event: MouseEvent): IPoint {
            return {
                x: event.offsetX,
                y: event.offsetY
            }
        }

        private getPointByTouchEvent(event: TouchEvent): IPoint | null {
            if (!(event.currentTarget instanceof Element)) {
                return null;
            }

            if (event.touches.length < 1) {
                return null;
            }

            const targetRect = event.currentTarget.getBoundingClientRect();
            const touchPoint = event.touches[0];

            return {
                x: touchPoint.clientX - targetRect.left,
                y: touchPoint.clientY - targetRect.top
            }
        }

        private mutateByScaling(point: IPoint): void {
            const svg: SVGSVGElement = this.d3SvgElement.node();
            const viewBox: SVGAnimatedRect = svg.viewBox;

            point.x *= viewBox.baseVal.width / svg.clientWidth;
            point.y *= viewBox.baseVal.height / svg.clientHeight;
        }

        private mutateYAxisOnIOS(point: IPoint): void {
            if (Session.IsRunningOnIOS) {
                point.y += $(window).scrollTop();
            }
        }

        private mutateByRounding(point: IPoint): void {
            point.x = Math.round(point.x);
            point.y = Math.round(point.y);
        }
    }
}
