//imports-start
/// <reference path="./property.ts"  />
//imports-end

module Model.Properties {
    export class StateInfo {
        public StateChangeAllowed: boolean;
        public FollowerStates: Array<Property>;

        constructor() {
            this.StateChangeAllowed = false;
            this.FollowerStates = [];
        }

        public SetStateChangeAllowed(flag: boolean): StateInfo {
            this.StateChangeAllowed = flag;

            return this;
        }

        public ClearFollowerState(): StateInfo {
            this.FollowerStates = [];

            return this;
        }

        public AddFollowerState(state: Property): StateInfo {
            const index = Utils.GetIndex(this.FollowerStates, state.OID, 'OID');

            if (~index) {
                this.FollowerStates[index] = state;
            } else {
                this.FollowerStates.push(state);
            }

            return this;
        }

        public RemoveFollowerState(state: Property): StateInfo {
            const index = Utils.GetIndex(this.FollowerStates, state.OID, 'OID');

            if (~index) {
                this.FollowerStates.splice(index, 1);
            }

            return this;
        }
    }
}