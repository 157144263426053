//imports-start
/// <reference path="../definitions.d.ts" />
/// <reference path="./utils.spinner.ts" />
/// <reference path="../app/app.ts" />
//imports-end

module Utils {
    export class ElementPickerPopup {
        private _searchInputOptions: Model.ClearableInput.Settings;
        private _searchInputField: Model.ClearableInput.Control;

        private _$overlay;
        private _$win;
        private _$btnHome;
        private _$searchRow;
        private _$tabControl;
        private _$btnReset;
        private _$btnQrCode;
        private _$btnNfc;
        private _$btnAbort;
        private _$btnOk;
        private _$btnDelete;
        private _$treeWrapper;

        private _visibleObjectTypes: Array<Enums.ElementType> | Array<Enums.PropertyType>;
        private _tree: Model.Tree;
        private _isLocationTree: boolean;
        private _isSelectionAllowed: boolean;
        private _isMultiSelectionAllowed: boolean;
        private _showFloorPlan: boolean;
        private _floorPlanIsInitialised: boolean;
        private _enableMarksOnFloorPlan: boolean;
        private _floorPlanID: string;
        private _floorPlanMarks: any;
        private _floorPlanMarksFillColor: string;
        private _floorPlanMarksStrokeColor: string;
        private _showResetButton: boolean;
        private _showQRCodeScannerButton: boolean;
        private _showNfcScannerButton: boolean;

        private _onDestroy: ElementPickerPopup.SelfReferenceCallback;
        private _floorPlan: Model.FloorPlan;

        constructor(options: ElementPickerPopup.Options) {
            this.initVariables(options);

            Utils.Spinner.Show();

            setTimeout(() => {
                $('body').append(this._$win);

                this._$win.modal({
                    show: true,
                    keyboard: false,
                    backdrop: false
                });

                Utils.ResizeModalWindow(this._$win);

                this._tree.Build();

                Utils.Spinner.Hide();

                this.bindEvents(options);

                if (this._$win.find('.canvas').is(':visible')) {
                    this.renderFloorPlan();
                }

                if (options.OnAfterWindowRendered instanceof Function) {
                    options.OnAfterWindowRendered(this._$win);
                }
            }, 100);
        }

        public Destroy() {
            this._searchInputOptions = null;
            this._searchInputField = null;
            this._$searchRow = null;

            if (this._$overlay) {
                Utils.Overlay.DestroyWithTimeout(this._$overlay);
                this._$overlay = null;
            }

            if (this._$win) {
                this._$win.remove();
                this._$win = null;
            }

            $('body').removeClass('modal-open').css('padding-right', '');

            if (this._onDestroy instanceof Function) {
                this._onDestroy(this);
            }
        }

        private onBtnHomeClick() {
            this.Destroy();
            Utils.Router.PushState('#main');
        }

        private onNavigateToLocationThroughFloorPlan(location: Model.Elements.Element) {
            this._tree
                .SetActiveItem(location.OID)
                .Build();

            this.renderFloorPlan();
        }

        private onBtnAddFloorPlanMarkClick() {
            this._floorPlan.CreateNewMark();
        }

        private onToggleMarkSelectionMode(evt: MouseEvent) {
            const $item = $(evt.currentTarget);

            $item.toggleClass('active');

            if ($item.hasClass('active')) {
                this._$win.find('.floor-plan').addClass('deletion-activated');
            } else {
                this._$win.find('.floor-plan').removeClass('deletion-activated');
            }

            this._floorPlan.SetRemoveMarkOnClick($item.hasClass('active'));
        }

        private onBtnAbortClick() {
            this.Destroy();
        }

        private onTabControlItemClick(evt: MouseEvent) {
            const $item = $(evt.currentTarget);
            const selectedTab = $item.data('tab');

            $item.addClass('selected').siblings().removeClass('selected');

            switch (selectedTab) {
                case 'tree-structure':
                    this._$win.find('.location').removeClass('screen-sm-max-invisible').addClass('screen-sm-max-visible');
                    this._$win.find('.floor-plan-container')
                        .removeClass('screen-sm-max-visible')
                        .addClass('screen-sm-max-invisible');
                    break;
                case 'floorplan':
                    this._$win.find('.location').removeClass('screen-sm-max-visible').addClass('screen-sm-max-invisible');
                    this._$win.find('.floor-plan-container')
                        .removeClass('screen-sm-max-invisible')
                        .addClass('screen-sm-max-visible');

                    if (!this._floorPlanIsInitialised) {
                        this.renderFloorPlan();
                    }

                    break;
            }
        }

        private onTreeNodeClick(evt: MouseEvent) {
            const $li = $(evt.currentTarget);
            const identifier = $li.data('identifier');

            if ($li.hasClass('disabled')) {
                return;
            }

            if (!this._isSelectionAllowed) {
                if (identifier === this._tree.ActiveItemIdentifier || $li.hasClass('disabled')) {
                    this._tree.ToggleNodeState(identifier);
                } else {
                    this._tree.SetActiveItem(identifier);
                }

                this._tree.Build();

                this._floorPlanIsInitialised = false;

                if (this._$win.find('.canvas').is(':visible')) {
                    this.renderFloorPlan();
                }
            } else {
                this._tree.ToggleNodeSelection(identifier, !this._isMultiSelectionAllowed);

                if (this._tree.GetIsNodeCollapsed(identifier)) {
                    this._tree
                        .ToggleNodeState(identifier)
                        .Build();
                }
            }
        }

        private renderFloorPlan(): void {
            const location: Model.Elements.Element = View.CurrentView === Enums.View.Scheduling ?
                ParameterList.GetElementRevisionByOID(this._tree.ActiveItemIdentifier) :
                DAL.Elements.GetByOID(this._tree.ActiveItemIdentifier);

            if (this._showFloorPlan) {
                this._floorPlan = new Model.FloorPlan({
                    ID: this._floorPlanID,
                    Location: location,
                    $Container: this._$win.find('.canvas'),
                    OnNavigateToLocation: (location: Model.Elements.Element) => this.onNavigateToLocationThroughFloorPlan(location),
                    ExistingMarks: this._floorPlanMarks,
                    MarksFillColor: this._floorPlanMarksFillColor,
                    MarksStrokeColor: this._floorPlanMarksStrokeColor
                });

                if (this._enableMarksOnFloorPlan) {
                    this._$win.find('.canvas').append('<div class="remove-mark icon-bin2"></div>');
                    this._$win.find('.remove-mark').on('click', (evt: MouseEvent) => this.onToggleMarkSelectionMode(evt));
                }

                this._floorPlanIsInitialised = true;
            }
        }

        private bindEvents(options: ElementPickerPopup.Options): void {
            this._$btnAbort.on('click', () => {
                this.onBtnAbortClick();
            });

            this._$btnHome.on('click', () => {
                this.onBtnHomeClick();
            });

            this._$btnDelete.on('click', () => {
                options.OnConfirmSelection(null);

                this.Destroy();
            });

            this._$btnOk.on('click', () => {
                const result = {
                    SelectedItems: null,
                    SelectedOID: null,
                    ElementOID: null,
                    Marks: null
                };

                if (this._isMultiSelectionAllowed) {
                    result.SelectedItems = this._tree.SelectedNodeIdentifiers;
                } else if (this._isSelectionAllowed) {
                    result.SelectedOID = this._tree.SelectedNodeIdentifiers[0];
                } else {
                    if (this._isLocationTree) {
                        result.ElementOID = this._tree.ActiveItemIdentifier;
                    } else {
                        result.SelectedOID = this._tree.ActiveItemIdentifier;
                    }

                    if (this._enableMarksOnFloorPlan && this._floorPlan) {
                        result.Marks = this._floorPlan.GetFloorPlanMarks();
                    }
                }

                options.OnConfirmSelection(result);

                this.Destroy();
            });

            if (this._showResetButton && options.OnResetButtonClick instanceof Function) {
                this._$btnReset.on('click', () => {
                    options.OnResetButtonClick();
                    this.Destroy();
                });
            }

            if (this._showQRCodeScannerButton) {
                if (!(options.OnBtnStartQRCodeScannerClick instanceof Function)) {
                    this._$btnQrCode.on('click', () => {
                        App.StartQrCodeScanner(() => {
                            this.Destroy();
                        });
                    });
                } else {
                    this._$btnQrCode.on('click', () => {
                        options.OnBtnStartQRCodeScannerClick(this);
                    });
                }
            }

            if (this._showNfcScannerButton) {
                // iOS only required
                if (Session.NfcEnabled && Session.IsRunningOnIOS) {
                    this._$btnNfc.on('click', () => {
                        App.StartNfcScanner()
                            .then(() => this.Destroy());
                    });
                }
            }

            if (this._showFloorPlan) {
                this._$win.find('.btn-add-default-mark').on('click', () => {
                    this.onBtnAddFloorPlanMarkClick();
                });

                this._$tabControl.on('click', 'li:not(.selected)', (evt: MouseEvent) => {
                    this.onTabControlItemClick(evt);
                });
            }
        }

        private initVariables(options: ElementPickerPopup.Options): void {
            if (!Utils.IsSet(options.ZIndex)) {
                options.ZIndex = 1301;
            }

            this._$overlay = Utils.Overlay.Generate('olElementPicker', options.ZIndex - 1);
            this._$win = $(Templates.Selections.ElementPickerPopup({
                Title: options.WindowTitle || i18next.t('ElementPicker.SelectLocation'),
                ShowFloorPlan: options.ShowFloorPlan,
                EnableMarksOnFloorPlan: options.ShowFloorPlan && options.EnableMarksOnFloorPlan,
                ShowQRCodeScannerButton: !!options.ShowQRCodeScannerButton,
                ShowNfcScannerButton: !!options.ShowNfcScannerButton,
                ShowResetButton: options.ShowResetButton,
                ZIndex: options.ZIndex,
                ShowDeleteButton: options.ShowDeleteButton
            }));

            this._$btnHome = this._$win.find('.btn-home');

            this._$btnReset = this._$win.find('.btn-reset');
            this._$btnQrCode = this._$win.find('.qr-code');
            this._$btnNfc = this._$win.find('.nfc');
            this._$btnAbort = this._$win.find('.btn-abort');
            this._$btnOk = this._$win.find('.btn-ok');
            this._$btnDelete = this._$win.find('.btn-delete');

            this._$treeWrapper = this._$win.find('.tree-wrapper');

            this._isLocationTree = !options.IsNotALocationTree;
            this._isSelectionAllowed = options.SelectionAllowed || options.MultiSelectionAllowed;
            this._isMultiSelectionAllowed = options.MultiSelectionAllowed;

            this._showFloorPlan = options.ShowFloorPlan;
            this._enableMarksOnFloorPlan = options.EnableMarksOnFloorPlan;

            if (this._showFloorPlan) {
                this._floorPlanIsInitialised = false;
                this._floorPlanID = options.FloorPlanID;
                this._floorPlanMarks = options.FloorPlanMarks;
                this._floorPlanMarksFillColor = options.FloorPlanMarksFillColor;
                this._floorPlanMarksStrokeColor = options.FloorPlanMarksStrokeColor;
                this._$tabControl = this._$win.find('.tab-control');
            }

            this._showQRCodeScannerButton = !!options.ShowQRCodeScannerButton;
            this._showNfcScannerButton = !!options.ShowNfcScannerButton;
            this._showResetButton = options.ShowResetButton;

            this._visibleObjectTypes = options.IsNotALocationTree ?
                options.VisibleObjectTypes :
                [Enums.ElementType.Root, Enums.ElementType.Location];

            this._$searchRow = this._$win.find('.search');

            const searchPresentation = Session.UserDeviceSettings.SearchResultPresentation;

            this._searchInputOptions = new Model.ClearableInput.Settings({
                ID: 'location-popup-search-input',
                InputType: 'text',
                ShowClearButton: true,
                ShowFilterButton: options.EnableKeywordFilter || false,
                ShowPresentationButton: options.EnablePresentationButton === false ? false : true,
                DefaultPresentation: searchPresentation
            });

            this._searchInputField = new Model.ClearableInput.Control(this._searchInputOptions);
            this._searchInputField.Build(this._$searchRow);

            this._onDestroy = options.OnDestroy;

            this.initTree(options);
        }

        private initTree(options: ElementPickerPopup.Options): void {
            const searchFieldPlaceholderText = options.SearchFieldPlaceholder ||
                i18next.t('SearchfieldPlaceholders.MainTree');

            this._tree = new Model.Tree()
                .SetIdentifier('popup-element-picker')
                .SetShowParentTitle(false)
                .SetShowExpanders(true)
                .SetForceExpand(!!options.ForceExpand)
                .SetKeyProperty(options.KeyProperty || 'OID')
                .SetRenderingContainer(this._$treeWrapper)
                .SetOnNodeClick((evt: MouseEvent) => this.onTreeNodeClick(evt))
                .SetRenderAsListView(!!options.RenderAsListView)
                .SetIsReadonly(!!options.IsReadonly);

            if (this._isSelectionAllowed) {
                this._tree
                    .SetEnableSelection(true)
                    .SetEnableCheckmarks(true);
            }

            if (!isNaN(options.NodeHeight)) {
                this._tree.SetNodeHeight(options.NodeHeight);
            }

            if (!!options.ItemTitlePattern) {
                this._tree.SetItemTitlePattern(options.ItemTitlePattern);
            }

            if ((options.SearchFields || []).length) {
                this._tree.SetSearchFields(options.SearchFields);
            }

            if (options.CannotCollapseRoot) {
                this._tree.SetRootCollapsable(false);
            }

            if (options.FnHideItem instanceof Function) {
                this._tree.SetFilter((item?: TreeItem | TreeSourceItem) => options.FnHideItem(item));
            } else if ((this._visibleObjectTypes || []).length) {
                this._tree.SetFilter((item: TreeSourceItem) => this.filterTreeItem(item));
            }

            this._tree.SetSearchField(this._searchInputField, searchFieldPlaceholderText);

            let rootItem: Model.Elements.Element | TreeSourceItem;
            if (!options.RenderAsListView) {
                if (!options.IsNotALocationTree && Utils.InArray(<Array<Enums.ElementType>>this._visibleObjectTypes,
                    Enums.ElementType.Location)) {
                    rootItem = View.CurrentView === Enums.View.Scheduling ?
                        ParameterList.GetResubmissionTree() :
                        DAL.Elements.Root;
                } else {
                    rootItem = options.RootItem;
                }

                if (!rootItem) {
                    return;
                }
            }

            if (options.FnItemIsDisabled instanceof Function) {
                this._tree.SetFnIsDisabled(options.FnItemIsDisabled);
            }

            if (options.ShowColor) {
                this._tree.SetShowColors(true);
            }

            if (Utils.HasProperties(options.AdditionalClasses)) {
                this._tree.SetAdditionalClasses(options.AdditionalClasses);
            }

            if (Utils.HasProperties(options.AdditionalTexts)) {
                this._tree.SetAdditionalTexts(options.AdditionalTexts);
            }

            if ((options.SelectedItemIdentifiers || []).length) {
                this._tree.SetSelectedItems(options.SelectedItemIdentifiers);
            }

            if (!options.RenderAsListView) {
                if (typeof options.Items !== 'object' || !options.RootItem || !options.RootItem.OID) {
                    this._tree.SetItems(DAL.Elements.GetAll(), rootItem.OID);
                } else {
                    this._tree.SetItems(options.Items, options.RootItem.OID);
                }
            } else {
                this._tree
                    .SetHideUnmatchedListViewItems(!!options.HideUnmatchedListViewItems)
                    .SetHideUnsearched(!!options.HideUnsearchedItems)
                    .SetItems(options.Items);
            }

            if (!!options.SelectedItemIdentifier) {
                this._tree.SetActiveItem(options.SelectedItemIdentifier);
            }
        }

        private filterTreeItem(item: TreeSourceItem): boolean {
            if (item['Type'] != null) {
                return Utils.InArray(this._visibleObjectTypes, (<{ Type: Enums.ElementType | Enums.PropertyType }>item).Type);
            }

            return true;
        }

        public IsVisible(): boolean {
            return this._$win && this._$win.is(':visible');
        }

        public SelectElement(identifier: string, immediateReturnOnFind: boolean = false): boolean {
            const node = ((this._tree || {}).ItemDictionary || {})[identifier];

            if (node) {
                this._tree.SetActiveItem(identifier);

                if (immediateReturnOnFind && this._$btnOk) {
                    this._$btnOk.trigger('click');
                }

                return true;
            }

            return false;
        }
    }

    export module ElementPickerPopup {
        export type ConfirmSelectionResult = {
            SelectedItems?: Array<string>,
            SelectedOID?: string,
            ElementOID?: string,
            Marks?: Array<Model.Issues.LocationMarker>
        };

        export type ConfirmSelectionCallback = (result: ConfirmSelectionResult) => void;
        export type SelfReferenceCallback = (picker: ElementPickerPopup) => void;

        export type Options = {
            OnConfirmSelection?: ConfirmSelectionCallback;
            OnBtnStartQRCodeScannerClick?: SelfReferenceCallback;
            OnDestroy?: SelfReferenceCallback;
            OnResetButtonClick?: Function;
            OnAfterWindowRendered?: Function;
            FnHideItem?: Model.ItemFilterCallback;
            FnItemIsDisabled?: Model.ItemIsDisabledCallback;

            EnableKeywordFilter?: boolean;
            EnablePresentationButton?: boolean;
            WindowTitle?: string;
            ShowFloorPlan?: boolean;
            EnableMarksOnFloorPlan?: boolean;
            FloorPlanMarksFillColor?: string;
            FloorPlanMarksStrokeColor?: string;
            ShowQRCodeScannerButton?: boolean;
            ShowNfcScannerButton?: boolean;
            ShowResetButton?: boolean;
            ShowDeleteButton?: boolean;
            IsNotALocationTree?: boolean;
            SelectionAllowed?: boolean;
            MultiSelectionAllowed?: boolean;
            FloorPlanID?: string;
            FloorPlanMarks?: any;
            VisibleObjectTypes?: Array<Enums.ElementType> | Array<Enums.PropertyType>;

            SearchFieldPlaceholder?: string;
            ForceExpand?: boolean;
            KeyProperty?: string;
            RenderAsListView?: boolean;
            NodeHeight?: number;
            ItemTitlePattern?: string | ((i: TreeItem) => string);
            SearchFields?: Array<string>;
            CannotCollapseRoot?: boolean;
            RootItem?: TreeSourceItem;
            ShowColor?: boolean;
            AdditionalClasses?: Dictionary<string[]>;
            AdditionalTexts?: Dictionary<string>;
            SelectedItemIdentifiers?: Array<string>;
            Items?: Array<TreeSourceItem> | Dictionary<TreeSourceItem>;
            HideUnmatchedListViewItems?: boolean;
            HideUnsearchedItems?: boolean;
            SelectedItemIdentifier?: string;
            IsReadonly?: boolean;
            ZIndex?: number;
        }

        // TODO zu einer eigenen, wiederverwendbaren Klasse auslagern?
        module InstanceManager {
            const PickerInstances: ElementPickerPopup[] = [];

            export function Add(instance: ElementPickerPopup): ElementPickerPopup {
                PickerInstances.push(instance);
                return instance;
            }

            export function Remove(instance: ElementPickerPopup): boolean {
                const pos = PickerInstances.indexOf(instance);

                if (pos < 0) {
                    return false;
                }

                PickerInstances.splice(pos, 1);

                return true;
            }

            export function IsVisible(): boolean {
                for (const item of PickerInstances) {
                    if (item.IsVisible()) {
                        return true;
                    }
                }

                return false;
            }
        }

        export function Show(options: ElementPickerPopup.Options): ElementPickerPopup {
            if (options.OnDestroy instanceof Function) {
                const pickerCallback = options.OnDestroy;
                options.OnDestroy = function(picker: ElementPickerPopup) {
                    InstanceManager.Remove(picker);
                    pickerCallback(picker);
                };
            } else {
                options.OnDestroy = function(picker: ElementPickerPopup) {
                    InstanceManager.Remove(picker);
                };
            }

            return InstanceManager.Add(new ElementPickerPopup(options));
        }

        export function IsAnyVisible(): boolean {
            return InstanceManager.IsVisible();
        }
    }
}
