(function (global) {
    function Stack() {
        this._values = [];
    }

    Stack.prototype.isEmpty = function () {
        return this._values.length === 0;
    };

    Stack.prototype.push = function (value) {
        this._values.push(value);
        return this;
    };

    Stack.prototype.pop = function () {
        return this._values.pop();
    };

    Stack.prototype.peek = function () {
        return this.isEmpty() ? undefined : this._values[this._values.length - 1];
    };


    function OperatorToken(symbol, isUnary) {
        this._symbol = symbol;
        this._isUnary = isUnary || false;

        switch (symbol) {
            case '!':
                this._rightAssociative = true;
                this._precedence = 7;
                break;
            case '^':
                this._rightAssociative = true;
                this._precedence = 7;
                break;
            case '*':
            case '/':
            case '%':
                this._rightAssociative = false;
                this._precedence = 6;
                break;
            case '+':
            case '-':
                if (isUnary) {
                    this._rightAssociative = true;
                    this._precedence = 7;
                } else {
                    this._rightAssociative = false;
                    this._precedence = 5;
                }
                break;
            case '<':
            case '<=':
            case '>':
            case '>=':
                this._rightAssociative = false;
                this._precedence = 4;
                break;
            case '==':
            case '!=':
                this._rightAssociative = false;
                this._precedence = 3;
                break;
            case '&&':
                this._rightAssociative = false;
                this._precedence = 2;
                break;
            case '||':
                this._rightAssociative = false;
                this._precedence = 1;
                break;
            default:
                throw 'Unknown operator "' + symbol + '"';
        }
    }

    OperatorToken.prototype.getSymbol = function () {
        return this._symbol;
    };

    OperatorToken.prototype.isLeftAssociative = function () {
        return !this._rightAssociative;
    };

    OperatorToken.prototype.isRightAssociative = function () {
        return this._rightAssociative;
    };

    OperatorToken.prototype.isUnary = function () {
        return this._isUnary;
    };

    OperatorToken.prototype.isBinary = function () {
        return !this._isUnary;
    };

    OperatorToken.prototype.comparePrecedence = function (other) {
        return this._precedence - other._precedence;
    };

    OperatorToken.prototype.toString = function () {
        return this._symbol;
    };


    function FunctionToken(name, arity) {
        this._name = name;
        this._arity = arity || 0;
    }

    FunctionToken.prototype.getName = function () {
        return this._name;
    };

    FunctionToken.prototype.setName = function (name) {
        this._name = name;
        return this;
    };

    FunctionToken.prototype.getArity = function () {
        return this._arity;
    };

    FunctionToken.prototype.setArity = function (arity) {
        this._arity = arity;
        return this;
    };

    FunctionToken.prototype.toString = function () {
        return this._name;
    };


    function FunctionLeftParenthesisToken() {
    }

    FunctionLeftParenthesisToken.prototype.toString = function () {
        return '(';
    };


    function FunctionRightParenthesisToken() {
    }

    FunctionRightParenthesisToken.prototype.toString = function () {
        return ')';
    };


    function LeftParenthesisToken() {
    }

    LeftParenthesisToken.prototype.toString = function () {
        return '(';
    };


    function RightParenthesisToken() {
    }

    RightParenthesisToken.prototype.toString = function () {
        return ')';
    };


    function CommaToken() {
    }

    CommaToken.prototype.toString = function () {
        return ',';
    };


    function NumberToken(value) {
        this._value = value;
    }

    NumberToken.prototype.getValue = function () {
        return this._value;
    };

    NumberToken.prototype.toString = function () {
        return this._value.toString();
    };


    function StringToken(value) {
        this._value = value || '';
    }

    StringToken.prototype.getValue = function () {
        return this._value;
    };

    StringToken.prototype.toString = function () {
        return '"' + this._value + '"';
    };


    function PartialStringToken(value) {
        this._value = value || '';
    }

    PartialStringToken.prototype.getValue = function () {
        return this._value;
    };

    PartialStringToken.prototype.toString = function () {
        return '"' + this._value;
    };


    function ReferenceToken(type, id) {
        this._type = type;
        this._id = id;
    }

    ReferenceToken.prototype.getType = function () {
        return this._type;
    };

    ReferenceToken.prototype.getId = function () {
        return this._id;
    };

    ReferenceToken.prototype.toString = function () {
        return '#' + this._type + '<' + this._id + '>';
    };

    ReferenceToken.prototype.getValue = function () {
        return this._id;
    };


    function WhitespaceToken() {
    }

    WhitespaceToken.prototype.toString = function () {
        return ' ';
    };


    function UnknownToken(value) {
        this._value = value;
    }

    UnknownToken.prototype.toString = function () {
        return this._value;
    };

    var _numberRegex = /[\d.]/;
    var _alphaRegex = /[a-z]/i;
    var _alphanumericRegex = /[\da-z_]/i;
    var _referenceRegex = /^#([\a-z]+)<([\da-z_-]+)>$/i;

    function tokenize(expression) {
        var tokens = [];
        var parenthesesStack = new Stack();
        var idx = 0;
        var lastIdx = expression.length - 1;
        var state, char, nextChar, remember, j, otherToken, match;

        while (idx <= lastIdx) {
            char = expression[idx];

            switch (state) {
                case 'WithinReference':
                    if (char === '>') {
                        match = _referenceRegex.exec(remember + char);

                        if (match) {
                            tokens.push(new ReferenceToken(match[1], match[2]));
                        } else {
                            tokens.push(new UnknownToken(remember + char));
                        }

                        state = null;
                        remember = null;
                    } else if (idx === lastIdx) {
                        tokens.push(new UnknownToken(remember + char));
                        state = null;
                        remember = null;
                    } else {
                        remember += char;
                    }
                    break;
                case 'WithinString':
                    if (char === '"') {
                        tokens.push(new StringToken(remember));
                        state = null;
                        remember = null;
                    } else if (idx === lastIdx) {
                        tokens.push(new PartialStringToken(remember + char));
                        state = null;
                        remember = null;
                    } else {
                        remember += char;
                    }
                    break;
                case 'WithinNumber':
                    if (_numberRegex.test(char)) {
                        if (idx === lastIdx) {
                            if (char === '.') {
                                tokens.push(new NumberToken(Number(remember)));
                                tokens.push(new UnknownToken('.'));
                            } else {
                                tokens.push(new NumberToken(Number(remember + char)));
                            }

                            state = null;
                            remember = null;
                        } else {
                            remember += char;
                        }
                    } else {
                        tokens.push(new NumberToken(Number(remember)));
                        state = null;
                        remember = null;
                        idx--;
                    }
                    break;
                case 'WithinFunction':
                    if (char === '(') {
                        tokens.push(new FunctionToken(remember));
                        tokens.push(new FunctionLeftParenthesisToken());
                        parenthesesStack.push(new FunctionLeftParenthesisToken());
                        state = null;
                    } else if (!_alphanumericRegex.test(char)) {
                        tokens.push(new FunctionToken(remember));
                        state = null;
                        remember = null;
                        idx--;
                    } else {
                        remember += char;

                        if (idx === lastIdx) {
                            tokens.push(new FunctionToken(remember));
                            state = null;
                            remember = null;
                        }
                    }
                    break;
                default:
                    switch (char) {
                        case ' ':
                            tokens.push(new WhitespaceToken());
                            break;
                        case ',':
                            tokens.push(new CommaToken());
                            break;
                        case '(':
                            tokens.push(new LeftParenthesisToken());
                            parenthesesStack.push(new LeftParenthesisToken());
                            break;
                        case ')':
                            if (parenthesesStack.pop() instanceof FunctionLeftParenthesisToken) {
                                tokens.push(new FunctionRightParenthesisToken());
                            } else {
                                tokens.push(new RightParenthesisToken());
                            }
                            break;
                        case '#':
                            state = 'WithinReference';
                            remember = '';
                            idx--;
                            break;
                        case '"':
                            if (idx === lastIdx) {
                                tokens.push(new PartialStringToken());
                            } else {
                                state = 'WithinString';
                                remember = '';
                            }
                            break;
                        case '=':
                            nextChar = idx < lastIdx ? expression[idx + 1] : null;

                            if (nextChar === '=') {
                                tokens.push(new OperatorToken('=='));
                                idx++;
                            } else {
                                tokens.push(new UnknownToken(char));
                            }
                            break;
                        case '!':
                            nextChar = idx < lastIdx ? expression[idx + 1] : null;

                            if (nextChar === '=') {
                                tokens.push(new OperatorToken('!='));
                                idx++;
                            } else {
                                tokens.push(new OperatorToken('!', true));
                            }
                            break;
                        case '<':
                        case '>':
                            nextChar = idx < lastIdx ? expression[idx + 1] : null;

                            if (nextChar === '=') {
                                tokens.push(new OperatorToken(char + '='));
                                idx++;
                            } else {
                                tokens.push(new OperatorToken(char));
                            }
                            break;
                        case '&':
                            nextChar = idx < lastIdx ? expression[idx + 1] : null;

                            if (nextChar === '&') {
                                tokens.push(new OperatorToken('&&'));
                                idx++;
                            } else {
                                tokens.push(new UnknownToken(char));
                            }
                            break;
                        case '|':
                            nextChar = idx < lastIdx ? expression[idx + 1] : null;

                            if (nextChar === '|') {
                                tokens.push(new OperatorToken('||'));
                                idx++;
                            } else {
                                tokens.push(new UnknownToken(char));
                            }
                            break;
                        case '^':
                        case '*':
                        case '/':
                        case '%':
                            tokens.push(new OperatorToken(char));
                            break;
                        case '+':
                        case '-':
                            otherToken = null;
                            j = tokens.length - 1;

                            while (j >= 0) {
                                if (!(tokens[j] instanceof WhitespaceToken)) {
                                    otherToken = tokens[j];
                                    break;
                                }

                                j--;
                            }

                            if (otherToken instanceof OperatorToken ||
                                otherToken instanceof FunctionLeftParenthesisToken ||
                                otherToken instanceof LeftParenthesisToken ||
                                otherToken instanceof CommaToken) {
                                tokens.push(new OperatorToken(char, true));
                            } else {
                                tokens.push(new OperatorToken(char));
                            }
                            break;
                        case '0':
                        case '1':
                        case '2':
                        case '3':
                        case '4':
                        case '5':
                        case '6':
                        case '7':
                        case '8':
                        case '9':
                            state = 'WithinNumber';
                            remember = '';
                            idx--;
                            break;
                        default:
                            if (_alphaRegex.test(char)) {
                                state = 'WithinFunction';
                                remember = '';
                                idx--;
                            } else {
                                tokens.push(new UnknownToken(char));
                            }
                            break;
                    }
                    break;
            }

            idx++;
        }

        return tokens;
    }

    function stringify(tokens) {
        return tokens
            .map(function (token) {
                return token.toString();
            })
            .join('');
    }

    function convertInfixToRpn(tokens) {
        var output = [];
        var idx = 0;
        var lastIdx = tokens.length - 1;
        var tokenStack = new Stack();
        var arityStack = new Stack();
        var token, otherToken, arity;

        while (idx <= lastIdx) {
            token = tokens[idx];

            if (token instanceof OperatorToken) {
                while (true) {
                    otherToken = tokenStack.peek();

                    if (otherToken instanceof OperatorToken &&
                        (otherToken.comparePrecedence(token) > 0 ||
                            otherToken.comparePrecedence(token) === 0 &&
                            otherToken.isLeftAssociative())) {
                        output.push(tokenStack.pop());
                    } else {
                        break;
                    }
                }

                tokenStack.push(token);
            } else if (token instanceof FunctionToken) {
                if (idx + 2 > lastIdx) {
                    throw 'Unexpected end of input';
                }

                if (tokens[idx + 2] instanceof RightParenthesisToken ||
                    tokens[idx + 2] instanceof FunctionRightParenthesisToken) {
                    output.push(token);
                    idx += 2;
                } else {
                    arityStack.push(1);
                    tokenStack.push(token);
                }
            } else if (token instanceof LeftParenthesisToken ||
                token instanceof FunctionLeftParenthesisToken) {
                tokenStack.push(token);
            } else if (token instanceof RightParenthesisToken ||
                token instanceof FunctionRightParenthesisToken) {
                while (true) {
                    otherToken = tokenStack.pop();

                    if (!otherToken) {
                        throw 'Unbalanced parentheses';
                    }

                    if (otherToken instanceof LeftParenthesisToken ||
                        otherToken instanceof FunctionLeftParenthesisToken) {
                        if (tokenStack.peek() instanceof FunctionToken) {
                            arity = arityStack.pop();
                            output.push(tokenStack.pop().setArity(arity));
                        }
                        break;
                    }

                    output.push(otherToken);
                }
            } else if (token instanceof CommaToken) {
                arity = arityStack.pop();

                if (!arity) {
                    throw 'Unexpected token ,';
                }

                arityStack.push(arity + 1);

                while (true) {
                    otherToken = tokenStack.peek();

                    if (!otherToken) {
                        throw 'Invalid formula';
                    }

                    if (otherToken instanceof LeftParenthesisToken ||
                        otherToken instanceof FunctionLeftParenthesisToken) {
                        break;
                    }

                    output.push(tokenStack.pop());
                }
            } else if (token instanceof NumberToken ||
                token instanceof StringToken ||
                token instanceof ReferenceToken) {
                output.push(token);
            } else if (token instanceof PartialStringToken) {
                throw 'Unexpected end of input';
            } else if (token instanceof UnknownToken) {
                throw 'Unexpected token ' + token.toString();
            }

            idx++;
        }

        while (true) {
            token = tokenStack.pop();

            if (!token) {
                break;
            }

            if (token instanceof LeftParenthesisToken ||
                token instanceof FunctionLeftParenthesisToken) {
                throw 'Unbalanced parentheses';
            }

            output.push(token);
        }

        return output;
    }

    function isNumber(value) {
        return typeof value === 'number' && !isNaN(value);
    }

    function isString(value) {
        return typeof value === 'string';
    }

    function isBoolean(value) {
        return typeof value === 'boolean';
    }

    function isDate(value) {
        return value instanceof Date;
    }

    function isSet(value) {
        return typeof value !== 'undefined' && value !== null && value !== '';
    }

    function isValidOperand(value) {
        return !isSet(value) || isNumber(value) || isString(value) || isBoolean(value) || isDate(value);
    }

    function getDateString(value) {
        var date = value;
        var dateString = Utils.DateTime.DateToString(date);

        return Utils.DateTime.IsTimeOfDateNull(date)
            ? dateString
            : (dateString + " " + date.toLocaleTimeString()).slice(0, -3);
    }

    function getElement(previousToken, idx, rpnTokens) {
        var element = null;
        var referenceToken;

        if (previousToken && previousToken instanceof FunctionToken && previousToken._name === "Value") {
            referenceToken = idx >= 2 ? rpnTokens[idx - 2] : null;
            if (referenceToken && referenceToken._type === "Element" && referenceToken._id) {
                element = ParameterList.GetElement(referenceToken._id);
            }
        }

        // Teilproben
        var subsampleReferenceToken, subsampleNumberToken;

        if (previousToken && previousToken instanceof FunctionToken && previousToken._name === "SubsampleValue") {
            subsampleReferenceToken = idx >= 3 ? rpnTokens[idx - 3] : null;
            subsampleNumberToken = idx >= 2 ? rpnTokens[idx - 2] : null;

            if (subsampleReferenceToken && subsampleReferenceToken._type === "Element" && subsampleReferenceToken._id &&
                subsampleNumberToken && subsampleNumberToken._value != null) {
                element = ParameterList.GetElement(subsampleReferenceToken._id, subsampleNumberToken._value);
            }
        }

        return element;
    }

    function checkIfParametersAreDateTypes(firstParameter, secondParameter) {
        return !!(firstParameter && firstParameter instanceof Date && secondParameter && secondParameter instanceof Date);
    }

    function Formula(options) {
        this._functions = {};

        Object
            .keys((options || {}).functions || {})
            .map(function (key) {
                var name = key.toLowerCase();
                var fn = options.functions[key];

                this._functions[name] = fn;
            }, this);
    }

    /**
     * Evaluiert eine gegebene Formel an Hand der übergebenen Daten.
     * @param {string|Array} expression
     * @param {object} data
     * @param {boolean} isIssueTitleGeneration
     * @returns {*}
     */
    Formula.prototype.evaluate = function (expression, data, isIssueTitleGeneration) {
        var infixTokens, rpnTokens, idx, lastIdx;
        var resultStack, token, i, arity, parameterArray, fnName, fn, result, symbol;
        var firstParameter, secondParameter, previousToken, element;

        if (typeof expression === 'string') {
            expression = expression.replace(/\s/g, ' ');
            infixTokens = tokenize(expression);
        } else if (expression != null &&
            Object.prototype.toString.call(expression) === '[object Array]') {
            infixTokens = expression;
        } else {
            throw "Not supported expression Argument!";
        }

        data = data || {};
        options = {};

        if (isIssueTitleGeneration) {
            options.expectedType = 'string';
        }

        rpnTokens = convertInfixToRpn(infixTokens);
        idx = 0;
        lastIdx = rpnTokens.length - 1;
        resultStack = new Stack();

        while (idx <= lastIdx) {
            token = rpnTokens[idx];

            if (token instanceof OperatorToken) {
                parameterArray = [];

                if (token.isUnary()) {
                    if (resultStack.isEmpty()) {
                        throw 'Operator ' + token.getSymbol() + ' expected 1 parameter.';
                    }

                    parameterArray.push(resultStack.pop());
                } else {
                    for (i = 0; i < 2; i++) {
                        if (resultStack.isEmpty()) {
                            throw 'Operator ' + token.getSymbol() + ' expected 2 parameters.';
                        }

                        parameterArray.push(resultStack.pop());
                    }
                }

                symbol = token.getSymbol();

                if (symbol === '+' || symbol === '==' || token.isBinary()) {
                    if (!parameterArray.every(isValidOperand)) {
                        throw 'Invalid number or string.';
                    }
                } else if (!parameterArray.every(isNumber)) {
                    throw 'Invalid number.';
                }

                var parametersAreDates = checkIfParametersAreDateTypes(parameterArray[0], parameterArray[1]);

                switch (symbol) {
                    case '!':
                        resultStack.push(!parameterArray[0]);
                        break;
                    case '^':
                        resultStack.push(parameterArray[1], parameterArray[0]);
                        break;
                    case '*':
                        resultStack.push(parameterArray[1] * parameterArray[0]);
                        break;
                    case '/':
                        if (parameterArray[0] === 0) {
                            throw 'Invalid argument.';
                        }

                        resultStack.push(parameterArray[1] / parameterArray[0]);
                        break;
                    case '%':
                        if (parameterArray[0] === 0) {
                            throw 'Invalid argument.';
                        }

                        resultStack.push(parameterArray[1] % parameterArray[0]);
                        break;
                    case '+':
                        if (token.isUnary()) {
                            resultStack.push(+parameterArray[0]);
                        } else {
                            firstParameter = parameterArray[0];
                            secondParameter = parameterArray[1];

                            previousToken = idx >= 1 ? rpnTokens[idx-1] : null;
                            element = getElement(previousToken, idx, rpnTokens);

                            if (element) {
                                switch (element.Type) {
                                    case Enums.ElementType.Date:
                                        if (firstParameter instanceof Date) {
                                            firstParameter = Utils.DateTime.DateToString(firstParameter);
                                        }
                                        break;
                                    case Enums.ElementType.Time:
                                        if (firstParameter instanceof Date) {
                                            firstParameter = firstParameter.toLocaleTimeString().slice(0, -3); // damit die Sekunden nicht angezeigt werden
                                        }
                                        break;
                                    case Enums.ElementType.Checkbox:
                                        firstParameter = isBoolean(firstParameter) ?
                                            (firstParameter ? i18next.t('Misc.Yes') : i18next.t('Misc.No'))
                                            : firstParameter;

                                        if (firstParameter == null) {
                                            firstParameter = i18next.t('Misc.Unrecorded');
                                        }
                                        break;
                                    case Enums.ElementType.ListBox:
                                        if (Utils.HasProperties(element.Structure)) {
                                            firstParameter = element.Structure[firstParameter];
                                        }
                                        break;
                                }
                            } else if (previousToken instanceof FunctionToken) {
                                if (Utils.InArray(['Date', 'Today', 'YearAdd', 'YearDif', 'MonthAdd', 'MonthDif', 'EndOfMonth', 'DayAdd', 'DayDif'], previousToken._name)) {
                                    if (firstParameter instanceof Date) {
                                        firstParameter = Utils.DateTime.DateToString(firstParameter);
                                    }
                                } else if (Utils.InArray(['Now', 'MinuteAdd', 'MinuteDif', 'HourAdd', 'HourDif', 'Time'], previousToken._name)) {
                                    if (firstParameter instanceof Date) {
                                        firstParameter = firstParameter.toLocaleTimeString().slice(0, -3);
                                    }
                                }

                                if (firstParameter instanceof Date) {
                                    firstParameter = getDateString(firstParameter);
                                }

                                if (secondParameter instanceof Date) {
                                    secondParameter = getDateString(secondParameter);
                                }
                            }

                            if (firstParameter instanceof Date) {
                                firstParameter = Utils.DateTime.DateToString(firstParameter);
                            }

                            if (secondParameter instanceof Date) {
                                secondParameter = Utils.DateTime.DateToString(secondParameter);
                            }

                            if (isIssueTitleGeneration) {
                                if (firstParameter == null) {
                                    firstParameter = i18next.t('RecorditemEditor.Formula.ValueError');
                                }

                                if (secondParameter == null) {
                                    secondParameter = i18next.t('RecorditemEditor.Formula.ValueError');
                                }
                            }

                            resultStack.push(secondParameter + firstParameter);
                        }
                        break;
                    case '-':
                        if (token.isUnary()) {
                            resultStack.push(-parameterArray[0]);
                        } else {
                            resultStack.push(parameterArray[1] - parameterArray[0]);
                        }
                        break;
                    case '<':
                        parametersAreDates ?
                            resultStack.push(parameterArray[1].getTime() < parameterArray[0].getTime()) :
                            resultStack.push(parameterArray[1] < parameterArray[0]);
                        break;
                    case '<=':
                        parametersAreDates ?
                            resultStack.push(parameterArray[1].getTime() <= parameterArray[0].getTime()) :
                            resultStack.push(parameterArray[1] <= parameterArray[0]);
                        break;
                    case '>':
                        parametersAreDates ?
                            resultStack.push(parameterArray[1].getTime() > parameterArray[0].getTime()) :
                            resultStack.push(parameterArray[1] > parameterArray[0]);
                        break;
                    case '>=':
                        parametersAreDates ?
                            resultStack.push(parameterArray[1].getTime() >= parameterArray[0].getTime()) :
                            resultStack.push(parameterArray[1] >= parameterArray[0]);
                        break;
                    case '==':
                        parametersAreDates ?
                            resultStack.push(parameterArray[1].getTime() == parameterArray[0].getTime()) :
                            resultStack.push(parameterArray[1] == parameterArray[0]);
                        break;
                    case '!=':
                        parametersAreDates ?
                            resultStack.push(parameterArray[1].getTime() != parameterArray[0].getTime()) :
                            resultStack.push(parameterArray[1] != parameterArray[0]);
                        break;
                    case '&&':
                        resultStack.push(parameterArray[1] && parameterArray[0]);
                        break;
                    case '||':
                        resultStack.push(parameterArray[1] || parameterArray[0]);
                        break;
                }
            } else if (token instanceof FunctionToken) {
                fnName = token.getName().toLowerCase();
                fn = this._functions[fnName];

                if (typeof fn !== 'function') {
                    Utils.Toaster.Show(i18next.t('RecorditemEditor.Formula.FunctionNotFoundError',
                        { function: i18next.t('RecorditemEditor.Formula.Functions.' + fnName, fnName) }), 2.0, Enums.Toaster.Icon.Warning);
                    return null;
                }

                parameterArray = [options, data];

                for (i = 0, arity = token.getArity(); i < arity; i++) {
                    if (resultStack.isEmpty()) {
                        throw 'Function ' + token.getName() + ' expected ' + arity + ' parameters.';
                    }

                    parameterArray.push(resultStack.pop());
                }

                /*
                 * TODO: Prüfen und entsprechende Checks in die jeweiligen Funktionen integrieren
                 *
                var usesDataAsParameter = Utils.InArray(['value', 'subsamplevalue', 'individualdataproperty', 'replacecharacter'], fnName, true);
                var parameterArrayLength = usesDataAsParameter ? parameterArray.length : parameterArray.length - 1;

                if (fn.length !== parameterArrayLength) {
                    Utils.Toaster.Show(i18next.t('RecorditemEditor.Formula.FunctionIsInvalid',
                        {
                            function: i18next.t('RecorditemEditor.Formula.Functions.' + fnName),
                            ParameterTitle: (data || {}).Title || '',
                            wrongArgumentsCount: parameterArrayLength,
                            correctArgumentsCount: fn.length
                        }), 4.0, Enums.Toaster.Icon.Warning);
                    return null;
                }
                */

                resultStack.push(fn.apply(this, parameterArray.reverse()));
            } else {
                resultStack.push(token.getValue());
            }

            idx++;
        }

        if (resultStack.isEmpty()) {
            throw 'Invalid expression';
        }

        result = resultStack.pop();

        if (!resultStack.isEmpty()) {
            throw 'Invalid expression';
        }

        return result;
    };

    /**
     * Durchsucht die angegebene Formel nach Aufrufen einer angegebenen Funktion.
     * @param {string} formula
     * @param {string} fnName
     * @returns {Array}
     */
    Formula.getFunctionCallTokens = function (formula, fnName) {
        if (typeof formula !== 'string' || !formula) {
            throw('The provided formula has to be string');
        }

        if (typeof fnName !== 'string' || !fnName) {
            throw('The provided fnName has to be string');
        }

        var tokens = tokenize(formula);

        if (!tokens.length) {
            throw('No valid tokens found in formula');
        }

        var fnDescriptors = [];
        var isIteratingFnTokens = false;
        var currentFnDescriptor;
        var openParanthesis = 0;
        var callstack = [];

        // Funktionen, die sich selbst aufrufen, wurden hier erst einmal nicht berücksichtigt.
        for (var tokenIndex = 0, tokenCount = tokens.length; tokenIndex < tokenCount; tokenIndex++) {
            var token = tokens[tokenIndex];

            if (token instanceof FunctionToken) {
                callstack.push(token._name);

                if (token._name === fnName) {
                    isIteratingFnTokens = true;
                    currentFnDescriptor = {
                        tokens: [],
                        args: [[]],
                        callstack: Utils.CloneArray(callstack)
                    };
                }
            }

            if (!isIteratingFnTokens) {
                continue;
            }

            currentFnDescriptor.tokens.push(token);

            var isLeftParenthesis = token instanceof FunctionLeftParenthesisToken;
            var isRightParenthesis = token instanceof FunctionRightParenthesisToken;
            var isCommaToken = token instanceof CommaToken;
            var isWhitespaceToken = token instanceof WhitespaceToken;

            if (isLeftParenthesis) {
                openParanthesis++;
            } else if (isRightParenthesis) {
                openParanthesis--;
                callstack.pop();

                if (openParanthesis === 0) {
                    fnDescriptors.push(currentFnDescriptor);
                    isIteratingFnTokens = false;
                    currentFnDescriptor = null;

                    continue;
                }
            } else if (isCommaToken) {
                currentFnDescriptor.args.push([]);
            }

            var currentArgTokens = currentFnDescriptor.args[currentFnDescriptor.args.length - 1];

            if (currentFnDescriptor.args.length === 1 &&
                currentArgTokens.length === 0 &&
                (token._name === fnName || isLeftParenthesis)) {
                continue;
            }

            if (currentArgTokens.length === 0 && (isWhitespaceToken || isCommaToken)) {
                continue;
            }

            currentArgTokens.push(token);
        }

        return fnDescriptors;
    };

    Formula.containsFormulaFunctions = function (str) {
        return /\w*\(.*\)/.test(str || '');
    };

    Formula.tokenize = tokenize;
    Formula.stringify = stringify;

    Formula.OperatorToken = OperatorToken;
    Formula.FunctionToken = FunctionToken;
    Formula.FunctionLeftParenthesisToken = FunctionLeftParenthesisToken;
    Formula.FunctionRightParenthesisToken = FunctionRightParenthesisToken;
    Formula.LeftParenthesisToken = LeftParenthesisToken;
    Formula.RightParenthesisToken = RightParenthesisToken;
    Formula.CommaToken = CommaToken;
    Formula.NumberToken = NumberToken;
    Formula.StringToken = StringToken;
    Formula.PartialStringToken = PartialStringToken;
    Formula.ReferenceToken = ReferenceToken;
    Formula.WhitespaceToken = WhitespaceToken;
    Formula.UnknownToken = UnknownToken;

    return (global.Formula = Formula);
})(window);