module Model.Errors {
    // Achtung!! instanceof auf CustomError kann nicht erfolgreich benutzt werden
    // da hier immer Error als Klasse ausgegeben wird.
    // z.B. error instanceof HttpError => false
    // Stattdessen kann der .name verglichen werden oder direkt auf Properties abgefragt werden

    export class ArgumentError extends Error {
        constructor(message: string) {
            super(message);
            this.name = 'ArgumentError';
        }

    }
    export class HttpError extends Error {
        public httpResponse: { error: string, responseText: string, status: number, type: string };

        constructor(message: string, _response) {
            if ((!message || message.length === 0) && _response) {
                message = _response.statusText;
            }

            super(message);

            this.httpResponse = _response;
            this.name = 'HttpError';
        }
    }

    export class ArgumentNullError extends Error {
        constructor(message: string) {
            super(message);
            this.name = 'ArgumentNullError';
        }
    }

    export class EmptyCollectionError extends Error {
        constructor(message: string) {
            super(message);
            this.name = 'EmptyCollectionError';
        }
    }

    export class SyncError extends Error {
        constructor(message: string) {
            super(message);
            this.name = 'SyncError';
        }
    }

    export class DatabaseError extends Error {
        constructor(message: string, error?: Error | Event | string | null) {
            super(message);
            this.name = 'DatabaseError';

            if (error != null) {
                let errorText = getErrorText(error);
                this.message = `${message} -> ${errorText}`;
            }

            // append stacktrace of previous Error
            if (error instanceof Error && typeof error['stack'] === 'string') {
                this.stack += '\n\n' + error.stack;
            }
        }
    }

    function getErrorText(error?: Error | Event | string) {
        if (error instanceof Error || typeof error == 'string') {
            return error;
        } else if (error instanceof Event && error.type != null) {
            if (error.target != null && error.target != null && error.target['error'] != null) {
                if (error.target['error'] instanceof Error) {
                    return `${error.type} [${error.target['error']}]`;
                } else {
                    return `${error.type} [${JSON.stringify(error.target['error'])}]`;
                }
            } else {
                return error.type;
            }
        }
        return JSON.stringify(error);
    }
}
