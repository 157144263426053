//imports-start
/// <reference path="../core/revision.ts"  />
/// <reference path="../../enums.ts"  />
//imports-end

module Model.Scheduling {
    export class RawScheduling extends Model.Core.Revision {
        ParentOID?: string;
        Title: string;
        Description: string;
        Position: number;
        StateOID: string;
        PriorityOID: string;
        Color: string;
        Classifications: Array<string>;
        Users: Array<string>;
        Teams: Array<string>;
        Contacts: Array<string>;
        ContactGroups: Array<string>;
        Elements: Array<Element>;
        UseFormTitleAndDescription: boolean;
        DeadlineOffsetManuallyExecuted?: number;

        /* not used
        RecipientUsers: Array<string>;
        RecipientTeams: Array<string>;
        PrintOptions: any;
        HasActiveAssignments: boolean;
        //*/
    }
}