module Model.SelectionWindow {
    export class Filter implements Model.Filter.IResettableFilter {
        Keywords: Array<string>;
        Classifications: Array<string>;

        constructor(obj?: any) {
            this.Reset();

            if (!Utils.IsSet(obj)) {
                return;
            }

            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    this[key] = obj[key];
                }
            }
        }

        public Reset() {
            this.Keywords = [];
            this.Classifications = [];
        }

        public AreFiltersActive() {
            if (Utils.IsSet(this.Keywords) && this.Keywords.length > 0) {
                return true;
            }

            if (Utils.IsSet(this.Classifications) && this.Classifications.length > 0) {
                return true;
            }

            return false;
        }

        static FromMenuItemConfiguration(config: Model.Menu.IMenuItemConfig): Filter {
            const filter = new Filter();

            if (!Utils.IsSet(config) || !Utils.IsSet(config.Configuration)) {
                return filter;
            }

            if (Utils.IsSet(config.Configuration.Keywords)) {
                filter.Keywords = config.Configuration.Keywords;
            }

            if (Utils.IsSet(config.Configuration.Classifications)) {
                filter.Classifications = config.Configuration.Classifications;
            }

            return filter;
        }
    }
}