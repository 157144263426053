//imports-start
/// <reference path="../../enums.ts"  />
//imports-end

module Model.Elements {
    export class TreeMetadata {
        OID: string;
        ParentOID: string;
        Title: string;
        Level: number;
        IssueCounters: { [key: number]: number };
        SchedulingCount: number;

        constructor(oid: string, parentOID: string, title: string, level: number, issueCounters?: { [key: number]: number }, schedulingCount?: number) {
            this.OID = oid;
            this.ParentOID = parentOID;
            this.Title = title;
            this.Level = level;
            this.IssueCounters = issueCounters || null;
            this.SchedulingCount = schedulingCount || 0;
        }
    }
}