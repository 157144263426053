//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../app/app.session.ts"  />
/// <reference path="./files.ts" />
/// <reference path="../model/individual-data/schema.ts" />
/// <reference path="../model/individual-data/schema.ts" />
/// <reference path="../enums.ts" />
//imports-end
module DAL.IndividualData {
    let _data: Dictionary<Array<any>>;
    let _dataByID: Dictionary<Dictionary<any>>;

    function getClassByAttr(attr: string): string {
        switch (attr) {
            case 'width-xs':
                return 'col-xs-';
            case 'width-sm':
                return 'col-sm-';
            case 'width-md':
                return 'col-md-';
            case 'width-lg':
                return 'col-lg-';
        }
    }

    function getDisplayValue(entity: any, attr: string, schema: Model.IndividualData.Schema): string {
        if (!entity || !attr || !schema) {
            return '-/-';
        }

        const value = entity[attr];
        const schemaProperty: Model.IndividualData.SchemaProperty = Utils.Where(
            schema.Properties,
            'Name',
            '===',
            attr
        );

        if (!schemaProperty) {
            return '-/-';
        }

        if (schemaProperty.Type !== Enums.IndividualDataType.Image) {
            let text = Utils.EscapeHTMLEntities(value || '-/-');

            if (schemaProperty.Type === Enums.IndividualDataType.Number &&
                schemaProperty.Decimals &&
                !!text) {
                text = parseFloat(text).toFixed(schemaProperty.Decimals);
            }

            if (!!schemaProperty.Suffix) {
                text += ' ' + schemaProperty.Suffix;
            }

            return text;
        }

        if (!value) {
            return '';
        }

        const image = DAL.Files.GetByOID(value);

        if (!image) {
            return '';
        }

        const fileSource = Session.IsSmartDeviceApplication && image.IsAvailableOffline ?
                           Utils.FixIOSFilepath(Utils.GetResourcesPath() + image.Filename) :
                           `${Session.BaseURI}images/s/${image.Filename}`;

        return Templates.Selections.IndividualData.Image({
            FileSource: fileSource,
            File: image
        });
    }

    function getStringByPattern(entity: any, pattern: string, schema: Model.IndividualData.Schema): string {
        if (!entity || !pattern) {
            return;
        }

        let match, attrMatch;
        let str = pattern;

        const colRegex = /<col\b[^>]*>([\s\S]*?)<\/col>/gm;
        const attrRegex = /(\S+)=[\'"]?((?:(?!\/>|>|"|\'|\s).)+)[\'"]/gm;

        while ((match = colRegex.exec(str))) {
            let colTmp = match[0];

            const classes = [];

            while ((attrMatch = attrRegex.exec(match[0]))) {
                const attr = attrMatch[1];
                const value = attrMatch[2];

                let cls = getClassByAttr(attr);

                if (!cls) {
                    continue;
                }

                cls += value;

                classes.push(cls);

                colTmp = colTmp.replace(attrMatch[0], '');
            }

            if (classes.length) {
                colTmp = colTmp.replace('<col', '<col class="' + classes.join(' ') + '"');
            }

            colTmp = colTmp.replace('<col', '<div').replace('</col>', '</div>');

            str = str.replace(match[0], colTmp);
        }

        while ((match = /\{[\w\s-]+\}/g.exec(str))) {
            const tmp = match[0];
            const displayValue = getDisplayValue(entity, tmp.replace(/[\{\}]/g, ''), schema);

            str = str.replace(tmp, displayValue);
        }

        str = str.replace(/\r?\n|\r/ig, '<br>');

        return str;
    }

    function prepareEntity(entity: any, schema: Model.IndividualData.Schema): any {
        entity.Title = getStringByPattern(entity, schema.Display, schema);

        let selectionPattern = schema.SelectionPattern;

        if (Session.LastKnownAPIVersion <= 2) {
            selectionPattern = schema.Print;
        }

        entity.SelectionTitle = getStringByPattern(entity, selectionPattern, schema);

        return entity;
    }

    export function Store(syncedData: Array<any>): void {
        if (!_data || !_dataByID) {
            _data = _data || {};
            _dataByID = _dataByID || {};
        }

        if (!(syncedData || []).length) {
            return;
        }

        for (let eCnt = 0, eLen = syncedData.length; eCnt < eLen; eCnt++) {
            let entity = syncedData[eCnt];
            const schema = DAL.Schemas.GetByType(entity.Type);

            if (!schema) {
                continue;
            }

            entity = prepareEntity(entity, schema);

            if (!_dataByID.hasOwnProperty(schema.Type)) {
                _dataByID[schema.Type] = {};
            }

            const entityDict = _dataByID[schema.Type];

            if (entity.IsDeleted) {
                delete entityDict[entity.ID];
            } else {
                entityDict[entity.ID] = entity;
            }
        }

        for (let type in _dataByID) {
            _data[type] = $.map(_dataByID[type], entity => entity);
        }
    }

    export function Exists(type: string): boolean {
        return _data && _data.hasOwnProperty(type);
    }

    export function GetByType(type: string): any {
        if (!_data || !_data.hasOwnProperty(type)) {
            return;
        }

        return _data[type];
    }

    export function GetIndividualData(type: string, id: string): any {
        if (!_dataByID || !_dataByID.hasOwnProperty(type)) {
            return null;
        }

        return _dataByID[type][id];
    }

    export function GetAll(): any {
        return _data ?
            $.map(_data, (entity) => entity) :
            null;
    }

    export function GetEntityTitle(schemaId: string, entityId: string): any {
        if (!_data || !_data.hasOwnProperty(schemaId)) {
            return;
        }
        // TODO use entity dictionary instead of array
        for (let eCnt = 0, eLen = _data[schemaId].length; eCnt < eLen; eCnt++) {
            if (_data[schemaId][eCnt].ID == entityId) {/* jslint ignore:line */
                return _data[schemaId][eCnt].Title;
            }
        }
    }

    export function Clear(): void {
        _data = {};
    }
}