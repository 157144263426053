module Model.Calendar {
    export class CounterResponseCounter {
        AppointmentType: number;
        Counter: number;
    }

    export class CounterResponse {
        CalendarDate: number;
        Counters: Array<CounterResponseCounter>;
    }
}