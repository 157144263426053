///<reference path="response-base.ts"/>
///<reference path="../workflow-base.ts"/>

module New.Checkpoints.Workflow.Response {
    export class PhotoWorkflow extends ResponseBase {
        readonly File: any;

        constructor(workflow: WorkflowBase, file: any) {
            super(workflow);

            if (!file) {
                throw new Error('no file provided');
            }

            this.File = file;
        }
    }
}