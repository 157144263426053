module Model.SelectionWindow {
    export class HierarchyFilterOptions {
        public OnClick: (evt: Event) => void;
        public IsActive: boolean;
        public Icon: string;
        public HoverTitle?: string;
    }

    export class CustomActionButtonOptions {
        public OnClick: (evt: Event) => void;
        public Caption: string;
    }

    export class Options {
        public DefaultItemIdentifier: string;
        public Location: Model.Elements.Element;
        public ShowLocationSelection: boolean;
        public ShowTreeFilter: boolean;
        public TabControlCSSClass: string;
        public OnAfterIssueCreated: (issue: Model.Issues.Issue) => void;
        public HierarchyFilter: HierarchyFilterOptions;
        public ShowResetButton: boolean;
        public CustomActionButton: CustomActionButtonOptions;
        public Filter: Model.SelectionWindow.Filter;
        public ZIndex: number;
        public ExitIfNoItemIsAvailable: boolean;

        public ShowTabControl: boolean;
        public ShowDetails: boolean;

        public SelectedItems?: string[];
        public EnableMultiSelection?: boolean;

        public OnOnlySingleApplicableItemExists: (item: any) => void;
        public OnAfterItemsSelected: (selectedIdentifier: Array<string>) => void;

        public Title: string;
        public TreeTabTitle: string;
        public TreeSearchFieldPlaceholder: string;
        public NoItemAvailableMessageHeader: string;
        public NoItemAvailableMessageBody: string;
    }
}
