//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../app/app.session.ts"/>
/// <reference path="../../enums.ts" />
//imports-end

module New.Analytics {
    export function ToggleGoogleAnalytics(enable: boolean) {
        if (enable) {
            Utils.Analytics.Init();
        }

        if (Session.IsSmartDeviceApplication) {
            setFirebaseValues(enable);
        } else {
            setWebTracker(enable);
        }
    }

    function setFirebaseValues(dateCollection: boolean): void {
        FirebasePlugin.setAnalyticsCollectionEnabled(dateCollection);
        FirebasePlugin.setPerformanceCollectionEnabled(dateCollection);
        FirebasePlugin.setCrashlyticsCollectionEnabled(dateCollection);
    }

    function setWebTracker(enable: boolean): void {
        if (window.gtag) {
            window.gtag('config', 'GA_MEASUREMENT_ID', { 'send_page_view': enable });
        }
    }
}
