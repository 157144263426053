//imports-start
/// <reference path="../model/tree-cache/tree-cache.ts" />
/// <reference path="../model/menu/imenu-item-config.ts" />
//imports-end


module DAL.TreeCache {

    //
    // Cache für alle Aufgaben
    //
    export const Global = new Model.TreeCache.TreeCache("Global");

    //
    // Sub-Filter für Aufgaben Menüpunkt
    //
    const taskFilter = <Model.Issues.Filter>{
        Types: [
            Enums.IssueType.Task,
            Enums.IssueType.Scheduling,
            Enums.IssueType.Form,
            Enums.IssueType.Inspection,
            Enums.IssueType.Survey,
            Enums.IssueType.Investigation
        ]
    };
    export const Tasks = Global.createSubInstance(taskFilter, "TaskReport");

    //
    // Sub-Filter für Aufgaben Menüpunkt
    //
    export const SubTaskFilter = <Model.Issues.Filter>{
        Types: [
            Enums.IssueType.Task,
            Enums.IssueType.Scheduling,
            Enums.IssueType.Form,
            Enums.IssueType.Inspection,
            Enums.IssueType.Survey,
            Enums.IssueType.Investigation
        ]
    };
    export let SubTasks = Tasks.createSubInstance(SubTaskFilter, "SubTaskReport");

    //
    // Sub-Filter für Notizen Menüpunkt
    //
    const noteFilter = <Model.Issues.Filter>{
        Types: [Enums.IssueType.Note]
    };
    export const Notes = Global.createSubInstance(noteFilter, "NoteReport");

    //
    // Sub-Filter für Störmeldungen Menüpunkt
    //
    const disturbanceFilter = <Model.Issues.Filter>{
        Types: [Enums.IssueType.Disturbance]
    };
    export const Disturbances = Global.createSubInstance(disturbanceFilter, "DisturbanceReport");

    //
    // Cache für alle eigene Menüpunkte
    //
    export const Custom: { [id: string]: Model.TreeCache.IFilteredTreeCache } = {};

    export function GenerateTreeCacheFromMenuItems(config?: Array<Model.Menu.IMenuItemConfig>): void {
        if (!config) {
            return;
        }

        for (let i = 0; i < config.length; i++) {
            const item = config[i];

            if (!item || item.ActionType != Enums.Menu.Action.Issues || item.IsSystemEntity) {
                continue;
            }

            // available since API 17
            const isAvailableInRecordingApp = typeof item.IsAvailableInRecordingApp === 'boolean' ? item.IsAvailableInRecordingApp : true;
            if (!isAvailableInRecordingApp) {
                continue;
            }

            // remove old cache
            const cache = Custom[item.ID];
            if (cache) {
                cache.destroy();
            }

            // create filters
            let tmpFilter: Model.Issues.IFilter = Menu.GetFilter(item);

            // create
            Custom[item.ID] = Global.createSubInstance(tmpFilter, item.ID);
        }
    }
}
