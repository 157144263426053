//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../templates.d.ts"  />
//// <reference path="../utils/utils.spinner.ts"  />
//// <reference path="../utils/utils.message.ts"  />
/// <reference path="../utils/utils.date-time-picker.ts"  />
/// <reference path="../utils/utils.set.ts"  />
//// <reference path="../model/model.tree.ts"  />
/// <reference path="./app.bluetooth-configuration.ts"  />
/// <reference path="./app.issue-view.ts"  />
/// <reference path="../utils/filter-window/utils.checkpoint-filter-window.ts" />
/// <reference path="../utils/utils.custom-data-picker.ts" />
/// <reference path="../utils/utils.element-information.ts" />
/// <reference path="../utils/utils.file-helper.ts" />
/// <reference path="../utils/utils.scroll-table.ts" />
/// <reference path="../dal/cache.ts"  />
/// <reference path="../utils/utils.recorditem-editor.individual.ts"  />
/// <reference path="../utils/utils.recorditem-editor.ts"  />
/// <reference path="../utils/utils.action-window.ts"  />
/// <reference path="../utils/utils.issue-viewer.ts"  />
/// <reference path="../utils/utils.evaluation.ts"  />
/// <reference path="../utils/utils.scan.ts"  />
/// <reference path="../model/parameters/filter.ts" />
/// <reference path="../utils/parameter-selection/utils.temperature-parameter-selection-window.ts"/>
/// <reference path="../utils/parameter-selection/utils.scancode-parameter-selection-window.ts"/>
/// <reference path="../new/checkpoints/workflow/workflow-stack.ts"/>
/// <reference path="../new/checkpoints/workflow/workflow-factory.ts"/>
/// <reference path="../new/checkpoints/workflow/workflow-factory.ts"/>
/// <reference path="../new/checkpoints/workflow/response/comment-workflow.ts"/>
/// <reference path="../new/checkpoints/workflow/response/task-workflow.ts"/>
/// <reference path="../new/checkpoints/workflow/response/form-workflow.ts"/>
/// <reference path="../new/checkpoints/workflow/response/photo-workflow.ts"/>
/// <reference path="../new/checkpoints/workflow/workflow-settings.ts"/>
/// <reference path="../new/pdf/PdfActionPlan.ts"  />
/// <reference path="../new/pdf/TSLActionPlan.ts"  />
/// <reference path="../new/pdf/HygieneInspectionActionPlan.ts"  />
//imports-end

module ParameterList {
    type OnAfterResubTreeBuildFunc = (rootLocation: Model.Elements.Element, elements?: Dictionary<Model.Elements.Element>) => void;

    const CODE_SCANNER_CALLBACK_ID: string = 'ParameterList_Scan';

    let $content;
    let _subsampleGroupTypes: Dictionary<boolean> = {};
    _subsampleGroupTypes[Enums.ElementType.FormRow] = true;

    let resubmissionTree: Model.Elements.ResubmissionElementTree;
    let _isRebuildingTree: boolean;
    let _eligableGroupTypes: Array<Enums.ElementType>;
    let _arguments: IArguments;
    let _requirementsCache: Dictionary<Dictionary<boolean>> = {};
    let _resubTreePrepared: boolean = false;
    let _lastResubTreeLocationOID: string;
    let _tabularSubsampleToggleState: Dictionary<boolean> = {};
    let _cachedIndividualData: Dictionary<Array<any>>;
    let _forceAdvancedEditor: Dictionary<boolean> = {};
    let _getFilterFromCache: boolean = false;
    let _customMenuID: string;
    let _temperatureParameterSelectionWindow: Utils.ParameterSelection.BaseParameterSelectionWindow;
    let _scancodeParameterSelectionWindow: Utils.ParameterSelection.BaseParameterSelectionWindow;
    let _locationCodeParameterSelectionWindow: Utils.ParameterSelection.BaseParameterSelectionWindow;
    let _openEditorAfterRoomChange: boolean = false;

    export let PreviousRecorditemsOfElements = {};
    export let CorrectiveActionsDictionary = {};
    export let CorrectiveActionsDictByOID = {};

    let additionalCorrectiveIssues: Dictionary<Model.Issues.IssueType> = null;

    let filters: Model.Parameters.Filter = new Model.Parameters.Filter();

    export function getHistoralRecorditems(groups?: Model.Elements.Element[]): Deferred {
        const paramGroups = groups || GetFormulaGroups();

        if (!paramGroups) {
            return $.Deferred().reject();
        }

        const results: Array<Deferred> = getRecorditemsDeferreds(paramGroups);

        return $.when.apply($, results)
            .then((...recorditems: Model.Recorditem[][]) => {
                const recorditemsWithHistoricalValue = [];

                for (const recItems of recorditems) {
                    if (!recItems || !recItems.length) {
                        continue;
                    }

                    // sortieren: neuere zum Schluss
                    if (recItems.length > 1) {
                        recItems.sort(function(a: Model.Recorditem, b: Model.Recorditem) {
                            return (<Date>a.ModificationTimestamp).getTime() - (<Date>b.ModificationTimestamp).getTime();
                        });
                    }

                    // aktuellste Erfassung (letzter Wert im Array)
                    const itemsLength = recItems.length;
                    const currentRecItem = recItems[itemsLength - 1];
                    recorditemsWithHistoricalValue.push(currentRecItem);

                    // "letzten Wert" ermitteln
                    if (itemsLength > 1) {
                        const previousRecItem = recItems[itemsLength - 2];
                        const elementOID = previousRecItem.ElementOID;
                        ParameterList.PreviousRecorditemsOfElements[elementOID] = previousRecItem;
                    }
                }

                return recorditemsWithHistoricalValue;
            });
    }

    function getRecorditemsDeferreds(groups?: Model.Elements.Element[]): Array<Deferred> {
        const paramGroups = groups || GetFormulaGroups();
        const results: Array<Deferred> = [];

        if (!paramGroups || !paramGroups.length) {
            return results;
        }

        for (const group of paramGroups) {
            const parameters = group.Parameters;

            if (!parameters || !parameters.length) {
                continue;
            }

            for (const param of parameters) {
                if (!param) {
                    continue;
                }

                if (Session.IsSmartDeviceApplication) {
                    if (Utils.IsSet(ParameterList.PreviousRecorditemsOfElements[param.OID])
                        && Utils.IsSet(param.LastRecorditem)) {
                        results.push(getRecorditemsDeferredFromDictionary(param));
                        continue;
                    }
                }

                const deferred = LoadLastRecorditemsFromDatabase(param);

                results.push(deferred);
            }
        }

        return results;
    }

    function getRecorditemsDeferredFromDictionary(param: Model.Elements.Element): Deferred {
        const deferred = $.Deferred();

        const recorditemArray: Array<Model.Recorditem> =
            [
                Utils.PrepareRecorditem(ParameterList.PreviousRecorditemsOfElements[param.OID]),
                Utils.PrepareRecorditem(param.LastRecorditem)
            ];

        return deferred.resolve(recorditemArray).promise();
    }

    function LoadLastRecorditemsFromDatabase(param: Model.Elements.Element): Deferred {
        if (Session.IsSmartDeviceApplication) {
            return window.Database.GetManyByKeys(Enums.DatabaseStorage.Recorditems, [param.OID], 'IDX_ElementOID')
                .then(function(recorditems: Model.Recorditem[]) {
                    if (!(recorditems || []).length) {
                        return null;
                    }

                    recorditems.forEach(Utils.PrepareRecorditem);

                    recorditems.sort(function(a: Model.Recorditem, b: Model.Recorditem) {
                        return (<Date>b.ModificationTimestamp).getTime() - (<Date>a.ModificationTimestamp).getTime();
                    });

                    // 2 aktuelle Werte => vorletzten Wert behalten für "LetzterWert" Funktion (Formeln)
                    return recorditems.slice(0, 2);
                });
        } else {
            return Utils.Http.Get(`recorditems?elementoid=${param.OID}&count=2`)
                .then(function(recorditems: Model.Recorditem[]) {
                    (recorditems || []).forEach(Utils.PrepareRecorditem);

                    return recorditems;
                }, function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        }
    }

    export function OnAfterGotMeasuredTemperature(temperature: number, preselectedElement?: Model.Elements.Element): void {
        if (typeof temperature === 'undefined' || temperature === null) {
            return;
        }

        if (temperature < -273.15) {
            return;
        }

        if (!Session.CurrentLocation) {
            return;
        }

        if (View.CurrentView === Enums.View.Inspection &&
            Session.Mode != Enums.Mode.CoveringPage) {
            return;
        }

        if (Utils.CustomDataPicker.IsVisible() ||
            Utils.RecorditemEditor.IsVisible() ||
            Utils.IssueViewer.IsVisible() ||
            Utils.InputWindow.IsVisible() ||
            Utils.ActionWindow.IsVisible() ||
            Utils.ElementInformation.IsVisible() ||
            Utils.ParameterHistory.IsVisible() ||
            Utils.ElementPickerPopup.IsAnyVisible() ||
            Utils.DateTimePicker.IsVisible() ||
            Utils.RecorditemEditor.Individual.IsVisible() ||
            Utils.ParameterSelection.BaseParameterSelectionWindow.IsVisible()) {
            return;
        }

        if (preselectedElement) {
            saveRecordedValueQuietly(preselectedElement, temperature);
            return;
        }

        const selectableGroups = [
            Enums.ElementType.SingletonFormRow,
            Enums.ElementType.FormHeader,
            Enums.ElementType.FormFooter,
            Enums.ElementType.FormRow,
            Enums.ElementType.Parametergroup
        ];

        if (_temperatureParameterSelectionWindow) {
            _temperatureParameterSelectionWindow.Close();
        }

        const isFormEditViewOpen = View.CurrentView !== Enums.View.Form &&
            View.CurrentView !== Enums.View.FormBatchEdit &&
            View.CurrentView !== Enums.View.Inspection;

        const paramGroups = isFormEditViewOpen ? Session.CurrentLocation.Parametergroups : resubmissionTree.Form.Parametergroups;

        _temperatureParameterSelectionWindow = new Utils.ParameterSelection.TemperatureParameterSelectionWindow(<Utils.ParameterSelection.Options>{
            infoText: i18next.t('ParameterList.MeasuredTemperature', { temperature: temperature.toFixed(2) }),
            title: i18next.t('ParameterList.SelectParameter'),
            groups: paramGroups,
            groupFilterExpression: (group) => groupIsVisible(group, selectableGroups),
            paramFilterExpression: (param => param.Type === Enums.ElementType.Number && (!param.Formula || param.AllowOverrideFormulaValue) && !param.DisableInAppEditing),
            itemClickHandler: function(parameter: Model.Elements.Element) {
                saveRecordedValueQuietly(parameter, temperature);
            },
            onClose: function() {
                _temperatureParameterSelectionWindow = null;
            }
        }).Show();
    }

    export function OnAfterGotWeight(weight: number, unit: string, data: string): void {
        if (typeof weight === 'undefined' || weight === null) {
            return;
        }

        if (!Session.CurrentLocation) {
            return;
        }

        if (View.CurrentView === Enums.View.Inspection &&
            Session.Mode != Enums.Mode.CoveringPage) {
            return;
        }

        if (Utils.CustomDataPicker.IsVisible() ||
            Utils.RecorditemEditor.IsVisible() ||
            Utils.IssueViewer.IsVisible() ||
            Utils.ActionWindow.IsVisible() ||
            Utils.ElementInformation.IsVisible() ||
            Utils.ParameterHistory.IsVisible() ||
            Utils.ElementPickerPopup.IsAnyVisible() ||
            Utils.DateTimePicker.IsVisible() ||
            Utils.RecorditemEditor.Individual.IsVisible() ||
            Utils.ParameterSelection.BaseParameterSelectionWindow.IsVisible()) {
            return;
        }

        const selectableGroups = [
            Enums.ElementType.SingletonFormRow,
            Enums.ElementType.FormHeader,
            Enums.ElementType.FormFooter,
            Enums.ElementType.FormRow,
            Enums.ElementType.Parametergroup
        ];

        if (_temperatureParameterSelectionWindow) {
            _temperatureParameterSelectionWindow.Close();
        }

        const isFormRecording = View.CurrentView !== Enums.View.Form &&
            View.CurrentView !== Enums.View.FormBatchEdit &&
            View.CurrentView !== Enums.View.Inspection;

        const paramGroups = isFormRecording ? Session.CurrentLocation.Parametergroups : resubmissionTree.Form.Parametergroups;

        _temperatureParameterSelectionWindow = new Utils.ParameterSelection.WeightParameterSelectionWindow(<Utils.ParameterSelection.Options>{
            infoText: i18next.t('ParameterList.ReceivedWeight', { weight: weight.toFixed(3), unit: unit }),
            title: i18next.t('ParameterList.SelectParameter'),
            groups: paramGroups,
            groupFilterExpression: (group) => groupIsVisible(group, selectableGroups),
            paramFilterExpression: (param => param.Type === Enums.ElementType.Number && (!param.Formula || param.AllowOverrideFormulaValue) && !param.DisableInAppEditing),
            itemClickHandler: function(parameter: Model.Elements.Element) {
                // copmare unit, show hint
                if (parameter && parameter.UnitOID) {
                    const syUnit = DAL.Properties.GetByOID(parameter.UnitOID)
                    if (syUnit && syUnit.Title.toLowerCase() !== unit.toLowerCase()) {
                        Utils.Toaster.Show(i18next.t('Scale.DifferentUnits', {
                            unit_a: unit,
                            unit_b: syUnit.Title
                        }), 2, Enums.Toaster.Icon.Warning);
                    }
                }

                saveRecordedValueQuietly(parameter, weight);
            },
            onClose: function() {
                _temperatureParameterSelectionWindow = null;
            }
        }).Show();
    }

    function saveRecordedValueQuietly(parameter: Model.Elements.Element, value: any): void {
        if (!parameter) {
            return;
        }

        if (IssueView.IsRecordingLocked(parameter)) {
            return;
        }

        const location = DAL.Elements.GetLocation(parameter) || Session.CurrentLocation;

        if (parameter.IsAdhoc &&
            !Utils.UserHasRight(Session.User.OID, Enums.Rights.AdhocRecording, true, location)) {
            Utils.Toaster.Show(i18next.t('RightError.AdHocAtLocation', { locationTitle: location.Title }), .2);
            return;
        }

        const recorditem = getRecorditemForParameter(parameter);
        const issue = IssueView.GetCurrentIssue();

        Utils.RecorditemEditor.SaveSelectedValue({
            Element: parameter,
            PreviousRecorditem: recorditem,
            Row: parameter.Row,
            Issue: issue,
            Value: value,
            OnAfterRecorditemSaved: onAfterRecorditemSaved,
            OnAfterWindowDestroyed: CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack
        });

        if (Session.Mode === Enums.Mode.Parameters) {
            ScrollTo(parameter.OID, parameter.Row);
        }

        if (Utils.CustomDataPicker.IsVisible()) {
            Utils.CustomDataPicker.Destroy();
        }

        Utils.Toaster.Show(i18next.t('ParameterList.ValueAssignedToCP'), 1, Enums.Toaster.Icon.Success);
    }

    export function OnAfterGotLocationCode(location: Model.Elements.Element, elligableCheckpoints: string[]): void {
        if (location == null) {
            return;
        }

        if (Utils.CustomDataPicker.IsVisible() ||
            Utils.RecorditemEditor.IsVisible() ||
            Utils.IssueViewer.IsVisible() ||
            Utils.InputWindow.IsVisible() ||
            Utils.ActionWindow.IsVisible() ||
            Utils.ElementInformation.IsVisible() ||
            Utils.ParameterHistory.IsVisible() ||
            Utils.ElementPickerPopup.IsAnyVisible() ||
            Utils.DateTimePicker.IsVisible() ||
            Utils.RecorditemEditor.Individual.IsVisible() ||
            Utils.ParameterSelection.BaseParameterSelectionWindow.IsVisible()) {
            return;
        }

        const selectableGroups = [
            Enums.ElementType.SingletonFormRow,
            Enums.ElementType.FormHeader,
            Enums.ElementType.FormFooter,
            Enums.ElementType.FormRow,
            Enums.ElementType.Parametergroup
        ];

        if (_locationCodeParameterSelectionWindow) {
            _locationCodeParameterSelectionWindow.Close();
        }

        _locationCodeParameterSelectionWindow = new Utils.ParameterSelection.LocationCodeParameterSelectionWindow(<Utils.ParameterSelection.Options>{
            infoText: i18next.t('ParameterList.ScannedLocationTag', { location: location.Title }),
            title: i18next.t('ParameterList.SelectParameter'),
            groups: View.CurrentView === Enums.View.Form || View.CurrentView === Enums.View.FormBatchEdit || View.CurrentView === Enums.View.Inspection ?
                resubmissionTree.Form.Parametergroups :
                Session.CurrentLocation.Parametergroups,
            groupFilterExpression: (group) => groupIsVisible(group, selectableGroups),
            paramFilterExpression: (param => {
                return param.Type === Enums.ElementType.LocationCode &&
                    elligableCheckpoints.indexOf(param.OID) > -1;
            }),
            itemClickHandler: function(parameter: Model.Elements.Element) {
                saveRecordedValueQuietly(parameter, location.OID);
            },
            onClose: function() {
                _locationCodeParameterSelectionWindow = null;
            }
        }).Show();
    }

    function onAfterGotScanCode(type: string, scannedCode: string, scannerMode: Enums.ScannerMode): void {
        if (Utils.CustomDataPicker.IsVisible() ||
            Utils.RecorditemEditor.IsVisible() ||
            Utils.IssueViewer.IsVisible() ||
            Utils.InputWindow.IsVisible() ||
            Utils.ActionWindow.IsVisible() ||
            Utils.ElementInformation.IsVisible() ||
            Utils.ParameterHistory.IsVisible() ||
            Utils.ElementPickerPopup.IsAnyVisible() ||
            Utils.DateTimePicker.IsVisible() ||
            Utils.RecorditemEditor.Individual.IsVisible() ||
            Utils.ParameterSelection.BaseParameterSelectionWindow.IsVisible()) {
            return;
        }

        if (type == null || scannedCode == null) {
            return;
        }

        if (scannerMode === Enums.ScannerMode.Search) {
            onParameterSearch(scannedCode);
            return;
        }

        if (!resubmissionTree) {
            return;
        }

        const groups = View.CurrentView === Enums.View.Form || View.CurrentView === Enums.View.FormBatchEdit || View.CurrentView === Enums.View.Inspection ?
            resubmissionTree.Form.Parametergroups :
            resubmissionTree.GetElementByOID(Session.CurrentLocation.OID).Parametergroups;

        _cachedIndividualData = {};

        (<any>groups || [])
            .filter(groupIsVisible)
            .forEach(function(group: Model.Elements.Element) {
                (group.Parameters || []).forEach(function(param: Model.Elements.Element) {
                    switch (param.Type) {
                        case Enums.ElementType.IndividualData:
                            const type = Utils.GetIndividualDataTypeOfElement(param);
                            let data = _cachedIndividualData[type];

                            if (!data) {
                                data = Utils.RecorditemEditor.Individual.GetIndividualData(param, scannedCode);
                                _cachedIndividualData[type] = data;
                            }
                            break;
                    }
                });
            });

        const selectableGroups = [
            Enums.ElementType.SingletonFormRow,
            Enums.ElementType.FormHeader,
            Enums.ElementType.FormFooter,
            Enums.ElementType.FormRow,
            Enums.ElementType.Parametergroup
        ];

        if (_scancodeParameterSelectionWindow) {
            _scancodeParameterSelectionWindow.Close();
        }

        _scancodeParameterSelectionWindow = new Utils.ParameterSelection.ScancodeParameterSelectionWindow(<Utils.ParameterSelection.Options>{
            infoText: i18next.t('CodeScanner.AssignValue'),
            title: i18next.t('ParameterList.SelectParameter'),
            groups: groups,
            groupFilterExpression: (group) => groupIsVisible(group, selectableGroups),
            paramFilterExpression: (param => param.Type === Enums.ElementType.Number),
            itemClickHandler: function(parameter: Model.Elements.Element) {
                onAfterSelectedParameterForScanCode(parameter, scannedCode);
            },
            onClose: function() {
                _scancodeParameterSelectionWindow = null;
            }
        }, scannedCode).Show();
    }

    function onAfterSelectedParameterForScanCode(param: Model.Elements.Element, code: string): void {
        if (!param) {
            return;
        }

        if (IssueView.IsRecordingLocked(param)) {
            return;
        }

        const recorditem = getRecorditemForParameter(param);
        const issue = IssueView.GetCurrentIssue();
        const deferred = $.Deferred();

        if (param.Type === Enums.ElementType.IndividualData) {
            const type = Utils.GetIndividualDataTypeOfElement(param);

            if (type) {
                if (_cachedIndividualData[type].length === 1) {
                    const item = _cachedIndividualData[type][0];
                    deferred.resolve({ [type]: [item.ID] });
                } else {
                    Utils.RecorditemEditor.Individual.Show(param, (result) => {
                        deferred.resolve({ [type]: result });
                    }, null, code)
                }
            } else {
                deferred.resolve(null);
            }
        } else {
            deferred.resolve(code);
        }

        deferred.then((value) => {
            if (value == null) {
                value = null;
            }

            Utils.RecorditemEditor.SaveSelectedValue({
                Element: param,
                PreviousRecorditem: recorditem,
                Row: param.Row,
                Issue: issue,
                Value: value,
                OnAfterRecorditemSaved: onAfterRecorditemSaved,
                OnAfterWindowDestroyed: CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack
            });

            ScrollTo(param.OID, param.Row);
        });
    }

    function onAfterRecorditemWindowDestroyed(recorditem: Model.Recorditem, predefinedCorrectiveActions, skipDefaultBehaviour?: boolean): Deferred {
        if (!recorditem || recorditem.IsDeleted) {
            return $.Deferred().resolve().promise();
        }

        if (!(recorditem instanceof Model.Recorditem)) {
            recorditem = new Model.Recorditem(recorditem);
        }

        BindBluetoothEvent();
        BindCodeScannerEvent();
        BindScaleEvent();

        const automaticWorkflowStack = new New.Checkpoints.Workflow.WorkflowStack();
        const elementIdentifier = View.CurrentView === Enums.View.Main ? recorditem.ElementOID : recorditem.ElementRevisionOID;
        const checkpoint = GetElement(elementIdentifier, recorditem.Row);

        if (!!recorditem.CategoryOID && checkpoint && (checkpoint.Actions || []).length) {
            checkpoint.Actions.forEach(action => {
                if (action.CategoryOID !== recorditem.CategoryOID || !action.TriggerAutomatically) {
                    return;
                }

                const parentIssue = View.CurrentView !== Enums.View.Main ? IssueView.GetCurrentIssue() : null;
                let locationIdentifier = View.CurrentView !== Enums.View.Form && View.CurrentView !== Enums.View.FormBatchEdit ?
                    Session.CurrentLocation.OID :
                    (parentIssue || <Model.Issues.Issue>{}).AssignedElementOID;

                if (!locationIdentifier) {
                    locationIdentifier = DAL.Elements.Root.OID;
                }

                const workflowSettings: New.Checkpoints.Workflow.WorkflowSettingsBase = {
                    Recorditem: recorditem,
                    Checkpoint: checkpoint,
                    LocationIdentifier: locationIdentifier
                };

                if (action.Type === Enums.CheckpointWorkflowType.CorrectiveAction ||
                    action.Type === Enums.CheckpointWorkflowType.Form ||
                    action.Type === Enums.CheckpointWorkflowType.ModifyParentIssue) {
                    (<New.Checkpoints.Workflow.IssueWorkflowSettings>workflowSettings).CreateTemporaryIssue = false;

                    if (parentIssue) {
                        const excludedProperties = [
                            'Identifier',
                            'Room',
                            'ResubmissionitemCollection',
                            'State',
                            'Form',
                            'Scheduling',
                            'ProgressState',
                            'IsLocked',
                            'IsTemporary',
                            'TemporaryFilesMarkup',
                            'Images',
                            'ShowFiles',
                            'IssueCellMarkerClass',
                            'NewFiles',
                            'PreviousRevisionIdentifiers',
                            'ProcessingStatus',
                            'Location',
                            'Ancestors',
                            'Descendants'
                        ];

                        (<New.Checkpoints.Workflow.IssueWorkflowSettings>workflowSettings).ParentIssue = Utils.CloneObject(parentIssue, excludedProperties);

                        if (parentIssue.AssignedFormRevisionOID) {
                            const form = GetElementRevisionByRevisionOID(parentIssue.AssignedFormRevisionOID);
                            (<New.Checkpoints.Workflow.IssueWorkflowSettings>workflowSettings).InheritResponsibilities =
                                form && form.AdditionalSettings && form.AdditionalSettings.BequeathResponsibilities;
                        }
                    }
                }

                const workflowInstance = New.Checkpoints.Workflow.WorkflowFactory.Create(action, workflowSettings);
                automaticWorkflowStack.push(workflowInstance);
            });
        }

        if (!automaticWorkflowStack.isEmpty()) {
            Utils.Spinner.Unlock();
            Utils.Spinner.Hide();
        }

        return automaticWorkflowStack
            .process()
            .then(function(executedWorkflows: Array<New.Checkpoints.Workflow.Response.ResponseBase> = []) {
                if (!executedWorkflows.length) {
                    return $.Deferred().resolve(recorditem);
                }

                recorditem = processExecutedWorkflows(recorditem, executedWorkflows);
                // Wiederholte Ausführen des Workflows durch "Prüfpunktänderung" vorbeugen
                const bakOnAfterSaved = recorditem.OnAfterSaved;
                recorditem.OnAfterSaved = null;
                recorditem.IsUpdate = true;

                return recorditem.Save()
                    .always(() => {
                        recorditem.OnAfterSaved = bakOnAfterSaved;
                    })
                    .then(() => {
                        UpdateElementCell(recorditem);
                        Utils.Toaster.Show(
                            i18next.t('CorrectiveActions.ExecutedWorkflows', { count: executedWorkflows.length }),
                            2,
                            Enums.Toaster.Icon.Success);

                        return $.Deferred().resolve(recorditem, executedWorkflows);
                    });
            })
            .then(function(recorditem: Model.Recorditem, executedWorkflows: Array<New.Checkpoints.Workflow.Response.ResponseBase>) {
                return onAfterAutomaticallyTriggeredActionsExecuted(recorditem, predefinedCorrectiveActions, executedWorkflows, skipDefaultBehaviour);
            });
    }

    function processExecutedWorkflows(recorditem: Model.Recorditem, executedWorkflows: Array<New.Checkpoints.Workflow.Response.IResponseBase> = []): Model.Recorditem {
        if (!recorditem || !executedWorkflows.length) {
            return recorditem;
        }

        const workflowTexts: string[] = [];

        executedWorkflows.forEach(workflowResponse => {
            switch (workflowResponse.Workflow.Type) {
                case Enums.CheckpointWorkflowType.Comment:
                    const comment = (<New.Checkpoints.Workflow.Response.CommentWorkflow>workflowResponse).Comment;

                    recorditem.Comments = recorditem.Comments || [];
                    recorditem.Comments.push(comment);
                    break;
                case Enums.CheckpointWorkflowType.CorrectiveAction: {
                    const task = (<New.Checkpoints.Workflow.Response.TaskWorkflow>workflowResponse).Issue;

                    recorditem.CorrectiveActions = recorditem.CorrectiveActions || [];
                    recorditem.CorrectiveActions.push(task);

                    ApplyNewCorrectiveActionToDictionary(task, recorditem);

                    const workflowText = (<New.Checkpoints.Workflow.Response.IssueResponseBase>workflowResponse).createWorkflowText();

                    workflowTexts.push(workflowText);
                    break;
                }
                case Enums.CheckpointWorkflowType.Form: {
                    const formIssue = (<New.Checkpoints.Workflow.Response.FormWorkflow>workflowResponse).Issue;

                    recorditem.CorrectiveActions = recorditem.CorrectiveActions || [];
                    recorditem.CorrectiveActions.push(formIssue);

                    const workflowText = (<New.Checkpoints.Workflow.Response.IssueResponseBase>workflowResponse).createWorkflowText();
                    ApplyNewCorrectiveActionToDictionary(formIssue, recorditem);

                    workflowTexts.push(workflowText);
                    break;
                }
                case Enums.CheckpointWorkflowType.Photo:
                    const photo = (<New.Checkpoints.Workflow.Response.PhotoWorkflow>workflowResponse).File;

                    recorditem.AdditionalFiles = recorditem.AdditionalFiles || [];

                    photo.Position = recorditem.AdditionalFiles.length ?
                        Math.max.apply(this, recorditem.AdditionalFiles.map(f => f.Position || 0)) + 1 :
                        0;

                    recorditem.AdditionalFiles.push(photo);
                    break;
                case Enums.CheckpointWorkflowType.ModifyParentIssue:
                    const issue = (<New.Checkpoints.Workflow.Response.ModifyParentIssueWorkflow>workflowResponse).Issue;

                    if (!issue) {
                        break;
                    }

                    IssueView.UpdateIssue(issue);
                    break;
            }
        });

        if (workflowTexts.length) {
            recorditem.WorkflowInformation = workflowTexts.join('\n\n');
        }

        (recorditem.Comments || []).sort(function(a: Model.Comment, b: Model.Comment) {
            return (<Date>a.ModificationTimestamp).getTime() - (<Date>b.ModificationTimestamp).getTime();
        });

        (recorditem.CorrectiveActions || []).sort(function(a: Model.Issues.Issue, b: Model.Issues.Issue) {
            return a.ModificationTimestamp.getTime() - b.ModificationTimestamp.getTime();
        });

        (recorditem.AdditionalFiles || []).sort(Utils.SortByPosition);

        return recorditem;
    }

    function onAfterAutomaticallyTriggeredActionsExecuted(recorditem: Model.Recorditem, predefinedCorrectiveActions, automaticWorkflows: Array<New.Checkpoints.Workflow.Response.IResponseBase>, skipDefaultBehaviour?: boolean): Deferred {
        const deferred = $.Deferred();
        const actionWindowSettings: Utils.ActionWindow.Settings = {
            AutomaticWorkflows: <New.Checkpoints.Workflow.Response.IssueResponseBase[]>(automaticWorkflows || []).filter(i => i instanceof New.Checkpoints.Workflow.Response.IssueResponseBase),
            Recorditem: recorditem,
            PredefinedCorrectiveActions: predefinedCorrectiveActions,
            Issue: null,
            OnAfterClosed: null,
            OnAfterCorrectiveIssueSaved: onAfterCorrectiveActionIssueCreated,
        };

        deferred.then(() => {
            ResizeScrollableTables();
        });

        BindBluetoothEvent();
        BindCodeScannerEvent();
        BindScaleEvent()

        if (!recorditem) {
            return deferred.resolve().promise();
        }

        function actionWindowCallback(isWindowShown: boolean): Deferred {
            if (isWindowShown) {
                return $.Deferred().resolve(true);
            }

            if (skipDefaultBehaviour) {
                if (Utils.ActionWindow.IsQueueEmpty()) {
                    return $.Deferred().resolve(false);
                } else if (!Utils.ActionWindow.IsVisible()) {
                    // Verbleibende Workflows aus der Liste ausführen
                    return Utils.ActionWindow.ShowNextActionWindow(actionWindowCallback);
                }
            }

            // Fix corrective actions
            const tmpCorrectiveActionsArray = $.extend(true, [], recorditem.CorrectiveActions || []);
            const tmpCorrectiveActionsDict = {};
            for (let i = 0, len = tmpCorrectiveActionsArray.length; i < len; i++) {
                const action = tmpCorrectiveActionsArray[i];
                tmpCorrectiveActionsDict[action.OID] = action;
            }

            (predefinedCorrectiveActions || []).forEach(function(action) {
                if (action.Issue) {
                    tmpCorrectiveActionsDict[action.Issue.OID] = action.Issue;
                }
            });
            recorditem.CorrectiveActions = $.map(tmpCorrectiveActionsDict, (issue) => issue);

            if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
                IssueView.SetRecorditem(recorditem);
            }

            let schedulingRoomState: Enums.StateOfGoingToNextRoom = null;
            if (View.CurrentView === Enums.View.Scheduling) {
                const param = GetElement(recorditem.ElementRevisionOID, recorditem.Row);
                const state = IssueView.GoToNextLocation(Session.CurrentLocation, param);

                if (state === Enums.StateOfGoingToNextRoom.StayInCurrentRoom && param && !param.IsHidden && !param.MissingRequirements) {
                    schedulingRoomState = IssueView.TryGoToNextRoom();
                }
            }

            if (schedulingRoomState === null) {
                schedulingRoomState = IssueView.TryGoToNextRoom(true);
            }

            const parentIssue = IssueView.GetCurrentIssue();

            if (parentIssue) {
                if (View.CurrentView === Enums.View.Inspection) {
                    const currentSubIssueCount = parentIssue.AdditionalData && parentIssue.AdditionalData.hasOwnProperty('SubIssueCounter') ?
                        (parentIssue.AdditionalData.SubIssueCounter) :
                        0;

                    const newCorrectiveActions = (predefinedCorrectiveActions || [])
                        .filter(action => action.Issue)
                        .map(action => action.Issue);

                    IssueView.UpdateSubIssues(currentSubIssueCount, newCorrectiveActions);
                }

                if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
                    // Standard-Folgestatus auswahl aktualisieren
                    IssueView.UpdateStatusQuickSelection();

                    if (!IssueView.GetUnfinishedParametersCount()) {
                        IssueView.TrySettingStandardFollowState();
                    } else if (Settings.IsAutomaticallyOpenNextParameterSettingActive()) {
                        if (View.CurrentView === Enums.View.Scheduling && schedulingRoomState === Enums.StateOfGoingToNextRoom.GoToNextRoom) {
                            IssueView.TryGoToNextRoom();
                        } else {
                            OpenNextAvailableCheckpoint(recorditem.ElementRevisionOID, recorditem.Row);
                        }
                    } else if (View.CurrentView === Enums.View.Scheduling && schedulingRoomState === Enums.StateOfGoingToNextRoom.GoToNextRoom) {
                        IssueView.TryGoToNextRoom();
                    }
                }
            }

            recorditem.CorrectiveActions.forEach((i) => ApplyNewCorrectiveActionToDictionary(i, recorditem));

            return $.Deferred().resolve(false);
        }

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            actionWindowSettings.Issue = IssueView.GetCurrentIssue();

            actionWindowSettings.OnAfterClosed = function(element: Model.Elements.Element) {
                if (!skipDefaultBehaviour) {
                    IssueView.OnAfterActionWindowClosed(element);
                }

                if (!Utils.ActionWindow.IsQueueEmpty()) {
                    return Utils.ActionWindow.ShowNextActionWindow(actionWindowCallback);
                }
            };
        } else {
            actionWindowSettings.OnAfterClosed = function(element: { OID: string; }) {
                if (!skipDefaultBehaviour) {
                    if (!element) {
                        return;
                    }

                    if (Settings.IsAutomaticallyOpenNextParameterSettingActive()) {
                        OpenNextAvailableCheckpoint(element.OID);
                    }

                    if (!Utils.ActionWindow.IsQueueEmpty()) {
                        return Utils.ActionWindow.ShowNextActionWindow(actionWindowCallback);
                    }
                }
            };
        }

        actionWindowSettings.ActionWindowCallback = actionWindowCallback;

        return Utils.ActionWindow.Show(actionWindowSettings)
            .then((windowIsShown: boolean) => {
                return windowIsShown
                    ? $.Deferred().resolve()
                    : actionWindowCallback(false);
            });
    }

    function onParameterSearch(searchValue: string): void {
        const elem = searchParametersByValue(searchValue);

        if (elem == null) {
            Utils.Toaster.Show(i18next.t('ParameterList.NoSuitableCPFound'), 3);

            return;
        }

        ScrollTo(elem.OID, elem.Row, true);
    }

    export function UpdateElementCell(recorditem: Model.Recorditem, updateVisibility: boolean = true): void | Deferred {
        if (!recorditem || (Session.Mode !== Enums.Mode.Parameters && Session.Mode !== Enums.Mode.CoveringPage)) {
            return;
        }

        const isValueCategoryFilterActive = !!(filters.ValueCategories || []).length;

        if (recorditem.AdditionalFiles) {
            recorditem.AdditionalFiles.sort(Utils.SortByPosition);
        }

        let element: Model.Elements.Element;
        if (View.CurrentView == Enums.View.Form || View.CurrentView == Enums.View.FormBatchEdit || View.CurrentView == Enums.View.Inspection) {
            element = GetParameter(recorditem.ElementRevisionOID, recorditem.Row);
        } else if (View.CurrentView === Enums.View.Scheduling) {
            element = GetParameter(recorditem.ElementRevisionOID);
        } else {
            element = GetParameter(recorditem.ElementOID);
        }

        if (!element) {
            return;
        }

        element.IsRecorded = recorditem.IsDeleted ? false : Model.Recorditem.IsRecorditemRecorded(recorditem);

        const group = element.Parent;

        if (group && group.AdditionalSettings && group.AdditionalSettings.SubsampleCanBeDeleted) {
            group.AdditionalSettings.SubsampleCanBeDeleted = false;
        }

        let grpCache = getGroupRowFromCache(group.OID, recorditem.Row);

        //if null then tabular
        if (!grpCache.$el.length) {
            grpCache = getGroupRowFromCache(group.OID);
        }

        const $parent = grpCache.$el;
        const paramCache = GetParameterRowFromCache(element.OID, recorditem.Row);

        let $cell = paramCache.$el;
        if (!$cell.length) {
            return;
        }

        let $subsampleRow = $cell.parents('.subsample-row, .groupRow');

        if ($subsampleRow.length === 0) {
            $subsampleRow = $cell.parents('.parameterList').prev();
        }

        if (recorditem.IsDeleted && +recorditem.Row > 0) {
            AppendSubsampleDeleteIcon($subsampleRow, recorditem);
        } else if ($subsampleRow.hasClass('can-be-deleted')) {
            RemoveSubsampleDeleteIcon($subsampleRow, group.OID);
        }

        element.ShowColorIndicator = !recorditem.IsDeleted && !recorditem.IsDummy && !!recorditem.CategoryOID;

        if (recorditem.IsDeleted) {
            element.LastRecorditem = null;
        } else if (recorditem.GetRawEntity) {
            element.LastRecorditem = recorditem.GetRawEntity();
        } else {
            element.LastRecorditem = recorditem;
        }

        if (element.LastRecorditem && (element.LastRecorditem.CorrectiveActions || []).length > 0 && !recorditem.IsUpdate) {
            const actions = element.LastRecorditem.CorrectiveActions.map((issue: Model.Issues.Issue) => ({
                IsIssue: true,
                Issue: issue
            }))

            element.LastRecorditem.WorkflowInformation = Utils.ActionWindow.CreateWorkflowInformation([], actions)
        }

        let removeCell = false;
        let removeParent = false;
        let currentRecordedCount = +$parent.data('recordedparametercount');
        if (!$cell.is('td') && isValueCategoryFilterActive && !Utils.InArray(filters.ValueCategories, recorditem.CategoryOID)) {
            removeCell = true;

            $parent
                .data('recordedparametercount', --currentRecordedCount)
                .attr('data-recordedparametercount', currentRecordedCount);

            removeParent = $parent.next().find('.checkpoint').length === 1;
        }

        if ($cell.data('isrequired') && !$cell.data('isrecorded')) {
            $parent
                .data('recordedparametercount', ++currentRecordedCount)
                .attr('data-recordedparametercount', currentRecordedCount);
            $cell
                .data('isrecorded', true)
                .attr('data-isrecorded', true);
        }

        const requiredParameterCount = +$parent.data('requiredparametercount');

        if (requiredParameterCount) {
            $parent.find('.title').html(getGroupCellTitle(element.Parent, currentRecordedCount,
                requiredParameterCount, $parent.hasClass('tabular'), recorditem.Row));
        }

        if (!removeCell) {
            let $newCell;

            // replace cell with new content
            if ($cell.is('td')) {
                $newCell = $(Templates.Parameters.TabularCell(element));
            } else {
                const availableRights = Utils.GetActiveUserRights(Session.User.OID, true, Session.CurrentLocation) || {};
                const rights = {
                    CanCreateFiles: availableRights.hasOwnProperty(Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint),
                    CanCreateComments: availableRights.hasOwnProperty(Enums.Rights.Comments_CreateAndModifyCommentsOnCheckpoint),
                    CanCreateCorrectiveActions: availableRights.hasOwnProperty(Enums.Rights.Comments_CreateAndModifyActionIssue)
                };
                const issue = IssueView.GetCurrentIssue();

                element.ShowElementInfoButton = Session.Settings.ShowElementInfoButtons;
                element.ShowFiles = Session.Settings.ShowPicturesInIssueReports;
                element.Rights = rights;
                element.IssueIsLocked = issue && issue.IsLocked;
                element.CreateToolbar = Session.Settings.ShowCheckpointToolbar && (
                    Session.IsSmartDeviceApplication && element.Type === Enums.ElementType.IndividualData ||
                    element.IsRecorded
                ) && !element.DisableInAppEditing;

                $newCell = $(Templates.Parameters.Cell(element));
            }

            // Verzögerung verwenden, damit Bilder-PP auf iOS korrekt angezeigt werden können
            if (Session.IsRunningOnIOS) {
                if (element.Type === Enums.ElementType.Photo || element.Type === Enums.ElementType.Signature) {
                    setTimeout(() => {
                        const $images = $newCell.find('img');
                        $.each($images, (_: number, img: HTMLImageElement) => {
                            const parent = img.parentElement;
                            if (parent.nodeName == 'DIV') {
                                parent.innerHTML = parent.innerHTML;
                            }
                        });
                    }, 150);
                }
            }

            // Elemente ersetzen und $cell aktualisieren
            $cell.replaceWith($newCell);

            const paramCache2 = DAL.Cache.ParameterList.Replace(element.OID, recorditem.Row, $newCell);
            $cell = paramCache2.$el;

            bindEvents(true);
        }

        let asyncResult: Deferred = undefined;

        if (View.CurrentView == Enums.View.Form ||
            View.CurrentView == Enums.View.FormBatchEdit ||
            View.CurrentView == Enums.View.Scheduling ||
            View.CurrentView == Enums.View.Inspection) {
            asyncResult = IssueView.SetRecorditem(recorditem);

            if (View.CurrentView === Enums.View.Scheduling) {
                IssueView.CheckRequiredParametersAtLocation(Session.CurrentLocation);
            }
        }

        if (removeCell) {
            $cell.remove();
            DAL.Cache.ParameterList.ResetParameterRow(element.OID, recorditem.Row);

            if (removeParent) {
                $parent.next().remove();
                $parent.remove();
            }
        }

        return (asyncResult || $.Deferred().resolve())
            .then(() => {
                IssueView.UpdateFilteredParameterCounter(true); // PrepareResubmissionTree
                IssueView.UpdateSidebarIndex(group.OID);

                if (updateVisibility) {
                    return updateElementVisibility();
                }
            });
    }

    function getGroupCellTitle(group: Model.Elements.Element, currentRecordedCount: number, requiredparametercount: number,
        isTabular: boolean, row?: number): string {
        let groupTitle: string;

        if (row && !isTabular) {
            groupTitle = `${row}. ${group.Title}`;
        } else {
            groupTitle = group.Title;
        }

        groupTitle = Utils.EscapeHTMLEntities(groupTitle);

        if (!isTabular && requiredparametercount && currentRecordedCount !== requiredparametercount) {
            groupTitle += ` (${currentRecordedCount} / ${requiredparametercount})`;
        }

        if (AreFiltersActive()) {
            groupTitle += ' (<span class="icon-filter" style="color: red"></span>)';
        }

        return groupTitle;
    }

    function onParameterGroupHeaderClick(): void {
        const $this = $(this);

        $this.toggleClass('collapsed');

        if (!$this.hasClass('collapsed') && $this.hasClass('tabular')) {
            $this.next().find('.sticky-enabled').trigger('resize');
        }
    }

    function onParameterClick(evt: Event): void {
        const $this = $(this);
        let showSimplifiedEditor = !Session.Settings.AlwaysShowAdvancedRecorditemEditor && $this.hasClass('value-wrapper');

        evt.stopPropagation();

        const issue = IssueView.GetCurrentIssue();
        if (issue && issue.IsLocked) {
            Utils.Message.Show(i18next.t('IssueView.IssueIsLocked.MessageHeader'),
                i18next.t('IssueView.IssueIsLocked.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        const $wrapper = $this.is('td, li') ? $this : $this.parents('li, td');
        const row = parseInt(<string>$wrapper.data('row'), 10);
        let element: Model.Elements.Element;
        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Inspection], View.CurrentView)) {
            element = GetParameter(<string>$wrapper.data('revisionoid'), row);
        } else if (View.CurrentView === Enums.View.Scheduling) {
            element = GetParameter(<string>$wrapper.data('revisionoid'));
        } else {
            element = GetParameter(<string>$wrapper.data('oid'));
        }

        if (!isCheckpointEditingAllowed(element)) {
            return;
        }

        if (element.ParentOID && _forceAdvancedEditor[element.ParentOID]) {
            showSimplifiedEditor = false;
        }

        let recorditem: Model.Recorditem = getRecorditemForParameter(element);

        if (element.Type === Enums.ElementType.Print) {
            executePrint(element);
        } else if (element.Type !== Enums.ElementType.Info) {
            onModifyRecorditem(element, function() {
                const isRecordingLocked = IssueView.IsRecordingLocked(element);

                if (showSimplifiedEditor) {
                    if (element.Type === Enums.ElementType.Checkbox) {
                        return;
                    }

                    Utils.RecorditemEditor.ShowSimplified({
                        Element: element,
                        PreviousRecorditem: recorditem,
                        Row: row,
                        Issue: issue,
                        OnAfterRecorditemSaved: (recorditem: Model.Recorditem) => onAfterRecorditemSaved(recorditem, true),
                        OnAfterWindowDestroyed: CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack,
                        IsReadonly: isRecordingLocked
                    });
                } else {
                    // add additional corrective actions
                    let additionalCorrectiveActions: Model.Issues.IssueType[] = null;
                    if (issue && issue.AdditionalData && issue.AdditionalData.Associations) {
                        const checkpointAssociations = issue.AdditionalData.Associations[element.OID];
                        if (checkpointAssociations && checkpointAssociations.length) {
                            additionalCorrectiveActions = [];
                            for (let i = 0; i < checkpointAssociations.length; i++) {
                                const currentAssociation = checkpointAssociations[i];
                                const issue = additionalCorrectiveIssues[currentAssociation.IssueID];
                                if (issue) {
                                    additionalCorrectiveActions.push(issue);
                                }
                            }
                        }
                    }

                    Utils.RecorditemEditor.Show({
                        Element: element,
                        PreviousRecorditem: recorditem,
                        Row: row,
                        Issue: issue,
                        OnAfterRecorditemSaved: onAfterRecorditemSaved,
                        OnAfterWindowDestroyed: CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack,
                        OnAfterCommentCreated: UpdateElementCell,
                        OnAfterCommentDeleted: UpdateElementCell,
                        IsReadonly: isRecordingLocked,
                        AdditionalCorrectiveActions: additionalCorrectiveActions
                    });
                }
            });
        }
    }

    function executePrint(element: Model.Elements.Element) {
        // Drucken nur auf Smart Devices unterstützen
        if (!Session.IsSmartDeviceApplication) {
            return;
        }

        if (!element.AdditionalSettings || !element.AdditionalSettings.PrintSettings) {
            Utils.Toaster.Show(i18next.t('Printer.SettingsMissing'));
            return;
        }

        if (!element.AdditionalSettings.PrintSettings.ZPL) {
            Utils.Toaster.Show(i18next.t('Printer.ZplIsMissing'));
            return;
        }

        // Einzusetzende Werte berechnen
        const formulas = element.AdditionalSettings.PrintSettings.Formulas;
        const calculations: Dictionary<string> = {};
        let zpl = element.AdditionalSettings.PrintSettings.ZPL.replace(/\\n/gi, '');
        for (const key in formulas) {
            if (formulas.hasOwnProperty(key)) {
                const f = formulas[key];
                let result = CalculatedCheckpoints.ResolveFormula(f, element);
                // Ergebnis escapen
                result = String(result || '').replace(/^/gi, '')  // ^ ist ein ZPL Steuerzeichen und soll entfernt werden
                    .replace(/\\n/gi, '\&');    // Zeilenumbruch in ZPL umwandeln
                calculations[key.toUpperCase()] = result;
                // ZPL aktualisieren, Platzhalter durch berechnete Werte ersetzen
                zpl = zpl.replace(new RegExp(`{{#${key.toUpperCase()}}}`, 'gi'), result);
            }
        }

        // Bluetooth Drucker ermitteln
        const devices = Utils.BluetoothDeviceManager.GetConnectedDevices();
        for (const key in devices) {
            if (devices.hasOwnProperty(key)) {
                const dev = devices[key];
                if (!(dev instanceof Model.Bluetooth.Printers.BluetoothPrinter)) {
                    delete devices[key];
                }
            }
        }

        // Drucken Fenster anzeigen
        Utils.PrintWindow.Show(calculations, zpl, <any>devices)
    }

    function onAfterRecorditemSaved(recorditem: Model.Recorditem, allowImageComparison?: boolean): void {
        if (!recorditem) {
            return;
        }

        const asyncUpdateResult = UpdateElementCell(recorditem) || $.Deferred().resolve();

        if (View.CurrentView !== Enums.View.Form) {
            return;
        }

        if (Session.Mode !== Enums.Mode.Parameters && Session.Mode !== Enums.Mode.CoveringPage) {
            return;
        }

        if (View.CurrentView !== Enums.View.Form && View.CurrentView !== Enums.View.FormBatchEdit && View.CurrentView !== Enums.View.Inspection) {
            return;
        }

        const element: Model.Elements.Element = GetParameter(recorditem.ElementRevisionOID, recorditem.Row);

        asyncUpdateResult.then(() => {
            // Foto auf Prüfpunkt für Anzeige in der Vorgangsübersicht aktualisieren
            return updatePreviewImageFromCheckpoint(element);
        }).then(() => {
            if (recorditem.IsDeleted) {
                return;
            }

            // handle QR-/AKIM-/Barcodes from here
            if (element &&
                element.Type === Enums.ElementType.Scancode &&
                element.AdditionalSettings &&
                element.AdditionalSettings.BarcodeType === Enums.BarcodeType.AKIM) {
                handleAKIMCodeScan(recorditem);
            }
        }).then(() => {
            if (allowImageComparison &&
                recorditem && recorditem.Element &&
                recorditem.Element.Type == Enums.ElementType.Photo &&
                recorditem.Element.AdditionalSettings &&
                recorditem.Element.AdditionalSettings.ImmediateImageComparison) {
                openImageViewer(recorditem.Element, true);
            }
        });
    }

    function handleAKIMCodeScan(recorditem: Model.Recorditem) {
        const values = Utils.ParseXmlString(recorditem.Value);

        if (!values || !values.QR || !values.QR.DF) {
            Utils.Toaster.Show(
                i18next.t('Akim.InvalidQRCode'),
                2,
                Enums.Toaster.Icon.Warning
            );
            return;
        }

        const form = resubmissionTree.Form;

        if (!form || form.Type !== Enums.ElementType.Form) {
            return;
        }

        const formIssue = IssueView.GetCurrentIssue();

        if (!formIssue) {
            return;
        }

        const now = new Date();
        const invocations: Array<Utils.RecorditemEditor.SaveOptions> = [];

        (form.Parametergroups || []).forEach(group => {
            if ((group.Row || 0) !== (recorditem.Row || 0)) {
                return;
            }

            (group.Parameters || []).forEach(param => {
                const property = values.QR.DF[param.Identcode] || (values.QR.CS || {})[param.Identcode];

                if (!!param.Identcode &&
                    property &&
                    property.hasOwnProperty('#text')) {
                    let value = property['#text'];

                    if (param.Identcode.toUpperCase() === 'D') {
                        const splitted = value.split('-');

                        now.setFullYear(splitted[0], parseInt(splitted[1], 10) - 1, splitted[2]);
                        value = Utils.DateTime.ToGMTString(now);
                    } else if (param.Identcode.toUpperCase() === 'T') {
                        const splitted = value.split(':');

                        now.setHours(splitted[0], splitted[1], splitted[2], 0);
                        value = Utils.DateTime.ToGMTString(now);
                    }

                    delete param.DontAskForUnlockRecording;

                    if (param.Parent) {
                        delete param.Parent.DontAskForUnlockRecording;
                    }

                    const elementRecorditems = Utils.All(formIssue.Recorditems, 'ElementOID', '===', param.OID);
                    const previousRecorditem = Utils.Where(elementRecorditems, 'Row', '===', group.Row);

                    invocations.push(<Utils.RecorditemEditor.SaveOptions>{
                        Element: param,
                        Issue: formIssue,
                        PreviousRecorditem: previousRecorditem,
                        Value: value,
                        Row: recorditem.Row
                    });
                }
            });
        });

        if (!invocations.length) {
            Utils.Toaster.Show(
                i18next.t('Akim.NoCheckpointsFound'),
                2,
                Enums.Toaster.Icon.Info
            );

            UpdateElementCell(recorditem);
            return;
        }

        setTimeout(function() {
            Utils.Spinner.Show();
            Utils.Spinner.Lock('akim');

            (function save(idx) {
                const options = invocations[idx];

                options.OnAfterWindowDestroyed = CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack;
                options.OnAfterRecorditemSaved = (recorditem: Model.Recorditem) => {
                    UpdateElementCell(recorditem);

                    if ((++idx) < invocations.length) {
                        save(idx);
                    } else {
                        Utils.Spinner.Unlock('akim');
                        Utils.Spinner.Hide();
                    }
                };

                onModifyRecorditem(options.Element, function() {
                    if (IssueView.IsRecordingLocked(options.Element)) {
                        if ((++idx) < invocations.length) {
                            save(idx);
                        } else {
                            Utils.Spinner.Unlock('akim');
                            Utils.Spinner.Hide();
                        }

                        return;
                    }

                    Utils.RecorditemEditor.SaveSelectedValue(options);
                });
            })(0);
        }, 50);
    }

    function findFirstCheckpointImage(form: Model.Elements.Element): { Recorditem: Model.Recorditem, CheckpointIndex: number } {
        const formShowCheckpointImageInIssueView = form && (form.AdditionalSettings || {}).ShowCheckpointImageInIssueView;
        let usedCheckpointIndex = 0;

        // ersten Foto-Prüfpunkt in der Prüfpunkt-Liste finden
        for (const group of form.Parametergroups) {
            if (!group || !group.Parameters || !group.Parameters.length) {
                continue;
            }

            // Teilproben ignorieren
            if (group.Type === Enums.ElementType.FormRow) {
                continue;
            }

            // checkpointIndex hochzählen, um einen absoluten Prüfpunkt Index zu erhalten
            for (const checkpoint of group.Parameters) {
                usedCheckpointIndex++;

                if (!checkpoint || !checkpointIsFirstImageInIssueView(group, checkpoint, formShowCheckpointImageInIssueView)) {
                    continue;
                }

                return {
                    Recorditem: checkpoint.LastRecorditem,
                    CheckpointIndex: usedCheckpointIndex
                };
            }
        }

        return null;
    }

    function checkpointIsFirstImageInIssueView(group: Model.Elements.Element, checkpoint: Model.Elements.Element,
        formShowCheckpointImageInIssueView: boolean): boolean {
        // Prüfen, ob Prüfpunkt Typ passt
        if (checkpoint.Type !== Enums.ElementType.Photo || !checkpoint.LastRecorditem || !checkpoint.LastRecorditem.Value) {
            return false;
        }

        const showCheckpointImageInIssueView = (checkpoint.AdditionalSettings || {}).ShowCheckpointImageInIssueView;

        // Foto-Prüfpunkt nur verwenden, wenn Option am Formular oder Prüfpunkt gesetzt
        if (!formShowCheckpointImageInIssueView && !showCheckpointImageInIssueView) {
            return false;
        }

        return groupIsVisible(group) && parameterIsVisible(checkpoint);
    }

    function updatePreviewImageFromCheckpoint(element: Model.Elements.Element): Deferred {
        if (!element) {
            return $.Deferred().resolve();
        }

        const form = resubmissionTree.Form;
        const showCheckpointImageInIssueView =
            (form && (form.AdditionalSettings || {}).ShowCheckpointImageInIssueView)
            || (element.AdditionalSettings || {}).ShowCheckpointImageInIssueView;

        // Option für die Funktion muss am Formular oder Prüfpunkt aktiviert sein
        // Erfassung von Teilproben ignorieren
        if (!showCheckpointImageInIssueView || element.Parent.Type === Enums.ElementType.FormRow) {
            return $.Deferred().resolve();
        }

        // Nur bei Prüfpunkten vom Typ Foto ausführen oder wenn an bestehenden Vorschaubildern Änderungen stattfinden
        if (element.Type !== Enums.ElementType.Photo) {
            // prüfen, ob Element andere Prüfpunkte/-gruppen-Sichtbarkeit beeinflusst
            if (!doesElementInfluenceRequirements(element, form)) {
                return $.Deferred().resolve();
            }
        }

        const formIssue = IssueView.GetCurrentIssue();
        let previewImageRecorditem: Model.Recorditem = null;
        let usedCheckpointIndex = 0;

        // ersten Foto-Prüfpunkt in der Prüfpunkt-Liste finden
        const imageResult = findFirstCheckpointImage(form);

        if (imageResult) {
            previewImageRecorditem = imageResult.Recorditem;
            usedCheckpointIndex = imageResult.CheckpointIndex;
        }

        // neues Vorschaubild an Aufgabe binden
        let hasChanges = false;
        if (previewImageRecorditem) {
            hasChanges = applyPreviewImageChangesIfAny(formIssue, element, previewImageRecorditem, usedCheckpointIndex);
        } else if (formIssue.AdditionalData && formIssue.AdditionalData.CheckpointPreviewImages) {
            // Alle Vorschau Bilder entfernen
            for (const key in formIssue.AdditionalData.CheckpointPreviewImages) {
                if (formIssue.AdditionalData.CheckpointPreviewImages.hasOwnProperty(key)) {
                    const previewImage = formIssue.AdditionalData.CheckpointPreviewImages[key];

                    if (previewImage) {
                        // muss auf null gesetzt werden, da es sonst auf dem Server nicht entfernt wird
                        formIssue.AdditionalData.CheckpointPreviewImages[key] = null;
                        hasChanges = true;
                    }
                }
            }
        }

        // Recht zum Ändern des Vorgangs abfragen, wenn Änderungen gespeichert werden sollen
        if (hasChanges) {
            const rights = Utils.GetActiveUserRights(Session.User.OID, true, formIssue.AssignedElementOID);

            if (Utils.UserHasIssueRight(formIssue, Enums.Rights.Issues_CreateOrModifyForms, rights)) {
                Utils.Spinner.Show();
                const issueObject = new Model.TemporaryIssue(formIssue, null, GetDependingOIDOfIssue(formIssue));
                return issueObject.Save()
                    .then(() => {
                        DAL.Issues.Store(<any>[formIssue]);
                    })
                    .always(() => {
                        Utils.Spinner.Hide();
                    });
            }
        }

        return $.Deferred().resolve();
    }

    function applyPreviewImageChangesIfAny(formIssue: Model.Issues.Issue, element: Model.Elements.Element,
        previewImageRecorditem: Model.Recorditem, usedCheckpointIndex: number): boolean {
        let hasChanges = false;
        formIssue.AdditionalData = formIssue.AdditionalData || {};

        let oldPreviewImage: { Filename: string };
        let oldPreviewIndex: number;

        // Vorherigen Eintrag für das Vorschaubild zu diesem PP finden
        for (const key in formIssue.AdditionalData.CheckpointPreviewImages) {
            if (formIssue.AdditionalData.CheckpointPreviewImages.hasOwnProperty(key)) {
                const previewImage = formIssue.AdditionalData.CheckpointPreviewImages[key];

                if (previewImage &&
                    previewImage.ElementOID === element.OID) {
                    oldPreviewImage = previewImage;
                    oldPreviewIndex = +key;
                    break;
                }
            }
        }

        // prüfen ob relevante Änderungen bestehen
        if (!formIssue.AdditionalData.CheckpointPreviewImages ||
            !oldPreviewImage || oldPreviewIndex !== usedCheckpointIndex ||
            oldPreviewImage.Filename !== previewImageRecorditem.Value) {
            if (formIssue.AdditionalData.CheckpointPreviewImages) {
                // alle (anderen) Einträge zur Löschung auf null setzen (damit die Server Datenbank es korrekt aufnimmt)!
                for (const key in formIssue.AdditionalData.CheckpointPreviewImages) {
                    if (formIssue.AdditionalData.CheckpointPreviewImages.hasOwnProperty(key)) {
                        formIssue.AdditionalData.CheckpointPreviewImages[key] = null;
                    }
                }
            } else {
                formIssue.AdditionalData.CheckpointPreviewImages = {};
            }

            formIssue.AdditionalData.CheckpointPreviewImages[usedCheckpointIndex] = {
                Filename: previewImageRecorditem.Value,
                MimeType: (previewImageRecorditem.ValueImage || {}).type || Enums.MimeType.Image,
                ElementOID: element.OID
            };

            hasChanges = true;
        }

        // Änderungen an Markierungen prüfen
        if (formIssue.AdditionalData.CheckpointPreviewImages[usedCheckpointIndex].Marks !== (previewImageRecorditem.AdditionalValueInfo || {}).Marks) {
            // muss auf null gesetzt werden, da es sonst auf dem Server nicht entfernt wird
            formIssue.AdditionalData.CheckpointPreviewImages[usedCheckpointIndex].Marks = (previewImageRecorditem.AdditionalValueInfo || {}).Marks || null;
            hasChanges = true;
        }

        return hasChanges;
    }

    function doesElementInfluenceRequirements(element: Model.Elements.Element, form: Model.Elements.Element): boolean {
        if (!element || !form || !form.Parametergroups) {
            return false;
        }

        // zuerst nur Prüfgruppen durchgehen, da hier am wahrscheinlichsten Bedingungen existieren
        for (const group of form.Parametergroups) {
            // Prüfen ob Prüfgruppen-Bedingungen beeinflusst werden vom Element
            if (group && group.Requirements && group.Requirements.Criteria) {
                for (const criteria of group.Requirements.Criteria) {
                    if (criteria.ElementOID === element.OID) {
                        return true;
                    }
                }
            }
        }

        // alle Prüfpunkte auf Bedingungen prüfen
        for (const group of form.Parametergroups) {
            if (!group.Parameters) {
                continue;
            }

            for (const param of group.Parameters) {
                // Prüfen ob Prüfpunkt-Bedingungen beeinflusst werden vom Element
                if (param && param.Requirements && param.Requirements.Criteria) {
                    for (const criteria of param.Requirements.Criteria) {
                        if (criteria.ElementOID === element.OID) {
                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }

    function onInfoParameterClick(): void {
        const $this = $(this);
        let oid = <string>$this.data('oid');

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            oid = <string>$this.data('revisionoid');
        }

        Utils.ElementInformation.Show(oid);
    }

    function onBooleanValueClick(evt: Event): void {
        const $this = $(this);
        const $wrapper = $this.parents('li, td');
        let value = $this.data('value');
        const issue = IssueView.GetCurrentIssue();
        const row = parseInt(<string>$wrapper.data('row'), 10);
        let element: Model.Elements.Element;

        evt.stopPropagation();

        if (issue && issue.IsLocked) {
            Utils.Message.Show(i18next.t('IssueView.IssueIsLocked.MessageHeader'),
                i18next.t('IssueView.IssueIsLocked.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Inspection], View.CurrentView)) {
            element = GetParameter(<string>$wrapper.data('revisionoid'), row);
        } else if (View.CurrentView === Enums.View.Scheduling) {
            element = GetParameter(<string>$wrapper.data('revisionoid'));
        } else {
            element = GetParameter(<string>$wrapper.data('oid'));
        }

        if (!isCheckpointEditingAllowed(element)) {
            return;
        }

        let recorditem = Utils.CloneObject(getRecorditemForParameter(element), ['Elements', 'Form', 'Room']);

        // beim Klick des gleichen Wertes das Löschen der Erfassung über 'undefined' triggern
        if (recorditem) {
            if (!$this.hasClass('desaturate') || recorditem.Value === value) {
                if (!issue && !Session.Settings.AlwaysShowAdvancedRecorditemEditor) {
                    // ohne Vorgang => Prüfpunkt am Raum => löschen nicht möglich
                    return;
                }

                value = undefined;
            }
        }

        onModifyRecorditem(element, function() {
            const isRecordingLocked = IssueView.IsRecordingLocked(element);

            if (Session.Settings.AlwaysShowAdvancedRecorditemEditor || (element.ParentOID && _forceAdvancedEditor[element.ParentOID])) {
                if (!isRecordingLocked) {
                    if (recorditem) {
                        recorditem.IsDefault = true;
                        recorditem.Value = value;
                    } else {
                        recorditem = <any>{
                            IsDefault: true,
                            Value: value
                        };
                    }
                }

                Utils.RecorditemEditor.Show({
                    Element: element,
                    PreviousRecorditem: recorditem,
                    Row: row,
                    Issue: issue,
                    OnAfterRecorditemSaved: onAfterRecorditemSaved,
                    OnAfterWindowDestroyed: CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack,
                    IsReadonly: isRecordingLocked
                });
            } else {
                if (isRecordingLocked) {
                    return;
                }

                Utils.Spinner.Show(null, true);

                Utils.RecorditemEditor.SaveSelectedValue({
                    Element: element,
                    PreviousRecorditem: recorditem,
                    Row: row,
                    Issue: issue,
                    Value: value,
                    OnAfterRecorditemSaved: onAfterRecorditemSaved,
                    OnAfterWindowDestroyed: CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack,
                    IsReadonly: isRecordingLocked
                });
            }
        });
    }

    function onListItemValueClick(evt: Event): void {
        const $this = $(this);
        const $wrapper = $this.parents('li, td');
        const issue = IssueView.GetCurrentIssue();

        evt.stopPropagation();

        if (issue && issue.IsLocked) {
            Utils.Message.Show(i18next.t('IssueView.IssueIsLocked.MessageHeader'),
                i18next.t('IssueView.IssueIsLocked.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        const row = parseInt(<string>$wrapper.data('row'), 10);
        let element: Model.Elements.Element;

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Inspection], View.CurrentView)) {
            element = GetParameter(<string>$wrapper.data('revisionoid'), row);
        } else if (View.CurrentView === Enums.View.Scheduling) {
            element = GetParameter(<string>$wrapper.data('revisionoid'));
        } else {
            element = GetParameter(<string>$wrapper.data('oid'));
        }

        if (!isCheckpointEditingAllowed(element)) {
            return;
        }

        let recorditem: any = Utils.CloneObject(getRecorditemForParameter(element), ['Elements', 'Form', 'Room']);

        let value = $this.data('value').toString();

        if (element.Type === Enums.ElementType.ListBox) {
            if ($this.hasClass('selected')) {
                value = null;
            }
        } else /* MultiListBox */ {
            let tmp = recorditem ? Utils.CloneArray(recorditem.Value) : null;

            if ($this.hasClass('selected') && tmp instanceof Array) {
                tmp.splice(tmp.indexOf(value), 1);
                if (!tmp.length) {
                    tmp = null;
                }
            } else {
                if (!(tmp instanceof Array)) {
                    tmp = [value];
                } else if (tmp.indexOf(value) === -1) {
                    tmp.push(value);
                }
            }

            value = tmp;
        }

        onModifyRecorditem(element, function() {
            const isRecordingLocked = IssueView.IsRecordingLocked(element);

            if (Session.Settings.AlwaysShowAdvancedRecorditemEditor || (element.ParentOID && _forceAdvancedEditor[element.ParentOID])) {
                if (!isRecordingLocked) {
                    if (recorditem) {
                        recorditem.IsDefault = true;
                        recorditem.Value = value;
                    } else {
                        recorditem = {
                            IsDefault: true,
                            Value: value
                        };
                    }
                }

                Utils.RecorditemEditor.Show({
                    Element: element,
                    PreviousRecorditem: recorditem,
                    Row: row,
                    Issue: issue,
                    OnAfterRecorditemSaved: onAfterRecorditemSaved,
                    OnAfterWindowDestroyed: CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack,
                    IsReadonly: isRecordingLocked
                });
            } else {
                if (isRecordingLocked) {
                    return;
                }

                Utils.Spinner.Show(null, true);

                Utils.RecorditemEditor.SaveSelectedValue({
                    Element: element,
                    PreviousRecorditem: recorditem,
                    Row: row,
                    Issue: issue,
                    Value: value,
                    OnAfterRecorditemSaved: onAfterRecorditemSaved,
                    OnAfterWindowDestroyed: CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack,
                    IsReadonly: isRecordingLocked
                });
            }
        });
    }

    function onAfterCorrectiveActionIssueCreated(parameterOID: string, issue: Model.Issues.Issue, row: number): void {
        const param = GetParameter(parameterOID, row);

        if (!param) {
            return;
        }

        const recorditem = getRecorditemForParameter(param);

        if (!recorditem) {
            return;
        }

        recorditem.CorrectiveActions = $.extend(true, [], recorditem.CorrectiveActions || []);

        if (!!issue.PrecedingOID) {
            const caLen = recorditem.CorrectiveActions.length;

            for (let caCnt = 0; caCnt < caLen; caCnt++) {
                if (recorditem.CorrectiveActions[caCnt].OID === issue.PrecedingOID) {
                    recorditem.CorrectiveActions.splice(caCnt, 1);
                    recorditem.CorrectiveActions.push(issue);

                    break;
                }
            }
        } else {
            const issueIndex = Utils.GetIndex(recorditem.CorrectiveActions, issue.OID, 'OID');

            if (issueIndex === -1) {
                recorditem.CorrectiveActions.push(issue);
            } else {
                recorditem.CorrectiveActions[issueIndex] = issue;
            }

            if (issue.ID == null || recorditem.ID == null) {
                CorrectiveActionsDictByOID[recorditem.OID] = [].concat(recorditem.CorrectiveActions);
            } else if (recorditem.ID != null) {
                CorrectiveActionsDictionary[recorditem.ID] = [].concat(recorditem.CorrectiveActions);
            }
        }

        const parentIssue = IssueView.GetCurrentIssue();

        if (!parentIssue) {
            return;
        }

        const subIssueCount = parentIssue.AdditionalData &&
            parentIssue.AdditionalData.hasOwnProperty('SubIssueCounter') ?
            (parentIssue.AdditionalData.SubIssueCounter) :
            0;

        IssueView.UpdateSubIssues(subIssueCount, [issue]);
    }

    function onFileClick(evt: Event): void {
        const $this = $(this);
        const $li = $this.parents('li, td');
        let element = DAL.Elements.GetByOID($li.data('oid'));
        let row: number, resubitemOID: string, recorditem: Model.Recorditem;

        evt.stopPropagation();

        if (!$this.data('filename')) {
            return;
        }

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Inspection], View.CurrentView)) {
            row = $li.parent().prev().data('row');
            resubitemOID = IssueView.GetResubmissionitemOID($li.data('oid'), row);
            recorditem = IssueView.GetRecorditemForResubmissionItem(resubitemOID);
            element = ParameterList.GetElementRevisionByRevisionOID($li.data('revisionoid'));
        } else if (View.CurrentView !== Enums.View.Main) {
            resubitemOID = IssueView.GetResubmissionitemOID($li.data('oid'));
            recorditem = IssueView.GetRecorditemForResubmissionItem(resubitemOID);
        } else {
            element = DAL.Elements.GetByOID($li.data('oid'));

            if (element) {
                recorditem = element.LastRecorditem;
            }
        }

        if (!recorditem) {
            return;
        }

        if (!$this.parent().hasClass('files') && !$this.parent().hasClass('additional-file') && !$this.hasClass('file-recorditem-row')) {
            Utils.OpenFile(recorditem.Value, true, false);
        } else {
            const files = $this.hasClass('file-recorditem-row') ?
                $.extend(true, [], recorditem.Value) :
                $.extend(true, [], recorditem.AdditionalFiles);

            if (!element) {
                return;
            }

            const filename = $this.data('filename');
            const mimeType = $this.data('mimetype');

            if (Utils.IsImage(mimeType)) {
                const comparisonImages = Utils.GetImagesFromFiles(element.Files);

                Utils.SortFilesByPrioritization(comparisonImages);
                Utils.OpenImages(
                    files,
                    filename,
                    false,
                    null,
                    null,
                    element.Title,
                    null,
                    comparisonImages
                );
            } else {
                const file: any = Utils.Where(files, 'Filename', '===', filename);

                Utils.OpenFile(filename,
                    false,
                    Utils.InArray(DAL.Files.GetVideoFileExtensions(), Utils.GetFileExtension(filename)),
                    file ? file.Title : element.Title,
                    mimeType
                );
            }
        }
    }

    function onReferenceFormFileClick(evt): void {
        let $this = $(evt.currentTarget);

        if ($this.hasClass('marks')) {
            $this = $this.prev('img');
        }

        const $td = $this.parents('td');
        const elementOID = $td.data('oid');
        const element = DAL.Elements.GetByOID(elementOID);
        const groups = this || [];

        evt.stopPropagation();

        if (!$this.data('filename') || !element || !groups.length) {
            return;
        }

        $('#olSpinner').addClass('hidden');
        $('.issue-review').addClass('hidden');

        const filename = $this.data('filename');

        function onCloseReferenceFormImage() {
            $('#olSpinner').removeClass('hidden');
            $('.issue-review').removeClass('hidden');
        }

        const imageViewerOptions = {
            ImageFilename: filename,
            FooterInformation: {
                Title: element.Title
            },
            closeFn: onCloseReferenceFormImage,
            Files: [],
            ForceLoadFromServer: true
        };

        let file;

        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];

            if ((group.Parameters || []).length === 0) {
                continue;
            }

            const elementIndex = Utils.GetIndex(group.Parameters, elementOID, (parameter) => {
                return parameter.OID === elementOID && parameter.LastRecorditem != null;
            });

            if (elementIndex === -1) {
                continue;
            }

            const referenceElement = group.Parameters[elementIndex];
            const lastRecorditem = referenceElement.LastRecorditem;

            if (!$this.hasClass('additional-file') && element.Type === Enums.ElementType.Photo
                && lastRecorditem.Value && lastRecorditem.AdditionalValueInfo && lastRecorditem.AdditionalValueInfo.Marks) {
                file = {
                    Filename: lastRecorditem.Value,
                    IsImage: true,
                    Marks: lastRecorditem.AdditionalValueInfo.Marks,
                    Title: element.Title
                };

                let mimeType = lastRecorditem.Value.match(/\.[0-9a-z]+$/i);

                if (!!mimeType) {
                    mimeType = mimeType[mimeType.length - 1].substring(1); // Punkt aus MimeType entfernen
                    file.MimeType = `image/${mimeType}`;
                }

                break;
            } else if ((lastRecorditem.AdditionalFiles || []).length) {
                file = Utils.FindByPredicate(lastRecorditem.AdditionalFiles, (file) => {
                    return file.Filename === filename;
                });

                break;
            }
        }

        if (file) {
            imageViewerOptions.Files.push(file);
        }

        Utils.ImageViewerManager.Show(imageViewerOptions);
    }

    function onPrioritizedFileClick(evt: Event): void {
        const $this = $(this);
        const filename = <string>$this.data('filename');
        const identifier = <string>$this.data('identifier');
        const file = !!filename ? DAL.Files.GetByFilename(filename) : DAL.Files.GetByOID(identifier);

        evt.stopPropagation();

        if (!file) {
            return;
        }

        if (file.IsEmbeddedVideo) {
            const $li = $this.parents('li');
            const elementIdentifier = Utils.InArray([Enums.View.Form, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView) ?
                $li.data('revisionoid') :
                $li.data('oid');

            if (!!elementIdentifier) {
                Utils.ElementInformation.Show(elementIdentifier, function($win) {
                    const $video = $win.find(`.video-container[data-identifier="${file.OID}"]`);

                    if (!$video.length) {
                        return;
                    }

                    $win.find('.modal-body').scrollTop($video[0].offsetTop);
                });
            }

            return;
        }

        if (!file.IsImage) {
            Utils.OpenFile(file.Filename, file.IsImage, file.IsVideo, file.Title);
            return;
        }

        const $li = $this.parents('li');
        let element: Model.Elements.Element;

        if (Utils.InArray([Enums.View.Form, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            const identifier = $li.data('revisionoid')
            element = ParameterList.GetElementRevisionByRevisionOID(identifier);
        } else {
            const identifier = $li.data('oid');
            element = DAL.Elements.GetByOID(identifier);
        }

        const images = Utils.GetImagesFromFiles(element.Files);

        Utils.SortFilesByPrioritization(images);
        Utils.OpenImages(images, images[0].Filename);
    }

    function onAfterAdditionalImageLoaded(): void {
        const $this = $(this);
        const $marks = $this.siblings('div');

        if ($marks.length) {
            $marks.css({
                top: $this.position().top,
                width: $this.width(),
                height: $this.height(),
                'margin-left': 0,
                'margin-right': 0
            });

            $marks.find('> svg')
                .attr({
                    width: '100%',
                    height: '100%'
                });
        }
    }

    function onAddSubsampleClick(evt: Event): void {
        const $this = $(this);

        evt.stopPropagation();

        if ($this.attr('disabled')) {
            return;
        }

        let groupIdentifier: string;

        if ($this.hasClass('subsample-add-footer')) {
            groupIdentifier = $this.data('groupoid');
        } else {
            const $group = $this.parents('.subsample-row, .groupRow');

            groupIdentifier = $group.data('groupoid');
        }

        Utils.Spinner.Show();
        Utils.Spinner.Lock('oASC');

        IssueView.AddSubsample(groupIdentifier)
            .then(addSubsampleToDependency)
            .then(() => IssueView.UpdateSidebarIndex(groupIdentifier))
            .always(() => {
                Utils.Spinner.Unlock('oASC');
                Utils.Spinner.Hide();
            });
    }

    function addSubsampleToDependency(element: Model.Elements.Element): void | Deferred {
        if (element == null || element.Row == null) {
            return;
        }

        const newSubsample = GetElement(element.OID, element.Row + 1, true);

        function refreshParameterDependencyValues(paramIndex: number) {
            if (paramIndex > newSubsample.Parameters.length - 1) {
                return;
            }

            const newParameter = newSubsample.Parameters[paramIndex];
            const dependantParameters = CalculatedCheckpoints.updateParameterDependencies(newParameter) || [];

            if (dependantParameters.length > 0) {
                return (function recalculate(index) {
                    if (index > dependantParameters.length - 1) {
                        return refreshParameterDependencyValues(++paramIndex);
                    }

                    const ident = dependantParameters[index];
                    const parts = ident.split('_');
                    const oid = parts[0];
                    const row = parts.length === 2 ? parseInt(parts[1], 10) : null;
                    const selfCalculateParameter = oid === newParameter.OID && (!row && !newParameter.Row || row === newParameter.Row);

                    return CalculatedCheckpoints.calculateFormulaValues(oid, row, true, selfCalculateParameter)
                        .then(() => recalculate(++index));
                })(0);
            } else {
                return refreshParameterDependencyValues(++paramIndex);
            }
        }

        if (newSubsample && (newSubsample.Parameters || []).length) {
            return refreshParameterDependencyValues(0);
        }
    }

    function onLockableRecordingClick(evt: Event): void {
        evt.stopPropagation();

        const $parent = $(this).parents('.subsample-row, .groupRow');

        if (!$parent.length) {
            return;
        }

        const group = GetElement(
            <string>$parent.data('revisionoid'),
            parseInt(<string>$parent.data('row'), 10),
            true
        );

        if (!group) {
            return;
        }

        IssueView.ToggleRecordingLockState(group, null, () => RemoveSubsampleDeleteIcon($parent, group.OID));
    }

    function AppendSubsampleDeleteIcon($subsampleRow: any, recorditem: Model.Recorditem): void {
        // this return only first element of subsamples! check for row necessary.
        const parameter = resubmissionTree.GetElementByRevisionOID(recorditem.ElementRevisionOID, recorditem.Row);
        if (!parameter || !parameter.Parent || !parameter.Parent.Parameters
            || !parameter.Parent.Parameters.length) {
            return;
        }

        let group = parameter.Parent;

        // only last row should be deleteable (for now)
        const root = group.Parent;
        if (!root || !root.Parametergroups || !root.Parametergroups.length) {
            return;
        }

        // get row count for group
        const maxRow = root.Parametergroups.reduce((acc: number, current: Model.Elements.Element): number => {
            if (current.OID == group.OID) {
                // get correct group
                if (current.Row == recorditem.Row) {
                    group = current;
                }
                return Math.max(acc, current.Row);
            }
            return acc;
        }, recorditem.Row);

        if (recorditem.Row < maxRow) {
            return;
        }

        // check for all empty values
        for (let i = 0; i < group.Parameters.length; i++) {
            const param = group.Parameters[i];

            // all recorditems has to be null, currently deleted recorditem excluded
            if (param.LastRecorditem && !param.LastRecorditem.IsDummy &&
                param.LastRecorditem.ElementRevisionOID != recorditem.ElementRevisionOID &&
                param.LastRecorditem.Row == recorditem.Row) {
                // subsample not empty yet
                return;
            }
        }

        // check for rights to add delete icon
        if (Utils.UserHasRight(Session.User.OID, Enums.Rights.DeleteSubsample) && !group.IsRecordingLocked &&
            group.Row > 1 && (group.MinSubsampleCount == null || group.MinSubsampleCount < group.Row)) {
            if ($subsampleRow.hasClass('can-be-deleted')) {
                return;
            }
            $subsampleRow.addClass('can-be-deleted');

            // tabellen oder normale Ansicht
            const showTabularSubsample = !!_tabularSubsampleToggleState[group.OID] ||
                (_tabularSubsampleToggleState[group.OID] == null && Session.Settings.ShowTabularSubsampleByDefault);

            // löschen icon hinzufügen
            if (showTabularSubsample) {
                $subsampleRow.find('.first-column').append('<div class="icon-bin2 delete-subsample"></div>');

                const $tableWrapper = $subsampleRow.parents('.sticky-wrap');
                const $row = $tableWrapper.find(`.sticky-col tr[data-row="${group.Row}"][data-revisionoid="${group.RevisionOID}"][data-groupoid="${group.OID}"][data-type="${group.Type}"]`);
                $row.addClass('can-be-deleted');
            } else {
                $subsampleRow.find('.add-subsample').before('<span class="icon-bin2 delete-subsample"></span>');
            }
        }
    }

    export function RemoveSubsampleDeleteIcon($subsampleRow: any, groupOID: string) {
        const showTabularSubsample = !!_tabularSubsampleToggleState[groupOID] ||
            (_tabularSubsampleToggleState[groupOID] == null && Session.Settings.ShowTabularSubsampleByDefault);

        if (showTabularSubsample) {
            const groupOID = $subsampleRow.data('groupoid');
            const revisionOID = $subsampleRow.data('revisionoid');
            const row = $subsampleRow.data('row');
            const type = $subsampleRow.data('type');

            $subsampleRow.removeClass('can-be-deleted');
            $subsampleRow.find('.delete-subsample').remove();
            if (!Session.Settings.TabularSubsampleShowNumber) {
                $subsampleRow.closest('.sticky-table').find(`.delete-subsample[data-groupoid="${groupOID}"][data-row="${row}"]`).addClass('hidden');
            }

            const $tableWrapper = $subsampleRow.parents('.sticky-wrap');
            const $row = $tableWrapper.find(`.sticky-col tr[data-row="${row}"][data-revisionoid="${revisionOID}"][data-groupoid="${groupOID}"][data-type="${type}"]`);
            $row.removeClass('can-be-deleted');
            $row.find('.delete-subsample').remove();

            if (!$tableWrapper.find('.delete-subsample, .recording-lockable').length) {
                $tableWrapper.find('.table-action-column, .table-action-header, .table-action-content').remove();
                const $subSamplesColspanCells = $tableWrapper.find('.subsample-add-colspan');

                for (let cCnt = 0; cCnt < $subSamplesColspanCells.length; cCnt++) {
                    $subSamplesColspanCells[cCnt].colSpan -= 1;
                }
            }
        } else {
            $subsampleRow.removeClass('can-be-deleted');
            $subsampleRow.find('.delete-subsample').remove();
        }
    }

    function onModifyRecorditem(element: Model.Elements.Element, callback: Function): void {
        if (!callback) {
            return;
        }

        if (IssueView.IsRecordingLocked(element)) {
            IssueView.AskForRecordingGroupUnlock(element, callback);
        } else {
            callback();
        }
    }

    function onToggleTabular(evt: Event): void {
        evt.stopPropagation();

        const $this = $(this);
        const $parent = $this.parents('.groupRow');

        if (!$parent.length) {
            return;
        }

        Utils.Spinner.Show();
        Utils.Spinner.Lock();

        window.setTimeout(() => {
            const oid = $parent.data('groupoid');
            let row = parseInt($parent.data('row'), 10);

            if (isNaN(row) || row == null) {
                row = 1;
            }

            const state = $this.hasClass('icon-table');

            if (!state) {
                delete _forceAdvancedEditor[oid];
            }

            _tabularSubsampleToggleState[oid] = state;
            RefreshView();

            $content.off('click.deleteSubsample', '.delete-subsample');
            $content.on('click.deleteSubsample', '.delete-subsample', onDeleteSubsampleRow);

            window.setTimeout(() => {
                Utils.Spinner.Unlock();
                Utils.Spinner.Hide();
                ScrollTo(oid, row);
            }, 10);
        }, 10);
    }

    function onToggleForceAdvancedEditor(evt: Event): void {
        evt.stopPropagation();

        const $this = $(this);
        const $parent = $this.parents('.groupRow');

        if (!$parent.length) {
            return;
        }

        const oid = $parent.data('groupoid');
        const state = !_forceAdvancedEditor[oid];

        $this.toggleClass('active', state);
        _forceAdvancedEditor[oid] = state;
    }

    function onDeleteSubsampleError(): void {
        Utils.Message.Show(i18next.t('ParameterList.DeleteSubsample.MessageHeader'), i18next.t('ParameterList.DeleteSubsample.ErrorMessageBody'), { Close: true })
    }

    function onDeleteSubsampleRow(evt: Event): void {
        evt.stopPropagation();

        if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.DeleteSubsample)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.DeleteSubsample.MessageBody'),
                {
                    Close: true
                });
            return;
        }

        const $this = $(this);
        let groupOID = $this.data('groupoid');
        let row = $this.data('row');

        const $parent = $this.parents('.subsample-row, .groupRow');

        if ($parent.length === 0 && !groupOID && !row) {
            onDeleteSubsampleError();
            return;
        } else if (!groupOID && !row) {
            groupOID = $parent.data('groupoid');
            row = $parent.data('row');
        }

        if (!groupOID || !row) {
            onDeleteSubsampleError();
            return;
        }

        const showSubsampleTabular = !!_tabularSubsampleToggleState[groupOID] ||
            (_tabularSubsampleToggleState[groupOID] == null && Session.Settings.ShowTabularSubsampleByDefault);

        Utils.Message.Show(
            i18next.t('ParameterList.DeleteSubsample.MessageHeader'),
            i18next.t('ParameterList.DeleteSubsample.MessageBody'),
            {
                No: true,
                Yes: onDeletionConfirmed
            }
        );

        function onDeletionConfirmed(): void {
            const group = GetElement(groupOID, row, true);
            const issue = IssueView.GetCurrentIssue();

            if (group == null || issue == null || (issue.Resubmissionitems || []).length === 0) {
                onDeleteSubsampleError();
                return;
            }

            const formOID = issue.AssignedFormOID;
            const formResubmissionitem = Utils.Where(issue.Resubmissionitems, 'ElementOID', '===', formOID);
            const formResubmissionOID = formResubmissionitem != null ? formResubmissionitem.OID : null;

            if (formResubmissionOID == null) {
                onDeleteSubsampleError();
                return;
            }

            if (group.Row === row && !IssueView.IsRecordingLocked(group) && group.RecordedParameterCount === 0) {
                const groupResubmissionitem = Utils.FindByPredicate(issue.Resubmissionitems, (item) => {
                    return item.ParentOID === formResubmissionOID && item.ElementOID === groupOID && item.Row === row
                });

                if (groupResubmissionitem == null) {
                    onDeleteSubsampleError();
                    return;
                }

                const groupResubmissionitemOID = groupResubmissionitem.OID;

                if (groupResubmissionitemOID == null) {
                    onDeleteSubsampleError();
                    return;
                }

                function updateRowState() {
                    const rowLookup = `.can-be-deleted[data-row='${group.Row}'][data-groupoid='${group.OID}'][data-revisionoid='${group.RevisionOID}']`;
                    const $row = $content.find(rowLookup);

                    if (!showSubsampleTabular) {
                        $row.next('ul.parameterList').remove();
                    }

                    $row.remove();

                    resubmissionTree = null;
                    return _tabularSubsampleToggleState;
                }

                Utils.Spinner.Show();

                IssueView.DeleteSubsample(groupResubmissionitemOID, updateRowState)
                    .fail(onDeleteSubsampleError)
                    .always(Utils.Spinner.Hide);
            }
        }
    }

    function isCheckpointEditingAllowed(element: Model.Elements.Element): boolean {
        if (!element ||
            (element.Formula && !element.AllowOverrideFormulaValue)) {
            return false;
        }

        if (element.Type === Enums.ElementType.Files) {
            return false;
        }

        if (element.DisableInAppEditing) {
            Utils.Toaster.Show(i18next.t('ParameterList.CheckpointEditingBlocked', { title: element.Title }));
            return false;
        }

        if ((View.CurrentView === Enums.View.Form || View.CurrentView === Enums.View.FormBatchEdit) &&
            resubmissionTree && resubmissionTree.Form && resubmissionTree.Form.ForceParameterOrderWhileRecording &&
            !checkIfPreviousParametersAreRecorded(element)) {
            Utils.Message.Show(i18next.t('IssueView.PreviousParametersNotRecorded.MessageHeader'),
                i18next.t('IssueView.PreviousParametersNotRecorded.MessageBody'),
                {
                    Close: true
                });

            return false;
        }

        return true;
    }

    function isNextCheckpointAvailable(checkpoint: Model.Elements.Element): boolean {
        return parameterIsVisible(checkpoint) &&
            checkpoint.Type !== Enums.ElementType.Info &&
            (!checkpoint.Formula || checkpoint.AllowOverrideFormulaValue) &&
            !checkpoint.DisableInAppEditing &&
            (Session.Settings.AutomaticallyOpenNextCheckpoint === Enums.AutomaticallyOpenNextCheckpoint.OnlyRequired ?
                checkpoint.Required : true);
    }

    export function OpenNextAvailableCheckpoint(previousIdentifier: string, row?: number): void {
        if (!previousIdentifier) {
            return;
        }

        const previousCheckpoint = GetParameter(previousIdentifier, row);

        if (!previousCheckpoint || previousCheckpoint.IsAdhoc) {
            return;
        }

        const previousGroup = previousCheckpoint.Parent;

        if (!previousGroup || !(previousGroup.Parameters || []).length) {
            return;
        }

        let followingCheckpoint: Model.Elements.Element = null;
        let previousCheckpointFound: boolean = false;

        for (let pCnt = 0, pLen = previousGroup.Parameters.length; pCnt < pLen; pCnt++) {
            const checkpoint = previousGroup.Parameters[pCnt];

            if (previousCheckpointFound &&
                isNextCheckpointAvailable(checkpoint)) {
                followingCheckpoint = checkpoint;
                break;
            }

            if (checkpoint.OID === previousCheckpoint.OID &&
                checkpoint.Row === previousCheckpoint.Row) {
                previousCheckpointFound = true;
            }
        }

        if (followingCheckpoint) {
            if (!isNextCheckpointAvailable(followingCheckpoint)) {
                // open next possible checkpoint
                OpenNextAvailableCheckpoint(followingCheckpoint.OID, followingCheckpoint.Row);
                return;
            }

            let selector = `.checkpoint[data-oid="${followingCheckpoint.OID}"]`;

            if (followingCheckpoint.Row) {
                selector += `[data-row="${followingCheckpoint.Row}"]`;
            }

            $content.find(selector).click();
            ScrollTo(followingCheckpoint.OID, followingCheckpoint.Row);

            return;
        }

        if (!previousGroup.Parent || !(previousGroup.Parent.Parametergroups || []).length) {
            return;
        }

        const groups = previousGroup.Parent.Parametergroups;
        groups.sort(function(a, b) {
            return (a.OID === b.OID && a.Row && b.Row)
                ? a.Row - b.Row
                : Utils.SortByPosition(a, b);
        });

        let previousGroupFound: boolean = false;

        for (let gCnt = 0, gLen = groups.length; gCnt < gLen; gCnt++) {
            const group = groups[gCnt];

            if (!groupIsVisible(group) ||
                !(group.Parameters || []).length ||
                group.IsRecordingLocked) {
                continue;
            }

            if (!previousGroupFound) {
                if (group.OID === previousGroup.OID &&
                    group.Row === previousGroup.Row) {
                    previousGroupFound = true;
                }

                continue;
            }

            for (let pCnt = 0, pLen = group.Parameters.length; pCnt < pLen; pCnt++) {
                const checkpoint = group.Parameters[pCnt];

                if (isNextCheckpointAvailable(checkpoint)) {
                    followingCheckpoint = checkpoint;
                    break;
                }
            }

            if (followingCheckpoint) {
                break;
            }
        }

        if (!followingCheckpoint ||
            (Session.Settings.AutomaticallyOpenNextCheckpoint !== Enums.AutomaticallyOpenNextCheckpoint.Always
                && !followingCheckpoint.Required)) {
            return;
        }

        if (!isNextCheckpointAvailable(followingCheckpoint)) {
            // open next possible checkpoint
            OpenNextAvailableCheckpoint(followingCheckpoint.OID, followingCheckpoint.Row);
            return;
        }

        let selector = `.checkpoint[data-oid="${followingCheckpoint.OID}"]`;

        if (followingCheckpoint.Row) {
            selector += `[data-row="${followingCheckpoint.Row}"]`;
        }

        $content.find(selector).click();
        ScrollTo(followingCheckpoint.OID, followingCheckpoint.Row);
    }

    export function SetOpenEditorAfterRoomChanged(openEditor: boolean = false): void {
        _openEditorAfterRoomChange = openEditor;
    }

    function openNextAvailableCheckpointAfterRoomChanged(groups: Model.Elements.Element[]): void {
        if (!_openEditorAfterRoomChange || (groups || []).length < 1) {
            return;
        }

        const parameterGroups = groups;
        const currentIssue = IssueView.GetCurrentIssue();

        if (!currentIssue) {
            SetOpenEditorAfterRoomChanged(false);
            return;
        }

        const assignedSchedulingOID = currentIssue.AssignedSchedulingOID;
        let schedulingParameterGroup;

        for (let i = 0; i < parameterGroups.length; i++) {
            const elem = parameterGroups[i];

            for (let j = 0; j < (elem.Scheduling || []).length; j++) {
                const scheduling = elem.Scheduling[j];
                if (scheduling && scheduling.OID === assignedSchedulingOID) {
                    schedulingParameterGroup = elem;
                    break;
                }
            }

            if (!schedulingParameterGroup) {
                SetOpenEditorAfterRoomChanged(false);
                continue;
            }

            if (schedulingParameterGroup && (schedulingParameterGroup.Parameters || []).length) {
                const parameters = schedulingParameterGroup.Parameters;

                for (let i = 0; i < parameters.length; i++) {
                    const checkpoint = parameters[i];

                    if (!checkpoint) {
                        continue;
                    }

                    if (isNextCheckpointAvailable(checkpoint)) {
                        let selector = `.checkpoint[data-oid="${checkpoint.OID}"]`;

                        if (checkpoint.Row) {
                            selector += `[data-row="${checkpoint.Row}"]`;
                        }

                        $content.find(selector).click();
                        ScrollTo(checkpoint.OID, checkpoint.Row);
                        SetOpenEditorAfterRoomChanged(false);
                        return;
                    }
                }
            }
        }

        SetOpenEditorAfterRoomChanged(false);
    }

    export function BindBluetoothEvent(): void {
        if (!Session.IsSmartDeviceApplication || !Utils.BluetoothDeviceManager) {
            return;
        }

        const listenForMeasureButton = () => {
            const connectedDevices = Utils.BluetoothDeviceManager.GetConnectedDevices();

            for (let key in connectedDevices) {
                const device = connectedDevices[key];

                if (device.DeviceType === Enums.BluetoothDeviceType.Thermometer) {
                    const thermometer = (<Model.Bluetooth.Thermometers.BluetoothThermometer>device);
                    thermometer.StopListenForMeasureButtonPressAndReadTemperature()
                        .then(function() {
                            thermometer.ListenForMeasureButtonPressAndReadTemperature(OnAfterGotMeasuredTemperature, (e) => {
                                console.error('while reading temperature');
                                console.error(e);
                                console.error('---------------------')
                            });
                        });
                }
            }
        };

        Utils.BluetoothDeviceManager.AddDeviceConnectedCallback('ParameterListTemperatureDevice', listenForMeasureButton);

        if (!Utils.BluetoothDeviceManager.IsBluetoothThermometerConnected()) {
            return;
        }

        listenForMeasureButton();
    }

    export function BindScaleEvent(): void {
        if (!Session.IsSmartDeviceApplication || !Utils.ScaleDeviceManager) {
            return;
        }

        Utils.ScaleDeviceManager.AddDeviceListenerCallback('ParameterList', OnAfterGotWeight);
    }

    export function UnbindScaleEvent(): void {
        if (!Session.IsSmartDeviceApplication || !Utils.ScaleDeviceManager) {
            return;
        }

        Utils.ScaleDeviceManager.RemoveDeviceListenerCallback('ParameterList');
    }

    export function BindCodeScannerEvent(): void {
        if (!CodeScanner.IsSupported()) {
            return;
        }

        CodeScanner.RemoveFromCallbackStack(CODE_SCANNER_CALLBACK_ID);
        CodeScanner.AddToCallbackStack(
            (type: string, text: string) => onAfterGotScanCode(type, text, Enums.ScannerMode.Recording),
            true,
            CODE_SCANNER_CALLBACK_ID
        );
    }

    function getCategoriesForFilter(): Utils.CheckpointFilterCategoryInformation {
        const disabledCategories: string[] = [];
        const visibleCategories: string[] = [];
        let recorditems: Model.Recorditem[],
            issue: Model.Issues.Issue;
        let evaluatedCategories: string[];

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            issue = IssueView.GetCurrentIssue();

            if (issue && (issue.Recorditems || []).length) {
                recorditems = [];

                (function walk(element) {
                    if ((element.Parametergroups || []).length) {
                        element.Parametergroups.forEach(function(group) {
                            if (group.IsHidden || group.MissingRequirements) {
                                return;
                            }

                            if ((group.Parameters || []).length) {
                                group.Parameters.forEach(function(param) {
                                    if (parameterIsVisible(param) && param.LastRecorditem) {
                                        recorditems.push(param.LastRecorditem);
                                    }
                                });
                            }
                        });
                    }

                    if ((element.Children || []).length) {
                        element.Children.forEach(walk);
                    }
                })(resubmissionTree.Root || resubmissionTree.Form);
            }
        } else if ((Session.CurrentLocation.Parametergroups || []).length) {
            recorditems = [];

            Session.CurrentLocation.Parametergroups.forEach(function(group) {
                if (group.IsHidden || group.MissingRequirements || !(group.Parameters || []).length) {
                    return;
                }

                group.Parameters.forEach(function(param) {
                    if (param.IsHidden || param.MissingRequirements || !param.LastRecorditem || !param.LastRecorditem.CategoryOID) {
                        return;
                    }

                    recorditems.push(param.LastRecorditem);
                });
            });
        }

        if ((recorditems || []).length) {
            evaluatedCategories = [];

            recorditems.forEach(function(recorditem) {
                if (!Utils.InArray(evaluatedCategories, recorditem.CategoryOID)) {
                    evaluatedCategories.push(recorditem.CategoryOID);
                }
            });
        }

        (function walk(property) {
            let parent: Model.Properties.Property;

            if (Utils.InArray(evaluatedCategories, property.OID)
                && !Utils.InArray(visibleCategories, property.OID)) {
                visibleCategories.push(property.OID);

                parent = property;

                while ((parent = parent.Parent)
                    && !Utils.InArray(visibleCategories, parent.OID)) {
                    if (!Utils.InArray(evaluatedCategories, parent.OID)) {
                        disabledCategories.push(parent.OID);
                    }

                    visibleCategories.push(parent.OID);
                }
            } else {
                disabledCategories.push(property.OID);
            }

            if ((property.Children || []).length) {
                property.Children.forEach(walk);
            }
        })(DAL.Properties.GetRootByType(Enums.PropertyType.ValueCategory));

        return {
            VisibleCategories: visibleCategories,
            DisabledCategories: disabledCategories
        };
    }

    function preventBubbling(evt: Event): void {
        evt.stopPropagation();
    }

    function checkIfPreviousParametersAreRecorded(parameter: Model.Elements.Element): boolean {
        if (!resubmissionTree || !resubmissionTree.Form.Parametergroups || !resubmissionTree.Form.Parametergroups.length) {
            return false;
        }

        const gLen = resubmissionTree.Form.Parametergroups.length;
        for (let gCnt = 0; gCnt < gLen; gCnt++) {
            const group = resubmissionTree.Form.Parametergroups[gCnt];

            if (!group.Parameters || !group.Parameters.length) {
                continue;
            }

            if (!groupIsVisible(group)) {
                continue;
            }

            for (let pCnt = 0, pLen = group.Parameters.length; pCnt < pLen; pCnt++) {
                const param = group.Parameters[pCnt];

                if (param.OID === parameter.OID) {
                    return true;
                }

                if (!parameterIsVisible(param) ||
                    !param.Required ||
                    param.DisableInAppEditing) {
                    continue;
                }

                const recItem = getRecorditemForParameter(param);
                if (!recItem) {
                    return false;
                }
            }
        }

        return false;
    }

    function searchParametersByValue(searchedCode: string): Model.Elements.Element {
        if (resubmissionTree == null) {
            return null;
        }

        let parameterGroupsToSearch = resubmissionTree.Form ? resubmissionTree.Form.Parametergroups : null;

        if (parameterGroupsToSearch == null) {
            // Ansicht befindet sich in einem Plan, hier die CurrentLocation für Parametergruppen Ermittlung verwenden
            const currentLocationFromResubTree = resubmissionTree.GetElementByOID(Session.CurrentLocation ? Session.CurrentLocation.OID : null);

            parameterGroupsToSearch = currentLocationFromResubTree.Parametergroups || null;
        }

        if (parameterGroupsToSearch == null) {
            return null;
        }

        for (let gi = 0; gi < parameterGroupsToSearch.length; gi++) {
            const group = parameterGroupsToSearch[gi];

            // Gruppen ohne Parameter überspringen
            if (!group.Parameters) {
                continue;
            }

            for (let i = 0; i < group.Parameters.length; i++) {
                const param = group.Parameters[i];

                // Prüfpunkte ohne Erfassung überspringen
                if (!Model.Recorditem.IsRecorditemRecorded(param.LastRecorditem)) {
                    continue;
                }

                if (param.Type !== Enums.ElementType.IndividualData) {
                    // Wert der Erfassung prüfen, wenn keine Individualdaten
                    const val = param.LastRecorditem.Value;

                    if (val === searchedCode || (val.contains instanceof Function && val.contains(searchedCode))) {
                        return param;
                    }

                    continue;
                }

                // Prüfen ob das Schema am Parameter angegeben ist
                if (param.AdditionalSettings == null || param.AdditionalSettings.Types == null) {
                    continue;
                }

                // Individualdaten Schema laden
                const schema = DAL.Schemas.GetByType(param.AdditionalSettings.Types[0]);

                if (schema == null || schema.SearchFields == null) {
                    continue;
                }

                const entityIDs = param.LastRecorditem.Value[schema.Type];

                for (let e = 0; e < entityIDs.length; e++) {
                    const entityID = entityIDs[e];
                    const entity = DAL.IndividualData.GetIndividualData(schema.Type, entityID);

                    // Prüfen ob Individualdaten Eintrag existiert
                    if (entity == null) {
                        continue;
                    }

                    // Prüfen ob Individualdaten-Wert den gesuchten Wert enthält
                    for (let f = 0; f < schema.SearchFields.length; f++) {
                        const val = entity[schema.SearchFields[f]];

                        if (val == null) {
                            continue;
                        }

                        if (val == searchedCode || (val.contains instanceof Function && val.contains(searchedCode))) {
                            return param;
                        }
                    }
                }
            }
        }

        return null;
    }

    export function GetElement(parameterIdentifier: string, row?: number, matchGroups: boolean = false): Model.Elements.Element {
        if (View.CurrentView == Enums.View.Form || View.CurrentView == Enums.View.FormBatchEdit || View.CurrentView == Enums.View.Inspection) {
            if (!resubmissionTree || !(resubmissionTree.Form.Parametergroups || []).length) {
                return null;
            }

            const resubGroups = resubmissionTree.Form.Parametergroups;

            for (let gCnt = 0, gLen = resubGroups.length; gCnt < gLen; gCnt++) {
                const group = resubGroups[gCnt];

                if ((!_subsampleGroupTypes[group.Type] || _subsampleGroupTypes[group.Type] && group.Row === row) &&
                    group.Parameters && group.Parameters.length) {
                    if (matchGroups && (parameterIdentifier == group.RevisionOID || parameterIdentifier == group.OID)) {
                        return group;
                    }

                    const groupParameters = group.Parameters;
                    for (let pCnt = 0, pLen = groupParameters.length; pCnt < pLen; pCnt++) {
                        const param = groupParameters[pCnt];

                        if (parameterIdentifier == param.RevisionOID || parameterIdentifier == param.OID) {
                            param.Row = group.Row;
                            return param
                        }
                    }
                }
            }
        } else if (View.CurrentView === Enums.View.Scheduling) {
            return resubmissionTree.GetElementByRevisionOID(parameterIdentifier) || resubmissionTree.GetElementByOID(parameterIdentifier);
        } else {
            return DAL.Elements.GetByOID(parameterIdentifier);
        }

        return null;
    }

    export function GetElementsIndependentOfRow(elementIdentifier: string, elementCache?: Array<Model.Elements.Element>): Array<Model.Elements.Element> {
        const elements: Model.Elements.Element[] = [];

        if (elementCache) {
            for (let i = 0, len = elementCache.length; i < len; i++) {
                const el = elementCache[i];
                if (el.OID === elementIdentifier) {
                    elements.push(el);
                }
            }
        } else if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Inspection], View.CurrentView)) {
            if (resubmissionTree && resubmissionTree.ElementsByOID) {
                // erstes Element in der Liste ist leer bei Teilproben => weil Row als Index
                return (resubmissionTree.ElementsByOID[elementIdentifier] || []).filter(e => !!e);
            }
        } else if (View.CurrentView === Enums.View.Scheduling && Utils.HasProperties(resubmissionTree.DistinctRevisions)) {
            // DistinctRevisions nur für Pläne verwendet (ohne Teilproben)
            for (let revisionOID in resubmissionTree.DistinctRevisions) {
                const element = resubmissionTree.DistinctRevisions[revisionOID];

                if (Utils.InArray([revisionOID, element.OID], elementIdentifier)) {
                    elements.push(element);
                }
            }
        } else {
            const element = DAL.Elements.GetByOID(elementIdentifier);

            if (element) {
                elements.push(element);
            }
        }

        return elements;
    }

    export function GetParameter(parameterIdentifier: string, row?: number): Model.Elements.Element {
        return GetElement(parameterIdentifier, row, false);
    }

    function getRecorditemForParameter(parameter: Model.Elements.Element): Model.Recorditem {
        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            return IssueView.GetRecorditemForElement(parameter);
        } else {
            return parameter.LastRecorditem;
        }
    }

    function locationHasGroups(types: Array<Enums.ElementType>, locationToUse: Model.Elements.Element): boolean {
        if (!locationToUse || !(locationToUse.Parametergroups || []).length) {
            return false;
        }

        let hasGroups = false;

        let gCnt = 0;
        const gLen = locationToUse.Parametergroups.length;

        for (; gCnt < gLen; gCnt++) {
            const group = locationToUse.Parametergroups[gCnt];

            if (Utils.InArray(types, group.Type)) {
                if (group.Type === Enums.ElementType.MasterdataGroup
                    || group.Attribute === 0 || group.Attribute === 4) {
                    hasGroups = true;
                    break;
                }
            }
        }

        return hasGroups;
    }

    function buildHierarchy(elements: Array<Model.Elements.Element>): void {
        if (!(elements || []).length) {
            return;
        }

        for (let eCnt = 0, eLen = elements.length; eCnt < eLen; eCnt++) {
            const element = elements[eCnt];
            const parent = DAL.Elements.GetByOID(element.ParentOID)

            if (parent) {
                element.Parent = parent;
            }

            const children = elements.filter((elem) => elem.ParentOID === element.OID);

            if (children.length) {
                if (element.Type === Enums.ElementType.Root || element.Type === Enums.ElementType.Location) {
                    element.Children = children.filter((elem) => elem.Type === Enums.ElementType.Location);

                    element.Children.sort(Utils.SortByPosition);

                    element.Parametergroups = children.filter(
                        (elem) => elem.Type >= Enums.ElementType.MasterdataGroup && elem.Type <= Enums.ElementType.Parametergroup
                    );

                    element.Parametergroups.sort(Utils.SortByPosition);
                } else if (element.Type >= Enums.ElementType.MasterdataGroup && element.Type <= Enums.ElementType.Parametergroup) {
                    element.Parameters = children;
                    element.Parameters.sort(Utils.SortByPosition);
                }
            }

            if (element.OID === Session.CurrentLocation.OID) {
                Session.CurrentLocation = element;
            }
        }
    }

    function getParametersWithRecorditems(location: Model.Elements.Element): Deferred {
        // Stammdaten höchstens 1x alle 5min aktualisieren
        if (!location) {
            return $.Deferred().resolve();
        }

        if (!Session.IsSmartDeviceApplication) {
            // Stammdaten nach max. 5min seit letzem Update aktualisieren
            if (location.LastMasterdataRefresh && (new Date().getTime() - location.LastMasterdataRefresh.getTime()) < 300000) {
                return $.Deferred().resolve();
            }

            return $.when(Utils.Http.Get(`elements/${location.OID}?withlastrecorditem=true&withchildelements=true`)
                .fail(function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                }))
                .then((elements: Model.Elements.Element[]) => {
                    if ((elements || []).length) {
                        buildHierarchy(elements);

                        const groupDict: Dictionary<Model.Elements.Element> = {};

                        if (location.Parametergroups) {
                            location.Parametergroups.forEach(group => {
                                groupDict[group.OID] = group;
                            });
                        }

                        for (let eCnt = 0, eLen = elements.length; eCnt < eLen; eCnt++) {
                            const element = elements[eCnt];

                            if (Utils.InArray([93, 94, 95, 96, 97, 98, 99], <number>element.Type)) {
                                const elementFromDAL = DAL.Elements.GetByOID(element.OID);
                                if (elementFromDAL) {
                                    element.IsCollapsed = elementFromDAL.IsCollapsed;
                                }
                            }

                            const prioFile = Utils.GetPrioritizedFile(element);

                            if (prioFile) {
                                element.PrioritizedFile = prioFile;
                            }

                            const parent = groupDict[element.ParentOID];

                            if (parent) {
                                const childIndex = Utils.GetIndex(parent.Parameters, element.OID, 'OID');

                                if (childIndex > -1) {
                                    parent.Parameters[childIndex] = element;
                                }
                            }

                            DAL.Elements.Update(element);

                            if (element.OID == location.OID) {
                                // an (neuer) aktueller Location markieren, dass Stammdaten-Werte geladen wurden
                                element.LastMasterdataRefresh = new Date();
                            }
                        }
                    }
                });
        } else if (location && location.Parametergroups) {
            // Stammdaten Aktualisierung wird nach Synchronisation automatisch angesteuert,
            // kein erneutes Laden nötig, wenn Stammdaten bereits geladen wurden
            if (location.LastMasterdataRefresh) {
                return $.Deferred().resolve();
            }

            // Stammdaten Prüfgruppen ermitteln
            const masterDataGroups = location.Parametergroups.filter(g => g.Type === Enums.ElementType.MasterdataGroup);

            if (!masterDataGroups.length) {
                location.LastMasterdataRefresh = new Date();
                return $.Deferred().resolve();
            }

            // Erfassungen zu Stammdaten laden
            return getHistoralRecorditems(masterDataGroups)
                .then(function(recorditems: Model.Recorditem[]) {
                    // Erfasung an Parameter-Element anhängen
                    for (let i = 0; i < recorditems.length; i++) {
                        const rec = recorditems[i];
                        const element = DAL.Elements.GetByOID(rec.ElementOID);

                        if (element) {
                            element.LastRecorditem = rec;
                        }
                    }

                    // IsAdhoc entfernen, da es sonst zu falscher Darstellung führt
                    masterDataGroups.forEach((g) => {
                        // IsAdhoc von allen Stammdaten-Elementen entfernen
                        g.Parameters.forEach((p) => {
                            delete p.IsAdhoc;
                        });
                    });

                    location.LastMasterdataRefresh = new Date();
                });
        } else {
            location.LastMasterdataRefresh = new Date();
        }

        return $.Deferred().resolve();
    }

    export function PrepareElementRevisions(elements: Array<Model.Elements.Element>, returnResubTree?: boolean): Model.Elements.Element {
        if (!elements || !elements.length) {
            Utils.Spinner.Hide();

            return null;
        }

        const issue = IssueView.GetCurrentIssue();

        if (!issue) {
            return null;
        }

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Inspection], View.CurrentView)) {
            // Elemente mit Teilproben können hier korrekt behandelt werden
            const resubItems = issue.Resubmissionitems;
            resubmissionTree = DAL.Elements.BuildForm(resubItems, elements.filter(revision => revision && revision.Enabled));
        } else {
            // bei Ausführung von Plänen: Enums.View.Scheduling == View.CurrentView
            resubmissionTree = DAL.Elements.BuildTree(
                elements.filter(revision => revision && revision.Enabled),
                true,
                ['LastRecorditem']
            );
        }

        // add recorditems as LastRecorditem on elements
        applyRecorditemsToParameters(issue, resubmissionTree);

        if (returnResubTree) {
            getPreparedResubGroups(issue.Recorditems);
        }

        Utils.Spinner.HideWithTimeout();

        if (returnResubTree) {
            return resubmissionTree.Root || resubmissionTree.Form;
        }
    }

    function applyRecorditemsToParameters(issue: Model.Issues.Issue, resubTree: Model.Elements.ResubmissionElementTree) {
        if (!issue || !issue.Recorditems || !issue.Recorditems.length || !resubTree || !resubTree.ElementsByOID) {
            return;
        }

        for (let i = 0; i < issue.Recorditems.length; i++) {
            const recorditem: Model.Recorditem = issue.Recorditems[i];
            const param = resubTree.GetElementByOID(recorditem.ElementOID, recorditem.Row);

            if (!param.LastRecorditem || param.LastRecorditem.Revision < recorditem.Revision) {
                prepareParameterRecorditem(param, recorditem);
            }
        }
    }

    function unbindEvents(lightBind?: boolean): void {
        if (!lightBind) {
            $content.off('click', '.checkpoint .parameter-description .info-button');
            $content.off('click', '.groupRow .info-button');
            $content.off('click.telephoneNumber');
            $content.off('click', '.checkpoint[data-oid][data-type!="111"], .checkpoint[data-oid][data-type!="111"] .parameter-description');
            $content.off('click', '.checkpoint[data-oid][data-type="111"], td.checkpoint[data-oid][data-type="111"]');
            $content.off('click', '.checkpoint[data-oid] .value-wrapper');
            $content.off('click', '.files, div[data-filename]:not(.prioritized-file)');
            $content.off('click', '.prioritized-file');
            $content.off('click.toggleListView', '.parameter-list > p');
            $content.off('click', '.parameter-list .subsample-add-footer, .groupRow .add-subsample');
            $content.off('click', '.parameter-list .recording-lockable');
            $content.off('load', '.file.image img');
            $content.off('click', '.toggle-tabular');
            $content.off('click', '.toggle-force-advanced-editor');
            $content.off('click.openSubsampleChart', '.parameterHeader[data-revisionoid]');
            $content.off('click.changeBooleanValue', 'img[data-value]');
            $content.off('click.changeBooleanValue', '.desaturate, img[data-value]');
            $content.off('click.changeBooleanValue', '.desaturate, .is-historical img[data-value]');
            $content.off('click.changeListItemValue', '.list-item-element.clickable');
            $content.off('click.deleteSubsample', '.delete-subsample');
            $content.off('click.addCorrectiveAction', '.add-corrective-action');
            $content.off('click.addComment', '.add-comment');
            $content.off('click.scanCode', '.scan-code');
            $content.off('click.editComment', '.comment');
            $content.off('click.showComment', '.show-comments');

            if (!Session.IsSmartDeviceApplication) {
                $content.find('.add-picture input[type="file"]')
                    .off('click')
                    .off('change');
            }

            $('body').off('click.issue-preview');
        }

        if (Session.IsSmartDeviceApplication) {
            $content.find('.add-picture')
                .off('click')
                .off();
        }

        $content.off('error', 'img');

        if (Utils.BluetoothDeviceManager) {
            Utils.BluetoothDeviceManager.RemoveDeviceConnectedCallback('ParameterListTemperatureDevice');

            if (Utils.BluetoothDeviceManager.IsBluetoothThermometerConnected()) {
                const connectedDevices = Utils.BluetoothDeviceManager.GetConnectedDevices();

                for (let key in connectedDevices) {
                    if (connectedDevices[key] instanceof Model.Bluetooth.Thermometers.BluetoothThermometer) {
                        connectedDevices[key].DeregisterAllNotifier();
                    }
                }
            }
        }

        UnbindScaleEvent();

        CodeScanner.RemoveFromCallbackStack(CODE_SCANNER_CALLBACK_ID);
    }

    function onElementInfoButtonClick(evt) {
        evt.stopPropagation();

        const $element = $(evt.target).parents('.checkpoint');
        const identifier = $element.data('oid');

        showElementInformation($element, identifier)
    }

    function onGroupInfoButtonClick(evt) {
        evt.stopPropagation();

        const $group = $(evt.target).parents('.groupRow');
        const oid = $group.data('groupoid');

        showElementInformation($group, oid)
    }

    function showElementInformation($element, identifier) {

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            identifier = $element.data('revisionoid');
        }

        Utils.ElementInformation.Show(identifier);
    }

    function bindEvents(lightBind?: boolean): void {
        unbindEvents(lightBind);

        if (!lightBind) {
            $content.on('click', '.checkpoint .parameter-description .info-button', onElementInfoButtonClick);
            $content.on('click', '.groupRow .info-button', onGroupInfoButtonClick);
            $content.on('click.telephoneNumber', '.checkpoint[data-type="115"] a[href^="tel:"]', preventBubbling);
            $content.on('click.showComment', '.show-comments', onShowCommentsClick);
            $content.on('click', '.checkpoint[data-oid][data-type!="111"], .checkpoint[data-oid][data-type!="111"] .parameter-description', onParameterClick);
            $content.on('click', '.checkpoint[data-oid][data-type="111"], td.checkpoint[data-oid][data-type="111"]', onInfoParameterClick);
            $content.on('click', '.checkpoint[data-oid] .value-wrapper', onParameterClick);
            $content.on('click', '.files, div[data-filename]:not(.prioritized-file)', onFileClick);
            $content.on('click', '.prioritized-file', onPrioritizedFileClick);
            $content.on('click.toggleListView', '.parameter-list > p', onParameterGroupHeaderClick);
            $content.on('click', '.parameter-list .subsample-add-footer, .groupRow .add-subsample', onAddSubsampleClick);
            $content.on('click', '.parameter-list .recording-lockable', onLockableRecordingClick);
            $content.on('load', '.file.image img', onAfterAdditionalImageLoaded);
            $content.on('click', '.toggle-tabular', onToggleTabular);
            $content.on('click', '.toggle-force-advanced-editor', onToggleForceAdvancedEditor);
            $content.on('click.openSubsampleChart', '.parameterHeader[data-revisionoid]', function(evt) {
                evt.stopPropagation();
                Utils.ParameterHistory.Show($(this).data('revisionoid'), true);
            });
            $content.on('click.deleteSubsample', '.delete-subsample', onDeleteSubsampleRow);
            $content.on('click.addCorrectiveAction', '.add-corrective-action', onAddCorrectiveActionClick);
            $content.on('click.scanCode', '.scan-code', onScanCodeClick);
            $content.on('click.addComment', '.add-comment', onAddCommentClick);
            $content.on('click.editComment', '.comment', onExistingCommentClick);

            if (!Session.IsSmartDeviceApplication) {
                $content
                    .off('click.addFileToCheckpoint')
                    .off('change.addFileToCheckpoint')
                    .on('click.addFileToCheckpoint', '.add-picture input[type="file"]', (evt: Event) => evt.stopPropagation())
                    .on('change.addFileToCheckpoint', '.add-picture input[type="file"]', onFileInput);
            }

            $content.on('click.changeBooleanValue', 'img[data-value]', onBooleanValueClick);
            $content.on('click.changeListItemValue', '.list-item-element.clickable', onListItemValueClick);

            $('body').on('click.issue-preview', '.issue-review .header .cancel', function(evt: Event) {
                $('#olSpinner').animate({
                    opacity: 0.0
                }, 400);

                $('.issue-review').animate({
                    opacity: 0.0,
                    height: "0"
                }, 500, function() {
                    $('#olReview').remove();
                    $('.issue-review-wrapper').remove();
                });
            });
        }

        if (Session.IsSmartDeviceApplication) {
            $content.find('.add-picture')
                .off()
                .on('click', (evt: Event) => evt.stopPropagation())
                .press(onFileButtonPress, onFileButtonClick, 500);
        }

        $content.find('img').on('error', Utils.OnImageNotFound);
        $content.find('iframe.info-text').on('load', Utils.OnIframeLoaded);

        $content.find('p[data-groupoid]').off('contextmenu').contextMenu([{
            Title: i18next.t('Misc.ContextMenu.ShowInformation'),
            BackgroundImage: 'info-outline.svg',
            Type: 1,
            FnClick: function($group) {
                const oid = $group.data('groupoid');
                showElementInformation($group, oid)
            },
            FnDisabled: function($group) {
                return !$group.data('hasinformation');
            },
        }], $content);

        $content.find('.checkpoint[data-oid]').off('contextmenu').contextMenu([{
            Title: i18next.t('Misc.ContextMenu.ShowInformation'),
            BackgroundImage: 'info-outline.svg',
            Type: 1,
            FnClick: function($element) {
                const identifier = $element.data('oid');
                showElementInformation($element, identifier)
            },
            FnDisabled: function($element) {
                return !$element.data('hasinformation');
            },
        }, {
            Title: i18next.t('Misc.ContextMenu.ShowRecorditemEditor'),
            BackgroundImage: 'pen-angled.svg',
            Type: 1,
            FnClick: function($element) {
                if (($element || []).length) {
                    $element.trigger('click');
                }
            },
            visible: function($element): boolean {
                const elementType: Enums.ElementType = $element.data('type');

                return !$element || !$element.length || elementType !== Enums.ElementType.Print && elementType !== Enums.ElementType.Files;
            }
        }, {
            Title: i18next.t('Misc.ContextMenu.ShowImageViewer'),
            BackgroundImage: 'search.svg',
            Type: 1,
            FnClick: ($element) => {
                const elementOID = $element.data('oid');
                if (!elementOID) {
                    return;
                }
                const elementRow = $element.data('row');
                const element = ParameterList.GetElement(elementOID, elementRow);
                openImageViewer(element)
            },
            FnDisabled: ($element) => !$element || !$element.data('isrecorded'),
            visible: ($element) => $element && $element.length && $element.data('type') === Enums.ElementType.Photo
        }, {
            Title: i18next.t('Misc.ContextMenu.ShowHistory'),
            BackgroundImage: 'ic_restore_black.svg',
            Type: 1,
            FnClick: function($element) {
                let identifier = $element.data('oid');

                if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
                    identifier = $element.data('revisionoid');
                }

                Utils.ParameterHistory.Show(identifier);
            },
            FnDisabled: function($element) {
                return $element.data('type') === Enums.ElementType.Info
                    || Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Inspection], View.CurrentView);
            }
        }], $content);

        BindBluetoothEvent();
        BindCodeScannerEvent();
        BindScaleEvent();
    }

    function openImageViewer(element: Model.Elements.Element, showComparison?: boolean) {
        const isImageElement = element && element.Type === Enums.ElementType.Photo;

        if (!isImageElement) {
            return;
        }

        if (!element.LastRecorditem || !element.LastRecorditem.Value) {
            return;
        }

        const filename = element.LastRecorditem.Value;

        let marks: string;
        if (element.LastRecorditem.AdditionalValueInfo && element.LastRecorditem.AdditionalValueInfo.Marks) {
            marks = element.LastRecorditem.AdditionalValueInfo.Marks;
        }

        let comparisonImages: Model.Files.File[];
        if (element.Files) {
            comparisonImages = Utils.GetImagesFromFiles(element.Files);
            Utils.SortFilesByPrioritization(comparisonImages);
        }

        const imageViewerOptions = <Utils.ImageViewerOptions>{
            Images: [{
                Filename: filename,
                MimeType: Enums.MimeType.Image,
                Marks: marks
            }],
            FooterInformation: {
                Title: filename
            },
            ComparisonImages: comparisonImages,
            ComparisonOnStart: showComparison || false
        };

        Utils.ImageViewerManager.Show(imageViewerOptions);
    }

    function updateElementVisibility(): Deferred {
        const recorditems: Array<Model.Recorditem> = [];
        const parameterCache = DAL.Cache.ParameterList;
        let groups = parameterCache.PreparedGroups;

        if (groups == null) {
            if (View.CurrentView === Enums.View.Main) {
                const paramGroups = Session.CurrentLocation.Parametergroups;

                for (let pi = 0; pi < (paramGroups || []).length; pi++) {
                    const parameters = paramGroups[pi].Parameters;

                    for (let gi = 0; gi < (parameters || []).length; gi++) {
                        let param = parameters[gi];

                        recorditems.push(param.LastRecorditem);
                    }
                }

                groups = getPreparedGroups(_eligableGroupTypes, recorditems);
            } else {
                groups = getPreparedResubGroups(IssueView.GetCurrentIssue().Recorditems);
            }
            parameterCache.PreparedGroups = groups;
        }

        if (!(groups || []).length) {
            setNoCheckpointsFoundInfo();
            return $.Deferred().resolve();;
        }

        let anyChanged = false;
        const modifiedGroups: Model.Elements.Element[] = [];
        for (const group of groups) {
            if (!(group.Parameters || []).length) {
                continue;
            }

            if (group.IsTabularSubsample) {
                if (updateGroupVisibility(group, true)) {
                    modifiedGroups.push(group);
                    anyChanged = true;
                }

                const $group = getGroupRowFromCache(group.OID).$el;
                const $tableWrapper = $group.next();

                for (const parameter of group.Parameters) {
                    const $paramColumn = parameterCache.GetParameterColumn(parameter.OID, function(oid: string) {
                        return $tableWrapper.find(
                            `.checkpoint[data-oid="${oid}"], .parameterHeader[data-parameteroid="${oid}"]`);
                    });

                    if (elementIsVisible(parameter)) {
                        $paramColumn.removeClass('hidden');
                    } else {
                        $paramColumn.removeClass('not-visible');
                        $paramColumn.addClass('hidden');
                    }
                }

                window.setTimeout(function() {
                    $tableWrapper.find('.sticky-enabled').trigger('resize');
                }, 10);
            } else {
                if (updateGroupVisibility(group)) {
                    modifiedGroups.push(group);
                    anyChanged = true;
                }
            }
        }

        if (anyChanged) {
            // Teilproben Gruppen gesondert berechnen/je Zeile hinzugügen
            const groupsToRecalculate = modifiedGroups.reduce((acc, group) => {
                if (resubmissionTree) {
                    const subGroups = resubmissionTree.ElementsByOID[group.OID];
                    if (subGroups) {
                        acc = acc.concat(subGroups.filter((sg) => !!sg));
                    }
                } else {
                    acc.push(group);
                }

                return acc;
            }, []);

            return CalculatedCheckpoints.recalculateGroups(groupsToRecalculate)
                .then(() => {
                    IssueView.UpdateRequiredParametersCounter();
                    IssueView.UpdateCurrentIssueParameterCounter();

                    const counter = IssueView.GetFilteredParametersCount();

                    if (counter.Overall === 0 || (View.CurrentView === Enums.View.Scheduling
                        && counter.Locations.hasOwnProperty(Session.CurrentLocation.OID)
                        && counter.Locations[Session.CurrentLocation.OID] === 0)) {
                        setNoCheckpointsFoundInfo();
                    }
                });
        }

        return $.Deferred().resolve();
    }

    function setNoCheckpointsFoundInfo(): void {
        if ($content == null || View.CurrentView === Enums.View.Main) {
            return;
        }

        $content.html(Templates.Parameters.List({
            Parametergroups: [],
            NoContentInfo: i18next.t('Parametergroups.NoContent', {
                LocationTitle: Session.CurrentLocation ? Session.CurrentLocation.Title : i18next.t('Misc.Unknown')
            }),
            ShowElementInfoButton: Session.Settings.ShowElementInfoButtons
        }));
    }

    function getGroupRowFromCache(oid: string, row?: number): DAL.Cache.IParameterListItem {
        return DAL.Cache.ParameterList.GetGroupRow(oid, row, (oid: string, row: number) => {
            if (row) {
                return $content.find(`p[data-groupoid="${oid}"][data-row="${row}"]`);
            } else {
                return $content.find(`p[data-groupoid="${oid}"]`);
            }
        });
    }

    function getTabularGroupRowFromCache(oid: string, row?: number): DAL.Cache.IParameterListItem {
        return DAL.Cache.ParameterList.GetGroupRow(oid, row, (oid: string, row: number) => {
            if (row) {
                return $content.find(`table.sticky-enabled tr.subsample-row[data-groupoid="${oid}"][data-row="${row}"]`);
            } else {
                return $content.find(`tr.subsample-row[data-groupoid="${oid}"]`);
            }
        });
    }

    export function GetParameterRowFromCache(oid: string, row?: number): DAL.Cache.IParameterListItem {
        return DAL.Cache.ParameterList.GetParameterRow(oid, row, (oid: string, row?: number) => {
            if (row) {
                return $content.find(`.checkpoint[data-oid="${oid}"][data-row="${row}"]`);
            } else {
                return $content.find(`.checkpoint[data-oid="${oid}"]`);
            }
        });
    }

    function updateGroupVisibility(group: Model.Elements.Element, isTabular: boolean = false): boolean {
        // check cache for elements
        let anyUpdated = false;

        let visibleRequiredParameters = 0;
        let recordedRequiredParameters = 0;

        let hideGroup = true;

        if (isTabular) {
            for (const tabularGroup of (group.Parametergroups || [])) {
                const tabularGroupRowCache = getTabularGroupRowFromCache(tabularGroup.OID, tabularGroup.Row);
                const tabularGroupIsHidden = !groupIsVisible(tabularGroup);

                if (hideGroup && !tabularGroupIsHidden) {
                    hideGroup = false;
                }

                if (tabularGroupRowCache.hidden == null) {
                    tabularGroupRowCache.hidden = tabularGroupRowCache.$el.css('display') === 'none';
                }

                // Teilprobenzeile in der Tabelle
                if (tabularGroupRowCache.hidden == null || tabularGroupIsHidden !== tabularGroupRowCache.hidden) {
                    tabularGroupRowCache.$el.toggleClass('hidden', tabularGroupIsHidden);
                    tabularGroupRowCache.hidden = tabularGroupIsHidden;
                    anyUpdated = true;
                }

                if (!tabularGroup.Parameters || !tabularGroup.Parameters.length) {
                    continue;
                }

                for (const parameterRow of tabularGroup.Parameters) {
                    const rowCache = GetParameterRowFromCache(parameterRow.OID, parameterRow.Row);
                    const hideParameter = !!(parameterRow.IsHidden || parameterRow.IsFiltered || parameterRow.MissingRequirements);

                    if (hideGroup && !hideParameter) {
                        hideGroup = false;
                    }

                    if (rowCache.hidden == null) {
                        rowCache.hidden = rowCache.$el.css('display') === 'none';
                    }

                    if (rowCache.hidden == null || hideParameter !== rowCache.hidden) {
                        if (tabularGroupIsHidden) {
                            rowCache.$el.removeClass('not-visible');
                            rowCache.$el.addClass('hidden');
                        } else if ((group.Parameters || []).length > 0) {
                            const parameterIndex = Utils.GetIndex(group.Parameters, parameterRow.OID, 'OID');

                            if (parameterIndex >= 0) {
                                const mergedParameter = group.Parameters[parameterIndex];
                                const isParameterHidden = !elementIsVisible(mergedParameter);
                                rowCache.$el.toggleClass('hidden', isParameterHidden);

                                if (parameterRow.IsFiltered) {
                                    rowCache.$el.toggleClass('not-visible', hideParameter);
                                }
                            }
                        }

                        if (rowCache.$el.is(':last-child')) {
                            rowCache.$el.prev().css('border-bottom', hideParameter ? 'none' : '');
                        }

                        rowCache.hidden = hideParameter;
                        anyUpdated = true;
                    }

                    // Wenn nur IsFiltered gesetzt ist, wird der Prüfpunkt trotzdem mitgezählt
                    if (!parameterRow.IsHidden && !parameterRow.MissingRequirements && parameterRow.Required) {
                        visibleRequiredParameters++;

                        if (parameterRow.IsRecorded) {
                            recordedRequiredParameters++;
                        }
                    }
                }
            }
        } else {
            for (const param of (group.Parameters || [])) {
                // check cache for elements
                const rowCache = GetParameterRowFromCache(param.OID, param.Row);
                const hideParameter =
                    !!(param.IsHidden || param.IsFiltered || param.MissingRequirements || !checkRequirements(param));

                if (hideGroup && !hideParameter) {
                    hideGroup = false;
                }

                if (rowCache.hidden == null) {
                    rowCache.hidden = rowCache.$el.css('display') === 'none';
                }
                // check visibility change need
                // null check for elements with initial hidden class
                if (rowCache.hidden == null || hideParameter !== rowCache.hidden) {
                    rowCache.$el.toggleClass('hidden', hideParameter);

                    if (rowCache.$el.is(':last-child')) {
                        rowCache.$el.prev().css('border-bottom', hideParameter ? 'none' : '');
                    }

                    rowCache.hidden = hideParameter;
                    anyUpdated = true;
                }

                // Wenn nur IsFiltered gesetzt ist, wird der Prüfpunkt trotzdem mitgezählt
                if (!param.IsHidden && !param.MissingRequirements && param.Required) {
                    visibleRequiredParameters++;

                    if (param.IsRecorded) {
                        recordedRequiredParameters++;
                    }
                }
            }
        }

        const grpCache = getGroupRowFromCache(group.OID, isTabular ? null : group.Row);

        if (grpCache.hidden == null) {
            grpCache.hidden = grpCache.$el.css('display') === 'none';
        }

        // null check for elements with initial hidden class
        if (grpCache.hidden == null || hideGroup !== grpCache.hidden) {
            grpCache.$el
                .toggleClass('hidden', hideGroup)
                .find('+ ul')
                .toggleClass('hidden', hideGroup);
            grpCache.hidden = hideGroup;
            anyUpdated = true;
        }

        if (!anyUpdated) {
            return false;
        }

        if (visibleRequiredParameters) {
            if (visibleRequiredParameters === recordedRequiredParameters) {
                grpCache.$el
                    .data('requiredparametercount', '')
                    .data('recordedparametercount', '')
                    .removeAttr('data-requiredparametercount')
                    .removeAttr('data-recordedparametercount');
            } else {
                grpCache.$el
                    .data('requiredparametercount', visibleRequiredParameters)
                    .data('recordedparametercount', recordedRequiredParameters)
                    .attr('data-requiredparametercount', visibleRequiredParameters)
                    .attr('data-recordedparametercount', recordedRequiredParameters);
            }
        }

        grpCache.$el.find('.title')
            .html(getGroupCellTitle(group, recordedRequiredParameters, visibleRequiredParameters, isTabular,
                group.Row));

        return true;
    }

    function checkCriterion(parameter: Model.Elements.Element, criteria: Model.Elements.RequirementCriterion): boolean {
        const categoryOID = parameter.LastRecorditem
            ? parameter.LastRecorditem.CategoryOID
            : Utils.Evaluation.Evaluate(<Model.Recorditem>{
                ElementOID: criteria.ElementOID,
                ElementRevisionOID: parameter.RevisionOID
            });

        return categoryOID === criteria.CategoryOID;
    }

    function checkRequirements(element: Model.Elements.Element, elementCache?: Array<Model.Elements.Element>): boolean {
        if (!element || !element.Requirements || !element.Requirements.Criteria) {
            return true;
        }

        switch (element.Requirements.Type) {
            case Enums.ElementRequirementsType.All:
                return element.Requirements.Criteria.every(function(c) {
                    if (c.ElementOID === element.OID) {
                        return true;
                    }

                    const params = GetElementsIndependentOfRow(c.ElementOID, elementCache);

                    if (_requirementsCache[c.ElementOID] == null) {
                        _requirementsCache[c.ElementOID] = {};
                    }

                    if (_requirementsCache[c.ElementOID][element.OID] == null) {
                        _requirementsCache[c.ElementOID][element.OID] = true;
                    }

                    if (!(params || []).length) {
                        return false;
                    }

                    for (let pCnt = 0, pLen = params.length; pCnt < pLen; pCnt++) {
                        const param = params[pCnt];

                        if (!checkCriterion(param, c)) {
                            return false;
                        }
                    }

                    return true;
                });
            case Enums.ElementRequirementsType.None:
                return element.Requirements.Criteria.every(function(c) {
                    if (c.ElementOID === element.OID) {
                        return true;
                    }

                    if (_requirementsCache[c.ElementOID] == null) {
                        _requirementsCache[c.ElementOID] = {};
                    }

                    if (_requirementsCache[c.ElementOID][element.OID] == null) {
                        _requirementsCache[c.ElementOID][element.OID] = true;
                    }

                    const params = GetElementsIndependentOfRow(c.ElementOID, elementCache);
                    if (!(params || []).length) {
                        return false;
                    }

                    for (let pCnt = 0, pLen = params.length; pCnt < pLen; pCnt++) {
                        const param = params[pCnt];

                        if (checkCriterion(param, c)) {
                            return false;
                        }
                    }

                    return true;
                });
            case Enums.ElementRequirementsType.Any:
            default:
                return element.Requirements.Criteria.some(function(c: Model.Elements.RequirementCriterion) {
                    if (c.ElementOID === element.OID) {
                        return true;
                    }

                    if (_requirementsCache[c.ElementOID] == null) {
                        _requirementsCache[c.ElementOID] = {};
                    }

                    if (_requirementsCache[c.ElementOID][element.OID] == null) {
                        _requirementsCache[c.ElementOID][element.OID] = true;
                    }

                    const params = GetElementsIndependentOfRow(c.ElementOID, elementCache);
                    if (!(params || []).length) {
                        return false;
                    }

                    for (let pCnt = 0, pLen = params.length; pCnt < pLen; pCnt++) {
                        const param = params[pCnt];

                        if (checkCriterion(param, c)) {
                            return true;
                        }
                    }

                    return false;
                });
        }
    }

    function parameterIsFilteredByText(element: Model.Elements.Element): boolean {
        if (!element || !filters.SearchText) {
            return false;
        }

        const regEx = new RegExp(Utils.EscapeRegExPattern(Utils.EscapeHTMLEntities(filters.SearchText)), 'i');

        // Suche nach Gruppenname
        if (filters.SearchInGroup && element.Parent) {
            if (regEx.test(element.Parent.Title)) {
                return false;
            }
        }

        // Suche nach Prüfpunkt
        if (filters.SearchInParameter) {
            if (regEx.test(element.Title) ||
                (!!element.Description && regEx.test(element.Description))) {
                return false;
            }
        }

        return true;
    }

    function parameterIsFilteredByKeywords(element: Model.Elements.Element): boolean {
        if (!element || !(filters.Keywords || []).length) {
            return false;
        }

        if (element.Parent && (element.Parent.Properties || [].length)) {
            if (element.Parent.Properties.some((identifier: string) => {
                return filters.Keywords.indexOf(identifier) !== -1;
            })) {
                return false;
            }
        }

        return !(element.Properties || []).some((identifier: string) => {
            return filters.Keywords.indexOf(identifier) !== -1;
        });
    }

    function parameterIsFiltered(element: Model.Elements.Element, applyCategoryFilter: boolean): boolean {
        if (parameterIsFilteredByText(element)) {
            return true;
        }

        if (parameterIsFilteredByKeywords(element)) {
            return true;
        }

        if (!filters.ShowRecordedParameters && Model.Recorditem.IsRecorditemRecorded(element.LastRecorditem)) {
            return true;
        }

        if (!filters.ShowUnrecordedParameters &&
            !element.Required && !Model.Recorditem.IsRecorditemRecorded(element.LastRecorditem)) {
            return true;
        }

        if (!filters.ShowRequiredUnrecordedParameters &&
            element.Required && !Model.Recorditem.IsRecorditemRecorded(element.LastRecorditem)) {
            return true;
        }

        if (applyCategoryFilter) {
            if (!element.LastRecorditem) {
                return true;
            }

            if (!Utils.InArray(filters.ValueCategories, element.LastRecorditem.CategoryOID)) {
                return true;
            }
        }

        return false;
    }

    function parameterIsVisible(element: Model.Elements.Element): boolean {
        if (!checkRequirements(element) ||
            View.CurrentView !== Enums.View.Main && !Utils.InArray(IssueView.GetResubmissionItems(), element.OID)) {
            return false;
        }

        return !element.IsAdhoc ||
            element.Type !== Enums.ElementType.EMailAddresses ||
            Utils.IsEMailCpEnabled();
    }

    function groupIsFiltered(group: Model.Elements.Element): boolean {
        if (!group) {
            return false;
        }

        return group.Parameters.every((param): boolean => {
            return param.IsFiltered;
        });
    }

    function elementIsVisible(element: Model.Elements.Element): boolean {
        if (element.Parameters == null) {
            return false;
        }

        return !element.Parameters.every((parameter): boolean => {
            return parameter.IsHidden || parameter.MissingRequirements || parameter.IsFiltered;
        });
    }

    function groupIsVisible(group: Model.Elements.Element, eligableGroupTypes: Array<Enums.ElementType> = []): boolean {
        if (!elementIsVisible(group)) {
            return false;
        }

        if (eligableGroupTypes != null && eligableGroupTypes.length
            && (!Utils.InArray(eligableGroupTypes, group.Type) || !Utils.InArray([0, 3, 4], group.Attribute))) {
            return false;
        }

        if (View.CurrentView !== Enums.View.Main
            && !Utils.InArray(IssueView.GetResubmissionItems(), group.OID)) {
            return false;
        }

        return checkRequirements(group);
    }

    function sortCommentsByModificationTimestamp(a: Model.Comment, b: Model.Comment): number {
        const ta = (<Date>a.ModificationTimestamp).getTime();
        const tb = (<Date>b.ModificationTimestamp).getTime();

        return ta - tb;
    }

    function prepareParameterRecorditem(parameter: Model.Elements.Element, recorditem: Model.Recorditem): void {
        if (recorditem != null && recorditem.IsDummy) {
            recorditem = null;
        }

        parameter.PrioritizedFile = Utils.GetPrioritizedFile(parameter);
        parameter.LastRecorditem = recorditem;

        if (recorditem) {
            parameter.LastRecorditem = recorditem;
        } else if (View.CurrentView !== Enums.View.Main) {
            parameter.LastRecorditem = null;
        }

        if (!parameter.LastRecorditem) {
            parameter.LastRecorditem = new Model.Recorditem();
            parameter.LastRecorditem.IsDummy = true;
            parameter.LastRecorditem.CategoryOID = Utils.Evaluation.Evaluate(<Model.Recorditem>{
                ElementOID: parameter.OID,
                ElementRevisionOID: parameter.RevisionOID,
                Row: parameter.Row
            });
        }

        if (View.CurrentView === Enums.View.Main) {
            if (parameter.LastRecorditem && parameter.LastRecorditem.ID) {
                parameter.LastRecorditem.IsHistorical = true;
            }
        } else {
            if (parameter.LastRecorditem) {
                parameter.LastRecorditem.IsHistorical = false; // = undefined
            }
        }

        if (parameter.LastRecorditem && (parameter.LastRecorditem.Comments || []).length) {
            parameter.LastRecorditem.Comments.forEach(function(comment) {
                if (typeof comment.CreationTimestamp === 'string') {
                    comment.CreationTimestamp = new Date(comment.CreationTimestamp);
                }

                if (typeof comment.ModificationTimestamp === 'string') {
                    comment.ModificationTimestamp = new Date(comment.ModificationTimestamp);
                }
            });

            parameter.LastRecorditem.Comments.sort(sortCommentsByModificationTimestamp);
        }

        const correctiveActions = [];

        if (parameter.LastRecorditem) {
            let correctiveActionsOfRecorditem;

            if (CorrectiveActionsDictionary && CorrectiveActionsDictionary[parameter.LastRecorditem.ID]) {
                correctiveActionsOfRecorditem = [].concat(CorrectiveActionsDictionary[parameter.LastRecorditem.ID]);
                parameter.LastRecorditem.CorrectiveActions = correctiveActionsOfRecorditem;
            } else if (parameter.LastRecorditem.ID == null && CorrectiveActionsDictByOID[parameter.LastRecorditem.OID]) {
                correctiveActionsOfRecorditem = [].concat(CorrectiveActionsDictByOID[parameter.LastRecorditem.OID]);
                parameter.LastRecorditem.CorrectiveActions = correctiveActionsOfRecorditem;
            }

            for (let ci = 0; ci < (correctiveActionsOfRecorditem || []).length; ++ci) {
                correctiveActions.push({
                    IsIssue: true,
                    Issue: correctiveActionsOfRecorditem[ci]
                });
            }
        }
    }

    function prepareParameters(group: Model.Elements.Element, recorditems: Array<Model.Recorditem> = [], applyCategoryFilter: boolean): {
        Parameters: Array<Model.Elements.Element>,
        ParameterChanged: boolean,
        RequiredParametersCount: number,
        RecordedParametersCount: number,
        HiddenParametersCount: number
    } {
        const result = {
            Parameters: [],
            ParameterChanged: false,
            RequiredParametersCount: 0,
            RecordedParametersCount: 0,
            HiddenParametersCount: 0
        };

        function filterRecorditems(recItem: Model.Recorditem): boolean {
            return recItem.ElementOID === this.OID && ((this.Row == null || isNaN(this.Row))
                ? (recItem.Row == null || isNaN(recItem.Row))
                : recItem.Row === this.Row);
        }

        if (!group) {
            return;
        }

        group.IsAdhoc = View.CurrentView === Enums.View.Main && group.Type !== Enums.ElementType.MasterdataGroup;

        if (!(group.Parameters || []).length) {
            return;
        }

        const groupParameters = group.Parameters || [];

        for (let i = 0; i < groupParameters.length; i++) {
            const param = groupParameters[i];
            let recorditem: Model.Recorditem = null;

            if (View.CurrentView === Enums.View.Main) {
                for (let j = 0; j < recorditems.length; j++) {
                    if (recorditems[j] == null) {
                        continue;
                    }

                    if (param.OID === recorditems[j].ElementOID) {
                        recorditem = recorditems[j];
                    }
                }
            } else {
                const recorditemList = recorditems.filter(filterRecorditems, param);

                if (recorditemList.length) {
                    recorditem = recorditemList.reduce(
                        (prev: Model.Recorditem, current: Model.Recorditem) => prev.Revision > current.Revision ? prev : current
                    );
                }
            }

            prepareParameterRecorditem(param, recorditem);
        }

        for (let i = 0; i < groupParameters.length; i++) {
            const param = groupParameters[i];

            param.IsAdhoc = View.CurrentView === Enums.View.Main && group.Type !== Enums.ElementType.MasterdataGroup;

            result.ParameterChanged = true;
            param.MissingRequirements = !checkRequirements(param);
            param.IsHidden = !parameterIsVisible(param);
            param.IsFiltered = parameterIsFiltered(param, applyCategoryFilter);

            param.ShowColorIndicator =
                param.LastRecorditem
                && !!param.LastRecorditem.CategoryOID
                && (!param.LastRecorditem.IsDummy || applyCategoryFilter);

            param.IsRecorded = Model.Recorditem.IsRecorditemRecorded(param.LastRecorditem);
            param.CreateToolbar = Session.Settings.ShowCheckpointToolbar && (
                Session.IsSmartDeviceApplication && param.Type === Enums.ElementType.IndividualData ||
                param.IsRecorded
            ) && !param.DisableInAppEditing;;

            if (param.IsHidden || param.MissingRequirements) {
                result.HiddenParametersCount++;
            } else if (param.Required) {
                result.RequiredParametersCount++;

                if (param.LastRecorditem && !param.LastRecorditem.IsDummy) {
                    result.RecordedParametersCount++;
                }
            }

            if (resubmissionTree) {
                const element = resubmissionTree.GetElementByRevisionOID(param.RevisionOID);
                if (element) {

                    if (element.Row == null || element.Row == param.Row) {
                        element.IsHidden = param.IsHidden;
                        element.MissingRequirements = param.MissingRequirements;
                        element.IsFiltered = param.IsFiltered;
                    }
                }
            }

            result.Parameters.push(param);
        }

        // Additional Workflow Information
        if (View.CurrentView !== Enums.View.Main) {
            const currentIssue = IssueView.GetCurrentIssue();
            if (currentIssue.AdditionalData && currentIssue.AdditionalData.Associations) {
                for (let i = 0; i < groupParameters.length; i++) {
                    const param = groupParameters[i];
                    const additionalAssociations = currentIssue.AdditionalData.Associations[param.OID];

                    if (!additionalAssociations || !additionalAssociations.length) {
                        continue;
                    }

                    // überprüfen ob Daten in additionalCorrectiveIssues vorhanden sind
                    if (!Object.keys(additionalCorrectiveIssues || {}).length) {
                        continue;
                    }

                    additionalAssociations.sort((a, b) => b.IssueID - a.IssueID);
                    param["AdditionalWorkflowInformation"] = '';

                    for (let i = 0; i < additionalAssociations.length; i++) {
                        const currentAssociation = additionalAssociations[i];
                        const issue = additionalCorrectiveIssues[currentAssociation.IssueID];

                        if (!issue) {
                            continue;
                        }

                        if (param["AdditionalWorkflowInformation"].length) {
                            param["AdditionalWorkflowInformation"] += '\n\n';
                        }

                        // Bezeichnung zusammensetzen
                        let title: string;
                        if (issue.Title) {
                            title = issue.Title;
                        } else {
                            let type: string;
                            switch (issue.Type) {
                                case Enums.IssueType.Disturbance:
                                    type = 'S';
                                    break;
                                case Enums.IssueType.Note:
                                    type = 'N';
                                    break;
                                default:
                                    type = 'A';
                            }

                            title = `${i18next.t('Misc.Untitled')} (${type}${issue.ID}.${issue.Revision})`;
                        }

                        param["AdditionalWorkflowInformation"] += i18next.t('CorrectiveActions.ActionState.TakenOver') + ': ' + title;
                    }
                }
            }
        }

        return result;
    }

    function getPreparedGroups(eligableGroupTypes, recorditems?: Array<Model.Recorditem>): Array<Model.Elements.Element> {
        const applyCategoryFilter = (filters.ValueCategories || []).length > 0;

        if (!(Session.CurrentLocation.Parametergroups || []).length) {
            return [];
        }

        const groups = [];

        Session.CurrentLocation.Parametergroups.forEach(function(group: any) {
            if (!(group.Parameters || []).length) {
                return;
            }

            const preparedParametersResult = prepareParameters(group, recorditems, applyCategoryFilter);

            group.Parameters = preparedParametersResult.Parameters;

            group.IsLocked = false; // = undefined
            group.IsRecordingLocked = false; // = undefined

            let updateParameterFunction: (param: Model.Elements.Element) => void = null;

            group.MissingRequirements = false;
            group.IsHidden = false;
            group.IsFiltered = false;

            if (!checkRequirements(group)) {
                group.MissingRequirements = true;

                updateParameterFunction = (param) => param.MissingRequirements = true;
            } else if (groupIsFiltered(group)) {
                group.IsFiltered = true;

                updateParameterFunction = (param) => param.IsFiltered = true;
            } else if (!groupIsVisible(group, eligableGroupTypes)) {
                group.IsHidden = true;

                updateParameterFunction = (param) => param.IsHidden = true;
            }

            if (updateParameterFunction != null) {
                group.Parameters.forEach(updateParameterFunction);
            }

            if (+preparedParametersResult.RequiredParametersCount
                && preparedParametersResult.RequiredParametersCount > preparedParametersResult.RecordedParametersCount) {
                group.RequiredParameterCount = preparedParametersResult.RequiredParametersCount;
                group.RecordedParameterCount = +preparedParametersResult.RecordedParametersCount;
            } else {
                group.RequiredParameterCount = false; // = undefined
                group.RecordedParameterCount = false; // = undefined
            }

            groups.push(group);
        });

        groups.sort(function(a, b) {
            return a.OID === b.OID
                ? (a.Row || 0) - (b.Row || 0)
                : Utils.SortByPosition(a, b);
        });

        return groups;
    }

    export function GetFormulaGroups(cloneResubTree: boolean = true): Array<Model.Elements.Element> {
        // Gruppen für Pläne/Begehung separat ermitteln, da die OE individuell geändert werden kann
        if (View.CurrentView === Enums.View.Scheduling) {
            return $.map(Session.CurrentLocation.Parametergroups, (group: Model.Elements.Element) => resubmissionTree.ElementsByOID[group.OID]);
        }

        return (<Model.Elements.Element>(ParameterList.GetResubmissionTree(cloneResubTree) || {})).Parametergroups || Session.CurrentLocation.Parametergroups;
    }

    function getPreparedResubGroups(recorditems: Array<Model.Recorditem>, suppressTabularSubsample: boolean = false): Array<Model.Elements.Element> {
        const applyCategoryFilter: boolean = (filters.ValueCategories || []).length > 0;
        const mergedGroupRows: Dictionary<Model.Elements.Element> = {};
        const mergedParameterRows: Dictionary<Model.Elements.Element> = {}; // wird nur für tabellarische Teilproben verwendet
        let groups: Model.Elements.Element[];

        if (resubmissionTree && (View.CurrentView == Enums.View.Form || View.CurrentView == Enums.View.FormBatchEdit || View.CurrentView == Enums.View.Inspection)) {
            groups = resubmissionTree.Form.Parametergroups;
        } else if (View.CurrentView === Enums.View.Scheduling
            && (Session.CurrentLocation.Parametergroups || []).length
            && Utils.HasProperties(resubmissionTree.DistinctRevisions)) {
            const currentLocationGroupIdentifiers = $.map(Session.CurrentLocation.Parametergroups, (group: Model.Elements.Element) => group.OID);

            if (currentLocationGroupIdentifiers.length) {
                groups = $.map(resubmissionTree.DistinctRevisions, function(element: Model.Elements.Element) {
                    if (Utils.InArray(currentLocationGroupIdentifiers, element.OID)) {
                        return element;
                    }
                });
            }
        }

        if (!(groups || []).length) {
            return;
        }

        groups.sort(Utils.SortGroup);

        const elementRevisionsFromTree = resubmissionTree.Revisions || {};
        for (let gi = 0; gi < groups.length; gi++) {
            const group = groups[gi];

            if (!(group.Parameters || []).length) {
                group.IsHidden = true;
                group.IsFiltered = false;
                group.MissingRequirements = false;

                if (elementRevisionsFromTree.hasOwnProperty(group.RevisionOID)) {
                    const elementRevisionInstances = elementRevisionsFromTree[group.RevisionOID];

                    for (let ri = 0; ri < elementRevisionInstances.length; ri++) {
                        const elementRevision = elementRevisionInstances[ri];

                        if (!elementRevision) {
                            // Leeres erstes Element bei Teilproben ignorieren
                            continue;
                        }

                        if (elementRevision.Row == null || elementRevision.Row == group.Row) {
                            elementRevision.IsHidden = group.IsHidden;
                            elementRevision.IsFiltered = group.IsFiltered;
                            elementRevision.MissingRequirements = group.MissingRequirements;
                        }
                    }
                }

                continue;
            }

            if (group.IsRecordingLockable) {
                group.IsRecordingLocked = IssueView.IsRecordingLocked(group);
            } else {
                delete group.IsRecordingLocked;
            }

            group.Parameters.sort(Utils.SortByPosition);

            const preparedParametersResult = prepareParameters(group, recorditems, applyCategoryFilter);
            const peekNextGroup = groups.length - 1 > gi ? groups[gi + 1] : null;
            const nextGroupRow = peekNextGroup && peekNextGroup.OID === group.OID ? peekNextGroup.Row : 0;

            if (Utils.UserHasRight(Session.User.OID, Enums.Rights.DeleteSubsample) && !group.IsRecordingLocked &&
                group.Row > 1 && (group.MinSubsampleCount == null || group.MinSubsampleCount < group.Row) &&
                IssueView.GetRecorditemsForRow(group.OID, group.Row).length === 0 && nextGroupRow < group.Row) {
                group.AdditionalSettings = group.AdditionalSettings || {};
                group.AdditionalSettings.SubsampleCanBeDeleted = true;
            } else if (group.AdditionalSettings && group.AdditionalSettings.SubsampleCanBeDeleted) {
                group.AdditionalSettings.SubsampleCanBeDeleted = false;
            }

            group.Parameters = preparedParametersResult.Parameters;

            group.MissingRequirements = false;
            group.IsHidden = false;
            group.IsFiltered = false;

            let updateParametersFunction: (param: Model.Elements.Element) => void = null;

            if (!checkRequirements(group)) {
                group.MissingRequirements = true;
                updateParametersFunction = (param) => param.MissingRequirements = true;
            } else if (groupIsFiltered(group)) {
                group.IsFiltered = true;
                updateParametersFunction = (param) => param.IsFiltered = true;
            } else if (!groupIsVisible(group, null)) {
                group.IsHidden = true;
                updateParametersFunction = (param) => param.IsHidden = true;
            }

            if (updateParametersFunction != null) {
                group.Parameters.forEach(p => {
                    updateParametersFunction(p);
                });
            }

            group.RequiredParameterCount = preparedParametersResult.RequiredParametersCount || 0;
            group.RecordedParameterCount = preparedParametersResult.RecordedParametersCount || 0;

            if (suppressTabularSubsample || !group.Row) {
                mergedGroupRows[group.OID + (group.Row || '')] = group;
                continue;
            }

            const showTabularSubsample =
                _tabularSubsampleToggleState[group.OID] === true ||
                (_tabularSubsampleToggleState[group.OID] == null && Session.Settings.ShowTabularSubsampleByDefault);

            if (!showTabularSubsample) {
                mergedGroupRows[group.OID + (group.Row || '')] = group;

                if (group.Row === group.MaxSubsampleCount) {
                    group.SubsampleLimitReached = true;

                    for (let i = 1; i < group.Row; i++) {
                        if (mergedGroupRows[group.OID + i] != null) {
                            mergedGroupRows[group.OID + i].SubsampleLimitReached = true;
                        }
                    }
                }
                continue;
            }

            let mergedGroupRow = mergedGroupRows[group.OID];

            if (mergedGroupRow == null) {
                mergedGroupRow = Utils.CloneElement(group);
                mergedGroupRow.Row = null;
                mergedGroupRow.IsTabularSubsample = true;
                mergedGroupRow.Parameters = [];
                mergedGroupRow.Parametergroups = [];
                mergedGroupRow.ForceAdvancedEditor = _forceAdvancedEditor[mergedGroupRow.OID];
                mergedGroupRow.ShowActionColumn = group.IsRecordingLockable || (group.AdditionalSettings || {}).SubsampleCanBeDeleted;

                mergedGroupRows[group.OID] = mergedGroupRow;
            }

            for (let pCnt = 0; pCnt < group.Parameters.length; pCnt++) {
                const param = group.Parameters[pCnt];

                if (!showTabularSubsample) {
                    mergedGroupRow.Parameters.push(param);
                    continue;
                }

                let mergedParameter = mergedParameterRows[param.OID];

                if (mergedParameter == null) {
                    mergedParameter = Utils.CloneElement(param);
                    mergedParameter.Row = null;
                    mergedParameter.IsTabularSubsample = true;
                    mergedParameter.Parameters = [param];

                    mergedParameterRows[param.OID] = mergedParameter;
                    const indexOfParameter = Utils.GetIndex(mergedGroupRow.Parameters, mergedParameter.OID, 'OID');

                    if (indexOfParameter < 0) {
                        mergedGroupRow.Parameters.push(mergedParameter);
                    }
                } else {
                    mergedParameter.Parameters.push(param);
                }
            }

            mergedGroupRow.Parametergroups = mergedGroupRow.Parametergroups || [];
            mergedGroupRow.Parametergroups.push(group);
            mergedGroupRow.Parametergroups.sort((a, b) => a.Row - b.Row);

            for (let j = 0; j < (mergedGroupRow.Parameters || []).length; j++) {
                const mergedParam = mergedGroupRow.Parameters[j];

                mergedParam.IsFiltered = groupIsFiltered(mergedParam);
                mergedParam.IsHidden = !groupIsVisible(mergedParam);
                mergedParam.MissingRequirements = !checkRequirements(mergedParam);
            }

            if (mergedGroupRow.Parametergroups.length === mergedGroupRow.MaxSubsampleCount) {
                mergedGroupRow.SubsampleLimitReached = true;

                for (let i = 0; i < mergedGroupRow.Parametergroups.length; i++) {
                    mergedGroupRow.Parametergroups[i].SubsampleLimitReached = true;
                }
            }
        }

        // Wird nicht am ResubTree gespeichert, da nur für die Anzeige der Daten notwendig.
        const mergedGroupRowsArray: Model.Elements.Element[] = $.map(mergedGroupRows, (a) => a);
        mergedGroupRowsArray.sort(Utils.SortGroup);

        // Alle Tabellarische Teilproben-Prüfgruppen durchlaufen und prüfen ob die mergedGroupRow angezeigt werden soll
        for (let i = 0; i < mergedGroupRowsArray.length; i++) {
            const groupRow = mergedGroupRowsArray[i];

            if ((groupRow.Parametergroups || []).length === 0 || !groupRow.IsTabularSubsample) {
                continue;
            }

            groupRow.IsFiltered = groupIsFiltered(groupRow);
            groupRow.IsHidden = !groupIsVisible(groupRow);
            groupRow.MissingRequirements = !checkRequirements(groupRow);

            for (let j = 0; j < groupRow.Parametergroups.length; j++) {
                const parameterGroup = groupRow.Parametergroups[j];

                if (parameterGroup.IsFiltered || parameterGroup.IsHidden || parameterGroup.MissingRequirements) {
                    continue;
                }

                // Falls eine Teilprobe sichtbar ist, wird die Prüfgruppe für die tabellarische Ansicht nicht ausgeblendet
                groupRow.IsFiltered = false;
                groupRow.IsHidden = false;
                groupRow.MissingRequirements = false;
                break;
            }
        }

        return mergedGroupRowsArray;
    }

    function prepareParameterGroup(group: Model.Elements.Element, recorditems: Array<Model.Recorditem>, applyCategoryFilter: boolean, requirementsCache?: any): void {
        const preparedParametersResult = prepareParameters(group, recorditems, applyCategoryFilter);

        const groupParameters = group.Parameters;

        if (!(groupParameters || []).length) {
            group.IsHidden = true;
            group.MissingRequirements = false;
            group.IsFiltered = false;

            return;
        }

        groupParameters.sort(Utils.SortByPosition);

        if (preparedParametersResult.ParameterChanged || requirementsCache == null || requirementsCache[group.OID]) {
            const parameterCache: any = DAL.Cache.ParameterList || {};
            const groups: any[] = parameterCache.PreparedGroups || [];

            group.MissingRequirements = false;
            group.IsFiltered = false;
            group.IsHidden = false;

            let updateFunction: (elem: Model.Elements.Element) => void = null;

            if (!checkRequirements(group)) {
                group.MissingRequirements = true;
                updateFunction = (elem) => elem.MissingRequirements = true;
            } else if (groupIsFiltered(group)) {
                group.IsFiltered = true;
                updateFunction = (elem) => elem.IsFiltered = true;
            } else if (!groupIsVisible(group)) {
                group.IsHidden = true;
                updateFunction = (elem) => elem.IsHidden = true;
            }

            if (updateFunction != null) {
                for (let gr of groups) {
                    if (gr.OID === group.OID && ((gr.IsTabularSubsample && group.Row === 1) || gr.Row === group.Row)) {
                        updateFunction(gr);
                    }
                }

                for (let param of groupParameters) {
                    updateFunction(param);
                }
            }
        }

        if (+preparedParametersResult.RequiredParametersCount
            && preparedParametersResult.RequiredParametersCount > preparedParametersResult.RecordedParametersCount) {
            group.RequiredParameterCount = preparedParametersResult.RequiredParametersCount;
            group.RecordedParameterCount = +preparedParametersResult.RecordedParametersCount;
        } else {
            group.RequiredParameterCount = null; // = undefined
            group.RecordedParameterCount = null; // = undefined
        }
    }

    export function PrepareResubmissionTree(recorditems: Array<Model.Recorditem>, locationOID?: string, changedRecorditem?: Model.Recorditem): Deferred {
        const applyCategoryFilter = (filters.ValueCategories || []).length > 0;

        if (!resubmissionTree || !changedRecorditem && (_resubTreePrepared || locationOID && locationOID === _lastResubTreeLocationOID)) {
            return;
        }

        let requirementsCache: Dictionary<boolean>;

        if (changedRecorditem) {
            requirementsCache = _requirementsCache[changedRecorditem.ElementOID];
        }

        if (_resubTreePrepared && changedRecorditem) {
            const changedParameter = GetParameter(changedRecorditem.ElementOID, changedRecorditem.Row);
            let parameterGroupOID: string;

            if (changedParameter) {
                const group = changedParameter.Parent || GetElement(changedParameter.ParentOID, changedParameter.Row, true);

                if (group) {
                    parameterGroupOID = group.OID;
                    prepareParameterGroup(group, recorditems, applyCategoryFilter, requirementsCache);
                }
            }

            if (Utils.HasProperties(requirementsCache)) {
                const alreadyChecked = {};

                for (let oid in requirementsCache) {
                    if (!requirementsCache.hasOwnProperty(oid) || alreadyChecked[oid] || (parameterGroupOID === oid && changedParameter)) {
                        continue;
                    }

                    const elements = GetElementsIndependentOfRow(oid);
                    if (!(elements || []).length) {
                        continue;
                    }

                    for (let gCnt = 0, gLen = elements.length; gCnt < gLen; gCnt++) {
                        let element = elements[gCnt];

                        if (!Utils.InArray(Enums.asArray(Enums.ElementType.Parametergroups), element.Type)) {
                            element = element.Parent
                        }

                        if (element) {
                            prepareParameterGroup(element, recorditems, applyCategoryFilter, requirementsCache);

                            if (!alreadyChecked[element.OID] && (element.Parameters || []).length) {
                                for (let pCnt = 0, pLen = element.Parameters.length; pCnt < pLen; pCnt++) {
                                    alreadyChecked[element.Parameters[pCnt].OID] = true;
                                }
                            }

                            alreadyChecked[element.OID] = true;
                        }
                    }
                }
            }
        } else {
            (function traverse(location) {
                if ((!locationOID || location.OID === locationOID) && (location.Parametergroups || []).length) {
                    location.Parametergroups.sort(Utils.SortByPosition);

                    const locationParametergroups = location.Parametergroups;
                    for (let i = 0; i < locationParametergroups.length; i++) {
                        const group = locationParametergroups[i];

                        prepareParameterGroup(group, recorditems, applyCategoryFilter, requirementsCache);
                    }
                }

                if (location.Children != null) {
                    for (let i = 0; i < location.Children.length; i++) {
                        traverse(location.Children[i]);
                    }
                }
            })(resubmissionTree.Root || resubmissionTree.Form);
        }

        _resubTreePrepared = true;
        _lastResubTreeLocationOID = locationOID;

        return updateElementVisibility();
    }

    function getVisibleElements(element: Model.Elements.Element): boolean {
        return !element.IsHidden && !element.IsFiltered && !element.MissingRequirements;
    }

    function renderContent(eligableGroupTypes: Array<Enums.ElementType>, recorditems?: Array<Model.Recorditem>): void {
        const groups: Array<Model.Elements.Element> = getPreparedGroups(eligableGroupTypes, recorditems) || [];

        _eligableGroupTypes = eligableGroupTypes;

        if ((groups || []).length === 1) {
            groups[0].IsCollapsed = false;
        }

        // reset DOM element cache
        DAL.Cache.ParameterList.Reset();

        const issue = IssueView.GetCurrentIssue();

        $content.html(Templates.Parameters.List({
            Parametergroups: groups,
            VisibleGroupsCount: groups.filter(getVisibleElements).length,
            NoContentInfo: i18next.t('Parametergroups.NoContent'),
            Filters: AreFiltersActive(),
            ShowFiles: Session.Settings.ShowPicturesInIssueReports,
            IssueIsLocked: issue && issue.IsLocked,
            ShowElementInfoButton: Session.Settings.ShowElementInfoButtons
        }));

        $(window).scrollTop(0);

        bindEvents();
    }

    function getSimplifiedView(issue: Model.Issues.Issue, groups: Array<Model.Elements.Element>): string {
        if ((groups || []).length === 1) {
            groups[0].IsCollapsed = false;
        }

        return Templates.Parameters.Review({
            Issue: issue,
            Parametergroups: groups,
            VisibleGroupsCount: groups.filter(getVisibleElements).length,
            NoContentInfo: i18next.t('Parametergroups.NoContent', {
                LocationTitle: Session.CurrentLocation ? Session.CurrentLocation.Title : i18next.t('Misc.Unknown')
            }),
            Filters: !!(filters.ValueCategories || []).length,
            ShowFiles: Session.Settings.ShowPicturesInIssueReports,
            AnySelectable: groups.some(e => e.IsSelectable),
            ShowElementInfoButton: Session.Settings.ShowElementInfoButtons
        });
    }

    function renderResubContent(): void {
        const issue = IssueView.GetCurrentIssue();

        if (!issue) {
            return;
        }

        const groups = getPreparedResubGroups(issue.Recorditems) || [];

        if (!groups || groups.length < 1) {
            setNoCheckpointsFoundInfo();
            return;
        }

        if (groups.length === 1) {
            groups[0].IsCollapsed = false;
        }

        // reset DOM element cache
        DAL.Cache.ParameterList.Reset();

        const availableRights = Utils.GetActiveUserRights(Session.User.OID, true, Session.CurrentLocation) || {};

        $content.html(Templates.Parameters.List({
            Parametergroups: groups,
            VisibleGroupsCount: groups.filter(getVisibleElements).length,
            NoContentInfo: i18next.t('Parametergroups.NoContent', {
                LocationTitle: Session.CurrentLocation ? Session.CurrentLocation.Title : i18next.t('Misc.Unknown')
            }),
            Filters: AreFiltersActive(),
            ShowFiles: Session.Settings.ShowPicturesInIssueReports,
            Rights: {
                CanCreateFiles: availableRights.hasOwnProperty(Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint),
                CanCreateComments: availableRights.hasOwnProperty(Enums.Rights.Comments_CreateAndModifyCommentsOnCheckpoint),
                CanCreateCorrectiveActions: availableRights.hasOwnProperty(Enums.Rights.Comments_CreateAndModifyActionIssue)
            },
            IssueIsLocked: issue && issue.IsLocked,
            ShowElementInfoButton: Session.Settings.ShowElementInfoButtons
        }));

        const $lastCheckpoints = $content.find('.parameterList > li:last-child.hidden');

        if (Utils.IsSet($lastCheckpoints)) {
            for (let i = 0; i < $lastCheckpoints.length; i++) {
                $lastCheckpoints.eq(i).prev().css('border-bottom', 'none')
            }
        }

        $(window).scrollTop(0);

        bindEvents();

        const options: any = {
            DecoupleHorizontalScrollbar: !Session.IsSmartDeviceApplication
        };

        if (!Session.Settings.TabularSubsampleConsistentWidth) {
            options.CalculateColWidth = calculateScrollableTableColumnWidth;
        }

        $content.find('.sticky-table table').each(function() {
            Utils.MakeTableScrollable($(this), $('#content'), options);
        });

        resizeMarksAndScrollableTables();

        if (!_isRebuildingTree
            && !IssueView.GetUnfinishedParametersCount()
            && View.CurrentView !== Enums.View.Inspection) {
            IssueView.TrySettingStandardFollowState();
        }

        if (issue.Type === Enums.IssueType.Scheduling) {
            openNextAvailableCheckpointAfterRoomChanged(groups);
            IssueView.RecreateSidebarIndex(groups);
        }

        _isRebuildingTree = false;
    }

    function resizeMarksAndScrollableTables(): void {
        window.setTimeout(function() {
            $content.find('.groupRow:not(.collapsed) + ul .sticky-enabled').trigger('resize');
            RepositionMarks();
        }, 50);
    }

    function calculateScrollableTableColumnWidth($originTable): Dictionary<number> {
        if ($originTable.width() <= $originTable.parent().width()) {
            return null;
        }

        const $columns = $originTable.find('colgroup col');
        const $parameterColumns = $originTable.find('thead, tbody');
        const columnWidths = {};

        for (let i = 0; i < $columns.length; i++) {
            const $col = $columns.eq(i);

            if ($col.hasClass('fixed-column-width')) {
                continue;
            }

            let $parameterCol = $parameterColumns.find(`tr td:nth-child(${i + 1})`);
            let $firstValueCell = $parameterColumns.find(`tr:first-child td:nth-child(${i + 1})`);

            if ($firstValueCell.data('type') != '111') {
                $firstValueCell = $firstValueCell.find('.value');
                $parameterCol = $parameterCol.find('.value');
            }

            let padding = $firstValueCell.parentsUntil('.subsample-row, .groupRow')
                .add($firstValueCell.parents('.subsample-row, .groupRow'))
                .add($firstValueCell)
                .toArray()
                .reduce((acc: number, c: Node) => {
                    //summs the paddings and margins for total width calculation
                    return acc + Math.max(0, $(c).outerWidth() - $(c).width())
                }, 0);

            if (isNaN(padding) || !isFinite(padding)) {
                padding = 0;
            }

            let originalWidth = parseInt($col.attr('data-originalwidth'), 10);

            if (isNaN(originalWidth) || !isFinite(originalWidth) || originalWidth === 0) {
                originalWidth = parseInt($col.css('max-width'), 10);
            }

            $col.width(originalWidth).data('width', originalWidth).attr('data-width', originalWidth);

            let maxWidthValue = $parameterCol.toArray().reduce((acc: number, c: Node) => {
                const oW = $(c).outerWidth(true);
                return acc > oW ? acc : oW;
            }, 0);

            if (isNaN(maxWidthValue) || !isFinite(maxWidthValue) || maxWidthValue === 0) {
                columnWidths[i] = parseInt($col.data('width'), 10);
                continue;
            }

            //+1 wegen dem nicht verhinderbaren Abrunden bei < ,5
            padding += 1;

            columnWidths[i] = Math.max(maxWidthValue + padding, 50 + padding);
        }

        return columnWidths;
    }

    export function RefreshView(): void {
        if (View.CurrentView === Enums.View.Scheduling &&
            Session.Mode === Enums.Mode.Menu) {
            Menu.ShowBackButton();
        }

        const types = _arguments[0];
        const elements = _arguments[1];
        const fnAfterResubmissionTreeBuilt = _arguments[2];

        if (!!_customMenuID) {
            ParameterList.ShowByMenu(types, _customMenuID, true);
            return;
        }

        ParameterList.Show(types, elements, fnAfterResubmissionTreeBuilt, true, _tabularSubsampleToggleState, _forceAdvancedEditor)
            .then(() => {
                IssueView.UpdateRequiredParametersCounter();
                IssueView.UpdateCurrentIssueParameterCounter(true);  // skipResubTreeUpdate kann auf true gesetzt werden, da dies bereits in UpdateRequiredParametersCounter passiert
            });
    }

    export function SetFilterState(): void {
        const $filterButton = $('.btn-issue-filters');

        if (ParameterList.AreFiltersActive()) {
            $filterButton.addClass('is-active');
        } else {
            $filterButton.removeClass('is-active');
        }

        IssueView.UpdateFilteredParameterCounter();
    }

    function showFilterSelectionWindow(): void {
        const categories = getCategoriesForFilter();

        new Utils.CheckpointFilterWindow(filters, onAfterFiltersSaved, categories).Show();
    }

    function onAfterFiltersSaved(newFilters: Model.Parameters.Filter): void {
        filters = newFilters;
        _getFilterFromCache = false;
        _customMenuID = null;

        DAL.Cache.Filters.Reset();
        RefreshView();
    }

    function preloadRequiredMasterdata(elements: Model.Elements.Element[]): Deferred {
        if (!elements || !elements.length) {
            return $.Deferred().resolve();
        }

        const totalRequiredOUs = new Utils.HashSet();

        for (const el of elements) {
            const issueTitleFormula = el.Type === Enums.ElementType.Form ? el.IssueTitleFormula : null;
            if (issueTitleFormula && /MasterdataValue/i.test(issueTitleFormula)) {
                const tokens = window.Formula.tokenize(issueTitleFormula);
                totalRequiredOUs.putRange(CalculatedCheckpoints.getRequiredOE(tokens, el));
            }

            const paramFormula = el.Formula;
            if (paramFormula && /MasterdataValue/i.test(paramFormula)) {
                const tokens = window.Formula.tokenize(paramFormula);
                totalRequiredOUs.putRange(CalculatedCheckpoints.getRequiredOE(tokens, el));
            }

            if (el.Files && el.Files.length) {
                for (const file of el.Files) {
                    const fileItem = DAL.Files.GetByOID(file.OID);
                    if (!fileItem || !fileItem.URL) {
                        continue;
                    }

                    if (/MasterdataValue/i.test(fileItem.URL)) {
                        const tokens = window.Formula.tokenize(fileItem.URL);
                        totalRequiredOUs.putRange(CalculatedCheckpoints.getRequiredOE(tokens, el));
                    }
                }
            }
        }

        if (!totalRequiredOUs.size()) {
            // keine Stammdaten erforderlich für Formeln
            return $.Deferred().resolve();
        }

        // benötigte Stammdaten nachladen
        let masterdataDeferred = $.Deferred().resolve();

        // alle benötigten Daten als Chain laden
        const requiredOUArray = totalRequiredOUs.toArray();
        for (const ouOID of requiredOUArray) {
            const currentLocation = DAL.Elements.GetByOID(ouOID);
            masterdataDeferred = masterdataDeferred
                .then(() => DAL.Sync.PreloadElementCheckpoints(ouOID))
                .then(() => getParametersWithRecorditems(currentLocation));
        }

        return masterdataDeferred;
    }

    function createCorrectiveActionsDictionary(): Deferred {
        const currentIssue = IssueView.GetCurrentIssue();
        if (!currentIssue) {
            return $.Deferred().reject();
        }

        // load associated still (open) Issues
        const loadDeferreds: Deferred[] = [];
        if (currentIssue.AdditionalData && currentIssue.AdditionalData.Associations) {
            additionalCorrectiveIssues = {};
            for (let key in currentIssue.AdditionalData.Associations) {
                const additionalAssociations = currentIssue.AdditionalData.Associations[key];
                for (let i = 0; i < additionalAssociations.length; i++) {
                    const currentAssociation = additionalAssociations[i];
                    const issueLoading = DAL.Issues.GetByID(currentAssociation.IssueID)
                        .then((issue: Model.Issues.IssueType) => {
                            if (issue) {
                                additionalCorrectiveIssues[issue.ID] = issue;
                            }
                        }, (xhr) => {
                            if (xhr && xhr.status == 404) {
                                // proceed on Not found Error
                                return $.Deferred().resolve();
                            }
                        });
                    loadDeferreds.push(issueLoading);
                }
            }
        }

        const associationsLoading = $.when.apply($, loadDeferreds)
            .then(null, () => $.Deferred().resolve());

        if (currentIssue.ID === 0) {
            return associationsLoading
                .then(() => $.Deferred().reject());
        }

        CorrectiveActionsDictionary = {};

        return associationsLoading
            .then(() => DAL.Issues.GetChildrenOfIssue(currentIssue, true))
            .then(function(issues: Model.Issues.IssueType[]) {
                const iLen = issues.length;
                for (let iCnt = 0; iCnt < iLen; iCnt++) {
                    const issue = issues[iCnt];

                    if (!issue.AssignedRecorditemID) {
                        continue;
                    }

                    if (issue.AssignedRecorditemID && CorrectiveActionsDictionary[issue.AssignedRecorditemID] == null) {
                        CorrectiveActionsDictionary[issue.AssignedRecorditemID] = [];
                    }

                    if (issue.AssignedRecorditemID) {
                        CorrectiveActionsDictionary[issue.AssignedRecorditemID].push(issue);
                    }
                }
            });
    }

    function onAfterHistoricalRecorditemsLoaded(types: Array<Enums.ElementType>, disabledPrecalculation: boolean = false, recorditems?: Array<Model.Recorditem>): Deferred {
        Utils.Spinner.Lock();

        renderContent(types, recorditems);

        Utils.Spinner.Unlock();
        Utils.Spinner.Hide();

        CalculatedCheckpoints.updateParameterDependencies();

        if (!disabledPrecalculation) {
            return CalculatedCheckpoints.precalculateFormulaValues();
        }

        return $.Deferred().resolve();
    }

    export function QuickFilterUnrecorded() {
        if (filters &&
            filters.ShowRecordedParameters === false &&
            filters.ShowUnrecordedParameters === false &&
            filters.ShowRequiredUnrecordedParameters === true &&
            !(filters.ValueCategories || []).length &&
            !(filters.Keywords || []).length &&
            (!filters.SearchText || filters.SearchText === '')) {
            // Filter sind bereist gesetzt
            return;
        }

        // Filter auf nicht erfasste, benötigte Prüfpunkte setzen
        const newFilters = new Model.Parameters.Filter();
        newFilters.ShowRecordedParameters = false;
        newFilters.ShowUnrecordedParameters = false;
        newFilters.ShowRequiredUnrecordedParameters = true;

        // neuen Filter anwenden
        onAfterFiltersSaved(newFilters);
    }

    export function Show(types: Array<Enums.ElementType>, elements?: Array<Model.Elements.Element>,
        afterResubmissionTreeBuilt?: OnAfterResubTreeBuildFunc, disablePrecalculation?: boolean,
        tabularTable: Dictionary<boolean> = {}, forceAdvancedEditor: Dictionary<boolean> = {}): Deferred {
        Utils.SetMode(View.CurrentView === Enums.View.Inspection ? Enums.Mode.CoveringPage : Enums.Mode.Parameters);

        // reset DOM element cache
        DAL.Cache.ParameterList.Reset();

        if (_getFilterFromCache) {
            filters = DAL.Cache.Filters.GetSystemParameterListFilter() || new Model.Parameters.Filter();
            _getFilterFromCache = false;
        }

        _customMenuID = null;
        _requirementsCache = {};
        _resubTreePrepared = false;
        _lastResubTreeLocationOID = null;
        _arguments = arguments;
        _tabularSubsampleToggleState = tabularTable;
        _forceAdvancedEditor = forceAdvancedEditor;
        $content = Utils.GetContentContainer();
        let deferred: Deferred = $.Deferred();

        if (View.CurrentView === Enums.View.Main) {
            if (Utils.Spinner.IsVisible()) {
                Utils.Spinner.UpdateText(i18next.t('RecorditemEditor.LoadingParameters'));
            } else {
                Utils.Spinner.Show(i18next.t('RecorditemEditor.LoadingParameters'));
            }

            getHistoralRecorditems()
                .then(function(recorditems: Array<Model.Recorditem>) {
                    onAfterHistoricalRecorditemsLoaded(types, disablePrecalculation, recorditems);
                    deferred.resolve();
                }, function() {
                    onAfterHistoricalRecorditemsLoaded(types, disablePrecalculation);
                    deferred.reject();
                });
        } else {
            createCorrectiveActionsDictionary()
                .then(null, () => { /* konvertiert reject => resolve */ })
                .then(() => {
                    if (!resubmissionTree) {
                        PrepareElementRevisions(elements);
                    }

                    return preloadRequiredMasterdata(elements)
                })
                .then(null, () => { /* konvertiert reject => resolve */ })
                .then(() => {
                    renderResubContent();

                    if (afterResubmissionTreeBuilt) {
                        // DistinctRevisions nur für Pläne verwendet (ohne Teilproben)
                        if (!elements || !elements.length) {
                            afterResubmissionTreeBuilt(null);
                        } else {
                            afterResubmissionTreeBuilt(resubmissionTree.Root, resubmissionTree.DistinctRevisions);
                            RepositionMarks();
                        }
                    }
                })
                .then(() => {
                    try {
                        CalculatedCheckpoints.updateParameterDependencies();
                    } catch (error) {
                        if (!Utils.Message.IsVisible()) {
                            Utils.Message.Show(i18next.t('ErrorWindow.MessageHeader'),
                                error,
                                {
                                    SendEMail: function() {
                                        Utils.SupportMail.Show(null, error);
                                    },
                                    OK: true
                                },
                                'error',
                                10001
                            );
                        }
                    }

                    if (Session.Client.Licenses && Session.Client.Licenses.AllowIssueTitleGenerationWithFormula) {
                        CalculatedCheckpoints.setIssueTitleFormulaDependencies();
                    }

                    if (!disablePrecalculation) {
                        CalculatedCheckpoints.precalculateFormulaValues();
                    }

                    deferred.resolve();
                });
        }

        return deferred.promise()
            .then(() => {
                SetFilterState();
            });
    }

    export function ShowByMenu(types: Array<Enums.ElementType>, menuID: string, disablePrecalculation: boolean = false): void {
        const menu = Menu.GetMenuItemConfig(menuID);

        if (!Utils.IsSet(menu) || View.CurrentView !== Enums.View.Main) {
            return;
        }

        _customMenuID = menuID;

        if (!_getFilterFromCache) {
            DAL.Cache.Filters.SetSystemParameterListFilter(filters);
            _getFilterFromCache = true;
        }

        filters = Model.Parameters.Filter.FromMenuItemConfiguration(menu);

        Utils.SetMode(Enums.Mode.Parameters);

        // reset DOM element cache
        DAL.Cache.ParameterList.Reset();

        _requirementsCache = {};
        _resubTreePrepared = false;
        _lastResubTreeLocationOID = null;
        _arguments = arguments;
        _tabularSubsampleToggleState = {};
        _forceAdvancedEditor = {};
        $content = Utils.GetContentContainer();

        if (Utils.Spinner.IsVisible()) {
            Utils.Spinner.UpdateText(i18next.t('RecorditemEditor.LoadingParameters'));
        } else {
            Utils.Spinner.Show(i18next.t('RecorditemEditor.LoadingParameters'));
        }

        getHistoralRecorditems()
            .then(function(recorditems) {
                onAfterHistoricalRecorditemsLoaded(types, disablePrecalculation, recorditems);
            })
            .fail(function() {
                onAfterHistoricalRecorditemsLoaded(types, disablePrecalculation);
            });
    }

    export function Create(types: Array<Enums.ElementType>, callback: Function, location?: Model.Elements.Element, checkpointFilter?: Model.Menu.IMasterDataConfiguration): void {
        if (!callback) {
            return;
        }

        const locationToUse = location || Session.CurrentLocation;

        if (!(locationToUse.Parametergroups || []).length) {
            callback();
            return;
        }

        if (!locationHasGroups(types, locationToUse)) {
            callback();
            return;
        }

        getParametersWithRecorditems(locationToUse)
            .then(function() {
                const groups = [];
                const userRoles = Utils.GetUserRoles(Session.CurrentLocation.OID);
                const renderMasterData = types.length === 1 && types.indexOf(Enums.ElementType.MasterdataGroup) === 0;
                const applyFilter = renderMasterData && (checkpointFilter.Keywords || []).length > 0;
                const excludeMatchedProperties = applyFilter && checkpointFilter.ExcludeMatchedEntities;

                const matchedGroupsByKeywordFilter: string[] = [];

                (locationToUse.Parametergroups || [])
                    .filter(group => {
                        const groupHasKeywordIntersection = !applyFilter ||
                            Utils.HasIntersection(group.Properties, checkpointFilter.Keywords);

                        if (applyFilter) {
                            if (excludeMatchedProperties && groupHasKeywordIntersection) {
                                return false;
                            }

                            if (groupHasKeywordIntersection) {
                                matchedGroupsByKeywordFilter.push(group.OID);
                            }
                        }

                        const rolesToSee = ((group.AdditionalSettings || {}).RolesThatMaySeeThisElement || [])
                            .filter(DAL.Roles.Exists);

                        return !rolesToSee.length || rolesToSee.some(identifier => userRoles.indexOf(identifier) > -1);
                    })
                    .forEach(group => {
                        let requiredParameters = 0;
                        let recordedParameters = 0;

                        if (Utils.InArray(types, group.Type)) {
                            if (group.Type === Enums.ElementType.MasterdataGroup) {
                                group.IsHidden = false;
                                group.IsFiltered = false;
                                group.MissingRequirements = false;

                                (group.Parameters || []).forEach(function(param) {
                                    param.IsHidden = false;
                                    param.IsFiltered = false;
                                    param.MissingRequirements = false;

                                    param.IsRecorded = Model.Recorditem.IsRecorditemRecorded(param.LastRecorditem);
                                });

                                groups.push(group);
                            }
                        }

                        if (!(group.Parameters || []).length) {
                            return;
                        }

                        let groupHasVisibleCheckpoints = false;

                        group.Parameters
                            .forEach(param => {
                                if (group.Type === Enums.ElementType.MasterdataGroup) {
                                    param.IsMasterdata = true;

                                    if (applyFilter) {
                                        param.IsHidden = matchedGroupsByKeywordFilter.indexOf(param.ParentOID) === -1 &&
                                            Utils.HasIntersection(param.Properties, checkpointFilter.Keywords) === excludeMatchedProperties;
                                    }
                                }

                                const rolesToSee = ((param.AdditionalSettings || {}).RolesThatMaySeeThisElement || [])
                                    .filter(DAL.Roles.Exists);

                                if (!param.IsHidden && rolesToSee.length && !rolesToSee.some(identifier => userRoles.indexOf(identifier) > -1)) {
                                    param.IsHidden = true;
                                }

                                groupHasVisibleCheckpoints = groupHasVisibleCheckpoints || param.IsHidden === false;

                                if (param.Required) {
                                    requiredParameters++;

                                    if (param.LastRecorditem) {
                                        recordedParameters++;
                                    }
                                }
                            });

                        if (groupHasVisibleCheckpoints === false) {
                            group.IsHidden = true;
                        }

                        if (requiredParameters && requiredParameters > recordedParameters) {
                            group.RequiredParameterCount = requiredParameters;
                            group.RecordedParameterCount = recordedParameters;
                        }
                    });

                const templateLocation = location || (!Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Inspection], View.CurrentView) ?
                    Session.CurrentLocation :
                    null);

                const renderedMasterdata = Templates.Parameters.List({
                    CurrentLocation: templateLocation,
                    Parametergroups: groups,
                    VisibleGroupsCount: groups.filter(getVisibleElements).length,
                    NoContentInfo: i18next.t('Parametergroups.NoContent', {
                        LocationTitle: locationToUse.Title
                    }),
                    ShowFiles: true,
                    ShowElementInfoButton: Session.Settings.ShowElementInfoButtons
                });

                callback(renderedMasterdata);
            });
    }

    export function GetAllElementRevisions(): Dictionary<Model.Elements.Element> {
        // DistinctRevisions nur für Pläne verwendet (ohne Teilproben)
        return resubmissionTree.DistinctRevisions;
    }

    export function GetElementRevisionByRevisionOID(revisionOID: string, row?: number): Model.Elements.Element {
        if (resubmissionTree) {
            if (Number(row)) {
                return resubmissionTree.GetElementByRevisionOID(revisionOID, row);
            } else {
                // undefinierte Elemente bekommen, auch bei nicht expliziten Teilproben
                return resubmissionTree.GetElementByRevisionOID(revisionOID, 1, true);
            }
        }

        return null;
    }

    export function GetElementRevisionByOID(oid: string, row?: number): Model.Elements.Element {
        if (resubmissionTree) {
            return resubmissionTree.GetElementByOID(oid, row);
        }

        return null;
    }

    export function GetElementRevisionByQRCode(qrCode: string): Model.Elements.Element {
        if (!resubmissionTree.Revisions) {
            return null;
        }

        for (let revisionOID in resubmissionTree.Revisions) {
            const element = resubmissionTree.Revisions[revisionOID][0];

            if (!!element.QRCode && element.QRCode === qrCode ||
                element.OID === qrCode) {
                return element;
            }
        }

        return null;
    }

    export function ClearElementRevisions(): void {
        resubmissionTree = null;
    }

    export function ClearIssueFormulaDependicies(): void {
        CalculatedCheckpoints.ClearIssueFormulaDependicies();
    }

    export function GetResubmissionTree(cloneResubTree: boolean = true): Model.Elements.Element {
        if (!resubmissionTree) {
            return <Model.Elements.Element>{};
        }

        if (cloneResubTree) {
            return $.extend({}, resubmissionTree.Root || resubmissionTree.Form);
        } else {
            return resubmissionTree.Root || resubmissionTree.Form;
        }
    }

    export function CreateSubsample(subsamples: Array<{ GroupOID: string, Row: number }>): void {
        for (let subsample of subsamples) {
            const resubmissionitemGroup = $.map(resubmissionTree.Form.Parametergroups,
                (group: Model.Elements.Element) => {
                    if (group.OID === subsample.GroupOID && group.Row === 1) {
                        return group;
                    }
                })[0];


            if (!resubmissionitemGroup) {
                continue;
            }

            _isRebuildingTree = true;

            const group = Utils.CloneElement(resubmissionitemGroup);
            group.Row = subsample.Row;

            if ((resubmissionitemGroup.Parameters || []).length) {
                group.Parameters = [];
                group.Parent = resubmissionitemGroup.Parent;

                for (let pCnt = 0, pLen = resubmissionitemGroup.Parameters.length; pCnt < pLen; pCnt++) {
                    const param = Utils.CloneElement(resubmissionitemGroup.Parameters[pCnt]);
                    param.Parent = group;
                    param.Row = group.Row;

                    group.Parameters.push(param);
                }
            }

            resubmissionTree.addElement(group);
            resubmissionTree.Form.Parametergroups.push(group);
        }

        if (_isRebuildingTree) {
            resubmissionTree.Form.Parametergroups.sort(Utils.SortByPositionAndRow);

            renderResubContent();
            _isRebuildingTree = false;
        }
    }

    export function ScrollTo(elementIdentifier: string, row?: number, highlight?: boolean): void {
        if (!$content) {
            return;
        }

        const element = GetElement(elementIdentifier, row, true);

        if (!element) {
            return;
        }

        let selector: string;

        if (element.Type >= 93 && element.Type <= 99) {
            selector = `[data-groupoid="${element.OID}"][data-type="${element.Type}"]`;
        } else {
            selector = `[data-oid="${element.OID}"]`;
        }

        if (element.Row) {
            selector += `[data-row="${element.Row}"]`;
        }

        const $element = $content.find(selector);
        const $parameterList = $element.parents('.parameterList');

        if (!($element || []).length) {
            return;
        }

        Utils.Spinner.Show();
        Utils.Spinner.Lock();

        const scrollToElement = () => {
            let scrollTop = null;

            if ($element.hasClass('groupRow')) {
                $element.removeClass('collapsed');
            } else if ($element.hasClass('subsample-row')) {
                const $tableWrapper = $element.parents('.sticky-wrap');
                const $groupHeader = $tableWrapper.parents('ul').prev();
                const $stickyHead = $tableWrapper.find('.sticky-thead');

                $groupHeader.removeClass('collapsed');
                $tableWrapper.find('.sticky-enabled').trigger('resize');

                scrollTop = $element[0].offsetTop;

                if (scrollTop > 0) {
                    const wasHidden = $stickyHead.hasClass('hidden');

                    if (wasHidden) {
                        $stickyHead.removeClass('hidden');
                    }

                    scrollTop -= $stickyHead.height() + 10;

                    if (wasHidden) {
                        $stickyHead.addClass('hidden');
                    }
                }

                $tableWrapper.scrollLeft(0);

                if ($tableWrapper.hasClass('overflow-y')) {
                    $tableWrapper.scrollTop(scrollTop);
                    scrollTop = null;
                } else {
                    scrollTop += $tableWrapper[0].offsetTop;
                }
            } else {
                if ($element.is('td, th')) {
                    const $tableWrapper = $element.parents('.sticky-wrap');
                    const $groupHeader = $tableWrapper.parents('ul').prev();
                    const $stickyHead = $tableWrapper.find('.sticky-thead');
                    const $stickyCol = $tableWrapper.find('.sticky-col');

                    $groupHeader.removeClass('collapsed');

                    scrollTop = $element[0].offsetTop;
                    let scrollLeft = $element[0].offsetLeft;

                    if (scrollTop > 0) {
                        const wasHidden = $stickyHead.hasClass('hidden');

                        if (wasHidden) {
                            $stickyHead.removeClass('hidden');
                        }

                        scrollTop -= $stickyHead.height() + 10;

                        if (wasHidden) {
                            $stickyHead.addClass('hidden');
                        }
                    }

                    if (scrollLeft > 0) {
                        const wasHidden = $stickyCol.hasClass('hidden');

                        if (wasHidden) {
                            $stickyCol.removeClass('hidden');
                        }

                        scrollLeft -= $stickyCol.width() + 10;

                        if (wasHidden) {
                            $stickyCol.addClass('hidden');
                        }

                        scrollLeft = Math.max(0, scrollLeft);
                    }

                    $tableWrapper.scrollLeft(scrollLeft);

                    if ($tableWrapper.hasClass('overflow-y')) {
                        $tableWrapper.scrollTop(scrollTop);
                        scrollTop = null;
                    } else {
                        scrollTop += $tableWrapper[0].offsetTop;
                    }
                } else {
                    const $groupRow = $element.parents('ul').prev();
                    $groupRow.removeClass('collapsed');
                }
            }

            if (Utils.IsSet(scrollTop)) {
                scrollTop = Math.max(scrollTop, 0);
            }

            if (highlight) {
                Extensions.HighlightElement($element);
            }

            if (scrollTop == null) {
                $('#content').scrollTop($element[0].offsetTop);
            } else {
                $('#content').scrollTop(scrollTop);
            }

            Utils.Spinner.Unlock();
            Utils.Spinner.Hide();
        };

        const $images = $parameterList.add($parameterList.prevAll()).find('img');
        const totalImages = $images.length;

        if (totalImages <= 0) {
            scrollToElement();
            return;
        }

        let loadedImages = 0;

        $images.each(function() {
            const $img = $(this);

            if ($img[0].complete) {
                loadedImages++;

                if (loadedImages >= totalImages) {
                    scrollToElement();
                }
            } else {
                $img.on('load error', () => {
                    loadedImages++;

                    if (loadedImages >= totalImages) {
                        scrollToElement();
                    }
                });
            }
        });
    }

    export function ShowParameterFilterSelection(): void {
        showFilterSelectionWindow();
    }

    export function AreFiltersActive(): boolean {
        return (filters.ValueCategories || []).length > 0 ||
            (filters.Keywords || []).length > 0 ||
            !!filters.SearchText ||
            !filters.ShowRecordedParameters ||
            !filters.ShowUnrecordedParameters ||
            !filters.ShowRequiredUnrecordedParameters;
    }

    export function GetFilters(): Model.Parameters.Filter {
        return _getFilterFromCache ? DAL.Cache.Filters.GetSystemParameterListFilter(true) : filters;
    }

    export function GetCustomMenuID(): string {
        return _customMenuID;
    }

    export function ResetFilters(): void {
        filters.Reset();
        _getFilterFromCache = false;

        DAL.Cache.Filters.Reset();
        SetFilterState();
    }

    export function RepositionMarks(): void {
        const $images = $content.find('.image img');

        $images.css({
            'margin-left': 0,
            'margin-right': 0
        });

        if ($images.length) {
            let iCnt = 0;
            const iLen = $images.length;
            for (; iCnt < iLen; iCnt++) {
                const $img = $images.eq(iCnt);
                const $marks = $img.siblings('div');

                if ($marks.length) {
                    $marks.css({
                        top: $img.position().top,
                        // left: $img.position().left,
                        width: $img.width(),
                        height: $img.height(),
                        'margin-left': 'auto',
                        'margin-right': 'auto'
                    });

                    $marks.find('> svg')
                        .attr({
                            width: '100%',
                            height: '100%'
                        });
                }
            }
        }
    }

    export function RecordingLockStateToggled(): void {
        renderResubContent();
    }

    export function ResizeScrollableTables(): void {
        window.setTimeout(function() {
            $content.find('.groupRow:not(.collapsed) + ul .sticky-enabled').trigger('resize');
        }, 10);
    }

    export function StartCodeScan(scannerMode: Enums.ScannerMode) {
        if (Session.Mode !== Enums.Mode.CoveringPage && Session.Mode !== Enums.Mode.Parameters) {
            return;
        }

        Utils.StartScanner((result: { format: string, text: string }) => {
            onAfterGotScanCode(result.format, result.text, scannerMode);
        });
    }

    export function GetDependingOIDOfIssue(issue: Model.Issues.Issue): string {
        if (!issue) {
            return null;
        }

        if (!!issue.AssignedRecorditemOID) {
            return issue.AssignedRecorditemOID;
        } else if (!!issue.ParentOID) {
            return issue.ParentOID;
        } else if (!!issue.PrecedingOID) {
            return issue.PrecedingOID;
        }

        return null;
    }

    function addCorrectiveActionToDictionary(correctiveAction: Model.Issues.Issue | Model.Issues.RawIssue, recorditemOID: string, recorditemID: number): void {
        const correctiveActionOID = correctiveAction.OID;

        if (recorditemOID != null) {
            const indexOfPrecedingIssue = Utils.GetIndex(CorrectiveActionsDictByOID[recorditemOID], correctiveAction.PrecedingOID, 'OID');
            const indexOfAction = Utils.GetIndex(CorrectiveActionsDictByOID[recorditemOID], correctiveActionOID, 'OID');

            if (indexOfPrecedingIssue === -1) {
                if (indexOfAction === -1) {
                    CorrectiveActionsDictByOID[recorditemOID].push(correctiveAction);
                } else {
                    CorrectiveActionsDictByOID[recorditemOID][indexOfAction] = correctiveAction;
                }
            } else {
                CorrectiveActionsDictByOID[recorditemOID][indexOfPrecedingIssue] = correctiveAction;
            }
        } else if (recorditemID != null) {
            const indexOfPrecedingIssue = Utils.GetIndex(CorrectiveActionsDictionary[recorditemID], correctiveAction.PrecedingOID, 'OID');
            const indexOfAction = Utils.GetIndex(CorrectiveActionsDictionary[recorditemID], correctiveActionOID, 'OID');

            if (indexOfPrecedingIssue === -1) {
                if (indexOfAction === -1) {
                    CorrectiveActionsDictionary[recorditemID].push(correctiveAction);
                } else {
                    CorrectiveActionsDictionary[recorditemID][indexOfAction] = correctiveAction;
                }
            } else {
                CorrectiveActionsDictionary[recorditemID][indexOfPrecedingIssue] = correctiveAction;
            }
        }
    }

    export function ApplyNewCorrectiveActionToDictionary(correctiveAction: Model.Issues.Issue | Model.Issues.RawIssue, recorditem: Model.Recorditem): void {
        if (correctiveAction == null || recorditem == null) {
            return;
        }

        if (CorrectiveActionsDictByOID == null) {
            CorrectiveActionsDictByOID = {};
        }

        if (CorrectiveActionsDictionary == null) {
            CorrectiveActionsDictionary = {};
        }

        const assignedRecorditemOID = correctiveAction.AssignedRecorditemOID;
        const recorditemID = recorditem.ID || 0;

        if (recorditemID === 0) {
            const oldRecorditem = PreviousRecorditemsOfElements[recorditem.ElementOID];
            const oldRecorditemOID = oldRecorditem ? oldRecorditem.OID : null;
            const sameOID = assignedRecorditemOID === oldRecorditemOID;

            if (sameOID) {
                if (CorrectiveActionsDictByOID[oldRecorditemOID] == null) {
                    CorrectiveActionsDictByOID[oldRecorditemOID] = [correctiveAction];
                } else {
                    addCorrectiveActionToDictionary(correctiveAction, oldRecorditemOID, null);
                }
            } else if (CorrectiveActionsDictByOID[oldRecorditemOID] != null) {
                CorrectiveActionsDictByOID[assignedRecorditemOID] = [].concat(CorrectiveActionsDictByOID[oldRecorditemOID]);
                CorrectiveActionsDictByOID[assignedRecorditemOID].push(correctiveAction);
                delete CorrectiveActionsDictByOID[oldRecorditemOID];
            } else if (CorrectiveActionsDictByOID[assignedRecorditemOID] == null) {
                CorrectiveActionsDictByOID[assignedRecorditemOID] = [correctiveAction];
            } else {
                addCorrectiveActionToDictionary(correctiveAction, assignedRecorditemOID, null);
            }
        } else if (recorditemID != null) {
            if (CorrectiveActionsDictionary[recorditemID] == null) {
                CorrectiveActionsDictionary[recorditemID] = [correctiveAction];
            } else {
                addCorrectiveActionToDictionary(correctiveAction, null, recorditemID);
            }
        }
    }

    /** Utils **/
    function getSelectedCheckpoint($checkpoint: any): Model.Elements.Element {
        const row = parseInt($checkpoint.data('row'), 10);

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Inspection], View.CurrentView)) {
            return GetParameter($checkpoint.data('revisionoid'), row);
        } else if (View.CurrentView === Enums.View.Scheduling) {
            return GetParameter($checkpoint.data('revisionoid'));
        }

        return GetParameter($checkpoint.data('oid'));
    }

    function checkSpecificRight(rightId: Enums.Rights): boolean {
        let parentIssue: Model.Issues.Issue;
        let location = Session.CurrentLocation;

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            parentIssue = IssueView.GetCurrentIssue();
        }

        if (View.CurrentView === Enums.View.Form && parentIssue) {
            location = DAL.Elements.GetByOID(parentIssue.AssignedElementOID);
        }

        return Utils.UserHasRight(Session.User.OID, rightId, true, location);
    }

    export function Recalculate(): Deferred {
        return CalculatedCheckpoints.precalculateFormulaValues();
    }

    export function UpdateCheckpointToolbarsVisibility(): void {
        const $toolbars = $content.find('.checkpoint .toolbar');
        const issue = IssueView.GetCurrentIssue();

        if (!issue) {
            return;
        }

        $toolbars.toArray().forEach(toolbar => {
            const $toolbar = $(toolbar);
            const $checkpoint = $toolbar.parents('.checkpoint');
            const checkpoint = getSelectedCheckpoint($checkpoint);

            if (!checkpoint) {
                return;
            }

            const recordingIsLocked = IssueView.IsRecordingLocked(checkpoint);

            let hideToolbar = issue.IsLocked || recordingIsLocked; // Erfassung ist gesperrt

            if (Session.IsSmartDeviceApplication && !hideToolbar) {
                // Prüfpunkt ist kein Individual-PP und besitzt keine zusätzlichen Toolbar-Buttons
                hideToolbar = checkpoint.Type !== Enums.ElementType.IndividualData &&
                    (!checkpoint.LastRecorditem || checkpoint.LastRecorditem.IsDummy);
            }

            $toolbar.toggleClass('hidden', hideToolbar || false);
        });
    }

    /** Comment handlers **/
    function onExistingCommentClick(evt: Event): void {
        if (View.CurrentView === Enums.View.Main) {
            return;
        }

        const issue = IssueView.GetCurrentIssue();
        if (issue) {
            if (issue.IsLocked) {
                return;
            }
        }

        evt.stopPropagation();

        const $comment = $(evt.currentTarget);
        const commentIdentifier = $comment.data('oid');

        const $checkpoint = $comment.parents('.checkpoint');
        const checkpoint = getSelectedCheckpoint($checkpoint);

        if (!checkpoint || !checkpoint.LastRecorditem || !checkpoint.LastRecorditem.Comments) {
            return;
        }

        const comment = Utils.Where(checkpoint.LastRecorditem.Comments, 'OID', '===', commentIdentifier);

        if (!comment) {
            return;
        }

        const rightToCheck = comment.CreatorOID === Session.User.OID ?
            Enums.Rights.Comments_CreateAndModifyCommentsOnCheckpoint :
            Enums.Rights.Comments_ModifyCommentsOfOtherUsers;

        if (!checkSpecificRight(rightToCheck)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.EditComment.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        const creator = DAL.Users.GetByOID(comment.CreatorOID);
        const editor = DAL.Users.GetByOID(comment.EditorOID);

        const messageBody = [`<p class="checkpoint"><i class="icon-location"></i> ${checkpoint.Title}</p>`];

        const commentInfo = comment.CreatorOID === comment.EditorOID ? i18next.t('ParameterList.EditComment.InfoText', {
            Creator: creator ? creator.Title : i18next.t('Misc.Unknown'),
            ModificationTimestamp: Utils.DateTime.ToString(comment.ModificationTimestamp)
        }) : i18next.t('ParameterList.EditComment.InfoTextModified', {
            Creator: creator ? creator.Title : i18next.t('Misc.Unknown'),
            Editor: editor ? editor.Title : i18next.t('Misc.Unknown'),
            CreationTimestamp: Utils.DateTime.ToString(comment.CreationTimestamp),
            ModificationTimestamp: Utils.DateTime.ToString(comment.ModificationTimestamp)
        });

        messageBody.push(`<p class="info">${commentInfo}</p>`);

        Utils.InputWindow.Show(
            i18next.t('ParameterList.EditComment.MessageHeader'),
            messageBody.join(''),
            {
                Abort: {},
                OK: {
                    Fn: (text) => {
                        text = $.trim(text);

                        const newComment = new Model.Comment(
                            comment.OID,
                            comment.CreationTimestamp,
                            new Date(),
                            comment.CreatorOID,
                            Session.User.OID,
                            text,
                            comment.AssignmentOID,
                            comment.Type
                        );

                        // set issue as helper information
                        if (Session.IsSmartDeviceApplication) {
                            newComment.IssueID = comment.IssueID;
                            newComment.IssueOID = comment.IssueOID;
                        }

                        newComment.Save()
                            .then(() => {
                                const commentIdx = Utils.GetIndex(checkpoint.LastRecorditem.Comments, comment.OID, 'OID');

                                if (commentIdx !== -1) {
                                    checkpoint.LastRecorditem.Comments[commentIdx] = newComment;
                                }

                                (checkpoint.LastRecorditem.Comments || []).sort(function(a: Model.Comment, b: Model.Comment) {
                                    return (<Date>a.ModificationTimestamp).getTime() - (<Date>b.ModificationTimestamp).getTime();
                                });

                                UpdateElementCell(new Model.Recorditem(checkpoint.LastRecorditem));
                            })
                            .fail(() => {
                                Utils.Toaster.Show(
                                    i18next.t('ParameterList.EditComment.CouldNotSaveComment'),
                                    2,
                                    Enums.Toaster.Icon.Warning
                                );
                            });
                    }
                }
            },
            comment.Text,
            'textarea'
        );
    }

    function onShowCommentsClick(evt: Event): void {
        const issue = IssueView.GetCurrentIssue();
        if (issue) {
            if (issue.IsLocked) {
                return;
            }
        }

        const $this = $(evt.currentTarget);
        const $checkpoint = $this.parents('.checkpoint');
        const checkpoint = getSelectedCheckpoint($checkpoint);

        if (!checkpoint || !checkpoint.LastRecorditem || !checkpoint.LastRecorditem.Comments) {
            return;
        }

        let parentIssue: Model.Issues.Issue;
        let location: Model.Elements.Element;

        const entityInformation = {
            Type: Enums.CommentEntity.Recorditem,
            Recorditem: checkpoint.LastRecorditem
        };

        if (Utils.InArray([Enums.View.Form, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            parentIssue = IssueView.GetCurrentIssue();
        }

        if (View.CurrentView === Enums.View.Form && parentIssue) {
            location = DAL.Elements.GetByOID(parentIssue.AssignedElementOID);
        }

        const canWriteComments = Utils.UserHasRight(
            Session.User.OID,
            Enums.Rights.Comments_CreateAndModifyOnCheckpoints,
            true,
            location
        );

        Utils.ChatWindow.Show(
            entityInformation,
            checkpoint.LastRecorditem.Comments,
            !canWriteComments,
            false,
            (comment: Model.Comment) => {
                checkpoint.LastRecorditem.Comments = checkpoint.LastRecorditem.Comments || [];

                const oldCommentIndex = Utils.GetIndex(checkpoint.LastRecorditem.Comments, comment.OID, 'OID');

                if (oldCommentIndex !== -1) {
                    checkpoint.LastRecorditem.Comments.splice(oldCommentIndex, 1);
                }

                checkpoint.LastRecorditem.Comments.push(comment.GetRawEntity());

                UpdateElementCell(new Model.Recorditem(checkpoint.LastRecorditem));
            },
            (comment: Model.Comment) => {
                checkpoint.LastRecorditem.Comments = checkpoint.LastRecorditem.Comments || [];

                const oldCommentIndex = Utils.GetIndex(checkpoint.LastRecorditem.Comments, comment.OID, 'OID');

                if (oldCommentIndex !== -1) {
                    checkpoint.LastRecorditem.Comments.splice(oldCommentIndex, 1);
                }

                UpdateElementCell(new Model.Recorditem(checkpoint.LastRecorditem));
            }
        );
    }

    /** Toolbar events **/
    /** Web File Inputs **/
    function onFileInput(evt: Event): void {
        const issue = IssueView.GetCurrentIssue();
        if (issue) {
            if (issue.IsLocked) {
                return;
            }
        }

        if (typeof FileReader === 'undefined') {
            return;
        }

        if (!(this.files || []).length) {
            return;
        }

        evt.stopPropagation();

        const $this = $(this);
        const $checkpoint = $this.parents('.checkpoint');

        if (!$checkpoint.length) {
            return;
        }

        if (!checkSpecificRight(Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.RecorditemCreateComment.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        const checkpoint = getSelectedCheckpoint($checkpoint);

        if (checkpoint.DisableInAppEditing) {
            Utils.Toaster.Show(i18next.t('ParameterList.CheckpointEditingBlocked', { title: checkpoint.Title }));
            return;
        }

        if (!checkpoint.LastRecorditem) {
            return;
        }

        const files = this.files;
        const fileInformation: Array<Utils.FileInformation> = [];

        Utils.LoopDeferredActions(files, (file: Utils.ExtendedFile) => {
            file.Title = Utils.GetFileTitle(file.name, '');

            return Utils.ReadFile(file, uuid())
                .then((fileInfo: Utils.FileInformation) => {
                    if (Utils.IsSet(fileInfo)) {
                        fileInformation.push(fileInfo);
                    }
                });
        })
            .then(() => onAfterFilesRead(fileInformation, checkpoint))
            .then((recorditem: Model.Recorditem) => {
                $(this).val('');

                if (recorditem != null) {
                    // NewCorrectiveActions zurücksetzen um Fehler beim nächsten Bild zu vermeiden
                    delete recorditem.NewCorrectiveActions;
                    checkpoint.LastRecorditem = recorditem;
                }
            })
            .then(() => UpdateElementCell(checkpoint.LastRecorditem))
            .always(() => {
                (<HTMLInputElement>evt.currentTarget).value = '';

                Utils.Spinner.Hide();
            });
    }

    function onAfterFilesRead(fileInformation: Array<Utils.FileInformation>, checkpoint: Model.Elements.Element): Deferred {
        if (!Utils.IsSet(fileInformation) || !fileInformation.length) {
            return $.Deferred().resolve().promise();
        }

        let filePosition = -1;

        if (!checkpoint.LastRecorditem) {
            return;
        }

        const recorditem = new Model.Recorditem(checkpoint.LastRecorditem);

        (recorditem.AdditionalFiles || []).forEach(function(file) {
            if (+file.Position > filePosition) {
                filePosition = +file.Position;
            }
        });

        const newFileInfos = {
            Properties: [],
            Definition: {}
        };

        const deferred = $.Deferred();

        function onAfterFilesPrepared() {
            if (!newFileInfos.Properties.length) {
                return $.Deferred().resolve().promise();
            }

            recorditem.IsUpdate = true;
            recorditem.NewFiles = (newFileInfos.Properties || []).map(file => newFileInfos.Definition[file.OID]);

            recorditem.Save()
                .then(() => deferred.resolve(recorditem), deferred.reject);
        }

        const additionalFiles: Dictionary<any> = {};

        (function iterateOverFiles(fileIndex: number) {
            const fileInfo = fileInformation[fileIndex];

            if (!fileInfo ||
                !fileInfo.File ||
                !fileInfo.Identifier ||
                fileIndex == fileInformation.length) {
                askWhetherToReUseFilesAndCorrectiveAction(checkpoint, recorditem, additionalFiles)
                    .always(() => {
                        onAfterFilesPrepared();
                    });
                return;
            }

            const fileExtension = Utils.GetFileExtension(fileInfo.File.name, '');

            const fileProperties = {
                Filename: fileInfo.Identifier + fileExtension,
                Title: fileInfo.File.Title,
                Description: fileInfo.File.Description,
                OID: fileInfo.Identifier,
                MimeType: <Enums.MimeType>fileInfo.File.type,
                Position: ++filePosition,
                IsImage: Utils.IsImage(fileInfo.File.type),
                IsAudio: Utils.IsAudio(fileInfo.File.type),
                IsVideo: Utils.IsVideo(fileInfo.File.type),
                Content: fileInfo.Content
            };

            additionalFiles[fileProperties.OID] = fileProperties;

            Utils.FileEditor.Show({
                Title: fileProperties.Title || fileProperties.Filename,
                Description: fileProperties.Description,
                Readonly: false,
                HideCloseButton: true,
                AllowImmediateSave: true,
                File: fileInfo.File
            }).then((title: string, description: string) => {
                Utils.UpdateFileTitleAndDescription(fileProperties, title, description);

                fileInfo.File.Filename = fileProperties.Filename;
                fileInfo.File.Position = fileProperties.Position;
                fileInfo.File.ModificationType = Enums.AdditionalImageModificationType.CREATED;

                newFileInfos.Properties.push(fileProperties);
                newFileInfos.Definition[fileInfo.Identifier] = fileInfo.File;

                recorditem.AdditionalFiles = recorditem.AdditionalFiles || [];
                recorditem.AdditionalFiles.push(fileProperties);

                iterateOverFiles(++fileIndex);
            }).fail(() => deferred.reject());
        })(0);

        return deferred.promise();
    }

    function getAdditionalFileUrl(recorditem: Model.Recorditem, additionalImage) {
        let uri = `${Session.BaseURI}recorditems/${recorditem.OID}/files?filename=${additionalImage.newFilename || additionalImage.Filename}`;

        if (Utils.IsSet(additionalImage.Position)) {
            uri += `&position=${additionalImage.Position}`;
        }

        if (Utils.IsSet(additionalImage.Title)) {
            uri += `&title=${additionalImage.Title}`;
        }

        if (Utils.IsSet(additionalImage.Description)) {
            uri += `&description=${additionalImage.Description}`;
        }

        uri += Utils.GetAuthQueryParameter('?')

        return uri;
    }

    /** App Camera / Library Handlers **/
    function onFileButtonClick(evt: Event): void {
        const issue = IssueView.GetCurrentIssue();
        if (issue) {
            if (issue.IsLocked) {
                return;
            }
        }

        evt.preventDefault();

        const $this = $(this);
        if ($this.attr('disabled') === 'disabled') {
            return;
        }

        if (!checkSpecificRight(Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.RecorditemCreateComment.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        const $checkpoint = $this.parents('.checkpoint');

        if (!$checkpoint.length) {
            return;
        }

        const checkpoint = getSelectedCheckpoint($checkpoint);

        Utils.RequestCamera()
            .then((path: string) => onAfterGotFilePaths([path], checkpoint), Utils.Spinner.Hide);
    }

    function onFileButtonPress(evt: Event): void {
        const issue = IssueView.GetCurrentIssue();
        if (issue) {
            if (issue.IsLocked) {
                return;
            }
        }

        evt.preventDefault();

        const $this = $(this);
        if ($this.attr('disabled') === 'disabled') {
            return;
        }

        if (!checkSpecificRight(Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.RecorditemCreateComment.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        const $checkpoint = $this.parents('.checkpoint');

        if (!$checkpoint.length) {
            return;
        }

        const checkpoint = getSelectedCheckpoint($checkpoint);

        Utils.GetImagesFromGallery({ MaximumImagesCount: 10 })
            .then((images: string[]) => {
                onAfterGotFilePaths(images, checkpoint);
            });
    }

    function onAfterGotFilePaths(imageURIs: Array<string>, checkpoint: Model.Elements.Element): void {
        if (!(imageURIs || []).length) {
            return;
        }

        if (!checkpoint.LastRecorditem) {
            return;
        }

        const recorditem = new Model.Recorditem(checkpoint.LastRecorditem);
        recorditem.NewCorrectiveActions = [];
        recorditem.AdditionalFiles = recorditem.AdditionalFiles || [];

        let filePosition = 0;
        recorditem.AdditionalFiles.forEach(function(file) {
            if (+file.Position > filePosition) {
                filePosition = +file.Position;
            }
        });

        const newFileInfos = [];

        function onAfterFilesPrepared(): Deferred {
            recorditem.IsUpdate = true;

            Utils.Spinner.Show();

            return recorditem.Save()
                .then(() => {
                    const uploadDeferreds = [];

                    newFileInfos.forEach((prop) => {
                        const saveDeferred = $.Deferred();

                        moveFileAndCreateSyncEntity(prop.Filename, prop.FileURI, recorditem)
                            .then((newFilename: string) => {
                                prop.FileURI = newFilename;
                                prop.Content = newFilename;
                                return newFilename;
                            })
                            .then(saveDeferred.resolve, saveDeferred.reject);

                        uploadDeferreds.push(saveDeferred.promise());
                    });

                    return $.when.apply($, uploadDeferreds)
                        .then(() => {
                            checkpoint.LastRecorditem = recorditem;
                            UpdateElementCell(checkpoint.LastRecorditem);
                        });
                })
                .always(Utils.Spinner.Hide)
        }

        const additionalFiles: Dictionary<any> = {};

        (function iterateOverFiles(fileIndex: number) {
            const imageUri = imageURIs[fileIndex];

            if (!imageUri || fileIndex == imageURIs.length) {
                askWhetherToReUseFilesAndCorrectiveAction(checkpoint, recorditem, additionalFiles)
                    .always(() => {
                        onAfterFilesPrepared();
                    })
                return;
            }

            const fileInfo = createFileInformation(imageUri);
            const fileProperties = {
                Filename: fileInfo.Filename,
                Description: '',
                Title: fileInfo.Filename,
                OID: fileInfo.OID,
                MimeType: <Enums.MimeType>fileInfo.MimeType,
                Position: ++filePosition,
                FileURI: fileInfo.FileURI,
                IsImage: Utils.IsImage(fileInfo.MimeType),
                IsAudio: Utils.IsAudio(fileInfo.MimeType),
                IsVideo: Utils.IsVideo(fileInfo.MimeType)
            };

            additionalFiles[fileProperties.OID] = fileProperties;

            const filePath = Session.IsRunningOnIOS ?
                Utils.FixIOSFilepath(fileProperties.FileURI) :
                fileProperties.FileURI;

            Utils.FileEditor.Show({
                Title: fileProperties.Title,
                Description: '',
                Readonly: false,
                HideCloseButton: true,
                AllowImmediateSave: true,
                File: { MimeType: fileProperties.MimeType, Filename: fileProperties.Filename, FilePath: filePath },
                OnSave: (title: string, description: string) => {
                    Utils.UpdateFileTitleAndDescription(fileProperties, title, description);

                    newFileInfos.push(fileProperties);

                    recorditem.AdditionalFiles = recorditem.AdditionalFiles || [];
                    recorditem.AdditionalFiles.push(Utils.CloneObject(fileProperties, ['FileURI', 'Content', 'OID']));
                    recorditem.Images = recorditem.Images || [];
                    recorditem.Images.push(Utils.CloneObject(fileProperties, ['FileURI', 'Content']))
                    recorditem['ImageFilenames'] = recorditem.Images.map((file) => file.Filename);

                    iterateOverFiles(++fileIndex);
                }
            });
        })(0);
    }

    function moveFileAndCreateSyncEntity(filename: string, filePath: string, recorditem: Model.Recorditem): Deferred {
        return Utils.MoveFileToResources(filePath, filename)
            .then(() => {
                const syncEntity = new Model.Synchronisation.RecordItemFileEntityDescription(
                    filename, Enums.SyncEntityType.RecorditemAdditionalFile, recorditem
                );

                return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, syncEntity)
                    .then(() => `${Utils.GetResourcesPath()}${filename}`);
            });
    }

    function createFileInformation(imageURI: string): {
        Filename: string, OID: string, MimeType: Enums.MimeType, FileURI: string
    } {
        const identifier = uuid();
        const fileExtension = Utils.GetFileExtension(imageURI, '.jpg');
        const newFilename = identifier + fileExtension;
        const mimeType = Enums.MimeType[fileExtension] || Enums.MimeType['.jpg'];   // Fallback zu JPEG

        return {
            Filename: newFilename,
            OID: identifier,
            MimeType: mimeType,
            FileURI: imageURI
        };
    }

    /** Add New Comment **/
    function onAddCommentClick(evt: Event): void {
        const issue = IssueView.GetCurrentIssue();
        if (issue) {
            if (issue.IsLocked) {
                return;
            }
        }

        evt.stopPropagation();

        const $btn = $(evt.currentTarget);

        if (!checkSpecificRight(Enums.Rights.Comments_CreateAndModifyCommentsOnCheckpoint)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.RecorditemCreateComment.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        const $checkpoint = $btn.parents('.checkpoint');

        if (!$checkpoint.length) {
            return;
        }

        const checkpoint = getSelectedCheckpoint($checkpoint);

        if (checkpoint.DisableInAppEditing) {
            Utils.Toaster.Show(i18next.t('ParameterList.CheckpointEditingBlocked', { title: checkpoint.Title }));
            return;
        }

        const messageBody = `<p class="checkpoint"><i class="icon-location"></i> ${checkpoint.Title}</p>`;

        Utils.InputWindow.Show(
            i18next.t('ParameterList.Toolbar.AddComment.MessageHeader'),
            messageBody,
            {
                Abort: {},
                OK: {
                    Fn: (text: string) => {
                        text = $.trim(text);

                        const recorditemOID = $checkpoint.data('recorditem-oid');
                        const now = new Date();
                        const comment = new Model.Comment(
                            uuid(),
                            now,
                            now,
                            Session.User.OID,
                            Session.User.OID,
                            text,
                            recorditemOID,
                            Enums.CommentType.RecorditemComment
                        );

                        // set issue as helper information
                        if (Session.IsSmartDeviceApplication) {
                            const issue = IssueView.GetCurrentIssue();

                            if (issue) {
                                if (issue.ID) {
                                    comment.IssueID = issue.ID;
                                }

                                comment.IssueOID = issue.OID;
                            }
                        }

                        comment.Save()
                            .then(() => {
                                checkpoint.LastRecorditem.Comments = checkpoint.LastRecorditem.Comments || [];
                                checkpoint.LastRecorditem.Comments.push(comment);

                                (checkpoint.LastRecorditem.Comments || []).sort(function(a: Model.Comment, b: Model.Comment) {
                                    if (typeof a.ModificationTimestamp === 'string') {
                                        a.ModificationTimestamp = new Date(a.ModificationTimestamp);
                                    }

                                    if (typeof b.ModificationTimestamp === 'string') {
                                        b.ModificationTimestamp = new Date(b.ModificationTimestamp);
                                    }

                                    return (<Date>a.ModificationTimestamp).getTime() - (<Date>b.ModificationTimestamp).getTime();
                                });
                            })
                            .fail(() => {
                                Utils.Toaster.Show(
                                    i18next.t('ParameterList.Toolbar.AddComment.CouldNotSaveComment'),
                                    2,
                                    Enums.Toaster.Icon.Warning
                                );
                            })
                            .always(function() {
                                UpdateElementCell(new Model.Recorditem(checkpoint.LastRecorditem));
                            });
                    }
                }
            },
            '',
            'textarea'
        );
    }

    /** Add New Corrective Action **/
    function askWhetherToReUseFilesAndCorrectiveAction(checkpoint: Model.Elements.Element, recorditem: Model.Recorditem, additionalFiles: Dictionary<any>): Deferred {
        if (!additionalFiles) {
            return $.Deferred().reject();
        }

        const fileIdentifiers: string[] = Object.keys(additionalFiles);
        return Utils.RecorditemEditor.AskWhetherToReUseFilesInCorrectiveAction(fileIdentifiers)
            .then(() => {
                recorditem = recorditem || new Model.Recorditem(checkpoint.LastRecorditem);
                return Utils.RecorditemEditor.CreateNewCorrectiveAction(null, fileIdentifiers, recorditem, checkpoint, additionalFiles, (action) => {
                    recorditem.NewCorrectiveActions = recorditem.NewCorrectiveActions || [];
                    recorditem.NewCorrectiveActions.push(action);
                }).fail(() => {
                    Utils.Message.Show(i18next.t('ParameterList.CreateIssue.ErrorMessageHeader'), i18next.t('ParameterList.CreateIssue.ErrorMessageBody'), { OK: true });
                });
            })
            .then((issue: Model.Issues.RawIssue) => {
                if (!issue) {
                    return $.Deferred().reject();
                }

                recorditem.CorrectiveActions = recorditem.CorrectiveActions || [];
                recorditem.CorrectiveActions.push(new Model.Issues.Issue(issue));

                if (recorditem.ID) {
                    CorrectiveActionsDictionary[recorditem.ID] = [].concat(recorditem.CorrectiveActions);
                }
                CorrectiveActionsDictByOID[recorditem.OID] = [].concat(recorditem.CorrectiveActions);

                // WorkflowInformation aktualisieren
                recorditem.WorkflowInformation = Utils.ActionWindow.CreateWorkflowInformation(null,
                    recorditem.CorrectiveActions.map((issue: Model.Issues.Issue) => ({ IsIssue: true, Issue: issue }))
                );

                return issue;
            });
    }

    /** Add New Corrective Action **/
    export function AskWhetherToReUseCommentAndCorrectiveAction(checkpoint: Model.Elements.Element, recorditem: Model.Recorditem, comment: Model.Comment): Deferred {
        if (!comment || !comment.Text || !comment.Text.length) {
            return $.Deferred().reject();
        }

        return Utils.RecorditemEditor.AskWhetherToReUseCommentInCorrectiveAction(comment)
            .then(() => {
                recorditem = recorditem || new Model.Recorditem(checkpoint.LastRecorditem);

                return Utils.RecorditemEditor.CreateNewCorrectiveAction(null, null, recorditem, checkpoint, null, (action) => {
                    recorditem.NewCorrectiveActions = recorditem.NewCorrectiveActions || [];
                    recorditem.NewCorrectiveActions.push(action);
                }, comment.Text).fail(() => {
                    Utils.Message.Show(
                        i18next.t('ParameterList.CreateIssue.ErrorMessageHeader'),
                        i18next.t('ParameterList.CreateIssue.ErrorMessageBody'),
                        { OK: true }
                    );
                });
            })
            .then((issue: Model.Issues.RawIssue) => {
                if (!issue) {
                    return $.Deferred().reject();
                }

                recorditem.CorrectiveActions = recorditem.CorrectiveActions || [];
                recorditem.CorrectiveActions.push(new Model.Issues.Issue(issue));

                if (recorditem.ID) {
                    CorrectiveActionsDictionary[recorditem.ID] = [].concat(recorditem.CorrectiveActions);
                }

                CorrectiveActionsDictByOID[recorditem.OID] = [].concat(recorditem.CorrectiveActions);

                // WorkflowInformation aktualisieren
                recorditem.WorkflowInformation = Utils.ActionWindow.CreateWorkflowInformation(null,
                    recorditem.CorrectiveActions.map((issue: Model.Issues.Issue) => ({ IsIssue: true, Issue: issue }))
                );

                return issue;
            });
    }

    function onAddCorrectiveActionClick(evt: Event): void {
        const issue = IssueView.GetCurrentIssue();
        if (issue && issue.IsLocked) {
            return;
        }

        evt.stopPropagation();

        const $btn = $(evt.currentTarget);

        if (!checkSpecificRight(Enums.Rights.Comments_CreateAndModifyActionIssue) &&
            Session.LastKnownAPIVersion >= 7) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        let parentIssue: Model.Issues.Issue;
        let location = Session.CurrentLocation;

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            parentIssue = IssueView.GetCurrentIssue();
        }

        if (View.CurrentView === Enums.View.Form && parentIssue) {
            location = DAL.Elements.GetByOID(parentIssue.AssignedElementOID);
        }

        if (!location) {
            return;
        }

        if (!Utils.CanUserCreateIssueType(Enums.IssueType.Task, location)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        if (!Utils.IsUserAbleToSetInitialState(location.OID, Session.Client.Settings.TicketOpened)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.DefaultState.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        const action = {
            Type: Enums.CheckpointWorkflowType.CorrectiveAction,
            TemporaryFiles: null,
            InheritResponsibilities: false
        };

        if (parentIssue && parentIssue.AssignedFormRevisionOID) {
            const form = ParameterList.GetElementRevisionByRevisionOID(parentIssue.AssignedFormRevisionOID);

            action.InheritResponsibilities = form &&
                form.AdditionalSettings &&
                form.AdditionalSettings.BequeathResponsibilities;
        }

        const $checkpoint = $btn.parents('.checkpoint');

        if (!$checkpoint.length) {
            return;
        }

        const recorditemOID = $checkpoint.data('recorditem-oid');
        const checkpoint = getSelectedCheckpoint($checkpoint);

        if (!checkpoint) {
            return;
        }

        if (checkpoint.DisableInAppEditing) {
            Utils.Toaster.Show(i18next.t('ParameterList.CheckpointEditingBlocked', { title: checkpoint.Title }));
            return;
        }

        const recorditem = <Model.Recorditem>{ ElementOID: checkpoint.OID, OID: recorditemOID };

        Utils.IssueViewer.CreateCorrectiveAction(
            recorditem, checkpoint, parentIssue, action,
            false,
            (issue: Model.Issues.Issue) => {
                onAfterCorrectiveActionSaved(checkpoint, issue);
            },
            false
        );
    }

    function onAfterCorrectiveActionSaved(checkpoint: Model.Elements.Element, issue: Model.Issues.Issue): void {
        if (!checkpoint.LastRecorditem) {
            return;
        }

        const existingCorrectiveActionIssues = checkpoint.LastRecorditem.CorrectiveActions || [];

        let existingCorrectiveActions: Utils.ActionWindow.Action[];
        if (existingCorrectiveActionIssues.length) {
            existingCorrectiveActions = Utils.RecorditemEditor.GetPreparedExistingCorrectiveActions(
                existingCorrectiveActionIssues
            );
        }

        const newActionMeta: Utils.ActionWindow.Action[] = [{
            IsIssue: true,
            Issue: issue
        }];

        checkpoint.LastRecorditem.WorkflowInformation = Utils.ActionWindow.CreateWorkflowInformation(
            newActionMeta,
            existingCorrectiveActions
        );

        checkpoint.LastRecorditem.IsUpdate = true;

        checkpoint.LastRecorditem = new Model.Recorditem(checkpoint.LastRecorditem);

        checkpoint.LastRecorditem.Save()
            .then(() => {
                checkpoint.LastRecorditem.CorrectiveActions = checkpoint.LastRecorditem.CorrectiveActions || [];
                checkpoint.LastRecorditem.CorrectiveActions.push(issue);

                UpdateElementCell(new Model.Recorditem(checkpoint.LastRecorditem));
            });
    }

    /** Scan code **/
    function onScanCodeClick(evt: Event): void {
        evt.stopPropagation();

        const issue = IssueView.GetCurrentIssue();
        if (issue) {
            if (issue.IsLocked) {
                Utils.Message.Show(i18next.t('IssueView.IssueIsLocked.MessageHeader'),
                    i18next.t('IssueView.IssueIsLocked.MessageBody'),
                    {
                        Close: true
                    });

                return;
            }
        }

        const $btn = $(evt.currentTarget);
        const $checkpoint = $btn.parents('.checkpoint');
        const checkpoint = getSelectedCheckpoint($checkpoint);

        if (!isCheckpointEditingAllowed(checkpoint) ||
            checkpoint.Type !== Enums.ElementType.IndividualData) {
            return;
        }

        onModifyRecorditem(checkpoint, () => {
            Utils.StartScanner((result: { format: string, text: string }) =>
                onAfterIndividualDataBarcodeScanned(checkpoint, result.text)
            );
        });
    }

    function onAfterIndividualDataBarcodeScanned(checkpoint: Model.Elements.Element, scannedValue: string): void {
        const schemaName = Utils.GetIndividualDataTypeOfElement(checkpoint);
        const schema = DAL.Schemas.GetByType(schemaName);

        if (schema == null || !(schema.SearchFields || []).length) {
            return;
        }

        const entities = DAL.IndividualData.GetByType(schemaName);

        if (!(entities || []).length) {
            return;
        }

        const issue = IssueView.GetCurrentIssue();
        const filteredEntities = entities.filter(entity => {
            if (!(schema.SearchFields || []).length) {
                return entity.Title.indexOf(scannedValue) > -1;
            }

            return schema.SearchFields.some(attr => {
                const field = Utils.FindByPredicate(schema.Properties, prop => prop.Name === attr);

                if (!field) {
                    return;
                }

                let value = entity[attr];

                switch (field.Type) {
                    case Enums.IndividualDataType.Bool:
                    case Enums.IndividualDataType.Date:
                    case Enums.IndividualDataType.Time:
                    case Enums.IndividualDataType.Image:
                    case Enums.IndividualDataType.File:
                        return false;
                    case Enums.IndividualDataType.Number:
                        value = value == null ? '' : value.toString();
                        return value.indexOf(scannedValue) > -1;
                    default:
                        value = value || '';
                        return value.indexOf(scannedValue) > -1;
                }
            });
        });

        if (!filteredEntities.length) {
            Utils.Toaster.Show(
                i18next.t('ParameterList.Toolbar.ScanCode.NoEntityFound.Message'),
                2,
                Enums.Toaster.Icon.Info
            );

            return;
        }

        if (filteredEntities.length > 1) {
            Utils.RecorditemEditor.ShowSimplified({
                Element: checkpoint,
                PreviousRecorditem: checkpoint.LastRecorditem,
                Row: checkpoint.Row,
                Issue: issue,
                OnAfterRecorditemSaved: onAfterRecorditemSaved,
                OnAfterWindowDestroyed: CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack,
                IsReadonly: IssueView.IsRecordingLocked(checkpoint),
                IndividualDataFilterText: scannedValue
            });
            return;
        }

        const value = {};
        value[schemaName] = [filteredEntities[0].ID];

        Utils.RecorditemEditor.SaveSelectedValue({
            Element: checkpoint,
            PreviousRecorditem: checkpoint.LastRecorditem,
            Row: checkpoint.Row,
            Issue: issue,
            Value: value,
            OnAfterRecorditemSaved: onAfterRecorditemSaved,
            OnAfterWindowDestroyed: CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack,
            IsReadonly: IssueView.IsRecordingLocked(checkpoint)
        });
    }

    export module ReferenceForm {
        const spinnerLock = 'reference-form';

        function hideSpinner() {
            Utils.Spinner.Unlock(spinnerLock);
            Utils.Spinner.Hide();
        }

        export function GetCurrentResubItemElementDictionary(): Dictionary<Model.Elements.Element> {
            const currentResubElements: Dictionary<Model.Elements.Element> = {};

            for (let gCnt = 0; gCnt < resubmissionTree.Form.Parametergroups.length; gCnt++) {
                const group = resubmissionTree.Form.Parametergroups[gCnt];

                if (!group.Parameters || !group.Parameters.length) {
                    continue;
                }

                for (let pCnt = 0; pCnt < group.Parameters.length; pCnt++) {
                    const parameter = group.Parameters[pCnt];
                    currentResubElements[`${parameter.OID}_${parameter.Row || 0}`] = parameter;
                }
            }

            return currentResubElements;
        }

        export function PrepareIssueGroups(issue: Model.Issues.Issue, elements: Array<Model.Elements.Element>): Array<Model.Elements.Element> {
            if (elements == null || issue == null) {
                return [];
            }

            const elementDict: Dictionary<Model.Elements.Element> = {};
            for (let i = 0; i < elements.length; i++) {
                const el = elements[i];
                elementDict[el.OID] = el;
            }

            const resubRoot = <any>getResubmissionitemRoot(issue, elementDict);
            const groups: Array<Model.Elements.Element> = [];
            const currentResubElements = GetCurrentResubItemElementDictionary();
            const recorditemsByResubmissionitemOID = getRecorditemsByResubmissionitemOID(issue);

            // build up groups with elements
            const newElements: Model.Elements.Element[] = [];
            for (let i = 0, len = resubRoot.Children.length; i < len; i++) {
                const groupResubmissionitem = resubRoot.Children[i];
                const groupElement = Utils.CloneObject(elementDict[groupResubmissionitem.ElementOID]);

                if (!groupElement) {
                    continue;
                }

                groups.push(groupElement);
                newElements.push(groupElement);

                if (groupResubmissionitem.Row) {
                    groupElement.Row = groupResubmissionitem.Row;
                    groupElement.Title = `${groupResubmissionitem.Row}. ${groupElement.Title}`;
                }

                groupElement.IsSelectable = false;

                if (groupResubmissionitem.Children) {
                    groupElement.Parameters = [];

                    for (let c = 0, pLen = groupResubmissionitem.Children.length; c < pLen; c++) {
                        const parameterResubItem = groupResubmissionitem.Children[c];
                        const parameter = Utils.CloneObject(elementDict[parameterResubItem.ElementOID]);
                        const lastRecorditem = recorditemsByResubmissionitemOID[parameterResubItem.OID] || null;
                        const preparedParameter = prepareIssueParameter(parameterResubItem, parameter, groupElement,
                            lastRecorditem, currentResubElements);

                        if (Utils.IsSet(preparedParameter)) {
                            newElements.push(preparedParameter);
                        }
                    }
                }
            }

            for (let i = 0, len = newElements.length; i < len; i++) {
                const el: Model.Elements.Element = newElements[i];

                if (!checkRequirements(el, newElements)) {
                    el.IsHidden = true;

                    if (el.Parameters && el.Parameters.length) {
                        el.Parameters.forEach(p => {
                            p.IsHidden = true;
                        });
                    }
                }
            }

            return groups;
        }

        function getResubmissionitemRoot(issue: Model.Issues.Issue, elementDict: Dictionary<Model.Elements.Element>): Model.Issues.ResubmissionItem {
            // sort resub items by position and row
            issue.Resubmissionitems.sort((a, b) => {
                const elA = elementDict[a.ElementOID];
                const elB = elementDict[b.ElementOID];

                if (elA && elB && elA.Position != elB.Position) {
                    return elA.Position - elB.Position;
                }

                if (a.Row == b.Row) {
                    return 0;
                }
                return (a.Row || 0) - (b.Row || 0);
            });

            const resubDict = {};
            let resubRoot: Model.Issues.ResubmissionItem;

            // create resub item dictionary and get root
            for (let i = 0; i < issue.Resubmissionitems.length; i++) {
                const resubItem = issue.Resubmissionitems[i];
                resubDict[resubItem.OID] = resubItem;

                if (!resubItem.ParentOID) {
                    resubRoot = resubItem;
                }

                // Parent und Children entfernen, falls diese gesetzt wurden, da diese weiter unten neu bestimmt werden
                delete resubItem.Parent;
                delete (<any>resubItem).Children;
            }

            // assign parameters to groups
            for (let i = 0; i < issue.Resubmissionitems.length; i++) {
                const resubItem = issue.Resubmissionitems[i];

                if (resubItem.ParentOID) {
                    const parent = resubDict[resubItem.ParentOID];
                    resubItem.Parent = parent;
                    (<any>parent).Children = (<any>parent).Children || [];
                    (<any>parent).Children.push(resubItem);
                }
            }

            return resubRoot;
        }

        function getRecorditemsByResubmissionitemOID(issue: Model.Issues.Issue): Dictionary<Model.Recorditem> {
            const recordByResub: Dictionary<Model.Recorditem> = {};

            if (!Utils.IsSet(issue)) {
                return recordByResub;
            }

            for (let rix = 0, riLen = (issue.Recorditems || []).length; rix < riLen; rix++) {
                const recorditem = issue.Recorditems[rix];
                recordByResub[recorditem.ResubmissionitemOID] = recorditem;
            }

            return recordByResub;
        }

        function prepareIssueParameter(resubitem: Model.Issues.ResubmissionItem, parameter: Model.Elements.Element,
            group: Model.Elements.Element, lastRecorditem: Model.Recorditem,
            currentResubElements: Dictionary<Model.Elements.Element>): Model.Elements.Element {

            if (!parameter) {
                return null;
            }

            parameter.IsSelectable = false;
            delete parameter.SelectionIdentifier;

            parameter.Parent = group;
            group.Parameters.push(parameter);
            parameter.Row = resubitem.Row;
            parameter.LastRecorditem = lastRecorditem;

            if (Utils.IsSet(lastRecorditem)) {
                Utils.PrepareRecorditem(lastRecorditem);
            }

            if (parameter.Type === Enums.ElementType.Info ||
                parameter.Type === Enums.ElementType.Signature) {
                return parameter;
            }

            if (!Utils.IsSet(parameter.LastRecorditem)) {
                return parameter;
            }

            // Teilproben Nummern > 1 ignorieren, da diese ggf. noch bei der Übernahme angelegt werden müssen
            const currentParameter = currentResubElements[`${parameter.OID}_${Math.min(parameter.Row || 0, 1)}`];

            if (!currentParameter || IssueView.IsRecordingLocked(currentParameter)) {
                return parameter;
            }

            if (currentParameter.Type === parameter.Type && !currentParameter.DisableInAppEditing) {
                parameter.IsSelectable = true;
                group.IsSelectable = true;
                parameter.SelectionIdentifier = `${parameter.OID}_${parameter.Row || 0}`;
            }

            return parameter;
        }

        export function ShowReferenceForm() {
            const issueId = $(this).data('id');

            DAL.Issues.GetByID(issueId, ['withrecorditems=true'], true)
                .then(onReferenceFormIssueLoaded);
        }

        function onReferenceFormIssueLoaded(issue: Model.Issues.Issue): void {
            if (!Utils.IsSet(issue)) {
                return;
            }

            IssueView.getRevisionsFromService(issue)
                .then(function(elements: Array<Model.Elements.Element>) {
                    const groups = ReferenceForm.PrepareIssueGroups(issue, elements);
                    const $cd = $(getSimplifiedView(issue, groups));

                    $cd.css('opacity', 0);

                    const currentIssue = IssueView.GetCurrentIssue();
                    if (!$cd.find('.parameter-selection').length || !currentIssue || currentIssue.IsLocked) {
                        $cd.find('.copy-values').toggleClass('hidden', true);
                    }

                    $('body').append(Utils.Overlay.Generate('olReview', 999));
                    $('body').append($cd);

                    const $images = $cd.find('.parameter-list .image img');
                    $images.off().on('click', $.proxy(onReferenceFormFileClick, groups));

                    loadImagesManually($images);

                    $images.each(function() {
                        const $img = $(this);

                        $img.on('load', initMarksForImage.bind(this, groups));
                    });

                    $images.on('error', Utils.OnImageNotFound);

                    if (Session.IsRunningOnIOS) {
                        // xhr reload image for WkWebView
                        $images.each(function(idx, img) {
                            Utils.XHRLoadImage(img.src)
                                .then(function(response) {
                                    img.src = window.URL.createObjectURL(response);
                                });
                        });
                    }

                    $cd
                        .animate({ opacity: 1.0 }, 400, hideSpinner)
                        .on('click', '.icon-zoom-in, .icon-zoom-out', onReferenceFormScaleChange)
                        .on('click', '.group-selection', onGroupCheckboxClick)
                        .on('click', '.parameter-selection', onParameterCheckboxClick)
                        .on('click', '.parameter-comment-selection, .image-selection', onCommentOrAdditionalImageCheckboxClick)
                        .on('click', '.copy-values', onTransferReferenceFormDataClick.bind(this, $cd, issue, elements));

                    $cd.find('iframe.info-text').on('load', Utils.OnIframeLoaded);
                });
        }

        function loadImagesManually($images): void {
            if (!$images || !Session.IsSmartDeviceApplication) {
                return;
            }

            $images.toArray().forEach(img => {
                const $img = $(img);

                if (!!$img.attr('src')) {
                    return;
                }

                const filename = $img.data('filename');

                Utils.Http.LoadImage(`${Session.BaseURI}images/s/${filename}`)
                    .then(
                        function(base64: string) {
                            $img.attr('src', base64);
                        },
                        function() {
                            Utils.OnImageNotFound.call($img);

                            // fehlende Bilder sollen nicht kopiert werden können
                            let $checkbox = $img.parent().find('input.image-selection');
                            if ($checkbox && $checkbox.length) {
                                // fehlende AdditionalImages abwählen und nicht wählbar machen
                                $checkbox.prop('checked', false).attr('disabled', true);
                            } else {
                                // PP Bilder abwählen, weitere PP Daten anwählbar lassen
                                $checkbox = $img.closest('tr').find('input.parameter-selection');
                                $checkbox.prop('checked', false);

                                onParameterCheckboxClick.call($checkbox);
                            }
                        }
                    );
            });
        }

        function initMarksForImage(groups): void {
            const $image = $(this);
            const $marks = $image.next('.marks') || [];

            if ($marks.length === 0) {
                const $svg = $image.next('svg') || [];

                if ($svg.length === 0) {
                    return;
                }

                $image.parent().css({ 'position': 'relative' });
                $image.after(`<div class="marks" style="position: absolute; top: ${this.offsetTop}px; width: ${$image.width()}px;"></div>`);
                const $mark = $image.next('.marks');
                $mark.append($svg);
                $mark.on('click', $.proxy(onReferenceFormFileClick, groups));
            } else {
                $marks.css({
                    position: 'absolute',
                    top: `${this.offsetTop}px`,
                    left: `${this.offsetLeft}px`,
                    height: `${$image.height()}px`,
                    width: `${$image.width()}px`
                });

                $marks.off().on('click', $.proxy(onReferenceFormFileClick, groups));
            }
        }

        function onReferenceFormScaleChange(evt): void {
            const $cd = $('.issue-review');
            const $btn = $(evt.target);
            const $container = $cd.find('.scroll-container > div');
            const matrixRegex = /matrix\((-?\d*\.?\d+),\s*0,\s*0,\s*(-?\d*\.?\d+),\s*0,\s*0\)/;
            const matches = $container.css('transform').match(matrixRegex);
            let scale = parseFloat(matches[1] || 1);

            if ($btn.hasClass('icon-zoom-in')) {
                scale += 0.25;
                scale = Math.min(scale, 2);
            } else {
                scale -= 0.25;
                scale = Math.max(scale, 0.25);
            }

            if (scale <= 1) {
                $container
                    .css('width', Math.floor(100 / scale) + '%');
            }

            $container.css('transform', `scale(${scale})`);
        }

        function onTransferReferenceFormDataClick($referenceForm: any, issue: Model.Issues.Issue,
            elements: Array<Model.Elements.Element>): void {
            if (!Utils.IsSet($referenceForm) || !Utils.IsSet(elements)) {
                return;
            }

            const selectedItems: Array<string> = $referenceForm.find('.parameter-selection:checked')
                .map((i, cb) => $(cb).data('identifier'));

            if (!(selectedItems || []).length) {
                Utils.Message.Show(
                    i18next.t('ReferenceForm.TransferData.NoSelectionMessage.Header'),
                    i18next.t('ReferenceForm.TransferData.NoSelectionMessage.Body'),
                    { OK: true },
                    '',
                    15500);
                return;
            }

            Utils.Spinner.Show(null, null, null, 15000);
            Utils.Spinner.Lock(spinnerLock);

            const groups = ReferenceForm.PrepareIssueGroups(issue, elements);
            const parametersByRowIdentifier = {};

            for (let group of groups) {
                for (let parameter of (group.Parameters || [])) {
                    parametersByRowIdentifier[`${parameter.OID}_${parameter.Row || 0}`] = parameter;
                }
            }

            const currentResubElements = ReferenceForm.GetCurrentResubItemElementDictionary();
            const parametersToCopy: Array<Model.Elements.Element> = [];
            let overrideValues = false;

            for (let selectedItemIdentifier of selectedItems) {
                const parameter = parametersByRowIdentifier[selectedItemIdentifier];

                if (!Utils.IsSet(parameter)) {
                    continue;
                }

                if (parameter.Type < 100) {
                    continue;
                }

                parametersToCopy.push(parameter);

                const currentParameter = currentResubElements[selectedItemIdentifier];

                if (currentParameter && Model.Recorditem.IsRecorditemRecorded(currentParameter.LastRecorditem)) {
                    overrideValues = true;
                }
            }

            const selectedComments = new Utils.HashSet($referenceForm.find(`.parameter-comment-selection:checked`)
                .map((i, cb) => $(cb).data('identifier')).toArray());
            const selectedImages = new Utils.HashSet($referenceForm.find(`.image-selection:checked`)
                .map((i, cb) => $(cb).data('identifier')).toArray());

            parametersToCopy.sort(Utils.SortByParentPositionAndRowThenByPositionAndRow);

            const subsampleCounters: Dictionary<number> = {};
            const missingSubsamplesDictionary: Dictionary<number> = {};

            // Teilproben auffüllen
            for (let parameter of parametersToCopy) {
                if (!parameter.Row) {
                    continue;
                }

                const row = (subsampleCounters[parameter.OID] || 0) + 1;
                parameter.Row = row;
                subsampleCounters[parameter.OID] = row;

                const currentParameter = currentResubElements[`${parameter.OID}_${row}`];
                if (currentParameter) {
                    if (Model.Recorditem.IsRecorditemRecorded(currentParameter.LastRecorditem)) {
                        overrideValues = true;
                    }
                } else {
                    if (!missingSubsamplesDictionary[parameter.ParentOID] ||
                        missingSubsamplesDictionary[parameter.ParentOID] < row) {
                        missingSubsamplesDictionary[parameter.ParentOID] = row;
                    }
                }
            }

            const missingSubsamples: Array<{ GroupOID: string, MaxRow: number }> = [];
            for (let groupOID in missingSubsamplesDictionary) {
                if (missingSubsamplesDictionary.hasOwnProperty(groupOID)) {
                    missingSubsamples.push({
                        GroupOID: groupOID,
                        MaxRow: missingSubsamplesDictionary[groupOID]
                    })
                }
            }

            function copy() {
                Utils.Spinner.Show();

                IssueView.AddSubsamples(missingSubsamples, true)
                    .then(() => copyValues(parametersToCopy, selectedComments, selectedImages), onCreateSubsampleError)
                    .then((recorditems: Array<Model.Recorditem>) => {
                        let calculateDeferred = $.Deferred().resolve();

                        hideSpinner();

                        for (let i = 0; i < recorditems.length; i++) {
                            calculateDeferred = calculateDeferred
                                .then(() => CalculatedCheckpoints.onAfterRecorditemWindowDestroyedStepHack(recorditems[i], null));
                        }

                        return calculateDeferred;
                    })
                    .then(() => {
                        Utils.Spinner.HideWithTimeout();
                        // Formular Ansicht aktualisieren
                        RefreshView();
                    })
                    .then(() => {
                        Utils.Message.Show(
                            i18next.t('ReferenceForm.TransferData.Success.MessageHeader'),
                            i18next.t('ReferenceForm.TransferData.Success.MessageBody'),
                            {
                                Close: true
                            }, null, 16001
                        );

                        for (const groupIdentifier in missingSubsamplesDictionary) {
                            IssueView.UpdateSidebarIndex(groupIdentifier);
                        }
                    })
                    .fail(onCopyError)
                    .always(hideSpinner);
            }

            if (overrideValues) {
                Utils.Message.Show(
                    i18next.t('ReferenceForm.TransferData.OverrideExistingRecorditemMessage.Header'),
                    i18next.t('ReferenceForm.TransferData.OverrideExistingRecorditemMessage.Body'),
                    {
                        Yes: copy,
                        No: hideSpinner
                    },
                    null,
                    16000);
            } else {
                copy();
            }
        }

        function onCreateSubsampleError(): void {
            hideSpinner();

            Utils.Message.Show(
                i18next.t('ReferenceForm.TransferData.Error.CreateSubsample.Header'),
                i18next.t('ReferenceForm.TransferData.Error.CreateSubsample.Body'),
                {
                    Close: true
                },
                null,
                16000
            );
        }

        function onCopyError(): void {
            hideSpinner();

            Utils.Message.Show(
                i18next.t('ReferenceForm.TransferData.Error.CopyValues.Header'),
                i18next.t('ReferenceForm.TransferData.Error.CopyValues.Body'),
                {
                    Close: true
                },
                null,
                16000
            );
        }

        function copyValues(referenceParameters: Array<Model.Elements.Element>, selectedComments: Utils.HashSet, selectedImages: Utils.HashSet): Deferred {
            const queue = getCopyValueQueue(referenceParameters, selectedComments, selectedImages);

            if (!queue.length) {
                return $.Deferred().resolve().promise();
            }

            return Utils.RecorditemEditor.SaveBatchValues(queue)
                .then((recorditems: Array<Model.Recorditem>) => {
                    return afterSaveBatchValues(recorditems)
                        .then(() => recorditems);
                });
        }

        function getCopyValueQueue(referenceParameters: Array<Model.Elements.Element>, selectedComments: Utils.HashSet, selectedImages: Utils.HashSet): Utils.RecorditemEditor.SaveOptions[] {
            const currentIssue = IssueView.GetCurrentIssue();
            const queue: Utils.RecorditemEditor.SaveOptions[] = [];
            const currentElements = ReferenceForm.GetCurrentResubItemElementDictionary();

            for (let rCnt = 0; rCnt < referenceParameters.length; rCnt++) {
                const referenceParameter = referenceParameters[rCnt];
                const currentParameter = currentElements[`${referenceParameter.OID}_${referenceParameter.Row || 0}`];
                const referenceRecorditem = referenceParameter.LastRecorditem;

                if (!Utils.IsSet(currentParameter) ||
                    !Utils.IsSet(referenceParameter.LastRecorditem.Value)) {
                    continue;
                }

                const data: Utils.RecorditemEditor.SaveOptions = {
                    Element: currentParameter,
                    PreviousRecorditem: currentParameter.LastRecorditem,
                    Row: currentParameter ? currentParameter.Row : null,
                    Issue: currentIssue,
                    Value: referenceRecorditem.Value,
                    Calculated: false,
                    IgnoreValueFromUI: true,
                    ReferenceData: {}
                };

                if (currentParameter.Type === Enums.ElementType.Photo) {
                    data.Value = uuid() + '.png';
                    data.ReferenceData.ImageValue = referenceRecorditem.Value;
                    data.ReferenceData.AdditionalValueInfo = referenceRecorditem.AdditionalValueInfo;
                }

                // ausgewählte Kommentare übernehmen
                if ((referenceRecorditem.Comments || []).length && Utils.IsSet(selectedComments)) {
                    const comments: Array<Model.IComment> = [];
                    const now = new Date()

                    for (const comment of referenceRecorditem.Comments) {
                        if (!selectedComments.has(comment.OID)) {
                            continue;
                        }

                        const newComment = new Model.Comment(uuid(), now, now, Session.User.OID, Session.User.OID, comment.Text, 'null', comment.Type);
                        newComment.IsCopy = true;
                        newComment.OriginalComment = comment.OID;
                        comments.push(newComment);
                    }

                    if (comments.length) {
                        data.ReferenceData.Comments = comments;
                    }
                }

                // gewählte Dateianhänge kopieren
                if ((referenceRecorditem.AdditionalFiles || []).length && Utils.IsSet(selectedImages)) {
                    const additionalImages = {};

                    for (const additionalFile of referenceRecorditem.AdditionalFiles) {
                        if (!selectedImages.has(additionalFile.Filename) || !additionalFile.IsImage) {
                            continue;
                        }

                        const additionalImageCopy = $.extend(true, {}, additionalFile);
                        const oid = uuid();

                        // Wichtig! HistoricalFilePath darf nicht bleiben
                        delete additionalImageCopy.HistoricalFilePath;

                        additionalImageCopy.OriginalFilename = additionalImageCopy.Filename;
                        additionalImageCopy.Filename = `${oid}.png`;    // TODO original Dateityp übernehmen! => #9281
                        additionalImageCopy.IsCopy = true;
                        additionalImageCopy.ModificationType = Enums.AdditionalImageModificationType.CREATED;

                        additionalImages[oid] = additionalImageCopy;
                    }

                    if (Utils.HasProperties(additionalImages)) {
                        data.ReferenceData.AdditionalImages = additionalImages;
                    }
                }

                if (!Utils.HasProperties(data.ReferenceData)) {
                    delete data.ReferenceData;
                }

                queue.push(data);
            }

            return queue;
        }

        function afterSaveBatchValues(recorditems: Array<Model.Recorditem>): Deferred {
            // set recordItem
            if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection],
                View.CurrentView)) {
                let saveDef = $.Deferred().resolve();

                const rLen = (recorditems || []).length
                for (let i = 0; i < rLen; i++) {
                    const recorditem = recorditems[i];
                    let param: Model.Elements.Element;

                    if (View.CurrentView == Enums.View.Form || View.CurrentView == Enums.View.FormBatchEdit || View.CurrentView == Enums.View.Inspection) {
                        param = GetParameter(recorditem.ElementRevisionOID, recorditem.Row);
                    } else if (View.CurrentView === Enums.View.Scheduling) {
                        param = GetParameter(recorditem.ElementRevisionOID);
                    } else {
                        param = GetParameter(recorditem.ElementOID);
                    }

                    if (param) {
                        param.LastRecorditem.Value = recorditem.Value;

                        if ((recorditem.Comments || []).length) {
                            param.LastRecorditem.Comments = recorditem.Comments;
                        }
                    }

                    saveDef = saveDef.then(() => IssueView.SetRecorditem(recorditem, i !== rLen - 1));
                }

                return saveDef;
            }

            return $.Deferred().resolve().promise();
        }

        function onGroupCheckboxClick() {
            const $issueReview = $('.issue-review');
            const $cb = $(this);
            const groupIdentifier = $cb.data('identifier');

            const $parameterCheckboxes = $issueReview
                .find(`.parameter-selection[data-parentidentifier="${groupIdentifier}"]`);

            const newCheckedState = !!$cb.prop('checked');
            $parameterCheckboxes.prop('checked', newCheckedState);

            for (let cnt = 0; cnt < $parameterCheckboxes.length; cnt++) {
                const parameterIdentifier = $parameterCheckboxes.eq(cnt).data('identifier');

                $issueReview
                    .find(`.parameter-comment-selection[data-parentidentifier="${parameterIdentifier}"],.image-selection[data-parentidentifier="${parameterIdentifier}"]`)
                    .prop('checked', newCheckedState);
            }
        }

        function onParameterCheckboxClick() {
            const $issueReview = $('.issue-review');
            const $cb = $(this);
            const identifier = $cb.data('identifier');
            const groupIdentifier = $cb.data('parentidentifier');
            const isChecked = !!$cb.prop('checked');

            $issueReview
                .find(`.parameter-comment-selection[data-parentidentifier="${identifier}"],.image-selection[data-parentidentifier="${identifier}"]`)
                .prop('checked', isChecked);

            if (!isChecked) {
                $issueReview
                    .find(`.group-selection[data-identifier="${groupIdentifier}"]`)
                    .prop('checked', false);
                return;
            }

            const $checkboxes = $issueReview
                .find(`.parameter-selection[data-parentidentifier="${groupIdentifier}"]`);

            // Sind alle Checkboxen einer Gruppe ausgewählt
            if ($checkboxes.filter(':checked').length == $checkboxes.length) {
                $issueReview
                    .find(`.group-selection[data-identifier="${groupIdentifier}"]`)
                    .prop('checked', true);
            }
        }

        function onCommentOrAdditionalImageCheckboxClick() {
            const $issueReview = $('.issue-review');
            const $cb = $(this);
            const parameterIdentifier = $cb.data('parentidentifier');
            const isChecked = !!$cb.prop('checked');

            if (isChecked) {
                $issueReview
                    .find(`.parameter-selection[data-identifier="${parameterIdentifier}"]`)
                    .prop('checked', true);
            }
        }
    }

    module CalculatedCheckpoints {
        class DependencyDescription {
            InfluencedBy: Utils.HashSet;
            Influences: Utils.HashSet;
            InfluencedByRequirements: Utils.HashSet;

            constructor() {
                this.InfluencedBy = new Utils.HashSet();
                this.Influences = new Utils.HashSet();
                this.InfluencedByRequirements = new Utils.HashSet();
            }
        }

        const formulaResolver: FormulaResolver = new window.Formula({ functions: window.Formula.Functions });

        let dependencies: Dictionary<DependencyDescription>;
        let callbacks = [];
        let defaultProcess = null;
        let finishedSingle: boolean = true;
        let finishedBatch: boolean = true;
        let saveLine = $.Deferred().resolve().promise();
        let dependentParametersOfIssueFormula = {};

        export function ClearIssueFormulaDependicies(): void {
            dependentParametersOfIssueFormula = {};
        }

        /**
         * Aktualisiert die Abgängigkeiten zwischen den Prüfpunkten und zur Formel des Vorgangstitels
         */
        export function setIssueTitleFormulaDependencies(updateIssueTitle: boolean = true): void {
            const currentIssue = IssueView.GetCurrentIssue();

            if (currentIssue == null || isIssueTitleOverwritten(currentIssue) || currentIssue.AssignedFormOID == null) {
                return;
            }

            const formRevisionOID = currentIssue.AssignedFormRevisionOID;
            const form = GetElementRevisionByRevisionOID(formRevisionOID);

            if (form == null || form.IssueTitleFormula == null) {
                // Formular hat keine Vorgangstitel-Formel
                return;
            }

            // Abhängigkeiten ermitteln
            dependentParametersOfIssueFormula = getDependenciesForFormula(form.IssueTitleFormula).toDictionary();

            if (updateIssueTitle) {
                setNewIssueTitleFromFormula();
            }
        }

        function setNewIssueTitleFromFormula(): Deferred {
            const issue = IssueView.GetCurrentIssue();

            if (!issue) {
                onIssueTitleGenerationError();
                // Kein Vorgang gefunden
                return $.Deferred().resolve();
            }

            const state = (issue.State || DAL.Properties.GetByOID(issue.StateOID));

            if ((state && state.IsLockedState) || isIssueTitleOverwritten(issue)) {
                // Titel wurde überschrieben oder ist gesperrt und sollte nicht mehr generiert werden
                return $.Deferred().resolve();
            }

            const formRevisionOID = issue.AssignedFormRevisionOID;
            const form = GetElementRevisionByRevisionOID(formRevisionOID);

            if (form == null || form.IssueTitleFormula == null) {
                // Formular hat keine Vorgangstitel-Formel
                return $.Deferred().resolve();
            }

            const title = generateIssueTitleByFormula(form.IssueTitleFormula);

            if (Utils.UnescapeHTMLEntities(issue.Title) === title) {
                // Titel hat sich nicht geändert, brauch also nicht gespeichert werden
                return $.Deferred().resolve();
            }

            // Recht zum ändern des Vorgangs abfragen
            const rights = Utils.GetActiveUserRights(Session.User.OID, true, issue.AssignedElementOID);
            if (!Utils.UserHasIssueRight(issue, Enums.Rights.Issues_CreateOrModifyForms, rights) ||
                !Utils.UserHasIssueRight(issue, Enums.Rights.IssueProperties_Title, rights)) {
                Utils.Toaster.Show(i18next.t('Forms.MissingTitleRights.MessageBody'), 2);
                return $.Deferred().resolve();
            }

            return saveNewIssueTitle(issue, title, form)
                .then(() => IssueView.UpdateIssueInfo(issue));
        }

        function generateIssueTitleByFormula(issueTitleFormula: string): string {
            if (issueTitleFormula == null) {
                onIssueTitleGenerationError();
                return null;
            }

            try {
                const tokens = window.Formula.tokenize(issueTitleFormula);
                let result = formulaResolver.evaluate(tokens, null, true);

                // Bei TeilprobenWert-Funktion Aktualität der Abhängigkeit prüfen und aktualisieren
                if (issueTitleFormula.contains('SubsampleValue') &&
                    hasDynamicSubsampleRow(issueTitleFormula)) {
                    setIssueTitleFormulaDependencies(false);
                }

                if (result == null) {
                    return null;
                }

                if (result instanceof Date) {
                    result = Utils.DateTime.DateToString(result);
                }

                result = result.toString();

                // remove newline character
                result = result.replace(/<br\s?\/?>|\r\n|\n\r|\n/gi, ' ');

                if (result.length > 100) {
                    result = result.substr(0, 100);
                }

                return result;
            } catch (e) {
                // ¯\_(ツ)_/¯
                onIssueTitleGenerationError();
                return null;
            }
        }

        function onIssueTitleGenerationError(): void {
            Utils.Toaster.Show(
                i18next.t('IssueView.IssueTitleGenerationError'),
                8,
                Enums.Toaster.Icon.Warning
            );
        }

        function saveNewIssueTitle(issue: Model.Issues.Issue, title: string, form?: Model.Elements.Element): Deferred {
            if (issue == null || title == null) {
                return $.Deferred().reject().promise();
            }

            issue.Title = title;
            issue.AdditionalData = $.extend(true, issue.AdditionalData || {},
                {
                    IsIssueTitleGenerated: true,
                    AllowOverrideTitleValue: form ? !!form.AllowOverrideFormulaValue : true
                });

            const issueObject = new Model.TemporaryIssue(issue, null, GetDependingOIDOfIssue(issue));
            return issueObject.Save();
        }

        /**
         * Aktualisiert die Abgängigkeiten zwischen den berechten Prüfpunkten.
         * @param {Model.Elements.Element} [newParam]
         */
        export function updateParameterDependencies(newParam?: Model.Elements.Element): string[] {
            const paramGroups = GetFormulaGroups();

            if (!paramGroups) {
                return;
            }

            if (newParam) {
                updateDependencyDescriptionForParameter(newParam, dependencies);
                return determineParametersToRecalculate(newParam);
            }

            dependencies = getParameterDependencies(paramGroups);
        }

        function determineParametersToRecalculate(param: Model.Elements.Element): string[] {
            const identifier = createElementIdentifier(param);
            const paramDependencies = dependencies[identifier] || {
                Influences: null,
                InfluencedBy: null,
                InfluencedByRequirements: null
            };
            const influences = paramDependencies.Influences || new Utils.HashSet();
            const influencedBy = paramDependencies.InfluencedBy || new Utils.HashSet();
            const influencedByRequirements = paramDependencies.InfluencedByRequirements || new Utils.HashSet();
            let paramsToRecalculate: string[] = [];

            if (influences.size() > 0) {
                paramsToRecalculate = paramsToRecalculate.concat(influences.toArray());
            }

            // Ohne Reference-Token -> Neuberechnung ohne Einfluss von außen (z.B. wenn-dann)
            if ((influencedBy.size() > 0 || influencedByRequirements.size() > 0) && paramsToRecalculate.indexOf(identifier) === -1 ||
                !!param.Formula && !param.Formula.contains('#Element<')) {
                paramsToRecalculate.push(identifier);
            }

            return paramsToRecalculate;
        }

        function getParameterDependencies(groups: Model.Elements.Element[]): Dictionary<DependencyDescription> {
            const dependencies: Dictionary<DependencyDescription> = {};

            if (!groups.length) {
                return dependencies;
            }

            const groupsWithFormulas = groups.filter(g => {
                return (g.Parameters || []).some(p => {
                    return !!p.Formula;
                });
            });

            if (!groupsWithFormulas.length) {
                return dependencies;
            }

            groupsWithFormulas.forEach(group => {
                const parameters = group.Parameters.filter(p => {
                    return !!p.Formula;
                });

                if (!parameters.length) {
                    return;
                }

                const groupCriteria: Dictionary<Model.Elements.RequirementCriterion[]> = {};

                if (Utils.IsSet(group.Requirements) && Utils.HasProperties(group.Requirements)) {
                    groupCriteria[group.OID] = group.Requirements.Criteria;
                }

                parameters.forEach(param => updateDependencyDescriptionForParameter(param, dependencies, groupCriteria));
            });

            return dependencies;
        }

        function updateDependencyDescriptionForParameter(param: Model.Elements.Element,
            dependencies: Dictionary<DependencyDescription>,
            groupCriteria: Dictionary<Model.Elements.RequirementCriterion[]> = {}): void {
            const paramIdent = createElementIdentifier(param);
            const paramDependencies = getDependenciesForParameter(param);

            const parameterCriteria: Dictionary<Model.Elements.RequirementCriterion[]> = {};

            if (Utils.IsSet(param.Requirements) && Utils.HasProperties(param.Requirements)) {
                parameterCriteria[param.OID] = param.Requirements.Criteria;
            }

            const dependentRequirementParameters = getDependentRequirementParameters(param, groupCriteria, parameterCriteria);

            const paramHasDependencies = paramDependencies.size() > 0;
            const paramHasRequirementDependenciesSize = dependentRequirementParameters.size() > 0;

            if (paramHasDependencies || paramHasRequirementDependenciesSize) {
                dependencies[paramIdent] = dependencies[paramIdent] || new DependencyDescription();

                if (paramHasDependencies) {
                    dependencies[paramIdent].InfluencedBy.putSet(paramDependencies);

                    paramDependencies.toArray().forEach(influencesIdent => {
                        dependencies[influencesIdent] = dependencies[influencesIdent] || new DependencyDescription();
                        dependencies[influencesIdent].Influences.put(paramIdent);
                    });
                }

                if (paramHasRequirementDependenciesSize) {
                    dependencies[paramIdent].InfluencedByRequirements.putSet(dependentRequirementParameters);

                    dependentRequirementParameters.toArray().forEach(influencesIdent => {
                        dependencies[influencesIdent] = dependencies[influencesIdent] || new DependencyDescription();
                        dependencies[influencesIdent].Influences.put(paramIdent);
                    });
                }
            }

            // neue Teilproben zu den Abhängigkeiten der Vorgangstitel-Formel hinzufügem
            if (dependentParametersOfIssueFormula &&
                dependentParametersOfIssueFormula[createElementIdentifier(param.OID)] &&
                !dependentParametersOfIssueFormula[paramIdent]) {
                dependentParametersOfIssueFormula[paramIdent] = true;
            }
        }

        function getDependentRequirementParameters(param: Model.Elements.Element,
            groupCriteria: Dictionary<Model.Elements.RequirementCriterion[]>,
            parameterCriteria: Dictionary<Model.Elements.RequirementCriterion[]>): Utils.HashSet {
            const dependentParameters = new Utils.HashSet();

            const parameterGroup = param.Parent;

            function setDependencies(criterion: Model.Elements.RequirementCriterion): void {
                const params = GetElementsIndependentOfRow(criterion.ElementOID);

                if (!(params || []).length) {
                    return;
                }

                for (let pCnt = 0, pLen = params.length; pCnt < pLen; pCnt++) {
                    const param = params[pCnt];
                    const paramOID = createElementIdentifier(param);

                    if (!checkCriterion(param, criterion)) {
                        dependentParameters.put(paramOID);
                    }
                }
            }

            if (parameterGroup && Utils.IsSet(parameterGroup.Requirements) && groupCriteria[parameterGroup.OID] != null) {
                const groupRequirementIsFulfilled = checkRequirements(parameterGroup);
                const groupCriterias = groupCriteria[parameterGroup.OID];

                // wenn die Gruppe nicht angezeit ist, soll die Formelberechnung vom Prüfpunkt in der Gruppe erst ausgeführt werden, wenn die Prüfgruppe durch die Bedingung eingeblendet wird
                if (!groupRequirementIsFulfilled) {
                    groupCriterias.forEach(criterion => setDependencies(criterion));
                }
            }

            const parameterCriterias = parameterCriteria[param.OID];
            if (parameterCriterias != null) {
                const parameterRequirementIsFulfilled = checkRequirements(param);

                if (!parameterRequirementIsFulfilled) {
                    parameterCriterias.forEach(criterion => setDependencies(criterion));
                }
            }

            return dependentParameters;
        }

        function hasDynamicSubsampleRow(formula: string): boolean {
            // Funktion Tokens laden
            const subsampleValueFunctions = window.Formula.getFunctionCallTokens(formula, 'SubsampleValue');
            if (!subsampleValueFunctions || !subsampleValueFunctions.length) {
                return;
            }

            // Referenz Token für TeilprobenNr ermitteln
            return subsampleValueFunctions.some((fnDescriptor: { args: any[] }) => {
                return fnDescriptor.args[1].some((token) => {
                    return token instanceof Formula.ReferenceToken;
                });
            });
        }

        function updateVariableDependencyForSubSampleMethod(parameter: Model.Elements.Element, dependencies: Dictionary<DependencyDescription>): void {
            // Element Abhängigkeit bei TeilprobenNr feststellen
            if (hasDynamicSubsampleRow(parameter.Formula)) {
                updateDependencyDescriptionForParameter(parameter, dependencies);
            }
        }

        function getDependenciesForParameter(param: Model.Elements.Element): Utils.HashSet {
            if (!param) {
                return new Utils.HashSet();
            }
            return getDependenciesForFormula(param.Formula, param);
        }

        function getDependenciesForFormula(formula: string, param?: Model.Elements.Element): Utils.HashSet {
            const dependencies = new Utils.HashSet();

            if (!formula) {
                return dependencies;
            }

            const row = param ? param.Row || null : null;

            // Erweiterte Ermittlung der Abhängigkeit bei übergreifenden Teilproben-Funktion
            const supportedSubsampleTokens = ['Sum', 'Avg', 'Min', 'Max'];

            for (const tokenName of supportedSubsampleTokens) {
                const subsampleSumCalls = Formula.getFunctionCallTokens(formula, tokenName);

                if (subsampleSumCalls && subsampleSumCalls.length) {
                    // Teilproben berechnen
                    subsampleSumCalls.forEach((fnDescriptor: { args: any[], tokens: any[], callstack: string[] }) => {
                        // bei unzureichender Parameter Anzahl abbrechen
                        if ((fnDescriptor.args || []).length < 1) {
                            return;
                        }

                        // neue Abhängigkeit hinzufügen
                        const referencedParamOID = fnDescriptor.args[0][0];
                        const referencedParamIdent = `${referencedParamOID._id}`;
                        dependencies.put(referencedParamIdent);

                        const elements = GetElementsIndependentOfRow(referencedParamIdent);

                        if (elements.length > 0) {
                            for (let element of elements) {
                                if (element && !isNaN(element.Row) && element.Row) {
                                    dependencies.put(`${element.OID}_${element.Row}`);
                                }
                            }
                        }
                    });
                }
            }

            // Erweiterte Ermittlung der Abhängigkeit bei TeilprobenWert-Funktion
            const subsampleValueCalls = Formula.getFunctionCallTokens(formula, 'SubsampleValue');
            if (subsampleValueCalls && subsampleValueCalls.length) {
                // TeilprobenNr berechnen
                subsampleValueCalls.forEach((fnDescriptor: { args: any[], tokens: any[], callstack: string[] }) => {
                    // bei unzureichender Parameter Anzahl abbrechen
                    if ((fnDescriptor.args || []).length < 2) {
                        return;
                    }

                    // ermitteln der Zeil-TeilprobenNr
                    const subsampleNumberArgs = fnDescriptor.args[1];
                    const calculatedRowNumber = !!subsampleNumberArgs ? formulaResolver.evaluate(subsampleNumberArgs, param) : null;

                    // bei ungültiger oder gleicher Nr wie aktueller Parameter, keine Aktionen
                    // (aktuelle Row wird bei 'allgemein' hinzugefügt)
                    if (isNaN(calculatedRowNumber) || calculatedRowNumber === 0 || calculatedRowNumber == row) {
                        return;
                    }

                    // neue Abhängigkeit hinzufügen
                    const referencedParamOID = fnDescriptor.args[0][0];
                    const referencedParamIdent = `${referencedParamOID._id}_${calculatedRowNumber}`;
                    dependencies.put(referencedParamIdent);
                });
            }

            // Prüfpunkt-Referenzen allgemein ermitteln
            const tokens: any[] = window.Formula.tokenize(formula);

            tokens
                .filter(token => token instanceof Formula.ReferenceToken && token._id)
                .forEach(token => {
                    const referencedParamOID = token._id;

                    // einfache Abhängigkeit hinzufügen
                    dependencies.put(referencedParamOID);

                    // für den Fall dass die Referenz auf die gleiche Teilprobe gehen könnte
                    // (erspart komplexe Ermittlung ob Ziel-PP tatsächlich innerhalb einer Teilprobe ist.
                    //  Keine negativen Effekte falls es nicht so ist. PP ist entweder innerhalb einer Teilprobe oder nicht.)
                    if (!isNaN(row) && row) {
                        dependencies.put(`${referencedParamOID}_${row}`);
                    }
                });

            return dependencies;
        }

        /**
         * Berechnet den Wert eines Prüfpunkts und ggf. weiterer, abhängiger Prüfpunkte.
         * @param {string} selectedParamOID
         * @param {number} [row=null]
         * @param {boolean} [newSubsample=false]
         * @param {boolean} [selfCalculateParamter=false]
         * @returns {Deferred}
         */
        export function calculateFormulaValues(selectedParamOID: string, row: number = null, newSubsample: boolean = false, selfCalculateParameter: boolean = false): Deferred {
            finishedSingle = false;

            const calculationDeferred = $.Deferred();
            const paramGroups = GetFormulaGroups();
            const defaultBehaviour = function() {
                const def = onAfterRecorditemWindowDestroyed(defaultProcess.recorditem,
                    defaultProcess.correctiveActions,
                    defaultProcess.preventDefault);

                defaultProcess = null;
                return def;
            };

            if (!paramGroups) {
                // Standardverhalten ausführen
                if (defaultProcess) {
                    saveLine = saveLine.then(defaultBehaviour);
                }

                return calculationDeferred.resolve().promise();
            }

            Utils.Spinner.Show();
            Utils.Spinner.Lock();

            const param = GetParameter(selectedParamOID, row);

            if (row != null && !newSubsample) {
                updateDependencyDescriptionForParameter(param, dependencies);
            }

            const calculateSelectedParameter = (): Deferred => {
                if (!selfCalculateParameter) {
                    // Berechnung der Abhängigkeiten initial ausführen
                    return $.Deferred().resolve(true).promise();
                }

                return calculateAndSaveValueForParameter(param);
            };

            calculateSelectedParameter()
                .then((valueChanged: boolean) => valueChanged ? traverseDependencies(param) : null)
                .then(() => {
                    // Callbacks für Maßnahmen abarbeiten
                    while (callbacks.length > 0) {
                        const call = callbacks.shift();
                        saveLine = saveLine.then(function() {
                            Utils.Spinner.Unlock();
                            Utils.Spinner.HideWithTimeout();

                            return onAfterRecorditemWindowDestroyed(
                                call.recorditem,
                                call.correctiveActions,
                                call.preventDefault)
                                .then(() => {
                                    Utils.Spinner.Show();
                                    Utils.Spinner.Lock();
                                });
                        });
                    }


                    saveLine = saveLine.always(function() {
                        Utils.Spinner.Unlock();
                        Utils.Spinner.HideWithTimeout();
                    });

                    // Standardverhalten ausführen
                    if (defaultProcess) {
                        saveLine = saveLine.then(defaultBehaviour);
                    }

                    // Ende ohne Bedingung
                    finishedSingle = true;

                    calculationDeferred.resolve();
                }, function() {
                    Utils.Spinner.Unlock();
                    Utils.Spinner.HideWithTimeout();
                });

            return calculationDeferred.promise();
        }

        function traverseDependencies(param: Model.Elements.Element, influencedByElements: Dictionary<Utils.HashSet> = {}): Deferred {
            const deferred = $.Deferred();

            if (param == null) {
                return deferred.resolve().promise();
            }

            const influencedParameters = getInfluencedParameters(param);

            if (dependentParametersOfIssueFormula &&
                dependentParametersOfIssueFormula[createElementIdentifier(param)]) {
                setNewIssueTitleFromFormula();
            }

            if (!influencedParameters.length) {
                return deferred.resolve().promise();
            }

            const me = this;

            (function next(index: number, influencer?: Model.Elements.Element): void {
                const influencerIdentifier = createElementIdentifier(influencer);

                const onAfterReadyForNext = (valueChanged: boolean) => {
                    if (influencedParameters.length === 0) {
                        deferred.resolve();
                        return;
                    }

                    const newInfluencer = influencedParameters[index];

                    window.setTimeout(() => {
                        // wenn der nächste Parameter dem Influencer entspricht überspringen
                        if (influencerIdentifier === createElementIdentifier(newInfluencer) ||
                            !valueChanged) {
                            next(++index, influencer);
                        } else if (valueChanged) {
                            traverseDependencies(newInfluencer, influencedByElements)
                                .then(() => next(++index, influencer), deferred.resolve);
                        } else {
                            next(++index, influencer);
                        }
                    }, 5);
                };

                if (index > influencedParameters.length - 1) {
                    deferred.resolve();
                    return;
                }

                const parameter = influencedParameters[index];
                const isHidden = !parameterIsVisible(parameter);
                if (parameter && isHidden) {
                    // nicht berechnen, falls der Prüfpunkt durch Bedingungen ausgeblendet wurde
                    onAfterReadyForNext(false);
                    return;
                }

                const paramIdent = createElementIdentifier(parameter);
                let influencedBy = influencedByElements[paramIdent];

                if (influencerIdentifier === paramIdent &&
                    (!influencedBy || influencedBy instanceof Utils.HashSet && !influencedBy.has(influencerIdentifier))) {
                    influencedByElements[paramIdent] = influencedByElements[paramIdent] || new Utils.HashSet();
                    influencedByElements[paramIdent].put(influencerIdentifier);

                    // Berechnung für diesen Durchlauf abbrechen, da bereits erfolgt
                    onAfterReadyForNext(false);
                    return;
                }

                if (influencedBy && influencedBy.has(influencerIdentifier)) {
                    let abortCalculation = containsCircularReference(parameter, influencedBy);

                    if (abortCalculation) {
                        const group = ParameterList.GetElement(parameter.ParentOID, parameter.Row, true);

                        Utils.Spinner.Unlock();
                        Utils.Spinner.Hide();

                        Utils.Message.Show(
                            i18next.t('ParameterList.CircularReference.MessageHeader'),
                            i18next.t(
                                'ParameterList.CircularReference.MessageBody',
                                {
                                    groupTitle: group.Title,
                                    parameterTitle: parameter.Title
                                }),
                            { OK: { Caption: i18next.t('Misc.Okay') } });

                        deferred.reject('Circular reference');
                        return;
                    }
                }

                if (influencer) {
                    if (!influencedBy) {
                        influencedByElements[paramIdent] = new Utils.HashSet();
                        influencedBy = influencedByElements[paramIdent];
                    }

                    if (!!influencerIdentifier) {
                        influencedBy.put(influencerIdentifier);
                    }
                }

                calculateAndSaveValueForParameter.call(me, parameter)
                    .then(onAfterReadyForNext);
            })(0, param);

            return deferred.promise();
        }

        function containsCircularReference(parameter: Model.Elements.Element, influencedBy: Utils.HashSet): boolean {
            if (!parameter || !parameter.Formula) {
                return false;
            }

            const valueCalls = Formula.getFunctionCallTokens(parameter.Formula, 'Value');
            const subsampleValueCalls = Formula.getFunctionCallTokens(parameter.Formula, 'SubsampleValue');
            const subsampleSumCalls = Formula.getFunctionCallTokens(parameter.Formula, 'Sum');
            const subsampleAvgCalls = Formula.getFunctionCallTokens(parameter.Formula, 'Avg');
            const subsampleMinCalls = Formula.getFunctionCallTokens(parameter.Formula, 'Min');
            const subsampleMaxCalls = Formula.getFunctionCallTokens(parameter.Formula, 'Max');

            return checkWhetherTokensContainCircularReference('Value', valueCalls, influencedBy) ||
                checkWhetherTokensContainCircularReference('SubsampleValue', subsampleValueCalls, influencedBy) ||
                checkWhetherTokensContainCircularReference('Sum', subsampleSumCalls, influencedBy) ||
                checkWhetherTokensContainCircularReference('Avg', subsampleAvgCalls, influencedBy) ||
                checkWhetherTokensContainCircularReference('Min', subsampleMinCalls, influencedBy) ||
                checkWhetherTokensContainCircularReference('Max', subsampleMaxCalls, influencedBy);
        }

        function checkWhetherTokensContainCircularReference(fnName: string, calls: Array<{ args: any[], tokens: any[], callstack: string[] }>, influencedBy: Utils.HashSet): boolean {
            let containsCircularReference: boolean = false;

            try {
                calls.forEach((fnDescriptor: { args: any[], tokens: any[], callstack: string[] }) => {
                    if (containsCircularReference) {
                        return;
                    }

                    if (!(fnDescriptor.callstack || []).length) {
                        return;
                    }

                    const callIndex = fnDescriptor.callstack.indexOf(fnName);

                    if (callIndex > 0) {
                        const firstFn = fnDescriptor.callstack[0];

                        if ((firstFn === 'IfBlank' || firstFn === 'If') &&
                            callIndex === 1) {

                            const referenceTokens = (fnDescriptor.tokens || [])
                                .filter(token => token instanceof window.Formula.ReferenceToken);
                            const referenceIdentifiers = (referenceTokens || []).map(token => {
                                return token._id
                            });

                            (referenceIdentifiers || []).forEach((identifier) => {
                                if (influencedBy.has(identifier)) {
                                    containsCircularReference = true;
                                    return;
                                }
                            });

                            return;
                        }
                    }

                    for (let argIdx = 0, argsLen = fnDescriptor.args.length; argIdx < argsLen; argIdx++) {
                        const argTokens = fnDescriptor.args[argIdx];

                        for (let tokenIdx = 0, tokenLen = argTokens.length; tokenIdx < tokenLen; tokenIdx++) {
                            const token = argTokens[tokenIdx];

                            if (!(token instanceof window.Formula.ReferenceToken)) {
                                continue;
                            }

                            if (influencedBy.has(token._id)) {
                                containsCircularReference = true;
                                break;
                            }
                        }

                        if (containsCircularReference) {
                            break;
                        }
                    }
                });
            } catch (e) {
            }

            return containsCircularReference;
        }

        function calculateAndSaveValueForParameter(parameter: Model.Elements.Element): Deferred {
            const deferred = $.Deferred();
            const result = formulizeParam(parameter);

            // Prüfen ob Stammdaten nachgeladen werden mussten
            if (result instanceof Utils.LightPromise) {
                result.then((hasError: string | any) => {
                    if (!hasError) {
                        deferred.resolve();
                    } else {
                        deferred.reject(hasError);
                    }
                })

                return deferred
                    .then(() => calculateAndSaveValueForParameter(parameter));
            }

            // Bei TeilprobenWert-Funktion Aktualität der Abhängigkeit prüfen und aktualisieren
            if (parameter.Formula && parameter.Formula.contains('SubsampleValue')) {
                updateVariableDependencyForSubSampleMethod(parameter, dependencies);
            }

            if (typeof result == 'undefined') {
                return deferred.resolve(false).promise();
            }

            if (result === null &&
                (!parameter.LastRecorditem ||
                    parameter.LastRecorditem.IsDummy)) {
                return deferred.resolve(false).promise();
            }

            saveCalculatedValue.call(this, parameter, result)
                .then(() => deferred.resolve(true), () => deferred.resolve(false));

            return deferred.promise();
        }

        function getInfluencedParameters(referencedParam: Model.Elements.Element): Array<Model.Elements.Element> {
            const influences: Array<Model.Elements.Element> = [];

            const tmpDepencies = new Utils.HashSet();
            const paramIdentA = createElementIdentifier(referencedParam);
            const tmpDependenciesA = dependencies[paramIdentA];

            if (tmpDependenciesA) {
                const influencedIdentifiersA = tmpDependenciesA.Influences;
                if (influencedIdentifiersA && influencedIdentifiersA.size()) {
                    influencedIdentifiersA.toArray().forEach((ident: string) => {
                        tmpDepencies.put(ident);

                        const parts = ident.split('_');
                        const oid = parts[0];
                        const row = parseInt(parts[1], 10);

                        influences.push(GetElement(oid, !isNaN(row) ? row : null));
                    });
                }
            }

            const paramIdentB = createElementIdentifier(referencedParam.OID);
            const tmpDependenciesB = dependencies[paramIdentB];
            if (tmpDependenciesB) {
                const influencedIdentifiersB = tmpDependenciesB.Influences;
                if (influencedIdentifiersB && influencedIdentifiersB.size()) {
                    influencedIdentifiersB.toArray().forEach((ident: string) => {
                        if (tmpDepencies.has(ident)) {
                            return;
                        }

                        tmpDepencies.put(ident);

                        const parts = ident.split('_');
                        const oid = parts[0];
                        const row = parseInt(parts[1], 10);

                        influences.push(GetElement(oid, !isNaN(row) ? row : null));
                    });
                }
            }

            return influences;
        }

        function ignoreFormulaIfLastValueIsNotAllowed(parameter: Model.Elements.Element): boolean {
            if (View.CurrentView === Enums.View.Main) {
                return false;
            }

            const tokens = window.Formula.tokenize(parameter.Formula);

            for (let to = 0; to < (tokens || []).length; to++) {
                const token = tokens[to];

                if (token._name === 'LastValue') {
                    return true;
                }
            }

            return false;
        }

        /**
         * Wird für die Berechnung beim Öffnen eines Formulars benötigt.
         */
        export function precalculateFormulaValues(): Deferred {
            const paramGroups = GetFormulaGroups();

            return recalculateGroups(paramGroups);
        }

        /*
        * Berechnet Prüfpunkte für angegebene Gruppen neu
        */
        export function recalculateGroups(paramGroups: Model.Elements.Element[]): Deferred {
            if (!paramGroups || !paramGroups.length || !finishedSingle) {
                return $.Deferred().resolve();
            }

            finishedBatch = false;

            const calculateQueue = [];
            const currentIssue = IssueView.GetCurrentIssue() || null;

            Utils.Spinner.Show();
            const calcLock = Utils.Spinner.Lock('recalculateGroups');

            function saveCalculatedValues(): Deferred {
                // Werte speichern als batch
                if (calculateQueue.length > 0) {
                    let hasIssueTitleAffection = false;

                    return Utils.RecorditemEditor.SaveBatchValues(calculateQueue)
                        .then((recorditems: Array<Model.Recorditem>) => {
                            // Recorditem am Vorgang setzen
                            if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
                                let savesDef = $.Deferred().resolve();

                                for (let i = 0; i < recorditems.length; i++) {
                                    const recorditem = recorditems[i];
                                    let param: Model.Elements.Element;

                                    if (View.CurrentView == Enums.View.Form || View.CurrentView == Enums.View.FormBatchEdit || View.CurrentView == Enums.View.Inspection) {
                                        param = GetParameter(recorditem.ElementRevisionOID, recorditem.Row);
                                    } else if (View.CurrentView === Enums.View.Scheduling) {
                                        param = GetParameter(recorditem.ElementRevisionOID);
                                    } else {
                                        param = GetParameter(recorditem.ElementOID);
                                    }

                                    if (param) {
                                        // aktualisiertes Recorditem am Element setzen
                                        param.LastRecorditem = recorditem;

                                        // Prüfen ob Parameter einer Teilprobe zugehört
                                        const refIdentifier = createElementIdentifier(param.OID, recorditem.Row);

                                        // Abhängigkeit zum aktuellen paramOID ermitteln
                                        hasIssueTitleAffection = dependentParametersOfIssueFormula[refIdentifier] || hasIssueTitleAffection;
                                    }

                                    savesDef = savesDef
                                        .then(() => IssueView.SetRecorditem(recorditem, i !== recorditems.length - 1));
                                }

                                return savesDef.then(() => recorditems);
                            }

                            return recorditems;
                        })
                        .then((recorditems: Array<Model.Recorditem>) => {
                            // alle betroffenen Zellen aktualisieren
                            let updateDeferreds = $.Deferred().resolve();

                            recorditems.forEach((rec) => {
                                // abhängig Deferred nutzen
                                updateDeferreds = updateDeferreds.then(() =>
                                    UpdateElementCell(rec, false)
                                );
                            });

                            // Titel Generierung über Formeln
                            if (hasIssueTitleAffection && isIssueTitleByFormulaEnabled()) {
                                return updateDeferreds.then(() => setNewIssueTitleFromFormula());
                            }

                            return updateDeferreds;
                        })
                        .always(() => {
                            // Ende mit Bedingungen
                            finishedBatch = true;

                            if (calcLock) {
                                calcLock.Unlock();
                            }
                        })
                        .then(() => {
                            Utils.Spinner.HideWithTimeout();

                            // Prüfpunkt Sichtbarkeit aktualisieren
                            return updateElementVisibility();
                        }, (err) => {
                            Utils.Spinner.Hide();
                            throw new Error(err);
                        });
                } else {
                    finishedBatch = true;

                    if (calcLock) {
                        calcLock.Unlock();
                    }

                    Utils.Spinner.HideWithTimeout();
                }

                return $.Deferred().resolve();
            }

            // Werte für die einzelnen Prüfpunkte berechnen
            for (const group of paramGroups) {
                // nur sichtbare Prüfgruppen berechnen
                if (group.IsHidden || group.MissingRequirements || !group.Parameters) {
                    continue;
                }

                const params = group.Parameters.filter(p => !!p.Formula || p.IsHidden || p.MissingRequirements);

                for (const param of params) {
                    const result = formulizeParam(param);

                    if (result instanceof Utils.LightPromise) {
                        const deferred = $.Deferred();
                        const saveDeferred = saveCalculatedValues();

                        // Gruppen neu berechnen, nachdem fehlende Stammdaten nachgeladen wurden
                        result.then((hasError: string | any) => {
                            if (!hasError) {
                                saveDeferred
                                    .then(() => recalculateGroups(paramGroups))
                                    .then(deferred.resolve, deferred.reject);
                            } else {
                                deferred.reject(hasError);
                            }
                        });

                        return saveDeferred
                            .then(() => deferred);
                    }

                    if (typeof result == 'undefined') {
                        continue;
                    }

                    if (result === null &&
                        (!param.Recorditem ||
                            param.Recorditem.IsDummy)) {
                        continue;
                    }

                    let lastRecorditem = null;

                    if (!param.LastRecorditem || param.LastRecorditem.IsDummy) {
                        param.LastRecorditem = <Model.Recorditem>{
                            Type: Enums.RecorditemType.CALCULATED,
                            Value: result
                        };

                        if (param.Row) {
                            param.LastRecorditem.Row = param.Row;
                        }
                    } else {
                        // LastRecorditem für Vergleich sichern
                        lastRecorditem = Utils.Clone(param.LastRecorditem);

                        param.LastRecorditem.Value = result;
                    }

                    calculateQueue.push({
                        Element: param,
                        PreviousRecorditem: lastRecorditem,
                        Row: param ? param.Row : null,
                        Issue: currentIssue,
                        Value: result,
                        Calculated: true,
                        IgnoreValueFromUI: true
                    });
                }
            }

            return saveCalculatedValues();
        }

        /**
         * Handler für das Zerstören des Recorditem-Windows. Beinhaltet den Start der Berechnung nach einer Erfassung.
         * @param {Model.Recorditem} recorditem
         * @param predefinedCorrectiveActions
         */
        export function onAfterRecorditemWindowDestroyedStepHack(recorditem: Model.Recorditem, predefinedCorrectiveActions): Deferred {
            if (recorditem == null) {
                ParameterList.ResizeScrollableTables();

                return onAfterRecorditemWindowDestroyed(recorditem, predefinedCorrectiveActions);
            }

            const deferred = $.Deferred();
            defaultProcess = {
                recorditem: recorditem,
                correctiveActions: predefinedCorrectiveActions,
                preventDefault: false
            };

            if (!finishedSingle) {
                return deferred.resolve().promise();
            }

            const paramOID = recorditem.ElementOID;

            if (checkIfParameterInfluencesOthers(paramOID, recorditem.Row)) {
                calculateFormulaValues(paramOID, recorditem.Row)
                    .always(deferred.resolve);
            } else if (defaultProcess && !recorditem.IsDeleted) {
                saveLine = saveLine.then(() => {
                    const def = onAfterRecorditemWindowDestroyed(defaultProcess.recorditem,
                        defaultProcess.correctiveActions, defaultProcess.preventDefault);
                    defaultProcess = null;
                    return def;
                }).then(() => {
                    deferred.resolve();
                });
            } else {
                deferred.resolve();
            }

            // Titel Generierung über Formeln
            if (isIssueTitleByFormulaEnabled()) {
                // Prüfen ob Parameter einer Teilprobe zugehört
                const refIdentifier = createElementIdentifier(paramOID, recorditem.Row);

                // Abhängigkeit zum aktuellen paramOID ermitteln
                if (dependentParametersOfIssueFormula[refIdentifier]) {
                    deferred.always(() => setNewIssueTitleFromFormula());
                }
            }

            return deferred.promise();
        }

        function isIssueTitleByFormulaEnabled(): boolean {
            // Titel Generierung über Formeln
            return Session.Client && Session.Client.Licenses &&
                Session.Client.Licenses.AllowIssueTitleGenerationWithFormula &&
                Utils.InArray([
                    Enums.View.Inspection,
                    Enums.View.Scheduling,
                    Enums.View.Form,
                    Enums.View.FormBatchEdit
                ], View.CurrentView) &&
                !isIssueTitleOverwritten(IssueView.GetCurrentIssue());
        }

        function checkIfParameterInfluencesOthers(paramOID: string, row?: number): boolean {
            const param = GetElement(paramOID, row);
            const ident = createElementIdentifier(param);

            if (dependencies.hasOwnProperty(ident) && dependencies[ident].Influences.size() > 0) {
                return true;
            }

            // Referenzen ohne spezifische Teilprobe
            const ident2 = createElementIdentifier(param.OID);

            return dependencies.hasOwnProperty(ident2) && dependencies[ident2].Influences.size() > 0;
        }

        export function getRequiredOE(tokens: any[], param: Model.Elements.Element): string[] {
            if (!tokens || !tokens.length) {
                return null;
            }

            const requiredOeOIDs = [];

            for (let i = 0; i < tokens.length; i++) {
                const masterToken = tokens[i];
                if (masterToken instanceof Formula.FunctionToken && masterToken._name === 'MasterdataValue') {
                    // Finde 3. Parameter
                    let openParenthesis = 0;
                    let currentParameter = 0;
                    let readMasterdataTokens = false;
                    let oeTokensCounter = 0;
                    let oeValueTokens = [];

                    for (i++; i < tokens.length; i++) {
                        const subToken = tokens[i];

                        if (readMasterdataTokens &&
                            !(subToken instanceof Formula.WhitespaceToken) || oeTokensCounter) {
                            oeValueTokens[oeTokensCounter++] = subToken;
                        }

                        // öffnende Klammern zählen
                        if (subToken instanceof Formula.FunctionLeftParenthesisToken) {
                            openParenthesis++;

                            if (openParenthesis == 1) {
                                currentParameter = 1;
                            }
                        }
                        // schließende Klammern zählen
                        else if (subToken instanceof Formula.FunctionRightParenthesisToken) {
                            openParenthesis--;

                            if (openParenthesis == 0) {
                                // Funktion beendet ohne 3. Parameter, aktuelle OE berücksichtigen
                                const currentIssue = IssueView.GetCurrentIssue();
                                const location = currentIssue ? DAL.Elements.GetByOID(currentIssue.AssignedElementOID) : Session.CurrentLocation;

                                if (!location.LastMasterdataRefresh) {
                                    requiredOeOIDs.push(location.OID);
                                }

                                break;
                            }
                            // 3. Parameter Funktion ermittelt
                            else if (openParenthesis == 1 && readMasterdataTokens) {
                                const elementOID = formulaResolver.evaluate(oeValueTokens, param);
                                const resultElement = elementOID ? DAL.Elements.GetByOID(elementOID) : null;

                                if (resultElement && resultElement.OID &&
                                    !resultElement.LastMasterdataRefresh) {
                                    requiredOeOIDs.push(resultElement.OID);
                                }

                                oeValueTokens = null;
                                readMasterdataTokens = false;
                                break;
                            }
                        }
                        // Kommas zählen um dritten Parameter zu finden
                        else if (subToken instanceof Formula.CommaToken && openParenthesis == 1) {
                            currentParameter++;

                            if (currentParameter == 3) {
                                // Lese OE-Wert über Formelauswetung aus
                                oeValueTokens = [];
                                readMasterdataTokens = true;
                                oeTokensCounter = 0;
                            }
                        }
                    }
                }
            }

            return requiredOeOIDs.length ? requiredOeOIDs : null;
        }


        function formulizeParam(param: Model.Elements.Element): Utils.LightPromise | any | undefined {
            /*
             * Prüfpunkt anhand der Formel berechnen.
             * undefined wenn kein Wert errechnet wurde.
             * null oder Value wenn gültiges Ergebnis.

             * Ein Utils.LightPromise wird zurückgegeben,
             * wenn Daten nachgeladen werden mussten und
             * die Formelberechnung erneut ausgeführt werden soll!
             */
            if (param.Formula == null) {
                return undefined;
            }

            if (ignoreFormulaIfLastValueIsNotAllowed(param)) {
                return undefined;
            }

            try {
                const tokens = window.Formula.tokenize(param.Formula);

                // Stammdaten nachladen falls benötigt
                const requiredOUs = getRequiredOE(tokens, param);
                if (requiredOUs && requiredOUs.length) {
                    const resultPromise = Utils.LightPromise.Create()
                    let masterdataDeferred = $.Deferred().resolve();

                    // alle benötigten Daten als Chain laden
                    for (const ouOID of requiredOUs) {
                        const currentLocation = DAL.Elements.GetByOID(ouOID);
                        masterdataDeferred = masterdataDeferred
                            .then(() => DAL.Sync.PreloadElementCheckpoints(ouOID))
                            .then(() => getParametersWithRecorditems(currentLocation));
                    }

                    masterdataDeferred
                        .then((data) => {
                            resultPromise.resolve(null);
                        }, (err) => {
                            resultPromise.resolve(err || 'error');
                        });

                    return resultPromise;
                }

                let result = formulaResolver.evaluate(tokens, param);

                if (result != null) {
                    result = normalizeValue(param, result);
                }

                if (param.LastRecorditem &&
                    param.LastRecorditem.Type != null &&
                    param.LastRecorditem.Type < Enums.RecorditemType.CALCULATED) {
                    /* Prüfpunkte, die manuell überschrieben wurden, nicht mehr ändern */
                    return undefined;
                }

                if (param.Type === Enums.ElementType.ListBox && param.Structure && typeof result === 'string') {
                    // Konvertiere Wert zu Auswahl
                    for (let key in param.Structure) {
                        if (param.Structure[key] == result) {
                            result = parseInt(key, 10);
                            break;
                        }
                    }
                }

                if (param.Type === Enums.ElementType.EMailAddresses
                    && typeof result === 'string' && result.length) {
                    // Validiere und konvertiere Ergebnis zu Array
                    result = Utils.IsValidMailAddress(result) ? [result] : null;
                }

                if ((param.Type === Enums.ElementType.Line ||
                    param.Type === Enums.ElementType.Memo ||
                    param.Type === Enums.ElementType.Scancode) &&
                    result !== null) {
                    // Konvertiere Ergebnis zu String
                    result = String(result);
                }

                const lastValue = (<Model.Recorditem>(param.LastRecorditem || {})).Value;

                if (typeof lastValue !== 'undefined' &&
                    Utils.Equals(lastValue, result)) {
                    // Werte haben sich nicht geändert, keine Aktion nötig
                    return undefined;
                }

                return result;
            } catch (e) {
                // ¯\_(ツ)_/¯
                // Parameter fehlen für Berechnung, weiter zum nächsten
                return undefined;
            }
        }

        export function ResolveFormula(formula: string, param: Model.Elements.Element): any {
            /*
             * Prüfpunkt anhand der Formel berechnen.
             * undefined wenn kein Wert errechnet wurde.
             * null oder Value wenn gültiges Ergebnis.
             */
            if (formula == null) {
                return undefined;
            }

            try {
                const tokens = window.Formula.tokenize(formula);
                let result = formulaResolver.evaluate(tokens, param);

                if (result instanceof Date) {
                    result = Utils.DateTime.ToGMTString(result);
                }

                return result;
            } catch (e) {
                // ¯\_(ツ)_/¯
                // Parameter fehlen für Berechnung, weiter zum nächsten
                return undefined;
            }
        }

        function normalizeValue(element: Model.Elements.Element, value: any): any {
            if (value == null) {
                return value;
            }

            if (value instanceof Date) {
                value = Utils.DateTime.ToGMTString(value);
            }

            if (element.Type === Enums.ElementType.EMailAddresses) {
                let possibleAdresses: string[] = null;

                if (typeof value == 'string') {
                    const distinctAdresses = value.split(/[;, |]/);

                    if (distinctAdresses && distinctAdresses.length) {
                        possibleAdresses = distinctAdresses;
                    }
                } else if (value instanceof Array) {
                    possibleAdresses = [];

                    for (let val of value) {
                        if (val) {
                            possibleAdresses.push(String(val));
                        }
                    }
                } else {
                    possibleAdresses = [value]
                }

                return possibleAdresses ? possibleAdresses.filter((email) => !!email && Utils.IsValidMailAddress(email)) : null;
            }

            if (element.Type === Enums.ElementType.Number &&
                !isNaN(value) && element.Decimals) {
                return parseFloat(value.toFixed(element.Decimals));
            }

            return value;
        }

        function saveCalculatedValue(parameter: Model.Elements.Element, value: any): Deferred {
            const defer = $.Deferred();

            Utils.RecorditemEditor.SaveSelectedValue({
                Element: parameter,
                PreviousRecorditem: parameter ? parameter.LastRecorditem : <Model.Recorditem>{ Type: Enums.RecorditemType.CALCULATED },
                Row: parameter ? parameter.Row : null,
                Issue: IssueView.GetCurrentIssue() || null,
                Value: value,
                Calculated: true,
                IgnoreValueFromUI: true,
                OnAfterRecorditemSaved: onAfterRecorditemSaved,
                OnAfterWindowDestroyed: function(recorditem: Model.Recorditem, predefinedCorrectiveActions) {
                    // is Action required
                    if (finishedSingle) {
                        saveLine = saveLine.then(function() {
                            return onAfterRecorditemWindowDestroyed(recorditem, predefinedCorrectiveActions, true);
                        });
                    } else {
                        callbacks.push({
                            recorditem: recorditem,
                            correctiveActions: predefinedCorrectiveActions,
                            preventDefault: true
                        });
                    }

                    defer.resolve();
                }
            });

            return defer.promise();
        }

        function createElementIdentifier(element: Model.Elements.Element): string | null
        function createElementIdentifier(oid: string, row?: number): string | null
        function createElementIdentifier(element_oid: Model.Elements.Element | string, row?: number): string | null {
            if (!element_oid) {
                return null;
            }

            if (typeof element_oid == 'string') {
                return row != null && !isNaN(row) ?
                    `${element_oid}_${row}` :
                    element_oid;
            }

            return element_oid.Row != null && !isNaN(element_oid.Row) ?
                `${element_oid.OID}_${element_oid.Row}` :
                element_oid.OID;
        }

        function isIssueTitleOverwritten(issue: Model.Issues.Issue): boolean {
            return issue == null
                ? false
                : Utils.HasProperties(issue.AdditionalData) && issue.AdditionalData.IsIssueTitleOverwritten;
        }
    }
}
