/// <reference path="../templates.d.ts"  />
/// <reference path="../definitions.d.ts"  />

module App.Init {
    let $initForm = null;
    let currentProgress: number = null;
    let sessionTerminatedMessageVisible = false;

    const touchInterceptorSelector = '#init-screen';
    const touchesRequiredForRecovery = 2;
    let touchesReceived = 0;
    let pauseLoading = false;

    export namespace Enums {
        export enum Icon {
            Init = 'icon-init',
            Login = 'icon-login',
            Download = 'icon-download',
            LoadMemory = 'icon-memory-load',
            Logout = 'icon-logout',
            ClearApp = 'icon-clear-app',
        }

        export enum SpinnerPosition {
            Left = 'left',
            Center = 'center',
            Right = 'right'
        }
    }

    let lastChange: Date = new Date();

    export function moveSpinner(position: Enums.SpinnerPosition): Deferred {
        $('#init-screen .cssload-container').attr('class', `cssload-container position-${position}`);

        if (position == Enums.SpinnerPosition.Right) {
            if (pauseLoading) {
                return showRecoveryMode();
            } else {
                // Pausieren nach diesem Status nicht mehr möglich
                $(touchInterceptorSelector).off('touchend.recovery');
            }
        }

        const deferred = $.Deferred();
        // Animation abwarten Timer
        setTimeout(function() {
            deferred.resolve();
        }, 500);
        return deferred;
    }

    export function enableInit(enable: boolean = true): Deferred {
        return checkIcon(Enums.Icon.Init, enable);
    }

    export function enableLogin(enable: boolean = true): Deferred {
        return checkIcon(Enums.Icon.Login, enable);
    }

    export function enableDownload(enable: boolean = true): Deferred {
        return checkIcon(Enums.Icon.Download, enable);
    }

    export function enableLoadMemory(enable: boolean = true): Deferred {
        return checkIcon(Enums.Icon.LoadMemory, enable);
    }

    export function enableClearApp(enable: boolean = true): Deferred {
        return checkIcon(Enums.Icon.ClearApp, enable);
    }

    export function initSupportButton(): void {
        // set tap support function (smart device only)
        if (window.cordova) /* smart device test */ {
            touchesReceived = 0;
            pauseLoading = false;

            $(touchInterceptorSelector).on('touchend.recovery', function() {
                touchesReceived++;

                // mind. [touchesRequiredForRecovery] Klicks erforderlich machen um den 'recovery mode' zu erreichen
                // (soll versehentliches tippen ignorieren)
                if (touchesReceived != touchesRequiredForRecovery) {
                    return;
                }

                $(touchInterceptorSelector).off('touchend.recovery');

                // Pausieren des Ladevorgangs initiieren
                pauseLoading = true;
            });
        }
    }

    function showRecoveryMode(): Deferred {
        const pauseDeferred: Deferred = $.Deferred();

        $('.recovery-mode .continue').off('click')
            .on('click', function() {
                // Button Events wieder entfernen
                $('.recovery-mode button').off('click');

                // Recovery Mode Buttons ausblenden
                $('#init-screen .recovery-mode').addClass('hidden');

                if (pauseDeferred) {
                    pauseLoading = false;
                    pauseDeferred.resolve();
                }
            });

        $('.recovery-mode .support-mail').off('click')
            .on('click', function() {
                Utils.SupportMail.Show();
            });

        $('#init-screen .recovery-mode').removeClass('hidden');

        return pauseDeferred.promise();
    }

    export function show(): void {
        $initForm = $(Templates.Init());
        Utils.GetContentContainer().empty();
        $('body').append($initForm);
        lastChange = new Date();

        initSupportButton();
    }

    export function hide(): Deferred {
        const deferred = $.Deferred();
        // init screen ausblenden
        $('#init-screen .logo-container').addClass('hide');
        $('#init-screen .icons-container').removeClass('show');
        $('#init-screen .progress-container').removeClass('show');

        $('#init-screen').off();
        $('#init-screen .support-mail').off();

        setTimeout(function() {
            $('#init-screen').remove();
            $initForm = null;
            deferred.resolve();
        }, 500);

        return deferred.promise();
    }

    export function showIcons(icons?: Array<Enums.Icon>, showTransition: boolean = true): Deferred {
        const iconsDeferred = $.Deferred();
        $('#init-screen .logo-container').addClass('move-up');

        if (!icons) {
            icons = [
                Enums.Icon.Init,
                Enums.Icon.Login,
                Enums.Icon.Download
            ];
        }

        $('#init-screen .icons-container td div').addClass('hide');
        for (let i = 0; i < icons.length; i++) {
            $(`#init-screen .icons-container td div.${icons[i]}`).removeClass('hide');
        }

        if (showTransition) {
            $('#init-screen .logo-container').addClass('move-up');
            setTimeout(function() {
                // lade symbole anzeigen
                $('#init-screen .icons-container').addClass('show');
                iconsDeferred.resolve();
            }, 500);
        } else {
            $('#init-screen .logo-container').addClass('no-transition').addClass('move-up');
            $('#init-screen .icons-container').addClass('show');
            iconsDeferred.resolve();
        }
        return iconsDeferred.promise();
    }

    export function replaceIcon(oldIcon: Enums.Icon, newIcon: Enums.Icon, enable?: boolean): void {
        if (typeof enable === 'boolean') {
            checkIcon(newIcon, enable);
        }

        $(`#init-screen .icons-container td div.${oldIcon}`).addClass('hide');
        $(`#init-screen .icons-container td div.${newIcon}`).removeClass('hide');
    }

    export function hideIcons(): Deferred {
        const iconsDeferred = $.Deferred();
        $('#init-screen .icons-container').removeClass('show');
        $('#init-screen .logo-container').removeClass('no-transition').removeClass('move-up');

        setTimeout(function() {
            iconsDeferred.resolve();
        }, 500);
        return iconsDeferred.promise();
    }

    export function enableProgress(enable: boolean = true, progress?: number): Deferred {
        const progressDeferred = $.Deferred();
        $('#init-screen .progress-container').toggleClass('show', enable);
        if (progress || progress === 0) {
            setProgress(progress);
        }

        setTimeout(function() {
            progressDeferred.resolve()
        }, 200);

        return progressDeferred.promise();
    }

    export function setProgress(progress: number = 0): void {
        // check current progress, prevent unnecessary calls to DOM Elements
        if (currentProgress !== null && currentProgress === progress) {
            return;
        }
        $('#init-screen .progress-container').text(`${progress.toFixed(1)}%`);
    }

    export function InitWindowFocusEvent() {
        if (Session.IsSmartDeviceApplication) {
            return;
        }

        $(window)
            .off('focus.session')
            .on('focus.session', () => {
                const currentSessionUser = Utils.Cookies.GetActiveSessionUser();
                if (Session.ActiveSession && !currentSessionUser) {
                    if (sessionTerminatedMessageVisible)
                        return;

                    sessionTerminatedMessageVisible = true;
                    Utils.Message.Show(i18next.t('Session.Terminated.MessageHeader'),
                        i18next.t('Session.Terminated.MessageBody'),
                        {
                            OK: () => {
                                sessionTerminatedMessageVisible = false;
                                App.Logout();
                            },
                            OnHide: false
                        });
                    return;
                }

                // Wurde der Benutzer in einem anderen Tab geändert?
                if (currentSessionUser !== (Session.User || { OID: null }).OID) {
                    // Cookie für Benutzereinstellungen entfernen
                    if (Session.User && Session.User.OID) {
                        Utils.Cookies.Delete(Session.User.OID);
                    }
                    location.reload();
                }
            });
    }

    export function UnbindWindowFocusEvent() {
        $(window).off('focus.session');
    }

    function checkIcon(name: Enums.Icon, enable: boolean = true): Deferred {
        // Icon aktiv / inaktiv schalten
        const icon = document.getElementsByClassName(name)[0];
        if (!icon) {
            return $.Deferred().resolve().promise();
        }
        if (enable) {
            icon.classList.remove('off');
        } else {
            icon.classList.add('off');
        }

        const deferred = $.Deferred();
        const diffChange = new Date().getTime() - lastChange.getTime();
        if (diffChange < 500) {
            setTimeout(function() {
                lastChange = new Date();
                deferred.resolve();
            }, diffChange)
        } else {
            deferred.resolve();
        }
        return deferred;
    }
}
