(function (global) {
    var Tools = {
        isNumber: function (value) {
            return typeof value === 'number' && !isNaN(value);
        },
        isDate: function (date) {
            return Object.prototype.toString.call(date) === '[object Date]' &&
                !isNaN(date.getTime());
        },
        isBool: function (value) {
            return typeof value === 'boolean';
        }
    };

    var functions = {};

    function getSubsambleValuesAsArray(oid, data, extendTypeConversion) {
        var paramGroups = ParameterList.GetFormulaGroups(false);
        var result = [];

        for (var gi = 0; gi < paramGroups.length; gi++) {
            var parameters = paramGroups[gi].Parameters;

            for (var pi = 0; pi < (parameters || []).length; pi++) {
                var param = parameters[pi];

                if (param.OID !== oid) {
                    continue;
                }

                // wenn Parameter keine Teilprobe, weitermachen
                if (isNaN(param.Row)) {
                    continue;
                }

                var value = (param.LastRecorditem && param.LastRecorditem.Value != null)
                    ? param.LastRecorditem.Value
                    : null;

                if (value != null && typeof value === 'string') {
                    if (param.Type === Enums.ElementType.ListBox) {
                        // Auswahllisten-Zeilennummer in Zahl umwandeln
                        value = parseInt(value, 10);
                    }
                }

                if (value != null) {
                    if (typeof value === 'number') {
                        result.push(value);
                    } else if (extendTypeConversion) {
                        if (param.Type === Enums.ElementType.Date) {
                            result.push(new Date(value));
                        } else if (param.Type === Enums.ElementType.Time) {
                            const normDate = new Date();
                            const dateValue = new Date(value);

                            const seconds = 0;
                            const minutes = dateValue.getMinutes();
                            const hour = dateValue.getHours();

                            normDate.setSeconds(seconds);
                            normDate.setMinutes(minutes);
                            normDate.setHours(hour);

                            result.push(normDate);
                        }
                    }
                }
            }
        }

        return result;
    }

    function getSumOfSubsample(oid, data) {
        var values = getSubsambleValuesAsArray(oid, data);

        if (!values || !values.length) {
            return null;
        }

        var sum = null;

        for (var i = 0; i < values.length; i++) {
            sum += values[i];
        }

        return sum;
    }

    function getAverageValueOfSubsample(oid, data) {
        var values = getSubsambleValuesAsArray(oid, data);

        if (!values || !values.length) {
            return null;
        }

        var count = values.length;
        var sum = null;

        for (var i = 0; i < values.length; i++) {
            sum += values[i];
        }

        return count ? sum / count : null;
    }

    function getMinValueOfSubsample(oid, data) {
        var values = getSubsambleValuesAsArray(oid, data, true);

        if (!values || !values.length) {
            return null;
        }

        var min = null;

        for (var i = 0; i < values.length; i++) {
            var tmpVal = values[i];

            if (min === null || tmpVal < min) {
                min = tmpVal;
            }
        }

        return min;
    }

    function getMaxValueOfSubsample(oid, data) {
        var values = getSubsambleValuesAsArray(oid, data, true);

        if (!values || !values.length) {
            return null;
        }

        var max = null;

        for (var i = 0; i < values.length; i++) {
            var tmpVal = values[i];

            if (max === null || tmpVal > max) {
                max = tmpVal;
            }
        }

        return max;
    }

    function getValueOfElement(oid, data, options) {
        var paramGroups = ParameterList.GetFormulaGroups(false);
        var row = (data || {}).Row;

        for (var gi = 0; gi < paramGroups.length; gi++) {
            var parameters = paramGroups[gi].Parameters;

            for (var pi = 0; pi < (parameters || []).length; pi++) {
                var param = parameters[pi];

                if (param.OID !== oid) {
                    continue;
                }

                // wenn Parameter nicht zu Teilproben gehört, die Row ignorieren
                // ansonsten auf IndividualData aus der gleichen Row beziehen
                if (!isNaN(param.Row) && row != null && param.Row !== row) {
                    continue;
                }

                var value = (param.LastRecorditem && param.LastRecorditem.Value != null) ?
                    param.LastRecorditem.Value :
                    null;

                return parseElementValue(param, value, (options || {}).expectedType === 'string');
            }
        }

        return null;
    }

    function parseElementValue(element, value, expectsString) {
        if (element == null) {
            return null;
        }

        var elemType = element.Type;

        if (elemType < 100) {
            return null;
        }

        if (value == null) {
            return null;
        }

        if (typeof value === 'string') {
            switch (elemType) {
                case Enums.ElementType.Date:
                case Enums.ElementType.Time:
                    value = new Date(value);
                    break;
                case Enums.ElementType.ListBox:
                    value = parseInt(value, 10);
                    break;
                case Enums.ElementType.Info:
                    value = null;
                    break;
                case Enums.ElementType.Users:
                case Enums.ElementType.EMailAddresses:
                case Enums.ElementType.IndividualData:
                    try {
                        value = JSON.parse(value);
                    } catch (e) {}

                    break;
            }
        }

        return expectsString ?
            stringifyCheckpointValue(element, value) :
            value;
    }

    function stringifyCheckpointValue(element, value) {
        if (element == null) {
            return null;
        }

        var elemType = element.Type;

        if (elemType < 100) {
            return null;
        }

        if (value == null) {
            return null;
        }

        switch (elemType) {
            case Enums.ElementType.Photo:
                return `(${i18next.t('Misc.ParameterTypes.Photo')})`;
            case Enums.ElementType.LocationCode:
                return stringifyLocalizationCodeValue(value);
            case Enums.ElementType.Signature:
                return `(${i18next.t('Misc.ParameterTypes.Signature')})`;
            case Enums.ElementType.Users:
                return stringifyUsersValue(value);
            case Enums.ElementType.IndividualData:
                var schemaType = ((element.AdditionalSettings || {}).Types || [])[0];

                return stringifyIndividualdataValue(schemaType, value[schemaType]);
            case Enums.ElementType.EMailAddresses:
                return stringifyEMailValue(value);
        }

        return value;
    }

    function stringifyLocalizationCodeValue(value) {
        var organizationUnit = DAL.Elements.GetByOID(value);

        return organizationUnit ? organizationUnit.Title : null;
    }

    function stringifyUsersValue(value) {
        if (typeof value !== 'object') {
            return null;
        }

        var usersCollection = [];

        if (value.Users instanceof Array) {
            value.Users
                .sort(function (identA, identB) {
                    var userA = DAL.Users.GetByOID(identA);
                    var userB = DAL.Users.GetByOID(identB);

                    if (!userA || !userB) {
                        return 0;
                    }

                    return Utils.SortByLastname(userA, userB);
                })
                .forEach(function (identifier) {
                    var user = DAL.Users.GetByOID(identifier);

                    if (!user) {
                        return;
                    }

                    usersCollection.push(user.Title);
                });
        }

        var teamsCollection = [];

        if (value.Teams instanceof Array) {
            value.Teams.forEach(function (identifier) {
                var team = DAL.Teams.GetByOID(identifier);

                if (!team) {
                    return;
                }

                teamsCollection.push(team.Title);
            });

            teamsCollection.sort(Utils.SortByString);
        }

        var valueCollection = usersCollection.concat(teamsCollection);

        return valueCollection.length ? valueCollection.join(', ') : null;
    }

    function stringifyIndividualdataValue(schemaType, value) {
        if (!schemaType) {
            return null;
        }

        if (!(value instanceof Array)) {
            return null;
        }

        var valueCollection = value
            .filter(function (identifier) {
                return DAL.IndividualData.GetIndividualData(schemaType, identifier) != null;
            })
            .map(function (identifier) {
                return DAL.IndividualData.GetEntityTitle(schemaType, identifier);
            });

        valueCollection.sort(Utils.SortByString);

        return valueCollection.join(', ');
    }

    function stringifyEMailValue(value) {
        return value instanceof Array && value.length ?
            value.map(v => v).join(', ') :
            null;
    }

    function getCurrentOrganizationUnit() {
        var currentIssue = IssueView.GetCurrentIssue();
        var isAdhoc = currentIssue == null ||
            currentIssue.AssignedElementOID == null ||
            currentIssue.Type === Enums.IssueType.Scheduling;

        if (!isAdhoc && !currentIssue) {
            return null;
        }

        return DAL.Elements.GetByOID(isAdhoc ?
            Session.CurrentLocation.OID :
            currentIssue.AssignedElementOID
        );
    }

    functions.Value = getValueOfElement;
    functions.Sum = getSumOfSubsample;
    functions.Avg = getAverageValueOfSubsample;
    functions.Min = getMinValueOfSubsample;
    functions.Max = getMaxValueOfSubsample;

    functions.MasterdataValue = function (groupTitle, cpTitle, locationOID) {
        if (!groupTitle || !cpTitle) {
            return null;
        }

        var currentIssue = IssueView.GetCurrentIssue();
        var isAdhoc = currentIssue == null ||
            currentIssue.AssignedElementOID == null ||
            currentIssue.Type === Enums.IssueType.Scheduling;

        if (!isAdhoc && !currentIssue) {
            return null;
        }

        if (typeof locationOID != 'string') {
            // Kein Wert für OE angegeben, obwohl dieser definiert wurde
            if (typeof locationOID === 'object' && locationOID === null) {
                return null;
            }

            // keine OID per optionalem Parameter, wenn kein string
            locationOID = null;
        }

        locationOID = locationOID || (isAdhoc ?
            Session.CurrentLocation.OID :
            currentIssue.AssignedElementOID);

        var masterdataLocation = DAL.Elements.GetByOID(locationOID);

        if (masterdataLocation == null) {
            return null;
        }

        if (!(masterdataLocation.Parametergroups || []).length) {
            return null;
        }

        groupTitle = groupTitle.toLowerCase();
        cpTitle = cpTitle.toLowerCase();

        for (var gCnt = 0, gLen = masterdataLocation.Parametergroups.length; gCnt < gLen; gCnt++) {
            var group = masterdataLocation.Parametergroups[gCnt];

            if (group.Type !== Enums.ElementType.MasterdataGroup) {
                continue;
            }

            if (!(group.Parameters || []).length) {
                continue;
            }

            if (group.Title.toLowerCase() !== groupTitle) {
                continue;
            }

            for (var pCnt = 0, pLen = group.Parameters.length; pCnt < pLen; pCnt++) {
                var cp = group.Parameters[pCnt];

                if (cp.Title.toLowerCase() !== cpTitle) {
                    continue;
                }

                return cp.LastRecorditem ? cp.LastRecorditem.Value : null;
            }
        }

        return null;
    };

    functions.ParentOrganizationUnit = function () {
        var currentIssue = IssueView.GetCurrentIssue();
        var isAdhoc = currentIssue == null ||
            currentIssue.AssignedElementOID == null ||
            currentIssue.Type === Enums.IssueType.Scheduling;

        if (!isAdhoc && !currentIssue) {
            return null;
        }

        var location = DAL.Elements.GetByOID(isAdhoc ?
            Session.CurrentLocation.OID :
            currentIssue.AssignedElementOID
        );

        return location && location.Parent ? location.Parent.OID : null;
    };

    functions.IndividualDataProperty = function (oid, propertyName, data, options) {
        var foreignValue = getValueOfElement(oid, data, Utils.CloneObject(options, ['expectedType']));

        if (!foreignValue) {
            return null;
        }

        var row = (data || {}).Row;
        var element = ParameterList.GetElement(oid, row);

        if (!element) {
            return null;
        }

        var schemaName = ((element.AdditionalSettings || {}).Types || [])[0] || '';

        if (!foreignValue.hasOwnProperty(schemaName)) {
            return null;
        }

        var selectedDataRecord = (foreignValue[schemaName] || [])[0];
        var dataRecord = DAL.IndividualData.GetIndividualData(schemaName, selectedDataRecord);

        if (!dataRecord) {
            return null;
        }

        var schema = DAL.Schemas.GetByType(schemaName);
        var propertyDefinition = Utils.Where(schema.Properties || [], 'Name', '===', propertyName);

        if (propertyDefinition && propertyDefinition.Type === Enums.IndividualDataType.RichText) {
            return null;
        }

        return dataRecord.hasOwnProperty(propertyName) ? dataRecord[propertyName] : null;
    };

    functions.Blank = function () {
        return null;
    };

    functions.Score = function (oid) {
        var structureEvalIndex = functions.Value(oid);
        var param = ParameterList.GetElementRevisionByOID(oid);

        if (!param || !param.StructureEval || !param.StructureEval.hasOwnProperty(structureEvalIndex)) {
            return null;
        }

        var tmpVal = param.StructureEval[structureEvalIndex];

        return isNaN(tmpVal) ? tmpVal : +tmpVal;
    };

    functions.LastValue = function(oid) {
        var previousRecorditem = ParameterList.PreviousRecorditemsOfElements[oid];

        if (!Utils.IsSet(previousRecorditem)) {
            return null;
        }

        var value = previousRecorditem.Value != null ? previousRecorditem.Value : null;

        if (value && previousRecorditem.Type == Enums.ElementType.Date || previousRecorditem.Type == Enums.ElementType.Time && typeof value === 'string') {
            value = new Date(value);
        }

        return value;
    };

    functions.SubsampleValue = function (oid, subsampleNumber, data, options) {
        return functions.Value(oid, { Row: subsampleNumber }, options);
    };

    functions.Subsamplenumber = function (data) {
        return (data || {}).Row || 1;
    };

    functions.DayDif = function (dateA, dateB) {
        if (!Tools.isDate(dateA) || !Tools.isDate(dateB)) {
            return null;
        }

        return Math.ceil((dateB - dateA) / 1000 / 60 / 60 / 24);
    };

    functions.MonthDif = function (dateA, dateB) {
        if (!Tools.isDate(dateA) || !Tools.isDate(dateB)) {
            return null;
        }

        var months = (dateB.getFullYear() - dateA.getFullYear()) * 12;
        months -= dateA.getMonth();
        months += dateB.getMonth();

        var dayA = dateA.getDate();
        var dayB = dateB.getDate();

        if (dateA < dateB && dayA > dayB) {
            months -= 1;
        } else if (dateA > dateB && dayA < dayB) {
            months += 1;
        }

        return months;
    };

    functions.YearDif = function (dateA, dateB) {
        if (!Tools.isDate(dateA) || !Tools.isDate(dateB)) {
            return null;
        }

        var years = dateB.getFullYear() - dateA.getFullYear();
        var monthA = dateA.getMonth();
        var monthB = dateB.getMonth();
        var dayA = dateA.getDate();
        var dayB = dateB.getDate();

        if (dateA < dateB && (monthA > monthB || monthA === monthB && dayA > dayB)) {
            years -= 1;
        } else if (dateA > dateB && (monthA < monthB || monthA === monthB && dayA < dayB)) {
            years += 1;
        }

        return years;
    };

    functions.DayAdd = function (date, days) {
        if (!Tools.isDate(date)) {
            return null;
        }

        if (!Tools.isNumber(days)) {
            return null;
        }

        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
    };

    functions.MonthAdd = function (date, months) {
        if (!Tools.isDate(date)) {
            return null;
        }

        if (!Tools.isNumber(months)) {
            return null;
        }

        return new Date(date.getFullYear(), date.getMonth() + months, date.getDate());
    };

    functions.YearAdd = function (date, years) {
        if (!Tools.isDate(date)) {
            return null;
        }

        if (!Tools.isNumber(years)) {
            return null;
        }

        return new Date(date.getFullYear() + years, date.getMonth(), date.getDate());
    };

    functions.EndOfMonth = function (date) {
        if (!Tools.isDate(date)) {
            return null;
        }

        return new Date(date.getFullYear(), date.getMonth() + 1, 0);
    };

    functions.Date = function (year, month, day) {
        if (!Tools.isNumber(year) ||
            !Tools.isNumber(month) ||
            !Tools.isNumber(day)) {
            return null;
        }

        return new Date(year, month - 1, day);
    };

    functions.Today = function () {
        var now = new Date();

        return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    };

    functions.Day = function (date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        if (!Tools.isDate(date)) {
            return null;
        }

        return date.getDate();
    };

    functions.Month = function (date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        if (!Tools.isDate(date)) {
            return null;
        }

        return date.getMonth() + 1;
    };

    functions.Year = function (date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        if (!Tools.isDate(date)) {
            return null;
        }

        return date.getFullYear();
    };

    functions.Time = function (hour, minute) {
        if (!Tools.isNumber(hour) ||
            !Tools.isNumber(minute)) {
            return null;
        }

        var date = new Date();
        date.setHours(hour, minute, 0, 0);

        return date;
    };

    functions.Now = function () {
        return new Date();
    };

    functions.Hour = function (date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        if (!Tools.isDate(date)) {
            return null;
        }

        return date.getHours();
    };

    functions.Minute = function (date) {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        if (!Tools.isDate(date)) {
            return null;
        }

        return date.getMinutes();
    };

    functions.MinuteDif = function (dateA, dateB) {
        if (!Tools.isDate(dateA) ||
            !Tools.isDate(dateB)) {
            return null;
        }

        var hourA = dateA.getHours();
        var hourB = dateB.getHours();
        var minuteA = dateA.getMinutes();
        var minuteB = dateB.getMinutes();
        var minutes = (hourB - hourA) * 60;
        minutes += (minuteB - minuteA);

        return minutes >= 0 ? minutes : minutes + 24 * 60;
    };

    functions.HourDif = function (dateA, dateB) {
        if (!Tools.isDate(dateA) ||
            !Tools.isDate(dateB)) {
            return null;
        }

        var hourA = dateA.getHours();
        var hourB = dateB.getHours();
        var minuteA = dateA.getMinutes();
        var minuteB = dateB.getMinutes();
        var hours = hourB - hourA;

        if (minuteA > minuteB) {
            hours -= 1;
        }

        return hours >= 0 ? hours : hours + 24;
    };

    functions.MinuteAdd = function (date, minutes) {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        if (!Tools.isDate(date)) {
            return null;
        }

        if (!Tools.isNumber(minutes)) {
            return null;
        }

        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes() + minutes);
    };

    functions.HourAdd = function (date, hours) {
        if (typeof date === 'string') {
            date = new Date(date);
        }

        if (!Tools.isDate(date)) {
            return null;
        }

        if (!Tools.isNumber(hours)) {
            return null;
        }

        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours() + hours,
            date.getMinutes());
    };

    functions.True = function () {
        return true;
    };

    functions.False = function () {
        return false;
    };

    functions.If = function (condition, trueValue, falseValue) {
        if (!Tools.isBool(condition)) {
            return;
        }

        return condition ? trueValue : falseValue;
    };

    functions.IsBlank = function (value) {
        return value === null || value === '';
    };

    functions.IfBlank = function (value, defaultValue) {
        return value === null || value === '' ? defaultValue : value;
    };

    functions.Abs = function (value) {
        if (!Tools.isNumber(value)) {
            return null;
        }

        return Math.abs(value);
    };

    functions.Sqrt = function (value) {
        if (!Tools.isNumber(value) || value < 0) {
            return null;
        }

        return Math.sqrt(value);
    };

    functions.Round = function (value, decimals) {
        if (!Tools.isNumber(value) ||
            !Tools.isNumber(decimals) ||
            decimals < 0) {
            return null;
        }

        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    };

    functions.Rounddown = function (value, decimals) {
        if (!Tools.isNumber(value) ||
            !Tools.isNumber(decimals) ||
            decimals < 0) {
            return null;
        }

        return Number(Math.floor(value + 'e' + decimals) + 'e-' + decimals);
    };

    functions.Roundup = function (value, decimals) {
        if (!Tools.isNumber(value) ||
            !Tools.isNumber(decimals) ||
            decimals < 0) {
            return null;
        }

        return Number(Math.ceil(value + 'e' + decimals) + 'e-' + decimals);
    };

    functions.ReplaceCharacter = function (data, char, replacement) {
        if (!Utils.IsSet(data) || char == null || replacement == null) {
            return;
        }

        var metaCharRegex = new RegExp(/[\[\^\$\.\?\*\+\(\)]/, '\gi');
        var replaceRegex = new RegExp(char, '\gi');

        if (char.match(metaCharRegex)) {
            replaceRegex = new RegExp("\\" + char, '\gi');
        }

        if (data instanceof Date) {
            var date = data;
            var isTimeNull = Utils.DateTime.IsTimeOfDateNull(date);
            var dateString = Utils.DateTime.GetDateString(date);
            data = isTimeNull ? dateString : (dateString + " " + date.toLocaleTimeString()).slice(0, -3);
        }

        if (!Utils.IsSet(replacement) || typeof replacement !== 'string') {
            replacement = '';
        }

        data = data.toString();
        data = data.replace(/\s+/gi, ' ');
        return data.replace(replaceRegex, Utils.IsSet(replacement) ? replacement : '');
    };

    functions.FormTitle = function () {
        var currentIssue = IssueView.GetCurrentIssue();

        if (currentIssue == null || currentIssue.AssignedFormOID == null || currentIssue.AssignedFormRevisionOID == null) {
            throw 'Form does not exist';
        }

        var formRevisionOID = currentIssue.AssignedFormRevisionOID;
        var form = ParameterList.GetElementRevisionByRevisionOID(formRevisionOID);

        if (form == null) {
            form = DAL.Elements.GetByRevisionOID(currentIssue.AssignedFormRevisionOID) || DAL.Elements.GetByOID(currentIssue.AssignedFormOID);

            if (form == null) {
                throw 'Form does not exist';
            }
        }

        return form.Title || i18next.t('misc.untitled');
    };

    functions.EntityProperty = function (propertyName, entity) {
        if (!propertyName || !entity) {
            return null;
        }

        return entity[propertyName];
    };

    functions.OuPropertyValue = function (groupTitle, propertyTitle, element) {
        if (!propertyTitle) {
            return null;
        }

        var title;

        // falls zu wenig Argumente in der Formel angegeben wurden -> fallback
        if (element == null && typeof propertyTitle !== 'string') {
            title = groupTitle;
            groupTitle = null;
            element = propertyTitle;
        } else {
            title = propertyTitle;
        }

        var currentLocation = getCurrentOrganizationUnit();

        if (currentLocation == null) {
            return null;
        }

        if (!Utils.IsSet(currentLocation.AdditionalProperties)) {
            return null;
        }

        var additionalProperties = currentLocation.AdditionalProperties || [];
        var group = {};

        if (groupTitle && typeof groupTitle === 'string') {
            group = Utils.FindByPredicate(additionalProperties, function (property) {
                return property.Type === Enums.AdditionalPropertyType.Group &&
                    (property.Title || '').toLowerCase() === groupTitle.toLowerCase();
            });
        }

        var properties = group != null && group.hasOwnProperty('Children') ?
            (group.Children || []) :
            additionalProperties;

        var additionalProperty = Utils.FindByPredicate(properties, function (property) {
            return property.Type !== Enums.AdditionalPropertyType.Group &&
                (property.Title || '').toLowerCase() === title.toLowerCase();
        });

        if (additionalProperty == null ||
            additionalProperty.RawValue == null ||
            additionalProperty.RawValue === '-/-') {
            return null;
        }

        var rawValue = additionalProperty.RawValue;

        if (additionalProperty.Type === Enums.AdditionalPropertyType.IndividualData) {
            if (typeof rawValue === 'object' && rawValue.hasOwnProperty(additionalProperty.SubType)) {
                var selectedEntities = rawValue[additionalProperty.SubType];

                if (selectedEntities instanceof Array && selectedEntities.length) {
                    rawValue = selectedEntities
                        .filter(function (id) {
                            return DAL.IndividualData.GetIndividualData(additionalProperty.SubType, id) != null;
                        });

                    if (!rawValue.length) {
                        rawValue = null;
                    }
                } else {
                    rawValue = null;
                }
            } else {
                rawValue = null;
            }
        }

        switch (element.Type) {
            case Enums.ElementType.Line:
            case Enums.ElementType.Memo:
                if (rawValue !== null && additionalProperty.Type === Enums.AdditionalPropertyType.IndividualData) {
                    var selectedIndividualdataEntities = $.map(rawValue, function (id) {
                        return DAL.IndividualData.GetIndividualData(additionalProperty.SubType, id);
                    }).filter(function (entity) {
                        return entity != null;
                    });

                    var entityTitles = {};

                    selectedIndividualdataEntities.sort(function (a, b) {
                        if (!entityTitles.hasOwnProperty(a.ID)) {
                            entityTitles[a.ID] = DAL.IndividualData.GetEntityTitle(additionalProperty.SubType, a.ID);
                        }

                        if (!entityTitles.hasOwnProperty(b.ID)) {
                            entityTitles[b.ID] = DAL.IndividualData.GetEntityTitle(additionalProperty.SubType, b.ID);
                        }

                        var titleA = entityTitles[a.ID].toLowerCase();
                        var titleB = entityTitles[b.ID].toLowerCase();

                        if (titleA === titleB) {
                            return 0;
                        }

                        return titleA > titleB ? 1 : -1;
                    });

                    selectedIndividualdataEntities = selectedIndividualdataEntities.map(function (item) {
                        return DAL.IndividualData.GetEntityTitle(additionalProperty.SubType, item.ID);
                    });

                    return selectedIndividualdataEntities.join(', ');
                }
                break;
            case Enums.ElementType.Checkbox:
            case Enums.ElementType.Time:
                return rawValue;
            case Enums.ElementType.Date:
                if (rawValue instanceof Date) {
                    return rawValue;
                }

                if (typeof rawValue === 'string') {
                    return new Date(rawValue);
                }

                break;
            case Enums.ElementType.Number:
                if (isNaN(rawValue)) {
                    return null;
                }
                break;
            case Enums.ElementType.IndividualData:
                if (rawValue !== null && additionalProperty.Type === Enums.AdditionalPropertyType.IndividualData) {
                    var individualdataResult = {};

                    individualdataResult[additionalProperty.SubType] = rawValue.map(function (item) {
                        return item.ID;
                    });

                    return individualdataResult;
                }

                break;
            case Enums.ElementType.EMailAddresses:
                var emailAddresses = typeof rawValue === 'string' ? rawValue.trim() : null;

                if (emailAddresses == null) {
                    return null;
                }

                emailAddresses = emailAddresses.split(/[;,]+/);

                for (var i = 0; i < emailAddresses.length; i++) {
                    emailAddresses[i] = emailAddresses[i].trim();
                }

                // doppelte Einträge und ungültige Email-Adressen entfernen
                emailAddresses = emailAddresses.filter(function (mail, index) {
                    return Utils.IsValidMailAddress(mail) && emailAddresses.indexOf(mail) === index;
                });

                if ((emailAddresses || []).length === 0) {
                    return null;
                }

                return emailAddresses;
        }

        return rawValue;
    };

    functions.OuIdentCode = function () {
        var currentLocation = getCurrentOrganizationUnit();
        var identcode = currentLocation ? currentLocation.Identcode : null;

        return identcode || null;
    };

    functions.OuInternalIdentifier = function () {
        var currentLocation = getCurrentOrganizationUnit();

        return currentLocation ? currentLocation.OID : null;
    };

    functions.Contains = function (collection, value) {
        if (!(collection instanceof Array) || collection.length === 0) {
            return false;
        }

        if (value == null || value === '') {
            return false;
        }

        return collection.indexOf(value) > -1;
    };

    functions.Length = function (value) {
        if (value == null || typeof value !== 'string') {
            return null;
        }

        return value.length;
    };

    functions.Substring = function (value, startIndex, length) {
        if (value == null || typeof value !== 'string') {
            return null;
        }

        if (isNaN(startIndex) || startIndex < 0 || startIndex > (value.length -1)) {
            return null;
        }

        if (isNaN(length) || length < 1) {
            return null;
        }

        return value.substr(startIndex, length);
    };

    functions.Split = function (value, separator) {
        if (value == null || typeof value !== 'string') {
            return null;
        }

        if (separator == null || typeof separator !== 'string') {
            return value;
        }

        if (value.indexOf('\n') >= 0 || value.indexOf('\t') >= 0) {
            // Aufteilung mit RegEx Erweiterung durchführen für \n und \t Applikation

            // besondere Zeichen escapen
            var specialChars = '\\^$*+?.()|{}[]';
            var rxText = [];
            for (var i = 0; i < separator.length; i++) {
                var ch = separator[i];

                // prüfen auf \n und \t und diese behalten
                if (ch == '\\' && separator.length > i + 1) {
                    var nextCh = separator[i + 1];

                    switch (nextCh) {
                        case '\\':
                            // \ escape Character vorsetzen
                            rxText.push('\\');
                            // überspringe nächstes '\' Zeichen
                            i++;
                            break;
                        case 'n':
                            // optionales \r? für \r?\n vorsetzen
                            rxText.push('\\');
                            rxText.push('r');
                            rxText.push('?');
                            break;
                        case 't':
                            // keine weitere Aktion um \t beizubehalten
                            break;
                        default:
                            // \ escape Character vorsetzen
                            rxText.push('\\');
                    }
                } else if (specialChars.indexOf(ch) >= 0) {
                    // \ escape Character vorsetzen
                    rxText.push('\\');
                }

                rxText.push(ch);
            }

            // trennen mit RegEx
            return value.split(new RegExp(rxText.join('')));
        }

        // Text-Trennung ohne RegEx
        return value.split(separator);
    };

    functions.ItemFromArray = function (value, index) {
        if (!(value instanceof Array)) {
            return null;
        }

        if (isNaN(index) || index < 0 || index > (value.length - 1)) {
            return null;
        }

        return value[index];
    };

    functions.StructureValue = function (elementOID, valueIdentifier) {
        if (!Utils.IsValidGuid(elementOID)) {
            return null;
        }

        if (valueIdentifier == null) {
            return null;
        }

        var elements = ParameterList.GetElementsIndependentOfRow(elementOID);

        if (!elements.length) {
            return null;
        }

        var element = elements[0];

        if (!element || !element.Structure) {
            return null;
        }

        if (valueIdentifier instanceof Array) {
            return valueIdentifier
                .map(function (identifier) {
                    return element.Structure.hasOwnProperty(identifier) ?
                        element.Structure[identifier] :
                        null;
                })
                .filter(function (v) { return v != null; })
                .join(', ');
        }

        return element.Structure.hasOwnProperty(valueIdentifier) ?
            element.Structure[valueIdentifier] :
            null;
    };

    functions.RandomNumber = function (minimum, maximum) {
        if (isNaN(minimum) || isNaN(maximum)) {
            return null;
        }

        if (minimum >= maximum) {
            return null;
        }

        minimum = Math.ceil(minimum);
        maximum = Math.floor(maximum);

        return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
    };

    functions.IssueCreationtimestamp = function () {
        var currentIssue = IssueView.GetCurrentIssue();

        return currentIssue ? currentIssue.CreationTimestamp : null;
    };

    functions.WeekDay = function (date, returnType) {
        if (!Utils.DateTime.IsValid(date) || isNaN(returnType) || returnType < 1 || returnType > 2) {
            return null;
        }

        var weekDay = date.getDay();

        if (returnType === 1) {
            return weekDay;
        }

        return weekDay === 0 ? 7 : weekDay;
    };


    functions.ParseToNumber = function (value) {
        if (value == null) {
            return null;
        }

        value = value.toString().replace(',', '.');

        if (isNaN(value)) {
            return null;
        }

        var result = parseFloat(value);

        return isNaN(result) ? null : result;
    };

    return (global.Formula.Functions = functions);
})(window);