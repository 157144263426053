module IndividualData {
    let _currentSchema: Model.IndividualData.Schema;

    let _schemaEntities: Array<any>;
    let _$content;
    let _$locationPicker;

    let _tree: Model.Tree;
    let currentMenuItem: Model.Menu.IMenuItemConfig;

    let _currentlySelectedEntity: any;

    function onThumbnailClick() {
        const $this = $(this);
        const identifier = $this.data('file-identifier');

        if (!identifier) {
            return;
        }

        const file = DAL.Files.GetByOID(identifier);

        if (!file) {
            return;
        }

        let isImage = Utils.IsImage(file.MimeType);
        let isVideo = Utils.IsVideo(file.MimeType);

        Utils.OpenFile(file.Filename, isImage, isVideo, file.Title, file.MimeType);
    }

    function onNodeClick(evt: Event) {
        const identifier = $(evt.currentTarget).data('identifier');

        if (_tree.ActiveItemIdentifier !== identifier) {
            _tree
                .SetActiveItem(identifier)
                .SetScrollToActiveNode(true)
                .Build()
                .SetPreventAnchorActionOnce(false);

            selectEntity(identifier);
            renderEntity();
        } else {
            _tree
                .SetPreventAnchorActionOnce(true);
        }
    }

    function onAfterDataLoaded(entities, entityIdentifier) {
        Utils.SetMode(Enums.Mode.IndividualData);

        if (!(entities || []).length) {
            Utils.Message.Show(i18next.t('IndividualData.NoDataAvailable.MessageHeader'),
                i18next.t('IndividualData.NoDataAvailable.MessageBody', { SchemaTitle: _currentSchema.NamePlural }),
                {
                    Close: true
                });

            Utils.Spinner.Hide();
            Utils.Router.ReplaceState('#main');

            return;
        }

        entities.sort(Utils.SortByTitle);

        if (currentMenuItem.Configuration.HideDataByDefault) {
            entityIdentifier = entityIdentifier || null;
        } else {
            entityIdentifier = entityIdentifier || entities[0].ID;
        }

        _schemaEntities = entities;
        _$locationPicker = $('.location-picker');

        entities.sort(function(a, b) {
            a = a || {};
            b = b || {};
            if (a.Title == b.Title) {
                return 0;
            }
            return a.Title > b.Title;
        });

        selectEntity(entityIdentifier);

        if (_$locationPicker.css('display') !== 'none') {
            if (Session.IsSmartDeviceApplication) {
                _$locationPicker.find('.buttons .qr-code').addClass('hidden');
            }

            UpdateTree();
        }

        renderEntity();
        setApplicationHeader();

        Utils.Spinner.Hide();
    }

    export function UpdateTree() {
        _tree = new Model.Tree()
            .SetIdentifier('individual-data-picker')
            .SetRouteBase('individual-data/' + currentMenuItem.ID)
            .SetEnableAnchors(true)
            .SetAnchorSuffix(null)
            .SetKeyProperty('ID')
            .SetRenderAsListView(true)
            .SetItems(_schemaEntities)
            .SetActiveItem(_currentlySelectedEntity ? _currentlySelectedEntity.ID : null)
            .SetRenderingContainer(_$locationPicker.find('.tree-wrapper'))
            .SetOnNodeClick(onNodeClick)
            .SetHideUnmatchedListViewItems(true)
            .SetHideUnsearched(currentMenuItem.Configuration.HideDataByDefault || false)
            .SetSearchField(initSearchField(), i18next.t('SearchfieldPlaceholders.IndividualData', { SchemaTitle: _currentSchema.NamePlural }))
            .Build();
    }

    function onContentHeaderClick() {
        Utils.ElementPickerPopup.Show({
            Items: _schemaEntities,
            SelectedItemIdentifier: _currentlySelectedEntity ? _currentlySelectedEntity.ID : null,
            RenderAsListView: true,
            IsNotALocationTree: true,
            EnableKeywordFilter: false,
            EnablePresentationButton: false,
            KeyProperty: 'ID',
            SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.IndividualData', { SchemaTitle: _currentSchema.NameSingular }),
            WindowTitle: i18next.t('ElementPicker.SelectIndividualDataEntity', { SchemaSingular: _currentSchema.NameSingular }),
            HideUnmatchedListViewItems: true,
            HideUnsearchedItems: currentMenuItem.Configuration.HideDataByDefault,
            OnConfirmSelection: function(result) {
                if (!_currentlySelectedEntity ||
                    _currentlySelectedEntity.ID !== result.SelectedOID) {
                    if (_tree) {
                        _tree
                            .SetActiveItem(result.SelectedOID)
                            .SetScrollToActiveNode(true)
                            .Build()
                            .SetPreventAnchorActionOnce(false);
                    }

                    selectEntity(result.SelectedOID);
                    renderEntity();
                } else if (_tree) {
                    _tree
                        .SetPreventAnchorActionOnce(true);
                }
            }
        });
    }

    function setApplicationHeader() {
        var $navbar = $('.navbar');

        $navbar.find('.navbar-brand')
            .removeClass('hidden');

        $navbar.find('.btn-issue-filters')
            .addClass('hidden');

        $navbar.find(`a[href="#individual-data/${currentMenuItem.ID}"]`)
            .parent()
            .addClass('active')
            .siblings('.active')
            .removeClass('active');
    }

    function renderEntity() {
        if (_currentlySelectedEntity) {
            _$content.html(Templates.IndividualViews.Entity({
                Schema: _currentSchema,
                Item: _currentlySelectedEntity
            }));
            _$content.find('img[data-file-identifier]').off().on('click', onThumbnailClick);
            _$content.find('img').off('error').on('error', Utils.OnImageNotFound);
            _$content.find('div.file.thumbnail').off().on('click', onThumbnailClick);
        } else if (currentMenuItem.Configuration.HideDataByDefault) {
            _$content.empty();
        } else {
            _$content.empty();

            Utils.Message.Show(i18next.t('IndividualData.SelectionError.MessageHeader')
                , i18next.t('IndividualData.SelectionError.MessageBody')
                , {
                    Close: true
                });
        }
    }

    function initSearchField(): Model.ClearableInput.Control {
        _$locationPicker.find('.search.row').html('');

        const searchField = App.CreateOrRecycleSearchInputField('main-tree-view-search-input', true);

        searchField.SetSearchPresentation(false);
        searchField.SetFilterButtonVisible(false);

        return searchField;
    }

    function selectEntity(identifier: string): void {
        if (identifier && (_schemaEntities || []).length) {
            for (let iCnt = 0, iLen = _schemaEntities.length; iCnt < iLen; iCnt++) {
                if (_schemaEntities[iCnt].ID == identifier) { /* jslint ignore:line */
                    _currentlySelectedEntity = _schemaEntities[iCnt];
                    break;
                }
            }
        }

        showContentHeader();
    }

    function $getContentHeader() {
        return $(Templates.Menus.IndividualDataContentHeader({
            Item: _currentlySelectedEntity || { Title: i18next.t('Misc.UseSearch') },
            Schema: _currentSchema
        }));
    }

    function showContentHeader() {
        var $content = $('.content');
        var $contentHeader = $('.content-header');
        var $contentHeaderNew = $getContentHeader();

        if ($contentHeader.length) {
            $contentHeader.replaceWith($contentHeaderNew);
        } else {
            $content.closest('main').before($contentHeaderNew);
        }

        App.ResizeLocationPicker();

        $contentHeaderNew.on('click', onContentHeaderClick);
    }

    export function Show(menuID: string, entityIdentifier?: string) {
        currentMenuItem = Menu.GetMenuItemConfig(menuID);

        if (!currentMenuItem) {
            return;
        }

        const schemaType = currentMenuItem.Configuration.IndividualDataSchema;

        if (_currentlySelectedEntity &&
            entityIdentifier === _currentlySelectedEntity.ID) {
            return;
        }

        if (!!entityIdentifier && (_currentSchema || { Type: null }).Type === schemaType) {
            selectEntity(entityIdentifier);
            return;
        } else {
            _currentlySelectedEntity = null;
        }

        _$content = Utils.GetContentContainer();

        _currentSchema = DAL.Schemas.GetByType(schemaType);
        _schemaEntities = null;

        if (_currentSchema) {
            Utils.Spinner.Show();

            setTimeout(function() {
                onAfterDataLoaded(DAL.IndividualData.GetByType(schemaType), entityIdentifier);
            }, 100);
        } else {
            Utils.Message.Show(i18next.t('IndividualData.SchemaNotExisting.MessageHeader'),
                i18next.t('IndividualData.SchemaNotExisting.MessageBody'),
                {
                    Close: function() {
                        Utils.Router.ReplaceState('#main');
                    }
                });
        }
    }

    export function Close() {
        if (!_$content) {
            return;
        }
        _$content.find('img[data-file-identifier]').off().on('click', onThumbnailClick);
    }

    export function UpdateItems() {
        if (!_currentSchema) {
            return;
        }

        Utils.Spinner.Show();

        setTimeout(function() {
            onAfterDataLoaded(
                DAL.IndividualData.GetByType(_currentSchema.Type),
                _currentlySelectedEntity ? _currentlySelectedEntity.ID : null);
        }, 100);
    }
}
