//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../templates.d.ts"  />
/// <reference path="./model.ts"  />
/// <reference path="./model.errors.ts"  />
/// <reference path="./database/model.database.ts" />
/// <reference path="../app/app.session.ts" />
//imports-end

module Model {
    export type ItemTitlePatternCallback = (item: TreeItem | TreeSourceItem) => string;
    export type ItemFilterCallback = (item?: TreeItem | TreeSourceItem) => boolean;
    export type ItemIsDisabledCallback = (item?: TreeItem | TreeSourceItem) => boolean;
    export type ItemClickCallback = (evt: any) => void;

    export type TreeCounter = { [id: string]: number };

    export class Tree {
        Identifier: string = '';
        KeyProperty: string = 'OID';
        ItemTitlePattern: string | ItemTitlePatternCallback = null;
        NodeHeight: number = 50;
        ScrollPosition: number = 0;
        IsInitialised: boolean = false;
        RouteBase: string = '';
        AnchorSuffix: string = '';
        EnableAnchors: boolean = false;
        IsReadonly: boolean = false;
        ForceExpand: boolean = false;
        ShowColors: boolean = false;
        ShowParentTitle: boolean = false;
        RootIsCollapsable: boolean = false;
        ShowExpanders: boolean = true;
        EnableSelection: boolean = false;
        EnableCheckmarks: boolean = false;
        PreventAnchorActionOnce: boolean = false;
        RenderAsListView: boolean = false;
        HideUnsearchedItems: boolean = false;
        ScrollToActiveNode: boolean = true;
        HideUnmatchedListViewItems: boolean = false;
        AdditionalClasses: Array<string> | Dictionary<Array<string>> = null;
        AdditionalTexts: string = null;
        FnFilter: ItemFilterCallback = function() {
            return true;
        };
        FnIsDisabled: ItemIsDisabledCallback = null;
        LastFilter: ClearableInput.SearchFilter;
        ActiveItemHierarchyIdentifiers: Dictionary<boolean> = {};
        Items: Array<TreeItem> = [];
        ItemDictionary: Dictionary<TreeItem> = {};
        Nodes: Array<Node> = [];
        NodeDictionary: Dictionary<Node> = {};
        VisibleNodes: Array<Node> = [];
        VisibleNodesDictionary: Dictionary<boolean | Node> = {};
        SelectedNodeIdentifiers: Array<string> = [];
        SearchFields: Array<string> = ['Title'];
        ResourceManager: ResourceManager = new ResourceManager();
        RootItem: TreeItem = null;
        RootItemIdentifier: string = null;
        ActiveItemIdentifier: string = null;
        $RenderingContainer: any = null;
        OnNodeClick: ItemClickCallback = null;
        SearchField: Model.ClearableInput.Control = null;
        TopNodePosition: number = 0;
        BottomNodePosition: number = 0;
        Counters: TreeCounter = {};
        private SearchHasResults: boolean = false;
        private SearchMatches: string[];

        public get SearchText(): string {
            return this.LastFilter ? this.LastFilter.SearchText : null;
        }

        protected get SearchIsActive(): boolean {
            return this.LastFilter &&
                (
                    (this.LastFilter.Keywords && this.LastFilter.Keywords.length > 0)
                    ||
                    (this.LastFilter.SearchText && this.LastFilter.SearchText.length > 0)
                );
        }

        constructor() {
        }

        SetNodeHeight(height: number): Tree {
            if (isNaN(height)) {
                return this;
            }

            if (height < 40) {
                height = 40;
            }

            this.NodeHeight = height;

            return this;
        }

        SetIdentifier(identifier: string): Tree {
            if (!identifier) {
                return this;
            }

            this.Identifier = identifier;

            return this;
        }

        SetKeyProperty(propertyName: string): Tree {
            if (!propertyName) {
                return this;
            }

            this.KeyProperty = propertyName;

            return this;
        }

        SetItemTitlePattern(pattern: string | ItemTitlePatternCallback): Tree {
            this.ItemTitlePattern = pattern;

            return this;
        }

        SetSearchFields(searchFields: Array<string>): Tree {
            if (!(searchFields || []).length) {
                return this;
            }

            this.SearchFields = searchFields;

            return this;
        }

        SetRouteBase(routeBase: string): Tree {
            this.RouteBase = !!routeBase ? routeBase : null;

            return this;
        }

        SetAnchorSuffix(suffix: string): Tree {
            this.AnchorSuffix = !!suffix ? suffix : null;

            return this;
        }

        SetEnableAnchors(enableAnchors: boolean): Tree {
            this.EnableAnchors = !!enableAnchors;

            return this;
        }

        SetIsReadonly(isReadonly: boolean): Tree {
            this.IsReadonly = !!isReadonly;

            return this;
        }

        SetForceExpand(forceExpand: boolean): Tree {
            this.ForceExpand = !!forceExpand;

            return this;
        }

        SetShowColors(showColors: boolean): Tree {
            this.ShowColors = !!showColors;

            return this;
        }

        SetShowParentTitle(showParentTitle: boolean): Tree {
            this.ShowParentTitle = !!showParentTitle;

            return this;
        }

        SetRootCollapsable(isCollapsable: boolean): Tree {
            this.RootIsCollapsable = !!isCollapsable;

            return this;
        }

        SetShowExpanders(showExpanders: boolean): Tree {
            this.ShowExpanders = !!showExpanders;

            return this;
        }

        SetEnableSelection(enableSelection: boolean): Tree {
            this.EnableSelection = !!enableSelection;

            return this;
        }

        SetEnableCheckmarks(enableCheckmarks: boolean): Tree {
            this.EnableCheckmarks = !!enableCheckmarks;

            if (this.$RenderingContainer instanceof $ &&
                this.$RenderingContainer.length) {
                this.$RenderingContainer.find('.tree').toggleClass('enable-checkmarks', this.EnableCheckmarks);
            }

            return this;
        }

        SetPreventAnchorActionOnce(preventOnce: boolean): Tree {
            this.PreventAnchorActionOnce = !!preventOnce;

            return this;
        }

        SetHideUnsearched(hideUnsearchedItems: boolean): Tree {
            this.HideUnsearchedItems = !!hideUnsearchedItems;

            return this;
        }

        SetRenderAsListView(renderAsListView: boolean): Tree {
            this.RenderAsListView = !!renderAsListView;

            return this;
        }

        SetScrollToActiveNode(scrollToActiveNode: boolean): Tree {
            this.ScrollToActiveNode = !!scrollToActiveNode;

            return this;
        }

        SetAdditionalClasses(additionalClasses: Array<string> | Dictionary<Array<string>>): Tree {
            if (!Utils.HasProperties(additionalClasses)) {
                return this;
            }

            this.AdditionalClasses = additionalClasses;

            return this;
        }

        SetAdditionalTexts(additionalTexts: any): Tree {
            const hasChanged = Utils.Equals(this.AdditionalTexts, additionalTexts);

            this.AdditionalTexts = additionalTexts;

            if (hasChanged) {
                const textsAvailable = Utils.HasProperties(additionalTexts);

                this.Nodes
                    .forEach(function(node) {
                        const additionalText = textsAvailable && additionalTexts.hasOwnProperty(node.Identifier)
                            ? additionalTexts[node.Identifier]
                            : undefined;

                        if (!Utils.Equals(additionalText, node.GetAdditionalText())) {
                            node
                                .SetAdditionalText(additionalText)
                                .Rerender();
                        }
                    });
            }

            return this;
        }

        SetFilter(fn: ItemFilterCallback): Tree {
            this.FnFilter = fn || function() {
                return true;
            };

            return this;
        }

        SetFnIsDisabled(fn: ItemIsDisabledCallback): Tree {
            this.FnIsDisabled = fn;
            return this;
        }

        SetItems(items: Array<TreeSourceItem> | Dictionary<TreeSourceItem>, rootItemIdentifier?: string): Tree {
            rootItemIdentifier = rootItemIdentifier || this.RootItemIdentifier;
            if (!items || !this.RenderAsListView && !rootItemIdentifier) {
                return this;
            }

            if (this.RenderAsListView) {
                this.Items = this._initListItems(items);
            } else {
                this.RootItemIdentifier = rootItemIdentifier;
                this.Items = this._initTreeItemsWithRoot(items, rootItemIdentifier);
            }

            this.ItemDictionary = this._getItemDictionaryPrepared(this.Items);

            if (this.ItemDictionary && !this.RenderAsListView) {
                if (!this.ActiveItemIdentifier) {
                    this.ActiveItemIdentifier = rootItemIdentifier;
                }
                this._setUnfoldedParentNodeIdentifiers(this.ItemDictionary[this.ActiveItemIdentifier], this.ForceExpand);
            }

            this._initNodes();
            this._setRenderingContainerHeight();

            if (this.SearchIsActive) {
                this._onSearchUpdate(this.LastFilter);
            }

            return this;
        }

        SetItemsFromRoot(rootItem: TreeItem | any): Tree {
            if (!rootItem) {
                return this;
            }

            if (this.RenderAsListView) {
                throw new Errors.ArgumentError('Ungültiger Funktionsaufruf!');
            }

            this.RootItemIdentifier = rootItem[this.KeyProperty];
            this.Items = this._initTreeItemsFromRoot(rootItem);

            this.ItemDictionary = this._getItemDictionaryPrepared(this.Items);

            if (this.ItemDictionary) {
                if (!this.ActiveItemIdentifier) {
                    this.ActiveItemIdentifier = this.RootItemIdentifier;
                }
                this._setUnfoldedParentNodeIdentifiers(this.ItemDictionary[this.ActiveItemIdentifier], this.ForceExpand);
            }

            this._initNodes();
            this._setRenderingContainerHeight();

            return this;
        }

        SetRenderingContainer($container: any): Tree {
            if (!($container instanceof $) || !$container.length) {
                return this;
            }

            this.$RenderingContainer = $container;

            this._unbindEvents();
            this._bindEvents();

            return this;
        }

        SetActiveItem(itemIdentifier: string): Tree {
            if (!itemIdentifier ||
                this._itemIsActive(itemIdentifier) ||
                !this.ItemDictionary) {
                return this;
            }

            const activeItem = this.ItemDictionary[itemIdentifier];
            if (!activeItem) {
                return this;
            }

            const previouslyActiveItemIdentifier = this.ActiveItemIdentifier;
            this.ActiveItemIdentifier = itemIdentifier;
            activeItem.IsActive = true;

            if (this.RenderAsListView) {
                if (!!previouslyActiveItemIdentifier) {
                    const previouslyActiveNode = this.NodeDictionary[previouslyActiveItemIdentifier];

                    if (previouslyActiveNode) {
                        previouslyActiveNode
                            .SetIsActive(false)
                            .Rerender();
                    }
                }

                const node = this.NodeDictionary[itemIdentifier];
                if (node) {
                    node
                        .SetIsActive(true)
                        .Rerender();
                }
            } else {
                this._addItemAndParentsToUnfoldedItems(activeItem);
                this._createMissingNodes(activeItem);
                this._updateVisibleNodes();
                this._prepareNodes(itemIdentifier);
                this._setRenderingContainerHeight();
            }

            return this;
        }

        SetSelectedItems(identifiers: Array<string>): Tree {
            if (!(identifiers || [])) {
                return this;
            }

            this.SelectedNodeIdentifiers = identifiers;

            return this;
        }

        SetOnNodeClick(fn: ItemClickCallback): Tree {
            this.OnNodeClick = fn;

            this._unbindEvents();
            this._bindEvents();

            return this;
        }

        SetSearchField(searchField: Model.ClearableInput.Control, placeholderText: string): Tree {
            if (!searchField) {
                return this;
            }

            this.SearchField = searchField;

            if (!!placeholderText) {
                this.SearchField.SetPlaceholderText(placeholderText);
            }

            this._unbindEvents();
            this._bindEvents();

            this._onSearchUpdate(searchField.GetFilter());

            return this;
        }

        SetHideUnmatchedListViewItems(hide: boolean): Tree {
            this.HideUnmatchedListViewItems = !!hide;

            return this;
        }

        SetCounters(counters: TreeCounter): Tree {
            this.Counters = counters;
            return this;
        }

        GetCounters() {
            return this.Counters;
        }

        UpdateCounters(counters: TreeCounter): Tree {
            this.Counters = counters;

            this.Nodes
                .forEach((node) => {
                    if (!counters.hasOwnProperty(node.Identifier)) {
                        if (typeof node.GetCounter() !== 'undefined') {
                            node.UnsetCounter()
                                .Rerender();
                        }

                        return;
                    }

                    const counter = counters[node.Identifier];

                    if (counter !== node.GetCounter()) {
                        node.SetCounter(counter)
                            .Rerender();
                    }
                });

            this._updateVisibleNodes();
            this._prepareNodes(this.ActiveItemIdentifier);
            this._setRenderingContainerHeight();

            return this;
        }

        ToggleNodeState(itemIdentifier: string): Tree {
            if (!itemIdentifier || this.RenderAsListView) {
                return this;
            }

            const node = this.NodeDictionary[itemIdentifier];
            if (!node || !node.GetIsCollapsed() && !node.Definition.IsCollapsable) {
                return this;
            }

            if (!node.ToggleCollapsed()) {
                const item = this.ItemDictionary[itemIdentifier];
                this.ActiveItemHierarchyIdentifiers[itemIdentifier] = true;

                this._createMissingNodes(item);
            } else {
                delete this.ActiveItemHierarchyIdentifiers[itemIdentifier];
            }

            this._updateVisibleNodes();
            this._prepareNodes(itemIdentifier);
            this._setRenderingContainerHeight();

            return this;
        }

        ToggleNodeSelection(itemIdentifier: string, unselectSelectItems: boolean): Tree {
            if (!itemIdentifier) {
                return this;
            }

            const instance = this;
            const identifierIndex = instance.SelectedNodeIdentifiers.indexOf(itemIdentifier);

            if (identifierIndex === -1) {
                if (unselectSelectItems) {
                    instance.SelectedNodeIdentifiers.forEach(function(identifier) {
                        const node = instance.NodeDictionary[identifier];

                        if (!node) {
                            return;
                        }

                        node
                            .SetIsSelected(false)
                            .Rerender();
                    });

                    instance.SelectedNodeIdentifiers = [];
                }

                instance.SelectedNodeIdentifiers.push(itemIdentifier);
            } else {
                instance.SelectedNodeIdentifiers.splice(identifierIndex, 1);
            }

            const node = instance.NodeDictionary[itemIdentifier];

            if (node) {
                node
                    .SetIsSelected(!node.GetIsSelected())
                    .Rerender();
            }

            return instance;
        }

        GetNodeByIdentifier(identifier: string): Node {
            return !!identifier && this.NodeDictionary ?
                this.NodeDictionary[identifier] :
                null;
        }

        GetIsNodeCollapsed(identifier: string): boolean {
            if (!identifier ||
                !this.NodeDictionary ||
                !this.NodeDictionary.hasOwnProperty(identifier)) {
                return false;
            }

            return this.NodeDictionary[identifier].GetIsCollapsed();
        }

        UpdateAdditionalClassesAtNode(identifier: string, classes: Array<string>): Tree {
            if (!identifier ||
                !this.NodeDictionary ||
                !this.NodeDictionary.hasOwnProperty(identifier)) {
                return this;
            }

            const node = this.NodeDictionary[identifier];
            node.SetAdditionalClasses(classes);

            if (!this.VisibleNodesDictionary.hasOwnProperty(identifier)) {
                return;
            }

            node.Rerender();

            return this;
        }

        UpdateAdditionalTextAtNode(identifier: string, text: string): Tree {
            if (!identifier ||
                !this.NodeDictionary ||
                !this.NodeDictionary.hasOwnProperty(identifier)) {
                return this;
            }

            const node = this.NodeDictionary[identifier];
            node.SetAdditionalText(text);

            if (!this.VisibleNodesDictionary.hasOwnProperty(identifier)) {
                return;
            }

            node.Rerender();

            return this;
        }

        UpdateRoute(routeBase: string, anchorSuffix: string): Tree {
            if (!routeBase && !anchorSuffix) {
                return this;
            }

            if (typeof (routeBase) != 'undefined') {
                this.RouteBase = routeBase;
            }

            if (typeof (anchorSuffix) != 'undefined') {
                this.AnchorSuffix = anchorSuffix;
            }

            this._updateVisibleRoute((node) => {
                node.UpdateRoute(this.RouteBase, this.AnchorSuffix);
            });

            return this;
        }

        UpdateRoutesByPattern(pattern: string): Tree {
            if (!pattern) {
                return this;
            }

            this._updateVisibleRoute((node) => {
                node.UpdateRouteByPattern(pattern);
            });

            return this;
        }

        private _updateVisibleRoute(fn: Function): void {
            if (!this.NodeDictionary) {
                return;
            }

            for (let identifier in this.NodeDictionary) {
                if (this.NodeDictionary.hasOwnProperty(identifier)) {
                    const node = this.NodeDictionary[identifier];

                    fn.call(this, node);

                    if (this.VisibleNodesDictionary.hasOwnProperty(identifier)) {
                        node.Rerender();
                    }
                }
            }
        }

        ScrollToNode(identifier: string): Tree {
            if (!identifier) {
                return this;
            }

            this._scrollToNode(identifier);

            return this;
        }

        Build(): Tree {
            if (!(this.$RenderingContainer instanceof $) || !this.$RenderingContainer.length) {
                return this;
            }

            this._render();
            //unbindEvents.call(this);
            //bindEvents.call(this);

            return this;
        }

        private _getItemDictionaryPrepared(items: Array<TreeItem>): Dictionary<TreeItem> {
            if (!$.isArray(items) || !items.length) {
                return null;
            }

            const dict = {};
            const keyProperty = this.KeyProperty;

            for (let i = 0; i < items.length; i++) {
                const item = items[i];

                if (!item) {
                    continue;
                }

                const key = item[keyProperty];

                if (key) {
                    dict[key] = this._prepareItem(item);
                }
            }

            return dict;
        }

        private _prepareItem(item: TreeItem): TreeItem {
            if (!item) {
                return null;
            }

            item.Identifier = item[this.KeyProperty] || null;

            if (this.ItemTitlePattern) {
                if (typeof this.ItemTitlePattern === 'string') {
                    let title = this.ItemTitlePattern;
                    let match: RegExpExecArray;

                    while ((match = /\{\w+\}/g.exec(title))) {
                        const attr = match[0].replace(/[\{\}]/g, '');
                        const replaceValue = !!attr && item.hasOwnProperty(attr)
                            ? item[attr] || '-/-'
                            : '-/-';

                        title = title.replace(match[0], replaceValue);
                    }

                    item.DisplayTitle = new Handlebars.SafeString(title);
                } else if (this.ItemTitlePattern instanceof Function) {
                    const title = this.ItemTitlePattern(item);

                    if (title) {
                        item.DisplayTitle = new Handlebars.SafeString(title);
                    } else {
                        item.DisplayTitle = item.Title || '[undefined]';
                    }
                } else {
                    item.DisplayTitle = item.Title || '[undefined]';
                }
            } else {
                item.DisplayTitle = item.Title || '[undefined]';
            }

            return item;
        }

        private _getEmptyResultItem(): any {
            let $item = this.ResourceManager.getItem();
            $item = $item instanceof $ ? $item : $($item);

            $item.attr('class', 'item info')
                .removeAttr('title')
                .removeAttr('style')
                .data('identifier', '')
                .removeAttr('data-identifier')
                .data('href', '')
                .removeAttr('data-href');

            $item.find('.item-content').removeAttr('style');
            if (this.HideUnsearchedItems && !this.SearchIsActive) {
                $item.find('.title .main').html(i18next.t('Misc.UseSearch'));
            } else {
                $item.find('.title .main').html(i18next.t('Misc.NoResultsFound'));
            }
            $item.find('.parent-name').text('');
            $item.find('.additional-text').text('');

            return $item;
        }

        private _render(scrollSpeed?: number, renderExtraItems?: boolean): void {
            // Alle Elemente recyclen, die nicht in der View sind
            // Zugewiesene Elemente wiederverwenden, wegen [laufender] Events
            const $tree = this.$RenderingContainer.find('.tree');
            const oldItems = this.$RenderingContainer.find('.tree').children();

            // wenn Suchergebnisse als Liste angezeigt werden sollen
            if (this.SearchIsActive && this.SearchField.GetSearchPresentation() === 'list') {
                $tree.addClass('force-list-view');
            } else {
                $tree.removeClass('force-list-view');
            }

            for (let i = 0; i < oldItems.length; i++) {
                const $item = $(oldItems.get(i));
                const identifier = $item.data('identifier');

                if (identifier) {
                    let node;
                    if ((node = this.VisibleNodesDictionary[identifier])) {
                        $item.remove();
                        continue;
                    } else if ((node = this.NodeDictionary[identifier])) {
                        node.AssignDOMNode(null);
                    }
                }

                this.ResourceManager.recycleItems($item);
            }

            if (!(this.VisibleNodes || []).length) {
                if (!this.IsInitialised) {
                    const skeleton = this._createTreeSkeleton();

                    this.$RenderingContainer.html(skeleton);

                    this.IsInitialised = true;
                }

                this.$RenderingContainer
                    .find('.tree')
                    .height(this.NodeHeight)
                    .append(this._getEmptyResultItem());
                return;
            }

            const renderAsList = (this.SearchIsActive && this.SearchField && this.SearchField.GetSearchPresentation() === 'list') || this.RenderAsListView;

            this.$RenderingContainer.toggleClass('list-view', renderAsList);

            if (!isNaN(this.ScrollPosition)) {
                this.ScrollPosition = this.$RenderingContainer.scrollTop();
            }

            scrollSpeed = scrollSpeed || 0;

            const tree = [];
            let extraItemsCount = 0;

            if (Session.IsRunningOnIOS && renderExtraItems) {
                const moveDistance = (Math.abs(scrollSpeed) > 1 ? scrollSpeed : 500) * 6;
                extraItemsCount = Math.ceil(moveDistance / this.NodeHeight);
                // TODO weiter tests durchführen, bis dahin auf iOS 200 extraNodes rendern
                extraItemsCount = Math.max(200, extraItemsCount);
            }

            extraItemsCount = Math.max(20, extraItemsCount);

            const viewportItemsCount = Math.ceil(this.$RenderingContainer.height() / this.NodeHeight);
            const maximumItemCount = viewportItemsCount + extraItemsCount;
            let nodeIndex = this.ScrollPosition / this.NodeHeight;

            if (scrollSpeed > 0) {   // nach oben scrollen
                nodeIndex -= 5;
            } else if (scrollSpeed < 0) {     // nach unten scrollen
                nodeIndex -= (extraItemsCount - 5);
            } else {                      //  standard rendern
                nodeIndex -= extraItemsCount / 2;
            }

            // Startelement auf min 0 setzen
            nodeIndex = Math.max(0, Math.floor(nodeIndex));

            this.TopNodePosition = nodeIndex * this.NodeHeight;

            const nodeCount = this.VisibleNodes.length;
            for (let cnt = 0; cnt < maximumItemCount && nodeIndex < nodeCount; cnt++ , nodeIndex++) {
                const node = this.VisibleNodes[nodeIndex];

                if (!node) {
                    continue;
                }

                node.SetIsActive(this._itemIsActive(node.Definition.Item.Identifier));

                let $recItem = node.GetDOMNode();
                if (!$recItem) {
                    $recItem = this.ResourceManager.getItem();
                }
                $recItem = node.SetupItem($recItem, nodeIndex * this.NodeHeight);

                node.AssignDOMNode($recItem);
                tree.push($recItem);
            }

            this.BottomNodePosition = nodeIndex * this.NodeHeight;

            if (!this.IsInitialised) {
                const skeleton = this._createTreeSkeleton(nodeCount);

                this.$RenderingContainer.html(skeleton);

                this.IsInitialised = true;
            }

            this.$RenderingContainer.find('.tree').append(tree);

            if (this.ScrollToActiveNode) {
                this._scrollToNode(this.ActiveItemIdentifier);
            }
        }

        private _createTreeSkeleton(visibleNodesCount: number = 1): string {
            return Templates.Tree.SkeletonStart({
                Height: this.NodeHeight * visibleNodesCount,
                EnableCheckmarks: this.EnableCheckmarks
            }) + Templates.Tree.SkeletonEnd();
        }

        private _createNodeDefinition(item: TreeItem, indentation?: number): NodeDefinition {
            if (!item) {
                return;
            }

            if (this.RootIsCollapsable && indentation) {
                ++indentation;
            }

            const definition = new NodeDefinition({
                Item: item,
                ShowParentTitle: this.ShowParentTitle,
                HighlightWhenActive: !this.EnableSelection,
                Indentation: indentation || 0,
                ShowColor: this.ShowColors,
                IsCollapsable: true,
                IsSelected: false
            });

            if (this.NodeHeight > 40) {
                definition.Height = this.NodeHeight;
            }

            if (this.RootItemIdentifier === item.Identifier) {
                definition.IsRoot = true;
                definition.IsCollapsable = this.RootIsCollapsable;
                definition.Classes['root'] = true;
            }

            if (this.ShowExpanders &&
                (item.Children || []).length &&
                (this.RootIsCollapsable || item.Identifier !== this.RootItemIdentifier)) {
                definition.Classes['parent'] = true;
                definition.IsCollapsed = !definition.IsRoot;

                if (definition.IsCollapsed) {
                    definition.Classes['collapsed'] = true;
                }
            }

            if (!this.ShowExpanders && item.Parent) {
                definition.Classes['list-item'] = true;
            }

            if (this.AdditionalClasses instanceof Array && this.AdditionalClasses.length) {
                definition.AdditionalClasses = this.AdditionalClasses;
            } else if (Utils.HasProperties(this.AdditionalClasses) &&
                this.AdditionalClasses.hasOwnProperty(item.Identifier)) {
                definition.AdditionalClasses = this.AdditionalClasses[item.Identifier];
            }

            if (Utils.HasProperties(this.AdditionalTexts) &&
                this.AdditionalTexts.hasOwnProperty(item.Identifier)) {
                definition.AdditionalText = this.AdditionalTexts[item.Identifier];
            }

            if (this.IsReadonly) {
                definition.Classes['disabled'] = true;
            }

            if ((this.SelectedNodeIdentifiers || []).length &&
                this.SelectedNodeIdentifiers.indexOf(item.Identifier) !== -1) {
                const checkMarkStyle = getCheckmarkBackgroundStyle(
                    indentation,
                    definition.ShowColor,
                    definition.IsCollapsable,
                    definition.Classes['parent']);

                definition.IsSelected = true;
                definition.Classes['selected'] = true;

                $.extend(definition.Styles, checkMarkStyle);
            }

            if (this.EnableAnchors && !!item.Identifier) {
                definition.Href = '{0}/{1}{2}'.format(
                    this.RouteBase,
                    item.Identifier,
                    this.AnchorSuffix || '');
            }

            if (this.FnIsDisabled instanceof Function &&
                this.FnIsDisabled(item)) {
                definition.Classes['disabled'] = true;
                definition.IsDisabled = true;
            }

            if (definition.Item && this.Counters.hasOwnProperty(definition.Item.Identifier)) {
                definition.Counter = this.Counters[definition.Item.Identifier];
            }

            return definition;
        }

        private _itemIsActive(itemIdentifier: string): boolean {
            return this.ActiveItemIdentifier == itemIdentifier;
        }

        private _unbindEvents(): void {
            this.$RenderingContainer.off('click.selectItem');
            this.$RenderingContainer.off('click.toggleCollapsed');

            if ('ontouchstart' in window ||        // works on most browsers
                navigator.maxTouchPoints) // works on IE10/11 and Surface
            {
                this.$RenderingContainer.off('touchstart');
                this.$RenderingContainer.off('touchmove');
                this.$RenderingContainer.off('touchend');
            }

            if (this.SearchField) {
                this.SearchField.ClearFilterListeners();
            }

            this.$RenderingContainer.off('scroll');
        }

        private _bindEvents(): void {
            this.$RenderingContainer
                .on('click.selectItem', '.item', this._cancelDuplicateEvents($.proxy(this._onNodeClick, this)));

            if (this.SearchField) {
                this.SearchField.ClearFilterListeners()
                    .AddFilterListener((filter: ClearableInput.SearchFilter) => {
                        this._onSearchUpdate(filter);
                    });

                this.SearchField.ClearPresentationListeners()
                    .AddPresentationListener((presentation: ClearableInput.PresentationType) => {
                        Session.UserDeviceSettings.SearchResultPresentation = presentation;

                        if (!this.SearchIsActive || !this.SearchHasResults) {
                            return;
                        }

                        // Schaltet die Ansicht der Suchergebnisse um
                        this._updateVisibleNodes();
                        this._prepareNodes(this.ActiveItemIdentifier);
                        this._setRenderingContainerHeight();
                        this.Build();
                    });
            }

            this.$RenderingContainer
                .on('click.toggleCollapsed', '.open-close', this._cancelDuplicateEvents($.proxy(this._onOpenCloseClick, this)));

            const onScrollContainer = this._getOnScrollContainerFunc();

            if ('ontouchstart' in window ||        // works on most browsers
                navigator.maxTouchPoints)         // works on IE10/11 and Surface
            {

                this.$RenderingContainer
                    .on('touchstart', this._onClickIdentify);
                this.$RenderingContainer
                    .on('touchmove', this._onClickIdentify);
                this.$RenderingContainer
                    .on('touchend', this._onClickIdentify);

                this.$RenderingContainer
                    .on('touchstart', $.proxy(onScrollContainer, this));
                this.$RenderingContainer
                    .on('touchmove', $.proxy(onScrollContainer, this));
                this.$RenderingContainer
                    .on('touchend', $.proxy(onScrollContainer, this));
            }

            this.$RenderingContainer
                .on('scroll', $.proxy(onScrollContainer, this));
        }

        private _cancelDuplicateEvents(fn: Function, threshhold?: number, scope?: any): Function {
            if (typeof threshhold !== 'number') {
                threshhold = 300;
            }

            let last = 0;

            return function() {
                const now = new Date().getTime();

                if (now >= last + threshhold) {
                    last = now;
                    fn.apply(scope || this, arguments);
                } else {
                    console.log('duplicate event canceled');
                }
            };
        }

        private _onClickIdentify(evt: any): void {
            if (evt.type === 'touchstart') {
                $(evt.currentTarget).data('touchStart', evt.originalEvent.touches[0]);
            }

            if (evt.type === 'touchend') {
                const touchStart = $(evt.currentTarget).data('touchStart');
                const touchEnd = evt.originalEvent.changedTouches[0];
                const diffX = Math.abs(touchEnd.clientX - touchStart.clientX);
                const diffY = Math.abs(touchEnd.clientY - touchStart.clientY);

                if (diffY < 5 && diffX < 5) {
                    const itemTarget = $(evt.target).closest('.item');

                    $(this).trigger({
                        type: 'click',
                        currentTarget: itemTarget,
                        target: evt.target
                    });

                    evt.preventDefault();
                }
            }
        }

        private _onNodeClick(evt: any): void {
            // !! Daten können sich während 'OnNodeClick' ändern !!
            // !! Daher notwendige Daten vorab übernehmen !!
            // const identifier = $(evt.currentTarget).data('identifier');
            const href = $(evt.currentTarget).data('href');

            // TODO pass identifier
            if (this.OnNodeClick instanceof Function) {
                this.OnNodeClick(evt);
            }
            // TODO toggle node state instead of in this.OnNodeClick

            if (this.PreventAnchorActionOnce) {
                this.PreventAnchorActionOnce = false;
            } else {
                this._navigate(href);
            }
        }

        private _onOpenCloseClick(evt: any): void {
            evt.stopPropagation();

            const $item = $(evt.currentTarget).closest('.item');
            const identifier = $item.data('identifier');

            this.ToggleNodeState(identifier)
                .Build();
        }

        private _onSearchUpdate(filter: ClearableInput.SearchFilter): void {
            this.LastFilter = filter ? Utils.CloneObject(filter) : null;

            if (this.LastFilter.SearchText) {
                this.LastFilter.SearchText = Utils.EscapeHTMLEntities(this.LastFilter.SearchText);
            }

            if (!this.SearchIsActive) {
                // suche zurücksetzen
                this._resetSearch();
                this.Build();
                return;
            }

            const searchMatches = this._performItemsSearch(this.LastFilter);

            this.SearchHasResults = searchMatches && searchMatches.length > 0;
            this.SearchMatches = searchMatches;

            // View aktualisieren
            this._updateVisibleNodes();
            this._prepareNodes(this.ActiveItemIdentifier);
            this._setRenderingContainerHeight();
            this.Build();

            // ersten Treffer in den Focus rücken
            if (searchMatches && searchMatches.length) {
                this.ScrollToNode(searchMatches[0]);
            }
        }

        private _getOnScrollContainerFunc(): (evt: any) => void {
            const lastScroll = {
                top: 0,
                time: 0,
                touchActive: false,
                speed: 0
            };

            return (evt) => {
                let forceRender = false;
                if (evt) {
                    if (evt.type === 'touchstart') {
                        lastScroll.time = new Date().getTime();
                        lastScroll.touchActive = true;
                        forceRender = true;
                    }

                    if (evt.type === 'touchend') {
                        forceRender = true;
                    }
                }

                // TODO: BEGIN
                // Optimierung für IsRunningOnIOS:
                // es scheint bei touchend keine änderung der nodes mehr möglich
                // zu testen: änderung der nodes bei touchmove
                // wenn möglich: nach dem ersten touchmove die max nötige anzahl der nodes in die richtige richtung rendern
                // bei wechsel der scrollrichtung: die nodes in die jeweilige richtung neu rendern
                // TODO: END
                if (Session.IsRunningOnIOS) {
                    clearTimeout($.data(this, 'scrollTimer'));
                    $.data(this, 'scrollTimer', setTimeout(() => {
                        // re-render tree view
                        this._render(null, true);
                    }, 250));
                }

                // Aktion nur ausführen, wenn ein bestimmter bereich gescrollt wurde
                // oder rendern durch 'momentum scrolling' erzwungen wird
                this.ScrollPosition = this.$RenderingContainer.scrollTop();

                const scrollDiff = this.ScrollPosition - lastScroll.top;

                if (Math.abs(scrollDiff) < this.NodeHeight && !forceRender) {
                    return;
                }

                lastScroll.top = this.ScrollPosition;
                let scrollSpeed = scrollDiff > 0 ? 1 : -1;

                if (lastScroll.touchActive) {
                    const now = new Date().getTime();
                    scrollSpeed = scrollDiff / (now - lastScroll.time) * 1000;
                    lastScroll.time = now;

                    if (forceRender) {
                        scrollSpeed = lastScroll.speed;
                    } else {
                        lastScroll.speed = scrollSpeed;
                    }
                }

                if (this._isUpdateRequired(scrollSpeed) || forceRender) {
                    this._render(scrollSpeed, forceRender);
                }

                if (forceRender) {
                    lastScroll.touchActive = false;
                }
            };
        }

        private _isUpdateRequired(scrollSpeed: number): boolean {
            const viewport = this.$RenderingContainer.height();
            const topOffset = this.ScrollPosition;
            const fullHeight = this.$RenderingContainer.find('.tree').height();

            const hasBottomNodeReached = this.BottomNodePosition < Math.min(topOffset + viewport + 150, fullHeight);
            const hasTopNodeReached = this.TopNodePosition > Math.max(0, topOffset - 150);

            if (scrollSpeed > 0) {
                return hasBottomNodeReached;
            } else if (scrollSpeed < 0) {
                return hasTopNodeReached;
            } else {
                return hasTopNodeReached || hasBottomNodeReached;
            }
        }

        private _getSearchText(): string {
            return this.SearchIsActive ?
                this.SearchText :
                null;
        }

        private _getSearchRegEx(searchText: string): RegExp {
            if (!searchText) {
                return null;
            }
            return new RegExp(Utils.EscapeRegExPattern(searchText), 'i');
        }

        private _performItemsSearch(filter: ClearableInput.SearchFilter): string[] | null {
            const regEx = this._getSearchRegEx(filter.SearchText);
            const matches: string[] = [];
            const keywordProperties: Model.Properties.Property[] = !regEx ? [] : (DAL.Properties.GetByType(Enums.PropertyType.Keyword) || [])
                .filter((prop: Model.Properties.Property) => regEx.test(prop.Title));

            for (const item of this.Items) {
                // Vorfiltern nach Schlagwörtern
                if (filter.Keywords && filter.Keywords.length) {
                    if (!item.Properties || !item.Properties.length) {
                        continue;
                    }

                    const hasMatchingKeywords = item.Properties.some((propertyOID: string) =>
                        filter.Keywords.some((keywordOID: string) =>
                            propertyOID === keywordOID
                        )
                    );

                    if (!hasMatchingKeywords) {
                        continue;
                    }
                }

                if (regEx) {
                    const textFound = this.SearchFields
                        .some(function(attr: string) {
                            const value = this.item[attr];
                            if (!value) {
                                return false;
                            }

                            return this.regEx.test(value);
                        }, { item, regEx });

                    if (!textFound) {
                        if (!item.Properties ||
                            !keywordProperties || !keywordProperties.length) {
                            continue;
                        }

                        const hasMatchingKeywords = item.Properties.some((propertyOID: string) =>
                            keywordProperties.some((keyword: Model.Properties.Property) =>
                                propertyOID === keyword.OID
                            )
                        );

                        if (!hasMatchingKeywords) {
                            continue;
                        }
                    }
                }

                matches.push(item.Identifier);
            }

            return matches.length ? matches : null;
        }

        private _resetSearch(): void {
            delete this.SearchHasResults;
            delete this.SearchMatches;

            const activeItem = this.ItemDictionary ? this.ItemDictionary[this.ActiveItemIdentifier] : null;

            this._setUnfoldedParentNodeIdentifiers(activeItem, this.ForceExpand);
            this._createMissingNodes(activeItem);
            this._updateVisibleNodes();
            this._prepareNodes(this.ActiveItemIdentifier);

            this._setRenderingContainerHeight();
        }

        private _navigate(fragment: string): void {
            Utils.Router.PushState(fragment);
        }

        private _prepareNodes(selectedItemIdentifier?: string): void {
            if (!this.Nodes) {
                return;
            }

            const searchText = this._getSearchText();
            for (const node of this.Nodes) {
                const nodeItem = node.Definition.Item;
                const nodeIsFoldedOut = this.ActiveItemHierarchyIdentifiers[nodeItem.Identifier];
                const nodeIsVisible = nodeIsFoldedOut ||
                    nodeItem.Parent && this.ActiveItemHierarchyIdentifiers[nodeItem.Parent.Identifier] ||
                    !!this.VisibleNodesDictionary[nodeItem.Identifier];

                if (nodeIsVisible) {
                    node.SetIsCollapsed(!nodeIsFoldedOut);
                }
                node.SetHighlightedTitle(searchText);

                if (selectedItemIdentifier && nodeItem.Identifier === selectedItemIdentifier) {
                    nodeItem.IsActive = false;
                }
            }
        }

        private _setRenderingContainerHeight(): void {
            if (!(this.$RenderingContainer instanceof $) ||
                !this.$RenderingContainer.length) {
                return;
            }

            // Math.max() => mind ein Element Platz für Info lassen
            const height = Math.max(this.VisibleNodes.length, 1) * this.NodeHeight;
            this.$RenderingContainer.find('.tree')
                .css('height', height);
        }

        private _addItemAndParentsToUnfoldedItems(item: TreeItem): void {
            if (this.RenderAsListView) {
                return;
            }

            while (item && !this.ActiveItemHierarchyIdentifiers.hasOwnProperty(item.Identifier)) {
                this.ActiveItemHierarchyIdentifiers[item.Identifier] = true;
                item = item.Parent;
            }
        }

        private _setUnfoldedParentNodeIdentifiers(item: TreeItem, unfoldTree: boolean): void {
            if (unfoldTree) {
                this._unfoldWholeTree();
                return;
            }

            if (!item) {
                return;
            }

            this.ActiveItemHierarchyIdentifiers = {};

            while (item) {
                this.ActiveItemHierarchyIdentifiers[item.Identifier] = true;
                if (item.Identifier === this.RootItemIdentifier) {
                    break;
                }
                item = item.Parent;
            }
        }

        private _unfoldWholeTree(): void {
            this.ActiveItemHierarchyIdentifiers = {};

            if (!this.RootItem) {
                return;
            }

            const walkTree = (item: TreeItem) => {
                if (!(item.Children || []).length) {
                    return;
                }

                this.ActiveItemHierarchyIdentifiers[item.Identifier] = true;

                for (let i = 0; i < item.Children.length; i++) {
                    const child = this.ItemDictionary[item.Children[i].Identifier];

                    if (child) {
                        walkTree.call(this, child);
                    }
                }
            }
            walkTree(this.RootItem);

            if (!Object.keys(this.ActiveItemHierarchyIdentifiers).length &&
                this.RootItem) {
                this.ActiveItemHierarchyIdentifiers[this.RootItem.Identifier] = true;
            }
        }

        private _checkIfItemNodeIsVisible(item: TreeItem): boolean {
            return item && (this.ActiveItemHierarchyIdentifiers[item.Identifier] ||
                item.Parent && this.ActiveItemHierarchyIdentifiers[item.Parent.Identifier]);
        }

        private _initListItems(items: Array<TreeSourceItem> | Dictionary<TreeSourceItem>): Array<TreeItem> {
            return $.map(items, (item) => {
                if (!this.FnFilter(item)) {
                    return null;
                }

                return item;
            });
        }

        private _initTreeItemsWithRoot(items: Array<TreeSourceItem> | Dictionary<TreeSourceItem>, rootItemIdentifier: string): Array<TreeItem> {
            const self = this;
            const keyProperty = self.KeyProperty;
            this.RootItem = null;

            $.each(items, function() {
                if (this[keyProperty] === rootItemIdentifier) {
                    self.RootItem = this;
                    return false;
                }
            });

            if (!self.RootItem) {
                return null;
            }

            // Iterate through children
            return [this.RootItem].concat(this._getChildrenTreeItems(this.RootItem));
        }

        private _initTreeItemsFromRoot(rootItem: TreeItem): Array<TreeItem> {
            if (rootItem == null) {
                this.RootItem = null;
                return null;
            }

            this.RootItem = rootItem;

            // Iterate through children
            return [this.RootItem].concat(this._getChildrenTreeItems(this.RootItem));
        }

        private _getChildrenTreeItems(item: TreeItem): Array<TreeItem> {
            if (!item.Children || !item.Children.length) {
                return [];
            }

            const result = [];
            for (let i = 0; i < item.Children.length; i++) {
                const child = item.Children[i];

                if (!this.FnFilter(child)) {
                    continue;
                }

                result.push(child);
                result.push(...this._getChildrenTreeItems(child));
            }

            return result;
        }

        private _initNodes(): void {
            this.Nodes = [];
            this.NodeDictionary = {};
            this.VisibleNodes = [];
            this.VisibleNodesDictionary = {};

            if (this.RenderAsListView) {
                this._initListViewNodes();
            } else {
                this._initTreeViewNodes(this.RootItem);
            }
        }

        private _initTreeViewNodes(item: TreeItem, indentation?: number): void {
            if (!this._checkIfItemNodeIsVisible(item)) {
                return;
            }
            if (!this.ItemDictionary.hasOwnProperty(item.Identifier)) {
                return;
            }

            indentation = indentation || 0;
            const node = this._createNodeAndAppend(item, indentation);

            if (node) {
                if (indentation > 0) {
                    node.SetIsCollapsed(!this.ForceExpand);
                }

                this.VisibleNodes.push(node);
                this.VisibleNodesDictionary[node.Identifier] = true;
            }

            if (item.Children) {
                for (let i = 0; i < item.Children.length; i++) {
                    this._initTreeViewNodes(item.Children[i], indentation + 1);
                }
            }
        }

        private _initListViewNodes(): void {
            if (!this.Items) {
                return;
            }

            for (let i = 0; i < this.Items.length; i++) {
                const item = this.Items[i];
                const node = this._createNodeAndAppend(item);

                // blendet Daten aus, wenn keine Suche stattfindet
                if (node && !this.HideUnsearchedItems) {
                    this.VisibleNodes.push(node);
                    this.VisibleNodesDictionary[node.Identifier] = true;
                }
            }
        }

        private _createNodeAndAppend(item: TreeItem, indentation?: number): Node {
            if (!item || this.NodeDictionary.hasOwnProperty(item.Identifier)) {
                return null;
            }

            const node = new Node(this._createNodeDefinition(item, indentation));
            this.Nodes.push(node);
            this.NodeDictionary[node.Identifier] = node;
            return node;
        }

        private _createMissingNodesUpwards(item: TreeItem): void {
            if (!item) {
                return;
            }

            // walk up and create parent Nodes
            const parents = [];
            while (item) {
                item = this.ItemDictionary[item.Identifier];
                if (!item) {
                    break;
                }
                parents.push(item.Identifier);
                if (item.Identifier === this.RootItemIdentifier) {
                    break;
                }
                item = item.Parent;
            }

            let level = 0;
            let currentParent: string;
            while (currentParent = parents.pop()) {
                const parent = this.ItemDictionary[currentParent];

                if (!this.NodeDictionary.hasOwnProperty(currentParent)) {
                    this._createNodeAndAppend(parent, level++);
                }

                this._createImmediateChildNodes(parent);
            }
        }

        private _createImmediateChildNodes(item: TreeItem): void {
            if (!item || !item.Children || !this.ItemDictionary.hasOwnProperty(item.Identifier)) {
                return;
            }

            const parentNode = this.NodeDictionary[item.Identifier];

            if (!parentNode) {
                return;
            }

            const indentation = ((parentNode.Definition.Indentation || 0) + 1);

            for (let i = 0; i < item.Children.length; i++) {
                const child = this.ItemDictionary[item.Children[i].Identifier];
                if (child) {
                    this._createNodeAndAppend(child, indentation);
                }
            }
        }

        private _createMissingNodes(item: TreeItem): void {
            if (!this.NodeDictionary || !item) {
                return;
            }

            if (!this._checkIfItemNodeIsVisible(item)) {
                return;
            }

            if (!this.NodeDictionary[item.Identifier]) {
                this._createMissingNodesUpwards(item);
            } else {
                this._createImmediateChildNodes(item);
            }
        }

        private _updateVisibleNodes(): void {
            this.VisibleNodes = [];
            this.VisibleNodesDictionary = {};

            if (this.SearchIsActive && !this.SearchHasResults) {
                // keine Suchergebnisse zum anzeigen vorhanden
                return;
            }

            if (!this.SearchIsActive && this.HideUnsearchedItems) {
                // blendet Daten aus, wenn keine Suche stattfindet
                return;
            }

            const renderAsList = this.RenderAsListView ||
                (this.SearchIsActive && this.SearchField && this.SearchField.GetSearchPresentation() === 'list');

            // sichtbare Nodes in VisibleNodes einsetzen
            if ((this.RenderAsListView && !this.HideUnmatchedListViewItems) ||
                renderAsList && !this.SearchIsActive) {
                this._initListViewNodes();

                this.VisibleNodesDictionary = this.NodeDictionary;
                this.VisibleNodes = this.Nodes;
            } else if (renderAsList) {
                for (const oid of this.SearchMatches) {
                    let node = this.NodeDictionary[oid];

                    if (!node) {
                        const item = this.ItemDictionary[oid];
                        node = this._createNodeAndAppend(item);
                    }

                    if (!node) {
                        continue;
                    }

                    this.VisibleNodes.push(node);
                    this.VisibleNodesDictionary[oid] = node;
                }
            } else {
                // Funktion zum Zusammenbau des Tree
                const buildVisible = (item: TreeItem, nodeCollection: Dictionary<Node>, indentation: number = 0) => {
                    if (!item) {
                        return;
                    }

                    const node = nodeCollection[item.Identifier];
                    if (node) {
                        this.VisibleNodes.push(node);
                        this.VisibleNodesDictionary[item.Identifier] = node;

                        node.Definition.Indentation = indentation;

                        // TODO umstellen zu for-each #9245
                        if (item.Children && this.ActiveItemHierarchyIdentifiers[item.Identifier]) {
                            for (let i = 0; i < item.Children.length; i++) {
                                const child = item.Children[i];
                                buildVisible(child, nodeCollection, indentation + 1);
                            }
                        }
                    }
                };

                if (this.SearchMatches && this.SearchMatches.length) {
                    const toBeVisibleNodes: Dictionary<Node> = {};

                    // nur benötigte Elemente sichtbar schalten
                    for (const oid of this.SearchMatches) {
                        let item = this.ItemDictionary[oid];

                        this._createMissingNodesUpwards(item);

                        // Erstellt Unterelemente für aufgeklappte Suchergebnisse
                        if (this.ActiveItemHierarchyIdentifiers[item.Identifier]) {
                            const nodesToAppendChildrenOf = [item.Identifier];

                            while (nodesToAppendChildrenOf.length) {
                                const identifier = nodesToAppendChildrenOf.pop();
                                const curNode = this.ItemDictionary[identifier];

                                if (!curNode.Children || !curNode.Children.length) {
                                    continue;
                                }

                                for (const childItem of curNode.Children) {
                                    const childIdentifier = childItem[this.KeyProperty] || null;
                                    if (!this.ItemDictionary[childIdentifier]) {
                                        continue;
                                    }

                                    const childNode = this.NodeDictionary[childIdentifier] || this._createNodeAndAppend(childItem);

                                    if (!childNode) {
                                        continue;
                                    }

                                    toBeVisibleNodes[childIdentifier] = childNode;

                                    if (this.ActiveItemHierarchyIdentifiers[childIdentifier]) {
                                        nodesToAppendChildrenOf.push(childIdentifier);
                                    }
                                }
                            }
                        }

                        // wählt alle notwendigen Elemente bis zum Root aus und klappt diese auf
                        while (item && !toBeVisibleNodes[item.Identifier]) {
                            const node = this.NodeDictionary[item.Identifier];
                            if (node) {
                                toBeVisibleNodes[item.Identifier] = node;
                            }

                            if (item.Parent) {
                                const node = this.NodeDictionary[item.Parent.Identifier];
                                if (node) {
                                    node.SetIsCollapsed(false)
                                }

                                this.ActiveItemHierarchyIdentifiers[item.Parent.Identifier] = true;
                                item = this.ItemDictionary[item.Parent.Identifier];
                            } else {
                                item = null;
                            }
                        }
                    }

                    buildVisible(this.RootItem, toBeVisibleNodes);
                } else {
                    buildVisible(this.RootItem, this.NodeDictionary);
                }
            }
        }

        private _scrollToNode(identifier: string): void {
            if (!identifier) {
                return;
            }

            const nodeIndex = Utils.GetIndex(this.VisibleNodes, identifier, 'Identifier');

            if (nodeIndex === -1) {
                return;
            }

            const maximumItemCount = Math.ceil(this.$RenderingContainer.height() / this.NodeHeight) + 7;

            if (nodeIndex >= maximumItemCount - 20) {
                let self = this;

                let onScrollContainer = this._getOnScrollContainerFunc();
                this.$RenderingContainer
                    .stop()
                    .animate(
                        {
                            scrollTop: nodeIndex * this.NodeHeight
                        }, {
                            duration: 500,
                            easing: 'linear',
                            progress: function() {
                                onScrollContainer.call(self);
                            },
                            start: function() {
                                self.SetScrollToActiveNode(false);
                                onScrollContainer.call(self);
                                self.Build();
                            },
                            complete: function() {
                                self.SetScrollToActiveNode(false);
                                onScrollContainer.call(self);
                                self.Build();
                            }
                        }
                    );
            } else if (this.$RenderingContainer.is(':animated')) {
                this.$RenderingContainer.stop();
                this.Build();
            }
        }
    }

    function getCheckmarkBackgroundStyle(indentation: number, showColor: boolean, isCollapsable: boolean, isParent: boolean): Object {
        let backgroundPositionX = 3;

        if (isParent) {
            indentation++;
        }

        if (showColor) {
            backgroundPositionX += 13;

            if (!isParent) {
                indentation++;
            }
        }

        if (indentation > 1) {
            backgroundPositionX += (indentation - 1) * 20;
        }

        if (isCollapsable) {
            backgroundPositionX += 20;
        }

        return {
            'background-position-x': backgroundPositionX + 'px'
        };
    }

    class NodeDefinition {
        public Item: TreeItem = null;
        public Classes: Dictionary<boolean> = {};
        public ShowParentTitle: boolean = false;
        public HighlightWhenActive: boolean = false;
        public Styles: Object = {};
        public Indentation: number = 0;
        public ShowColor: boolean = false;
        public IsCollapsable: boolean = true;
        public IsSelected: boolean = false;
        public IsRoot: boolean = false;
        public IsCollapsed: boolean = false;
        public IsDisabled: boolean = false;
        public Height: number = 0;
        public AdditionalClasses: Array<string> = [];
        public AdditionalText: string = null;
        public Href: string = null;
        public OffsetTop: number = 0;
        public DOMClasses: string;
        public DOMStyles: string;
        public HighlightedTitle: string;
        public IsActive: boolean;
        public Counter: number;

        constructor(pass?: Object) {
            if (pass) {
                const keys = Object.keys(pass);

                for (let i = 0; i < keys.length; i++) {
                    const curKey = keys[i];
                    this[curKey] = pass[curKey];
                }
            }
        }
    }

    class Node {
        public Identifier: string;
        public Definition: NodeDefinition;
        public $Node: any;

        constructor(definition) {
            if (!definition) {
                throw new Model.Errors.ArgumentNullError('definition missing');
            }

            this.Identifier = definition.Item.Identifier;
            this.Definition = definition;
        }

        _prepareDefinition(definition: NodeDefinition): NodeDefinition {
            if (!definition) {
                return;
            }

            const DOMClasses = $.extend({}, definition.Classes);

            if ($.isArray(definition.AdditionalClasses)) {
                $.each(definition.AdditionalClasses, function(_idx: number, val: string) {
                    DOMClasses[val] = true;
                });
            }
            if (definition.ShowParentTitle) {
                DOMClasses['parent-title'] = true;
            }
            if (definition.AdditionalText) {
                DOMClasses['extra-text'] = true;
            }
            if (definition.ShowColor) {
                DOMClasses.colored = true;
            }
            if (definition.Indentation) {
                definition.Styles['padding-left'] = definition.Indentation * 20 + 'px';
            } else {
                delete definition.Styles['padding-left'];
            }

            const DOMStyles = $.map(definition.Styles, function(val: string, key: string) {
                return key + ':' + val;
            });

            definition.DOMClasses = Object.keys(DOMClasses).join(' ');
            definition.DOMStyles = DOMStyles.join(';');

            return definition;
        }

        SetupItem($item: any, offsetTop: number) {
            this.Definition = this._prepareDefinition(this.Definition);
            this.Definition.OffsetTop = offsetTop === null ? this.Definition.OffsetTop : offsetTop || 0;

            $item = $item instanceof $ ? $item : $($item);
            $item.attr('class', 'item ' + this.Definition.DOMClasses)
                .attr('title', this.Definition.Item.Title)
                .attr('style', 'top: ' + this.Definition.OffsetTop + 'px' + (this.Definition.Height ? ';height: ' + this.Definition.Height + 'px' : ''))
                .data('identifier', this.Definition.Item.Identifier || '')
                .attr('data-identifier', this.Definition.Item.Identifier || '')
                .data('href', this.Definition.Href)
                .attr('data-href', this.Definition.Href);
            $item.find('.item-content')
                .attr('style', this.Definition.DOMStyles);
            $item.find('.title .main')
                .html((this.Definition.HighlightedTitle || Utils.EscapeHTMLEntities(this.Definition.Item.DisplayTitle || '')).toString());

            if (this.Definition.ShowParentTitle) {
                $item.find('.parent-name')
                    .text('@ ' + this.Definition.Item.Parent.DisplayTitle);
            }

            if (this.Definition.ShowColor) {
                $item.find('.color')
                    .attr('style', 'background-color:' + this.Definition.Item.Color);
            }

            if (this.Definition.AdditionalText) {
                $item.find('.additional-text')
                    .html(this.Definition.AdditionalText);
            }

            if (this.Definition.Counter !== null && !isNaN(this.Definition.Counter)) {
                $item.find('.counter')
                    .text(this.Definition.Counter);
            } else {
                $item.find('.counter').text('');
            }

            return $item;
        }

        GetMarkup(offsetTop: number): string {
            this.Definition.OffsetTop = offsetTop;
            return Templates.Tree.Item(this._prepareDefinition.call(this, this.Definition));
        }

        SetIsActive(isActive: boolean): Node {
            this.Definition.IsActive = !!isActive;

            if (!this.Definition.HighlightWhenActive) {
                delete this.Definition.Classes.active;
                return this;
            }

            const hasActiveClass = this.Definition.Classes.active;

            if (!this.Definition.IsActive && hasActiveClass) {
                delete this.Definition.Classes.active;
            } else if (this.Definition.IsActive) {
                this.Definition.Classes.active = true;
            }

            return this;
        }

        SetIsSelected(isSelected: boolean): Node {
            this.Definition.IsSelected = !!isSelected;

            const hasSelectedClass = this.Definition.Classes.selected;

            if (!isSelected && hasSelectedClass) {
                delete this.Definition.Classes.selected;

                if (this.Definition.Styles) {
                    delete this.Definition.Styles['background-position-x'];
                }
            } else if (!!isSelected && !hasSelectedClass) {
                const checkMarkStyle = getCheckmarkBackgroundStyle(
                    this.Definition.Indentation,
                    this.Definition.ShowColor,
                    this.Definition.IsCollapsable,
                    this.Definition.Classes.parent);

                this.Definition.Classes.selected = true;

                $.extend(this.Definition.Styles, checkMarkStyle);
            }

            return this;
        }

        GetIsSelected(): boolean {
            return !!this.Definition.IsSelected;
        }

        SetIsCollapsed(isCollapsed: boolean): Node {
            this.Definition.IsCollapsed = !!isCollapsed;

            if (!(this.Definition.Item.Children || []).length) {
                return this;
            }

            const hasCollapsedClass = this.Definition.Classes.collapsed;

            if (!isCollapsed && hasCollapsedClass) {
                delete this.Definition.Classes.collapsed;
            } else if (!!isCollapsed && !hasCollapsedClass) {
                this.Definition.Classes.collapsed = true;
            }

            return this;
        }

        GetIsCollapsed(): boolean {
            return !!this.Definition.IsCollapsed;
        }

        ToggleCollapsed() {
            this.SetIsCollapsed(!this.Definition.IsCollapsed);
            return this.GetIsCollapsed();
        }

        SetCounter(counter: number) {
            this.Definition.Counter = counter;

            return this;
        }

        GetCounter() {
            return this.Definition.Counter;
        }

        UnsetCounter() {
            delete this.Definition.Counter;

            return this;
        }

        SetHighlightedTitle(searchText?: string): Node {
            if (!searchText) {
                delete this.Definition.HighlightedTitle;
                return this;
            }

            const regEx = new RegExp(Utils.EscapeRegExPattern(searchText), 'ig');
            const title = Utils.EscapeHTMLEntities((this.Definition.Item.DisplayTitle || '').toString());

            this.Definition.HighlightedTitle = title.replace(regEx, function(match) {
                return `<span class="matched">${match}</span>`;
            });

            return this;
        }

        AssignDOMNode($node): Node {
            this.$Node = ($node instanceof $) ? $node : null;

            return this;
        }

        GetDOMNode() {
            return this.$Node;
        }

        SetAdditionalText(text: string): Node {
            this.Definition.AdditionalText = text;

            return this;
        }

        GetAdditionalText(): string {
            return this.Definition.AdditionalText;
        }

        SetAdditionalClasses(classes: Array<string>): Node {
            this.Definition.AdditionalClasses = classes;

            return this;
        }

        UpdateRoute(routeBase: string, anchorSuffix: string): Node {
            this.Definition.Href = '{0}/{1}{2}'.format(
                routeBase || '',
                this.Definition.Item.Identifier,
                anchorSuffix || '');

            return this;
        }

        UpdateRouteByPattern(pattern: string): Node {
            if (!pattern) {
                return this;
            }

            let match: RegExpExecArray;

            while ((match = /\{\w+\}/g.exec(pattern))) {
                let attr = match[0].replace(/[\{\}]/g, '');
                let replaceValue = !!attr && this.Definition.Item.hasOwnProperty(attr) ?
                    this.Definition.Item[attr] || '' :
                    '';

                pattern = pattern.replace(match[0], replaceValue);
            }

            this.Definition.Href = pattern;

            return this;
        }

        Rerender(): Node {
            const $currentDOMNode = this.GetDOMNode();

            if ($currentDOMNode instanceof $) {
                this.SetupItem($currentDOMNode, this.Definition.OffsetTop);
            }

            return this;
        }
    }

    class ResourceManager {
        private items: Array<any> = [];

        public getItem(): any {
            return this.items.pop() || $(Templates.Tree.Item());
        }

        public recycleItems(items: any | HTMLElement | Array<any>): void {
            if (items instanceof $) {
                this.items = this.items.concat(items.toArray());
            } else if (items instanceof HTMLElement) {
                this.items.push(items);
            } else if (items) {
                this.items = this.items.concat($(items).toArray());
            } else {
                return;
            }

            $(items).remove();
            this._removeDoubles();
        }

        private _removeDoubles(): void {
            this.items = this.items.filter(function(elem: any, index: number, self: any[]) {
                return index === self.indexOf(elem);
            });
        }
    }
}
