//imports-start
/// <reference path="../model/calendar/day-result.ts"  />
/// <reference path="../model/calendar/day-view-item.ts"  />
/// <reference path="../model/calendar/month-view-item.ts"  />
/// <reference path="../model/calendar/request.ts" />
/// <reference path="../model/calendar/counter-response.ts" />
//imports-end

module Calendar {
    let _$content: any;
    let _selectedDate: Date;

    let _$viewSelection: any;
    let _$calendar: any;

    let _selectedView: string;

    let _issues: Array<Model.Issues.Issue>;
    let _counters: Dictionary<number>;

    let _$btnPreviousPeriod, _$btnNextPeriod;

    let _swipeHandlerAttached: Boolean = false;
    let _hammerManager;

    export function Show(calendarMode?: Enums.Calendar.ViewType): void {
        _swipeHandlerAttached = false;

        if (!!calendarMode) {
            _selectedView = calendarMode;
        }

        if (Session.Mode !== Enums.Mode.Calendar) {
            if (!calendarMode) {
                _selectedView = 'month';
            }

            _selectedDate = getDefaultDate();
        }

        Utils.SetMode(Enums.Mode.Calendar);

        _$content = Utils.GetContentContainer();

        View.SetRefreshMethod(RefreshView);
        loadAndRenderCalendarData();
    }

    function getDefaultDate() {
        const now = new Date();

        return new Date(
            now.getFullYear(), now.getMonth(), now.getDate(),
            0, 0, 0
        );
    }

    function loadAndRenderCalendarData(): void {
        setTimeout(() => {
            if (Session.IsSmartDeviceApplication) {
                loadDataFromDatabase()
                    .then((response: Model.Issues.InternalResponse) => {
                        _counters = null;
                        _issues = null;

                        if (_selectedView === Enums.Calendar.ViewType.Month) {
                            transformInternalResponseToCounters(response);
                        } else {
                            _issues = (response.Issues || []).map(rawIssue => new Model.Issues.Issue(rawIssue));
                            sortIssuesByDeadline(_issues);
                        }
                    })
                    .then(renderContent);
            } else {
                loadIssuesFromServer()
                    .then(onGotServerResponse)
                    .then(renderContent)
                    .fail(function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    });
            }
        }, 10);
    }

    export function UpdateIssueInDayAndWeekView(issue: Model.Issues.Issue): void {
        if (_selectedView === Enums.Calendar.ViewType.Month) {
            return;
        }

        const $issue = _$calendar.find(`.issue[data-identifier="${issue.Identifier}"]`);

        if ($issue && $issue.length) {
            $issue.remove();
        }

        // vorige Vorgangrevision aus _issues entfernen
        const prevIssue = _issues.filter(i => i.OID === issue.PrecedingOID);
        if (prevIssue && prevIssue.length) {
            const idx = _issues.indexOf(prevIssue[0]);
            if (idx >= 0) {
                _issues.splice(idx, 1);
            }
        }

        if (!issue.DeadlineTimestamp) {
            return;
        }

        // neue Vorgangsrevision zur Liste hinzufügen
        _issues.push(issue);
        sortIssuesByDeadline(_issues);

        const deadlineTimestamp = issue.DeadlineTimestamp.getTime();

        if (_selectedView === Enums.Calendar.ViewType.Day) {
            const today = new Date(_selectedDate.getTime()).setHours(0, 0, 0, 0);
            const tomorrow = today + 24 * 60 * 60 * 1000;

            if (deadlineTimestamp < today ||
                deadlineTimestamp >= tomorrow) {
                // neuer Termin liegt ausserhalb des aktuellen Tages
                return;
            }
        }

        const deadlineHour = new Date(deadlineTimestamp).setMinutes(0, 0, 0);
        const $parent = _$calendar.find(`.hour[data-timestamp="${deadlineHour}"]`);

        if (!$parent.length) {
            return;
        }

        $parent.find('ul.issues').append(
            Templates.Calendar.DayViewIssueItem(issue)
        );

        $parent.find('ul.issues').html(
            $parent.find('.issue').sort(sortIssueItemsByTimestamp)
        );
    }

    export function RefreshView(): void {
        Show();
    }

    function sortIssueItemsByTimestamp(a: HTMLElement, b: HTMLElement): number {
        return parseInt($(a).data('timestamp'), 10) - parseInt($(b).data('timestamp'), 10);
    }

    function loadDataFromDatabase(): any {
        const deferred = $.Deferred();
        const filter =
            new Model.Issues.Filter()
                .SetTypes(getAvailableIssueTypes())
                .SetLocationOID(Session.CurrentLocation.OID)
                .SetWithOpenIssues(true)
                .SetWithArchivedIssues(false)
                .SetWithChildLocations(true)
                .SetLoadIssues(true)
                .SetTake(Number.POSITIVE_INFINITY);

        let periodStart: Date;
        let periodEnd: Date;

        switch (_selectedView) {
            case Enums.Calendar.ViewType.Month:
                periodStart = new Date(
                    _selectedDate.getFullYear(), _selectedDate.getMonth(), 1,
                    0, 0, 0, 0);
                periodEnd = new Date(
                    _selectedDate.getFullYear(), _selectedDate.getMonth() + 1, 0,
                    23, 59, 59, 999);
                break;
            case Enums.Calendar.ViewType.Day:
                periodStart = new Date(
                    _selectedDate.getFullYear(), _selectedDate.getMonth(), _selectedDate.getDate(),
                    0, 0, 0, 0);
                periodEnd = new Date(
                    _selectedDate.getFullYear(), _selectedDate.getMonth(), _selectedDate.getDate(),
                    23, 59, 59, 999);
                break;
            case Enums.Calendar.ViewType.Week:
                const period = createWeekPeriod();

                periodStart = period.Start;
                periodEnd = period.End;

                break;
        }

        filter.SetDeadlinePeriod(periodStart, periodEnd);

        DAL.Issues.GetByFilters(filter, false)
            .then(deferred.resolve);

        return deferred.promise();
    }

    function getAvailableIssueTypes(): Array<number> {
        const issueTypes: Array<number> = [];

        if (Utils.IsMenuItemEnabled(Enums.MenuItemID.TaskReport)) {
            issueTypes.push(Enums.IssueType.Task);
            issueTypes.push(Enums.IssueType.Scheduling);
            issueTypes.push(Enums.IssueType.Form);

            if (Session.Client.Licenses.Inspections) {
                issueTypes.push(Enums.IssueType.Inspection);
            }
        }

        return issueTypes;
    }

    function transformInternalResponseToCounters(response: Model.Issues.InternalResponse): void {
        const counters: Dictionary<number> = {};

        (response.Issues || []).forEach(issue => {
            const deadline = new Date(issue.DeadlineTimestamp);

            if (isNaN(deadline.getTime())) {
                return;
            }

            const day = deadline.getDate();

            if (!counters.hasOwnProperty(day)) {
                counters[day] = 0;
            }

            counters[day] += 1;
        });

        _counters = counters;
    }

    function loadIssuesFromServer(): any {
        if (Session.IsSmartDeviceApplication) {
            return;
        }

        const request =
            new Model.Calendar.Request()
                .SetIgnoreSchedules(true)
                .SetLoadCounters(_selectedView === Enums.Calendar.ViewType.Month)
                .SetLocationOID(Session.CurrentLocation.OID)
                .SetSelectedDate(new Date(_selectedDate.setHours(0, 0, 0, 0)));

        if (_selectedView === Enums.Calendar.ViewType.Week) {
            const period = createWeekPeriod();

            request.SetDeadlinePeriod(period.Start, period.End);
        }

        return Utils.Http.Post('calendar', request);
    }

    function createWeekPeriod(): { Start: Date, End: Date } {
        const startOfWeek = parseInt(i18next.t('DateTime.FirstDayOfWeek'), 10);
        const dayOfWeek = _selectedDate.getDay();

        let periodStart: Date;
        let periodEnd: Date;

        if (startOfWeek === 0) {
            periodStart = new Date(
                _selectedDate.getFullYear(), _selectedDate.getMonth(), _selectedDate.getDate() - dayOfWeek,
                0, 0, 0, 0
            );

            periodEnd = new Date(
                _selectedDate.getFullYear(), _selectedDate.getMonth(), _selectedDate.getDate() + (6 - dayOfWeek),
                23, 59, 59, 999
            );
        } else {
            periodStart = new Date(
                _selectedDate.getFullYear(), _selectedDate.getMonth(), _selectedDate.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1),
                0, 0, 0, 0
            );

            periodEnd = new Date(
                _selectedDate.getFullYear(), _selectedDate.getMonth(), _selectedDate.getDate() + (dayOfWeek === 0 ? 0 : 7 - dayOfWeek),
                23, 59, 59, 999
            );
        }

        return { Start: periodStart, End: periodEnd };
    }

    function onGotServerResponse(rawResponse: any | Array<Model.Calendar.CounterResponse>): void {
        if (_selectedView !== Enums.Calendar.ViewType.Month) {
            _issues = [];
            _counters = null;

            if (!rawResponse || !(rawResponse.Issues instanceof Array)) {
                return;
            }

            const rawIssues = (rawResponse.Issues as Array<Model.Issues.RawIssue>);

            for (const issueIterator of rawIssues) {
                _issues.push(new Model.Issues.Issue(issueIterator));
            }

            sortIssuesByDeadline(_issues);
        } else {
            _counters = {};
            _issues = null;

            if (!(rawResponse instanceof Array)) {
                return;
            }

            for (const counter of rawResponse) {
                _counters[counter.CalendarDate] = counter.Counters[0].Counter;
            }
        }
    }

    function renderContent(): void {
        _$content.html(Templates.Calendar.Calendar({
            SelectedView: _selectedView
        }));

        _$btnPreviousPeriod = _$content.find('.btn-previous-period');
        _$btnNextPeriod = _$content.find('.btn-next-period');
        _$viewSelection = _$content.find('.view-selection');
        _$calendar = _$content.find('.calendar');

        renderCalendarView();
        bindEvents();

        Utils.Spinner.Hide();
    }

    function onWeekTableCellClick(): void {
        const date = new Date($(this).data('timestamp'));

        if (isNaN(date.getTime())) {
            return;
        }

        _selectedView = Enums.Calendar.ViewType.Day;
        _selectedDate = date;

        loadAndRenderCalendarData();
    }

    function onMonthTableCellClick(): void {
        const date = new Date($(this).data('timestamp'));

        if (isNaN(date.getTime())) {
            return;
        }

        _selectedView = Enums.Calendar.ViewType.Day;
        _selectedDate = date;

        loadAndRenderCalendarData();
    }

    function onCreateIssueFromClick(evt: Event): void {
        const userCanCreateTasks = Utils.CanUserCreateIssueType(Enums.IssueType.Task, Session.CurrentLocation);

        if (!userCanCreateTasks) {
            return;
        }

        const $this = $(evt.currentTarget);
        const date = new Date($this.data('timestamp'));

        if (isNaN(date.getTime())) {
            return;
        }

        Utils.IssueViewer.CreateIssue(Enums.IssueType.Task, function(issue: Model.Issues.Issue) {
            if (!issue.DeadlineTimestamp) {
                // Vorgänge ohne Deadline werden nicht im Kalender dargestellt
                return;
            }

            _issues.push(issue);

            sortIssuesByDeadline(_issues);
            renderCalendarView();
        }, { Deadline: date });
    }

    function bindEvents(): void {
        _$btnPreviousPeriod.on('click', loadAndRenderPreviousPeriod);
        _$btnNextPeriod.on('click', loadAndRenderNextPeriod);
        _$viewSelection.on('click', '.item', onViewSelectionItemClick);

        _$calendar
            .off('click.preventBubbling')
            .off('click.goToDay')
            .off('click.createNewIssue');

        switch (_selectedView) {
            case Enums.Calendar.ViewType.Month:
                _$calendar
                    .on('click.preventBubbling', '.issue a', (evt: Event) => evt.stopPropagation())
                    .on('click.goToDay', 'td[data-timestamp]', onMonthTableCellClick);
                break;
            case Enums.Calendar.ViewType.Week:
                _$calendar
                    .on('click.preventBubbling', '.issue a', (evt: Event) => evt.stopPropagation())
                    .on('click.createNewIssue', '.hour', onCreateIssueFromClick)
                    .on('click.goToDay', 'th[data-timestamp]', onWeekTableCellClick);
                break;
            case Enums.Calendar.ViewType.Day:
                _$calendar
                    .on('click.preventBubbling', '.issue a', (evt: Event) => evt.stopPropagation())
                    .on('click.createNewIssue', '.hour', onCreateIssueFromClick);
                break;
        }

        initSwipeHandler();
    }

    function onViewSelectionItemClick(): void {
        const $this = $(this);

        _selectedView = $this.data('view');

        $this
            .addClass('active')
            .siblings('.active')
            .removeClass('active');

        loadAndRenderCalendarData();
    }

    function loadAndRenderPreviousPeriod(): void {
        switch (_selectedView) {
            case Enums.Calendar.ViewType.Day:
                _selectedDate = new Date(
                    _selectedDate.getFullYear(), _selectedDate.getMonth(), _selectedDate.getDate() - 1,
                    0, 0, 0
                );
                break;
            case Enums.Calendar.ViewType.Week:
                _selectedDate = new Date(
                    _selectedDate.getFullYear(), _selectedDate.getMonth(), _selectedDate.getDate() - 7,
                    0, 0, 0
                );
                break;
            case Enums.Calendar.ViewType.Month:
                _selectedDate.setDate(1);

                _selectedDate = new Date(
                    _selectedDate.getFullYear(), _selectedDate.getMonth() - 1, _selectedDate.getDate(),
                    0, 0, 0
                );
                break;
        }

        loadAndRenderCalendarData();
    }

    function loadAndRenderNextPeriod(): void {
        switch (_selectedView) {
            case Enums.Calendar.ViewType.Day:
                _selectedDate = new Date(
                    _selectedDate.getFullYear(), _selectedDate.getMonth(), _selectedDate.getDate() + 1,
                    0, 0, 0
                );
                break;
            case Enums.Calendar.ViewType.Week:
                _selectedDate = new Date(
                    _selectedDate.getFullYear(), _selectedDate.getMonth(), _selectedDate.getDate() + 7,
                    0, 0, 0
                );
                break;
            case Enums.Calendar.ViewType.Month:
                _selectedDate.setDate(1);

                _selectedDate = new Date(
                    _selectedDate.getFullYear(), _selectedDate.getMonth() + 1, _selectedDate.getDate(),
                    0, 0, 0
                );
                break;
        }

        loadAndRenderCalendarData();
    }

    function initSwipeHandler(): void {
        if (_swipeHandlerAttached) {
            return;
        }

        createHammerManager();

        const swipe = new Hammer.Swipe({
            direction: Hammer.DIRECTION_HORIZONTAL,
            threshold: 1,
            velocity: 0.4
        });

        _hammerManager.off('swipe');
        _hammerManager.remove('swipe');

        _hammerManager.add(swipe);
        _hammerManager.on('swipe', onHandleSwipeEvent);

        _swipeHandlerAttached = true;
    }

    function createHammerManager() {
        if (_hammerManager) {
            return;
        }

        const contentElement = _$content.get(0);

        _hammerManager = new Hammer.Manager(contentElement, {
            touchAction: 'auto',
            recognizers: [
                [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
            ]
        });
    }

    function onHandleSwipeEvent(event): void {
        if (Session.Mode !== Enums.Mode.Calendar) {
            return;
        }

        if (event.direction === Hammer.DIRECTION_RIGHT) {
            loadAndRenderPreviousPeriod();
        } else if (event.direction === Hammer.DIRECTION_LEFT) {
            loadAndRenderNextPeriod();
        }
    }

    function renderCalendarView(): void {
        _$calendar.removeClass('day, week, month');

        switch (_selectedView) {
            case Enums.Calendar.ViewType.Day:
                createDayCalendar();
                break;
            case Enums.Calendar.ViewType.Week:
                createWeekCalendar();
                break;
            case Enums.Calendar.ViewType.Month:
                createMonthCalendar();
                break;
        }
    }

    function createDayCalendar(): void {
        _$content.find('.date-range').html(_selectedDate.format(i18next.t('DateTime.DateFormat')));
        _$calendar.addClass('day');
        _$calendar.html(Templates.Calendar.DayView(createDayViewContext()));

        const currentHour = new Date().getHours();
        const now = new Date(_selectedDate.getTime()).setHours(currentHour, 0, 0, 0);
        const $currentTimeElement = _$content.find(`div[data-timestamp="${now}"]`);

        if ($currentTimeElement.length) {
            const contentHeaderHeight = $('.content-header').outerHeight();
            const contentPaddingTop = parseInt(_$content.parent().css('padding-top'), 10);
            const posTop = parseInt(_$calendar.css('top'), 10);
            const borderTopWidth = parseInt($currentTimeElement.css('border-top-width'), 10);

            _$calendar.animate({
                scrollTop: $currentTimeElement.offset().top - contentHeaderHeight - contentPaddingTop - posTop - borderTopWidth
            });
        }
    }

    function createDayViewContext(): { Timestamps: Array<Model.Calendar.DayViewItem>, UserCanCreateTasks: boolean } {
        const now = new Date().getTime();

        let lowerBoundary = new Date(_selectedDate.getTime());
        let upperBoundary = new Date(_selectedDate.getTime());
        const timestamps: Array<Model.Calendar.DayViewItem> = [];

        for (let hr = 0, max = 24; hr < max; hr++) {
            lowerBoundary.setHours(hr, 0, 0, 0);
            upperBoundary.setHours(hr, 59, 59, 999);

            const issues = getIssuesWithDeadlineInHour(lowerBoundary);

            const calendarTimestamp =
                new Model.Calendar.DayViewItem()
                    .SetTimestamp(lowerBoundary.getTime())
                    .SetDisplayValue(Utils.DateTime.TimeToString(lowerBoundary))
                    .SetGreyed(now > upperBoundary.getTime())
                    .SetIssues(issues);

            timestamps.push(calendarTimestamp);
        }

        const userCanCreateTasks = Utils.CanUserCreateIssueType(Enums.IssueType.Task, Session.CurrentLocation);

        return { Timestamps: timestamps, UserCanCreateTasks: userCanCreateTasks };
    }

    function getIssuesWithDeadlineInHour(timestamp: Date): Array<Model.Issues.Issue> {
        const lowerBoundary = new Date(
            timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate(),
            timestamp.getHours(), 0, 0, 0
        );
        const upperBoundary = new Date(
            timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate(),
            timestamp.getHours(), 59, 59, 999
        );

        return (_issues || [])
            .filter(issue =>
                issue.DeadlineTimestamp.getTime() >= lowerBoundary.getTime() &&
                issue.DeadlineTimestamp.getTime() <= upperBoundary.getTime()
            );
    }

    function createWeekCalendar(): void {
        const monthName = i18next.t('DateTime.MonthLong')[_selectedDate.getMonth()];
        const weekNoAndMonth = moment(_selectedDate).format('<b>WW</b>/YYYY');
        const header = `${i18next.t('DateTime.CW')} ${weekNoAndMonth} (${monthName})`;

        _$content.find('.date-range').html(header);
        _$calendar.addClass('week');
        _$calendar.html(Templates.Calendar.WeekView(createWeekViewContext()));
    }

    function createWeekViewContext(): { Headlines: Array<any>, Hours: Array<any>, UserCanCreateTasks: boolean } {
        const period = createWeekPeriod();
        const context = { Headlines: [], Hours: [], UserCanCreateTasks: false };

        context.UserCanCreateTasks = Utils.CanUserCreateIssueType(Enums.IssueType.Task, Session.CurrentLocation);

        const now = new Date();

        let day = new Date(period.Start.getTime());

        do {
            const dayNo = day.getDay();

            context.Headlines.push({
                IsToday: day.getFullYear() === now.getFullYear() &&
                    day.getMonth() === now.getMonth() &&
                    day.getDate() === now.getDate(),
                IsSunday: day.getDay() === 0,
                DayOfWeekAbbr: i18next.t('DateTime.DaysShort')[dayNo > 0 ? dayNo - 1 : 6],
                DayOfMonth: day.getDate(),
                DayTimestamp: day.getTime()
            });

            day = new Date(
                day.getFullYear(), day.getMonth(), day.getDate() + 1,
                0, 0, 0, 0
            );
        } while (day.getTime() <= period.End.getTime());

        const timestamp = new Date(period.Start.getTime());

        for (let hr = 0, max = 24; hr < max; hr++) {
            timestamp.setHours(hr, 0, 0, 0);

            const hourContext = {
                Timestamp: timestamp.getTime(),
                FormattedTime: Utils.PadLeft(timestamp.getHours(), 2, '0'),
                Days: []
            };

            day = new Date(
                period.Start.getFullYear(), period.Start.getMonth(), period.Start.getDate(),
                hr, 0, 0, 0
            );

            do {
                const issues = getIssuesWithDeadlineInHour(day);

                hourContext.Days.push({
                    Timestamp: new Date(day.getTime()).setHours(hr, 0, 0, 0),
                    Issues: issues
                });

                day = new Date(
                    day.getFullYear(), day.getMonth(), day.getDate() + 1,
                    hr, 0, 0, 0
                );
            } while (day.getTime() <= period.End.getTime());

            context.Hours.push(hourContext);
        }

        return context;
    }

    function createMonthCalendar(): void {
        const headers = { short: [], long: [] };
        const now = new Date();

        headers.short = i18next.t('DateTime.DaysShort').map((str) => {
            return {
                name: str
            };
        });

        headers.long = i18next.t('DateTime.DaysLong').map((str) => {
            return {
                name: str
            };
        });

        const rows = [];

        const firstDayOfWeek = parseInt(i18next.t('DateTime.FirstDayOfWeek'), 10);
        const lastDayOfMonth = getLastDayOfMonth();
        let currentDate = getFirstDayOfMonth();

        let day: number;
        let row: Array<Model.Calendar.MonthViewItem> = [];

        if (currentDate.getDay() !== firstDayOfWeek) {
            const tmpDate = new Date(currentDate.getTime());
            tmpDate.setDate(tmpDate.getDate() - ((!currentDate.getDay() ? 7 : currentDate.getDay()) - 1));

            if (!firstDayOfWeek) {
                tmpDate.setDate(tmpDate.getDate() - 1);
            }

            let end = !currentDate.getDay() ? 7 : currentDate.getDay();
            end -= firstDayOfWeek ? 1 : 0;

            for (let day = 0; day < end; day++) {
                const item = new Model.Calendar.MonthViewItem()
                    .SetClasses(tmpDate.getDate() === 0 ? 'faded sunday' : 'faded')
                    .SetTimestamp(tmpDate.getTime())
                    .SetDayOfMonth(tmpDate.getDate());

                row.push(item);

                tmpDate.setDate(tmpDate.getDate() + 1);
            }
        }

        while (currentDate.getDate() <= lastDayOfMonth.getDate() &&
            currentDate.getMonth() === _selectedDate.getMonth()) {
            while ((day = currentDate.getDay()) <= 6) {
                const cellClasses = [];

                if (currentDate.getMonth() !== _selectedDate.getMonth()) {
                    cellClasses.push('faded');
                }

                if (datesAreEqual(currentDate, now) &&
                    !Utils.InArray(cellClasses, 'faded')) {
                    cellClasses.push('selected');
                }

                if (!day) {
                    cellClasses.push('sunday');
                }

                const item = new Model.Calendar.MonthViewItem()
                    .SetClasses(cellClasses.join(' '))
                    .SetTimestamp(currentDate.getTime())
                    .SetDayOfMonth(currentDate.getDate());

                if (currentDate.getMonth() === _selectedDate.getMonth()) {
                    const issueCounter = (_counters || {}).hasOwnProperty(currentDate.getDate())
                        ? _counters[currentDate.getDate()]
                        : null;

                    item.SetIssueCounter(issueCounter);
                }

                row.push(item);

                currentDate.setDate(currentDate.getDate() + 1);

                if (!firstDayOfWeek && day === 6 ||
                    firstDayOfWeek && !day) {
                    break;
                }
            }

            rows.push(row);
            row = [];
        }

        _$content.find('.date-range').html(moment(_selectedDate).format('<b>MMMM</b> YYYY'));
        _$calendar.addClass('month');
        _$calendar.html(Templates.Calendar.MonthView({
            Headers: headers,
            Rows: rows
        }));
    }

    function datesAreEqual(date1: Date, date2: Date): boolean {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    }

    function getFirstDayOfMonth() {
        return new Date(
            _selectedDate.getFullYear(), _selectedDate.getMonth(), 1,
            0, 0, 0
        );
    }

    function getLastDayOfMonth() {
        return new Date(
            _selectedDate.getFullYear(), _selectedDate.getMonth() + 1, 0,
            0, 0, 0
        );
    }

    function sortIssuesByDeadline(issues: Model.Issues.Issue[]): void {
        issues.sort((i1: Model.Issues.Issue, i2: Model.Issues.Issue) => {
            if (!(i1.DeadlineTimestamp instanceof Date) || !(i2.DeadlineTimestamp instanceof Date)) {
                return NaN;
            }

            if (i1.DeadlineTimestamp === i2.DeadlineTimestamp) {
                return 0;
            }

            return i2.DeadlineTimestamp > i1.DeadlineTimestamp ? -1 : 1;
        });
    }
}
