//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../templates.d.ts"  />
/// <reference path="./app.session.ts"  />
/// <reference path="./scanners/ZebraDataWedge.ts"  />
/// <reference path="./scanners/Newland.ts"  />
//imports-end

module CodeScanner {
    let usedScanner = null;
    let initialized = false;

    export function Init(force: boolean = false) {
        if (usedScanner == null) {
            initialized = true;

            if (Scanner.ZebraDataWedge.IsSupported()) {
                usedScanner = Scanner.ZebraDataWedge;
            } else if (Scanner.Newland.IsSupported()) {
                usedScanner = Scanner.Newland;
            } else if (Scanner.Speedata.IsSupported()) {
                usedScanner = Scanner.Speedata;
            } else {
                return;
            }
        }

        usedScanner.Init(force);
    }

    export function Show() {
        if (!initialized) {
            Init();
        }

        if (!usedScanner) {
            return;
        }

        Utils.SetMode(Enums.Mode.CodeScanner);
        View.SetView(Enums.View.CodeScanner);

        usedScanner.Show();
    }

    export function Hide() {
        if (!initialized) {
            Init();
        }

        if (!usedScanner) {
            return;
        }

        usedScanner.Hide();
    }

    export function IsSupported(locationOID?: string): boolean {
        if (!initialized) {
            Init();
        }

        if (!usedScanner) {
            return false;
        }

        return usedScanner.IsSupported(locationOID);
    }

    export function AddToCallbackStack(callback: (type: string, text: string) => void, keepUntilRemove: boolean = false, id?: string) {
        if (!initialized) {
            Init();
        }

        if (!usedScanner) {
            return;
        }

        usedScanner.AddToCallbackStack(callback, keepUntilRemove, id);
    }

    export function RemoveFromCallbackStack(id: string) {
        if (!initialized) {
            Init();
        }

        if (!usedScanner) {
            return;
        }

        usedScanner.RemoveFromCallbackStack(id);
    }

    export function ClearCallbackStack() {
        if (!initialized) {
            Init();
        }

        if (!usedScanner) {
            return;
        }

        usedScanner.ClearCallbackStack();
    }

    export function GetName(): string {
        if (!initialized) {
            Init();
        }

        if (!initialized) {
            return;
        }

        return usedScanner.GetName();
    }

    //DEBUG ONLY
    export function SimulateScan(type: string, code: string) {
        if (!initialized) {
            Init();
        }

        if (!usedScanner) {
            return;
        }

        usedScanner.SimulateScan(type, code);
    }
}
