//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../enums.ts"  />
/// <reference path="./requirement.ts"  />
/// <reference path="../model.recorditem.ts"  />
/// <reference path="../properties/property.ts"  />
/// <reference path="../files/file.ts"  />
/// <reference path="./evaluation.ts"  />
//imports-end

module Model.Elements {
    export class Address {
        Street?: string;
        StreetNumber?: string;
        ZipCode?: string;
        City?: string;
        StateNumber?: string;
        Name?: string;
        Phone?: string;
        WebAddress?: string;
        Latitude?: number;
        Longitude?: number;
    }
}