//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../model/issues/issue.ts"  />
//imports-end

module Issues {
    export class FullscreenIssueViewer {
        private readonly $content: any;
        private $subViewToggle: any;
        private $commentInput: any;
        private $submitComment: any;

        constructor(private readonly issue: Model.Issues.Issue) {
            this.$content = Utils.GetContentContainer();
            this.prepareComments();
        }

        load(): Deferred {
            const deferred = $.Deferred();

            if (Session.IsSmartDeviceApplication) {
                DAL.Issues.GetChildIssues(this.issue.ID)
                    .then((issues: Model.Issues.Issue[]) => {
                        if (!(issues || []).length) {
                            deferred.resolve();
                            return;
                        }

                        this.issue.Descendants = issues;

                        deferred.resolve();
                    })
                    .fail(deferred.reject);
            } else {
                deferred.resolve();
            }

            return deferred.promise();
        }

        render(): void {
            this.$content.html(Templates.FullscreenIssueViewer.Content({
                Issue: this.issue,
                Headers: {
                    Issues: i18next.t('FullscreenIssueViewer.Tabs.Issues.Caption', { count: (this.issue.Descendants || []).length }),
                    Files: i18next.t('FullscreenIssueViewer.Tabs.Files.Caption', { count: (this.issue.Files || []).length }),
                    Comments: i18next.t('FullscreenIssueViewer.Tabs.Comments.Caption', { count: (this.issue.Comments || []).length })
                }
            }));

            if (this.issue.Description) {
                // Markdown parsen und gerenderter Content in die View einsetzen
                toastui.Editor.factory({
                    el: this.$content.find('.issue-description').get(0),
                    initialValue: this.issue.Description,
                    viewer: true,
                    customHTMLSanitizer: DOMPurify.sanitize
                });
            }

            this.$subViewToggle = this.$content.find('.sub-view-toggle');
            this.$commentInput = this.$content.find('.comment-input');
            this.$submitComment = this.$content.find('.btn-submit-comment');

            this.bindEvents();
            Menu.ShowBackButton();
        }

        private prepareComments = (): void => {
            if (!(this.issue.Comments || []).length) {
                return;
            }

            for (let cnt = 0, len = this.issue.Comments.length; cnt < len; cnt++) {
                let comment = this.issue.Comments[cnt];

                if (!(comment instanceof Model.Comment)) {
                    this.issue.Comments[cnt] = new Model.Comment(comment);
                }
            }

            this.issue.Comments.sort((a: Model.Comment, b: Model.Comment) => {
                if (a.CreationTimestamp && b.CreationTimestamp) {
                    return (<Date>a.CreationTimestamp).getTime() - (<Date>b.CreationTimestamp).getTime();
                }

                return (<Date>a.Timestamp).getTime() - (<Date>b.Timestamp).getTime();
            });

            const userCanModifyForeignComments = this.getUserCanModifyForeignComment();

            (this.issue.Comments || []).forEach((comment: any) => {
                if (comment.CreatorOID === Session.User.OID) {
                    comment.ByCurrentUser = true;
                }

                comment.User = DAL.Users.GetByOID(comment.CreatorOID);
                comment.Editor = DAL.Users.GetByOID(comment.EditorOID);

                if (!comment.User) {
                    comment.User = {
                        Title: i18next.t('Misc.Unknown')
                    };
                }

                comment.IsEditable = comment.CreatorOID === Session.User.OID || userCanModifyForeignComments;
            });
        }

        private getUserCanModifyForeignComment = (): boolean => {
            const organizationUnit = DAL.Elements.GetByOID(this.issue.AssignedElementOID);

            return Utils.UserHasRight(Session.User.OID,
                Enums.Rights.Comments_ModifyCommentsOfOtherUsers,
                true,
                organizationUnit
            );
        }

        private unbindEvents(): void {
            this.$subViewToggle.off('click.changeTab');
            this.$content.off('click.openFile');
            this.$content.off('click.showCommentOptions');
            this.$commentInput.off('input');
            this.$submitComment.off('click');
        }

        private bindEvents(): void {
            this.unbindEvents();

            this.$subViewToggle.on('click.changeTab', '.tab-item[data-tab-id]', this.onSelectTabItem);
            this.$content.on('click.openFile', '.attachment[data-filename]', this.onSelectFile);
            this.$content.on('click.showCommentOptions', '.show-comment-options', this.onShowCommentOptionsClick);
            this.$content.find('img').on('error.onLoadError', Utils.OnImageNotFound);
            this.$commentInput.on('input', this.onCommentInput);
            this.$submitComment.on('click', this.onSubmitComment);

            this.$content.find('.add-comment-wrapper input[name="internal-comment"]').bootstrapSwitch({
                onText: 'intern',
                offText: 'global',
                state: true,
                disabled: true
            });
        }

        private onSelectTabItem = (evt: Event): void => {
            const $itm = $(evt.currentTarget);
            const tabId = $itm.data('tab-id');

            if (!tabId) {
                return;
            }

            const $newTab = this.$content.find(`.tab[data-tab="${tabId}"]`);

            if (!$newTab.length) {
                return;
            }

            $itm
                .addClass('active')
                .siblings()
                .removeClass('active');

            $newTab
                .removeClass('hide')
                .siblings()
                .addClass('hide');
        };

        private onSelectFile = (evt: Event): void => {
            const $file = $(evt.currentTarget);
            const filename = $file.data('filename');

            if (!filename) {
                return;
            }

            const file = Utils.Where(this.issue.Files, 'Filename', '===', filename);

            if (!file) {
                return;
            }

            const isImage = Utils.IsImage(file.MimeType);
            const isVideo = Utils.IsVideo(file.MimeType);

            if (isImage) {
                Utils.OpenFiles(this.issue.Files, file.Filename, null, file.Title);
            } else if (isVideo) {
                Utils.OpenFile(file.Filename, false,  true, file.Title);
            } else {
                Utils.OpenFile(file.Filename, false, false, file.Title);
            }
        };

        private onCommentInput = (evt: Event): void => {
            const text = this.getPreparedMessageText();

            if (text && text.trim()) {
                this.$submitComment.removeAttr('disabled');
            } else {
                this.$submitComment.attr('disabled', 'disabled');
            }
        };

        private getPreparedMessageText = (): string => {
            if (!(this.$commentInput instanceof $) ||
                this.$commentInput.length === 0) {
                return null;
            }

            return $.trim(this.$commentInput.val());
        }

        private onSubmitComment = (evt: Event): void => {
            const $btn = $(evt.currentTarget);

            if ($btn.attr('disabled')) {
                return;
            }

            const text = this.getPreparedMessageText();
            const comment = this.createCommentInstance(text);

            comment
                .Save()
                .then(this.onAfterCommentSaved, (_response, _state, _error) => {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        };

        private createCommentInstance(text: string, previousComment?: Model.Comment): Model.Comment {
            const now = new Date();

            const comment = previousComment ? previousComment : new Model.Comment(
                uuid(),
                now, now,
                Session.User.OID, Session.User.OID,
                text,
                this.issue.OID,
                Enums.CommentType.IssueComment
            );

            if (previousComment) {
                comment.EditorOID = Session.User.OID;
                comment.ModificationTimestamp = now;
                comment.Text = text;
            }

            // set issue as helper information
            if (Session.IsSmartDeviceApplication) {
                if (this.issue.ID) {
                    comment.IssueID = this.issue.ID;
                }

                comment.IssueOID = this.issue.OID;
            }

            if (this.issue.ID) {
                comment.AssignmentID = this.issue.ID;
            }

            return comment;
        }

        private onAfterCommentSaved = (comment: Model.Comment): void => {
            this
                .updateIssue(comment)
                .then(() => {
                    this.prepareComments();
                    this.updateComments();
                });
        }

        private updateIssue = (comment: Model.Comment, removeComment: boolean = false): Deferred => {
            if (comment == null) {
                return;
            }

            this.issue.Comments = this.issue.Comments || [];

            const commentIndex = Utils.Where(
                this.issue.Comments,
                'OID',
                '===',
                comment.OID,
                true
            );

            if (commentIndex > -1 && removeComment) {
                this.issue.Comments.splice(commentIndex, 1);
            } else {
                if (commentIndex === -1) {
                    this.issue.Comments.push(comment);
                } else {
                    this.issue.Comments[commentIndex] = comment;
                }
            }

            if (Session.IsSmartDeviceApplication) {
                return window.Database.GetSingleByKey(Enums.DatabaseStorage.Issues, this.issue.OID)
                    .then((dbEntity: Model.Issues.RawIssue) => {
                        if (!dbEntity) {
                            return;
                        }

                        dbEntity.Comments = this.issue.Comments;

                        DAL.Issues.SaveToDatabase(dbEntity, true);
                    });
            }

            return $.Deferred().resolve().promise();
        }

        private updateComments = (): void => {
            const markup = Templates.FullscreenIssueViewer.Comments({
                Comments: this.issue.Comments
            });

            this.$content.find('.comments-wrapper').html(markup);
            this.$content.find('.sub-view-toggle .tab-item[data-tab-id="comment-list"] .text')
                .text(i18next.t('FullscreenIssueViewer.Tabs.Comments.Caption', { count: this.issue.Comments.length }));

            this.$commentInput.val('');
            this.$submitComment.attr('disabled', 'disabled');
        }

        private onShowCommentOptionsClick = (evt: Event): void => {
            const $btn = $(evt.currentTarget);
            const commentIdentifier = $btn.closest('.list-item').data('oid');
            const comment = Utils.Where(this.issue.Comments, 'OID', '===', commentIdentifier);

            if (!comment) {
                return;
            }

            const creator = DAL.Users.GetByOID(comment.CreatorOID);
            const editor = DAL.Users.GetByOID(comment.EditorOID);

            const commentInfo = comment.CreatorOID === comment.EditorOID ? i18next.t('FullscreenIssueViewer.Tabs.Comments.EditComment.InfoText', {
                Creator: creator ? creator.Title : i18next.t('Misc.Unknown'),
                ModificationTimestamp: Utils.DateTime.ToString(comment.ModificationTimestamp)
            }) : i18next.t('FullscreenIssueViewer.Tabs.Comments.EditComment.InfoTextModified', {
                Creator: creator ? creator.Title : i18next.t('Misc.Unknown'),
                Editor: editor ? editor.Title : i18next.t('Misc.Unknown'),
                CreationTimestamp: Utils.DateTime.ToString(comment.CreationTimestamp),
                ModificationTimestamp: Utils.DateTime.ToString(comment.ModificationTimestamp)
            });

            Utils.InputWindow.Show(
                i18next.t('FullscreenIssueViewer.Tabs.Comments.EditComment.MessageHeader'),
                commentInfo,
                {
                    Custom: {
                        Classes: ['btn', 'flat', 'btn-danger', 'pull-left'],
                        Caption: '<i class="icon-bin2"></i>',
                        Fn: () => {
                            const buttonOptions: Utils.Message.OptionButtons = {
                                Abort: {
                                    Classes: ['btn', 'flat'],
                                },
                                Yes: {
                                    Classes: ['btn', 'flat', 'btn-danger'],
                                    Fn: () => this.deleteComment(comment)
                                }
                            };

                            Utils.Message.Show(
                                i18next.t('FullscreenIssueViewer.Tabs.Comments.DeleteComment.MessageHeader'),
                                i18next.t('FullscreenIssueViewer.Tabs.Comments.DeleteComment.MessageBody'),
                                buttonOptions
                            );
                        }
                    },
                    Abort: {},
                    OK: {
                        Fn: (text) => {
                            text = $.trim(text);

                            const newComment = this.createCommentInstance(text, comment);

                            // set issue as helper information
                            if (Session.IsSmartDeviceApplication) {
                                newComment.IssueID = comment.IssueID;
                                newComment.IssueOID = comment.IssueOID;
                            }

                            newComment.Save()
                                .then((comment: Model.Comment) => this.onAfterCommentSaved(comment))
                                .fail((_response, _state, _error) => {
                                    throw new Model.Errors.HttpError(_error, _response);
                                });
                        }
                    }
                },
                comment.Text,
                'textarea'
            );
        }

        private deleteComment = (comment: Model.Comment): Deferred => {
            if (!comment) {
                return $.Deferred.reject('no comment given').promise();
            }

            comment
                .Delete()
                .then(this.onAfterCommentDeleted, (_response, _state, _error) => {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        }

        private onAfterCommentDeleted = (comment: Model.Comment): void => {
            this
                .updateIssue(comment, true)
                .then(() => {
                    this.prepareComments();
                    this.updateComments();
                });
        }
    }
}