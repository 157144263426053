//imports-start
/// <reference path="./app.session.ts"/>
/// <reference path="./app.view.ts"/>
//// <reference path="../templates.d.ts"/>
/// <reference path="../utils/utils.push-notifications.ts"  />
/// <reference path="../utils/utils.credential-editor.ts"  />
/// <reference path="../dal/teams.ts"  />
//// <reference path="../dal/users.ts"  />
/// <reference path="../utils/utils.analytics.ts"  />
/// <reference path="../enums.ts" />
/// <reference path="../new/login/advanced-settings.ts" />
/// <reference path="../new/analytics/web-tracker.ts" />
//imports-end

module Settings {
    let _$content: any;
    let _$contentHeader: any;
    let _user: any;
    let _teams: any;
    let _changeViewRefreshInputTimeout: any;
    let _tagDiscoveryTimer: number;
    let _selectedNfcTagLocationIdentifier: string;
    let _$nfcInfoWindow: any;
    let _$nfcInfoWindowOverlay: any;
    let _nfcTagRead = false;

    export function MarkChanged(name: Enums.UserSettings) {
        if (!Session.SettingsMetadata) {
            Session.SettingsMetadata = {};
        }
        if (!Session.SettingsMetadata[name]) {
            Session.SettingsMetadata[name] = new Model.SettingsMetadata();
        }
        // mark for sync
        Session.SettingsMetadata[name].HasUnsyncedChanges = true;
    }

    function onChangeBooleanSetting(name: Enums.UserSettings): Function {
        if (!name) {
            throw new Error("Missing settings name!");
        }

        /*
        * return handler for value change
        */
        return (_evt, val: boolean) => {
            ChangeBooleanSetting(name, val);
        };
    }

    function ChangeBooleanSetting(name: Enums.UserSettings, enable: boolean): void {
        if (!name) {
            throw new Error("Missing settings name!");
        }

        // set new value
        switch (name) {
            case Enums.UserSettings.LocationPickerWidth:
                Session.Settings[name] = 25;
                break;
            case Enums.UserSettings.FoldParametergroups:
            case Enums.UserSettings.DisableDynamicTextSize:
                Session.Settings[name] = !enable;
                break;
            case Enums.UserSettings.UseGoogleAnalytics:
                {
                    Session.Settings[name] = enable;

                    if (!isLocked(Enums.UserSettings.UseAdvancedAnalyticsInformation)) {

                        if (!enable) {
                            Session.Settings[Enums.UserSettings.UseAdvancedAnalyticsInformation] = false;
                            MarkChanged(Enums.UserSettings.UseAdvancedAnalyticsInformation);
                        }

                        const $advancedAnalytics = $('#cbSettingsAdvancedAnalytics');
                        $advancedAnalytics.bootstrapSwitch('state', !!enable);
                        $advancedAnalytics.bootstrapSwitch('disabled', !enable);
                    }
                }
                break;
            case Enums.UserSettings.UseAdvancedAnalyticsInformation:
                // enable AdvancedAnalyticsInformation only if UseGoogleAnalytics active
                if (Session.Settings.UseGoogleAnalytics) {
                    Session.Settings[name] = enable;
                }
                break;
            default:
                Session.Settings.Set(name, enable);
        }

        // mark for save/sync
        MarkChanged(name);

        Save()
            .then(() => ApplyPostAction(name, enable));
    }

    export function SetupMetadataAfterUpdate() {
        // mark all user settings for sync
        for (let key in Enums.UserSettings) {
            MarkChanged(<Enums.UserSettings>Enums.UserSettings[key]);
        }
    }

    export function ApplyPostAction(name: Enums.UserSettings, enable: boolean): void
    export function ApplyPostAction(name: Enums.UserSettings, value: number): void
    export function ApplyPostAction(name: Enums.UserSettings, value: boolean | number): void {
        // no changes while still in login view
        if (View.CurrentView == Enums.View.Login) {
            return;
        }

        // post actions after value change
        switch (name) {
            case Enums.UserSettings.DeadlineFilterPreset:
                IssueReport.InitDeadlineFilter();
                break;
            case Enums.UserSettings.DisableDynamicTextSize:
                updateDynamicTextSize();
                break;
            case Enums.UserSettings.UseGoogleAnalytics:
                New.Analytics.ToggleGoogleAnalytics(<boolean>value);
                break;
            case Enums.UserSettings.AutoSync:
                if (value) {
                    SyncCenter.StartAutoSync()
                } else {
                    SyncCenter.StopAutoSync();
                }
                break;
            case Enums.UserSettings.AutoSyncInterval:
                if (Session.Settings.AutoSync) {
                    SyncCenter.StartAutoSync();
                }
                break;
            case Enums.UserSettings.EnableSyncLog:
                if (!value) {
                    window.Database.ClearStorage(Enums.DatabaseStorage.SyncLog);
                }
                break;
            case Enums.UserSettings.ShowOnlyMyIssuesPerDefault:
                IssueReport.UpdateTeamsAndUsersFilter(true);
                IssueReport.SetFilterState();
                break;
            case Enums.UserSettings.ShowTreeCounters:
                App.UpdateTreeCounters();
                break;
            case Enums.UserSettings.ShowMenuItemsAsTiles:
                Menu.UpdateColorsAndIcon();
                break;
            case Enums.UserSettings.ShowAllIssuesInOneReport:
                IssueReport.SetFilterState();
                break;
            case Enums.UserSettings.ShowTreeView:
            case Enums.UserSettings.LocationPickerWidth:
                View.Refresh();
                break;
            case Enums.UserSettings.EnablePushNotifications:
                toggleNotifications(<boolean>value);
                break;
            case Enums.UserSettings.DisableProcessingStatusSmiley:
                App.UpdateContentHeaderIssueProcessingIndicator();
                break;
        }
    }

    function updateDynamicTextSize(): void {
        if (localStorage) {
            localStorage.setItem('_viewport_scale_text_', JSON.stringify(Session.Settings.DisableDynamicTextSize));
        }

        if (Session.IsRunningOnIOS) {
            window.location.reload();
        } else {
            App.setViewport();
        }
    }

    function onBlurAutoSyncInterval(evt: any): void {
        const $this = $(this);
        let value = parseFloat($this.val());

        if (isNaN(value) || value < 1) {
            value = 1;
            $this.val(value);
        }

        Session.Settings.AutoSyncInterval = value;
        // mark for save/sync
        MarkChanged(Enums.UserSettings.AutoSyncInterval);

        Save();

        ApplyPostAction(Enums.UserSettings.AutoSyncInterval, value);
    }


    function toggleNotifications(enable: boolean): void {
        if (!enable) {
            Utils.UpdateAppBadge(0);

            if (Utils.PushNotifications) {
                Utils.PushNotifications.Unregister();
            }
        } else {
            if (Utils.PushNotifications) {
                Utils.PushNotifications.Init();
            }
        }
    }

    function onAutomaticallyChangeRoomAfterRecordingButtonClick(evt: any): void {
        const $this = $(this);

        if ($this.hasClass('disabled')) {
            return;
        }

        const preset = $this.data('value') || 0;

        $this
            .addClass('active')
            .siblings()
            .removeClass('active');

        if (Enums.AutomaticallyChangeRoomAfterRecordingType[preset] != null) {
            Session.Settings.AutomaticallyChangeRoomAfterRecording = preset;

            MarkChanged(Enums.UserSettings.AutomaticallyChangeRoomAfterRecording);

            Save();
        }
    }

    function onAutomaticallyOpenNextCheckpointButtonClick(evt: any): void {
        const $this = $(this);

        if ($this.hasClass('disabled')) {
            return;
        }

        const preset = $this.data('value') || 0;

        $this
            .addClass('active')
            .siblings()
            .removeClass('active');

        if (Enums.AutomaticallyOpenNextCheckpoint[preset] != null) {
            Session.Settings.AutomaticallyOpenNextCheckpoint = preset;

            MarkChanged(Enums.UserSettings.AutomaticallyOpenNextCheckpoint);

            Save();
        }
    }

    function onChangeEnableViewRefresh(evt: any, enableViewRefresh: boolean): void {
        const $input = $('#numberSettingsViewRefreshTimeout');

        $input.parents('.setting').toggleClass('hidden', !enableViewRefresh);

        if (!enableViewRefresh) {
            delete Session.Settings.ViewRefreshTimeout;
        } else {
            Session.Settings.ViewRefreshTimeout = 5;
            $input.val(5)
        }

        Session.Settings.EnableViewRefresh = enableViewRefresh;

        MarkChanged(Enums.UserSettings.ViewRefreshTimeout);
        MarkChanged(Enums.UserSettings.EnableViewRefresh);

        Save();
    }

    function onChangeViewRefreshTimeout(evt: any): void {
        const $this = $(this);

        if (_changeViewRefreshInputTimeout) {
            window.clearTimeout(_changeViewRefreshInputTimeout);
        }

        _changeViewRefreshInputTimeout = window.setTimeout(() => {
            let value = parseFloat($this.val());

            if (isNaN(value) || value < 5) {
                value = 5;
                $this.val(value);
            }

            Session.Settings.ViewRefreshTimeout = value;

            MarkChanged(Enums.UserSettings.ViewRefreshTimeout);

            Save();
        }, 600)
    }

    function onDeadlineFilterPresetButtonClick(evt: any): void {
        const $this = $(this);

        if ($this.hasClass('disabled')) {
            return;
        }

        const presetInDays = $this.data('days') || 0;

        $this
            .addClass('active')
            .siblings()
            .removeClass('active');

        Session.Settings.DeadlineFilterPreset = presetInDays;

        MarkChanged(Enums.UserSettings.DeadlineFilterPreset);

        Save();

        ApplyPostAction(Enums.UserSettings.DeadlineFilterPreset, presetInDays);
    }

    function onUserImageClick(): void {
        const options: Utils.ImageViewerOptions = {
            ImageFilename: $(this).data('filename')
        };

        Utils.ImageViewerManager.Show(options);
    }

    function onBtnChangeCredentialsClick(): void {
        Utils.CheckIfDeviceIsOnline()
            .then(function() {
                Utils.CredentialEditor.Show();
            }, function() {
                Utils.Message.Show(i18next.t('Settings.NoInternetConnection.MessageHeader'),
                    i18next.t('Misc.NoInternetConnection.MessageBody'),
                    {
                        Close: true
                    }, null, 1051);
            });
    }

    function onBtnDeleteClientDataClick(): void {
        if ($(this).hasClass('disabled') || SyncCenter.GetIsSynchronisationInProgress()) {
            return;
        }

        Utils.WipeClientData();
    }

    function onBtnWriteLocationToNFCTagClick(): void {
        if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.WriteToNfcTags, false)) {
            return;
        }

        Utils.ElementPickerPopup.Show({
            SelectedItemIdentifier: (Session.CurrentLocation || DAL.Elements.Root).OID,
            CannotCollapseRoot: true,
            OnConfirmSelection: onConfirmWriteToNFCTag,
            ZIndex: 10000
        });
    }

    function onConfirmWriteToNFCTag(result: { ElementOID: string }): void {
        _selectedNfcTagLocationIdentifier = result.ElementOID;

        // Allgemeinen Handler entfernen, um Tag hier fangen zu können
        window.nfc.removeNdefListener(App.OnNdefTagRead);

        if (Session.IsRunningOnIOS) {
            onNFCTagDiscovered(null, null);
        } else {
            window.nfc.addTagDiscoveredListener(onTagDiscovered, onDiscoverNFCTagStarted, onStartDiscoverNFCTagFailed);
        }
    }

    function onTagDiscovered(nfcTagEvent) {
        if (_nfcTagRead) {
            return;
        }
        _nfcTagRead = true;
        clearDiscoveryTimer();
        onNFCTagDiscovered(nfcTagEvent, _selectedNfcTagLocationIdentifier);
    }

    function onDiscoverNFCTagStarted(): void {
        _tagDiscoveryTimer = setTimeout(function() {
            clearDiscoveryTimer();
            finishWritingNfcTag();
        }, 60000);

        showNfcInfoWindow();
    }

    function showNfcInfoWindow(): void {
        const $window = $(Templates.Settings.NfcInfoWindow());

        $window.find('.btn-close').on('click', closeNfcInfoWindow);

        _$nfcInfoWindow = $window;
        _$nfcInfoWindowOverlay = Utils.Overlay.Generate('olSettingsNfcInfoWindow', 1049, closeNfcInfoWindow);

        $('body').append($window);

        _$nfcInfoWindow.modal({
            show: true,
            backdrop: false
        });

        Utils.RepositionModalWindow(_$nfcInfoWindow);

        $window.find('img').on('load', function() {
            Utils.RepositionModalWindow(_$nfcInfoWindow);
        });

        $window.find('.btn-close').on('click', finishWritingNfcTag);
    }

    function closeNfcInfoWindow(): void {
        if (_$nfcInfoWindow) {
            _$nfcInfoWindow.remove();
            _$nfcInfoWindow = null;
        }

        if (_$nfcInfoWindowOverlay) {
            Utils.Overlay.DestroyWithTimeout(_$nfcInfoWindowOverlay);
            _$nfcInfoWindowOverlay = null;
        }

        $('body').removeClass('modal-open');
    }

    function onStartDiscoverNFCTagFailed(): void {
        window.nfc.removeTagDiscoveredListener();
        window.nfc.addNdefListener(App.OnNdefTagRead);
        _nfcTagRead = false;
    }

    function clearDiscoveryTimer(): void {
        clearTimeout(_tagDiscoveryTimer);
        _tagDiscoveryTimer = null;
    }

    function onNFCTagDiscovered(nfcTagEvent, locationIdentifier: string): void {
        const mimeType = 'text/plain';
        const payload = `aw_loc_${_selectedNfcTagLocationIdentifier}`;
        const record = window.ndef.mimeMediaRecord(mimeType, window.nfc.stringToBytes(payload));

        window.nfc.write([record], onAfterWrittenToNFCTag, onAfterWritingToNFCTagFailed);
    }

    function onAfterWrittenToNFCTag(): void {
        Utils.Toaster.Show(i18next.t('Settings.WriteNfcTag.WritingToTagSuccessful'), .2);
        finishWritingNfcTag();
    }

    function onAfterWritingToNFCTagFailed(): void {
        Utils.Toaster.Show(i18next.t('Settings.WriteNfcTag.WritingToTagFailed'), .2);
        finishWritingNfcTag();
    }

    function finishWritingNfcTag(): void {
        closeNfcInfoWindow();
        window.nfc.removeTagDiscoveredListener(onNFCTagDiscovered);
        window.nfc.addNdefListener(App.OnNdefTagRead);
        _selectedNfcTagLocationIdentifier = null;
        _nfcTagRead = false;
    }

    function onPanelHeadingClick(evt: any): void {
        const $this = $(this);
        const $panel = $this.parent();
        const panel = $panel.data('panel');

        $panel.toggleClass('collapsed');

        if (!!panel) {
            if (!Session.Settings.Settings) {
                Session.Settings.Settings = {};
            }

            if (!Session.Settings.Settings.hasOwnProperty(panel)) {
                Session.Settings.Settings[panel] = {};
            }

            Session.Settings.Settings[panel] = {
                IsCollapsed: $panel.hasClass('collapsed')
            };

            MarkChanged(Enums.UserSettings.Settings);

            Save();
        }
    }

    function determinePersonInformation(): void {
        if (_user) {
            _teams = DAL.Teams.GetByUserOID(_user.OID);
            _teams.sort(Utils.SortByTitle);
        }
    }

    function isLocked(name: Enums.UserSettings): boolean {
        if (Session.SettingsMetadata[name]) {
            return Session.SettingsMetadata[name].IsLocked || false;
        }
        return false;
    }

    export function BindBooleanSwitch(elementID: string, settingName: Enums.UserSettings, options?: { onText?: string, offText?: string, onSwitchChange?: Function }) {
        options = options || {};

        let state = Session.Settings[settingName];

        if (settingName == Enums.UserSettings.FoldParametergroups ||
            settingName == Enums.UserSettings.DisableDynamicTextSize) {
            state = !state;
        }

        $(elementID).bootstrapSwitch({
            onText: options.onText || i18next.t('Misc.Yes'),
            offText: options.offText || i18next.t('Misc.No'),
            state: state,
            disabled: isLocked(settingName),
            onSwitchChange: options.onSwitchChange || onChangeBooleanSetting(settingName)
        });
    }

    function bindEvents(): void {
        _$content.find('img').on('error', Utils.OnImageNotFound);

        BindBooleanSwitch(
            '#cbSettingsShowTreeView',
            Enums.UserSettings.ShowTreeView,
            {
                onText: i18next.t('Settings.ShowTreeView.Show'),
                offText: i18next.t('Settings.ShowTreeView.Hide')
            }
        );

        BindBooleanSwitch(
            '#cbSettingsFoldParametergroups',
            Enums.UserSettings.FoldParametergroups,
            {
                onText: i18next.t('Settings.FoldParametergroups.Unfolded'),
                offText: i18next.t('Settings.FoldParametergroups.Folded')
            }
        );

        BindBooleanSwitch(
            '#cbAlwaysShowAdvancedRecorditemEditor',
            Enums.UserSettings.AlwaysShowAdvancedRecorditemEditor
        );

        BindBooleanSwitch(
            '#cbShowCheckpointToolbar',
            Enums.UserSettings.ShowCheckpointToolbar
        );

        BindBooleanSwitch(
            '#cbShowEvaluationInElementInformation',
            Enums.UserSettings.ShowEvaluationInElementInformation
        );

        BindBooleanSwitch(
            '#cbExpandTreeInSelectionWindow',
            Enums.UserSettings.ExpandTreeInSelectionWindow
        );

        BindBooleanSwitch(
            '#cbShowOnlyAssignedForms',
            Enums.UserSettings.ShowOnlyAssignedForms
        );

        BindBooleanSwitch(
            '#cbShowIssueViewerAfterTakingPhotoDuringInspection',
            Enums.UserSettings.ShowIssueViewerAfterTakingPhotoDuringInspection
        );

        BindBooleanSwitch(
            '#cbConfirmOverwriteExistingPhotos',
            Enums.UserSettings.ConfirmOverwriteExistingPhotos
        );

        BindBooleanSwitch(
            '#cbRedirectToMainMenuAfterClosingIssueView',
            Enums.UserSettings.RedirectToMainMenuAfterClosingIssueView
        );

        BindBooleanSwitch(
            '#cbShowAllIssuesInOneReport',
            Enums.UserSettings.ShowAllIssuesInOneReport
        );

        BindBooleanSwitch(
            '#cbShowMenuItemsAsTiles',
            Enums.UserSettings.ShowMenuItemsAsTiles
        );

        BindBooleanSwitch(
            '#cbShowOnlyMyIssuesPerDefault',
            Enums.UserSettings.ShowOnlyMyIssuesPerDefault
        );

        BindBooleanSwitch(
            '#cbShowIdInIssueReports',
            Enums.UserSettings.ShowIdInIssueReports
        );

        BindBooleanSwitch(
            '#cbShowCreationTimestampInIssueReports',
            Enums.UserSettings.ShowCreationTimestampInIssueReports
        );

        BindBooleanSwitch(
            '#cbShowResponsibilitiesInIssueReports',
            Enums.UserSettings.ShowResponsibilitiesInIssueReports
        );

        BindBooleanSwitch(
            '#cbShowLocationInIssueReports',
            Enums.UserSettings.ShowLocationInIssueReports
        );

        BindBooleanSwitch(
            '#cbDisableProcessingStatusSmiley ',
            Enums.UserSettings.DisableProcessingStatusSmiley
        );

        BindBooleanSwitch(
            '#cbShowRecordingPicturesInOverviews',
            Enums.UserSettings.ShowPicturesInIssueReports
        );

        BindBooleanSwitch(
            '#cbSettingsUseAnalytics',
            Enums.UserSettings.UseGoogleAnalytics
        );

        BindBooleanSwitch(
            '#cbSettingsAdvancedAnalytics',
            Enums.UserSettings.UseAdvancedAnalyticsInformation
        )

        BindBooleanSwitch(
            '#cbEnableDynamicTextSize',
            Enums.UserSettings.DisableDynamicTextSize
        );

        BindBooleanSwitch(
            '#cbEnablePushNotifications',
            Enums.UserSettings.EnablePushNotifications
        );

        BindBooleanSwitch(
            '#cbAskIfCreateIssueAfterPhotoComment',
            Enums.UserSettings.AskIfCreateIssueAfterPhotoComment
        );

        BindBooleanSwitch(
            '#cbAskIfCreateIssueAfterTextComment',
            Enums.UserSettings.AskIfCreateIssueAfterTextComment
        );

        BindBooleanSwitch(
            '#cbEnableTwoStateCheckbox',
            Enums.UserSettings.EnableTwoStateCheckbox
        );

        BindBooleanSwitch(
            '#cbShowTabularSubsampleByDefault',
            Enums.UserSettings.ShowTabularSubsampleByDefault
        );

        BindBooleanSwitch(
            '#cbTabularSubsampleShowNumber',
            Enums.UserSettings.TabularSubsampleShowNumber
        );

        BindBooleanSwitch(
            '#cbTabularSubsampleConsistentWidth',
            Enums.UserSettings.TabularSubsampleConsistentWidth
        );

        BindBooleanSwitch(
            '#cbSidebarDefaultVisibility',
            Enums.UserSettings.SidebarDefaultVisibility,
            {
                onText: i18next.t('Settings.SidebarDefaultVisibility.Show'),
                offText: i18next.t('Settings.SidebarDefaultVisibility.Hide')
            }
        );

        BindBooleanSwitch(
            '#cbShowCreatorAndTimeInSidebar',
            Enums.UserSettings.ShowCreatorAndTimeInSidebar
        );

        BindBooleanSwitch(
            '#cbAskForFollowStateAfterCompletion',
            Enums.UserSettings.AskForFollowStateAfterCompletion
        );

        BindBooleanSwitch(
            '#cbShowNextStateOnUnrecorded',
            Enums.UserSettings.ShowNextStateOnUnrecorded
        );

        BindBooleanSwitch(
            '#cbPasteImageAsSignature',
            Enums.UserSettings.PasteImageAsSignature
        );

        BindBooleanSwitch(
            '#cbShowElementInfoButtons',
            Enums.UserSettings.ShowElementInfoButtons
        );

        if (Session.IsSmartDeviceApplication || Session.LastKnownAPIVersion >= 5) {
            BindBooleanSwitch(
                '#cbShowTreeCounters',
                Enums.UserSettings.ShowTreeCounters
            );
        }

        if (Session.IsSmartDeviceApplication) {
            BindBooleanSwitch(
                '#cbAskBeforeSynchronisation',
                Enums.UserSettings.AskBeforeSynchronisation
            );

            BindBooleanSwitch(
                '#cbAutoSync',
                Enums.UserSettings.AutoSync
            );

            $('#autoSyncIntervalValue, #cbAllowAutoSyncOverCell')
                .parents('.setting')
                .toggleClass('hidden', !Session.Settings.AutoSync);

            $('#autoSyncIntervalValue')
                .on('blur', onBlurAutoSyncInterval)
                .val(Session.Settings.AutoSyncInterval)
                .prop('disabled', isLocked(Enums.UserSettings.AutoSyncInterval));

            BindBooleanSwitch(
                '#cbAllowAutoSyncOverCell',
                Enums.UserSettings.AllowAutoSyncOverCell
            );

            BindBooleanSwitch(
                '#cbSyncAtApplicationStart',
                Enums.UserSettings.SyncAtApplicationStart
            );

            BindBooleanSwitch(
                '#cbEnableSyncLog',
                Enums.UserSettings.EnableSyncLog
            );
        } else {
            BindBooleanSwitch(
                '#cbEnableViewRefresh',
                Enums.UserSettings.EnableViewRefresh,
                { onSwitchChange: onChangeEnableViewRefresh }
            );

            $('#numberSettingsViewRefreshTimeout')
                .parents('.setting')
                .toggleClass('hidden', !Session.Settings.EnableViewRefresh);

            $('#numberSettingsViewRefreshTimeout')
                .on('change input', onChangeViewRefreshTimeout)
                .val(Session.Settings.ViewRefreshTimeout)
                .prop('disabled', isLocked(Enums.UserSettings.ViewRefreshTimeout));
        }

        if (Session.LastKnownAPIVersion >= 2) {
            BindBooleanSwitch(
                '#cbShowPrioritizedFiles',
                Enums.UserSettings.ShowPrioritizedFiles
            );
        }

        _$content.find('.panel-heading').on('click', onPanelHeadingClick);

        _$content.find('.deadline-filter-preset .btn')
            .prop('disabled', isLocked(Enums.UserSettings.DeadlineFilterPreset))
            .on('click', onDeadlineFilterPresetButtonClick);

        _$content.find('.automatically-change-room-after-recording .btn')
            .prop('disabled', isLocked(Enums.UserSettings.AutomaticallyChangeRoomAfterRecording))
            .on('click', onAutomaticallyChangeRoomAfterRecordingButtonClick);

        _$content.find('.automatically-open-next-checkpoint .btn')
            .prop('disabled', isLocked(Enums.UserSettings.AutomaticallyOpenNextCheckpoint))
            .on('click', onAutomaticallyOpenNextCheckpointButtonClick);

        _$content.find('.btn-reset-location-picker-view').on('click', onChangeBooleanSetting(Enums.UserSettings.LocationPickerWidth));
        _$content.find('.btn-change-credentials').on('click', onBtnChangeCredentialsClick);

        _$content.find('img[data-filename]').on('click', onUserImageClick);
        _$content.find('button[data-action="delete-data"]').on('click', onBtnDeleteClientDataClick);
        _$contentHeader.on('click', App.OnContentHeaderClick);

        if (Session.NfcEnabled && Utils.UserHasRight(Session.User.OID, Enums.Rights.WriteToNfcTags, false)) {
            _$content.find('.btn[data-action="write-to-nfc-tag"]').on('click', onBtnWriteLocationToNFCTagClick);
        }

        if (!Session.Settings.UseGoogleAnalytics) {
            $('#cbSettingsAdvancedAnalytics').bootstrapSwitch('disabled', true);
        }
    }

    function showContentHeader(): void {
        const $content = $('#content');

        _$contentHeader = Utils.GetDefaultContentHeader(Session.CurrentLocation);

        if ($content.siblings('.content-header').length) {
            $content.siblings('.content-header').replaceWith(_$contentHeader);
        } else {
            $content.before(_$contentHeader);
        }
    }

    function getIfBluetoothSettingIsEnabled(): boolean {
        const availableMenus = Utils.GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Settings);

        return (availableMenus || []).indexOf(Enums.MenuItemID.BluetoothConfiguration) !== -1;
    }

    export function Show(): void {
        _$content = Utils.GetContentContainer();
        _user = DAL.Users.GetByOID(Session.User.OID);

        Utils.SetMode(Enums.Mode.Settings);
        View.SetView(Enums.View.Main);

        determinePersonInformation();

        const showNfcPanel =
            Session.NfcEnabled &&
            Utils.UserHasRight(Session.User.OID, Enums.Rights.WriteToNfcTags, false);

        _$content.html(Templates.Settings.Content({
            User: Session.User,
            Client: Session.Client,
            EMailAddress: (_user || {}).EmailAddress,
            BluetoothSettingIsEnabled: getIfBluetoothSettingIsEnabled(),
            Teams: _teams,
            BaseURI: Session.BaseURI,
            APIVersion: Session.LastKnownAPIVersion,
            SynchronisationInProgress: SyncCenter.GetIsSynchronisationInProgress(),
            Settings: Session.Settings,
            AutomaticallyChangeRoomAfterRecordingItems: getAutomaticallyChangeRoomAfterRecordingItems(),
            AutomaticallyOpenNextCheckpointItems: getAutomaticallyOpenNextCheckpointItems(),
            ShowNfcPanel: showNfcPanel
        }));

        showContentHeader();
        bindEvents();
    }

    function getAutomaticallyChangeRoomAfterRecordingItems() {
        return [
            {
                Value: Enums.AutomaticallyChangeRoomAfterRecordingType.Always,
                Active: Enums.AutomaticallyChangeRoomAfterRecordingType.Always === Session.Settings.AutomaticallyChangeRoomAfterRecording,
                TitleKey: 'Settings.AutomaticallyChangeRoomAfterRecording.Always'
            },
            {
                Value: Enums.AutomaticallyChangeRoomAfterRecordingType.Ask,
                Active: Enums.AutomaticallyChangeRoomAfterRecordingType.Ask === Session.Settings.AutomaticallyChangeRoomAfterRecording,
                TitleKey: 'Settings.AutomaticallyChangeRoomAfterRecording.Ask'
            },
            {
                Value: Enums.AutomaticallyChangeRoomAfterRecordingType.Never,
                Active: Enums.AutomaticallyChangeRoomAfterRecordingType.Never === Session.Settings.AutomaticallyChangeRoomAfterRecording,
                TitleKey: 'Settings.AutomaticallyChangeRoomAfterRecording.Never'
            }
        ]
    }

    function getAutomaticallyOpenNextCheckpointItems() {
        return [
            {
                Value: Enums.AutomaticallyOpenNextCheckpoint.Always,
                Active: Enums.AutomaticallyOpenNextCheckpoint.Always === Session.Settings.AutomaticallyOpenNextCheckpoint,
                TitleKey: 'Settings.AutomaticallyOpenNextCheckpoint.Always'
            },
            {
                Value: Enums.AutomaticallyOpenNextCheckpoint.OnlyRequired,
                Active: Enums.AutomaticallyOpenNextCheckpoint.OnlyRequired === Session.Settings.AutomaticallyOpenNextCheckpoint,
                TitleKey: 'Settings.AutomaticallyOpenNextCheckpoint.OnlyRequired'
            },
            {
                Value: Enums.AutomaticallyOpenNextCheckpoint.Never,
                Active: Enums.AutomaticallyOpenNextCheckpoint.Never === Session.Settings.AutomaticallyOpenNextCheckpoint,
                TitleKey: 'Settings.AutomaticallyOpenNextCheckpoint.Never'
            }
        ]
    }

    export function Save(): Deferred {
        if (Session.User) {
            if (Session.IsSmartDeviceApplication) {
                return Session.SaveSystemData(true);
            } else {
                // works with API 13 or newer
                return Utils.Synchronisation.Upload.UploadUserSettings();
            }
        }

        return $.Deferred().resolve();
    }

    export function IsAutomaticallyOpenNextParameterSettingActive() {
        switch (Session.Settings.AutomaticallyOpenNextCheckpoint) {
            case Enums.AutomaticallyOpenNextCheckpoint.Always:
            case Enums.AutomaticallyOpenNextCheckpoint.OnlyRequired:
                return true;
            case Enums.AutomaticallyOpenNextCheckpoint.Never:
                return false;
        }
    }
}