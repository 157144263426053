//imports-start
/// <reference path="./utils.base-filter-window.ts"  />
/// <reference path="../../model/filter-window/group.ts"  />
/// <reference path="../../model/filter-window/button-item.ts"  />
//imports-end

module Utils {
    export class IssueFilterWindow extends BaseFilterWindow<Model.Issues.Filter> {
        constructor(predefinedFilters?: Model.Issues.Filter, onSave?: Function) {
            super(new Model.Issues.Filter(predefinedFilters), onSave);
        }

        protected getAvailableFilterItems(): Array<Model.FilterWindow.Group> {
            const distributor = new Model.FilterWindow.Group(
                i18next.t('IssueFilterWindow.Distributor'),
                0,
                [
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.UsersAndTeams,
                        `${i18next.t('Misc.User_plural')} / ${i18next.t('Misc.Team_plural')}`,
                        (this.filters.Users || []).length || (this.filters.Teams || []).length ? 'red' : null,
                        this.onBtnUserFilterClick,
                        'users'
                    )
                ]
            );

            if (Session.LastKnownAPIVersion >= 10 &&
                (Utils.UserHasRight(Session.User.OID, Enums.Rights.ShowAllIssues, true) ||
                    Utils.UserHasRight(Session.User.OID, Enums.Rights.ShowSelfCreateOrModifiedIssues, true))) {
                distributor.Items.push(new Model.FilterWindow.ButtonItem(
                    Enums.FilterWindow.Property.HideNonResponsibleIssues,
                    i18next.t('IssueFilterWindow.HideNonResponsibleIssues'),
                    this.filters.HideNonResponsibleIssues ? 'red' : null,
                    this.onBtnHideNonResponsibleIssuesClick,
                    'user'
                ));
            }

            if (this.getIsContactFilterAvailable()) {
                distributor.Items.push(new Model.FilterWindow.ButtonItem(
                    Enums.FilterWindow.Property.Contacts,
                    i18next.t('Misc.Contact_plural'),
                    (this.filters.Contacts || []).length ? 'red' : null,
                    this.onBtnContactsFilterClick,
                    'mail'
                ));
            }

            if (this.getIsContactGroupFilterAvailable()) {
                distributor.Items.push(new Model.FilterWindow.ButtonItem(
                    Enums.FilterWindow.Property.ContactGroups,
                    i18next.t('Misc.ContactGroup_plural'),
                    (this.filters.ContactGroups || []).length ? 'red' : null,
                    this.onBtnContactGroupsFilterClick,
                    'mail'
                ));
            }


            const properties = new Model.FilterWindow.Group(
                i18next.t('IssueFilterWindow.Properties'),
                1,
                [
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.States,
                        i18next.t('Misc.State_plural'),
                        (this.filters.States || []).length ? 'red' : null,
                        this.onBtnStateFilterClick,
                        'cogs'),
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.Priorities,
                        i18next.t('Misc.Priority_plural'),
                        (this.filters.Priorities || []).length ? 'red' : null,
                        this.onBtnPrioritiesFilterClick,
                        'notification'),
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.Keywords,
                        i18next.t('Misc.Keyword_plural'),
                        (this.filters.Keywords || []).length ? 'red' : null,
                        this.onBtnKeywordFilterClick,
                        'price-tags'),
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.Classifications,
                        i18next.t('Misc.Classification_plural'),
                        (this.filters.Classifications || []).length ? 'red' : null,
                        this.onBtnClassificationFilterClick,
                        'ticket')
                ]
            );

            const dates = new Model.FilterWindow.Group(
                i18next.t('IssueFilterWindow.Dates'),
                2,
                [
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.DeadlineTimestamp,
                        i18next.t('IssueReport.Deadline'),
                        this.filters.DeadlinePeriod instanceof Model.DateTime.Period ? 'red' : null,
                        this.onBtnDeadlineFilterClick,
                        'calendar')
                ]
            );

            const linkedTemplates = new Model.FilterWindow.Group(
                i18next.t('IssueFilterWindow.LinkedTemplates'),
                3,
                [
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.Forms,
                        i18next.t('Misc.Form_plural'),
                        (this.filters.Forms || []).length ? 'red' : null,
                        this.onBtnFormFilterClick,
                        'clipboard'),
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.Schedulings,
                        i18next.t('Misc.Scheduling_plural'),
                        (this.filters.Schedulings || []).length ? 'red' : null,
                        this.onBtnSchedulingFilterClick,
                        'tree')
                ]
            );

            const misc = new Model.FilterWindow.Group(
                i18next.t('IssueFilterWindow.Misc'),
                4,
                [
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.SearchText,
                        i18next.t('IssueReport.FilterByText.ButtonText'),
                        !!this.filters.SearchText ? 'red' : null,
                        this.onBtnTextSearchButtonClick,
                        'i-cursor'),
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.Types,
                        i18next.t('Misc.IssueType.Text_plural'),
                        (this.filters.UserSelectedTypes || []).length ? 'red' : null,
                        this.onBtnTypeFilterClick,
                        'drawer'),
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.ProcessingStatus,
                        i18next.t('IssueReport.ProcessingStatus'),
                        Utils.IsSet(this.filters.ProcessingStatus) ? 'red' : null,
                        this.onBtnProcessingFilterClick,
                        'smile')
                ]
            );

            return [distributor, properties, dates, linkedTemplates, misc];
        };

        private getIsContactFilterAvailable(): boolean {
            return Utils.IsLicenseAvailable('Contacts', false) && (DAL.Contacts.GetAll() || []).length > 0;
        }

        private getIsContactGroupFilterAvailable(): boolean {
            return Utils.IsLicenseAvailable('Contacts', false) && (DAL.ContactGroups.GetAll() || []).length > 0;
        }

        private onBtnUserFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);

            Utils.UserPicker.Show(
                {
                    AllowUsers: true,
                    AllowTeams: true,
                    ShowSystemUsers: true,
                    ShowLockedUsers: true,
                    FilterIssueResponsibilities: true
                },
                {
                    Users: Utils.CloneArray(this.filters.Users),
                    Teams: Utils.CloneArray((this.filters.Teams || []).filter(function(oid: string) {
                        return DAL.Teams.GetByOID(oid);
                    }))
                },
                Session.CurrentLocation,
                (selections) => {
                    this.filters.Users = selections ? (selections.Users || []) : [];
                    this.filters.Teams = selections ? (selections.Teams || []) : [];

                    $btn.toggleClass('red', this.filters.Users.length || this.filters.Teams.length);

                    this.onAfterFilterChanged();
                });
        };

        private onBtnKeywordFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            Utils.ElementPickerPopup.Show({
                RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Keyword),
                Items: DAL.Properties.GetByType(Enums.PropertyType.Keyword),
                VisibleObjectTypes: [Enums.PropertyType.Keyword],
                IsNotALocationTree: true,
                ForceExpand: true,
                MultiSelectionAllowed: true,
                SelectedItemIdentifiers: Utils.CloneArray(this.filters.Keywords),
                CannotCollapseRoot: true,
                ShowResetButton: true,
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Keywords'),
                WindowTitle: i18next.t('ElementPicker.SelectKeywordFilter'),
                OnConfirmSelection: (result) => {
                    this.filters.Keywords = result.SelectedItems;
                    $btn.toggleClass('red', (this.filters.Keywords || []).length > 0);
                    this.onAfterFilterChanged();
                },
                OnResetButtonClick: () => {
                    this.filters.Keywords = null;
                    $btn.removeClass('red');
                    this.onAfterFilterChanged();
                }
            });
        };

        private onBtnTextSearchButtonClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const onOKClick = (text) => {
                this.filters.SearchText = !!text ? text : null;
                $btn.toggleClass('red', !!text);
                this.onAfterFilterChanged();
            };

            Utils.InputWindow.Show(
                i18next.t('IssueReport.FilterByText.InputWindowTitle'), null, {
                    Abort: {},
                    OK: { Fn: onOKClick }
                }, this.filters.SearchText, 'text');
        };

        private onBtnClassificationFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            Utils.ElementPickerPopup.Show({
                RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Classification),
                Items: DAL.Properties.GetByType(Enums.PropertyType.Classification),
                VisibleObjectTypes: [Enums.PropertyType.Classification],
                IsNotALocationTree: true,
                ForceExpand: true,
                MultiSelectionAllowed: true,
                SelectedItemIdentifiers: Utils.CloneArray(this.filters.Classifications),
                CannotCollapseRoot: true,
                ShowResetButton: true,
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Classifications'),
                WindowTitle: i18next.t('ElementPicker.SelectClassificationFilter'),
                OnConfirmSelection: (result) => {
                    this.filters.Classifications = result.SelectedItems;
                    $btn.toggleClass('red', (this.filters.Classifications || []).length > 0);
                    this.onAfterFilterChanged();
                },
                OnResetButtonClick: () => {
                    this.filters.Classifications = null;
                    $btn.removeClass('red');
                    this.onAfterFilterChanged();
                }
            });
        };

        private onBtnPrioritiesFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            Utils.ElementPickerPopup.Show({
                RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Priority),
                Items: DAL.Properties.GetByType(Enums.PropertyType.Priority),
                VisibleObjectTypes: [Enums.PropertyType.Priority],
                IsNotALocationTree: true,
                ShowColor: true,
                ForceExpand: true,
                MultiSelectionAllowed: true,
                SelectedItemIdentifiers: Utils.CloneArray(this.filters.Priorities),
                CannotCollapseRoot: true,
                ShowResetButton: true,
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Priorities'),
                WindowTitle: i18next.t('ElementPicker.SelectPriorityFilter'),
                OnConfirmSelection: (result) => {
                    this.filters.Priorities = result.SelectedItems;
                    $btn.toggleClass('red', (this.filters.Priorities || []).length > 0);
                    this.onAfterFilterChanged();
                },
                OnResetButtonClick: () => {
                    this.filters.Priorities = null;
                    $btn.removeClass('red');
                    this.onAfterFilterChanged();
                }
            });
        };

        private onBtnDeadlineFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const period = this.filters.DeadlinePeriod ?
                Model.DateTime.Period.fromFilterPeriod(this.filters.DeadlinePeriod) :
                null;

            Utils.DateTimePicker.Show({
                ShowTime: false,
                ShowCalendar: true,
                Clearable: true,
                SelectedPeriod: period,
                HeaderText: i18next.t('IssueReport.FilterByDeadline.WindowTitle'),
                EnablePeriodSelection: true,
                SelectedDateTime: null,
                FnSuccess: (period) => {
                    this.filters.DeadlinePeriod = period;
                    $btn.toggleClass('red', Utils.IsSet(this.filters.DeadlinePeriod));
                    this.onAfterFilterChanged();
                }
            });
        };

        private onBtnStateFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            Utils.ElementPickerPopup.Show({
                RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Status),
                Items: DAL.Properties.GetByType(Enums.PropertyType.Status),
                VisibleObjectTypes: [Enums.PropertyType.Status],
                IsNotALocationTree: true,
                ForceExpand: true,
                MultiSelectionAllowed: true,
                SelectedItemIdentifiers: Utils.CloneArray(this.filters.States),
                CannotCollapseRoot: true,
                ShowResetButton: true,
                ShowColor: true,
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Status'),
                WindowTitle: i18next.t('ElementPicker.SelectStatusFilter'),
                FnHideItem: (state?: TreeItem | TreeSourceItem) => {
                    return !(<Model.Properties.Property>state).ClosedState;
                },
                OnConfirmSelection: (result) => {
                    this.filters.States = result.SelectedItems;
                    $btn.toggleClass('red', (this.filters.States || []).length > 0);
                    this.onAfterFilterChanged();
                },
                OnResetButtonClick: () => {
                    this.filters.States = null;
                    $btn.removeClass('red');
                    this.onAfterFilterChanged();
                }
            });
        };

        private onBtnFormFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const userCanSeeAllForms = Utils.UserHasRight(
                Session.User.OID,
                Enums.Rights.SeeAllForms,
                true,
                Session.CurrentLocation
            );

            const showLocationAssignedFormsOnly = Session.Settings.ShowOnlyAssignedForms || !userCanSeeAllForms;
            const options: Model.SelectionWindow.FormOptions = <Model.SelectionWindow.FormOptions>{
                IgnoreIssueCreationRights: true,
                ShowInspections: Session.Client.Licenses.Inspections,
                ShowRegularForms: true,
                ShowLocationAssignedFormsOnly: showLocationAssignedFormsOnly,
                ExitIfNoItemIsAvailable: !userCanSeeAllForms,
                ShowTabControl: false,
                ShowDetails: false,
                ShowTreeFilter: false,
                ShowResetButton: true,
                EnableMultiSelection: true,
                SelectedItems: this.filters.Forms,
                OnAfterItemsSelected: (selectedIdentifiers: string[]) => {
                    this.filters.Forms = selectedIdentifiers;

                    $btn.toggleClass('red', (this.filters.Forms || []).length > 0);

                    this.onAfterFilterChanged();
                }
            };

            if (userCanSeeAllForms) {
                const hoverTitle = showLocationAssignedFormsOnly ?
                    i18next.t('SelectionWindow.Forms.HierarchyFilter.ShowAllForms') :
                    i18next.t('SelectionWindow.Forms.HierarchyFilter.ShowAssignedFormsOnly');

                options.HierarchyFilter = {
                    Icon: 'icon-clipboard',
                    IsActive: showLocationAssignedFormsOnly,
                    HoverTitle: hoverTitle,
                    OnClick: function(evt: Event): void {
                        const $buttons = $(evt.currentTarget).parents('.item-tree').find('.btn-hierarchy-filter');

                        this.showLocationAssignedFormsOnly = !this.showLocationAssignedFormsOnly;

                        const newHoverTitle = this.showLocationAssignedFormsOnly ?
                            i18next.t('SelectionWindow.Forms.HierarchyFilter.ShowAllForms') :
                            i18next.t('SelectionWindow.Forms.HierarchyFilter.ShowAssignedFormsOnly');

                        $buttons
                            .attr('title', newHoverTitle)
                            .toggleClass('is-active', this.showLocationAssignedFormsOnly);

                        this.fillWindow();

                        Session.Settings[Enums.UserSettings.ShowOnlyAssignedForms] = this.showLocationAssignedFormsOnly;

                        Settings.MarkChanged(Enums.UserSettings.ShowOnlyAssignedForms);
                        Settings.Save();
                    }
                }
            }

            const formSelectionWindow = new Utils.FormSelectionWindow(options);
            formSelectionWindow.Show();
        };

        private onBtnSchedulingFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const visibleAndSelectableSchedulings = DAL.Scheduling.GetAllSelectableAndVisibleSchedulings();
            const items = $.map(visibleAndSelectableSchedulings.Schedulings, function(scheduling) {
                return scheduling;
            });

            Utils.ElementPickerPopup.Show({
                RootItem: DAL.Scheduling.GetRoot(),
                Items: items,
                IsNotALocationTree: true,
                ForceExpand: Session.Settings.ExpandTreeInSelectionWindow,
                MultiSelectionAllowed: true,
                SelectedItemIdentifiers: Utils.CloneArray(this.filters.Schedulings),
                CannotCollapseRoot: true,
                ShowResetButton: true,
                ShowColor: true,
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.SchedulingTree'),
                WindowTitle: i18next.t('ElementPicker.SelectSchedulingFilter'),
                FnItemIsDisabled: (scheduling?: TreeItem | TreeSourceItem) => {
                    if (!scheduling) {
                        return true;
                    }

                    return !visibleAndSelectableSchedulings.SelectableSchedulings[(<Model.Scheduling.Scheduling>scheduling).OID];
                },
                FnHideItem: (scheduling?: TreeItem | TreeSourceItem) => {
                    if (!scheduling) {
                        return true;
                    }

                    return !!visibleAndSelectableSchedulings.VisibleSchedulings[(<Model.Scheduling.Scheduling>scheduling).OID];
                },
                OnConfirmSelection: (result) => {
                    this.filters.Schedulings = result.SelectedItems;
                    $btn.toggleClass('red', (this.filters.Schedulings || []).length > 0);
                    this.onAfterFilterChanged();
                },
                OnResetButtonClick: () => {
                    this.filters.Schedulings = null;
                    $btn.removeClass('red');
                    this.onAfterFilterChanged();
                }
            });
        };

        private onBtnContactsFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const onConfirmSelection = (result) => {
                this.filters.Contacts = result && (result.SelectedItems || []).length ?
                    result.SelectedItems :
                    null;

                $btn.toggleClass('red', (this.filters.Contacts || []).length > 0);
                this.onAfterFilterChanged();
            };

            Utils.ElementPickerPopup.Show({
                NodeHeight: 60,
                Items: DAL.Contacts.GetAll(),
                SelectedItemIdentifiers: Utils.CloneArray(this.filters.Contacts),
                RenderAsListView: true,
                HideUnmatchedListViewItems: true,
                IsNotALocationTree: true,
                MultiSelectionAllowed: true,
                ShowResetButton: true,
                ItemTitlePattern: '{Title}<br>{Company}',
                SearchFields: ['Title', 'Company'],
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Contacts'),
                WindowTitle: i18next.t('ElementPicker.SelectContacts'),
                OnConfirmSelection: onConfirmSelection,
                OnResetButtonClick: onConfirmSelection
            });
        };

        private onBtnContactGroupsFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const onConfirmSelection = (result) => {
                this.filters.ContactGroups = result && (result.SelectedItems || []).length ?
                    result.SelectedItems :
                    null;

                $btn.toggleClass('red', (this.filters.ContactGroups || []).length > 0);
                this.onAfterFilterChanged();
            };

            Utils.ElementPickerPopup.Show({
                NodeHeight: 60,
                Items: DAL.ContactGroups.GetAll(),
                SelectedItemIdentifiers: Utils.CloneArray(this.filters.ContactGroups),
                RenderAsListView: true,
                HideUnmatchedListViewItems: true,
                IsNotALocationTree: true,
                MultiSelectionAllowed: true,
                ShowResetButton: true,
                ItemTitlePattern: '{Title}',
                SearchFields: ['Title'],
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.ContactGroups'),
                WindowTitle: i18next.t('ElementPicker.SelectContactGroups'),
                OnConfirmSelection: onConfirmSelection,
                OnResetButtonClick: onConfirmSelection
            });
        };

        private onBtnTypeFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const listItems = [
                {
                    Title: i18next.t('Misc.IssueType.Task'),
                    PropertyValue: Enums.IssueType.Task
                }, {
                    Title: i18next.t('Misc.IssueType.Resubmission'),
                    PropertyValue: Enums.IssueType.Scheduling
                }, {
                    Title: i18next.t('Misc.IssueType.Form'),
                    PropertyValue: Enums.IssueType.Form
                }
            ];

            if (Session.Client.Licenses.Inspections) {
                listItems.push({
                    Title: i18next.t('Misc.IssueType.Inspection'),
                    PropertyValue: Enums.IssueType.Inspection
                });
            }

            listItems.push(
                {
                    Title: i18next.t('Misc.IssueType.Note'),
                    PropertyValue: Enums.IssueType.Note
                },
                {
                    Title: i18next.t('Misc.IssueType.Disturbance'),
                    PropertyValue: Enums.IssueType.Disturbance
                }
            );

            if (Session.Client.Settings.SyncOpenSurveys) {
                listItems.push({
                    Title: i18next.t('Misc.IssueType.Survey'),
                    PropertyValue: Enums.IssueType.Survey
                });
            }

            if (Session.Client.Settings.SyncOpenInvestigations) {
                listItems.push({
                    Title: i18next.t('Misc.IssueType.Investigation'),
                    PropertyValue: Enums.IssueType.Investigation
                });
            }

            const onOKClick = ($selectedItems) => {
                if (($selectedItems || []).length) {
                    this.filters.UserSelectedTypes = $.map($selectedItems, function(button) {
                        return $(button).data('propertyvalue');
                    });
                    $btn.addClass('red');
                } else {
                    this.filters.UserSelectedTypes = null;
                    $btn.removeClass('red');
                }

                this.onAfterFilterChanged();
            };

            Utils.CustomDataPicker.Show(listItems, {
                Title: i18next.t('Misc.IssueType.Text_plural'),
                Width: 300,
                UseSystemProperties: false,
                Callback: onOKClick,
                SelectedProperties: Utils.CloneArray(this.filters.UserSelectedTypes),
                IsMultiSelectionAllowed: true,
                HideResetButton: false
            });
        };

        private onBtnProcessingFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const listItems = [{
                ImgPath: './img/smiley_red.svg',
                PropertyValue: Enums.IssueProcessingStatus.Overdue
            }, {
                ImgPath: './img/smiley_yellow.svg',
                PropertyValue: Enums.IssueProcessingStatus.Warning
            }, {
                ImgPath: './img/smiley_green.svg',
                PropertyValue: Enums.IssueProcessingStatus.OK
            }];

            const onItemClick = function($processingStatus) {
                $processingStatus.toggleClass('selected');
                $processingStatus.siblings('.selected').removeClass('selected');
            };

            const onOKClick = ($selectedItems) => {
                if (($selectedItems || []).length) {
                    this.filters.ProcessingStatus = $.map($selectedItems, function(button) {
                        return $(button).data('propertyvalue');
                    })[0];
                    $btn.addClass('red');
                    this.onAfterFilterChanged();
                } else {
                    this.filters.ProcessingStatus = null;
                    $btn.removeClass('red');
                    this.onAfterFilterChanged();
                }
            };

            Utils.CustomDataPicker.Show(listItems, {
                Title: i18next.t('IssueReport.ProcessingStatus'),
                Width: 300,
                Height: 300,
                UseSystemProperties: false,
                Callback: onOKClick,
                OnItemClick: onItemClick,
                SelectedProperties: [this.filters.ProcessingStatus],
                IsMultiSelectionAllowed: false
            });
        }

        private onBtnHideNonResponsibleIssuesClick(e: Event): void {
            const $btn = $(e.currentTarget);

            this.filters.HideNonResponsibleIssues = !this.filters.HideNonResponsibleIssues;

            if (this.filters.HideNonResponsibleIssues) {
                $btn.addClass('red');
            } else {
                $btn.removeClass('red');
            }

            this.onAfterFilterChanged();
        }
    }
}
