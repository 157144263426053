//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../config.ts"  />
//imports-end

module Utils.Cookies {
    export function GetAll(): Dictionary<any> {
        const cookies = document.cookie.split(';');

        if (!cookies || !cookies.length) {
            return {};
        }

        const result: Dictionary<any> = {};
        for (let cCnt = 0, cLen = cookies.length; cCnt < cLen; cCnt++) {
            const cookie = cookies[cCnt];
            const cookieContent = cookie.split('=');
            const cookieKey = cookieContent[0].trim();
            const cookieValue = unescape(cookieContent[1]);

            result[cookieKey] = cookieValue;
        }

        return result;
    }

    export function Get(key: string, expectedTypeOfValue?: any): any {
        const cookies = document.cookie.split(';');

        if (cookies && cookies.length) {
            let value: any;
            for (let cCnt = 0, cLen = cookies.length; cCnt < cLen; cCnt++) {
                let cookie = cookies[cCnt];
                let cookieContent = cookie.split('=');

                if (cookieContent[0].trim() === key) {
                    value = unescape(cookieContent[1]);
                    break;
                }
            }

            if (!!value && expectedTypeOfValue === 'object') {
                value = JSON.parse(value);
            }

            return value;
        }
    }

    export function Set(key: string, value: any): void {
        let expiryDate = new Date();

        expiryDate.setTime(expiryDate.getTime() + (365 * 24 * 60 * 60 * 1000));

        if ($.isPlainObject(value)) {
            value = JSON.stringify(value);
        }

        document.cookie = `${key}=${escape(value)}; expires=${expiryDate.toGMTString()}`;
    }

    export function Delete(key: string): void {
        document.cookie = `${key}=; expires=${Utils.DateTime.ToGMTString(new Date(0))}`;
    }

    export function GetCSRFToken() {
        const csrf = Session.IsSmartDeviceApplication
            ? GetTokenFromHttpPluginCookie(Session.CookieConfig.CsrfCookieName)
            : Get(Session.CookieConfig.CsrfCookieName);

        return csrf && csrf.length ? csrf : null;
    }

    export function GetActiveSessionUser() {
        const activeSessionUserOID = Get(Session.CookieConfig.SessionUserCookieName);
        return activeSessionUserOID && activeSessionUserOID.length ? activeSessionUserOID : null;
    }

    export function GetTokenFromHttpPluginCookie(tokenName: string): string | null {
        if (!Session.IsSmartDeviceApplication) {
            return null;
        }

        if (!tokenName) {
            return null;
        }

        const tokens = cordova.plugin.http.getCookieString(Session.BaseURI);

        if (!tokens) {
            return null;
        }

        const keyedTokens = tokens.split(';').reduce((map, token) => {
            token = $.trim(token);

            const splitted = token.split('=');

            map[splitted[0].toLowerCase()] = splitted[1];

            return map;
        }, {});

        return keyedTokens[tokenName.toLowerCase()];
    }

    /**
     * Prüft ob der übergebene CSRF-Token noch gültig ist
     * @param csrfToken - Der Token der geprüft werden soll
     * @returns {boolean} - Ist der Token noch gültig
     */
    export function IsCsrfTokenValid(): boolean {
        const csrfToken = Utils.Cookies.GetCSRFToken();

        if (!csrfToken) {
            return false;
        }

        const tokenCreationTimestamp = parseInt(csrfToken.split('.').pop() || '0', 10);

        if (isNaN(tokenCreationTimestamp)) {
            return false;
        }

        // Erstellzeitpunkt des Tokens + Gültigkeit (1/4 wird als Puffer abgezogen)
        return Date.now() < (tokenCreationTimestamp + (Session.CookieConfig.CsrfValidityMinutes * 45)) * 1000;
    }
}
