//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../core/revision.ts"  />
/// <reference path="../files/file-metrics.ts"  />
/// <reference path="./raw-file.ts"  />
//imports-end

module Model.Issues {
    export class File {
        OID?: string;
        Filename: string;
        AlternativeFilename?: string;
        FilePath: string;
        AlternativeFilePath: string;
        Title: string;
        Position: number;
        Description?: string;
        MimeType: Enums.MimeType;
        IsSeen: boolean;
        Metadata: Dictionary<any>;
        Marks?: string;
        Metrics?: Model.Files.FileMetrics;
        IsNewTemporaryFile: boolean;
        IsImage: boolean;
        IsAudio: boolean;
        IsVideo: boolean;

        // internal
        IsTemporary?: boolean;
        UploadDisabled?: boolean;

        constructor(file: RawFile) {
            if (!file) {
                return;
            }

            this.initProperties(file);
        }

        initProperties(file: RawFile): void {
            this.OID = file.OID;
            this.Filename = file.Filename;
            this.FilePath = this.getFilePath(this.Filename);
            this.Title = file.Title || file.Filename;
            this.Position = file.Position;
            this.Description = file.Description;
            this.MimeType = file.MimeType;
            this.IsSeen = file.IsSeen;
            this.Metadata = file.Metadata;
            this.Marks = file.Marks;
            this.IsNewTemporaryFile = file.IsNewTemporaryFile;
            this.IsTemporary = file.IsTemporary;

            if (Utils.IsImage(this.MimeType)) {
                this.IsImage = true;
            } else if (Utils.IsAudio(this.MimeType)) {
                this.IsAudio = true;
            }

            if (this.IsImage) {
                if (this.Metadata) {
                    this.Metrics = this.getMetrics();
                }
            } else if (this.IsAudio &&
                this.Metadata &&
                (this.Metadata.AlternativeMimeTypes || []).length &&
                this.Metadata.AlternativeMimeTypes[0] === 'audio/ogg') {
                this.AlternativeFilename = this.Filename.replace(Utils.GetFileExtension(this.Filename), '.ogg');
                this.AlternativeFilePath = this.getFilePath(this.AlternativeFilename);
            }
        }

        private getMetrics(): Model.Files.FileMetrics {
            const metrics = new Model.Files.FileMetrics();

            if (!!this.Metadata.SizeS) {
                const tmp = this.Metadata.SizeS.split('x');
                const height = parseInt(tmp[1], 10);
                const width = parseInt(tmp[0], 10);

                metrics.SetSizeS(new Model.Files.FileDimensions(height, width));
            }

            if (!!this.Metadata.SizeM) {
                const tmp = this.Metadata.SizeM.split('x');
                const height = parseInt(tmp[1], 10);
                const width = parseInt(tmp[0], 10);

                metrics.SetSizeM(new Model.Files.FileDimensions(height, width));
            }

            if (!!this.Metadata.SizeL) {
                const tmp = this.Metadata.SizeL.split('x');
                const height = parseInt(tmp[1], 10);
                const width = parseInt(tmp[0], 10);

                metrics.SetSizeL(new Model.Files.FileDimensions(height, width));
            }

            if (!!this.Metadata.SizeO) {
                const tmp = this.Metadata.SizeO.split('x');
                const height = parseInt(tmp[1], 10);
                const width = parseInt(tmp[0], 10);

                metrics.SetSizeO(new Model.Files.FileDimensions(height, width));
            }

            return metrics;
        }

        private getFilePath(filename: string): string {
            if (!Session.IsSmartDeviceApplication) {
                return `${Session.BaseURI}images/s/${filename}`;
            }

            return Utils.GetResourcesPath() + filename;
        }
    }
}
