//imports-start
/// <reference path="../../model/nfc/tag.ts"/>
/// <reference path="./decoder.ts" />
/// <reference path="./nhs3100-decoder.ts" />
/// <reference path="./nav-tag-decoder.ts" />
//imports-end

module Utils.Nfc {
    export class DecoderFactory {
        private readonly Tag: Model.Nfc.Tag;
        private readonly Decoder: Utils.Nfc.Decoder;

        constructor(tag: Model.Nfc.Tag) {
            this.Tag = tag;
            this.Decoder = new Utils.Nfc.Decoder(tag);
        }

        create(): Utils.Nfc.Nhs3100Decoder | NavTagDecoder | null {
            if (this.isNavigationTag()) {
                return new NavTagDecoder(this.Tag);
            }

            if (this.isNhs3100Tag()) {
                return new Nhs3100Decoder(this.Tag);
            }

            return null;
        }

        private isNavigationTag(): boolean {
            const message = this.Decoder.GetDecodedMessage();

            return message.length === 1 && message[0].startsWith('aw_loc_');
        }

        private isNhs3100Tag(): boolean {
            const message = this.Decoder.GetDecodedMessage();

            return message.length === 3 && message[1].contains('Current temperature');
        }
    }
}