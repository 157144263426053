///<reference path="i-workflow.ts"/>
///<reference path="issue-workflow-base.ts"/>
///<reference path="workflow-settings.ts"/>
///<reference path="../../../enums.ts"/>

module New.Checkpoints.Workflow {
    export class ModifyParentIssueWorkflow extends IssueWorkflowBase {
        constructor(workflow: IWorkflow, workflowSettings: IssueWorkflowSettings) {
            super(workflow, workflowSettings);
        }

        public Execute(): Deferred {
            const deferred = $.Deferred();
            const response = new Response.ModifyParentIssueWorkflow(this);

            if (!(this.ParentIssueModifications || []).length) {
                Utils.Toaster.Show(
                    i18next.t('CorrectiveActions.NoWorkflowStepsDefined'),
                    2,
                    Enums.Toaster.Icon.Warning
                );

                return deferred.resolve(response).promise();
            }

            if (!this.canUserModifyTheParentIssue()) {
                Utils.Toaster.Show(
                    i18next.t('Misc.RightError.ModifyParentIssueWorkflowNotAllowed.MessageBody'),
                    2,
                    Enums.Toaster.Icon.Warning
                );

                return deferred.reject(response).promise();
            }

            let parentIssueModified = false;

            this.ParentIssueModifications
                .sort(Utils.SortByPosition)
                .forEach(definition => {
                    switch (definition.Property) {
                        case 'Keywords':
                            if (this.modifySimpleArrayProperty(Enums.Rights.IssueProperties_Keywords, definition)) {
                                parentIssueModified = true;
                            }
                            break;
                    }
                });

            if (!parentIssueModified) {
                return deferred.resolve(response).promise();
            }

            this.ParentIssue.ModificationTimestamp = new Date();
            this.ParentIssue.EditorOID = Session.User.OID;
            this.ParentIssue.Revision += 1;
            this.ParentIssue.PrecedingOID = this.ParentIssue.OID;
            this.ParentIssue.OID = uuid();

            const issueObject = new Model.TemporaryIssue(
                this.ParentIssue,
                null,
                ParameterList.GetDependingOIDOfIssue(this.ParentIssue)
            );

            issueObject
                .Save()
                .then((issue?: Model.Issues.RawIssue) => {
                    setTimeout(() => {
                        if (!issue) {
                            deferred.resolve(response);
                            return;
                        }

                        response.SetIssue(new Model.Issues.Issue(issue));
                        deferred.resolve(response);
                    }, 5);
                });

            return deferred.promise();
        }

        private modifySimpleArrayProperty(right: Enums.Rights, definition: Model.Elements.ParentIssueModificationDefinition): boolean {
            if (!definition) {
                return false;
            }

            if (!Utils.UserHasIssueRight(this.ParentIssue, right)) {
                Utils.Toaster.Show(
                    i18next.t('Misc.RightError.ModifyParentIssuePropertyNotAllowed.MessageBody'),
                    2,
                    Enums.Toaster.Icon.Warning
                );

                return false;
            }

            this.ParentIssue[definition.Property] = this.ParentIssue[definition.Property] || [];

            let arrayModified = false;

            switch (definition.Type) {
                case Enums.ParentIssueModificationDefinitionType.Add:
                    arrayModified = this.addEntitiesToArray(this.ParentIssue[definition.Property], definition.NewEntities);
                    break;
                case Enums.ParentIssueModificationDefinitionType.Replace:
                    if (this.removeEntitiesFromArray(this.ParentIssue[definition.Property], definition.PreviousEntities)) {
                        arrayModified = true;
                    }

                    if (this.addEntitiesToArray(this.ParentIssue[definition.Property], definition.NewEntities)) {
                        arrayModified = true;
                    }
                    break;
                case Enums.ParentIssueModificationDefinitionType.ReplaceAll:
                    if (!Utils.CompareArrays(this.ParentIssue[definition.Property], definition.NewEntities)) {
                        arrayModified = true;
                        this.ParentIssue[definition.Property] = Utils.CloneArray(definition.NewEntities);
                    }
                    break;
                case Enums.ParentIssueModificationDefinitionType.Remove:
                    arrayModified = this.removeEntitiesFromArray(this.ParentIssue[definition.Property], definition.PreviousEntities);
                    break;
            }

            return arrayModified;
        }

        private addEntitiesToArray(array: Array<any>, entities: Array<any>): boolean {
            let arrayModified = false;

            if (!(array instanceof Array)) {
                return arrayModified;
            }

            if (!(entities || []).length) {
                return arrayModified;
            }

            entities.forEach(entity => {
                const idx = array.indexOf(entity);

                if (idx === -1) {
                    arrayModified = true;
                    array.push(entity);
                }
            });

            return arrayModified;
        }

        private removeEntitiesFromArray(array: Array<any>, entities: Array<any>): boolean {
            let arrayModified = false;

            if (!(array instanceof Array)) {
                return arrayModified;
            }

            if (!(entities || []).length) {
                return arrayModified;
            }

            entities.forEach(entity => {
                const idx = array.indexOf(entity);

                if (idx > -1) {
                    arrayModified = true;
                    array.splice(idx, 1);
                }
            });

            return arrayModified;
        }
    }
}
