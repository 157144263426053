//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../utils/utils.spinner.ts"  />
//// <reference path="./app.session.ts"  />
//// <reference path="./app.individual-data.ts"  />
//// <reference path="../enums.ts"  />
//// <reference path="./app.parameter-list.ts"  />
/// <reference path="./app.ts"  />
/// <reference path="../dal/elements.ts"  />
//// <reference path="../model/model.clearable-input.ts"  />
/// <reference path="../model/model.sync-center.ts"  />
/// <reference path="../utils/utils.support-mail.ts"  />
//// <reference path="../utils/utils.password-editor.ts"  />
/// <reference path="../utils/utils.support-mail.ts"  />
//imports-end

module SyncCenter {
    enum SyncCenterView {
        MAIN = 'main',
        RESPONSE_TIMESTAMPS = 'response_timestamps'
    }

    let $content;
    let $btnSynchronisation;
    let $fileDownloadInfo;
    let $filesRemainingInfo;
    let $footer;

    let editModeIsActive: boolean = false;
    let isSyncing: boolean;
    let syncStartTimestamp: Date;
    let reloadTriggered: boolean;
    let treeStructureHasChanged: boolean;
    let reloadIssuesFromServer: boolean;
    let preventSyncDownload: boolean;

    let view: SyncCenterView;
    let lastKnownHttpError: Model.Synchronisation.HttpError;

    let hasDataBeenSynchronised: boolean;

    let autoSyncTimer: number;
    let lastAutoSyncTimestamp: Date;

    function onSyncFinishedSuccesfully(): void {
        hasDataBeenSynchronised = true;
        lastKnownHttpError = null;
        ParameterList.CorrectiveActionsDictByOID = {};

        showError(lastKnownHttpError);

        if (Session.Mode === Enums.Mode.SyncCenter &&
            view === SyncCenterView.MAIN) {
            $content.find('.unsynced-entities')
                .removeClass('with-type-icons');

            $content.find('.btn[data-action="start-sync"]')
                .removeClass('btn-danger')
                .addClass('btn-success');
        }

        if (View.CurrentView === Enums.View.Main) {
            if (!Session.CurrentLocation ||
                !DAL.Elements.Exists(Session.CurrentLocation.OID) ||
                !DAL.Elements.IsChildOfPersonalRoot(Session.CurrentLocation)) {
                Session.CurrentLocation = DAL.Elements.Root;
                Utils.Router.ReplaceState('#location/' + Session.CurrentLocation.OID + '/menu', true);
            } else {
                Session.CurrentLocation = DAL.Elements.GetByOID(Session.CurrentLocation.OID);
            }
        } else if (View.CurrentView !== Enums.View.IndividualData) {
            Session.CurrentLocation = !Session.CurrentLocation ||
                !DAL.Elements.Exists(Session.CurrentLocation.OID) ||
                !DAL.Elements.IsChildOfPersonalRoot(Session.CurrentLocation) ?
                DAL.Elements.Root :
                DAL.Elements.GetByOID(Session.CurrentLocation.OID);
        }

        if (Session.CurrentLocation && View.CurrentView !== Enums.View.IndividualData) {
            App.UpdateContentHeaderIssueProcessingIndicator();
        }

        DAL.Elements.PreparePrioritizedFiles();

        if (Session.IsSmartDeviceApplication) {
            App.SetBluetoothNavbar();
            App.SetScaleNavbar();
        }

        // set i18next language
        i18next.changeLanguage(Session.User.Language, function() {
            App.LabelPage();
            // set moment.js locale for format
            moment.locale(Session.User.Language);

            if (Utils.InArray([Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
                IssueView.Update();
            } else if (Session.Mode !== Enums.Mode.SyncCenter) {
                if (View.CurrentView === Enums.View.Main) {
                    App.CreateContentHeader();
                }

                reloadTriggered = true;

                Utils.Router.RefreshState();
            } else if (view === SyncCenterView.MAIN) {
                SyncCenter.Show();
            } else if (view === SyncCenterView.RESPONSE_TIMESTAMPS) {
                SyncCenter.ShowResponseTimestamps();
            }

            if (Utils.Synchronisation.Download.GetWhetherToIssueCommandToUpdateTree()) {
                App.UpdateTree();
            } else {
                App.UpdateTreeItems();
            }

            Utils.Spinner.Hide();
        });
    }

    export function OnSyncError(
        httpStatusCode: Enums.HttpStatusCode | Error,
        rejectedRequest?: { type: Enums.HttpMethod },
        syncEntity?: Model.Synchronisation.IEntityDescription
    ): void {
        const error: Model.Errors.HttpError | Error = httpStatusCode instanceof Error ? httpStatusCode : null;
        let requestType: Enums.HttpMethod = rejectedRequest ? rejectedRequest.type : null;

        if (error && (<Model.Errors.HttpError>error).httpResponse) {
            const response = (<Model.Errors.HttpError>error).httpResponse;
            httpStatusCode = response.status;
            if (response.type) {
                requestType = <Enums.HttpMethod>response.type.toUpperCase();
            }
        }

        View.EnableLogoutButton();

        if (typeof httpStatusCode !== 'undefined' && httpStatusCode !== null) {
            lastKnownHttpError = new Model.Synchronisation.HttpError(requestType,
                <number>httpStatusCode,
                error ? error.message : null,
                syncEntity ? syncEntity.Type : null,
                syncEntity ? syncEntity.OID : null);

            // Fehler in der Datenbank merken
            SaveError(error || lastKnownHttpError);
        }

        View.SetSyncIconClass('error');

        if (View.CurrentView === Enums.View.SyncCenter && view === SyncCenterView.MAIN) {
            showError(lastKnownHttpError);

            $content.find('.file-download-info').remove();
        }

        if (syncEntity && !!syncEntity.OID) {
            window.Database.GetSingleByKey(Enums.DatabaseStorage.SyncEntities, syncEntity.OID)
                .then(function(entity: Model.Synchronisation.IEntityDescription) {
                    if (!entity) {
                        return;
                    }

                    entity.IsErroneous = true;
                    window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, entity);
                })
                .then(function() {
                    View.SetSyncIconClass('error');
                });

            SyncCenter.OnAfterEntityUploaded(syncEntity.OID, false);
        }

        SyncCenter.RemoveFileDownloadInfos(true);

        if (View.CurrentView === Enums.View.SyncCenter &&
            view === SyncCenterView.MAIN &&
            $btnSynchronisation) {
            $btnSynchronisation.removeAttr('disabled');
        }

        Utils.Spinner.Hide();
    }

    export function SaveError(error: Error | Model.Synchronisation.HttpError): Deferred {
        if (!error) {
            return $.Deferred().reject('ArgumentError');
        }

        // Kopie der Fehlermeldung erstellen zur weiterverarbeitung
        const copy = $.extend(true, {}, error);

        // Eindeutigen Identifier für Datenbank hinzufügen
        copy.OID = uuid();

        // bestehenden Timestamp zu ISO-Format umwandeln
        if (copy.Timestamp) {
            copy.Timestamp = Utils.DateTime.ToISOString(new Date(copy.Timestamp));
        }

        // aktuelle Zeit als Timestamp setzen, wenn keiner vorhanden
        if (!Utils.DateTime.IsValid(copy.Timestamp)) {
            copy.Timestamp = Utils.DateTime.ToISOString(new Date());
        }

        // Zur Bereinigung der Fehlermeldungen, Anzahl bestehender Einträge prüfen
        return window.Database.GetCount(Enums.DatabaseStorage.ErrorLog)
            .then((count: number) => {
                if (count > 150) {
                    // bei mehr als 150 Einträgen alle Error Logs laden für Bereinigung
                    return window.Database.GetAllFromStorage(Enums.DatabaseStorage.ErrorLog);
                }

                // bei weniger als 150 Einträgen keine Bereinigung durchführen
            })
            .then((logEntities: Array<{ Timestamp: string }>) => {
                if (!logEntities || !logEntities.length) {
                    // Keine Bereinigung durchführen, nur die neue Meldung speichern
                    return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.ErrorLog, copy);
                }

                // letzten 10 Einträge (nach Datum) beibehalten,
                // da diese relevante für neue Meldung relevante Daten enthalten könnte
                logEntities.sort((a, b) => b.Timestamp.localeCompare(a.Timestamp, undefined, { sensitivity: 'accent' }));
                logEntities.splice(10);

                // letzte Meldung hinzufügen
                logEntities.push(copy);

                // Alle Fehlermeldungen aus der Datenbank entfernen
                return window.Database.ClearStorage(Enums.DatabaseStorage.ErrorLog)
                    // die Letzten 10 und die neueste Fehlermeldung in Datenbank einfügen
                    .then(() => window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.ErrorLog, logEntities));
            });
    }

    export function GetStatusCodeMessage(httpStatusCode: number | Enums.HttpStatusCode, originalMessage?: string) {
        switch (httpStatusCode) {
            case Enums.HttpStatusCode.Forbidden:
                return i18next.t('SyncCenter.Error.Forbidden');
            case Enums.HttpStatusCode.Unauthorized:
                return i18next.t('SyncCenter.Error.Unauthorized');
            case Enums.HttpStatusCode.Internal_Server_Error:
                return i18next.t('SyncCenter.Error.InternalServerError');
            case Enums.HttpStatusCode.Not_Found:
                return i18next.t('SyncCenter.Error.NotFound');

            case 0:
                // non-standard statusCode = 0 -> no internet connection
                return i18next.t('SyncCenter.Error.PoorInternetConnection');

            // cordova spezifische http-Plugin Statuscodes (cordova.plugin.http.ErrorCode) werden nur in der App verwendet,
            // da onSyncError gewöhnlich nicht in der Weberfassung aufgerufen werden sollte
            case cordova.plugin.http.ErrorCode.GENERIC:
                return i18next.t('SyncCenter.Error.GenericConnectionError');
            case cordova.plugin.http.ErrorCode.SSL_EXCEPTION:
                return `${i18next.t('SyncCenter.Error.SslException')} (${originalMessage})`;
            case cordova.plugin.http.ErrorCode.SERVER_NOT_FOUND:
                return i18next.t('SyncCenter.Error.ServerNotFound');
            case cordova.plugin.http.ErrorCode.TIMEOUT:
                return i18next.t('SyncCenter.Error.Timeout');
            case cordova.plugin.http.ErrorCode.UNSUPPORTED_URL:
                return i18next.t('SyncCenter.Error.UnsupportedUrl');
            case cordova.plugin.http.ErrorCode.NOT_CONNECTED:
                return i18next.t('SyncCenter.Error.NotConnected');
            case cordova.plugin.http.ErrorCode.POST_PROCESSING_FAILED:
                return i18next.t('SyncCenter.Error.PostProcessingFailed');
            case cordova.plugin.http.ErrorCode.ABORTED:
                return i18next.t('SyncCenter.Error.Aborted');
            default:
                return originalMessage || null;
        }
    }

    function showError(error: Model.Synchronisation.HttpError) {
        if (View.CurrentView !== Enums.View.SyncCenter || view !== SyncCenterView.MAIN) {
            return;
        }

        if (!error) {
            $content.find('.regular-content').css('padding-bottom', '');
            $content.find('.http-error').addClass('hidden');
            return;
        }

        const requestType = error.HttpMethod;
        const httpStatusCode = error.StatusCode;
        const message = error.Message;
        let errorText = "";

        if (requestType != null) {
            errorText += `${requestType} - `;
        }

        errorText += `${i18next.t('SyncCenter.Error.HttpStatusCode')}: ${httpStatusCode}`;

        switch (httpStatusCode) {
            case Enums.HttpStatusCode.Unauthorized:
                SetIsSyncing(false);
                View.SetSyncIconClass('default');

                Utils.Message.Show(i18next.t('Login.LoginFailed.MessageHeader'),
                    i18next.t('Login.LoginFailed.UnauthorizedMessageBodySynchronization'),
                    {
                        Close: true,
                        No: {
                            Fn: () => {
                                Utils.CredentialEditor.Show();
                            },
                            Caption: i18next.t('Login.Login')
                        }
                    });
                break;

            case Enums.HttpStatusCode.Forbidden:
                SetIsSyncing(false);

                if (Utils.Message.IsVisible()) {
                    Utils.Message.Hide();
                }

                Utils.Message.Show(
                    i18next.t('SyncCenter.SynchronisationNotAllowed.Title'),
                    i18next.t('SyncCenter.SynchronisationNotAllowed.Description'),
                    {
                        Close: true,
                        SendEMail: function() {
                            Utils.SupportMail.Show();
                        }
                    }
                );
                break;

            default:
                errorText += ` - ${GetStatusCodeMessage(httpStatusCode, message)}`;
        }

        const errorUrl = error.EntityType != null ? `${Model.Synchronisation.GetTypeString(error.EntityType)} - ${error.EntityOID || ''}` : '';

        $content.find('.regular-content').css('padding-bottom', 50);
        $content.find('.http-error').removeClass('hidden')
            .find('.error-text').text(errorText)
            .parent()
            .find('.url').text(errorUrl);
    }

    function onBtnEditSyncEntitiesClick(): void {
        editModeIsActive = !editModeIsActive;

        SyncCenter.Show();
    }

    function onBtnCreateSupportMailClick(): void {
        Utils.SupportMail.Show(lastKnownHttpError);
    }

    function onAfterSyncEntitiesLoaded(syncEntities: Array<Model.Synchronisation.IEntityDescription>): Deferred {
        const preparedSyncEntities: Model.Synchronisation.IEntityDescription[] = [];
        if ((syncEntities || []).length) {
            for (let seCnt = 0, seLen = syncEntities.length; seCnt < seLen; seCnt++) {
                const prepEntity = prepareEntityInformation(syncEntities[seCnt]);
                if (prepEntity) {
                    preparedSyncEntities.push(prepEntity);
                }
            }

            syncEntities = null;
        }

        const viewCreatedDeferred = $.Deferred();

        if (preparedSyncEntities.length) {
            new Model.SyncCenter.EntityCollection(preparedSyncEntities, { EditModeIsActive: editModeIsActive }, function(entityCollection: Model.SyncCenter.EntityCollection) {/* jshint ignore:line */
                $content.html(Templates.SyncCenter.UnsyncedData({
                    EditModeIsActive: editModeIsActive,
                    EntityMarkup: entityCollection.GetMarkup(),
                    SynchronisationInProgress: isSyncing || Utils.Synchronisation.Download.GetIsDownloadingFiles()
                }));

                entityCollection.BindEventsTo($content);

                viewCreatedDeferred.resolve($content);
            });
        } else {
            $content.html(Templates.SyncCenter.UnsyncedData({
                EditModeIsActive: editModeIsActive,
                EntityMarkup: null,
                SynchronisationInProgress: isSyncing || Utils.Synchronisation.Download.GetIsDownloadingFiles()
            }));

            viewCreatedDeferred.resolve($content);
        }

        return viewCreatedDeferred
            .then(($content) => {
                $btnSynchronisation = $content.find('.btn[data-action="start-sync"]');

                unbindSyncEvents();
                bindSyncEvents();

                Utils.Spinner.Hide();

                showError(lastKnownHttpError);
            })
    }

    function onAfterSynchronisationInformationLoaded(information: { [type: string]: Model.Synchronisation.ResponseInformation }): void {
        const responseInformationCollection = new Model.SyncCenter.ResponseInformationCollection(information);
        const markup = responseInformationCollection.GetMarkup();

        $content.html(Templates.SyncCenter.ResponseInformation({
            Markup: markup,
            DisableResetButton: isSyncing || Utils.Synchronisation.Download.GetIsDownloadingFiles() || !markup
        }));

        unbindResponseViewEvents();
        bindResponseViewEvents();

        Utils.Spinner.Hide();
    }

    export function StartSynchronisation(preventQuestion?: boolean): void {
        if (isSyncing ||
            $(this).attr('disabled') === 'disabled') {
            return;
        }

        Utils.CheckIfDeviceIsOnline()
            .then(function() {
                if (!preventQuestion && Session.Settings.AskBeforeSynchronisation) {
                    Utils.Message.Show(
                        i18next.t('Synchronization.Confirmation.MessageHeader'),
                        i18next.t('Synchronization.Confirmation.MessageBody'),
                        { Yes: triggerSynchronization, No: true });
                } else {
                    triggerSynchronization();
                }
            }, function() {
                Utils.Message.Show(i18next.t('Synchronization.NoInternetConnection.MessageHeader'),
                    i18next.t('Misc.NoInternetConnection.MessageBody'), {
                        Close: true
                    });
            });
    }

    function onAfterResetResponseTimestamps(): void {
        /*
            KEEP! will be required, if "reset response times" is implemented again
        */
        $content
            .html(Templates.SyncCenter.ResponseInformation({
                DisableResetButton: true
            }));

        StartSynchronisation(true);
    }

    function triggerSynchronization(): void {
        if (isSyncing) {
            return;
        }

        lastAutoSyncTimestamp = new Date(new Date().setMilliseconds(0));
        treeStructureHasChanged = false;
        reloadIssuesFromServer = false;
        preventSyncDownload = false;

        View.DisableLogoutButton();
        SetIsSyncing(true);
        View.SetSyncIconClass('default animated');

        Session.RefreshServerSession()
            .then(() => {
                Utils.Synchronisation.Download.UpdateAccount()
                    .then(function(accountUpdateInfo: { TreeStructureChanged: boolean, ReloadIssuesFromServer: boolean, PreventDownload: boolean }) {
                        treeStructureHasChanged = accountUpdateInfo.TreeStructureChanged;
                        reloadIssuesFromServer = accountUpdateInfo.ReloadIssuesFromServer;
                        preventSyncDownload = accountUpdateInfo.PreventDownload;

                        Session.SaveSystemData(true);
                        startSynchronization();
                    }).fail(function(xhr, status, errorText, rejectedRequest) {
                        SetIsSyncing(false);

                        // status = -1 => Update abbrechen wegen aktivem Änderungsmodus
                        if (xhr.status !== -1) {
                            View.SetSyncIconClass('error');
                            OnSyncError(xhr instanceof Error ? xhr : xhr.status, rejectedRequest);
                        } else {
                            View.SetSyncIconClass('default');
                            View.EnableLogoutButton();
                        }
                    });
            }, (xhr, status, errorText, rejectedRequest) => {
                if (xhr.status !== -1) {
                    View.SetSyncIconClass('error');
                    OnSyncError(xhr.status ? xhr.status : xhr.httpResponse.status, rejectedRequest);
                } else {
                    View.SetSyncIconClass('default');
                    View.EnableLogoutButton();
                }
            });
    }

    function startSynchronization(): void {
        $fileDownloadInfo = $(Templates.SyncCenter.FileDownloadInfo({
            CurrentFileIndex: 0,
            NumberOfFiles: 0,
            Percentage: 0,
            FormattedPercentage: 0
        }));

        RemoveFilesRemainingInfo();

        if (View.CurrentView === Enums.View.SyncCenter) {
            $content.find('.unsynced-entities').before($fileDownloadInfo);

            if ($btnSynchronisation) {
                $btnSynchronisation.text(i18next.t('SyncCenter.SynchronisationInProgress')).attr('disabled', 'disabled');
            }

            $content.find('.btn[data-action="edit-sync-entities"]').addClass('hidden');
        }

        reloadTriggered = false;

        DAL.Sync.Start(onSyncFinishedSuccesfully, OnSyncError, false, true, true);
    }

    function prepareEntityInformation(entity: Model.Synchronisation.IEntityDescription): Model.Synchronisation.IEntityDescription {
        if (typeof entity.Timestamp === 'string') {
            entity.Timestamp = <any>new Date(entity.Timestamp);
        }

        return Model.Synchronisation.BuildEntityDescription(entity);
    }

    function unbindSyncEvents(): void {
        $content.find('button[data-action="start-sync"]').off('click');
        $content.find('button[data-action="create-support-mail"]').off('click');
        //$content.find('button[data-action="reset-response-timestamps"]').off('click');
        $content.find('button[data-action="reset-issue-cache"]').off('click');
    }

    function bindSyncEvents(): void {
        $btnSynchronisation.on('click', StartSynchronisation);
        $content.find('.btn[data-action="edit-sync-entities"]').on('click', onBtnEditSyncEntitiesClick);
        $content.find('.btn[data-action="create-support-mail"]').on('click', onBtnCreateSupportMailClick);
    }

    function unbindResponseViewEvents(): void {
        //$content.find('button[data-action="reset-response-timestamps"]').off('click');
        $content.find('button[data-action="reset-issue-cache"]').off('click');
    }

    function bindResponseViewEvents(): void {
        /*$content.find('button[data-action="reset-response-timestamps"]').on('click', function() {
            if ($(this).hasClass('disabled')) {
                return;
            }

            Utils.ResetResponseTimestamps()
                .then(Utils.Spinner.Show)
                .then($.proxy(window.Database.ClearStoragesForCleanSync, window.Database))
                .then(DAL.Clear)
                .then(onAfterResetResponseTimestamps);
        });*/

        $content.find('button[data-action="reset-issue-cache"]').on('click', function() {
            if ($(this).hasClass('disabled')) {
                return;
            }

            if (!Session.IsSmartDeviceApplication) {
                console.warn('Wrong use of ResetResponseTimestamps.')
                return;
            }

            Utils.Spinner.Show();
            ResetReducedIssues()
                .always(() => {
                    Utils.Spinner.Hide();
                });
        });
    }

    export function ResetReducedIssues(updateStore: boolean = true): Deferred {
        return window.Database.ClearStorage(Enums.DatabaseStorage.ReducedIssues)
            .then(() => window.Database.GetAllFromStorage(Enums.DatabaseStorage.Issues))
            .then((issues: Model.Issues.RawIssue[]) => DAL.Issues.GenerateReducedIssues(issues, true))
            .then(function(reducedIssues: Array<any>) {
                if (updateStore) {
                    DAL.Issues.Clear();
                    DAL.Issues.Store(reducedIssues);
                }
                return reducedIssues;
            });
    }

    export function Show(): void {
        Utils.SetMode(Enums.Mode.SyncCenter);
        View.SetView(Enums.View.SyncCenter);

        $content = Utils.GetContentContainer();
        $footer = $('#content').siblings('.footer-toolbar');

        if (!isSyncing) {
            $fileDownloadInfo = null;

            window.Database.GetCount(Enums.DatabaseStorage.MissingFiles)
                .then(UpdateFilesRemainingInfo);
        }

        Utils.Spinner.Show();

        view = SyncCenterView.MAIN;

        $footer.find('.show-unsynced-entities').addClass('active').siblings('.active').removeClass('active');

        window.Database.GetAllFromStorage(Enums.DatabaseStorage.SyncEntities)
            .then(onAfterSyncEntitiesLoaded);
    }

    export function ShowResponseTimestamps(): void {
        Utils.SetMode(Enums.Mode.SyncCenter);
        View.Open(Enums.View.SyncCenter);

        Utils.Spinner.Show();

        view = SyncCenterView.RESPONSE_TIMESTAMPS;

        $footer.find('.show-response-timestamps').addClass('active').siblings('.active').removeClass('active');

        Login.LoadSynchronisationInformation(false)
            .then($.proxy(onAfterSynchronisationInformationLoaded, this));
    }

    export function HasDataBeenSynchronised(): boolean {
        return hasDataBeenSynchronised;
    }

    export function ResetState(): void {
        editModeIsActive = false;
        hasDataBeenSynchronised = false;
        reloadTriggered = false;
    }

    export function UpdateFileDownloadInfo(currentFileIndex: number, numberOfFiles: number): void {
        if (View.CurrentView !== Enums.View.SyncCenter || view !== SyncCenterView.MAIN || !$('div.sync-center:visible').length) {
            return;
        }

        const currentProgress = currentFileIndex > 0 ? (currentFileIndex / numberOfFiles * 100) : 0;

        if (!$fileDownloadInfo || !$fileDownloadInfo.is(':visible')) {
            $fileDownloadInfo = $(Templates.SyncCenter.FileDownloadInfo({
                CurrentFileIndex: 0,
                NumberOfFiles: 0,
                Percentage: 0,
                FormattedPercentage: 0
            }));

            $content.find('.unsynced-entities').before($fileDownloadInfo);
        }

        $fileDownloadInfo.find('.progress-bar')
            .css('width', currentProgress + '%')
            .text(currentProgress.toFixed(1) + '%');
    }

    export function RemoveFileDownloadInfos(dontChangeSyncIcon?: boolean, refreshState?: boolean): Deferred {
        if (View.CurrentView === Enums.View.SyncCenter && view === SyncCenterView.MAIN) {
            $content.find('button[data-action="start-sync"]').removeClass('disabled').text(i18next.t('SyncCenter.StartSync'));
            $content.find('.file-download-info').remove();
        }

        let resultDeferred: Deferred;
        if (!dontChangeSyncIcon &&
            (!View.HasSyncIconClass('error') || View.HasSyncIconClass('animated'))) {
            resultDeferred = $.when(window.Database.GetCount(Enums.DatabaseStorage.SyncEntities),
                window.Database.GetCount(Enums.DatabaseStorage.MissingFiles))
                .then(function(syncEntities, missingFiles) {
                    View.SetSyncIconClass((syncEntities[0] || missingFiles[0]) ? 'default' : 'success');

                    // show missing files
                    UpdateFilesRemainingInfo(missingFiles[0]);
                });
        } else {
            resultDeferred = window.Database.GetCount(Enums.DatabaseStorage.MissingFiles)
                .then(UpdateFilesRemainingInfo);
        }

        SetIsSyncing(false);
        $fileDownloadInfo = null;

        View.EnableLogoutButton();

        if (View.CurrentView === Enums.View.SyncCenter) {
            if ($btnSynchronisation instanceof $) {
                $btnSynchronisation.removeAttr('disabled');
            }

            $content.find('.btn[data-action="edit-sync-entities"]').removeClass('hidden');
        } else if (!reloadTriggered && refreshState !== false) {
            Utils.Router.RefreshState();
        }

        reloadTriggered = false;

        return resultDeferred;
    }

    export function UpdateFilesRemainingInfo(missingFiles: number): void {
        if (View.CurrentView !== Enums.View.SyncCenter || view !== SyncCenterView.MAIN || !$('div.sync-center:visible').length) {
            return;
        }

        if (!missingFiles) {
            RemoveFilesRemainingInfo();
            return;
        }

        if (!$filesRemainingInfo || !$filesRemainingInfo.is(':visible')) {
            $filesRemainingInfo = $(Templates.SyncCenter.FilesRemainingInfo({
                MissingFiles: missingFiles
            }));

            $content.find('.unsynced-entities').before($filesRemainingInfo);
        }
    }

    export function RemoveFilesRemainingInfo(): void {
        if (View.CurrentView === Enums.View.SyncCenter && view === SyncCenterView.MAIN) {
            $content.find('.files-remaining-info').remove();
            $filesRemainingInfo = null;
        }
    }

    export function OnAfterEntityUploaded(oid: string, hasBeenSuccessfully: boolean): void {
        if (View.CurrentView === Enums.View.SyncCenter && view === SyncCenterView.MAIN) {
            if (hasBeenSuccessfully) {
                $content.find(`li[data-oid="${oid}"]`)
                    .slideUp(200, function() {
                        this.remove();
                    });
            } else {
                $content.find(`li[data-oid="${oid}"]`).toggleClass('upload-error', !hasBeenSuccessfully);
            }
        }
    }

    export function GetIsSynchronisationInProgress(): boolean {
        return isSyncing || Utils.Synchronisation.Download.GetIsDownloadingFiles();
    }

    export function GetSynchronisationStartTimestamp(): Date {
        return isSyncing ? syncStartTimestamp : null;
    }

    export function GetHasTreeStructureChanged(): boolean {
        return treeStructureHasChanged;
    }

    export function GetReloadIssuesFromServer(): boolean {
        return reloadIssuesFromServer;
    }

    export function PreventSyncDownload(): boolean {
        return preventSyncDownload;
    }

    export function SetIsSyncing(flag: boolean): void {
        if (!flag) {
            syncStartTimestamp = null;
        } else if (flag && (!isSyncing || !syncStartTimestamp)) {
            syncStartTimestamp = new Date();
        }

        isSyncing = flag || false;
    }

    export function IsSyncing(): boolean {
        return isSyncing || false;
    }

    export function StartSynchronisationImmediately(showMessage: boolean = false): Deferred {
        return Utils.CheckIfDeviceIsOnline()
            .then(function() {
                triggerSynchronization();
            }, function() {
                if (showMessage) {
                    Utils.Message.Show(i18next.t('Synchronization.NoInternetConnection.MessageHeader'),
                        i18next.t('Misc.NoInternetConnection.MessageBody'),
                        {
                            Close: true
                        });
                }

                return this;
            });

    }

    function onAutoSyncProcess() {
        if (!Session.User || !(Session.AuthHash || Session.LastKnownAPIVersion >= 21)) {
            return;
        }

        if (SyncCenter.IsSyncing()) {
            return;
        }

        if (Utils.IssueViewer.GetCurrentIssue()) {
            const closeDeferred = Utils.IssueViewer.GetCloseDeferred();
            if (closeDeferred) {
                // postpone auto sync after editor closed
                closeDeferred.then(function() {
                    onAutoSyncProcess();
                });
            }
            return;
        }

        if (IssueView.GetCurrentIssue()) {
            const closeDeferred = IssueView.GetCloseDeferred();
            if (closeDeferred) {
                // postpone auto sync after editor close
                closeDeferred.then(function() {
                    onAutoSyncProcess();
                });
            }
            return;
        }

        if (lastAutoSyncTimestamp) {
            // don't start, if last sync is not that old
            const timeout = Session.Settings.AutoSyncInterval * 60 * 1000;
            if (lastAutoSyncTimestamp.getTime() + timeout > new Date().getTime()) {
                return;
            }
        }

        if (!Utils.CheckIfDeviceIsAllowedToAutoSync()) {
            return;
        }

        StartSynchronisationImmediately(false)
            .fail(function() {
                $(document).on('online.sync', function() {
                    $(document).off('online.sync');
                    onAutoSyncProcess();
                });
            });
    }

    export function StartAutoSync() {
        if (!Session.Settings.AutoSync) {
            return;
        }

        if (autoSyncTimer) {
            clearInterval(autoSyncTimer);
        }

        autoSyncTimer = setInterval(onAutoSyncProcess, Session.Settings.AutoSyncInterval * 60 * 1000);
    }

    export function StopAutoSync() {
        if (autoSyncTimer) {
            clearInterval(autoSyncTimer);
            autoSyncTimer = null;
        }
    }
}
