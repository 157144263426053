/// <reference path="i-workflow.ts"/>
///<reference path="response/response-base.ts"/>
/// <reference path="../../../model/model.recorditem.ts"/>
/// <reference path="../../../model/elements/element.ts"/>
///<reference path="workflow-settings.ts"/>

module New.Checkpoints.Workflow {
    export abstract class WorkflowBase implements IWorkflow {
        Type: Enums.CheckpointWorkflowType;
        CategoryOID?: string;
        Order?: number;
        IntervalType: Enums.CheckpointWorkflowIntervalType;
        MaximumInterval?: number;
        Indicator?: number;
        FormOID?: string;
        LocationOID?: string;
        Title: string;
        Text: string;
        Teams: Array<string>;
        Users: Array<string>;
        InheritResponsibilitiesFromOU?: boolean;
        ResponsibilityAssignments?: Model.Issues.ResponsibilityAssignments;
        Keywords?: Array<string>;
        TriggerAutomatically: boolean;
        DeadlineOffset?: New.Checkpoints.Workflow.DeadlineOffset;
        ParentIssueModifications: Array<Model.Elements.ParentIssueModificationDefinition>;
        LocationIdentifier: string;

        readonly Checkpoint: Model.Elements.Element;
        readonly Recorditem: Model.Recorditem;

        protected constructor(workflow: IWorkflow, workflowSettings: WorkflowSettingsBase) {
            if (!workflow) {
                throw new Error('No workflow given');
            }
            if (!workflowSettings) {
                throw new Error('No workflowSettings given');
            }
            if (!workflowSettings.Checkpoint) {
                throw new Error('No checkpoint given');
            }
            if (!workflowSettings.Recorditem) {
                throw new Error('No recorditem given');
            }

            this.Type = workflow.Type;
            this.CategoryOID = workflow.CategoryOID;
            this.Order = workflow.Order;
            this.IntervalType = workflow.IntervalType;
            this.MaximumInterval = workflow.MaximumInterval;
            this.Indicator = workflow.Indicator;
            this.FormOID = workflow.FormOID;
            this.LocationOID = workflow.LocationOID;
            this.Title = workflow.Title;
            this.Text = workflow.Text;
            this.Teams = workflow.Teams;
            this.Users = workflow.Users;
            this.TriggerAutomatically = workflow.TriggerAutomatically;
            this.DeadlineOffset = workflow.DeadlineOffset;
            this.InheritResponsibilitiesFromOU = workflow.InheritResponsibilitiesFromOU;
            this.ResponsibilityAssignments = workflow.ResponsibilityAssignments;
            this.Keywords = workflow.Keywords;
            this.ParentIssueModifications = workflow.ParentIssueModifications;

            this.Checkpoint = workflowSettings.Checkpoint;
            this.Recorditem = workflowSettings.Recorditem;
            this.LocationIdentifier = workflowSettings.LocationIdentifier;
        }

        public abstract Execute(): any;
    }
}