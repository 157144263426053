//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../app/app.parameter-list.ts" />
/// <reference path="../model/model.recorditem.ts"  />
/// <reference path="./utils.chat-window.ts"  />
/// <reference path="./utils.parameter-history.ts"  />
/// <reference path="./utils.action-window.ts"  />
/// <reference path="./utils.change-image-order.ts"  />
/// <reference path="./utils.scan.ts"  />
/// <reference path="../utils/utils.custom-data-picker.ts"  />
//imports-end

module Utils.RecorditemEditor {
    const DATAWEDGE_CALLBACK_ID = 'RecorditemEditor_Scan';

    module InstanceManager {
        const EditorInstances: Instance[] = [];

        export function Add(instance: Instance): void {
            EditorInstances.push(instance);
        }

        export function Remove(instance: Instance): boolean {
            const pos = EditorInstances.indexOf(instance);

            if (pos < 0) {
                return false;
            }

            EditorInstances.splice(pos, 1);

            return true;
        }

        export function IsVisible(): boolean {
            for (const item of EditorInstances) {
                if (item.IsVisible()) {
                    return true;
                }
            }

            return false;
        }
    }

    class Instance {
        private $win;
        private $body;
        private $overlay;
        private $files;
        private $additonalFiles;
        private $correctiveActions;
        private $correctiveActionList;
        private $commentsWrapper;
        private $commentSection;
        private $selectFromSuggestedValues;

        private $btnSave;
        private $btnHistory;
        private $btnInformation;
        private $btnAbort;
        private $btnDelete;

        private recorditemOID: string;
        private additionalCorrectiveActions: any[];
        private selectedElement: Model.Elements.Element;
        private previousRecorditem: Model.Recorditem;
        private onSave: OnSaveFunc;
        private recorditemValueImage: ExtendedFile;
        private recorditemValueImageMarks: string;
        private issueInfos: { Issue: Model.Issues.Issue; Row: number; };
        private selectedFilename: string;
        private hasReferenceImage: boolean;
        private onAfterDestroy: Function;
        private onAfterCommentCreated: Function;
        private onAfterCommentDeleted: Function;

        private updatePreviousRecorditem: boolean;
        private imageMarksHaveChanged: boolean;
        private newCorrectiveActions: Array<any>;
        private usedPictures: string[];
        private newComments: Model.Comment[];
        private isReadonly: boolean;
        private disableDeleteOption: boolean;
        private bluetoothEnabled: boolean;
        private scaleEnabled: boolean;
        private hasCanvasImageChanged: boolean = false;

        private _additionalFiles: Dictionary<any>;

        private removedFiles: Dictionary<boolean> = {};
        private removedFilesSyncOID: Array<string> = [];

        private isSimplified: boolean;
        private resizeTimeout: number = null;
        private elementPickerPopup: Utils.ElementPickerPopup;

        public constructor() {
            // Editor Instanz zur globaler Liste hinzufügen
            InstanceManager.Add(this);
        }

        private destroy(recorditem?: Model.Recorditem): void {
            if (this.bluetoothEnabled) {
                this.unbindBluetoothEvent();
            }

            // unbind scale event
            if (this.scaleEnabled) {
                this.unbindScaleEvent();
            }

            this.selectedElement = null;
            this.previousRecorditem = null;
            this.recorditemValueImage = null;
            this.recorditemValueImageMarks = null;
            this.hasReferenceImage = false;
            this.issueInfos = null;
            this.selectedFilename = null;
            this._additionalFiles = null;
            this.updatePreviousRecorditem = null;
            this.imageMarksHaveChanged = null;
            this.newComments = null;
            this.onAfterCommentCreated = null;
            this.usedPictures = null;

            this.$btnSave = null;
            this.$btnHistory = null;
            this.$btnInformation = null;
            this.$btnAbort = null;
            this.$btnDelete = null;

            $('body')
                .removeClass('modal-open')
                .css('padding-right', 0);

            this.$body = null;

            if (this.$win) {
                this.$win.remove();
                this.$win = null;
            }

            if (this.$overlay) {
                Utils.Overlay.DestroyWithTimeout(this.$overlay, this.onAfterDestroy instanceof Function ? 300 : null);    // Timeout von 260ms um das debounce Timeout von 250ms zu überstehen
                this.$overlay = null;
            }

            if (!this.isSimplified) {
                ChangeImageOrder.UnBind();
            }

            if (!Session.IsSmartDeviceApplication) {
                $(document).off('paste.drop-in');
            }

            this.removedFiles = {};
            this.removedFilesSyncOID = [];

            this.unbindDataWedgeEvent();
            this.unbindEvents();

            //ohne temporäre Variable würde newCorrectiveActions auf null gesetzt werden, nachdem das neue recorditemeditor Fenster geöffnet wurde, bei der Einstellung nächsten Prüfpunkt öffnen
            const tmpNewCorrectiveActions = this.newCorrectiveActions;
            this.newCorrectiveActions = null;

            if (this.onAfterDestroy instanceof Function) {
                if (recorditem && recorditem.hasOwnProperty('OID')) {
                    this.onAfterDestroy(recorditem, tmpNewCorrectiveActions);
                } else {
                    this.onAfterDestroy();
                }
            }

            // Editor Instanz aus globaler Liste entfernen
            InstanceManager.Remove(this);
        }

        private onWeightReceived(weight: number, unit: string, data: string) {
            if (typeof weight === 'undefined' || weight === null) {
                return;
            }
            if ((this.$body instanceof $) && this.$body.length) {
                this.$body.find('input[type="number"]').val(weight);
            }

            // Einheiten vergleichen, Hinweis einblenden bei Unterschied
            if (this.selectedElement.UnitOID) {
                const syUnit = DAL.Properties.GetByOID(this.selectedElement.UnitOID)
                if (syUnit && syUnit.Title.toLowerCase() !== unit.toLowerCase()) {
                    Utils.Toaster.Show(i18next.t('Scale.DifferentUnits', { unit_a: unit, unit_b: syUnit.Title }), 2, Enums.Toaster.Icon.Warning);
                }
            }

            this.prepareRecorditemForSave(weight);
        }

        private onAfterGotMeasuredTemperature(temperature: number) {
            if (typeof temperature === 'undefined' || temperature === null) {
                return;
            }

            if (temperature < -273.15) {
                return;
            }

            if ((this.$body instanceof $) && this.$body.length) {
                this.$body.find('input[type="number"]').val(temperature);
            }

            this.prepareRecorditemForSave(temperature);
        }

        private onAfterGotDataWedgeScan(t: string, code: string): void {
            const deferred = $.Deferred();

            if (this.selectedElement.Type === Enums.ElementType.IndividualData) {
                const schemaType = Utils.GetIndividualDataTypeOfElement(this.selectedElement);

                if (schemaType) {
                    const data = Utils.RecorditemEditor.Individual.GetIndividualData(this.selectedElement, code);

                    if (data && data.length) {
                        if (data.length === 1) {
                            deferred.resolve({ [schemaType]: data });
                        } else {
                            Utils.RecorditemEditor.Individual.Show(this.selectedElement, (result) => {
                                deferred.resolve({ [schemaType]: result });
                            }, null, code);
                        }
                    } else {
                        deferred.resolve(null);
                    }
                } else {
                    deferred.resolve(null);
                }
            } else {
                deferred.resolve(code);
            }

            deferred.then((value) => {
                if (value == null) {
                    //explicit null
                    value = null;
                }

                this.prepareRecorditemForSave(value, true);
            });
        }

        private onMailAddressFocusIn(evt: Event): void {
            const $input = $(evt.currentTarget);
            const $wrapper = $input.parents('.multi-value-input');

            $wrapper.addClass('focus');
        }

        private onMailAddressFocusOut(evt: Event): void {
            const $input = $(evt.currentTarget);
            const $wrapper = $input.parents('.multi-value-input');

            $wrapper.removeClass('focus');
        }

        private onMailAddressKeyDown(evt: KeyboardEvent): void {
            const $input = $(evt.currentTarget);
            const keyCode = evt.keyCode || evt.charCode;
            const currentValue = $input.val();

            if (keyCode === Enums.KeyCode.SPACE) {
                evt.preventDefault();
                return;
            }

            if (!currentValue) {
                $input.removeClass('error');

                if (keyCode === Enums.KeyCode.RETURN) {
                    this.$btnSave.trigger('click');
                }
            }
        }

        private onMailAddressKeyUp(evt: KeyboardEvent): void {
            const keyCode = evt.keyCode || evt.charCode;

            if (keyCode !== Enums.KeyCode.RETURN) {
                return;
            }

            this.processMailAddressInput(evt);
        }

        private onMailAddressInput(evt: Event): void {
            const $input = $(evt.currentTarget);
            const value = $input.val();
            const lastChar = value.substr(-1);

            if (lastChar !== ',' && lastChar !== ';') {
                return;
            }

            this.processMailAddressInput(evt);
        }

        private onMailAddressBlur(evt: Event): void {
            this.processMailAddressInput(evt);
        }

        private processMailAddressInput(evt: Event): void {
            const $input = $(evt.currentTarget);
            const $parent = $input.parent();
            let currentValue = $input.val();

            if (!currentValue) {
                return;
            }

            currentValue = $.trim(currentValue.replace(/[,;]/g, ''));

            if (!Utils.IsValidMailAddress(currentValue)) {
                $input.addClass('error');
                return;
            }

            const $mailAddresses = $parent.parent().find('.value');
            const currentMailValue = $.map($mailAddresses, function(valueNode) {
                return $(valueNode).data('value');
            });

            if (currentMailValue.indexOf(currentValue) > -1) {
                $input.addClass('error');
                return;
            }

            $input.removeClass('error');

            const newValue = this.createMultiInputItem(currentValue);

            $parent.before(newValue);
            $input.val('');
        }

        private createMultiInputItem(value: string): string | null {
            if (!value || typeof value !== 'string') {
                return null;
            }

            const markup = [
                '<li class="value" data-value="',
                value,
                '">',
                value
            ];

            if (!this.isReadonly) {
                markup.push('<div class="delete-value">&times;</div>');
            }

            markup.push('</li>');

            return markup.join('');
        }

        private onRemoveMailAddressClick(evt: Event): void {
            const $input = $(evt.currentTarget);
            const $parent = $input.parent();

            $parent.remove();
        }

        private onKeyPress(evt: KeyboardEvent): void {
            const keyCode = evt.keyCode || evt.charCode;

            if (keyCode === Enums.KeyCode.RETURN) {
                this.$btnSave.trigger('click');
            }
        }

        private onNumberKeyPress(evt: KeyboardEvent): boolean {
            const keyCode = evt.keyCode || evt.charCode;

            if (keyCode === Enums.KeyCode.RETURN) {
                this.$btnSave.trigger('click');
                return false;
            }

            return /^[\d.,+-]+$/.test(evt.key);
        }

        private onBtnSelectFromSuggestedValuesClick(): void {
            if (!(this.selectedElement.SuggestedValues || []).length) {
                return;
            }

            const items = this.selectedElement.SuggestedValues.map((sg, idx: number) => {
                return {
                    PropertyValue: idx,
                    Text: sg.Value.length > 200 ? `${sg.Value.substr(0, 197)}...` : sg.Value
                };
            });

            const $win = this.$win;

            const options: Utils.CustomDataPicker.Options = <Utils.CustomDataPicker.Options>{
                Title: i18next.t('RecorditemEditor.SelectFromSuggestedValues'),
                TextSchema: '{Text}',
                HideResetButton: true,
                Width: 400,
                PropertyValue: 'Position',
                ButtonTextWrap: true,
                OnItemClick: ($btn) => {
                    const idx = parseInt($btn.data('propertyvalue'), 10);

                    if (!Utils.IsSet(idx)) {
                        return;
                    }

                    const selectedValue: Model.Elements.SuggestedValue = this.selectedElement.SuggestedValues[idx];

                    if (selectedValue) {
                        $win.find('.form-control').val(selectedValue.Value);
                    }

                    Utils.CustomDataPicker.Destroy();
                },
                Callback: $.noop,
            };

            Utils.CustomDataPicker.Show(items, options);
        }

        private onChangeBooleanValue($selector) {
            const currentValue: boolean = $selector.data('value');

            if (currentValue === true) {
                $selector = this.$win.find('div[data-value="true"]');

                $selector.find('img').attr('src', './img/checked.svg')
                    .parent().siblings('div[data-value="false"]').find('img').attr('src', './img/crosschecked_unselected.svg')
                    .parent().siblings('div[data-value="null"]').find('img').attr('src', './img/unchecked_unselected.svg');
            } else if (currentValue === false) {
                $selector = this.$win.find('div[data-value="false"]');

                $selector.find('img').attr('src', './img/crosschecked.svg')
                    .parent().siblings('div[data-value="true"]').find('img').attr('src', './img/checked_unselected.svg')
                    .parent().siblings('div[data-value="null"]').find('img').attr('src', './img/unchecked_unselected.svg');
            } else {
                $selector = this.$win.find('div[data-value="null"]');

                $selector.find('img').attr('src', './img/unchecked.svg')
                    .parent().siblings('div[data-value="true"]').find('img').attr('src', './img/checked_unselected.svg')
                    .parent().siblings('div[data-value="false"]').find('img').attr('src', './img/crosschecked_unselected.svg');
            }

            $selector.removeClass('desaturate').siblings().addClass('desaturate');
        }

        private onAfterIndividualValueRecorded(selectedValues: string[], selectionTitle?: string) {
            this.$win.find('input[type="text"]').data('val', selectedValues.join('|')).val(Utils.UnescapeHTMLEntities(selectionTitle || ''));
        }

        private onSelectionItemClick(evt: Event) {
            const $this = $(evt.currentTarget);

            if (this.selectedElement.Type === Enums.ElementType.ListBox) {
                $this.siblings('.selected').removeClass('selected');
            }

            $this.toggleClass('selected');
        }

        private onInputClick(evt?: Event) {
            const $inputField = this.$win.find('.modal-body > input');

            if (evt) {
                evt.stopPropagation();
            }

            if (this.selectedElement.Type === Enums.ElementType.Date) {
                this.showDatePicker(new Date($inputField.data('timestamp')), function(value) {
                    $inputField.val(Utils.DateTime.DateToString(value)).data('timestamp', value.getTime());
                });
            } else if (this.selectedElement.Type === Enums.ElementType.Time) {
                this.showTimePicker(new Date($inputField.data('timestamp')), function(value) {
                    $inputField.val(Utils.DateTime.TimeToString(value)).data('timestamp', value.getTime());
                });
            } else if (this.selectedElement.Type === Enums.ElementType.Users) {
                let tmp = $inputField.data('raw-value');

                if (typeof tmp === 'string') {
                    tmp = JSON.parse(tmp);
                }

                this.showUserPicker(tmp);
            } else if (this.selectedElement.Type === Enums.ElementType.IndividualData) {
                this.showIndividualDataPicker($inputField.data('val'), $.proxy(this.onAfterIndividualValueRecorded, this));
            }
        }

        private onBtnStartScannerClick() {
            if (Session.IsSmartDeviceApplication) {
                Utils.Spinner.Show();

                let typeConstraint: string = null;

                if (this.selectedElement.Type === Enums.ElementType.Scancode) {
                    if (this.selectedElement.AdditionalSettings) {
                        typeConstraint = this.selectedElement.AdditionalSettings.BarcodeType;

                        if (typeConstraint === Enums.BarcodeType.AKIM) {
                            typeConstraint = 'QR_CODE';
                        }
                    }
                } else if (this.selectedElement.Type === Enums.ElementType.LocationCode) {
                    typeConstraint = 'QR_CODE';
                }

                Utils.StartScanner(
                    $.proxy(this.onValueScanned, this),
                    $.proxy(this.onScannerCancelled, this),
                    $.proxy(this.onScannerError, this),
                    typeConstraint);
            } else {
                this.showLocationPicker();
            }
        }

        public ApplyScannedValue(value: string): boolean {
            if (this.selectedElement.Type === Enums.ElementType.LocationCode) {
                this.onValueScanned({ text: value });
                return true;
            }

            return false;
        }

        private onValueScanned(scanResult: { text: string }) {
            const result = scanResult.text;

            Utils.Spinner.Hide();

            if (!result) {
                Utils.Message.Show(i18next.t('Misc.ScanError.NoContent.MessageHeader'),
                    i18next.t('Misc.ScanError.NoContent.MessageBody'),
                    {
                        Close: true
                    });

                return;
            }

            if (this.selectedElement.Type === Enums.ElementType.LocationCode) {
                if (this.selectedElement.AdditionalSettings &&
                    this.selectedElement.AdditionalSettings.RestrictScannableLocations &&
                    IssueView.GetCurrentIssue()) {
                    const issue = IssueView.GetCurrentIssue();
                    let eligableLocations = [];

                    if ((this.selectedElement.AdditionalSettings.SelectedScannableLocations || []).length) {
                        eligableLocations = this.selectedElement.AdditionalSettings.SelectedScannableLocations.map(identifier => {
                            return DAL.Elements.GetByOID(identifier);
                        });
                    } else {
                        if (issue.Type === Enums.IssueType.Form ||
                            issue.Type === Enums.IssueType.Inspection) {
                            eligableLocations.push(DAL.Elements.GetByOID(issue.AssignedElementOID));
                        } else if (issue.Type === Enums.IssueType.Scheduling) {
                            eligableLocations.push(Session.CurrentLocation);
                        }
                    }

                    const wrongLocationScanned = !eligableLocations.some((location: Model.Elements.Element) => {
                        return location && (location.OID === result || location.QRCode === result);
                    });

                    if (wrongLocationScanned) {
                        Utils.Message.Show(i18next.t('Misc.ScanError.WrongLocationScanned.MessageHeader'),
                            i18next.t('Misc.ScanError.WrongLocationScanned.MessageBody'),
                            {
                                Close: true
                            });

                        return;
                    }
                }

                const element = DAL.Elements.GetByQRCode(result);

                if (element) {
                    if (this.previousRecorditem &&
                        result !== this.previousRecorditem.Value) {
                        this.updatePreviousRecorditem = false;
                    }

                    if (this.isSimplified) {
                        this.prepareRecorditemForSave(element.OID);
                    } else {
                        let title = element.Title;
                        const parent = DAL.Elements.GetByOID(element.ParentOID);

                        if (parent && parent.Title) {
                            title += ` @ ${parent.Title}`;
                        }

                        this.$body.find('input[type="text"]')
                            .data('raw-value', element.OID)
                            .val(Utils.UnescapeHTMLEntities(title || i18next.t('misc.unknown')));
                    }
                } else {
                    Utils.Message.Show(i18next.t('Misc.ScanError.ElementDoesNotExist.MessageHeader'),
                        i18next.t('Misc.ScanError.ElementDoesNotExist.MessageHeader'),
                        {
                            Close: true
                        });
                }
            } else {
                this.checkBarcodeValue(result)
                    .then(() => {
                        if (this.selectedElement.AdditionalSettings && this.selectedElement.AdditionalSettings.BarcodeType === Enums.BarcodeType.AKIM) {
                            this.handleAKIMDeliveryNote(result);
                            return;
                        }

                        if (this.isSimplified) {
                            this.prepareRecorditemForSave(result);
                        } else {
                            this.$body.find('input[type="text"]').val(Utils.UnescapeHTMLEntities(result));
                        }
                    });
            }
        }

        private handleAKIMDeliveryNote(xml: string): void {
            const values = Utils.ParseXmlString(xml);

            if (!values || !values.QR || !values.QR.DF) {
                Utils.Toaster.Show(
                    i18next.t('Akim.InvalidQRCode'),
                    2,
                    Enums.Toaster.Icon.Warning
                );
                return;
            }

            if (this.isSimplified) {
                this.prepareRecorditemForSave(xml);
            } else {
                this.$body.find('input[type="text"]').val(xml);
            }
        }

        private onScannerCancelled() {
            Utils.Spinner.Hide();
        }

        private onScannerError(error: string) {
            if (error === 'unable to obtain video capture device input') {
                error = i18next.t('Misc.NoAccessToCamera.MessageBody');
            }

            Utils.Spinner.Hide();
            Utils.Message.Show(i18next.t('Misc.ScanError.GeneralError.MessageHeader'),
                error,
                {
                    Close: true
                });
        }

        private onLocationSelected(result: Utils.ElementPickerPopup.ConfirmSelectionResult) {
            if (this.isSimplified) {
                this.prepareRecorditemForSave(result ? result.ElementOID : null);
                return;
            }

            let title = '-/-';
            let oid: string = null;

            if (result && result.ElementOID) {
                const element = DAL.Elements.GetByOID(result.ElementOID);

                if (element) {
                    title = element.Title;

                    const parent = DAL.Elements.GetByOID(element.ParentOID);
                    if (parent && parent.Title) {
                        title += ` @ ${parent.Title}`;
                    }

                    oid = element.OID;
                }
            }

            this.$body.find('input[type="text"]')
                .val(Utils.UnescapeHTMLEntities(title || i18next.t('misc.unknown')))
                .attr('data-raw-value', oid)
                .data('raw-value', oid);
        }

        private onAfterUsersSelected(value: UserPicker.IUserTeamsSelection) {
            if (!this.isSimplified) {
                this.$body.find('input[type="text"]')
                    .data('raw-value', JSON.stringify(value))
                    .val(Utils.UnescapeHTMLEntities(this.getStringValue(value)));
            } else {
                this.prepareRecorditemForSave(JSON.stringify(value));
            }
        }

        private onBtnHistoryClick() {
            let oid = this.selectedElement.OID;

            if (Utils.InArray([Enums.View.Form, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
                oid = this.selectedElement.RevisionOID;
            }

            Utils.ParameterHistory.Show(oid);
        }

        private onBtnInformationClick() {
            let oid = this.selectedElement.OID;

            if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
                oid = this.selectedElement.RevisionOID;
            }

            Utils.ElementInformation.Show(oid);
        }

        private onBtnDeleteClick(_: Event, calculated: boolean = false): Deferred {
            if (Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion < 20) {
                // bis API 20 löschen von Recorditems nicht vorhanden
                return $.Deferred().reject('Delete recorditem not available by API.');
            }

            if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Recorditem_Delete, true)) {
                if (calculated) {
                    // bei berechneten Werten still abbrechen
                    return $.Deferred().resolve();
                }

                // Meldung zeigen, dass Rechte zum Löschen fehlen
                Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                    i18next.t('Misc.RightError.DeleteRecorditem.MessageBody'),
                    { OK: true });
                Utils.Spinner.Hide();

                return $.Deferred().reject('Missing user right: Recorditem_Delete');
            }

            const deleteDeferred: Deferred = $.Deferred();

            const resultDeferred = deleteDeferred.then(() => {
                Utils.Spinner.Show();

                return Model.Recorditem.Delete(this.previousRecorditem)
                    .then(() => {
                        if (this.onSave) {
                            this.onSave(this.previousRecorditem);
                        }

                        Utils.Spinner.Hide();

                        this.destroy(this.previousRecorditem);
                    }, (xhr) => {
                        Utils.Message.Show(i18next.t('RecorditemEditor.DeleteRecorditemFailed.MessageHeader'),
                            i18next.t('RecorditemEditor.DeleteRecorditemFailed.MessageBody'),
                            { Close: true });
                        Utils.Spinner.Hide();

                        // Fehler weitergeben
                        return $.Deferred().reject(xhr);
                    });
            });

            if (calculated) {
                // bei berechneten Werten automatisch löschen
                deleteDeferred.resolve();
            } else {
                // Fragen ob die Erfassung entfernt werden soll
                Utils.Message.Show(i18next.t('RecorditemEditor.DeleteRecorditem.MessageHeader'),
                    i18next.t('RecorditemEditor.DeleteRecorditem.MessageBody'),
                    {
                        Yes: () => {
                            deleteDeferred.resolve();
                        },
                        No: true
                    }, null, 190002);
                Utils.Spinner.Hide();
            }

            return resultDeferred;
        }

        private onBtnSaveClick() {
            this.checkBarcodeValue(this.getValue())
                .then(() => this.prepareRecorditemForSave());
        }

        private checkBarcodeValue(scanCode: string): Deferred {
            if (!Utils.IsSet(this.selectedElement) ||
                this.selectedElement.Type !== Enums.ElementType.Scancode ||
                !Utils.IsSet(this.selectedElement.AdditionalSettings)) {
                return $.Deferred().resolve().promise();
            }

            const resultDeferred = $.Deferred();

            if (!!this.selectedElement.AdditionalSettings.MustStartWith) {
                const mustStartWithRegex = new RegExp(
                    `^${Utils.EscapeRegExPattern(this.selectedElement.AdditionalSettings.MustStartWith)}`,
                    'ig'
                );

                if (!mustStartWithRegex.test(scanCode)) {
                    Utils.Message.Show(i18next.t('Misc.ScanError.WrongPrefix.MessageHeader'),
                        i18next.t('Misc.ScanError.WrongPrefix.MessageBody', {
                            value: scanCode,
                            requiredPrefix: this.selectedElement.AdditionalSettings.MustStartWith
                        }),
                        {
                            Abort: {
                                Classes: ['btn', 'flat', 'btn-danger', 'btn-abort', 'pull-left', !Session.IsSmartDeviceApplication ? 'hidden' : '']
                            },
                            Yes: {
                                Fn: Session.IsSmartDeviceApplication ? $.proxy(this.onBtnStartScannerClick, this) : $.noop,
                                Caption: Session.IsSmartDeviceApplication ? i18next.t('Misc.ScanError.WrongPrefix.RepeatScan') : i18next.t('Misc.ScanError.WrongPrefix.RepeatInput'),
                                Classes: ['btn', 'flat', 'btn-yes']
                            }
                        });

                    return resultDeferred.reject().promise();
                }
            }

            let dialogDeferred = $.Deferred().resolve().promise();

            dialogDeferred
                .then(() => {
                    const deferred = $.Deferred();

                    if (this.selectedElement.AdditionalSettings.BarcodeType !== Enums.BarcodeType.EAN_13 ||
                        Utils.ValidateEAN13Barcode(scanCode)) {
                        return deferred.resolve().promise();
                    }

                    Utils.Message.Show(i18next.t('Misc.ScanError.InvalidEAN13.MessageHeader'),
                        i18next.t('Misc.ScanError.InvalidEAN13.MessageBody', { value: scanCode }),
                        {
                            Abort: {
                                Fn: () => deferred.reject(),
                                Classes: ['btn', 'flat', 'btn-danger', 'btn-abort', 'pull-left']
                            },
                            Save: {
                                Fn: () => deferred.resolve(),
                                Caption: i18next.t('Misc.ScanError.InvalidEAN13.SaveValue')
                            }
                        });

                    return deferred.promise();
                })
                .then(() => {
                    const deferred = $.Deferred();

                    if (!this.selectedElement.AdditionalSettings.CheckForUniqueness ||
                        !DAL.ScancodeInfos.Exists(scanCode)) {
                        return deferred.resolve().promise();
                    }

                    Utils.Message.Show(i18next.t('Misc.ScanError.DuplicateScanCode.MessageHeader'),
                        i18next.t('Misc.ScanError.DuplicateScanCode.MessageBody', { value: Utils.EscapeHTMLEntities(scanCode) }),
                        {
                            Abort: {
                                Fn: () => deferred.reject(),
                                Classes: ['btn', 'flat', 'btn-danger', 'btn-abort', 'pull-left']
                            },
                            Save: {
                                Fn: () => deferred.resolve(),
                                Caption: i18next.t('Misc.ScanError.DuplicateScanCode.SaveValue')
                            }
                        })

                    return deferred.promise();
                })
                .then(resultDeferred.resolve, resultDeferred.reject);

            return resultDeferred.promise();
        }

        private onTabControlItemClick(evt: Event) {
            const $this = $(evt.currentTarget);
            const tab = $this.data('tab');

            $this.addClass('selected').siblings('.selected').removeClass('selected');

            this.$body.find(`.content [data-tab="${tab}"]`).removeClass('hidden').siblings('[data-tab]').addClass('hidden');

            if (tab === 'canvas' && !this.$body.find('.signature-input').data('is-initialized')) {
                this.initSignature();
            }

            Utils.ResizeModalWindow(this.$win);
        }

        private onAfterExistingCorrectiveActionModified(issue: Model.Issues.Issue) {
            if (this.previousRecorditem && this.previousRecorditem.CorrectiveActions) {
                for (let caCnt = 0, caLen = this.previousRecorditem.CorrectiveActions.length; caCnt < caLen; caCnt++) {
                    if (this.previousRecorditem.CorrectiveActions[caCnt].OID === issue.PrecedingOID) {
                        this.previousRecorditem.CorrectiveActions.splice(caCnt, 1);
                        this.previousRecorditem.CorrectiveActions.push(issue);

                        break;
                    }
                }
            }

            if (this.additionalCorrectiveActions &&
                this.additionalCorrectiveActions.length) {
                for (let caCnt = 0, caLen = this.additionalCorrectiveActions.length; caCnt < caLen; caCnt++) {
                    if (this.additionalCorrectiveActions[caCnt].OID === issue.PrecedingOID) {
                        this.additionalCorrectiveActions.splice(caCnt, 1);
                        this.additionalCorrectiveActions.push(issue);

                        break;
                    }
                }
            }

            const $existingCorrectiveAction = this.$correctiveActionList.find(`li[data-oid="${issue.PrecedingOID}"]`);
            $existingCorrectiveAction.replaceWith(Templates.RecorditemEditor.CorrectiveAction({
                OID: issue.OID,
                ID: issue.ID,
                Title: issue.Title || i18next.t('Misc.Untitled'),
                IsTemporary: false,
                Type: issue.Type
            }));
        }

        private onExistingCorrectiveActionClick(evt: Event) {
            if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue) && Session.LastKnownAPIVersion >= 7) {
                return;
            }

            const $this = $(evt.currentTarget);

            const closePromise = Utils.IssueViewer.OpenIssue($this.data('id') || $this.data('oid'), $.proxy(this.onAfterExistingCorrectiveActionModified, this));
            this.hideInBackground(closePromise);
        }

        private onTemporaryCorrectiveActionClick(evt: Event) {
            if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue) && Session.LastKnownAPIVersion >= 7) {
                return;
            }

            const $this = $(evt.currentTarget);
            const action = Utils.Where(this.newCorrectiveActions, 'OID', '===', $this.data('oid'));

            const closePromise = Utils.IssueViewer.OpenTemporaryIssue(
                action.Issue,
                action.AdditionalFiles,
                $.proxy(this.onAfterTemporaryCorrectiveActionModified, this));

            this.hideInBackground(closePromise);
        }

        private onStartCorrectiveActionRecordingClick(evt: Event) {
            evt.stopPropagation();
            this.destroy();
        }

        private onAfterCorrectiveIssueSaved(data: { Issue: Model.Issues.Issue, AdditionalFiles: Dictionary<any> }) {
            const $badge = this.$correctiveActions.find('.badge');
            const action = this.CreateCorrectiveActionFromMetaData(data);

            this.newCorrectiveActions.push(action);

            this.$additonalFiles.find('.footer.hidden')
                .removeClass('hidden');

            this.setUsedPictures();
            this.setImageFootersVisibility();

            if ($badge.hasClass('hidden')) {
                $badge.removeClass('hidden');
            }

            this.renderCorrectiveActionList();

            $badge.text(this.$correctiveActionList.find('li').length);

            Utils.ResizeModalWindow(this.$win);
        }

        private onAfterTemporaryCorrectiveActionModified(data: { Issue: Model.Issues.Issue, AdditionalFiles: Dictionary<any> }) {
            const action = this.CreateCorrectiveActionFromMetaData(data);
            const issueIdx = Utils.Where(this.newCorrectiveActions, 'OID', '===', action.Issue.OID, true);
            const $correctiveAction = this.$correctiveActionList.find(`li[data-oid="${action.Issue.OID}"]`);

            this.newCorrectiveActions.splice(issueIdx, 1);
            this.newCorrectiveActions.push(action);

            $correctiveAction.replaceWith(Templates.RecorditemEditor.CorrectiveAction({
                OID: action.Issue.OID,
                ID: action.Issue.ID,
                Title: action.Issue.Title || i18next.t('Misc.Untitled'),
                IsTemporary: action.IsTemporary,
                Type: action.Issue.Type
            }));

            this.setUsedPictures();
            this.setImageFootersVisibility();
        }

        private onBtnClearSignatureClick() {
            const $signature = this.$body.find('.signature-input');
            $signature.jSignature('clear');
            $signature.data('is-cleared', true);
            this.hasCanvasImageChanged = false;

            if (this.previousRecorditem && !!this.previousRecorditem.Value) {
                this.updatePreviousRecorditem = true;
            }
        }

        private onResizeWindow() {
            if (this.$win.css('display') == 'none') {
                return;
            }

            if (this.selectedElement && this.$body &&
                this.selectedElement.Type === Enums.ElementType.Signature) {
                // fit resize signature
                this.onResizeSignatureDiv();

                // wenn Größenänderung länger dauert, mehrfaches Neuladen der signatur unterbinden
                if (this.resizeTimeout) {
                    clearTimeout(this.resizeTimeout);
                }

                // place previous signature
                this.resizeTimeout = setTimeout(() => {
                    this.placePreviousSignature();
                    this.resizeTimeout = null;
                }, 500);
            } else if (!this.selectedElement && !this.$body) {
                $(window).off('.recorditem');
            }
        }

        private onWindowShown() {
            Utils.ResizeModalWindow(this.$win);

            if (this.isReadonly) {
                return;
            }

            const paramTypesWithExtraEditor = [
                Enums.ElementType.Date,
                Enums.ElementType.Time,
                Enums.ElementType.Users,
                Enums.ElementType.IndividualData
            ];

            if (this.previousRecorditem) {
                if (Utils.InArray(paramTypesWithExtraEditor, this.selectedElement.Type) ||
                    this.selectedElement.Type === Enums.ElementType.LocationCode && !Session.IsSmartDeviceApplication) {
                    return;
                }
            }

            if (Utils.InArray([Enums.ElementType.Line, Enums.ElementType.Scancode], this.selectedElement.Type)) {
                this.$body.find('input[type="text"]').focus();
            } else if (this.selectedElement.Type === Enums.ElementType.Number) {
                this.$body.find('input[type="number"]').focus();
            } else if (this.selectedElement.Type === Enums.ElementType.Memo) {
                this.$body.find('textarea:not(#additional-text)').focus();
            } else if (Utils.InArray(paramTypesWithExtraEditor, this.selectedElement.Type)) {
                this.onInputClick();
            } else if (this.selectedElement.Type === Enums.ElementType.Photo) {
                this.initMarks();
            } else if (this.selectedElement.Type === Enums.ElementType.Signature) {
                this.initSignature();
            } else if (this.selectedElement.Type === Enums.ElementType.TelephoneNumber) {
                this.$body.find('input[type="tel"]').focus();
            } else if (this.selectedElement.Type === Enums.ElementType.LocationCode) {
                if (!Session.IsSmartDeviceApplication) {
                    this.onBtnStartScannerClick();
                }
            } else if (this.selectedElement.Type === Enums.ElementType.EMailAddresses) {
                this.$body.find('.multi-value-input')
                    .addClass('focus')
                    .find('input[type="text"]')
                    .focus();
            }
        }

        private onResizeSignatureDiv() {
            const $signature = this.$body.find('.signature-input');
            const $signatureLine = this.$body.find('.signature-line');
            const width = $signature.width();
            const factor = width > 1000 ? 2.5 : 3.0;
            const newHeight = Math.round(width / factor);
            $signature.height(newHeight);
            $signatureLine.css('top', -Math.round(newHeight / 3.5) - 20);

            // force signature to resize proportional
            $signature.jSignature('globalEvents').publish('jSignature.parentresized');

            Utils.ResizeModalWindow(this.$win);
        }

        private resizeSignature(targetWidth: number): Deferred {
            const $signature = this.$body.find('.signature-input');
            const height = $signature.height();
            const width = $signature.width();

            $signature.width(targetWidth);
            $signature.height(Math.round(targetWidth / (width / height)));

            // force signature to resize proportional
            $signature.jSignature('globalEvents').publish('jSignature.parentresized');

            // alte signatur (wert) setzen
            return this.placePreviousSignature();
        }

        private onAfterCommentSaved(comment: Model.Comment) {
            let commentsCount = 0;

            this.replaceSmallInformationSection();

            if (this.previousRecorditem) {
                this.previousRecorditem.Comments = this.previousRecorditem.Comments || [];

                const oldCommentIndex = Utils.GetIndex(this.previousRecorditem.Comments, comment.OID, 'OID');

                if (oldCommentIndex !== -1) {
                    this.previousRecorditem.Comments.splice(oldCommentIndex, 1);
                }

                this.previousRecorditem.Comments.push(comment.GetRawEntity());
                commentsCount = this.previousRecorditem.Comments.length;

                if (this.onAfterCommentCreated instanceof Function) {
                    this.onAfterCommentCreated(new Model.Recorditem(this.previousRecorditem));
                }
            } else {
                this.newComments = this.newComments || [];

                const oldCommentIndex = Utils.GetIndex(this.newComments, comment.OID, 'OID');

                if (oldCommentIndex !== -1) {
                    this.newComments.splice(oldCommentIndex, 1);
                }

                this.newComments.push(comment);
                commentsCount = this.newComments.length;
            }

            if (commentsCount) {
                if (commentsCount === 1) {
                    this.$commentSection.find('.form-control').text(i18next.t('RecorditemEditor.ShowComments'));
                    this.$commentsWrapper.find('.badge').removeClass('hidden');
                }

                this.$commentsWrapper.find('.badge').text(commentsCount);
            }
        }

        private onAfterCommentDeletedUpdate(comment: Model.Comment) {
            let commentsCount = 0;

            this.replaceSmallInformationSection();

            if (this.previousRecorditem) {
                this.previousRecorditem.Comments = this.previousRecorditem.Comments || [];

                const oldCommentIndex = Utils.GetIndex(this.previousRecorditem.Comments, comment.OID, 'OID');

                if (oldCommentIndex !== -1) {
                    this.previousRecorditem.Comments.splice(oldCommentIndex, 1);
                }

                commentsCount = this.previousRecorditem.Comments.length;

                if (this.onAfterCommentDeleted instanceof Function) {
                    this.onAfterCommentDeleted(new Model.Recorditem(this.previousRecorditem));
                }
            } else {
                this.newComments = this.newComments || [];

                const oldCommentIndex = Utils.GetIndex(this.newComments, comment.OID, 'OID');

                if (oldCommentIndex !== -1) {
                    this.newComments.splice(oldCommentIndex, 1);
                }

                commentsCount = this.newComments.length;
            }

            if (commentsCount || commentsCount === 0) {
                this.$commentsWrapper.find('.badge').text(commentsCount);

                if (commentsCount === 0) {
                    this.$commentsWrapper.find('.badge').addClass('hidden');
                    this.$commentSection.find('.form-control').text(i18next.t('RecorditemEditor.AddNewComment'));
                }
            }
        }

        private onCommentsWrapperClick() {
            if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyCommentsOnCheckpoint) && Session.LastKnownAPIVersion >= 7) {
                return;
            }

            let parentIssue: Model.Issues.Issue,
                location: Model.Elements.Element;

            const entityInformation = {
                Type: Enums.CommentEntity.Recorditem,
                Recorditem: this.previousRecorditem || { OID: this.recorditemOID },
                Checkpoint: this.selectedElement
            };

            if (Utils.InArray([Enums.View.Form, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
                parentIssue = IssueView.GetCurrentIssue();
            }

            if (View.CurrentView === Enums.View.Form && parentIssue) {
                location = DAL.Elements.GetByOID(parentIssue.AssignedElementOID);
            }

            const canWriteComments = Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyOnCheckpoints, true, location);

            const closePromise = Utils.ChatWindow.Show(
                entityInformation,
                (this.previousRecorditem || {}).Comments || this.newComments,
                !canWriteComments,
                !this.previousRecorditem,
                $.proxy(this.onAfterCommentSaved, this),
                $.proxy(this.onAfterCommentDeletedUpdate, this));

            this.hideInBackground(closePromise);
        }

        private onAfterRecorditemSaved(recorditem: Model.Recorditem) {
            recorditem = Utils.PrepareRecorditem(recorditem);

            if (this.onSave) {
                this.onSave(new Model.Recorditem(recorditem));
            }

            Utils.Spinner.HideWithTimeout();

            this.destroy(recorditem);
        }

        private onAfterRecorditemUploadError(recorditem: Model.Recorditem, response, state, error): Deferred {
            if (response.status !== 409 || recorditem == null || !recorditem.IssueID) {
                return $.Deferred().reject();
            }

            const issue = IssueView.GetCurrentIssue();

            if (issue == null) {
                return $.Deferred().reject();
            }

            return Utils.Http.Put('issues/' + issue.OID, DAL.Issues.PrepareForSync(issue))
                .then(() => Utils.Http.Put('recorditems/' + recorditem.OID, recorditem.GetSyncEntity()),
                    function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    })
                .then($.proxy(recorditem.OnAfterSavedToServer, recorditem));
        }

        /* file event handlers */
        // smartdevices
        private onBtnTakePictureClick(evt?: Event) {
            const $this = evt ? $(evt.currentTarget) : null;
            const isAdditionalImage = $this ? $this.hasClass('input-group-addon') || $this.hasClass('additional-files') : false;

            Utils.Spinner.Show();

            Utils.RequestCamera().then((filePath: string) => {
                Utils.Spinner.Hide();

                // zusätzliche Dateien am Prüfpunkt behandeln
                if (isAdditionalImage) {
                    const temporaryIdentifier = this.onAfterGotAdditionalImagePath(filePath);

                    this.askWhetherToReUseFilesAndCorrectiveAction([temporaryIdentifier], this.selectedElement)
                        .then(() => {
                            if (this.selectedElement &&
                                this.selectedElement.Type == Enums.ElementType.Photo &&
                                this.selectedElement.AdditionalSettings &&
                                this.selectedElement.AdditionalSettings.ImmediateImageComparison) {
                                this.displayInImageViewer(true);
                            }
                        });
                    return;
                }

                // Hauptbild an einem Foto-Prüfpunkt verarbeiten
                const updateDeferred = this.onAfterGotMainImagePath(filePath);

                if (updateDeferred &&
                    !this.isSimplified &&
                    this.selectedElement &&
                    this.selectedElement.Type == Enums.ElementType.Photo &&
                    this.selectedElement.AdditionalSettings &&
                    this.selectedElement.AdditionalSettings.ImmediateImageComparison) {
                    updateDeferred.then(() => {
                        this.displayInImageViewer(true);
                    });
                }
            }, Utils.Spinner.Hide);
        }

        private onBtnTakePicturePress(evt: Event) {
            const $this = evt ? $(evt.currentTarget) : null;
            const isAdditionalImage = $this ? $this.hasClass('input-group-addon') || $this.hasClass('additional-files') : false;

            evt.preventDefault();

            Utils.GetImagesFromGallery({ MaximumImagesCount: isAdditionalImage ? 10 : 1 })
                .then((imagePaths: string[]) => {
                    this.onAfterImagesSelectedFromGallery(imagePaths, isAdditionalImage);
                });
        }

        private onAfterImagesSelectedFromGallery(result: string[], isAdditionalImage: boolean) {
            if (result == null || !result.length) {
                return;
            }

            // Nur das Hauptbild an einem Foto-Prüfpunkt behandeln
            if (!isAdditionalImage) {
                const updateDeferred = this.onAfterGotMainImagePath(result[0]);

                if (updateDeferred &&
                    !this.isSimplified &&
                    this.selectedElement &&
                    this.selectedElement.Type == Enums.ElementType.Photo &&
                    this.selectedElement.AdditionalSettings &&
                    this.selectedElement.AdditionalSettings.ImmediateImageComparison) {
                    updateDeferred.then(() => {
                        this.displayInImageViewer(true);
                    });
                }
                return;
            }

            // zusätzliche Dateien am Prüfpunkt behandeln
            const imageIdentifiers = [];

            for (const path of result) {
                imageIdentifiers.push(this.onAfterGotAdditionalImagePath(path, true));
            }

            this.replaceAdditionalFiles(true);

            this.askWhetherToReUseFilesAndCorrectiveAction(imageIdentifiers, this.selectedElement);
            this.scrollFileScrollerToEnd();
        }

        private onAfterGotAdditionalImagePath(filePath: string, fromGallery: boolean = false): string {
            this.replaceSmallInformationSection();

            if (this.$files.find('.info').length) {
                this.$files.find('.info').remove();
            }

            const fileExtension = Utils.GetFileExtension(filePath);
            const tmpOID: string = uuid();
            const mimeType = fileExtension === '.png' ? 'image/png' : 'image/jpeg';
            const newFilename = uuid() + fileExtension;

            this._additionalFiles[tmpOID] = {
                FileURI: filePath,
                Content: filePath,
                Filename: newFilename,
                Position: this.getMaxFilePosition() + 1,
                MimeType: mimeType,
                ModificationType: Enums.AdditionalImageModificationType.CREATED,
                OID: tmpOID,
                IsTemporary: true,
                IsBase64: true,
                IsImage: Utils.IsImage(mimeType)
            };

            const $image = $(Templates.RecorditemEditor.AdditionalFile({
                IsTemporary: true,
                OID: tmpOID,
                Title: newFilename,
                FilenameContainsPath: true,
                FileContent: filePath,
                Filename: `${Utils.FixIOSFilepath(filePath)}?${new Date().getTime()}`
            }));

            $image.find('img').on('load', $.proxy(this.onAfterImageLoaded, this));

            if (!fromGallery) {
                this.replaceAdditionalFiles(true);
            }

            this.$additonalFiles
                .hammer()
                .off('tap')
                .off('press');

            if (!this.isSimplified && Session.IsSmartDeviceApplication) {
                this.$body.find('.additional-files').hammer().off('tap').off('press');
                this.$body.find('.additional-files > .clickable').hammer().off('tap').off('press');
                this.$body.find('.additional-files > .clickable')
                    .press($.proxy(this.onBtnTakePicturePress, this), $.proxy(this.onBtnTakePictureClick, this), 500);
            }

            return tmpOID;
        }

        private onAfterGotMainImagePath(filePath: string): Deferred | null {
            if (this.selectedElement.Type !== Enums.ElementType.Photo) {
                return null;
            }

            const updatePhotoValueDef: Deferred = $.Deferred();

            if (Session.Settings.ConfirmOverwriteExistingPhotos &&
                ((this.previousRecorditem || {}).Value || this.recorditemValueImage)) {
                Utils.Message.Show(i18next.t('RecorditemEditor.OverwritePhoto.MessageHeader'),
                    i18next.t('RecorditemEditor.OverwritePhoto.MessageBody'),
                    {
                        Yes: () => updatePhotoValueDef.resolve(),
                        No: () => updatePhotoValueDef.reject()
                    }, null, 1100);
            } else {
                updatePhotoValueDef.resolve();
            }

            return updatePhotoValueDef.then(() => {
                if (!this.isSimplified) {
                    this.$body.find('.btn-mark-picture').removeClass('disabled');

                    this.updatePreviousRecorditem = false;

                    this.$body.find('.recorditem-value-image')
                        .replaceWith(`<img class="recorditem-value-image" src="${Utils.FixIOSFilepath(filePath)}" />`);

                    this.$body.find('.recorditem-value-image')
                        .data('tmp-filepath', filePath)
                        .attr('data-tmp-filepath', filePath)
                        .on('load', $.proxy(this.onAfterImageLoaded, this));

                    this.$btnSave.text(i18next.t('Misc.Okay'));
                } else {
                    this.prepareRecorditemForSave(filePath);
                }
            }).fail(() => {
                if (this.$win) {
                    Utils.ResizeModalWindow(this.$win);
                }
            });
        }

        private onAfterImageLoaded(evt: Event) {
            const $img = $(evt.currentTarget);

            if (!($img instanceof $) ||
                !$img.length) {
                return;
            }

            Utils.ResizeModalWindow(this.$win);

            if ($img.attr('src') === './img/file_not_found.svg') {
                return;
            }

            if ($img.hasClass('recorditem-value-image')) {
                this.initMarks();
            } else {
                const $file = $img.parents('.file');

                if ($file.length) {
                    const minFileWidth = parseInt($file.css('min-width'), 10) || 200;
                    const imageWidth = $img.width();
                    $file.width(Math.max(minFileWidth, imageWidth));
                }
            }

            const $marks = $img.siblings('div');

            if (!$marks.length) {
                return;
            }

            $marks.css({
                top: $img.position().top,
                left: $img.position().left,
                width: $img.width(),
                height: $img.height()
            });

            if (this.selectedElement &&
                this.selectedElement.Type == Enums.ElementType.Photo &&
                this.selectedElement.AdditionalSettings &&
                this.selectedElement.AdditionalSettings.ImmediateImageComparison) {
                this.displayInImageViewer(true);
            }
        }

        // desktop
        private onAllAdditionalFilesPrepared(identifiers: Array<string>) {
            this.askWhetherToReUseFilesAndCorrectiveAction(identifiers, this.selectedElement);
        }

        private onReadFileError(result: any) {
            if (result === 'cancelled') {
                return;
            }

            Utils.Message.Show(
                i18next.t('IssueViewer.ReadFileError.MessageHeader'),
                i18next.t('IssueViewer.ReadFileError.MessageBody'),
                { Close: true }
            );
        }

        private onDropFiles(evt) {
            evt.preventDefault();

            const dataTransfer = evt.originalEvent.dataTransfer;

            let files: Array<File>;

            if (dataTransfer.items) {
                files = Utils.DataTransferListToFileArray(evt.originalEvent.dataTransfer.items);
            } else if (dataTransfer.files) { // IE Unterstüzung
                files = Utils.DataTransferListToFileArray(evt.originalEvent.dataTransfer.files, evt.originalEvent.dataTransfer.types);
            }

            Utils.LoopDeferredActions(files, $.proxy(this.prepareNewAdditionalFile, this))
                .then($.proxy(this.onAllAdditionalFilesPrepared, this), $.proxy(this.onReadFileError, this));
        }

        private onPasteFile(event) {
            if (IssueViewer.IsVisible()) {
                return;
            }

            const files = Utils.DataTransferListToFileArray((event.clipboardData || event.originalEvent.clipboardData).items)
                .filter(file => Utils.IsImage(file.type));

            // Wenn 'Bild hochladen' Tab bei Signatur offen ist, eingefügte Bilder als Signatur übernehmen
            // oder wenn persönliche Einstellung für PasteImageAsSignature aktiv ist
            if (this.selectedElement.Type == Enums.ElementType.Signature &&
                (this.getCurrentlySelectedTab() === 'file-input' || Session.Settings.PasteImageAsSignature) &&
                files.length > 0 && files[0].type.contains('image/')) {
                this.prepareNewRecorditemValueFile(files[0])
                    .fail($.proxy(this.onReadFileError, this));
            } else if (!this.isSimplified) {
                // Bilder als Anhang einfügen
                Utils.LoopDeferredActions(files, $.proxy(this.prepareNewAdditionalFile, this))
                    .then($.proxy(this.onAllAdditionalFilesPrepared, this), $.proxy(this.onReadFileError, this));
            }
        }

        private onFileInput(input: HTMLInputElement, isRecorditemValue: boolean = false): void {
            if (typeof FileReader === 'undefined') {
                return;
            }

            let deferred: Deferred;

            if (isRecorditemValue) {
                deferred = Utils.LoopDeferredActions(input.files, $.proxy(this.prepareNewRecorditemValueFile, this))
                    .fail($.proxy(this.onReadFileError, this));
            } else {
                deferred = Utils.LoopDeferredActions(input.files, $.proxy(this.prepareNewAdditionalFile, this))
                    .then($.proxy(this.onAllAdditionalFilesPrepared, this), $.proxy(this.onReadFileError, this))
            }

            deferred.always(() => $(input).val(''));
        }

        private onAfterFileRead(fileInformation: FileInformation): Deferred {
            if (!fileInformation ||
                !fileInformation.Event ||
                !fileInformation.Event.target ||
                !fileInformation.Identifier ||
                !fileInformation.AdditionalInformation) {
                return $.Deferred().reject().promise();
            }

            const src = <string>fileInformation.Event.target.result;
            const file = fileInformation.File;
            const mimeType = file.type;
            const isRecorditemValue = fileInformation.AdditionalInformation.IsRecorditemValue;

            if (!isRecorditemValue && this.$files.find('.info').length) {
                this.$files.find('.info').remove();
            }

            if (!mimeType.contains('image/')) {
                this.updateAdditionalFileSection();
                return $.Deferred().resolve(fileInformation.Identifier).promise();
            }

            const deferred = $.Deferred();
            const canvas = $('<canvas>')[0];
            const context = canvas.getContext('2d');
            const imageObj: HTMLImageElement = new Image();
            imageObj.src = src;

            const me = this;
            imageObj.onload = function(this: HTMLImageElement) { //default: this => HTMLElement
                $(canvas).attr({
                    width: this.width,
                    height: this.height
                });

                context.drawImage(this, 0, 0, this.width, this.height);

                const dataUrl = canvas.toDataURL();

                if (isRecorditemValue) {
                    me.$body
                        .find('.btn-take-picture')
                        .find('input[type="file"]').val('');

                    if (Session.Settings.ConfirmOverwriteExistingPhotos &&
                        ((me.previousRecorditem || {}).Value || me.recorditemValueImage)) {
                        Utils.Message.Show(i18next.t('RecorditemEditor.OverwritePhoto.MessageHeader'),
                            i18next.t('RecorditemEditor.OverwritePhoto.MessageBody'),
                            {
                                Yes: () => {
                                    me.recorditemValueImage = file;
                                    me.recorditemValueImage.OID = fileInformation.Identifier;
                                    me.recorditemValueImageMarks = null;
                                    me.imageMarksHaveChanged = true;

                                    me.updateRecorditemValuePicture(dataUrl);
                                    deferred.resolve(fileInformation.Identifier);
                                },
                                No: () => {
                                    deferred.reject('cancelled');
                                }
                            }, null, 1100);

                        return;
                    }

                    me.recorditemValueImage = file;
                    me.recorditemValueImage.OID = fileInformation.Identifier;
                    me.recorditemValueImageMarks = null;
                    me.imageMarksHaveChanged = true;

                    me.$body.find('.btn-mark-picture').removeClass('disabled');

                    me.updateRecorditemValuePicture(dataUrl);
                } else {
                    if (me._additionalFiles.hasOwnProperty(fileInformation.Identifier)) {
                        const additionalImage = me._additionalFiles[fileInformation.Identifier];

                        additionalImage.Content = dataUrl;
                        additionalImage.IsBase64 = true;
                    }

                    me.updateAdditionalFileSection();
                }

                deferred.resolve(fileInformation.Identifier);
            };

            imageObj.onerror = () => deferred.reject();

            return deferred.promise();
        }

        private updateRecorditemValuePicture(dataUrl: string): void {
            if (!dataUrl) {
                return;
            }

            // Bild in Signatur-Canvas setzen, wenn Tab aktiv beim einfügen
            if (this.selectedElement.Type === Enums.ElementType.Signature && this.getCurrentlySelectedTab() === 'canvas') {
                const $signature = this.$body.find('.signature-input');
                $signature.jSignature('clear');
                // Bild in das Canvas zeichnen
                this.drawCanvasImage(dataUrl);
                this.hasCanvasImageChanged = true;
            }

            this.$body.find('.recorditem-value-image')
                .replaceWith(`<img src="${dataUrl}" class="recorditem-value-image" />`);

            this.initMarks();

            this.$body.find('.recorditem-value-image').on('load', () => {
                Utils.ResizeModalWindow(this.$win);
            });
        }

        // shared
        private onBtnDeleteFileClick(evt: Event) {
            evt.stopPropagation();

            if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint) && Session.LastKnownAPIVersion >= 7) {
                return;
            }

            const $file = $(evt.currentTarget).parents('.file');
            const isTemporary = $file.hasClass('temporary-file');
            let filename: string;

            if (isTemporary) {
                const temporaryIdentifier = $file.data('tmpoid');

                if (!temporaryIdentifier ||
                    !(this._additionalFiles || {}).hasOwnProperty(temporaryIdentifier) ||
                    this.usedPictures.indexOf(temporaryIdentifier) !== -1) {
                    return;
                }

                filename = this._additionalFiles[temporaryIdentifier].Filename;
                delete this._additionalFiles[temporaryIdentifier];
            } else {
                filename = $file.data('filename');

                if (!filename) {
                    return;
                }

                if (this._additionalFiles.hasOwnProperty(filename)) {
                    delete this._additionalFiles[filename];
                }

                const idx = Utils.GetIndex(this.previousRecorditem.AdditionalFiles, filename, 'Filename');

                if (idx === -1) {
                    return;
                }

                if (this.previousRecorditem.AdditionalFiles[idx].OID && Session.IsSmartDeviceApplication) {
                    this.removedFilesSyncOID.push(this.previousRecorditem.AdditionalFiles[idx].OID);
                }
            }

            if (filename) {
                this.removedFiles[filename] = true;
            }

            $file.next().remove();
            $file.remove();

            const filesCount = this.$files.find('li.file:not(.placeholder)').length;

            this.$additonalFiles.find('.badge')
                .text(filesCount);

            if (filesCount === 0) {
                this.$files.find('li').remove();

                this.$additonalFiles.find('.badge')
                    .addClass('hidden');

                this.$additonalFiles.find('.files')
                    .append(Templates.RecorditemEditor.AddNewPhotoHint());

                Utils.ResizeModalWindow(this.$win);
            }
        }

        private onTemporaryFileClick(evt: Event) {
            evt.stopImmediatePropagation();

            const $file = $(evt.currentTarget).parents('.file');

            //can only open temporary images
            if (!Utils.IsImage($file.data('mimetype'))) {
                return;
            }

            const issue = IssueView.GetCurrentIssue();
            const location = View.CurrentView === Enums.View.Form && issue ?
                DAL.Elements.GetByOID(issue.AssignedElementOID) :
                Session.CurrentLocation;
            const canWriteComments = Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyOnCheckpoints, true, location);
            const canUserEditFile = Session.LastKnownAPIVersion < 7 || Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint);

            let uri: string = $file.data('tmp-uri');
            let tmpFileOID: string;
            let marks;
            let title: string;
            let description: string;

            if (!uri) {
                uri = $file.find('img').attr('src');
                tmpFileOID = $file.data('tmpoid');
            }

            for (let oid in this._additionalFiles) {
                const file = this._additionalFiles[oid];

                if (!!file.FileURI && file.FileURI === uri ||
                    file.OID && file.OID === tmpFileOID) {
                    marks = file.Marks;
                    title = file.Title;
                    description = file.Description;
                    break;
                }
            }

            Utils.OpenTemporaryImages([{
                Filename: uri,
                OID: tmpFileOID,
                MimeType: Enums.MimeType.Image,
                Marks: marks,
                Title: title,
                Descriptions: description
            }],
                uri,
                canUserEditFile ? $.proxy(this.updateImageMarks, this) : null,
                this.selectedElement.Title,
                canWriteComments,
                Utils.GetImagesFromFiles(this.selectedElement.Files)
            );
        }

        private onExistingFileClick(evt: Event) {
            evt.stopImmediatePropagation();

            const $file = $(evt.currentTarget).parents('.file');
            const mimeType = $file.data('mimetype');
            const filename = $file.data('filename');
            const issue = IssueView.GetCurrentIssue();
            const location = View.CurrentView === Enums.View.Form && issue ?
                DAL.Elements.GetByOID(issue.AssignedElementOID) :
                Session.CurrentLocation;
            const canWriteComments = Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyOnCheckpoints, true, location) /* && !SyncCenter.GetIsSynchronisationInProgress();*/

            if (Utils.IsImage(mimeType)) {
                const files = $.extend(true, [], this.previousRecorditem.Images);
                const canUserEditFile = Session.LastKnownAPIVersion < 7 || Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint);

                if (this._additionalFiles && files.length) {
                    for (let identifier in this._additionalFiles) {
                        const file = this._additionalFiles[identifier];
                        const existingFile: Model.Files.File = Utils.Where(files, 'Filename', '===', identifier);

                        if (!existingFile) {
                            continue;
                        }

                        existingFile.Marks = file.Marks;
                        existingFile.Title = file.Title;
                        existingFile.Description = file.Description;
                    }
                }

                Utils.OpenImages(
                    files,
                    filename,
                    false,
                    null,
                    canUserEditFile ? $.proxy(this.updateImageMarks, this) : null,
                    this.selectedElement.Title,
                    canWriteComments,
                    Utils.GetImagesFromFiles(this.selectedElement.Files));
            } else {
                const file: Model.IFileProperties = Utils.Where(this.previousRecorditem.AdditionalFiles, 'Filename', '===', filename);

                Utils.OpenFile(filename,
                    false,
                    Utils.InArray(DAL.Files.GetVideoFileExtensions(), Utils.GetFileExtension(filename)),
                    file ? file.Title : this.selectedElement.Title,
                    mimeType);
            }
        }

        private onEditFileInformationClick(evt: Event) {
            const $file = $(evt.currentTarget).parents('.file');
            const isTemporary = $file.hasClass('temporary-file');

            if (evt) {
                evt.stopImmediatePropagation();
            }

            const readonly = this.isReadonly || (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint) && Session.LastKnownAPIVersion >= 7);
            const identifier = isTemporary ? $file.data('tmpoid') : $file.data('filename');
            let file;

            if (Utils.HasProperties(this._additionalFiles)) {
                if (Utils.IsValidGuid(identifier)) {
                    file = this._additionalFiles[identifier];
                } else {
                    for (let oid in this._additionalFiles) {
                        const tmpFile = this._additionalFiles[oid];
                        tmpFile.OID = oid;

                        if (tmpFile.FileURI === identifier || tmpFile.OID === identifier) {
                            file = tmpFile;
                        }
                    }
                }
            }

            if (!Utils.IsSet(file) && this.previousRecorditem && (this.previousRecorditem.AdditionalFiles || []).length) {
                for (let fCnt = 0, fLen = this.previousRecorditem.AdditionalFiles.length; fCnt < fLen; fCnt++) {
                    const tmpFile = this.previousRecorditem.AdditionalFiles[fCnt];

                    if (tmpFile.Filename === identifier || tmpFile.OID === identifier) {
                        file = Utils.Clone(tmpFile);
                        file.ModificationType = Enums.AdditionalImageModificationType.MODIFIED;
                    }
                }
            }

            if (Utils.IsSet(file)) {
                Utils.FileEditor.Show({
                    Title: file.Title || file.Filename,
                    Description: file.Description,
                    Readonly: readonly,
                    OnSave: readonly ? null : (title: string, description: string) => this.updateFileInformation($file, file, title, description)
                });
            }
        }

        private updateFileInformation($file, file: Model.Files.RawFile, title: string, description: string) {
            if (!file) {
                return;
            }

            Utils.UpdateFileTitleAndDescription(file, title, description);

            if (file.OID && this._additionalFiles.hasOwnProperty(file.OID)) {
                this._additionalFiles[file.OID].Title = title;
                this._additionalFiles[file.OID].Description = description;
            } else if (file.Filename && this._additionalFiles.hasOwnProperty(file.Filename)) {
                this._additionalFiles[file.Filename].Title = title;
                this._additionalFiles[file.Filename].Description = description;
            } else {
                file.Title = title;
                file.Description = description;

                this._additionalFiles[file.Filename] = file;
            }

            const $fileHeader = $file.find('.file-header');
            $fileHeader.toggleClass('has-description', !!file.Description);
            $fileHeader.find('.file-title').text(file.Title);
        }

        // reposition files
        private onImagesDragOver(evt: Event) {
            evt.preventDefault();
        }

        private onImagesDragEnd(evt: Event) {
            evt.preventDefault();
        }
        /* End file event handlers */

        private setUsedPictures() {
            this.usedPictures = [];
            this.newCorrectiveActions.forEach((a) => {
                if (a.Issue && (a.Issue.Files || []).length) {
                    a.Issue.Files.forEach((f) => {
                        if (this.usedPictures.indexOf(f.OID) === -1) {
                            this.usedPictures.push(f.OID);
                        }
                    });
                }
            });
        }

        private setImageFootersVisibility() {
            this.$files.find('.footer.hidden')
                .removeClass('hidden');

            this.usedPictures.forEach((identifier: string) => {
                if (!identifier) {
                    return;
                }

                const $img = this.$files.find(`.file[data-tmpoid="${identifier}"]`);

                if (!$img.length) {
                    return;
                }

                $img.find('.footer .icon-bin2')
                    .addClass('hidden');
            });
        }

        private replaceSmallInformationSection() {
            if (!(this.$body.find('.small-information').length)) {
                return;
            }

            const recorditem = this.previousRecorditem || {
                Images: Utils.HasProperties(this._additionalFiles) ? [] : null,
                Comments: (this.newComments || []).length ? this.newComments : null,
                CorrectiveActions: (this.newCorrectiveActions || []).length ? this.newCorrectiveActions : null
            };

            const $additionalInformation = $(Templates.RecorditemEditor.AdditionalInformation({
                PreviousRecorditem: recorditem,
                AdditionalFiles: this._additionalFiles,
                SortedFiles: [],
                IsReadonly: this.isReadonly
            }));

            this.$body.find('.small-information').before($additionalInformation);
            this.$body.find('.small-information').remove();

            this.$additonalFiles = this.$body.find('.additional-files');
            this.$files = this.$additonalFiles.find('.files');
            this.$correctiveActions = this.$body.find('.corrective-actions');
            this.$correctiveActionList = this.$correctiveActions.find('.corrective-action-list');
            this.$commentsWrapper = this.$body.find('.comments-wrapper');
            this.$commentSection = this.$commentsWrapper.find('.comment-section');

            if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint) && Session.LastKnownAPIVersion >= 7) {
                this.$additonalFiles.addClass('readonly');
                this.$additonalFiles.find('input[type="file"]').remove();
                this.$additonalFiles.find('.file-scroller').addClass('readonly');
            }
            if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyCommentsOnCheckpoint) && Session.LastKnownAPIVersion >= 7) {
                this.$commentSection.addClass('readonly');
            }
            if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue) && Session.LastKnownAPIVersion >= 7) {
                this.$correctiveActions.addClass('readonly');
            }

            this.bindAdditionalInformationEvents();
        }

        private createNewCorrectiveActionFromImageAndHide(evt: Event, imageIdentifiers: string[], checkpoint: Model.Elements.Element): Deferred {
            checkpoint = checkpoint || this.selectedElement;
            const recorditem = this.previousRecorditem ?
                <Model.Recorditem>{ ElementOID: checkpoint.OID, OID: this.previousRecorditem.OID } :
                <Model.Recorditem>{ ElementOID: checkpoint.OID, OID: this.recorditemOID };
            const closePromise = CreateNewCorrectiveAction(evt, imageIdentifiers, recorditem, checkpoint, this._additionalFiles, $.proxy(this.onAfterCorrectiveIssueSaved, this));
            this.hideInBackground(closePromise);
            return closePromise;
        }

        public CreateNewCorrectiveActionFromCommentAndHide(evt: Event, comment: Model.Comment, checkpoint: Model.Elements.Element, zIndex?: number): Deferred {
            checkpoint = checkpoint || this.selectedElement;
            const recorditem = this.previousRecorditem ?
                <Model.Recorditem>{ ElementOID: checkpoint.OID, OID: this.previousRecorditem.OID } :
                <Model.Recorditem>{ ElementOID: checkpoint.OID, OID: this.recorditemOID };
            const closePromise = CreateNewCorrectiveAction(evt, null, recorditem, checkpoint, this._additionalFiles, $.proxy(this.onAfterCorrectiveIssueSaved, this), comment.Text, zIndex);
            this.hideInBackground(closePromise);
            return closePromise;
        }

        public CreateCorrectiveActionFromMetaData(data: { Issue: Model.Issues.Issue, AdditionalFiles: Dictionary<any> }) {
            const action = {
                IsIssue: true,
                IsTemporary: true,
                Issue: data.Issue,
                OID: data.Issue.OID,
                State: Enums.ActionState.Done,
                AdditionalFiles: null
            };

            if (Utils.HasProperties(data.AdditionalFiles)) {
                action.AdditionalFiles = data.AdditionalFiles;
            }

            return action;
        }

        private hasValueChanged(newValue?: any): boolean {
            if (!this.previousRecorditem) {
                return true;
            }

            const previousValue = this.previousRecorditem.Value || '';
            newValue = this.getValue(newValue) || '';

            if (newValue == null && previousValue == null) {
                return false;
            }

            switch (this.selectedElement.Type) {
                case Enums.ElementType.Date:
                case Enums.ElementType.Time:
                    const tmpPreviousDate = new Date(previousValue);
                    const tmpNewDate = new Date(newValue);

                    if (tmpPreviousDate.getTime() !== tmpNewDate.getTime()) {
                        return true;
                    }

                    return false;
                case Enums.ElementType.Photo:
                    if (this.hasReferenceImage) {
                        return true;
                    }

                    if (Session.IsSmartDeviceApplication) {
                        if (this.isSimplified) {
                            return true;
                        } else {
                            return (this.$body.find('img').data('filename') || this.$body.find('img').attr('src')) !== this.previousRecorditem.Value;
                        }
                    }

                    return !!this.recorditemValueImage;
                case Enums.ElementType.MultiListBox:
                    return !Utils.Equals(previousValue, newValue, 'array');
                case Enums.ElementType.Signature:
                    if (this.hasReferenceImage) {
                        return true;
                    }

                    if (!Session.IsSmartDeviceApplication) {
                        if (this.getCurrentlySelectedTab() === 'file-input' ||
                            (this.getCurrentlySelectedTab() === 'canvas' && this.hasCanvasImageChanged))
                            return !!this.recorditemValueImage;
                    }

                    return this.$win.find('.signature-input').data('is-initialized') && !!this.$win.find('.signature-input').jSignature('getData', 'base30')[1];
                case Enums.ElementType.Users:
                    if (!previousValue || !newValue) {
                        return true;
                    }

                    const tmpPrevious = JSON.parse(previousValue);
                    const tmpNew = JSON.parse(newValue);

                    if (!tmpPrevious && !tmpNew) {
                        return false
                    } else if ((!tmpPrevious && tmpNew) ||
                        (tmpPrevious && !tmpNew)) {
                        return true;
                    }

                    return !(Utils.Equals(tmpPrevious.Users, tmpNew.Users, 'array') && Utils.Equals(tmpPrevious.Teams, tmpNew.Teams, 'array'));
                case Enums.ElementType.IndividualData:
                    if (!previousValue || !newValue) {
                        return true;
                    }

                    const tmp = (this.selectedElement.AdditionalSettings || {}).Types[0];
                    const tmpPreviousValue = previousValue[tmp];
                    const tmpNewValue = newValue[tmp];

                    return !Utils.Equals(tmpPreviousValue, tmpNewValue, 'array');
                default:
                    return previousValue != newValue;
            }
        }

        private getValue(value?: any): any {
            if (!this.$win) {
                return value;
            }

            switch (this.selectedElement.Type) {
                case Enums.ElementType.Checkbox:
                    return this.$body.find('div[data-value]:not(.desaturate, .hidden)').data('value');
                case Enums.ElementType.Number:
                    value = $.trim(this.$body.find('input[type="number"]').val());
                    value = value.replace(/,/g, '.');

                    if (/(^[<>][+-]?\d+(\.\d+)?$)|(^[<>]?[+-]?\d+(\.\d+)?e[+-]?\d+(\.\d+)?$)|(^n{2}$)|(^ni$)|(^n\.(n\.|i\.)$)/.test(value)) {
                        return value;
                    } else {
                        if (!this.selectedElement.Decimals) {
                            if (!isNaN(parseInt(value, 10))) {
                                return parseInt(value, 10);
                            }
                        } else if (!isNaN(parseFloat(value))) {
                            return parseFloat(value);
                        }
                    }

                    return null;
                case Enums.ElementType.Date:
                case Enums.ElementType.Time:
                    const result = new Date(this.$body.find('input[type="text"]').data('timestamp'));

                    return Utils.DateTime.ToGMTString(result);
                case Enums.ElementType.Photo:
                    if (Session.IsSmartDeviceApplication && !this.isSimplified) {
                        return this.$body.find('img').data('filename') || this.$body.find('img').data('tmp-filepath') || this.$body.find('img').attr('src') || null;
                    }

                    return this.selectedFilename || null;
                case Enums.ElementType.Scancode:
                    return this.$body.find('input[type="text"]').val() || null;
                case Enums.ElementType.LocationCode:
                    return this.$body.find('input[type="text"]').data('raw-value') || null;
                case Enums.ElementType.ListBox:
                    return $.map(this.$body.find('.selection .selected'), function(li: HTMLLIElement) {
                        return $(li).data('value').toString();
                    })[0] || null;
                case Enums.ElementType.MultiListBox:
                    {
                        const tmp = $.map(this.$body.find('.selection .selected'), function(li: HTMLLIElement) {
                            return $(li).data('value').toString();
                        });

                        return (tmp && tmp.length) ? tmp : null;
                    }
                case Enums.ElementType.Memo:
                    {
                        return $.trim(this.$body.find('textarea').val()) || null;
                    }
                case Enums.ElementType.Signature:
                    const $signature = this.$win.find('.signature-input');
                    if ((Session.IsSmartDeviceApplication || this.getCurrentlySelectedTab() === 'canvas') &&
                        $signature.data('is-initialized') && !!$signature.jSignature('getData', 'base30')[1]) {
                        return uuid() + '.png';
                    } else if (this.previousRecorditem && !this.recorditemValueImage) {
                        if (!$signature.data('is-cleared')) {
                            return this.previousRecorditem.Value;
                        } else {
                            return null;
                        }
                    }

                    return this.selectedFilename;
                case Enums.ElementType.Users:
                    {
                        let tmp = this.$body.find('input[type="text"]').data('raw-value');
                        if (!tmp || tmp == 'null') {
                            return null;
                        }

                        if ($.isPlainObject(tmp)) {
                            return JSON.stringify(tmp);
                        }

                        return tmp;
                    }
                case Enums.ElementType.IndividualData:
                    {
                        value = {};
                        const tmp = (this.selectedElement.AdditionalSettings || {}).Types[0];

                        if (!!tmp && !!this.$body.find('input[type="text"]').data('val')) {
                            value[tmp] = this.$body.find('input[type="text"]').data('val').toString().split('|');

                            return value;
                        }

                        return null;
                    }
                case Enums.ElementType.TelephoneNumber:
                    return $.trim(this.$body.find('input[type="tel"]').val()) || null;
                case Enums.ElementType.EMailAddresses:
                    {
                        const $mailAddresses = this.$body.find('.multi-value-input .value');
                        const mailAddresses = $.map($mailAddresses, function(valueNode: HTMLElement) {
                            return $(valueNode).data('value');
                        });

                        return mailAddresses.length ? mailAddresses : null;
                    }
                default:
                    return $.trim(this.$body.find('input[type="text"]').val()) || null;
            }
        }

        private getStringValue(value: any): string {
            if (!value) {
                return '';
            }

            switch (this.selectedElement.Type) {
                case Enums.ElementType.Date:
                    value = new Date(value);

                    return Utils.DateTime.DateToString(value);
                case Enums.ElementType.Time:
                    value = new Date(value);

                    return Utils.DateTime.TimeToString(value);
                case Enums.ElementType.Users:
                    const raw: UserPicker.IUserTeamsSelection = typeof value === 'string' ? JSON.parse(value) : value;
                    if (!raw) {
                        return '-/-';
                    }

                    const strValue: string[] = [];

                    if (raw.Users && raw.Users.length) {
                        const users = $.map(raw.Users, function(oid: string) {
                            const tmp = DAL.Users.GetByOID(oid);
                            return tmp ? tmp.Title : null;
                        });

                        if (users.length) {
                            strValue.push(i18next.t('Misc.User_plural') + ': ');

                            if (users.length > 1) {
                                strValue.push(users.slice(0, -1).join(', '));
                                strValue.push(` ${i18next.t('Misc.And')} `);
                            }

                            strValue.push(users[users.length - 1]);
                        }
                    }

                    if (raw.Teams && raw.Teams.length) {
                        const teams = $.map(raw.Teams, function(oid: string) {
                            const tmp = DAL.Teams.GetByOID(oid);
                            return tmp ? tmp.Title : null;
                        });

                        if (teams.length) {
                            if (strValue.length) {
                                // Trennzeichen zwischen User und Team
                                strValue.push(' - ');
                            }

                            strValue.push(i18next.t('Misc.Team_plural') + ': ');

                            if (teams.length > 1) {
                                strValue.push(teams.slice(0, -1).join(', '));
                                strValue.push(` ${i18next.t('Misc.And')} `);
                            }

                            strValue.push(teams[teams.length - 1]);
                        }
                    }

                    if (!strValue.length) {
                        return '-/-';
                    }

                    return strValue.join('');
                case Enums.ElementType.IndividualData:
                    {
                        let strValue: string = null;

                        const tmp = DAL.IndividualData.GetByType((this.selectedElement.AdditionalSettings || {}).Types[0]);

                        if ((tmp || []).length && !!value) {
                            strValue = value.split('|').map(function(id: string) {
                                const entity = Utils.Where(<Array<any>>tmp, 'ID', '==', id);
                                return entity ? entity.Title : i18next.t('Misc.Unknown');
                            }).join(', ').replace(/<br>/ig, ' - ');
                        }

                        if (!!strValue) {
                            return strValue;
                        }
                    }
            }
        }

        private getIndividualDataValue(valueObject: any): string | null {
            const type = (this.selectedElement.AdditionalSettings || {}).Types[0];

            if (!type || !valueObject) {
                return null;
            }

            if (valueObject && (valueObject[type] || []).length) {
                return valueObject[type].join('|');
            }
        }

        private getStepAttributeValue() {
            if (!this.selectedElement.Decimals) {
                return 1;
            } else {
                return '0.' + Utils.PadLeft('1', this.selectedElement.Decimals, '0');
            }
        }

        private $getInputByType() {
            let $input = $('<div></div>');
            let prevValue = (this.previousRecorditem || {}).Value;
            let formattedValue: string;

            switch (this.selectedElement.Type) {
                case Enums.ElementType.Checkbox:
                    if (!this.previousRecorditem && !this.isReadonly) {
                        prevValue = true;
                    }

                    $input = $(Templates.RecorditemEditor.Bool({
                        PreviousValue: prevValue,
                        IsReadonly: this.isReadonly
                    }));
                    break;
                case Enums.ElementType.Number:
                    $input = $(Templates.RecorditemEditor.Number({
                        PreviousValue: prevValue,
                        Unit: DAL.Properties.GetByOID(this.selectedElement.UnitOID),
                        Step: this.getStepAttributeValue(),
                        IsReadonly: this.isReadonly,
                        BluetoothIsAvailable: Session.IsSmartDeviceApplication && Utils.BluetoothDeviceManager.IsBluetoothThermometerConnected(),
                        ScaleIsAvailable: Session.IsSmartDeviceApplication && Utils.ScaleDeviceManager.IsAnyDeviceConnected()
                    }));
                    break;
                case Enums.ElementType.Line:
                    $input = $(Templates.RecorditemEditor.Line({
                        PreviousValue: Utils.UnescapeHTMLEntities(prevValue),
                        IsReadonly: this.isReadonly,
                        SuggestedValues: this.selectedElement.SuggestedValues
                    }));
                    break;
                case Enums.ElementType.Date:
                case Enums.ElementType.Time:
                    if (prevValue) {
                        prevValue = new Date(prevValue);
                        formattedValue = this.getStringValue(prevValue);
                    }

                    $input = $(Templates.RecorditemEditor.DateTime({
                        FormattedPreviousValue: formattedValue,
                        Timestamp: prevValue ? prevValue.getTime() : '',
                        IsReadonly: this.isReadonly
                    }));
                    break;
                case Enums.ElementType.Scancode:
                    $input = $(Templates.RecorditemEditor.ScanCode({
                        PreviousValue: Utils.UnescapeHTMLEntities(prevValue),
                        IsReadonly: this.isReadonly
                    }));
                    break;
                case Enums.ElementType.Photo:
                    if (!!prevValue) {
                        formattedValue = Session.IsSmartDeviceApplication ?
                            (Utils.GetResourcesPath() + prevValue) :
                            `${Session.BaseURI}images/${prevValue}`;
                    }

                    $input = $(Templates.RecorditemEditor.Photo({
                        Overwrite: !!prevValue,
                        Filename: prevValue,
                        PreviousValue: formattedValue,
                        IsReadonly: this.isReadonly
                    }));

                    $input.eq(0)
                        .on('load', $.proxy(this.onAfterImageLoaded, this))
                        .on('error', Utils.OnImageNotFound);

                    break;
                case Enums.ElementType.LocationCode:
                    if (prevValue) {
                        let title = '';
                        const element = DAL.Elements.GetByOID(prevValue);

                        if (element) {
                            const parent = DAL.Elements.GetByOID(element.ParentOID);

                            title = element.Title;

                            if (parent && parent.Title) {
                                title += ` @ ${parent.Title}`;
                            }
                        }

                        formattedValue = Utils.UnescapeHTMLEntities(title || i18next.t('Misc.Unknown'));
                    }

                    $input = $(Templates.RecorditemEditor.LocationCode({
                        PreviousValue: prevValue,
                        FormattedPreviousValue: formattedValue,
                        IsReadonly: this.isReadonly
                    }));
                    break;
                case Enums.ElementType.ListBox:
                    if (!prevValue) {
                        prevValue = this.isReadonly ? '' : 1;
                    }

                    $input = $(Templates.RecorditemEditor.ListBox({
                        Structure: this.selectedElement.Structure,
                        PreviousValue: prevValue.toString(),
                        MapStructureToImages: this.selectedElement.hasOwnProperty('AdditionalSettings') && this.selectedElement.AdditionalSettings.MapStructureToImages,
                        IsReadonly: this.isReadonly
                    }));
                    $input.find('img')
                        .on('load', $.proxy(this.onAfterImageLoaded, this))
                        .on('error', Utils.OnImageNotFound);
                    break;
                case Enums.ElementType.MultiListBox:
                    $input = $(Templates.RecorditemEditor.MultiListBox({
                        Structure: this.selectedElement.Structure,
                        PreviousValue: (prevValue || []).length ? prevValue.join(',') : '',
                        MapStructureToImages: this.selectedElement.hasOwnProperty('AdditionalSettings') && this.selectedElement.AdditionalSettings.MapStructureToImages,
                        IsReadonly: this.isReadonly
                    }));
                    $input.find('img')
                        .on('load', $.proxy(this.onAfterImageLoaded, this))
                        .on('error', Utils.OnImageNotFound);
                    break;
                case Enums.ElementType.Memo:
                    $input = $(Templates.RecorditemEditor.Memo({
                        PreviousValue: Utils.UnescapeHTMLEntities(prevValue),
                        IsReadonly: this.isReadonly,
                        SuggestedValues: this.selectedElement.SuggestedValues
                    }));
                    break;
                case Enums.ElementType.Signature:
                    if (!!prevValue && this.isReadonly) {
                        formattedValue = Session.IsSmartDeviceApplication ?
                            (Utils.GetResourcesPath() + prevValue) :
                            `${Session.BaseURI}images/${prevValue}`;
                    }

                    let showImageUpload = !Session.IsSmartDeviceApplication;

                    if (this.selectedElement.AdditionalSettings &&
                        Utils.IsSet(this.selectedElement.AdditionalSettings.SignatureAllowImageUpload)) {
                        showImageUpload = showImageUpload && this.selectedElement.AdditionalSettings.SignatureAllowImageUpload;
                    }

                    $input = $(Templates.RecorditemEditor.Signature({
                        ShowImageUpload: showImageUpload,
                        PreviousValue: formattedValue,
                        IsReadonly: this.isReadonly
                    }));

                    break;
                case Enums.ElementType.Users:
                    if (!!prevValue) {
                        formattedValue = Utils.UnescapeHTMLEntities(this.getStringValue(prevValue));
                    }

                    $input = $(Templates.RecorditemEditor.Users({
                        PreviousValue: prevValue,
                        FormattedPreviousValue: formattedValue,
                        IsReadonly: this.isReadonly
                    }));
                    break;
                case Enums.ElementType.IndividualData:
                    prevValue = this.getIndividualDataValue(prevValue);

                    $input = $(Templates.RecorditemEditor.IndividualData({
                        FormattedPreviousValue: Utils.UnescapeHTMLEntities(this.getStringValue(prevValue)),
                        PreviousValue: prevValue,
                        IsReadonly: this.isReadonly
                    }));
                    break;
                case Enums.ElementType.TelephoneNumber:
                    $input = $(Templates.RecorditemEditor.TelephoneNumber({
                        PreviousValue: Utils.UnescapeHTMLEntities(prevValue),
                        IsReadonly: this.isReadonly
                    }));
                    break;
                case Enums.ElementType.EMailAddresses:
                    try {
                        if (typeof prevValue === 'string') {
                            prevValue = JSON.parse(prevValue);
                        } else if (!(prevValue instanceof Array)) {
                            prevValue = null;
                        }
                    }
                    catch {
                        prevValue = null;
                    }

                    $input = $(Templates.RecorditemEditor.EMailAddresses({
                        PreviousValue: prevValue,
                        IsReadonly: this.isReadonly
                    }));
                    break;
                default:
                    $input = $(Templates.RecorditemEditor.Default({
                        PreviousValue: Utils.UnescapeHTMLEntities(prevValue),
                        IsReadonly: this.isReadonly
                    }));
            }

            return $input;
        }

        private getCurrentTimestamp(): string {
            return Utils.DateTime.ToGMTString(new Date());
        }

        private applyNewCorrectiveActionsToDictionary() {
            if ((this.newCorrectiveActions || []).length) {
                this.newCorrectiveActions.forEach(
                    (i) => ParameterList.ApplyNewCorrectiveActionToDictionary(i.Issue, this.previousRecorditem));
            }
        }

        private getAdditionalImageIndex(recorditem: Model.Recorditem, filename: string): number {
            if ((recorditem.AdditionalFiles || []).length) {
                for (let iCnt = 0, iLen = recorditem.AdditionalFiles.length; iCnt < iLen; iCnt++) {
                    const file = recorditem.AdditionalFiles[iCnt];

                    if (file.Filename === filename) {
                        return iCnt;
                    }
                }
            }
        }

        private prepareRecorditemForSave(value?: any, ignoreValueFromUI?: boolean, calculated?: boolean,
            skipSave: boolean = false, referenceData?: ReferenceData): Model.Recorditem {
            if (!this.selectedElement) {
                if (!skipSave && ignoreValueFromUI) {
                    this.destroy();
                }

                return null;
            }

            referenceData = referenceData || {};

            const now = this.getCurrentTimestamp();
            let existingCorrectiveActionIssues: any[];
            let isSignatureDrawn: boolean;
            let recorditem = new Model.Recorditem({
                OID: this.recorditemOID,
                ElementOID: this.selectedElement.OID,
                ElementRevisionOID: this.selectedElement.RevisionOID,
                CreatorOID: Session.User.OID,
                EditorOID: Session.User.OID,
                CreationTimestamp: now,
                ModificationTimestamp: now,
                Revision: 1,
                Row: this.issueInfos ? this.issueInfos.Row : null
            });

            Utils.Spinner.Show(null, true);

            ParameterList.PreviousRecorditemsOfElements[this.selectedElement.OID] = this.selectedElement.LastRecorditem;

            if (Session.IsSmartDeviceApplication) {
                this.unbindDataWedgeEvent();

                if (this.bluetoothEnabled) {
                    Utils.BluetoothDeviceManager.RemoveDeviceConnectedCallback('RecorditemEditor');
                    this.unbindBluetoothEvent();
                }

                if (this.scaleEnabled) {
                    this.unbindScaleEvent();
                }
            }

            this.hasReferenceImage = !!referenceData.ImageValue;
            this.updatePreviousRecorditem = this.selectedElement.IsAdhoc ? false : !this.hasValueChanged(value);

            // check if additional images/files have been removed
            if (this.updatePreviousRecorditem && this.hasAdditionalFilesRemoved()) {
                // require a new revision on deletion, no update
                this.updatePreviousRecorditem = false;
            }

            if (this.issueInfos &&
                this.issueInfos.Issue &&
                this.previousRecorditem &&
                this.previousRecorditem.Type === Enums.RecorditemType.UNRECORDED) {
                this.updatePreviousRecorditem = false;
                this.previousRecorditem.Type = Enums.RecorditemType.NORMAL;
            }

            this.applyNewCorrectiveActionsToDictionary();

            if (!this.selectedElement.IsAdhoc && this.selectedElement.Type === Enums.ElementType.Signature &&
                !Session.IsSmartDeviceApplication && this.getCurrentlySelectedTab() === 'canvas') {
                isSignatureDrawn = (this.$win.find('.signature-input').data('is-initialized') &&
                    !!this.$win.find('.signature-input').jSignature('getData', 'base30')[1]) || this.hasCanvasImageChanged;
            }

            if (!this.updatePreviousRecorditem) {
                if (!Session.IsSmartDeviceApplication &&
                    (this.selectedElement.Type === Enums.ElementType.Photo || this.selectedElement.Type === Enums.ElementType.Signature &&
                        (this.getCurrentlySelectedTab() === 'file-input' || this.getCurrentlySelectedTab() === 'canvas' && this.hasCanvasImageChanged))) {
                    if (this.recorditemValueImage) {
                        const fileExtension = Utils.GetFileExtension(this.recorditemValueImage.name);
                        const filename = uuid() + fileExtension;

                        value = filename;
                        this.selectedFilename = value;
                    } else if (!this.hasReferenceImage) {
                        value = null;
                    }
                }
            } else if (this.previousRecorditem) {
                this.recorditemOID = this.previousRecorditem.OID;

                if (!Session.IsSmartDeviceApplication &&
                    (this.selectedElement.Type === Enums.ElementType.Photo && !this.selectedFilename ||
                        this.selectedElement.Type === Enums.ElementType.Signature &&
                        (this.getCurrentlySelectedTab() === 'file-input' &&
                            !this.selectedFilename ||
                            this.getCurrentlySelectedTab() === 'canvas' && !isSignatureDrawn)) ||
                    this.selectedElement.Type === Enums.ElementType.Signature && !isSignatureDrawn) {
                    this.selectedFilename = this.previousRecorditem.Value;
                }
            }

            if (!this.isSimplified) {
                if (!ignoreValueFromUI) {
                    value = this.getValue(value);
                }

                if (this.previousRecorditem) {
                    if (this.selectedElement.IsAdhoc) {
                        recorditem.OID = this.previousRecorditem.OID;
                        recorditem.WorkflowInformation = this.previousRecorditem.WorkflowInformation;
                        existingCorrectiveActionIssues = this.previousRecorditem.CorrectiveActions;
                    }

                    if (this.updatePreviousRecorditem && !this.selectedElement.IsAdhoc || View.CurrentView !== Enums.View.Main) {
                        existingCorrectiveActionIssues = this.previousRecorditem.CorrectiveActions;

                        if (this.updatePreviousRecorditem) {
                            recorditem = new Model.Recorditem(this.previousRecorditem);
                        }
                    }
                }

                let existingCorrectiveActions: any[];
                if ((existingCorrectiveActionIssues || []).length) {
                    existingCorrectiveActions = GetPreparedExistingCorrectiveActions(existingCorrectiveActionIssues);
                }

                if ((this.newCorrectiveActions || []).length || (existingCorrectiveActions || []).length) {
                    if (Session.LastKnownAPIVersion < 7 ||
                        Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue)) {
                        recorditem.WorkflowInformation = Utils.ActionWindow.CreateWorkflowInformation(this.newCorrectiveActions, existingCorrectiveActions);
                    }
                }

                if ((this.newCorrectiveActions || []).length) {
                    for (let gi = 0; gi < this.newCorrectiveActions.length; ++gi) {
                        let newCorrectiveAction = this.newCorrectiveActions[gi];

                        if (newCorrectiveAction.Issue != null) {
                            newCorrectiveAction = this.newCorrectiveActions[gi].Issue;
                        }

                        if (newCorrectiveAction.AssignedRecorditemID == null) {
                            continue;
                        }

                        if (!this.selectedElement.IsAdhoc) {
                            ParameterList.ApplyNewCorrectiveActionToDictionary(newCorrectiveAction, recorditem);
                        }
                    }
                }

                this.prepareAdditionalFiles(recorditem);
            } else {
                if (this.previousRecorditem && !this.selectedElement.IsAdhoc) {
                    recorditem = new Model.Recorditem(this.previousRecorditem);

                    recorditem.ModificationTimestamp = now;
                    recorditem.WorkflowInformation = this.previousRecorditem.WorkflowInformation;
                    recorditem.EditorOID = Session.User.OID;
                }

                if (!ignoreValueFromUI) {
                    value = this.getValue(value);
                }
            }

            // Bei Checkbox kann der dritte Status 'null' erfasst werden, wenn in persönlichen Einstellungen aktiviert
            if ((value === null && recorditem.Element.Type != Enums.ElementType.Checkbox) ||
                (recorditem.Element.Type == Enums.ElementType.Checkbox && typeof value == 'undefined')) {
                // Implizites Löschen erlauben, wenn auch die Berechtigung zum Löschen des Prüfpunktes vorliegt
                const options = {
                    IsReadonly: this.isReadonly,
                    DisableDeleteOption: this.disableDeleteOption
                };

                if (IsDeletionAllowed(options, this.selectedElement, this.previousRecorditem)) {
                    // Funktion zum Löschen der Erfassung aufrufen
                    this.onBtnDeleteClick(null, calculated)
                        .fail(() => {
                            // destroy() ausführen, weil beim Fehler keine onSave ausgeführt wird
                            if (!skipSave && ignoreValueFromUI) {
                                this.destroy();
                            }
                        });

                    return null;
                } else {
                    // 'Kein Wert eingegeben' Meldung anzeigen
                    Utils.Message.Show(i18next.t('RecorditemEditor.NoValue.MessageHeader'),
                        i18next.t('RecorditemEditor.NoValue.MessageBody', { Element: this.selectedElement.Title }),
                        { OK: true });
                    Utils.Spinner.Hide();

                    if (!skipSave && ignoreValueFromUI) {
                        this.destroy();
                    }

                    return null;
                }
            }

            if (!this.updatePreviousRecorditem) {
                if (this.issueInfos && this.issueInfos.Issue) {
                    if (+this.issueInfos.Issue.ID) {
                        recorditem.IssueID = this.issueInfos.Issue.ID;
                    } else if (!!this.issueInfos.Issue.OID) {
                        recorditem.IssueOID = this.issueInfos.Issue.OID;
                    }

                    if (!isNaN(this.issueInfos.Row)) {
                        recorditem.Row = this.issueInfos.Row;
                    }

                    recorditem.ResubmissionitemOID = IssueView.GetResubmissionitemOID(recorditem.ElementOID, this.issueInfos.Row);
                }

                if (this.previousRecorditem) {
                    recorditem.CreatorOID = this.previousRecorditem.CreatorOID;
                    recorditem.CreationTimestamp = this.previousRecorditem.CreationTimestamp;

                    if ((+this.previousRecorditem.IssueID || !!this.previousRecorditem.IssueOID) || Session.Mode === Enums.Mode.Information) {
                        recorditem.OID = this.recorditemOID;
                        recorditem.Revision = this.previousRecorditem.Revision + 1;
                        recorditem.PrecedingOID = this.previousRecorditem.OID;
                        recorditem.ID = this.previousRecorditem.ID;
                    }

                    if (this.isSimplified || !Utils.HasProperties(this._additionalFiles) && (this.previousRecorditem.AdditionalFiles || []).length) {
                        const additionalFiles = this.previousRecorditem.AdditionalFiles || [];

                        for (let filename in this.removedFiles) {
                            const idx = Utils.GetIndex(this.previousRecorditem.AdditionalFiles, filename, 'Filename');

                            if (idx !== -1) {
                                this.previousRecorditem.AdditionalFiles.splice(idx, 1);
                            }
                        }

                        additionalFiles.sort(function(a, b) {
                            if (a.Position > b.Position) {
                                return 1;
                            } else {
                                return -1;
                            }
                        });

                        recorditem.AdditionalFiles = additionalFiles;
                    }
                }
            }

            recorditem.IsUpdate = this.updatePreviousRecorditem;
            recorditem.IsImageMarksUpdate = this.imageMarksHaveChanged;

            if (calculated) {
                recorditem.Type = Enums.RecorditemType.CALCULATED;
            } else if (recorditem.Type >= 4) {
                recorditem.Type -= 2;
            } else if (!recorditem.Type) {
                recorditem.Type = Enums.RecorditemType.NORMAL;
            }

            recorditem
                .SetValue(value)
                .SetValueImageMarks(this.recorditemValueImageMarks)
                .SetExistingComments((this.previousRecorditem || {}).Comments)
                .SetNewComments((this.newComments || []).concat(this.getCopyOfComments(recorditem, referenceData.Comments)))
                .SetValueImage(this.recorditemValueImage)
                .SetNewFiles($.extend({}, this._additionalFiles, referenceData.AdditionalImages))
                .SetExistingCorrectiveActionIssues(existingCorrectiveActionIssues)
                .SetOnAfterSavedHandler($.proxy(this.onAfterRecorditemSaved, this))
                .SetOnAfterErrorHandler($.proxy(this.onAfterRecorditemUploadError, this));

            if (Session.IsSmartDeviceApplication) {
                recorditem.SetGetSignature($.proxy(this.getSignatureData, this));
            } else {
                recorditem.SetGetSignature($.proxy(this.getSignatureBlob, this));
            }

            if (referenceData.ImageValue) {
                recorditem.SetGetImageCopy(() => this.getCopyImageBlob(referenceData.ImageValue));
            }

            if (referenceData.AdditionalValueInfo) {
                recorditem.AdditionalValueInfo = referenceData.AdditionalValueInfo;
            }

            if (referenceData.AdditionalImages) {
                recorditem.SetGetAdditionalImageCopy($.proxy(this.getCopyImageBlob, this));

                (recorditem.NewFiles || []).sort((a, b) => a.Position > b.Position ? 1 : -1);

                recorditem.AdditionalFiles = (recorditem.AdditionalFiles || []).concat(recorditem.NewFiles);
                recorditem.Images = (recorditem.Images || []).concat(recorditem.NewFiles);
            }

            if (!skipSave) {
                recorditem.NewCorrectiveActions = this.newCorrectiveActions;

                if (this.removedFilesSyncOID.length && Session.IsSmartDeviceApplication) {
                    window.Database.GetManyByKeys(Enums.DatabaseStorage.SyncEntities, this.removedFilesSyncOID)
                        .then((entities: Model.Synchronisation.IEntityDescription[]) => {
                            const entitiesOIDs = [];
                            for (let i = 0; i < entities.length; i++) {
                                const oid = entities[i].OID;
                                entitiesOIDs.push(oid);
                            }
                            return window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.SyncEntities, entitiesOIDs);
                        }).then(() => {
                            recorditem.Save();
                        });
                } else {
                    recorditem.Save();
                }
            }

            return recorditem;
        }

        private getCopyOfComments(recorditem: Model.Recorditem, referenceComments: Array<Model.IComment>): Array<Model.Comment> {
            if (!(referenceComments || []).length) {
                return [];
            }

            const newComments = [];

            for (const comment of referenceComments) {
                const newComment = new Model.Comment($.extend(true, {}, comment, {
                    OID: uuid(),
                    AssignmentOID: recorditem.OID,
                    AssignmentID: recorditem.ID,
                    IssueOID: recorditem.IssueOID,
                    IssueID: recorditem.IssueID
                }));

                newComments.push(newComment)
            }

            return newComments;
        }

        private showDatePicker(defaultValue: string | Date, onSuccess: Model.DateTimePicker.OnSelectDateFunc, showDeleteButton?: boolean) {
            if (typeof defaultValue === 'string') {
                defaultValue = new Date(defaultValue);
            }

            if (!(defaultValue instanceof Date) || (isNaN(defaultValue.getTime()))) {
                defaultValue = null;
            }

            Utils.DateTimePicker.Show({
                HeaderText: this.isSimplified ? this.selectedElement.Title : null,
                ShowCalendar: true,
                ShowTime: false,
                Clearable: !!showDeleteButton,
                SelectedDateTime: <Date>defaultValue,
                FnSuccess: onSuccess
            });
        }

        private showTimePicker(defaultValue: string | Date, onSuccess: Model.DateTimePicker.OnSelectDateFunc, showDeleteButton?: boolean) {
            if (typeof defaultValue === 'string') {
                defaultValue = new Date(defaultValue);
            }

            if (!(defaultValue instanceof Date) || (isNaN(defaultValue.getTime()))) {
                defaultValue = null;
            }

            Utils.DateTimePicker.Show({
                HeaderText: this.isSimplified ? this.selectedElement.Title : null,
                ShowCalendar: false,
                ShowTime: true,
                Clearable: !!showDeleteButton,
                SelectedDateTime: <Date>defaultValue,
                FnSuccess: onSuccess
            });
        }

        private showUserPicker(previousValue: UserPicker.IUserTeamsSelection) {
            Utils.UserPicker.Show(<UserPicker.IFilter>this.selectedElement.AdditionalSettings,
                previousValue,
                Session.CurrentLocation,
                $.proxy(this.onAfterUsersSelected, this)
            );
        }

        private showIndividualDataPicker(defaultValue: string, onSuccess: Utils.RecorditemEditor.Individual.CallbackFunc, showDeleteButton: boolean = false, filterText: string = null) {
            Utils.RecorditemEditor.Individual.Show(this.selectedElement, onSuccess, defaultValue, filterText, showDeleteButton);
        }

        private getSignatureBlob(callback: (r: Blob) => void): void {
            if (!this.$body) {
                callback(null);
                return;
            }

            //Hochgeladene Bilder zu canvas hinzugügen und anschließend als blob hochladen
            if (this.$body.find('.tab-control li[data-tab="file-input"]').is('.selected')) {
                const srcImage = this.$body.find('.recorditem-value-image')[0];

                const img = new Image();
                const c = document.createElement('canvas');
                const ctx = c.getContext('2d');

                img.onload = function() {
                    c.width = (<any>this).naturalWidth;
                    c.height = (<any>this).naturalHeight;
                    ctx.drawImage(<any>this, 0, 0);
                    c.toBlob(callback);
                };

                img.onerror = function() {
                    callback(null);
                };

                img.src = srcImage.src;
            } else if (callback instanceof Function) {
                // resize on small screen
                const width = this.$body.find('.signature-input').width();
                if (width < 500) {
                    this.resizeSignature(900)
                        .always(() => {
                            this.$body.find('.signature-input canvas')[0].toBlob(callback);
                        });
                } else {
                    this.$body.find('.signature-input canvas')[0].toBlob(callback);
                }
            }
        }

        private getCopyImageBlob(imagePath: string): Deferred {
            const imgUrl = `${Session.BaseURI}images/o/${imagePath}${Utils.GetAuthQueryParameter('?')}`;

            const deferred = $.Deferred();
            const img = new Image();
            const c = document.createElement('canvas');
            const ctx = c.getContext('2d');

            img.onerror = deferred.reject;
            img.onload = function() {
                c.width = (<any>this).naturalWidth;
                c.height = (<any>this).naturalHeight;
                ctx.drawImage(<any>this, 0, 0, c.width, c.height);

                try {
                    c.toBlob((blob) => {
                        deferred.resolve(blob);
                    });
                } catch (err) {
                    // catch possible SecurityError exception
                    deferred.reject();
                }
            };

            if (Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion >= 21) {
                Utils.Http.LoadImage(imgUrl)
                    .then((base64: string) => {
                        img.src = base64;
                    }, () => {
                        // handle image load failures
                        deferred.reject();
                    });
            } else {
                img.crossOrigin = 'use-credentials';
                img.src = imgUrl;
            }

            return deferred.promise();
        }

        private getSignatureData(callback: (r: string[]) => void) {
            // resize on small screen
            const width = this.$win.find('.signature-input').width();
            let deferred: Deferred;
            if (width < 500) {
                deferred = this.resizeSignature(900);
            } else {
                deferred = $.Deferred().resolve();
            }

            deferred.always(() => {
                const data = this.$win.find('.signature-input').jSignature('getData', 'image');

                if (callback instanceof Function) {
                    callback(data);
                }
            });
        }

        private getCurrentlySelectedTab() {
            return this.$body.find('.tab-control .selected').data('tab');
        }

        private renderCorrectiveActionList(): void {
            const correctiveActions: Utils.ActionWindow.ActionEx[] = [];
            const correctiveActionsListed: Dictionary<boolean> = {};

            if (((this.previousRecorditem || {}).CorrectiveActions || []).length) {
                this.previousRecorditem.CorrectiveActions.forEach(function(issue: Model.Issues.Issue) {
                    if (correctiveActionsListed[issue.OID]) {
                        return;
                    }

                    correctiveActions.push({
                        IsIssue: true,
                        IsTemporary: false,
                        Issue: issue,
                        OID: issue.OID,
                        State: Enums.ActionState.Done
                    });
                });
            }

            if (this.newCorrectiveActions.length) {
                this.newCorrectiveActions.forEach(function(action) {
                    if (correctiveActionsListed[action.OID]) {
                        return;
                    }

                    correctiveActions.push(action);
                });

                this.replaceSmallInformationSection();
            }

            correctiveActions.sort(Utils.ActionWindow.SortCorrectiveActionIssuesByID);

            if (this.additionalCorrectiveActions) {
                this.additionalCorrectiveActions.forEach(function(issue: Model.Issues.Issue) {
                    correctiveActions.push({
                        IsIssue: true,
                        IsTemporary: false,
                        Issue: issue,
                        OID: issue.OID,
                        State: Enums.ActionState.Done
                    });
                });
            }

            this.$correctiveActionList.empty();

            if (Object.keys(correctiveActions).length) {
                this.$body.find('.corrective-actions')
                    .off('click.createCorrectiveAction');

                const html = [];
                for (let i = 0; i < correctiveActions.length; i++) {
                    const action = correctiveActions[i];

                    html.push(
                        $(Templates.RecorditemEditor.CorrectiveAction({
                            ID: action.Issue.ID,
                            OID: action.Issue.OID,
                            Title: action.Issue.Title || i18next.t('Misc.Untitled'),
                            IsTemporary: action.IsTemporary || false,
                            Type: action.Issue.Type
                        }))
                    );
                }

                this.$correctiveActionList.append(html);
            } else {
                if (!this.isReadonly) {
                    if (Session.LastKnownAPIVersion < 7 ||
                        Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue)) {
                        this.$body.find('.corrective-actions')
                            .on('click.createCorrectiveAction', $.proxy(this.createNewCorrectiveActionFromImageAndHide, this));
                    }
                }

                this.$correctiveActionList.append(Templates.RecorditemEditor.CorrectiveActionInfo({
                    Text: i18next.t('RecorditemEditor.AddNewCorrectiveAction'),
                    AdditionalClasses: 'empty'
                }));
            }
        }

        private initVariables(options: ShowOptions) {
            this.$overlay = Utils.Overlay.Generate('olRecorditemEditor', 1050);

            if (this.selectedElement.Type === Enums.ElementType.Users &&
                this.previousRecorditem &&
                $.isPlainObject(this.previousRecorditem.Value)) {
                this.previousRecorditem.Value = JSON.stringify(this.previousRecorditem.Value);
            }

            let correctiveActionTotalCount = 0;
            if (this.additionalCorrectiveActions) {
                correctiveActionTotalCount += this.additionalCorrectiveActions.length;
            }
            if (!this.selectedElement.IsAdhoc &&
                this.previousRecorditem &&
                this.previousRecorditem.CorrectiveActions) {
                correctiveActionTotalCount += this.previousRecorditem.CorrectiveActions.length;
            }

            this.$win = $(Templates.RecorditemEditor.Window({
                Element: this.selectedElement,
                PreviousRecorditem: !this.selectedElement.IsAdhoc ? this.previousRecorditem : null,
                IsSimplified: this.isSimplified,
                IsReadonly: this.isReadonly,
                DisableDeleteOption: options.DisableDeleteOption,
                AdditionalCorrectiveActions: this.additionalCorrectiveActions,
                CorrectiveActionsCount: correctiveActionTotalCount || null
            }));

            $('body')
                .addClass('modal-open')
                .append(this.$win);

            this.$win = $(this.$win.getSelector());
            this.$body = this.$win.find('.modal-body');

            if (!this.isSimplified) {
                this.$additonalFiles = this.$body.find('.additional-files.additional-files');
                this.$files = this.$additonalFiles.find('.files');

                if (this.$files.length !== 0) {
                    this.replaceAdditionalFiles(false);
                }

                if (Session.IsRunningOnIOS && this.$files != null) {
                    this.$files.css('overflow-x', 'scroll');
                }

                this.$correctiveActions = this.$body.find('.corrective-actions');
                this.$correctiveActionList = this.$correctiveActions.find('.corrective-action-list');
                this.$commentsWrapper = this.$body.find('.comments-wrapper');
                this.$commentSection = this.$commentsWrapper.find('.comment-section');

                if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint) && Session.LastKnownAPIVersion >= 7) {
                    this.$additonalFiles.addClass('readonly');
                    this.$additonalFiles.find('input[type="file"]').remove();
                    this.$additonalFiles.find('.file-scroller').addClass('readonly');
                }

                if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyCommentsOnCheckpoint) && Session.LastKnownAPIVersion >= 7) {
                    this.$commentSection.addClass('readonly');
                }

                if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue) && Session.LastKnownAPIVersion >= 7) {
                    this.$correctiveActions.addClass('readonly');
                }
            }

            this.$btnSave = this.$win.find('.btn-save');
            this.$btnHistory = this.$win.find('.btn-history');
            this.$btnInformation = this.$win.find('.btn-information');
            this.$btnAbort = this.$win.find('.btn-abort');
            this.$btnDelete = this.$win.find('.btn-delete');

            this.hasCanvasImageChanged = false;
            this._additionalFiles = {};
            this.newCorrectiveActions = [];
            this.usedPictures = [];
        }

        private initMarks(): void {
            const $imageContainer = this.$win.find('.recorditem-image');

            $imageContainer.find('div.marks').remove();

            if (!this.recorditemValueImageMarks) {
                return;
            }

            const $image = $imageContainer.find('img');
            const img = $image.get(0);
            const isLoaded = img.complete && img.naturalHeight !== 0;

            const me = this;
            const applyMarks = function() {
                $imageContainer.append(`<div class="marks" style="position: absolute; top: ${img.offsetTop}px; left: ${img.offsetLeft}px; height: ${$image.height()}px; width: ${$image.width()}px;"></div>`);
                $imageContainer.find('div').append(Utils.FixSVGSizeForView(me.recorditemValueImageMarks));
            }

            if (isLoaded) {
                applyMarks();
            } else {
                $image.on('load', applyMarks);
            }
        }

        private initSignature(): void {
            const $signature = this.$body.find('.signature-input');
            if (!!$signature.data('is-initialized')) {
                return;
            }

            this.onResizeSignatureDiv();

            $signature.jSignature({
                'background-color': 'transparent',
                'decor-color': 'transparent',
                height: '100%',
                width: '100%',
                lineWidth: 2
            });

            // vorherigen Wert als Bild anzeigen
            this.placePreviousSignature();

            $signature.data('is-initialized', true);
        }

        private placePreviousSignature(): Deferred {
            if (!!this.$win.find('.signature-input').data('is-cleared')) {
                return $.Deferred().resolve().promise();
            }

            const prevValue = (this.previousRecorditem || {}).Value;
            if (!prevValue) {
                return $.Deferred().resolve().promise();
            }

            const $ctxTest = this.$win.find('.signature-input canvas').get(0);
            if (!$ctxTest.getContext) {
                return $.Deferred().resolve().promise();
            }

            const formattedValue = Session.IsSmartDeviceApplication ?
                (Utils.GetResourcesPath() + prevValue) :
                `${Session.BaseURI}images/${prevValue}`;

            return this.drawCanvasImage(formattedValue);
        }

        private drawCanvasImage(formattedValue: string): Deferred {
            const deferred = $.Deferred();

            const tmpImg = new Image();
            tmpImg.onload = () => {
                const $ctx = this.$win.find('.signature-input canvas').get(0);
                const ctx = $ctx.getContext('2d');
                const ctxWidth = $ctx.width;
                const ctxHeight = $ctx.height;

                // größe umberechnen
                let targetHeight = tmpImg.height;
                let targetWidth = tmpImg.width;
                if (ctxWidth < tmpImg.width) {
                    const ratio = ctxWidth / tmpImg.width;
                    targetHeight = Math.abs(tmpImg.height * ratio);
                    targetWidth = Math.abs(tmpImg.width * ratio);
                } else if (ctxHeight < tmpImg.height) {
                    const ratio = ctxHeight / tmpImg.height;
                    targetHeight = Math.abs(tmpImg.height * ratio);
                    targetWidth = Math.abs(tmpImg.width * ratio);
                }
                const offsetX = Math.abs((ctxWidth - targetWidth) / 2);
                const offsetY = Math.abs((ctxHeight - targetHeight) / 2);
                ctx.drawImage(tmpImg, offsetX, offsetY, targetWidth, targetHeight);
                deferred.resolve();
            };
            tmpImg.onerror = deferred.reject;
            tmpImg.src = formattedValue;

            return deferred.promise();
        }

        private unbindBluetoothEvent() {
            if (!Session.IsSmartDeviceApplication) {
                return;
            }

            if (!this.selectedElement ||
                this.selectedElement.Type !== Enums.ElementType.Number ||
                !Utils.BluetoothDeviceManager.IsBluetoothThermometerConnected()) {
                return;
            }

            const devices = Utils.BluetoothDeviceManager.GetConnectedDevices();
            const deferreds = [];

            for (let key in devices) {
                if (devices[key] instanceof Model.Bluetooth.Thermometers.BluetoothThermometer) {
                    deferreds.push((<Model.Bluetooth.Thermometers.BluetoothThermometer>devices[key]).StopListenForMeasureButtonPressAndReadTemperature());
                }
            }

            $.when(deferreds).then(() => {
                this.bluetoothEnabled = true;
            });
        }

        private bindBluetoothEvent() {
            if (!Session.IsSmartDeviceApplication) {
                return;
            }

            // prüfen ob Thermometer verbunden sind
            if (!this.selectedElement ||
                this.selectedElement.Type !== Enums.ElementType.Number ||
                !Utils.BluetoothDeviceManager.IsBluetoothThermometerConnected()) {
                return;
            }

            const devices = Utils.BluetoothDeviceManager.GetConnectedDevices();
            let deferreds = [];

            for (let key in devices) {
                if (devices[key] instanceof Model.Bluetooth.Thermometers.BluetoothThermometer) {
                    deferreds.push((<Model.Bluetooth.Thermometers.BluetoothThermometer>devices[key]).StopListenForMeasureButtonPressAndReadTemperature());
                }
            }

            if (!deferreds.length) {
                return;
            }

            $.when(deferreds)
                .then(() => {
                    this.bluetoothEnabled = true;
                    deferreds = [];

                    for (let key in devices) {
                        if (devices[key] instanceof Model.Bluetooth.Thermometers.BluetoothThermometer) {
                            const btDevice = (<Model.Bluetooth.Thermometers.BluetoothThermometer>devices[key]);
                            deferreds.push(btDevice.ListenForMeasureButtonPressAndReadTemperature($.proxy(this.onAfterGotMeasuredTemperature, this), () => {
                                this.bluetoothEnabled = false;
                            }));
                        }
                    }
                }, () => {
                    this.bluetoothEnabled = false;
                });
        }

        private unbindScaleEvent() {
            if (!Session.IsSmartDeviceApplication) {
                return;
            }

            if (!Utils.ScaleDeviceManager.IsAnyDeviceConnected() ||
                !this.selectedElement ||
                this.selectedElement.Type !== Enums.ElementType.Number) {
                return;
            }

            Utils.ScaleDeviceManager.RemoveDeviceListenerCallback('RecorditemEditor');
            this.scaleEnabled = false;
        }

        private bindScaleEvent() {
            if (!Session.IsSmartDeviceApplication) {
                return;
            }

            if (!Utils.ScaleDeviceManager.IsAnyDeviceConnected() ||
                !this.selectedElement ||
                this.selectedElement.Type !== Enums.ElementType.Number) {
                return;
            }

            Utils.ScaleDeviceManager.AddDeviceListenerCallback('RecorditemEditor', $.proxy(this.onWeightReceived, this));
            this.scaleEnabled = true;
        }

        private unbindDataWedgeEvent() {
            CodeScanner.RemoveFromCallbackStack(DATAWEDGE_CALLBACK_ID);
        }

        private bindDataWedgeEvent() {
            this.unbindDataWedgeEvent();

            CodeScanner.AddToCallbackStack($.proxy(this.onAfterGotDataWedgeScan, this), false, DATAWEDGE_CALLBACK_ID);
        }

        private bindEvents() {
            this.$btnHistory.on('click', $.proxy(this.onBtnHistoryClick, this));
            this.$btnInformation.on('click', $.proxy(this.onBtnInformationClick, this));
            this.$btnAbort.on('click', $.proxy(this.destroy, this));
            $(window).off('.recorditem')
                .on('resize.recorditem', $.proxy(this.onResizeWindow, this));

            if (!this.isReadonly) {
                this.$btnSave.on('click.save', $.proxy(this.onBtnSaveClick, this));
                this.$btnDelete.on('click', $.proxy(this.onBtnDeleteClick, this));

                if (this.selectedElement.Type === Enums.ElementType.Checkbox) {
                    this.$body.find('div[data-value]').on('click', (evt: Event) => {
                        const $selector = $(evt.currentTarget);
                        this.onChangeBooleanValue($selector);
                    });
                } else if (Utils.InArray([Enums.ElementType.Date, Enums.ElementType.Time, Enums.ElementType.Users, Enums.ElementType.IndividualData], this.selectedElement.Type)) {
                    this.$body.find('input[type="text"]').on('click', $.proxy(this.onInputClick, this));
                } else if (Utils.InArray([Enums.ElementType.ListBox, Enums.ElementType.MultiListBox], this.selectedElement.Type)) {
                    this.$body.on('click', '.selection li', $.proxy(this.onSelectionItemClick, this));
                } else if (this.selectedElement.Type === Enums.ElementType.LocationCode) {
                    this.$body.find('.btn-start-scanner').on('click', $.proxy(this.onBtnStartScannerClick, this));

                    if (Session.IsRunningOnIOS && Session.NfcEnabled) {
                        this.$body.find('.btn-start-nfc-scanner').on('click', App.StartNfcScanner);
                    }

                    const issue = IssueView.GetCurrentIssue();
                    const restrictScannableLocations = Session.IsSmartDeviceApplication &&
                        issue && Utils.InArray([Enums.IssueType.Inspection, Enums.IssueType.Scheduling, Enums.IssueType.Form], issue.Type) &&
                        ((this.selectedElement.AdditionalSettings || {}).RestrictScannableLocations);

                    this.$body.find('input[type="text"]')
                        .on('click', $.proxy(restrictScannableLocations ? this.onBtnStartScannerClick : this.showLocationPicker, this));
                } else if (this.selectedElement.Type === Enums.ElementType.Scancode) {
                    if (Session.IsSmartDeviceApplication) {
                        this.$body.find('.btn-start-scanner').on('click', $.proxy(this.onBtnStartScannerClick, this));
                        this.$body.find('input[type="text"]').on('click', $.proxy(this.onBtnStartScannerClick, this));
                    }
                } else if (this.selectedElement.Type === Enums.ElementType.Signature) {
                    this.$body.find('.tab-control').on('click', 'li', $.proxy(this.onTabControlItemClick, this));
                    this.$body.find('.content .btn-clear-signature').on('click', $.proxy(this.onBtnClearSignatureClick, this));
                } else if (this.selectedElement.Type === Enums.ElementType.EMailAddresses) {
                    this.$body.find('input[type="text"]')
                        .on('focusin', $.proxy(this.onMailAddressFocusIn, this))
                        .on('focusout', $.proxy(this.onMailAddressFocusOut, this))
                        .on('keydown', $.proxy(this.onMailAddressKeyDown, this))
                        .on('keyup', $.proxy(this.onMailAddressKeyUp, this))
                        .on('input', $.proxy(this.onMailAddressInput, this))
                        .on('blur', $.proxy(this.onMailAddressBlur, this));
                    this.$body.find('.multi-value-input').on('click', '.delete-value', $.proxy(this.onRemoveMailAddressClick, this));
                } else if (this.selectedElement.Type === Enums.ElementType.Number) {
                    this.$body.find('input[type="number"]').on('keypress', $.proxy(this.onNumberKeyPress, this));
                } else if (this.selectedElement.Type === Enums.ElementType.TelephoneNumber) {
                    this.$body.find('input[type="tel"]').on('keypress', $.proxy(this.onKeyPress, this));
                } else {
                    this.$body.find('input[type="text"]').on('keypress', $.proxy(this.onKeyPress, this));
                }

                if (this.$selectFromSuggestedValues.length) {
                    this.$selectFromSuggestedValues.on('click', $.proxy(this.onBtnSelectFromSuggestedValuesClick, this));
                }
            }

            if (!this.isReadonly) {
                if (Session.IsSmartDeviceApplication && this.selectedElement.Type === Enums.ElementType.Photo) {
                    this.$body.find('.btn-take-picture').press($.proxy(this.onBtnTakePicturePress, this), $.proxy(this.onBtnTakePictureClick, this), 500);
                } else if (!Session.IsSmartDeviceApplication && Utils.InArray([Enums.ElementType.Photo, Enums.ElementType.Signature], this.selectedElement.Type)) {
                    this.$body.find('.btn-take-picture > input[type="file"]').on('change', (evt: Event) => {
                        this.onFileInput(<HTMLInputElement>evt.currentTarget, true);
                    });
                }

                // Zeichnen auf Bildern wird erst ab API-Version 24 unterstützt
                if (Session.LastKnownAPIVersion >= 24) {
                    this.$body.find('.btn-mark-picture').removeClass('hidden');
                    this.$body.find('.btn-mark-picture, .recorditem-image')
                        .on('click', () => this.displayInImageViewer());
                }
            }

            this.bindAdditionalFilesEvents();
            this.bindAdditionalInformationEvents();

            if (Session.IsSmartDeviceApplication) {
                this.bindDataWedgeEvent();

                if (Utils.BluetoothDeviceManager) {
                    this.bindBluetoothEvent();
                    Utils.BluetoothDeviceManager.AddDeviceConnectedCallback('RecorditemEditor', $.proxy(this.bindBluetoothEvent, this))
                }

                // bind scale event
                if (Utils.ScaleDeviceManager) {
                    this.bindScaleEvent();

                    this.$body.find('.input-group-addon.scale').on('click', () => {
                        Utils.ScaleDeviceManager.RequestWeight();
                    });
                }
            }
        }

        private unbindEvents() {
            $(window).off('.recorditem');
        }

        private bindAdditionalFilesEvents(): void {
            if (this.isSimplified) {
                // Event für Signatur-Pad binden
                if (this.selectedElement.Type == Enums.ElementType.Signature &&
                    !Session.IsSmartDeviceApplication) {
                    $(document).on('paste.drop-in', $.proxy(this.onPasteFile, this));
                }

                return;
            }

            this.unbindAdditionalFilesEvents();

            if (this.$files) {
                this.$files.on('click', '.file .file-header', $.proxy(this.onEditFileInformationClick, this));
                this.$files.on('click', 'li:not(.placeholder):not(.temporary-file)[data-filename] .file-content', $.proxy(this.onExistingFileClick, this));
                this.$files.find('img')
                    .on('load', $.proxy(this.onAfterImageLoaded, this))
                    .on('error', Utils.OnImageNotFound);
            }

            if (Session.LastKnownAPIVersion < 7 ||
                Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint)) {

                if (!Session.IsSmartDeviceApplication) {
                    $(document).on('paste.drop-in', $.proxy(this.onPasteFile, this));
                }

                if (!this.isReadonly) {
                    if (this.$files) {
                        this.$files.on('click', '.file .delete', $.proxy(this.onBtnDeleteFileClick, this));
                        this.$files.on('click', '.file.temporary-file:not(.placeholder) .file-content', $.proxy(this.onTemporaryFileClick, this));

                        if (this.$files != null) {
                            ChangeImageOrder.Init(this.$files, 'files', 'file', null, 'recorditemEditor');
                        }
                    }

                    if (this.$body) {
                        this.$body.find('.additional-files').on('change', 'input[type="file"]', (evt: Event) => { this.onFileInput(<HTMLInputElement>evt.currentTarget) });

                        if (Session.IsSmartDeviceApplication) {
                            if (this.$files && this.$files.find('.file:not(.placeholder)').length) {
                                this.$body.find('.additional-files > .clickable').press($.proxy(this.onBtnTakePicturePress, this), $.proxy(this.onBtnTakePictureClick, this), 500);
                            } else {
                                this.$body.find('.additional-files').press($.proxy(this.onBtnTakePicturePress, this), $.proxy(this.onBtnTakePictureClick, this), 500);
                            }
                        } else {
                            this.$body.find('.additional-files').on('drop', $.proxy(this.onDropFiles, this));
                            this.$body.find('.additional-files').on('dragover', $.proxy(this.onImagesDragOver, this));
                            this.$body.find('.additional-files').on('dragend', $.proxy(this.onImagesDragEnd, this));
                        }
                    }
                }
            }
        }

        private unbindAdditionalFilesEvents(): void {
            ChangeImageOrder.UnBind();

            $(document).off('paste.drop-in');
            this.$body.find('.additional-files > .clickable').off();

            if (this.$files) {
                this.$files.off();
                this.$files.find('img').off();
            }

            if (this.$additonalFiles) {
                this.$additonalFiles.off();
            }
        }

        private displayInImageViewer(showComparison?: boolean): void {
            if (!this.$body) {
                return;
            }

            const filename = this.$body.find('.recorditem-value-image').attr('src');
            const imageOID = this.getValue();

            if (!filename && !imageOID) {
                return;
            }

            const imageViewerOptions: Utils.ImageViewerOptions = {
                Images: [<Model.IFileProperties>{
                    Filename: filename,
                    OID: imageOID,
                    MimeType: Enums.MimeType.Image,
                    Marks: this.recorditemValueImageMarks
                }],
                StartFilename: filename,
                CanWriteComments: true,
                FullPathGiven: true,
                ImageEditedCallback: $.proxy(this.updateImageMarks, this),
                FooterInformation: {
                    Title: this.selectedElement ? this.selectedElement.Title : null
                },
                closeFn: (hasDownloadUpdate: boolean) => {
                    if (hasDownloadUpdate) {
                        const srcFilename = this.$body.find('.recorditem-value-image').attr('src');
                        this.updateRecorditemValuePicture(srcFilename);
                    }
                },
                ComparisonImages: Utils.GetImagesFromFiles(this.selectedElement.Files),
                ComparisonOnStart: showComparison || false
            };

            ImageViewerManager.Show(imageViewerOptions);
        }

        private bindAdditionalInformationEvents(): void {
            if (this.isSimplified) {
                return;
            }

            this.bindAdditionalFilesEvents();

            if (this.$correctiveActions) {
                this.$correctiveActions.find('.clickable').off('click');
            }

            if (this.$correctiveActionList) {
                this.$correctiveActionList.find('li[data-oid]:not(.is-temporary)').off('click');
                this.$correctiveActionList.find('li[data-oid].is-temporary').off('click');
                this.$correctiveActionList.find('li[data-oid] a').off('click');
            }

            if (Session.LastKnownAPIVersion < 7 ||
                Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyCommentsOnCheckpoint)) {

                if (this.$commentsWrapper) {
                    this.$commentsWrapper.on('click', $.proxy(this.onCommentsWrapperClick, this));
                }

                if (this.$correctiveActionList) {
                    if (!this.isReadonly) {
                        this.$correctiveActions.find('.corrective-actions').on('click', $.proxy(this.createNewCorrectiveActionFromImageAndHide, this));
                        this.$correctiveActions.find('.clickable').on('click', $.proxy(this.createNewCorrectiveActionFromImageAndHide, this));
                    }

                    if (!this.$body.find('.small-information').length) {
                        this.$correctiveActionList.on('click', 'li[data-oid]:not(.is-temporary)', $.proxy(this.onExistingCorrectiveActionClick, this));
                        this.$correctiveActionList.on('click', 'li[data-oid].is-temporary', $.proxy(this.onTemporaryCorrectiveActionClick, this));
                        this.$correctiveActionList.on('click', 'li[data-oid] a', $.proxy(this.onStartCorrectiveActionRecordingClick, this));
                    }
                }
            } else {
                if (this.$commentsWrapper) {
                    this.$commentsWrapper.off('click');
                }

                if (this.$correctiveActions && this.$correctiveActionList) {
                    this.$correctiveActions.off('click');
                    this.$correctiveActionList.off('click');
                }
            }
        }

        private prepareRecorditem(recorditem: Model.Recorditem): Model.Recorditem {
            if (!recorditem) {
                return;
            }

            if ((recorditem.AdditionalFiles || []).length) {
                recorditem.Images = recorditem.AdditionalFiles.filter((file) => {
                    return Utils.IsImage(file.MimeType);
                });
            }

            return recorditem;
        }

        private showLocationPicker() {
            if (this.elementPickerPopup && this.elementPickerPopup.IsVisible()) {
                return;
            }

            let defaultValue: any;

            if (!this.isSimplified) {
                defaultValue = this.$body.find('input[type="text"]').data('raw-value');
            }

            this.elementPickerPopup = Utils.ElementPickerPopup.Show({
                EnableKeywordFilter: true,
                RootItem: DAL.Elements.Root,
                Items: DAL.Elements.GetAll(),
                SelectedItemIdentifier: defaultValue || (this.previousRecorditem || {}).Value,
                ShowDeleteButton: !!this.previousRecorditem,
                ShowFloorPlan: false,
                ShowQRCodeScannerButton: Session.IsSmartDeviceApplication,
                ShowNfcScannerButton: Session.IsSmartDeviceApplication,
                OnBtnStartQRCodeScannerClick: (picker: Utils.ElementPickerPopup) => {
                    Utils.StartScanner((result) => {
                        picker.Destroy();
                        this.onValueScanned(result);
                    }, () => {
                        this.onScannerCancelled();
                    }, (error) => {
                        picker.Destroy();
                        this.onScannerError(error);
                    });
                },
                CannotCollapseRoot: true,
                IsReadonly: this.isReadonly,
                FnHideItem: (item: Model.Elements.Element) => Utils.InArray([Enums.ElementType.Root, Enums.ElementType.Location], item.Type),
                OnConfirmSelection: $.proxy(this.onLocationSelected, this),
                OnDestroy: (picker: Utils.ElementPickerPopup) => {
                    if (picker === this.elementPickerPopup) {
                        this.elementPickerPopup = null;
                    }
                }
            });
        }

        private hideInBackground(closePromise: Deferred) {
            if (!closePromise) {
                return;
            }

            if (!this.$win) {
                return;
            }

            this.$win.css('display', 'none');
            closePromise.then(() => {
                this.$win.css('display', 'block');

                if (this.selectedElement && this.$body &&
                    this.selectedElement.Type === Enums.ElementType.Signature) {
                    this.onResizeWindow();
                } else {
                    Utils.ResizeModalWindow(this.$win);
                }
            });
        }

        /* file methods */
        private prepareNewRecorditemValueFile(file: File): Deferred {
            if (!file) {
                return $.Deferred().resolve().promise();
            }
            if (!Utils.IsImage(file.type)) {
                return;
            }

            const tmpOID = uuid();

            return this.readFile(file, tmpOID, true)
                .then($.proxy(this.onAfterFileRead, this));
        }

        private prepareNewAdditionalFile(file: ExtendedFile): Deferred {
            if (!file) {
                return $.Deferred().resolve().promise();
            }

            const tmpOID = uuid();

            const fileExtension = Utils.GetFileExtension(file.name);
            const filename = tmpOID + fileExtension;

            file.OID = tmpOID;
            file.Title = Utils.GetFileTitle(file.name, filename);
            file.ModificationType = Enums.AdditionalImageModificationType.CREATED;
            file.MimeType = file.type;
            file.Position = this.getMaxFilePosition() + 1;
            file.IsTemporary = true;
            file.IsImage = Utils.IsImage(file.type);
            file.IsAudio = Utils.IsAudio(file.type);
            file.Filename = filename;
            file.newFilename = filename;

            this._additionalFiles[tmpOID] = file;

            return this.readFile(file, tmpOID, false)
                .then($.proxy(this.onAfterFileRead, this));
        }

        private readFile(file: ExtendedFile, tmpOID: string, isRecorditemValue: boolean = false): Deferred {
            if (!Session.IsSmartDeviceApplication && isRecorditemValue) {
                this.updatePreviousRecorditem = false;
                this.$btnSave.text(i18next.t('Misc.Okay'));
            }

            return Utils.ReadFile(file, tmpOID, { IsRecorditemValue: isRecorditemValue });
        }

        private updateAdditionalFileSection(): void {
            this.replaceSmallInformationSection();
            this.replaceAdditionalFiles(true);

            Utils.ResizeModalWindow(this.$win);
            this.scrollFileScrollerToEnd();
        }

        private replaceAdditionalFiles(includeTemporaryFiles: boolean): void {
            const $additionalFilesRow = $(Templates.RecorditemEditor.AdditionalFileRow({
                PreviousRecorditem: this.previousRecorditem,
                SortedFiles: this.sortAdditionalFiles(includeTemporaryFiles),
                IsReadonly: this.isReadonly
            }));

            this.$additonalFiles.parents('.file-row').replaceWith($additionalFilesRow);

            this.$additonalFiles = $additionalFilesRow.find('.additional-files');
            this.$files = this.$additonalFiles.find('.files');

            if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint) && Session.LastKnownAPIVersion >= 7) {
                this.$additonalFiles.addClass('readonly');
                this.$additonalFiles.find('input[type="file"]').remove();
                this.$additonalFiles.find('.file-scroller').addClass('readonly');
            }

            this.$additonalFiles.find('.badge')
                .removeClass('hidden')
                .text(this.$files.find('li.file:not(.placeholder)').length);

            this.$additonalFiles.find('.form-control')
                .removeClass('no-pointer-events');

            this.$additonalFiles.find('input[type="file"]').val('');

            this.bindAdditionalFilesEvents();
        }

        private askWhetherToReUseFilesAndCorrectiveAction(fileIdentifiers: string[], checkpoint: Model.Elements.Element): Deferred {
            return AskWhetherToReUseFilesInCorrectiveAction(fileIdentifiers)
                .then(() => {
                    return this.createNewCorrectiveActionFromImageAndHide(null, fileIdentifiers, checkpoint);
                });
        }

        private scrollFileScrollerToEnd() {
            if (!(this.$files instanceof $) || !this.$files.length) {
                return;
            }

            this.$files.scrollLeft(this.$files.get(0).scrollWidth);
        }

        private getMaxFilePosition(): number {
            let filePosition = -1;

            if (this.previousRecorditem) {
                (this.previousRecorditem.AdditionalFiles || []).forEach((file) => {
                    if (+file.Position > filePosition) {
                        filePosition = file.Position;
                    }
                });
            }

            if (this._additionalFiles) {
                for (let ident in this._additionalFiles) {
                    if (+this._additionalFiles[ident].Position > filePosition) {
                        filePosition = this._additionalFiles[ident].Position;
                    }
                }
            }

            return filePosition;
        }

        private updateImageMarks(fileIdentifier: string, marks: string): void {
            const filename = this.$body.find('.recorditem-value-image').attr('src');
            const imageOID = this.getValue();

            if ((this.recorditemValueImage &&
                this.recorditemValueImage.OID == fileIdentifier) ||
                (imageOID == fileIdentifier || filename == fileIdentifier)) {
                // handle Recorditem Image
                this.recorditemValueImageMarks = marks;
                this.imageMarksHaveChanged = true;

                // Markierungen anzeigen
                this.initMarks();
                return;
            }

            let file;
            let $listItem;

            if (this._additionalFiles) {
                if (Utils.IsValidGuid(fileIdentifier) &&
                    this._additionalFiles[fileIdentifier]) {
                    file = this._additionalFiles[fileIdentifier];
                    file.OID = fileIdentifier;
                    file.FileContent = this.$win.find(`li[data-tmpoid="${fileIdentifier}"] img`).attr('src')
                } else {
                    for (let identifier in this._additionalFiles) {
                        const tmpFile = this._additionalFiles[identifier];

                        if (identifier === fileIdentifier || tmpFile.FileURI === fileIdentifier || tmpFile.Filename == fileIdentifier) {
                            file = tmpFile;
                            break;
                        }
                    }
                }
            }

            if (!file) {
                if (this.previousRecorditem && (this.previousRecorditem.AdditionalFiles || []).length) {
                    for (let fCnt = 0, fLen = this.previousRecorditem.AdditionalFiles.length; fCnt < fLen; fCnt++) {
                        const tmpFile = this.previousRecorditem.AdditionalFiles[fCnt];

                        if (tmpFile.Filename === fileIdentifier ||
                            (tmpFile.OID === fileIdentifier && Utils.IsValidGuid(fileIdentifier))) {
                            file = Utils.Clone(tmpFile);
                            file.ModificationType = Enums.AdditionalImageModificationType.MODIFIED;
                            this._additionalFiles[file.Filename] = file;

                            break;
                        }
                    }
                }
            }

            if (!file) {
                return;
            }

            file.Marks = marks;

            if (file.IsTemporary) {
                if (file.IsBase64) {
                    file.FileContent = this.$win.find(`li[data-tmpoid="${file.OID}"] img`).attr('src');
                }

                $listItem = this.$win.find(`li[data-tmpoid="${file.OID}"]`);
            } else {
                $listItem = this.$win.find(`li[data-filename="${file.Filename}"]`);
            }

            const $image = $(Templates.RecorditemEditor.AdditionalFile(file));

            if (this.usedPictures.indexOf(fileIdentifier) !== -1) {
                $image.find('.footer')
                    .addClass('hidden');
            }

            $listItem.replaceWith($image);
            $image.find('img').on('load', $.proxy(this.onAfterImageLoaded, this));
        }

        private hasAdditionalFilesRemoved(): boolean {
            if (!this.selectedElement.IsAdhoc && ((this.previousRecorditem || {}).AdditionalFiles || []).length) {
                for (let filename in this.removedFiles) {
                    const idx = Utils.GetIndex(this.previousRecorditem.AdditionalFiles, filename, 'Filename');

                    if (idx !== -1) {
                        return true;
                    }
                }
            }

            return false;
        }

        private prepareAdditionalFiles(recorditem: Model.Recorditem): void {
            if (!this.selectedElement.IsAdhoc && ((this.previousRecorditem || {}).AdditionalFiles || []).length) {
                for (let filename in this.removedFiles) {
                    const idx = Utils.GetIndex(this.previousRecorditem.AdditionalFiles, filename, 'Filename');

                    if (idx !== -1) {
                        this.previousRecorditem.AdditionalFiles.splice(idx, 1);
                    }
                }

                recorditem.AdditionalFiles = this.previousRecorditem.AdditionalFiles;
            }

            const sortedImages = this.sortAdditionalFiles(false);

            if (Utils.HasProperties(this._additionalFiles)) {
                recorditem.NewFiles = [];

                for (let identifier in this._additionalFiles) {
                    const additionalImage = this._additionalFiles[identifier];

                    switch (additionalImage.ModificationType) {
                        case Enums.AdditionalImageModificationType.CREATED:
                            const filename = additionalImage.Filename;

                            const idx = Utils.GetIndex(sortedImages, identifier, 'OID');

                            if (idx !== -1) {
                                sortedImages.splice(idx, 1);
                            }

                            recorditem.NewFiles.push({
                                Filename: filename,
                                OID: identifier,
                                Position: additionalImage.Position,
                                MimeType: additionalImage.MimeType || Enums.MimeType.Image,
                                Marks: additionalImage.Marks,
                                Title: additionalImage.Title,
                                Description: additionalImage.Description,
                                IsImage: additionalImage.IsImage,
                                IsAudio: additionalImage.IsAudio
                            });
                            break;
                        case Enums.AdditionalImageModificationType.MODIFIED:
                            const additionalImageIndex = this.getAdditionalImageIndex(recorditem, identifier);

                            recorditem.AdditionalFiles[additionalImageIndex].Marks = additionalImage.Marks;
                            recorditem.AdditionalFiles[additionalImageIndex].Title = additionalImage.Title;
                            recorditem.AdditionalFiles[additionalImageIndex].Description = additionalImage.Description;
                            break;
                    }
                }

                const additionalFilesSorted = (sortedImages || []).concat(recorditem.NewFiles);

                (additionalFilesSorted || []).sort((a, b) => a.Position > b.Position ? 1 : -1);

                recorditem.AdditionalFiles = additionalFilesSorted;
                recorditem.Images = additionalFilesSorted;
            }
        }

        /**
         * Sortiert die Dateien | Sortiert die aktuellen Bilder am Recorditem und die temporären
         * @param includeTemporaryFilesInResult Sollen temporäre "neue" Dateien mit zurückgegeben werden
         * @return Die sortierte Liste der Dateien
         **/
        private sortAdditionalFiles(includeTemporaryFilesInResult: boolean): Array<any> {
            const filePositionDic = {};
            const $sortedFiles = (this.$files || $).find('li:not(.placeholder)');

            if ($sortedFiles.length > 0) {
                const lng = $sortedFiles.length;

                for (let pos = 0; pos < lng; ++pos) {
                    const $sortedFile = $($sortedFiles[pos]);

                    if ($sortedFile.attr('data-tmpoid')) {
                        filePositionDic[$sortedFile.data('tmpoid')] = pos;
                    }

                    if ($sortedFile.attr('data-filename')) {
                        filePositionDic[$sortedFile.data('filename')] = pos;
                    }
                }
            }

            let filesToReposition = [];
            if (this.previousRecorditem != null && this.previousRecorditem.AdditionalFiles != null) {
                const previousRecorditemFilesToIgnore = Object.keys(this.removedFiles).concat(Object.keys(this._additionalFiles || {}));
                filesToReposition = Utils.All(this.previousRecorditem.AdditionalFiles, 'Filename', 'not in', previousRecorditemFilesToIgnore);
            }

            filesToReposition = filesToReposition.concat(Utils.ObjectToArray(this._additionalFiles));

            const getPos = (file: Model.Files.File) => {
                let pos: number;

                if (file.OID) {
                    pos = filePositionDic[file.OID];
                }

                if (pos == null && file.Filename) {
                    pos = filePositionDic[file.Filename];
                }

                if (pos == null) {
                    //Wenn unbekannt dann zum Ende
                    pos = Number.POSITIVE_INFINITY;
                    console.log('Unbekannte Position:', file);
                }

                return pos;
            };

            const sortPos = (posA: number | null, posB: number | null) => {
                if ((posA == null && posB == null) ||
                    (posA === Number.POSITIVE_INFINITY && posB === Number.POSITIVE_INFINITY)) {
                    return 0;
                }

                if (posA == null || posA === Number.POSITIVE_INFINITY) {
                    return 1;
                }

                if (posB == null || posB === Number.POSITIVE_INFINITY) {
                    return -1;
                }

                return posA - posB;
            };

            const sortByPos = (a: { Position: number; }, b: { Position: number; }) => sortPos(a.Position, b.Position);

            const sortByDic = (a: Model.Files.File, b: Model.Files.File) => sortPos(getPos(a), getPos(b));

            (filesToReposition || []).sort(Utils.HasProperties(filePositionDic) ? sortByDic : sortByPos);

            for (let fCnt = 0; fCnt < filesToReposition.length; fCnt++) {
                const file = filesToReposition[fCnt];

                if (file == null) {
                    continue;
                }

                file.IsTemporary = this._additionalFiles != null && this._additionalFiles[file.OID] != null && this._additionalFiles[file.OID].IsTemporary;
                file.Position = fCnt;
            }

            if (includeTemporaryFilesInResult) {
                return filesToReposition;
            }

            if (this.previousRecorditem == null || this.previousRecorditem.AdditionalFiles == null) {
                return [];
            }

            return this.previousRecorditem.AdditionalFiles.sort(sortByPos);
        }
        /* end file methods */

        public Show(options: ShowOptions) {
            const location = View.CurrentView === Enums.View.Form || View.CurrentView === Enums.View.Inspection ?
                DAL.Elements.GetByOID(IssueView.GetCurrentIssue().AssignedElementOID) :
                Session.CurrentLocation;

            if (RecorditemEditor.IsVisible()) {
                return;
            }

            if (!options || !options.Element) {
                return;
            }

            this.removedFiles = {};
            this.removedFilesSyncOID = [];

            this.isSimplified = false;
            this.isReadonly = options.IsReadonly || false;
            this.disableDeleteOption = options.DisableDeleteOption || false;

            let defaultValue: boolean;
            if (options.PreviousRecorditem && options.PreviousRecorditem.IsDefault) {
                defaultValue = options.PreviousRecorditem.Value;

                if (options.PreviousRecorditem.Revision) {
                    delete options.PreviousRecorditem.IsDefault;
                }
            }

            if (options.PreviousRecorditem && (options.PreviousRecorditem.IsDummy || options.PreviousRecorditem.IsDefault)) {
                options.PreviousRecorditem = null;
            }

            this.additionalCorrectiveActions = options.AdditionalCorrectiveActions;
            this.selectedElement = options.Element;
            this.onSave = options.OnAfterRecorditemSaved;
            this.onAfterDestroy = options.OnAfterWindowDestroyed;
            this.onAfterCommentCreated = options.OnAfterCommentCreated;
            this.onAfterCommentDeleted = options.OnAfterCommentDeleted;
            this.previousRecorditem = !this.selectedElement.IsAdhoc ? this.prepareRecorditem(options.PreviousRecorditem) : null;

            if (View.CurrentView !== Enums.View.Scheduling &&
                this.selectedElement.IsAdhoc &&
                !Utils.UserHasRight(Session.User.OID, Enums.Rights.AdhocRecording, true, location)) {
                Utils.Spinner.Hide();
                Utils.ParameterHistory.Show(this.selectedElement.OID);

                return;
            }

            this.recorditemValueImageMarks = null;
            this.imageMarksHaveChanged = false;

            if (this.previousRecorditem) {
                if (this.previousRecorditem.CorrectiveActions instanceof Array &&
                    !this.previousRecorditem.CorrectiveActions.length) {
                    delete this.previousRecorditem.CorrectiveActions;
                }

                if (this.previousRecorditem.AdditionalValueInfo) {
                    this.recorditemValueImageMarks = this.previousRecorditem.AdditionalValueInfo.Marks;
                }
            }

            this.recorditemOID = uuid();

            if (options.Issue || +options.Row) {
                this.issueInfos = {
                    Issue: options.Issue,
                    Row: options.Row
                };
            } else {
                this.issueInfos = null;
            }

            this.initVariables(options);

            this.$body.find('> .file-row').before(this.$getInputByType());
            this.$body.find('.recorditem-value-image').on('load', () => {
                Utils.ResizeModalWindow(this.$win);
            });

            this.$body.find('.btn-mark-picture').toggleClass('disabled', !this.previousRecorditem);

            this.$selectFromSuggestedValues = this.$win.find('.btn-select-from-suggested-values');

            if (this.issueInfos &&
                this.issueInfos.Issue &&
                Utils.InArray([Enums.IssueType.Form, Enums.IssueType.Inspection], this.issueInfos.Issue.Type) ||
                options.DisableHistoryOption) {
                this.$btnHistory.remove();
            }

            this.$win.on('hidden.bs.modal', $.proxy(this.destroy, this));
            this.$win.on('shown.bs.modal', $.proxy(this.onWindowShown, this));

            this.$win.modal({
                show: true,
                keyboard: false,
                backdrop: false
            });

            if (this.selectedElement.Type === Enums.ElementType.Checkbox &&
                typeof defaultValue !== 'undefined') {
                if (defaultValue === true) {
                    this.onChangeBooleanValue(this.$win.find('div[data-value="true"]'));
                } else if (defaultValue === false) {
                    this.onChangeBooleanValue(this.$win.find('div[data-value="false"]'));
                } else {
                    this.onChangeBooleanValue(this.$win.find('div[data-value="null"]'));
                }
            }

            this.$win.prev('form').remove();

            if (this.isReadonly) {
                this.$win.find('.file .footer').remove();
            }

            this.bindEvents();
            this.renderCorrectiveActionList();
            this.scrollFileScrollerToEnd();
        }

        public ShowSimplified(options: ShowOptions) {
            const location = View.CurrentView === Enums.View.Form || View.CurrentView === Enums.View.Inspection ?
                DAL.Elements.GetByOID(IssueView.GetCurrentIssue().AssignedElementOID) :
                Session.CurrentLocation;

            if (RecorditemEditor.IsVisible()) {
                return;
            }

            this.removedFiles = {};
            this.removedFilesSyncOID = [];

            this.isSimplified = true;
            this.isReadonly = options.IsReadonly || false;
            this.disableDeleteOption = options.DisableDeleteOption || false;

            if (!options || !options.Element) {
                return;
            }

            if (options.PreviousRecorditem && (options.PreviousRecorditem.IsDummy || options.PreviousRecorditem.IsDefault)) {
                options.PreviousRecorditem = null;
            }

            this.selectedElement = options.Element;
            this.onSave = options.OnAfterRecorditemSaved;
            this.onAfterDestroy = options.OnAfterWindowDestroyed;
            this.previousRecorditem = !this.selectedElement.IsAdhoc ? options.PreviousRecorditem : null;
            this.recorditemOID = uuid();
            this.recorditemValueImageMarks = null;
            this.imageMarksHaveChanged = false;

            if (this.previousRecorditem && this.previousRecorditem.AdditionalValueInfo) {
                this.recorditemValueImageMarks = this.previousRecorditem.AdditionalValueInfo.Marks;
            }

            if (View.CurrentView !== Enums.View.Scheduling &&
                this.selectedElement.IsAdhoc &&
                !Utils.UserHasRight(Session.User.OID, Enums.Rights.AdhocRecording, true, location)) {
                Utils.Spinner.Hide();
                Utils.ParameterHistory.Show(this.selectedElement.OID);

                return;
            }

            if (options.Issue || +options.Row) {
                this.issueInfos = {
                    Issue: options.Issue,
                    Row: options.Row
                };
            } else {
                this.issueInfos = null;
            }

            const showDeleteButton = !!this.previousRecorditem &&
                Session.LastKnownAPIVersion >= 20 &&
                Utils.UserHasRight(Session.User.OID, Enums.Rights.Recorditem_Delete, true) &&
                !options.DisableDeleteOption;

            if (!this.isReadonly) {
                switch (this.selectedElement.Type) {
                    case Enums.ElementType.LocationCode:
                        this.onBtnStartScannerClick();
                        return;
                    case Enums.ElementType.Scancode:
                        if (Session.IsSmartDeviceApplication) {
                            this.onBtnStartScannerClick();
                            return;
                        }
                        break;
                    case Enums.ElementType.Date:
                        this.showDatePicker(this.previousRecorditem ? this.previousRecorditem.Value : null, (value: Date) => {
                            this.prepareRecorditemForSave(Utils.DateTime.ToGMTString(value));
                        }, showDeleteButton);
                        return;
                    case Enums.ElementType.Time:
                        this.showTimePicker(this.previousRecorditem ? this.previousRecorditem.Value : null, (value: Date) => {
                            this.prepareRecorditemForSave(Utils.DateTime.ToGMTString(value));
                        }, showDeleteButton);
                        return;
                    case Enums.ElementType.Users:
                        Utils.UserPicker.Show(<UserPicker.IFilter>this.selectedElement.AdditionalSettings,
                            this.previousRecorditem && !!this.previousRecorditem.Value ? JSON.parse(this.previousRecorditem.Value) : null,
                            Session.CurrentLocation,
                            (value: Utils.UserPicker.IUserTeamsSelection) => {
                                this.prepareRecorditemForSave(value ? JSON.stringify(value) : null);
                            },
                            showDeleteButton
                        );
                        return;
                    case Enums.ElementType.Photo:
                        if (Session.IsSmartDeviceApplication) {
                            this.onBtnTakePictureClick();
                            return;
                        }
                        break;
                    case Enums.ElementType.IndividualData:
                        const previousValue = this.previousRecorditem ?
                            this.getIndividualDataValue(this.previousRecorditem.Value) :
                            null;

                        this.showIndividualDataPicker(previousValue, (selectedValues: string[]) => {
                            const schema = (this.selectedElement.AdditionalSettings || {}).Types[0];
                            let value = {};

                            if ((!selectedValues || !selectedValues.length)) {
                                value = null;
                            } else {
                                value[schema] = selectedValues;
                            }

                            this.prepareRecorditemForSave(value);
                        }, showDeleteButton, options.IndividualDataFilterText);
                        return;
                }
            }

            this.initVariables(options);

            this.$body.append(this.$getInputByType());
            this.$body.find('.recorditem-value-image').on('load', () => {
                Utils.ResizeModalWindow(this.$win);
            });

            this.$body.find('.btn-mark-picture').toggleClass('disabled', !this.previousRecorditem);

            this.$selectFromSuggestedValues = this.$win.find('.btn-select-from-suggested-values');

            if (this.issueInfos &&
                this.issueInfos.Issue &&
                Utils.InArray([Enums.IssueType.Form, Enums.IssueType.Inspection], this.issueInfos.Issue.Type) ||
                options.DisableHistoryOption) {
                this.$btnHistory.remove();
            }

            this.$win.on('hidden.bs.modal', $.proxy(this.destroy, this));
            this.$win.on('shown.bs.modal', $.proxy(this.onWindowShown, this));

            if (!this.isReadonly && Utils.InArray([Enums.ElementType.Date, Enums.ElementType.Time, Enums.ElementType.IndividualData], this.selectedElement.Type)) {
                this.onInputClick();
            }

            this.$win.modal({
                show: true,
                keyboard: false,
                backdrop: false
            });

            this.$win.prev('form').remove();

            this.bindEvents();
        }

        public SaveBatchValues(optionsArray: Array<SaveOptions>): Deferred {
            /*
            * Massenspeichern von Werten
            * Keine Validierung auf Rechte oder ähnliches!!
            */

            if (!optionsArray) {
                return $.Deferred().resolve().promise();
            }

            const preparedRecorditems = [];

            for (let i = 0; i < optionsArray.length; i++) {
                const options = optionsArray[i];

                if (!options || !options.Element) {
                    continue;
                }

                if (options.PreviousRecorditem && options.PreviousRecorditem.IsDummy) {
                    options.PreviousRecorditem = null;
                }

                this.isSimplified = true;
                this.selectedElement = options.Element;
                this.onSave = options.OnAfterRecorditemSaved;
                this.onAfterDestroy = options.OnAfterWindowDestroyed;
                this.previousRecorditem = !this.selectedElement.IsAdhoc ? options.PreviousRecorditem : null;
                this.recorditemOID = uuid();

                if (options.Issue || +options.Row) {
                    this.issueInfos = {
                        Issue: options.Issue,
                        Row: options.Row
                    };
                } else {
                    this.issueInfos = null;
                }

                const recordItem = this.prepareRecorditemForSave(options.Value, options.IgnoreValueFromUI, options.Calculated, true, options.ReferenceData);

                this.selectedElement.LastRecorditem = recordItem;

                if (!this.previousRecorditem ||
                    (recordItem.OID !== this.previousRecorditem.OID && recordItem.PrecedingOID === this.previousRecorditem.OID) ||
                    ((recordItem.AdditionalFiles || []).length !== (this.previousRecorditem.AdditionalFiles || []).length) ||
                    ((recordItem.Comments || []).length) !== (this.previousRecorditem.Comments || []).length) {
                    preparedRecorditems.push(recordItem);
                }
            }

            return Model.Recorditem.SaveBatch(preparedRecorditems)
                .then(() => preparedRecorditems);
        }

        public SaveSelectedValue(options: SaveOptions) {
            if (!options || !options.Element) {
                return;
            }

            const location = View.CurrentView === Enums.View.Form || View.CurrentView === Enums.View.Inspection ?
                (IssueView.GetCurrentIssue() ? DAL.Elements.GetByOID(IssueView.GetCurrentIssue().AssignedElementOID) : null) :
                DAL.Elements.GetLocation(options.Element) || Session.CurrentLocation;

            if (options.PreviousRecorditem && options.PreviousRecorditem.IsDummy) {
                options.PreviousRecorditem = null;
            }

            this.isSimplified = true;
            this.selectedElement = options.Element;
            this.onSave = options.OnAfterRecorditemSaved;
            this.onAfterDestroy = options.OnAfterWindowDestroyed;
            this.previousRecorditem = !this.selectedElement.IsAdhoc ? options.PreviousRecorditem : null;
            this.recorditemOID = uuid();

            if (View.CurrentView !== Enums.View.Scheduling &&
                this.selectedElement.IsAdhoc &&
                !Utils.UserHasRight(Session.User.OID, Enums.Rights.AdhocRecording, true, location)) {
                Utils.Spinner.Hide();
                Utils.ParameterHistory.Show(this.selectedElement.OID);

                return;
            }

            if (options.Issue || +options.Row) {
                this.issueInfos = {
                    Issue: options.Issue,
                    Row: options.Row
                };
            } else {
                this.issueInfos = null;
            }

            this.prepareRecorditemForSave(options.Value, options.IgnoreValueFromUI, options.Calculated);
        }

        public IsVisible(): boolean {
            return this.$win && this.$win.css('display') !== 'none';
        }
    }

    function createInstance(): Instance {
        return new Instance();
    }

    export function ApplyScannedValue(value: string): boolean {
        return createInstance().ApplyScannedValue(value);
    }

    export function SaveSelectedValue(options: SaveOptions) {
        return createInstance().SaveSelectedValue(options);
    }

    export function SaveBatchValues(optionsArray: Array<SaveOptions>): Deferred {
        return createInstance().SaveBatchValues(optionsArray);
    }

    export function Show(options: ShowOptions) {
        return createInstance().Show(options);
    }

    export function ShowSimplified(options: ShowOptions) {
        return createInstance().ShowSimplified(options);
    }

    export function IsVisible(): boolean {
        return InstanceManager.IsVisible();
    }

    export function IsDeletionAllowed(options: ShowOptions, element: Model.Elements.Element, previousRecorditem: Model.Recorditem): boolean {
        // Kein Löschen bei readonly
        if (options.IsReadonly) {
            return false;
        }

        // Kein Löschen, wenn Löschen Funktion deaktiviert
        if (options.DisableDeleteOption) {
            return false;
        }

        // Löschen erst ab API 20 möglich
        if (Session.LastKnownAPIVersion < 20) {
            return false;
        }

        // Kein Löschen bei Adhoc Prüfpunkten
        if (element.IsAdhoc) {
            return false;
        }

        // Nur Löschen wenn vorher ein Wert vorhanden war
        if (!previousRecorditem || previousRecorditem.IsDummy) {
            return false;
        }

        // Löschen Rechte des Benutzers prüfen
        if (!Session.User || !Utils.UserHasRight(Session.User.OID, Enums.Rights.Recorditem_Delete, true)) {
            return false;
        };

        return true;
    }

    export function AskWhetherToReUseFilesInCorrectiveAction(fileIdentifiers: string[]): Deferred {
        const result: Deferred = $.Deferred();

        if (!(fileIdentifiers instanceof Array) ||
            fileIdentifiers.length === 0) {
            return result.reject();
        }

        let location = Session.CurrentLocation;

        if (View.CurrentView === Enums.View.Form) {
            const parentIssue = IssueView.GetCurrentIssue();

            if (!parentIssue) {
                return result.reject();
            }

            location = DAL.Elements.GetByOID(parentIssue.AssignedElementOID);
        }

        if (!location) {
            return result.reject();
        }

        if (!Utils.CanUserCreateIssueType(Enums.IssueType.Task, location)) {
            return result.reject();
        }

        if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue) && Session.LastKnownAPIVersion >= 7) {
            return result.reject();
        }

        if (!Session.Settings.AskIfCreateIssueAfterPhotoComment) {
            return result.reject();
        }

        Utils.Message.Show(i18next.t('RecorditemEditor.ReUseAdditionalImages.MessageHeader'),
            i18next.t('RecorditemEditor.ReUseAdditionalImages.MessageBody'),
            {
                Yes: () => { result.resolve(fileIdentifiers) },
                No: () => { result.reject() },
                OnHide: () => { result.reject() }
            });

        return result;
    }

    export function AskWhetherToReUseCommentInCorrectiveAction(comment: Model.Comment): Deferred {
        const result: Deferred = $.Deferred();

        if (!comment || !comment.Text || !comment.Text.length) {
            return result.reject();
        }

        let location = Session.CurrentLocation;

        if (View.CurrentView === Enums.View.Form) {
            const parentIssue = IssueView.GetCurrentIssue();

            if (!parentIssue) {
                return result.reject();
            }

            location = DAL.Elements.GetByOID(parentIssue.AssignedElementOID);
        }

        if (!location) {
            return result.reject();
        }

        if (!Utils.CanUserCreateIssueType(Enums.IssueType.Task, location)) {
            return result.reject();
        }

        if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue) && Session.LastKnownAPIVersion >= 7) {
            return result.reject();
        }

        if (!Session.Settings.AskIfCreateIssueAfterTextComment) {
            return result.reject();
        }

        Utils.Message.Show(i18next.t('RecorditemEditor.ReUseTextComment.MessageHeader'),
            i18next.t('RecorditemEditor.ReUseTextComment.MessageBody'),
            {
                Yes: () => { result.resolve(comment) },
                No: () => { result.reject() },
                OnHide: () => { result.reject() }
            });

        return result;
    }

    export function CreateNewCorrectiveAction(evt: Event, imageIdentifiers: string[], recorditem: Model.Recorditem, checkpoint: Model.Elements.Element, additionalFiles: Dictionary<any>, callback: Function, title?: string, zIndex?: number): Deferred {
        if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue) && Session.LastKnownAPIVersion >= 7) {
            return $.Deferred().reject();
        }

        let parentIssue: Model.Issues.Issue;
        let location = Session.CurrentLocation;

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            parentIssue = IssueView.GetCurrentIssue();
        }

        if (View.CurrentView === Enums.View.Form && parentIssue) {
            location = DAL.Elements.GetByOID(parentIssue.AssignedElementOID);
        }

        if (!location) {
            return $.Deferred().reject();
        }

        if (!Utils.CanUserCreateIssueType(Enums.IssueType.Task, location)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                {
                    Close: true
                });
            return $.Deferred().reject();
        }

        if (evt) {
            evt.stopPropagation();
        }

        // Zeilenumbruch entfernen
        if (title) {
            title = title.replace(/\s*(\r\n)+\s*|\s*(\n\r)+\s*|\s*(\n)+\s*/gi, ' ').trim();

            if (title.length > 100) {
                title = title.substr(0, 100);
            }
        }

        const action = {
            Title: title,
            Type: Enums.CheckpointWorkflowType.CorrectiveAction,
            TemporaryFiles: null,
            InheritResponsibilities: false
        };

        if ((imageIdentifiers || []).length) {
            const issueImages = [];

            for (let iCnt = 0, iLen = imageIdentifiers.length; iCnt < iLen; iCnt++) {
                const identifier = imageIdentifiers[iCnt];
                const image = additionalFiles[identifier];

                if (!image) {
                    continue;
                }

                const copy = $.extend(true, {}, image);
                copy.UploadDisabled = true;

                issueImages.push(copy);
            }

            action.TemporaryFiles = issueImages;
        }

        if (!Utils.IsUserAbleToSetInitialState(location.OID, Session.Client.Settings.TicketOpened)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.DefaultState.MessageBody'),
                {
                    Close: true
                });

            return $.Deferred().reject();
        }

        if (parentIssue && parentIssue.AssignedFormRevisionOID) {
            const form = ParameterList.GetElementRevisionByRevisionOID(parentIssue.AssignedFormRevisionOID);
            action.InheritResponsibilities = form && form.AdditionalSettings && form.AdditionalSettings.BequeathResponsibilities;
        }

        const closePromise = Utils.IssueViewer.CreateCorrectiveAction(recorditem,
            checkpoint,
            parentIssue,
            action,
            true,
            callback,
            false,
            zIndex
        );

        return closePromise;
    }

    export function GetPreparedExistingCorrectiveActions(issues: Array<Model.Issues.Issue | Model.Issues.RawIssue>): Utils.ActionWindow.Action[] {
        if (!issues || !issues.length) {
            return null;
        }

        const actionList: Utils.ActionWindow.Action[] = [];
        const issuesInList = {};

        for (let iCnt = 0, iLen = issues.length; iCnt < iLen; iCnt++) {
            const targetIssue = issues[iCnt];

            // doppelte Einträge vermeiden
            if (!issuesInList[targetIssue.OID]) {
                actionList.push({
                    IsIssue: true,
                    Issue: targetIssue
                });

                issuesInList[targetIssue.OID] = true;
            }
        }

        return actionList;
    }

    export type OnSaveFunc = (recorditem: Model.Recorditem) => void;

    export class ShowOptions {
        IsReadonly?: boolean;
        Element?: Model.Elements.Element;
        PreviousRecorditem?: Model.Recorditem;
        Issue?: Model.Issues.Issue;
        Row?: number;
        AdditionalCorrectiveActions?: any[];
        IndividualDataFilterText?: string;
        DisableDeleteOption?: boolean;
        DisableHistoryOption?: boolean;

        OnAfterRecorditemSaved?: OnSaveFunc;
        OnAfterWindowDestroyed?: Function;
        OnAfterCommentCreated?: Function;
        OnAfterCommentDeleted?: Function;
    }

    export class SaveOptions extends ShowOptions {
        Value: any;
        IgnoreValueFromUI?: boolean;
        Calculated?: boolean;
        ReferenceData?: ReferenceData;
    }

    export type ReferenceData = {
        ImageValue?: string,
        AdditionalValueInfo?: { Marks?: string };
        Comments?: Array<Model.IComment>,
        AdditionalImages?: Dictionary<any>
    }
}
