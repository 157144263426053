//imports-start
/// <reference path="../definitions.d.ts" />
//imports-end

module Utils.ElementInformation {
    let _$win, _$overlay;
    let _element: Model.Elements.Element;

    let _imageCounter: number,
        _imagesTotal: number;
    let _onAfterRendered: ($win: any) => void;

    function destroy(): void {
        _element = null;
        _imageCounter = 0;
        _imagesTotal = 0;

        if (_$win) {
            _$win.remove();
            _$win = null;
        }

        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }
    }

    function onImageLoaded(): void {
        _imageCounter++;

        if (_imageCounter === _imagesTotal) {
            onAllImagesLoaded();
        }
    }

    function onImageError(): void {
        _imageCounter++;

        if (_imageCounter === _imagesTotal) {
            onAllImagesLoaded();
        }
    }

    function onAllImagesLoaded() {
        Utils.RepositionModalWindow(_$win);

        if (_onAfterRendered) {
            _onAfterRendered(_$win);
        }
    }

    function onFileClick(): void {
        const $this = $(this);
        const $a = $this.find('a');
        const isImage = $a.hasClass('image');
        const isVideo = $a.hasClass('video');

        if (isImage) {
            Utils.OpenFiles($.map(_element.Files, function(elementFile: Model.Files.File) {
                if (DAL.Files.Exists(elementFile.OID)) {
                    return DAL.Files.GetByOID(elementFile.OID);
                }
            }), $this.data('filename'), null, _element.Title);
        } else if (isVideo) {
            const file = DAL.Files.GetByFilename($this.data('filename'));

            if (!file) {
                return;
            }

            Utils.OpenFile($this.data('filename'), false, true, _element.Title);
        } else {
            Utils.OpenFile($this.data('filename'), false, false, _element.Title);
        }
    }

    function getElementUnit(unitOID: string): Model.Properties.Property | null {
        if (!unitOID || !DAL.Properties.Exists(Enums.PropertyType.Unit, unitOID)) {
            return null;
        }

        return $.extend(true, DAL.Properties.GetByOID(_element.UnitOID), { Parent: null, Children: null });
    }

    function getSortedEvaluationByCategoryOrder(evaluation: Array<Model.Elements.Evaluation>, order: Array<string>): Array<Model.Elements.Evaluation> {
        return evaluation.sort(function (a, b) {
            const idxA = order.indexOf(a.CategoryOID);
            const idxB = order.indexOf(b.CategoryOID);

            return idxA - idxB;
        });
    }

    function getSortedActionsByCategoryOrder(actions, order: Array<string>): Array<Model.Properties.Property> {
        return actions.sort(function (a, b) {
            const idxA = order.indexOf(a.CategoryOID);
            const idxB = order.indexOf(b.CategoryOID);

            return idxA - idxB;
        });
    }

    function renderWindow(isOnline: boolean): void {
        const categoryOrder = DAL.Properties.GetOrderByType(Enums.PropertyType.ValueCategory);
        const images = [];
        const files = [];
        const embeddedVideos = [];

        (_element.Files || [])
            .sort(Utils.SortAssignedFilesFromCatalogue)
            .forEach(function (ef: { OID: string }) {
                const file = DAL.Files.GetByOID(ef.OID);

                if (!file) {
                    return;
                }

                switch (file.Type) {
                    case Enums.FileType.File:
                        if (Utils.IsImage(file.MimeType)) {
                            images.push(file);
                        } else {
                            files.push(file);
                        }

                        break;
                    case Enums.FileType.Link:
                        files.push(file);
                        break;
                    case Enums.FileType.Youtube:
                    case Enums.FileType.VimeoPrivate:
                    case Enums.FileType.VimeoPublic:
                        embeddedVideos.push(file);
                        break;
                }
            });

        if ((_element.Forms || []).length) {
            _element.Forms.sort((a, b) => {
                const formA = DAL.Elements.GetByOID(a);
                const formB = DAL.Elements.GetByOID(b);

                return Utils.SortByTitle(formA, formB);
            });
        }

        if ((_element.Teams || []).length) {
            _element.Teams.sort((a, b) => {
                return Utils.SortByTitle(a, b);
            });
        }

        let evaluation, actions;

        if ((_element.Evaluation || []).length) {
            evaluation = getSortedEvaluationByCategoryOrder(_element.Evaluation, categoryOrder);
        }

        if ((_element.Actions || []).length) {
            actions = getSortedActionsByCategoryOrder(_element.Actions, categoryOrder);
        }

        _$win = $(Templates.ElementInformation({
            Element: Utils.CloneElement(_element),
            Unit: getElementUnit(_element.UnitOID),
            Images: images,
            Files: files,
            EmbeddedVideos: embeddedVideos,
            Evaluation: evaluation,
            Actions: actions,
            UserIsOnline: isOnline
        }));

        _imagesTotal = _$win.find('img').length;
        _imageCounter = 0;

        if (!_imagesTotal) {
            Utils.RepositionModalWindow(_$win);
        }

        _$overlay = Utils.Overlay.Generate('olElementInformation', 9999, destroy);

        $('body').append(_$win);
        _$win.find('.modal-content').css('max-height', $(window).height() - 60);

        Utils.RepositionModalWindow(_$win);

        _$win.modal({
            show: true,
            keyboard: false,
            backdrop: false
        });
    }

    function onIframeLoaded(): void {
        Utils.OnIframeLoaded.call(this, () => {
            Utils.RepositionModalWindow(_$win);
        });
    }

    function onAdditionalPropertyGroupHeaderClick(): void {
        const $this = $(this);

        $this.toggleClass('collapsed');
    }

    function onParameterGroupHeaderClick(): void {
        const $this = $(this);

        $this.toggleClass('collapsed');

        if (!$this.hasClass('collapsed') && $this.hasClass('tabular')) {
            $this.next().find('.sticky-enabled').trigger('resize');
        }
    }

    function bindEvents(): void {
        _$win.on('hidden.bs.modal', destroy);
        _$win.find('div[data-filename]').on('click', onFileClick);
        _$win.find('img').on('load', onImageLoaded).on('error', onImageError);
        _$win.find('iframe.info-text').on('load', onIframeLoaded);
        _$win.find('.parameter-list > p').on('click.toggleListView', onParameterGroupHeaderClick);
        _$win.find('.additional-property-group-header').on('click.toggleCollapsedState', onAdditionalPropertyGroupHeaderClick);
    }

    export function Show(elementIdentifier: string, onAfterRendered?: ($win: any) => void): void {
        _element = Utils.InArray([Enums.View.Form, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView) ?
            ParameterList.GetElementRevisionByRevisionOID(elementIdentifier) :
            DAL.Elements.GetByOID(elementIdentifier);

        if (!_element) {
            return;
        }

        _onAfterRendered = onAfterRendered;

        checkIfUserIsOnline()
            .then((isOnline: boolean) => {
                renderWindow(isOnline);
                bindEvents();

                if (_imagesTotal === 0 && _onAfterRendered) {
                    _onAfterRendered(_$win);
                }
            });
    }

    export function ShowForLocation(location: Model.Elements.Element, onAfterRendered?: ($win: any) => void): void {
        _element = location;

        if (!_element) {
            return;
        }

        _onAfterRendered = onAfterRendered;

        checkIfUserIsOnline()
            .then((isOnline: boolean) => {
                renderWindow(isOnline);
                bindEvents();

                if (_imagesTotal === 0 && _onAfterRendered) {
                    _onAfterRendered(_$win);
                }
            });
    }

    function checkIfUserIsOnline(): Deferred {
        const deferred = $.Deferred();

        Utils.CheckIfDeviceIsOnline()
            .then(deferred.resolve, deferred.resolve);

        return deferred.promise();
    }

    export function IsVisible(): boolean {
        return _$win && _$win.css('display') !== 'none';
    }
}
