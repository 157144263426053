module Model.Parameters {
    import IResettableFilter = Model.Filter.IResettableFilter;

    export interface IParameterCounter {
        RecordedParametersCount?: number;
        RequiredParameterCount?: number;
        StateOfGoingToNextRoom?: number;
        NotRequiredParameterCount?: number;
        RecordedNotRequiredParametersCount?: number;
    }

    export class Filter implements IResettableFilter {
        ValueCategories: string[];
        Keywords: string[];
        SearchText: string;
        SearchInParameter: boolean;
        SearchInGroup: boolean;
        ShowRecordedParameters: boolean;
        ShowUnrecordedParameters: boolean;
        ShowRequiredUnrecordedParameters: boolean;

        constructor(values?) {
            if (values) {
                for (let key in values) {
                    this[key] = values[key];
                }
            } else {
                this.ValueCategories = [];
                this.Keywords = [];
                this.SearchText = null;
                this.SearchInParameter = true;
                this.SearchInGroup = false;
                this.ShowRecordedParameters = true;
                this.ShowUnrecordedParameters = true;
                this.ShowRequiredUnrecordedParameters = true;
            }
        }

        static FromMenuItemConfiguration(config: Model.Menu.IMenuItemConfig): Filter {
            const filter = new Filter();

            if (Utils.HasProperties(config.Configuration)) {
                filter.Keywords = config.Configuration.Keywords;
            }

            return filter;
        }

        Reset() {
            this.ValueCategories = [];
            this.Keywords = [];
            this.SearchText = null;
            this.SearchInParameter = true;
            this.SearchInGroup = false;
            this.ShowRecordedParameters = true;
            this.ShowUnrecordedParameters = true;
            this.ShowRequiredUnrecordedParameters = true;
        }
    }
}
