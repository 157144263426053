//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../core/revision.ts"  />
/// <reference path="../roles/right.ts"  />
/// <reference path="../elements/element-rights.ts"  />
/// <reference path="../../app/app.session.ts" />
/// <reference path="./raw-user.ts" />
/// <reference path="./synchronization.ts" />
//imports-end

module Model.Users {
    export class User extends Model.Core.Revision {
        Username: string;
        EnforcePasswordPolicy: boolean;
        ChangePassword: boolean;
        EmailAddress: string;
        Firstname: string;
        Lastname: string;
        ImageOID?: string;
        Language: string;
        IsSystemUser: boolean;
        IsTrustUser: boolean;
        IsInvestigationUser: boolean;
        IsExternalUser: boolean;
        IsLocked: boolean;
        RootElementOID?: string;
        Settings: Dictionary<any>;
        Rights: Array<Model.Roles.Right>;
        ElementRights: Array<Model.Elements.ElementRights>;
        Teams: Array<string>;
        LastSynchronization: Model.Users.Synchronization;
        AuthHash?: string;

        Title: string;
        ImagePath: string;
        ImageFilename: string;
        HasPersonalImage: boolean;

        constructor(user: RawUser) {
            super();

            this.initProperties(user);
        }

        private initProperties(user: RawUser): void {
            this.OID = user.OID;
            this.RevisionOID = user.RevisionOID;
            this.Deleted = user.Deleted;
            this.Username = user.Username;
            this.EnforcePasswordPolicy = user.EnforcePasswordPolicy;
            this.ChangePassword = user.ChangePassword;
            this.EmailAddress = user.EmailAddress;
            this.Firstname = user.Firstname;
            this.Lastname = user.Lastname;
            this.ImageOID = user.ImageOID;
            this.Language = user.Language;
            this.IsSystemUser = user.IsSystemUser;
            this.IsTrustUser = user.IsTrustUser;
            this.IsInvestigationUser = user.IsInvestigationUser;
            this.IsExternalUser = user.IsExternalUser;
            this.IsLocked = user.IsLocked;
            this.RootElementOID = user.RootElementOID;
            this.Settings = user.Settings;
            this.Rights = user.Rights;
            this.ElementRights = user.ElementRights;
            this.Teams = user.Teams;
            this.LastSynchronization = user.LastSynchronization;

            if (!!this.Firstname) {
                this.Firstname = Utils.EscapeHTMLEntities(this.Firstname);
            }

            if (!!this.Lastname) {
                this.Lastname = Utils.EscapeHTMLEntities(this.Lastname);
            }

            if (!!this.Username) {
                this.Username = Utils.EscapeHTMLEntities(this.Username);
            }

            if (!!this.EmailAddress) {
                this.EmailAddress = Utils.EscapeHTMLEntities(this.EmailAddress);
            }

            this.Title = i18next.t('Misc.FullName', {
                Firstname: this.Firstname || '',
                Seperator: !!this.Firstname && !!this.Lastname ? ' ' : '',
                Lastname: this.Lastname || ''
            });

            if (!this.ImageOID) {
                this.ImagePath = `./img/user.svg`;

                return;
            }

            const file = DAL.Files.GetByOID(this.ImageOID);

            if (!file) {
                this.ImagePath = `./img/user.svg`;

                return;
            }

            if (Session.IsRunningOnIOS) {
                this.ImagePath = Utils.FixIOSFilepath(file.FilePath);
            } else {
                this.ImagePath = file.FilePath;
            }

            this.ImageFilename = file.Filename;
            this.HasPersonalImage = true;
        }
    }
}