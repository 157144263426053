///<reference path="response-base.ts"/>
///<reference path="../workflow-base.ts"/>
///<reference path="../../../../model/model.comment.ts"/>


module New.Checkpoints.Workflow.Response {
    export class CommentWorkflow extends IssueResponseBase {
        public readonly Comment: Model.Comment;

        constructor(workflow: WorkflowBase, comment: Model.Comment) {
            super(workflow);

            if (!comment) {
                throw new Error('no comment provided');
            }

            this.Comment = comment;
        }

        createWorkflowText(): string {
            let text = `${i18next.t('CorrectiveActions.ActionState.Created')}: `;

            if (this.Issue.ID) {
                text += this.Issue.ID;

                if (!!this.Issue.Title) {
                    text += ` - ${this.Issue.Title}`;
                }
            } else if (!!this.Issue.Title) {
                text += this.Issue.Title;
            }

            return text;
        }
    }
}
