//imports-start
/// <reference path="../issues/issue.ts"  />
//imports-end

module Model.Calendar {
    export class DayViewItem {
        Timestamp: number;
        DisplayValue: string;
        Greyed: boolean;
        Issues: Array<Model.Issues.Issue>;

        SetTimestamp(timestamp: number): DayViewItem {
            this.Timestamp = timestamp;

            return this;
        }

        SetDisplayValue(value: string): DayViewItem {
            this.DisplayValue = value;

            return this;
        }

        SetGreyed(flag: boolean): DayViewItem {
            this.Greyed = flag || false;

            return this;
        }

        SetIssues(issues: Array<Model.Issues.Issue>): DayViewItem {
            if (!(issues || []).length) {
                issues = null;
            }

            this.Issues = issues;

            return this;
        }
    }
}