module Utils.InfoWindow {
    export abstract class BaseInfoWindow<T> {
        protected abstract infoWindowID: string;

        protected item: T;
        protected zIndex: number;
        protected $content;
        protected userIsOnline: boolean;

        private $win;
        private $btnClose;
        private $overlay;

        protected constructor(item: T | string, zIndex: number) {
            this.item = typeof item === 'string' ? this.getItemByIdentifier(item) : item;
            this.zIndex = zIndex;
        }

        public Show(): void {
            if (!Utils.IsSet(this.item)) {
                this.showNoItemFoundMessage();
                return;
            }

            Utils.CheckIfDeviceIsOnline()
                .then(this.onAfterCheckIfUserIsOnline, this.onAfterCheckIfUserIsOnline);
        }

        private onAfterCheckIfUserIsOnline = (isOnline: boolean) => {
            this.userIsOnline = isOnline;

            this.renderWindow();
            this.bindEvents();
        };

        protected destroy(): void {
            this.unbindEvents();

            if (this.$win) {
                this.$win.remove();
                this.$win = null;
                this.$content = null;
                this.$btnClose = null;
            }

            if (this.$overlay) {
                Utils.Overlay.DestroyWithTimeout(this.$overlay);
                this.$overlay = null;
            }

            $('body').removeClass('modal-open').css('padding-right', '');
        }

        protected abstract showNoItemFoundMessage(): void;
        protected abstract getItemByIdentifier(identifier: string): T;
        protected abstract getContent(): any;
        protected abstract getItemTitle(): string;

        private getTemplateContext(): any {
            return {
                InfoWindowID: this.infoWindowID,
                Title: this.getItemTitle(),
                ZIndex: this.zIndex + 1
            }
        }

        private renderWindow(): void {
            this.$win = $(Templates.InfoWindow.BaseWindow(this.getTemplateContext()));
            this.$content = this.$win.find('.content');
            this.$btnClose = this.$win.find('.btn-close');
            this.$overlay = Utils.Overlay.Generate('olSelection', this.zIndex, this.destroy);

            this.$content.append(this.getContent());

            $('body').append(this.$win);
            Utils.RepositionModalWindow(this.$win);
            this.$win.find('.modal-body').css('height', this.$win.find('.modal-body').css('max-height'));
            this.$win.find('.modal-dialog').css({
                'max-height': '',
                'margin-top': 0,
                'margin-left': 0
            });
            this.$win.modal({
                show: true,
                backdrop: false
            });
        }

        protected unbindEvents(): void {
            this.$btnClose.off('click');
        }

        protected bindEvents(): void {
            this.$btnClose.on('click', this.destroy.bind(this));
        }
    }
}