///<reference path="i-workflow.ts"/>
///<reference path="workflow-base.ts"/>
///<reference path="workflow-settings.ts"/>
/// <reference path="../../../model/model.recorditem.ts"/>
/// <reference path="../../../model/elements/element.ts"/>

module New.Checkpoints.Workflow {
    export class NullWorkflow extends WorkflowBase {
        constructor(workflow: IWorkflow, workflowSettings: WorkflowSettingsBase) {
            super(workflow, workflowSettings);
        }

        public Execute(): any {
            return $.Deferred().resolve().promise();
        }
    }
}