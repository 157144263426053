//imports-start
/// <reference path="./utils.base-filter-window.ts"  />
/// <reference path="../../model/filter-window/group.ts"  />
/// <reference path="../../model/filter-window/button-item.ts"  />
//imports-end

module Utils {
    export type CheckpointFilterCategoryInformation = {
        VisibleCategories: string[],
        DisabledCategories: string[]
    };

    export class CheckpointFilterWindow extends BaseFilterWindow<Model.Parameters.Filter> {
        private readonly categoryInformations: Utils.CheckpointFilterCategoryInformation;

        constructor(predefinedFilters?: Model.Parameters.Filter, onSave?: Function, categoryInformations?: CheckpointFilterCategoryInformation) {
            super(new Model.Parameters.Filter(predefinedFilters), onSave);

            this.categoryInformations = categoryInformations;
        }

        protected getAvailableFilterItems(): Array<Model.FilterWindow.Group> {
            const filter = this.filters;
            const properties = new Model.FilterWindow.Group(
                i18next.t('CheckpointFilterWindow.Properties'),
                1,
                [
                    new Model.FilterWindow.ButtonItem(
                        Enums.CheckpointFilterWindow.Property.RecordingStatus,
                        i18next.t('ParameterList.FilterByIsRecorded.ButtonText'),
                        !filter.ShowRecordedParameters ||
                        !filter.ShowRequiredUnrecordedParameters ||
                        !filter.ShowUnrecordedParameters ? 'red' : null,
                        this.onBtnRecordingStatusFilterClick,
                        'clipboard'),
                    new Model.FilterWindow.ButtonItem(
                        Enums.CheckpointFilterWindow.Property.ValueCategories,
                        i18next.t('Misc.ValueCategory_plural'),
                        (filter.ValueCategories || []).length ? 'red' : null,
                        this.onBtnCategoriesFilterClick,
                        'notification'),
                    new Model.FilterWindow.ButtonItem(
                        Enums.CheckpointFilterWindow.Property.Keywords,
                        i18next.t('Misc.Keyword_plural'),
                        (filter.Keywords || []).length ? 'red' : null,
                        this.onBtnKeywordsFilterClick,
                        'price-tags')
                ]
            );

            const texts = new Model.FilterWindow.Group(
                i18next.t('CheckpointFilterWindow.Texts'),
                2,
                [
                    new Model.FilterWindow.ButtonItem(
                        Enums.CheckpointFilterWindow.Property.TextSearch,
                        i18next.t('ParameterList.FilterByText.ButtonText'),
                        !!filter.SearchText ? 'red' : null,
                        this.onBtnTextSearchButtonClick,
                        'i-cursor')
                ]
            );

            return [ properties, texts ];
        };

        private onBtnCategoriesFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const filters = this.filters;

            if (!this.categoryInformations || !(this.categoryInformations.VisibleCategories || []).length) {
                Utils.Message.Show(i18next.t('Parametergroups.ValueCategoryFilter.MessageHeader'),
                    i18next.t('Parametergroups.ValueCategoryFilter.MessageBody'),
                    {
                        Close: true
                    });

                return;
            }

            const isValueCategoryDisabled = (category) => {
                const categoryInformation = (this.categoryInformations || <CheckpointFilterCategoryInformation>{});

                return (categoryInformation.DisabledCategories || []).indexOf(category.OID) !== -1;
            };

            const hideValueCategory = (category) => {
                const categoryInformation = (this.categoryInformations || <CheckpointFilterCategoryInformation>{});

                return (categoryInformation.VisibleCategories || []).indexOf(category.OID) !== -1;
            };

            Utils.ElementPickerPopup.Show({
                RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.ValueCategory),
                Items: DAL.Properties.GetByType(Enums.PropertyType.ValueCategory),
                VisibleObjectTypes: [ Enums.PropertyType.ValueCategory ],
                IsNotALocationTree: true,
                MultiSelectionAllowed: true,
                SelectedItemIdentifiers: filters.ValueCategories,
                CannotCollapseRoot: true,
                ShowResetButton: true,
                ShowQRCodeScannerButton: false,
                ShowNfcScannerButton: false,
                ShowColor: true,
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.ValueCategories'),
                WindowTitle: i18next.t('Misc.ValueCategory_plural'),
                FnHideItem: hideValueCategory,
                FnItemIsDisabled: isValueCategoryDisabled,
                OnAfterWindowRendered: ($window) => {
                    $window.find('.active').addClass('selected');
                },
                OnConfirmSelection: (result: any) => {
                    filters.ValueCategories = result.SelectedItems;
                    $btn.toggleClass('red', (this.filters.ValueCategories || []).length > 0);
                    this.onAfterFilterChanged();
                },
                OnResetButtonClick: () => {
                    filters.ValueCategories = null;
                    $btn.removeClass('red');
                    this.onAfterFilterChanged();
                }
            });
        }

        private onBtnKeywordsFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const filters = this.filters;
            Utils.ElementPickerPopup.Show({
                RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Keyword),
                Items: DAL.Properties.GetByType(Enums.PropertyType.Keyword),
                VisibleObjectTypes: [ Enums.PropertyType.Keyword ],
                IsNotALocationTree: true,
                MultiSelectionAllowed: true,
                SelectedItemIdentifiers: filters.Keywords,
                CannotCollapseRoot: true,
                ShowResetButton: true,
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Keywords'),
                WindowTitle: i18next.t('Misc.Keyword_plural'),
                OnAfterWindowRendered: ($window) => {
                    $window.find('.active').addClass('selected');
                },
                OnConfirmSelection: (result: any) => {
                    filters.Keywords = result.SelectedItems;
                    $btn.toggleClass('red', (this.filters.Keywords || []).length > 0);
                    this.onAfterFilterChanged();
                },
                OnResetButtonClick: () => {
                    filters.Keywords = null;
                    $btn.removeClass('red');
                    this.onAfterFilterChanged();
                }
            });
        }

        private onBtnRecordingStatusFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const filters = this.filters;
            const selectedProperties = [];
            const listItems = [ {
                Title: i18next.t('ParameterList.FilterByIsRecorded.Recorded'),
                PropertyValue: 'recorded'
            }, {
                Title: i18next.t('ParameterList.FilterByIsRecorded.Unrecorded'),
                PropertyValue: 'unrecorded'
            }, {
                Title: i18next.t('ParameterList.FilterByIsRecorded.RequiredUnrecorded'),
                PropertyValue: 'required-unrecorded'
            } ];

            const onOKClick = ($selectedItems) => {
                let selectedValues;

                if (($selectedItems || []).length) {
                    selectedValues = $.map($selectedItems, (button: any) => {
                        return $(button).data('propertyvalue');
                    });

                    filters.ShowRecordedParameters = Utils.InArray(selectedValues, 'recorded');
                    filters.ShowUnrecordedParameters = Utils.InArray(selectedValues, 'unrecorded');
                    filters.ShowRequiredUnrecordedParameters = Utils.InArray(selectedValues, 'required-unrecorded');
                } else {
                    filters.ShowRecordedParameters = false;
                    filters.ShowUnrecordedParameters = false;
                    filters.ShowRequiredUnrecordedParameters = false;
                }

                $btn.toggleClass('red',
                    filters.ShowRecordedParameters ||
                    filters.ShowUnrecordedParameters ||
                    filters.ShowRequiredUnrecordedParameters);
                this.onAfterFilterChanged();
            };

            const onResetButtonClick = () => {
                filters.ShowRecordedParameters = true;
                filters.ShowUnrecordedParameters = true;
                filters.ShowRequiredUnrecordedParameters = true;

                $btn.removeClass('red');
                this.onAfterFilterChanged();
            };

            if (filters.ShowRecordedParameters) {
                selectedProperties.push('recorded');
            }

            if (filters.ShowUnrecordedParameters) {
                selectedProperties.push('unrecorded');
            }

            if (filters.ShowRequiredUnrecordedParameters) {
                selectedProperties.push('required-unrecorded');
            }

            Utils.CustomDataPicker.Show(listItems, {
                Title: i18next.t('ParameterList.FilterByIsRecorded.ButtonText'),
                Width: 330,
                UseSystemProperties: false,
                Callback: onOKClick,
                SelectedProperties: selectedProperties,
                IsMultiSelectionAllowed: true,
                HideResetButton: false,
                OnResetButtonClick: onResetButtonClick
            });
        }

        private onBtnTextSearchButtonClick(e: Event): void {
            const $btn = $(e.currentTarget);
            const filters = this.filters;
            const onOKClick = (text: string, $inputs) => {
                filters.SearchText = !!text ? text : null;
                filters.SearchInParameter = true;
                filters.SearchInGroup = false;

                if ($inputs) {
                    const $filterParam = $inputs.filter('[name="select-param"]');

                    if ($filterParam.length) {
                        filters.SearchInParameter = $filterParam.is(':checked');
                    }

                    const $filterGroup = $inputs.filter('[name="select-group"]');

                    if ($filterGroup.length) {
                        filters.SearchInGroup = $filterGroup.is(':checked');
                    }
                }

                $btn.toggleClass('red', !!filters.SearchText);
                this.onAfterFilterChanged();
            };

            Utils.InputWindow.Show(
                i18next.t('ParameterList.FilterByText.InputWindowTitle'), null, {
                    Abort: {},
                    OK: { Fn: onOKClick }
                }, filters.SearchText,
                'parameter',
                null,
                {
                    Param: filters.SearchInParameter,
                    Group: filters.SearchInGroup
                }
            );
        }
    }
}
