//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="./model.key-value-storage.ts"  />
///// <reference path="../enums.ts"  />
//// <reference path="../utils/utils.date-time.ts"  />
/// <reference path="../utils/utils.router.ts"  />
/// <reference path="./issues/location-marker.ts" />
//imports-end

module Model {
    interface IFloorPlanOptions {
        ID: string,
        Location: Model.Elements.Element,
        FloorPlanHeight?: string,
        $Container: any,
        MarksFillColor?: string,
        MarksStrokeColor?: string,
        OnNavigateToLocation?: (location: Model.Elements.Element) => void,
        ExistingMarks?: Array<Model.Issues.LocationMarker>
    }

    interface IFloorPlanSettings {
        Marks: Array<Model.Floor.Mark>,
        CurrentZoomLevel: number,
        OnNavigateToLocation: (location: Model.Elements.Element) => void,
        Zoom: any,
        $InfoWindow?: any,
        RemoveMarkOnClick?: boolean,
        IsCurrentLocationsLayout?: boolean
    }

    export class FloorPlan {
        private readonly ID: string;
        private readonly Location: Model.Elements.Element;
        private readonly Layout: string;
        private readonly $Container: any;
        private readonly $FloorPlan: any;
        private readonly FloorPlanZoom: any;
        private readonly MarksFillColor: string;
        private readonly MarksStrokeColor: string;

        private RemoveMarkOnClick: boolean;
        private setting: IFloorPlanSettings;
        private $layout: any;
        private $selectedFloorPlanGraphicsElement: any;

        constructor(options: IFloorPlanOptions) {
            this.setting = {
                Marks: [],
                CurrentZoomLevel: 1,
                OnNavigateToLocation: null,
                Zoom: null,
                $InfoWindow: null,
                RemoveMarkOnClick: false,
                IsCurrentLocationsLayout: true
            };

            this.ID = options.ID;
            this.Location = options.Location;
            this.Layout = this.getFloorPlanLayout(this.ID, this.Location, options.FloorPlanHeight);
            this.$Container = options.$Container;
            this.$FloorPlan = Model.FloorPlan.renderLayoutToContainer(this.$Container, this.Layout);
            this.FloorPlanZoom = this.bindEvents(this.$FloorPlan, this.$Container);
            this.MarksFillColor = options.MarksFillColor;
            this.MarksStrokeColor = options.MarksStrokeColor;

            options.OnNavigateToLocation = options.OnNavigateToLocation || function (location) {
                Utils.Router.PushState('location/' + location.OID);
            };

            this.setting.OnNavigateToLocation = options.OnNavigateToLocation;
            this.setting.Zoom = this.FloorPlanZoom;

            if ((options.ExistingMarks || []).length) {
                this.addExistingMarks(options.ExistingMarks);
            }

            if(typeof window != 'undefined'){
                $(window).off('.floor-plan').on('resize.floor-plan', () => {
                    FloorPlan.resizeFloorPlan(this.$Container);
                });
            }
        }

        private static resizeFloorPlan($container) {
            if (Session.Mode == Enums.Mode.FloorPlan) {
                if(typeof $container != 'undefined') {
                    let maxHeight = -1;
                    let $parent;

                    if (($parent = $container.closest('main#content')).length) {
                        maxHeight = $parent.height();
                    } else if (($parent = $container.closest('.floor-plan-container')).length) {
                        maxHeight = $parent.height();
                    }

                    if (maxHeight > 0) {
                        $container.find('svg').attr('height', maxHeight + 'px');
                    }
                }
            }
        }

        private static getLayoutInformation(element: Model.Elements.Element,
            floorPlanSettings: IFloorPlanSettings): { Layout: string, PassedParents: Array<string> } {
            let layout;
            let passedParents = [];

            if (!!element.Layout) {
                layout = element.Layout;
                floorPlanSettings.IsCurrentLocationsLayout = true;
            } else {
                let parent = element;
                let ancestor;

                while ((parent = parent.Parent)) {
                    passedParents.push(parent.OID);

                    if (parent.Layout) {
                        ancestor = parent;
                        break;
                    }
                }

                if (ancestor) {
                    layout = ancestor.Layout;
                    floorPlanSettings.IsCurrentLocationsLayout = false;
                }
            }

            return {
                Layout: layout,
                PassedParents: passedParents
            };
        }

        private static setVisibilityOfGraphics($svg: any, passedParents: Array<string>): void {
            const $locations = $svg.find('#locations');

            if (passedParents.length) {
                $locations.find(passedParents.map(function (oid) {
                    return 'g[id="{0}"]'.format(oid);
                }).join(',')).attr('display', 'none');
            }
        }

        private static renderLayoutToContainer($container: any, layout: string): void {
            if ($container && $container.length) {
                if (!!layout) {
                    $container.html(layout);

                    FloorPlan.resizeFloorPlan($container);

                    return $container.find('.floor-plan');
                } else {
                    $container.html('');
                }
            }
        }

        private static bindMarkClickEvent(mark: Model.Floor.Mark, deletionMode: boolean) {
            if (deletionMode) {
                mark.BindDeleteOnClickEvent();
            } else {
                mark.BindEvents();
            }
        }

        public CreateNewMark(): void {
            if (!((this.$FloorPlan || []).length && this.FloorPlanZoom)) {
                return;
            }

            const pan = this.FloorPlanZoom.getPan();
            const size = this.FloorPlanZoom.getSizes();
            const zoom = this.FloorPlanZoom.getZoom();

            const relativeX = (1 / size.width) * ((pan.x / zoom * -1) + (size.width / 2 / zoom));
            const relativeY = (1 / size.height) * ((pan.y / zoom * -1) + (size.height / 2 / zoom));

            let mark = new Model.Floor.Mark({
                FloorPlanID: this.ID,
                $FloorPlan: this.$FloorPlan,
                ID: this.setting.Marks.length,
                Color: this.MarksFillColor,
                StrokeColor: this.MarksStrokeColor,
                ZoomLevel: this.setting.CurrentZoomLevel,
                CreateNewMark: true,
                RawCoordinates: {
                    X: relativeX,
                    Y: relativeY
                }
            });

            this.setting.Marks.push(mark);
            this.$FloorPlan.find('g > svg').append(mark.Markup);
            Model.FloorPlan.bindMarkClickEvent(mark, this.RemoveMarkOnClick);
        }

        public GetFloorPlanMarks(): Array<Model.Issues.LocationMarker> | null {
            if ((this.setting.Marks || []).length) {
                let marks = [];

                for (let mCnt = 0, mLen = this.setting.Marks.length; mCnt < mLen; mCnt++) {
                    let mark = this.setting.Marks[mCnt];

                    if (mark && !mark.IsDeleted && mark.Type != null &&
                            mark.RawCoordinates && mark.RawCoordinates.X != null && mark.RawCoordinates.Y != null) {
                        marks.push(new Model.Issues.LocationMarker(mark.Type, mark.RawCoordinates.X, mark.RawCoordinates.Y));
                    }
                }

                return marks;
            }

            return null;
        }

        public SetRemoveMarkOnClick(value: boolean): void {
            if (typeof value !== 'boolean') {
                return;
            }

            this.RemoveMarkOnClick = value;

            this.setting.RemoveMarkOnClick = value;

            for (let mCnt = 0, mLen = this.setting.Marks.length; mCnt < mLen; mCnt++) {
                let mark = this.setting.Marks[mCnt];

                if (!mark) {
                    continue;
                }

                Model.FloorPlan.bindMarkClickEvent(mark, value);
            }
        }

        private onPanFloorPlan(evt: any): void {
            if (evt.stopPropagation instanceof Function) {
                evt.stopPropagation();
            }
        }

        private onDblClickFloorPlan(): void {
            this.setting.Zoom.reset();
            this.setInfoWindowPosition();
        }

        private onDrawingClick(evt: any, $floorPlan: any): void {
            const $this = $(evt.currentTarget);
            const $parentGraphic = $this.parents('g');
            const oid = $parentGraphic.attr('id');

            let element = View.CurrentView === Enums.View.Scheduling ?
                          ParameterList.GetElementRevisionByOID(oid) :
                          DAL.Elements.GetByOID(oid);

            evt.stopPropagation();

            this.$selectedFloorPlanGraphicsElement = $this;

            this.resetAlphaValueOfMarks($floorPlan);

            const color = new Model.Color($parentGraphic.attr('fill') || '#aa4643');
            color.setAlpha(0.8);
            $this.attr('fill', color.getRGBA());

            $('body').one('click.resetAlphaValueOfMarks', () => {
                this.resetAlphaValueOfMarks($floorPlan);
            });

            this.createInfoWindow(element, $floorPlan);
        }

        private getFloorPlanLayout(id: string, location: Model.Elements.Element, floorPlanHeight: string): string {
            if (!location) {
                return;
            }

            const pdfRegex = /([\dA-F]{8}-[\dA-F]{4}-[\dA-F]{4}-[\dA-F]{4}-[\dA-F]{12})\.pdf/i;
            const noAuthHashRegex = /xlink:href="([\dA-F]{8}-[\dA-F]{4}-[\dA-F]{4}-[\dA-F]{4}-[\dA-F]{12}\.[\w\d]+)"/i;

            // noinspection XmlUnusedNamespaceDeclaration
            const $svg = $(
                `<svg class="floor-plan" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="${floorPlanHeight || '100%'}"></svg>`);

            $svg.svg();
            const svg = $svg.svg('get');
            svg.clear();

            const layoutInformation = Model.FloorPlan.getLayoutInformation(location, this.setting);
            let layout = layoutInformation.Layout;

            if (!layout) {
                return;
            }

            if (!Session.IsSmartDeviceApplication) {
                layout = layout.replace(noAuthHashRegex,
                    `xlink:href="${Session.BaseURI}files/$1"`);
            } else {
                let floorPlanPath = Utils.GetResourcesPath();

                if (Session.IsRunningOnIOS) {
                    floorPlanPath = Utils.FixIOSFilepath(floorPlanPath);
                }

                layout = layout.replace(noAuthHashRegex, `xlink:href="${floorPlanPath}$1"`);
            }

            layout = layout.replace(pdfRegex, '$1.png');
            this.$layout = $(layout);
            this.$layout.append('<rect x="0" y="0" width="100%" height="100%" fill="transparent" fill-opacity="0" stroke="#000" stroke-width="1" pointer-events="none"></rect>');
            this.$layout.find('#layoutPlan').removeAttr('id');

            svg.add(this.$layout);

            this.calcAlpha($svg);
            Model.FloorPlan.setVisibilityOfGraphics($svg, layoutInformation.PassedParents);

            $svg.attr('id', id);

            let svgStr = $('<div></div>').append($svg).html();

            if (/NS\d:href/.test(svgStr)) {
                svgStr = svgStr.replace(/NS\d:href/ig, 'xlink:href');
            }

            return svgStr;
        }

        private calcAlpha($svg: any): void {
            $svg.svg();
            const canvas = $svg.svg('get');

            $('g', $svg).each((idx, g) => {
                let $g = $(g);

                if ($g.attr('class') !== 'svg-pan-zoom_viewport') {
                    const color = new Model.Color($g.attr('fill') || '#aa4643');
                    color.setAlpha(0.5);
                    canvas.configure(g, {
                        'fill': color.getRGBA()
                    });
                }
            });
        }

        private setInfoWindowPosition($floorPlan?: any): void {
            if (!($floorPlan || []).length) {
                return;
            }

            const $infoWindow = this.setting.$InfoWindow;

            if (this.setting.Zoom && $infoWindow && $infoWindow.is(':visible')) {
                let infoWindowPosition = this.getInfoWindowPosition($floorPlan, this.$selectedFloorPlanGraphicsElement);

                $infoWindow.offset({
                    top: infoWindowPosition.Offset.Y,
                    left: infoWindowPosition.Offset.X
                });
            }
        }

        private getInfoWindowPosition($floorPlan: any, bindingElement: any): Dictionary<any> {
            const margin: number = 10;
            const rect = bindingElement[0].getBoundingClientRect();
            const elemOffset = {
                left: rect.x,
                top: rect.y
            };

            const $infoWindow: any = this.setting.$InfoWindow;
            const infoWindowWidth = $infoWindow.width();
            const infoWindowHeight = $infoWindow.height();

            const $win: any = $(window);
            const windowScrollOffset = $win.scrollTop();
            const windowHeight = $win.height();

            let position: string;

            if ((elemOffset.left - infoWindowWidth - margin) > 0 &&
                (elemOffset.left - infoWindowWidth - margin) > $floorPlan.parent().offset().left &&
                (elemOffset.top - windowScrollOffset) + (rect.height / 2) + (infoWindowHeight / 2) < windowHeight) {
                position = 'left';
            } else if ((elemOffset.left + rect.width + windowHeight + margin) < $win.width() &&
                (elemOffset.top - windowScrollOffset) + (rect.height / 2) + (infoWindowHeight / 2) < windowHeight) {
                position = 'right';
            } else if ((elemOffset.top - windowScrollOffset - infoWindowHeight - margin) > 0) {
                position = 'top';
            } else if ((elemOffset.top - windowScrollOffset + rect.height + infoWindowHeight + margin) < windowHeight) {
                position = 'bottom';
            }

            let offset;
            let offsetTop, offsetLeft: number;
            if ((offset = this.getInfoWindowOffset(position, elemOffset, rect, margin, $win))) {
                offsetTop = offset.offsetTop;
                offsetLeft = offset.offsetLeft;
            }

            return {
                Position: position,
                Offset: {
                    X: offsetLeft,
                    Y: offsetTop
                }
            };
        }

        private getInfoWindowOffset(position: string, offset: any, elem: any, margin: number,
                                    $win: any): { offsetTop?: number, offsetLeft?: number } {
            const returnObj = {
                offsetTop: null,
                offsetLeft: null
            };

            const $infoWindow = this.setting.$InfoWindow;

            $infoWindow.removeClass('top bottom right left');

            switch (position) {
                case 'top':
                    $infoWindow.addClass('bottom');

                    if (offset.left + (elem.width / 2) - ($infoWindow.width() / 2) <= 0) {
                        returnObj.offsetTop = offset.top - $infoWindow.height() - margin;
                        returnObj.offsetLeft = offset.left;

                        $infoWindow.addClass('left');
                    } else if (offset.left + (elem.width / 2) + ($infoWindow.width() / 2) > $win.width()) {
                        returnObj.offsetTop = offset.top - $infoWindow.height() - margin;
                        returnObj.offsetLeft = offset.left + elem.width - $infoWindow.width();
                        $infoWindow.addClass('right');
                    } else {
                        returnObj.offsetTop = offset.top - margin - $infoWindow.height();
                        returnObj.offsetLeft = offset.left + (elem.width / 2) - ($infoWindow.width() / 2);
                    }

                    return returnObj;
                case 'bottom':
                    $infoWindow.addClass('top');

                    if (offset.left + (elem.width / 2) - ($infoWindow.width() / 2) <= 0) {
                        returnObj.offsetTop = offset.top + elem.height + margin;
                        returnObj.offsetLeft = offset.left;
                        $infoWindow.addClass('left');
                    } else if (offset.left + (elem.width / 2) + ($infoWindow.width() / 2) > $win.width()) {
                        returnObj.offsetTop = offset.top + elem.height + margin;
                        returnObj.offsetLeft = offset.left + elem.width - $infoWindow.width();
                        $infoWindow.addClass('right');
                    } else {
                        returnObj.offsetTop = offset.top + elem.height + margin;
                        returnObj.offsetLeft = offset.left + (elem.width / 2) - ($infoWindow.width() / 2);
                    }

                    return returnObj;
                case 'left':
                    returnObj.offsetTop = offset.top + (elem.height / 2) - ($infoWindow.height() / 2);
                    returnObj.offsetLeft = offset.left - $infoWindow.width() - margin;
                    $infoWindow.addClass('right');

                    return returnObj;
                case 'right':
                    returnObj.offsetTop = offset.top + (elem.height / 2) - ($infoWindow.height() / 2);
                    returnObj.offsetLeft = offset.left + elem.width + margin * 2;
                    $infoWindow.addClass('left');

                    return returnObj;
                default:
                    $infoWindow.css({
                        top: ($win.height() / 2) - ($infoWindow.outerHeight() / 2),
                        left: '50%'
                    });

                    break;
            }

            return null;
        }

        private createInfoWindow(location: Model.Elements.Element, $floorPlan: any): void {
            if (!location) {
                return;
            }

            const $container = $floorPlan.parent();
            const $infoWindow = $(Templates.FloorPlanInfoWindow(
                {
                    LocationOID: location.OID,
                    Title: location.Title,
                    Parent: location.Parent.Title
                }));

            $container.append($infoWindow);

            this.setting.$InfoWindow = $infoWindow;

            this.setInfoWindowPosition($floorPlan);

            $infoWindow.on('click', (evt) => {
                evt.stopPropagation();
            });

            $infoWindow.find('.show-location-information').on('click', () => {
                Utils.LocationInformation.Show(location.OID);
            });

            $infoWindow.find('.goto-location').on('click', () => {
                this.setting.OnNavigateToLocation(location);
                $infoWindow.remove();

                this.setting.$InfoWindow = null;
            });
        }

        private resetAlphaValueOfMarks($floorPlan: any): void {
            if (!$floorPlan.length) {
                return;
            }

            const $infoWindow = this.setting.$InfoWindow;

            if ($infoWindow) {
                $infoWindow.remove();
                this.setting.$InfoWindow = null;
            }

            $floorPlan.parent().svg();
            let canvas = $floorPlan.parent().svg('get');

            if (canvas) {
                $floorPlan.find('circle, polygon, rect').each(function (idx, drawing) {
                    let $drawing = $(drawing);

                    if ($drawing.parent().attr('class') !== 'svg-pan-zoom_viewport' &&
                        !$drawing.parent().hasClass('floor-plan') &&
                        !($drawing.parent().attr('class') || '').contains('mark')) {
                        let color = new Model.Color($drawing.parents('g').attr('fill') || '#aa4643');
                        color.setAlpha(0.5);
                        canvas.configure(drawing, {
                            'fill': color.getRGBA()
                        });
                    }
                });
            }
        }

        private getFloorPlanTouchEventsHandler(): { haltEventListeners: Array<string>, init: (options: any) => void, destroy: () => void } {
            let instance = this;

            return {
                haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
                init: function (options) {
                    let initialScale = 1;
                    let pannedX = 0;
                    let pannedY = 0;

                    this.hammer = Hammer(options.svgElement, {
                        inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
                    });

                    this.hammer.get('pinch').set({
                        enable: true
                    });

                    this.hammer.on('panstart panmove', function (evt) {
                        let $target = $(evt.target);

                        if ($target.hasClass('mark') || $target.parent().hasClass('mark')) {
                            return;
                        }

                        if (evt.type === 'panstart') {
                            pannedX = 0;
                            pannedY = 0;
                        }

                        options.instance.panBy({
                            x: evt.deltaX - pannedX,
                            y: evt.deltaY - pannedY
                        });

                        pannedX = evt.deltaX;
                        pannedY = evt.deltaY;
                    });

                    this.hammer.on('pinchstart pinchmove', function (evt) {
                        if (evt.type === 'pinchstart') {
                            initialScale = options.instance.getZoom();
                            options.instance.zoom(initialScale * evt.scale);
                        }

                        options.instance.zoom(initialScale * evt.scale);

                        instance.onPanFloorPlan(evt.srcEvent);
                    });

                    $(options.svgElement).on('touchmove', function (evt) {
                        evt.stopPropagation();
                        evt.preventDefault();
                    });
                },
                destroy: function () {
                    this.hammer.destroy();
                }
            };
        }

        private bindEvents($floorPlan: any, $container: any): any {
            if (($floorPlan || []).length) {
                $floorPlan.find('circle, polygon, rect').on('click', (evt) => {
                    this.onDrawingClick(evt, $floorPlan);
                });

                $floorPlan.find('.viewport-selector').on('dblclick', this.onDblClickFloorPlan);

                const instance = this;
                const borderLimits = {
                    Top: 0,
                    Right: 0,
                    Bottom: 0,
                    Left: 0,
                    IsUnset: true
                };

                const setBorderLimits = (sizes) => {
                    const gutterWidth = 30;
                    const gutterHeight = 30;

                    borderLimits.Left = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth;
                    borderLimits.Right = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom);
                    borderLimits.Top = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight;
                    borderLimits.Bottom = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom);
                    borderLimits.IsUnset = false;
                };

                return svgPanZoom($floorPlan.get(0), {
                    customEventsHandler: this.getFloorPlanTouchEventsHandler(),
                    dblClickZoomEnabled: false,
                    viewportSelector: $container.getSelector(),
                    eventsListenerElement: document.querySelector($container.getSelector() + ' svg'),
                    beforePan: function (oldPan, newPan) {
                        // TODO nach rotieren des Bildschirms die Limits neu setzen
                        if (borderLimits.IsUnset) {
                            setBorderLimits(this.getSizes());
                        }

                        return {
                            x: Math.max(borderLimits.Left, Math.min(borderLimits.Right, newPan.x)),
                            y: Math.max(borderLimits.Top, Math.min(borderLimits.Bottom, newPan.y))
                        }
                    },
                    onPan: function (evt) {
                        if (instance.setting.$InfoWindow) {
                            instance.resetAlphaValueOfMarks($floorPlan);
                        }

                        if (!Session.IsSmartDeviceApplication) {
                            instance.onPanFloorPlan(evt);
                        }
                    },
                    onZoom: function (zoomLevel) {
                        if (instance.setting.$InfoWindow) {
                            instance.resetAlphaValueOfMarks($floorPlan);
                        }

                        instance.setting.CurrentZoomLevel = zoomLevel;
                        setBorderLimits(this.getSizes());

                        const $image = $floorPlan.find('image');
                        let imageWidth = 1024;

                        if ($image.length) {
                            imageWidth = $image[0].getBBox().width;
                        }

                        const scale = Model.Floor.Mark.GetScale(imageWidth, zoomLevel);
                        const $markers = $floorPlan.find('.mark > path');
                        const mLen = $markers.length;

                        for (let mCnt = 0; mCnt < mLen; mCnt++) {
                            const $marker = $markers.eq(mCnt);
                            let transform = $marker.attr('transform');

                            transform = transform.replace(/scale\(\d*(.\d*)?\)/, `scale(${scale})`);

                            $marker.attr('transform', transform)
                        }
                    },
                    zoomScaleSensitivity: 0.2
                });
            }
        }

        private addExistingMarks(marks: Array<Model.Issues.LocationMarker>): void {
            if ((this.$FloorPlan || []).length && (marks || []).length) {
                const mLen = marks.length;

                for (let mCnt = 0; mCnt < mLen; mCnt++) {
                    const existingMark = marks[mCnt];
                    const mark = new Model.Floor.Mark({
                        FloorPlanID: this.ID,
                        $FloorPlan: this.$FloorPlan,
                        Color: this.MarksFillColor,
                        StrokeColor: this.MarksStrokeColor,
                        Type: existingMark.Type,
                        ID: this.setting.Marks.length,
                        RawCoordinates: {
                            X: existingMark.X,
                            Y: existingMark.Y
                        }
                    });

                    this.setting.Marks.push(mark);
                    this.$FloorPlan.find('g > svg').append(mark.Markup);

                    Model.FloorPlan.bindMarkClickEvent(mark, this.RemoveMarkOnClick);
                }
            }
        }
    }
}