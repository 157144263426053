//imports-start
//// <reference path="../definitions.d.ts"  />
/// <reference path="../utils/utils.spinner.ts"  />
//// <reference path="./app.session.ts"  />
//// <reference path="../enums.ts"  />
/// <reference path="./app.parameter-list.ts"  />
/// <reference path="./app.menu.ts"  />
/// <reference path="./app.ts"  />
/// <reference path="./app.issue-report.ts"  />
/// <reference path="../dal/elements.ts" />
//imports-end

module View {
    export let CurrentView: Enums.View = Enums.View.Login;

    let $content;
    let $locationPicker = $('.location-picker');
    let $headerBar = $('nav.navbar');

    let _newView: Enums.View;
    let _refreshMethod: Function = null;
    let _refreshTimeout: number;
    let _lastRefresh: Date = new Date();

    function onOldViewIsSchedulingOrInspection(oldViewIsInspection: boolean): void {
        Utils.Spinner.Lock();

        if (_newView !== View.CurrentView) {
            $('.footer-toolbar').remove();
            $content.siblings('.content-header').remove();
        }

        if (Session.Settings.ShowTreeView && _newView !== Enums.View.Login && $locationPicker.is(':visible')) {
            if (oldViewIsInspection) {
                App.GetTree()
                    .UpdateRoute('location', null)
                    .SetOnNodeClick(null)
                    .Build();
            } else {
                App.UpdateTree();
            }
        }

        if (_newView != View.CurrentView) {
            $('.issue-information-sidebar').remove();

            // Filter beim Verlassen der Begehung/Planaufgabe zurücksetzen
            if (oldViewIsInspection) {
                IssueReport.ResetFilters(true);
            }

            ParameterList.ResetFilters();
        }

        Utils.Spinner.Unlock();
        Utils.Spinner.Hide();
    }

    function onOldViewIsMainView(): void {
        $headerBar.find('.btn-issue-filters').removeClass('invisible');

        if (Session.IsSmartDeviceApplication) {
            $headerBar.find('.synchronisation').removeClass('invisible');
        }

        if (_newView !== View.CurrentView) {
            $('.footer-toolbar').remove();
        }
    }

    function onOldViewIsForm(): void {
        ParameterList.ResetFilters();

        if (_newView !== View.CurrentView) {
            $('.issue-information-sidebar').remove();

            $content.siblings('.footer-toolbar, .content-header').remove();
            $content.find('.content').removeClass('has-toolbar');
        }
    }

    function onOldViewIsSyncCenter(): void {
        $content.siblings('.footer-toolbar').remove();
        $content.find('.content').removeClass('has-toolbar without-padding-top');
        $headerBar.find('.btn-issue-filters').removeClass('invisible');
    }

    function onNewViewIsLogin(): void {
        $('body').css('padding', 10);
        $('.footer-toolbar').remove();
        $content.attr('class', 'container');
        $locationPicker.addClass('hidden');
        $headerBar.addClass('hidden');

        Menu.HideBackButton();
    }

    export function UpdateBackButton() {
        const issueBarWidth = IssueView.GetIssueSidebarWidth();

        if (Session.Settings.ShowTreeView) {
            $('.back-button').removeClass('left');
        } else if (issueBarWidth > 0) {
            $('.back-button').removeClass('left');
            $('.back-button').css('left', issueBarWidth + 15, 'important');
        } else {
            $('.back-button').addClass('left');
            $('.back-button').css('left', '');
        }
    }

    function onNewViewNeedsTreepicker(newView: Enums.View): void {
        const issueBarWidth = IssueView.GetIssueSidebarWidth();

        if (Session.Settings.ShowTreeView) {
            $locationPicker.removeClass('hidden');
            $content
                .attr('class', 'col-xs-12 col-sm-8 col-sm-offset-4 col-md-9 col-md-offset-3')
                .css('left', issueBarWidth);

            if (Session.IsSmartDeviceApplication) {
                $locationPicker.find('.qr-code').removeClass('hidden');

                // iOS only required
                if (Session.NfcEnabled && Session.IsRunningOnIOS) {
                    $locationPicker.find('.nfc').removeClass('hidden');
                }
            }
        } else {
            $locationPicker.addClass('hidden');

            if ($(document).width() >= 768) {
                $content.attr('class', 'col-xs-12')
                    .css('left', issueBarWidth);
            } else {
                $content.css('left', '');
            }

            if (Session.Mode === Enums.Mode.Calendar) {
                $content.addClass('absolute-pos-content');
            }
        }

        UpdateBackButton();

        const isFullWidthMode = Session.Mode === Enums.Mode.Calendar ||
            Session.Mode === Enums.Mode.Menu;

        if (isFullWidthMode) {
            $content.addClass('full-width');
        }

        if (newView === Enums.View.Main) {
            resetHeaderNavigationSelection();
        }

        if (Utils.InArray([Enums.View.Scheduling, Enums.View.Inspection], newView)) {
            let $footerToolbar;

            if (_newView !== View.CurrentView) {
                $footerToolbar = $(Templates.Menus.FooterToolbar({
                    IsInspection: newView === Enums.View.Inspection,
                    IsIssueView: newView !== Enums.View.Main,
                    ShowIssueReportButtons: false,
                    AlignToContentContainer: Session.Settings.ShowTreeView,
                    LocationOID: Session.CurrentLocation.OID,
                    ShowCreateIssue: false,
                    CanModifyIssue: false
                }));

                $content.before($footerToolbar);

                if (newView === Enums.View.Inspection) {
                    IssueReport.BindFooterToolbarEvents();
                }
            } else {
                $footerToolbar = $('.footer-toolbar');
            }

            if ($footerToolbar.length) {
                $locationPicker.css('bottom', $footerToolbar.outerHeight());
            }
        } else {
            $locationPicker.css('bottom', '');
        }

        App.ResizeLocationPicker();
    }

    function onNewViewIsForm(): void {
        $locationPicker.addClass('hidden');

        if (_newView !== View.CurrentView) {
            let issue = IssueView.GetCurrentIssue();
            let issueType: Enums.IssueType,
                locationOID: string;

            if (issue) {
                issueType = issue.Type;
                locationOID = issue.AssignedElementOID;
            }

            $content.before(Templates.Menus.FooterToolbar({
                IsIssueView: true,
                ShowIssueReportButtons: false,
                AlignToContentContainer: false,
                IssueType: issueType,
                LocationOID: locationOID,
                ShowCreateIssue: Utils.CanUserCreateIssueType(issueType, locationOID),
                CanModifyIssue: issue ? Utils.CanUserModifyIssue(issue) : Utils.CanUserCreateIssueType(issueType, locationOID)
            }));
        }

        $content.attr('class', 'col-xs-12');
        $content.find('.content').addClass('has-toolbar');
    }

    function onNewViewIsSyncCenter(): void {
        $headerBar.find('.btn-issue-filters').addClass('invisible');
        $content.attr('class', 'col-md-12').css('left', '');
        $content.siblings('.content-header').remove();
        $locationPicker.addClass('hidden');
        $content.before(Templates.Menus.SyncCenterFooter());
        $content.find('.content').addClass('has-toolbar without-padding-top');

        Menu.HideBackButton();
    }

    function onNewViewIsAboutPage(): void {
        $headerBar.find('.btn-issue-filters').addClass('invisible');

        Menu.HideBackButton();

        $content.attr('class', 'col-xs-12');
        $content.siblings('.content-header').remove();
        $locationPicker.addClass('hidden');
    }

    function onNewViewIsIndividualData() {
        if (Session.Settings.ShowTreeView) {
            $locationPicker.removeClass('hidden');
            $content.attr('class', 'col-xs-12 col-sm-8 col-sm-offset-4 col-md-9 col-md-offset-3');
        } else {
            $content.attr('class', 'col-xs-12');
        }

        if (Session.IsSmartDeviceApplication) {
            $locationPicker.find('.qr-code').addClass('hidden');
            $locationPicker.find('.nfc').addClass('hidden');
        }
    }

    function onOldViewIsAboutPage(): void {
        Menu.ShowBackButton();

        $headerBar.find('.btn-issue-filters').removeClass('invisible');
        $locationPicker.removeClass('hidden');
        App.CreateContentHeader();
    }

    function onNewViewIsCodeScanner(): void {
        Menu.HideBackButton();

        $headerBar.find('.btn-issue-filters').addClass('invisible');
        $content.attr('class', 'col-xs-12');
        $content.siblings('.content-header').remove();
        $locationPicker.addClass('hidden');
    }

    function onOldViewIsCodeScanner(): void {
        Menu.ShowBackButton();

        $headerBar.find('.btn-issue-filters').removeClass('invisible');
        $locationPicker.removeClass('hidden');
        App.CreateContentHeader();
    }

    function onOldViewIsIndividualData() {
        IndividualData.Close();

        if (Session.IsSmartDeviceApplication) {
            $locationPicker.find('.qr-code').removeClass('hidden');

            // iOS only required
            if (Session.NfcEnabled && Session.IsRunningOnIOS) {
                $locationPicker.find('.buttons .nfc').removeClass('hidden');
            }
        }
    }

    function resetHeaderNavigationSelection(): void {
        $headerBar.find('.active').removeClass('active');
        $headerBar.find('a[href="#main"]').parent().addClass('active');
    }

    function prepareView(newView: Enums.View): void {
        _newView = newView;

        switch (View.CurrentView) {
            case Enums.View.Scheduling:
            case Enums.View.Inspection:
            case Enums.View.FormBatchEdit:
                onOldViewIsSchedulingOrInspection(View.CurrentView === Enums.View.Inspection);
                break;
            case Enums.View.Form:
                onOldViewIsForm();
                break;
            case Enums.View.SyncCenter:
                onOldViewIsSyncCenter();
                break;
            case Enums.View.About:
                onOldViewIsAboutPage();
                break;
            case Enums.View.CodeScanner:
                onOldViewIsCodeScanner();
                break;
            case Enums.View.IndividualData:
                onOldViewIsIndividualData();
                break;
            case Enums.View.Main:
            default:
                onOldViewIsMainView();
                break;
        }

        switch (newView) {
            case Enums.View.Login:
                onNewViewIsLogin();
                break;
            case Enums.View.Scheduling:
            case Enums.View.Inspection:
                onNewViewNeedsTreepicker(newView);
                break;
            case Enums.View.Form:
                onNewViewIsForm();
                break;
            case Enums.View.SyncCenter:
                onNewViewIsSyncCenter();
                break;
            case Enums.View.IndividualData:
                onNewViewNeedsTreepicker(newView);
                onNewViewIsIndividualData();
                break;
            case Enums.View.About:
                onNewViewIsAboutPage();
                break;
            case Enums.View.CodeScanner:
                onNewViewIsCodeScanner();
                break;
            case Enums.View.Main:
            default:
                onNewViewNeedsTreepicker(newView);
                break;
        }

        _newView = null;
    }

    function onRefreshTimeout(): void {
        Utils.Synchronisation.Download.UpdateAccount()
            .then((accountChanges: { TreeStructureChanged: boolean }) => {
                const tmpLastRefresh = _lastRefresh;
                _refreshTimeout = null;
                _lastRefresh = new Date();

                Utils.Synchronisation.Download.UpdateElements(tmpLastRefresh, accountChanges.TreeStructureChanged)
                    .then(() => {
                        Refresh();
                        StartRefreshTimeout();
                    });
            });
    }

    export function StopRefreshTimeout(): void {
        if (!_refreshTimeout) {
            return;
        }

        _refreshMethod = null;
        window.clearTimeout(_refreshTimeout);
    }

    export function StartRefreshTimeout(): void {
        if (_refreshTimeout) {
            window.clearTimeout(_refreshTimeout);
            _refreshTimeout = null;
        }

        if (Session.IsSmartDeviceApplication || !Session.Settings.EnableViewRefresh || !Session.Settings.ViewRefreshTimeout) {
            return;
        }

        _refreshTimeout = window.setTimeout(onRefreshTimeout, 1000 * 60 * Session.Settings.ViewRefreshTimeout);
    }

    export function Open(newView: Enums.View, ...more): void {
        const viewArguments = arguments;

        _newView = newView;

        $content = $('#content');

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView) &&
            View.CurrentView !== newView) {
            IssueView.Clear(arguments[2] || false, true);
        }

        if (View.CurrentView !== newView) {
            prepareView(newView);
        }

        View.CurrentView = newView;

        switch (newView) {
            case Enums.View.Form:
            case Enums.View.FormBatchEdit:
            case Enums.View.Scheduling:
            case Enums.View.Inspection:
                IssueView.Init(viewArguments[1]);
                SetRefreshMethod(null);
                break;
            case Enums.View.IndividualData:
                IndividualData.Show(viewArguments[1], viewArguments[2]);
                SetRefreshMethod(null);
                break;
            case Enums.View.IssueFullscreen:
                const view = new Issues.FullscreenIssueViewer(viewArguments[1]);

                view
                    .load()
                    .then(() => view.render(), () => {
                        Utils.Message.Show(
                            i18next.t('FullscreenIssueViewer.Errors.LoadingData.MessageHeader'),
                            i18next.t('FullscreenIssueViewer.Errors.LoadingData.MessageBody')
                        );
                    });
                break;
            case Enums.View.Main:
                App.UpdateTree();
                App.UpdateTreeCounters();
            default:
                if (!Utils.InArray([Enums.View.About, Enums.View.SyncCenter], newView)) {
                    if (viewArguments.length >= 2 && viewArguments[1] instanceof Function) {
                        viewArguments[1]();
                    } else {
                        App.GetBadgeCounters()
                            .then((counters: Model.Menu.Counters) => Menu.Show(counters));
                    }
                }
                break;
        }

        App.ResizeLocationPicker();
    }

    export function SetView(newView: Enums.View, refreshMethod?: Function): void {
        $content = $('#content');

        if (View.CurrentView !== newView) {
            if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView) &&
                View.CurrentView !== newView) {
                IssueView.Clear(true, true);
            }

            if (newView == Enums.View.Main) {
                // primary to exit individual data view
                App.UpdateTree();
            }

            _newView = newView;

            prepareView(newView);
        }

        View.CurrentView = newView;
        _refreshMethod = refreshMethod;

        App.ResizeLocationPicker();
    }

    export function SetRefreshMethod(method: Function): void {
        _refreshMethod = method;
    }

    export function Refresh(): void {
        prepareView(View.CurrentView);

        if ($locationPicker.is(':visible')) {
            Utils.Spinner.Show();

            setTimeout(function() {
                App.UpdateTree();
                App.UpdateTreeCounters();

                if (_refreshMethod) {
                    _refreshMethod();
                }

                Utils.Spinner.Hide();
            }, 100);
        } else {
            $locationPicker.find('.tree-wrapper > .tree').html('');

            if (_refreshMethod) {
                _refreshMethod();
            }
        }

        App.ResizeLocationPicker();
    }

    export function SetSyncIconClass(clazz: string): void {
        $headerBar.find('.synchronisation')
            .removeClass('default error success animated')
            .addClass(clazz);

        $('#outdatedVersionMessageBox .btn-sync')
            .removeClass('animated')
            .addClass(clazz);
    }

    export function HasSyncIconClass(clazz: string): boolean {
        return $headerBar.find('.synchronisation').hasClass(clazz) ||
            $('#outdatedVersionMessageBox .btn-sync').hasClass(clazz);
    }

    export function EnableLogoutButton(): void {
        $headerBar.find('a[href="#logout"]')
            .parent()
            .removeClass('disabled');
    }

    export function DisableLogoutButton(): void {
        $headerBar.find('a[href="#logout"]')
            .parent()
            .addClass('disabled');
    }
}