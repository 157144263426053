//imports-start
/// <reference path="../issues/processing-information.ts"  />
//imports-end

module Model.Elements {
    export class IssueMetadata extends Issues.ProcessingInformation {
        Count: number;
        Identifiers: Array<string>;

        constructor(count: number = 0, identifiers: Array<string> = [], processingStatus: Enums.IssueProcessingStatus = Enums.IssueProcessingStatus.OK) {
            super(processingStatus);
            this.Count = count;
            this.Identifiers = identifiers;
        }
    }
}