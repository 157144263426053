//imports-start
/// <reference path="../../utils/utils.set.ts"  />
//imports-end

module Model.Scheduling {
    export class SchedulingMetadataElement {
        Identifier: string;
        RevisionIdentifier: string;
        Type: Enums.ElementType;
        ParentIdentifier?: string;
        Children: Array<SchedulingMetadataElement>;
        IsRecordingLockedByDefault: boolean;
        LocationIdentifier: string;

        constructor(identifier: string, revisionIdentifier: string, type: Enums.ElementType, parentIdentifier?: string, isRecordingLockedByDefault?: boolean) {
            this.Identifier = identifier;
            this.RevisionIdentifier = revisionIdentifier;
            this.Type = type;
            this.Children = [];
            this.IsRecordingLockedByDefault = isRecordingLockedByDefault || false;

            if (!!parentIdentifier) {
                this.ParentIdentifier = parentIdentifier;
            }
        }
    }
}
