//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../app/app.session.ts"  />
/// <reference path="../core/revision.ts"  />
/// <reference path="./raw-team.ts"  />
//imports-end

module Model.Teams {
    export class Team extends Model.Core.Revision {
        ParentOID?: string;
        Title: string;
        Description?: string;
        Position: number;
        Color?: string;
        ImageOID?: string;
        ImagePath?: string;
        Users: Array<any>;
        Parent?: Team;
        Children?: Array<Team>;
        Type;

        constructor(team: RawTeam) {
            super();

            this.initProperties(team);
        }

        private initProperties(team: RawTeam): void {
            this.OID = team.OID;
            this.RevisionOID = team.RevisionOID;
            this.ParentOID = team.ParentOID;
            this.Title = Utils.EscapeHTMLEntities(team.Title);
            this.Description = team.Description;
            this.Position = team.Position;
            this.Color = team.Color;
            this.ImageOID = team.ImageOID;
            this.Users = team.Users;
            this.Deleted = team.Deleted;

            if (this.Description) {
                this.Description = Utils.EscapeHTMLEntities(this.Description);
            }

            if (!this.ImageOID) {
                this.ImagePath = './img/group.svg';

                return;
            }

            const file = DAL.Files.GetByOID(this.ImageOID);

            if (!file) {
                return;
            }

            this.ImagePath = this.getImagePath(file);
        }

        private getImagePath(file: any): string {
            if (!file) {
                return;
            }

            if (!Session.IsSmartDeviceApplication) {
                return `${Session.BaseURI}images/s/${file.Filename}`;
            }

            if (Session.IsRunningOnIOS) {
                return Utils.FixIOSFilepath(Utils.GetResourcesPath() + file.Filename);
            }

            return Utils.GetResourcesPath() + file.Filename;
        }

        public toJSON() {
            return new RawTeam(this);
        }
    }
}
