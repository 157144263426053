//imports-start
/// <reference path="./property.ts"  />
//imports-end

module Model.Properties {
    export class RootLookup {
        States: Property;
        Classifications: Property;
        Priorities: Property;
        Keywords: Property;
        Categories: Property;
        Units: Property;
    }
}