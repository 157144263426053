module Model.FilterWindow {
    export class Group {
        public Title: string;
        public Position: number;
        public Items: Array<Model.FilterWindow.ButtonItem>;

        constructor(title: string, position: number, items: Array<Model.FilterWindow.ButtonItem>) {
            this.Title = title;
            this.Position = position;
            this.Items = items;
        }
    }
}