//imports-start
/// <reference path="./utils.base-filter-window.ts"  />
/// <reference path="../utils.element-picker-popup.ts"  />
/// <reference path="../../model/filter-window/group.ts"  />
/// <reference path="../../model/filter-window/button-item.ts"  />
/// <reference path="../../model/selection-window/filter.ts"  />
//imports-end

module Utils.FilterWindow {
    export class SchedulingFilterWindow extends BaseFilterWindow<Model.SelectionWindow.Filter>{
        constructor(predefinedFilter?: Model.SelectionWindow.Filter, onSave?: Function, zIndex?: number) {
            super(predefinedFilter, onSave, zIndex);
        }

        protected getAvailableFilterItems(): Array<Model.FilterWindow.Group> {
            return [ new Model.FilterWindow.Group(
                i18next.t('FilterWindow.Base.Properties'),
                1,
                [
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.Classifications,
                        i18next.t('Misc.Classification_plural'),
                        (this.filters.Classifications || []).length ? 'red' : null,
                        this.onBtnClassificationFilterClick,
                        'ticket')
                ]
            )];
        }

        private onBtnClassificationFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            Utils.ElementPickerPopup.Show({
                RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Classification),
                Items: DAL.Properties.GetByType(Enums.PropertyType.Classification),
                VisibleObjectTypes: [Enums.PropertyType.Classification],
                IsNotALocationTree: true,
                ForceExpand: true,
                MultiSelectionAllowed: true,
                SelectedItemIdentifiers: Utils.CloneArray(this.filters.Classifications),
                CannotCollapseRoot: true,
                ShowResetButton: true,
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Classifications'),
                WindowTitle: i18next.t('ElementPicker.SelectClassificationFilter'),
                ZIndex: this.zIndex + 50,
                OnConfirmSelection: (result) => {
                    this.filters.Classifications = result.SelectedItems;
                    $btn.toggleClass('red', (this.filters.Classifications || []).length > 0);
                    this.onAfterFilterChanged();
                },
                OnResetButtonClick: () => {
                    this.filters.Classifications = null;
                    $btn.removeClass('red');
                    this.onAfterFilterChanged();
                }
            });
        };
    }
}