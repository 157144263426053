/// <reference path="../definitions.d.ts"  />


module Utils.UserPicker {

    export interface IUserTeamsSelection {
        Users?: string[],
        Teams?: string[]
    }

    export interface IItemFilter {
        RoleFilter?: string[],
        TeamFilter?: string[]
    }

    export interface IFilter {
        AllowTeams?: boolean;
        AllowUsers?: boolean;
        Filters?: { Teams?: string[], Roles?: string[] };
        IsReadonly?: boolean;
        MaximumSelectionCount?: number;
        FilterIssueResponsibilities?: boolean;
        ShowLockedUsers?: boolean;
        ShowSystemUsers?: boolean;
    }

    export type OnSelectionResultFunc = (selection: IUserTeamsSelection) => void;

    const enum PickerMode {
        Users = 'users',
        Teams = 'teams',
        TeamMembers = 'team-members'
    }

    let $win, $overlay;
    let $header, $content, $footer;
    let $search;
    let $doc;

    let userSelectionAllowed: boolean;
    let isReadonly: boolean;
    let userTeams: string[], membersOfUserTeams: string[];
    let selectedLocation: Model.Elements.Element;
    let selectedItems: IUserTeamsSelection;
    let selectionMode: PickerMode;
    let itemFilters: IItemFilter,
        hierarchyFilter: string,
        filterIssueResponsibilites: boolean;
    let selectionBackup: IUserTeamsSelection,
        selectableItems: IUserTeamsSelection;
    let maxSelectionCount: number;
    let searchInput: Model.ClearableInput.Control;
    let fnCallback: OnSelectionResultFunc;
    let showSystemUsers: boolean;
    let showLockedUsers: boolean;
    let selectedTeamIdentifier: string;

    function onHierarchyFilterClick(): void {
        let $hierarchyFilter = $header.find('li[data-action="hierarchy-filter"]');

        switch ($hierarchyFilter.data('hierarchy')) {
            case 'global':
                $hierarchyFilter.data('hierarchy', 'current').attr('data-hierarchy', 'current');
                break;
            case 'current':
                $hierarchyFilter.data('hierarchy', 'root').attr('data-hierarchy', 'root');
                break;
            case 'root':
                $hierarchyFilter.data('hierarchy', 'global').attr('data-hierarchy', 'global');
                break;
        }

        hierarchyFilter = $hierarchyFilter.data('hierarchy');

        setSelectableTeamsAndUsers();
        render();
    }

    function onTabClick(): void {
        const $this = $(this);

        if ($this.data('tab') === selectionMode ||
            $this.attr('disabled')) {
            return;
        }

        selectionMode = $this.data('tab');
        selectedTeamIdentifier = null;

        render();
    }

    function getSelectableItems(): any[] {
        switch (selectionMode) {
            case PickerMode.Users:
            case PickerMode.TeamMembers:
                return getSelectableUsers();
            case PickerMode.Teams:
                return getSelectableTeams();
        }
    }

    function getSelections(): any[] {
        const result = [];

        (selectedItems.Users || []).forEach((userIdent: string) => {
            const user = DAL.Users.GetByOID(userIdent);

            if (!user) {
                return;
            }

            (<any>user).ItemType = PickerMode.Users;
            result.push(user);
        });

        (selectedItems.Teams || []).forEach((teamIdent: string) => {
            const team = DAL.Teams.GetByOID(teamIdent);

            if (!team) {
                return;
            }

            (<any>team).ItemType = PickerMode.Teams;
            result.push($.extend(true, team, { Parent: null, Children: null }));
        });

        result.sort(Utils.SortByTitle);

        return result;
    }

    function resize(): void {
        const $dividers = $content.find('.divider');
        const $unselected = $content.find('.unselected');
        const $selected = $content.find('.selected');
        const $clientWin = $(window);
        const $firstDivider = $dividers.length > 1 ? $($dividers[0]) : $dividers;
        const firstDividerHeight = $firstDivider && $firstDivider.is(':visible') ? $firstDivider.outerHeight() : 0;
        const $secondDivider = $dividers.length > 1 ? $($dividers[1]) : null;
        const secondDividerHeight = $secondDivider && $secondDivider.is(':visible') ? $secondDivider.outerHeight() : 0;

        if ($clientWin.outerHeight() <= 600) {
            $win.css('height', $clientWin.outerHeight() - 20);
            $win.css('margin-top', -($win.outerHeight() / 2));
        } else {
            $win.css({
                'height': 600,
                'margin-top': -300
            });
        }

        const headerHeight = $win.find('.window-header').height();

        $content.css('height', $win.height() - headerHeight - $search.outerHeight() - $footer.outerHeight());

        let contentHeight = $content.outerHeight() - firstDividerHeight - secondDividerHeight - 2;

        if (!$unselected.is(':visible')) {
            $selected.css('height', contentHeight);
            return;
        }

        if (!$selected.is(':visible')) {
            if (!secondDividerHeight) {
                contentHeight = contentHeight + firstDividerHeight;
            }

            $unselected.css('height', contentHeight);
            return;
        }

        let selectedItemsHeight = (getSelections().length * 60) + 3;

        if (selectedItemsHeight > contentHeight / 2) {
            selectedItemsHeight = contentHeight / 2;
        }

        let unselectedItemsHeight = contentHeight - selectedItemsHeight + (secondDividerHeight ? firstDividerHeight : 0);

        $unselected.css('max-height', unselectedItemsHeight);
        $selected.css('height', selectedItemsHeight);

        const dividerHeight = (secondDividerHeight ? secondDividerHeight : firstDividerHeight);

        if (($unselected.height() + selectedItemsHeight + 2) < ($content.height() - dividerHeight)) {
            $selected.css('height', $content.height() - ($unselected.height() + dividerHeight + 2));
        }
    }

    function checkForModifications(): void {
        const hasModifications = !selectionBackup ||
            !Utils.Equals(selectionBackup.Users, selectedItems.Users, 'array') ||
            !Utils.Equals(selectionBackup.Teams, selectedItems.Teams, 'array');

        $footer.find('.btn-close').toggleClass('hidden', hasModifications);
        $footer.find('.btn-apply, .btn-abort').toggleClass('hidden', !hasModifications);
    }

    function setSelectableTeamsAndUsers(): void {
        if (hierarchyFilter === 'global') {
            selectableItems = null;
            return;
        }

        const teams = [];
        const users = [];

        let location = hierarchyFilter === 'root' ? DAL.Elements.Root : selectedLocation;
        let allowAssigningAllUsersToIssues = false;

        while (location) {
            if (!(location.Teams || []).length) {
                location = location.Parent;
                continue;
            }

            allowAssigningAllUsersToIssues =
                Utils.UserHasRight(Session.User.OID, Enums.Rights.AllowAssigningAllUsersToIssues, true, location);

            for (let tCnt = 0, tLen = location.Teams.length; tCnt < tLen; tCnt++) {
                const team = DAL.Teams.GetByOID(location.Teams[tCnt].OID);

                if (!allowAssigningAllUsersToIssues && !Utils.InArray(Session.User.Teams, team.OID)) {
                    continue;
                }

                if (!Utils.InArray(teams, team.OID)) {
                    teams.push(team.OID);
                }

                if (!(team.Users || []).length) {
                    continue;
                }

                for (let pCnt = 0, pLen = team.Users.length; pCnt < pLen; pCnt++) {
                    const user = team.Users[pCnt];

                    if (Utils.InArray(users, user.OID)) {
                        continue;
                    }

                    users.push(user.OID);
                }
            }

            location = location.Parent;
        }

        selectableItems = {
            Users: users,
            Teams: teams
        };
    }

    function getSearchValue(): string | null {
        return $search && $search.is(':visible') ? $.trim(searchInput.GetFilter().SearchText) : null;
    }

    function applyAdditionalUserFilters(user: Model.Users.User): any | null {
        if (filterIssueResponsibilites &&
            !Utils.UserHasRight(Session.User.OID, Enums.Rights.AllowAssigningAllUsersToIssues) &&
            !Utils.InArray(membersOfUserTeams, user.OID)) {
            return;
        }

        const searchVal = getSearchValue();

        if (!searchVal) {
            return user;
        }

        const regex = new RegExp(Utils.EscapeHTMLEntities(searchVal), 'ig');

        if (!regex.test(user.Title)) {
            return;
        }

        return user;
    }

    function isUserSelectable(user: Model.Users.User): boolean {
        if (!showSystemUsers && (user.IsSystemUser || user.IsExternalUser)) {
            return false;
        }

        if (!showLockedUsers && user.IsLocked) {
            return false;
        }

        let isSelectable = true;

        if ((!selectableItems || Utils.InArray(selectableItems.Users, user.OID))
            && !Utils.InArray(selectedItems.Users, user.OID)) {
            if ((itemFilters.RoleFilter || []).length) {
                const teams = DAL.Teams.GetByUserOID(user.OID);
                let roleFound = false;

                if ((teams || []).length) {
                    for (let tCnt = 0, tLen = teams.length; tCnt < tLen; tCnt++) {
                        const team = teams[tCnt];

                        if ((itemFilters.TeamFilter || []).length
                            && !Utils.InArray(itemFilters.TeamFilter, team.OID)) {
                            continue;
                        }

                        const tmpUser = Utils.Where(team.Users, 'OID', '===', user.OID);

                        if (!(tmpUser && (tmpUser.Roles || []).length)) {
                            continue;
                        }

                        for (let rCnt = 0, rLen = tmpUser.Roles.length; rCnt < rLen; rCnt++) {
                            if (Utils.InArray(itemFilters.RoleFilter, tmpUser.Roles[rCnt])) {
                                roleFound = true;
                                break;
                            }
                        }

                        if (roleFound) {
                            break;
                        }
                    }
                }

                if (!roleFound) {
                    return;
                }
            }

            const teamIdentifierFilter = selectionMode === PickerMode.TeamMembers && !!selectedTeamIdentifier ?
                [selectedTeamIdentifier] :
                itemFilters.TeamFilter;

            if ((teamIdentifierFilter || []).length) {
                let teamFound = false;
                if ((user.Teams || []).length) {
                    for (let tCnt = 0, tLen = user.Teams.length; tCnt < tLen; tCnt++) {
                        if (Utils.InArray(teamIdentifierFilter, user.Teams[tCnt])) {
                            teamFound = true;
                            break;
                        }
                    }
                }

                isSelectable = teamFound;
            }
        } else {
            isSelectable = false;
        }

        if (isSelectable) {
            isSelectable = applyAdditionalUserFilters(user);
        }

        return isSelectable;
    }

    function getSelectableUsers(): Model.Users.User[] {
        const items = DAL.Users.GetAll()
            .filter((user: Model.Users.User) => isUserSelectable(user));

        items.sort(Utils.SortByLastname);

        return items;
    }

    function getSelectableTeams(): Model.Teams.Team[] {
        const items = DAL.Teams.GetAll()
            .filter((team: Model.Teams.Team) => isTeamSelectable(team, selectedItems.Teams, itemFilters.TeamFilter));

        items.sort(Utils.SortByTitle);

        return items;
    }

    function isTeamSelectable(team: Model.Teams.Team, selItems: string[], appliedFilters: string[]): any {
        if (selectableItems && !Utils.InArray(selectableItems.Teams, team.OID)) {
            return false;
        }

        if (Utils.InArray(selItems, team.OID)) {
            return false;
        }

        if (appliedFilters && !Utils.InArray(appliedFilters, team.OID)) {
            return false;
        }

        if (filterIssueResponsibilites &&
            !Utils.UserHasRight(Session.User.OID, Enums.Rights.AllowAssigningAllUsersToIssues) &&
            !Utils.InArray(userTeams, team.OID)) {
            return false;
        }

        const searchVal = getSearchValue();
        if (!!searchVal) {
            const regex = new RegExp(Utils.EscapeHTMLEntities(searchVal), 'ig');

            if (!regex.test(team.Title)) {
                return false;
            }
        }

        return true;
    }

    function destroy(): void {
        $win.remove();
        Utils.Overlay.DestroyWithTimeout($overlay);

        $doc.off('keydown.closeWindow');
        $(window).off('resize.UserPicker');

        if (!Utils.RecorditemEditor.IsVisible() && !Utils.IssueViewer.IsVisible()) {
            $('body').removeClass('modal-open');
        }

        $win = null;
        $header = null;
        $content = null;
        $footer = null;
        $overlay = null;
        $doc = null;

        selectedItems = null;
        selectionMode = null;
        itemFilters = null;
        hierarchyFilter = null;
        maxSelectionCount = null;
        selectionBackup = null;
        selectableItems = null;
        fnCallback = null;
        selectedTeamIdentifier = null;
    }

    function returnAndDestroy(): void {
        let result: IUserTeamsSelection = null;

        if (selectedItems) {
            if (selectedItems.Teams && selectedItems.Teams.length) {
                result = result || {};
                result.Teams = selectedItems.Teams;
            }

            if (selectedItems.Users && selectedItems.Users.length) {
                result = result || {};
                result.Users = selectedItems.Users;
            }
        }

        fnCallback(result);
        destroy();
    }

    function removeAndDestroy(): void {
        fnCallback(null);
        destroy();
    }

    function onUnselectedItemClick(): void {
        if (!!maxSelectionCount && selectedItems.Users.length + selectedItems.Teams.length === maxSelectionCount) {
            return;
        }

        const $this = $(this);
        const oid = $this.data('oid');

        switch (selectionMode) {
            case PickerMode.Users:
            case PickerMode.TeamMembers:
                if (!Utils.InArray(selectedItems.Users, oid)) {
                    selectedItems.Users.push(oid);
                }
                break;
            case PickerMode.Teams:
                if (!Utils.InArray(selectedItems.Teams, oid)) {
                    selectedItems.Teams.push(oid);
                }
                break;
        }

        render();
        checkForModifications();
    }

    function onShowTeamMembersClick(evt: Event): void {
        evt.stopPropagation();

        const teamIdent = $(this).parents('li').data('oid');

        if (!teamIdent) {
            return;
        }

        selectedTeamIdentifier = teamIdent;
        selectionMode = PickerMode.TeamMembers;

        render();
    }

    function onHideTeamMembersClick() {
        selectedTeamIdentifier = null;
        selectionMode = PickerMode.Teams;

        render();
    }

    function onSelectedItemClick(): void {
        const $this = $(this);
        const oid = $this.data('oid');
        const type = $this.data('type');

        switch (type) {
            case PickerMode.Users:
                {
                    const idx = Utils.GetIndex(selectedItems.Users, oid);
                    if (idx !== -1) {
                        selectedItems.Users.splice(idx, 1);
                    }
                }
                break;
            case PickerMode.Teams:
                {
                    const idx = Utils.GetIndex(selectedItems.Teams, oid);
                    if (idx !== -1) {
                        selectedItems.Teams.splice(idx, 1);
                    }
                }
                break;
        }

        render();
        checkForModifications();
    }

    function toggleSearchRowVisibility(): void {
        $(this).toggleClass('active');
        $search.toggleClass('hidden');

        if ($search.is(':visible')) {
            $search.find('input').focus();
        }

        resize();
    }

    function render(showDeleteButton?: boolean): void {
        const items = getSelectableItems();
        const selected = getSelections();

        if (!$win) {
            buildWindow(items, selected, showDeleteButton);
        } else {
            refreshWindow(items, selected, showDeleteButton);
        }

        $header.find(`li[data-tab="${selectionMode}"]`)
            .addClass('active')
            .siblings('[data-tab]')
            .removeClass('active');
    }

    function buildWindow(items: any[], selected: string[], showDeleteButton?: boolean): void {
        $win = $(Templates.UserPicker.Window({
            SelectableItemsCount: (items || []).length,
            SelectableItemsMarkup: getSelectableItemsListMarkup(items, selected),
            SelectedItems: selected,
            IsReadonly: isReadonly,
            HideAddButton: isReadonly || !!maxSelectionCount && selected.length === maxSelectionCount,
            ShowDeleteButton: !!showDeleteButton
        }));

        $header = $win.find('.header-navigation');
        $search = $win.find('.search');
        $content = $win.find('.content');
        $footer = $win.find('.footer');

        $('body').append($win);

        searchInput.Build($win.find('.search'));

        $overlay = Utils.Overlay.Generate('olUserPicker', 10000, destroy);

        bindEvents();
        resize();
    }

    function refreshWindow(items: any[], selected: string[], showDeleteButton?: boolean): void {
        $content = $(Templates.UserPicker.Window({
            SelectableItemsCount: (items || []).length,
            SelectableItemsMarkup: getSelectableItemsListMarkup(items, selected),
            SelectedItems: selected,
            IsReadonly: isReadonly,
            HideAddButton: isReadonly || !!maxSelectionCount && selected.length === maxSelectionCount,
            ShowDeleteButton: !!showDeleteButton
        })).find('.content');

        $win.find('.content').replaceWith($content);

        resize();

        if (selectionMode === PickerMode.Teams) {
            $content.on('click.showTeamMembers', '.unselected li .show-team-members', onShowTeamMembersClick);
        }

        if (selectionMode === PickerMode.TeamMembers) {
            $content.on('click.hideTeamMembers', '.hide-team-members', onHideTeamMembersClick);
        }

        if (!isReadonly) {
            $content.on('click.addItem', '.unselected li', onUnselectedItemClick);
            $content.on('click.removeItem', '.selected li', onSelectedItemClick);
        }
    }

    function getSelectableItemsListMarkup(items: any[], selected: string[]) {
        if (selectionMode === PickerMode.TeamMembers) {
            return Templates.UserPicker.TeamMembers({
                Team: DAL.Teams.GetByOID(selectedTeamIdentifier),
                Items: items,
                DataMode: PickerMode.Users,
                IsReadonly: isReadonly,
                HideAddButton: isReadonly || !!maxSelectionCount && selected.length === maxSelectionCount
            });
        }

        return Templates.UserPicker.SelectableItemsList({
            Items: items,
            DataMode: selectionMode,
            IsReadonly: isReadonly,
            HideAddButton: isReadonly || !!maxSelectionCount && selected.length === maxSelectionCount,
            UserSelectionAllowed: userSelectionAllowed
        });
    }

    function hideByEscape(evt): void {
        if (evt.keyCode === 27) {
            destroy();
        }
    }

    function bindEvents(): void {
        $header.on('click.changeMode', 'li[data-tab]:not(.active)', onTabClick);
        $header.on('click.toggleSearch', 'li[data-action="search"]', toggleSearchRowVisibility);
        $header.on('click.changeHierarchy', 'li[data-action="hierarchy-filter"]', onHierarchyFilterClick);

        searchInput.ClearFilterListeners()
            .AddFilterListener(() => {
                render();
            });

        if (selectionMode === PickerMode.Teams) {
            $content.on('click.addItem', '.unselected li .show-team-members', onShowTeamMembersClick);
        }

        if (!isReadonly) {
            $content.on('click.addItem', '.unselected li', onUnselectedItemClick);
            $content.on('click.removeItem', '.selected li', onSelectedItemClick);
        }

        $footer.on('click', '.btn:not(.btn-apply, .btn-delete)', destroy);
        $footer.on('click', '.btn-apply', returnAndDestroy);
        $footer.on('click', '.btn-delete', removeAndDestroy);

        $doc.on('keydown.closeWindow', hideByEscape);
        $(window).on('resize.UserPicker', resize);
    }

    export function Show(filters: IFilter, selections: IUserTeamsSelection, location: Model.Elements.Element, callback: OnSelectionResultFunc, showDeleteButton?: boolean): void {
        if (!filters || !(callback instanceof Function)) {
            return;
        }

        const searchOptions = new Model.ClearableInput.Settings({
            ID: 'user-selection-search-input',
            InputType: 'text',
            ShowClearButton: true
        });

        searchInput = new Model.ClearableInput.Control(searchOptions);

        $doc = $(document);
        $('body').addClass('modal-open');

        isReadonly = filters.IsReadonly || false;
        selectedLocation = location;
        maxSelectionCount = filters.MaximumSelectionCount;
        filterIssueResponsibilites = filters.FilterIssueResponsibilities || false;
        showSystemUsers = filters.ShowSystemUsers || false;
        showLockedUsers = filters.ShowLockedUsers || false;

        if (filterIssueResponsibilites) {
            filterAvailableUsersAndTeamsByOwnTeamAssignments();
        }

        if (!selectionMode) {
            if (!initMode(filters)) {
                return;
            }
        }

        fnCallback = callback;
        selectedItems = {};
        itemFilters = {};

        if (selections) {
            selections.Users = selections.Users || [];
            selections.Teams = selections.Teams || [];

            selectedItems = $.extend(true, {}, selections);
            selectionBackup = $.extend(true, {}, selections);
        } else {
            selectedItems = {
                Users: [],
                Teams: []
            };
        }

        if (filters.Filters) {
            if ((filters.Filters.Roles || []).length) {
                itemFilters.RoleFilter = filters.Filters.Roles;
            }

            if ((filters.Filters.Teams || []).length) {
                itemFilters.TeamFilter = filters.Filters.Teams;
            }
        }

        hierarchyFilter = 'global';
        userSelectionAllowed = filters.AllowUsers;

        setSelectableTeamsAndUsers();
        render(showDeleteButton);

        if (!filters.AllowUsers) {
            $header.find('li[data-tab="users"]').attr('disabled', 'disabled');
        }

        if (!filters.AllowTeams) {
            $header.find('li[data-tab="teams"]').attr('disabled', 'disabled');
        }
    }

    function initMode(filters): boolean {
        if (filters.AllowUsers) {
            selectionMode = PickerMode.Users;
            return true;
        }

        if (filters.AllowTeams) {
            selectionMode = PickerMode.Teams;
            return true;
        }

        Utils.Message.Show(i18next.t('UserPicker.FaultySettings.MessageHeader'),
            i18next.t('UserPicker.FaultySettings.MessageBody'),
            {
                Close: true
            });

        return false;
    }

    function filterAvailableUsersAndTeamsByOwnTeamAssignments(): void {
        const teamsOfUser = DAL.Teams.GetByUserOID(Session.User.OID);

        membersOfUserTeams = $.unique($.map(teamsOfUser, function(team) {
            const members = [];

            if (!(team.Users || []).length) {
                return null;
            }

            for (let pCnt = 0, pLen = team.Users.length; pCnt < pLen; pCnt++) {
                members.push(team.Users[pCnt].OID);
            }

            if (members.length) {
                return members;
            }
        }));

        userTeams = $.map(teamsOfUser, function(team) {
            return team.OID;
        });
    }
}
