module Model.Issues {
    export class FilterPeriod {
        PeriodStart?: string;
        PeriodEnd?: string;

        constructor(start?: string, end?: string) {
            this.PeriodStart = start;
            this.PeriodEnd = end;
        }
    }
}