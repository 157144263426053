//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../enums.ts"  />
/// <reference path="../../utils/utils.date-time.ts"  />
/// <reference path="../date-time/period.ts"  />
/// <reference path="./filter-period.ts"  />
/// <reference path="./ifilter.ts"  />
//imports-end

module Model.Issues {
    export class Filter implements IFilter, Model.Filter.IResettableFilter {
        AggregateProcessingStatusesByIssueType?: boolean;
        Classifications?: Array<string>;
        Contacts?: Array<string>;
        ContactGroups?: Array<string>;
        CustomID?: string;
        Deadline?: Date;
        DeadlinePeriod?: FilterPeriod | Model.DateTime.Period;
        Description?: string;
        Forms?: Array<string>; // TODO implement within app
        IsArchived?: boolean = false;
        IssueIdentifiers?: Array<string>;
        Keywords?: Array<string>;
        LoadIssues?: boolean;
        LocationOID?: string;
        ModificationPeriod?: FilterPeriod;
        OnlyCurrentLocationAndNextChildren?: boolean;
        OnlyShowIssuesAssignedToUser?: boolean;
        Priorities?: Array<string>;
        ProcessingStatus?: Array<Enums.IssueProcessingStatus> | Enums.IssueProcessingStatus;
        Schedulings?: Array<string>;
        SearchText?: string;
        Skip?: number;
        Sorting?: Enums.SortingBy;
        SortingOrder?: Enums.SortingOrder;
        States?: Array<string>;
        Take?: number;
        Teams?: Array<string>;
        Title?: string;
        Types?: Array<Enums.IssueType>;
        Users?: Array<string>;
        UserSelectedTypes?: any;
        WithArchivedIssues?: boolean;    // TODO reimplement or check, because not supported property yet
        WithChildLocations?: boolean;
        WithOpenIssues?: boolean;
        WithProcessingStatusInformation?: boolean = true;   // new since API 19
        HideNonResponsibleIssues?: boolean;
        IncludeDerivations?: boolean;

        constructor(values?) {
            if (values) {
                for (let key in values) {
                    this[key] = values[key];
                }
                this.AggregateProcessingStatusesByIssueType = values.AggregateProcessingStatusesByIssueType || true;
            } else {
                this.AggregateProcessingStatusesByIssueType = true;
            }
        }

        SetIssueIdentifiers(identifiers: Array<string>): Filter {
            if (!(identifiers || []).length) {
                identifiers = null;
            }

            this.IssueIdentifiers = identifiers;

            return this;
        }

        SetLocationOID(locationOID: string): Filter {
            this.LocationOID = locationOID;

            return this;
        }

        SetTypes(types: Array<number>): Filter {
            this.Types = types;

            return this;
        }

        SetWithChildLocations(withChildLocations: boolean): Filter {
            this.WithChildLocations = withChildLocations;

            return this;
        }

        SetTeams(teams: Array<string>): Filter {
            this.Teams = teams;

            return this;
        }

        SetUsers(users: Array<string>): Filter {
            this.Users = users;

            return this;
        }

        SetContacts(contacts: Array<string>): Filter {
            this.Contacts = contacts;

            return this;
        }

        SetContactGroups(contactGroups: Array<string>): Filter {
            this.ContactGroups = contactGroups;

            return this;
        }

        SetStates(states: Array<string>): Filter {
            this.States = states;

            return this;
        }

        SetForms(forms: Array<string>): Filter {
            this.Forms = forms;

            return this;
        }

        SetSchedulings(schedulings: Array<string>): Filter {
            this.Schedulings = schedulings;

            return this;
        }

        SetClassifications(classifications: Array<string>): Filter {
            this.Classifications = classifications;

            return this;
        }

        SetPriorities(priorities: Array<string>): Filter {
            this.Priorities = priorities;

            return this;
        }

        SetKeywords(keywords: Array<string>): Filter {
            this.Keywords = keywords;

            return this;
        }

        SetSearchText(text: string): Filter {
            this.SearchText = text;
            this.CustomID = text;
            this.Description = text;
            this.Title = text;

            return this;
        }

        SetWithOpenIssues(withOpenIssues: boolean): Filter {
            this.WithOpenIssues = withOpenIssues;

            return this;
        }

        SetWithArchivedIssues(withArchivedIssues: boolean): Filter {
            this.WithArchivedIssues = withArchivedIssues;

            return this;
        }

        SetModificationPeriod(startingTimestamp: Date, endingTimestamp: Date): Filter {
            let periodStart: string;
            let periodEnd: string;

            if (startingTimestamp instanceof Date &&
                !isNaN(startingTimestamp.getTime())) {
                periodStart = Utils.DateTime.ToGMTString(startingTimestamp);
            }

            if (endingTimestamp instanceof Date &&
                !isNaN(endingTimestamp.getTime())) {
                periodEnd = Utils.DateTime.ToGMTString(endingTimestamp);
            }

            const period = new FilterPeriod(periodStart, periodEnd);

            if (!!period.PeriodStart || !!period.PeriodEnd) {
                this.ModificationPeriod = period;
            }

            return this;
        }

        SetDeadlinePeriod(startingTimestamp: Date, endingTimestamp: Date): Filter {
            let periodStart: string;
            let periodEnd: string;

            if (startingTimestamp instanceof Date &&
                !isNaN(startingTimestamp.getTime())) {
                periodStart = Utils.DateTime.ToGMTString(startingTimestamp);
            }

            if (endingTimestamp instanceof Date &&
                !isNaN(endingTimestamp.getTime())) {
                periodEnd = Utils.DateTime.ToGMTString(endingTimestamp);
            }

            const period = new FilterPeriod(periodStart, periodEnd);

            if (!!period.PeriodStart || !!period.PeriodEnd) {
                this.DeadlinePeriod = period;
            }

            return this;
        }

        SetLoadIssues(loadIssues: boolean): Filter {
            this.LoadIssues = loadIssues;

            return this;
        }

        SetSkip(skip: number): Filter {
            this.Skip = skip;

            return this;
        }

        SetTake(take: number): Filter {
            this.Take = take;

            return this;
        }

        SetSorting(sorting: Enums.SortingBy): Filter {
            this.Sorting = sorting;

            return this;
        }

        SetSortingOrder(sortingOrder: Enums.SortingOrder): Filter {
            this.SortingOrder = sortingOrder;

            return this;
        }

        SetProcessingStatus(processingStatus: number): Filter
        SetProcessingStatus(processingStatus: Array<number>): Filter
        SetProcessingStatus(processingStatus: Enums.IssueProcessingStatus[] | Enums.IssueProcessingStatus): Filter
        SetProcessingStatus(processingStatus: Array<number> | number | Enums.IssueProcessingStatus[] | Enums.IssueProcessingStatus): Filter {
            if (processingStatus instanceof Array) {
                this.ProcessingStatus = processingStatus;
            } else {
                this.ProcessingStatus = [processingStatus];
            }

            return this;
        }

        SetOnlyShowIssuesAssignedToUser(flag: boolean): Filter {
            this.OnlyShowIssuesAssignedToUser = flag;

            return this;
        }

        SetOnlyCurrentLocationAndNextChildren(flag: boolean): Filter {
            this.OnlyCurrentLocationAndNextChildren = flag;

            return this;
        }

        SetHideNonResponsibleIssues(flag: boolean): Filter {
            this.HideNonResponsibleIssues = flag;

            return this;
        }

        SetIncludeDerivations(flag: boolean): Filter {
            this.IncludeDerivations = flag;

            return this;
        }

        Reset(): void {
            this.Users = null;
            this.Teams = null;
            this.Contacts = null;
            this.ContactGroups = null;
            this.Keywords = null;
            this.SearchText = null;
            this.Classifications = null;
            this.Priorities = null;
            this.States = null;
            this.Forms = null;
            this.Schedulings = null;
            this.DeadlinePeriod = null;
            this.ProcessingStatus = null;
            this.UserSelectedTypes = null;
            this.HideNonResponsibleIssues = false;
        }

        IsSet(): boolean {
            return (this.Users || []).length > 0 ||
                (this.Teams || []).length > 0 ||
                (this.Contacts || []).length > 0 ||
                (this.ContactGroups || []).length > 0 ||
                (this.Keywords || []).length > 0 ||
                (this.Classifications || []).length > 0 ||
                (this.Priorities || []).length > 0 ||
                (this.States || []).length > 0 ||
                !!this.SearchText ||
                (this.Forms || []).length > 0 ||
                (this.Schedulings || []).length > 0 ||
                (this.DeadlinePeriod instanceof Model.DateTime.Period) ||
                (this.UserSelectedTypes || []).length > 0 ||
                Utils.IsSet(this.ProcessingStatus) || this.HideNonResponsibleIssues;
        }

        static IsRelevant(filter: IFilter): boolean {
            if (!filter) {
                return false;
            }

            if (!!filter.SearchText) {
                return true;
            }

            const location = filter.LocationOID ? DAL.Elements.GetByOID(filter.LocationOID) : null;

            if (filter.OnlyShowIssuesAssignedToUser || filter.HideNonResponsibleIssues ||
                !Utils.UserHasRight(Session.User.OID, Enums.Rights.ShowAllIssues, true, location)) {
                return true;
            }

            if (filter.Users || filter.Teams) {
                return true;
            }

            if (filter.Contacts) {
                return true;
            }

            if (filter.ContactGroups) {
                return true;
            }

            if (filter.Classifications) {
                return true;
            }

            if (filter.Keywords) {
                return true;
            }

            if (filter.Priorities) {
                return true;
            }

            if (filter.States) {
                return true;
            }

            if (filter.Forms) {
                return true;
            }

            if (filter.Schedulings) {
                return true;
            }

            if (filter.Types) {
                return true;
            }

            if (!filter.WithOpenIssues &&
                filter.WithOpenIssues !== filter.WithArchivedIssues) {
                return true;
            }

            if (typeof filter.ProcessingStatus === 'number' ||
                filter.ProcessingStatus instanceof Array) {
                return true;
            }

            if (filter.DeadlinePeriod) {
                return true;
            }

            return false;
        }

        static Equals(filterA: IFilter, filterB: IFilter): boolean {
            if (!filterA && !filterB) {
                return true;
            }

            if ((filterA && !filterB) ||
                (!filterA && filterB)) {
                return false;
            }

            if (filterA.SearchText != filterB.SearchText) {
                return false;
            }

            if (filterA.LocationOID != filterB.LocationOID) {
                return false;
            }

            if (!Utils.EqualsArray(filterA.Users, filterB.Users) ||
                !Utils.EqualsArray(filterA.Teams, filterB.Teams)) {
                return false;
            }

            if (!Utils.EqualsArray(filterA.Contacts, filterB.Contacts)) {
                return false;
            }

            if (!Utils.EqualsArray(filterA.ContactGroups, filterB.ContactGroups)) {
                return false;
            }

            if (!Utils.EqualsArray(filterA.Classifications, filterB.Classifications)) {
                return false;
            }

            if (!Utils.EqualsArray(filterA.Keywords, filterB.Keywords)) {
                return false;
            }

            if (!Utils.EqualsArray(filterA.Priorities, filterB.Priorities)) {
                return false;
            }

            if (!Utils.EqualsArray(filterA.States, filterB.States)) {
                return false;
            }

            if (!Utils.EqualsArray(filterA.Forms, filterB.Forms)) {
                return false;
            }

            if (!Utils.EqualsArray(filterA.Schedulings, filterB.Schedulings)) {
                return false;
            }

            if (!Utils.EqualsArray(filterA.Types, filterB.Types)) {
                return false;
            }

            if ((filterA.WithOpenIssues != filterB.WithOpenIssues) ||
                (filterA.WithArchivedIssues !== filterB.WithArchivedIssues)) {
                return false;
            }


            if (filterA.ProcessingStatus != filterB.ProcessingStatus) {
                return false;
            }

            if (filterA.DeadlinePeriod != filterB.DeadlinePeriod) {
                return false;
            }

            if (filterA.HideNonResponsibleIssues != filterB.HideNonResponsibleIssues) {
                return false;
            }

            return true;
        }
    }
}