//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../app/app.session.ts"  />
//// <reference path="../model/teams/raw-team.ts"  />
/// <reference path="../model/teams/team.ts"  />
//imports-end

module DAL.Teams {
    let _teams: Dictionary<Model.Teams.Team>;

    export function Store(syncedTeams: Array<Model.Teams.RawTeam>): void {
        if (!(syncedTeams || []).length) {
            return;
        }

        if (!_teams) {
            _teams = {};
        }

        for (let tCnt = 0, tLen = syncedTeams.length; tCnt < tLen; tCnt++) {
            const team = new Model.Teams.Team(syncedTeams[tCnt]);

            if (!team.Deleted) {
                _teams[team.OID] = team;
            } else {
                delete _teams[team.OID];
            }
        }

        createRelations();
    }

    function createRelations(): void {
        let team: Model.Teams.Team;
        let parent: Model.Teams.Team;

        for (let oid in _teams) {
            team = _teams[oid];

            if (!team.ParentOID ||
                !(parent = _teams[team.ParentOID])) {
                continue;
            }

            team.Parent = parent;

            parent.Children = parent.Children || [];
            parent.Children.push(team);
            parent.Children.sort(Utils.SortByPosition);
        }
    }

    export function GetAll(): Array<Model.Teams.Team> {
        return $.map(_teams, (team: Model.Teams.Team) => team);
    }

    export function Exists(identifier: string): boolean {
        return !!identifier && _teams.hasOwnProperty(identifier);
    }

    export function GetByOID(identifier: string): Model.Teams.Team {
        if (!identifier || !_teams) {
            return;
        }

        return _teams[identifier];
    }

    export function GetByUserOID(userIdentifier: string): Array<Model.Teams.Team> {
        let userTeams: Array<Model.Teams.Team> = [];

        for (let teamOID in _teams) {
            let team = _teams[teamOID];

            if (!(team.Users || []).length) {
                continue;
            }

            for (let uCnt = 0, uLen = team.Users.length; uCnt < uLen; uCnt++) {
                if (team.Users[uCnt].OID === userIdentifier) {
                    userTeams.push(team);
                    break;
                }
            }
        }

        return userTeams;
    }

    export function Clear(): void {
        _teams = null;
    }
}