///<reference path="i-workflow.ts"/>
///<reference path="workflow-base.ts"/>
///<reference path="workflow-settings.ts"/>
/// <reference path="../../../model/model.recorditem.ts"/>
/// <reference path="../../../model/elements/element.ts"/>
/// <reference path="../../../enums.ts"/>

module New.Checkpoints.Workflow {
    export abstract class IssueWorkflowBase extends WorkflowBase {
        public CreateTemporaryIssue: boolean;
        public ParentIssue: Model.Issues.Issue;
        public InheritResponsibilities: boolean;

        protected constructor(workflow: IWorkflow, workflowSettings: IssueWorkflowSettings) {
            super(workflow, workflowSettings);

            this.CreateTemporaryIssue = workflowSettings.CreateTemporaryIssue || false;
            this.ParentIssue = workflowSettings.ParentIssue;
            this.InheritResponsibilities = workflowSettings.InheritResponsibilities || false;
        }

        protected canUserModifyTheParentIssue(): boolean {
            if (!this.ParentIssue) {
                return false;
            }

            if (Session.LastKnownAPIVersion >= 7) {
                return Utils.UserHasIssueRight(
                    this.ParentIssue,
                    Enums.Rights.Issues_CreateOrModifyIssues
                );
            }

            return true;
        }

        protected canUserCreateANewIssue(type: Enums.IssueType, initialStateOID?: string): boolean {
            if (Session.LastKnownAPIVersion >= 7) {
                let userIsAllowedToCreateAIssue = false;

                // Prüfe Einstellungen am Workflow
                if (!!this.LocationOID &&
                    Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue, true,
                        this.LocationOID)) {
                    userIsAllowedToCreateAIssue = true;
                }

                // Prüfe Standard-OE für neue Einzelaufgaben
                if (!userIsAllowedToCreateAIssue &&
                    Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyActionIssue, true,
                        this.LocationIdentifier)) {
                    userIsAllowedToCreateAIssue = true;
                }

                if (!userIsAllowedToCreateAIssue) {
                    return false;
                }
            }

            if (!Utils.CanUserCreateIssueType(type, this.LocationOID || this.LocationIdentifier)) {
                if (!this.LocationOID) {
                    return false;
                }

                if (!Utils.CanUserCreateIssueType(type, this.LocationIdentifier)) {
                    return false;
                }
            }

            if (!Utils.IsUserAbleToSetInitialState(this.LocationOID || this.LocationIdentifier,
                initialStateOID || Session.Client.Settings.TicketOpened)) {
                if (!this.LocationOID) {
                    return false;
                }

                if (!Utils.IsUserAbleToSetInitialState(this.LocationIdentifier,
                    initialStateOID || Session.Client.Settings.TicketOpened)) {
                    return false;
                }
            }

            return true;
        }
    }
}
