/// <reference path="../../../model/model.recorditem.ts"/>
/// <reference path="../../../model/elements/element.ts"/>

module New.Checkpoints.Workflow {
    export interface IWorkflowSettings {
        Checkpoint: Model.Elements.Element,
        Recorditem: Model.Recorditem
        LocationIdentifier: string;
    }

    export class WorkflowSettingsBase implements IWorkflowSettings {
        Checkpoint: Model.Elements.Element;
        Recorditem: Model.Recorditem;
        LocationIdentifier: string;
    }

    export class IssueWorkflowSettings extends WorkflowSettingsBase {
        CreateTemporaryIssue: boolean;
        ParentIssue: Model.Issues.Issue;
        InheritResponsibilities: boolean;
    }
}