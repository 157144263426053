//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../app/app.session.ts"/>
/// <reference path="../../enums.ts" />
//imports-end

module New.Login {
    let _$modal;
    let _$win;

    export function ShowSettingsDialog() {
        if (Session.Settings.UseGoogleAnalytics == null) {
            Session.Settings.UseGoogleAnalytics = true;
        }

        if (Session.Settings.UseAdvancedAnalyticsInformation == null) {
            Session.Settings.UseAdvancedAnalyticsInformation = true;
        }

        initWindow();
        initControls();
        bindEvents();
    }

    function initWindow(): void {
        const renderedContent = Templates.LoginAdvancedSettings();

        Utils.Message.Show(
            i18next.t('Login.AdvancedSettings.WindowTitle'),
            renderedContent,
            {
                Close: true
            }, null, 0
        );

        _$win = $('#login-advanced-settings');
        _$modal = _$win.parents('.modal-material');
    }

    function initControls(): void {
        Settings.BindBooleanSwitch(
            '#cbLoginSettingsUseAnalytics',
            Enums.UserSettings.UseGoogleAnalytics
        );

        Settings.BindBooleanSwitch(
            '#cbLoginSettingsAdvancedAnalytics',
            Enums.UserSettings.UseAdvancedAnalyticsInformation
        );

        if (!Session.Settings.UseGoogleAnalytics) {
            $('#cbLoginSettingsAdvancedAnalytics').bootstrapSwitch('disabled', true);
        }
    }

    function unbindEvents(): void {
        _$win.find('.collapsable').off('click.toggleCollapsed');
    }

    function bindEvents(): void {
        unbindEvents();

        _$win.find('.collapsable').on('click.toggleCollapsed', '.headline', onCollapsableClick);
    }

    function onCollapsableClick(evt: Event): void {
        const $this = $(evt.currentTarget);

        $this.parents('.collapsable').toggleClass('collapsed');

        Utils.RepositionNewModalWindow(_$modal);
    }
}
