//imports-start
/// <reference path="./decoder.ts"  />
//imports-end

module Utils.Nfc {
    export class NavTagDecoder extends Decoder {
        constructor(tag: Model.Nfc.Tag) {
            super(tag);
        }

        public GetLocationIdentifier(): string {
            const message = this.GetDecodedMessage();

            if (!message.length) {
                return null;
            }

            const guidRegex = /[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/ig;
            const identifierMatches = guidRegex.exec(message[0]);

            return identifierMatches.length === 1 ? identifierMatches[0] : null;
        }
    }
}