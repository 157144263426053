//imports-start
/// <reference path="../../utils/utils.set.ts"  />
/// <reference path="./scheduling-meta-data-element.ts"  />
/// <reference path="./scheduling-meta-data-form-element.ts"  />
//imports-end

module Model.Scheduling {
    export class SchedulingMetadata {
        Identifier: string; // Scheduling OID
        ParentOID?: string; // Parent zur Scheduling OID
        Elements: SchedulingMetadataElement[];  // verknüpfte Prüfgruppen
        Forms: SchedulingMetadataFormElement[]; // verknüpfte Formulare

        constructor(identifier: string, elements: SchedulingMetadataElement[] = null, forms: Array<SchedulingMetadataFormElement> = null, parentOID?: string) {
            this.Identifier = identifier;

            if (!!parentOID) {
                this.ParentOID = parentOID;
            }

            this.Elements = elements;
            this.Forms = forms;
        }
    }
}
