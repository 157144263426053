//imports-start
/// <reference path="../utils.ts"  />
/// <reference path="../../model/filter-window/button-item.ts"  />
/// <reference path="../../model/filter-window/group.ts"  />
/// <reference path="../../model/filter-window/i-filter-window.ts"  />
/// <reference path="../../model/filter/i-resettable-filter.ts"  />
//imports-end

module Utils {
    export abstract class BaseFilterWindow<T extends Model.Filter.IResettableFilter> implements Model.FilterWindow.IFilterWindow {
        protected readonly zIndex: number;
        protected readonly singleFilterMode: boolean;
        protected filters: T;
        private groups: Array<Model.FilterWindow.Group>;
        private onSaveCallback: Function;

        private $win: any;
        private $btnReset: any;
        private $btnAbort: any;
        private $btnSave: any;
        private $overlay: any;

        protected constructor(filters: T, onSave?: Function, zIndex: number = 1050) {
            this.filters = filters;
            this.groups = this.getAvailableFilterItems();
            this.onSaveCallback = onSave;
            this.zIndex = zIndex;

            this.groups.sort((a, b) => a.Position - b.Position);

            this.singleFilterMode = this.groups.length === 1 && this.groups[0].Items.length === 1;
        }

        protected abstract getAvailableFilterItems(): Array<Model.FilterWindow.Group>;

        protected onAfterFilterChanged() {
            if (this.singleFilterMode) {
                this.onBtnSaveClick();
            }
        }

        public Show(): void {
            if (this.singleFilterMode) {
                const item = this.groups[0].Items[0];
                item.OnClick.call(this, new CustomEvent('click')); //Pass fake event to open the sub window directly
                return;
            }

            this.$win = $(Templates.FilterWindow.Window({
                Groups: this.groups,
                ZIndex: this.zIndex
            }));

            this.$btnReset = this.$win.find('.btn-reset');
            this.$btnAbort = this.$win.find('.btn-abort');
            this.$btnSave = this.$win.find('.btn-save');
            this.$overlay = Utils.Overlay.Generate('filter-window-overlay', this.zIndex - 1);

            $('body').append(this.$win);

            this.$win.modal({
                show: true,
                keyboard: false,
                backdrop: false
            });

            Utils.RepositionModalWindow(this.$win);

            this.bindEvents();
        }

        private bindEvents(): void {
            this.$btnAbort.on('click', this.destroy);

            if (this.onSaveCallback) {
                this.$btnReset.on('click', () => {
                    this.filters.Reset();
                    this.onBtnSaveClick();
                });
                this.$btnSave.on('click', this.onBtnSaveClick);
            }

            this.groups.forEach(group => {
                group.Items.forEach(item => {
                    const $item = this.$win.find(`.btn[data-key="${item.Key}"]`);

                    if ($item.length && item.OnClick) {
                        $item.on('click', $.proxy(item.OnClick, this));
                    }
                });
            });
        }

        private destroy = (): void => {
            this.filters = null;
            this.groups = null;
            this.onSaveCallback = null;

            if (this.$win) {
                this.$win.remove();
                this.$win = null;
            }

            if (this.$overlay) {
                Utils.Overlay.DestroyWithTimeout(this.$overlay);
                this.$overlay = null;
            }

            $('body').removeClass('modal-open');
        };

        private onBtnSaveClick = (): void => {
            if (!this.onSaveCallback) {
                return;
            }

            this.onSaveCallback(this.filters);
            this.destroy();
        };
    }
}
