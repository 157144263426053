//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../app/app.session.ts"  />
/// <reference path="../core/revision.ts"  />
/// <reference path="./raw-scheduling.ts"  />
/// <reference path="./scheduling-meta-data-element.ts"  />
/// <reference path="./scheduling-meta-data-form-element.ts"  />
/// <reference path="../../enums.ts"  />
/// <reference path="../model.resubmissionitem-collection.ts"  />
/// <reference path="../../utils/utils.set.ts"  />
//imports-end

module Model.Scheduling {
    export class Scheduling extends Model.Core.Revision {
        ParentOID?: string;
        Title: string;
        Description: string;
        Position: number;
        StateOID: string;
        PriorityOID: string;
        Color: string;
        Classifications: Array<string>;
        Users: Array<string>;
        Teams: Array<string>;
        Contacts: Array<string>;
        ContactGroups: Array<string>;
        DeadlineOffsetManuallyExecuted?: number;
        Elements: SchedulingMetadataElement[];
        Forms: Array<SchedulingMetadataFormElement>;
        UseFormTitleAndDescription: boolean;
        Parent?: Scheduling;
        Children?: Array<Scheduling>;

        constructor(scheduling: RawScheduling = null) {
            super();

            if (!scheduling) {
                return;
            }

            this.initProperties(scheduling);
        }

        private initProperties(scheduling: RawScheduling): void {
            this.OID = scheduling.OID;
            this.Deleted = scheduling.Deleted;
            this.ParentOID = scheduling.ParentOID;
            this.Title = scheduling.Title;
            this.Description = scheduling.Description;
            this.Position = scheduling.Position;
            this.StateOID = scheduling.StateOID;
            this.PriorityOID = scheduling.PriorityOID;
            this.Color = scheduling.Color;
            this.Classifications = scheduling.Classifications;
            this.Users = scheduling.Users;
            this.Teams = scheduling.Teams;
            this.Contacts = scheduling.Contacts;
            this.ContactGroups = scheduling.ContactGroups;
            this.DeadlineOffsetManuallyExecuted = scheduling.DeadlineOffsetManuallyExecuted;
            this.UseFormTitleAndDescription = scheduling.UseFormTitleAndDescription || false;
            this.Forms = [];
        }

        public SetMetadata(metadata: SchedulingMetadata) {
            this.Elements = metadata ? metadata.Elements : null;
            this.Forms = metadata ? metadata.Forms : null;
        }

        public IsCurrentUserAssigned(): boolean {
            let isCurrentUserAssigned = false;

            if ((this.Users || []).length) {
                isCurrentUserAssigned = Utils.InArray(this.Users, Session.User.OID);
            }

            if (isCurrentUserAssigned ||
                !(this.Teams || []).length ||
                !(Session.User.Teams || []).length) {
                return isCurrentUserAssigned;
            }

            for (let tCnt = 0, tLen = Session.User.Teams.length; tCnt < tLen; tCnt++) {
                if (!Utils.InArray(this.Teams, Session.User.Teams[tCnt])) {
                    continue;
                }

                isCurrentUserAssigned = true;
                break;
            }

            return isCurrentUserAssigned;
        }
    }
}