//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Model {
    export interface ILogger {
        LogMessage(categoryName: string, text: string): void
        LogData(categoryName: string, text: string, data?: any): void
        IsEnabled(): boolean;
    }

    export class NullLogger implements ILogger {
        LogMessage(categoryName: string, text: string): void {
            // do nothing
        }
        LogData(categoryName: string, text: string, data?: any): void {
            // do nothing
        }
        IsEnabled(): boolean {
            return false;
        }
    }

    export class Logger implements ILogger {
        private OID: string;
        private Timestamp: Date;
        private Categories: Object;

        constructor() {
            this.OID = uuid();
            this.Timestamp = new Date();
            this.Categories = {};
        }

        LogMessage(categoryName: string, text: string): void {
            if (!this.Categories.hasOwnProperty(categoryName)) {
                this.createCategory(categoryName);
            }

            if (!text) {
                return;
            }

            const logEntry = this.createLogEntry(text);

            if (!logEntry) {
                return;
            }

            this.Categories[categoryName].push(logEntry);
        }

        LogData(categoryName: string, text: string, data: any): void {
            if (!this.Categories.hasOwnProperty(categoryName)) {
                this.createCategory(categoryName);
            }

            if (!text || !data) {
                return;
            }

            const logEntry = this.createLogEntry(text, data);

            if (!logEntry) {
                return;
            }

            this.Categories[categoryName].push(logEntry);
        }

        IsEnabled(): boolean {
            return true;
        }

        private createCategory(name: string): void {
            if (!name) {
                return;
            }

            this.Categories[name] = [];
        }

        private createLogEntry(text: string, data?: any): Object {
            if (!text) {
                return;
            }

            const obj = {
                Timestamp: Utils.DateTime.ToGMTString(new Date()),
                Text: text,
                Data: data ? Utils.CloneObject(data) : null,
                User: Session.User ? Session.User.OID : null
            };

            return obj;
        }
    }
}