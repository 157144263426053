//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Utils.ChatWindow {
    let _entityInformation,
        _rawMessages: Model.IComment[],
        _messages: Array<any>,
        _isReadonly: boolean;
    let _onMessageSaved: Function,
        _onMessageDeleted: Function;
    let _$win, _$header, _$footer, _$overlay,
        _$contentWrapper, _$chatHistory,
        _$inputWrapper, _$input,
        _$sendButton, _$btnClose;
    let _parentEntityIsTemporary: boolean,
        _blockSaving: boolean;
    let _closeDeferred: Deferred;
    let _clickedMessageIdentifier: string;

    function destroy() {
        if (_$win) {
            _$win.remove();
            _$win = null;
            _$header = null;
            _$footer = null;
            _$contentWrapper = null;
            _$chatHistory = null;
            _$inputWrapper = null;
            _$input = null;
            _$sendButton = null;
            _$btnClose = null;
        }

        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }

        if (_closeDeferred) {
            _closeDeferred.resolve(_messages);
        }

        _entityInformation = null;
        _rawMessages = null;
        _messages = null;
        _isReadonly = null;
        _onMessageSaved = null;
        _onMessageDeleted = null;
        _parentEntityIsTemporary = null;

        if (!Utils.IssueViewer.IsVisible() &&
            !Utils.RecorditemEditor.IsVisible()) {
            $('body').removeClass('modal-open');
        }
    }

    function onKeyPress(evt) {
        if (!Session.IsSmartDeviceApplication &&
            evt.keyCode === Enums.KeyCode.RETURN &&
            !evt.shiftKey) {
            evt.preventDefault();

            $(this).parents('.input-wrapper')
                .find('.btn-send')
                .click();

            return;
        }
    }

    function onInput() {
        const $this = $(this);
        const $wrapper = $this.parents('.input-wrapper');
        const $sendButton = $wrapper.find('.btn-send');
        const text = getPreparedMessageText($this);

        if (text && text.trim()) {
            $sendButton.removeClass('hidden');
        } else {
            $sendButton.addClass('hidden');
        }

        setChatHistoryHeight();
        scrollToEnd();
    }

    function onAfterMessageSaved(message: Model.Comment) {
        message.IsEditable = true;

        const $precedingMessage = _$chatHistory.find(`.message-wrapper[data-identifier="${message.OID}"]`);

        if ($precedingMessage.length) {
            const oldRawMessageIndex = Utils.GetIndex(_rawMessages, message.OID, 'OID');

            if (oldRawMessageIndex !== -1) {
                _rawMessages[oldRawMessageIndex] = $.extend(true, {}, message);
            }
        } else {
            _rawMessages.push($.extend(true, {}, message));
        }

        _messages = prepareMessages(_rawMessages);

        _$chatHistory.find('.info-message').remove();

        if ($precedingMessage.length) {
            const messageIdx = Utils.GetIndex(_messages, message.OID, 'OID');

            if (messageIdx !== -1) {
                $precedingMessage.replaceWith(Templates.ChatWindow.Message(_messages[messageIdx]));
            }
        } else {
            _$chatHistory.append(Templates.ChatWindow.Message(_messages[_messages.length - 1]));
        }

        _$input.val('');
        _$input.text('');
        _$sendButton.addClass('hidden');

        setChatHistoryHeight();
        scrollToEnd();

        if (!message.IsTemporary && _onMessageSaved instanceof Function) {
            _onMessageSaved(message);
        }

        _blockSaving = false;
    }

    function onAfterMessageDeleted(message) {
        message.IsEditable = true;

        for (let ri = 0; ri < _rawMessages.length; ++ri) {
            if (message.OID !== _rawMessages[ri].OID) {
                continue;
            }

            _rawMessages.splice(ri, 1);
        }

        _messages = prepareMessages(_rawMessages);

        if (_$chatHistory.find(`.message-wrapper[data-identifier="${message.OID}"]`).length) {
            const oldMessageIndex = Utils.GetIndex(_messages, message.OID, 'OID');
            if (oldMessageIndex !== -1) {
                _messages.splice(oldMessageIndex, 1);
            }

            _$chatHistory.find(`.message-wrapper[data-identifier="${message.OID}"]`)
                .remove();
        }

        if (!message.IsTemporary && _onMessageDeleted instanceof Function) {
            _onMessageDeleted(message);
        }

        if (_messages.length === 0) {
            _$chatHistory.append(Templates.ChatWindow.InfoMessage());
        }

        setChatHistoryHeight();
        scrollToEnd();
    }

    function onMessageDeleteFailed() {
        Utils.Message.Show(i18next.t('ChatWindow.DeletingMessageFailed.MessageHeader'),
            i18next.t('ChatWindow.DeletingMessageFailed.MessageBody'),
            {
                Close: true
            }, null, 1203);
    }

    function onMessageClick() {
        const $message = $(this);

        if ($message.hasClass('editor-open')) {
            return;
        }

        const $wrapper = $message.parents('.message-wrapper');
        const messageIdentifier = $wrapper.data('identifier');
        const message = Utils.Where(_messages, 'OID', '===', messageIdentifier);

        if (!!_clickedMessageIdentifier && messageIdentifier !== _clickedMessageIdentifier) {
            reRenderMessage(_clickedMessageIdentifier);
        }

        _clickedMessageIdentifier = messageIdentifier;

        if (!message) {
            return;
        }

        const location = getCurrentLocation();
        const userCanModifyForeignComments = getUserCanModifyForeignComment(location);
        const $editableMessage = $(Templates.ChatWindow.EditableMessage({
            Message: message,
            IsOwnComment: message.CreatorOID === Session.User.OID,
            AllowModification: message.CreatorOID === Session.User.OID || userCanModifyForeignComments,
            AllowDeletion: message.CreatorOID === Session.User.OID || userCanModifyForeignComments
        }));

        const isConsecutiveMessage = $wrapper.hasClass('consecutive-message');

        $editableMessage.toggleClass('consecutive-message', isConsecutiveMessage);

        hideNewMessageWrapper();

        $wrapper.find('.message').replaceWith($editableMessage.find('.message'))
            .find('.message-input')
            .focus();

        const $textarea = $wrapper.find('textarea');

        $textarea
            .css({
                'height': $textarea.get(0).scrollHeight,
                'overflow-y': 'hidden'
            })
            .on('input', onTextareaInput);

        $wrapper.find('.btn-cancel')
            .on('click', onBtnCancelClick);

        if (message.CreatorOID === Session.User.OID || userCanModifyForeignComments) {
            $wrapper.find('.message-input')
                .on('input', onInput)
                .on('keyup keydown', onKeyPress);

            $wrapper.find('.btn-send')
                .on('click', saveMessage);

            $wrapper.find('.btn-delete')
                .on('click', deleteMessage);
        }
    }

    function onTextareaInput(): void {
        this.style.height = 'auto';
        this.style.height = (this.scrollHeight) + 'px';
    }

    function onBtnCancelClick() {
        const $btn = $(this);
        const $wrapper = $btn.parents('.message-wrapper');
        const messageIdentifier = $wrapper.data('identifier');

        if (!messageIdentifier) {
            return;
        }

        reRenderMessage(messageIdentifier);

        _clickedMessageIdentifier = null;
    }

    function reRenderMessage(identifier) {
        const $oldMessage = _$chatHistory.find(`.message-wrapper[data-identifier="${identifier}"]`);
        if (!$oldMessage.length) {
            return;
        }

        const message = Utils.Where(_messages, 'OID', '===', identifier);
        if (!message) {
            return;
        }

        const $newMessage = $(Templates.ChatWindow.Message(message));

        $oldMessage
            .find('.message')
            .replaceWith($newMessage.find('.message'));

        showNewMessageWrapper();
    }

    function saveMessage() {
        if (_blockSaving) {
            return;
        }

        const $wrapper = $(this).parents('.input-wrapper');
        const text = getPreparedMessageText($wrapper.find('.message-input'));

        if (!text) {
            return;
        }

        const modificationTimestamp = new Date();
        const isNewMessage = $wrapper.hasClass('new-message');
        const identifier = isNewMessage ?
            uuid() :
            $wrapper.parents('.message-wrapper').data('identifier');

        const previousMessage = !isNewMessage ? _messages.filter(message => message.OID === identifier)[0] : null;

        const message = new Model.Comment(
            identifier,
            previousMessage ? previousMessage.CreationTimestamp : modificationTimestamp,
            modificationTimestamp,
            previousMessage ? previousMessage.CreatorOID : Session.User.OID,
            Session.User.OID,
            text,
            getAssignmentOID(),
            getCommentType()
        );

        // set issue as helper information
        if (Session.IsSmartDeviceApplication) {
            const issueID = getIssueID();
            if (issueID) {
                message.IssueID = issueID;
            }
            message.IssueOID = getIssueOID();
        }

        message.AssignmentID = getAssignmentID();
        _blockSaving = true;

        if (_parentEntityIsTemporary) {
            message.IsTemporary = true;

            if (_onMessageSaved instanceof Function) {
                _onMessageSaved(message);

                _blockSaving = false;

                onAfterMessageSaved(message);

                return;
            }
        }

        message
            .Save()
            .then(onAfterMessageSaved)
            .then(null, function(_response, _state, _error) {
                _blockSaving = false;

                throw new Model.Errors.HttpError(_error, _response);
            })
            .always(showNewMessageWrapper);
    }

    function hideNewMessageWrapper(): void {
        _$win.find('.new-message')
            .addClass('hidden');

        setChatHistoryHeight();
    }

    function showNewMessageWrapper(): void {
        _$win.find('.new-message')
            .removeClass('hidden');

        setChatHistoryHeight();
    }

    function deleteMessage() {
        const $wrapper = $(this).parents('.input-wrapper');
        const identifier = $wrapper.parents('.message-wrapper').data('identifier');

        if (!identifier) {
            return;
        }

        const now = new Date();
        let message = Utils.Where(_messages, 'OID', '===', identifier);

        message = new Model.Comment(
            identifier,
            now,
            now,
            message.CreatorOID,
            Session.User.OID,
            message.Text,
            message.AssignmentOID,
            getCommentType()
        );

        if (!message) {
            return;
        }

        // set issue as helper information
        if (Session.IsSmartDeviceApplication) {
            const issueID = getIssueID();
            if (issueID)
                message.IssueID = issueID;
            message.IssueOID = getIssueOID();
        }

        message.AssignmentID = getAssignmentID();

        if (_parentEntityIsTemporary) {
            if (_onMessageDeleted instanceof Function) {
                _onMessageDeleted(message);

                onAfterMessageDeleted(message);
                return;
            }
        }

        message
            .Delete()
            .then(onAfterMessageDeleted, onMessageDeleteFailed)
            .always(showNewMessageWrapper);
    }

    function getAssignmentOID(): string {
        switch (_entityInformation.Type) {
            case Enums.CommentEntity.Issue:
                return _entityInformation.Issue.OID;
            case Enums.CommentEntity.Recorditem:
                return _entityInformation.Recorditem.OID;
        }
    }

    function getAssignmentID(): number {
        switch (_entityInformation.Type) {
            case Enums.CommentEntity.Issue:
                return _entityInformation.Issue.ID;
            case Enums.CommentEntity.Recorditem:
                return _entityInformation.Recorditem.ID;
        }
    }

    function getIssueID(): number {
        switch (_entityInformation.Type) {
            case Enums.CommentEntity.Issue:
                return _entityInformation.Issue.ID;
            case Enums.CommentEntity.Recorditem:
                return _entityInformation.Recorditem.IssueID;
        }
    }

    function getIssueOID(): string {
        switch (_entityInformation.Type) {
            case Enums.CommentEntity.Issue:
                return _entityInformation.Issue.OID;
            case Enums.CommentEntity.Recorditem:
                return _entityInformation.Recorditem.IssueOID;
        }
    }

    function getPreparedMessageText($input): string {
        if (!($input instanceof $) ||
            $input.length === 0) {
            return null;
        }

        return $.trim($input.val() || $input.text());
    }

    function getCommentType(): Enums.CommentType {
        switch (_entityInformation.Type) {
            case Enums.CommentEntity.Issue:
                return Enums.CommentType.IssueComment;
            case Enums.CommentEntity.Recorditem:
                return Enums.CommentType.RecorditemComment;
        }
    }

    function prepareMessages(messages: Model.IComment[]) {
        messages = $.extend(true, [], messages);

        (messages || []).forEach(function(message: Model.Comment) {
            if (typeof message.Timestamp === 'string') {
                message.Timestamp = new Date(<string>message.Timestamp);
            }

            if (typeof message.CreationTimestamp === 'string') {
                message.CreationTimestamp = new Date(<string>message.CreationTimestamp);
            }

            if (typeof message.ModificationTimestamp === 'string') {
                message.ModificationTimestamp = new Date(<string>message.ModificationTimestamp);
            }
        });

        messages.sort(function(a: Model.Comment, b: Model.Comment) {
            if (a.CreationTimestamp && b.CreationTimestamp) {
                return (<Date>a.CreationTimestamp).getTime() - (<Date>b.CreationTimestamp).getTime();
            }

            return (<Date>a.Timestamp).getTime() - (<Date>b.Timestamp).getTime();
        });

        let lastUserIdentifier: string;
        (messages || []).forEach(function(message: any) {
            if (message.CreatorOID === Session.User.OID) {
                message.ByCurrentUser = true;
            }

            message.User = DAL.Users.GetByOID(message.CreatorOID);
            message.Editor = DAL.Users.GetByOID(message.EditorOID);

            if (!message.User) {
                message.User = {
                    Title: i18next.t('Misc.Unknown')
                };
            }

            if (message.CreatorOID === lastUserIdentifier) {
                message.RepeatedUser = true;
            }

            if (!_isReadonly) {
                const location = getCurrentLocation();
                const userCanModifyForeignComments = getUserCanModifyForeignComment(location);

                message.IsEditable = message.CreatorOID === Session.User.OID || userCanModifyForeignComments;
            }

            lastUserIdentifier = message.CreatorOID;
        });

        return messages;
    }

    function getCurrentLocation(): Model.Elements.Element {
        return _entityInformation.Type === Enums.CommentEntity.Issue ?
            DAL.Elements.GetByOID(_entityInformation.Issue.AssignedElementOID) :
            Session.CurrentLocation;
    }

    function getUserCanModifyForeignComment(location: Model.Elements.Element): boolean {
        return Utils.UserHasRight(Session.User.OID,
            Enums.Rights.Comments_ModifyCommentsOfOtherUsers,
            true,
            location
        );
    }

    function render(): void {
        _$win = $(Templates.ChatWindow.Window({
            Messages: _messages,
            EntityInfo: getTemplateContextEntityInformationString(),
            IsReadonly: _isReadonly
        }));

        _$overlay = Utils.Overlay.Generate('olChatWindow', 1200);

        $('body')
            .addClass('modal-open')
            .append(_$win);

        _$contentWrapper = _$win.find('.content-wrapper');
        _$header = _$win.find('.window-header');
        _$chatHistory = _$contentWrapper.find('.chat-history');
        _$footer = _$win.find('.footer');

        _$inputWrapper = _$win.find('.input-wrapper');
        _$input = _$inputWrapper.find('.message-input');

        _$sendButton = _$win.find('.btn-send');
        _$btnClose = _$win.find('.btn-close');

        _$input.focus();

        setChatHistoryHeight();
    }

    function setChatHistoryHeight(): void {
        const topGap: number = _$header.outerHeight();
        let bottomGap: number = _$footer.outerHeight();

        if (_$inputWrapper instanceof $) {
            bottomGap += _$inputWrapper.outerHeight();
        }

        _$contentWrapper.css('bottom', bottomGap);
        _$contentWrapper.css('top', topGap);
    }

    function scrollToEnd(): void {
        _$contentWrapper.scrollTop(_$contentWrapper.get(0).scrollHeight);
    }

    function getTemplateContextEntityInformationString(): string {
        switch (_entityInformation.Type) {
            case Enums.CommentEntity.Issue:
                let issueTitle = _entityInformation.Issue.GetAbbreviation() + _entityInformation.Issue.ID;

                if (!!_entityInformation.Issue.Title) {
                    issueTitle += ` - ${_entityInformation.Issue.Title}`;
                }

                return issueTitle;
            case Enums.CommentEntity.Recorditem:
                const checkpoint = ParameterList.GetElementRevisionByRevisionOID(_entityInformation.Recorditem.ElementRevisionOID);

                return checkpoint ? checkpoint.Title : null;
            default:
                return null;
        }
    }

    function bindEvents(): void {
        if (!_isReadonly) {
            _$win.on('click', '.editable', onMessageClick);
            _$input.on('input', onInput).on('keyup keydown', onKeyPress);
            _$sendButton.on('click', saveMessage);
        }

        _$btnClose.on('click', destroy);
    }

    export function Show(entityInformation, messages: Model.Comment[], isReadonly: boolean, parentEntityIsTemporary: boolean, onMessageSaved: Function, onMessageDeleted: Function): Deferred | null {
        if (!entityInformation) {
            return null;
        }

        if (entityInformation.Type === Enums.CommentEntity.Issue && !entityInformation.Issue ||
            entityInformation.Type === Enums.CommentEntity.Recorditem && !entityInformation.Recorditem) {
            return null;
        }

        _closeDeferred = $.Deferred();
        _entityInformation = entityInformation;
        _rawMessages = $.extend(true, [], messages);
        _messages = prepareMessages(_rawMessages);
        _isReadonly = isReadonly;
        _parentEntityIsTemporary = parentEntityIsTemporary;

        if (onMessageSaved instanceof Function) {
            _onMessageSaved = onMessageSaved;
        }

        if (onMessageDeleted instanceof Function) {
            _onMessageDeleted = onMessageDeleted;
        }

        render();
        scrollToEnd();
        bindEvents();

        return _closeDeferred;
    }
}