//imports-start
/// <reference path="./grading.ts"  />
//imports-end

module Model.Elements {
    export class GradingScale {
        UseAsBarColor :boolean;
        UseAsBackgroundColor :boolean;
        Gradings :Array<Grading>;
    }
}