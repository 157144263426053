//imports-start
/// <reference path="../../dal/elements.ts"  />
/// <reference path="../../model/model.tree.ts"  />
/// <reference path="../../model/model.clearable-input.ts"  />
/// <reference path="../../model/elements/element.ts"  />
/// <reference path="../../model/selection-window/tab.ts"  />
/// <reference path="../../model/selection-window/options.ts"  />
/// <reference path="../../model/selection-window/form-options.ts"  />
/// <reference path="../../model/selection-window/filter.ts"  />
/// <reference path="../utils.ts"  />
/// <reference path="../utils.element-picker-popup.ts"  />
/// <reference path="../utils.message.ts"  />
/// <reference path="../utils.file-helper.ts"  />
/// <reference path="./utils.base-selection-window.ts"  />
/// <reference path="../filter-window/utils.form-filter-window.ts"  />
//imports-end

module Utils {
    export class FormSelectionWindow extends BaseSelectionWindow<Model.Elements.Element> {
        protected selectionID: string = 'form-selection';
        protected keyProperty: string = 'OID';

        private readonly disableEmptyForms: boolean;
        private readonly showRegularForms: boolean;
        private readonly showInspections: boolean;
        private readonly informationTab: Model.SelectionWindow.Tab;
        private readonly parametersTab: Model.SelectionWindow.Tab;

        // noinspection TypeScriptFieldCanBeMadeReadonly
        private showLocationAssignedFormsOnly: boolean;

        public constructor(options: Model.SelectionWindow.FormOptions) {
            super(options);

            this.disableEmptyForms = options.DisableEmptyForms;
            this.showRegularForms = options.ShowRegularForms &&
                (options.IgnoreIssueCreationRights || Utils.CanUserCreateIssueType(Enums.IssueType.Form, this.options.Location));
            this.showInspections = options.ShowInspections && Session.Client.Licenses.Inspections &&
                (options.IgnoreIssueCreationRights || Utils.CanUserCreateIssueType(Enums.IssueType.Inspection, this.options.Location));
            this.showLocationAssignedFormsOnly = options.ShowLocationAssignedFormsOnly;

            this.informationTab = new Model.SelectionWindow.Tab('information', '',
                i18next.t('SelectionWindow.Forms.Tab.Information'), this.onInformationTabClick.bind(this), false);
            this.parametersTab = new Model.SelectionWindow.Tab('parameters', '',
                i18next.t('SelectionWindow.Forms.Tab.Parameters'), this.onParametersTabClick.bind(this));

            this.tabs.push(this.informationTab, this.parametersTab);

            this.allowDisablingTabs = false;
        }

        public Show(): void {
            if (!Utils.IsSet(this.options)) {
                return;
            }

            if (!this.showRegularForms && !this.showInspections) {
                Utils.Message.Show(
                    i18next.t('Misc.RightError.MessageHeader'),
                    i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                    {
                        Close: true
                    }, null, this.options.ZIndex + 50);
                return;
            }

            super.Show();
        }

        protected prepareOptions(options: Model.SelectionWindow.Options): Model.SelectionWindow.Options {
            if (!Utils.IsSet(options.Location)) {
                options.Location = Session.CurrentLocation;
            }

            if (!Utils.IsSet(options.Title)) {
                options.Title = i18next.t('SelectionWindow.Forms.Title');
            }

            if (!Utils.IsSet(options.DefaultItemIdentifier)) {
                options.DefaultItemIdentifier = DAL.Elements.FormRoot.OID;
            }

            if (!Utils.IsSet(options.ShowLocationSelection)) {
                options.ShowLocationSelection = true;
            }

            if (!Utils.IsSet(options.ShowTreeFilter)) {
                options.ShowTreeFilter = true;
            }

            if (!Utils.IsSet(options.ZIndex)) {
                options.ZIndex = 1200;
            }

            if (!Utils.IsSet(options.TreeTabTitle)) {
                options.TreeTabTitle = i18next.t('SelectionWindow.Forms.Tab.Tree');
            }

            if (!Utils.IsSet(options.NoItemAvailableMessageHeader)) {
                options.NoItemAvailableMessageHeader = i18next.t('SelectionWindow.Forms.NoFormAvailable.MessageHeader');
            }

            if (!Utils.IsSet(options.NoItemAvailableMessageBody)) {
                options.NoItemAvailableMessageBody = i18next.t('SelectionWindow.Forms.NoFormAvailable.MessageBody');
            }

            if (!Utils.IsSet(options.TreeSearchFieldPlaceholder)) {
                options.TreeSearchFieldPlaceholder = i18next.t('SearchfieldPlaceholders.FormTree');
            }

            options.ExitIfNoItemIsAvailable = typeof options.ExitIfNoItemIsAvailable === 'boolean' ?
                options.ExitIfNoItemIsAvailable :
                true;

            options.ShowTabControl = typeof options.ShowTabControl === 'boolean' ?
                options.ShowTabControl :
                true;

            options.ShowDetails = typeof options.ShowDetails === 'boolean' ?
                options.ShowDetails :
                true;

            options.EnableMultiSelection = typeof options.EnableMultiSelection === 'boolean' ?
                options.EnableMultiSelection :
                false;

            options.ShowResetButton = typeof options.ShowResetButton === 'boolean' ?
                options.ShowResetButton :
                false;

            return options;
        }

        protected prepareItems(): void {
            this.availableItems = [];
            this.availableItemsDict = {};
            this.visibleItems = {};
            this.applicableItems = {};

            const traverse = (form: Model.Elements.Element): void => {
                let displayForm = false;

                if (this.showRegularForms &&
                    !form.IsSurvey &&
                    !form.IsInspection &&
                    !form.IsInvestigation) {
                    displayForm = true;
                }

                if (this.showInspections &&
                    form.IsInspection) {
                    displayForm = true;
                }

                if (displayForm && this.showLocationAssignedFormsOnly) {
                    displayForm = Utils.InArray((this.options.Location).Forms, form.OID);
                }

                displayForm = displayForm && this.filterForm(form);

                // if child is selectable
                (form.Children || []).forEach(traverse);

                if (displayForm) {
                    this.availableItems.push(form);
                    this.availableItemsDict[form[this.keyProperty]] = form;
                    this.visibleItems[form.OID] = true;
                    this.applicableItems[form.OID] = this.disableEmptyForms ?
                        (form.HasCheckpoints || (form.Parametergroups || []).some((group: Model.Elements.Element) => (group.Parameters || []).length > 0) || false) :
                        true;

                    let parent = form;
                    while (Utils.IsSet(parent = parent.Parent) && !this.visibleItems[parent.OID]) {
                        this.availableItemsDict[parent[this.keyProperty]] = parent;
                        this.availableItems.push(parent);
                        this.visibleItems[parent.OID] = true;
                    }
                }
            };

            traverse(DAL.Elements.FormRoot);
        }

        protected getAdditionalCssClasses(location: Model.Elements.Element): Dictionary<Array<string>> {
            const additionalClasses = {};

            if ((location.Forms || []).length) {
                const fLen = location.Forms.length;

                for (let fCnt = 0; fCnt < fLen; fCnt++) {
                    const form = DAL.Elements.GetByOID(location.Forms[fCnt]);

                    if (!Utils.IsSet(form)) {
                        continue;
                    }

                    additionalClasses[form.OID] = ['bold'];
                }
            }

            if (!Utils.IsSet(this.visibleItems) || !Utils.IsSet(this.applicableItems)) {
                return additionalClasses;
            }

            for (const oid in this.visibleItems) {
                if (!this.visibleItems.hasOwnProperty(oid)) {
                    continue;
                }

                additionalClasses[oid] = additionalClasses[oid] || [];

                if (!this.applicableItems[oid]) {
                    additionalClasses[oid].push('grayedOut');
                    continue;
                }

                const form = DAL.Elements.GetByOID(oid);

                if (!Utils.IsSet(form)) {
                    continue;
                }

                if (form.IsInspection) {
                    additionalClasses[oid].push('italic');
                }
            }

            return additionalClasses;
        }

        protected getDefaultTab(): Model.SelectionWindow.Tab {
            return this.parametersTab;
        }

        protected getShowHierarchyFilter(): boolean {
            return Utils.UserHasRight(
                Session.User.OID,
                Enums.Rights.SeeAllForms,
                true,
                Session.CurrentLocation
            );
        }

        protected onSingleApplicableItem(): boolean {
            const needOID = Object.keys(this.applicableItems)[0];
            let applicableItem: Model.Elements.Element;

            for (let i = 0; i < this.availableItems.length; i++) {
                const curItem = this.availableItems[i];
                if (curItem.OID == needOID) {
                    applicableItem = curItem;
                    break;
                }
            }

            if (!applicableItem) {
                return false;
            }

            Utils.Message.Show(i18next.t('SelectionWindow.Forms.SingleApplicableItem.MessageHeader'),
                i18next.t('SelectionWindow.Forms.SingleApplicableItem.MessageBody', { ApplicableItemTitle: applicableItem.Title }),
                {
                    Yes: () => {
                        this.selectedItem = applicableItem;
                        this.onItemApplied();
                    },
                    No: true
                }, null, this.options.ZIndex + 50);

            return true;
        }

        protected onTreeItemFilterClick(): void {
            const filterWindow = new Utils.FilterWindow.FormFilterWindow(
                this.options.Filter,
                this.onTreeItemFilterSaved.bind(this),
                this.options.ZIndex + 50
            );

            filterWindow.Show();
        }

        protected onItemApplied(): void {
            const defaultStateIdentifier = this.selectedItem.InitialStateOID || Session.Client.Settings.FormOpened;

            if (!Utils.IsUserAbleToSetInitialState(Session.CurrentLocation.OID, defaultStateIdentifier)) {
                this.destroy();

                Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                    i18next.t('Misc.RightError.DefaultState.MessageBody'),
                    {
                        Close: true
                    });

                return;
            }

            Utils.IssueViewer.CreateFormIssue(this.selectedItem.OID, this.options.OnAfterIssueCreated);
        }

        protected getTreeRootItemIdentifier(): string {
            return Utils.IsSet(DAL.Elements.FormRoot) ? DAL.Elements.FormRoot.OID : null;
        }

        private filterForm(form: Model.Elements.Element): boolean {
            if (!Utils.IsSet(this.options.Filter)) {
                return true;
            }

            if (Utils.IsSet(this.options.Filter.Keywords) && this.options.Filter.Keywords.length > 0) {
                if (!Utils.IsSet(form.Properties) || form.Properties.length <= 0) {
                    return false;
                }

                if (!Utils.HasIntersection(form.Properties, this.options.Filter.Keywords)) {
                    return false;
                }
            }

            return true;
        }

        private onInformationTabClick(): void {
            const $information = $(`<div class="information">${this.createInformationMarkup()}</div>`);

            $information.on('click.openFile', 'div[data-filename] .file', this.onFileClick.bind(this));

            this.showContent($information);
        };

        private onParametersTabClick(): void {
            let preloadDeferred: Deferred;

            // Prüfgruppen und Prüfpunkte nachladen, wenn Element noch keine besitzt
            if (this.currentlyHighlightedItem && !(this.currentlyHighlightedItem.Parametergroups || []).length) {
                // Prüfgruppen & Prüfpunkte des Raums laden, welche nicht den Type [Root] oder [Location] enthalten
                if (this.currentlyHighlightedItem.HasCheckpoints) {
                    // Änderungen während des Nachladens mit Spinner blockieren
                    Utils.Spinner.Show();

                    preloadDeferred = DAL.Sync.PreloadElementCheckpoints(this.currentlyHighlightedItem.OID)
                        .always(() => {
                            Utils.Spinner.Hide();
                        });
                }
            }

            preloadDeferred = preloadDeferred || $.Deferred().resolve();

            // Formular Vorschau generieren
            preloadDeferred.then(() => {
                const $content = $(`<div class="parameter-list">${this.createParametersMarkup()}</div>`);

                $content.find('iframe.info-text').on('load', Utils.OnIframeLoaded);

                this.showContent($content);
            });
        };

        private createInformationMarkup(): string {
            if (!Utils.IsSet(this.currentlyHighlightedItem)) {
                return Templates.SelectionWindow.NoEntrySelected();
            }

            const images = [];
            const files = [];
            const embeddedVideos = [];

            (this.currentlyHighlightedItem.Files || []).forEach((assignedFile) => {
                const file = DAL.Files.GetByOID(assignedFile.OID);

                if (!file) {
                    return;
                }

                switch (file.Type) {
                    case Enums.FileType.File:
                        if (Utils.IsImage(file.MimeType)) {
                            images.push(file);
                        } else {
                            files.push(file);
                        }

                        break;
                    case Enums.FileType.Link:
                        files.push(file);
                        break;
                    case Enums.FileType.Youtube:
                    case Enums.FileType.VimeoPrivate:
                    case Enums.FileType.VimeoPublic:
                        embeddedVideos.push(file);
                        break;
                }
            });

            return Templates.Information({
                Element: this.currentlyHighlightedItem,
                Images: images,
                Files: files,
                EmbeddedVideos: embeddedVideos,
                UserIsOnline: this.userIsOnline
            });
        }

        private onFileClick(evt: Event): void {
            if (!this.currentlyHighlightedItem) {
                return;
            }

            const $file = $(evt.currentTarget);
            const $li = $file.parent();

            if ($file.hasClass('image')) {
                const files = $.map(this.currentlyHighlightedItem.Files, (locFile: Model.Files.File) => {
                    return DAL.Files.GetByOID(locFile.OID);
                });

                Utils.OpenFiles(files,
                    $li.data('filename'),
                    null,
                    this.currentlyHighlightedItem.Title
                );
            } else {
                Utils.OpenFile(
                    $li.data('filename'),
                    false,
                    $file.hasClass('video'),
                    this.currentlyHighlightedItem.Title
                );
            }
        };

        private createParametersMarkup(): string {
            if (!Utils.IsSet(this.currentlyHighlightedItem)) {
                return Templates.SelectionWindow.NoEntrySelected();
            }

            if (this.currentlyHighlightedItemIsApplicable) {
                return Templates.SelectionWindow.Form.Parameterlist({
                    Form: this.currentlyHighlightedItem
                });
            }

            return Templates.SelectionWindow.Form.Unselectable();
        }
    }
}
