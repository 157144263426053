//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../issues/filter-period.ts"  />
//imports-end

module Model.DateTime {
    export class Period {
        PeriodStart?: Date;
        PeriodEnd?: Date;

        constructor(start?: Date, end?: Date) {
            this.PeriodStart = start;
            this.PeriodEnd = end;
        }

        toFilterPeriod(){
            return new Model.Issues.FilterPeriod(this.PeriodStart.toString(), this.PeriodEnd.toString());
        }

        static fromFilterPeriod(filterPeriod) {
            if(!filterPeriod) {
                return null;
            }
            return new Period(new Date(filterPeriod.PeriodStart), new Date(filterPeriod.PeriodEnd));
        }
    }
}