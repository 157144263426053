module New.Checkpoints.Workflow {
    export enum DeadlineOffsetUnit {
        Hours = 0,
        Days = 1
    }

    export class DeadlineOffset {
        Offset: number;
        Unit: DeadlineOffsetUnit;
    }
}