//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../templates.d.ts"  />
/// <reference path="../app.session.ts"  />
/// <reference path="../app.ts"  />
/// <reference path="../../model/speedata/Provider.ts" />
//imports-end

module Scanner.Speedata {
    const SCANNER_CALLBACK_ID: string = 'SpeedataScanner_Trial';

    let SCAN_CALLBACK_STACK: Array<{ Callback: (type: string, value: string) => void, KeepUntilRemove: boolean, ID?: string }> = [];
    let isInitialized: boolean = false;
    let provider: Model.Speedata.Provider = null;
    let $container: any;
    let scanValue: string = '';
    let scanType: string = '';

    function setLastCommandResult(result) {
        render();
    }

    function afterScan(scanData: any, time: Date) {
        const scannedData = scanData.extras['SCAN_BARCODE1'];
        const scannedType = scanData.extras['SCAN_BARCODE_TYPE'];

        const stackLength = SCAN_CALLBACK_STACK.length;
        if (stackLength > 0) {
            let stack = SCAN_CALLBACK_STACK[stackLength - 1];

            if (!stack.KeepUntilRemove) {
                SCAN_CALLBACK_STACK.splice(stackLength - 1, 1);
            }

            if (typeof stack.Callback === 'function') {
                stack.Callback(scannedType, scannedData);
            }
        }
    }

    function onPanelHeadingClick(evt: any): void {
        $(this).parent().toggleClass('collapsed');
    }

    function unbindEvents() {
        RemoveFromCallbackStack(SCANNER_CALLBACK_ID);

        if ($container == null) {
            return;
        }

        $container.find('.panel-heading').off('click');
    }

    function bindEvents() {
        if ($container == null) {
            return;
        }

        unbindEvents();

        $container.find('.panel-heading').on('click', onPanelHeadingClick);

        AddToCallbackStack((type: string, value: string) => {
            scanValue = value;
            scanType = type;
            render();
        }, false, SCANNER_CALLBACK_ID);
    }

    function render() {
        if (Session.Mode !== Enums.Mode.CodeScanner) {
            return;
        }

        $container = Utils.GetContentContainer();

        $container.html(Templates.CodeScanner.Content({
            ScanValue: scanValue,
            ScanType: scanType,
            Configuration: Templates.CodeScanner.Speedata()
        }));

        bindEvents();
    }

    export function Show() {
        if (!IsSupported()) {
            return;
        }

        if (provider == null) {
            Init();
        }

        render();
    }

    export function Hide() {
        unbindEvents();
    }

    export function Init(force: boolean = false) {
        if (!IsSupported()) {
            return;
        }

        if (isInitialized && !force) {
            return;
        }

        provider = new Model.Speedata.Provider();

        provider.on('data:commandResult', setLastCommandResult);
        provider.on('data:scan', afterScan);
    }

    export function IsSupported(locationOID?: string): boolean {
        if (App.DEBUG_ENABLED_NEWLAND) {
            return true;
        }

        if (window.device == null || window.device.manufacturer == null || !Session || !Session.IsSmartDeviceApplication) {
            return false;
        }

        // noinspection RedundantIfStatementJS
        if (!window.device.manufacturer.toLowerCase().contains('alps') &&
            !window.device.manufacturer.toLowerCase().contains('speedata')) {
            return false;
        }

        return true;

        // Disabled for Debug / Beta / exhibition until hosting service is updated
        // noinspection UnreachableCodeJS
        locationOID = locationOID || Session.CurrentLocation ? Session.CurrentLocation.OID : DAL.Elements.Root ? DAL.Elements.Root.OID : null;

        if (locationOID === null) {
            return false;
        }

        const availableItems = Utils.GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Settings, locationOID);

        return Utils.InArray(availableItems, Enums.MenuItemID.CodeScanner);
    }

    export function AddToCallbackStack(callback: (type: string, value: string) => void, keepUntilRemove: boolean = false, id?: string) {
        if (keepUntilRemove) {
            if (id == null) {
                return;
            }
        }

        SCAN_CALLBACK_STACK.push({
            Callback: callback,
            KeepUntilRemove: keepUntilRemove,
            ID: id,
        });
    }

    export function RemoveFromCallbackStack(id: string) {
        const indexOf = Utils.GetIndex(SCAN_CALLBACK_STACK, id, 'ID');

        if (indexOf !== -1) {
            SCAN_CALLBACK_STACK.splice(indexOf, 1);
        }
    }

    export function ClearCallbackStack() {
        SCAN_CALLBACK_STACK = [];
    }

    export function GetName() {
        return `Speedata ${window.device.model}`;
    }

    //DEBUG ONLY
    export function SimulateScan(type, code) {
        if (!App.DEBUG_ENABLED) {
            return;
        }

        const extras = {};
        extras['SCAN_BARCODE1'] = code;
        extras['SCAN_BARCODE_TYPE'] = type;

        afterScan({ extras: extras }, new Date());
    }
}
