//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../enums.ts"  />
//// <reference path="../app/app.session.ts"  />
//// <reference path="../model/files/file-metrics.ts"  />
//// <reference path="../model/files/file-dimensions.ts"  />
//// <reference path="../model/model.errors.ts"  />
//// <reference path="../model/issues/issue.ts"  />
//// <reference path="../model/issues/processing-information.ts"  />
/// <reference path="../model/database/model.database.ts"  />
/// <reference path="../model/elements/issue-meta-data.ts"  />
/// <reference path="../model/issues/filter.ts"  />
/// <reference path="../model/issues/internal-response.ts"  />
//// <reference path="../utils/utils.date-time.ts"  />
/// <reference path="../dal/users.ts"  />
/// <reference path="./dal.ts"  />
//imports-end

module DAL.Issues {
    let issues: Dictionary<Model.Issues.RawIssue>;
    let issuesByID: Dictionary<Dictionary<{ OID: string, ID: string, Revision: number, IsDead: boolean }>>;
    let parentChildRelations: Dictionary<number[]>;
    const MinDate: Date = new Date(1970, 0, 1, 0, 0, 0);

    export function PrepareRawIssue(issue: Model.Issues.RawIssue): Model.Issues.RawIssue {
        if (!!issue.Title) {
            issue.Title = Utils.EscapeHTMLEntities(issue.Title);
        }

        if (!!issue.Description && !(!!issue.IsTemplate || !!issue.TemplateID)) {
            issue.Description = Utils.EscapeHTMLEntities(issue.Description);
        }

        if (typeof issue.CustomID === 'string') {
            issue.CustomID = Utils.EscapeHTMLEntities(issue.CustomID);
        }

        if ((issue.Descendants || []).length) {
            issue.Descendants = issue.Descendants
                .sort((a: Model.Issues.RawIssue, b: Model.Issues.RawIssue) => sortByCustomID('asc', a, b));

            issue.Descendants.forEach(issue => PrepareRawIssue(issue));
        }

        return issue;
    }

    export function Store(syncedIssues: Array<Model.Issues.RawIssue>): void {
        if (!issues || !issuesByID || !parentChildRelations) {
            issues = {};
            issuesByID = {};
            parentChildRelations = {};
        }

        if (!(syncedIssues || []).length) {
            return;
        }

        for (let sCnt = 0, sLen = syncedIssues.length; sCnt < sLen; sCnt++) {
            const rawIssue = syncedIssues[sCnt];
            if (!rawIssue) {
                continue;
            }

            const issue = PrepareRawIssue(rawIssue);

            if (issue.Type === Enums.IssueType.Survey &&
                !Session.Client.Settings.SyncOpenSurveys) {
                continue;
            }

            if (issue.Type === Enums.IssueType.Investigation &&
                !Session.Client.Settings.SyncOpenInvestigations) {
                continue;
            }

            // TODO check for parent id (children) before removement
            if (!issue.IsArchived && !issue.IsDeleted && !issue.FollowerOID) {
                issues[issue.OID] = issue;
                // append to cache tree from elements
                DAL.TreeCache.Global.setIssue(issue);
            }

            // issues with ID = 0
            if (issue.ID) {
                issuesByID[issue.ID] = issuesByID[issue.ID] || {};
                issuesByID[issue.ID][issue.OID] = <any>{
                    OID: issue.OID,
                    ID: issue.ID,
                    Revision: issue.Revision,
                    IsDead: !!(issue.IsArchived || issue.IsDeleted || issue.FollowerOID)
                };
            }

            // remove any previous version of that issue
            if (!!issue.PrecedingOID) {
                // treat local changes without revision jumps
                if (issues.hasOwnProperty(issue.PrecedingOID)) {
                    issues[issue.PrecedingOID].FollowerOID = issue.OID;
                    delete issues[issue.PrecedingOID];
                    DAL.TreeCache.Global.removeIssue(<any>{ OID: issue.PrecedingOID });
                }
            }

            // Beziehungen zwischen den Vorgängen aufbauen
            // Vorgänge aus Erfassungen zunächst ignoriert
            if (issue.ParentID && issue.ID) {
                parentChildRelations[issue.ParentID] = parentChildRelations[issue.ParentID] || [];
                parentChildRelations[issue.ParentID].push(issue.ID);
            }

            // get all revisions to remove from TreeCache
            let revisionsByID = $.map(issuesByID[issue.ID], (issue) => issue);
            if (revisionsByID && revisionsByID.length > 1) {
                let startItem = 1;
                // sort DESC to remove older revisions only
                revisionsByID.sort((a, b) => b.Revision - a.Revision);

                if (revisionsByID[0].IsDead) {
                    // delete all existing revisions from list
                    startItem = 0;
                }

                // delete any older issues with same ID
                for (let i = startItem, len = revisionsByID.length; i < len; i++) {
                    const removeIssueOID = revisionsByID[i].OID;
                    if (issues.hasOwnProperty(removeIssueOID)) {
                        // remove from issues
                        delete issues[removeIssueOID];
                        // remove from tree cache
                        DAL.TreeCache.Global.removeIssue(<any>{ OID: removeIssueOID });
                    }
                }
            }
        }
    }

    /**
     * Gibt alle direkten Kinder eines Vorgangs zurück.
     * @param issueId
     */
    export function GetChildIssues(issueId: number): Deferred {
        const deferred = $.Deferred();

        if (!Session.IsSmartDeviceApplication) {
            return deferred.resolve().promise();
        }

        if (isNaN(issueId) || issueId < 1) {
            return deferred.resolve().promise();
        }

        let childIds = [];

        (function getChildIssueIds(id) {
            const ids = parentChildRelations.hasOwnProperty(id) ?
                parentChildRelations[id] :
                [];

            childIds = childIds.concat(ids);

            ids.forEach(id => getChildIssueIds(id));
        })(issueId);

        window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, childIds, 'IDX_ID')
            .then((issues: Model.Issues.RawIssue[]) => {
                if (!(issues || []).length) {
                    deferred.resolve();
                    return;
                }

                const response = issues
                    .filter(i => i.AssignedRecorditemID || i.AssignedRecorditemOID || !i.FollowerOID)
                    .map(i => DAL.Issues.PrepareIssue(i));

                deferred.resolve(response);
            })
            .fail(deferred.reject);

        return deferred.promise();
    }

    export function SaveToDatabase(saveIssues: Model.Issues.RawIssue[] | Model.Issues.RawIssue, reUseIssues: boolean): Deferred {
        /*
        * Save issues to database and internal storage
        */
        if (saveIssues == null) {
            return $.Deferred().resolve();
        }

        if (!(saveIssues instanceof Array)) {
            saveIssues = [saveIssues];
        }

        return window.Database
            .SetInStorageNoChecks(Enums.DatabaseStorage.Issues, saveIssues)
            .then(() => GenerateReducedIssues(<Model.Issues.RawIssue[]>saveIssues, reUseIssues))
            .then(function(reducedIssues: Model.Issues.RawIssue[]) {
                Store(reducedIssues);
                return this;
            });
    };

    export function Clear(): void {
        issues = {};
        issuesByID = {};
        parentChildRelations = {};
    }

    export function GetIssueProcessingInformation(stateOID: string, isArchived: boolean, deadlineTimestamp: Date): Model.Issues.ProcessingInformation {
        const state = DAL.Properties.GetByOID(stateOID);

        if (state && state.ClosedState || isArchived) {
            return new Model.Issues.ProcessingInformation(Enums.IssueProcessingStatus.OK);
        }

        if (!(deadlineTimestamp instanceof Date) || isNaN(deadlineTimestamp.getTime())) {
            return new Model.Issues.ProcessingInformation(Enums.IssueProcessingStatus.Warning);
        }

        const now = new Date();
        const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

        if (!deadlineTimestamp) {
            return new Model.Issues.ProcessingInformation(Enums.IssueProcessingStatus.Warning);
        } else if (deadlineTimestamp.getTime() < now.getTime()) {
            return new Model.Issues.ProcessingInformation(Enums.IssueProcessingStatus.Overdue);
        } else if (deadlineTimestamp.getTime() < tomorrow.getTime()) {
            return new Model.Issues.ProcessingInformation(Enums.IssueProcessingStatus.Warning);
        } else {
            return new Model.Issues.ProcessingInformation(Enums.IssueProcessingStatus.OK);
        }
    }

    export function GenerateReducedIssues(saveIssues: Model.Issues.RawIssue[], reUseIssues: boolean): Deferred {
        const propertiesToIgnore = [
            "LocationMarkers",
            "NotificationRecipients",
            "Recorditems",
            "Resubmissionitems",
            "RequiredParameterCount",
            "Persons"
        ];

        console.warn("Generate reduced issues");
        const smallIssues = [];
        for (let i = 0; i < saveIssues.length; i++) {
            // reduce issues
            const issue = saveIssues[i];

            let newSmallIssue: Model.Issues.RawIssue;
            if (reUseIssues) {
                newSmallIssue = issue;
                for (let i = 0; i < propertiesToIgnore.length; i++) {
                    const prop = propertiesToIgnore[i];
                    delete newSmallIssue[prop];
                }
            } else {
                newSmallIssue = Utils.CloneObject(issue, propertiesToIgnore);
            }

            const storedIssue = (issues || [])[issue.OID];
            // improve performance, if no changes available
            if (!storedIssue || JSON.stringify(newSmallIssue) != JSON.stringify(storedIssue)) {
                smallIssues.push(newSmallIssue);
            }
        }
        return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.ReducedIssues, smallIssues)
            .then(() => smallIssues);
    }

    export function PrepareIssue(rawIssue: Model.Issues.RawIssue | Model.Issues.Issue, withoutHierarchy?: boolean): Model.Issues.Issue {
        if (!rawIssue) {
            return;
        }

        if (rawIssue instanceof Model.Issues.RawIssue) {
            rawIssue = PrepareRawIssue(rawIssue);
        }

        const issue = new Model.Issues.Issue(<any>rawIssue);

        if (withoutHierarchy) {
            issue.Ancestors = null;
            issue.Descendants = null;
        }

        if (!isNaN(issue.ParameterCount)) {
            issue.CollectedParameterCount = issue.CollectedParameterCount || 0;
            issue.CollectedRequiredParameterCount = issue.CollectedRequiredParameterCount || 0;
        }

        return issue;
    }

    export function HasResponsibilities(issue: Model.Issues.RawIssue): boolean {
        if (!issue) {
            return null;
        }

        if (issue.ResponsibilityAssignments) {
            if (HasResponsible(issue.ResponsibilityAssignments.Users)) {
                return true;
            }
            if (HasResponsible(issue.ResponsibilityAssignments.Teams)) {
                return true;
            }
            if (HasResponsible(issue.ResponsibilityAssignments.Contacts)) {
                return true;
            }
            if (HasResponsible(issue.ResponsibilityAssignments.ContactGroups)) {
                return true;
            }
        }

        return false;
    }

    export function GetResponsibilities(issue: Model.Issues.IssueType): Array<Model.Users.User | Model.Teams.Team | Model.Contacts.Contact | Model.ContactGroups.ContactGroup> {
        if (!issue) {
            return null;
        }

        const responsibilities = [];

        if (issue.ResponsibilityAssignments) {
            const users: Model.Users.User[] = GetResponsible(issue.ResponsibilityAssignments.Users, DAL.Users);
            responsibilities.push.apply(responsibilities, users);

            const teams: Model.Teams.Team[] = GetResponsible(issue.ResponsibilityAssignments.Teams, DAL.Teams, (team) => {
                return $.extend(true, team, { Parent: null, Children: null });
            });
            responsibilities.push.apply(responsibilities, teams);

            const contacts: Model.Contacts.Contact[] = GetResponsible(issue.ResponsibilityAssignments.Contacts, DAL.Contacts);
            responsibilities.push.apply(responsibilities, contacts);

            const contactGroups: Model.ContactGroups.ContactGroup[] = GetResponsible(issue.ResponsibilityAssignments.ContactGroups, DAL.ContactGroups);
            responsibilities.push.apply(responsibilities, contactGroups);
        }

        return responsibilities;
    }

    export function GetResponsibilitiesForIssue(issueUsers?: string[], issueTeams?: string[], issueContacts?: string[]): Array<Model.Users.User | Model.Teams.Team | Model.Contacts.Contact> {
        const responsibilities = [];

        if (issueUsers instanceof Array &&
            (issueUsers || []).length) {
            const users = [];

            for (let pCnt = 0, pLen = issueUsers.length; pCnt < pLen; pCnt++) {
                const user = DAL.Users.GetByOID(issueUsers[pCnt]);

                if (user && !Utils.Where(users, 'OID', '===', user.OID)) {
                    users.push({ User: user });
                }
            }

            users.sort(Utils.SortByTitle);
            responsibilities.push.apply(responsibilities, users);
        }

        if (issueTeams instanceof Array &&
            (issueTeams || []).length) {
            const teams = [];

            for (let tCnt = 0, tLen = issueTeams.length; tCnt < tLen; tCnt++) {
                const team = DAL.Teams.GetByOID(issueTeams[tCnt]);

                if (team && !Utils.Where(teams, 'OID', '===', team.OID)) {
                    teams.push({ Team: $.extend(true, team, { Parent: null, Children: null }) });
                }
            }

            teams.sort(Utils.SortByTitle);
            responsibilities.push.apply(responsibilities, teams);
        }

        if (issueContacts instanceof Array &&
            (issueContacts || []).length) {
            const contacts = [];

            for (let cCnt = 0, cLen = issueContacts.length; cCnt < cLen; cCnt++) {
                const contact = DAL.Contacts.GetByOID(issueContacts[cCnt]);

                if (contact && !Utils.Where(contacts, 'OID', '===', contact.OID)) {
                    contacts.push({ Contact: contact });
                }
            }

            contacts.sort(Utils.SortByTitle);
            responsibilities.push.apply(responsibilities, contacts);
        }
        return responsibilities;
    }

    export function GetResponsibilitiesEx(assignments: Model.Issues.ResponsibilityAssignments): Model.Issues.Responsibilities {
        if (!assignments) {
            return null;
        }

        const responsibilities: Model.Issues.Responsibilities = {};

        GetResponsibleEx(assignments.Users, DAL.Users, responsibilities);
        GetResponsibleEx(assignments.Teams, DAL.Teams, responsibilities, (team) => {
            return $.extend(true, team, { Parent: null, Children: null });
        });

        // TODO check user view rights for Contacts, ContactGroups
        GetResponsibleEx(assignments.Contacts, DAL.Contacts, responsibilities);
        GetResponsibleEx(assignments.ContactGroups, DAL.ContactGroups, responsibilities);

        return responsibilities;
    }

    function HasResponsible(dict: Dictionary<Model.Issues.RACI>): boolean {
        if (dict) {
            for (let userOID in dict) {
                if (!dict.hasOwnProperty(userOID)) {
                    continue;
                }
                const user = dict[userOID];
                if (user.IsResponsible || user.IsAccountable) {
                    return true;
                }
            }
        }
        return false;
    }

    function GetResponsible<T>(dict: Dictionary<Model.Issues.RACI>, dal: { GetByOID: (oid: string) => T }, onApply?: (T) => T): T[] | null {
        if (!dict) {
            return null;
        }

        const entry: T[] = [];
        for (let oid in dict) {
            if (!dict.hasOwnProperty(oid)) {
                continue;
            }

            const info = dict[oid];
            if (info.IsResponsible || info.IsAccountable || info.IsConsulted || info.IsInformed) {
                const obj = dal.GetByOID(oid);
                entry.push(onApply ? onApply(obj) : obj);
            }
        }

        (<any>entry).sort(Utils.SortByTitle);
        return entry;
    }

    function GetResponsibleEx<T>(dict: Dictionary<Model.Issues.RACI>, dal: { GetByOID: (oid: string) => T }, responsibilities: Model.Issues.Responsibilities, onApply?: (T) => T): Model.Issues.Responsibilities {
        if (!dict) {
            return null;
        }

        responsibilities = responsibilities || {};

        for (let oid in dict) {
            const info = dict[oid];
            if (info) {
                const entry = dal.GetByOID(oid);
                if (!entry) {
                    continue;
                }

                if (info.IsResponsible) {
                    responsibilities.Responsible = responsibilities.Responsible || [];
                    responsibilities.Responsible.push(<any>entry);
                }

                if (info.IsAccountable) {
                    responsibilities.Accountable = responsibilities.Accountable || [];
                    responsibilities.Accountable.push(<any>entry);
                }

                if (info.IsInformed) {
                    responsibilities.Informed = responsibilities.Informed || [];
                    responsibilities.Informed.push(<any>entry);
                }

                if (info.IsConsulted) {
                    responsibilities.Consulted = responsibilities.Consulted || [];
                    responsibilities.Consulted.push(<any>entry);
                }
            }
        }

        return responsibilities;
    }

    export function PrepareResponsibilityAssignments<T extends Model.Issues.IssueType | Model.Issues.RawIssue>(issue: T): T {
        /*
        * Prepare ResponsibilityAssignments on Issues of API <14 to be used in newer app versions
        */
        if (issue && !issue.ResponsibilityAssignments) {
            if (issue.Users) {
                issue.ResponsibilityAssignments = issue.ResponsibilityAssignments || {};
                const userResponsibilities = {};

                for (let i = 0; i < issue.Users.length; i++) {
                    const userOID = issue.Users[i];
                    userResponsibilities[userOID] = { IsResponsible: true };
                }

                issue.ResponsibilityAssignments.Users = userResponsibilities;
            }

            if (issue.Teams) {
                issue.ResponsibilityAssignments = issue.ResponsibilityAssignments || {};
                const teamResponsibilities = {};

                for (let i = 0; i < issue.Teams.length; i++) {
                    const teamOID = issue.Teams[i];
                    teamResponsibilities[teamOID] = { IsResponsible: true };
                }

                issue.ResponsibilityAssignments.Teams = teamResponsibilities;
            }

            if (issue.Contacts) {
                issue.ResponsibilityAssignments = issue.ResponsibilityAssignments || {};
                const contactResponsibilities = {};

                for (let i = 0; i < issue.Contacts.length; i++) {
                    const contactOID = issue.Contacts[i];
                    contactResponsibilities[contactOID] = { IsInformed: true };
                }

                issue.ResponsibilityAssignments.Contacts = contactResponsibilities;
            }
        }

        return issue;
    }

    export function AddRecorditems(recorditems: Array<any>, issues: Array<any>): Deferred {
        const deferred = $.Deferred();

        if (!Session.IsSmartDeviceApplication ||
            !(recorditems || []).length) {
            deferred.resolve();

            return deferred.promise();
        }

        const modifiedIssues: Dictionary<Model.Issues.Issue> = {};
        for (let idx = 0, len = recorditems.length; idx < len; idx++) {
            const recorditem = recorditems[idx];

            if (!recorditem.IssueID && !recorditem.IssueOID ||
                !!recorditem.FollowerOID) {
                continue;
            }

            const issue = !recorditem.IssueOID ?
                Utils.Where(issues, 'ID', '===', recorditem.IssueID) :
                Utils.Where(issues, 'OID', '===', recorditem.IssueOID);

            if (!issue) {
                continue;
            }

            const issueOID = recorditem.IssueOID || issue.OID;

            if (modifiedIssues.hasOwnProperty(issueOID)) {
                addRecorditemToIssue(modifiedIssues[issueOID], recorditem);

                continue;
            }

            if (!addRecorditemToIssue(issue, recorditem)) {
                continue;
            }

            modifiedIssues[issueOID] = issue;
        }

        updateModifiedIssues(modifiedIssues)
            .then(deferred.resolve);

        return deferred.promise();
    }

    export function RemoveRecorditem(recorditem: Model.Recorditem, issue: Model.Issues.Issue): Deferred {
        if (!recorditem || !issue) {
            return $.Deferred().resolve().promise();
        }

        for (let rCnt = issue.Recorditems.length - 1; rCnt >= 0; rCnt--) {
            const oldRecordItem = issue.Recorditems[rCnt];

            if ((oldRecordItem.ElementOID === recorditem.ElementOID ||
                oldRecordItem.ElementRevisionOID === recorditem.ElementRevisionOID) &&
                (oldRecordItem.Row || 0) === (recorditem.Row || 0)) {
                issue.Recorditems.splice(rCnt, 1);
            }
        }

        if (!Session.IsSmartDeviceApplication) {
            return $.Deferred().resolve().promise();
        }

        const modifiedIssues: Dictionary<Model.Issues.Issue> = {};
        modifiedIssues[issue.OID] = issue;

        return updateModifiedIssues(modifiedIssues);
    }

    function addRecorditemToIssue(issue: Model.Issues.Issue, recorditem: any): boolean {
        issue.Recorditems = issue.Recorditems || [];

        const idx = Utils.Where(issue.Recorditems, 'OID', '===', recorditem.OID, true);
        if (idx > -1) {
            // Optimierung: Schreiben in DB kann gespart werden, wenn Recorditems identisch sind
            const oldRecordItem = issue.Recorditems[idx];
            const newRecorditemJSON = JSON.stringify(recorditem, dateReplacerRecorditem);
            const oldRecorditemJSON = JSON.stringify(oldRecordItem, dateReplacerRecorditem);
            if (newRecorditemJSON == oldRecorditemJSON) {
                return false;
            }

            issue.Recorditems.splice(idx, 1);
        }

        issue.Recorditems.push(recorditem);

        return true;
    }

    function dateReplacerRecorditem(key: string, val: any) {
        // Recorditem Datum zu GMT konvertieren
        if (key == "CreationTimestamp" || key == "ModificationTimestamp") {
            return Utils.DateTime.ToGMTString(val);
        }

        return val;
    }

    function updateModifiedIssues(modifiedIssues: Dictionary<Model.Issues.Issue>): Deferred {
        if (!Utils.HasProperties(modifiedIssues)) {
            return $.Deferred().resolve().promise();
        }

        return window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, Object.keys(modifiedIssues))
            .then(function(issues: Model.Issues.RawIssue[]) {
                const updatedIssues = [];
                const issuesToSave = [];
                for (let i = 0, len = issues.length; i < len; i++) {
                    const issue = issues[i];
                    const modifiedIssue = modifiedIssues[issue.OID];

                    if (!modifiedIssues || !issue) {
                        continue;
                    }

                    issue.Recorditems = modifiedIssue.Recorditems;

                    updatedIssues.push(modifiedIssue);
                }

                // 2. Durchgang damit evtl Änderungen mitgenommen werden
                for (let i = 0, len = issues.length; i < len; i++) {
                    const issue = issues[i];
                    if (issue) {
                        // TODO check if CloneObject required
                        issuesToSave.push(Utils.CloneObject(issue));
                    }
                }

                return SaveToDatabase(issuesToSave, true);
            });
    }

    export function GetByID(id: number, additionalParameters?: Array<any>, forceOnline?: boolean): Deferred {
        const deferred = $.Deferred();

        additionalParameters = additionalParameters || [];

        if (!Session.IsSmartDeviceApplication || forceOnline) {
            const paramString = additionalParameters.length ? '?' + additionalParameters.join('&') : '';
            const uri = `issues/${id}${paramString}`;

            Utils.Http.Get(uri)
                .then(PrepareResponsibilityAssignments)
                .then((issue: any) => deferred.resolve(PrepareIssue(new Model.Issues.RawIssue(issue))))
                .fail(deferred.reject);
        } else {
            window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, [id], 'IDX_ID')
                .then((revisions: Array<any>) => {
                    if (!(revisions || []).length) {
                        deferred.resolve();
                        return;
                    }

                    revisions.sort((a, b) => b.Revision - a.Revision);

                    const issue = new Model.Issues.RawIssue(revisions[0]);

                    deferred.resolve(PrepareIssue(issue));
                })
                .fail(deferred.reject);
        }

        return deferred.promise();
    }

    export function GetByOID(identifier: string, getLatestRevision: boolean = false): Deferred {
        if (!identifier) {
            throw new Model.Errors.ArgumentError('identifier');
        }

        const deferred = $.Deferred();

        const onAfterIssueLoaded = (issue: Model.Issues.RawIssue) => {
            if (!issue) {
                deferred.resolve();
                return;
            }

            if (getLatestRevision && issue && !!issue.FollowerOID) {
                GetByOID(issue.FollowerOID, getLatestRevision)
                    .then(onAfterIssueLoaded);

                return;
            }

            issue = PrepareRawIssue(issue);

            deferred.resolve(issue);
        };

        if (!Session.IsSmartDeviceApplication) {
            Utils.Http.Get(`issues/${identifier}`)
                .then(PrepareResponsibilityAssignments)
                .then(onAfterIssueLoaded)
                .fail(deferred.reject);
        } else {
            window.Database.GetSingleByKey(Enums.DatabaseStorage.Issues, identifier)
                .then(onAfterIssueLoaded)
                .fail(deferred.reject);
        }

        return deferred.promise();
    }

    export function GetFromCache(filters: Model.Issues.IFilter, returnOnlyProcessingStatus: boolean, issues: any): Deferred {
        const deferred = $.Deferred();
        deferred.resolve(onAfterIssuesLoadedFromDatabase(issues, filters, returnOnlyProcessingStatus));
        return deferred.promise();
    }

    export function GetByFilters(filters: Model.Issues.IFilter, returnOnlyProcessingStatus: boolean = false, issueList?: Array<Model.Issues.Issue>): Deferred {
        const deferred = $.Deferred();

        if ((issueList || []).length) {
            const issues: Array<Model.Issues.RawIssue> = [];

            issueList.forEach(issue => {
                issues.push(new Model.Issues.RawIssue(issue));
            });

            deferred.resolve(onAfterIssuesLoadedFromDatabase(issues, filters, returnOnlyProcessingStatus));
            return deferred.promise();
        }

        if (!Session.IsSmartDeviceApplication) {
            Utils.Http.Get(`issues`)
                .then((issues: Array<Model.Issues.RawIssue>) => {
                    deferred.resolve(onAfterIssuesLoadedFromDatabase(issues, filters, returnOnlyProcessingStatus));
                })
                .fail(deferred.reject);
        } else {
            const cacheNode = DAL.TreeCache.Global.getNode(filters.LocationOID || Session.CurrentLocation.OID);
            const issueArray = cacheNode.getTotalIssues();

            deferred.resolve(onAfterIssuesLoadedFromDatabase(<any>issueArray, filters, returnOnlyProcessingStatus));
        }

        return deferred.promise();
    }

    function onAfterIssuesLoadedFromDatabase(dbIssues: Array<Model.Issues.RawIssue>, filters: Model.Issues.IFilter, returnOnlyProcessingStatus: boolean): Model.Issues.InternalResponse {
        if (!(dbIssues || []).length) {
            return new Model.Issues.InternalResponse()
                .SetIssueCount(0)
                .SetProcessingStatus(Enums.IssueProcessingStatus.OK);
        }

        if (!Session.Client.Licenses.EnableInfoBoard) {
            filters.IncludeDerivations = false;
        }

        if (Session.IsSmartDeviceApplication) {
            let targetNode = DAL.TreeCache.Global.getNode(filters.LocationOID || Session.CurrentLocation.OID, filters.Types);
            dbIssues = <any>targetNode.getTotalIssues(filters);
        } else {
            dbIssues = dbIssues.filter(i => !FilterIssue(filters, i));
        }

        if (View.CurrentView === Enums.View.Inspection) {
            const locationDictionary = {};
            locationDictionary[Session.CurrentLocation.OID] = true;

            // reverse search for all children of the current room
            (function traverse(location) {
                // just add root and location elements
                if (location.Type == Enums.ElementType.Root || location.Type == Enums.ElementType.Location) {
                    locationDictionary[location.OID] = true;
                }

                // reverse search for all children elements inside the children
                if ((location.Children || []).length) {
                    if (Utils.InArray([Enums.ElementType.Root, Enums.ElementType.Location], location.Type)) {
                        for (let cCnt = 0, cLen = location.Children.length; cCnt < cLen; cCnt++) {
                            traverse(location.Children[cCnt]);
                        }
                    }
                }
            })(Session.CurrentLocation);

            const currentIssueID = (IssueView.GetCurrentIssue() || <Model.Issues.Issue>{}).ID;
            dbIssues = dbIssues.filter(i => {
                return (i.ParentID === currentIssueID || i.ParentOID === IssueView.GetCurrentIssue().OID)
                    && i.AssignedElementOID && !!locationDictionary[i.AssignedElementOID];
            });
        }

        if (filters.LoadIssues) {
            filters.Sorting = filters.Sorting || Enums.SortingBy.ID;
            filters.SortingOrder = filters.SortingOrder || Enums.SortingOrder.DESC;

            SortIssuesList(dbIssues, filters);
        }

        const processingStatus = getProcessingStatusForIssueList(dbIssues);
        const response = new Model.Issues.InternalResponse();

        if (filters.LoadIssues) {
            let chunkedIssues: Array<any>;

            if (+filters.Take > 0) {
                if (filters.Skip) {
                    chunkedIssues = Utils.ChunkArray(dbIssues, filters.Take, filters.Skip);
                } else {
                    chunkedIssues = Utils.ChunkArray(dbIssues, filters.Take)[0];
                }
            } else {
                chunkedIssues = dbIssues;
            }

            response
                .SetIssues(chunkedIssues || [])
                .SetIssueCount((chunkedIssues || []).length)
                .SetProcessingStatus(processingStatus);

            if (View.CurrentView === Enums.View.Inspection) {
                if (+filters.Take > 0) {
                    if (filters.Skip) {
                        chunkedIssues = Utils.ChunkArray(IssueView.GetCurrentIssue().Descendants, filters.Take, filters.Skip);
                    } else {
                        chunkedIssues = Utils.ChunkArray(IssueView.GetCurrentIssue().Descendants, filters.Take)[0];
                    }
                } else {
                    chunkedIssues = IssueView.GetCurrentIssue().Descendants;
                }

                IssueView.GetCurrentIssue().Descendants = chunkedIssues || [];
            }
        } else if (returnOnlyProcessingStatus) {
            response
                .SetProcessingStatus(processingStatus);
        } else {
            response
                .SetIssueCount(dbIssues.length)
                .SetProcessingStatus(processingStatus);
        }

        return response;
    }

    export function SortIssuesList(issues: Array<Model.Issues.RawIssue>, filter: Model.Issues.IFilter): void {
        issues.sort((a, b) => SortIssues(a, b, filter));
    }

    export function SortIssues(a: Model.Issues.RawIssue, b: Model.Issues.RawIssue, f: Model.Issues.IFilter): number {
        switch (f.Sorting) {
            case Enums.SortingBy.ID:
                return sortByID(f.SortingOrder, a, b);
            case Enums.SortingBy.MODIFICATIONTIMESTAMP:
                return sortByTimestamp(f.SortingOrder, a, b, 'ModificationTimestamp');
            case Enums.SortingBy.DEADLINETIMESTAMP:
                return sortByTimestamp(f.SortingOrder, a, b, 'DeadlineTimestamp');
            case Enums.SortingBy.STATE:
                return sortByProperty(f.SortingOrder, a, b, 'StateOID');
            case Enums.SortingBy.PRIORITY:
                return sortByProperty(f.SortingOrder, a, b, 'PriorityOID');
            case Enums.SortingBy.CUSTOMIDENT:
                return sortByCustomID(f.SortingOrder, a, b);
            case Enums.SortingBy.ESTIMATEDEFFORT:
                return sortByEstimatedEffort(f.SortingOrder, a, b);
        }
    }

    function getProcessingStatusForIssueList(issues: Array<Model.Issues.RawIssue>): Enums.IssueProcessingStatus {
        let processingStatus = Enums.IssueProcessingStatus.OK;

        if (!issues.length) {
            return processingStatus;
        }

        let issue: Model.Issues.RawIssue;

        for (let sCnt = 0, sLen = issues.length; sCnt < sLen; sCnt++) {
            issue = issues[sCnt];

            if (!issue.DeadlineTimestamp) {
                continue;
            }

            let processingInformation = GetIssueProcessingInformation(issue.StateOID, issue.IsArchived, new Date(issue.DeadlineTimestamp));

            if (processingInformation.ProcessingStatus > processingStatus) {
                processingStatus = processingInformation.ProcessingStatus;

                if (processingStatus === Enums.IssueProcessingStatus.Overdue) {
                    break;
                }
            }
        }

        return processingStatus;
    }

    export function GetProcessingStatusForIssue(issue: Model.Issues.RawIssue): Enums.IssueProcessingStatus {
        const deadline = issue.DeadlineTimestamp ? new Date(issue.DeadlineTimestamp) : null;

        let processingInformation = GetIssueProcessingInformation(issue.StateOID, issue.IsArchived, deadline);
        return processingInformation.ProcessingStatus;
    }

    function checkForArray(filters: Model.Issues.IFilter, issue: Model.Issues.RawIssue, property: string): boolean {
        if (!filters.hasOwnProperty(property)) {
            return false;
        }

        let filtersProp = filters[property];
        if (!(filtersProp instanceof Array) ||
            !(filtersProp as Array<any>).length) {
            return false;
        }

        let issueProp = issue[property];
        if (!(issueProp || []).length) {
            return false;
        }

        for (let pCnt = 0, pLen = filtersProp.length; pCnt < pLen; pCnt++) {
            let value = filtersProp[pCnt];

            if (Utils.InArray(issueProp, value)) {
                return true;
            }
        }

        return false;
    }

    function sortByID(sortingOrder: string, a: Model.Issues.RawIssue, b: Model.Issues.RawIssue): number {
        const sortFactor = sortingOrder === 'asc' ? -1 : 1;
        let sortValue = (b.ID - a.ID);

        if (!a.ID || !b.ID) {
            if (!a.ID && b.ID) {
                sortValue = -1;
            } else if (a.ID && !b.ID) {
                sortValue = 1;
            } else {
                sortValue = 0;
            }
        }

        if (sortValue == 0) {
            sortValue = (b.Revision - a.Revision);
        }

        return sortValue * sortFactor;
    }

    function sortByCustomID(sortingOrder: string, a: Model.Issues.RawIssue, b: Model.Issues.RawIssue): number {
        if (!!a.CustomID && typeof a.CustomID === 'string' && a.CustomID.contains('.')
            && !!b.CustomID && typeof b.CustomID === 'string' && b.CustomID.contains('.')) {
            const customIdASplitted = a.CustomID.split('.');
            const customIdBSplitted = b.CustomID.split('.');
            const counterA = parseInt(customIdASplitted[1], 10);
            const counterB = parseInt(customIdBSplitted[1], 10);

            if (counterA === counterB) {
                return sortByID(sortingOrder, a, b);
            }

            return sortingOrder === 'asc' ?
                counterA - counterB :
                counterB - counterA;
        } else {
            return Utils.SortByString(
                (a.CustomID || 0).toString(),
                (b.CustomID || 0).toString()) * (sortingOrder === 'asc' ? 1 : -1);
        }
    }

    function sortByEstimatedEffort(sortingOrder: string, a: Model.Issues.RawIssue, b: Model.Issues.RawIssue): number {
        const sortFactor = sortingOrder === 'asc' ? -1 : 1;
        let estimatedEffortA = a.EstimatedEffort || 0;
        let estimatedEffortB = b.EstimatedEffort || 0;
        let sortValue = (estimatedEffortB - estimatedEffortA);

        if (sortValue === 0 || (estimatedEffortA === estimatedEffortB)) {
            return sortByID(sortingOrder, a, b);
        }

        return sortValue * sortFactor;
    }

    function sortByTimestamp(sortingOrder: string, a: Model.Issues.RawIssue, b: Model.Issues.RawIssue, field: string): number {
        let aDate: Date;
        let bDate: Date;

        if (typeof a[field] === 'string') {
            aDate = new Date(a[field]);
        } else if (Utils.DateTime.IsValid(a[field])) {
            aDate = a[field];
        }

        if (typeof b[field] === 'string') {
            bDate = new Date(b[field]);
        } else if (Utils.DateTime.IsValid(b[field])) {
            bDate = b[field];
        }

        const aTime = (aDate || MinDate).getTime();
        const bTime = (bDate || MinDate).getTime();

        if (aTime === bTime) {
            return sortByID(sortingOrder, a, b);
        }

        return sortingOrder === 'asc' ?
            aTime - bTime :
            bTime - aTime;
    }

    function sortByProperty(sortingOrder: string, a: Model.Issues.RawIssue, b: Model.Issues.RawIssue, propertyName: string): number {
        const stateA = DAL.Properties.GetByOID(a[propertyName]);
        const stateB = DAL.Properties.GetByOID(b[propertyName]);
        const sortResult = Utils.SortByPropertyHierarchy(stateA, stateB);

        if (sortResult == 0) {
            return sortByID(sortingOrder, a, b);
        }

        return sortingOrder === 'asc' ?
            sortResult * -1 :
            sortResult;
    }

    export function IsUserAssignedToIssue(issue: Model.Issues.RawIssue, user: Model.Users.User): boolean
    export function IsUserAssignedToIssue(issue: Model.Issues.RawIssue, userOID: string, teamOIDs?: string[]): boolean
    export function IsUserAssignedToIssue(issue: Model.Issues.RawIssue, user_userOIDs: Model.Users.User | string, teamOIDs?: string[]): boolean {
        if (!issue || (!user_userOIDs && !teamOIDs)) {
            return false;
        }

        if (!issue.ResponsibilityAssignments) {
            if (issue.Users || issue.Teams || issue.Contacts) {
                PrepareResponsibilityAssignments(issue);

                if (!issue.ResponsibilityAssignments) {
                    return false;
                }
            } else {
                return false;
            }
        }

        // compare user
        if (issue.ResponsibilityAssignments.Users) {
            const userToTest = typeof user_userOIDs == 'string' ? user_userOIDs : (user_userOIDs || { OID: null }).OID;
            const userInfo = issue.ResponsibilityAssignments.Users[userToTest];
            if (userInfo && (
                userInfo.IsResponsible ||
                userInfo.IsAccountable ||
                userInfo.IsInformed ||
                userInfo.IsConsulted)) {
                return true;
            }
        }

        // compare teams
        if (issue.ResponsibilityAssignments.Teams) {
            const userTeamsOIDs = user_userOIDs && typeof user_userOIDs != 'string' ? user_userOIDs.Teams : null;
            const teamsToTest = teamOIDs || userTeamsOIDs || [];

            for (let i = 0; i < teamsToTest.length; i++) {
                const teamOID = teamsToTest[i];
                const teamInfo = issue.ResponsibilityAssignments.Teams[teamOID];
                if (teamInfo && (
                    teamInfo.IsResponsible ||
                    teamInfo.IsAccountable ||
                    teamInfo.IsInformed ||
                    teamInfo.IsConsulted)) {
                    return true;
                }
            }
        }

        return false;
    }

    /*
        Liefert true zurück, wenn das issue nicht den Filtern entspricht.
        Liefert false zurück, wenn das issue gültig ist.
     */
    export function FilterIssue(filters: Model.Issues.IFilter, issue: Model.Issues.RawIssue): boolean {
        if (!!filters.SearchText) {
            let regEx = new RegExp(Utils.EscapeRegExPattern(filters.SearchText), 'i');
            if (regEx &&
                !regEx.test(issue.Title) &&
                !regEx.test(issue.Description) &&
                !regEx.test(issue.CustomID)) {
                return true;
            }
        }

        if (!!issue.FollowerOID) {
            return true;
        }

        if (!!issue.IsTemplate) {
            return true;
        }

        if (filters.IncludeDerivations !== true && !!issue.TemplateID) {
            return true;
        }

        const location = filters.LocationOID ? DAL.Elements.GetByOID(filters.LocationOID) : DAL.Elements.GetByOID(issue.AssignedElementOID);
        let matchesResponsibilities = true;

        if (filters.OnlyShowIssuesAssignedToUser || filters.HideNonResponsibleIssues ||
            !Utils.UserHasRight(Session.User.OID, Enums.Rights.ShowAllIssues, true, location)) {
            const userIsAssignedToIssue = IsUserAssignedToIssue(issue, Session.User);
            if (!userIsAssignedToIssue) {
                if (filters.HideNonResponsibleIssues) {
                    matchesResponsibilities = false;
                } else if (Session.LastKnownAPIVersion >= 10) {
                    matchesResponsibilities =
                        Utils.UserHasRight(Session.User.OID, Enums.Rights.ShowSelfCreateOrModifiedIssues, true, location) &&
                        (issue.CreatorOID === Session.User.OID || issue.EditorOID === Session.User.OID);
                } else {
                    matchesResponsibilities = issue.CreatorOID === Session.User.OID || issue.EditorOID === Session.User.OID;
                }
            }
        }

        if (matchesResponsibilities && ((filters.Users || []).length || (filters.Teams || []).length)) {
            let matchesUsers = true;
            let matchesTeams = true;

            if ((filters.Users || []).length) {
                if (!(filters.Teams || []).length) {
                    matchesTeams = false;
                }

                let isAssigned = false;
                for (let i = 0; i < filters.Users.length; i++) {
                    const userFilter = filters.Users[i];
                    if (IsUserAssignedToIssue(issue, userFilter)) {
                        isAssigned = true;
                        break;
                    }
                }
                if (!isAssigned) {
                    matchesUsers = false;
                }
            }

            if ((filters.Teams || []).length) {
                if (!(filters.Users || []).length) {
                    matchesUsers = false;
                }

                if (!IsUserAssignedToIssue(issue, null, filters.Teams)) {
                    matchesTeams = false;
                }
            }

            matchesResponsibilities = (matchesUsers || matchesTeams);
        }

        // TODO apply ResponsibilityAssignments
        if ((filters.Contacts || []).length) {
            if (!issue.ResponsibilityAssignments ||
                !issue.ResponsibilityAssignments.Contacts) {
                return true;
            }

            const contactsToTest = Object.keys(issue.ResponsibilityAssignments.Contacts);
            if (!(contactsToTest || []).length ||
                !checkForArray(filters, <any>{ Contacts: contactsToTest }, 'Contacts')) {
                return true;
            }
        }

        if ((filters.ContactGroups || []).length) {
            if (!issue.ResponsibilityAssignments ||
                !issue.ResponsibilityAssignments.ContactGroups) {
                return true;
            }

            const contactGroupsToTest = Object.keys(issue.ResponsibilityAssignments.ContactGroups);
            if (!(contactGroupsToTest || []).length ||
                !checkForArray(filters, <any>{ ContactGroups: contactGroupsToTest }, 'ContactGroups')) {
                return true;
            }
        }

        if ((filters.Classifications || []).length) {
            if (!(issue.Classifications || []).length ||
                !checkForArray(filters, issue, 'Classifications')) {
                return true;
            }
        }

        if ((filters.Keywords || []).length) {
            if (!(issue.Keywords || []).length ||
                !checkForArray(filters, issue, 'Keywords')) {
                return true;
            }
        }

        if ((filters.Priorities || []).length) {
            if (!issue.PriorityOID || !Utils.InArray(filters.Priorities, issue.PriorityOID)) {
                return true;
            }
        }

        if ((filters.States || []).length) {
            if (!issue.StateOID || !Utils.InArray(filters.States, issue.StateOID)) {
                return true;
            }
        }

        if ((filters.Forms || []).length) {
            if (!issue.AssignedFormOID || !Utils.InArray(filters.Forms, issue.AssignedFormOID)) {
                return true;
            }
        }

        if ((filters.Schedulings || []).length) {
            if (!issue.AssignedSchedulingOID || !Utils.InArray(filters.Schedulings, issue.AssignedSchedulingOID)) {
                return true;
            }
        }

        if (!matchesResponsibilities) {
            return true;
        }

        if ((filters.Types || []).length &&
            !Utils.InArray(filters.Types, issue.Type)) {
            return true;
        }

        if (filters.WithOpenIssues !== filters.WithArchivedIssues) {
            const isArchived = issue.IsArchived || false;

            if (filters.WithOpenIssues && isArchived) {
                return true;
            }

            if (filters.WithArchivedIssues && !isArchived) {
                return true;
            }
        }

        const deadline = new Date(issue.DeadlineTimestamp);
        const processingStatus = GetIssueProcessingInformation(issue.StateOID, issue.IsArchived, deadline).ProcessingStatus;

        if (typeof filters.ProcessingStatus === 'number') {
            if ((filters.ProcessingStatus as number) !== processingStatus) {
                return true;
            }
        } else if (filters.ProcessingStatus instanceof Array) {
            if ((filters.ProcessingStatus as Array<number>).indexOf(processingStatus) === -1) {
                return true;
            }
        }

        if (filters.DeadlinePeriod) {
            if (!issue.DeadlineTimestamp) {
                return true;
            }

            const deadlineTimestamp = deadline.getTime();
            const lowerBoundary = new Date(<string>filters.DeadlinePeriod.PeriodStart).getTime();
            const upperBoundary = new Date(<string>filters.DeadlinePeriod.PeriodEnd).getTime();

            if (deadlineTimestamp < lowerBoundary || deadlineTimestamp > upperBoundary) {
                return true;
            }
        }

        return false;
    }

    export function GetChildrenOfID(issueID: number): Array<Model.Issues.Issue> {
        if (isNaN(issueID)) {
            return;
        }

        return [];
    }

    export function GetChildrenOfIssue(issue: Model.Issues.Issue, withPreviousRevisions: boolean): Deferred {
        const onAfterIssueLoaded = (issues: Array<any>): Array<any> => {
            if (!issues || !issues.length) {
                return [];
            }

            if (+issue.ID) {
                issues = issues.filter((i) => {
                    return i.ParentID === issue.ID && !i.FollowerOID;
                });
            } else {
                const sourceIssues = {};
                const issueDic = {};

                sourceIssues[issue.OID] = true;

                for (let i = 0; i < issues.length; i++) {
                    issueDic[issues[i].OID] = issues[i];
                }

                if (withPreviousRevisions) {
                    let cIssue = issue;
                    while (cIssue && cIssue.PrecedingOID != null) {
                        sourceIssues[cIssue.PrecedingOID] = true;
                        cIssue = issueDic[cIssue.PrecedingOID];
                    }
                }

                issues = issues.filter((i) => {
                    return sourceIssues[i.ParentOID] && !i.FollowerOID;
                });
            }

            return issues;
        };

        if (!Session.IsSmartDeviceApplication) {
            // load from restservice
            return Utils.Http.Get(`issues/${issue.ID}?withdescendants=true`)
                .then(function(serverIssue) {
                    if (serverIssue && serverIssue.Descendants) {
                        return serverIssue.Descendants;
                    }

                    return [];
                }, function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        } else {
            // load from database
            if (+issue.ID) {
                // get by ParentID
                return window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, [issue.ID], 'IDX_ParentID')
                    .then(onAfterIssueLoaded)
            } else {
                // get previous revisions and retrieve issues by ParentOID in reverse
                const progressRevisions: Deferred = $.Deferred().resolve();

                const sourceIssues: Array<string> = [];
                sourceIssues.push(issue.OID);

                if (issue.PrecedingOID) {
                    const getPrevious = function getPrevious(precedingOID: string) {
                        return window.Database.GetSingleByKey(Enums.DatabaseStorage.Issues, precedingOID)
                            .then((issue) => {
                                if (issue) {
                                    sourceIssues.push(issue.OID);
                                    if (issue.PrecedingOID) {
                                        return getPrevious(issue.PrecedingOID);
                                    }
                                }
                            });
                    }
                    progressRevisions.then($.proxy(getPrevious, this, issue.PrecedingOID));
                }

                return progressRevisions.then(() => {
                    return window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, sourceIssues, 'IDX_ParentOID')
                        .then(onAfterIssueLoaded)
                });
            }
        }
    }

    export function GetChildrenOfOIDs(identifiers: Array<string>): Array<Model.Issues.Issue> {
        if (!(identifiers || []).length) {
            return;
        }

        return [];
    }

    export function PrepareForSync(issue: Model.Issues.Issue): Model.Issues.RawIssue {
        if (!issue) {
            return;
        }

        const syncableIssue = new Model.Issues.RawIssue(issue);
        for (let key in syncableIssue) {
            if (!syncableIssue.hasOwnProperty(key)) {
                continue;
            }

            const property = syncableIssue[key];
            if (typeof property === 'undefined' || property === null) {
                delete syncableIssue[key];
            }
        }

        /** set legacy responsibilities for older API Version */
        if (Session.LastKnownAPIVersion < 14 && issue.ResponsibilityAssignments) {
            PrepareResponsibilityForSync(issue);

            delete issue.ResponsibilityAssignments;
        }

        delete syncableIssue.Recorditems;

        return syncableIssue;
    }

    export function PrepareResponsibilityForSync(issue: Model.Issues.Issue | Model.Issues.RawIssue) {
        // Users
        issue.Users = [];
        for (let userOID in (issue.ResponsibilityAssignments.Users || {})) {
            if (!issue.ResponsibilityAssignments.Users.hasOwnProperty(userOID)) {
                continue;
            }
            const user = issue.ResponsibilityAssignments.Users[userOID];
            if (user.IsAccountable || user.IsResponsible) {
                issue.Users.push(userOID);
            }
        }

        // Teams
        issue.Teams = [];
        for (let teamOID in (issue.ResponsibilityAssignments.Teams || {})) {
            if (!issue.ResponsibilityAssignments.Teams.hasOwnProperty(teamOID)) {
                continue;
            }
            const team = issue.ResponsibilityAssignments.Teams[teamOID];
            if (team.IsAccountable || team.IsResponsible) {
                issue.Teams.push(teamOID);
            }
        }

        // Contacts
        issue.Contacts = [];
        for (let contactOID in (issue.ResponsibilityAssignments.Contacts || {})) {
            if (!issue.ResponsibilityAssignments.Contacts.hasOwnProperty(contactOID)) {
                continue;
            }
            const contact = issue.ResponsibilityAssignments.Contacts[contactOID];
            if (contact.IsInformed) {
                issue.Contacts.push(contactOID);
            }
        }
    }

    export function LoadIssueMetaDataForRoom(roomIdentifier: string): Deferred {
        const deferred = $.Deferred();

        window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, [roomIdentifier], 'IDX_AssignedElementOID')
            .then((issues: Array<Model.Issues.RawIssue>) => {
                deferred.resolve(getIssueMetadata(issues));
            });

        return deferred.promise();
    }

    export function GetIssueMetaDataForRoom(issues: Array<Model.Issues.RawIssue>): { [key: number]: Model.Elements.IssueMetadata } {
        return getIssueMetadata(issues);
    }

    function getIssueMetadata(issues: Array<Model.Issues.RawIssue>): { [key: number]: Model.Elements.IssueMetadata } {
        if (!(issues || []).length) {
            return null;
        }

        const metadata: { [key: number]: Model.Elements.IssueMetadata } = {};

        issues.forEach(i => {
            if (!metadata.hasOwnProperty(i.Type.toString())) {
                metadata[i.Type] = new Model.Elements.IssueMetadata();
            }

            const typeMeta = metadata[i.Type];

            typeMeta.Count += 1;
            typeMeta.Identifiers.push(i.OID);

            const processingInfo = GetIssueProcessingInformation(i.StateOID, i.IsArchived, new Date(i.DeadlineTimestamp));

            if (processingInfo.ProcessingStatus > typeMeta.ProcessingStatus) {
                typeMeta.ProcessingStatus = processingInfo.ProcessingStatus;
                typeMeta.IssueCellMarkerClass = processingInfo.IssueCellMarkerClass;
            }
        });

        return metadata;
    }
}