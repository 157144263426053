//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../enums.ts"  />
/// <reference path="./requirement.ts"  />
/// <reference path="../model.recorditem.ts"  />
/// <reference path="../properties/property.ts"  />
/// <reference path="../files/file.ts"  />
/// <reference path="./evaluation.ts"  />
//imports-end

module Model.Elements {
    export class ParentIssueModificationDefinition {
        OID: string;
        Type: Enums.ParentIssueModificationDefinitionType;
        Property: string;
        Position: number;
        PreviousEntities: Array<string>;
        NewEntities: Array<string>;
    }

    export class Action {
        Type: Enums.CheckpointWorkflowType;
        CategoryOID?: string;
        Order?: number;
        IntervalType?: Enums.CheckpointWorkflowIntervalType;
        MaximumInterval?: number;
        Indicator?: number;
        FormOID?: string;
        Title?: string;
        Text?: string;
        Teams?: Array<string>;
        Users?: Array<string>;
        TriggerAutomatically?: boolean;
        ParentIssueModifications: Array<ParentIssueModificationDefinition>
    }
}