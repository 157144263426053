module Model.FilterWindow {
    export class ButtonItem {
        public Key: string;
        public Title: string;
        public CssClasses: string;
        public OnClick: Function;
        public Icon: string;

        constructor(key: string, title: string, cssClasses: string, onClick: Function, icon?: string) {
            this.Key = key;
            this.Title = title;
            this.CssClasses = cssClasses;
            this.OnClick = onClick;
            this.Icon = icon;
        }
    }
}