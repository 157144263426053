///<reference path="i-workflow.ts"/>
///<reference path="issue-workflow-base.ts"/>
///<reference path="workflow-settings.ts"/>
/// <reference path="../../../model/model.recorditem.ts"/>
/// <reference path="../../../model/elements/element.ts"/>
/// <reference path="../../../enums.ts"/>

module New.Checkpoints.Workflow {
    export class FormWorkflow extends IssueWorkflowBase {
        constructor(workflow: IWorkflow, workflowSettings: IssueWorkflowSettings) {
            super(workflow, workflowSettings);
        }

        public Execute(): any {
            const deferred = $.Deferred();

            const form = DAL.Elements.GetByOID(this.FormOID) || { InitialStateOID: null };

            if (!this.canUserCreateANewIssue(Enums.IssueType.Form, form.InitialStateOID || Session.Client.Settings.FormOpened)) {
                return deferred.reject().promise();
            }

            Utils.IssueViewer.CreateCorrectiveAction(
                this.Recorditem,
                this.Checkpoint,
                this.ParentIssue,
                this,
                this.CreateTemporaryIssue,
                $.noop,
                true)
                .then((issue?: Model.Issues.RawIssue) => {
                    setTimeout(() => {
                        if (!issue) {
                            deferred.resolve();
                            return;
                        }

                        const response = new Response.FormWorkflow(this, new Model.Issues.Issue(issue));

                        deferred.resolve(response);
                    }, 5);
                })
                .fail(deferred.resolve);

            return deferred.promise();
        }
    }
}
