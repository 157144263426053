/// <reference path="./login.base.ts"  />
/// <reference path="../../config.ts"  />

class WebLogin extends LoginBase {

    protected showDialog(): Deferred {
        let resultDeferred = super.showDialog();

        // hide & disable baseURI row
        this.$btnBaseURI.attr('disabled', 'disabled');
        this.$baseUriRow.addClass('hidden');
        this.$btnScanServiceQRCode.attr('disabled', 'disabled');

        // show remember me checkbox
        this.$cbRememberMe.parent().removeClass('hidden');

        const rememberCookie = Utils.Cookies.Get(Session.CookieConfig.RememberCookieName);
        this.$cbRememberMe.attr('checked', /true/i.test(rememberCookie));

        resultDeferred = resultDeferred.then(() => {
            this.$txtUsername.focus();
        });

        return resultDeferred;
    }

    protected checkExistingUser(): Deferred {
        return DAL.GetWebUser()
            .then((account: UserAccount, apiVersion: number) => {
                this.lastKnownApiVersion = apiVersion;

                if (this.lastKnownApiVersion >= 16 && account && account.User && !this.userHasLoginRight(account)) {
                    return $.Deferred().reject().promise();
                }

                return $.Deferred().resolve(account, true);
            });
    }

    protected bindEvents(): void {
        super.bindEvents()
        this.$cbRememberMe.on('keypress', (evt: KeyboardEvent) => {
            if (evt.keyCode === Enums.KeyCode.RETURN) {
                this.tryLogin();
            }
        });
    }

    protected unbindEvents(): void {
        super.unbindEvents();
        this.$cbRememberMe.off('change').off('keypress');
    }

    protected beforeSessionStart(username: string, password: string): Deferred {
        if (!username || !username.trim() || !password || !password.trim()) {
            return $.Deferred().reject();
        }

        const remember = this.$cbRememberMe.is(':checked');
        const loginData: Model.LoginData = {
            Username: username,
            Password: password,
            Remember: remember,
            Application: 'Web'
        };

        return Utils.Http.Post('login', loginData, null, true, null, Utils.Http.TIMEOUT_MS_SHORT, false)
            .then((result: string, status: string, xhr: any) => {
                Session.SetCookieConfig(xhr);

                Session.ActiveSession = true;
            });
    }
}
