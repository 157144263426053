//imports-start
/// <reference path="../definitions.d.ts" />
/// <reference path="./utils.http.ts" />
//imports-end

module Utils.CustomDataPicker {
    let _$win, _$overlay, _$footer, _$body;
    let _$btnReset, _$btnAbort, _$btnOkay;

    let _onItemClick, _onBtnResetClick, _callback, _onAbort;
    let _isMultiSelectionAllowed;
    let _options: Options;

    let _items;
    let _loadedImagesCounter;

    export type Options = {
        Title: string,
        TextSchema?: string,
        UseSystemProperties?: boolean,
        ShowPropertyColors?: boolean,
        SelectedProperties?: any[],
        HideResetButton?: boolean,
        HideConfirmationButtons?: boolean,
        InfoText?: string,
        ZIndex?: number,
        OnItemClick?: Function,
        OnResetButtonClick?: Function,
        Callback?: Function,
        OnAbort?: Function,
        IsMultiSelectionAllowed?: boolean,
        MaxWindowHeight?: number
        MaxWindowWidth?: number,
        Width?: number,
        Height?: number,
        ButtonTextWrap?: boolean
    };

    function onBtnResetClick(): void {
        if (_onBtnResetClick instanceof Function) {
            _onBtnResetClick();
        } else {
            _callback();
        }

        Destroy();
    }

    function onBtnAbortClick(): void {
        if (_onAbort instanceof Function) {
            _onAbort();
        }

        Destroy();
    }

    function onBtnOkayClick(): void {
        let returnValue = _$win.find('.selected');

        if (!_isMultiSelectionAllowed) {
            returnValue = returnValue.eq(0);
        }

        _callback(returnValue);

        Destroy();
    }

    function onItemClick(): void {
        let $this = $(this);

        if (!_isMultiSelectionAllowed) {
            $this.siblings().removeClass('selected');
        }

        if (!$this.hasClass('selected')) {
            $this.addClass('selected');
        } else if (_isMultiSelectionAllowed) {
            $this.removeClass('selected');
        }
    }

    function onImageLoaded(): void {
        _loadedImagesCounter++;

        if (_loadedImagesCounter === _$win.find('img').length) {
            Resize();
        }
    }

    export function Destroy(): void {
        if (_$win) {
            _$win.remove();
            _$win = null;
            _$footer = null;
            _$btnReset = null;
            _$btnAbort = null;
            _$btnOkay = null;
        }

        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }

        if (!Utils.RecorditemEditor.IsVisible() &&
            !Utils.IssueViewer.IsVisible() && _$body) {
            _$body.removeClass('modal-open');
        }

        _$body = null;

        _onItemClick = null;
        _callback = null;
        _onAbort = null;
        _isMultiSelectionAllowed = null;
        _items = null;
        _loadedImagesCounter = null;
    }

    function bindEvents(): void {
        _$win.find('.body button').on('click', _onItemClick instanceof Function ? function () { _onItemClick($(this)); } : onItemClick);
        _$btnReset.on('click', onBtnResetClick);
        _$btnAbort.on('click', onBtnAbortClick);
        _$btnOkay.on('click', onBtnOkayClick);

        if (_$win.find('img').length) {
            _loadedImagesCounter = 0;
            _$win.find('img').on('load', onImageLoaded);
        }
    }

    export function Resize() {
        _$win.css({
            width: _options.Width,
        });

        let windowHeight = _$win.find('.header').outerHeight(true) + calculateWindowBodyHeight() + _$footer.outerHeight(true);
        let viewportWidth = $(window).width();

        let maxWindowHeight = _options.MaxWindowHeight || $(window).height() - 60;
        let maxWindowWidth = _options.MaxWindowWidth || viewportWidth - (viewportWidth < 400 ? 10 : 60);

        if (windowHeight > maxWindowHeight) {
            windowHeight = maxWindowHeight;
        }

        if (_options.Width > maxWindowWidth) {
            _options.Width = maxWindowWidth;
        }

        if (+_options.MaxWindowHeight && windowHeight > _options.MaxWindowHeight) {
            windowHeight = _options.MaxWindowHeight;
        }

        _$win.css({
            height: windowHeight,
            'max-height': maxWindowHeight,
            'max-width': maxWindowWidth
        });

        _$win.css({
            'margin-top': -(windowHeight / 2),
            'margin-left': -(_options.Width / 2)
        });

        if (_options.ZIndex) {
            _$win.css('z-index', _options.ZIndex)
        }
    }

    function calculateWindowBodyHeight(): number {
        const $body = _$win.find('.body');
        let windowBodyHeight = 0;

        $body.children().toArray().forEach(function (child) {
            const $child = $(child);

            windowBodyHeight += $child.outerHeight(true);
            windowBodyHeight += parseInt($child.css('margin-top'), 10);
        });

        windowBodyHeight += parseInt($body.css('padding-top'), 10);
        windowBodyHeight += parseInt($body.css('padding-bottom'), 10);

        return windowBodyHeight;
    }

    export function Show(items: Array<any>, options: Options): void {
        if (!(items || []).length || !(options.Callback instanceof Function)) {
            return;
        }

        _$win = $(Templates.Selections.CustomDataPicker({
            Items: items,
            Title: options.Title,
            UseSystemProperties: options.UseSystemProperties,
            ShowPropertyColors: options.ShowPropertyColors,
            SelectedProperties: (options.SelectedProperties || []).join(','),
            HideResetButton: options.HideResetButton,
            HideConfirmationButtons: options.HideConfirmationButtons,
            TextSchema: options.TextSchema,
            InfoText: options.InfoText,
            ButtonTextWrap: options.ButtonTextWrap
        }));

        _$overlay = Utils.Overlay.Generate('olCustomDataPicker', options.ZIndex ? options.ZIndex - 1 : 1102);
        _$footer = _$win.find('.footer');
        _$btnReset = _$footer.find('.btn-reset');
        _$btnAbort = _$footer.find('.btn-abort');
        _$btnOkay = _$footer.find('.btn-okay');
        _$body = $('body');

        _onItemClick = options.OnItemClick;
        _onBtnResetClick = options.OnResetButtonClick;
        _callback = options.Callback;
        _onAbort = options.OnAbort;
        _isMultiSelectionAllowed = options.IsMultiSelectionAllowed;

        _$body.append(_$win);
        _$body.addClass('modal-open');

        _options = options;

        Resize();

        bindEvents();
    }

    export function IsVisible(): boolean {
        return _$win && _$win.is(':visible');
    }
}
