//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="./model.errors.ts"  />
/// <reference path="./model.comment.ts"  />
/// <reference path="./model.errors.ts"  />
/// <reference path="../dal/issues.ts"  />
/// <reference path="../dal/schemas.ts"  />
/// <reference path="../utils/utils.date-time.ts"  />
/// <reference path="../utils/utils.support-mail.ts" />
//imports-end

module Model.SyncCenter {

    function onAfterEntitiesUpdated($container, identifiersOfChangedEntities: string[], ignore: boolean): void {
        if ((identifiersOfChangedEntities || []).length) {
            for (let iCnt = 0, iLen = identifiersOfChangedEntities.length; iCnt < iLen; iCnt++) {
                const identifier = identifiersOfChangedEntities[iCnt];
                const $listItem = $container.find(`li[data-oid="${identifier}"]`);

                if (ignore) {
                    $listItem.attr('disabled', 'disabled');
                } else {
                    $listItem.removeAttr('disabled');
                }
            }
        }
    }

    function createSyncTree(): Deferred {
        const deferred = $.Deferred();

        window.Database.GetAllFromStorage(Enums.DatabaseStorage.SyncEntities)
            .then(function(plainEntities) {
                const preparedEntities = [];

                if ((plainEntities || []).length) {
                    for (let eCnt = 0, eLen = plainEntities.length; eCnt < eLen; eCnt++) {
                        const entity = plainEntities[eCnt];
                        const entityDescription = Model.Synchronisation.BuildEntityDescription(entity);

                        if (entityDescription) {
                            preparedEntities.push(entityDescription);
                        }
                    }
                }

                deferred.resolve(new Model.Synchronisation.Tree(preparedEntities, true));
            });

        return deferred.promise();
    }

    function updateEntity(entity: Model.Synchronisation.IEntityDescription, ignore: boolean): Deferred {
        if (!entity) {
            return $.Deferred().resolve();
        }

        entity.Ignore = ignore;

        return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, entity);
    }

    function setWhetherToSyncEntities(rootIdentifier: string, ignore: boolean): Deferred {
        if (!rootIdentifier) {
            throw new Model.Errors.ArgumentError('rootIdentifier is missing');
        }

        const deferred = $.Deferred();

        createSyncTree()
            .then(function(syncTree: Model.Synchronisation.Tree) {
                if (!syncTree || !syncTree.Entities || !syncTree.Entities.length) {
                    deferred.resolve();
                    return;
                }

                const identifiers: string[] = [rootIdentifier];
                const identifiersDict: Dictionary<boolean> = {};
                const syncTreeEntities = syncTree.Entities;
                identifiersDict[rootIdentifier] = true;

                // Abhängigkeiten zu verknüpften Entities durchsuchen
                for (const currentIdentifier of identifiers) {
                    // alle abhängigen Entities finden und deaktivieren
                    for (const entity of syncTreeEntities) {
                        if (!identifiersDict[entity.OID] && entity.Dependencies &&
                            entity.Dependencies.has(currentIdentifier)) {
                            identifiers.push(entity.OID);
                            identifiersDict[entity.OID] = true;
                        }
                    }
                }

                (function updateNext(idx: number) {
                    const identifier = identifiers[idx];

                    if (!identifier) {
                        deferred.resolve();
                        return;
                    }

                    window.Database.GetSingleByKey(Enums.DatabaseStorage.SyncEntities, identifier)
                        .then(function(entity: Model.Synchronisation.IEntityDescription) {
                            if (!entity) {
                                deferred.resolve();
                                return;
                            }

                            updateEntity(entity, ignore)
                                .then(function() {
                                    if (idx < identifiers.length - 1) {
                                        updateNext(++idx);
                                    } else {
                                        deferred.resolve(identifiers, ignore);
                                    }
                                });
                        })
                        .fail(deferred.resolve);
                })(0);
            });

        return deferred.promise();
    }

    export interface IEntity {
        OID: string;
        SynchronisationProperties: Model.Synchronisation.IEntityDescription;

        GetMarkup(): string;
        ClickEvent($container, $li): void;
    }

    abstract class Entity implements IEntity {
        OID: string;
        EditModeIsActive: boolean;
        SynchronisationProperties: Model.Synchronisation.IEntityDescription;

        constructor(oid: string, synchronisationProperties: Model.Synchronisation.IEntityDescription, editModeIsActive: boolean) {
            this.OID = oid;
            this.EditModeIsActive = editModeIsActive;
            this.SynchronisationProperties = synchronisationProperties;
        }

        public abstract GetMarkup(): string;

        public ClickEvent($container, $li): void {
            if (this.EditModeIsActive) {
                Utils.Spinner.Show();

                setWhetherToSyncEntities(this.OID, $li.attr('disabled') !== 'disabled')
                    .then(function(identifiersOfChangedEntities: string[], ignore: boolean) {
                        onAfterEntitiesUpdated($container, identifiersOfChangedEntities, ignore);
                    })
                    .always(() => {
                        Utils.Spinner.Hide();
                    });
            }
        }
    }

    export class FileEntity implements IEntity {
        OID: string;
        SynchronisationProperties: Model.Synchronisation.IFileEntityDescription;
        Filename: string;
        FilePath: string;
        CreationTimestamp: string;
        EditModeIsActive: boolean;
        FileMeta: { MimeType: Enums.MimeType, Filename?: string };

        public constructor(synchronisationProperties: Model.Synchronisation.IFileEntityDescription, editModeIsActive: boolean) {
            this.OID = synchronisationProperties.OID;
            this.SynchronisationProperties = synchronisationProperties;
            this.Filename = synchronisationProperties.Filename;
            this.FilePath = Utils.GetResourcesPath() + synchronisationProperties.Filename;
            this.CreationTimestamp = Utils.DateTime.ToString(synchronisationProperties.Timestamp);
            this.EditModeIsActive = editModeIsActive;
        }

        protected onFileClick(file): void {
            if (!file) {
                throw new Model.Errors.ArgumentNullError('file meta data missing');
            }

            if (file.MimeType.startsWith('image/')) {
                Utils.OpenImages([file], file.Filename);
            }
        }

        public GetMarkup(): string {
            if (!this.FileMeta) {
                return Templates.SyncCenter.EntityType.File(this);
            }

            return Utils.IsImage(this.FileMeta.MimeType) ?
                Templates.SyncCenter.EntityType.Image(this) :
                Templates.SyncCenter.EntityType.VoiceMail(this);
        }

        public ClickEvent($container, $li): void {
            const metaData = this.FileMeta;
            const instance = this;

            if (!instance.EditModeIsActive) {
                if (metaData) {
                    instance.onFileClick(metaData);
                }
            } else {
                Utils.Spinner.Show();

                setWhetherToSyncEntities(instance.SynchronisationProperties.OID, $li.attr('disabled') !== 'disabled')
                    .then(function(identifiersOfChangedEntities: string[], ignore: boolean) {
                        onAfterEntitiesUpdated($container, identifiersOfChangedEntities, ignore);
                    })
                    .always(() => {
                        Utils.Spinner.Hide();
                    });
            }
        }
    }

    export class DefaultEntity extends Entity {
        IssueOID: string;
        IssueID: number;

        constructor(synchronisationProperties: Model.Synchronisation.IEntityDescription, editModeIsActive: boolean) {
            super(synchronisationProperties.OID, synchronisationProperties, editModeIsActive);

            if (!!synchronisationProperties.IssueOID) {
                this.IssueOID = synchronisationProperties.IssueOID;
            }

            if (+synchronisationProperties.IssueID) {
                this.IssueID = synchronisationProperties.IssueID;
            }
        }

        public GetMarkup(): string {
            return Templates.SyncCenter.EntityType.Default(this);
        }
    }

    export class IssueEntity extends Entity {
        ID: number;
        Revision: number;
        Type: Enums.SyncEntityType;
        Abbrevation: string;
        AssignedFormOID: string;
        CreationTimestamp: string;
        ModificationTimestamp: string;
        RawModificationTimestamp: string | Date;
        Title: string;
        TypeIconFilename: string;
        FollowerOID: string;
        Description: string;
        AssignedElement: Model.Elements.Element;
        AssignedElementHierarchy: string;
        Editor: string;
        Creator: string;
        State: string;
        Priority: string;
        PrecedingOID: string;

        constructor(synchronisationProperties: Model.Synchronisation.IssuesEntityDescription, issue: Model.Issues.IssueType | Model.Issues.RawIssue, editModeIsActive: boolean) {
            super(issue.OID, synchronisationProperties, editModeIsActive);

            this.ID = issue.ID || 0;
            this.Revision = issue.Revision;
            this.Type = issue.Type;
            this.Abbrevation = Utils.GetIssueAbbreviation(issue.Type);
            this.AssignedFormOID = issue.AssignedFormOID;
            this.CreationTimestamp = Utils.DateTime.ToString(new Date(<string>issue.CreationTimestamp));
            this.ModificationTimestamp = Utils.DateTime.ToString(new Date(<string>issue.ModificationTimestamp));
            this.RawModificationTimestamp = issue.ModificationTimestamp;
            this.Title = issue.Title || i18next.t('Misc.Untitled');
            this.TypeIconFilename = Utils.GetIssueTypeIcon(issue.Type);

            if (!!issue.FollowerOID) {
                this.FollowerOID = issue.FollowerOID;
            }

            if (!!issue.PrecedingOID) {
                this.PrecedingOID = issue.PrecedingOID;
            }

            if (!!issue.PriorityOID) {
                const priority = DAL.Properties.GetByOID(issue.PriorityOID);
                if (priority) {
                    this.Priority = priority.Title;
                }
            }

            if (!!issue.StateOID) {
                const state = DAL.Properties.GetByOID(issue.StateOID);
                if (state) {
                    this.State = state.Title;
                }
            }

            const creator = DAL.Users.GetByOID(issue.CreatorOID);
            if (creator) {
                this.Creator = creator.Title;
            }

            const editor = DAL.Users.GetByOID(issue.EditorOID);
            if (editor) {
                this.Editor = editor.Title;
            }

            const element = DAL.Elements.GetByOID(issue.AssignedElementOID);
            if (element) {
                this.AssignedElement = element;
                this.AssignedElementHierarchy = Utils.GetElementHierarchy(element);
            }

            if (!!issue.Description) {
                this.Description = issue.Description;
            }
        }

        public GetMarkup(): string {
            return Templates.SyncCenter.EntityType.Issue(this);
        }
    }

    export class IssueFileEntity extends FileEntity {
        Issue: Model.Issues.Issue;

        constructor(synchronisationProperties: Model.Synchronisation.IFileEntityDescription, issue: Model.Issues.Issue, editModeIsActive: boolean) {
            super(synchronisationProperties, editModeIsActive);

            this.Issue = issue;
            this.Issue.Abbreviation = Utils.GetIssueAbbreviation((issue || {}).Type);
            this.FileMeta = Utils.Where(this.Issue.Files, 'Filename', '===', this.Filename);

            if (!this.FileMeta) {
                throw new Error('File meta data is missing');
            }
        }
    }

    export class RecorditemEntity extends Entity {
        ElementOID: string;
        RawModificationTimestamp: string | Date;
        CreationTimestamp: string;
        ModificationTimestamp: string;
        IssueOID: string;
        IssueID: number;
        Creator: string;
        Editor: string;
        AssignedElement: Model.Elements.Element;
        AssignedElementHierarchy: string;

        constructor(synchronisationProperties: Model.Synchronisation.RecordItemEntityDescription, recorditem: Model.Recorditem, editModeIsActive: boolean) {
            super(recorditem.OID, synchronisationProperties, editModeIsActive);

            this.ElementOID = recorditem.ElementOID;
            this.RawModificationTimestamp = recorditem.ModificationTimestamp;
            this.CreationTimestamp = Utils.DateTime.ToString(new Date(recorditem.CreationTimestamp));
            this.ModificationTimestamp = Utils.DateTime.ToString(new Date(recorditem.ModificationTimestamp));

            if (!!recorditem.IssueOID) {
                this.IssueOID = recorditem.IssueOID;
            }

            if (+recorditem.IssueID) {
                this.IssueID = recorditem.IssueID;
            }

            const creator = DAL.Users.GetByOID(recorditem.CreatorOID);
            if (creator) {
                this.Creator = creator.Title;
            }

            const editor = DAL.Users.GetByOID(recorditem.EditorOID);
            if (editor) {
                this.Editor = editor.Title;
            }

            const element = DAL.Elements.GetByOID(recorditem.ElementOID);
            if (element) {
                this.AssignedElement = element;
                this.AssignedElementHierarchy = Utils.GetElementHierarchy(element);
            }
        }

        public GetMarkup(): string {
            return Templates.SyncCenter.EntityType.Recorditem(this);
        }
    }

    export class RecorditemDeleteEntity extends Entity {
        RawDeletionTimestamp: string | Date;
        DeletionTimestamp: string;
        RecorditemID: number;
        IssueID?: number;

        constructor(synchronisationProperties: Model.Synchronisation.DeleteRecorditemEntityDescription, editModeIsActive: boolean) {
            super(synchronisationProperties.OID, synchronisationProperties, editModeIsActive);

            this.RawDeletionTimestamp = synchronisationProperties.Timestamp;
            this.DeletionTimestamp = Utils.DateTime.ToString(new Date(synchronisationProperties.Timestamp));

            if (+synchronisationProperties.ID) {
                this.RecorditemID = synchronisationProperties.ID;
            }

            if (+synchronisationProperties.IssueID) {
                this.IssueID = synchronisationProperties.IssueID;
            }
        }

        public GetMarkup(): string {
            return Templates.SyncCenter.EntityType.RecorditemDelete(this);
        }
    }

    export class RecorditemFileEntity extends FileEntity {
        Recorditem: Model.Recorditem;

        constructor(synchronisationProperties: Model.Synchronisation.IFileEntityDescription, recorditem: Model.Recorditem, editModeIsActive: boolean) {
            super(synchronisationProperties, editModeIsActive);

            let metaData = {
                MimeType: Enums.MimeType.Image,
                Filename: synchronisationProperties.Filename
            };

            this.Recorditem = recorditem;

            if (recorditem && synchronisationProperties.Filename !== recorditem.Value) {
                metaData = Utils.Where(this.Recorditem.AdditionalFiles, 'Filename', '===', this.Filename);
            }

            if (metaData) {
                this.FileMeta = metaData;
            }
        }
    }

    export class CommentEntity extends Entity {
        RawTimestamp: string | Date;
        Timestamp: string;
        IsIssueComment: boolean;
        RecorditemID: number;
        RecorditemOID: string;
        IssueID: number;
        IssueOID: string;
        Creator: string;

        constructor(synchronisationProperties: Model.Synchronisation.CommentEntityDescription, comment: Model.Comment, editModeIsActive: boolean) {
            super(comment.OID, synchronisationProperties, editModeIsActive);

            this.RawTimestamp = comment.Timestamp;
            this.Timestamp = Utils.DateTime.ToString(new Date(<string>comment.Timestamp));
            this.IsIssueComment = comment.Type === Enums.CommentType.IssueComment || comment.Type === Enums.CommentType.IssueChangeComment;

            if (!!synchronisationProperties.IssueID) {
                this.IssueID = synchronisationProperties.IssueID;
            }

            this.IssueOID = synchronisationProperties.IssueOID || null;

            if (!this.IsIssueComment) {
                if (!!comment.AssignmentID) {
                    this.RecorditemID = comment.AssignmentID;
                }

                this.RecorditemOID = comment.AssignmentOID || null;
            }

            const creator = DAL.Users.GetByOID(comment.CreatorOID);
            if (creator) {
                this.Creator = creator.Title;
            }
        }

        public GetMarkup(): string {
            return Templates.SyncCenter.EntityType.Comment(this);
        }
    }

    export class CommentDeleteEntity extends Entity {
        RawDeletionTimestamp: string | Date;
        DeletionTimestamp: string;
        IsIssueComment: boolean;
        AssignmentOID: string;
        RecorditemID: number;
        IssueID: number;
        IssueOID: string;

        constructor(synchronisationProperties: Model.Synchronisation.DeleteCommentEntityDescription, editModeIsActive: boolean) {
            super(synchronisationProperties.OID, synchronisationProperties, editModeIsActive);

            this.RawDeletionTimestamp = synchronisationProperties.Timestamp;
            this.DeletionTimestamp = Utils.DateTime.ToString(new Date(synchronisationProperties.Timestamp));
            this.IsIssueComment = synchronisationProperties.Type == Enums.SyncEntityType.IssueDeleteComment;

            if (+synchronisationProperties.IssueID) {
                this.IssueID = synchronisationProperties.IssueID;
            }

            this.IssueOID = synchronisationProperties.IssueOID;

            if (+synchronisationProperties.RecorditemID) {
                this.RecorditemID = synchronisationProperties.RecorditemID;
            }

            if (!this.IssueID && !this.RecorditemID || !this.IsIssueComment) {
                this.AssignmentOID = synchronisationProperties.DependingOnOID;
            }
        }

        public GetMarkup(): string {
            return Templates.SyncCenter.EntityType.CommentDelete(this);
        }
    }

    export type OnAfterEntityCollectionCreatedFunc = (EntityCollection) => void;
    export type EntityCollectionOptions = { EditModeIsActive: boolean };

    export class EntityCollection {
        RawEntityCollection: Model.Synchronisation.IEntityDescription[];
        Options: EntityCollectionOptions;
        OnAfterCollectionCreated: OnAfterEntityCollectionCreatedFunc;
        Entities: IEntity[];

        constructor(entities: Model.Synchronisation.IEntityDescription[], options: EntityCollectionOptions, onAfterEntityCollectionCreated: OnAfterEntityCollectionCreatedFunc) {
            if (!(entities || []).length) {
                throw new Model.Errors.ArgumentError('entities may not be empty');
            }

            if (!(onAfterEntityCollectionCreated instanceof Function)) {
                throw new Model.Errors.ArgumentError('callback must be a function');
            }

            this.RawEntityCollection = new Model.Synchronisation.Tree(entities, true).Entities;
            this.Options = options || { EditModeIsActive: false };
            this.OnAfterCollectionCreated = onAfterEntityCollectionCreated;

            this.Entities = [];

            this.createEntityCollection(this.onAfterCollectionCreated);
        }

        private onAfterCollectionCreated(entities: IEntity[]) {
            this.Entities = entities;

            this.OnAfterCollectionCreated(this);
        }

        private createEntityCollection(callback: Function) {
            const instance = this;
            const entityObjects: IEntity[] = [];

            if (!(this.RawEntityCollection || []).length) {
                callback.call(this, entityObjects);
                return;
            }

            const preloadRecorditemOIDs = {};
            const preloadIssueOIDs = {};
            const preloadCommentOIDs = {};

            for (const syncEntity of instance.RawEntityCollection) {
                // preload optional service entities
                switch (syncEntity.Type) {
                    case Enums.SyncEntityType.Recorditem:
                        preloadRecorditemOIDs[syncEntity.OID] = true;
                        break;
                    case Enums.SyncEntityType.RecorditemAdditionalFile:
                    case Enums.SyncEntityType.RecorditemValueFile:
                        preloadRecorditemOIDs[syncEntity.DependingOnOID] = true;
                        break;
                    case Enums.SyncEntityType.Issue:
                        preloadIssueOIDs[syncEntity.OID] = true;
                        break;
                    case Enums.SyncEntityType.IssueFile:
                        preloadIssueOIDs[syncEntity.DependingOnOID] = true;
                        break;
                    case Enums.SyncEntityType.IssueComment:
                    case Enums.SyncEntityType.RecorditemComment:
                        preloadCommentOIDs[syncEntity.OID] = true;
                        break;
                }
            }

            const preloadRecorditems = {};
            const preloadIssues = {};
            const preloadComments = {};

            window.Database.GetManyByKeys(Enums.DatabaseStorage.Recorditems, Object.keys(preloadRecorditemOIDs))
                .then((recorditems: Model.Recorditem[]) => {
                    for (const item of recorditems) {
                        preloadRecorditems[item.OID] = Utils.PrepareRecorditem(item);
                    }

                    return window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, Object.keys(preloadIssueOIDs));
                })
                .then((issues: Model.Issues.Issue[]) => {
                    for (const item of issues) {
                        preloadIssues[item.OID] = item;
                    }

                    return window.Database.GetManyByKeys(Enums.DatabaseStorage.Comments, Object.keys(preloadCommentOIDs));
                })
                .then((comments: Model.Comment[]) => {
                    for (const item of comments) {
                        preloadComments[item.OID] = item;
                    }
                })
                .then(() => {
                    for (const syncEntity of instance.RawEntityCollection) {
                        switch (syncEntity.Type) {
                            case Enums.SyncEntityType.Recorditem:
                                {
                                    const recorditem = preloadRecorditems[syncEntity.OID];

                                    if (recorditem) {
                                        entityObjects.push(new SyncCenter.RecorditemEntity(<Synchronisation.RecordItemEntityDescription>syncEntity, recorditem, instance.Options.EditModeIsActive));
                                    }
                                }
                                break;
                            case Enums.SyncEntityType.RecorditemDelete:
                                entityObjects.push(new SyncCenter.RecorditemDeleteEntity(<Synchronisation.DeleteRecorditemEntityDescription>syncEntity, instance.Options.EditModeIsActive));
                                break;
                            case Enums.SyncEntityType.RecorditemAdditionalFile:
                            case Enums.SyncEntityType.RecorditemValueFile:
                                {
                                    const recorditem = preloadRecorditems[syncEntity.DependingOnOID];

                                    // Sync Entity soll angezeigt werden, auch wenn das [recorditem] nicht mehr existiert
                                    entityObjects.push(new SyncCenter.RecorditemFileEntity(<Synchronisation.IFileEntityDescription>syncEntity, recorditem, instance.Options.EditModeIsActive));
                                }
                                break;
                            case Enums.SyncEntityType.Issue:
                                {
                                    const issue = preloadIssues[syncEntity.OID];

                                    if (issue) {
                                        entityObjects.push(new SyncCenter.IssueEntity(<Synchronisation.IssuesEntityDescription>syncEntity, issue, instance.Options.EditModeIsActive));
                                    }
                                }
                                break;
                            case Enums.SyncEntityType.IssueFile:
                                {
                                    const issue = preloadIssues[syncEntity.DependingOnOID];

                                    // Sync Entity soll angezeigt werden, auch wenn das [issue] nicht mehr existiert
                                    entityObjects.push(new SyncCenter.IssueFileEntity(<Synchronisation.IFileEntityDescription>syncEntity, issue, instance.Options.EditModeIsActive));
                                }
                                break;
                            case Enums.SyncEntityType.IssueComment:
                            case Enums.SyncEntityType.RecorditemComment:
                                {
                                    const comment = preloadComments[syncEntity.OID];

                                    if (comment) {
                                        entityObjects.push(new SyncCenter.CommentEntity(<Synchronisation.CommentEntityDescription>syncEntity, comment, instance.Options.EditModeIsActive));
                                    }
                                }
                                break;
                            case Enums.SyncEntityType.IssueDeleteComment:
                            case Enums.SyncEntityType.RecorditemDeleteComment:
                                entityObjects.push(new SyncCenter.CommentDeleteEntity(<Synchronisation.DeleteCommentEntityDescription>syncEntity, instance.Options.EditModeIsActive));
                                break;
                            default:
                                entityObjects.push(new SyncCenter.DefaultEntity(syncEntity, instance.Options.EditModeIsActive));
                                break;
                        }
                    }

                    // finish
                    callback.call(instance, entityObjects);
                });
        }

        private getServiceEntities(): Deferred {
            const deferred = $.Deferred();
            const serviceEntities = [];
            const instance = this;

            (function iterate(idx: number) {
                const uploadEntity = Model.Synchronisation.BuildEntityDescription(instance.RawEntityCollection[idx]);

                if (uploadEntity['GetServiceEntity'] instanceof Function) {
                    (<Model.Synchronisation.IWithGetServiceEntity>uploadEntity).GetServiceEntity(null, true)
                        .then(function(serviceEntity: Model.Synchronisation.IEntityDescription) {
                            serviceEntities.push(serviceEntity);

                            if (idx < instance.RawEntityCollection.length - 1) {
                                iterate(++idx);
                            } else {
                                deferred.resolve(serviceEntities);
                            }
                        })
                        .fail(function() {
                            if (idx < instance.RawEntityCollection.length - 1) {
                                iterate(++idx);
                            } else {
                                deferred.resolve(serviceEntities);
                            }
                        });
                } else {
                    if (idx < instance.RawEntityCollection.length - 1) {
                        iterate(++idx);
                    } else {
                        deferred.resolve(serviceEntities);
                    }
                }
            })(0);

            return deferred.promise();
        }

        public GetMarkup(): string {
            const html = [];

            if ((this.Entities || []).length) {
                for (let eCnt = 0, eLen = this.Entities.length; eCnt < eLen; eCnt++) {
                    const entity = this.Entities[eCnt];

                    html.push(entity.GetMarkup());
                }

                return html.join('');
            }

            return '';
        }

        public BindEventsTo($container): void {
            $container.off('click')
                .on('click', 'li', (event: Event) => {
                    const element = <HTMLElement>event.currentTarget;
                    const oid = element.getAttribute('data-oid');
                    const fileName = element.getAttribute('data-filename');

                    // Klick Event an Sync-Entities weiterleiten
                    if ((this.Entities || []).length) {
                        const syncEntity = Utils.FindByPredicate(this.Entities, (entity) => {
                            if (oid && entity.hasOwnProperty('OID')) {
                                return entity['OID'] == oid;
                            } else if (fileName && entity.hasOwnProperty('Filename')) {
                                return entity['Filename'] == fileName;
                            }
                        });

                        if (!syncEntity) {
                            return;
                        }

                        if (syncEntity && syncEntity.ClickEvent instanceof Function) {
                            syncEntity.ClickEvent($container, $(element));
                        }
                    }
                });
        }

        public IsEmpty(): boolean {
            return this.Entities.length === 0;
        }

        public IsNotEmpty(): boolean {
            return !this.IsEmpty();
        }

        public GetServiceEntities(): Deferred {
            const deferred = $.Deferred();

            if ((this.RawEntityCollection || []).length) {
                this.getServiceEntities()
                    .then(function(serviceEntities: any[]) {
                        deferred.resolve(serviceEntities);
                    });
            }

            return deferred.promise();
        }
    }

    export class ResponseInformation {
        public EntityType: string | Enums.EntityType;
        public Title: string;
        public RawTimestamp: Date;
        public Timestamp: string;
        public LastModifiedDate: string;
        public RawLastModifiedDate: string | Date;

        constructor(information: Model.Synchronisation.ResponseInformation) {
            this.EntityType = information.EntityType;
            this.Title = this.getEntityTypeTitle(information.EntityType);
            this.RawTimestamp = information.ResponseDate;
            this.Timestamp = Utils.DateTime.ToString(information.ResponseDate);
            this.RawLastModifiedDate = information.LastModifiedDate;
            this.LastModifiedDate = Utils.DateTime.ToString(information.LastModifiedDate);
        }

        private getEntityTypeTitle(type: Enums.EntityType | string): string {
            switch (type) {
                case Enums.EntityType.CONTACTS:
                    return i18next.t('SyncCenter.EntityTypes.Contacts');
                case Enums.EntityType.CONTACTGROUPS:
                    return i18next.t('SyncCenter.EntityTypes.ContactGroups');
                case Enums.EntityType.ELEMENTS:
                    return i18next.t('SyncCenter.EntityTypes.Elements');
                case Enums.EntityType.FILES:
                    return i18next.t('SyncCenter.EntityTypes.Files');
                case Enums.EntityType.ISSUES:
                    return i18next.t('SyncCenter.EntityTypes.Issues');
                case Enums.EntityType.USERS:
                    return i18next.t('SyncCenter.EntityTypes.Users');
                case Enums.EntityType.ROLES:
                    return i18next.t('SyncCenter.EntityTypes.Roles');
                case Enums.EntityType.TEAMS:
                    return i18next.t('SyncCenter.EntityTypes.Teams');
                case Enums.EntityType.PROPERTIES:
                    return i18next.t('SyncCenter.EntityTypes.Properties');
                case Enums.EntityType.RECORDITEMS:
                    return i18next.t('SyncCenter.EntityTypes.Recorditems');
                case Enums.EntityType.SCHEDULING:
                    return i18next.t('SyncCenter.EntityTypes.Scheduling');
                case Enums.EntityType.SCHEMAS:
                    return i18next.t('SyncCenter.EntityTypes.Schemas');
                case Enums.EntityType.CUSTOMMENUITEMS:
                    return i18next.t('SyncCenter.EntityTypes.CustomMenuItems');
                default:
                    // Individualdaten Schema
                    let schema = DAL.Schemas.GetByType(type);

                    if (!schema && type && type.indexOf('schema-') === 0) {
                        // 'schema-' Prefix entfernen und erneut versuchen
                        schema = DAL.Schemas.GetByType(type.substr(7));
                    }

                    return schema ?
                        i18next.t('SyncCenter.EntityTypes.Individual', { EntityType: schema ? schema.NamePlural : type }) :
                        i18next.t('Misc.Unknown');
            }
        }

        public GetMarkup(): string {
            return Templates.SyncCenter.EntityType.ResponseRow({
                EntityType: this.EntityType,
                Title: this.Title,
                Timestamp: this.RawTimestamp <= new Date(2001, 0, 2) ? null : this.Timestamp,
                LastModifiedDate: this.RawLastModifiedDate <= new Date(2001, 0, 2) ? null : this.LastModifiedDate
            });
        }

        public GetMailBodyMarkup(): string {
            return Templates.SyncCenter.SupportMail.ResponseTime({
                Title: this.Title,
                Timestamp: this.RawTimestamp <= new Date(2001, 0, 2) ? null : this.Timestamp,
                LastModifiedDate: this.RawLastModifiedDate <= new Date(2001, 0, 2) ? null : this.LastModifiedDate
            });
        }
    }

    export class ResponseInformationCollection {
        public Information: Array<ResponseInformation>;

        constructor(information: Dictionary<Model.Synchronisation.ResponseInformation>) {
            this.Information = [];

            if (!information) {
                return;
            }

            for (let key in information) {
                this.Information.push(new ResponseInformation(information[key]));
            }

            this.Information.sort((a: ResponseInformation, b: ResponseInformation): number =>
                a.Title.localeCompare(b.Title, undefined, { sensitivity: 'accent' })
            );
        }

        public GetMarkup(): string {
            const html = [];

            if (this.Information.length) {
                for (let iCnt = 0, iLen = this.Information.length; iCnt < iLen; iCnt++) {
                    const information = this.Information[iCnt];

                    html.push(information.GetMarkup());
                }
            }

            return html.join('');
        }

        public GetMailBodyMarkup(): string {
            const html = [];

            if (this.Information.length) {
                for (let iCnt = 0, iLen = this.Information.length; iCnt < iLen; iCnt++) {
                    const information = this.Information[iCnt];

                    html.push(information.GetMailBodyMarkup());
                }
            }

            return html.join('<br>');
        }
    }
}
