//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../app/app.ts" />
//imports-end

module Utils.Analytics {

    const PROPERTY_ID = 'UA-157345811-1';
    //const PROPERTY_ID = 'UA-60815214-1';
    let tracker: New.Analytics.ITracker = null;

    let _isInitialized = false;

    function getTimestampString() {
        return new Date().format('yyyy-mm-dd HH:MM:ss');
    }

    function getExceptionPrefix(exceptionType: Enums.AnalyticsExceptionType) {
        switch (exceptionType) {
            case Enums.AnalyticsExceptionType.Download:
                return 'Sync - Download';
            case Enums.AnalyticsExceptionType.Upload:
                return 'Sync - Upload';
            case Enums.AnalyticsExceptionType.Javascript:
                return 'JS-Error';
            case Enums.AnalyticsExceptionType.DatabaseSave:
                return 'DB - Save';
            case Enums.AnalyticsExceptionType.DatabaseRead:
                return 'DB - Read';
            case Enums.AnalyticsExceptionType.DatabaseDelete:
                return 'DB - Delete';
            case Enums.AnalyticsExceptionType.TakePhoto:
                return 'Taking Photo';
            case Enums.AnalyticsExceptionType.Other:
                return 'Other';
            default:
                return 'Debug';
        }
    }

    function getViewInformation(): string {
        switch (View.CurrentView) {
            case Enums.View.Main:
                return 'Main';
            case Enums.View.Form:
                return 'Form';
            case Enums.View.Scheduling:
                return 'Scheduling';
            case Enums.View.Inspection:
                return 'Inspection';
            case Enums.View.About:
                return 'About';
            case Enums.View.Login:
                return 'Login';
            case Enums.View.SyncCenter:
                return 'SyncCenter';
            default:
                return Enums.View[View.CurrentView];
        }
    }

    function getIssueOID(): string {
        const issue = IssueView.GetCurrentIssue();
        return issue ? issue.OID : 'N/A';
    }

    function getDeviceText(): string {
        /*
            Returns analytics info on current device
        */
        const result = ['['];

        if (Session.IsSmartDeviceApplication) {
            if (window['device']) {
                result.push(window['device'].platform[0]);
                result.push('|');
                result.push(window['device'].version);
            } else if (Session.IsRunningOnIOS) {
                result.push('I|-');
            } else if (Session.IsRunningOnAndroid) {
                result.push('A|-');
            } else {
                result.push('?');
            }
        } else {
            result.push("W");
        }

        result.push("]");

        return result.join('');
    }

    function isEnabled() {
        return tracker && _isInitialized && Session.Settings.UseGoogleAnalytics;
    }

    function init() {
        if (Session.IsSmartDeviceApplication) {
            tracker = new New.Analytics.AppTracker();
        } else {
            tracker = new New.Analytics.WebTracker(PROPERTY_ID);
            tracker.setValue("Device", getDeviceText());
        }

        if (App.DEBUG_ENABLED) {
            tracker.setValue("DEBUG", true);
        }
    }

    export function TrackClientOID(clientOID?: string) {
        if (tracker) {
            clientOID = clientOID || ((Session || { Client: {} }).Client || {}).OID;
            tracker.setValue("ClientOID", clientOID || 'N/A');
        }
    }

    export function TrackView(viewName) {
        if (isEnabled() && !!viewName) {
            tracker.trackView(viewName);
        }
    };

    export function TrackException(exceptionType: Enums.AnalyticsExceptionType, exceptionDescription: Error | string, stackFrames?: New.Analytics.StackTraceLine[]) {
        /*
            log exceptional error
        */
        if (!isEnabled() || !exceptionDescription) {
            return;
        }

        let error: Error;
        if (exceptionDescription instanceof Error) {
            error = <Error>exceptionDescription;
        } else if (exceptionDescription['toString'] != null) {
            error = new Error(exceptionDescription.toString());
        } else {
            error = new Error(JSON.stringify(exceptionDescription));
        }

        // set additional information
        TrackClientOID();
        tracker.setValue("Timestamp", getTimestampString());
        tracker.setValue("Action", getExceptionPrefix(exceptionType));
        tracker.setValue("View", getViewInformation());
        tracker.setValue("Mode", Session.Mode);
        tracker.setValue("IssueID", getIssueOID());

        // resolve by Stacktrace.js if no stackFrames passed
        if (!stackFrames && error instanceof Error && window["StackTrace"] != null) {
            StackTrace.fromError(error)
                .then(function(stackframes: Array<any>) {
                    return $.Deferred().resolve([error, stackframes]).promise();
                }, function() {
                    // stackframes could not be resolved, log original error
                    return $.Deferred().resolve([error, [{ source: error ? error.stack : '' }]]);
                })
                .then(function(args: Array<any>) {
                    const error: Error = args[0];
                    const stackFrames: New.Analytics.StackTraceLine[] = args[1];
                    tracker.trackError(error, stackFrames);
                });
        } else {
            // track error with resolved stackframes
            tracker.trackError(error, stackFrames);
        }
    };

    export function Init() {
        if (!_isInitialized && Session.Settings.UseGoogleAnalytics) {
            init();

            _isInitialized = true;
        }

        if (_isInitialized) {
            TrackClientOID();
        }
    };
}
