//imports-start
/// <reference path="../../dal/elements.ts"  />
/// <reference path="../../model/model.tree.ts"  />
/// <reference path="../../model/model.clearable-input.ts"  />
/// <reference path="../../model/elements/element.ts"  />
/// <reference path="../../model/selection-window/tab.ts"  />
/// <reference path="../../model/selection-window/options.ts"  />
/// <reference path="../../model/selection-window/filter.ts"  />
/// <reference path="../utils.ts"  />
/// <reference path="../utils.element-picker-popup.ts"  />
//imports-end

module Utils {
    export abstract class BaseSelectionWindow<T extends Model.Core.Unique> {
        protected abstract selectionID: string;
        protected abstract keyProperty: string;

        protected tabs: Array<Model.SelectionWindow.Tab>;
        protected options: Model.SelectionWindow.Options;
        protected tree: Model.Tree;
        protected availableItems: Array<T>;
        protected availableItemsDict: Dictionary<T>;
        protected visibleItems: Dictionary<boolean>;
        protected applicableItems: Dictionary<boolean>;
        protected selectedItem: T;
        protected userIsOnline: boolean;
        protected allowDisablingTabs: boolean;

        protected selectedItemIdentifiers: string[];

        protected currentlyHighlightedItem: T;
        protected currentlyHighlightedItemIsApplicable: boolean;

        private $win: any;
        private $tree: any;
        private $treeWrapper: any;
        private $content: any;
        private $tabControl: any;
        private $treeTab: any;
        private $filterRow: any;
        private $btnHierarchyFilter: any;
        private $btnResetSelection: any;
        private $btnCustomAction: any;
        private $btnApply: any;
        private $btnClose: any;
        private $overlay: any;
        private searchInputControl: Model.ClearableInput.Control;
        private activeTab: Model.SelectionWindow.Tab;
        private initialRenderingIsDone: boolean;

        private readonly uniqueWindowIdentifier: string;
        private readonly treeTab: Model.SelectionWindow.Tab;

        protected constructor(options: Model.SelectionWindow.Options) {
            this.options = this.prepareOptions(options);
            this.tabs = [];

            this.treeTab = new Model.SelectionWindow.Tab(
                'tree',
                'screen-max-sm',
                this.options.TreeTabTitle,
                this.showTreeTab.bind(this),
                false
            );

            this.tabs.push(this.treeTab);

            this.uniqueWindowIdentifier = uuid();
            this.selectedItemIdentifiers = this.options.SelectedItems || [];
        }

        public Show(): void {
            if (!Utils.IsSet(this.options)) {
                return;
            }

            Utils.CheckIfDeviceIsOnline()
                .then(this.onAfterCheckIfDeviceIsOnline, this.onAfterCheckIfDeviceIsOnline);
        }

        private onAfterCheckIfDeviceIsOnline = (isOnline: boolean): void => {
            this.userIsOnline = isOnline;

            this.fillWindow(true);
        };

        protected fillWindow(applySingleApplicableItem: boolean = false): void {
            this.prepareItems();
            this.setSelectedItem();

            if (!this.initialRenderingIsDone &&
                this.options.ExitIfNoItemIsAvailable &&
                !Utils.HasProperties(this.applicableItems)) {
                Utils.Message.Show(this.options.NoItemAvailableMessageHeader,
                    this.options.NoItemAvailableMessageBody,
                    {
                        Close: true
                    }, null, this.options.ZIndex + 50);

                return;
            }

            if (applySingleApplicableItem &&
                this.options.OnOnlySingleApplicableItemExists instanceof Function &&
                this.applicableItems && Object.keys(this.applicableItems).length == 1) {
                const firstApplicableItemIdentifier = Object.keys(this.applicableItems)[0];
                let applicableItem: T;

                for (let i = 0; i < this.availableItems.length; i++) {
                    const curItem = this.availableItems[i];

                    if (curItem.OID === firstApplicableItemIdentifier) {
                        applicableItem = curItem;
                        break;
                    }
                }

                if (!applicableItem) {
                    return;
                }

                this.options.OnOnlySingleApplicableItemExists.apply(this, [applicableItem]);
                return;
            }

            this.renderWindow();

            if (this.options.ShowLocationSelection) {
                this.showLocationSelection();
            }

            this.createTree();
            this.bindEvents();

            if (!Utils.IsSet(this.tabs) && this.tabs.length > 0) {
                return
            }

            this.updateTabs(!this.initialRenderingIsDone);

            if (!this.initialRenderingIsDone) {
                this.SetApplyButtonState();
            }

            this.setFilterVisibility();

            this.initialRenderingIsDone = true;
        }

        protected destroy(): void {
            this.unbindEvents();

            if (this.$win) {
                this.$win.remove();
                this.$win = null;

                this.$tree = null;
                this.$treeWrapper = null;
                this.$content = null;
                this.$tabControl = null;
                this.$treeTab = null;
                this.$filterRow = null;
                this.$btnHierarchyFilter = null;
                this.$btnResetSelection = null;
                this.$btnCustomAction = null;
                this.$btnApply = null;
                this.$btnClose = null;
            }

            if (this.$overlay) {
                Utils.Overlay.DestroyWithTimeout(this.$overlay);
                this.$overlay = null;
            }

            this.selectionID = null;
            this.keyProperty = null;

            this.tree = null;

            this.searchInputControl = null;
            this.availableItems = null;
            this.visibleItems = null;
            this.applicableItems = null;
            this.currentlyHighlightedItemIsApplicable = false;
            this.activeTab = null;

            $('body').removeClass('modal-open').css('padding-right', '');
        }

        protected showContent($content?: any): void {
            if (this.options.ShowTabControl && Utils.IsSet(this.$tree)) {
                this.$tree.addClass('screen-min-md');
            }

            if (Utils.IsSet($content) && Utils.IsSet(this.$content)) {
                this.$content.css('display', '');
                this.$content.empty();
                this.$content.append($content);
            }
        }

        protected getTreeRootItemIdentifier(): string {
            return this.options.DefaultItemIdentifier;
        }

        // Only required if tree-filter is visible
        protected onTreeItemFilterClick(): void { }

        protected onTreeItemFilterSaved(filter: Model.SelectionWindow.Filter): void {
            this.options.Filter = filter;

            this.$tree.find('.btn-tree-filter').toggleClass('is-active', filter.AreFiltersActive());
            this.prepareItems();
            this.setSelectedItem();
            this.refreshTree();
            this.updateTabs(true);
        }

        protected abstract prepareOptions(options: Model.SelectionWindow.Options): Model.SelectionWindow.Options;
        protected abstract prepareItems(): void;
        protected abstract getAdditionalCssClasses(location: Model.Elements.Element): Dictionary<Array<string>>;
        protected abstract getDefaultTab(): Model.SelectionWindow.Tab;
        protected abstract getShowHierarchyFilter(): boolean;

        private setSelectedItem(): void {
            if (this.options.EnableMultiSelection) {
                return;
            }

            this.currentlyHighlightedItemIsApplicable = false;

            if (!Utils.IsSet(this.visibleItems)) {
                this.SetApplyButtonState();
                return;
            }

            const identifier = this.selectedItemIdentifiers.length === 1 ?
                this.selectedItemIdentifiers[0] :
                this.getTreeRootItemIdentifier();

            if (this.visibleItems[identifier]) {
                this.currentlyHighlightedItem = this.availableItemsDict[identifier];
                this.currentlyHighlightedItemIsApplicable = !!this.applicableItems[identifier];

                if (this.currentlyHighlightedItemIsApplicable) {
                    this.selectedItemIdentifiers = [identifier];
                }

                this.SetApplyButtonState();
                return;
            }

            if (this.visibleItems[this.options.DefaultItemIdentifier]) {
                this.currentlyHighlightedItem = this.availableItemsDict[this.options.DefaultItemIdentifier];
                this.currentlyHighlightedItemIsApplicable = !!this.applicableItems[this.options.DefaultItemIdentifier];

                if (this.currentlyHighlightedItemIsApplicable) {
                    this.selectedItemIdentifiers = [this.options.DefaultItemIdentifier];
                }

                this.SetApplyButtonState();
                return;
            }

            this.selectedItemIdentifiers = [];

            this.SetApplyButtonState();
        }

        private unbindEvents(): void {
            $(window).off(`resize.${this.uniqueWindowIdentifier}`);
        }

        private bindEvents(): void {
            if (this.initialRenderingIsDone) {
                return;
            }

            this.$win.on('hidden.bs.modal', this.destroy.bind(this));

            if (this.options.ShowResetButton && this.$btnResetSelection) {
                this.$btnResetSelection.on('click', this.onBtnResetSelectionClick.bind(this));
            }

            this.$btnApply.on('click', this.onBtnApplyClick.bind(this));
            this.$btnClose.on('click', this.destroy.bind(this));
            this.$tabControl.on('click', 'li[data-tab]', this.onTabClick.bind(this));

            if (this.$btnHierarchyFilter &&
                this.options.HierarchyFilter &&
                this.options.HierarchyFilter.OnClick instanceof Function) {
                this.$btnHierarchyFilter.on('click', this.options.HierarchyFilter.OnClick.bind(this));
            }

            if (this.$btnCustomAction &&
                this.options.CustomActionButton &&
                this.options.CustomActionButton.OnClick instanceof Function) {
                this.$btnCustomAction.on('click', this.options.CustomActionButton.OnClick.bind(this));
            }

            $(window).on(`resize.${this.uniqueWindowIdentifier}`, this.onResize.bind(this));

            if (this.options.ShowLocationSelection) {
                this.$win.on('click', '.content-header', this.onLocationSelectionClick.bind(this));
            }

            this.$tree.on('click', '.btn-tree-filter', this.onTreeItemFilterClick.bind(this));
        }

        private getTemplateContext(): any {
            if (!Utils.IsSet(this.options)) {
                return {};
            }

            return {
                SelectionID: this.selectionID,
                Title: this.options.Title,
                Tabs: this.tabs,
                TabControlCSSClass: this.options.TabControlCSSClass,
                ShowTreeFilter: this.options.ShowTreeFilter,
                IsTreeFiltered: Utils.IsSet(this.options.Filter) && this.options.Filter.AreFiltersActive(),
                ZIndex: this.options.ZIndex + 1,
                ShowResetButton: this.options.ShowResetButton,
                ShowCustomActionButton: this.options.CustomActionButton != null,
                ShowHierarchyFilter: this.options.HierarchyFilter != null,
                HierarchyFilter: this.options.HierarchyFilter,
                ShowTabControl: this.options.ShowTabControl,
                ShowDetails: this.options.ShowDetails
            }
        }

        private onTreeItemClick(evt: Event): void {
            const $node = $(evt.currentTarget);
            const identifier = $node.data('identifier');

            if ($node.hasClass('disabled')) {
                this.tree.ToggleNodeState(identifier).Build();
                return;
            }

            if (this.options.EnableMultiSelection) {
                this.tree.ToggleNodeSelection(identifier, false);

                if (this.tree.GetIsNodeCollapsed(identifier)) {
                    this.tree
                        .ToggleNodeState(identifier)
                        .Build();
                }
            } else {
                if (this.tree.ActiveItemIdentifier !== identifier) {
                    this.tree.SetActiveItem(identifier);
                } else {
                    this.tree.ToggleNodeState(identifier);
                }
            }

            this.tree.Build();

            const nodeIsSelected = $node.hasClass('selected');
            const clickedItem = this.availableItemsDict[identifier];

            if (!clickedItem) {
                return;
            }

            this.$tabControl.removeClass('disabled');

            if (this.options.EnableMultiSelection) {
                if (nodeIsSelected) {
                    this.selectedItemIdentifiers.push(identifier);
                } else {
                    const idxItemIdentifiers = this.selectedItemIdentifiers.indexOf(identifier);

                    if (idxItemIdentifiers > -1) {
                        this.selectedItemIdentifiers.splice(idxItemIdentifiers, 1);
                    }
                }
            } else {
                this.selectedItemIdentifiers = [identifier];
            }

            this.currentlyHighlightedItem = clickedItem;
            this.currentlyHighlightedItemIsApplicable = !!this.applicableItems[identifier];

            this.SetApplyButtonState();

            if (!this.currentlyHighlightedItemIsApplicable && this.activeTab.ShowOnlyIfItemIsSelectable) {
                this.activeTab = this.getDefaultTab();
            }

            this.updateTabs();
        }

        private onResize(): void {
            if (!(Utils.IsSet(this.activeTab) && this.activeTab.ID === 'tree')) {
                return;
            }

            if (this.$treeTab.is(':visible')) {
                return;
            }

            if (!this.selectedItemIdentifiers.length) {
                return;
            }

            this.activeTab = this.getDefaultTab();

            if (Utils.IsSet(this.activeTab)) {
                this.activeTab.CallbackFunction();
            }

            this.updateVisibleTabStates();
        }

        private filterTreeItem(item: T): boolean {
            return Utils.IsSet(this.visibleItems) && this.visibleItems[item[this.keyProperty]];
        };

        private onLocationSelectionClick(): void {
            Utils.ElementPickerPopup.Show({
                SelectedItemIdentifier: Session.CurrentLocation.OID,
                ShowFloorPlan: true,
                CannotCollapseRoot: true,
                FloorPlanID: 'content-header-location-picker-floor-plan',
                OnConfirmSelection: this.onLocationSelected.bind(this),
                OnBtnStartQRCodeScannerClick: (picker: Utils.ElementPickerPopup) => { this.onBtnFindLocationByQRCodeClick(picker); },
                ZIndex: this.options.ZIndex + 50
            });
        }

        private onTabClick(evt: Event): void {
            if (!Utils.IsSet(evt)) {
                return;
            }

            evt.stopImmediatePropagation();

            const $tab = $(evt.currentTarget);
            const selectedTabID = $tab.data('tab');

            if (!Utils.IsSet(selectedTabID) || $tab.hasClass('selected') || $tab.hasClass('disabled') ||
                $tab.parents('.tab-control').hasClass('disabled')) {
                return;
            }

            const tab = Utils.Where(this.tabs, 'ID', '===', selectedTabID);

            if (!Utils.IsSet(tab)) {
                return;
            }

            $tab.addClass('selected').siblings().removeClass('selected');
            this.activeTab = tab;

            if (Utils.IsFunction(tab.CallbackFunction)) {
                tab.CallbackFunction();
            }
        }

        private onLocationSelected(result: any): void {
            if (!Utils.IsSet(result)) {
                return;
            }

            this.options.Location = DAL.Elements.GetByOID(result.ElementOID) || DAL.Elements.Root;

            Utils.Router.PushState(`location/${this.options.Location.OID}/${Session.Mode}`);

            this.prepareItems();
            this.setSelectedItem();
            this.refreshTree();
            this.showLocationSelection();
            this.updateTabs(true);
            this.setFilterVisibility();
        }

        private onBtnFindLocationByQRCodeClick(picker: Utils.ElementPickerPopup): void {
            Utils.Spinner.Show();
            Utils.StartScanner((result) => {
                picker.Destroy();
                Utils.Spinner.Hide();

                if (!Utils.IsSet(result) || !result.text) {
                    return;
                }

                const element = DAL.Elements.GetByQRCode(result.text);

                if (element) {
                    this.onLocationSelected({
                        ElementOID: result.text
                    });
                } else {
                    Utils.Message.Show(i18next.t('Navigation.ElementUndefined.MessageHeader'),
                        i18next.t('Navigation.ElementUndefined.MessageBody'),
                        {
                            Close: true
                        }, null, this.options.ZIndex + 50);
                }
            });
        }

        private refreshTree(): void {
            this.createTree();
            this.searchInputControl.CallFilterListeners();
        }

        private updateTabs(updateActiveTab: boolean = false): void {
            if (updateActiveTab) {
                if (this.$treeTab.is(':visible')) {
                    this.activeTab = this.treeTab;
                } else {
                    this.activeTab = this.getDefaultTab();
                }
            }

            this.updateVisibleTabStates();

            if (Utils.IsSet(this.activeTab)) {
                this.activeTab.CallbackFunction();
            }
        }

        private updateVisibleTabStates(): void {
            const $tabs = this.$tabControl.find('li[data-tab]');

            for (let tCnt = 0; tCnt < this.tabs.length; tCnt++) {
                const tab = this.tabs[tCnt];
                const $tab = $tabs.filter(`li[data-tab="${tab.ID}"]`);

                $tab.toggleClass('selected', tab === this.activeTab);

                if (!this.allowDisablingTabs) {
                    continue;
                }

                $tab.toggleClass('disabled', !this.currentlyHighlightedItemIsApplicable && tab.ShowOnlyIfItemIsSelectable);
            }
        }

        private createTree(): void {
            const rootIdentifier = this.getTreeRootItemIdentifier();

            this.$tree.find('.tree-wrapper').scrollTop(0);

            this.tree = new Model.Tree()
                .SetIdentifier(`${this.uniqueWindowIdentifier}-item-tree`)
                .SetRootCollapsable(false)
                .SetShowParentTitle(false)
                .SetShowExpanders(true)
                .SetForceExpand(Session.Settings.ExpandTreeInSelectionWindow)
                .SetShowColors(true)
                .SetEnableCheckmarks(true)
                .SetKeyProperty(this.keyProperty)
                .SetFilter(this.filterTreeItem.bind(this))
                .SetAdditionalClasses(this.getAdditionalCssClasses(this.options.Location))
                .SetFnIsDisabled(this.isItemDisabled.bind(this))
                .SetRenderingContainer(this.$win.find('.tree-wrapper'))
                .SetOnNodeClick(this.onTreeItemClick.bind(this));

            if (this.options.EnableMultiSelection) {
                this.tree.SetEnableSelection(true);

                if (this.selectedItemIdentifiers.length) {
                    this.tree.SetSelectedItems($.extend(true, [], this.selectedItemIdentifiers))
                }
            }

            this.tree.SetItems(<Array<any>>this.availableItems, rootIdentifier);

            if (!this.options.EnableMultiSelection) {
                const selectedItemIdentifier = this.selectedItemIdentifiers.length > 0 ?
                    this.selectedItemIdentifiers[0] :
                    null;

                const activeItemIdentifier = !!selectedItemIdentifier && this.applicableItems[selectedItemIdentifier] ?
                    selectedItemIdentifier :
                    rootIdentifier;

                this.tree.SetActiveItem(activeItemIdentifier);
            }

            this.tree
                .SetSearchField(this.searchInputControl, this.options.TreeSearchFieldPlaceholder)
                .Build();
        }

        private isItemDisabled(form: Model.Elements.Element): boolean {
            return !(this.applicableItems[form.OID] || false);
        }

        private renderWindow(): void {
            if (this.initialRenderingIsDone) {
                return;
            }

            this.$win = $(Templates.SelectionWindow.BaseWindow(this.getTemplateContext()));
            this.$content = this.$win.find('.content');
            this.$tabControl = this.$win.find('.tab-control');
            this.$filterRow = this.$win.find('.filter-row');
            this.$btnHierarchyFilter = this.$win.find('.btn-hierarchy-filter');
            this.$btnResetSelection = this.$win.find('.btn-reset');
            this.$btnCustomAction = this.$win.find('.btn-custom-action');
            this.$btnApply = this.$win.find('.btn-apply');
            this.$btnClose = this.$win.find('.btn-abort');
            this.$overlay = Utils.Overlay.Generate('olSelection', this.options.ZIndex, this.destroy);

            const searchPresentation = Session.UserDeviceSettings.SearchResultPresentation;

            const searchInputOptions = new Model.ClearableInput.Settings({
                ID: 'search-input',
                InputType: 'text',
                ShowClearButton: true,
                ShowFilterButton: false,
                ShowPresentationButton: true,
                DefaultPresentation: searchPresentation
            });

            this.$tree = this.$win.find('.item-tree');
            this.$treeWrapper = this.$tree.find('.tree-wrapper');
            this.$treeTab = this.$tabControl.find('li[data-tab="tree"]');
            this.searchInputControl = new Model.ClearableInput.Control(searchInputOptions);
            this.searchInputControl.Build(this.$win.find('.search'));

            if (this.options.CustomActionButton) {
                this.$btnCustomAction.text(this.options.CustomActionButton.Caption);
            }

            $('body').append(this.$win);
            Utils.RepositionModalWindow(this.$win);

            this.$win.find('.modal-body').css('height', this.$win.find('.modal-body').css('max-height'));
            this.$win.find('.modal-dialog').css({
                'max-height': '',
                'margin-top': 0,
                'margin-left': 0
            });

            this.$win.modal({
                show: true,
                backdrop: false
            });
        }

        private setFilterVisibility(): void {
            if (this.getShowHierarchyFilter()) {
                this.$btnHierarchyFilter.toggleClass('hidden', this.options.HierarchyFilter == null);
            } else {
                this.$btnHierarchyFilter.addClass('hidden');
            }

            const $visibleFilterRowChildren = this.$filterRow.children(':not(.hidden)');
            const hideFilterRow = $visibleFilterRowChildren.length === 0;

            this.$filterRow.toggleClass('hidden', hideFilterRow);
            this.$treeWrapper.toggleClass('pull-up', hideFilterRow);
        }

        private showLocationSelection(): void {
            const $locationSelection = Utils.GetDefaultContentHeader(this.options.Location);

            if (this.$win.find('.content-header').length) {
                this.$win.find('.content-header').replaceWith($locationSelection);
            } else {
                this.$win.find('.modal-body').prepend($locationSelection);
            }
        }

        private showTreeTab(): void {
            this.$tree.removeClass('screen-min-md');
            this.$content.css('display', 'none');
        };

        private onBtnResetSelectionClick(evt: Event): void {
            const $button = $(evt.currentTarget);

            if ($button.hasClass('disabled')) {
                return;
            }

            this.selectedItemIdentifiers = [];

            this.options.OnAfterItemsSelected(this.selectedItemIdentifiers);
            this.destroy();
        }

        private onBtnApplyClick(evt: Event): void {
            if (!Utils.IsSet(evt)) {
                return;
            }

            evt.stopImmediatePropagation();

            const $button = $(evt.currentTarget);

            if ($button.hasClass('disabled')) {
                return;
            }

            if (!this.options.EnableMultiSelection) {
                if (this.selectedItemIdentifiers.length === 0 ||
                    !this.currentlyHighlightedItemIsApplicable) {
                    return;
                }
            }

            this.options.OnAfterItemsSelected(this.selectedItemIdentifiers);
            this.destroy();
        };

        public SetApplyButtonState() {
            if (!Utils.IsSet(this.$btnApply)) {
                return;
            }

            if (this.options.EnableMultiSelection) {
                this.$btnApply.removeClass('disabled');
                return;
            }

            this.$btnApply.toggleClass('disabled', !this.selectedItemIdentifiers.length);
        }
    }
}
