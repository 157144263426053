//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../templates.d.ts"  />
/// <reference path="../app.session.ts"  />
/// <reference path="../../model/zebra/Provider.ts" />
//imports-end

module Scanner.ZebraDataWedge {
    const DATAWEDGE_CALLBACK_ID: string = 'ZebraDataWedge_Trial';

    const PROFILE_NAME: string = 'Awenko.QM';

    let SCAN_CALLBACK_STACK: Array<{Callback: (type: string, value: string) => void, KeepUntilRemove: boolean, ID?: string}> = [];
    let isInitialized: boolean = false;
    let provider: Model.Zebra.Provider = null;
    let lastCommandResult: string = '';
    let activeProfileText: string = '';
    let versionString: string;
    let profileConfigured: boolean = false;
    let $container;
    let scanValue: string = '';
    let scanType: string = '';

    function dw63ApiIsAvailable() {
        versionString = '6.3';

        provider.SendCommand('com.symbol.datawedge.api.CREATE_PROFILE', PROFILE_NAME);

        render();
    }

    function dw64ApiIsAvailable() {
        versionString = '6.4';

        const profileConfig = {
            'PROFILE_NAME': PROFILE_NAME,
            'PROFILE_ENABLED': 'true',
            'CONFIG_MODE': 'UPDATE',
            'PLUGIN_CONFIG': {
                'PLUGIN_NAME': 'BARCODE',
                'RESET_CONFIG': 'true',
                'PARAM_LIST': {}
            },
            'APP_LIST': [{
                'PACKAGE_NAME': 'de.awenko.mobile',
                'ACTIVITY_LIST': ['*']
            }]
        };

        provider.SendCommand('com.symbol.datawedge.api.SET_CONFIG', profileConfig);

        const profileConfig2 = {
            'PROFILE_NAME': PROFILE_NAME,
            'PROFILE_ENABLED': 'true',
            'CONFIG_MODE': 'UPDATE',
            'PLUGIN_CONFIG': {
                'PLUGIN_NAME': 'INTENT',
                'RESET_CONFIG': 'true',
                'PARAM_LIST': {
                    'intent_output_enabled': 'true',
                    'intent_action': 'de.awenko.mobile.ACTION',
                    'intent_delivery': '2'
                }
            }
        };

        provider.SendCommand('com.symbol.datawedge.api.SET_CONFIG', profileConfig2);
        profileConfigured = true;

        window.setTimeout(() => {
            provider.SendCommand('com.symbol.datawedge.api.GET_ACTIVE_PROFILE', '');
        }, 1000);

        render();
    }

    function dw65ApiIsAvailable() {
        versionString = i18next.t('CodeScanner.VersionEqualOrHigher', {Version: '6.5'});
        provider.RequestResult(true);

        render();
    }

    function activeProfileChange(activeProfile) {
        activeProfileText = activeProfile;
        render();
    }

    function setLastCommandResult(result) {
        lastCommandResult = result;
        render();
    }

    function afterScan(scanData: any, time: Date) {
        const scannedData = scanData.extras['com.symbol.datawedge.data_string'];
        const scannedType = scanData.extras['com.symbol.datawedge.label_type'];

        const stackLength = SCAN_CALLBACK_STACK.length;
        if (stackLength > 0) {
            let stack = SCAN_CALLBACK_STACK[stackLength - 1];

            if (!stack.KeepUntilRemove) {
                SCAN_CALLBACK_STACK.splice(stackLength - 1, 1);
            }

            if (typeof stack.Callback === 'function') {
                stack.Callback(scannedType, scannedData);
            }
        }
    }

    function onPanelHeadingClick(evt: any): void {
        $(this).parent().toggleClass('collapsed');
    }

    function unbindEvents() {
        RemoveFromCallbackStack(DATAWEDGE_CALLBACK_ID);

        if ($container == null) {
            return;
        }

        $container.find('.panel-heading').off('click');
    }

    function bindEvents() {
        if ($container == null) {
            return;
        }

        unbindEvents();

        $container.find('.panel-heading').on('click', onPanelHeadingClick);

        AddToCallbackStack((type: string, value: string) => {
            scanValue = value;
            scanType = type;
            render();
        }, false, DATAWEDGE_CALLBACK_ID);
    }

    function render() {
        if (Session.Mode !== Enums.Mode.CodeScanner) {
            return;
        }

        $container = Utils.GetContentContainer();

        $container.html(Templates.CodeScanner.Content({
            ScanValue: scanValue,
            ScanType: scanType,
            Configuration: Templates.CodeScanner.ZebraDataWedge({VersionString: versionString, ActiveProfile: activeProfileText, ProfileConfigured: profileConfigured})
        }));

        bindEvents();
    }

    export function Show() {
        if (!IsSupported()) {
            return;
        }

        if (provider == null) {
            Init();
        }

        render();
    }

    export function Hide() {
        unbindEvents();
    }

    export function Init(force: boolean = false) {
        if (!IsSupported()) {
            return;
        }

        if (isInitialized && !force) {
            return;
        }

        versionString = i18next.t('CodeScanner.VersionEqualOrBelow', {Version: '6.2'});

        provider = new Model.Zebra.Provider();

        provider.on('status:dw63ApiIsAvailable', dw63ApiIsAvailable);
        provider.on('status:dw64ApiIsAvailable', dw64ApiIsAvailable);
        provider.on('status:dw65ApiIsAvailable', dw65ApiIsAvailable);
        provider.on('data:activeProfile', activeProfileChange);
        provider.on('data:commandResult', setLastCommandResult);
        provider.on('data:scan', afterScan);

        if (!App.DEBUG_ENABLED_DATAWEDGE) {
            provider.SendCommand('com.symbol.datawedge.api.GET_VERSION_INFO', '');
        }

        isInitialized = true;
    }

    export function IsSupported(locationOID?: string): boolean {
        if (App.DEBUG_ENABLED_DATAWEDGE) {
            return true;
        }

        if (window.device == null || window.device.manufacturer == null || !Session || !Session.IsSmartDeviceApplication) { // || !Session.Client || !Session.Client.Licenses || !Session.Client.Licenses.AllowZebraDataWedge
            return false;
        }

        // noinspection RedundantIfStatementJS
        if (!window.device.manufacturer.toLowerCase().contains('zebra') && !window.device.manufacturer.toLowerCase().contains('motorola solutions')) {
            return false;
        }

        return true;

        //Disabled for Debug / Beta / exhibition until hosting service is updated
        // noinspection UnreachableCodeJS
        locationOID = locationOID || Session.CurrentLocation ? Session.CurrentLocation.OID : DAL.Elements.Root ? DAL.Elements.Root.OID : null;

        if (locationOID === null) {
            return false;
        }

        const availableItems = Utils.GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Settings, locationOID);

        return Utils.InArray(availableItems, Enums.MenuItemID.CodeScanner);
    }

    export function AddToCallbackStack(callback: (type: string, value: string) => void, keepUntilRemove: boolean = false, id?: string) {
        if (keepUntilRemove) {
            if (id == null) {
                return;
            }
        }

        SCAN_CALLBACK_STACK.push({
            Callback: callback,
            KeepUntilRemove: keepUntilRemove,
            ID: id,
        });
    }

    export function RemoveFromCallbackStack(id: string) {
        let indexOf = Utils.GetIndex(SCAN_CALLBACK_STACK, id, 'ID');

        if (indexOf !== -1) {
            SCAN_CALLBACK_STACK.splice(indexOf, 1);
        }
    }

    export function ClearCallbackStack() {
        SCAN_CALLBACK_STACK = [];
    }

    export function GetName() {
        return 'Zebra DataWedge';
    }

    //DEBUG ONLY
    export function SimulateScan (type, code) {
        if (!App.DEBUG_ENABLED) {
            return;
        }

        const extras = {};
        extras['com.symbol.datawedge.data_string'] = code;
        extras['com.symbol.datawedge.label_type'] = type;

        afterScan({extras: extras}, new Date());
    }
}