/// <reference path="./login.base.ts"  />

class AppLogin extends LoginBase {
    private outdatedVersionWindow: Utils.OutdatedVersionWindow;
    private systemData: UserAccount;

    constructor() {
        super();
    }

    protected showDialog(): Deferred {
        let resultDeferred = super.showDialog();
        let preventFocusUsername = false;

        if (this.userData && this.userData.Client) {
            if (this.userData.Client.Licenses.EnableLoginUserSelection && DAL.Users.GetAll().length) {
                preventFocusUsername = true;

                this.$txtUsername
                    .addClass('small-input-field')
                    .attr('readonly', 'readonly')
                    .on('click', () => { this.onUsernameClick(); });

                this.$btnUnlockUsername.removeClass('hidden');
            }

            this.$clientNameRow.removeClass('hidden');
            this.$txtClientName.text(this.userData.Client.CompanyName);

            this.$btnBaseURI.attr('disabled', 'disabled');
            this.$baseUriRow.addClass('hidden');
            this.$btnScanServiceQRCode.attr('disabled', 'disabled');

            // mit Timeout, damit der Anmelde-Dialog den Wechsel überdeckt
            setTimeout(() => {
                App.Init.replaceIcon(App.Init.Enums.Icon.Download, App.Init.Enums.Icon.LoadMemory);
            }, 10);
        } else {
            this.$btnBaseURI.removeAttr('disabled');
            this.$baseUriRow.removeClass('hidden');
            this.$btnScanServiceQRCode.removeAttr('disabled');

            // mit Timeout, damit der Anmelde-Dialog den Wechsel überdeckt
            setTimeout(() => {
                App.Init.replaceIcon(App.Init.Enums.Icon.LoadMemory, App.Init.Enums.Icon.Download);
            }, 10);
        }

        this.$cbRememberMe.parent().addClass('hidden');

        if (!preventFocusUsername && !Session.IsRunningOnIOS) {
            resultDeferred = resultDeferred.then(() => {
                this.$txtUsername.focus();
            });
        }

        return resultDeferred;
    }

    protected checkExistingUser(): Deferred {
        return DAL.GetSmartDeviceUser()
            .then((account: UserAccount, apiVersion: number) => {
                if (!account.Client) {
                    this.userData = null;
                    this.systemData = null;
                    return $.Deferred().reject().promise();
                }

                this.systemData = account;
                this.lastKnownApiVersion = apiVersion;

                if (!account.User) {
                    this.userData = account;
                    // load client user list for selection
                    return DAL.Sync.LoadUsersAndTeams()
                        .then(() => {
                            return $.Deferred().reject().promise();
                        });
                }

                if (apiVersion >= 16 && !this.userHasLoginRight(account)) {
                    return this.showDialog();
                }

                // has user logged in, no need for sync
                return $.Deferred().resolve(account, false).promise();
            });
    }

    protected tryLogin(): Deferred {
        if (!(Session.BaseURI || '').length) {
            return $.Deferred().reject().promise();
        }

        // TODO check for new user within client

        const me = this;

        return super.tryLogin()
            .then(function(account: UserAccount) {
                if (Session.Client && Session.Client.OID !== account.Client.OID) {
                    Utils.Message.Show(i18next.t('Login.WrongClient.MessageHeader'),
                        i18next.t('Login.WrongClient.MessageBody', {
                            ClientTitle: Session.Client.Name
                        }),
                        {
                            Close: true
                        });

                    me.$btnLogin.removeClass('busy').removeAttr('disabled');
                    me.$txtUsername.removeAttr('disabled');
                    me.$txtPassword.removeAttr('disabled');

                    return $.Deferred().reject();
                }

                return this;
            }, () => {
                me.$btnLogin.removeClass('busy').removeAttr('disabled');
                me.$txtUsername.removeAttr('disabled');
                me.$txtPassword.removeAttr('disabled');
            });
    }

    protected bindEvents(): void {
        super.bindEvents();
        this.$btnUnlockUsername.on('click', (evt: MouseEvent) => { this.onBtnUnlockUsernameClick(evt); });
        this.$btnScanServiceQRCode.on('click', () => { this.onBtnScanServiceQRCode(); });
        this.$btnBaseURI.on('click', (evt: MouseEvent) => { this.onBtnBaseURIClick(evt); });
    }

    protected unbindEvents(): void {
        super.unbindEvents();
        this.$btnUnlockUsername.off('click');
        this.$btnScanServiceQRCode.off('click');
        this.$btnBaseURI.off('click');
    }

    protected onUsernameClick(): void {
        Utils.UserPicker.Show({
            MaximumSelectionCount: 1,
            AllowUsers: true,
            ShowSystemUsers: false,
            ShowLockedUsers: false
        }, null, null, (selection) => {
            this.onAfterUserSelected(selection);
        });
    }

    protected onAfterUserSelected(selectedItems): void {
        if (!(selectedItems.Users || []).length) {
            return;
        }

        let user = DAL.Users.GetByOID(selectedItems.Users[0]);

        if (user) {
            this.$txtUsername.val(user.Username);
            if (!Session.IsRunningOnIOS) {
                this.$txtPassword.focus();
            }
        }
    }

    protected onBtnBaseURIClick(evt: Event): void {
        if ($(evt.currentTarget).attr('disabled')) {
            return;
        }

        Utils.InputWindow.Show(i18next.t('Login.BaseURIPrompt.MessageHeader'), null, {
            Abort: {},
            OK: {
                Fn: this.updateBaseUri
            }
        }, Session.BaseURI, 'url');
    }

    protected onBtnScanServiceQRCode(): void {
        if ($(this).attr('disabled')) {
            return;
        }

        Utils.StartScanner((result) => {
            this.updateBaseUri(result.text);
        });
    }

    protected updateBaseUri(baseURI: string): void {
        if (!!baseURI) {
            if (!/^https?/.test(baseURI)) {
                baseURI = 'http://' + $.trim(baseURI);
            }

            if (!/\/$/.test(baseURI)) {
                baseURI += '/';
            }

            Session.BaseURI = baseURI;
        }
    }

    protected onBtnUnlockUsernameClick(evt: MouseEvent): void {
        let $this = $(evt.currentTarget);
        let $input = $this.siblings('input[type="text"]');

        $this.remove();

        $input
            .removeClass('small-input-field')
            .removeAttr('readonly')
            .off('click')
            .focus();
    }

    protected OnSuccess(account: UserAccount, syncFromServer: boolean): Deferred {
        if (syncFromServer) {
            // check for min app version, cancel login
            if (this.checkIfAppUpdateIsRequired(account, syncFromServer)) {
                return $.Deferred().reject().promise();
            }
        }

        // save account data to database
        account.User.AuthHash = Session.AuthHash;
        account.BaseURI = Session.BaseURI;

        return this.hideDialog()
            .then(() => {
                const needResync = this.systemData &&
                    !!this.systemData.LastLoggedInUserIdentifier &&
                    this.systemData.LastLoggedInUserIdentifier !== account.User.OID;

                if (this.successDeferred) {
                    this.successDeferred.resolve(account, syncFromServer, this.lastKnownApiVersion, needResync);
                }

                return $.Deferred().resolve(account, syncFromServer, this.lastKnownApiVersion, needResync).promise();
            });
    }

    private checkIfAppUpdateIsRequired(account: UserAccount, isNewLogin: boolean = false): boolean {
        // TODO test method
        if (!account.Client.Settings) {
            this.HideOutdatedVersionWindow();
            return false;
        }

        const minAppVersion = Session.IsRunningOnAndroid ? account.Client.Settings.MinAppVersionAndroid : account.Client.Settings.MinAppVersionIOS;

        if (!Utils.IsSet(minAppVersion) || minAppVersion === 'none') {
            this.HideOutdatedVersionWindow();
            return false;
        }

        const newerVersion = Utils.CompareVersion(App.GetVersionString(), minAppVersion);

        if (newerVersion !== -1) {
            this.HideOutdatedVersionWindow();
            return false;
        }

        //Dialog already exists
        if (Utils.IsSet(this.outdatedVersionWindow)) {
            this.outdatedVersionWindow.SetMinVersion(minAppVersion);

            if (!this.outdatedVersionWindow.IsVisible()) {
                this.outdatedVersionWindow.Show();
            }
        } else {
            this.outdatedVersionWindow = new Utils.OutdatedVersionWindow(minAppVersion, isNewLogin);
            this.outdatedVersionWindow.Show();
        }

        return true;
    }

    private HideOutdatedVersionWindow() {
        if (Utils.IsSet(this.outdatedVersionWindow)) {
            this.outdatedVersionWindow.Hide();
        }
    }

    protected onCloseErrorMessage() {
        if (!Session.IsRunningOnIOS) {
            this.$txtPassword.focus();
        }
    }

    protected beforeSessionStart(username: string, password: string): Deferred {
        const deferred = $.Deferred();

        if (!username || !username.trim() || !password || !password.trim()) {
            return deferred.reject().promise();
        }

        const loginData: Model.LoginData = {
            Username: username,
            Password: password,
            Remember: true,
            Application: 'App'
        };

        Utils.Http.Post('login', loginData, null, true, null, Utils.Http.TIMEOUT_MS_SHORT, false)
            .then(
                (result, status, xhr) => {
                    Session.ActiveSession = true;

                    const apiVersion: number = parseInt(Utils.Http.GetResponseHeader(xhr, 'api-version', '1'), 10);

                    this.lastKnownApiVersion = apiVersion;

                    if (apiVersion < 21) {
                        Session.AuthHash = this.getHash(username, password);

                        deferred.resolve();
                        return;
                    }

                    return Session.SetCookieConfig(xhr, false)
                        .then(deferred.resolve, deferred.reject);
                },
                (xhr) => {
                    const apiVersion: number = parseInt(Utils.Http.GetResponseHeader(xhr, 'api-version', '1'), 10);

                    this.lastKnownApiVersion = apiVersion;

                    if (apiVersion >= 21) {
                        deferred.reject(xhr);
                        return;
                    }

                    Session.AuthHash = this.getHash(username, password);

                    deferred.resolve();
                }
            );

        return deferred.promise();
    }
}
