//imports-start
/// <reference path="../definitions.d.ts" />
/// <reference path="../enums.ts" />
/// <reference path="../model/elements/evaluation.ts" />
//imports-end

module Utils.Evaluation {
    export enum OperatorType {
        Equal = 0,
        Unequal = 1,
        Less = 2,
        LessOrEqual = 3,
        Greater = 4,
        GreaterOrEqual = 5
    };

    export enum LinkingType {
        And = 0,
        Or = 1
    };

    export enum TristateValueType {
        True = 1,
        False = 2,
        Unrecorded = 3
    };

    export enum DateComparisonType {
        Days = 0,
        Months = 1,
        Years = 2
    };

    export enum TimeComparisonType {
        Seconds = 0,
        Minutes = 1,
        Hours = 2
    };
}