//imports-start
/// <reference path="./ifiltered-tree-cache.ts"  />
//imports-end
module Model.TreeCache {
    export class FilteredTreeCache extends TreeCache implements Model.TreeCache.IFilteredTreeCache {
        private parentCache: ITreeCache;
        private subFilter: Model.Issues.IFilter;

        constructor(name: string, parentCache: ITreeCache, subFilter: Model.Issues.IFilter) {
            super(name);
            this.parentCache = parentCache;
            this.subFilter = subFilter;
        }

        public setIssue(issue: Model.Issues.RawIssue): boolean {
            /*
            * save issues in cache
            */
            if (this.subFilter && DAL.Issues.FilterIssue(this.subFilter, issue)) {
                return false;
            }

            return super.setIssue(issue);
        }

        protected isFilterChangeRelevant(_newFilter: Model.Issues.IFilter): boolean {
            // parent tree should have done sthe check already, skip it here
            return true;
        }

        public getName(): string {
            return this.name;
        }

        public destroy(): boolean {
            return this.parentCache.removeSubInstance(this);
        }
    }
}
