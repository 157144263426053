//imports-start
/// <reference path="./right.ts"  />
/// <reference path="./recording-ui-right.ts"  />
/// <reference path="../core/revision.ts"  />
//imports-end

module Model.Roles {
    export class Role extends Model.Core.Revision {
        Title: string;
        Description: string;
        HideDisabledFieldsInIssueViewer: boolean;
        Identcode: string;
        IsSystemRole: boolean;
        Rights: Array<Right>;
        RecordingUIRights: Array<RecordingUiRight>;
    }
}
