//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../model/files/raw-file.ts"  />
/// <reference path="../model/files/file.ts"  />
//imports-end


module DAL.Files {
    let _files: Dictionary<Model.Files.File>;

    const _videoFileExtensions = [
        '.3g2', '.3gp', '.3gpp', '.amv', '.asf', '.avi', '.drv', '.flv', '.m4v', '.mov', '.qt', '.mp4',
        '.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.ogv', '.ogg', '.rm', '.webm', '.wmv', '.yuv', '.mkv'
    ];

    export function GetVideoFileExtensions(): Array<string> {
        return _videoFileExtensions;
    }

    function prepareFile(file: Model.Files.RawFile): Model.Files.RawFile {
        file.Title = Utils.EscapeHTMLEntities(file.Title);

        if (!!file.Description) {
            file.Description = Utils.EscapeHTMLEntities(file.Description);
        }

        return file;
    }

    export function Store(files: Array<Model.Files.RawFile>): void {
        if (!_files) {
            _files = {};
        }

        if (!(files || []).length) {
            return;
        }
        
        for (let fCnt = 0, fLen = files.length; fCnt < fLen; fCnt++) {
            const file = prepareFile(files[fCnt]);

            if (file.Deleted) {
                delete _files[file.OID];
            } else {
                _files[file.OID] = new Model.Files.File(file);
            }
        }
    }

    export function Exists(identifier: string): boolean {
        if (!_files) {
            return false;
        }

        return _files.hasOwnProperty(identifier);
    }

    export function GetByOID(identifier: string): Model.Files.File {
        if (!identifier || !_files) {
            return null;
        }

        return _files[identifier];
    }

    export function GetByFilename(filename: string): Model.Files.File {
        if (!filename || !_files) {
            return null;
        }

        for (let oid in _files) {
            let file = _files[oid];

            if (file.Filename === filename) {
                return file;
            }
        }

        return null;
    }

    export function Clear(): void {
        _files = null;
    }
}