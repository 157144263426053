/// <reference path="../definitions.d.ts"  />

module Utils.SupportMail {
    const supportMailAddress: string = 'support@awenko.de';
    const supportMailSubject: string = 'Support-Mail awenko:360-App';

    let responseInformationCollection: Model.SyncCenter.ResponseInformationCollection;
    let lastKnownHttpError: Model.Synchronisation.HttpError;
    let errorMessage: string;

    export function Show(lastHttpError?: Model.Synchronisation.HttpError, error?: string): Deferred {
        // TODO [future] pass error as Error with full stack trace
        lastKnownHttpError = lastHttpError;

        if (error != null) {
            errorMessage = error;
        }

        if (Session.IsRunningOnAndroid) {
            return CheckIfContactsPermissionIsGranted()
                .then(onContactsPermissionGranted, requestEmailPermission);

        } else {
            return Utils.IsPermissionGranted(cordova.plugins.permissions.GET_ACCOUNTS)
                .then(onContactsPermissionGranted, requestContactsPermission);
        }
    }

    function CheckIfContactsPermissionIsGranted(): Deferred {
        const $deferred = $.Deferred();

        cordova.plugins.email.hasPermission(cordova.plugins.email.permission.GET_ACCOUNTS, function(permissionGranted: boolean) {
            if (permissionGranted) {
                $deferred.resolve();
            } else {
                $deferred.reject();
            }
        });

        return $deferred.promise();
    }

    function requestEmailPermission(): Deferred {
        const deferred: Deferred = $.Deferred();

        cordova.plugins.email.requestPermission(
            cordova.plugins.email.permission.GET_ACCOUNTS,
            () => {
                onContactsPermissionGranted()
                    .then(deferred.resolve, deferred.reject);
            }
        );

        return deferred;
    }

    function onContactsPermissionGranted(): Deferred {
        const deferred: Deferred = $.Deferred();

        cordova.plugins.email.hasClient('mailto:', function(emailsSupported: boolean) {
            if (!emailsSupported) {
                Utils.Message.Show(i18next.t('SyncCenter.EMailComposerError.MessageHeader'),
                    i18next.t('SyncCenter.EMailComposerError.MessageBody'),
                    {
                        Close: true
                    })
                    .then(deferred.resolve, deferred.reject);
            }

            const listItems = [{
                Title: i18next.t('SyncCenter.SupportMail.Database'),
                PropertyValue: 'database'
            }, {
                Title: i18next.t('SyncCenter.SupportMail.UnsyncedRecordingData'),
                PropertyValue: 'unsynced-data'
            }, {
                Title: i18next.t('SyncCenter.SupportMail.ErrorLog'),
                PropertyValue: 'error-log'
            }, {
                Title: i18next.t('SyncCenter.SupportMail.SyncLog'),
                PropertyValue: 'sync-log'
            }, {
                Title: i18next.t('SyncCenter.SupportMail.DataStructure'),
                PropertyValue: 'data-structure'
            }, {
                Title: i18next.t('SyncCenter.SupportMail.SelectStores.ButtonCaption'),
                PropertyValue: 'select-stores'
            }];

            if (emailsSupported && errorMessage) {
                listItems.unshift({
                    Title: i18next.t('SyncCenter.SupportMail.ErrorMessageOnly'),
                    PropertyValue: 'error-message'
                });
            }

            Utils.CustomDataPicker.Show(listItems, {
                Title: i18next.t('SyncCenter.SupportMail.WindowTitle'),
                Width: 330,
                HideResetButton: true,
                HideConfirmationButtons: true,
                OnItemClick: $btn => { onSupportMailSelectionButtonClick($btn, emailsSupported).then(deferred.resolve, deferred.reject) },
                Callback: () => { deferred.resolve(); },
                OnAbort: () => { deferred.resolve(); }
            });
        });

        return deferred;
    }

    function requestContactsPermission(): Deferred {
        return Utils.RequestPermission(cordova.plugins.permissions.GET_ACCOUNTS)
            .then(onContactsPermissionGranted);
    }

    function onSupportMailSelectionButtonClick($btn, emailsSupported: boolean): Deferred {
        Utils.CustomDataPicker.Destroy();

        switch ($btn.data('propertyvalue')) {
            case 'error-message':
                return sendMail({
                    to: [supportMailAddress],
                    subject: supportMailSubject,
                    body: createSupportMailBody(),
                    isHtml: true
                });

            case 'database':
                if ((<any>window.Database).GetZippedDbFile != null) {
                    Utils.Spinner.Show(i18next.t('SyncCenter.ExportDatabase.CompressDatabase'));
                    return (<any>window.Database).GetZippedDbFile()
                        .always(Utils.Spinner.Hide)
                        .then((result: { filename: string, size: number }) => {
                            const sizeMB = (result.size / (1024 * 1024)).toFixed(2); // calculate size to MB
                            return Utils.Message.Show(i18next.t('SyncCenter.ExportDatabase.SupportDatabase'),
                                i18next.t('SyncCenter.ExportDatabase.AskForSaveOrSend', { size: sizeMB }), {
                                    Save: function() {
                                        downloadLocalFile(result.filename, 'application/zip')
                                            .then(null, () => {
                                                Utils.Message.Show(i18next.t('SyncCenter.ExportDatabase.SupportDatabase'),
                                                    i18next.t('SyncCenter.ExportDatabase.MissingFilesApp'), { OK: true }
                                                );
                                            });
                                    },
                                    SendEMail: {
                                        Fn: function() {
                                            sendMail({
                                                to: [supportMailAddress],
                                                subject: supportMailSubject,
                                                body: createSupportMailBody(),
                                                attachments: [result.filename],
                                                isHtml: true
                                            });
                                        },
                                        Caption: i18next.t('SyncCenter.ExportDatabase.Send'),
                                        Classes: !emailsSupported ? ['hidden'] : null
                                    }
                                });
                        }, (err: string) => {
                            return Utils.Message.Show(i18next.t('SyncCenter.Error.Database'),
                                err, {
                                    OK: true
                                });
                        });
                }
                break;

            case 'unsynced-data':
                Utils.Spinner.Show();
                let unsyncedEntitiesDeferred: Deferred;
                return Login.LoadSynchronisationInformation(false)
                    .then(function(information: Dictionary<Model.Synchronisation.ResponseInformation>) {
                        responseInformationCollection = new Model.SyncCenter.ResponseInformationCollection(information);
                        unsyncedEntitiesDeferred = getUnsyncedEntities();

                        return createSupportMailFile(unsyncedEntitiesDeferred);
                    })
                    .then((supportMailFilename: string) => getMailAttachments(supportMailFilename, unsyncedEntitiesDeferred))
                    .then(function(supportMailAttachments: string[], supportMailFilename: string) {
                        Utils.Spinner.HideWithTimeout();
                        // Auswahl für Datei-Download vs Email
                        return deliverFiles('UnsyncedRecordingData', emailsSupported, supportMailAttachments, supportMailFilename);
                    });

            case 'data-structure':
                Utils.Spinner.Show();
                return createSupportMailFilesFromWholeDatabase()
                    .then((filenames: string[]) => {
                        Utils.Spinner.HideWithTimeout();
                        deliverFiles('DataStructure', emailsSupported, filenames)
                    });

            case 'select-stores':
                return onSelectObjectStoresClick(emailsSupported);

            case 'error-log':
                Utils.Spinner.Show();
                return createSupportMailErrorLogFile()
                    .then(function(filenames: string[]) {
                        // Fehler logs anhängen
                        return Utils.GetLogFiles()
                            .then((files: any[]) => {
                                // nach neuesten zuerst sortieren
                                files.sort((f1, f2) => f2.name.localeCompare(f1.name, undefined, { sensitivity: 'accent' }));

                                // max. die neusten 10 Log-Dateien anhängen
                                for (let i = 0; i < files.length && i < 10; i++) {
                                    const file = files[i];
                                    filenames.push(file.nativeURL);
                                }
                            }, () => $.Deferred().resolve())
                            .then(() => {
                                Utils.Spinner.HideWithTimeout();
                                // Email mit Fehlerlog verschicken
                                return deliverFiles('ErrorLog', emailsSupported, filenames);
                            });
                    });

            case 'sync-log':
                Utils.Spinner.Show();
                return createSupportMailSyncLogFile()
                    .then((filenames: string[]) => {
                        Utils.Spinner.HideWithTimeout();
                        return deliverFiles('SyncLog', emailsSupported, filenames);
                    });
        }

        return $.Deferred().resolve();
    }

    function onSelectObjectStoresClick(emailsSupported: boolean): Deferred {
        const listItems = [{
            Title: i18next.t('SyncCenter.EntityTypes.Contacts'),
            PropertyValue: Enums.DatabaseStorage.Contacts
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.ContactGroups'),
            PropertyValue: Enums.DatabaseStorage.ContactGroups
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.Elements'),
            PropertyValue: Enums.DatabaseStorage.Elements
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.Files'),
            PropertyValue: Enums.DatabaseStorage.Files
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.Issues'),
            PropertyValue: Enums.DatabaseStorage.Issues
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.Users'),
            PropertyValue: Enums.DatabaseStorage.Persons
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.Roles'),
            PropertyValue: Enums.DatabaseStorage.Roles
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.Teams'),
            PropertyValue: Enums.DatabaseStorage.Teams
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.Properties'),
            PropertyValue: Enums.DatabaseStorage.Properties
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.Recorditems'),
            PropertyValue: Enums.DatabaseStorage.Recorditems
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.Scheduling'),
            PropertyValue: Enums.DatabaseStorage.Scheduling
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.Schemas'),
            PropertyValue: Enums.DatabaseStorage.Schemas
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.IndividualGeneral'),
            PropertyValue: Enums.DatabaseStorage.IndividualData
        }, {
            Title: i18next.t('SyncCenter.EntityTypes.SyncLog'),
            PropertyValue: Enums.DatabaseStorage.SyncLog
        }];

        listItems.sort(Utils.SortByTitle);

        const deferred: Deferred = $.Deferred();

        Utils.CustomDataPicker.Show(listItems, {
            Title: i18next.t('SyncCenter.SupportMail.SelectStores.WindowTitle'),
            Width: 330,
            HideResetButton: true,
            IsMultiSelectionAllowed: true,
            Callback: function(selectedItems) {
                if (!(selectedItems || []).length) {
                    deferred.resolve();
                    return;
                }

                Utils.Spinner.Show();

                const storageNames = [];

                for (let cnt = 0, len = selectedItems.length; cnt < len; cnt++) {
                    const selectedItem = selectedItems.eq(cnt);

                    storageNames.push(selectedItem.data('propertyvalue'));
                }

                onAfterObjectStoresSelected(storageNames)
                    .then((filenames: string[]) => {
                        Utils.Spinner.HideWithTimeout();
                        deliverFiles('SelectStores.WindowTitle', emailsSupported, filenames);
                    })
                    .then(deferred.resolve, deferred.reject);
            },
            OnAbort: () => { deferred.resolve(); }
        });

        return deferred;
    }

    function getPredefinedFilename(prefix?: string) {
        let fileName: string;
        if (prefix && prefix.length) {
            fileName = `${prefix}_${Session.Client.Name}-${Session.User.Username}.txt`
        } else {
            fileName = `${Session.Client.Name}-${Session.User.Username}.txt`
        }

        // alle ungültigen zeichen entfernen
        return fileName.replace(/[\s]/gi, '_').replace(/[^a-zA-Z0-9_\-\.]/gi, '');
    }

    function onAfterObjectStoresSelected(storageNames: Array<Enums.DatabaseStorage>): Deferred {
        if (!(storageNames || []).length) {
            return $.Deferred().reject();
        }

        const filenames = [];

        return (function load(idx): Deferred {
            const storageName = storageNames[idx];
            const filename = getPredefinedFilename(storageName);

            return window.Database.GetAllFromStorage(storageName)
                .then(function(storageData) {
                    return Utils.CreateFile(filename, storageData);
                })
                .then(function() {
                    filenames.push(Utils.GetResourcesPath() + filename);

                    if (idx < storageNames.length - 1) {
                        return load(++idx);
                    } else {
                        return filenames;
                    }
                });
        })(0);
    }

    function createSupportMailFile(unsyncedEntities?: Deferred): Deferred {
        const filename = getPredefinedFilename('unsynchronized-content');

        return (unsyncedEntities || getUnsyncedEntities())
            .then(function(entityCollection: Model.SyncCenter.EntityCollection) {
                if (entityCollection == null || !(entityCollection instanceof Model.SyncCenter.EntityCollection) || entityCollection.IsEmpty()) {
                    return;
                }

                return entityCollection
                    .GetServiceEntities()
                    .then(function(serviceEntities: Model.Synchronisation.IEntityDescription[]) {
                        return Utils.CreateFile(filename, serviceEntities)
                    })
                    .then(() => {
                        return filename;
                    });
            });
    }

    function getUnsyncedEntities(): Deferred {
        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.SyncEntities)
            .then(function(syncEntities: Array<Model.Synchronisation.IEntityDescription>) {
                if ((syncEntities || []).length) {
                    for (let seCnt = 0, seLen = syncEntities.length; seCnt < seLen; seCnt++) {
                        syncEntities[seCnt] = prepareEntityInformation(syncEntities[seCnt]);
                    }

                    const deferred = $.Deferred();

                    new Model.SyncCenter.EntityCollection(syncEntities, { EditModeIsActive: false }, function(entityCollection) {/* jshint ignore:line */
                        deferred.resolve(entityCollection);
                    });

                    return deferred.promise();
                }
            });
    }

    function prepareEntityInformation(entity: Model.Synchronisation.IEntityDescription): Model.Synchronisation.IEntityDescription {
        if (typeof entity.Timestamp === 'string') {
            entity.Timestamp = <any>new Date(entity.Timestamp);
        }

        return entity;
    }

    function createSupportMailFilesFromWholeDatabase(): Deferred {
        return window.Database.LoadEverything()
            .then(function(data) {
                const storageNames = $.map(Enums.DatabaseStorage, (name) => name);
                const filenames = [];

                return (function load(idx) {
                    const storageName = storageNames[idx];
                    const filename = getPredefinedFilename(storageName);

                    // next step - goto next type or return data
                    const next = function() {
                        if (idx < storageNames.length - 1) {
                            return load(++idx);
                        } else {
                            return filenames;
                        }
                    };

                    if (!data.hasOwnProperty(storageName)) {
                        return next();
                    }

                    const storageData = data[storageName];
                    if (storageName === Enums.DatabaseStorage.SystemData && storageData) {
                        for (const storedAccount of storageData) {
                            if (storedAccount.hasOwnProperty('User')) {
                                delete storedAccount.User.AuthHash;
                            }
                        }
                    }

                    return Utils.CreateFile(filename, storageData)
                        .then(function() {
                            filenames.push(Utils.GetResourcesPath() + filename);
                            return next();
                        }, () => {
                            return next();
                        });
                })(0);
            });
    }

    function createSupportMailErrorLogFile(): Deferred {
        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.ErrorLog)
            .then(function(logEntries: any[]) {
                const filename = getPredefinedFilename(Enums.DatabaseStorage.ErrorLog);
                return Utils.CreateFile(filename, logEntries)
                    .then(() => [Utils.GetResourcesPath() + filename]);
            });
    }

    function createSupportMailSyncLogFile(): Deferred {
        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.SyncLog)
            .then(function(log) {
                const filename = getPredefinedFilename(Enums.DatabaseStorage.SyncLog);

                return Utils.CreateFile(filename, log)
                    .then(() => [Utils.GetResourcesPath() + filename]);
            });
    }

    function createSupportMailBody(): string {
        const now = new Date();
        let timezone: any = now.getTimezoneOffset() * -1;

        timezone = timezone ? timezone / 60 : 0;

        if (timezone > 0) {
            timezone = '+' + timezone;
        }

        let lastKnownHttpErrorMarkup: string;

        if (lastKnownHttpError) {
            const errorMessage = SyncCenter.GetStatusCodeMessage(lastKnownHttpError.StatusCode, lastKnownHttpError.Message);
            lastKnownHttpErrorMarkup = Templates.SyncCenter.SupportMail.LastKnownHttpError({
                HttpMethod: lastKnownHttpError.HttpMethod,
                StatusCode: lastKnownHttpError.StatusCode,
                Message: errorMessage,
                EntityType: lastKnownHttpError.EntityType,
                EntityOID: lastKnownHttpError.EntityOID,
                Timestamp: lastKnownHttpError.Timestamp
            });
        }

        const responseTimestampsMarkup: string = responseInformationCollection ?
            responseInformationCollection.GetMailBodyMarkup() :
            null;

        return Templates.SyncCenter.SupportMail.Mail({
            AppVersion: App.GetVersionString(),
            Device: window.device,
            ConnectionType: navigator.connection.type,
            ServiceURI: Session.BaseURI,
            APIVersion: Session.LastKnownAPIVersion,
            Client: Session.Client,
            User: Session.User,
            DeviceTime: Utils.DateTime.ToString(now, true),
            LastKnownHttpErrorMarkup: lastKnownHttpErrorMarkup,
            ResponseTimestampsMarkup: responseTimestampsMarkup,
            Timezone: timezone,
            ErrorMessage: errorMessage
        });
    }

    function getMailAttachments(supportMailFilename: string, unsyncedEntities: Deferred): Deferred {
        return (unsyncedEntities || getUnsyncedEntities())
            .then(function(unsyncedEntitiesCollection: Model.SyncCenter.EntityCollection) {
                const files = [];

                if (unsyncedEntitiesCollection instanceof Model.SyncCenter.EntityCollection && unsyncedEntitiesCollection.IsNotEmpty()) {
                    for (let eCnt = 0, eLen = unsyncedEntitiesCollection.Entities.length; eCnt < eLen; eCnt++) {
                        const entity = unsyncedEntitiesCollection.Entities[eCnt];

                        if (entity.hasOwnProperty('FilePath')) {
                            files.push((<Model.SyncCenter.FileEntity>entity).FilePath);
                        }
                    }
                }

                if (!!supportMailFilename) {
                    files.push(Utils.GetResourcesPath() + supportMailFilename);
                }

                return $.Deferred().resolve(files, supportMailFilename);
            });
    }

    function deliverFiles(dataType: string, emailsSupported: boolean, fileNames: string[], targetFile?: string): Deferred {
        const result = $.Deferred();
        const text = i18next.t('SyncCenter.SupportMail.AskForSaveOrSend');
        let title = i18next.t(`SyncCenter.SupportMail.${dataType}`);
        title = title[0].toUpperCase() + title.substring(1, title.length);;

        if (emailsSupported) {
            // Auswahl für Datei-Download oder Email
            Utils.Message.Show(title, text, {
                Save: function() {
                    result.resolve('save');
                },
                SendEMail: {
                    Fn: function() {
                        result.resolve('email');
                    },
                    Caption: i18next.t('SyncCenter.ExportDatabase.Send'),
                    Classes: !emailsSupported ? ['hidden'] : null
                },
                OnHide: function() {
                    result.resolve('close');
                }
            });
        } else {
            result.resolve('save');
        }

        return result.then((type: string) => {
            switch (type) {
                case 'email':
                    // Als Email senden
                    Utils.Spinner.Show();

                    return sendMail({
                        to: [supportMailAddress],
                        subject: supportMailSubject,
                        body: createSupportMailBody(),
                        attachments: fileNames,
                        isHtml: true
                    });

                case 'save':
                    // Lokal als Zip speichern
                    Utils.Spinner.Show();

                    targetFile = targetFile || getPredefinedFilename(dataType);
                    const zipOutputFile = `${Utils.GetResourcesPath()}${targetFile}.zip`;

                    return Utils.ZipFiles(zipOutputFile, fileNames)
                        .then(() => downloadLocalFile(zipOutputFile, 'application/zip'))
                        .then(null, () => {
                            if (Session.IsRunningOnAndroid) {
                                // Versuche Datei in 'Download' Ordner zu verschieben
                                return Utils.MoveFile(zipOutputFile, cordova.file.externalRootDirectory + 'Download/')
                                    .then(() => {
                                        Utils.Spinner.Hide();

                                        Utils.Message.Show(title,
                                            i18next.t('SyncCenter.SupportMail.FileMovedToDownload', { filename: targetFile + '.zip' }),
                                            { OK: true }
                                        );
                                    });
                            }

                            return $.Deferred().reject();
                        })
                        .then(null, () => {
                            Utils.Spinner.Hide();

                            Utils.Message.Show(title,
                                i18next.t('SyncCenter.SupportMail.MissingFilesApp'), { OK: true }
                            );
                        })
                        .then(() => Utils.DeleteFile(zipOutputFile));
            }
        }).always(() => Utils.Spinner.Hide());
    }

    function sendMail(options): Deferred {
        const deferred: Deferred = $.Deferred();

        cordova.plugins.email.open(options,
            function() {
                deferred.resolve();
            });

        return deferred;
    }

    function downloadLocalFile(targetFileName: string, mimeType: string): Deferred {
        const deferred: Deferred = $.Deferred();

        cordova.plugins.fileOpener2.open(targetFileName, mimeType, {
            error: (err: { status: string, message: string }) => {
                deferred.reject(err);
            },
            success: function() {
                deferred.resolve();
            }
        });

        return deferred;
    }
}
