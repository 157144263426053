
module Model.Scheduling {
    // Ab API Version 27 vorhanden
    export class SchedulingLocationSettings extends Model.Core.Unique {
        IsParentIssueLocation?: boolean;
        ExecutionCount?: number;

        constructor()
        constructor(oid: string)
        constructor(oid: string, executionCount: number)
        constructor(oid?: string, executionCount?: number) {
            super(oid);

            if (executionCount && !isNaN(+executionCount)) {
                this.ExecutionCount = +executionCount;
            }
        }
    }
}
