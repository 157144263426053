//imports-start
/// <reference path="../definitions.d.ts" />
/// <reference path="../enums.ts" />
/// <reference path="../model/elements/evaluation.ts" />
/// <reference path="./utils.evaluation-types.ts" />
//imports-end

module Utils.Evaluation {
    let _parameter;
    let _recorditem;
    let _evaluatedCategoryOID;

    function compareNumbers(operator: OperatorType, firstValue: number, secondValue: number): boolean {
        switch (operator) {
            case OperatorType.Equal:
                return firstValue === secondValue;
            case OperatorType.Unequal:
                return firstValue !== secondValue;
            case OperatorType.Less:
                return firstValue < secondValue;
            case OperatorType.LessOrEqual:
                return firstValue <= secondValue;
            case OperatorType.Greater:
                return firstValue > secondValue;
            case OperatorType.GreaterOrEqual:
                return firstValue >= secondValue;
        }
    }

    export function Evaluate(recorditem: Model.Recorditem): string {
        if (!recorditem) {
            return null;
        }

        if (View.CurrentView == Enums.View.Form || View.CurrentView == Enums.View.Inspection) {
            _parameter = ParameterList.GetParameter(recorditem.ElementRevisionOID, recorditem.Row);
        } else if (View.CurrentView === Enums.View.Scheduling) {
            _parameter = ParameterList.GetParameter(recorditem.ElementRevisionOID);
        } else {
            _parameter = ParameterList.GetParameter(recorditem.ElementOID);
        }

        if (!_parameter || !(_parameter.Evaluation || []).length) {
            return null;
        }

        _recorditem = $.extend({}, recorditem);
        _evaluatedCategoryOID = null;

        switch (_parameter.Type) {
            case Enums.ElementType.Checkbox:
                evalBool();
                break;
            case Enums.ElementType.Number:
                evalNumeric();
                break;
            case Enums.ElementType.Date:
                evalDate();
                break;
            case Enums.ElementType.Time:
                evalTime();
                break;
            case Enums.ElementType.ListBox:
                evalSelection();
                break;
            case Enums.ElementType.MultiListBox:
                evalMultiSelection();
                break;
            default:
                evalDefault();
                break;
        }

        return _evaluatedCategoryOID;
    }

    export function ToString(evaluation: Model.Elements.Evaluation, element: Model.Elements.Element) {
        let strEvaluation, strLinking, strType;
        let firstValue, secondValue;
        let file, text;
        let unit;

        let getOperatorSign = function(operatorValue: OperatorType) {
            switch (operatorValue) {
                case OperatorType.Equal:
                    return '=';
                case OperatorType.Unequal:
                    return '≠';
                case OperatorType.Less:
                    return '<';
                case OperatorType.LessOrEqual:
                    return '<=';
                case OperatorType.Greater:
                    return '>';
                case OperatorType.GreaterOrEqual:
                    return '>=';
                default:
                    return '';
            }
        };

        if (evaluation.Type === Enums.ElementType.Checkbox) {
            switch (evaluation.Value) {
                case TristateValueType.True:
                    return i18next.t('Misc.Yes').toLowerCase();
                case TristateValueType.False:
                    return i18next.t('Misc.No').toLowerCase();
                case TristateValueType.Unrecorded:
                    return i18next.t('Misc.Unrecorded');
            }
        } else if (evaluation.Type === Enums.ElementType.Date) {
            switch (evaluation.ValueType) {
                case DateComparisonType.Days:
                    strType = i18next.t('DateTime.Day_plural');
                    break;
                case DateComparisonType.Months:
                    strType = i18next.t('DateTime.Month_plural');
                    break;
                case DateComparisonType.Years:
                    strType = i18next.t('DateTime.Year_plural');
                    break;
            }
        } else if (evaluation.Type === Enums.ElementType.Time) {
            switch (evaluation.ValueType) {
                case TimeComparisonType.Seconds:
                    strType = i18next.t('DateTime.Second_plural');
                    break;
                case TimeComparisonType.Minutes:
                    strType = i18next.t('DateTime.Minute_plural');
                    break;
                case TimeComparisonType.Hours:
                    strType = i18next.t('DateTime.Hour_plural');
                    break;
            }
        } else if (Utils.InArray([Enums.ElementType.ListBox, Enums.ElementType.MultiListBox], evaluation.Type)) {
            if (element && element.Structure) {
                text = element.Structure[evaluation.Value];

                if (element.hasOwnProperty('AdditionalSettings') && element.AdditionalSettings.MapStructureToImages) {
                    file = DAL.Files.GetByOID(text);

                    if (file) {
                        text = file.Title;
                    }
                }

                return text;
            } else {
                return i18next.t('Misc.Unknown');
            }
        } else if (evaluation.Type === Enums.ElementType.Info) {
            return null;
        } else if (Utils.InArray([
            Enums.ElementType.Line,
            Enums.ElementType.Memo,
            Enums.ElementType.Photo,
            Enums.ElementType.Scancode,
            Enums.ElementType.LocationCode,
            Enums.ElementType.Signature,
            Enums.ElementType.Users,
            Enums.ElementType.IndividualData,
        ], evaluation.Type)) {
            return !evaluation.Value ? i18next.t('Misc.Recorded') : i18next.t('Misc.Unrecorded');
        }

        firstValue = typeof evaluation.FirstValue !== 'undefined' && evaluation.FirstValue !== null ?
            parseFloat(evaluation.FirstValue) :
            null;

        secondValue = typeof evaluation.SecondValue !== 'undefined' && evaluation.SecondValue !== null ?
            parseFloat(evaluation.SecondValue) :
            null;

        if (element.Type === Enums.ElementType.Number &&
            !!element.UnitOID &&
            DAL.Properties.Exists(Enums.PropertyType.Unit, element.UnitOID)) {
            unit = DAL.Properties.GetByOID(element.UnitOID);

            firstValue += unit.Title;

            if (secondValue !== null) {
                secondValue += unit.Title;
            }
        }

        if (secondValue !== null) {
            switch (evaluation.Linking) {
                case LinkingType.And:
                    strLinking = i18next.t('Misc.And');
                    break;
                case LinkingType.Or:
                    strLinking = i18next.t('Misc.Or');
                    break;
            }
        }

        const firstOpSign = getOperatorSign(evaluation.FirstOperator);
        strType = !!strType ? ' ' + strType : '';

        if (!!strLinking) {
            const secOpSign = getOperatorSign(evaluation.SecondOperator);
            strEvaluation = `${firstOpSign} ${firstValue}${strType} ${strLinking} ${secOpSign} ${secondValue}${strType}`;
        } else {
            strEvaluation = `${firstOpSign} ${firstValue}${strType}`;
        }

        return strEvaluation;
    }

    function evalBool(): void {
        if ((_parameter.Evaluation || []).length) {
            for (let eCnt = 0, eLen = _parameter.Evaluation.length; eCnt < eLen; eCnt++) {
                const evaluation = _parameter.Evaluation[eCnt];

                if (evaluation.Type === Enums.ElementType.Checkbox && evaluation.Value) {
                    let isMatch = false;
                    switch (evaluation.Value) {
                        case TristateValueType.True:
                            isMatch = _recorditem.Value === true;
                            break;
                        case TristateValueType.False:
                            isMatch = typeof _recorditem.Value !== 'undefined'
                                && _recorditem.Value !== null
                                && _recorditem.Value === false;
                            break;
                        case TristateValueType.Unrecorded:
                            isMatch = typeof _recorditem.Value === 'undefined'
                                || _recorditem.Value === null;
                            break;
                    }

                    if (isMatch) {
                        _evaluatedCategoryOID = evaluation.CategoryOID;
                        return;
                    }
                }
            }
        }
    }

    function evalNumeric(): void {
        if (isNaN(_recorditem.Value = parseFloat(_recorditem.Value))) {
            return;
        }

        if ((_parameter.Evaluation || []).length) {
            for (let eCnt = 0, eLen = _parameter.Evaluation.length; eCnt < eLen; eCnt++) {
                const evaluation = _parameter.Evaluation[eCnt];

                const firstValue = parseFloat(evaluation.FirstValue);
                const secondValue = parseFloat(evaluation.SecondValue);

                if (evaluation.Type === Enums.ElementType.Number) {
                    let isMatch = false;
                    if (!isNaN(firstValue) && !isNaN(secondValue)) {
                        switch (evaluation.Linking) {
                            case LinkingType.And:
                                isMatch = compareNumbers(evaluation.FirstOperator, _recorditem.Value, firstValue)
                                    && compareNumbers(evaluation.SecondOperator, _recorditem.Value, secondValue);
                                break;
                            case LinkingType.Or:
                                isMatch = compareNumbers(evaluation.FirstOperator, _recorditem.Value, firstValue)
                                    || compareNumbers(evaluation.SecondOperator, _recorditem.Value, secondValue);
                                break;
                            default:
                                isMatch = compareNumbers(evaluation.FirstOperator, _recorditem.Value, firstValue);
                                break;
                        }
                    } else if (!isNaN(firstValue)) {
                        isMatch = compareNumbers(evaluation.FirstOperator, _recorditem.Value, firstValue);
                    }

                    if (isMatch) {
                        _evaluatedCategoryOID = evaluation.CategoryOID;
                        return;
                    }
                }
            }
        }
    }

    function evalDate(): void {
        _recorditem.Value = new Date(_recorditem.Value);
        if (isNaN(_recorditem.Value)) {
            return;
        }

        let modTimestamp = new Date(_recorditem.ModificationTimestamp);
        modTimestamp.setHours(0, 0, 0);

        if ((_parameter.Evaluation || []).length) {
            for (let eCnt = 0, eLen = _parameter.Evaluation.length; eCnt < eLen; eCnt++) {
                let isMatch = false;
                const evaluation = _parameter.Evaluation[eCnt];
                const firstValue = parseInt(evaluation.FirstValue, 10);
                let secondValue = parseInt(evaluation.SecondValue, 10);
                let comparableFirstValue = null;
                let comparableSecondValue = null;

                if (evaluation.Type === Enums.ElementType.Date) {
                    comparableFirstValue = new Date(_recorditem.Value.getTime());
                    comparableFirstValue = new Date(comparableFirstValue.setHours(0, 0, 0));

                    switch (evaluation.ValueType) {
                        case DateComparisonType.Days:
                            comparableFirstValue = comparableFirstValue.addDays(firstValue);
                            break;
                        case DateComparisonType.Months:
                            comparableFirstValue = comparableFirstValue.addMonths(firstValue);
                            break;
                        case DateComparisonType.Years:
                            comparableFirstValue = comparableFirstValue.addYears(firstValue);
                            break;
                    }

                    if (isNaN(secondValue)) {
                        secondValue = undefined;
                    } else {
                        comparableSecondValue = new Date(_recorditem.Value.getTime());
                        comparableSecondValue = new Date(comparableSecondValue.setHours(0, 0, 0));

                        switch (evaluation.ValueType) {
                            case DateComparisonType.Days:
                                comparableSecondValue = comparableSecondValue.addDays(secondValue);
                                break;
                            case DateComparisonType.Months:
                                comparableSecondValue = comparableSecondValue.addMonths(secondValue);
                                break;
                            case DateComparisonType.Years:
                                comparableSecondValue = comparableSecondValue.addYears(secondValue);
                                break;
                        }
                    }

                    const modTimestampTime = modTimestamp.getTime();
                    const comparableFirstValueTime = comparableFirstValue.getTime();

                    if (evaluation.Linking === LinkingType.And && comparableSecondValue instanceof Date) {
                        const comparableSecondValueTime = comparableSecondValue.getTime();
                        isMatch = compareNumbers(evaluation.FirstOperator, modTimestampTime, comparableFirstValueTime) &&
                            compareNumbers(evaluation.SecondOperator, modTimestampTime, comparableSecondValueTime);
                    } else if (evaluation.Linking === LinkingType.Or && comparableSecondValue instanceof Date) {
                        const comparableSecondValueTime = comparableSecondValue.getTime();
                        isMatch = compareNumbers(evaluation.FirstOperator, modTimestampTime, comparableFirstValueTime) ||
                            compareNumbers(evaluation.SecondOperator, modTimestampTime, comparableSecondValueTime);
                    } else {
                        isMatch = compareNumbers(evaluation.FirstOperator, modTimestampTime, comparableFirstValueTime);
                    }

                    if (isMatch) {
                        _evaluatedCategoryOID = evaluation.CategoryOID;
                        return;
                    }
                }
            }
        }
    }

    function evalTime(): void {
        if (!((_recorditem.Value = new Date(_recorditem.Value)) instanceof Date)) {
            return;
        }

        const modTimestamp = new Date(_recorditem.ModificationTimestamp);

        if ((_parameter.Evaluation || []).length) {
            for (let eCnt = 0, eLen = _parameter.Evaluation.length; eCnt < eLen; eCnt++) {
                let isMatch = false;
                const evaluation = _parameter.Evaluation[eCnt];
                let comparableFirstValue = null;
                let comparableSecondValue = null;

                if (evaluation.Type === Enums.ElementType.Time) {
                    comparableFirstValue = new Date(_recorditem.Value.getTime());

                    const firstValue = parseFloat(evaluation.FirstValue);
                    let secondValue = parseFloat(evaluation.SecondValue);

                    switch (evaluation.ValueType) {
                        case TimeComparisonType.Seconds:
                            comparableFirstValue = comparableFirstValue.addSeconds(firstValue);
                            break;
                        case TimeComparisonType.Minutes:
                            comparableFirstValue = comparableFirstValue.addMinutes(firstValue);
                            break;
                        case TimeComparisonType.Hours:
                            comparableFirstValue = comparableFirstValue.addHours(firstValue);
                            break;
                    }

                    if (isNaN(secondValue)) {
                        secondValue = undefined;
                    } else {
                        comparableSecondValue = new Date(_recorditem.Value.getTime());

                        switch (evaluation.ValueType) {
                            case TimeComparisonType.Seconds:
                                comparableSecondValue = comparableSecondValue.addSeconds(secondValue);
                                break;
                            case TimeComparisonType.Minutes:
                                comparableSecondValue = comparableSecondValue.addMinutes(secondValue);
                                break;
                            case TimeComparisonType.Hours:
                                comparableSecondValue = comparableSecondValue.addHours(secondValue);
                                break;
                        }
                    }
                    const modTimestampTime = modTimestamp.getTime();
                    const comparableFirstValueTime = comparableFirstValue.getTime();

                    if (evaluation.Linking === LinkingType.And && comparableSecondValue instanceof Date) {
                        const comparableSecondValueTime = comparableSecondValue.getTime();
                        isMatch = compareNumbers(evaluation.FirstOperator, modTimestampTime, comparableFirstValueTime)
                            && compareNumbers(evaluation.SecondOperator, modTimestampTime, comparableSecondValueTime);
                    } else if (evaluation.Linking === LinkingType.Or && comparableSecondValue instanceof Date) {
                        const comparableSecondValueTime = comparableSecondValue.getTime();
                        isMatch = compareNumbers(evaluation.FirstOperator, modTimestampTime, comparableFirstValueTime)
                            || compareNumbers(evaluation.SecondOperator, modTimestampTime, comparableSecondValueTime);
                    } else {
                        isMatch = compareNumbers(evaluation.FirstOperator, modTimestampTime, comparableFirstValueTime);
                    }

                    if (isMatch) {
                        _evaluatedCategoryOID = evaluation.CategoryOID;
                        return;
                    }
                }
            }
        }
    }

    function evalSelection(): void {
        if ((_parameter.Evaluation || []).length) {
            for (let eCnt = 0, eLen = _parameter.Evaluation.length; eCnt < eLen; eCnt++) {
                const evaluation = _parameter.Evaluation[eCnt];

                if (evaluation.Type === Enums.ElementType.ListBox &&
                    parseInt(evaluation.Value, 10) === parseInt(_recorditem.Value, 10)) {
                    _evaluatedCategoryOID = evaluation.CategoryOID;
                    return;
                }
            }
        }
    }

    function evalMultiSelection(): void {
        let tmpValue = _recorditem.Value;

        if (typeof tmpValue === 'string') {
            tmpValue = JSON.parse(tmpValue);
        }

        if (tmpValue instanceof Array && (tmpValue || []).length) {
            if ((_parameter.Evaluation || []).length) {
                for (let eCnt = 0, eLen = _parameter.Evaluation.length; eCnt < eLen; eCnt++) {
                    const evaluation = _parameter.Evaluation[eCnt];

                    if (evaluation.Type === Enums.ElementType.MultiListBox
                        && $.inArray(parseInt(evaluation.Value, 10), tmpValue) !== -1) {
                        _evaluatedCategoryOID = evaluation.CategoryOID;
                        return;
                    }
                }
            }
        }
    }

    function evalDefault(): void {
        const excludedTypes = [
            Enums.ElementType.Checkbox,
            Enums.ElementType.Number,
            Enums.ElementType.Date,
            Enums.ElementType.Time,
            Enums.ElementType.ListBox,
            Enums.ElementType.MultiListBox
        ];

        if ((_parameter.Evaluation || []).length) {
            for (let eCnt = 0, eLen = _parameter.Evaluation.length; eCnt < eLen; eCnt++) {
                let evaluation = _parameter.Evaluation[eCnt];

                if (!Utils.InArray(excludedTypes, evaluation.Type)
                    && typeof evaluation.Value !== 'undefined') {
                    if ((parseInt(evaluation.Value, 10) === 0 && !!_recorditem.Value)
                        || (parseInt(evaluation.Value, 10) === 1 && !_recorditem.Value)) {
                        _evaluatedCategoryOID = evaluation.CategoryOID;
                        return;
                    }
                }
            }
        }
    }
}