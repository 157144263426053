/// <reference path="../definitions.d.ts"  />

module Model {
    interface DropDownListEntity {
        Title: string,
        OID?: string,
        Value?: any,
        Color?: string,
        IsLockable?: boolean
    }

    export interface DropDownSettings {
        ShowCustomDropdownIcon: boolean,
        $DropdownIcon: any,
        $BtnContainer: any,
        Entities: Array<DropDownListEntity>,
        OnItemSelected: (evt: Event) => void,
        MarginToToggleElement?: number
    }

    export class DropDownList {
        public ShowCustomDropdownIcon: boolean;
        private readonly marginToToggleElement: number;
        private readonly entities: Array<DropDownListEntity>;
        private readonly onItemSelected: (evt: Event) => void;
        private readonly $dropdownIcon: any;

        private $dropdownWrapper: any;
        private $dropDownToggleContainer: any;
        private $dropDownToggleButtonGroup: any;
        private $dropDownMenu: any;

        constructor(settings: DropDownSettings) {
            this.$dropDownToggleContainer = settings.$BtnContainer;
            this.entities = settings.Entities;
            this.onItemSelected = settings.OnItemSelected;
            this.marginToToggleElement = settings.MarginToToggleElement || 6;
            this.ShowCustomDropdownIcon = !!settings.ShowCustomDropdownIcon;
            this.$dropdownIcon = settings.$DropdownIcon;

            this.build();
            this.bindEvents();
        }

        public Show(): void {
            this.$dropDownToggleButtonGroup.find('.has-icon').addClass('drop-up-visible');
            this.$dropdownWrapper.addClass('open');
            this.Reposition();
        }

        public Hide(): void {
            this.$dropDownToggleButtonGroup.find('.has-icon').removeClass('drop-up-visible');
            this.$dropdownWrapper.removeClass('open');
        }

        private isDropdownOpen(): boolean {
            return this.$dropdownWrapper != null && this.$dropdownWrapper.hasClass('open');
        }

        public Reposition(): void {
            const $window = $(window);
            const maxScreenHeight = $window.height();
            const maxScreenWidth = $window.width();

            const newPosition = this.$dropDownToggleButtonGroup[0].getBoundingClientRect();
            const maxHeight = maxScreenHeight - (maxScreenHeight - newPosition.top) - this.marginToToggleElement - 10;
            const width = this.$dropDownMenu.width();
            let height = this.$dropDownMenu[0].scrollHeight;

            if (height > maxHeight) {
                height = maxHeight;

                this.$dropDownMenu.css('max-height', maxHeight);
            } else {
                this.$dropDownMenu.css('max-height', '');
            }

            this.$dropdownWrapper.css({
                top: Math.max((newPosition.top - height - this.marginToToggleElement), 0),
                left: Math.max(Math.min(newPosition.left, maxScreenWidth - width - 10), 0)
            });
        }

        public Destroy(): void {
            this.unbindEvents();

            this.$dropDownToggleButtonGroup.remove();
            this.$dropdownWrapper.remove();
        }

        public UnshiftEntities(...entities: Array<DropDownListEntity>): void {
            this.entities.unshift(...entities);

            for (let eCnt = entities.length - 1; eCnt >= 0; eCnt--) {
                this.$dropDownMenu.prepend(Templates.DropDownList.Entity(entities[eCnt]));
            }
        }

        public ShiftEntity(): DropDownListEntity {
            this.$dropDownMenu.children().first().remove();
            return this.entities.shift();
        }

        private build(): void {
            this.$dropDownToggleButtonGroup = $('<div class="btn-group dropup" role="group"></div>');
            this.$dropDownMenu = $('<ul class="dropdown-menu"></ul>');

            const bLen = this.entities.length;
            for (let bCnt = 0; bCnt < bLen; bCnt++) {
                this.$dropDownMenu.append($(Templates.DropDownList.Entity(this.entities[bCnt])));
            }

            const $dropdownToggleButton = this.CreateDropdownButton();

            this.$dropDownToggleButtonGroup
                .append($dropdownToggleButton);

            this.$dropDownToggleContainer.append(this.$dropDownToggleButtonGroup);

            this.$dropdownWrapper = $('<div class="undocked-dropdown dropup"></div>');
            this.$dropdownWrapper.append(this.$dropDownMenu);

            $('body').append(this.$dropdownWrapper);
        }

        public CreateDropdownButton(): any {
            const $dropdownToggleButton = $(`<div class="btn ${this.ShowCustomDropdownIcon ? 'has-icon ' : 'btn-default '}dropdown-toggle ${this.isDropdownOpen() ? 'drop-up-visible ' : ''}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>`);
            const $dropdownToggleContent = this.ShowCustomDropdownIcon && this.$dropdownIcon != null ? this.$dropdownIcon : $('<span class="caret"></span>');

            $dropdownToggleButton.append($dropdownToggleContent);

            return $dropdownToggleButton;
        }

        private bindEvents(): void {
            this.unbindEvents();

            this.$dropDownToggleButtonGroup
                .on('show.bs.dropdown', this.Show.bind(this))
                .on('hide.bs.dropdown', this.Hide.bind(this));

            this.$dropDownMenu.on('click', 'a', (evt) => {
                this.onItemSelected(evt);
            });
        }

        private unbindEvents(): void {
            this.$dropDownMenu.off('click');

            this.$dropDownToggleButtonGroup
                .off('show.bs.dropdown hide.bs.dropdown');
        }
    }
}