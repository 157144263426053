///<reference path="./i-response-base.ts"/>
///<reference path="../workflow-base.ts"/>

module New.Checkpoints.Workflow.Response {
    export abstract class IssueResponseBase implements IResponseBase {
        readonly Workflow: WorkflowBase;

        public Issue: Model.Issues.Issue;

        protected constructor(workflow: WorkflowBase, issue?: Model.Issues.Issue) {
            if (!workflow) {
                throw new Error('no workflow provided');
            }

            this.Workflow = workflow;
        }

        public SetIssue(issue: Model.Issues.Issue): this {
            this.Issue = issue;

            return this;
        }

        abstract createWorkflowText(): string;
    }
}