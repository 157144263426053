//imports-start
/// <reference path="../issues/counters.ts"  />
//imports-end

module Model.Menu {
    export class Counters {
        IssueCounters: Model.Issues.Counters;
        SchedulingsCount: number;

        constructor() {
            this.IssueCounters = new Model.Issues.Counters();
            this.SchedulingsCount = 0;
        }
    }
}