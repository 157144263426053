//imports-start
/// <reference path="../definitions.d.ts"  />
//// <reference path="./issues.ts"  />
//// <reference path="../model/elements/room-meta-data.ts"  />
//// <reference path="../model/elements/issue-meta-data.ts"  />
//// <reference path="../model/elements/tree-meta-data.ts"  />
//// <reference path="../utils/utils.set.ts"  />
//// <reference path="../model/scheduling/scheduling-meta-data.ts" />
//// <reference path="../model/scheduling/scheduling-meta-data-element.ts" />
//imports-end


module DAL.Cache {
    export function Clear() {
        ParameterList.Reset();
        Rights.ResetUserRights();
    }

    export module Rights {
        let UserRightsByLocation: Dictionary<Dictionary<any>>;

        export function GetUserRights(userIdentifier: string, locationOID?: string): Dictionary<any>
        export function GetUserRights(userIdentifier: string, location?: Model.Elements.Element): Dictionary<any>
        export function GetUserRights(userIdentifier: string, location?: Model.Elements.Element | string): Dictionary<any> {
            if (!UserRightsByLocation) {
                return null;
            }

            const locationOID = !location ? "null" : (typeof location === 'string' ? location : location.OID);

            if (!UserRightsByLocation[locationOID]) {
                return null;
            }

            return UserRightsByLocation[locationOID][userIdentifier] || null;

        }

        export function SetUserRights(userIdentifier: string, location: Model.Elements.Element, rights: Dictionary<any>): void
        export function SetUserRights(userIdentifier: string, locationOID: string, rights: Dictionary<any>): void
        export function SetUserRights(userIdentifier: string, location: Model.Elements.Element | string, rights: Dictionary<any>): void {
            if (!UserRightsByLocation) {
                UserRightsByLocation = {};
            }

            const locationOID = !location ? "null" : (typeof location === 'string' ? location : location.OID);

            if (!UserRightsByLocation[locationOID]) {
                UserRightsByLocation[locationOID] = {};
            }

            UserRightsByLocation[locationOID][userIdentifier] = rights;
        }

        export function ResetUserRights(): void {
            UserRightsByLocation = null;
        }
    }

    export interface IParameterListItem {
        $el: any;
        hidden?: boolean;
    }

    export module ParameterList {
        export let GroupRows = {};
        export let ParamRows = {};
        export let ParameterColumn = {};
        export let PreparedParameters;
        export let DOMElements = {};
        export let PreparedGroups: Model.Elements.Element[];
        export let PreparedResubTree;

        export function Reset() {
            /*
            * Reset all cache objects.
            */
            GroupRows = {};
            ParamRows = {};
            ParameterColumn = {};
            PreparedParameters = null;
            DOMElements = {};
            PreparedGroups = null;
            PreparedResubTree = null;
        }

        export function ResetParameterRow(oid: string, row?: number): DAL.Cache.IParameterListItem {
            /*
            * Remove Parameter $elementRow from cache
            */
            const rowIdx = row || 'x';
            const paramCache = this.ParamRows[oid];


            if (!paramCache) {
                return;
            }

            const rowCache: DAL.Cache.IParameterListItem = paramCache[rowIdx];

            if (rowCache == null) {
                return;
            }

            paramCache.$all = paramCache.$all.remove(rowCache.$el);

            delete paramCache[rowIdx];
        }

        export function GetGroupRow(oid: string, row?: number, getParameter?: Function): DAL.Cache.IParameterListItem {
            /*
            * Get requested Group $elementRow from cache
            */
            let rowIdx = row || 'x';
            let groupCache = this.GroupRows[oid];
            let rowCache: DAL.Cache.IParameterListItem;
            if (!groupCache) {
                groupCache = {}
                this.GroupRows[oid] = groupCache;
            } else {
                rowCache = groupCache[rowIdx];
            }

            if (rowCache == null) {
                let $parameter = getParameter(oid, row) || [];

                if ($parameter.length) {
                    rowCache = {
                        $el: $parameter
                    };

                    groupCache[rowIdx] = rowCache;
                } else {
                    rowCache = {
                        $el: $()
                    };
                }
            }

            return rowCache;
        }

        export function GetParameterRow(oid: string, row?: number, getParameter?: Function): DAL.Cache.IParameterListItem {
            /*
            * Get requested Parameter $elementRow from cache
            */
            let rowIdx = row || 'x';
            let paramCache = this.ParamRows[oid];
            let rowCache: DAL.Cache.IParameterListItem;

            if (!paramCache) {
                paramCache = {
                    $all: $()
                };
                this.ParamRows[oid] = paramCache;
            } else {
                rowCache = paramCache[rowIdx];
            }

            if (rowCache == null) {
                rowCache = {
                    $el: getParameter(oid, row)
                };

                paramCache[rowIdx] = rowCache;
                paramCache.$all = paramCache.$all.add(rowCache.$el);
            }

            return rowCache;
        }

        export function GetParameterColumn(oid: string, getParameters: Function) {
            let paramCache = this.ParameterColumn[oid];

            if (paramCache != null) {
                return paramCache;
            }

            paramCache = $();

            const $parameters = getParameters(oid);
            const pLen = ($parameters || []).length;
            for (let pCnt = 0; pCnt < pLen; pCnt++) {
                let $parameter = $parameters[pCnt];
                paramCache = paramCache.add($parameter);
            }

            this.ParameterColumn[oid] = paramCache;
            return paramCache;
        }

        export function Replace(oid: string, row: number | null = null, $newCell): IParameterListItem {
            /*
            * Ersetzt das spezifizierte HTML-Element durch ein Neues
            */
            const rowIdx = row || 'x';
            let paramCache = this.ParamRows[oid];
            let rowCache: DAL.Cache.IParameterListItem;

            if (!paramCache) {
                paramCache = {
                    $all: $()
                };
                this.ParamRows[oid] = paramCache;
            } else {
                rowCache = paramCache[rowIdx];
            }

            if (rowCache == null) {
                rowCache = {
                    $el: $newCell
                };

                paramCache[rowIdx] = rowCache;
            } else {
                const $oldCell = rowCache.$el;
                rowCache.$el = $newCell;
                paramCache.$all = paramCache.$all.not($oldCell[0]);
            }

            rowCache.hidden = $newCell.hasClass('hidden');
            paramCache.$all = paramCache.$all.add(rowCache.$el);

            return rowCache;
        }
    }

    export module Filters {
        let _systemParameterList: Model.Parameters.Filter;

        export function SetSystemParameterListFilter(filter: Model.Parameters.Filter): void {
            _systemParameterList = new Model.Parameters.Filter(Utils.CloneObject(filter));
        }

        export function GetSystemParameterListFilter(retainInCache: boolean = false): Model.Parameters.Filter {
            const filter = _systemParameterList;

            if (!retainInCache) {
                _systemParameterList = null;
            }

            return filter;
        }

        export function Reset(): void {
            _systemParameterList = null;
        }
    }
}
