//imports-start
/// <reference path="../definitions.d.ts" />
//imports-end

module Utils {

    export interface ImagePickerOptions {
        MaximumImagesCount?: number;
        SuppressError?: boolean;
    }

    export function OpenFile(filename: string, isImage: boolean, isVideo: boolean, title?: string, predefinedMimeType?: string, closeCallback?: (hasDownloadUpdate: boolean) => void): void {
        function fallback(): void {
            const file = DAL.Files.GetByFilename(filename);

            if (!Session.IsSmartDeviceApplication) {
                let fileUri = `${Session.BaseURI}files/${filename}`;

                if (file) {
                    const sanitizedFilename = Utils.GetSanitizedFilename(file.Title || filename);

                    fileUri += `?friendlyDownloadName=${sanitizedFilename}`;
                } else if (!!title) {
                    const sanitizedFilename = Utils.GetSanitizedFilename(title || filename);

                    fileUri += `?friendlyDownloadName=${sanitizedFilename}`;
                }

                window.open(fileUri, '_blank');

                return;
            }

            $.Deferred().resolve()
                .then(() => {
                    //check if file is from file catalog
                    if (file && !file.IsAvailableOffline) {
                        return Utils.CheckIfDeviceIsOnline()
                            .then(() => {
                                const sanitizedFilename = Utils.GetSanitizedFilename(file.Title || filename);
                                const filePath = `${Session.BaseURI}files/${filename}?friendlyDownloadName=${sanitizedFilename}${Utils.GetAuthQueryParameter('&')}`;

                                return $.Deferred().resolve(filePath, file.MimeType);
                            }, () => {
                                Utils.Message.Show(i18next.t('Synchronization.NoInternetConnection.MessageHeader'),
                                    i18next.t('Misc.NoInternetConnection.MessageBody'),
                                    {
                                        Close: true
                                    });
                                return $.Deferred().reject();
                            });
                    }

                    //use predefined MimeType if file is not in catalog
                    return $.Deferred().resolve(Utils.GetResourcesPath() + filename, file == null ? predefinedMimeType : file.MimeType);
                })
                .then((filePath: string, mimeType: string) => {
                    cordova.plugins.fileOpener2.open(filePath, mimeType, { error: onFileOpenerError });
                });
        }

        // Prüfen, ob Datei lokal existiert; bei Fehlen nach Direkt-Download fragen
        downloadMissingFileIfRequired(filename)
            .then(function() {
                if (isImage) {
                    const options: ImageViewerOptions = {
                        ImageFilename: filename,
                        FooterInformation: {
                            Title: title
                        },
                        closeFn: closeCallback
                    };

                    ImageViewerManager.Show(options);
                } else if ((isVideo || /\.pdf/.test(filename)) && Session.IsRunningOnIOS) {
                    openInAppBrowser(filename);
                } else {
                    fallback();
                }
            })
    }

    function onFileOpenerError(e: any): void {
        if (!Utils.Message.IsVisible()) {
            if (e.message.startsWith('Activity not found')) {
                Utils.Message.Show(i18next.t('FileOpener.NoCompatibleApp.MessageHeader'),
                    i18next.t('FileOpener.NoCompatibleApp.MessageBody'),
                    {
                        Close: true
                    }, null, 99999);
            } else if (e.message.startsWith('File not found')) {
                Utils.Message.Show(i18next.t('FileOpener.FileNotFound.MessageHeader'),
                    i18next.t('FileOpener.FileNotFound.MessageBody'),
                    {
                        Close: true
                    }, null, 99999);
            }
        }
    }

    function openInAppBrowser(filename: string): void {
        const options = {
            enableViewPortScale: 'yes',
            location: 'no',
            closeButtonCaption: i18next.t('Misc.Close').toUpperCase()
        };

        cordova.InAppBrowser.open(
            Utils.GetResourcesPath() + filename,
            '_blank',
            $.map(options, (v, k) => `${k}=${v}`).join(',')
        );
    }

    function downloadMissingFileIfRequired(filename: string): Deferred {
        // in Weberfassung keine Download-Vorprüfung notwendig
        if (!Session.IsSmartDeviceApplication) {
            return $.Deferred().resolve().promise();
        }

        // Prüfen, ob Datei existiert; bei Fehlen nach Direkt-Download fragen
        return CheckIfFileExists(filename)
            .then(function(exist: boolean) {
                if (exist) {
                    return false; // keinen Download anstreben
                }

                return Utils.CheckIfDeviceIsOnline();
            })
            .then(function(continueWithDownload: boolean) {
                if (!continueWithDownload) {
                    return $.Deferred().resolve().promise();
                }

                const file = DAL.Files.GetByFilename(filename);

                if (file && !file.IsAvailableOffline) {
                    return $.Deferred().resolve().promise(); // keinen Download anbieten, wenn es eine Online-Only Datei ist
                }

                const downloadDeferred = $.Deferred();

                Utils.Message.Show(i18next.t('FileOpener.DownloadMissingFile.MessageHeader'),
                    i18next.t('FileOpener.DownloadMissingFile.MessageBody', { Filename: filename }),
                    {
                        No: () => { downloadDeferred.reject() },
                        OnHide: () => { downloadDeferred.reject() },
                        Yes: () => {
                            Utils.Spinner.Show(i18next.t('Misc.LoadingData', { DataType: filename }));
                            Utils.Http.DownloadFile(filename, Utils.Synchronisation.Download.FileSource.FileCatalog)
                                .always(() => {
                                    Utils.Spinner.Hide();
                                })
                                .then(downloadDeferred.resolve, downloadDeferred.reject);
                        }
                    }, null, 99999);

                return downloadDeferred;
            }, function() {
                return $.Deferred().resolve().promise();  // bei fehlender Online-Verbindung soll Datei-Öffnungsprozess fortgesetzt werden
            })
    }

    export function OpenFiles(
        files,
        startFilename: string,
        callback: (image: any) => void,
        title: string, canWriteComments?: boolean)
        : void {
        const options: ImageViewerOptions = {
            Files: files,
            StartFilename: startFilename,
            CanWriteComments: canWriteComments,
            FileSeenCallback: callback,
            FooterInformation: {
                Title: title
            }
        }

        ImageViewerManager.Show(options)
    }

    export function OpenImages(
        files,
        startFilename: string,
        forceLoadFromServer?: boolean,
        callback?: (image: any) => void,
        imageEditedCallback?: (identifier: any, marks: string) => void,
        title?: string,
        canWriteComments?: boolean,
        comparisonImages?: Array<Model.Files.File>)
        : void {
        const options: ImageViewerOptions = {
            Images: files,
            StartFilename: startFilename,
            CanWriteComments: canWriteComments,
            FileSeenCallback: callback,
            ForceLoadFromServer: forceLoadFromServer,
            ImageEditedCallback: imageEditedCallback,
            FooterInformation: {
                Title: title
            },
            ComparisonImages: comparisonImages
        };

        ImageViewerManager.Show(options);
    }

    export function OpenTemporaryImages(
        imagePaths: Array<any>,
        startFilename: string,
        imageEditedCallback?: (identifier: any, marks: string) => void,
        title?: string,
        canWriteComments?: boolean,
        comparisonImages?: Array<Model.Files.File>)
        : void {
        const options: ImageViewerOptions = {
            Images: imagePaths,
            StartFilename: startFilename,
            CanWriteComments: canWriteComments,
            FullPathGiven: true,
            ImageEditedCallback: imageEditedCallback,
            FooterInformation: {
                Title: title
            },
            ComparisonImages: comparisonImages
        };

        ImageViewerManager.Show(options);
    }


    export function GetUserImagePath(userOID: string): string {
        let user = DAL.Users.GetByOID(userOID);

        if (!user || !user.ImagePath) {
            return './img/user.svg';
        }

        return user.ImagePath;
    }

    export function XHRLoadImage(url: string): Deferred {
        const resultDeferred = $.Deferred();

        if (url.startsWith('app://')) {
            return resultDeferred.reject('File is local!');
        }

        // Standard XHR to load an image
        const request = new XMLHttpRequest();

        request.responseType = 'blob';

        // When the request loads, check whether it was successful
        request.onload = function() {
            if (request.status === 200) {
                // If successful, resolve the promise by passing back the request response
                resultDeferred.resolve(request.response);
            } else {
                // If it fails, reject the promise with an error message
                resultDeferred.reject(new Error('Image didn\'t load successfully; error code:' + request.statusText));
            }
        };

        request.onerror = function(err) {
            // Also deal with the case when the entire request fails to begin with
            // This is probably a network error, so reject the promise with an appropriate message
            resultDeferred.reject(new Error('There was a network error.'));
        };

        // Send the request
        request.open('GET', url);
        request.send();

        return resultDeferred;
    }

    export function GetResourcesPath(): string {
        if (Session.IsSmartDeviceApplication) {
            return Session.ResourcesFolder.nativeURL;
        }
    }


    export function CheckIfFileExists(filename: string): Deferred {
        const deferred = $.Deferred();

        if (Session.IsSmartDeviceApplication) {
            if (!Session.ResourcesFolder) {
                deferred.resolve(false);
                return;
            }

            Session.ResourcesFolder.getFile(filename,
                { create: false },
                function() { deferred.resolve(true); },
                function() { deferred.resolve(false); }
            );
        } else {
            // (Vor-)Prüfung auf Vorhandensein der Datei wird in Weberfassung nicht durchgeführt
            deferred.resolve(true);
        }

        return deferred.promise();
    }

    export function RemoveTemporaryImages(): Deferred {
        const deferred = $.Deferred();

        if (Session.IsRunningOnIOS) {
            // temporör deaktiviert, da Bildern an Maßnahmen an Prüfpunkten sonst vorm Verschieben gelöscht werden
            // Vorher muss das verhalten des Speicherns von Maßnahmen und Erfassungen überarbeitet werden es passieren zu viele Dinge an verschiedenen Stellen asynchron die hier die temporäaren Bilder entfernen wollen
            deferred.resolve();
            //navigator.camera.cleanup(deferred.resolve, deferred.reject);
        } else {
            deferred.resolve();
        }

        return deferred.promise();
    }

    export function MoveFileToResources(srcFilenamePath: string, targetFilename: string): Deferred {
        const deferred: Deferred = $.Deferred();

        window.resolveLocalFileSystemURL(srcFilenamePath, (imageFile) => {
            window.resolveLocalFileSystemURL(Utils.GetResourcesPath(), (destinationFolder: string) => {
                imageFile.moveTo(destinationFolder, targetFilename, () => {
                    deferred.resolve(targetFilename);
                }, deferred.reject);
            }, deferred.reject);
        }, deferred.reject);

        return deferred;
    }

    export function MoveFile(srcFilenamePath: string, targetPath: string): Deferred {
        const deferred: Deferred = $.Deferred();

        window.resolveLocalFileSystemURL(srcFilenamePath, (file) => {
            window.resolveLocalFileSystemURL(targetPath, (destinationFolder: string) => {
                file.moveTo(destinationFolder, file.name, () => {
                    deferred.resolve(file.name);
                }, deferred.reject);
            }, deferred.reject);
        }, deferred.reject);

        return deferred;
    }

    export function CreateFile(filename: string, content: any[] | any): Deferred {
        let deferred = $.Deferred();
        let lIdx, line;

        if (!filename) {
            throw new Model.Errors.ArgumentError('filename not given');
        }

        if (!content) {
            throw new Model.Errors.ArgumentError('content not given');
        }

        if (!Session.ResourcesFolder) {
            deferred.reject();
            return;
        }

        Session.ResourcesFolder.getFile(filename, { create: true }, function(fileEntry) {
            if (!fileEntry) {
                deferred.reject();
                return;
            }

            fileEntry.createWriter(function(fileWriter) {
                fileWriter.onerror = deferred.reject;

                if (content instanceof Array) {
                    content.unshift('[');
                    content.push(']');
                } else {
                    content = [content];
                }

                lIdx = 0;

                let writeLine = function() {
                    if (content.length <= lIdx) {
                        deferred.resolve();
                        return;
                    }

                    line = content[lIdx++];

                    if (!line) {
                        return;
                    }

                    if (typeof line !== 'string') {
                        line = JSON.stringify(line);

                        if (content.length > lIdx && typeof content[lIdx] !== 'string') {
                            line += ',';
                        }
                    }

                    fileWriter.write(new Blob([line]));
                };

                fileWriter.onwriteend = writeLine;
                writeLine();

            }, deferred.reject);
        }, deferred.reject);

        return deferred.promise();
    }

    export function GetImagesFromGallery(options?: ImagePickerOptions): Deferred {
        options = options || {};

        const deferred: Deferred = $.Deferred();

        window.imagePicker.getPictures(
            function(imagePaths: string[]) {
                if (typeof imagePaths === 'string') {
                    deferred.reject();
                    return;
                }

                if (Session.IsRunningOnIOS) {
                    // Ab iOS > 13 wird am Anfang das file:// nicht mitgeliefert,
                    // wodurch die Stellen die mit dem filePath weiterarbeiten die Datei nicht finden können
                    for (let cnt = 0, len = imagePaths.length; cnt < len; cnt++) {
                        imagePaths[cnt] = Utils.FixIOSImagePickerFilepath(imagePaths[cnt]);
                    }
                }

                deferred.resolve(imagePaths);
            }, function(error) {
                if (error === 'User cancelled.' || options.SuppressError) {
                    deferred.reject(error);
                    return;
                }

                Utils.Message.Show(i18next.t('Misc.ImageSelectionError.MessageHeader'),
                    i18next.t('Misc.ImageSelectionError.MessageBody'),
                    {
                        Close: true
                    })
                    .then(() => deferred.reject(error));
            }, {
                maximumImagesCount: options.MaximumImagesCount || 1,
                // Muss auf iOS gesetzt werden (undokumentiert im Plugin!)
                // ansonsten funktioniert der Picker auf diversen iPads nichts
                disable_popover: Session.IsRunningOnIOS
            }
        );

        return deferred.promise();
    }


    export function GetFileExtension(filePath: string, defaultExtension: string = undefined): string | null {
        if (!filePath) {
            return null;
        }

        const basename = filePath.split(/[\\/]/).pop();
        const pos = basename.lastIndexOf(".");

        if (basename === '' || pos < 1)
            return defaultExtension;

        return basename.slice(pos);
    }

    export function DeleteFile(filename: string): Deferred {
        if (!filename) {
            return $.Deferred().resolve().promise();
        }

        const deferred = $.Deferred();
        const options = { create: false, exclusive: false };

        Session.ResourcesFolder.getFile(filename, options, function(file) {
            if (!file || !file.isFile) {
                deferred.resolve();
                return;
            }

            file.remove(deferred.resolve, deferred.reject);
        }, deferred.reject);

        return deferred.promise();
    }


    export function UploadFile(uri: string, file: any): Deferred {
        const data = new FormData();
        const xhr = new XMLHttpRequest();
        const deferred = $.Deferred();

        // TODO: check correct type for 'file'
        data.append('file', file);

        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4) {
                if (xhr.status === Enums.HttpStatusCode.Ok) {
                    deferred.resolve(xhr.responseText, file.Marks, file.Position || 0);
                } else {
                    deferred.reject(xhr, xhr.status);
                }
            }
        };

        xhr.open(Enums.HttpMethod.Post, uri, true);

        if (Session.LastKnownAPIVersion >= 21 || !Session.IsSmartDeviceApplication) {
            xhr.setRequestHeader('X-Csrf-Token', Utils.Cookies.GetCSRFToken())
            xhr.withCredentials = true;
        }

        xhr.send(data);

        return deferred;
    }


    export function ReadFile(file: Blob, temporaryIdentifier: string, additionalInformation?: any): Deferred {
        let reader = new FileReader();
        let deferred = $.Deferred();

        reader.onloadend = function(evt: ProgressEvent) {
            deferred.resolve({
                Event: evt,
                File: file,
                Identifier: temporaryIdentifier,
                AdditionalInformation: additionalInformation,
                Content: (<any>evt.target).result
            });
        };

        reader.readAsDataURL(file);

        return deferred.promise();
    }

    export function DeleteFiles(filenames: Array<string>): Deferred {
        const deferred = $.Deferred();
        const errors: string[] = [];

        if (!(filenames || []).length) {
            return deferred.resolve().promise();
        }

        (function remove(idx: number) {
            Utils.DeleteFile(filenames[idx])
                .then(null, function() {
                    // fehlerhafte Datei erfassen
                    errors.push(filenames[idx]);
                    // mit dem löschen anderer dateien weitermachen
                    return $.Deferred().resolve();
                })
                .then(function() {
                    if (++idx <= filenames.length) {
                        // nächste Datei löschen
                        setTimeout(() => remove(idx), 5);
                        return;
                    }

                    if (errors.length) {
                        deferred.reject(errors);
                    } else {
                        deferred.resolve();
                    }
                });
        })(0);

        return deferred.promise();
    }

    export function GetValidFilename(filename: string, extension: string): string {
        if (!Utils.IsSet(filename) || !Utils.IsSet(extension)) {
            return null;
        }

        filename = filename.trim();
        extension = extension.trim();

        if (!filename || !extension) {
            return null;
        }

        if (!extension.startsWith('.')) {
            extension = `.${extension}`;
        }

        const maxFilenameLength = 150 - extension.length;  // Länge der Datei darf nicht 150 Zeichen überschreiten

        filename = filename.replace(new RegExp(/[~"#%&*:<>?\/\\{}|]/gm), '').trim();

        if (filename.length > maxFilenameLength) {
            filename = filename.substr(0, maxFilenameLength);
        }

        return filename + extension;
    }

    export function WriteBlobToFile(filename: string, data: any): Deferred {
        const deferred = $.Deferred();

        if (!Utils.IsSet(data)) {
            return deferred.reject().promise();
        }

        Session.ResourcesFolder.getFile(
            filename,
            { create: true },
            (file) => {
                file.createWriter((writer) => {
                    writer.onwrite = () => {
                        deferred.resolve();
                    }

                    writer.onerror = deferred.reject;

                    writer.write(data);
                }, deferred.reject);
            }
        )

        return deferred.promise();
    }

    export function WriteLogFile(filename: string, buffer: ArrayBuffer): Deferred {
        const deferred = $.Deferred();

        if (!buffer || !buffer.byteLength) {
            return deferred.reject().promise();
        }

        Session.ResourcesFolder.getDirectory('logs', { create: true }, function(subDirEntry) {
            subDirEntry.getFile(
                filename,
                { create: true },
                (file) => {
                    file.createWriter((writer) => {
                        writer.onwrite = () => {
                            deferred.resolve();
                        }

                        writer.onerror = deferred.reject;

                        writer.write(buffer);
                    }, deferred.reject);
                }
            )
        }, deferred.reject);

        return deferred.promise();
    }

    export function GetLogFiles(): Deferred {
        const deferred = $.Deferred();

        Session.ResourcesFolder.getDirectory('logs', null,
            function(subDirEntry) {
                const reader = subDirEntry.createReader();
                reader.readEntries(deferred.resolve, deferred.reject);
            }, deferred.reject);

        return deferred.promise();
    }

    export type ExtendedFile = File & OptionalFileAttributes;

    export type OptionalFileAttributes = {
        OID?: string,
        Position?: number,
        Title?: string,
        Description?: string,
        IsTemporary?: boolean,
        Content?: any,

        ModificationType?: Enums.AdditionalImageModificationType,
        MimeType?: string,
        IsImage?: boolean,
        IsAudio?: boolean,
        Filename?: string,
        newFilename?: string
    }

    export type FileInformation = {
        Event: ProgressEvent<FileReader>,
        File: ExtendedFile,
        Identifier: string,
        AdditionalInformation: any,
        Content?: string
    }

    export function FixIOSFilepath(filepath: string): string {
        if (window['WkWebView']) {
            return window['WkWebView'].convertFilePath(filepath);
        }

        return filepath;
    }

    export function FixIOSImagePickerFilepath(filePath: string): string {
        if (!Session.IsRunningOnIOS || filePath.contains(':')) {
            return filePath;
        }

        if (filePath.startsWith('file://')) {
            return filePath;
        }

        return 'file://' + filePath;
    }


    export function IsImage(mimeType: string): boolean {
        return /^image\//i.test(mimeType);
    }

    export function IsAudio(mimeType: string): boolean {
        return /^audio\//i.test(mimeType);
    }

    export function IsVideo(mimeType: string): boolean {
        return /^video\//i.test(mimeType);
    }

    export function ZipFiles(targetFileName: string, files: string[]): Deferred {
        const result = $.Deferred();

        window.JJzip.zipFiles(
            files,
            targetFileName,
            function() {
                result.resolve(targetFileName);
            },
            function(err) { result.reject(err); }
        );

        return result;
    }

    export function GetFreeDiskSpace(): Deferred {
        /// smart-device only!!
        // Gibt die freie Speichergröße in MB zurück.

        const result = $.Deferred();

        if (typeof window['cordova'] === 'undefined') {
            return result.reject();
        }

        cordova.exec(
            function(freeSpace: number) {
                result.resolve(Math.floor(+freeSpace / 1024));
            },
            function() {
                result.reject();
            },
            "File", "getFreeDiskSpace", []
        );

        return result;
    }
}
