//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../app/app.ts" />
/// <reference path="./i-tracker.ts" />
//imports-end

module New.Analytics {

    export class WebTracker implements ITracker {
        private keyValues: Dictionary<any> = {};

        constructor(id: string) {
            // append google analytics script here
            if (!$('#ga-script').length) {
                $(document).find('head')
                    .append(this.getScriptBlock(id));
            }
        }

        getScriptBlock(id: string) {
            return `
            <!-- Global site tag (gtag.js) - Google Analytics -->
            <script id="ga-script" async src="https://www.googletagmanager.com/gtag/js?id=${id}"></script>
            <script>
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${id}', {'app_name': 'awenko:Web', 'app_version': App.GetVersionString()});
            </script>`;
        }

        setValue(key: string, value: string | number | boolean) {
            if (key == "ClientOID" && !Session.Settings.UseAdvancedAnalyticsInformation) {
                this.keyValues[key] = "0000";
            }
            if (key == "IssueID" && !Session.Settings.UseAdvancedAnalyticsInformation) {
                this.keyValues[key] = "N/A";
            }
            else {
                this.keyValues[key] = value;
            }
        }

        trackView(viewName: Enums.View) {
            this.setValue('View', viewName);

            window.gtag('event', 'screen_view', {
                'screen_name': viewName
            });
        }

        logEvent(message: string) {
            window.gtag('event', 'verbose', {
                description: message
            });
        }

        trackError(error?: Error, stackFrames?: StackTraceLine[]) {
            const errorText = this.getString(error.toString(), this.keyValues, stackFrames);

            window.gtag('event', 'exception', {
                description: errorText,
                fatal: this.keyValues["IsFatal"] || false
            });
        }

        private getString(eventName: string, params: Dictionary<any>, stackFrames?: StackTraceLine[]): string {
            return eventName + (stackFrames ? JSON.stringify(stackFrames) : '') + ' (' + JSON.stringify(params) + ')';
        }
    }
}
