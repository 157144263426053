//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="./model.errors.ts"  />
/// <reference path="../enums.ts"  />
/// <reference path="../utils/utils.date-time.ts"  />
/// <reference path="../utils/utils.http.ts"  />
/// <reference path="./model.synchronisation.ts"  />
//imports-end

module Model {
    export interface IComment {
        OID: string;
        Timestamp: Date | string;
        CreationTimestamp: Date | string;
        ModificationTimestamp: Date | string;
        CreatorOID: string;
        EditorOID: string;
        Text: string;
        AssignmentOID: string;   // used for Issue-OID or Recorditem-OID, depending on Type
        AssignmentID?: number;   // used for Issue-ID or Recorditem-ID, depending on Type
        Type: Enums.CommentType;

        IsTemporary?: boolean;  // in-app use only
        IsDeleted?: boolean;    // in-app use support mail use only
        IssueID?: number;       // in-app and support mail use only
        IssueOID?: string;      // in-app and support mail use only
    }

    export class Comment implements IComment {
        OID: string;
        Timestamp: Date | string;
        CreationTimestamp: Date | string;
        ModificationTimestamp: Date | string;
        CreatorOID: string;
        EditorOID: string;
        Text: string;
        AssignmentOID: string;   // used for Issue-OID or Recorditem-OID, depending on Type
        AssignmentID?: number;   // used für Issue-ID or Recorditem-ID, depending on Type
        Type: Enums.CommentType;

        IsTemporary?: boolean;  // in-app use only
        IsDeleted?: boolean;    // in-app use support mail use only
        IssueID?: number;       // in-app and support mail use only
        IssueOID?: string;      // in-app and support mail use only
        IsEditable?: boolean;    // in-app use only
        IsCopy?: boolean;    // in-app use only
        OriginalComment?: string;    // in-app use only

        private static propsToCopy = [
            "OID",
            "Timestamp",
            "CreationTimestamp",
            "ModificationTimestamp",
            "CreatorOID",
            "EditorOID",
            "Text",
            "AssignmentOID",
            "AssignmentID",
            "Type",
            "IsDeleted",
            /* [IsTemporary] not required here */
            "IssueID",
            "IssueOID"
        ];

        constructor(comment: IComment)
        constructor(identifier: string, creationTimestamp: Date, modificationTimestamp: Date, creatorIdentifier: string, editorIdentifier: string, text: string, assignmentIdentifier: string, type: Enums.CommentType)
        constructor(identifier: string | any, creationTimestamp?: Date, modificationTimestamp?: Date, creatorIdentifier?: string, editorIdentifier?: string, text?: string, assignmentIdentifier?: string, type?: Enums.CommentType) {
            if (typeof identifier !== 'string' && typeof identifier['OID'] !== 'undefined') {
                const rawComment = <any>identifier;
                // copy values from raw comment
                for (let i = 0; i < Comment.propsToCopy.length; i++) {
                    const prop = Comment.propsToCopy[i];
                    if (!rawComment.hasOwnProperty(prop) &&
                        typeof rawComment[prop] != 'undefined') {
                        continue;
                    }
                    if ((prop === 'Timestamp' || prop === 'CreationTimestamp' || prop === 'ModificationTimestamp') &&
                        rawComment[prop] != null) {
                        this[prop] = new Date(rawComment[prop]);
                    } else {
                        this[prop] = rawComment[prop];
                    }
                }

                if (!this.EditorOID) {
                    this.EditorOID = this.CreatorOID;
                }

                if (this.CreationTimestamp == null) {
                    this.CreationTimestamp = new Date((<Date>this.Timestamp).getTime());
                }

                if (this.ModificationTimestamp == null) {
                    this.ModificationTimestamp = new Date((<Date>this.CreationTimestamp).getTime());
                }
            } else {
                this.OID = identifier || null;
                this.CreationTimestamp = creationTimestamp || new Date();
                this.ModificationTimestamp = modificationTimestamp || new Date();
                this.CreatorOID = creatorIdentifier || null;
                this.EditorOID = editorIdentifier || creatorIdentifier || null;
                this.Text = text || '';
                this.AssignmentOID = assignmentIdentifier || null;
                this.Type = type;
            }

            if (!this.AssignmentOID) {
                throw new Model.Errors.ArgumentNullError('AssignmentOID must be defined');
            }

            if (typeof this.Type === 'undefined' || this.Type == null) {
                throw new Model.Errors.ArgumentError('Type must be defined');
            }
        }

        private onAfterSavedToDatabase(): Deferred {
            const entityDescription = new Model.Synchronisation.CommentEntityDescription(this);

            return window.Database
                .SetInStorage(Enums.DatabaseStorage.SyncEntities, entityDescription);
        }

        private onAfterDeletedFromDatabase(): Deferred {
            const entityDescription = new Model.Synchronisation.DeleteCommentEntityDescription(this);

            return window.Database
                .SetInStorage(Enums.DatabaseStorage.SyncEntities, entityDescription);
        }

        private saveToServer(): Deferred {
            return Utils.Http.Put(`comments/${this.OID}`, this.GetPreparedForSync())
                .then(() => this); // return current item as result
        }

        private deleteFromServer(): Deferred {
            return Utils.Http.Delete(`comments/${this.OID}`, this.GetPreparedForSync())
                .then(() => this, // return current item as result
                    function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    });
        }

        private saveToDatabase(): Deferred {
            return window.Database
                .SetInStorageNoChecks(Enums.DatabaseStorage.Comments, this.GetRawEntity())
                .then(() => this.onAfterSavedToDatabase())
                .then(() => this);  // return current item as result
        }

        private deleteFromDatabase(): Deferred {
            // add deleted flag
            this.IsDeleted = true;

            return window.Database
                .SetInStorageNoChecks(Enums.DatabaseStorage.Comments, this.GetRawEntity())
                .then(() => this.onAfterDeletedFromDatabase())
                .then(() => this);  // return current item as result
        }

        public GetPreparedForSync(): Dictionary<any> {
            const entity = this.GetRawEntity();
            if (entity) {
                // remove app-only values
                delete entity.IsTemporary;
                delete entity.IsDeleted;
                delete entity.IssueID;
                delete entity.IssueOID;
            }
            return entity;
        }

        public GetRawEntity(): Dictionary<any> {
            const entity = <Dictionary<any>>{};

            for (let attr in this) {
                if (this.hasOwnProperty(attr)) {
                    let value: any = this[attr];

                    if (value instanceof Function) {
                        continue;
                    }

                    entity[attr] = value;
                }
            }

            // Timestamp Fix
            if (this.Timestamp) {
                entity.Timestamp = Utils.DateTime.ToGMTString(this.Timestamp);
            }

            if (this.CreationTimestamp) {
                entity.CreationTimestamp = Utils.DateTime.ToGMTString(this.CreationTimestamp);
            }

            if (this.ModificationTimestamp) {
                entity.ModificationTimestamp = Utils.DateTime.ToGMTString(this.ModificationTimestamp);
            }

            return entity;
        }

        public Save(): Deferred {
            if (!(this.Text || '').trim()) {
                return $.Deferred().reject();
            }

            if (!Session.IsSmartDeviceApplication) {
                return this.saveToServer();
            } else {
                return this.saveToDatabase();
            }
        }

        public Delete(): Deferred {
            if (!Session.IsSmartDeviceApplication) {
                return this.deleteFromServer();
            } else {
                return this.deleteFromDatabase();
            }
        }
    }
}
