//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Model.Floor {
    interface IMarkOptions {
        ID: number,
        FloorPlanID: string
        $FloorPlan: any,
        RawCoordinates: { X: number, Y: number }

        Color?: string,
        StrokeColor?: string,
        Type?: number,
        IsDeleted?: boolean,
        CreateNewMark?: boolean
        ZoomLevel?: number
    }

    export class Mark {
        private static readonly SVG_MARKER_WIDTH: number = 20;
        private static readonly SVG_MARKER_HEIGHT: number = 32;

        private static readonly STROKE_WIDTH: number = 2;
        private static readonly ACTIVE_STROKE_COLOR: string = '#FF0000';

        public Markup: string;
        public IsDeleted: boolean;
        public RawCoordinates: { X: number, Y: number };

        public readonly ID: number;
        public readonly FloorPlanID: string;
        public readonly $FloorPlan: any;
        public readonly Type: number;

        private readonly StrokeColor: string;
        private readonly Color: string;

        constructor(options: IMarkOptions) {
            this.ID = options.ID;
            this.FloorPlanID = options.FloorPlanID;
            this.$FloorPlan = options.$FloorPlan;
            this.RawCoordinates = options.RawCoordinates;


            if (this.RawCoordinates.X == null) {
                this.RawCoordinates.X = 0.5;
            } else if (this.RawCoordinates.X < 0) {
                this.RawCoordinates.X = 0;
            } else if (this.RawCoordinates.X > 1) {
                this.RawCoordinates.X = 1;
            }

            if (this.RawCoordinates.Y == null) {
                this.RawCoordinates.Y = 0.5;
            } else if (this.RawCoordinates.Y < 0) {
                this.RawCoordinates.Y = 0;
            } else if (this.RawCoordinates.Y > 1){
                this.RawCoordinates.Y = 1;
            }

            if (options.IsDeleted) {
                this.IsDeleted = true;
                return;
            }

            this.StrokeColor = options.StrokeColor || '#FFFFFF';
            this.Color = options.Color || '#75A1D6';
            this.Type = options.Type || 1;

            this.initMark(options.ZoomLevel);
        }

        public static GetScale(floorPlanWidth: number, zoomLevel: number): number {
            // 32x20 marker is optimum for 1024x768 floor plan
            const base = (Mark.SVG_MARKER_WIDTH / 1024 * floorPlanWidth / Mark.SVG_MARKER_WIDTH)
                            * (Session.IsSmartDeviceApplication ? 2 : 1);

            return Math.min(Math.max(base / (zoomLevel || 1) * 1.5, base * 0.5), base * 4)
        }

        public BindEvents(): void {
            const $floorPlan = this.$FloorPlan;
            const $mark = $floorPlan.find(`.mark[data-id="${this.ID}"]`);

            $mark.off('mousedown touchstart');
            $mark.on('mousedown touchstart', (evt) => {
                evt.stopPropagation();

                this.onMouseDown(evt);
            });
        };

        public BindDeleteOnClickEvent(): void {
            const $mark = this.$FloorPlan.find(`.mark[data-id="${this.ID}"]`);

            $mark.off('mousedown touchstart');
            $mark.on('mousedown touchstart', (evt) => {
                this.onMouseDownDeleteMark(evt);
            });
        };

        private initMark(zoomLevel: number): void {
            const innerSvg = this.$FloorPlan.find('g > svg').get(0);
            const viewBox = innerSvg.getAttribute('viewBox').split(' ');

            let x = this.RawCoordinates.X;
            let y = this.RawCoordinates.Y;

            const scale = Mark.GetScale(parseInt(viewBox[2], 10), zoomLevel);

            let coordinates = {
                X: x * parseInt(viewBox[2], 10),
                Y: y * parseInt(viewBox[3], 10)
            };

            // noinspection HtmlUnknownAttribute
            const svg = [
                `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" data-id="${this.ID}"
                      x="${coordinates.X}" y="${coordinates.Y}" width="${Mark.SVG_MARKER_WIDTH}" height="${Mark.SVG_MARKER_HEIGHT}"
                      stroke="${this.StrokeColor}" stroke-width="${Mark.STROKE_WIDTH}" class="mark draggable">`,
                `<path fill="${this.Color}"
                           d="M16 0c-5.523 0-10 4.477-10 10 0 10 10 22 10 22s10-12 10-22c0-5.523-4.477-10-10-10zM16 16c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z"
                           transform="scale(${scale}) translate(-16, -33)" />`,
                '</svg>'
            ];

            // Displays the stored position that should be at the bottom point of the marker
            if (App.DEBUG_ENABLED && false) {
                const $existing = this.$FloorPlan.find(`.debug-mark[data-idDebug="${this.ID}"]`);

                if ($existing.length) {
                    $existing.slice(1).remove();

                    $existing.attr('x', coordinates.X - 5);
                    $existing.attr('y', coordinates.Y - 5);
                }

                // noinspection HtmlUnknownAttribute
                svg.push(
                    `<svg data-iddebug="${this.ID}" class="debug-mark" xmlns="http://www.w3.org/2000/svg" version="1.1" x="${coordinates.X - 5}" y="${coordinates.Y - 5}" height="10" width="10">`);
                svg.push(
                    `<line x1="${0}" y1="${5}" x2="${10}" y2="${5}" style="stroke: rgba(255, 0, 0, 0.2); stroke-width: 2" />`);
                svg.push(
                    `<line x1="${5}" y1="${0}" x2="${5}" y2="${10}" style="stroke: rgba(255, 0, 0, 0.2); stroke-width: 2" />`);
                svg.push('</svg>');
            }

            this.Markup = svg.join('');
            this.RawCoordinates = {X: x, Y: y};
        }

        private onMouseDown(evt: any): void {
            const $marker = $(evt.currentTarget);
            const marker = $marker.get(0);
            const $floorPlan = this.$FloorPlan;
            const svg = $floorPlan.find('g > svg').get(0);
            const image = $(svg).find('> image').get(0);
            let rawCoordinates: { X: number, Y: number };

            evt.preventDefault();

            $marker.attr('stroke', Mark.ACTIVE_STROKE_COLOR)
                   .siblings('.mark').attr('stroke', this.StrokeColor);

            $floorPlan.on('mousemove touchmove', (evt) => {
                let pt = marker.createSVGPoint();
                const viewBox = svg.getAttribute('viewBox').split(' ');
                const touchEvt = (evt.originalEvent.touches || [])[0];
                const transform = image.getScreenCTM().inverse();
                const viewPortWidth = parseInt(viewBox[2], 10);
                const viewPortHeight = parseInt(viewBox[3], 10);

                evt.preventDefault();
                evt.stopPropagation();
                evt.stopImmediatePropagation();

                pt.x = (touchEvt || evt).pageX;
                pt.y = (touchEvt || evt).pageY;

                pt = pt.matrixTransform(transform);

                if (pt.x <= 0) {
                    pt.x = 1;
                } else if (pt.x >= viewPortWidth) {
                    pt.x = viewPortWidth - 1;
                }

                if (pt.y <= 0) {
                    pt.y = 1;
                } else if (pt.y >= viewPortHeight){
                    pt.y = viewPortHeight - 1;
                }

                marker.setAttribute('x', pt.x);
                marker.setAttribute('y', pt.y);

                if (App.DEBUG_ENABLED) {
                    if (marker.nextSibling && marker.nextSibling.getAttribute('data-iddebug') === marker.getAttribute(
                        'data-id')) {
                        marker.nextSibling.setAttribute('x', pt.x - 5);
                        marker.nextSibling.setAttribute('y', pt.y - 5);
                    }
                }

                rawCoordinates = {
                    X: pt.x / viewPortWidth,
                    Y: pt.y / viewPortHeight
                };
            });

            $floorPlan.one('mouseup touchend touchcancel', (evt) => {
                evt.preventDefault();

                if (rawCoordinates) {
                    this.RawCoordinates = rawCoordinates;
                }

                this.$FloorPlan.off('mousemove touchmove keyup');
                $marker.attr('stroke', this.StrokeColor);
            });
        }

        private onMouseDownDeleteMark(evt: any): void {
            evt.preventDefault();
            evt.stopPropagation();

            this.IsDeleted = true;
            this.$FloorPlan.find(`svg[data-id="${this.ID}"]`).remove();

            if (App.DEBUG_ENABLED) {
                this.$FloorPlan.find(`svg[data-iddebug="${this.ID}"]`).remove();
            }
        }
    }
}