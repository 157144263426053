/// <reference path="../../enums.ts"  />

abstract class LoginBase {
    protected successDeferred: Deferred;
    protected userData: UserAccount;

    protected lastKnownApiVersion: number;

    protected $loginFormContainer;
    protected $loginForm;
    protected $txtUsername;
    protected $txtPassword;
    protected $btnBaseURI;
    protected $clientNameRow;
    protected $txtClientName;
    protected $baseUriRow;
    protected $btnUnlockUsername;
    protected $btnScanServiceQRCode;
    protected $cbRememberMe;
    protected $btnLogin;

    public Start(): Deferred {
        this.successDeferred = $.Deferred();

        // TODO move outside or make it unnecessary in #4.0
        Utils.SetMode(Enums.Mode.Login);

        this.checkExistingUser()
            .then((account: UserAccount, syncFromServer: boolean) => {
                Session.ActiveSession = true;

                // request for password change on reload if required
                if (account.User.ChangePassword) {
                    return this.requestPasswordChange(account, syncFromServer);
                }

                return this.OnSuccess(account, syncFromServer);
            }, () => {
                this.showDialog();
            });

        return this.successDeferred.promise();
    }

    protected abstract checkExistingUser(): Deferred

    protected showDialog(): Deferred {
        if (this.$loginFormContainer) {
            this.$loginFormContainer.remove();
        }

        this.$loginFormContainer = $(Templates.LoginDialog());

        $('body').append(this.$loginFormContainer);

        this.$loginForm = this.$loginFormContainer.find('#login');
        this.$clientNameRow = this.$loginForm.find('.row.client-name');
        this.$txtClientName = this.$clientNameRow.find('.client-name');
        this.$txtUsername = this.$loginForm.find('#txtUsername');
        this.$txtPassword = this.$loginForm.find('#txtPassword');
        this.$btnBaseURI = this.$loginForm.find('.btn-set-baseuri');
        this.$baseUriRow = this.$loginForm.find('.row.baseuri-row');
        this.$btnUnlockUsername = this.$loginForm.find('.btn-unlock-username');
        this.$btnScanServiceQRCode = this.$loginForm.find('.icon-qrcode').parent();
        this.$cbRememberMe = this.$loginForm.find('#cbRememberMe');
        this.$btnLogin = this.$loginForm.find('button.btn-login');

        this.resizeLoginForm();

        this.bindEvents();

        const showDeferred = $.Deferred();
        setTimeout(() => {
            this.$loginForm.addClass('show');
            setTimeout(() => {
                showDeferred.resolve();
            }, 500);
        }, 10);
        return showDeferred.promise();
    }

    protected hideDialog(): Deferred {
        if (this.$loginForm) {
            const animationDefer = $.Deferred();
            this.unbindEvents();

            setTimeout(() => {
                this.$loginForm.removeClass('show');

                setTimeout(() => {
                    this.$loginFormContainer.remove();
                    this.$loginFormContainer = null;
                    this.$loginForm = null;
                    animationDefer.resolve();
                }, 500);
            }, 10);
            return animationDefer.promise();
        }

        return $.Deferred().resolve().promise();
    }

    protected bindEvents(): void {
        this.$txtUsername.on('keypress', (evt: KeyboardEvent) => {
            if (evt.keyCode === Enums.KeyCode.RETURN) {
                evt.preventDefault();
                this.$txtPassword.focus();
            }
        });

        this.$txtPassword.on('keypress', (evt: KeyboardEvent) => {
            if (evt.keyCode === Enums.KeyCode.RETURN) {
                evt.preventDefault();
                this.tryLogin();
            }
        });

        this.$loginForm.find('.btn-login').on('click.login', (evt) => {
            evt.preventDefault();
            this.tryLogin()
        });

        // resize event
        $(window).on('resize.login', () => {
            this.resizeLoginForm();
        })

        this.$loginForm.find('.show-advanced-settings').on('click.login', () => {
            New.Login.ShowSettingsDialog();
        });
    }

    protected unbindEvents(): void {
        this.$txtUsername.off('keypress');
        this.$txtPassword.off('keypress');
        this.$loginForm.find('.btn-login').off('click.login');
        $(window).off('resize.login');
        this.$loginForm.find('.show-advanced-settings').off('click.login');
    }

    protected resizeLoginForm() {
        this.$loginForm.css('max-width', $(window).outerWidth() - 40);
        const windowHeight = $(window).height();
        const dialogHeight = this.$loginForm.innerHeight();
        this.$loginForm.css('margin-top', dialogHeight > windowHeight ? (dialogHeight - windowHeight) / 2 : '');
    }

    protected getHash(userName: string, pw: string): string {
        return (`${userName}:${pw}`).toBase64();
    }

    protected userHasLoginRight(account: UserAccount): boolean {
        return account.User.LicenseType == null || account.User.LicenseType !== Enums.LicenseType.ViewRight;
    }

    protected abstract beforeSessionStart(username: string, password): Deferred

    protected onSessionStarted(previousClient: any, account: UserAccount, status, responseInformation: XMLHttpRequest) {
        if (previousClient && previousClient.OID !== account.Client.OID) {
            Session.Client = previousClient;
            return $.Deferred().resolve(account).promise();
        }

        Session.ActiveSession = true;

        if (account.User.ChangePassword) {
            return this.requestPasswordChange(account);
        }

        this.lastKnownApiVersion = responseInformation ?
            parseInt(Utils.Http.GetResponseHeader(responseInformation, 'API-Version', '1'), 10) :
            1;

        const syncFromServer = !this.userData || !this.userData.Client;
        this.userData = account;

        if (this.lastKnownApiVersion >= 16 && account && account.User && !this.userHasLoginRight(account)) {
            if (Utils.Message.IsVisible()) {
                Utils.Message.Hide();
            }

            Utils.Message.Show(
                i18next.t('Login.LoginFailed.MessageHeader'),
                i18next.t('Login.LoginFailed.LoginNotAllowed'),
                {
                    Close: true
                }
            );

            return $.Deferred().reject().promise();
        }

        return this.OnSuccess(account, syncFromServer);
    }

    protected tryLogin(): Deferred {
        const username = this.$txtUsername.val().trim();
        const password = this.$txtPassword.val().trim();

        if (!username.length) {
            this.$txtUsername.focus();
            return $.Deferred().reject().resolve();
        } else if (!password.length) {
            this.$txtPassword.focus();
            return $.Deferred().reject().resolve();
        }

        // show loading in button & disable button
        this.$btnLogin.attr('disabled', 'disabled').addClass('busy');
        this.$txtUsername.attr('disabled', 'disabled');
        this.$txtPassword.attr('disabled', 'disabled');

        const previousClient = this.userData ? this.userData.Client : null;

        return this.beforeSessionStart(username, password)
            .then(Session.Start)
            .then((account: UserAccount, status, responseInformation: XMLHttpRequest) => this.onSessionStarted(previousClient, account, status, responseInformation))
            .fail((xhr: XMLHttpRequest) => {
                Utils.OnLoginError(xhr, $.proxy(Utils.OnLoginError, this, this.onCloseErrorMessage));
                this.$btnLogin.removeClass('busy').removeAttr('disabled');
                this.$txtUsername.removeAttr('disabled');
                this.$txtPassword.removeAttr('disabled');
            });
    }

    protected requestPasswordChange(account: UserAccount, syncFromServer?: boolean): Deferred {
        syncFromServer = syncFromServer || !this.userData || !this.userData.Client;

        return Utils.PasswordEditor.Show(account.User)
            .then((newPassword) => {
                if (Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion < 21) {
                    Session.AuthHash = this.getHash(account.User.Username, newPassword);
                }

                delete account.User.ChangePassword;

                this.userData = account;
                return this.OnSuccess(account, syncFromServer);
            });
    }

    protected OnSuccess(account: UserAccount, syncFromServer: boolean): Deferred {
        return this.hideDialog().then(() => {
            if (this.successDeferred) {
                this.successDeferred.resolve(account, syncFromServer, this.lastKnownApiVersion);
            }

            return $.Deferred().resolve(account, syncFromServer, this.lastKnownApiVersion).promise();
        });
    }

    protected onCloseErrorMessage() {
        this.$txtPassword.focus();
    }
}
