/// <reference path="../definitions.d.ts"  />

module Utils.VoiceRecorder {
    let _$win, _$overlay;
    let _$recordingInformation;
    let _$btnStartRecording, _$btnStopPlayback;
    let _$btnAbort, _$btnOkay, _$btnClose;

    let _voiceRecorder;
    let _pathToRecording, _filenameOfRecording, _alternativeFilenameOfRecording;
    let _durationTimer, _durationOfRecordingInSeconds: number;
    let _callback: VoiceRecorderCallback;
    let _isPlayMode;

    export type VoiceRecorderCallback = (metaData: VoiceRecorderMetaData) => void;

    export type VoiceRecorderMetaData = {
        Filename: string,
        FullPath: string,
        MimeType: string
    }

    function destroy() {
        if (_durationTimer) {
            clearInterval(_durationTimer);
            _durationTimer = null;
        }

        if (_voiceRecorder) {
            if (_$btnStartRecording.hasClass('is-recording')) {
                _voiceRecorder.stop();
            }

            _voiceRecorder.release();
            _voiceRecorder = null;
        }

        if (_$win) {
            _$win.remove();
            _$win = null;
        }

        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }

        _$recordingInformation = null;
        _$btnStartRecording = null;
        _$btnStopPlayback = null;
        _$btnAbort = null;
        _$btnOkay = null;
        _$btnClose = null;

        _pathToRecording = null;
        _filenameOfRecording = null;
        _alternativeFilenameOfRecording = null;
        _durationOfRecordingInSeconds = null;
        _callback = null;

        _isPlayMode = false;

        $('body').removeClass('modal-open');
    }

    function onRecordingStopped() {
        _$btnStartRecording.removeClass('is-recording').attr('disabled', 'disabled');
        _$btnStopPlayback.attr('disabled', 'disabled');

        _voiceRecorder.release();

        _$btnAbort.removeClass('hidden');
        _$btnOkay.removeClass('hidden');
        _$btnClose.addClass('hidden');

        clearInterval(_durationTimer);

        _durationTimer = null;
        _durationOfRecordingInSeconds = 0;

        if (Session.IsRunningOnIOS) {
            Utils.Spinner.Show();

            window.requestFileSystem(LocalFileSystem.TEMPORARY, 0, function(filesystem) {
                filesystem.root.getFile(_filenameOfRecording, { create: false }, function(fileEntry) {
                    fileEntry.moveTo(Session.ResourcesFolder, _filenameOfRecording, Utils.Spinner.Hide);
                }, Utils.Spinner.Hide);
            }, Utils.Spinner.Hide);
        }
    }

    function onVoiceRecorderError(error) {
        Utils.Spinner.Hide();

        Utils.Message.Show(i18next.t('VoiceRecorder.RecordingError.MessageHeader'),
            error.message,
            {
                Close: true
            }, null, 10002);

        _$btnStartRecording.removeClass('is-recording');
    }

    function onRecordingStarted() {
        _$btnStartRecording.addClass('is-recording');
        _$btnStopPlayback.removeAttr('disabled');

        _durationOfRecordingInSeconds = 0;
        _$recordingInformation.text(i18next.t('VoiceRecorder.Duration', {
            Duration: '00:00'
        }));

        _durationTimer = setInterval(function() {
            var humanReadableDuration;
            var seconds, minutes;

            ++_durationOfRecordingInSeconds;

            seconds = Utils.PadLeft(_durationOfRecordingInSeconds % 60, 2);
            minutes = Utils.PadLeft(Math.floor(_durationOfRecordingInSeconds / 60), 2);

            humanReadableDuration = `${minutes}:${seconds}`;

            _$recordingInformation.text(i18next.t('VoiceRecorder.Duration', {
                Duration: humanReadableDuration
            }));
        }, 1000);
    }

    function onBtnStartRecordingClick() {
        var $this = $(this);

        if ($this.hasClass('is-recording') || !!_filenameOfRecording || $this.attr('disabled')) {
            return;
        }

        if (Session.IsRunningOnIOS) {
            _pathToRecording = ''; //default: 'documents://tmp/';
        } else {
            _pathToRecording = Utils.GetResourcesPath();
        }

        _filenameOfRecording = uuid() + '.m4a';
        _pathToRecording += _filenameOfRecording;

        _voiceRecorder = new Media(_pathToRecording, onRecordingStopped, onVoiceRecorderError);

        _voiceRecorder.startRecord();
        onRecordingStarted();
    }

    function onBtnStopPlaybackClick() {
        if (!$(this).attr('disabled')) {
            _voiceRecorder.stopRecord();
        }
    }

    function onBtnAbortClick() {
        if (!!_filenameOfRecording && !_isPlayMode) {
            Utils.DeleteFile(_filenameOfRecording)
                .then(destroy)
                .fail(destroy);
        } else {
            destroy();
        }
    }

    function onBtnOkayClick() {
        if (_callback instanceof Function) {
            _callback({
                Filename: _filenameOfRecording,
                FullPath: _pathToRecording,
                MimeType: 'audio/mp4'
            });
        }

        destroy();
    }

    function onRecordAudioPermissionIsGranted() {
        initVariables();
        appendWindow();
        bindEvents();
    }

    function onRecordAudioPermissionMissing() {
        Utils.Message.Show(i18next.t('VoiceRecorder.MissingRecordAudioPermission.MessageHeader'),
            i18next.t('VoiceRecorder.MissingRecordAudioPermission.MessageBody'),
            {
                OK: true
            },
            null,
            10002);
    }

    function requestPermission() {
        Utils.RequestPermission(cordova.plugins.permissions.RECORD_AUDIO)
            .then(onRecordAudioPermissionIsGranted)
            .fail(onRecordAudioPermissionMissing);
    }

    function initVariables() {
        let defaultResourcePath, alternativeResourcePath;

        if (!!_filenameOfRecording) {
            defaultResourcePath = Session.IsSmartDeviceApplication ?
                Utils.GetResourcesPath() + _filenameOfRecording :
                `${Session.BaseURI}/files/${_filenameOfRecording}`;
        }

        if (!!_alternativeFilenameOfRecording) {
            alternativeResourcePath = Session.IsSmartDeviceApplication ?
                Utils.GetResourcesPath() + _alternativeFilenameOfRecording :
                `${Session.BaseURI}/files/${_alternativeFilenameOfRecording}`;
        }

        if (Session.IsRunningOnIOS) {
            defaultResourcePath = Utils.FixIOSFilepath(defaultResourcePath);
            alternativeResourcePath = Utils.FixIOSFilepath(alternativeResourcePath);
        }

        _$win = $(Templates.VoiceRecorder.Window({
            DefaultResourcePath: defaultResourcePath,
            AlternativeResourcePath: alternativeResourcePath
        }));

        _$overlay = Utils.Overlay.Generate('olVoiceRecorder', 10000);

        _$recordingInformation = _$win.find('.recording-information');

        _$btnStartRecording = _$win.find('.btn-start-recording');
        _$btnStopPlayback = _$win.find('.btn-stop-playback');

        _$btnAbort = _$win.find('.btn-abort');
        _$btnOkay = _$win.find('.btn-ok');
        _$btnClose = _$win.find('.btn-close');
    }

    function appendWindow() {
        $('body').addClass('modal-open').append(_$win);

        Utils.RepositionNewModalWindow(_$win);
    }

    function bindEvents() {
        _$btnStartRecording.on('click', onBtnStartRecordingClick);
        _$btnStopPlayback.on('click', onBtnStopPlaybackClick);
        _$btnAbort.on('click', onBtnAbortClick);
        _$btnClose.on('click', destroy);
        _$btnOkay.on('click', onBtnOkayClick);
    }

    export function Show(callback: VoiceRecorderCallback) {
        _callback = callback;

        Utils.IsPermissionGranted(cordova.plugins.permissions.RECORD_AUDIO)
            .then(onRecordAudioPermissionIsGranted)
            .fail(requestPermission);
    }

    export function Play(filename: string, alternativeFilename: string): void {
        _filenameOfRecording = filename;
        _alternativeFilenameOfRecording = alternativeFilename;
        _isPlayMode = true;

        initVariables();
        appendWindow();
        bindEvents();

        _$btnStartRecording.attr('disabled', 'disabled');
    }
}
