//imports-start
/// <reference path="./element.ts"  />
//imports-end

module Model.Elements {
    export class ResubmissionElementTree {

        public Root: Model.Elements.Element;
        public TopElement: Model.Elements.Element;
        public FormRoot: Model.Elements.Element;
        public Form: Model.Elements.Element;
        public ElementsByOID: Dictionary<Model.Elements.Element[]>;
        public Revisions: Dictionary<Model.Elements.Element[]>;
        public DistinctRevisions: Dictionary<Model.Elements.Element>;
        public List: Model.Elements.Element[];
        public UnfilteredElements: Dictionary<Model.Elements.Element>;

        constructor(elements: Model.Elements.Element[], targetRootOID?: string) {
            this.init(elements, targetRootOID);
        }

        public GetElementByOID(oid: string, row?: number, rowOptional?: boolean): Model.Elements.Element {
            if (oid && this.ElementsByOID[oid]) {
                if (!rowOptional) {
                    return this.ElementsByOID[oid][row || 0];
                } else {
                    // sucht auch alternativ Elemente ohne Row (z.b. Parent)
                    return this.ElementsByOID[oid][row || 0] || this.ElementsByOID[oid][0];
                }
            }

            return null;
        }

        public GetElementByRevisionOID(oid: string, row?: number, rowOptional?: boolean): Model.Elements.Element {
            if (oid && this.Revisions[oid]) {
                if (!rowOptional) {
                    return this.Revisions[oid][row || 0];
                } else {
                    // sucht auch alternativ Elemente ohne Row (z.b. Parent)
                    return this.Revisions[oid][row || 0] || this.Revisions[oid][0];
                }
            }

            return null;
        }

        protected init(elements: Model.Elements.Element[], targetRootOID?: string) {
            this.Root = null;
            this.FormRoot = null;
            this.Form = null;
            this.TopElement = null;

            if (!elements || !elements.length) {
                this.ElementsByOID = null;
                this.Revisions = null;
                this.DistinctRevisions = null;
                this.List = null;
                return;
            }

            // Elemente Array kopieren
            this.List = elements.slice();
            this.ElementsByOID = {};
            this.Revisions = {};
            this.DistinctRevisions = {};

            // Dictionaries und Listen erstellen für Schnellzugriff
            for (const element of elements) {
                if (!element) {
                    continue;
                }

                this.insertElement(element);

                // Root ermitteln
                if (element.Type === Enums.ElementType.Root) {
                    this.Root = element;
                }
            }

            this.TopElement = this.createRelations(true);

            // prüfen ob TopElement ein Formular ist (!= FormRoot)
            if (this.TopElement && this.TopElement.Type === Enums.ElementType.Form) {
                this.Form = this.TopElement;
            }

            // prüfen ob das ermittelte TopElement der freigegebenen Root-OE entspricht, ansonsten ändern
            const limitRootElement = targetRootOID ? (this.ElementsByOID[targetRootOID] || [])[0] : null;
            if (limitRootElement && !this.isChildOfOrSame(this.TopElement, limitRootElement)) {
                this.TopElement = limitRootElement;
            }

            // FormRoot finden
            if (this.Root) {
                this.FormRoot = this.getFormRoot();

                // prüfen ob der ermittelte Root der freigegebenen Root-OE entspricht, ansonsten ändern
                if (limitRootElement && !this.isChildOfOrSame(this.Root, limitRootElement)) {
                    this.Root = limitRootElement;
                }
            } else {
                // TopElement als alternativen Root setzen (Root benötigt für einige Stellen)
                this.Root = this.TopElement;
            }

            // Prüfpunkte vom Typ Email-Adresse entfernen, wenn Lizenz nicht vorhanden
            if (!Utils.IsEMailCpEnabled()) {
                this.removeEmailCheckpoints(this.TopElement);
            }
        }

        private insertElement(element: Model.Elements.Element) {
            // Elemente nach OID vormerken
            this.ElementsByOID[element.OID] = this.ElementsByOID[element.OID] || [];
            this.ElementsByOID[element.OID][element.Row || 0] = element;

            // Elemente nach RevisionOID vormerken
            this.Revisions[element.RevisionOID] = this.Revisions[element.RevisionOID] || [];
            this.Revisions[element.RevisionOID][element.Row || 0] = element;

            if ((element.Row || 0) === 0) {
                // nur Elemente ohne Row vormerken
                this.DistinctRevisions[element.RevisionOID] = element;
            }
        }

        public addElement(element: Model.Elements.Element) {
            if (!element) {
                return;
            }

            this.insertElement(element);

            // alle Kindelemente hinzufügen
            let elementsToAppend = [];
            if (element.Parametergroups) {
                elementsToAppend = elementsToAppend.concat(element.Parametergroups);
            }
            if (element.Parameters) {
                elementsToAppend = elementsToAppend.concat(element.Parameters);
            }
            if (element.Children) {
                elementsToAppend = elementsToAppend.concat(element.Children);
            }

            for (let i = 0; i < elementsToAppend.length; i++) {
                const child = elementsToAppend[i];
                this.addElement(child);
            }
        }

        private isChildOfOrSame(supposedChildElement: Model.Elements.Element, parentElement: Model.Elements.Element): boolean {
            if (!supposedChildElement || !parentElement) {
                return false;
            }

            do {
                if (supposedChildElement.OID === parentElement.OID) {
                    return true;
                }

                supposedChildElement = supposedChildElement.Parent;
            } while (supposedChildElement);

            return false;
        }

        private getFormRoot(): Model.Elements.Element | null {
            if (!this.Root || !this.Root.Children) {
                return null;
            }

            for (let i = 0; i < this.Root.Children.length; i++) {
                const element = this.Root.Children[i];

                // Erstes Child des Root vom Typ Form ist FormRoot
                if (element.Type === Enums.ElementType.Form) {
                    return element;
                }
            }

            return null;
        }

        private removeEmailCheckpoints(element: Model.Elements.Element) {
            if (!element) {
                return;
            }

            for (let gCnt = (element.Parametergroups || []).length - 1; gCnt > -1; gCnt--) {
                const group = element.Parametergroups[gCnt];

                // Email Prüfpunkte entfernen
                for (let pCnt = (group.Parameters || []).length - 1; pCnt > -1; pCnt--) {
                    if (group.Parameters[pCnt].Type === Enums.ElementType.EMailAddresses) {
                        group.Parameters.splice(pCnt, 1);
                    }
                }

                // Leere Parameter-Gruppen entfernen
                if ((group.Parameters || []).length === 0) {
                    element.Parametergroups.splice(gCnt, 1);
                }
            }

            (element.Children || []).forEach(this.removeEmailCheckpoints, this);
        }

        public createRelations(collapseAllElements?: boolean): Model.Elements.Element | null {
            /**
            * Verknüpfung zwischen Children and Parent generieren.
            * @returns: Root Element.
            */
            if (!this.List) {
                return null;
            }

            let presumableTopLevelElement: Model.Elements.Element;

            for (const element of this.List) {
                if (!element) {
                    continue;
                }

                const parentOID: string = element.ParentOID;

                if (!parentOID) {
                    presumableTopLevelElement = element;
                    continue;
                }

                if (element.Type != Enums.ElementType.Root && !!parentOID) {
                    const parent: Model.Elements.Element = this.GetElementByOID(parentOID, element.Row, true);

                    if (!parent) {
                        presumableTopLevelElement = element;
                        continue;
                    }

                    element.Parent = parent;

                    if (element.Type === Enums.ElementType.Location ||
                        element.Type === Enums.ElementType.Form) {
                        // Alle Element Hierarchie Typen (OEs, Formulare)
                        parent.Children = parent.Children || [];
                        parent.Children.push(element);

                        parent.IsCollapsed = collapseAllElements || false;
                    } else if (element.Type >= Enums.ElementType.SingletonFormRow && element.Type <= Enums.ElementType.Parametergroup) {
                        // Alle Parametergruppen Typen
                        parent.Parametergroups = parent.Parametergroups || [];
                        parent.Parametergroups.push(element);
                    } else if (element.Type >= Enums.ElementType.Checkbox) {
                        // Alle Parameter Typen >= Enums.ElementType.Checkbox
                        parent.Parameters = parent.Parameters || [];
                        parent.Parameters.push(element);
                    }
                }
            }

            // Daten sortieren
            for (const element of this.List) {
                if (!element) {
                    continue;
                }

                if (element.Children) {
                    element.Children.sort(Utils.SortByPosition);
                }
                if (element.Parametergroups) {
                    element.Parametergroups.sort(Utils.SortByPosition);
                }
                if (element.Parameters) {
                    element.Parameters.sort(Utils.SortByPosition);
                }
            }

            return presumableTopLevelElement;
        }

        public removeRelations() {
            if (!this.List || !this.List.length) {
                return;
            }

            for (let i = 0; i < this.List.length; i++) {
                const element = this.List[i];

                delete element.Children;
                delete element.Parametergroups;
                delete element.Parameters;
                delete element.Parent;

                if (element.Type >= 93 && element.Type <= 99) {
                    element.IsCollapsed = true;
                } else {
                    element.IsCollapsed = false;
                }
            }
        }
    }
}
