//imports-start
/// <reference path="../issues/issue.ts"  />
//imports-end

module Model.Calendar {
    export class MonthViewItem {
        Timestamp: number;
        DayOfMonth: number;
        Classes: string;
        IssueCounter?: number;

        SetTimestamp(timestamp: number): this {
            this.Timestamp = timestamp;

            return this;
        }

        SetDayOfMonth(value: number): this {
            this.DayOfMonth = value;

            return this;
        }

        SetClasses(classes: string): this {
            this.Classes = classes;

            return this;
        }

        SetIssueCounter(counter?: number): this {
            if (isNaN(counter)) {
                counter = null;
            }

            this.IssueCounter = counter;
            return this;
        }
    }
}