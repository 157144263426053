//imports-start
/// <reference path="../core/revision.ts"  />
/// <reference path="./raw-file.ts"  />
/// <reference path="./file-metrics.ts"  />
/// <reference path="../../dal/files.ts"  />
//imports-end

module Model.Files {
    export class File extends Model.Core.Revision {
        Title: string;
        Filename: string;
        FilePath: string;
        Tag: number;
        IsAvailableOffline: boolean;
        FolderOID?: string;
        Description?: string;
        MimeType?: string;
        SizeOriginal?: string;
        Marks?: any;
        SizeS?: string;
        SizeM?: string;
        SizeL?: string;
        Metrics?: FileMetrics;
        IsImage: boolean;
        IsAudio: boolean;
        IsVideo: boolean;
        IsEmbeddedVideo: boolean;
        Type: number;
        URL: string;
        Position: number;
        VideoID?: string;
        Prioritize?: boolean;

        constructor(file: RawFile) {
            super();

            if (!file) {
                return;
            }

            this.initProperties(file);
        }

        initProperties(file: RawFile): void {
            this.OID = file.OID;
            this.RevisionOID = file.RevisionOID;
            this.Deleted = file.Deleted;
            this.Title = file.Title;
            this.Filename = file.Filename;
            this.FilePath = this.getFilePath();
            this.Tag = file.Tag;
            this.IsAvailableOffline = file.IsAvailableOffline;
            this.FolderOID = file.FolderOID;
            this.Description = file.Description;
            this.MimeType = file.MimeType;
            this.Type = file.Type;
            this.URL = file.URL;
            this.VideoID = file.VideoID;
            this.SizeOriginal = file.SizeOriginal;
            this.SizeS = file.SizeS;
            this.SizeM = file.SizeM;
            this.SizeL = file.SizeL;
            this.Metrics = this.getMetrics();

            if (Utils.IsImage(this.MimeType)) {
                this.IsImage = true;
            } else if (Utils.IsAudio(this.MimeType)) {
                this.IsAudio = true;
            } else if (this.isVideo()) {
                this.IsVideo = true;

                if (this.isEmbeddedVideo()) {
                    this.IsEmbeddedVideo = true;
                }
            }
        }

        private isVideo(): boolean {
            return this.isEmbeddedVideo() ||
                Utils.InArray(DAL.Files.GetVideoFileExtensions(), Utils.GetFileExtension(this.Filename));
        }

        private isEmbeddedVideo(): boolean {
            return this.Type === Enums.FileType.VimeoPublic ||
                this.Type === Enums.FileType.VimeoPrivate ||
                this.Type === Enums.FileType.Youtube;
        }

        private getMetrics(): FileMetrics {
            const metrics = new FileMetrics();

            if (!!this.SizeS) {
                const tmp = this.SizeS.split('x');
                const height = parseInt(tmp[1], 10);
                const width = parseInt(tmp[0], 10);

                metrics.SetSizeS(new FileDimensions(height, width));
            }

            if (!!this.SizeM) {
                const tmp = this.SizeM.split('x');
                const height = parseInt(tmp[1], 10);
                const width = parseInt(tmp[0], 10);

                metrics.SetSizeM(new FileDimensions(height, width));
            }

            if (!!this.SizeL) {
                const tmp = this.SizeL.split('x');
                const height = parseInt(tmp[1], 10);
                const width = parseInt(tmp[0], 10);

                metrics.SetSizeL(new FileDimensions(height, width));
            }

            if (!!this.SizeOriginal) {
                const tmp = this.SizeOriginal.split('x');
                const height = parseInt(tmp[1], 10);
                const width = parseInt(tmp[0], 10);

                metrics.SetSizeO(new FileDimensions(height, width));
            }

            return metrics;
        }

        private getFilePath(): string {
            if (!Session.IsSmartDeviceApplication) {
                return `${Session.BaseURI}images/s/${this.Filename}`;
            }

            return Utils.GetResourcesPath() + this.Filename;
        }
    }
}
