module Utils.ParameterSelection {
    export type Options = {
        groupFilterExpression?: (group: Model.Elements.Element) => boolean,
        paramFilterExpression?: (param: Model.Elements.Element) => boolean,
        itemClickHandler: (param: Model.Elements.Element) => void,
        groups: Model.Elements.Element[],
        title: string,
        infoText: string,
        width: number
        onClose?: () => void,
    };

    export type ListItemGroup = {
        Title: string,
        Parameters: ListItemParameter[]
    };

    export type ListItemParameter = {
        Title: string,
        Type: Enums.ElementType,
        Identifier: string,
        Markup: string
    };


    export abstract class BaseParameterSelectionWindow {
        private $win;
        private $overlay;
        private $btnAbort;
        private uniqueWindowIdentifier: string;

        protected width: number;
        protected parameters: Dictionary<Model.Elements.Element>;
        protected listItemGroups: ListItemGroup[];
        protected listItemParameterTemplate: string;
        protected readonly groupFilterExpression?: (group: Model.Elements.Element) => boolean;
        protected readonly paramFilterExpression?: (param: Model.Elements.Element) => boolean;
        protected readonly itemClickHandler: (param: Model.Elements.Element) => void;
        protected readonly onClose: () => void;
        protected readonly groups: Model.Elements.Element[];
        protected readonly title: string;

        protected readonly infoText: string;

        constructor(options: Options) {
            this.uniqueWindowIdentifier = uuid();
            this.parameters = {};

            this.width = options.width || 330;
            this.groupFilterExpression = options.groupFilterExpression;
            this.paramFilterExpression = options.paramFilterExpression;
            this.itemClickHandler = options.itemClickHandler;
            this.onClose = options.onClose;
            this.groups = options.groups;
            this.title = options.title;
            this.infoText = options.infoText;
            this.listItemParameterTemplate = '<button class="btn btn-default parameter" data-identifier="{Identifier}">{Title}<p class="small">{Type}</p></button>';
        }

        Show(): BaseParameterSelectionWindow {
            this.listItemGroups = this.createListItems();

            if (!this.listItemGroups.length) {
                Utils.Toaster.Show(i18next.t('ParameterList.NoSuitableCPFound'), .2);
                return;
            }

            this.$win = $(Templates.Selections.ParameterSelectionWindow({
                Title: this.title,
                InfoText: this.infoText,
                Groups: this.listItemGroups
            }));

            this.$overlay = Utils.Overlay.Generate('olParameterSelection', 1102);
            this.$btnAbort = this.$win.find('.btn-abort');

            const $body = $('body');

            $body.append(this.$win);
            $body.addClass('modal-open');

            this.bindEvents();
            this.resize();

            return this;
        }

        public static IsVisible(): boolean {
            return $('#parameter-selection-window').is(':visible');
        }

        public Close(): void {
            this.destroy();
        }

        private destroy(): void {
            if (this.$win) {
                this.$win.remove();
                this.$win = null;
            }

            if (this.$overlay) {
                Utils.Overlay.DestroyWithTimeout(this.$overlay);
                this.$overlay = null;
            }

            this.$btnAbort = null;

            $(window).off(`resize.${this.uniqueWindowIdentifier}`);
            $('body').removeClass('modal-open');

            if (this.onClose) {
                this.onClose();
            }
        }

        protected abstract createListItems(): ListItemGroup[];

        protected parseTemplate(context: any): string {
            let markup = this.listItemParameterTemplate;
            let match;

            while ((match = /\{\w+\}/g.exec(markup))) {
                markup = markup.replace(match[0], context[match[0].replace(/[\{\}]/g, '')] || '-/-');
            }

            return new Handlebars.SafeString(markup);
        }

        private bindEvents(): void {
            this.$win.find('.body .btn').on('click', this.onListItemClick);
            this.$btnAbort.on('click', this.onAbortClick);
            $(window).on(`resize.${this.uniqueWindowIdentifier}`, this.resize.bind(this));
        }

        private onListItemClick = (evt: Event): void => {
            const $btn = $(evt.currentTarget);
            const ident = $btn.data('identifier');
            const param = this.parameters[ident];

            this.itemClickHandler(param);
            this.destroy();
        };

        private onAbortClick = (): void => {
            this.destroy();
        };

        private resize(): void {
            this.$win.css('width', this.width);

            const windowBodyHeight = this.calculateWindowBodyHeight();
            let windowHeight =
                this.$win.find('.header').outerHeight() +
                windowBodyHeight +
                this.$win.find('.footer').outerHeight();

            const viewportWidth = $(window).width();
            const maxWindowHeight = $(window).height() - 60;
            const maxWindowWidth = viewportWidth - (viewportWidth < 400 ? 10 : 60);

            if (windowHeight > maxWindowHeight) {
                windowHeight = maxWindowHeight;
            }

            this.$win.css({
                height: windowHeight,
                'max-height': maxWindowHeight,
                'max-width': maxWindowWidth
            });

            this.$win.css({
                'margin-top': -(windowHeight / 2),
                'margin-left': -(330 / 2)
            });
        }

        private calculateWindowBodyHeight(): number {
            const $body = this.$win.find('.body');
            let windowBodyHeight = 0;

            $body.children().toArray().forEach(function(child) {
                windowBodyHeight += $(child).outerHeight(true);
            });

            windowBodyHeight += parseInt($body.css('padding-top'), 10);
            windowBodyHeight += parseInt($body.css('padding-bottom'), 10);

            return windowBodyHeight;
        }
    }
}
