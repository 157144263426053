/// <reference path="../definitions.d.ts"  />
/// <reference path="../enums.ts"/>


module Utils {
    export class Toaster {
        private static IsVisible: boolean = false;
        private static Queue: Array<{ Message: string, Duration: number, Icon: Enums.Toaster.Icon }> = [];
        private static $toaster: any = $(Templates.Toast.Default());

        public static Show(message: string, duration: number = 0.5, icon: Enums.Toaster.Icon = Enums.Toaster.Icon.Default) {
            Toaster.Queue.push({ Message: message, Duration: duration, Icon: icon });

            if (!Toaster.IsVisible) {
                Toaster.ShowNext();
            }
        }

        private static ShowNext() {
            if (!Toaster.Queue.length) {
                Toaster.$toaster.detach();
                return;
            }

            Toaster.$toaster.css({
                'margin-left': 0
            });

            Toaster.ShowToast(Toaster.Queue.shift());
        }

        private static ShowToast(toast: { Message: string, Duration: number, Icon: Enums.Toaster.Icon }) {
            Toaster.IsVisible = true;

            $('body').append(Toaster.$toaster);

            Toaster.$toaster.html(toast.Message);
            Toaster.$toaster.addClass('show');

            const width = $(document).innerWidth() - Toaster.$toaster.outerWidth();

            Toaster.$toaster.css({
                'margin-left': (width / 2)
            });

            Toaster.$toaster.removeClass('icon info warning success');

            switch (toast.Icon) {
                case Enums.Toaster.Icon.Info:
                    Toaster.$toaster.addClass('icon info');
                    break;
                case Enums.Toaster.Icon.Warning:
                    Toaster.$toaster.addClass('icon warning');
                    break;
                case Enums.Toaster.Icon.Success:
                    Toaster.$toaster.addClass('icon success');
                    break;
            }

            window.setTimeout(Toaster.HideToast, toast.Duration * 1000);
        }

        private static HideToast() {
            Toaster.$toaster.removeClass('show');

            Toaster.IsVisible = false;

            window.setTimeout(Toaster.ShowNext, 2000);
        }
    }
}