//imports-start
/// <reference path="../definitions.d.ts" />
/// <reference path="./utils.http.ts" />
//imports-end

module Utils.FileEditor {
    let _$btnApply, _$btnClose;
    let _$fileTitle, _$fileDescription;
    let _$overlay, _$win;
    let _fileTitle, _fileDescription;
    let _settings: FileEditorSettings;
    let _readonly: boolean;
    let _hideCloseButton: boolean;
    let _allowImmediateSave: boolean;

    let _file: File | Model.Files.File;
    let closeDeferred: Deferred;

    export type FileEditorSettings = {
        Title: string;
        Description: string;
        Readonly: boolean;
        zIndex?: number;
        HideCloseButton: boolean;
        AllowImmediateSave: boolean;
        File: ExtendedFile | Model.Files.File;

        OnSave?: (title: string, description: string) => void;
    };

    function initVariables(settings: FileEditorSettings): Deferred {
        const initDeferred = $.Deferred();

        if (!settings) {
            return initDeferred.reject().promise();
        }

        _settings = settings;
        _fileTitle = settings.Title;
        _fileDescription = settings.Description;
        _readonly = settings.Readonly;
        _hideCloseButton = settings.HideCloseButton;
        _allowImmediateSave = settings.AllowImmediateSave;

        _file = settings.File;

        const zIndex = Utils.IsSet(settings.zIndex)
            ? settings.zIndex
            : 2000;

        _$overlay = Utils.Overlay.Generate(
            'olFileEditor', zIndex - 1, onBtnCloseClick
        );

        const readerDeferred = _file instanceof File ?
            readFile(_file) :
            $.Deferred().resolve().promise();

        readerDeferred.then(base64 => {
            let fileInfo;

            if (_file) {
                if (_file instanceof File) {
                    fileInfo = {
                        MimeType: _file.type,
                        IsImage: Utils.IsImage(_file.type),
                        Base64Content: base64
                    };
                } else {
                    fileInfo = {
                        MimeType: _file.MimeType,
                        IsImage: Utils.IsImage(_file.MimeType),
                        Filename: _file.Filename,
                        FilePath: _file.FilePath
                    }
                }
            }

            _$win = $(Templates.FileEditor({
                Title: _fileTitle,
                Description: _fileDescription,
                IsReadonly: _readonly,
                File: fileInfo
            }));

            _$win.css('z-index', zIndex - 1);

            _$fileTitle = _$win.find('[data-id="fileTitle"]');
            _$fileDescription = _$win.find('[data-id="fileDescription"]');

            _$btnClose = _$win.find('.close');
            _$btnApply = _$win.find('.apply');

            initDeferred.resolve();
        });

        return initDeferred.promise();
    }

    function readFile(file: File): Deferred {
        const deferred = $.Deferred();
        const reader = new FileReader();

        reader.onloadend = () => {
            deferred.resolve(reader.result);
        };

        reader.onerror = deferred.reject;

        reader.readAsDataURL(file);

        return deferred.promise();
    }

    function setButtonVisibility() {
        _$btnApply.removeAttr('disabled');

        if (!_allowImmediateSave) {
            if (_fileTitle === _settings.Title &&
                _fileDescription === _settings.Description) {
                _$btnApply.attr('disabled', 'disabled');
            }
        }
    }

    function destroy() {
        unbindEvents();

        if (_$win) {
            _$win.remove();
            _$win = null;
        }

        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }

        _$fileTitle = null;
        _$fileDescription = null;
        _$btnClose = null;
        _$btnApply = null;
    }

    function showWindow() {
        $('body').append(_$win);

        _$fileTitle.focus();

        if (_hideCloseButton) {
            _$btnClose.addClass('hidden');
        }

        Utils.RepositionNewModalWindow(_$win);

        if (_allowImmediateSave) {
            setButtonVisibility();
        }
    }

    function onBtnCloseClick() {
        destroy();

        closeDeferred.reject();
    }

    function onBtnSaveClick() {
        destroy();

        if (_settings.OnSave instanceof Function) {
            _settings.OnSave(_fileTitle, _fileDescription);
        }

        closeDeferred.resolve(_fileTitle, _fileDescription);
    }

    function onFileTitleInput() {
        _fileTitle = $.trim($(this).val());

        setButtonVisibility();
    }

    function onFileTitleKeyPress(evt) {
        if (evt.keyCode === Enums.KeyCode.RETURN) {
            evt.preventDefault();
            _$fileDescription.focus();
        }
    }

    function onFileDescriptionInput() {
        _fileDescription = $.trim($(this).val());

        setButtonVisibility();
    }

    function onImageClick(evt: Event): void {
        const $this = $(evt.currentTarget);
        const uri = $this.attr('src');

        Utils.OpenTemporaryImages([{
            Filename: uri,
            OID: uuid(),
            MimeType: Enums.MimeType.Image,
            Title: $.trim(_$fileTitle.val())
        }], uri);
    }

    function onImageLoaded() {
        Utils.RepositionNewModalWindow(_$win);
    }

    function bindEvents() {
        _$btnClose.on('click', onBtnCloseClick);
        _$btnApply.on('click', onBtnSaveClick);
        _$fileTitle.on('input', onFileTitleInput);
        _$fileDescription.on('input', onFileDescriptionInput);
        _$fileTitle.on('keypress', onFileTitleKeyPress);
        _$win.on('keypress', onFileTitleKeyPress);

        _$win.find('img')
            .on('click', onImageClick)
            .on('load', onImageLoaded)
            .on('error', Utils.OnImageNotFound);
    }

    function unbindEvents() {
        _$btnClose.off('click');
        _$btnApply.off('click');
        _$fileTitle.off('input');
        _$fileDescription.off('input');
        _$fileTitle.off('keypress');
        _$fileDescription.off('keypress');

        _$win.find('img')
            .off('click')
            .off('load')
            .off('error');
    }

    export function Show(settings): Deferred {
        closeDeferred = $.Deferred();

        initVariables(settings)
            .then(() => {
                showWindow();
                unbindEvents();
                bindEvents();
            });

        return closeDeferred;
    }
}
