//imports-start
/// <reference path="../definitions.d.ts" />
//imports-end

module Utils {

    export type MakeTableOptions = { CalculateColWidth?: Function, DecoupleHorizontalScrollbar?: boolean };
    export type DecoupleHorizontalScrollbarOptions = { $VerticalScrollContainer?: any, $Wrapper?: any, BottomOffset?: Function };

    export function MakeTableScrollable($table, $scrollContainer?, options: MakeTableOptions = {}): void {
        if ($table == null || $table.length <= 0) {
            return;
        }

        if ($table.find('thead').length <= 0 && $table.find('th').length <= 0) {
            return;
        }

        // Clone <thead>
        const $w = $(window);
        const $t = $table;
        const $thead = $t.find('thead').clone();
        const $col = $t.find('thead, tbody').clone();
        const tableIdentifier = uuid();

        // Add class, remove margins, reset width and wrap table
        $t
            .attr('data-identifier', tableIdentifier)
            .data('identifier', tableIdentifier)
            .addClass('sticky-enabled')
            .css({ margin: 0 })
            .wrap('<div class="sticky-wrap" />');

        if ($t.hasClass('overflow-y')) {
            $t.removeClass('overflow-y').parent().addClass('overflow-y');
        }

        if ($t.find('tbody th').length > 0 && Session.Settings.TabularSubsampleShowNumber) {
            // If <tbody> contains <th>, then we create sticky table head, sticky column and intersect (advanced)
            $t.after('<table class="sticky-thead hidden" /><table class="sticky-col hidden" /><table class="sticky-intersect hidden" />');
        } else {
            // Create new sticky table head (basic)
            $t.after('<table class="sticky-thead hidden" />');
        }

        // Create shorthand for things
        const $stickyHead = $table.siblings('.sticky-thead');
        const $stickyCol = $table.siblings('.sticky-col');
        const $stickyInsct = $table.siblings('.sticky-intersect');
        const $stickyWrap = $table.parent('.sticky-wrap');
        const table = {
            tr: $t.find('tr'),
            th: $t.find('thead th'),
            col: $t.find('col')
        };

        $stickyHead.append($thead);
        $stickyCol.append($col);

        const stickyInsectionHTML = [];

        $stickyCol.find('thead th:gt(0)').remove()
            .end()
            .find('tbody td').remove();

        const sLen = $stickyCol.find('tbody tr:first-child th').length;
        for (let sCnt = 0; sCnt < sLen; sCnt++) {
            stickyInsectionHTML.push(table.th[sCnt].outerHTML)
        }

        $stickyInsct.html('<thead><tr>' + stickyInsectionHTML.join('') + '</tr></thead>');

        const stickyHead = {
            tr: $stickyHead.find('tr'),
            th: $stickyHead.find('th')
        };
        const stickyCol = {
            tr: $stickyCol.find('tr'),
            th: $stickyCol.find('th')
        };
        const stickyInsct = {
            tr: $stickyInsct.find('tr'),
            th: $stickyInsct.find('th')
        };

        // Set widths
        const setWidths = () => {
            const $colgroups = $stickyWrap.find('.sticky-enabled colgroup col');
            const colgroupLength = $colgroups.length;

            if (options != null && options.CalculateColWidth != null) {
                const $blindTable = $stickyWrap.clone();
                $blindTable.addClass('blindtable').css('margin-top', -$stickyWrap.height());
                $blindTable.children(':not(.sticky-enabled)').remove();
                $stickyWrap.parent().append($blindTable);

                const calculatedWidths = options.CalculateColWidth($blindTable.find('.sticky-enabled'));

                $blindTable.remove();

                for (let key in calculatedWidths) {
                    if (!calculatedWidths.hasOwnProperty(key) || colgroupLength <= key) {
                        continue;
                    }

                    const width = calculatedWidths[key];
                    const $colgroup = $colgroups.eq(key);
                    $colgroup.width(width).data('width', width).attr('data-width', width);
                }
            }

            for (let tCnt = 0; tCnt < colgroupLength; tCnt++) {
                const cell = table.th[tCnt];
                const boundingClientRect = cell.getBoundingClientRect();
                stickyHead.th.eq(tCnt).css('width', boundingClientRect.width);
            }

            const trLen = table.tr.length;
            for (let trCnt = 0; trCnt < trLen; trCnt++) {
                const cell = table.tr[trCnt];
                const boundingClientRect = cell.getBoundingClientRect();

                stickyCol.tr.eq(trCnt).css('height', boundingClientRect.height);
            }

            // Set width of sticky table col
            const firstColumn = table.th[0];
            const boundingClientRect = firstColumn.getBoundingClientRect();
            stickyCol.th.add(stickyInsct.th).css('width', boundingClientRect.width);
            $stickyCol.css('width', boundingClientRect.width);
            $stickyInsct.css('width', boundingClientRect.width);
            $stickyInsct.css('height', boundingClientRect.height);
            stickyInsct.tr.css('height', boundingClientRect.height);
        };

        const calcAllowance = () => {
            let a = 0;
            // Calculate allowance
            $t.find('tbody tr:lt(3)').each(function() {
                a += $(this).height();
            });

            // Set fail safe limit (last three row might be too tall)
            // Set arbitrary limit at 0.25 of viewport height, or you can use an arbitrary pixel value
            if (a > $w.height() * 0.25) {
                a = $w.height() * 0.25;
            }

            // Add the height of sticky header
            a += $stickyHead.height();
            return a;
        };

        const repositionStickyHead = () => {
            // Return value of calculated allowance
            const allowance = calcAllowance();

            const offset = $scrollContainer ? $scrollContainer[0].getBoundingClientRect().top : 0;
            const $elements = $stickyHead.add($stickyInsct);
            const tHeight = $t.height();
            const stickyWrapHeight = $stickyWrap.height();

            // Check if wrapper parent is overflowing along the y-axis
            if (tHeight > stickyWrapHeight) {
                const stickyWrapScrollTop = $stickyWrap.scrollTop();

                // If it is overflowing (advanced layout)
                // Position sticky header based on wrapper scrollTop()
                if (stickyWrapScrollTop > 0) {
                    // When top of wrapping parent is out of view
                    if ($elements.hasClass('hidden')) {
                        $elements.removeClass('hidden');
                    }

                    $elements.css({ top: offset + stickyWrapScrollTop });
                } else {
                    // When top of wrapping parent is in view
                    $stickyHead.addClass('hidden');
                    $elements.css({ top: 0 });
                }
            } else {
                const wScrollTop = $w.scrollTop();
                const tOffsetTop = $t.offset().top;
                const tOuterHeight = $t.outerHeight();
                // If it is not overflowing (basic layout)
                // Position sticky header based on viewport scrollTop
                if (wScrollTop > tOffsetTop && wScrollTop < tOffsetTop + offset + tOuterHeight - allowance) {
                    // When top of viewport is in the table itself
                    if ($elements.hasClass('hidden')) {
                        $elements.removeClass('hidden');
                    }

                    $elements.css({ top: offset + wScrollTop - tOffsetTop });
                } else {
                    // When top of viewport is above or below table
                    $stickyHead.addClass('hidden');
                    $elements.css({ top: 0 });
                }
            }
        };

        const repositionStickyCol = () => {
            const $elements = $stickyCol.add($stickyInsct);

            if ($stickyWrap.scrollLeft() > 0) {
                // When left of wrapping parent is out of view
                if ($elements.hasClass('hidden')) {
                    $elements.removeClass('hidden');
                }

                $elements.css({ left: $stickyWrap.scrollLeft() });
            } else {
                // When left of wrapping parent is in view
                $stickyCol.addClass('hidden');
                $elements.css({ left: 0 });
            }
        };

        const unbindEvents = () => {
            $w.off(`resize.${tableIdentifier}`);
            $t.off('resize');
            $w.off(`load.${tableIdentifier}`);
        };

        setWidths();

        $w.on(`resize.${tableIdentifier}`, $.debounce(250, () => {
            if (!document.body.contains($t[0])) {
                unbindEvents();
                return;
            }

            setWidths();
            repositionStickyHead();
            repositionStickyCol();
        }));

        $w.on(`load.${tableIdentifier}`, 'img', $.debounce(250, () => {
            if (!document.body.contains($t[0])) {
                unbindEvents();
                return;
            }

            setWidths();
            repositionStickyHead();
            repositionStickyCol();
        }));

        $t.resize($.debounce(250, function(evt) {
            evt.stopPropagation();

            setWidths();
            repositionStickyHead();
            repositionStickyCol();
        }));

        if ($t.parent('.sticky-wrap').hasClass('overflow-y')) {
            $t.parent('.sticky-wrap').scroll($.throttle(250, function(evt) {
                repositionStickyHead();
                repositionStickyCol();
            }));
        } else {
            if ($scrollContainer) {
                $scrollContainer.scroll($.throttle(250, repositionStickyHead));
            } else {
                $w.scroll($.throttle(250, repositionStickyHead));
            }

            $stickyWrap.scroll($.throttle(250, function(evt) {
                repositionStickyCol();
            }));
        }

        if (options.DecoupleHorizontalScrollbar) {
            DecoupleHorizontalScrollbar($stickyWrap, { $VerticalScrollContainer: $scrollContainer });
        }
    }

    export function DecoupleHorizontalScrollbar($scrollContainer: any, options: DecoupleHorizontalScrollbarOptions = {}) {
        $scrollContainer = $scrollContainer instanceof $ ? $scrollContainer : $($scrollContainer);
        let $scrollbar = $('<div class="fixedScrollbar"></div>');
        let $fakeContent = $('<div class="fakeContent"></div>');
        let scrollContainer = $scrollContainer[0];
        let eventUUID = uuid();

        if (options == null) {
            options = {};
        } else {
            if (options.$VerticalScrollContainer != null) {
                options.$VerticalScrollContainer = options.$VerticalScrollContainer instanceof $ ? options.$VerticalScrollContainer : $(options.$VerticalScrollContainer);
            }

            if (options.$Wrapper != null) {
                options.$Wrapper = options.$Wrapper instanceof $ ? options.$Wrapper : $(options.$Wrapper);
            }
        }

        function dispose() {
            $(window).off(`scroll.dHS${eventUUID}`);

            if ($scrollbar != null) {
                $scrollbar.off(`scroll.dHS${eventUUID}`);
            }

            if ($scrollContainer != null) {
                $scrollContainer.off(`scroll.dHS${eventUUID}`);
            }

            if (options.$VerticalScrollContainer != null) {
                options.$VerticalScrollContainer.off(`scroll.dHS${eventUUID}`);
            }

            scrollContainer = undefined;
            $scrollbar = undefined;
            $fakeContent = undefined;
            eventUUID = undefined;
        }

        function setPositionAndSize() {
            if ($scrollbar == null || $scrollbar.closest('body').length <= 0) {
                dispose();
                return;
            }

            if ($scrollContainer == null || $scrollContainer.closest('body').length <= 0) {
                $scrollbar.remove();
                dispose();
                return;
            }

            const clientRect = scrollContainer.getBoundingClientRect();
            let wrapperRect;

            if (options.$VerticalScrollContainer == null) {
                wrapperRect = document.documentElement.getBoundingClientRect();
            } else {
                wrapperRect = options.$VerticalScrollContainer[0].getBoundingClientRect();
            }

            if (clientRect.top > wrapperRect.bottom || clientRect.bottom < wrapperRect.bottom) {
                $scrollbar.addClass('hidden');
            } else {
                $scrollbar.removeClass('hidden');
            }

            $scrollbar.css('left', clientRect.left);
            $scrollbar.css('top', wrapperRect.bottom - GetScrollBarWidth());
            $scrollbar.css('width', clientRect.width);
            $fakeContent.css('width', scrollContainer.scrollWidth + 'px');
        }

        $scrollbar.on(`scroll.dHS${eventUUID}`, function() {
            setPositionAndSize();
            $scrollContainer.scrollLeft($scrollbar.scrollLeft());
        });

        $scrollContainer.on(`scroll.dHS${eventUUID}`, function() {
            setPositionAndSize();
            $scrollbar.scrollLeft($scrollContainer.scrollLeft());
        });

        $(window).on(`resize.dHS${eventUUID}`, function() {
            setPositionAndSize();
        });

        if (options.$VerticalScrollContainer != null) {
            options.$VerticalScrollContainer.on(`scroll.dHs${eventUUID}`, () => {
                setPositionAndSize();
            });
        }

        if (options.$Wrapper == null) {
            $scrollbar.insertAfter($scrollContainer);
        } else {
            $(options.$Wrapper).append($scrollbar);
        }

        $scrollbar.append($fakeContent);
        setPositionAndSize();
    }

    export function GetScrollBarWidth(): number {
        const inner = document.createElement('p');
        inner.style.width = '100%';
        inner.style.height = '200px';

        const outer = document.createElement('div');
        outer.style.position = 'absolute';
        outer.style.top = '0px';
        outer.style.left = '0px';
        outer.style.visibility = 'hidden';
        outer.style.width = '200px';
        outer.style.height = '150px';
        outer.style.overflow = 'hidden';
        outer.appendChild(inner);

        document.body.appendChild(outer);
        let w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        let w2 = inner.offsetWidth;
        if (w1 == w2) w2 = outer.clientWidth;

        document.body.removeChild(outer);

        return (w1 - w2);
    }

}
