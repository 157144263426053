//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="./model.errors.ts"  />
/// <reference path="./model.comment.ts"  />
/// <reference path="../app/app.session.ts"  />
/// <reference path="../dal/issues.ts"  />
//imports-end

module Model {
    export class TemporaryIssue {
        private fileCount: number;
        Issue: Model.Issues.RawIssue | Model.Issues.Issue;
        IssueFiles: Dictionary<any>;
        DependingOnOID: string;
        NewComments: Model.IComment[];

        constructor(issue: Model.Issues.Issue | Model.Issues.RawIssue, newIssueFiles: Dictionary<any>, dependingOnOID: string) {
            if (!issue) {
                throw new Model.Errors.ArgumentNullError('issue is needed');
            }

            this.Issue = issue;
            this.IssueFiles = newIssueFiles;

            if (Session.IsSmartDeviceApplication) {
                this.DependingOnOID = dependingOnOID;
            }
        }

        private onAfterFileUploaded(uploadedFileInformation, marks: any[], filePosition: number, transferredFiles: any[]): void {
            if (!uploadedFileInformation) {
                return;
            }

            const f = JSON.parse(uploadedFileInformation);

            f.Marks = marks;
            f.Position = filePosition;

            if (f.MimeType.contains('image/') && f.Metadata) {
                f.Metrics = this.prepareFileMetrics(f.Metadata);
            }

            if (Utils.IsImage(f.MimeType)) {
                f.IsImage = true;
            } else if (Utils.IsAudio(f.MimeType)) {
                f.IsAudio = true;
            } else if (Utils.IsVideo(f.MimeType)) {
                f.IsVideo = true;
            }

            transferredFiles.push(f);
        }

        private createAndSaveSyncEntity(): Deferred {
            const entityDescription = new Model.Synchronisation.IssuesEntityDescription(
                this.Issue, this.DependingOnOID
            );

            return window.Database
                .SetInStorage(Enums.DatabaseStorage.SyncEntities, entityDescription);
        }

        private saveComments(): Deferred {
            const isSmartDevice = Session.IsSmartDeviceApplication;
            const deferred = $.Deferred();
            const issue = this.Issue;

            if (this.NewComments && this.NewComments.length > 0) {
                (function saveComment(idx: number, unsavedComments: Model.IComment[]) {
                    const rawComment = unsavedComments[idx];

                    let comment: Model.Comment;
                    if (rawComment instanceof Model.Comment) {
                        comment = rawComment;
                    } else {
                        comment = new Model.Comment(rawComment);
                    }

                    // set issue as helper information
                    if (isSmartDevice) {
                        if (issue.ID) {
                            if (!comment.IssueID) {
                                comment.IssueID = issue.ID;
                            }
                        }
                        else if (!comment.IssueOID && issue.OID) {
                            comment.IssueOID = issue.OID;
                        }
                    }

                    function loop() {
                        if (idx === unsavedComments.length - 1) {
                            deferred.resolve(issue);
                        } else {
                            saveComment(++idx, unsavedComments);
                        }
                    }

                    comment
                        .Save()
                        .fail(function(_response, _state, _error) {
                            deferred.reject();
                            throw new Model.Errors.HttpError(_error, _response);
                        })
                        .always(loop);

                })(0, this.NewComments);
            } else {
                deferred.resolve(issue);
            }

            return deferred.promise();
        }

        private applyIssueProgressInformation(): void {
            const issueProgressInformation = DAL.Issues.GetIssueProcessingInformation(this.Issue.StateOID, this.Issue.IsArchived, <Date>this.Issue.DeadlineTimestamp);

            (<Model.Issues.Issue>this.Issue).IssueCellMarkerClass = issueProgressInformation.IssueCellMarkerClass;
            (<Model.Issues.Issue>this.Issue).ProgressState = issueProgressInformation.ProcessingStatus;
            (<Model.Issues.Issue>this.Issue).IsLocked = DAL.Properties.IsLockedState(this.Issue.StateOID);
        }

        private getFileUploadURI(issueOID: string, file: Model.Files.File): string {
            let uri = `${Session.BaseURI}issues/${issueOID}/files?filename=${file.Filename}&position=${file.Position}`;

            if (!!file.Title) {
                uri += `&title=${file.Title}`;
            }

            if (!!file.Description) {
                uri += `&description=${file.Description}`;
            }

            uri += Utils.GetAuthQueryParameter('&');

            return uri;
        }

        private prepareFileMetrics(metadata): any {
            const metrics = {
                s: {
                    width: 0,
                    height: 0
                },
                m: {
                    width: 0,
                    height: 0
                },
                l: {
                    width: 0,
                    height: 0
                },
                o: {
                    width: 0,
                    height: 0
                }
            };

            let tmp: string[];
            if ((tmp = metadata.SizeS.split('x')).length === 2) {
                metrics.s.width = parseInt(tmp[0], 10) || 0;
                metrics.s.height = parseInt(tmp[1], 10) || 0;
            }
            if ((tmp = metadata.SizeM.split('x')).length === 2) {
                metrics.m.width = parseInt(tmp[0], 10) || 0;
                metrics.m.height = parseInt(tmp[1], 10) || 0;
            }
            if ((tmp = metadata.SizeL.split('x')).length === 2) {
                metrics.l.width = parseInt(tmp[0], 10) || 0;
                metrics.l.height = parseInt(tmp[1], 10) || 0;
            }
            if ((tmp = metadata.SizeOriginal.split('x')).length === 2) {
                metrics.o.width = parseInt(tmp[0], 10) || 0;
                metrics.o.height = parseInt(tmp[1], 10) || 0;
            }

            return metrics;
        }

        private moveFile(issue: Model.Issues.Issue | Model.Issues.RawIssue, fileDescription): Deferred {
            /**
             * TODO
             * Beim Schreiben eines Vorgangs mit Dateien nach der Titelbestimmung durch eine Formel kommt es hier zu einem Fehler,
             * da an der FileDescription FileURI nicht gesetzt ist
             */

            return Utils.MoveFileToResources(fileDescription.FileURI, fileDescription.Filename)
                .then(() => {
                    const entityDescription = new Model.Synchronisation.IssueFileEntityDescription(
                        fileDescription.Filename,
                        Enums.SyncEntityType.IssueFile,
                        issue);

                    return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, entityDescription);
                })
                .then(null, () => {/*sogt dafür dass ein postitives ergebnis zurückkommt*/ })
        }

        private moveFiles(): Deferred {
            const fileOIDs: string[] = !this.IssueFiles ? null : $.map(this.IssueFiles, (_file, oid: string) => oid);
            let deferred = $.Deferred().resolve();

            if (!fileOIDs || !fileOIDs.length) {
                return deferred.promise();
            }

            for (let i = 0; i < fileOIDs.length; i++) {
                const fileOID = fileOIDs[i];
                const file = this.IssueFiles[fileOID];

                if (file.UploadDisabled) {
                    continue;
                }

                deferred = deferred
                    .then(() => this.moveFile(this.Issue, file));
            }

            return deferred;
        }

        private uploadFiles(): Deferred {
            const resultDeferred = $.Deferred();
            const transferredFiles = [];
            const me = this;

            this.fileCount = Object.keys(this.IssueFiles || {}).length;

            if (this.fileCount > 0) {
                const uploadDeferreds: Deferred[] = [];

                for (let identifier in this.IssueFiles) {
                    const file = this.IssueFiles[identifier];

                    if (file.UploadDisabled) {
                        --this.fileCount;

                        continue;
                    }

                    const uri = this.getFileUploadURI(this.Issue.OID, file);

                    const uploadDeferred = Utils.UploadFile(uri, file)
                        .then(function(data, marks, position) {
                            delete file.IsTemporary;

                            me.onAfterFileUploaded(data, marks, position, transferredFiles);
                            me.Issue.Files = me.coalesceFilesAfterTransfer(transferredFiles);
                        }, (...params) => resultDeferred.reject.apply(resultDeferred, params));

                    uploadDeferreds.push(uploadDeferred);
                }

                $.when.apply($, uploadDeferreds)
                    .then(
                        () => resultDeferred.resolve(),
                        () => resultDeferred.reject()
                    );
            } else {
                resultDeferred.resolve();
            }

            return resultDeferred.promise();
        }

        private assignIDToIssue(id: number): void {
            this.Issue.ID = id;
        }

        private saveIssueToDatabase(): Deferred {
            (<Model.Issues.Issue>this.Issue).HasNotBeenSynced = true;
            const issueDbEntity = this.getDatabaseEntity(this.Issue);
            this.fixIssueFilesForDatabase(issueDbEntity);

            return DAL.Issues.SaveToDatabase(issueDbEntity, false)
                .then(() => this.createAndSaveSyncEntity())
                .then(() => this.moveFiles())
                .then(() => this.saveComments())
                .then(() => Utils.RemoveTemporaryImages())
                .then(() => this.applyIssueProgressInformation())
                .then(() => new Model.Issues.Issue(this.Issue));
        }

        private uploadIssue(): Deferred {
            // neue Dateien vom Vorgang entfernen, nach erfolgreichem upload anhängen
            const issueToUpload = new Model.Issues.Issue(this.Issue);
            this.fixIssueFilesForUpload(issueToUpload);

            return Utils.Http
                .Put(`issues/${this.Issue.OID}`, DAL.Issues.PrepareForSync(issueToUpload))
                .then((id: number) => this.assignIDToIssue(id))
                .then(() => this.uploadFiles())
                .then(() => this.saveComments())
                .then(() => this.applyIssueProgressInformation())
                .then(() => this.Issue)
                .fail(function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        }

        private fixIssueFilesForUpload(issueToUpload: Model.Issues.Issue): void {
            if (!issueToUpload.Files || !issueToUpload.Files.length ||
                !this.IssueFiles) {
                return;
            }

            for (const oid in this.IssueFiles) {
                if (!this.IssueFiles.hasOwnProperty(oid)) {
                    continue;
                }

                const newFile = this.IssueFiles[oid];
                const fileOID = newFile.OID;

                for (let ii = issueToUpload.Files.length - 1; ii >= 0; ii--) {
                    const file = issueToUpload.Files[ii];
                    // Temporäre Dateien die nicht aus Erfassung oder Workflow stammen ignorieren.
                    // Diese werden automatisch zugefügt nachdem der Upload des Vorgangs und der Datei selbst erfolgreich war.
                    if (!newFile.UploadDisabled && file.OID === fileOID && file.IsTemporary) {
                        issueToUpload.Files.splice(ii, 1);
                        break;
                    }
                }
            }
        }

        private fixIssueFilesForDatabase(issueToUpload: Model.Issues.RawIssue): void {
            if (!issueToUpload.Files || !issueToUpload.Files.length) {
                return;
            }

            for (let i = 0; i < issueToUpload.Files.length; i++) {
                const file = issueToUpload.Files[i];

                delete file.IsNewTemporaryFile;
            }
        }

        private getDatabaseEntity(issue: Model.Issues.RawIssue | Model.Issues.Issue | Model.Issues.IssueType): Model.Issues.RawIssue {
            const excludedProperties = [
                'Identifier',
                'Room',
                'ResubmissionitemCollection',
                'State',
                'Form',
                'Scheduling',
                'ProgressState',
                'IsLocked',
                'IsTemporary',
                'TemporaryFilesMarkup',
                'Images',
                'ShowFiles',
                'IssueCellMarkerClass',
                'NewFiles',
                'PreviousRevisionIdentifiers',
                'ProcessingStatus',
                'Location'
            ];

            for (let key in issue) {
                const attr = issue[key];

                if (!issue.hasOwnProperty(key) ||
                    attr instanceof Function ||
                    typeof attr === 'undefined' ||
                    attr === null ||
                    Utils.InArray(excludedProperties, key)) {
                    delete issue[key];
                }
            }

            if ((issue.Recorditems || []).length) {
                const propertiesToIgnore = Recorditem.GetPropertiesToIgnoreOnClone();

                issue.Recorditems = issue.Recorditems
                    .filter(recorditem => !recorditem.IsDummy)
                    .map(recorditem => {
                        if (recorditem instanceof Model.Recorditem) {
                            return recorditem.GetDatabaseEntity();
                        }

                        return Utils.CloneObject(recorditem, propertiesToIgnore);
                    });
            }

            return <Model.Issues.RawIssue>issue;
        }

        private coalesceFilesAfterTransfer(transferedFiles: (Issues.RawFile | Issues.File)[]): any[] {
            if (!(this.Issue.Files || []).length) {
                return transferedFiles;
            }

            if (!(transferedFiles || []).length) {
                return this.Issue.Files;
            }

            for (let fCnt = 0, fLen = this.Issue.Files.length; fCnt < fLen; fCnt++) {
                const file = this.Issue.Files[fCnt];

                if (!Utils.Where(transferedFiles, 'Filename', '===', file.Filename)) {
                    transferedFiles.push(file);
                }
            }

            return transferedFiles;
        }

        public Save(): Deferred {
            this.NewComments = (this.Issue.Comments || []).filter((comment) => comment.IsTemporary);
            this.NewComments.forEach((comment) => {
                comment.CreationTimestamp = this.Issue.ModificationTimestamp;
                comment.ModificationTimestamp = this.Issue.ModificationTimestamp;
            });

            if (!Session.IsSmartDeviceApplication) {
                return this.uploadIssue();
            }

            return this.saveIssueToDatabase();
        }
    }
}
