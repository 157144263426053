//imports-start
/// <reference path="../definitions.d.ts" />
//imports-end

module Utils {
    export type PositionType = { Position: number };
    export type GroupType = { OID: string, Row: number, Position: number };
    export type TitleType = { Title: string };
    export type UserType = { Firstname: string, Lastname: string };

    export function SortDescendingByRevision(a: { Revision: number }, b: { Revision: number }): number {
        return b.Revision - a.Revision;
    }

    export function SortByPosition(objA: PositionType, objB: PositionType): number {
        return (objA.Position || 0) - (objB.Position || 0);
    }

    export function SortAssignedFilesFromCatalogue(objA: Model.Files.File, objB: Model.Files.File): number {
        const posA = objA.Position || 0;
        const posB = objB.Position || 0;

        if (posA === posB) {
            const fileA = DAL.Files.GetByOID(objA.OID);
            const fileB = DAL.Files.GetByOID(objB.OID);

            if (!(fileA && fileB)) {
                return 0;
            }

            return SortByTitle(fileA, fileB);
        }

        return posA - posB;
    }

    export function SortGroup(groupA: GroupType, groupB: GroupType): number {
        if (groupA.OID === groupB.OID) {
            return (groupA.Row || 0) - (groupB.Row || 0)
        } else {
            return Utils.SortByPosition(groupA, groupB);
        }
    }

    export function SortByString(strA: string, strB: string): number {
        strA = (strA || '').toLowerCase();
        strB = (strB || '').toLowerCase();

        if (strA === strB) {
            return 0;
        }

        return strA < strB ? -1 : 1;
    }

    export function SortByTitle(objA: TitleType, objB: TitleType): number {
        if (!objA) {
            return 1;
        }

        if (!objB) {
            return -1;
        }

        const titleA = (objA.Title || '').toLowerCase();
        const titleB = (objB.Title || '').toLowerCase();

        if (titleA === titleB) {
            return 0;
        }

        return titleA < titleB ? -1 : 1;
    }

    export function SortByLastname(userA: UserType, userB: UserType): number {
        const lastnameA = (userA.Lastname || '').toLowerCase();
        const lastnameB = (userB.Lastname || '').toLowerCase();

        if (lastnameA === lastnameB) {
            return SortByString(userA.Firstname, userB.Firstname);
        }

        return lastnameA < lastnameB ? -1 : 1;
    }

    export function SortByRow(itemA: { Row?: number }, itemB: { Row?: number }): number {
        if (!itemA && !itemB) {
            return 0;
        }

        if (itemA && !itemB) {
            return -1;
        }

        if (!itemA && itemB) {
            return 1;
        }

        const rowA = itemA.Row || 0;
        const rowB = itemB.Row || 0;

        return rowA - rowB;
    }

    export function SortByPositionAndRow(recitemA: GroupType, recitemB: GroupType): number {
        if (!recitemA && !recitemB) {
            return 0;
        }

        if (recitemA && !recitemB) {
            return -1;
        }

        if (!recitemA && recitemB) {
            return 1;
        }

        const posA = recitemA.Position || 0;
        const posB = recitemB.Position || 0;

        if (posA !== posB) {
            return posA - posB;
        }

        const rowA = recitemA.Row || 0;
        const rowB = recitemB.Row || 0;

        return rowA - rowB;
    }

    export function SortByParentPositionAndRowThenByPositionAndRow(a: Model.Elements.Element, b: Model.Elements.Element): number {
        return Utils.SortByPositionAndRow((a || { Parent: null }).Parent, (b || { Parent: null }).Parent) ||
            Utils.SortByPositionAndRow(a, b);
    }

    export function Equals(objA: any, objB: any, type?: string): boolean {
        if (type === 'string') {
            return EqualsString(<string>objA, <string>objB);
        } else if (type === 'array') {
            return EqualsArray(objA, objB);
        } else if (type === 'filearray') {
            return EqualsFileArray(objA, objB);
        } else if (type === 'datetime') {
            return EqualsDateTime(objA, objB);
        } else if (type === 'date') {
            return EqualsDate(objA, objB);
        } else if (type === 'time') {
            return EqualsTime(objA, objB);
        } else if (type === 'locationmarkers') {
            return EqualsLocationMarkers(objA, objB);
        } else if (type === 'recorditemarray') {
            return EqualsRecorditemArray(objA, objB);
        } else if (type === 'recorditem') {
            return EqualsRecorditem(objA, objB);
        } else if (type === 'elementRights') {
            return EqualsElementRights(objA, objB);
        } else {
            return objA === objB;
        }
    }

    export function EqualsString(objA: string, objB: string): boolean {
        return (objA || '').trim() === (objB || '').trim();
    }

    export function EqualsArray<U>(objA: Array<U>, objB: Array<U>): boolean {
        if ((objA || []).length !== (objB || []).length) {
            return false;
        }

        let equal = true;

        if ((objA || []).length) {
            objA.forEach(function(obj) {
                if (!Utils.InArray(objB, obj)) {
                    equal = false;
                    return false;
                }
            });
        }

        return equal;
    }

    export function EqualsFileArray(objA: Array<any>, objB: Array<any>): boolean {
        let equal = true;

        if ((objA || []).length) {
            objA.forEach(function(obj) {
                let fileInArray = false;
                let sameDescription = false;
                let sameTitle = false;

                if ((objB || []).length) {
                    objB.forEach(function(compObj) {
                        if (obj.Filename === compObj.Filename) {
                            fileInArray = true;
                            sameDescription = (obj.Description || '') === (compObj.Description || '');
                            sameTitle = (obj.Title || '') === (compObj.Title || '');

                            return false;
                        }
                    });
                }

                if (!fileInArray || !sameDescription || !sameTitle) {
                    equal = false;
                    return false;
                }
            });
        }

        if (equal) {
            if ((objB || []).length) {
                objB.forEach(function(obj) {
                    let fileInArray = false;
                    let sameDescription = false;
                    let sameTitle = false;

                    if ((objA || []).length) {
                        objA.forEach(function(compObj) {
                            if (obj.Filename === compObj.Filename) {
                                fileInArray = true;
                                sameDescription = (obj.Description || '') === (compObj.Description || '');
                                sameTitle = (obj.Title || '') === (compObj.Title || '');

                                return false;
                            }
                        });
                    }

                    if (!fileInArray || !sameDescription || !sameTitle) {
                        equal = false;
                        return false;
                    }
                });
            }
        }

        return equal;
    }

    export function EqualsDateTime(objA: string, objB: string): boolean {
        /*
            Full time & date compare
        */
        const timeA = objA ? new Date(objA).getTime() : null;
        const timeB = objB ? new Date(objB).getTime() : null;

        return timeA === timeB;
    }

    export function EqualsDate(objA: string, objB: string): boolean {
        /*
            Compare date part
        */
        if ((!objA) !== (!objB)) {
            return false;
        }

        if (!objA) {
            return true;
        }

        let dateA = new Date(objA);
        let dateB = new Date(objB);

        if (dateA.getFullYear() !== dateB.getFullYear()) {
            return false;
        }

        if (dateA.getMonth() !== dateB.getMonth()) {
            return false;
        }

        if (dateA.getDate() !== dateB.getDate()) {
            return false;
        }

        return true;
    }

    export function EqualsTime(objA: string, objB: string): boolean {
        /*
            Compare time part
        */
        if ((!objA) != (!objB)) {
            return false;
        }

        if (!objA) {
            return true;
        }

        const timeA = new Date(objA);
        const timeB = new Date(objB);

        if (timeA.getHours() !== timeB.getHours()) {
            return false;
        }

        if (timeA.getMinutes() !== timeB.getMinutes()) {
            return false;
        }

        return true;
    }

    export function EqualsLocationMarkers(objA: Array<any>, objB: Array<any>): boolean {
        if (!(objA || []).length && !(objB || []).length) {
            return true;
        }

        if (objA) {
            objA.sort(function(a, b) {
                if (a.Type !== b.Type) {
                    return a.Type - b.Type;
                } else if (a.X !== b.X) {
                    return a.X - b.X;
                } else if (a.Y !== b.Y) {
                    return a.Y - b.Y;
                }
            });
        }

        if (objB) {
            objB.sort(function(a, b) {
                if (a.Type !== b.Type) {
                    return a.Type - b.Type;
                } else if (a.X !== b.X) {
                    return a.X - b.X;
                } else if (a.Y !== b.Y) {
                    return a.Y - b.Y;
                }
            });
        }

        if ((objA || []).length !== (objB || []).length) {
            return false;
        } else {
            for (let mCnt = 0, mLen = objA.length; mCnt < mLen; mCnt++) {
                const markerA = objA[mCnt];
                const markerB = objB[mCnt];

                if (!markerB) {
                    return false;
                }

                if (markerA.Type !== markerB.Type ||
                    markerA.X !== markerB.X ||
                    markerA.Y !== markerB.Y) {
                    return false;
                }
            }

            return true;
        }
    }

    export function EqualsRecorditemArray(objA: Array<Model.Recorditem>, objB: Array<Model.Recorditem>): boolean {
        if ((objA || []).length !== (objB || []).length) {
            return false;
        }

        if (!(objA || []).length) {
            return true;
        }

        objA.sort(function(a, b) { return a.OID === b.OID ? 0 : a.OID > b.OID ? 1 : -1; });
        objB.sort(function(a, b) { return a.OID === b.OID ? 0 : a.OID > b.OID ? 1 : -1; });

        for (let i = 0, len = objA.length; i < len; i++) {
            if (objA[i].OID !== objB[i].OID) {
                return false;
            }

            if (!Utils.Equals(objA[i], objB[i], 'recorditem')) {
                return false;
            }
        }

        return true;
    }

    export function EqualsRecorditem(objA: Model.Recorditem, objB: Model.Recorditem): boolean {
        objA = objA || <Model.Recorditem>{};
        objB = objB || <Model.Recorditem>{};

        switch (objA.ElementType) {
            case Enums.ElementType.Date:
                return Utils.Equals(objA.Value, objB.Value, 'date');
            case Enums.ElementType.Time:
                return Utils.Equals(objA.Value, objB.Value, 'time');
            case Enums.ElementType.ListBox:
                objA.Value = parseInt(objA.Value, 10);
                objA.Value = isNaN(objA.Value) ? null : objA.Value;
                objB.Value = parseInt(objB.Value, 10);
                objB.Value = isNaN(objB.Value) ? null : objB.Value;
                return objA.Value === objB.Value;
            case Enums.ElementType.MultiListBox:
                objA.Value = $.map(objA.Value || [], function(val) { return parseInt(val, 10); });
                objB.Value = $.map(objB.Value || [], function(val) { return parseInt(val, 10); });
                return Utils.Equals(objA.Value, objB.Value, 'array');
            case Enums.ElementType.Users:
                objA.Value = objA.Value || {};
                objB.Value = objB.Value || {};

                if (!Utils.Equals(objA.Value.Users, objB.Value.Users, 'array')) {
                    return false;
                }

                if (!Utils.Equals(objA.Value.Teams, objB.Value.Teams, 'array')) {
                    return false;
                }

                return true;
            default:
                return objA.Value === objB.Value;
        }
    }

    export function EqualsElementRights(objA: Array<any>, objB: Array<any>): boolean {
        const lenElemRightsA = (objA || []).length;
        const lenElemRightsB = (objB || []).length;

        if (objA === objB || (!lenElemRightsA && !lenElemRightsB)) {
            return true;
        }

        if (lenElemRightsA !== lenElemRightsB) {
            return false;
        }

        for (let eRCnt = 0; eRCnt < lenElemRightsA; eRCnt++) {
            const elemRightA = objA[eRCnt];

            const bIndex = Utils.GetIndex(objB, elemRightA.ElementOID, 'ElementOID');

            if (bIndex === -1) {
                return false;
            }

            const elemRightB = objB[bIndex];

            const lenRightsA = (elemRightA.Rights || []).length;
            const lenRightsB = (elemRightB.Rights || []).length;

            if (lenRightsA !== lenRightsB) {
                return false;
            }

            if (!lenRightsA && !lenRightsB) {
                continue;
            }

            for (let rCnt = 0; rCnt < lenRightsA; rCnt++) {
                const rightA = elemRightA.Rights[rCnt];
                const bIndex = Utils.GetIndex(elemRightB.Rights, rightA.ID, 'ID');

                if (bIndex === -1) {
                    return false;
                }

                const rightB = elemRightB.Rights[bIndex];

                if (rightA.Value !== rightB.Value) {
                    return false;
                }
            }
        }

        return true;
    }

    export function HasIntersection<T>(a: Array<T>, b: Array<T>): boolean {
        if (!(a || []).length || !(b || []).length) {
            return false;
        }

        return a.some(val => Utils.InArray(b, val));
    }

    export function SortByPropertyHierarchy(a: Model.Properties.Property, b: Model.Properties.Property) {
        if (a && !b) {
            return -1;
        } else if (!a && b) {
            return 1;
        } else if (!a || !b) {
            return 0;
        }

        if (a.OID === b.OID) {
            return 0;
        }

        if (a.ParentOID === b.ParentOID) {
            return a.Position - b.Position;
        }

        const stackA = [a];
        while (a.Parent) {
            stackA.unshift(a.Parent);
            a = a.Parent;
        }

        const stackB = [b];
        while (b.Parent) {
            stackB.unshift(b.Parent);
            b = b.Parent;
        }

        const maxDepth = Math.min(stackA.length, stackB.length);
        for (let i = 0; i < maxDepth; i++) {
            if (stackA[i].Position == stackB[i].Position) {
                continue;
            }
            return stackA[i].Position - stackB[i].Position;
        }

        return stackA.length - stackB.length;
    }

    // Global Test Variable
    declare let __TEST__;
    if (typeof __TEST__ != 'undefined') {
        __TEST__.Utils = Utils;
    }
}
