//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../model/scheduling/raw-scheduling.ts"  />
/// <reference path="../model/scheduling/scheduling.ts"  />
//imports-end

module DAL.Scheduling {
    let _scheduling: Dictionary<Model.Scheduling.Scheduling>;
    let _root: Model.Scheduling.Scheduling;
    // Pläne Set über Plan-OID
    let schedulingMetadata: Dictionary<Model.Scheduling.SchedulingMetadata>;
    let schedulingMetadataByElement: Dictionary<Dictionary<Model.Scheduling.SchedulingMetadata>>;

    function removeRelations(): void {
        if (!_scheduling) {
            return;
        }

        for (let identifier in _scheduling) {
            const scheduling: Model.Scheduling.Scheduling = _scheduling[identifier];

            delete scheduling.Parent;
            delete scheduling.Children;
        }
    }

    function createRelations(): void {
        if (!_scheduling) {
            return;
        }

        // bind parent / children
        for (let identifier in _scheduling) {
            const scheduling: Model.Scheduling.Scheduling = _scheduling[identifier];

            if (scheduling.Deleted) {
                continue;
            }

            if (!scheduling.ParentOID) {
                _root = scheduling;
            }

            if (!scheduling.ParentOID) {
                continue;
            }

            const parent: Model.Scheduling.Scheduling = _scheduling[scheduling.ParentOID];
            if (!parent) {
                continue;
            }

            scheduling.Parent = parent;
            parent.Children = parent.Children || [];
            parent.Children.push(scheduling);
        }

        // sort children
        for (let identifier in _scheduling) {
            const scheduling: Model.Scheduling.Scheduling = _scheduling[identifier];
            if (scheduling.Children && scheduling.Children.length) {
                scheduling.Children.sort(Utils.SortByPosition);
            }
        }
    }

    export function PrepareSchedulingElements(elements: Model.Scheduling.SchedulingMetadataElement[]): Model.Scheduling.SchedulingMetadataElement {
        if (!elements || !elements.length) {
            return null;
        }

        const elementLookup: Dictionary<Model.Scheduling.SchedulingMetadataElement> = {};
        let result: Model.Scheduling.SchedulingMetadataElement = null;

        for (let ei = 0; ei < elements.length; ei++) {
            // Element zur Prüfgruppe laden
            const group = DAL.Elements.GetByOID(elements[ei].Identifier);

            if (!group) {
                continue;
            }

            const recordingIsLockedByDefault = group.IsRecordingLockable && group.IsRecordingLockedByDefault;

            // neues SchedulingMetadataElement erzeugen
            if (elementLookup[group.OID] == null) {
                elementLookup[group.OID] = new Model.Scheduling.SchedulingMetadataElement(group.OID, group.RevisionOID, group.Type, group.ParentOID, recordingIsLockedByDefault);
            }

            const parameters = elementLookup[group.OID].Children;

            // Elemente nach Prüfgruppe ermitteln
            const pLen = (group.Parameters || []).length;
            for (let pCnt = 0; pCnt < pLen; pCnt++) {
                let parameter = group.Parameters[pCnt];
                parameters.push(new Model.Scheduling.SchedulingMetadataElement(parameter.OID, parameter.RevisionOID, parameter.Type, parameter.ParentOID, recordingIsLockedByDefault));
            }

            let parent = group.ParentOID ? group.Parent || DAL.Elements.GetByOID(group.ParentOID) : null;
            let previousOID = group.OID;

            while (parent != null) {
                if (elementLookup[parent.OID] == null) {
                    elementLookup[parent.OID] = new Model.Scheduling.SchedulingMetadataElement(parent.OID, parent.RevisionOID, parent.Type, parent.ParentOID);
                    elementLookup[parent.OID].Children.push(elementLookup[previousOID]);
                } else {
                    //hierarchy already exists
                    elementLookup[parent.OID].Children.push(elementLookup[previousOID]);
                    previousOID = null;
                    break;
                }

                previousOID = parent.OID;
                parent = parent.ParentOID
                    ? parent.Parent || DAL.Elements.GetByOID(parent.ParentOID)
                    : null;
            }

            // Root setzen, falls noch nicht vorhanden
            if (!result) {
                result = elementLookup[previousOID];
            }
        }

        return result;
    }

    function prepareScheduling(scheduling: Model.Scheduling.RawScheduling): Model.Scheduling.RawScheduling {
        scheduling.Title = Utils.EscapeHTMLEntities(scheduling.Title);

        if (!!scheduling.Description) {
            scheduling.Description = Utils.EscapeHTMLEntities(scheduling.Description);
        }

        return scheduling;
    }

    export function Store(syncedScheduling: Array<Model.Scheduling.RawScheduling>): Deferred {
        if (!_scheduling) {
            _scheduling = {};
        }

        if (!(syncedScheduling || []).length) {
            return $.Deferred().resolve();
        }

        schedulingMetadata = schedulingMetadata || {};

        const elementsToUpdate: Dictionary<boolean> = {};
        const userCanSeeAllSchedulings = IsUserAllowedToSeeAllSchedulings();

        for (let sCnt = 0, sLen = syncedScheduling.length; sCnt < sLen; sCnt++) {
            const schedule = prepareScheduling(syncedScheduling[sCnt]);

            // INFO: Deleted is handled in createRelations()

            const scheduling = new Model.Scheduling.Scheduling(schedule);
            _scheduling[schedule.OID] = scheduling;

            if (!userCanSeeAllSchedulings &&
                !scheduling.IsCurrentUserAssigned()) {
                continue;
            }

            if (schedulingMetadata[schedule.OID]) {
                scheduling.SetMetadata(schedulingMetadata[schedule.OID]);
            }

            // collect Elements
            if (schedule.Elements && schedule.Elements.length) {
                for (const element of schedule.Elements) {
                    elementsToUpdate[element.OID] = true;
                }
            }
        }

        removeRelations();
        createRelations();

        if (Session.IsSmartDeviceApplication) {
            // Keine weiteren Aktionen in der mobilen App
            return $.Deferred().resolve();
        }

        // Bisherige Beziehungen zu aktualisieren Plänen zurücksetzen
        ResetSchedulingByElement(syncedScheduling.map(function(item: Model.Scheduling.RawScheduling) {
            return <Model.Scheduling.IRelation>{
                ElementOID: null,
                LocationSettings: null,
                SchedulingOID: item.OID
            };
        }));

        // Nur im Web: Elemente laden um Beziehung zu Räumen zu ermitteln
        const elementOIDs = Object.keys(elementsToUpdate);

        if (!elementOIDs.length) {
            return $.Deferred().resolve();
        }

        return DAL.Sync.PreloadElementAncestors(elementOIDs)
            .then((elements: Model.Elements.Element[]) => {
                if (!elements || !elements.length) {
                    return;
                }

                // Elemente für schnellen Zugriff vorbereiten
                const preloadedElementsSet: Dictionary<Model.Elements.Element> = {};

                for (let i = 0; i < elements.length; i++) {
                    const element = elements[i];

                    if (element.Enabled && !element.Deleted) {
                        preloadedElementsSet[element.OID] = element;
                    }
                }

                // Beziehungen von Plänen <> OEs extrahieren
                const relations: Model.Scheduling.Relation[] = ExtractRelations(syncedScheduling, preloadedElementsSet);

                // Beziehungen zwischen Plänen <> OEs zusammenbauen
                FillSchedulingRelations(relations);
            });
    }

    export function ExtractRelations(syncedScheduling: Array<Model.Scheduling.RawScheduling>, preloadedElementsLookup: Dictionary<Model.Elements.Element>): Model.Scheduling.Relation[] {
        const result: Model.Scheduling.Relation[] = [];

        if (!syncedScheduling) {
            return result;
        }

        for (const scheduling of syncedScheduling) {
            if (!scheduling.Elements || !scheduling.Elements.length) {
                continue;
            }

            // Prüfgruppen-Elemente des Plans durchgehen
            for (const schedulingElement of scheduling.Elements) {
                const elementOID = schedulingElement.OID;
                const element = preloadedElementsLookup[elementOID];
                const tmpResult = GetRelations(scheduling.OID, element, schedulingElement);

                if (tmpResult && tmpResult.length) {
                    result.push.apply(result, tmpResult);
                }
            }
        }

        return result;
    }

    export function GetRelations(schedulingOID: string, element: Model.Elements.Element, elementScheduling: Model.Elements.Scheduling): Model.Scheduling.Relation[] {
        if (!element) {
            return [];
        }

        const result: Model.Scheduling.Relation[] = [];

        // neue Element<>Plan Beziehungen hinzufügen
        if (element.Type === Enums.ElementType.Parametergroup) {
            // ParentOID nur bei Prüfgruppe als LocationOID verwenden
            const rel: Model.Scheduling.Relation = {
                OID: uuid(),
                Type: element.Type,
                LocationSettings: new Model.Scheduling.SchedulingLocationSettings(element.ParentOID),
                ElementOID: element.OID,
                SchedulingOID: schedulingOID
            };

            result.push(rel);
        } else if (element.Type === Enums.ElementType.Form) {
            // LocationSettings ab API Version 27 vorhanden, davor nur Locations existent
            const locations = elementScheduling.LocationSettings || elementScheduling.Locations || [];

            // Alle Locations zu dem Formular einzeln ablegen
            for (const loc of locations) {
                let locationSettings: Model.Scheduling.SchedulingLocationSettings;

                if (typeof loc === 'string') {
                    // neue LocationSettings aus Location OID erstellen
                    locationSettings = new Model.Scheduling.SchedulingLocationSettings(loc, 1);
                } else {
                    // LocationSettings übernehmen
                    locationSettings = loc;
                }

                const rel: Model.Scheduling.Relation = {
                    OID: uuid(),
                    Type: element.Type,
                    LocationSettings: locationSettings,
                    ElementOID: element.OID,
                    SchedulingOID: schedulingOID
                };

                result.push(rel);
            }
        }

        return result;
    }

    export function GetRoot(): Model.Scheduling.Scheduling {
        return _root;
    }

    export function Exists(identifier: string): boolean {
        return _scheduling.hasOwnProperty(identifier);
    }

    export function GetByOID(identifier: string): Model.Scheduling.Scheduling {
        return _scheduling[identifier] || null;
    }

    export function GetAll(): Array<Model.Scheduling.Scheduling> {
        return $.map(_scheduling, (scheduling: Model.Scheduling.Scheduling) => scheduling);
    }

    export function IsUserAllowedToSeeAllSchedulings(): boolean {
        return Session.LastKnownAPIVersion >= 11 && Utils.UserHasRight(Session.User.OID, Enums.Rights.ShowAllSchedulings);
    }

    function getLocation(oid: string): Model.Elements.Element {
        let node = DAL.Elements.GetByOID(oid);
        node = (function getLocation(node) {
            if (node == null) {
                return null;
            }
            if (node.Type == Enums.ElementType.Location ||
                node.Type == Enums.ElementType.Root) {
                return node;
            } else {
                return getLocation(node.Parent);
            }
        })(node);
        return node;
    }

    export function GetCountOfSchedulingsAtLocation(locationOID: string): number {
        return (GetAvailableSchedulingsAtLocation(locationOID) || []).length;
    }

    export function GetAvailableSchedulingsAtLocation(locationOID: string): Array<string> {
        const targetNode = DAL.TreeCache.Global.getNode(locationOID);
        if (!targetNode) {
            return null;
        }

        const availableSchedulings: string[] = [];
        const targetSchedulings = targetNode.getTotalSchedulings();

        targetSchedulings.toArray().forEach(schedulingIdentifier => {
            const scheduling = _scheduling[schedulingIdentifier];

            if (scheduling && !scheduling.Deleted && (IsUserAllowedToSeeAllSchedulings() || scheduling.IsCurrentUserAssigned())) {
                availableSchedulings.push(schedulingIdentifier);
            }
        });

        return availableSchedulings;
    }

    export function CreateResubmissionitemsForCurrentUser(rootElement: Model.Scheduling.SchedulingMetadataElement): Array<Model.Issues.ResubmissionItem> | null {
        if (!rootElement) {
            return null;
        }

        return createResubmissionItems(rootElement);
    }

    function createResubmissionItems(rootElement: Model.Scheduling.SchedulingMetadataElement): Array<Model.Issues.ResubmissionItem> {
        const elementLookup: Dictionary<Model.Scheduling.SchedulingMetadataElement> = {};
        const emailCpIsEnabled = Utils.IsEMailCpEnabled();
        const resubItems: Array<Model.Issues.ResubmissionItem> = [];

        (function walk(element) {
            if (element.Type !== Enums.ElementType.EMailAddresses || emailCpIsEnabled) {
                elementLookup[element.Identifier] = element;
            }

            if (!(element.Children || []).length) {
                return;
            }

            element.Children.forEach(walk);
        })(rootElement);

        (function walk(location: Model.Scheduling.SchedulingMetadataElement) {
            const parentResubitem = Utils.FindByPredicate(resubItems, (resubItem) => resubItem.ElementOID === location.ParentIdentifier);
            const locationResubitemIdentifier = uuid();

            // Resubitem für Location/Root anlegen
            resubItems.push(<Model.Issues.ResubmissionItem>{
                OID: locationResubitemIdentifier,
                ElementOID: location.Identifier,
                ElementRevisionOID: location.RevisionIdentifier,
                ParentOID: parentResubitem ? parentResubitem.OID : null
            });

            let parentElementIdentifier = location.Identifier;
            let parent = location;
            let parentLocationResubitemIdentifier: string;
            let tmpResubitem: Model.Issues.ResubmissionItem;

            while ((parent = elementLookup[parent.ParentIdentifier])) {
                if (Utils.FindByPredicate(resubItems, (resubItem) => resubItem.ElementOID === parent.Identifier)) {
                    break;
                }

                parentLocationResubitemIdentifier = uuid();

                Utils.FindByPredicate(
                    resubItems,
                    (resubItem) => resubItem.ElementOID === parentElementIdentifier).ParentOID = parentLocationResubitemIdentifier;

                tmpResubitem = <Model.Issues.ResubmissionItem>{
                    OID: parentLocationResubitemIdentifier,
                    ElementOID: parent.Identifier,
                    ElementRevisionOID: parent.RevisionIdentifier
                };

                let grandParent = elementLookup[parent.ParentIdentifier];

                if (grandParent && Utils.FindByPredicate(resubItems, (resubItem) => resubItem.ElementOID === grandParent.Identifier)) {
                    tmpResubitem.ParentOID = Utils.FindByPredicate(resubItems, (resubItem) => resubItem.ElementOID === grandParent.Identifier).OID;
                }

                resubItems.push(tmpResubitem);

                parentElementIdentifier = parent.Identifier;
            }

            // Abbrechen wenn keine weiteren Kindelement
            if (!(location.Children || []).length) {
                return;
            }

            let child: Model.Scheduling.SchedulingMetadataElement;

            for (let cCnt = 0, cLen = location.Children.length; cCnt < cLen; cCnt++) {
                child = location.Children[cCnt];

                if (child.Type === Enums.ElementType.Location) {
                    walk(child);
                } else {
                    Array.prototype.push.apply(resubItems, createResubitemsForGroup(child, locationResubitemIdentifier));
                }
            }
        })(rootElement);

        return resubItems;
    }

    function createResubitemsForGroup(group: Model.Scheduling.SchedulingMetadataElement, locationResubitemIdentifier: string): Array<Model.Issues.ResubmissionItem> {
        if (!group) {
            return [];
        }

        const resubItems: Array<Model.Issues.ResubmissionItem> = [];
        const groupResubitemOID: string = uuid();

        resubItems.push(<Model.Issues.ResubmissionItem>{
            OID: groupResubitemOID,
            ElementOID: group.Identifier,
            ElementRevisionOID: group.RevisionIdentifier,
            ParentOID: locationResubitemIdentifier,
            IsRecordingLocked: group.IsRecordingLockedByDefault
        });

        // Elemente für Prüfpunkte laden
        const groupElement = DAL.Elements.GetByOID(group.Identifier);

        if (groupElement && (groupElement.Parameters || []).length) {
            Array.prototype.push.apply(resubItems, createResubitemsForParameters(groupElement.Parameters, groupResubitemOID, group.IsRecordingLockedByDefault));
        }

        return resubItems;
    }

    function createResubitemsForParameters(parameters: Model.Elements.Element[], groupResubitemIdentifier: string, recordingIsLockedByDefault: boolean): Array<Model.Issues.ResubmissionItem> {
        if (!(parameters || []).length) {
            return [];
        }

        const resubItems: Array<Model.Issues.ResubmissionItem> = [];

        for (const param of parameters) {
            resubItems.push(<Model.Issues.ResubmissionItem>{
                OID: uuid(),
                ElementOID: param.OID,
                ElementRevisionOID: param.RevisionOID,
                ParentOID: groupResubitemIdentifier,
                IsRecordingLocked: recordingIsLockedByDefault
            });
        }

        return resubItems;
    }

    export function Clear(): void {
        _scheduling = {};
    }

    export function GenerateSchedulingMetadata(preloadElements?: Dictionary<Model.Elements.Element>): void {
        schedulingMetadata = schedulingMetadata || {};
        schedulingMetadataByElement = schedulingMetadataByElement || {};

        const elementList: Dictionary<Model.Elements.Element> = preloadElements || DAL.Elements.List;

        for (let oid in elementList) {
            const element = elementList[oid];

            if (element.Type !== Enums.ElementType.Form && element.Type !== Enums.ElementType.Parametergroup) {
                continue;
            }

            if (element.Scheduling == null || !element.Scheduling.length) {
                // bestehende Pläne vom Element entfernen
                DAL.TreeCache.Global.getNode(element.OID);
                const node = DAL.TreeCache.Global.getNode(element.OID);
                if (node) {
                    node.removeAllScheduling();
                }

                continue;
            }

            const sLen = element.Scheduling.length;
            for (let sCnt = 0; sCnt < sLen; sCnt++) {
                const scheduling = element.Scheduling[sCnt];

                // Pläne von Prüfgruppen an darüberliegende Location hängen
                if (element.Type > 3) {
                    DAL.TreeCache.Global.setScheduling({
                        LocationSettings: new Model.Scheduling.SchedulingLocationSettings(element.ParentOID),
                        ElementOID: element.OID,
                        SchedulingOID: scheduling.OID
                    });
                }

                let meta = schedulingMetadata[scheduling.OID];
                if (!meta) {
                    meta = new Model.Scheduling.SchedulingMetadata(scheduling.OID);
                    schedulingMetadata[scheduling.OID] = meta;
                }

                if (element.Type === Enums.ElementType.Form) {
                    meta.Forms = meta.Forms || [];

                    // Ab API Version 27
                    if (scheduling.LocationSettings && scheduling.LocationSettings.length) {
                        for (const locationSetting of scheduling.LocationSettings) {
                            meta.Forms = meta.Forms.filter(f => f.Identifier !== element.OID || f.LocationIdentifier !== locationSetting.OID);
                            meta.Forms.push(
                                new Model.Scheduling.SchedulingMetadataFormElement(
                                    element.OID,
                                    element.RevisionOID,
                                    element.Type,
                                    locationSetting.OID,
                                    locationSetting.IsParentIssueLocation,
                                    locationSetting.ExecutionCount
                                ));
                        }

                        continue;
                    }

                    // Fallback auf die Locations - bis API Version 27
                    if (!scheduling.Locations || !scheduling.Locations.length) {
                        continue;
                    }

                    for (const schedulingLocationOID of scheduling.Locations) {
                        meta.Forms = meta.Forms.filter(f => f.Identifier !== element.OID || f.LocationIdentifier !== schedulingLocationOID);
                        meta.Forms.push(
                            new Model.Scheduling.SchedulingMetadataFormElement(
                                element.OID,
                                element.RevisionOID,
                                element.Type,
                                schedulingLocationOID));
                    }
                }

                // Beziehungen zu Elementen setzen
                if (!schedulingMetadataByElement[element.OID]) {
                    schedulingMetadataByElement[element.OID] = {};
                }
                if (!schedulingMetadataByElement[element.OID][scheduling.OID]) {
                    schedulingMetadataByElement[element.OID][scheduling.OID] = meta;
                }
            }
        }
    }

    export function GenerateSchedulingMetadataFromRelations(relations?: Model.Scheduling.Relation[]): void {
        schedulingMetadata = schedulingMetadata || {};
        schedulingMetadataByElement = schedulingMetadataByElement || {};

        for (let ri = 0; ri < relations.length; ri++) {
            const rel = relations[ri];
            let meta = schedulingMetadata[rel.SchedulingOID];

            // Metadaten für Plan anlegen
            if (!meta) {
                meta = new Model.Scheduling.SchedulingMetadata(rel.SchedulingOID);
                schedulingMetadata[rel.SchedulingOID] = meta;
            }

            const locationOID = rel.LocationSettings!.OID;

            if (rel.Type === Enums.ElementType.Form) {
                meta.Forms = meta.Forms || [];
                meta.Forms = meta.Forms.filter(f => f.Identifier !== rel.ElementOID || f.LocationIdentifier !== locationOID);
                meta.Forms.push(new Model.Scheduling.SchedulingMetadataFormElement(
                    rel.ElementOID,
                    null,
                    rel.Type,
                    locationOID,
                    rel.LocationSettings!.IsParentIssueLocation,
                    rel.LocationSettings!.ExecutionCount
                ));
            } else if (rel.Type === Enums.ElementType.Parametergroup) {
                const newMeta = new Model.Scheduling.SchedulingMetadataElement(rel.ElementOID, null, rel.Type, locationOID);
                newMeta.LocationIdentifier = locationOID;

                meta.Elements = meta.Elements || [];
                meta.Elements = meta.Elements.filter(f => f.Identifier !== rel.ElementOID || f.LocationIdentifier !== locationOID);
                meta.Elements.push(newMeta);
            }

            // Beziehungen zu Elementen setzen
            if (!schedulingMetadataByElement[rel.ElementOID]) {
                schedulingMetadataByElement[rel.ElementOID] = {};
            }
            if (!schedulingMetadataByElement[rel.ElementOID][rel.SchedulingOID]) {
                schedulingMetadataByElement[rel.ElementOID][rel.SchedulingOID] = meta;
            }
        }

        // schedulingMetadata auf Pläne anwenden
        if (_scheduling) {
            for (const schedulingOID in schedulingMetadata) {
                if (schedulingMetadata.hasOwnProperty(schedulingOID) && _scheduling[schedulingOID]) {
                    const meta = schedulingMetadata[schedulingOID];
                    _scheduling[schedulingOID].SetMetadata(meta);
                }
            }
        }
    }

    export function FillSchedulingRelations(schedulingElements: Model.Scheduling.Relation[]) {
        if (!schedulingElements || !schedulingElements.length) {
            return;
        }

        // Scheduling Metadaten generieren
        GenerateSchedulingMetadataFromRelations(schedulingElements);

        for (const rel of schedulingElements) {
            // Pläne an Parent von der PG binden
            DAL.TreeCache.Global.setScheduling(rel);
        }
    }

    export function ResetSchedulingByElement(schedulingElements: Model.Scheduling.IRelation[]) {
        for (const relation of schedulingElements) {
            // Beziehung zum PLan aus dem Plan entfernen
            removeScheduling(relation);

            // Pläne von OE-Elementen entfernen
            DAL.TreeCache.Global.removeScheduling(relation);
        }
    }

    function removeScheduling(relation: Model.Scheduling.IRelation) {
        const elementOID = relation.ElementOID;
        const scheduleOID = relation.SchedulingOID;
        const locationOID = relation.LocationSettings ? relation.LocationSettings.OID : null;

        if (!elementOID && !scheduleOID && !locationOID) {
            return;
        }

        const clearScheduleRelation = function <T extends Model.Scheduling.SchedulingMetadataElement>(metaElements: T[]) {
            return metaElements.filter(f => {
                return !((!elementOID || f.Identifier === elementOID) && (!locationOID || f.LocationIdentifier === locationOID));
            })
        };

        if (scheduleOID) {
            const schedule = _scheduling[scheduleOID];

            if (schedule) {
                if (schedule.Forms && schedule.Forms.length) {
                    schedule.Forms = clearScheduleRelation(schedule.Forms);
                }

                if (schedule.Elements && schedule.Elements.length) {
                    schedule.Elements = clearScheduleRelation(schedule.Elements);
                }
            }

            if (schedulingMetadata) {
                const schedulingMeta = schedulingMetadata[scheduleOID];
                if (schedulingMeta) {
                    if (schedulingMeta.Forms && schedulingMeta.Forms.length) {
                        schedulingMeta.Forms = clearScheduleRelation(schedulingMeta.Forms);
                    }

                    if (schedulingMeta.Elements && schedulingMeta.Elements.length) {
                        schedulingMeta.Elements = clearScheduleRelation(schedulingMeta.Elements);
                    }
                }
            }

            return;
        }

        for (const schedulingOID in _scheduling) {
            const schedule = _scheduling[schedulingOID];

            if (!schedule || !_scheduling.hasOwnProperty(schedulingOID)) {
                continue;
            }

            if (schedule.Forms && schedule.Forms.length) {
                schedule.Forms = clearScheduleRelation(schedule.Forms);
            }

            if (schedule.Elements && schedule.Elements.length) {
                schedule.Elements = clearScheduleRelation(schedule.Elements);
            }
        }

        if (schedulingMetadata) {
            for (const schedulingOID in schedulingMetadata) {
                const scheduleMeta = schedulingMetadata[schedulingOID];

                if (!scheduleMeta || !schedulingMetadata.hasOwnProperty(schedulingOID)) {
                    continue;
                }

                if (scheduleMeta.Forms && scheduleMeta.Forms.length) {
                    scheduleMeta.Forms = clearScheduleRelation(scheduleMeta.Forms);
                }

                if (scheduleMeta.Elements && scheduleMeta.Elements.length) {
                    scheduleMeta.Elements = clearScheduleRelation(scheduleMeta.Elements);
                }
            }
        }
    }

    export function GetAllSelectableAndVisibleSchedulings(): {
        Schedulings: Dictionary<Model.Scheduling.Scheduling>,
        SelectableSchedulings: Dictionary<string>,
        VisibleSchedulings: Dictionary<string>
    } {
        const userCanSeeAllSchedulings = IsUserAllowedToSeeAllSchedulings();
        const schedulings = {};
        const selectableSchedulings = {};
        const visibleSchedulings = {};

        for (let oid in _scheduling) {
            const scheduling = _scheduling[oid];

            if (!scheduling) {
                continue;
            }

            if (!userCanSeeAllSchedulings && !scheduling.IsCurrentUserAssigned()) {
                continue;
            }

            selectableSchedulings[scheduling.OID] = true;
            visibleSchedulings[scheduling.OID] = true;
            schedulings[scheduling.OID] = scheduling;

            if (userCanSeeAllSchedulings) {
                continue;
            }

            let parent = scheduling;

            while (Utils.IsSet(parent = parent.Parent) && !selectableSchedulings[parent.OID]) {
                schedulings[parent.OID] = parent;
                visibleSchedulings[parent.OID] = true;
            }
        }

        return {
            Schedulings: schedulings,
            SelectableSchedulings: selectableSchedulings,
            VisibleSchedulings: visibleSchedulings
        }
    }
}
