module Model.DateTimePicker {
    export class DateTimeTemplateContext {
        Hour: number;
        Minute: number;
        Days: Array<string>;
        HeaderText?: string;
        ShowDatePicker: boolean;
        ShowTimePicker: boolean;

        constructor(hour: number, minute: number, days: Array<string>, headerText?: string, showDatePicker: boolean = false, showTimePicker: boolean = false) {
            this.Hour = hour;
            this.Minute = minute;
            this.Days = days;
            this.HeaderText = headerText;
            this.ShowDatePicker = showDatePicker;
            this.ShowTimePicker = showTimePicker;
        }
    }
}