//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../core/revision.ts"  />
/// <reference path="./file.ts"  />
//imports-end

module Model.Issues {
    export class RawFile {
        OID?: string;
        Filename: string;
        Title: string;
        Position: number;
        Description?: string;
        MimeType: Enums.MimeType;
        IsSeen: boolean;
        Metadata: Dictionary<any>;
        Marks?: string;

        // internal
        IsNewTemporaryFile: boolean;
        IsTemporary?: boolean;

        constructor(file: File) {
            if (!file) {
                return;
            }

            this.initProperties(file);
        }

        initProperties(file: File): void {
            this.OID = file.OID;
            this.Filename = file.Filename;
            this.Title = file.Title;
            this.Position = file.Position;
            this.Description = file.Description;
            this.MimeType = file.MimeType;
            this.IsSeen = file.IsSeen;
            this.Metadata = file.Metadata;
            this.Marks = file.Marks;
            this.IsNewTemporaryFile = file.IsNewTemporaryFile;
        }
    }
}
