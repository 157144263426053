//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../app/app.session.ts"  />
/// <reference path="./utils.input-window.ts"  />
/// <reference path="./utils.http.ts"  />
//imports-end

module Utils.PasswordEditor {
    let _user: any;

    let _$body;
    let _$overlay, _$win, _$policyList;
    let _$password, _$passwordConfirmation;
    let _$pwStrengthIndicator;
    let _$btnSave;

    let _passwordChangedDeferred;

    function onPasswordChange(): void {
        let pw: string = _$password.val();
        let confirmation: string = _$passwordConfirmation.val();
        let checkResult: any = checkPolicies(pw, confirmation);
        let enableSaveButton: boolean = checkResult.MatchesConfirmation;
        let passedRequirements: number = 0;

        _$policyList.find('.success').removeClass('success');

        if (checkResult.MatchesConfirmation) {
            _$policyList.find('li[data-policy="matches-confirmation"]').addClass('success');
        }

        if (_user.EnforcePasswordPolicy) {
            enableSaveButton = enableSaveButton &&
                checkResult.IsLengthSufficient &&
                checkResult.ContainsLowerCaseLetters &&
                checkResult.ContainsUpperCaseLetters &&
                checkResult.ContainsDigits &&
                checkResult.ContainsSpecialCharacters;
        }

        if (checkResult.IsLengthSufficient) {
            _$policyList.find('li[data-policy="is-length-sufficient"]').addClass('success');
            passedRequirements++;
        }

        if (checkResult.ContainsLowerCaseLetters) {
            _$policyList.find('li[data-policy="contains-lowercase-letters"]').addClass('success');
            passedRequirements++;
        }

        if (checkResult.ContainsUpperCaseLetters) {
            _$policyList.find('li[data-policy="contains-uppercase-letters"]').addClass('success');
            passedRequirements++;
        }

        if (checkResult.ContainsDigits) {
            _$policyList.find('li[data-policy="contains-digits"]').addClass('success');
            passedRequirements++;
        }

        if (checkResult.ContainsSpecialCharacters) {
            _$policyList.find('li[data-policy="contains-special-characters"]').addClass('success');
            passedRequirements++;
        }

        if (checkResult.HasExtendedLength) {
            passedRequirements++;
        }

        // don't allow username as password
        if (_user.Username == pw) {
            enableSaveButton = false;
            passedRequirements = 0;
        }

        // set indicator
        let text, backgroundColor;
        if (!pw || !pw.length) {
            backgroundColor = '#fff';
            text = i18next.t('PasswordEditor.Complexity.NoPassword');
            enableSaveButton = false;
        } else if (passedRequirements == 0) {
            backgroundColor = '#FF5252';
            text = i18next.t('PasswordEditor.Complexity.VeryWeak');
            enableSaveButton = false;
        } else if (passedRequirements >= 6) {
            backgroundColor = '#27AE60';
            text = i18next.t('PasswordEditor.Complexity.VeryStrong');
        } else if (passedRequirements >= 5) {
            backgroundColor = '#d4edda';
            text = i18next.t('PasswordEditor.Complexity.Strong');
        } else if (passedRequirements >= 3 && checkResult.IsLengthSufficient) {
            backgroundColor = '#F5D76E';
            text = i18next.t('PasswordEditor.Complexity.Medium');
            enableSaveButton = false;
        } else if (passedRequirements > 2) {
            backgroundColor = '#FF5722';
            text = i18next.t('PasswordEditor.Complexity.Weak');
            enableSaveButton = false;
        } else {
            backgroundColor = '#FF5252';
            text = i18next.t('PasswordEditor.Complexity.VeryWeak');
            enableSaveButton = false;
        }

        const textColor = new Model.Color(backgroundColor)
            .GetContrastColor()
            .getHex();

        _$pwStrengthIndicator
            .css({
                'background-color': backgroundColor,
                color: textColor
            })
            .text(text);

        if (enableSaveButton) {
            _$btnSave.removeAttr('disabled');
        } else {
            _$btnSave.attr('disabled', 'disabled');
        }
    }

    function onBtnSaveClick(): void {
        let pw: string;

        if ($(this).attr('disabled')) {
            return;
        }

        pw = _$password.val();

        Utils.CheckIfDeviceIsOnline()
            .then(function() {
                Session.RefreshServerSession()
                    .then(() => {
                        Utils.Http
                            .Post('users/setpassword', { Action: 'SetPassword', Password: pw })
                            .then(function() {
                                _passwordChangedDeferred.resolve(pw);
                                destroy();
                            });
                    }, function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    });
            }, function() {
                Utils.Message.Show(i18next.t('PasswordEditor.NoInternetConnection.MessageHeader'),
                    i18next.t('Misc.NoInternetConnection.MessageBody'),
                    {
                        Close: true
                    }, null, 10003);

                _passwordChangedDeferred.reject();
            });
    }

    function onPasswordKeyPress(evt): void {
        if (evt.keyCode === Enums.KeyCode.RETURN) {
            _$passwordConfirmation.focus();
            return;
        }
    }

    function onPasswordConfirmationKeyPress(evt): void {
        if (evt.keyCode === Enums.KeyCode.RETURN) {
            onBtnSaveClick.call(_$btnSave);
            return;
        }
    }

    function destroy() {
        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }

        if (_$win) {
            _$win.remove();
            _$win = null;
        }

        _$body.removeClass('modal-open');

        _user = null;
        _$policyList = null;
        _$password = null;
        _$passwordConfirmation = null;
        _$pwStrengthIndicator = null;
        _$btnSave = null;
        _passwordChangedDeferred = null;
    }

    function checkPolicies(password?: string, confirmation?: string) {
        const result = {
            MatchesConfirmation: null,
            IsLengthSufficient: null,
            HasExtendedLength: null,
            ContainsLowerCaseLetters: null,
            ContainsUpperCaseLetters: null,
            ContainsDigits: null,
            ContainsSpecialCharacters: null
        };

        if (!password) {
            return result;
        }

        result.MatchesConfirmation = password === confirmation;
        result.IsLengthSufficient = password.length >= 8;
        result.HasExtendedLength = password.length >= 10;
        result.ContainsLowerCaseLetters = /[a-z\u00E4\u00F6\u00FC\u00DF]/.test(password);
        result.ContainsUpperCaseLetters = /[A-Z\u00C4\u00D6\u00DC]/.test(password);
        result.ContainsDigits = /\d/.test(password);
        result.ContainsSpecialCharacters = /[!@#$%^&*()_+{}:"|~<>?\-=[\];'\\\,.\/]/.test(password);

        return result;
    }

    function bindEvents() {
        _$password.on('change input', onPasswordChange);
        _$password.on('keypress', onPasswordKeyPress);
        _$passwordConfirmation.on('change input', onPasswordChange);
        _$passwordConfirmation.on('keypress', onPasswordConfirmationKeyPress);
        _$btnSave.on('click', onBtnSaveClick);
    }

    export function Show(user) {
        _$body = $('body');
        _user = user;

        _$overlay = Utils.Overlay.Generate('olPasswordEditor', 1050);
        _$win = $(Templates.PasswordEditor({
            Policies: checkPolicies(),
            EnforcePasswordPolicy: _user.EnforcePasswordPolicy
        }));

        _$password = _$win.find('input[data-id="password"]');
        _$passwordConfirmation = _$win.find('input[data-id="password-confirmation"]');
        _$pwStrengthIndicator = _$win.find('.strength-indicator');
        _$policyList = _$win.find('ul');
        _$btnSave = _$win.find('.btn-success');

        _passwordChangedDeferred = $.Deferred();

        _$body.addClass('modal-open').append(_$win);

        if (!Session.IsRunningOnIOS) {
            _$password.focus();
        }

        bindEvents();

        Utils.RepositionNewModalWindow(_$win);

        return _passwordChangedDeferred;
    };
}
