//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../model/contacts/contact.ts"  />
//imports-end

module DAL.Contacts {
    let _contacts: Dictionary<Model.Contacts.Contact>;

    function prepareContact(contact: Model.Contacts.Contact): Model.Contacts.Contact {
        let title = contact.Lastname;

        if (!!contact.Firstname && !!contact.Lastname) {
            title += ', ' + contact.Firstname;
        } else if (!title && !!contact.Firstname) {
            title = contact.Firstname;
        }

        contact.Title = Utils.EscapeHTMLEntities(title);

        if (!!contact.Company) {
            contact.Company = Utils.EscapeHTMLEntities(contact.Company);
        }

        if (!!contact.EmailAddress) {
            contact.EmailAddress = Utils.EscapeHTMLEntities(contact.EmailAddress);
        }

        return contact;
    }

    export function Store(syncedContacts: Array<Model.Contacts.Contact>): void {
        if (!_contacts) {
            _contacts = {};
        }

        if (!(syncedContacts || []).length) {
            return;
        }

        for (let sCnt = 0, sLen = syncedContacts.length; sCnt < sLen; sCnt++) {
            const contact = syncedContacts[sCnt];

            if (!contact.Deleted) {
                _contacts[contact.OID] = prepareContact(contact);
            } else {
                delete _contacts[contact.OID];
            }
        }
    }

    export function GetByOID(oid: string): Model.Contacts.Contact {
        if (!_contacts) {
            return null;
        }

        if (!Exists(oid)) {
            return;
        }

        return _contacts[oid];
    }

    export function Exists(oid: string): boolean {
        if (!_contacts) {
            return false;
        }

        return _contacts.hasOwnProperty(oid);
    }

    export function GetAll(): Array<Model.Contacts.Contact>|null {
        if (!_contacts) {
            return null;
        }

        return $.map(_contacts, (contact: Model.Contacts.Contact) => contact)
            .sort(Utils.SortByTitle);
    }

    export function Clear(): void {
        _contacts = {};
    }
}