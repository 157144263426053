module Model.Nfc {
    export class Tag {
        public canMakeReadOnly: boolean;
        public id: number[];
        public isWritable: boolean;
        public maxSize: number;
        public ndefMessage: NdefMessage[];
        public techTypes: string[];
        public type: string;

        constructor() {
        }
    }

    export interface IDecoder {
        GetDecodedId(): string;
        GetDecodedMessage(): string[];
    }

    export class NdefMessage {
        public id: number[];
        public payload: number[];
        public type: number[];
    }
}