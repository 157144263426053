/// <reference path="../../ua-parser.d.ts" />

module Model.SessionItem {
    export class SessionItem {
        UserOID: string;
        Key: string;
        CreatedAt: string;
        ExpiresAt: string;
        LastAction: string;
        Remember: boolean;
        Application: string;
        UserAgent: string;
        IsCurrentSession: string;
        Deletable: boolean;

        constructor(rawSession) {
            this.UserOID = rawSession.UserOID;
            this.Key = rawSession.Key;
            this.ExpiresAt = DateToString(rawSession.ExpiresAt);
            this.CreatedAt = !rawSession.CreatedAt ? '-/-' : DateToString(rawSession.CreatedAt);
            this.LastAction = !rawSession.LastAction ? '-/-' : DateToString(rawSession.LastAction);
            this.Remember = rawSession.Remember;
            this.Application = !rawSession.Application ? '-/-' : rawSession.Application;

            if (!rawSession.UserAgent) {
                this.UserAgent = '-/-';
            } else {
                const ua = new UAParser(rawSession.UserAgent);
                if (!ua.getBrowser().name) {
                    this.UserAgent = `${ua.getOS().name} ${ua.getOS().version}`;
                } else {
                    this.UserAgent = `${ua.getBrowser().name}, ${ua.getOS().name} ${ua.getOS().version}`;
                }
            }

            if (!rawSession.IsCurrentSession) {
                this.IsCurrentSession = '';
                this.Deletable = true;
            } else {
                this.IsCurrentSession = i18next.t('Settings.ManageSessions.CurrentSession');
                this.Deletable = false;
            }
        }
    }

    function DateToString(value: string | number | Date): string {
        const date = new Date(value);

        return `${Utils.DateTime.GetDateString(date)} ${Utils.DateTime.TimeToString(date)}`;
    }
}
