//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../lib/jquery.min.js"  />
/// <reference path="../model/users/user.ts"  />
//imports-end

module DAL.Users {
    let _users: Dictionary<any>;

    export function Store(syncedUsers: Array<Model.Users.RawUser>): void {
        if (!_users) {
            _users = {};
        }

        if (!(syncedUsers || []).length) {
            return;
        }

        for (let pCnt = 0, pLen = syncedUsers.length; pCnt < pLen; pCnt++) {
            const user = new Model.Users.User(syncedUsers[pCnt]);

            if (!user.Deleted) {
                _users[user.OID] = user;
            } else {
                delete _users[user.OID];
            }
        }
    }

    export function Exists(identifier: string): boolean {
        return !!identifier && _users.hasOwnProperty(identifier);
    }

    export function GetByOID(identifier: string): Model.Users.User {
        if (!identifier || !_users) {
            return;
        }

        return _users[identifier];
    }

    export function GetByTeamOID(teamIdentifier: string): Array<Model.Users.User> {
        if (!teamIdentifier) {
            return;
        }

        const team = DAL.Teams.GetByOID(teamIdentifier);

        if (!team ||
            !(team.Users || []).length) {
            return;
        }

        const teamMembers = [];
        for (let pCnt = 0, pLen = team.Users.length; pCnt < pLen; pCnt++) {
            const user = GetByOID(team.Users[pCnt].OID);
            if (user) {
                teamMembers.push(user);
            }
        }

        return teamMembers;
    }

    export function GetAll(): Array<Model.Users.User> {
        return $.map(_users, (user: Model.Users.User) => user);
    }

    export function Clear(): void {
        _users = null;
    }
}