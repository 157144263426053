//imports-start
/// <reference path="../../enums.ts"  />
//imports-end

module Model.Issues {
    export class ProcessingInformation {
        public ProcessingStatus: Enums.IssueProcessingStatus;
        public IssueCellMarkerClass: Enums.IssueCellMarker;

        constructor(processingStatus: Enums.IssueProcessingStatus = Enums.IssueProcessingStatus.OK) {
            this.SetStatus(processingStatus);
        }

        public SetStatus(processingStatus: Enums.IssueProcessingStatus): ProcessingInformation {
            this.ProcessingStatus = processingStatus;
            // set marker color depending from ProcessingStatus
            this.IssueCellMarkerClass = ProcessingInformation.GetStatusCssClass(processingStatus);
            return this;
        }

        static GetStatusCssClass(status: Enums.IssueProcessingStatus): Enums.IssueCellMarker {
            switch (status) {
                case Enums.IssueProcessingStatus.Warning:
                    return Enums.IssueCellMarker.Yellow;
                case Enums.IssueProcessingStatus.Overdue:
                    return Enums.IssueCellMarker.Red;
                default:
                    return Enums.IssueCellMarker.Green;
            }
        }
    }
}