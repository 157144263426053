/// <reference path="../definitions.d.ts"  />
/// <reference path="./model.dropdown-list.ts" />

module Model {
    interface ButtonGroupEntity {
        Title: string,
        OID?: string,
        Value?: any,
        Color?: string,
        IsLockable?: boolean
    }

    interface ButtonGroupSettings {
        $Container: any;
        ButtonEntities: Array<ButtonGroupEntity>;
        OverflowAsDropDown: boolean;
        ForceFirstItem: boolean;
        OnItemClick: (evt: Event) => void;
        GetRemainingWidth: () => number;
        $DropDownIcon: any;
    }

    export class ButtonGroup {
        private readonly settings: ButtonGroupSettings;
        private readonly $dropdownIcon: any;

        private $btnGroup: any;
        private remainingWidth: number;
        private visibleButtonEntities: Array<ButtonGroupEntity>;
        private dropDownList: DropDownList = null;

        private minSpaceForNextButton: number;

        constructor(settings: ButtonGroupSettings) {
            if (settings.$Container == null || !settings.$Container.length) {
                return;
            }

            if (settings.ButtonEntities == null || !settings.ButtonEntities.length) {
                return;
            }

            if (settings.GetRemainingWidth == null) {
                settings.GetRemainingWidth = () => Infinity;
            }

            this.$dropdownIcon = settings.$DropDownIcon;

            this.settings = settings;
            this.remainingWidth = settings.GetRemainingWidth();
            this.build();
            this.bindEvents();
        }

        public Destroy(): void {
            this.unbindEvents();

            if (this.dropDownList != null) {
                this.dropDownList.Destroy();
            }

            if (this.$btnGroup != null) {
                this.$btnGroup.remove();
            }
        }

        public Resize(): void {
            this.moveButtons();

            if (this.dropDownList != null) {
                this.dropDownList.Reposition();
            }
        }

        private build(): void {
            this.createButtonsList();

            if (this.$btnGroup.children('.btn').length >= this.settings.ButtonEntities.length) {
                this.visibleButtonEntities = this.settings.ButtonEntities.slice();
                return;
            }

            if (!this.settings.OverflowAsDropDown) {
                return;
            }

            const visibleButtonsCount = this.$btnGroup.children('.btn').length;
            this.visibleButtonEntities = this.settings.ButtonEntities.slice(0, visibleButtonsCount);
            this.dropDownList = this.createDropdown(this.settings.ButtonEntities.slice(visibleButtonsCount), visibleButtonsCount === 0);
        }

        private createButtonsList(): void {
            this.$btnGroup = $('<div class="btn-group" role="group"></div>');

            this.settings.$Container
                .empty()
                .append(this.$btnGroup);

            const buttonEntities = this.settings.ButtonEntities;
            const bLen = buttonEntities.length;

            if (!this.settings.ForceFirstItem && this.remainingWidth <= 0) {
                return;
            }

            for (let bCnt = 0; bCnt < bLen; bCnt++) {
                const buttonEntity = buttonEntities[bCnt];
                const $btn = $(Templates.ButtonGroup.ButtonEntity(buttonEntity));

                this.$btnGroup.append($btn);

                const btnWidth = $btn.outerWidth(true);

                if ((this.remainingWidth - btnWidth) < 0) {
                    if (bCnt > 0 || !this.settings.ForceFirstItem) {
                        $btn.remove();
                        break;
                    }
                }

                this.remainingWidth -= btnWidth;
            }
        }

        private createDropdown(entities: Array<ButtonGroupEntity>, showCustomDropdownIcon: boolean = false): Model.DropDownList {
            if (!Utils.IsSet(entities) || entities.length <= 0) {
                return null;
            }

            const $lastBtn = this.$btnGroup.children('.btn').last();

            const dropdownSettings: Model.DropDownSettings = {
                $BtnContainer: this.$btnGroup,
                Entities: entities,
                OnItemSelected: this.settings.OnItemClick,
                ShowCustomDropdownIcon: showCustomDropdownIcon,
                $DropdownIcon: this.$dropdownIcon
            };

            const dropDownList = new Model.DropDownList(dropdownSettings);
            this.remainingWidth -= $lastBtn.outerWidth();

            if (this.remainingWidth < 0) {
                if (!this.settings.ForceFirstItem && $lastBtn.prev().length) {
                    $lastBtn.remove();
                    dropDownList.UnshiftEntities(this.visibleButtonEntities.pop());
                }
            }

            return dropDownList;
        }

        private moveButtons(): void {
            this.remainingWidth = this.settings.GetRemainingWidth();

            if (this.remainingWidth >= 150) {
                this.moveButtonsFromDropDown();
            } else if (this.remainingWidth < 150) {
                this.moveButtonsToDropdown();
            }

            if (!this.settings.ForceFirstItem && this.dropDownList != null) {
                this.dropDownList.ShowCustomDropdownIcon = (this.$btnGroup.children('.btn') || []).length === 0;
                this.setDropdownToggleButton();
            }
        }

        private setDropdownToggleButton(): void {
            const $dropdownButton = this.dropDownList.CreateDropdownButton();
            this.$btnGroup.find('.dropup > .dropdown-toggle').remove();
            this.$btnGroup.find('.dropup').append($dropdownButton);
        }

        private moveButtonsFromDropDown(): void {
            if (this.dropDownList == null) {
                return;
            }

            if (this.minSpaceForNextButton != null && this.minSpaceForNextButton > this.remainingWidth) {
                return;
            }

            do {
                const entity = this.dropDownList.ShiftEntity();

                if (entity == null) {
                    this.dropDownList.Destroy();
                    this.dropDownList = null;
                    break;
                }

                const $newButton = $(Templates.ButtonGroup.ButtonEntity(entity));
                this.$btnGroup.children().last().before($newButton);

                const buttonWidth = $newButton.outerWidth(true);

                if (buttonWidth > this.remainingWidth) {
                    $newButton.remove();
                    this.dropDownList.UnshiftEntities(entity);
                    this.minSpaceForNextButton = buttonWidth;
                    break;
                }

                this.remainingWidth -= buttonWidth;
                this.visibleButtonEntities.push(entity);
                this.minSpaceForNextButton = null;
            } while (this.remainingWidth >= 0);
        }

        private moveButtonsToDropdown(): void {
            const $buttons = this.$btnGroup.children('.btn');
            const bLen = $buttons.length;
            const bMin = this.settings.ForceFirstItem ? 1 : 0;
            let bCnt;
            let availableSpace = this.remainingWidth;

            for (bCnt = bLen; bCnt >= bMin && availableSpace <= 0; bCnt--) {
                let $button = $buttons.eq(bCnt);

                if ($button.length === 0) {
                    continue;
                }

                const space = $button.outerWidth(true) || 0;

                this.minSpaceForNextButton = space;
                $button.remove();

                availableSpace += space;
            }

            //nothing changed
            if (bCnt === bLen) {
                return;
            }

            let entities: Array<ButtonGroupEntity> = this.visibleButtonEntities.splice(bCnt + 1);
            const $visibleButtons = this.$btnGroup.children('.btn');
            const visibleButtonsCount: number = $visibleButtons.length;
            const showCustomDropdownIcon: boolean = visibleButtonsCount === 0;

            if (this.dropDownList == null) {
                this.dropDownList = this.createDropdown(entities, showCustomDropdownIcon);
            } else {
                this.dropDownList.UnshiftEntities(...entities);
            }

            const difference = availableSpace - this.settings.GetRemainingWidth();

            if (difference > 50) {
                this.moveButtonsToDropdown();
            }
        }

        private unbindEvents(): void {
            this.$btnGroup.off('click');
        }

        private bindEvents(): void {
            this.$btnGroup.on('click', '>.btn', (evt) => {
                this.settings.OnItemClick(evt);
            });
        }
    }
}