//imports-start
/// <reference path="../definitions.d.ts" />
/// <reference path="./utils.spinner.ts" />
/// <reference path="../app/app.ts" />
//imports-end

module Utils.ResponsibilitiesPicker {
    export interface IOptions {
        AllowUsers?: boolean,
        AllowTeams?: boolean,
        AllowContacts?: boolean,
        AllowContactGroups?: boolean,

        ResponsibilityAssignments?: Model.Issues.ResponsibilityAssignments,

        ShowSystemUsers?: boolean,
        ShowLockedUsers?: boolean,

        FilterIssueResponsibilities?: boolean,
        Issue: Model.Issues.Issue | Model.Issues.RawIssue,
        Location?: Model.Elements.Element,
        MaximumSelectionCount?: number,
        Filters?: { Roles?: string[], Users?: string[], Teams?: string, Contacts?: string, ContactGroups?: string }
    }

    interface ISelectionWithRACI {
        User?: Model.Users.User;
        Team?: Model.Teams.Team;
        Contact?: Model.Contacts.Contact;
        ContactGroup?: Model.ContactGroups.ContactGroup;
        RACI?: Model.Issues.RACI;
        IsReadOnly?: boolean;
    }

    const enum PickerMode {
        Selected = 'selected',
        Users = 'users',
        Teams = 'teams',
        TeamMembers = 'team-members',
        Contacts = 'contacts',
        ContactGroups = 'contactgroups'
    }

    let $win, $overlay;
    let $footerNavigation, $content, $footer;
    let $search;
    let $doc;

    let _options: IOptions;
    let resultDeferred: Deferred;
    let userTeams: string[], membersOfUserTeams: string[];
    let selectionMode: PickerMode;
    let hierarchyFilter: string;
    let selectionBackup: Model.Issues.ResponsibilityAssignments;
    let searchInput: Model.ClearableInput.Control;
    let showSystemUsers: boolean;
    let showLockedUsers: boolean;
    let selectedTeamIdentifier: string;
    let dropdownIsActive = false;

    function onHierarchyFilterClick(): void {
        let $hierarchyFilter = $footerNavigation.find('li[data-action="hierarchy-filter"]');

        switch ($hierarchyFilter.data('hierarchy')) {
            case 'global':
                $hierarchyFilter.data('hierarchy', 'current').attr('data-hierarchy', 'current');
                break;
            case 'current':
                $hierarchyFilter.data('hierarchy', 'root').attr('data-hierarchy', 'root');
                break;
            case 'root':
                $hierarchyFilter.data('hierarchy', 'global').attr('data-hierarchy', 'global');
                break;
        }

        hierarchyFilter = $hierarchyFilter.data('hierarchy');

        setSelectableTeamsAndUsers();
        render();
    }

    function onTabClick(): void {
        const $this = $(this);

        if ($this.data('tab') === selectionMode ||
            $this.attr('disabled')) {
            return;
        }

        selectionMode = $this.data('tab');
        selectedTeamIdentifier = null;

        render();
    }

    function getSelectableItems() {
        switch (selectionMode) {
            case PickerMode.Selected:
                return getSelected();
            case PickerMode.Users:
            case PickerMode.TeamMembers:
                return getUsers();
            case PickerMode.Teams:
                return getTeams();
            case PickerMode.Contacts:
                return getContacts();
            case PickerMode.ContactGroups:
                return getContactGroups();
        }
    }

    function resize(): void {
        if (!$content) {
            return;
        }

        const $selectionList = $content.find('.selection-list');
        const $clientWin = $(window);

        if ($clientWin.outerHeight() <= 600) {
            $win.css('height', $clientWin.outerHeight() - 20);
            $win.css('margin-top', -($win.outerHeight() / 2));
        } else {
            $win.css({
                'height': 600,
                'margin-top': -300
            });
        }

        const $header = $win.find('.window-header');
        const headerHeight = $header.length ? Math.ceil($header.height()) : 0;
        $content.css('height', Math.ceil($win.height()) - headerHeight - Math.ceil($search.outerHeight()) - Math.ceil($footer.outerHeight()) - 2);
        const contentHeight = $content.outerHeight() - 3;

        $selectionList.css('max-height', contentHeight);
        $selectionList.css('height', contentHeight);

        const contentBoundaries = $content ? $content[0].getBoundingClientRect() : null;

        $content.find('.tags').toggleClass('hide', contentBoundaries && contentBoundaries.width < 460);

        resizeContactImages();
    }

    function resizeContactImages() {
        $content.find('.selected[data-type="contacts"] .rounded-image').addClass('contact-image-resized');
        $content.find('.selected[data-type="contactgroups"] .rounded-image').addClass('contact-image-resized');

        if (selectionMode === PickerMode.Contacts || selectionMode === PickerMode.ContactGroups) {
            $content.find('.unselected .rounded-image').addClass('contact-image-resized');
        }
    }

    function checkForModifications(): void {
        // für älteren API Versionen werden ResponsibilityAssignments beim sync zurückgemapped
        const hasModifications =
            Utils.CheckResponsibilityAssignmentModifications(_options.ResponsibilityAssignments, selectionBackup);

        $footer.find('.btn-close').toggleClass('hidden', hasModifications);
        $footer.find('.btn-apply, .btn-abort').toggleClass('hidden', !hasModifications);
    }

    function setSelectableTeamsAndUsers(): void {
        if (hierarchyFilter === 'global') {
            return;
        }

        const teams = [];
        const users = [];

        let location = hierarchyFilter === 'root' ? DAL.Elements.Root : _options.Location;
        let allowAssigningAllUsersToIssues = false;

        while (location) {
            if (!(location.Teams || []).length) {
                location = location.Parent;
                continue;
            }

            allowAssigningAllUsersToIssues =
                Utils.UserHasRight(Session.User.OID, Enums.Rights.AllowAssigningAllUsersToIssues, true, location);

            for (let tCnt = 0, tLen = location.Teams.length; tCnt < tLen; tCnt++) {
                const team = DAL.Teams.GetByOID(location.Teams[tCnt].OID);

                if (!allowAssigningAllUsersToIssues && !Utils.InArray(Session.User.Teams, team.OID)) {
                    continue;
                }

                if (!Utils.InArray(teams, team.OID)) {
                    teams.push(team.OID);
                }

                if (!(team.Users || []).length) {
                    continue;
                }

                for (let pCnt = 0, pLen = team.Users.length; pCnt < pLen; pCnt++) {
                    const user = team.Users[pCnt];

                    if (Utils.InArray(users, user.OID)) {
                        continue;
                    }

                    users.push(user.OID);
                }
            }

            location = location.Parent;
        }
    }

    function getSearchValue(): string | null {
        return $search && $search.is(':visible') ? $.trim(searchInput.GetFilter().SearchText) : null;
    }

    function isUserSelectable(user: Model.Users.User): boolean {
        if (!showSystemUsers && (user.IsSystemUser || user.IsExternalUser)) {
            return false;
        }

        if (!showLockedUsers && user.IsLocked) {
            return false;
        }

        if (!_options) {
            return false;
        }

        if (_options.FilterIssueResponsibilities &&
            !Utils.UserHasRight(Session.User.OID, Enums.Rights.AllowAssigningAllUsersToIssues, true, _options.Issue.AssignedElementOID) &&
            !Utils.InArray(membersOfUserTeams, user.OID)) {
            return false;
        }

        if (!getSearchResult(user.Title)) {
            return false;
        }

        if (selectionMode === PickerMode.TeamMembers &&
            !!selectedTeamIdentifier &&
            (user.Teams || []).indexOf(selectedTeamIdentifier) === -1) {
            return false;
        }

        if (!_options.ResponsibilityAssignments || !_options.ResponsibilityAssignments.Users) {
            return true;
        }

        if (_options.ResponsibilityAssignments.Users[user.OID]) {
            return false;
        }

        return true;
    }

    function getSelected(): ISelectionWithRACI[] | Array<Model.Users.User | Model.Teams.Team | Model.Contacts.Contact | Model.ContactGroups.ContactGroup> {
        if (!_options) {
            return [];
        }

        if (!_options.ResponsibilityAssignments) {
            return [];
        }

        const list: ISelectionWithRACI[] = [];

        retrieveEntities(_options.ResponsibilityAssignments.Users, DAL.Users, list, 'User');
        retrieveEntities(_options.ResponsibilityAssignments.Teams, DAL.Teams, list, 'Team');
        retrieveEntities(_options.ResponsibilityAssignments.Contacts, DAL.Contacts, list, 'Contact');
        retrieveEntities(_options.ResponsibilityAssignments.ContactGroups, DAL.ContactGroups, list, 'ContactGroup');

        return list;
    }

    function retrieveEntities(raciDict: Dictionary<Model.Issues.RACI>, dal: { GetByOID: (oid: string) => any }, list: ISelectionWithRACI[], propName: string) {
        let isReadOnly = true;

        switch (propName) {
            case 'User':
                isReadOnly = !_options.AllowUsers;
                break;
            case 'Team':
                isReadOnly = !_options.AllowTeams;
                break;
            case 'Contact':
                isReadOnly = !_options.AllowContacts;
                break;
            case 'ContactGroup':
                isReadOnly = !_options.AllowContactGroups;
                break;
        }

        for (let itemOID in raciDict) {
            const itemInfo = raciDict[itemOID];

            if (!itemInfo) {
                continue;
            }

            const item = dal.GetByOID(itemOID);
            if (!item) {
                continue;
            }

            if (!getSearchResult(item.Title)) {
                continue;
            }

            const entity = { RACI: itemInfo, IsReadOnly: isReadOnly };
            entity[propName] = item;
            list.push(entity);
        }
    }

    function getUsers(): Model.Users.User[] {
        const items = DAL.Users.GetAll().filter(isUserSelectable);

        items.sort(Utils.SortByLastname);

        return items;
    }

    function getTeams(): Model.Teams.Team[] {
        const items = DAL.Teams.GetAll().filter(isTeamSelectable);

        items.sort(Utils.SortByTitle);

        return items;
    }

    function getContacts(): any[] {
        const unfilteredItems = DAL.Contacts.GetAll() || [];
        const items = unfilteredItems.filter(filterContact);

        items.sort(Utils.SortByTitle);

        return items;
    }

    function getContactGroups(): any[] {
        const unfilteredItems = DAL.ContactGroups.GetAll() || [];
        const items = unfilteredItems.filter(filterContactGroup);

        items.sort(Utils.SortByTitle);

        return items;
    }

    function isTeamSelectable(team: Model.Teams.Team): boolean {
        if (!_options) {
            return false;
        }

        if (_options.FilterIssueResponsibilities &&
            !Utils.UserHasRight(Session.User.OID, Enums.Rights.AllowAssigningAllUsersToIssues, true, _options.Issue.AssignedElementOID) &&
            !Utils.InArray(userTeams, team.OID)) {
            return false;
        }

        if (!getSearchResult(team.Title)) {
            return false;
        }

        if (!_options.ResponsibilityAssignments || !_options.ResponsibilityAssignments.Teams) {
            return true;
        }

        return !_options.ResponsibilityAssignments.Teams[team.OID];
    }

    function filterContact(contact): any {
        if (!contact || !_options) {
            return false;
        }

        if (!Utils.UserHasIssueRight(_options.Issue, Enums.Rights.IssueProperties_Contacts)) {
            return false;
        }

        if (!getSearchResult(contact.Title)) {
            return false;
        }

        if (!_options.ResponsibilityAssignments || !_options.ResponsibilityAssignments.Contacts) {
            return true;
        }

        return !_options.ResponsibilityAssignments.Contacts[contact.OID];
    }

    function filterContactGroup(contactGroup): any {
        if (!contactGroup || !_options) {
            return false;
        }

        if (!Utils.UserHasIssueRight(_options.Issue, Enums.Rights.IssueProperties_ContactGroups)) {
            return false;
        }

        if (!getSearchResult(contactGroup.Title)) {
            return false;
        }

        if (!_options.ResponsibilityAssignments || !_options.ResponsibilityAssignments.ContactGroups) {
            return true;
        }

        return !_options.ResponsibilityAssignments.ContactGroups[contactGroup.OID];
    }

    function getSearchResult(searchText) {
        const searchVal = getSearchValue();

        if (!!searchVal) {
            const regex = new RegExp(Utils.EscapeHTMLEntities(searchVal), 'ig');

            if (!regex.test(searchText)) {
                return false;
            }
        }

        return true;
    }

    function destroy(): void {
        $win.remove();
        Utils.Overlay.DestroyWithTimeout($overlay);

        $doc.off('keydown.closeWindow');
        $(window).off('resize.UserPicker');

        if (!Utils.RecorditemEditor.IsVisible() && !Utils.IssueViewer.IsVisible()) {
            $('body').removeClass('modal-open');
        }

        $win = null;
        $footerNavigation = null;
        $content = null;
        $footer = null;
        $overlay = null;
        $doc = null;
        _options = null;
        selectionMode = null;
        hierarchyFilter = null;
        selectionBackup = null;
        selectedTeamIdentifier = null;
        dropdownIsActive = null;
    }

    function returnAndDestroy(): void {
        if (resultDeferred) {
            resultDeferred.resolve(_options.ResponsibilityAssignments);
        }

        destroy();
    }

    function onUnselectedItemClick(): void {
        if (!_options) {
            return;
        }

        const $this = $(this);
        const oid = $this.data('oid');
        const raci: Model.Issues.RACI = {};
        const selectionWithRACI: ISelectionWithRACI = {
            RACI: raci
        };

        let selectedItem;

        switch (selectionMode) {
            case PickerMode.Users:
            case PickerMode.TeamMembers:
                if (!_options.AllowUsers) {
                    return;
                }

                selectedItem = DAL.Users.GetByOID(oid);
                break;
            case PickerMode.Teams:
                if (!_options.AllowTeams) {
                    return;
                }

                selectedItem = DAL.Teams.GetByOID(oid);
                break;
            case PickerMode.Contacts:
                if (!_options.AllowContacts) {
                    return;
                }

                selectedItem = DAL.Contacts.GetByOID(oid);
                break;
            case PickerMode.ContactGroups:
                if (!_options.AllowContactGroups) {
                    return;
                }

                selectedItem = DAL.ContactGroups.GetByOID(oid);
                break;
        }

        if (!selectedItem) {
            return;
        }

        if (!_options.ResponsibilityAssignments) {
            _options.ResponsibilityAssignments = {};
        }

        switch (selectionMode) {
            case PickerMode.Users:
            case PickerMode.TeamMembers:
                raci.IsResponsible = true;
                selectionWithRACI.User = selectedItem;
                _options.ResponsibilityAssignments.Users = _options.ResponsibilityAssignments.Users || {};
                _options.ResponsibilityAssignments.Users[selectedItem.OID] = raci;
                break;
            case PickerMode.Teams:
                raci.IsResponsible = true;
                selectionWithRACI.Team = selectedItem;
                _options.ResponsibilityAssignments.Teams = _options.ResponsibilityAssignments.Teams || {};
                _options.ResponsibilityAssignments.Teams[selectedItem.OID] = raci;
                break;
            case PickerMode.Contacts:
                raci.IsInformed = true;
                selectionWithRACI.Contact = selectedItem;
                _options.ResponsibilityAssignments.Contacts = _options.ResponsibilityAssignments.Contacts || {};
                _options.ResponsibilityAssignments.Contacts[selectedItem.OID] = raci;
                break;
            case PickerMode.ContactGroups:
                raci.IsInformed = true;
                selectionWithRACI.ContactGroup = selectedItem;
                _options.ResponsibilityAssignments.ContactGroups = _options.ResponsibilityAssignments.ContactGroups || {};
                _options.ResponsibilityAssignments.ContactGroups[selectedItem.OID] = raci;
        }

        render();
        checkForModifications();
    }

    function onShowTeamMembersClick(evt: Event): void {
        evt.stopPropagation();

        const teamIdent = $(this).parents('li').data('oid');

        if (!teamIdent) {
            return;
        }

        selectedTeamIdentifier = teamIdent;
        selectionMode = PickerMode.TeamMembers;

        render();
    }

    function onHideTeamMembersClick() {
        selectedTeamIdentifier = null;
        selectionMode = PickerMode.Teams;

        render();
    }

    function onEditResponsibilitiesClick(evt) {
        evt.stopPropagation();
        const $this = $(this);
        const $li = $this.closest('li');
        const oid = $li.data('oid');

        if (dropdownIsActive) {
            $content.find('.action-dropdown-menu').remove();
            dropdownIsActive = false;
            return;
        }

        if (!oid || !_options.ResponsibilityAssignments) {
            return;
        }

        let type = $li.data('type');
        type = type.toLowerCase();
        let raci: Model.Issues.RACI = {};

        switch (type) {
            case 'users':
                _options.ResponsibilityAssignments.Users = _options.ResponsibilityAssignments.Users || {};
                raci = _options.ResponsibilityAssignments.Users[oid];
                break;
            case 'teams':
                _options.ResponsibilityAssignments.Teams = _options.ResponsibilityAssignments.Teams || {};
                raci = _options.ResponsibilityAssignments.Teams[oid];
                break;
            case 'contacts':
                _options.ResponsibilityAssignments.Contacts = _options.ResponsibilityAssignments.Contacts || {};
                raci = _options.ResponsibilityAssignments.Contacts[oid];
                break;
            case 'contactgroups':
                _options.ResponsibilityAssignments.ContactGroups = _options.ResponsibilityAssignments.ContactGroups || {};
                raci = _options.ResponsibilityAssignments.ContactGroups[oid];
                break;
            default:
                return;
        }

        const $dropdown = $(Templates.ResponsibilityPicker.RaciSelectionMenu({
            RACI: raci,
            Type: type,
            Identifier: oid
        }));

        $this.after($dropdown);
        dropdownIsActive = true;

        $dropdown.on('click.responsibleItem', 'li', onResponsibleAssignmentsItemClick);
        resizeDropdown($dropdown);
    }

    function resizeDropdown($dropdown) {
        const contentBoundaries = $content ? $content[0].getBoundingClientRect() : null;
        const dropdownBoundaries = $dropdown ? $dropdown[0].getBoundingClientRect() : null;

        if (!contentBoundaries || !dropdownBoundaries) {
            return;
        }

        const maxTopPosition = contentBoundaries.top + contentBoundaries.height;
        const dropdownBottomPosition = dropdownBoundaries.bottom;
        const positionDropdownBelow = (contentBoundaries.height - 100) <= dropdownBoundaries.height;

        if (dropdownBottomPosition > maxTopPosition && !positionDropdownBelow) {
            $dropdown.css('top', -dropdownBoundaries.height);
        }
    }

    function getRACIForType(type, oid) {
        if (!_options.ResponsibilityAssignments) {
            return {};
        }

        switch (type) {
            case 'users':
                _options.ResponsibilityAssignments.Users = _options.ResponsibilityAssignments.Users || {};
                return _options.ResponsibilityAssignments.Users[oid];
            case 'teams':
                _options.ResponsibilityAssignments.Teams = _options.ResponsibilityAssignments.Teams || {};
                return _options.ResponsibilityAssignments.Teams[oid];
            case 'contacts':
                _options.ResponsibilityAssignments.Contacts = _options.ResponsibilityAssignments.Contacts || {};
                return _options.ResponsibilityAssignments.Contacts[oid];
            case 'contactgroups':
                _options.ResponsibilityAssignments.ContactGroups = _options.ResponsibilityAssignments.ContactGroups || {};
                return _options.ResponsibilityAssignments.ContactGroups[oid];
            default:
                return {};
        }
    }

    function onResponsibleAssignmentsItemClick(evt) {
        evt.stopPropagation();

        const $this = $(this);
        const $dropdown = $this.closest('.action-dropdown-menu');
        const type = $dropdown.data('type');
        const oid = $dropdown.data('oid');
        const action = $this.data('action');
        const $checkmark = $this.find('.icon-checkmark');
        const isChecked = !!$checkmark.length;
        const $li = $dropdown.closest('li');

        if (!_options.ResponsibilityAssignments) {
            return;
        }

        const raci = getRACIForType(type, oid);

        switch (action) {
            case 'accountable':
                raci.IsAccountable = !isChecked;
                break;
            case 'responsible':
                raci.IsResponsible = !isChecked;
                break;
            case 'consulted':
                raci.IsConsulted = !isChecked;
                break;
            case 'informed':
                raci.IsInformed = !isChecked;
                break;
        }

        isChecked ? $checkmark.remove() : $this.append('<span class="icon-checkmark"></span>');

        const $tags = $dropdown.parent().find('.tags');
        const $newTags = $(Templates.ResponsibilityPicker.RaciTags({
            RACI: raci
        }));

        if ($li) {
            $li.toggleClass('unassigned', !hasResponsibleAssignments(raci));
        }

        $tags.replaceWith($newTags);

        $content.off('click.removeTag', '.remove-tag');
        $content.on('click.removeTag', '.remove-tag', onRemoveTagClick);

        checkForModifications();
        resize();
    }

    function onRemoveTagClick() {
        const $this = $(this);
        const $li = $this.closest('li');
        const oid = $li.data('oid');
        const type = $li.data('type');
        const action = $this.data('id');
        const raci: Model.Issues.RACI = getRACIForType(type, oid);

        if (!action) {
            return;
        }

        switch (action) {
            case 'accountable':
                raci.IsAccountable = false;
                break;
            case 'responsible':
                raci.IsResponsible = false;
                break;
            case 'consulted':
                raci.IsConsulted = false;
                break;
            case 'informed':
                raci.IsInformed = false;
                break;
        }

        $this.remove();

        $li.toggleClass('unassigned', !hasResponsibleAssignments(raci));

        checkForModifications();
    }

    function onRemoveAssignmentClick(evt) {
        evt.stopPropagation();

        const $this = $(this);
        const $li = $this.closest('li');
        const oid = $li.data('oid');
        const type = $li.data('type');

        if (!_options.ResponsibilityAssignments) {
            return;
        }

        switch (type) {
            case PickerMode.Users:
                {
                    if (!_options.ResponsibilityAssignments.Users || !_options.ResponsibilityAssignments.Users[oid]) {
                        return;
                    }

                    delete _options.ResponsibilityAssignments.Users[oid];
                }
                break;
            case PickerMode.Teams:
                {
                    if (!_options.ResponsibilityAssignments.Teams || !_options.ResponsibilityAssignments.Teams[oid]) {
                        return;
                    }

                    delete _options.ResponsibilityAssignments.Teams[oid];
                }
                break;
            case PickerMode.Contacts:
                {
                    if (!_options.ResponsibilityAssignments.Contacts || !_options.ResponsibilityAssignments.Contacts[oid]) {
                        return;
                    }

                    delete _options.ResponsibilityAssignments.Contacts[oid];
                }
                break;
            case PickerMode.ContactGroups:
                {
                    if (!_options.ResponsibilityAssignments.ContactGroups || !_options.ResponsibilityAssignments.ContactGroups[oid]) {
                        return;
                    }

                    delete _options.ResponsibilityAssignments.ContactGroups[oid];
                }
                break;
        }

        render();
        checkForModifications();
    }

    function hasResponsibleAssignments(raci: Model.Issues.RACI) {
        if (!raci) {
            return false;
        }

        return !!(raci.IsInformed || raci.IsConsulted || raci.IsAccountable || raci.IsResponsible);
    }

    function render(): void {
        const items = getSelectableItems();

        if (!$win) {
            buildWindow(items);
        } else {
            refreshWindow(items);
        }

        $footerNavigation.find(`li[data-tab="${selectionMode}"]`)
            .addClass('active')
            .siblings('[data-tab]')
            .removeClass('active');
    }

    function buildWindow(items: ISelectionWithRACI[]): void {
        $win = $(Templates.ResponsibilityPicker.Window({
            SelectableItemsCount: (items || []).length,
            Items: items,
            SelectableItemsMarkup: getSelectableItemsListMarkup(items),
            CurrentView: selectionMode
        }));

        $footerNavigation = $win.find('.footer-navigation');
        $search = $win.find('.search');
        $content = $win.find('.content');
        $footer = $win.find('.footer');

        $('body').append($win);

        searchInput.Build($win.find('.search'));
        searchInput.SetPlaceholderText(i18next.t('SearchfieldPlaceholders.Generic'));

        $overlay = Utils.Overlay.Generate('olResponsibilityPicker', 10000, destroy);

        bindEvents();

        if (Session.LastKnownAPIVersion < 14) {
            hideTagsAndResponsibilityAssignments();
        }

        disableContactEditing();
        resize();
    }

    function refreshWindow(items: ISelectionWithRACI[]): void {
        $content = $(Templates.ResponsibilityPicker.Window({
            SelectableItemsCount: (items || []).length,
            Items: items,
            SelectableItemsMarkup: getSelectableItemsListMarkup(items),
            CurrentView: selectionMode
        })).find('.content');

        $win.find('.content').replaceWith($content);

        resize();

        if (selectionMode === PickerMode.Teams) {
            $content.on('click.showTeamMembers', '.unselected .show-team-members', onShowTeamMembersClick);
        }

        if (selectionMode === PickerMode.TeamMembers) {
            $content.on('click.hideTeamMembers', '.hide-team-members', onHideTeamMembersClick);
        }

        bindSelectionEvents();

        if (Session.LastKnownAPIVersion < 14) {
            hideTagsAndResponsibilityAssignments();
        }

        disableContactEditing();
    }

    function bindSelectionEvents() {
        if (!_options.AllowTeams && !_options.AllowUsers && !_options.AllowContacts && !_options.AllowContactGroups) {
            return;
        }

        if (selectionMode === PickerMode.Selected) {
            $content.on('click.editItem', '.edit', onEditResponsibilitiesClick);
            $win.on('click.hideRACIDropdown', hideRACIDropdown);
            $content.on('click.removeTag', '.tag:not(.read-only)', onRemoveTagClick);
            $content.on('click.removeAssignment', '.remove', onRemoveAssignmentClick);
        } else {
            $content.on('click.addItem', '.unselected', onUnselectedItemClick);
        }
    }

    function hideTagsAndResponsibilityAssignments() {
        if (Session.LastKnownAPIVersion < 14) {
            // alte API Versionen hatten keine Unterstützung für das komplette RACI Model
            $content.find('.edit').remove();
        }
    }

    function disableContactEditing() {
        if (selectionMode === PickerMode.Selected) {
            if (!Utils.UserHasIssueRight(_options.Issue, Enums.Rights.IssueProperties_Contacts) || Session.LastKnownAPIVersion < 14) {
                // alte API Versionen hatten keine Unterstützung für das komplette RACI Model
                $content.find('li[data-type="contacts"] .edit').remove();
            }

            if (!Utils.UserHasIssueRight(_options.Issue, Enums.Rights.IssueProperties_ContactGroups) || Session.LastKnownAPIVersion < 14) {
                // alte API Versionen hatten keine Unterstützung für das komplette RACI Model
                $content.find('li[data-type="contactgroups"] .edit').remove();
            }
        }
    }

    function getSelectableItemsListMarkup(items: ISelectionWithRACI[]) {
        if (selectionMode === PickerMode.TeamMembers) {
            return Templates.ResponsibilityPicker.TeamMembers({
                Team: DAL.Teams.GetByOID(selectedTeamIdentifier),
                Items: items,
                DataMode: PickerMode.Users
            });
        }

        return Templates.ResponsibilityPicker.SelectableItemsList({
            Items: items,
            DataMode: selectionMode,
            UserSelectionAllowed: _options.AllowUsers
        });
    }

    function hideByEscape(evt): void {
        if (evt.keyCode === 27) {
            destroy();
        }
    }

    function bindEvents(): void {
        $footerNavigation.on('click.changeMode', 'li[data-tab]:not(.active)', onTabClick);
        $footerNavigation.on('click.changeHierarchy', 'li[data-action="hierarchy-filter"]', onHierarchyFilterClick);

        if (selectionMode === PickerMode.Teams) {
            $content.on('click.addItem', '.unselected .show-team-members', onShowTeamMembersClick);
        }

        searchInput.ClearFilterListeners()
            .AddFilterListener(() => {
                render();
            });

        bindSelectionEvents();

        $footer.on('click', '.btn:not(.btn-apply)', destroy);
        $footer.on('click', '.btn-apply', returnAndDestroy);
        $doc.on('keydown.closeWindow', hideByEscape);
        $(window).on('resize.ResponsibilityPicker', resize);
    }

    function hideRACIDropdown(evt) {
        evt.stopPropagation();

        $win.find('.action-dropdown-menu').remove();
        dropdownIsActive = false;
    }

    export function Show(options: IOptions): Deferred {
        _options = $.extend(true, {}, options);
        resultDeferred = $.Deferred();

        const searchOptions = new Model.ClearableInput.Settings({
            ID: 'responsibility-selection-search-input',
            InputType: 'text',
            ShowClearButton: true
        });

        searchInput = new Model.ClearableInput.Control(searchOptions);

        $doc = $(document);
        $('body').addClass('modal-open');

        if (_options.FilterIssueResponsibilities) {
            filterAvailableUsersAndTeamsByOwnTeamAssignments();
        }

        if (!selectionMode) {
            if (!initMode(_options)) {
                return resultDeferred.reject();
            }
        }

        hierarchyFilter = 'global';

        setSelectableTeamsAndUsers();
        render();

        selectionBackup = $.extend(true, {}, _options.ResponsibilityAssignments);

        if (!_options.AllowUsers) {
            $footerNavigation.find('li[data-tab="users"]').remove();
        }

        if (!_options.AllowTeams) {
            $footerNavigation.find('li[data-tab="teams"]').remove();
        }

        if (!_options.AllowContacts) {
            $footerNavigation.find('li[data-tab="contacts"]').remove();
        }

        if (!_options.AllowContactGroups) {
            $footerNavigation.find('li[data-tab="contactgroups"]').remove();
        }

        return resultDeferred.promise();
    }

    function initMode(filters?: IOptions): boolean {
        for (let type in _options.ResponsibilityAssignments) {
            const assignments = _options.ResponsibilityAssignments[type];

            if (Utils.HasProperties(assignments)) {
                selectionMode = PickerMode.Selected;
                return true;
            }
        }

        if (filters.AllowUsers) {
            selectionMode = PickerMode.Users;
            return true;
        }

        if (filters.AllowTeams) {
            selectionMode = PickerMode.Teams;
            return true;
        }

        if (filters.AllowContacts) {
            selectionMode = PickerMode.Contacts;
            return true;
        }

        if (filters.AllowContactGroups) {
            selectionMode = PickerMode.ContactGroups;
            return true;
        }

        Utils.Message.Show(i18next.t('UserPicker.FaultySettings.MessageHeader'),
            i18next.t('UserPicker.FaultySettings.MessageBody'),
            {
                Close: true
            });

        return false;
    }

    function filterAvailableUsersAndTeamsByOwnTeamAssignments(): void {
        const teamsOfUser = DAL.Teams.GetByUserOID(Session.User.OID);

        membersOfUserTeams = $.unique($.map(teamsOfUser, function(team) {
            const members = [];

            if (!(team.Users || []).length) {
                return null;
            }

            for (let pCnt = 0, pLen = team.Users.length; pCnt < pLen; pCnt++) {
                members.push(team.Users[pCnt].OID);
            }

            if (members.length) {
                return members;
            }
        }));

        userTeams = $.map(teamsOfUser, function(team) {
            return team.OID;
        });
    }

    export function IsVisible(): boolean {
        return $win && $win.is(':visible');
    }
}
