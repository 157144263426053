//imports-start
/// <reference path="../issues/raw-issue.ts"  />
/// <reference path="../model.errors.ts"  />
/// <reference path="../model.temporary-issue.ts"  />
/// <reference path="../properties/property.ts"  />
/// <reference path="../scheduling/scheduling.ts"  />
/// <reference path="../../app/app.parameter-list.ts"  />
/// <reference path="../../app/app.session.ts"  />
/// <reference path="../../dal/scheduling.ts"  />
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../enums.ts"  />
/// <reference path="../elements/element.ts"  />
//imports-end

module Model.TreeCache {
    export class CacheMetaNode {
        private issuesArray: Array<Issues.Issue>;
        private issuesByKey: Dictionary<Issues.Issue>;
        private counter: Dictionary<number>;
        private processingState: Dictionary<Enums.IssueProcessingStatus>;
        private schedulingsDict: Dictionary<Dictionary<Model.Scheduling.IRelation>>;

        public oid: string;
        public title: string;
        public parent: CacheMetaNode;
        public children: Array<CacheMetaNode>;

        constructor() {
            this.issuesArray = [];
            this.issuesByKey = {};
            this.counter = {};
            this.processingState = {};

            this.schedulingsDict = {};
        }

        public addIssue(issue: Issues.Issue): boolean {
            if (this.issuesByKey[issue.OID] === issue) {
                // wenn die Instanz bereits im Tree existiert, keine Änderungen durchführen
                return false;
            }

            let isUpdate = false;
            if (this.issuesByKey[issue.OID]) {

                for (let i = 0, len = this.issuesArray.length; i < len; i++) {
                    let tmpIssue = this.issuesArray[i];
                    if (tmpIssue.OID == issue.OID) {
                        this.issuesArray[i] = issue;
                        isUpdate = true;
                        break;
                    }
                }
            }

            this.issuesByKey[issue.OID] = issue;

            if (isUpdate) {
                // reset all meta data
                this.processingState = null;
                this.counter = null;
            } else {
                // add to issues list
                this.issuesArray.push(issue);

                // counter by type
                if (this.counter != null) {
                    if (this.counter[issue.Type] == null) {
                        this.counter[issue.Type] = 1;
                    } else {
                        this.counter[issue.Type]++;
                    }
                }

                if (this.processingState != null) {
                    // processing status by type
                    let state = DAL.Issues.GetProcessingStatusForIssue(<any>issue);
                    if (this.processingState[issue.Type] == null) {
                        this.processingState[issue.Type] = state;
                    } else if (this.processingState[issue.Type] < state) {
                        this.processingState[issue.Type] = state;
                    }
                }
            }

            return true;
        }

        public removeIssue(issue: Issues.Issue): void {
            if (this.issuesByKey[issue.OID]) {
                for (let i = 0, len = this.issuesArray.length; i < len; i++) {
                    let tmpIssue = this.issuesArray[i];
                    if (tmpIssue.OID == issue.OID) {
                        this.issuesArray.splice(i, 1);
                        delete this.issuesByKey[issue.OID];
                        // reset meta data
                        this.processingState = null;
                        this.counter = null;
                        return;
                    }
                }
            }
        }

        public addScheduling(relation: Model.Scheduling.IRelation): void {
            if (!relation || relation.LocationSettings.OID !== this.oid) {
                return;
            }

            this.schedulingsDict[relation.SchedulingOID] = this.schedulingsDict[relation.SchedulingOID] || {};
            this.schedulingsDict[relation.SchedulingOID][relation.ElementOID] = relation;
        }

        public removeScheduling(relation: Model.Scheduling.IRelation): void {
            if (this.schedulingsDict[relation.SchedulingOID]) {
                delete this.schedulingsDict[relation.SchedulingOID][relation.ElementOID];
            }
        }

        public removeAllScheduling(): void {
            this.schedulingsDict = {};
        }

        public purge(): void {
            this.issuesArray = [];
            this.issuesByKey = {};
            this.counter = {};
            this.processingState = {};
        }

        private updateMetaData() {
            this.counter = {};
            this.processingState = {};

            for (let key in this.issuesByKey) {
                let issue = this.issuesByKey[key]

                // update type counters
                if (this.counter[issue.Type] == null) {
                    this.counter[issue.Type] = 1;
                } else {
                    this.counter[issue.Type]++;
                }

                // processing status by type
                let state = DAL.Issues.GetProcessingStatusForIssue(<any>issue);
                if (this.processingState[issue.Type] == null) {
                    this.processingState[issue.Type] = state;
                } else if (this.processingState[issue.Type] < state) {
                    this.processingState[issue.Type] = state;
                }
            }
        }

        public getState(): Enums.IssueProcessingStatus {
            let taskState = Enums.IssueProcessingStatus.OK;
            const def = Enums.IssueProcessingStatus.OK;

            if (this.processingState == null) {
                this.updateMetaData();
            }

            for (let key in this.processingState) {
                taskState = Math.max(this.processingState[key] || def, taskState);
            }

            return taskState;
        }

        public getCount(): number {
            let totalCount = 0;

            if (this.counter == null) {
                this.updateMetaData();
            }

            for (let type in this.counter) {
                totalCount += this.counter[type] || 0;
            }

            return totalCount;
        }

        public getStateCounter(): Model.Issues.Counter {
            return new Model.Issues.Counter(this.getCount(), this.getState());
        }

        public getTotalStateCounter(): Model.Issues.Counter {
            const counter = this.getStateCounter();

            // iterate through children
            for (let i = 0, len = this.children.length; i < len; i++) {
                let child = this.children[i];
                let childCounters = child.getTotalStateCounter();
                counter.merge(childCounters);
            }

            return counter;
        }

        public getSchedulings(): Array<Model.Scheduling.IRelation> {
            const result: Model.Scheduling.IRelation[] = [];

            for (const schedulingOID in this.schedulingsDict) {
                if (!this.schedulingsDict.hasOwnProperty(schedulingOID)) {
                    continue;
                }

                const elementRelations = this.schedulingsDict[schedulingOID];
                for (const elementOID in elementRelations) {
                    if (elementRelations.hasOwnProperty(elementOID)) {
                        const relation = elementRelations[elementOID];
                        result.push(relation);
                    }
                }
            }

            return result;
        }

        public getTotalSchedulings(): Utils.HashSet {
            const totalSchedulings: Utils.HashSet = new Utils.HashSet();
            totalSchedulings.putObjectKeys(this.schedulingsDict);

            // Kindelement nach Plänen durchsuchen
            if (this.children) {
                for (const child of this.children) {
                    const childSchedulings = child.getTotalSchedulings();
                    if (childSchedulings && childSchedulings.size()) {
                        totalSchedulings.putSet(childSchedulings);
                    }
                }
            }

            return totalSchedulings;
        }

        public getIssues(issueFilter?: Model.Issues.IFilter): Array<Issues.Issue> {
            let result: Array<Issues.Issue>;

            if (issueFilter) {
                // filter issues
                result = [];
                for (let i = 0, len = this.issuesArray.length; i < len; i++) {
                    let issue = this.issuesArray[i];
                    if (!DAL.Issues.FilterIssue(issueFilter, <any>issue)) {
                        result.push(issue);
                    }
                }
            } else {
                result = this.issuesArray.slice(0);
            }

            return result;
        }

        public getTotalIssues(issueFilter?: Model.Issues.IFilter): Array<Issues.Issue> {
            let result: Array<Issues.Issue> = this.getIssues(issueFilter);

            // sorting
            if (issueFilter && issueFilter.Sorting) {
                DAL.Issues.SortIssuesList(<any>result, issueFilter);
            }

            // iterate through children
            for (const child of this.children) {
                const childIssues = child.getTotalIssues(issueFilter);
                if (!childIssues.length) {
                    continue;
                } else if (!result.length) {
                    result = childIssues;
                } else {
                    for (const issue of childIssues) {
                        result.push(issue);
                    }
                }
            }

            return result;
        }

        public findChildByOID(oid: string): CacheMetaNode {
            if (this.oid == oid) {
                return this;
            }

            // iterate through children
            for (let i = 0, len = this.children.length; i < len; i++) {
                let child = this.children[i];
                let result = child.findChildByOID(oid);
                if (result) {
                    return result;
                }
            }

            return null;
        }

        public iterate(callback: (node: CacheMetaNode) => void) {
            callback.call(this, this);

            // iterate thru children
            for (let i = 0, len = this.children.length; i < len; i++) {
                let child = this.children[i];
                child.iterate(callback);
            }
        }

        public clone(issueFilter?: Model.Issues.IFilter, withChildren: boolean = true): CacheMetaNode {
            const newNode = new CacheMetaNode();
            newNode.oid = this.oid;
            newNode.title = this.title + '(clone)';
            newNode.counter = null;
            newNode.processingState = null;

            // Scheduling direkt übernehmen
            newNode.schedulingsDict = this.cloneScheduling();

            if (issueFilter) {
                // filter issues
                newNode.issuesArray = [];
                newNode.issuesByKey = {};

                for (const issue of this.issuesArray) {
                    if (!DAL.Issues.FilterIssue(issueFilter, <any>issue)) {
                        newNode.issuesArray.push(issue);
                        newNode.issuesByKey[issue.OID] = issue;
                    }
                }
            } else {
                // copy issues 1:1
                newNode.issuesArray = this.issuesArray.slice(0);
                newNode.issuesByKey = $.extend({}, this.issuesByKey);
            }

            if (withChildren && this.children != null) {
                newNode.children = [];

                for (const child of this.children) {
                    const newChild = child.clone(issueFilter);
                    newChild.parent = newNode;
                    newNode.children.push(newChild);
                }
            }

            return newNode;
        }

        private cloneScheduling(): Dictionary<Dictionary<Model.Scheduling.IRelation>> {
            const result: Dictionary<Dictionary<Model.Scheduling.IRelation>> = {};

            for (const keyScheduling in this.schedulingsDict) {
                if (this.schedulingsDict.hasOwnProperty(keyScheduling)) {
                    const elementDict = this.schedulingsDict[keyScheduling];

                    result[keyScheduling] = result[keyScheduling] || {};

                    for (const keyElement in elementDict) {
                        if (elementDict.hasOwnProperty(keyElement)) {
                            result[keyScheduling][keyElement] = elementDict[keyElement];
                        }
                    }
                }
            }

            return result;
        }

        public static generateTree(root: Model.Elements.Element | Model.TreeCache.CacheMetaNode, metaNodes: CacheMetaNode[], parent?: CacheMetaNode, filter?: (e: Model.Elements.Element | Model.TreeCache.CacheMetaNode) => boolean) {
            if (root == null) {
                return null;
            }

            const oid: string = (<Model.Elements.Element>root).OID || (<Model.TreeCache.CacheMetaNode>root).oid;
            const title: string = (<Model.Elements.Element>root).Title || (<Model.TreeCache.CacheMetaNode>root).title;
            const children = (<Model.Elements.Element>root).Children || (<Model.TreeCache.CacheMetaNode>root).children;

            const metaRoot: CacheMetaNode = metaNodes[oid] || new CacheMetaNode();
            metaRoot.parent = parent || null;
            metaRoot.children = [];
            metaRoot.oid = oid;
            metaRoot.title = title;

            // Pläne hinzufügen
            metaRoot.addScheduling.apply(metaRoot, this.getSchedulingsAtLocation(oid));

            for (let i = 0, len = (children || []).length; i < len; i++) {
                const nextNode = children[i];

                // Elemente filtern
                if (filter && !filter(nextNode)) {
                    continue;
                }

                const metaChild = CacheMetaNode.generateTree(nextNode, metaNodes, metaRoot, filter);
                metaRoot.children.push(metaChild);
            }

            return metaRoot;
        }

        private static getSchedulingsAtLocation(locationOID: string): Array<Model.Scheduling.IRelation> {
            const targetNode = DAL.TreeCache.Global.getNode(locationOID);
            if (!targetNode) {
                return null;
            }

            const availableSchedulings: Model.Scheduling.IRelation[] = [];
            const targetSchedulings = targetNode.getSchedulings();
            const userCanSeeAllSchedulings = DAL.Scheduling.IsUserAllowedToSeeAllSchedulings();

            targetSchedulings.forEach((relation: Model.Scheduling.IRelation) => {
                const scheduling = DAL.Scheduling.GetByOID(relation.SchedulingOID);

                if (scheduling &&
                    !scheduling.Deleted &&
                    (userCanSeeAllSchedulings || scheduling.IsCurrentUserAssigned())) {
                    availableSchedulings.push(relation);
                }
            });

            return availableSchedulings;
        }
    }
}
