module Model.DateTimePicker {
    export class PeriodTemplateContext {
        Days: Array<string>;
        HeaderText?: string;
        ShowPeriodSwitch: boolean;
        ShowDatePicker: boolean;

        constructor(days: Array<string>, headerText?: string) {
            this.Days = days;
            this.HeaderText = headerText;
            this.ShowPeriodSwitch = true;
            this.ShowDatePicker = true;
        }
    }
}