//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="./utils.router.ts"  />
/// <reference path="../app/app.session.ts"  />
/// <reference path="../app/app.form-issues-pool.ts"  />
/// <reference path="./utils.input-window.ts"  />
/// <reference path="../app/app.view.ts"  />
/// <reference path="../app/app.calendar.ts"  />
/// <reference path="../app/app.information.ts"  />
/// <reference path="../app/app.floor-plan.ts"  />
/// <reference path="../app/app.ts"  />
/// <reference path="../app/app.issue-view.ts"  />
/// <reference path="../utils/utils.synchronisation.download.ts"  />
/// <reference path="../enums.ts"  />
//imports-end

module Utils.Router {

    const _parameterGroups = [
        Enums.ElementType.FormFooter,
        Enums.ElementType.FormHeader,
        Enums.ElementType.FormRow,
        Enums.ElementType.Parametergroup,
        Enums.ElementType.SingletonFormRow
    ];

    function checkIfMenuActionIsAllowed(locationOID: string, menuAction: Enums.Menu.Action): boolean {
        if (!Utils.CanUserSeeLocation(Session.User.OID, Session.CurrentLocation)) {
            return true;
        }

        const enabledItems = Utils.GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Content, locationOID);
        for (let i = 0; i < (enabledItems || []).length; i++) {
            const menuID = enabledItems[i].substr(Enums.MenuSection.Content.length + 1);
            const menuItem = Menu.GetMenuItemConfig(menuID);
            if (menuItem && menuItem.ActionType == menuAction) {
                return true;
            }
            else if (!Menu.HasConfig()) {
                if (Enums.Menu.Action[menuAction] === menuID) {
                    return true;
                } else if (menuID === Enums.Menu.SystemID.Information && menuAction == Enums.Menu.Action.Info) {
                    return true;
                }
            }
        }
        return false;
    }

    function checkIfMenuItemIsEnabled(locationOID: string, uiItemID: Enums.MenuItemID | string): boolean {
        if (!Utils.CanUserSeeLocation(Session.User.OID, Session.CurrentLocation)) {
            return true;
        }

        const enabledItems = Utils.GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Content, locationOID);
        return Utils.InArray(enabledItems, uiItemID);
    }

    function routeToCorrectSection(locationOID: string): void {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        const enabledItems = Utils.GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Content, locationOID);
        let href;

        if ((enabledItems || []).length === 1) {
            switch (enabledItems[0]) {
                case Enums.MenuItemID.TaskReport:
                    href = `location/${locationOID}/task-report`;
                    break;
                case Enums.MenuItemID.NoteReport:
                    href = `location/${locationOID}/note-report`;
                    break;
                case Enums.MenuItemID.DisturbanceReport:
                    href = `location/${locationOID}/disturbance-report`;
                    break;
                case Enums.MenuItemID.Parameters:
                    href = `location/${locationOID}/parameters`;
                    break;
                case Enums.MenuItemID.Information:
                    href = `location/${locationOID}/information`;
                    break;
                case Enums.MenuItemID.FloorPlan:
                    href = `location/${locationOID}/floor-plan`;
                    break;
                default:
                    href = `location/${locationOID}/menu`;
            }
        } else {
            href = `location/${locationOID}/menu`;
        }

        ReplaceState(href);
    }

    function openIssue(issueIdentifier: string | number, issueType: Enums.IssueType, isBulletin: boolean = false): void {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (issueType == null || Utils.InArray([Enums.IssueType.Disturbance, Enums.IssueType.Note, Enums.IssueType.Task], issueType)) {
            if (View.CurrentView === Enums.View.Login) {
                App.ShowMainMode(function() {
                    App.GetBadgeCounters()
                        .then(function(counters) {
                            Menu.Show(counters);
                            App.HandleIssueHashChange(issueIdentifier, issueType, isBulletin);
                        });
                });
            } else {
                App.HandleIssueHashChange(issueIdentifier, issueType, isBulletin);
            }
        } else {
            App.HandleIssueHashChange(issueIdentifier, issueType, isBulletin);
        }
    }

    function showSettings(locationOID: string): void {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        Menu.ShowBackButton();

        App.GetAndSelectLocation(locationOID, Settings.Show);
    }

    function showCalendar(locationOID: string): void {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        Menu.ShowBackButton();

        App.GetAndSelectLocation(locationOID, function() {
            Calendar.Show();
        });
    }

    function showMenu(locationOID: string): void {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        Menu.HideBackButton();

        App.GetAndSelectLocation(locationOID, function() {
            App.GetBadgeCounters()
                .then((counters: Model.Menu.Counters) => {
                    Menu.Show(counters);
                });
        });
    }

    function showIssueReport(locationOID: string, issueReportType: string, menuID?: string): void {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        Menu.ShowBackButton();

        App.GetAndSelectLocation(locationOID, function() {
            if (menuID) {
                IssueReport.ShowByMenu(menuID);
            } else {
                IssueReport.Show(issueReportType);
            }
        });
    }

    function showIssuePool(locationOID: string, menuID?: string): void {
        if (!Session.IsSmartDeviceApplication || !Utils.IsSet(Session.User)) {
            return;
        }

        Menu.ShowBackButton();

        App.GetAndSelectLocation(locationOID, function() {
            App.FormIssuesPool.Show(menuID);
        });
    }

    function showInformation(locationOID: string, menuID?: string): void {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        Menu.ShowBackButton();

        App.GetAndSelectLocation(locationOID, function() {
            Information.Show(null, menuID);
        });
    }

    function showParameters(locationOID: string, menuID?: string): void {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        Menu.ShowBackButton();

        App.GetAndSelectLocation(locationOID, function() {
            if (!menuID) {
                ParameterList.Show(_parameterGroups);
            } else {
                ParameterList.ShowByMenu(_parameterGroups, menuID);
            }
        });
    }

    function showFloorplan(locationOID: string): void {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        Menu.ShowBackButton();

        App.GetAndSelectLocation(locationOID, FloorPlan.Show);
    }

    function showBluetoothConfiguration(locationOID: string): void {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        Menu.ShowBackButton();

        App.GetAndSelectLocation(locationOID, BluetoothConfiguration.Show);
    }

    function showScaleConfiguration(locationOID: string): void {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        Menu.ShowBackButton();

        App.GetAndSelectLocation(locationOID, ScaleConfiguration.Show);
    }

    function showCodeScannerConfiguration(locationOID): void {
        if (!Utils.IsSet(Session.User) || !CodeScanner.IsSupported()) {
            return;
        }

        App.GetAndSelectLocation(locationOID, CodeScanner.Show);
    }

    //
    // Routen für die App setzen
    //

    Add('?issue=:int&type=:int', (issueID: number, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let location = window.location.href;

        location = location.substr(0, location.indexOf('?'));
        location += `#issue/${issueID}?type=${issueType}`;

        history.replaceState(null, navigator.title, location);
        Utils.Router.RefreshState();
    });

    // Öffnen eines Issues per Link
    Add('issue/:int', openIssue);
    Add('issue/:int?type=:int', openIssue);
    Add('issue/:int?type=:int&isbulletin=:boolean', openIssue);
    Add('issue/:guid', openIssue);
    Add('issue/:guid?type=:int', openIssue);
    Add('issue/:guid?type=:int&isbulletin=:boolean', openIssue);

    Add('issue/:int/settings?type=:int', showSettings);
    Add('issue/:guid/settings?type=:int', showSettings);

    /* Routen zur Navigation innerhalb der Baumstruktur */
    Add('location/:guid', (locationOID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let mode = Session.Mode || Enums.Mode.Menu;

        if (Utils.InArray([
            Enums.Mode.Settings,
            Enums.Mode.About,
            Enums.Mode.SyncCenter,
            Enums.Mode.IssueProgressReport,
            Enums.Mode.BluetoothConfiguration,
            Enums.Mode.ScaleConfiguration
        ], mode)) {
            if (mode === Enums.Mode.IssueProgressReport) {
                IssueReport.ResetProcessingFilter();
            }

            mode = Enums.Mode.Menu;
        }

        if (mode === Enums.Mode.IssueReport && IssueReport.CustomMenuID) {
            ReplaceState(`location/${locationOID}/${mode}/${IssueReport.CustomMenuID}`);
        } else if (mode === Enums.Mode.IssuePools && !!App.FormIssuesPool.MenuItemFilterID) {
            ReplaceState(`location/${locationOID}/issue-pool/${App.FormIssuesPool.MenuItemFilterID}`);
        } else if (mode === Enums.Mode.Parameters && !!ParameterList.GetCustomMenuID()) {
            ReplaceState(`location/${locationOID}/${mode}/${ParameterList.GetCustomMenuID()}`);
        } else if (mode === Enums.Mode.Information && !!Information.GetMenuItemID()) {
            ReplaceState(`location/${locationOID}/${mode}/${Information.GetMenuItemID()}`);
        } else {
            ReplaceState(`location/${locationOID}/${mode}`);
        }
    });

    /* Routen zur Navigation innerhalb eines Raumes */
    Add('location/:guid/calendar', (locationOID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (View.CurrentView !== Enums.View.Main) {
            App.ShowMainMode(function() {
                showCalendar(locationOID);
            });
        } else {
            showCalendar(locationOID);
        }
    });

    Add('location/:guid/menu', (locationOID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (View.CurrentView !== Enums.View.Main) {
            App.ShowMainMode(function() {
                showMenu(locationOID);
            });
        } else {
            showMenu(locationOID);
        }
    });

    Add('location/:guid/issue-report', (locationOID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issueReportIsEnabled =
            checkIfMenuItemIsEnabled(locationOID, Enums.MenuItemID.TaskReport) ||
            checkIfMenuItemIsEnabled(locationOID, Enums.MenuItemID.NoteReport) ||
            checkIfMenuItemIsEnabled(locationOID, Enums.MenuItemID.DisturbanceReport);

        if (issueReportIsEnabled) {
            if (View.CurrentView !== Enums.View.Main) {
                App.ShowMainMode(function() {
                    showIssueReport(locationOID, 'issues');
                });
            } else {
                showIssueReport(locationOID, 'issues');
            }
        } else {
            routeToCorrectSection(locationOID);
        }
    });

    Add('location/:guid/task-report', (locationOID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (checkIfMenuItemIsEnabled(locationOID, Enums.MenuItemID.TaskReport)) {
            if (View.CurrentView !== Enums.View.Main) {
                App.ShowMainMode(function() {
                    showIssueReport(locationOID, 'tasks');
                });
            } else {
                showIssueReport(locationOID, 'tasks');
            }
        } else {
            routeToCorrectSection(locationOID);
        }
    });

    Add('location/:guid/issue-report/:guid', (locationOID: string, menuID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (checkIfMenuItemIsEnabled(locationOID, `${Enums.MenuSection.Content}_${menuID}`)) {
            if (View.CurrentView !== Enums.View.Main) {
                App.ShowMainMode(function() {
                    showIssueReport(locationOID, 'issues', menuID);
                });
            } else {
                showIssueReport(locationOID, 'issues', menuID);
            }
        } else {
            routeToCorrectSection(locationOID);
        }
    });

    Add('location/:guid/note-report', (locationOID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (checkIfMenuItemIsEnabled(locationOID, Enums.MenuItemID.NoteReport)) {
            if (View.CurrentView !== Enums.View.Main) {
                App.ShowMainMode(function() {
                    showIssueReport(locationOID, 'notes');
                });
            } else {
                showIssueReport(locationOID, 'notes');
            }
        } else {
            routeToCorrectSection(locationOID);
        }
    });

    Add('location/:guid/disturbance-report', (locationOID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (checkIfMenuItemIsEnabled(locationOID, Enums.MenuItemID.DisturbanceReport)) {
            if (View.CurrentView !== Enums.View.Main) {
                App.ShowMainMode(function() {
                    showIssueReport(locationOID, 'disturbances');
                });
            } else {
                showIssueReport(locationOID, 'disturbances');
            }
        } else {
            routeToCorrectSection(locationOID);
        }
    });

    Add('location/:guid/issue-pool/:guid', (locationOID: string, menuID: string) => {
        if (!Session.IsSmartDeviceApplication || !Utils.IsSet(Session.User)) {
            return;
        }

        if (checkIfMenuItemIsEnabled(locationOID, `${Enums.MenuSection.Content}_${menuID}`)) {
            if (View.CurrentView !== Enums.View.Main) {
                App.ShowMainMode(function() {
                    showIssuePool(locationOID, menuID);
                });
            } else {
                showIssuePool(locationOID, menuID);
            }
        } else {
            routeToCorrectSection(locationOID);
        }
    });

    AddRoutes([
        'location/:guid/information',
        'location/:guid/information/:guid'
    ], (locationOID: string, menuID?: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (!menuID && !checkIfMenuActionIsAllowed(locationOID, Enums.Menu.Action.Info) ||
            menuID && !checkIfMenuItemIsEnabled(locationOID, `${Enums.MenuSection.Content}_${menuID}`)) {
            routeToCorrectSection(locationOID);
            return;
        }

        if (!Utils.InArray([Enums.View.Main, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            App.ShowMainMode(function() {
                showInformation(locationOID, menuID);
            });
        } else {
            showInformation(locationOID, menuID);
        }
    });

    Add('location/:guid/floor-plan', (locationOID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (checkIfMenuActionIsAllowed(locationOID, Enums.Menu.Action.FloorPlan)) {
            if (!Utils.InArray([Enums.View.Main, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
                App.ShowMainMode(function() {
                    showFloorplan(locationOID);
                });
            } else {
                showFloorplan(locationOID);
            }
        } else {
            routeToCorrectSection(locationOID);
        }
    });

    Add('location/:guid/parameters', (locationOID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (checkIfMenuActionIsAllowed(locationOID, Enums.Menu.Action.Parameters)) {
            if (View.CurrentView !== Enums.View.Main) {
                App.ShowMainMode(function() {
                    showParameters(locationOID);
                });
            } else {
                showParameters(locationOID);
            }
        } else {
            routeToCorrectSection(locationOID);
        }
    });

    Add('location/:guid/parameters/:guid', (locationOID: string, menuID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (checkIfMenuItemIsEnabled(locationOID, `${Enums.MenuSection.Content}_${menuID}`)) {
            if (View.CurrentView !== Enums.View.Main) {
                App.ShowMainMode(function() {
                    showParameters(locationOID, menuID);
                });
            } else {
                showParameters(locationOID, menuID);
            }
        } else {
            routeToCorrectSection(locationOID);
        }
    });

    Add('location/:guid/settings', showSettings);

    Add('location/:guid/bluetooth-configuration', showBluetoothConfiguration);

    Add('location/:guid/scale-configuration', showScaleConfiguration);

    Add('location/:guid/code-scanner', showCodeScannerConfiguration);

    /* Routen für die Navigation innerhalb von Vorgängen */
    Add('issue/:int/location/:guid?type=:int', (issueID: number, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.ID === issueID) {
            ReplaceState(`issue/${issue.ID}/location/${locationOID}/${Session.Mode}?type=${issueType}`);
        } else {
            openIssue(issueID, issueType);
        }
    });

    Add('issue/:int/location/:guid/menu?type=:int', (issueID: number, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.ID === issueID) {
            showMenu(locationOID);
        } else {
            openIssue(issueID, issueType);
        }
    });

    Add('issue/:int/location/:guid/information?type=:int', (issueID: number, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.ID === issueID) {
            if (checkIfMenuItemIsEnabled(locationOID, Enums.MenuItemID.Information)) {
                showInformation(locationOID);
            } else {
                showMenu(locationOID);
            }
        } else {
            openIssue(issueID, issueType);
        }
    });

    Add('issue/:int/location/:guid/floor-plan?type=:int', (issueID: number, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.ID === issueID) {
            if (checkIfMenuItemIsEnabled(locationOID, Enums.MenuItemID.FloorPlan)) {
                showFloorplan(locationOID);
            } else {
                showMenu(locationOID);
            }
        } else {
            openIssue(issueID, issueType);
        }
    });

    Add('issue/:int/location/:guid/parameters?type=:int', (issueID: number, locationOID: string, issueType: Enums.IssueType) => {
        /* Inspection */
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        const issue = IssueView.GetCurrentIssue();
        if (issue && issue.ID === issueID) {
            Menu.ShowBackButton();

            App.GetAndSelectLocation(locationOID, function() {
                ParameterList.Show(_parameterGroups);
            });
        } else {
            openIssue(issueID, issueType);
        }
    });

    AddRoutes([
        'menu/:string/location/:guid/issue/:guid',
        'menu/:string/location/:guid/issue/:int',
        'menu/:string/location/:guid?formOID=:guid&stateOID=:guid'
    ], function(menuID: string, locationOID: string, issueOID_formOID: string | number | null, stateOID: string) {
        /* Batch Form Issue Editor */
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (!checkIfMenuItemIsEnabled(locationOID, `${Enums.MenuSection.Content}_${menuID}`)) {
            showMenu(locationOID);
            return;
        }

        App.FormIssuesPool.UpdateIssuesCache(menuID, locationOID);

        let issue;
        if (this.indexOf('stateOID') >= 0) {
            // update tree cache
            App.FormIssuesPool.GenerateIssuesTree(<string>issueOID_formOID, stateOID);
            issue = App.FormIssuesPool.GetFirstIssue(<string>issueOID_formOID, stateOID);
        } else {
            issue = App.FormIssuesPool.GetIssue(issueOID_formOID);
        }

        if (!issue) {
            // go back to menu
            routeToCorrectSection(locationOID);
            return;
        }

        if (!issueOID_formOID) {
            // set new url and proceed with current issue
            Utils.Router.ReplaceState(`menu/${menuID}/location/${locationOID}/issue/${issue.ID || issue.OID}`, true);
        }

        App.GetAndSelectLocation(issue.AssignedElementOID, function() {
            // open view / form
            App.FormIssuesPool.ShowRecording(issue);
        }, false);
    });

    Add('issue/:int/location/:guid/covering-page?type=:int', (issueID: number, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.ID === issueID) {
            App.GetAndSelectLocation(locationOID, IssueView.ShowCoveringPage);
        } else {
            openIssue(issueID, issueType);
        }
    });

    Add('issue/:int/location/:guid/issue-report?type=:int', (issueID: number, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.ID === issueID) {
            if (View.CurrentView !== Enums.View.Inspection) {
                Menu.ShowBackButton();
            }

            App.GetAndSelectLocation(locationOID, IssueView.RenderInspectionIssues);
        } else {
            openIssue(issueID, issueType);
        }
    });

    Add('issue/:guid/location/:guid?type=:int', (issueOID: string, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.OID === issueOID) {
            ReplaceState(`issue/${issue.OID}/location/${locationOID}/${Session.Mode}?type=${issueType}`);
        } else {
            openIssue(issueOID, issueType);
        }
    });

    Add('issue/:guid/location/:guid/menu?type=:int', (issueOID: string, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.OID === issueOID) {
            showMenu(locationOID);
        } else {
            openIssue(issueOID, issueType);
        }
    });

    Add('issue/:guid/location/:guid/information?type=:int', (issueOID: string, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.OID === issueOID) {
            if (checkIfMenuItemIsEnabled(locationOID, Enums.MenuItemID.Information)) {
                showInformation(locationOID);
            } else {
                showMenu(locationOID);
            }
        } else {
            openIssue(issueOID, issueType);
        }
    });

    Add('issue/:guid/location/:guid/floor-plan?type=:int', (issueOID: string, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.OID === issueOID) {
            if (checkIfMenuItemIsEnabled(locationOID, Enums.MenuItemID.FloorPlan)) {
                showFloorplan(locationOID);
            } else {
                showMenu(locationOID);
            }
        } else {
            openIssue(issueOID, issueType);
        }
    });

    Add('issue/:guid/location/:guid/parameters?type=:int', (issueOID: string, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.OID === issueOID) {
            if (checkIfMenuItemIsEnabled(locationOID, Enums.MenuItemID.Parameters) ||
                View.CurrentView == Enums.View.Scheduling) {
                Menu.ShowBackButton();

                App.GetAndSelectLocation(locationOID, function() {
                    ParameterList.Show(_parameterGroups);
                });
            } else {
                showMenu(locationOID);
            }
        } else {
            openIssue(issueOID, issueType);
        }
    });

    Add('issue/:guid/location/:guid/covering-page?type=:int', (issueOID: string, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.OID === issueOID) {
            App.GetAndSelectLocation(locationOID, IssueView.ShowCoveringPage);
        } else {
            openIssue(issueOID, issueType);
        }
    });

    Add('issue/:guid/location/:guid/issue-report?type=:int', (issueOID: string, locationOID: string, issueType: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        let issue = IssueView.GetCurrentIssue();
        if (issue && issue.OID === issueOID) {
            if (View.CurrentView !== Enums.View.Inspection) {
                Menu.ShowBackButton();
            }

            App.GetAndSelectLocation(locationOID, IssueView.RenderInspectionIssues);
        } else {
            openIssue(issueOID, issueType);
        }
    });

    Add('issue/:guid/location/:guid/settings?type=:int', (iI: string, locationIdentifier: string, iT: Enums.IssueType) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        showSettings(locationIdentifier);
    });

    Add('individual-data/:string/*', (schemaType: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }
        // Menu.ShowBackButton();

        View.Open(Enums.View.IndividualData, schemaType);
    });

    Add('individual-data/:string/:string/*', (schemaType: string, itemID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        // Menu.ShowBackButton();

        if (View.CurrentView === Enums.View.IndividualData) {
            IndividualData.Show(schemaType, itemID);
        } else {
            View.Open(Enums.View.IndividualData, schemaType, itemID);
        }
    });

    /* Raumunabhängige Routen */
    Add('main', () => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        const fragment = 'location/' + DAL.Elements.Root.OID + '/menu';

        if (View.CurrentView === Enums.View.Main &&
            Session.Mode === Enums.Mode.IssueProgressReport) {
            IssueReport.ResetProcessingFilter();
        } else if (Utils.InArray([Enums.View.Form, Enums.View.Scheduling], View.CurrentView)) {
            App.SetRenderTree(true);
        } else if (View.CurrentView === Enums.View.Inspection) {
            const tree = App.GetTree();

            if (tree) {
                App.SetRenderTree(false);
                tree.SetAnchorSuffix('');
            }
        } else if (SyncCenter.HasDataBeenSynchronised() &&
            Utils.Synchronisation.Download.GetWhetherToIssueCommandToUpdateTree()) {
            App.SetRenderTree(true);
            SyncCenter.ResetState();
        }

        ReplaceState(fragment);
    });

    Add('about', App.ShowAbout);

    AddRoutes(['issue-report/progress/:int', 'issue-report/progress/:int?from=:string'], (processingStatus: Enums.IssueProcessingStatus) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        Menu.ShowBackButton();

        if (View.CurrentView !== Enums.View.Main) {
            App.ShowMainMode(function() {
                IssueReport.Show('issue-progress', null, processingStatus);
            });
        } else {
            IssueReport.Show('issue-progress', null, processingStatus);
        }
    });

    Add('location/:guid/settings', (locationOID: string) => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (View.CurrentView !== Enums.View.Main) {
            App.ShowMainMode(function() {
                showSettings(locationOID);
            });
        } else {
            showSettings(locationOID);
        }
    });

    Add('^settings', () => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (View.CurrentView !== Enums.View.Main) {
            View.SetView(Enums.View.Main);
        }

        ReplaceState(`#location/${Session.CurrentLocation.OID}/settings`);
    });

    Add('^bluetooth-configuration', () => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (View.CurrentView !== Enums.View.Main) {
            View.SetView(Enums.View.Main);
        }

        ReplaceState(`#location/${Session.CurrentLocation.OID}/bluetooth-configuration`);
    });

    Add('^scale-configuration', () => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (View.CurrentView !== Enums.View.Main) {
            View.SetView(Enums.View.Main);
        }

        ReplaceState(`#location/${Session.CurrentLocation.OID}/scale-configuration`);
    });

    Add('^code-scanner', () => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (View.CurrentView !== Enums.View.Main) {
            View.SetView(Enums.View.Main);
        }

        ReplaceState(`#location/${Session.CurrentLocation.OID}/code-scanner`);
    });

    Add('sync-center', () => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        SyncCenter.Show();
    });

    Add('sync-center/timestamps', () => {
        if (!Utils.IsSet(Session.User)) {
            return;
        }

        SyncCenter.ShowResponseTimestamps();
    });
}