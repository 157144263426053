///<reference path="utils.draw-handler.ts"/>
///<reference path="../../lib/d3.min.js" />

module Utils.SVGEditor {

    export class PathDrawHandler extends DrawHandler {

        private static REGEX_REMOVE_DECIMALS: RegExp = /([.][0-9])[0-9]*/g;
        private static d3LineGenerator = PathDrawHandler.d3CreateLineGenerator();

        private ptdata: Array<IPoint> = [];    // point data, eigentlicher Typ: Datum aus d3.js
        private d3path: any;

        constructor(editor: SVGEditor, svg: SVGSVGElement, onChangeListener?: Function) {
            super(editor, svg, onChangeListener);
        }

        public bindEvents(): void {
            this.d3SvgElement
                .on('mousedown', (event: MouseEvent) => this.onStartDrawing(event))
                .on('touchstart', (event: TouchEvent) => this.onStartDrawing(event))
                .on('touchend', () => this.onFinishDrawing())
                .on('touchleave', () => this.onFinishDrawing())
                .on('mouseup', () => this.onFinishDrawing())
                .on('mouseleave', () => this.onFinishDrawing());
        }

        private static d3CreateLineGenerator(): any {
            const d3CurveFactory = d3.curveBundle.beta(1);
            return d3.line()
                .curve(d3CurveFactory)
                .x((d: IPoint) => d.x)
                .y((d: IPoint) => d.y);
        }

        private d3GenerateLine(...args: Array<any>): any {
            const data = PathDrawHandler.d3LineGenerator.apply(this, args);
            return data
                ? data.replace(PathDrawHandler.REGEX_REMOVE_DECIMALS, '')
                : data;
        }

        private getVectorLengthPow2(vector: IPoint): number {
            /*
                Die Länge des Vektors zum Quadrat zurückgeben.
                Erspart das aufwändige Anwenden der Wurzelfunktion .sqrt() | Viktor, commit: c91dabf
            */

            if (!vector.x && !vector.y) {
                return 0;
            }

            if (!vector.x) {
                return vector.y * vector.y;
            }

            if (!vector.y) {
                return vector.x * vector.x;
            }

            return vector.x * vector.x + vector.y * vector.y;
        }

        private equalsDirection(vectorA: IPoint, vectorB: IPoint): boolean {
            if (vectorA.x !== vectorB.x && (!vectorA.x || !vectorB.x) ||
                vectorA.y !== vectorB.y && (!vectorA.y || !vectorB.y)) {
                return false;
            }

            if (vectorA.x === 0 && vectorB.x ||
                vectorA.y === 0 && vectorB.y) {
                return true;
            }

            return (vectorA.x / vectorA.y) === (vectorB.x / vectorB.y);
        }

        private updateLinePath(): void {
            this.d3path.attr('d', this.d3GenerateLine);
        }

        private onStartDrawing(event: MouseEvent | TouchEvent): void {
            this.isUserDrawing = true;

            const d3SvgGroup = this.d3SvgElement.select('g');

            this.ptdata = [];
            this.d3path = d3SvgGroup.append('path')
                .data([this.ptdata])
                .attr('class', 'line')
                .attr('d', this.d3GenerateLine);

            const pathStyle = this.d3path.node().style;

            pathStyle.stroke = this.editor.DrawStyle.color;
            pathStyle.strokeWidth = this.editor.DrawStyle.strokeWidth;
            pathStyle.strokeOpacity = this.editor.DrawStyle.strokeOpacity;
            pathStyle.fill = 'none';
            pathStyle.strokeLinejoin = 'round';
            pathStyle.strokeLinecap = 'round';

            this.editor.IsModified = true;

            const point = this.getPoint(event);
            if (point) {
                this.ptdata.push(point);
                this.updateLinePath();
            }

            if (event.type === 'mousedown') {
                this.d3SvgElement.on('mousemove', (event: MouseEvent) => this.onMove(event));
            } else {
                this.d3SvgElement.on('touchmove', (event: TouchEvent) => this.onMove(event));

                const svgElement = this.d3SvgElement.node();
                svgElement.addEventListener('touchmove', (event: TouchEvent) => event.preventDefault(), false);
            }
        }

        private onFinishDrawing(): void {
            this.d3SvgElement.on('mousemove', null);
            this.d3SvgElement.on('touchmove', null);

            if (!this.isUserDrawing) {
                return;
            }

            this.isUserDrawing = false;
            this.updateLinePath();
            this.triggerChange();
        }

        private onMove(event: MouseEvent | TouchEvent): void {
            const point: IPoint | null = this.getPoint(event);

            if (!point) {
                return;
            }

            if (this.ptdata.length) {
                const lastPoint = this.ptdata[this.ptdata.length - 1];
                if (lastPoint.x === point.x &&
                    lastPoint.y === point.y) {
                    // kein Update, wenn keine Änderung vorliegt
                    return;
                }

                if (this.ptdata.length > 1) {
                    const preLastPoint = this.ptdata[this.ptdata.length - 2];
                    const lastDirectionVector: IPoint = { x: (lastPoint.x - preLastPoint.x), y: (lastPoint.y - preLastPoint.y) };
                    const newDirectionVector: IPoint = { x: (point.x - lastPoint.x), y: (point.y - lastPoint.y) };

                    if (this.equalsDirection(lastDirectionVector, newDirectionVector)) {
                        // bei gleichbleibender Richtung, unnötige Punkte eliminieren
                        this.ptdata.pop();
                    } else {
                        const lastVectorLength = this.getVectorLengthPow2(lastDirectionVector);
                        const newVectorLength = this.getVectorLengthPow2(newDirectionVector);

                        const refLength = this.editor.DrawStyle.strokeWidth * 0.5;
                        if (lastVectorLength + newVectorLength < (refLength * refLength)) {
                            // Zwischenpunkte reduzieren, wenn Abstand nicht mind 1/2 Strichbreite entspricht und im gleichen Winkel gezeichnet wird
                            this.ptdata.pop();
                        }
                    }
                }
            }

            this.ptdata.push(point);
            this.updateLinePath();
        }
    }
}
