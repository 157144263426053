//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../templates.d.ts"  />
/// <reference path="../model/model.errors.ts"  />
/// <reference path="../model/date-time/period.ts"  />
/// <reference path="../model/date-time-picker/options.ts"  />
/// <reference path="../model/date-time-picker/date-time-template-context.ts"  />
/// <reference path="../model/date-time-picker/period-template-context.ts"  />
/// <reference path="./utils.date-time.ts"  />
//imports-end

module Utils.DateTimePicker {
    let _$overlay, _$win;
    let _$calendar, _$customDateInput, _$inputLowerBoundary, _$inputUpperBoundary;
    let _$timePicker;
    let _periodSelectionEnabled: boolean;
    let _changeLowerBoundary: boolean;
    let _currentlySelectedDate: Date;
    let _currentlySelectedPeriod: Model.DateTime.Period;
    let _currentDisplayDate: Date;
    let _fnSuccess: Model.DateTimePicker.OnSelectDateFunc | Model.DateTimePicker.OnSelectPeriodFunc;

    let _swipeHandlerAttached: Boolean = false;
    let _hammerManager;

    function destroy(): void {
        _$calendar = null;
        _$timePicker = null;
        _$customDateInput = null;
        _$inputLowerBoundary = null;
        _$inputUpperBoundary = null;

        _periodSelectionEnabled = false;
        _changeLowerBoundary = true;
        _currentlySelectedDate = null;
        _currentlySelectedPeriod = null;
        _currentDisplayDate = null;
        _fnSuccess = null;

        if (Session.IsSmartDeviceApplication && _hammerManager) {
            _hammerManager.off('swipe');
            _hammerManager = null;
            _swipeHandlerAttached = false;
        }

        if (_$win) {
            _$win.remove();
            _$win = null;
        }

        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }

        if (!Utils.RecorditemEditor.IsVisible() &&
            !Utils.IssueViewer.IsVisible()) {
            $('body').removeClass('modal-open');
        }
    }

    function selectPreviousMonth(): void {
        _currentDisplayDate.setDate(1);
        _currentDisplayDate.setMonth(_currentDisplayDate.getMonth() - 1);
        _currentDisplayDate = new Date(<any>_currentDisplayDate);

        reRenderBody();
    }

    function onGotoCurrentDateClick(): void {
        _currentDisplayDate = new Date();

        reRenderBody();
    }

    function selectFollowingMonth(): void {
        _currentDisplayDate.setDate(1);
        _currentDisplayDate.setMonth(_currentDisplayDate.getMonth() + 1);
        _currentDisplayDate = new Date(<any>_currentDisplayDate);

        reRenderBody();
    }

    function onDateClick(): void {
        const $this = $(this);
        const selectedDate = new Date(parseInt($this.data('timestamp'), 10));

        if (!_periodSelectionEnabled) {
            if (selectedDate.getMonth() !== _currentlySelectedDate.getMonth()) {
                _currentDisplayDate = selectedDate;
                _currentlySelectedDate = new Date(selectedDate.getTime());

                reRenderBody();
                _$calendar.find(`td[data-timestamp="${$this.data('timestamp')}"]`).addClass('selected');
            } else {
                _currentlySelectedDate = selectedDate;

                _$calendar.find('td.selected').removeClass('selected');
                $this.addClass('selected');
            }
        } else {
            if (_changeLowerBoundary) {
                _currentlySelectedPeriod.PeriodStart = selectedDate;
                if (_currentlySelectedPeriod.PeriodEnd < selectedDate) {
                    _currentlySelectedPeriod.PeriodEnd = new Date(selectedDate.getTime() + (24 * 60 * 60 * 1000) - 1);;
                }
                _currentDisplayDate = new Date(selectedDate.getTime());
            } else {
                _currentlySelectedPeriod.PeriodEnd = new Date(selectedDate.getTime() + (24 * 60 * 60 * 1000) - 1);
                if (_currentlySelectedPeriod.PeriodStart > _currentlySelectedPeriod.PeriodEnd) {
                    _currentlySelectedPeriod.PeriodStart = selectedDate;
                }
                _currentDisplayDate = new Date(selectedDate.getTime() + (24 * 60 * 60 * 1000) - 1);
            }

            reRenderBody();
        }

        setDateInputValues();
    }

    function onBtnClearClick(evt: any): void {
        evt.stopPropagation();

        _currentlySelectedDate = null;
        _currentlySelectedPeriod = null;

        _$win.find('.btn-apply').click();
    }

    function onBtnOKClick(): void {
        if (!_periodSelectionEnabled) {
            if (_currentlySelectedDate && _$timePicker && !_$timePicker.hasClass('hidden')) {
                const selectedTime = _$timePicker.find('input').val() || '00:00';
                const splittedTime = selectedTime.split(':');
                const hours = parseInt(splittedTime[0], 10);
                const minutes = parseInt(splittedTime[1], 10);

                _currentlySelectedDate.setHours(hours, minutes, 0, 0);
            }
            (<Model.DateTimePicker.OnSelectDateFunc>_fnSuccess)(_currentlySelectedDate);
        } else {
            (<Model.DateTimePicker.OnSelectPeriodFunc>_fnSuccess)(_currentlySelectedPeriod);
        }

        destroy();
    }

    function parseDate(value): any {
        let match;

        switch (Session.User.Language) {
            case 'en-US':
                match = /^([1-9]|1[012]|0\d)[\\|/|.|,|-]([1-9]|1[0-9]|2[0-9]|3[01]|0\d)[\\|/|.|,|-](\d{4,4})$/.exec(value);

                if (match) {
                    return [
                        parseInt(match[3], 10),
                        parseInt(match[1], 10),
                        parseInt(match[2], 10)
                    ];
                }
                break;
            default:
                match = /^([1-9]|1[0-9]|2[0-9]|3[01]|0\d)[\\|/|.|,|-]([1-9]|1[012]|0\d)[\\|/|.|,|-](\d{4,4})$/.exec(value);

                if (match) {
                    return [
                        parseInt(match[3], 10),
                        parseInt(match[2], 10),
                        parseInt(match[1], 10)
                    ];
                }
                break;
        }
    }

    function onCustomDateChanged(): void {
        const $dateInput = $(this);
        const dateString = $dateInput.val();
        const dateArray = parseDate(dateString);
        let date;

        if (dateArray) {
            date = _currentlySelectedDate;

            date.setFullYear(dateArray[0], dateArray[1] - 1, dateArray[2]);

            _$customDateInput.removeClass('wrong-date');

            _currentDisplayDate = date;
            _currentlySelectedDate = date;

            reRenderBody();
        } else {
            _$customDateInput.addClass('wrong-date');
        }
    }

    function changeLowerBoundary(): void {
        const $this = $(this);
        const startDateArray = parseDate(_$inputLowerBoundary.val());

        if (startDateArray) {
            const startDate = _currentlySelectedPeriod.PeriodStart;

            startDate.setFullYear(startDateArray[0], startDateArray[1] - 1, startDateArray[2]);

            _$inputLowerBoundary.removeClass('wrong-date');

            if (startDate.getTime() < (_currentlySelectedPeriod.PeriodEnd as Date).getTime()) {
                _currentlySelectedPeriod.PeriodStart = startDate;
                _currentDisplayDate = new Date(startDate.getTime());
                reRenderBody();
            } else if ($this.hasClass('input-lower-boundary')) {
                let newEndDate = new Date(startDate.getTime() + 2 * (24 * 60 * 60 * 1000) - 1);
                _currentlySelectedPeriod.PeriodStart = startDate;
                _currentlySelectedPeriod.PeriodEnd = newEndDate;
                _currentDisplayDate = new Date(startDate.getTime());
                setDateInputValues();
                reRenderBody();
                return;
            }
        } else {
            _$inputLowerBoundary.addClass('wrong-date');
        }
    }

    function changeUpperBoundary(): void {
        const $this = $(this);
        const endDateArray = parseDate(_$inputUpperBoundary.val());

        if (endDateArray) {
            const endDate = _currentlySelectedPeriod.PeriodEnd;

            endDate.setFullYear(endDateArray[0], endDateArray[1] - 1, endDateArray[2]);

            _$inputUpperBoundary.removeClass('wrong-date');

            if (endDate.getTime() > (_currentlySelectedPeriod.PeriodStart as Date).getTime()) {
                _currentlySelectedPeriod.PeriodEnd = endDate;
                _currentDisplayDate = new Date(endDate.getTime());
                reRenderBody();
            } else if ($this.hasClass('input-upper-boundary')) {
                let newStartDate = new Date(endDate.getTime() - (24 * 60 * 60 * 1000) + 1);
                _currentlySelectedPeriod.PeriodEnd = endDate;
                _currentlySelectedPeriod.PeriodStart = newStartDate;
                _currentDisplayDate = new Date(newStartDate.setDate(endDate.getDate() - 1));
                setDateInputValues();
                reRenderBody();
                return;
            }
        } else {
            _$inputUpperBoundary.addClass('wrong-date');
        }
    }

    function isSelected(date: Date): boolean {
        if (!_periodSelectionEnabled) {
            return datesAreEqual(date, _currentlySelectedDate);
        }

        return datesAreEqual(date, _currentlySelectedPeriod.PeriodStart as Date) ||
            datesAreEqual(date, _currentlySelectedPeriod.PeriodEnd as Date);
    }

    function datesAreEqual(date1: Date, date2: Date): boolean {
        return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear();
    }

    function calculateHeight(): void {
        let height = _$win.find('.body').outerHeight() + _$win.find('.footer').outerHeight();

        if (_$win.find('.header').length) {
            height += _$win.find('.header').outerHeight();
        }

        _$win.css('height', height);
    }

    function calculateWidth(): void {
        let width = $(window).width() - 60;

        if (width > parseInt(_$win.css('max-width'), 10)) {
            width = parseInt(_$win.css('max-width'), 10);
        }

        _$win.css('width', width);
    }

    function $getCalendarBody(): any {
        const $tbody = $('<tbody></tbody>');
        const firstDayOfWeek = parseInt(i18next.t('DateTime.FirstDayOfWeek'), 10);

        let $row = $('<tr></tr>');
        const currentDate = new Date(_currentDisplayDate.getFullYear(), _currentDisplayDate.getMonth(), 1, 0, 0, 0);
        const lastDayOfMonth = new Date(_currentDisplayDate.getFullYear(), _currentDisplayDate.getMonth() + 1, 0, 0, 0, 0).getDate();

        const now = new Date();

        if (currentDate.getDay() !== firstDayOfWeek) {
            const tmpDate = new Date(currentDate.getTime());
            tmpDate.setDate(tmpDate.getDate() - ((!currentDate.getDay() ? 7 : currentDate.getDay()) - 1));

            if (!firstDayOfWeek) {
                tmpDate.setDate(tmpDate.getDate() - 1);
            }

            let end = !currentDate.getDay() ? 7 : currentDate.getDay();
            end -= firstDayOfWeek ? 1 : 0;

            for (let day = 0; day < end; day++) {
                const cellClasses = getDateCellClasses(now, tmpDate);
                const strCssClasses = cellClasses.length ? ` class="${cellClasses.join(' ')}"` : '';

                $row.append(`<td ${strCssClasses} data-timestamp="${tmpDate.getTime()}"><span>${tmpDate.getDate()}</span></td>`);

                tmpDate.setDate(tmpDate.getDate() + 1);
            }
        }

        while (currentDate.getDate() <= lastDayOfMonth &&
            currentDate.getMonth() === _currentDisplayDate.getMonth()) {
            let day = 0;

            while ((day = currentDate.getDay()) <= 6) {
                const cellClasses = getDateCellClasses(now, currentDate);
                const strCssClasses = cellClasses.length ? ` class="${cellClasses.join(' ')}"` : '';

                $row.append(`<td${strCssClasses} data-timestamp="${currentDate.getTime()}"><span>${currentDate.getDate()}</span></td>`);

                currentDate.setDate(currentDate.getDate() + 1);

                if (!firstDayOfWeek && day === 6 ||
                    firstDayOfWeek && !day) {
                    break;
                }
            }

            $tbody.append($row);
            $row = $('<tr></tr>');
        }

        return $tbody;
    }

    function getDateCellClasses(now: Date, currentDate: Date): string[] {
        const classes = [];

        if (currentDate.getMonth() !== _currentDisplayDate.getMonth()) {
            classes.push('faded');
        }

        if (currentDate.getDate() === now.getDate() &&
            currentDate.getMonth() === now.getMonth() &&
            currentDate.getFullYear() === now.getFullYear()) {
            classes.push('today');
        }

        if (isSelected(currentDate)) {
            classes.push('selected');
        } else if (_periodSelectionEnabled) {
            if (currentDate.getTime() >= _currentlySelectedPeriod.PeriodStart.getTime() &&
                currentDate.getTime() <= _currentlySelectedPeriod.PeriodEnd.getTime()) {
                classes.push('selected');
            }
        }

        if (!currentDate.getDay()) {
            classes.push('sunday');
        }

        return classes;
    }

    function getCalendarTitle(): string {
        return `<strong>${Utils.DateTime.GetMonthTitle(_currentDisplayDate)}</strong>  ${_currentDisplayDate.getFullYear()}`;
    }

    function setDateInputValues(): void {
        if (_periodSelectionEnabled) {
            const startDate = Utils.DateTime.DateToString(_currentlySelectedPeriod.PeriodStart, false, true);
            const endDate = Utils.DateTime.DateToString(_currentlySelectedPeriod.PeriodEnd, false, true);

            _$inputLowerBoundary.val(startDate);
            _$inputUpperBoundary.val(endDate);
            _$inputLowerBoundary.removeClass('wrong-date');
            _$inputUpperBoundary.removeClass('wrong-date');
        } else {
            const date = Utils.DateTime.DateToString(_currentlySelectedDate, false, true);
            _$customDateInput.val(date);
        }
    }

    function onDateClickEmptyField() {
        $(this).val('');
    }

    function onDateInputBlur(): void {
        setDateInputValues();
    }

    function onTimeInput() {
        const $this = $(this);
        const value = $this.val();

        if (!!value) {
            return;
        }

        $this.val('00:00');
    }

    function onTimeKeydown(evt) {
        if (evt.keyCode === Enums.KeyCode.RETURN) {
            onBtnOKClick();
        }
    }

    function reRenderBody(): void {
        _$win.find('.current-month').html(getCalendarTitle());
        _$win.find('tbody').replaceWith($getCalendarBody());

        calculateHeight();
    }

    function bindEvents(): void {
        _$calendar.on('click', 'td', onDateClick);
        _$calendar.find('.previous-month').on('click', selectPreviousMonth);
        _$calendar.find('.goto-current').on('click', onGotoCurrentDateClick);
        _$calendar.find('.next-month').on('click', selectFollowingMonth);
        _$win.find('.btn-clear').on('click', onBtnClearClick);
        _$win.find('.btn-abort').on('click', destroy);
        _$win.find('.btn-apply').on('click', onBtnOKClick);
        _$customDateInput.on('keyup', onCustomDateChanged);
        _$inputLowerBoundary.on('keyup', changeLowerBoundary);
        _$inputUpperBoundary.on('keyup', changeUpperBoundary);

        _$customDateInput.on('blur', onDateInputBlur);
        _$customDateInput.on('click', onDateClickEmptyField);
        _$inputLowerBoundary.on('click', onDateClickEmptyField);
        _$inputLowerBoundary.on('blur', onDateInputBlur);
        _$inputUpperBoundary.on('click', onDateClickEmptyField);
        _$inputUpperBoundary.on('blur', onDateInputBlur);
        _$timePicker.find('input')
            .on('input', onTimeInput)
            .on('keydown', onTimeKeydown);

        _$win.on('click', function(evt) {
            evt.stopPropagation();
        });

        if (Session.IsSmartDeviceApplication) {
            initSwipeHandler();
        }
    }

    function initSwipeHandler(): void {
        if (_swipeHandlerAttached) {
            return;
        }

        if (!createHammerManager()) {
            return;
        }

        const swipe = new Hammer.Swipe({
            direction: Hammer.DIRECTION_HORIZONTAL,
            threshold: 1,
            velocity: 0.4
        });

        _hammerManager.off('swipe');
        _hammerManager.remove('swipe');

        _hammerManager.add(swipe);
        _hammerManager.on('swipe', onHandleSwipeEvent);

        _swipeHandlerAttached = true;
    }

    function createHammerManager(): boolean {
        if (_hammerManager) {
            return false;
        }

        const contentElement = _$calendar.get(0);

        if (!contentElement) {
            return false;
        }

        _hammerManager = new Hammer.Manager(contentElement, {
            touchAction: 'auto',
            recognizers: [
                [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
            ]
        });

        return true;
    }

    function onHandleSwipeEvent(event): void {
        if (event.direction === Hammer.DIRECTION_RIGHT) {
            selectPreviousMonth();
        } else if (event.direction === Hammer.DIRECTION_LEFT) {
            selectFollowingMonth();
        }
    }

    export function Show(options: Model.DateTimePicker.IOptions): void {
        if (_$win) {
            destroy();
        }

        if (!(options.ShowCalendar || options.ShowTime)) {
            return;
        }

        if (!(options.FnSuccess instanceof Function)) {
            throw new Model.Errors.ArgumentError('success callback needed');
        }

        initVariables(options);
        bindEvents();
        renderWindow(options);
    }

    function initVariables(options: Model.DateTimePicker.IOptions): void {
        _swipeHandlerAttached = false;

        if (!options.EnablePeriodSelection) {
            if (options.SelectedDateTime instanceof Date &&
                !isNaN(options.SelectedDateTime.getTime())) {
                _currentlySelectedDate = new Date(options.SelectedDateTime.getTime());
                _currentDisplayDate = new Date(options.SelectedDateTime.getTime());
            } else {
                _currentlySelectedDate = new Date();
                _currentDisplayDate = new Date();
            }

            _currentlySelectedDate = new Date(_currentlySelectedDate.getTime());
        } else {
            if (options.SelectedPeriod instanceof Model.DateTime.Period) {
                _currentlySelectedPeriod = options.SelectedPeriod;
            } else {
                const today = new Date();
                const tomorrow = new Date();

                today.setHours(0, 0, 0, 0);
                tomorrow.setHours(23, 59, 59, 999);
                tomorrow.setDate(tomorrow.getDate() + 1);

                _currentlySelectedPeriod = new Model.DateTime.Period(today, tomorrow);
            }

            _currentDisplayDate = new Date(_currentlySelectedPeriod.PeriodStart.getTime());
        }

        _periodSelectionEnabled = options.EnablePeriodSelection;
        _fnSuccess = options.FnSuccess;

        _$win = $(Templates.Selections.DateTime(getWindowTemplateContext(options)));

        $('body')
            .addClass('modal-open')
            .append(_$win);

        _$calendar = _$win.find('.date-picker');
        _$timePicker = _$win.find('.time-picker');
        _$customDateInput = _$win.find('.custom-date-input');
        _$inputLowerBoundary = _$win.find('.input-lower-boundary');
        _$inputUpperBoundary = _$win.find('.input-upper-boundary');
    }

    function getWindowTemplateContext(options: Model.DateTimePicker.IOptions): any {
        if (_periodSelectionEnabled) {
            return new Model.DateTimePicker.PeriodTemplateContext(
                i18next.t('DateTime.DaysShort'),
                options.HeaderText
            );
        }

        return new Model.DateTimePicker.DateTimeTemplateContext(
            _currentlySelectedDate.getHours(),
            _currentlySelectedDate.getMinutes(),
            i18next.t('DateTime.DaysShort'),
            options.HeaderText,
            options.ShowCalendar,
            options.ShowTime
        );
    }

    function renderWindow(options: Model.DateTimePicker.IOptions): void {
        _$overlay = Utils.Overlay.Generate('olDateTimePicker', 9000, destroy);

        if (options.Clearable) {
            _$win.find('.btn-clear').removeClass('hidden');
        }

        if (options.ShowCalendar) {
            _$calendar.removeClass('hidden');
        }

        if (options.ShowTime) {
            const hours = Utils.PadLeft(_currentlySelectedDate.getHours(), 2, '0');
            const minutes = Utils.PadLeft(_currentlySelectedDate.getMinutes(), 2, '0');

            _$timePicker.find('label').text(i18next.t('DateTimePicker.Time'));
            _$timePicker.find('input').val(`${hours}:${minutes}`);
            _$timePicker.removeClass('hidden');
        }

        _$win.find('.current-month').html(getCalendarTitle());
        _$win.find('tbody').replaceWith($getCalendarBody());

        setDateInputValues();

        calculateWidth();
        calculateHeight();

        _$win.css('margin-left', -(_$win.outerWidth() / 2));
        _$win.css('margin-top', -(_$win.outerHeight() / 2));

        if (_periodSelectionEnabled) {
            _changeLowerBoundary = true;
            _$customDateInput.addClass('hidden');

            setDateInputValues();

            _$win.find('.period-input').removeClass('hidden');
            _$win.find('.change-lower-boundary').bootstrapSwitch({
                onText: i18next.t('DateTimePicker.PeriodSwitch.LowerBoundary'),
                offText: i18next.t('DateTimePicker.PeriodSwitch.UpperBoundary'),
                state: true,
                onSwitchChange: onSetChangeLowerBoundary
            });
            Resize();
        }

        if (!Session.IsSmartDeviceApplication &&
            !options.ShowCalendar &&
            options.ShowTime) {
            _$win.find('input[type="time"]').focus();
        }
    }

    function onSetChangeLowerBoundary(evt: Event, changeLowerBoundary: boolean) {
        _changeLowerBoundary = changeLowerBoundary;
    }

    export function Resize(): void {
        calculateWidth();
        calculateHeight();

        _$win.css('margin-left', -(_$win.outerWidth() / 2));
        _$win.css('margin-top', -(_$win.outerHeight() / 2));
    }

    export function IsVisible(): boolean {
        return _$win && _$win.is(':visible');
    }
}
