//imports-start
/// <reference path="../../definitions.d.ts" />
//imports-end

module Utils.SVGEditor {

    export class SVGEditor {

        private svg: SVGSVGElement;

        private $viewerWindow: any;
        private $svgContainer: any;

        private $settings: any;
        private $colorPicker: any;
        private $pixelSlider: any;
        private $opacitySlider: any;

        private $svgBar: any;
        private $selectedColor: any;
        private $btnOpenColorPicker: any;
        private $btnBrush: any;
        private $btnLine: any;
        private $btnText: any;

        private $btnUndo: any;
        private $btnRedo: any;
        private $btnClear: any;
        private $btnSave: any;
        private $btnDropdown: any;
        private $actionButtons: any;

        private history: any[] = [];
        private markerCache: any;
        private imgHeight: number;
        private imgWidth: number;
        private callFnSaveMarks: (svg: string | null) => void
        private drawStyle: { color: string, strokeWidth: number, strokeOpacity: number };
        private isModified: boolean;
        private IsDropdownOpen: boolean = false;

        private lineDrawHandler: DrawHandler;
        private pathDrawHandler: DrawHandler;
        private textDrawHandler: DrawHandler;
        private currentDrawHandler: DrawHandler;

        public get $SvgContainer() {
            return this.$svgContainer;
        }

        public get $PixelSlider() {
            return this.$pixelSlider;
        }

        public get $SelectedColor() {
            return this.$selectedColor;
        }

        public get DrawStyle() {
            return this.drawStyle;
        }

        public get IsModified() {
            return this.$svgBar && this.$svgBar.is(':visible') && this.isModified;
        }

        public set IsModified(value: boolean) {
            this.isModified = value;
        }

        constructor(
            $viewerWindow,
            $svgWrapper: any,
            metrics: any,
            canWriteComments: boolean,
            fnSaveMarks: (svg: string | null) => void
        ) {
            this.$viewerWindow = $viewerWindow;
            this.$svgContainer = $svgWrapper;
            this.$svgContainer.addClass('svg-editor-open');
            this.isModified = false;
            this.callFnSaveMarks = fnSaveMarks;
            this.imgHeight = metrics.SizeO.Height;
            this.imgWidth = metrics.SizeO.Width;
            this.svg = this.$svgContainer.find('svg')[0]!;

            this.$svgBar = $(Templates.ImageViewer.MarkSettings({
                CanWriteComments: canWriteComments
            }));

            $viewerWindow.find('.inner').after(this.$svgBar);
            $viewerWindow.addClass('svg-editor-visible');

            this.initSvg(metrics);
            this.initVariables();
            this.initSliders();

            this.drawStyle = {
                color: this.$selectedColor.data('color'),
                strokeWidth: this.$pixelSlider.val(),
                strokeOpacity: parseInt(this.$opacitySlider.val().replace('%', ''), 10) / 100
            };

            this.unbindEvents();
            this.bindEvents();

            this.lineDrawHandler = new StraightLineDrawHandler(this, this.svg, () => this.toggleSaveButton());
            this.pathDrawHandler = new PathDrawHandler(this, this.svg, () => this.toggleSaveButton());
            this.textDrawHandler = new TextDrawHandler(this, this.svg, () => this.toggleSaveButton());
            this.tryChangeDrawHandler(this.pathDrawHandler);

            this.markerCache = $('<div>').append($(this.$svgContainer[0].getElementsByTagName('g')).clone()).html();
            this.$settings.addClass('hidden');
        }

        private initSvg(metrics: any) {
            const hasViewbox = this.svg.hasAttribute('viewbox')
            const hasSvgGroup = this.svg.querySelector(':scope > g');

            this.svg.style.cursor = 'crosshair';

            if (!hasViewbox) {
                this.svg.setAttribute('viewBox', `0 0 ${metrics.SizeO.Width} ${metrics.SizeO.Height}`);
            }

            if (!hasSvgGroup) {
                let svgGroup = document.createElementNS("http://www.w3.org/2000/svg", 'g')
                this.svg.appendChild(svgGroup);
            }
        }

        private initVariables(): void {
            this.$settings = this.$svgBar.find('.settings');
            this.$colorPicker = this.$settings.find('.color-picker');
            this.$pixelSlider = this.$settings.find('.pixel-slider');
            this.$opacitySlider = this.$settings.find('.opacity-slider');

            this.$selectedColor = this.$svgBar.find('.selected-color');
            this.$btnOpenColorPicker = this.$svgBar.find('.toggle-settings');
            this.$btnBrush = this.$svgBar.find('.action.brush');
            this.$btnLine = this.$svgBar.find('.action.line');
            this.$btnText = this.$svgBar.find('.action.text');

            this.$btnUndo = this.$svgBar.find('.icon-undo2');
            this.$btnRedo = this.$svgBar.find('.icon-redo2');
            this.$btnClear = this.$svgBar.find('.icon-bin2');
            this.$btnSave = this.$svgBar.find('.save');
            this.$btnDropdown = this.$svgBar.find('.dropdown');
            this.$actionButtons = this.$svgBar.find('.actions-dropdown-menu');

            this.$selectedColor.data('color', new Model.Color(this.$selectedColor.css('background-color')).getRGBA());
        }

        public Destroy() {
            this.unbindEvents();

            this.lineDrawHandler.UnbindEvents();
            this.pathDrawHandler.UnbindEvents();
            this.textDrawHandler.UnbindEvents();
            this.currentDrawHandler.UnbindEvents();

            this.$viewerWindow.removeClass('svg-editor-visible')
            this.$svgContainer.removeClass('svg-editor-open');

            this.$svgBar.remove();

            this.svg = null

            this.$viewerWindow = null;
            this.$svgContainer = null;

            this.$settings = null;
            this.$colorPicker = null;
            this.$pixelSlider = null;
            this.$opacitySlider = null;

            this.$svgBar = null;
            this.$selectedColor = null;
            this.$btnOpenColorPicker = null;
            this.$btnBrush = null;
            this.$btnLine = null;
            this.$btnText = null;

            this.$btnUndo = null;
            this.$btnRedo = null;
            this.$btnClear = null;
            this.$btnSave = null;
            this.$btnDropdown = null;
            this.$actionButtons = null;

            this.history = null;
            this.markerCache = null;
            this.imgHeight = null;
            this.imgWidth = null;
            this.callFnSaveMarks = null;
            this.drawStyle = null;
            this.isModified = null;
            this.IsDropdownOpen = null;

            this.lineDrawHandler = null;
            this.pathDrawHandler = null;
            this.textDrawHandler = null;
            this.currentDrawHandler = null;
        }

        public IsVisible(): boolean {
            return this.$svgBar && this.$svgBar.css('display') !== 'none';
        }

        public SaveMarks(event: Event | undefined = undefined): void {
            const newMarksString = this.getSaveableMarksString();

            if (newMarksString) {
                const $newMarks = $(newMarksString);
                const xmlSchema = '<?xml version="1.0" standalone="no"?>';
                const hiddenElems = $($newMarks[0].getElementsByTagName('g')[0]).find('.hidden');

                if (hiddenElems.length) {
                    for (let eCnt = hiddenElems.length - 1; eCnt >= 0; eCnt--) {
                        const hiddenElem = hiddenElems[eCnt];
                        hiddenElem.parentNode.removeChild(hiddenElem);
                    }
                }

                $newMarks.attr({
                    style: '',
                    width: this.imgWidth,
                    height: this.imgHeight,
                });
                this.callFnSaveMarks(xmlSchema + $($newMarks).clone().wrap('<p>').parent().html());
            } else {
                this.callFnSaveMarks(null);
            }

            this.markerCache = newMarksString;
            this.isModified = false;

            if (!event) {
                return;
            }

            $(event.currentTarget)
                .attr('class', 'tile icon-checkmark save')
                .off('click');
        }

        private updateOpacityValues(opacity: string | number): void {
            const alpha = parseInt(<string>opacity, 10) / 100;
            const color = new Model.Color(this.$selectedColor.css('background-color')).setAlpha(alpha);

            this.$colorPicker.find('.color').css('opacity', alpha);
            this.$selectedColor.css('background-color', color.getRGBA());
            this.setLineProperties(null, alpha);
        }

        public GetOffset($event): IPoint {
            const touch: Touch = ($event.originalEvent.touches || [])[0];
            return touch
                ? { x: touch.pageX, y: touch.pageY }
                : { x: $event.pageX, y: $event.pageY };
        }

        private toggleSaveButton(): void {
            const newMarksString = $('<div>').append(this.$svgContainer.find('svg').clone()).html();
            const $newMarks = $(newMarksString);
            const $saveButton = this.$svgBar.find('.save');
            const hiddenElements = $newMarks[0].getElementsByTagName('g')[0].querySelectorAll('.hidden');

            if (hiddenElements.length) {
                for (let i = hiddenElements.length - 1; i >= 0; i--) {
                    const domElement = hiddenElements[i];
                    domElement.parentNode.removeChild(domElement);
                }
            }

            const newMarkerString = $('<div>').append($($newMarks[0].getElementsByTagName('g')).clone()).html();

            if (newMarkerString === this.markerCache) {
                $saveButton.off('click');
                $saveButton.attr('class', 'tile icon-checkmark save');
                this.isModified = false;
            } else {
                $saveButton.off('click').on('click', (event: Event) => this.SaveMarks(event));
                $saveButton.attr('class', 'tile icon-checkmark2 save');
                this.isModified = true;
            }
        }

        private setLineProperties(rgba: string, alpha?: number): void {
            if (!rgba) {
                rgba = this.$selectedColor.data('color');
            }

            if (!alpha) {
                alpha = parseInt(this.$opacitySlider.val().replace('%', ''), 10) / 100;
            }

            rgba = rgba.replace(/[\d\.]+\)$/g, '{0})'.format(alpha));

            const color = new Model.Color(rgba);

            this.drawStyle.color = color.getHex();
            this.drawStyle.strokeOpacity = alpha;
        }

        private getSaveableMarksString(): string {
            const $newSvg = this.$svgContainer.find('svg').clone();
            if (!$newSvg.find('g').children().length) {
                return null;
            }

            const $textNodes = $newSvg.find('.text');
            if ($textNodes.length) {
                for (let tnCnt = 0, tnLen = $textNodes.length; tnCnt < tnLen; tnCnt++) {
                    const $textNode = $textNodes.eq(tnCnt);

                    $textNode.removeAttr('class');
                    $textNode.removeAttr('id');
                }
            }

            return $('<div>').append($newSvg).html();
        }

        private showSettings(): void {
            this.$settings.toggleClass('hidden', !this.$settings.hasClass('hidden'));
        }

        public initSliders(): void {
            const $sliderWrapper = this.$settings.find('.slider-wrapper');
            const $sliderWrapper1 = $sliderWrapper.eq(0);
            const $sliderWrapper2 = $sliderWrapper.eq(1);
            const $val1 = this.$settings.find('.val').eq(0);
            const $val2 = this.$settings.find('.val').eq(1);
            const $colorValue = this.$svgBar.find('.actions > .color');

            this.$svgBar.find('.mark-settings').removeClass('hidden');

            this.$pixelSlider.noUiSlider({
                range: {
                    'min': 1,
                    'max': 30
                },
                step: 1,
                behaviour: 'snap',
                direction: 'rtl',
                start: 5,
                connect: 'lower',
                format: {
                    to: function(value: number): number {
                        return value;
                    },
                    from: function(value: number): number {
                        return value;
                    }
                },
                orientation: 'vertical'
            }, true);

            this.$opacitySlider.noUiSlider({
                range: {
                    'min': 0.1,
                    'max': 1
                },
                step: 0.1,
                behaviour: 'snap',
                direction: 'rtl',
                start: 1.0,
                connect: 'lower',
                format: {
                    to: function(value: number): string {
                        value = value * 100;
                        return value + '%';
                    },
                    from: function(value: number): number {
                        value = value * 100;
                        return value;
                    }
                },
                orientation: 'vertical'
            }, true);

            this.$pixelSlider.attr('style', '');
            this.$opacitySlider.attr('style', '');

            $colorValue.offset({ left: this.$colorPicker.offset().left + this.$colorPicker.outerWidth() / 2 - $colorValue.outerWidth() / 2 });
            this.$pixelSlider.offset({ left: (($sliderWrapper1.outerWidth() / 2) - this.$pixelSlider.outerWidth() / 2 + $sliderWrapper1.offset().left) + 15 });
            this.$opacitySlider.offset({ left: (($sliderWrapper2.outerWidth() / 2) - this.$opacitySlider.outerWidth() / 2 + $sliderWrapper2.offset().left) + 80 });
            $val1.offset({ left: ($sliderWrapper1.outerWidth() / 2) + $sliderWrapper1.offset().left - $val1.outerWidth() / 2 });
            $val2.offset({ left: ($sliderWrapper2.outerWidth() / 2) + $sliderWrapper2.offset().left - $val2.outerWidth() / 2 });

            $('.sliderTooltip').remove();

            this.$pixelSlider.Link('lower').to('-inline-<div class="sliderTooltip"></div>', function(value: number) {
                const $this = $(this);

                $this.html(
                    '<strong>' + i18next.t('Draw.Width') + ':</strong><br />' +
                    '<span>' + value + '</span>'
                );
            });

            this.$opacitySlider.Link('lower').to('-inline-<div class="sliderTooltip"></div>', function(value: string) {
                const $this = $(this);

                $this.html(
                    '<strong>' + i18next.t('Draw.Opacity') + ':</strong><br />' +
                    '<span>' + value + '</span>'
                );
            });

            const $sliderButton1 = this.$settings.find('.noUi-handle').eq(0);
            const $sliderButton2 = this.$settings.find('.noUi-handle').eq(1);

            const $tooltip1 = this.$settings.find('.sliderTooltip').eq(0);
            const $tooltip2 = this.$settings.find('.sliderTooltip').eq(1);

            $tooltip1.offset({ left: $sliderButton1.offset().left + $sliderButton1.outerWidth() / 2 - $tooltip1.outerWidth() / 2 });
            $tooltip2.offset({ left: $sliderButton2.offset().left + $sliderButton2.outerWidth() / 2 - $tooltip2.outerWidth() / 2 });
        }

        private tryChangeDrawHandler(handler: DrawHandler): boolean {
            if (this.currentDrawHandler === handler) {
                return false;
            }

            if (this.currentDrawHandler) {
                this.currentDrawHandler.UnbindEvents();
            }

            this.currentDrawHandler = handler;

            if (this.currentDrawHandler) {
                this.currentDrawHandler.bindEvents()
            }

            return true
        }

        public bindEvents(): void {
            this.$selectedColor.on('click', () => this.showSettings());
            this.$btnOpenColorPicker.on('click', () => this.showSettings());
            this.$btnBrush.on('click', (event: Event) => this.onBtnSelectBrushActionClick(event));
            this.$btnLine.on('click', (event: Event) => this.onBtnSelectLineActionClick(event));
            this.$btnText.on('click', (event: Event) => this.onBtnSelectTextActionClick(event));

            this.$btnUndo.on('click', () => this.onBtnUndoClick());
            this.$btnRedo.on('click', () => this.onBtnRedoClick());
            this.$btnClear.on('click', () => this.onBtnClearImageClick());
            this.$btnSave.on('click', (event: Event) => this.SaveMarks(event));

            this.$btnDropdown.on('click.openDropdown', () => this.onDropdownButtonClick());
            this.$colorPicker.find('.color').on('click', (event: Event) => this.onColorClick(event));
            this.$pixelSlider.on('change', (event: Event) => this.onStrokeWidthChange(event));
            this.$opacitySlider.on('change', (event: Event) => this.onStrokeOpacityChange(event));
        }

        public unbindEvents(): void {
            this.$selectedColor.off('click');
            this.$btnOpenColorPicker.off('click');
            this.$btnBrush.off('click');
            this.$btnLine.off('click');
            this.$btnText.off('click');

            this.$btnUndo.off('click');
            this.$btnRedo.off('click');
            this.$btnClear.off('click');
            this.$btnClear.off('click');
            this.$btnDropdown.off('click.openDropdown');

            this.$colorPicker.find('color').off('click');
            this.$pixelSlider.off('change');
            this.$opacitySlider.off('change');
        }

        private onDropdownButtonClick(): void {
            if (!this.$actionButtons) {
                return;
            }

            this.IsDropdownOpen = !this.IsDropdownOpen;

            if (!this.IsDropdownOpen) {
                this.$settings.addClass('hidden');
            }

            const shrinkBreakpoint = 450; //siehe svg-marks.less, @shrink-breakpoint
            const isActionsCollapsed = Utils.GetOrientation() == 'portrait'
                ? this.$svgContainer.width() <= shrinkBreakpoint
                : this.$svgContainer.height() <= shrinkBreakpoint;

            if (isActionsCollapsed && this.IsDropdownOpen) {
                this.$actionButtons.addClass('openDropdown');
            }
            else if (isActionsCollapsed && !this.IsDropdownOpen) {
                this.$actionButtons.removeClass('openDropdown');
            }
        }

        private onColorClick(event: Event): void {
            const $target = $(event.currentTarget);
            const alpha = parseInt(this.$opacitySlider.val().replace('%', ''), 10) / 100;
            const color = new Model.Color($target.data('color')).setAlpha(alpha);

            const rgba = color.getRGBA();

            this.$selectedColor.css('background-color', rgba).data('color', rgba);
            this.$colorPicker.find('.active').removeClass('active');

            $target.addClass('active');

            this.setLineProperties($target.data('color'));
        }

        private onBtnUndoClick(): void {
            const $childNodes = $(this.$svgContainer[0].getElementsByTagName('g')[0].childNodes);
            const $lastVisibleChild = $childNodes.not('.hidden').last();

            if (!$lastVisibleChild.length) {
                return;
            }

            const tagName = $lastVisibleChild.prop('tagName');

            if (tagName === 'path' || tagName === 'text' || tagName === 'line') {
                this.history.push($lastVisibleChild[0]);
                $lastVisibleChild.attr('class', 'hidden');

                this.toggleSaveButton();
            }
        }

        private onBtnRedoClick(): void {
            const i = this.history.length - 1;
            const line = this.history[i];

            if (!line) {
                return;
            }

            $(line).removeAttr('class');
            this.history.splice(i, 1);

            this.toggleSaveButton();
        }

        private onBtnClearImageClick(): void {
            if (!this.$svgContainer.find('svg > g').children().length) {
                return;
            }

            Utils.Message.Show(i18next.t('Draw.Clear.QuestionHeader'),
                i18next.t('Draw.Clear.QuestionBody'),
                {
                    Yes: () => {
                        const paths = this.$svgContainer[0].getElementsByTagName('g')[0].childNodes;

                        this.history = [];

                        if (paths.length) {
                            for (let i = paths.length; i >= 0; i--) {
                                const path = paths[i];

                                if (path) {
                                    path.parentNode.removeChild(path);
                                }
                            }

                            this.toggleSaveButton();
                        }
                    },
                    No: true
                }, null, 99999);
        }

        private onStrokeWidthChange(event: Event): void {
            this.drawStyle.strokeWidth = $(event.currentTarget).val();
        }

        private onStrokeOpacityChange(event: Event): void {
            const $target = $(event.currentTarget);

            if ($target.attr('disabled')) {
                return;
            }

            this.updateOpacityValues($target.val().replace('%', ''));
        }

        private onBtnSelectBrushActionClick(event: Event): void {
            if (!this.tryChangeDrawHandler(this.pathDrawHandler)) {
                return;
            }

            const $btn = $(event.currentTarget);

            this.$btnDropdown.find('.icon-brush').removeClass('hidden');
            this.$btnDropdown.find('.icon-bubble').addClass('hidden');
            this.$btnDropdown.find('.icon-arrow-down-right2').addClass('hidden');

            this.$pixelSlider.val(5);
            this.$opacitySlider.removeAttr('disabled');

            $btn.addClass('active')
                .siblings('.active')
                .removeClass('active');
        }

        private onBtnSelectLineActionClick(event: Event): void {
            if (!this.tryChangeDrawHandler(this.lineDrawHandler)) {
                return;
            }

            const $btn = $(event.currentTarget);

            this.$btnDropdown.find('.icon-arrow-down-right2').removeClass('hidden');
            this.$btnDropdown.find('.icon-brush').addClass('hidden');
            this.$btnDropdown.find('.icon-bubble').addClass('hidden');

            this.$pixelSlider.val(5);
            this.$opacitySlider.removeAttr('disabled');

            $btn
                .addClass('active')
                .siblings('.active')
                .removeClass('active');
        }

        private onBtnSelectTextActionClick(event: Event): void {
            if (!this.tryChangeDrawHandler(this.textDrawHandler)) {
                return;
            }

            const $btn = $(event.currentTarget);

            this.$btnDropdown.find('.icon-arrow-down-right2').addClass('hidden');
            this.$btnDropdown.find('.icon-brush').addClass('hidden');
            this.$btnDropdown.find('.icon-bubble').removeClass('hidden');

            this.$pixelSlider.val(20);
            this.$opacitySlider.val(100);
            this.$opacitySlider.attr('disabled', 'disabled');

            this.updateOpacityValues(100);

            $btn
                .addClass('active')
                .siblings('.active')
                .removeClass('active');
        }
    }
}
