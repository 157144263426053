//imports-start
/// <reference path="./file-dimensions.ts"  />
//imports-end

module Model.Files {
    export class FileMetrics {
        SizeS: FileDimensions;
        SizeM: FileDimensions;
        SizeL: FileDimensions;
        SizeO: FileDimensions;

        SetSizeS(dimensions: FileDimensions): FileMetrics {
            this.SizeS = dimensions;

            return this;
        }

        SetSizeM(dimensions: FileDimensions): FileMetrics {
            this.SizeM = dimensions;

            return this;
        }

        SetSizeL(dimensions: FileDimensions): FileMetrics {
            this.SizeL = dimensions;

            return this;
        }

        SetSizeO(dimensions: FileDimensions): FileMetrics {
            this.SizeO = dimensions;

            return this;
        }
    }
}