//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Model {
    export class SettingsMetadata {
        public IsLocked: boolean = false;
        public LastModificationTimestamp: string;
        public HasUnsyncedChanges: boolean = false;
    }
}
