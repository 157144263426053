//imports-start
///<reference path="../model/elements/element.ts"/>
///<reference path="../model/properties/property.ts"/>
///<reference path="../model/issues/issue.ts"/>
//imports-end

module App.FormIssuesPool {
    export type PoolCache = {
        [FormOID: string]: {
            [StateOID: string]: Array<Model.Issues.Issue>
        }
    }

    export class IssuePoolContext {
        public Form: Model.Elements.Element;
        public State: Model.Properties.Property;
        public IssueType: string;
        public IssueCount: number;
        public IssuesTitle: string;

        constructor(form: Model.Elements.Element, state: Model.Properties.Property, issueCount: number = 0) {
            if (!form || !state) {
                throw new Model.Errors.ArgumentError('form and state expected');
            }

            this.Form = form;
            this.State = state;
            this.IssueCount = issueCount;
            this.IssueType = i18next.t('Misc.IssueType.Form', { count: issueCount });
            this.IssuesTitle = i18next.t('Misc.Issue', { count: issueCount });
        }
    }

    export let MenuItemFilterID: string;
    export let FormIssuesCache: PoolCache;

    let currentLocationOID: string;
    let sortedIssuesList: Array<Model.Issues.Issue>;
    let issuesCache: { [IssueOID: string]: Model.Issues.Issue };
    let treeItemsList: { [id: string]: Model.Elements.Element };

    let counterByLocationAndIssue: {
        [LocationOID: string]: {
            [IssueOID: string]: number
        }
    };

    let formIssuesCacheByLocation: {
        [LocationOID: string]: Array<Model.Issues.Issue>
    };

    let workingLocationOID: string;
    let workingIssue: Model.Issues.Issue;
    let workingFormOID: string;
    let workingStateOID: string;

    export function Show(menuItemFilterId: string): void {
        if (!menuItemFilterId) {
            return;
        }

        MenuItemFilterID = menuItemFilterId;
        FormIssuesCache = null;
        currentLocationOID = null;

        Utils.Spinner.Show();

        setTimeout(() => {
            Utils.SetMode(Enums.Mode.IssuePools);
            buildFormIssuesPool();

            const pools = createPoolContexts();

            Utils.GetContentContainer().html(Templates.FormIssuePool.Content({
                Pools: pools,
                MenuID: menuItemFilterId,
                LocationOID: Session.CurrentLocation.OID,
                IssueType: i18next.t('Misc.IssueType.Form_plural')
            }));

            Utils.Spinner.Hide();
        }, 10);
    }

    function buildFormIssuesPool(): void {
        const issues = getAvailableIssues();

        FormIssuesCache = {};

        if (issues.length === 0) {
            return;
        }

        FormIssuesCache = createPoolCache(issues);
    }

    function getAvailableIssues(): Array<Model.Issues.Issue> {
        if (!Utils.IsSet(DAL.TreeCache.Custom) ||
            !DAL.TreeCache.Custom.hasOwnProperty(MenuItemFilterID)) {
            return [];
        }

        const cacheNode = DAL.TreeCache.Custom[MenuItemFilterID].getNode(Session.CurrentLocation.OID);

        if (!cacheNode) {
            return [];
        }

        return cacheNode.getTotalIssues();
    }

    function createPoolCache(issues: Array<Model.Issues.Issue>): PoolCache {
        const pool: PoolCache = {};

        for (let cnt = 0, len = issues.length; cnt < len; cnt++) {
            const issue = issues[cnt];

            if (issue.Type !== Enums.IssueType.Form || !issue.AssignedFormOID || !issue.StateOID) {
                continue;
            }

            if (!pool.hasOwnProperty(issue.AssignedFormOID)) {
                pool[issue.AssignedFormOID] = {};
            }

            if (!pool[issue.AssignedFormOID].hasOwnProperty(issue.StateOID)) {
                pool[issue.AssignedFormOID][issue.StateOID] = [];
            }

            pool[issue.AssignedFormOID][issue.StateOID].push(issue);
        }

        return pool;
    }

    function createPoolContexts(): Array<IssuePoolContext> {
        const contexts: Array<IssuePoolContext> = [];

        if (!FormIssuesCache) {
            return [];
        }

        for (let formOid in FormIssuesCache) {
            const form = DAL.Elements.GetByOID(formOid);

            if (!form) {
                continue;
            }

            const states = FormIssuesCache[formOid];

            for (let stateOid in states) {
                const state = DAL.Properties.GetByOID(stateOid);

                if (!state) {
                    continue;
                }

                const issueCount = states[stateOid].length;

                contexts.push(new IssuePoolContext(form, state, issueCount));
            }
        }

        contexts.sort(sortPoolContexts);

        return contexts;
    }

    function sortPoolContexts(a: IssuePoolContext, b: IssuePoolContext): number {
        if (!a.Form || !b.Form) {
            return 0;
        }

        const formTitleA = a.Form.Title.toLowerCase();
        const formTitleB = b.Form.Title.toLowerCase();

        if (formTitleA === formTitleB) {
            return Utils.SortByPropertyHierarchy(a.State, b.State);
        }

        return formTitleA < formTitleB ? -1 : 1;
    }

    export function GetFirstIssue(formOID: string, stateOID: string): Model.Issues.Issue | null {
        if (!FormIssuesCache ||
            !FormIssuesCache[formOID] ||
            !FormIssuesCache[formOID][stateOID]) {
            return null;
        }

        if (sortedIssuesList) {
            return sortedIssuesList[0];
        }
        // get first issue within working location
        return FormIssuesCache[formOID][stateOID][0];
    }

    export function GetIssue(issueOID: string | number | null): Model.Issues.Issue {
        if (typeof issueOID === 'string') {
            return App.FormIssuesPool.GetByOID(<string>issueOID);
        } else if (!isNaN(<number>issueOID)) {
            return App.FormIssuesPool.GetByID(<number>issueOID);
        }
        return null;
    }

    export function GetByID(issueID: number): Model.Issues.Issue {
        if (!FormIssuesCache) {
            return null;
        }

        for (let key in FormIssuesCache) {
            for (let key2 in FormIssuesCache[key]) {
                for (let i = 0; i < FormIssuesCache[key][key2].length; i++) {
                    const tmp = FormIssuesCache[key][key2][i];
                    if (tmp.ID == issueID) {
                        return tmp;
                    }
                }
            }
        }
        return null;
    }

    export function GetByOID(issueOID: string): Model.Issues.Issue {
        if (!FormIssuesCache) {
            return null;
        }

        for (let key in FormIssuesCache) {
            for (let key2 in FormIssuesCache[key]) {
                for (let i = 0; i < FormIssuesCache[key][key2].length; i++) {
                    const tmp = FormIssuesCache[key][key2][i];
                    if (tmp.OID == issueOID) {
                        return tmp;
                    }
                }
            }
        }
        return null;
    }

    export function UpdateIssuesCache(menuID: string, locationOID: string): void {
        // check cache for current data
        if (MenuItemFilterID != menuID ||
            currentLocationOID != locationOID) {
            MenuItemFilterID = menuID;
            currentLocationOID = locationOID;
            FormIssuesCache = null;
            formIssuesCacheByLocation = null;
            sortedIssuesList = null;
            treeItemsList = null;
            counterByLocationAndIssue = null;
        }

        // identify all relevant form issues
        if (!FormIssuesCache) {
            // cache form issues by menu item filter
            let issues = getAvailableIssues();
            let tmpCache = {};
            const locationCache: { [LocationOID: string]: Array<any> } = {};
            locationCache[locationOID] = null;

            if (!issues || !issues.length) {
                return;
            }

            for (let i = 0; i < issues.length; i++) {
                let tmpIssue = issues[i];
                if (!tmpIssue) {
                    continue;
                }

                if (!tmpCache[tmpIssue.AssignedFormOID]) {
                    tmpCache[tmpIssue.AssignedFormOID] = {};
                }
                if (!tmpCache[tmpIssue.AssignedFormOID][tmpIssue.StateOID]) {
                    tmpCache[tmpIssue.AssignedFormOID][tmpIssue.StateOID] = [];
                }
                tmpCache[tmpIssue.AssignedFormOID][tmpIssue.StateOID].push(tmpIssue);


                locationCache[tmpIssue.AssignedElementOID] = locationCache[tmpIssue.AssignedElementOID] || [];
                locationCache[tmpIssue.AssignedElementOID].push(tmpIssue);
            }

            // sort issues by ID / Revision
            for (let key in locationCache) {
                if (locationCache[key]) {
                    locationCache[key].sort(function(a, b): number {
                        const aID = a.ID || -1;
                        const bID = b.ID || -1;
                        if (aID == bID) {
                            return b.Revision - a.Revision;
                        }
                        return aID - bID;
                    });
                }
            }
            FormIssuesCache = tmpCache;
            formIssuesCacheByLocation = locationCache;
        }
    }

    export function ShowRecording(issue: Model.Issues.Issue) {
        Utils.Spinner.Show();
        Utils.Spinner.Lock();

        // Set View & Mode
        View.SetView(Enums.View.FormBatchEdit, null);
        Utils.SetMode(Enums.Mode.FormBatchEdit);

        // reset DOM element cache
        DAL.Cache.ParameterList.Reset();

        workingIssue = issue;
        workingLocationOID = issue.AssignedElementOID;

        let initDefer = $.Deferred().resolve();

        if (!formIssuesCacheByLocation || !treeItemsList) {
            // update all parameters counter
            initDefer = initDefer.then($.proxy(GenerateIssuesTree, FormIssuesPool, issue.AssignedFormOID, issue.StateOID))
        }

        if (!counterByLocationAndIssue) {
            initDefer = initDefer
                .then(() => {
                    // watch for unrecorded parameter count
                    const allIssues = FormIssuesCache[issue.AssignedFormOID][issue.StateOID];
                    let counterDef = $.Deferred().resolve();
                    issuesCache = {};

                    if (!counterByLocationAndIssue) {
                        counterByLocationAndIssue = {};
                        for (let i = 0; i < allIssues.length; i++) {
                            let tmpIssue = allIssues[i];
                            counterDef = counterDef.then($.proxy(getUnrecordedParametersCount, this, tmpIssue))
                                .then((count: number, issue: Model.Issues.Issue) => {
                                    issuesCache[issue.OID] = issue;
                                    const issueLocationOID = issue.AssignedElementOID;
                                    counterByLocationAndIssue[issueLocationOID] = counterByLocationAndIssue[issueLocationOID] || {};
                                    counterByLocationAndIssue[issueLocationOID][issue.OID] = count;
                                });
                        }
                    }

                    return counterDef;
                })
        }

        initDefer = initDefer.then(() => {
            const counters = getCounters();
            const extraClasses = {};
            for (let key in counters) {
                extraClasses[key] = [counters[key] ? 'incomplete' : 'complete'];
            }

            let tree = new Model.Tree()
                .SetCounters(counters)
                .SetIdentifier('location-picker')
                .SetRootCollapsable(false)
                .SetShowParentTitle(false)
                .SetShowExpanders(true)
                .SetForceExpand(true)
                .SetKeyProperty('OID')
                .SetFilter(function(item: Model.Elements.Element) { return item.Type == Enums.ElementType.Root || item.Type == Enums.ElementType.Location; })
                .SetAdditionalClasses(extraClasses)
                .SetItems(treeItemsList, currentLocationOID)
                .SetActiveItem(workingLocationOID)
                .SetRenderingContainer($('.location-picker .tree-wrapper'))
                .SetOnNodeClick((e) => {
                    const newOid = e.currentTarget.attributes['data-identifier'].value;

                    const locationIssues = formIssuesCacheByLocation[newOid];
                    if (locationIssues) {
                        const issueIdentifier = locationIssues[0].ID || locationIssues[0].OID;
                        Utils.Router.PushState(`#menu/${MenuItemFilterID}/location/${currentLocationOID}/issue/${issueIdentifier}`);
                    }
                })
                .SetSearchField(App.CreateOrRecycleSearchInputField(), i18next.t('SearchfieldPlaceholders.MainTree'))
                .Build()
                .SetForceExpand(false);

            App.SetTree(tree);

            // set Footer
            const oldFooter = $('.footer-toolbar');
            $('#content').before(Templates.Menus.FooterToolbar({
                IsIssueView: true,
                ShowIssueReportButtons: false,
                AlignToContentContainer: false,
                IssueType: Enums.IssueType.Form,
                LocationOID: Session.CurrentLocation.OID,
                ShowCreateIssue: Utils.CanUserCreateIssueType(Enums.IssueType.Form, Session.CurrentLocation),
                CanModifyIssue: Utils.CanUserModifyIssue(issue)
            }));
            oldFooter.remove();

            return App.loadIssue(issue.ID || issue.OID, ['withrecorditems=true'])
                .then((loadedIssue: Model.Issues.RawIssue) => {
                    return IssueView.Init(loadedIssue, (currentIssue: Model.Issues.Issue) => {
                        issuesCache[currentIssue.OID] = workingIssue = currentIssue;

                        IssueView.OnRequiredParameterCountChanged = function(count: number, ignoreElementChange: boolean) {
                            let cntObj = tree.GetCounters() || {};
                            counterByLocationAndIssue[workingLocationOID][workingIssue.OID] = count;
                            // just update current location
                            let newCount = 0;
                            for (let key in counterByLocationAndIssue[workingLocationOID]) {
                                newCount += counterByLocationAndIssue[workingLocationOID][key];
                            }
                            cntObj[workingLocationOID] = newCount;
                            tree.UpdateCounters(cntObj);
                            if (newCount == 0) {
                                // set room green, when clean
                                tree.UpdateAdditionalClassesAtNode(workingLocationOID, ['complete']);
                            } else {
                                tree.UpdateAdditionalClassesAtNode(workingLocationOID, ['incomplete']);
                            }

                            //  check if all finished / followerstate can be set
                            if (ignoreElementChange) {
                                return;
                            } else if (canShowFollowerStateSelect()) {
                                IssueView.OnBeforeAutosetFollowerState = null;
                                IssueView.TrySettingStandardFollowState(true);
                            } else if (Session.Settings.AutomaticallyChangeRoomAfterRecording != Enums.AutomaticallyChangeRoomAfterRecordingType.Never &&
                                canGoToNextIssue(workingLocationOID, workingIssue.OID) && sortedIssuesList) {
                                // go to next form issue
                                for (let i = 1; i < sortedIssuesList.length; i++) {
                                    const tmpIssue = sortedIssuesList[i - 1];
                                    if (tmpIssue.OID == workingIssue.OID) {
                                        const nextIssue = sortedIssuesList[i];
                                        switch (Session.Settings.AutomaticallyChangeRoomAfterRecording) {
                                            case Enums.AutomaticallyChangeRoomAfterRecordingType.Always:
                                                setTimeout(function() {
                                                    Utils.Router.PushState(`#menu/${MenuItemFilterID}/location/${currentLocationOID}/issue/${nextIssue.ID || nextIssue.OID}`);
                                                }, 100);
                                                break;
                                            case Enums.AutomaticallyChangeRoomAfterRecordingType.Ask:
                                                Utils.Message.Show(i18next.t('IssueView.GoToNextFormIssue.MessageHeader'),
                                                    i18next.t('IssueView.GoToNextFormIssue.MessageBody', { Title: nextIssue.Title }),
                                                    {
                                                        Yes: function() {
                                                            Utils.Router.PushState(`#menu/${MenuItemFilterID}/location/${currentLocationOID}/issue/${nextIssue.ID || nextIssue.OID}`);
                                                        },
                                                        No: true
                                                    });
                                                break;
                                        }
                                        break;
                                    }
                                }
                            }
                        };

                        IssueView.OnBeforeUpdateState = function(followState: Model.Properties.Property): boolean {
                            // update all issues, that are 'completed'
                            saveCompletedIssues(followState)
                                .then(() => {
                                    // leave recording
                                    Utils.Router.PushState(`location/${currentLocationOID}/issue-pool/${MenuItemFilterID}`);
                                });
                            return false;
                        };

                        // add onFollowerStateSet callback
                        IssueView.OnBeforeAutosetFollowerState = function(states: Array<Model.Properties.Property>, force: boolean): boolean {
                            return force || false;
                        }
                    }).then(() => {
                        //  check if all finished / followerstate can be set on start
                        if (canShowFollowerStateSelect()) {
                            IssueView.TrySettingStandardFollowState(true);
                        }
                    });
                });
        }).then($.proxy(App.ResizeLocationPicker, App))
            .then(() => {
                // add next icon, if required
                const issuesAtLocation = formIssuesCacheByLocation[workingLocationOID];
                if (issuesAtLocation && issuesAtLocation.length > 1) {
                    const total = issuesAtLocation.length;
                    let current = 0;

                    for (let i = 0; i < total; i++) {
                        current++;

                        if (issuesAtLocation[i].OID == issue.OID ||
                            (issuesAtLocation[i].ID == issue.ID && issue.ID)) {
                            break;
                        }
                    }

                    const $buttonHtml = $(Templates.Menus.FormSwitchButton({
                        currentFormIndex: current,
                        totalFormCount: total
                    }));

                    $('.content-header.full-width').append($buttonHtml);

                    $buttonHtml.on('click', '.icon-wrapper', function(evt: MouseEvent) {
                        evt.preventDefault();

                        const $currentTarget = $(evt.currentTarget);
                        const direction = $currentTarget.data('direction');
                        const nextIssue = direction === 'next' ?
                            issuesAtLocation[current < total ? current : 0] :
                            issuesAtLocation[current <= 1 ? total - 1 : current - 2];

                        Utils.Router.PushState(`#menu/${MenuItemFilterID}/location/${currentLocationOID}/issue/${nextIssue.ID || nextIssue.OID}`);
                    })
                }
            })
            .always(() => {
                Utils.Spinner.Unlock();
                Utils.Spinner.Hide();
            });
    }

    function getCounters(existingCounters?: Dictionary<number>): any {
        existingCounters = existingCounters || {};
        for (let key in counterByLocationAndIssue) {
            const byIssues = counterByLocationAndIssue[key];
            let tmpCount = 0;
            if (byIssues) {
                for (let key in byIssues) {
                    tmpCount += byIssues[key];
                }
            }
            existingCounters[key] = tmpCount;
        }
        return existingCounters;
    }

    function canGoToNextIssue(locationOID: string, issueOID: string) {
        /* check all counters for being 0 */
        const byIssues = counterByLocationAndIssue[locationOID];
        if (!byIssues) {
            return true;
        }

        if (byIssues[issueOID]) {
            return false;
        }

        return true;
    }

    function canShowFollowerStateSelect(): boolean {
        /* check all counters for being 0 */
        for (let key in counterByLocationAndIssue) {
            const byIssues = counterByLocationAndIssue[key];
            if (!byIssues) {
                continue;
            }

            for (let key in byIssues) {
                if (byIssues[key]) {
                    return false;
                }
            }
        }

        return true;
    }

    function saveCompletedIssues(followState: Model.Properties.Property): Deferred {
        Utils.Spinner.Show();
        Utils.Spinner.Lock('_batch_forms_');

        let processDefer = $.Deferred();
        let anyIssueToClose = false;

        setTimeout($.proxy((defer: Deferred) => {
            if (anyIssueToClose) {
                defer.resolve();
            } else {
                // Es wurden keine Vorgänge geschlossen
                defer.reject();
            }
        }, null, processDefer), 10);

        // Update completed issues only
        for (let keyLocation in counterByLocationAndIssue) {
            const byIssues = counterByLocationAndIssue[keyLocation];
            if (!byIssues) {
                continue;
            }

            for (let issueOID in byIssues) {
                if (byIssues[issueOID]) {
                    continue;
                }

                let issue = issuesCache[issueOID];
                if (!issue) {
                    continue;
                }

                processDefer = processDefer.then(() => Utils.IssueViewer.UpdateState(issue, followState, (updatedIssue: Model.Issues.Issue | Model.Issues.RawIssue, previousIssue: Model.Issues.IssueType) => {
                    // updateIssue with previous record items
                    if ((previousIssue.Recorditems || []).length) {
                        updatedIssue.Recorditems = previousIssue.Recorditems;
                    }

                    if (View.CurrentView === Enums.View.Inspection) {
                        updatedIssue.Ancestors = previousIssue.Ancestors;
                        updatedIssue.Descendants = previousIssue.Descendants;
                    }

                    if (!(updatedIssue instanceof Model.Issues.Issue)) {
                        updatedIssue = new Model.Issues.Issue(updatedIssue);
                    }

                    IssueView.UpdateIssue(updatedIssue);
                }));
                anyIssueToClose = true;
            }
        }

        return processDefer.always(function() {
            Utils.Spinner.Unlock('_batch_forms_');
            Utils.Spinner.Hide();
        }).promise();
    }

    export function GenerateIssuesTree(formOID: string, stateOID: string): void {
        if (workingFormOID != formOID || workingStateOID != stateOID) {
            treeItemsList = null;
            sortedIssuesList = null;
        }

        if (treeItemsList && sortedIssuesList) {
            return;
        }

        // get all issues with same form / state
        const listIssues = FormIssuesCache[formOID][stateOID];
        const elements = [];
        const elementsDict = {};
        formIssuesCacheByLocation = {};
        for (let i = 0; i < listIssues.length; i++) {
            const curIssue = listIssues[i];
            // get location OIDs
            let tmpElem = DAL.Elements.GetByOID(curIssue.AssignedElementOID);
            formIssuesCacheByLocation[curIssue.AssignedElementOID] = formIssuesCacheByLocation[curIssue.AssignedElementOID] || [];
            formIssuesCacheByLocation[curIssue.AssignedElementOID].push(curIssue);

            do {
                if (!elementsDict[tmpElem.OID]) {
                    const cloneElement = Utils.CloneObject(tmpElem);
                    (<any>cloneElement).IssueCount = 1;
                    elements.push(cloneElement);
                    elementsDict[tmpElem.OID] = cloneElement;
                } else {
                    (<any>elementsDict[tmpElem.OID]).IssueCount++;
                }
                if (tmpElem.OID == currentLocationOID) {
                    break;
                }
                tmpElem = tmpElem.Parent;
            }
            while (tmpElem)
        }

        // sort issues by ID / Revision
        for (let key in formIssuesCacheByLocation) {
            if (formIssuesCacheByLocation[key]) {
                formIssuesCacheByLocation[key].sort(function(a, b): number {
                    const aID = a.ID || -1;
                    const bID = b.ID || -1;
                    if (aID == bID) {
                        return b.Revision - a.Revision;
                    }
                    return aID - bID;
                });
            }
        }

        const treeItems = DAL.Elements.BuildTree(elements, true);
        sortedIssuesList = getSortedIssues(treeItems.TopElement);
        treeItemsList = treeItems.List.reduce((acc, item) => {
            acc[item.OID] = item;
            return acc;
        }, {});
    }

    function getSortedIssues(root: Model.Elements.Element): Array<Model.Issues.Issue> {
        let issueIDs = [];
        const tmpLocationIssues = formIssuesCacheByLocation[root.OID]
        if (tmpLocationIssues && tmpLocationIssues.length) {
            issueIDs = issueIDs.concat(tmpLocationIssues);
        }
        if (root.Children) {
            for (let i = 0; i < root.Children.length; i++) {
                issueIDs = issueIDs.concat(getSortedIssues(root.Children[i]));
            }
        }
        return issueIDs;
    }

    function getUnrecordedParametersCount(issue: Model.Issues.IssueType): Deferred {
        return App.loadIssue(issue.ID || issue.OID, ['withrecorditems=true'])
            .then((issue: Model.Issues.Issue) => {
                let countRequired = [];
                if (issue.Resubmissionitems) {
                    for (let i = 0; i < issue.Resubmissionitems.length; i++) {
                        const resubItem = issue.Resubmissionitems[i];
                        const tmpElem = DAL.Elements.GetByRevisionOID(resubItem.ElementRevisionOID) || DAL.Elements.GetByOID(resubItem.ElementOID);
                        if (!tmpElem || tmpElem.Type < 100 || tmpElem.Type > 115) {
                            continue;
                        }

                        if (tmpElem.hasOwnProperty('Requirements') &&
                            tmpElem.Requirements.Criteria && tmpElem.Requirements.Criteria.length) {
                            countRequired[resubItem.OID] = false;
                        } else {
                            countRequired[resubItem.OID] = tmpElem.Required || false;
                        }
                    }
                }

                if (issue.Recorditems) {
                    for (let i = 0; i < issue.Recorditems.length; i++) {
                        const recItem = issue.Recorditems[i];
                        if (recItem.ResubmissionitemOID) {
                            countRequired[recItem.ResubmissionitemOID] = false;
                        }
                    }
                }

                let unrecordedCounter = 0;
                for (let key in countRequired) {
                    if (countRequired[key]) {
                        unrecordedCounter++;
                    }
                }

                return $.Deferred().resolve(unrecordedCounter, issue);
            });
    }
}
