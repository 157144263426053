//imports-start
/// <reference path="../../enums.ts"  />
//imports-end

module Model.IndividualData {
    export class SchemaProperty {
        Type: Enums.IndividualDataType;
        Name: string;
        IsNullable: boolean;
        Suffix?: string;
        Decimals?: number;
    }
}