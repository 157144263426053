//imports-start
//// <reference path="../definitions.d.ts"  />
/// <reference path="../model/individual-data/schema.ts"  />
//imports-end

module DAL.Schemas {
    let schemas: Dictionary<Model.IndividualData.Schema>;

    function prepareSchema(schema: Model.IndividualData.Schema): Model.IndividualData.Schema {
        schema.NamePlural = Utils.EscapeHTMLEntities(schema.NamePlural);
        schema.NameSingular = Utils.EscapeHTMLEntities(schema.NameSingular);

        return schema;
    }

    export function Store(syncedSchemas: Array<Model.IndividualData.Schema>): void {
        if (!schemas) {
            schemas = {};
        }

        if (!(syncedSchemas || []).length) {
            return;
        }

        for (let sCnt = 0, sLen = syncedSchemas.length; sCnt < sLen; sCnt++) {
            const schema = prepareSchema(syncedSchemas[sCnt]);

            if (!schema.Deleted) {
                schemas[schema.Type] = schema;
            } else {
                delete schemas[schema.Type];
            }
        }
    };

    export function Exists(type: string): boolean {
        return schemas.hasOwnProperty(type);
    }

    export function GetByType(type: string): Model.IndividualData.Schema {
        if (!schemas || !schemas.hasOwnProperty(type)) {
            return;
        }

        return schemas[type];
    }

    export function GetAll(): Array<Model.IndividualData.Schema> {
        return schemas ?
            $.map(schemas, (schema: Model.IndividualData.Schema) => schema) :
            null;
    }

    export function Clear(): void {
        schemas = {};
    }
}