//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Utils {
    export class IndexedArray<T> {
        private ArrayBuffer: T[] = [];
        private BufferIndex: Dictionary<number> = {};
        private keyName: string;

        constructor(keyName: string)
        constructor(item: T, keyName: string)
        constructor(items: T[] | IndexedArray<T>, keyName: string)
        constructor(key_items?: T | T[] | IndexedArray<T> | string | null, keyName?: string) {
            if (typeof key_items === 'string') {
                this.keyName = key_items;
            } else {
                this.keyName = keyName;

                if (key_items instanceof IndexedArray) {
                    this.putRange(key_items, keyName);
                } else if (key_items instanceof Array) {
                    this.putRange(key_items, keyName);
                } else if (key_items) {
                    this.push(<T>key_items);
                }
            }
        }

        public push(item: T, key?: string): void {
            if (item == null && key == null) {
                return;
            }

            const newIndex = this.ArrayBuffer.length;
            key = key || item[this.keyName];

            this.ArrayBuffer.push(item);
            this.BufferIndex[key] = newIndex;
        }

        public putRange(items: T[], keyName?: string): void
        public putRange(items: IndexedArray<T>, keyName?: string): void
        public putRange(items: T[] | IndexedArray<T>, keyName?: string): void {
            if (!items) {
                return;
            }

            const itemsArray = items instanceof IndexedArray ? items.toArray() : items;
            keyName = keyName || this.keyName;

            for (let i = 0, len = itemsArray.length; i < len; ++i) {
                const tmpItem = itemsArray[i];
                const key = tmpItem[keyName];
                this.push(tmpItem, key);
            }
        }

        public get(index: number): T | null {
            return this.ArrayBuffer[index] || null;
        }

        public getByKey(key: string | number): T | null {
            const index = this.BufferIndex[key];

            return index >= 0 ? this.get(index) : null;
        }

        public remove(item: T): boolean {
            const index = this.indexOf(item);

            if (index < 0) {
                return false;
            }

            return this.removeByIndex(index);
        }

        public removeByKey(key: string | number): boolean {
            if (key == null) {
                return false;
            }

            const index = this.BufferIndex[key];

            if (index === null) {
                return false;
            }

            return this.removeByIndex(index);
        }

        public removeByIndex(index: number): boolean {
            if (index == null) {
                return false;
            }

            this.ArrayBuffer.splice(index, 1);

            this.updateBufferIndices(index);

            return true;
        }

        private updateBufferIndices(index: number): void {
            let keyToDelete: string | number;

            for (const key in this.BufferIndex) {
                if (this.BufferIndex.hasOwnProperty(key)) {
                    const itemIndex = this.BufferIndex[key];

                    if (itemIndex > index) {
                        this.BufferIndex[key] = itemIndex - 1;
                    } else if (itemIndex === index) {
                        keyToDelete = key;
                    }
                }
            }

            if (keyToDelete) {
                delete this.BufferIndex[keyToDelete];
            }
        }

        public clear(): void {
            this.ArrayBuffer = [];
            this.BufferIndex = {};
        }

        public has(key: number | string): boolean
        public has(item: T): boolean
        public has(item_key: number | string | T): boolean {
            return this.indexOf(<any>item_key) >= 0;
        }

        public indexOf(key: number | string): number
        public indexOf(item: T): number
        public indexOf(item_key: number | string | T): number {
            if (item_key == null) {
                return -1;
            }

            let indexOf: number;

            if (typeof item_key == 'string' ||
                typeof item_key == 'number') {
                indexOf = this.BufferIndex[item_key];
            } else {
                var key = item_key[this.keyName];
                if (key && this.BufferIndex[key] != null) {
                    indexOf = this.BufferIndex[key];
                } else {
                    indexOf = this.ArrayBuffer.indexOf(item_key);
                }
            }

            if (indexOf == null) {
                return -1;
            }

            return indexOf;
        }

        public size(): number {
            return this.ArrayBuffer.length;
        }

        public isEmpty(): boolean {
            return this.ArrayBuffer.length === 0;
        }

        public toArray(): T[] {
            return [].concat(this.ArrayBuffer);
        }

        public toDictionary(): Dictionary<T> {
            const resultDict: Dictionary<T> = {};

            for (const key in this.BufferIndex) {
                if (this.BufferIndex.hasOwnProperty(key)) {
                    const index = this.BufferIndex[key];
                    resultDict[key] = this.ArrayBuffer[index];
                }
            }

            return resultDict;
        }

        public toSet(): HashSet {
            return new HashSet(Object.keys(this.BufferIndex));
        }
    }

    // Global Test Variable
    if (typeof window.__TEST__ != 'undefined') {
        window.__TEST__.Utils = window.__TEST__.Utils || Utils;
        window.__TEST__.Utils.IndexedArray = IndexedArray;
    }
}
