/// <reference path="../../definitions.d.ts" />
/// <reference path="./PdfBase.ts"  />

class PdfActionPlan extends PdfDocument {
    public static readonly AuditKeywordOID = 'ff43e566-856a-4ff4-a58b-24d75cf6a479';
    public static readonly HashKeywordOID = 'cad083bf-957b-44a4-ab19-805e4589d4bf';

    private static readonly SuedbayernOID = '0316b4c5-99e0-4166-80af-9afac1dea6e4';
    private static readonly MarketTypePrototypeOID = '85ce84fe-4395-4b67-8705-a9135337c589';
    private static readonly MarketIDPrototypeOID = 'ab457038-629f-4d8d-84e3-1a64ba7a93d2';
    private static readonly MarketPartnerPrototypeOID = '620ffc4f-b6ee-4d86-ba8f-65991e30f169';
    private static readonly MarketStreetPrototypeOID = 'e636fd55-c9ff-4114-80b2-8c4894e87309';
    private static readonly MarketOldCityPrototypeOID = 'ed29b8e3-1532-456d-8b49-6fedc6c9b013';
    private static readonly MarketZIPCodePrototypeOID = 'e992a138-4638-4dcb-995c-e0cf75538138';
    private static readonly MarketCityPrototypeOID = 'e0b66617-0391-4b7e-835f-effedb26e6ab';
    private static readonly MarketClassificationPrototypeOID = '8fa71397-33ac-47ed-9c84-3883663c799f';

    protected readonly preparedRecorditems: Dictionary<Dictionary<Model.Recorditem>>;
    protected readonly deviatingRecords: { Recorditem: Model.Recorditem, Element: Model.Elements.Element }[];
    protected readonly imageData: Dictionary<PdfImageData>;

    private readonly issue: Model.Issues.Issue;
    private readonly resubTree: Model.Elements.Element;
    private readonly logoOID: string;
    private auditor: string;
    private auditDate: string;
    private auditStartTime: string;
    private auditEndTime: string;

    public constructor(issue: Model.Issues.Issue, resubTree: Model.Elements.Element) {
        super('a4', 'landscape');

        this.logoOID = DAL.Elements.Root.ImageOID;
        this.issue = issue;
        this.resubTree = resubTree;
        this.preparedRecorditems = {};
        this.deviatingRecords = [];
        this.imageData = {};

        this.prepareRecorditems();
        this.prepareDeviatingRecorditems();
        this.prepareAuditInformations();
    }

    public IsCompleted(): boolean {
        return Utils.IsSet(this.auditor) &&
            Utils.IsSet(this.auditDate) &&
            Utils.IsSet(this.auditStartTime) &&
            Utils.IsSet(this.auditEndTime)
    }

    public ContainsData(): boolean {
        return this.deviatingRecords.length > 0
    }

    public Create(): Deferred {
        const deferred = $.Deferred();

        this.SetFontStyle('bold', null);
        this.document.textEx(i18next.t('customer:TUEV.ActionPlan.Title'), 10, this.currentY, 'top', 'left');
        this.currentY += 5;

        this.LoadRequiredImages()
            .always(() => {
                this.createContent()
                deferred.resolve();
            });

        return deferred.promise();
    }

    private LoadRequiredImages(): Deferred {
        const deferreds = this.GetImageIdentifiers().map((imageIdentifier) => {
            const filename = Utils.IsValidGuid(imageIdentifier)
                             ? (DAL.Files.GetByOID(imageIdentifier) || {Filename: null}).Filename
                             : imageIdentifier;

            if (filename == null) {
                return null;
            }

            const url: string = Session.IsSmartDeviceApplication
                ? Utils.FixIOSFilepath(Utils.GetResourcesPath() + filename)
                : `${Session.BaseURI}images/s/${filename}`;

            return this.LoadImage(imageIdentifier, url);
        }).filter(f => f != null);

        return $.when.apply($, deferreds)
            .then((...imageDataArray: Array<PdfImageData>) => {
                for (const imageData of imageDataArray) {
                    if (imageData) {
                        this.imageData[imageData.Identifier] = imageData;
                    }
                }
            });
    }

    protected GetImageIdentifiers(): Array<string> {
        return [this.logoOID]
    }

    private createContent(): void {
        this.PlaceLogo();
        this.CreateHeaderTable();
        this.CreateActionPlanTable();
        this.CreateFooterText();
    }

    protected GetFilename(): string {
        return `${Utils.UnescapeHTMLEntities(this.issue.Room.Title)} - ${this.auditDate}`;
    }

    protected PlaceLogo(): void {
        if (!this.imageData[this.logoOID]) {
            return;
        }

        this.document.addImage(this.imageData[this.logoOID].Canvas, 'JPEG', this.document.internal.pageSize.getWidth() - 30, 10, 20, 20);
    }

    protected CreateHeaderTable(): void {
        const table = new PdfTable();
        const lastHeaderRow = [
            i18next.t('customer:TUEV.ActionPlan.HeaderTable.Location'),
            this.getCityText()
        ];

        if (this.issue.Room.ParentOID === PdfActionPlan.SuedbayernOID) {
            lastHeaderRow.push(i18next.t('customer:TUEV.ActionPlan.HeaderTable.Classification'));
            lastHeaderRow.push(this.getAdditionalPropertyValueByPrototypeOID(PdfActionPlan.MarketClassificationPrototypeOID));
        } else {
            lastHeaderRow.push('', '');
        }

        table.AddBodyRow([
            i18next.t('customer:TUEV.ActionPlan.HeaderTable.Company'),
            Utils.UnescapeHTMLEntities(((this.issue.Room || {}).Parent || {}).Title),
            i18next.t('customer:TUEV.ActionPlan.HeaderTable.IssueID'),
            `${this.issue.ID}`
        ]);

        table.AddBodyRow([
            i18next.t('customer:TUEV.ActionPlan.HeaderTable.MarketType'),
            this.getAdditionalPropertyValueByPrototypeOID(PdfActionPlan.MarketTypePrototypeOID),
            i18next.t('customer:TUEV.ActionPlan.HeaderTable.AuditDate'),
            this.auditDate
        ]);
        table.AddBodyRow([
            i18next.t('customer:TUEV.ActionPlan.HeaderTable.MarketID'),
            this.getAdditionalPropertyValueByPrototypeOID(PdfActionPlan.MarketIDPrototypeOID),
            i18next.t('customer:TUEV.ActionPlan.HeaderTable.AuditStartTime'),
            this.auditStartTime
        ]);
        table.AddBodyRow([
            i18next.t('customer:TUEV.ActionPlan.HeaderTable.MarketPartner'),
            this.getAdditionalPropertyValueByPrototypeOID(PdfActionPlan.MarketPartnerPrototypeOID),
            i18next.t('customer:TUEV.ActionPlan.HeaderTable.AuditEndTime'),
            this.auditEndTime
        ]);
        table.AddBodyRow([
            i18next.t('customer:TUEV.ActionPlan.HeaderTable.Street'),
            this.getAdditionalPropertyValueByPrototypeOID(PdfActionPlan.MarketStreetPrototypeOID),
            i18next.t('customer:TUEV.ActionPlan.HeaderTable.Auditor'),
            Utils.UnescapeHTMLEntities(this.auditor)
        ]);
        table.AddBodyRow(lastHeaderRow);

        table.SetColumnStyles(0, {
            cellWidth: 30,
            minCellWidth: 30,
            fillColor: [204, 204, 204],
            overflow: 'linebreak',
            cellPadding: 0.5
        });
        table.SetColumnStyles(1, {
            cellWidth: 60,
            minCellWidth: 60,
            overflow: 'linebreak',
            cellPadding: 0.5
        });
        table.SetColumnStyles(2, {
            cellWidth: 30,
            minCellWidth: 30,
            fillColor: [204, 204, 204],
            overflow: 'linebreak',
            cellPadding: 0.5
        });
        table.SetColumnStyles(3, {
            cellWidth: 60,
            minCellWidth: 60,
            overflow: 'linebreak',
            cellPadding: 0.5
        });

        const tableOptions = table.GetOptions();
        tableOptions.startY = this.currentY;
        tableOptions.tableWidth = 180;
        tableOptions.margin = 10;
        tableOptions.theme = 'grid';
        tableOptions.styles = {
            showHead: 'everyPage',
            rowPageBreak: 'avoid',
            fontSize: 7,
            valign: 'middle',
            halign: 'left',
            lineWidth: 0.1,
            lineColor: [160, 160, 160],
            textColor: [0, 0, 0]
        };

        this.FitTable(tableOptions);
    }

    protected CreateActionPlanTable(): void {
        const startPage = this.document.page;
        const table = new PdfTable();

        this.AddHeaderRow(table);
        this.SetColumnStyles(table);
        this.CreateTableBody(table);

        const tableOptions = table.GetOptions();
        tableOptions.startY = this.currentY + 5;
        tableOptions.margin = { left: 10, top: 10, right: 10, bottom: 20 };
        tableOptions.theme = 'grid';
        tableOptions.styles = {
            showHead: 'everyPage',
            rowPageBreak: 'avoid',
            fontSize: 7,
            valign: 'top',
            halign: 'left',
            lineWidth: 0.1,
            lineColor: [160, 160, 160]
        };

        tableOptions.headStyles = {
            valign: 'middle', halign: 'center', fillColor: [204, 204, 204], textColor: [0, 0, 0]
        };

        tableOptions.didDrawPage = (hookData) => {
            if (hookData.pageNumber == startPage) {
                return;
            }

            this.document.page++;
            this.CreateFooter();
        };

        this.FitTable(tableOptions);
    }

    protected AddHeaderRow(table: PdfTable) {
        table.AddHeaderRow([
            { content: i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Information'), colSpan: 2, styles: { textColor: [0, 0, 0] } },
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Rating'),
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Remarks'),
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.What'),
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Who'),
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.When')
        ]);
    }

    protected SetColumnStyles(table: PdfTable): void {
        table.SetColumnStyles(0, { cellWidth: 5, minCellWidth: 5, cellPadding: 0.5, valign: 'middle' });
        table.SetColumnStyles(1, { cellWidth: 'auto', overflow: 'linebreak', cellPadding: 0.5 });
        table.SetColumnStyles(2, { cellWidth: 10, minCellWidth: 10, overflow: 'linebreak', cellPadding: 0.5 });
        table.SetColumnStyles(3, { cellWidth: 50, minCellWidth: 50, overflow: 'linebreak', cellPadding: 0.5 });
        table.SetColumnStyles(4, { cellWidth: 50, minCellWidth: 50, overflow: 'linebreak', cellPadding: 0.5 });
        table.SetColumnStyles(5, { cellWidth: 50, minCellWidth: 50, overflow: 'linebreak', cellPadding: 0.5 });
        table.SetColumnStyles(6, { cellWidth: 50, minCellWidth: 50, overflow: 'linebreak', cellPadding: 0.5 });
    }

    protected CreateTableBody(table: PdfTable): void {
        const rLen = this.deviatingRecords.length;
        for (let rCnt = 0; rCnt < rLen; rCnt++) {
            const relevantRecord = this.deviatingRecords[rCnt];
            const parameter = relevantRecord.Element;
            const recorditem = relevantRecord.Recorditem;

            const structureEval = Utils.UnescapeHTMLEntities(parameter.Structure[recorditem.Value] || '');
            const comments = [];

            const cLen = (recorditem.Comments || []).length;
            for (let cCnt = 0; cCnt < cLen; cCnt++) {
                const comment = recorditem.Comments[cCnt];
                comments.push(Utils.UnescapeHTMLEntities(comment.Text) || '');
            }

            table.AddBodyRow([
                `${rCnt + 1}`,
                `${Utils.UnescapeHTMLEntities(parameter.Title) || ''} ${Utils.UnescapeHTMLEntities(parameter.Description || '')}`.trim(),
                structureEval,
                comments.join('\r\n\r\n'),
                '',
                '',
                ''
            ]);
        }
    }

    private prepareDeviatingRecorditems(): void{
        const resubGroups = this.resubTree.Parametergroups;

        resubGroups.sort((a, b) => {
            return a.Position - b.Position;
        });

        for (let gCnt = 0, gLen = resubGroups.length; gCnt < gLen; gCnt++) {
            const group = resubGroups[gCnt];

            if (Utils.IsSet(group.Row) && group.Row > 1) {
                continue;
            }

            const groupParameters = group.Parameters || [];

            groupParameters.sort((a, b) => {
                return a.Position - b.Position;
            });

            for (let pCnt = 0, pLen = groupParameters.length; pCnt < pLen; pCnt++) {
                const param = groupParameters[pCnt];

                if (!Utils.IsSet(param.Structure)) {
                    continue;
                }

                const recorditems = this.preparedRecorditems[param.RevisionOID];

                if (!Utils.IsSet(recorditems)) {
                    continue;
                }

                const recorditem = recorditems[param.Row || 0];

                if (!Utils.IsSet(recorditem) || !Utils.IsSet(recorditem.Value)) {
                    continue;
                }

                const structureValue = param.Structure[recorditem.Value];
                const structureCompareValue = typeof (structureValue) === "string" ? structureValue.toUpperCase() : structureValue;

                if (!Utils.InArray(this.GetValidValues(), structureCompareValue)) {
                    continue;
                }

                this.deviatingRecords.push({ Recorditem: recorditem, Element: param });
            }
        }
    }

    public GetValidValues(): Array<string> { return ['B', 'C', 'D', 'KO', "TRIFFT NICHT ZU", "MAJOR", 'II', 'III', 'IV', 'NEIN']; }

    private prepareRecorditems(): void {
        const rLen = (this.issue.Recorditems || []).length;

        for (let rCnt = 0; rCnt < rLen; rCnt++) {
            const recorditem = this.issue.Recorditems[rCnt];

            if (!this.preparedRecorditems[recorditem.ElementRevisionOID]) {
                this.preparedRecorditems[recorditem.ElementRevisionOID] = {};
            }

            this.preparedRecorditems[recorditem.ElementRevisionOID][recorditem.Row || 0] = recorditem;
        }
    }

    protected CreateFooterText(): void {
        if (this.currentY > this.document.internal.pageSize.getHeight() - 50) {
            this.AddPage('a4', 'landscape');
            this.PlaceLogo();
            this.CreateHeaderTable();
        }

        this.document.setFontSize(9);

        this.currentY += 5;
        this.SetFontStyle('bold');
        this.document.textEx(i18next.t('customer:TUEV.ActionPlan.Information.Header'), 10, this.currentY, 'top', 'left');

        this.currentY += 5;
        this.SetFontStyle('', 'normal');
        this.document.textEx(i18next.t('customer:TUEV.ActionPlan.Information.Message'), 10, this.currentY, 'top', 'left');

        this.currentY += 10;
        this.SetFontStyle('bold');
        this.document.textEx(i18next.t('customer:TUEV.ActionPlan.Signing.Header'), 10, this.currentY, 'top', 'left');

        this.currentY += 5;
        this.SetFontStyle('', 'normal');
        this.document.textEx(i18next.t('customer:TUEV.ActionPlan.Signing.Message'), 10, this.currentY, 'top', 'left');

        this.currentY += 5;
    }

    protected getCreatedByText(): string {
        const todayString = (new Date()).format(i18next.t('customer:TUEV.ActionPlan.CreatedByDateFormat'));

        return i18next.t('customer:TUEV.ActionPlan.CreatedByAwenko', { Date: todayString });
    }

    protected GetBusinessSecretText(): string {
        return i18next.t('customer:TUEV.ActionPlan.BusinessSecret');
    }

    protected GetPageText(): string {
        return i18next.t('customer:TUEV.ActionPlan.Page', { CurrentPage: this.document.page, MaxPage: PdfDocument.totalPagesExp });
    }

    private getAdditionalPropertyValueByPrototypeOID(prototypeOID: string): string {
        const location = <Model.Elements.Element>this.issue.Room;

        if (!Utils.IsSet(location)) {
            return null;
        }

        const additionalProperty = Utils.Where(location.AdditionalProperties, 'PrototypeOID', '===', prototypeOID);

        if (!Utils.IsSet(additionalProperty)) {
            return null;
        }

        return Utils.UnescapeHTMLEntities(additionalProperty.Value.toString() || '');
    }

    private prepareAuditInformations(): void {
        const resubGroups = this.resubTree.Parametergroups;

        for (let gCnt = 0, gLen = resubGroups.length; gCnt < gLen; gCnt++) {
            const group = resubGroups[gCnt];

            const isAuditGroup = group.Title == 'Audit';
            const isSignatureGroup = /Unterschrift(en)? \/ Ende/.test(group.Title);

            if (!isAuditGroup && !isSignatureGroup) {
                continue;
            }

            const groupParameters = group.Parameters || [];

            for (let pCnt = 0, pLen = groupParameters.length; pCnt < pLen; pCnt++) {
                const param = groupParameters[pCnt];

                const recorditems = this.preparedRecorditems[param.RevisionOID];

                if (!Utils.IsSet(recorditems)) {
                    continue;
                }

                const recorditem = recorditems[param.Row || 0];

                if (!Utils.IsSet(recorditem)) {
                    continue;
                }

                if (isAuditGroup) {
                    if (param.Title === 'Datum') {
                        this.auditDate = Utils.DateTime.DateToString(new Date(recorditem.Value), false, true);
                    } else if (param.Title === 'Beginn') {
                        this.auditStartTime = Utils.DateTime.TimeToString(new Date(recorditem.Value));
                    }

                    continue;
                }

                if (isSignatureGroup) {
                    if (param.Title === 'Ende') {
                        this.auditEndTime = Utils.DateTime.TimeToString(new Date(recorditem.Value));
                    } else if (param.Title === 'Unterschrift Auditor') {
                        this.auditor = DAL.Users.GetByOID(recorditem.EditorOID).Title;
                    }

                    this.OnSignatureGroupParameterFound(param, recorditem);
                }
            }
        }
    }

    protected OnSignatureGroupParameterFound(parameter: Model.Elements.Element, recorditem: Model.Recorditem): void {}

    private getCityText(): string {
        const oldCity = this.getAdditionalPropertyValueByPrototypeOID(PdfActionPlan.MarketOldCityPrototypeOID);

        if (Utils.IsSet(oldCity)) {
            return oldCity;
        }

        const zipCode = this.getAdditionalPropertyValueByPrototypeOID(PdfActionPlan.MarketZIPCodePrototypeOID);
        const city = this.getAdditionalPropertyValueByPrototypeOID(PdfActionPlan.MarketCityPrototypeOID);

        return `${zipCode} ${city}`.trim();
    }
}

