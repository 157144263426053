module Utils {
    export class CustomChartTooltip {
        private _$element: any
        private _modelTooltip: any
        private _chart: any
        public _isTarget: boolean

        constructor() {
            this._$element = this.getTooltip();
        }

        public isModelHidden(): boolean {
            if (!this._modelTooltip) {
                return true;
            }

            return this._modelTooltip.opacity === 0;
        }

        public hide(): void {
            if (!this._$element || this._$element.hasClass('hidden')) {
                return;
            }

            this._$element.addClass('hidden');
        }

        public handleTooltip(tooltipModel: any, chart: any): void {
            if (!this.isValidModel(tooltipModel)) {
                throw new Error('model is not valid')
            }

            if (!this.isValidChart(chart)) {
                throw new Error('chart is not valid')
            }

            this.update(tooltipModel, chart.chart);

            if (this.isModelHidden() && !this._isTarget) {
                this.hide();
                return;
            }

            if (tooltipModel.tooltip.body) {
                this.setText();
            }

            this.setCaretPosition();
            this.display();
        }

        private update(tooltipModel: any, chart: any): void {
            this._modelTooltip = tooltipModel.tooltip;
            this._chart = chart;
        }

        private getTooltip(): any {
            const $tooltip = $('#chartjs-tooltip')
            if ($tooltip.length == 0) {
                throw new Error('tooltip doesn\'t exist')
            }

            return $tooltip;
        }

        private setText(): void {
            const thead = this._$element.find('thead');
            thead.empty();

            if (!!(this._modelTooltip.title || []).length) {
                this._modelTooltip.title.forEach((title: string) => {
                    thead.append($(Templates.ChartjsCustomTooltip.TimestampRow(title)));
                });
            }

            const $value = this._$element.find('tbody th');
            $value.empty();

            if (!!(this._modelTooltip.body || []).length && !!(this._modelTooltip.body[0].lines || []).length) {
                $value.text(this._modelTooltip.body[0].lines[0]);
            }
        }

        private setCaretPosition(): void {
            this._$element.removeClass('above', 'below', 'no-transform');
            this._$element.addClass(this._modelTooltip.yAlign || 'no-transform');
        }

        private display(): void {
            this.show();
            this.setStyle(this.getChartPosition());
        }

        private getChartPosition(): DOMRect {
            return this._chart.canvas.getBoundingClientRect();
        }

        private setStyle(chartPosition: DOMRect): void {
            this._$element.css({
                left: this.calcLeft(chartPosition) + 'px',
                top: this.calcTop(chartPosition) + 'px'
            });
        }

        private calcLeft(chartPosition: DOMRect): number {
            const modifier: number = this._modelTooltip.caretX < this._chart.width / 2 ? 0 : this._$element.innerWidth();
            return chartPosition.left + this._modelTooltip.caretX - modifier;
        }

        private calcTop(chartPosition: DOMRect): number {
            let modifier = this._$element.innerHeight() / 2;
            if (this._modelTooltip.caretY < this._$element.innerHeight()) {
                modifier = 0;
            } else if (this._chart.height - this._modelTooltip.caretY < this._$element.innerHeight()) {
                modifier *= 2;
            }
            return chartPosition.top + this._modelTooltip.caretY - modifier;
        }

        private show(): void {
            this._$element.removeClass('hidden');
        }

        private isValidModel(model): boolean {
            return !!model &&
                !!model.tooltip &&
                !!model.tooltip.yAlign &&
                model.tooltip.caretX >= 0 &&
                model.tooltip.caretY >= 0 &&
                model.tooltip.opacity >= 0;
        }

        private isValidChart(chart: any): boolean {
            return !!chart &&
                !!chart.chart &&
                chart.chart.width >= 0 &&
                chart.chart.height >= 0 &&
                !!chart.chart.canvas &&
                !!chart.chart.canvas.getBoundingClientRect;
        }
    }
}
