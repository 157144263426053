//imports-start
/// <reference path="../../dal/elements.ts"  />
/// <reference path="../../model/elements/element.ts"  />
/// <reference path="../utils.message.ts"  />
/// <reference path="../utils.file-helper.ts"  />
/// <reference path="./utils.base-info-window.ts"  />
//imports-end

module Utils.InfoWindow {
    export class FormInfoWindow extends BaseInfoWindow<Model.Elements.Element> {
        protected infoWindowID: string = 'form-info';

        constructor(form: Model.Elements.Element | string, zIndex: number = 1200) {
            super(form, zIndex);
        }

        protected showNoItemFoundMessage(): void {
            Utils.Message.Show(i18next.t('SelectionWindow.Forms.UnknownForm.MessageHeader'),
                i18next.t('SelectionWindow.Forms.UnknownForm.MessageBody'),
                {
                    Close: true
                }, null, this.zIndex + 50);
        }

        protected getContent(): any {
            return $(`<div class="information">${this.createInformationMarkup()}</div>`);
        }

        protected getItemTitle(): string {
            return this.item.Title;
        }

        protected getItemByIdentifier(formOID: string): Model.Elements.Element {
            return DAL.Elements.GetByOID(formOID);
        }

        protected bindEvents(): void {
            super.bindEvents();

            this.$content.find('div[data-filename] .file').on('click.openFile', this.onFileClick.bind(this))
        }

        private createInformationMarkup(): string {
            if (!Utils.IsSet(this.item)) {
                return '';
            }

            const images = [];
            const files = [];
            const embeddedVideos = [];

            (this.item.Files || []).forEach((assignedFile) => {
                const file = DAL.Files.GetByOID(assignedFile.OID);

                if (!file) {
                    return;
                }

                switch (file.Type) {
                    case Enums.FileType.File:
                        if (Utils.IsImage(file.MimeType)) {
                            images.push(file);
                        } else {
                            files.push(file);
                        }

                        break;
                    case Enums.FileType.Link:
                        files.push(file);
                        break;
                    case Enums.FileType.Youtube:
                    case Enums.FileType.VimeoPrivate:
                    case Enums.FileType.VimeoPublic:
                        embeddedVideos.push(file);
                        break;
                }
            });

            return Templates.Information({
                Element: this.item,
                Images: images,
                Files: files,
                EmbeddedVideos: embeddedVideos,
                UserIsOnline: this.userIsOnline
            });
        }

        private onFileClick(evt: Event): void {
            const $file = $(evt.currentTarget);
            const $li = $file.parent();

            if ($file.hasClass('image')) {
                Utils.OpenFiles($.map(this.item.Files, (locFile) => {
                    return DAL.Files.GetByOID(locFile.OID);
                }), $li.data('filename'), null, this.item.Title);
            } else {
                Utils.OpenFile($li.data('filename'), false, $file.hasClass('video'), this.item.Title);
            }
        };
    }
}