//imports-start
/// <reference path="../definitions.d.ts" />
//imports-end

module Utils {
    export function OpenCodeScannerScan(fnSuccess: (result: { format: string, text: string }) => void, fnCancelled?: () => void, fnError?: () => void) {
        if (!CodeScanner.IsSupported()) {
            return;
        }

        const code_scanner_callback_id = 'CodeScannerDialog_Scan';

        Utils.Spinner.Hide();

        Utils.Message.Show(i18next.t('Scanner.Title'), i18next.t('Scanner.Description'), {
            Abort: () => {
                CodeScanner.RemoveFromCallbackStack(code_scanner_callback_id);
                fnCancelled();
            }
        }, null, 100000);

        CodeScanner.AddToCallbackStack((type: string, value: string) => {
            Utils.Message.Hide();

            fnSuccess({
                format: type === 'LABEL-TYPE-QRCODE' ? 'QR_CODE' : type,
                text: value
            });
        }, false, code_scanner_callback_id);
    }

    export function StartScanner(
        fnSuccess: (data: { format: string, text: string }) => void,
        fnCancelled?: () => void,
        fnError?: (error?: any) => void,
        barcodeTypeConstraint?: string
    ) {
        fnCancelled = fnCancelled || Utils.Spinner.Hide;
        fnError = fnError || function(error) {
            if (error === 'unable to obtain video capture device input' ||
                error === 'Illegal access') {
                error = i18next.t('Misc.NoAccessToCamera.MessageBody');
            }

            Utils.Spinner.Hide();
            Utils.Message.Show(i18next.t('Misc.ScanError.GeneralError.MessageHeader'),
                error,
                {
                    Close: true
                }, null, 10000);
        };

        if (Session.IsSmartDeviceApplication) {
            if (CodeScanner.IsSupported()) {
                startScanner(fnSuccess, fnCancelled, fnError, barcodeTypeConstraint);
            } else {
                Utils.IsPermissionGranted(cordova.plugins.permissions.CAMERA)
                    .then(null, () => Utils.RequestPermission(cordova.plugins.permissions.CAMERA))
                    .then(() => startScanner(fnSuccess, fnCancelled, fnError, barcodeTypeConstraint))
                    .fail(Utils.Spinner.Hide);
            }
        }
    }

    function startScanner(
        fnSuccess: (data: { format: string, text: string }) => void,
        fnCancelled?: () => void,
        fnError?: (error?: any) => void,
        barcodeTypeConstraint?: string
    ): Deferred {
        if (CodeScanner.IsSupported()) {
            OpenCodeScannerScan(fnSuccess, fnCancelled, fnError);
        } else if (Session.IsSmartDeviceApplication) {
            return Utils.IsCameraPresent()
                .then(function(isPresent: boolean) {
                    if (!isPresent) {
                        Utils.Message.Show(
                            i18next.t('Camera.IsNotPresent.MessageHeader'),
                            i18next.t('Camera.IsNotPresent.MessageBody'),
                            {
                                Close: true
                            }
                        );

                        if (fnCancelled instanceof Function) {
                            fnCancelled();
                        }

                        return $.Deferred().reject();
                    }

                    let options: any = undefined;

                    if (!!barcodeTypeConstraint && barcodeTypeConstraint !== 'no-constraint') {
                        options = {
                            formats: barcodeTypeConstraint,
                            prompt: i18next.t('Misc.SpecificScannerPrompt',
                                { barcodeType: i18next.t(`Misc.BarcodeTypes.${barcodeTypeConstraint}`) }
                            )
                        };
                    }

                    // Button zum einschalten der Taschenlampe anzeigen
                    options = options || {};
                    options.showTorchButton = true;

                    cordova.plugins.barcodeScanner.scan((scanResult: { format: string, text: string, cancelled: boolean }) => {
                        if (fnSuccess) {
                            if (!scanResult.cancelled) {
                                fnSuccess(scanResult);
                            } else if (fnCancelled) {
                                fnCancelled();
                            }
                        }
                    }, fnError, options);
                }, function (error: string){
                    Utils.Message.Show(
                        i18next.t('Camera.ErrorDetectingCamera.MessageHeader'),
                        i18next.t('Camera.ErrorDetectingCamera.MessageBody', { Error: error }),
                        {
                            Close: true
                        }
                    );

                    if (fnError instanceof Function) {
                        fnError(error);
                    }
                });
        }

        return $.Deferred().resolve();
    }

    export function ValidateEAN13Barcode(barcodeValue: string): boolean {
        if (!barcodeValue || barcodeValue.length !== 13) {
            return false;
        }

        let sum = 0;

        for (let digitCnt = barcodeValue.length - 1; digitCnt >= 0; digitCnt--) {
            const digit = parseInt(barcodeValue.charAt(digitCnt), 10);

            sum = sum + digit * (1 + (2 * (digitCnt % 2)));
        }

        return (10 - (sum % 10)) % 10 === 0;
    }
}
