//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Utils.RecorditemEditor.Individual {
    const DATAWEDGE_CALLBACK_ID = 'RecorditemEditorIndividual_Scan';
    const ITEMS_PER_PAGE = 15;

    export type CallbackFunc = (result: string[], titles?: string) => void;

    let _$win, _$overlay, _$pagination, _$searchRow, _$scanCode;
    let _$btnToggleVisibleEntities;
    let _searchInputOptions: Model.ClearableInput.Settings,
        _searchInputField: Model.ClearableInput.Control;

    let _type: string;
    let _schema: Model.IndividualData.Schema;
    let _items: any[];
    let _maximumOfSelectableItems: number;

    let _previouslySelectedValues: string[];
    let _selectedValues: string[];

    let _showSelectedEntitiesOnly: boolean = false;

    let _page: number;

    let _loadedImagesCounter: number;

    let _onAfterBtnSaveClick: CallbackFunc;

    function destroy(): void {
        if (_$win) {
            _$win.find('.modal-backdrop').remove();
            _$win.remove();

            _$win = null;
        }

        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }

        _type = null;
        _schema = null;
        _items = null;
        _maximumOfSelectableItems = null;
        _onAfterBtnSaveClick = null;
        _page = null;
        _previouslySelectedValues = null;
        _selectedValues = null;
        _searchInputOptions = null;
        _searchInputField = null;
        _$searchRow = null;

        CodeScanner.RemoveFromCallbackStack(DATAWEDGE_CALLBACK_ID);

        if (!Utils.RecorditemEditor.IsVisible() && !Utils.IssueViewer.IsVisible()) {
            $('body').removeClass('modal-open').css('padding-right', '');
        }
    }

    function onPaginationItemClick(): void {
        const $this = $(this);

        _page = $this.data('page');

        $this.addClass('active').siblings().removeClass('active');

        _$win.find('.items').replaceWith(getListMarkup());

        RecalculateHeight();
    }

    function rerenderWindowContent(): void {
        if (!_$win) {
            return;
        }

        _$pagination = _$win.find('.pagination');

        _page = 1;

        _$win.find('.items').replaceWith(getListMarkup());

        const pagination = getPaginationMarkup();
        const $paginationWrapper = _$win.find('.pagination-wrapper');

        if (_$pagination.length) {
            _$pagination.replaceWith(pagination);
        } else {
            $paginationWrapper.html(pagination);
        }

        $paginationWrapper.toggleClass('hidden', !$paginationWrapper.children().length);

        RecalculateHeight();
    }

    function onAfterGotDataWedgeScan(type: string, code: string) {
        _searchInputField.SetFilter({ SearchText: code });
    }

    function onItemClick(): void {
        const $this = $(this);
        const isSelected = $this.hasClass('selected');

        if (isSelected) {
            $this.removeClass('selected');
            _selectedValues.splice(_selectedValues.indexOf($this.data('id').toString()), 1);
        }

        if (_maximumOfSelectableItems > 0 && _selectedValues.length === _maximumOfSelectableItems) {
            return;
        }

        if (!isSelected) {
            $this.addClass('selected');
            _selectedValues.push($this.data('id').toString());
        }
    }

    function onToggleEntityVisibilityClick(): void {
        _showSelectedEntitiesOnly = !_showSelectedEntitiesOnly;

        _$btnToggleVisibleEntities
            .toggleClass('btn-default', !_showSelectedEntitiesOnly)
            .toggleClass('btn-success', _showSelectedEntitiesOnly);

        rerenderWindowContent();
    }

    function onSaveClick(): void {
        let titles: string;
        let result = _selectedValues || _previouslySelectedValues;

        if ((result || []).length) {
            result = result.filter((id: string) => DAL.IndividualData.GetIndividualData(_type, id));

            titles = result.map(function(id: string) {
                const title = DAL.IndividualData.GetEntityTitle(_type, id);

                if (!title) {
                    return;
                }

                return title.replace(/<br>/ig, ' - ');
            }).join(', ');
        }

        _onAfterBtnSaveClick(result, titles);

        destroy();
    }

    function onBtnDeleteClick(): void {
        _onAfterBtnSaveClick(null, null);
        destroy();
    }

    function onImageClick(evt: Event): void {
        const identifier = $(this).data('file-identifier');

        evt.stopPropagation();

        if (!identifier) {
            return;
        }

        const file = DAL.Files.GetByOID(identifier);

        if (!file) {
            return;
        }

        Utils.OpenFile(file.Filename, true, false, file.Title);
    }

    function onImageLoaded(): void {
        _loadedImagesCounter++;

        if (_loadedImagesCounter === _$win.find('img').length) {
            RecalculateHeight();
        }
    }

    function filterItems(items: Array<any>, schema: Model.IndividualData.Schema, filterValue: string): Array<any> {
        if (!(filterValue || _showSelectedEntitiesOnly)) {
            return items;
        }

        if (!filterValue && _showSelectedEntitiesOnly) {
            return items.filter(itm => _selectedValues.indexOf(itm.ID) > -1);
        }

        const filteredItems = [];
        const regex = new RegExp(Utils.EscapeRegExPattern(filterValue), 'ig');

        for (let iCnt = 0, iLen = items.length; iCnt < iLen; iCnt++) {
            const item = items[iCnt];

            regex.lastIndex = 0;

            let isMatch = false;

            if (!(schema.SearchFields || []).length) {
                isMatch = regex.test(item.Title);
            } else {
                for (let sfCnt = 0, sfLen = schema.SearchFields.length; sfCnt < sfLen; sfCnt++) {
                    let searchFieldTitle = schema.SearchFields[sfCnt];

                    if (item.hasOwnProperty(searchFieldTitle) && regex.test(item[searchFieldTitle])) {
                        isMatch = true;
                        break;
                    }
                }
            }

            if (isMatch) {
                if (_showSelectedEntitiesOnly && _selectedValues.indexOf(item.ID) === -1) {
                    continue;
                }

                filteredItems.push(item);
            }
        }

        return filteredItems;
    }

    function getFilteredItems(): Array<any> {
        if (!_$win) {
            return _items || [];
        }

        return filterItems(_items, _schema, $.trim(_searchInputField.GetFilter().SearchText));
    }

    function getListMarkup(alternativelyUsePreviouslySelectedValue: boolean = false): string {
        const $list = $('<div></div>');
        const visibleItems = Utils.ChunkArray(getFilteredItems(), ITEMS_PER_PAGE, 0);
        let preselected = _selectedValues;

        if (alternativelyUsePreviouslySelectedValue && !(preselected || []).length) {
            preselected = _previouslySelectedValues;
        }

        $list.append($(Templates.Selections.IndividualData.ItemList({
            PreviousValue: (preselected || []).join(','),
            Items: visibleItems.length >= _page - 1 ? visibleItems[_page - 1] : null
        })));

        return $list.html();
    }

    function getPaginationMarkup(): string {
        const _$pagination = $('<div></div>');

        _$pagination.append($(Templates.Menus.Pagination({
            Maximum: Math.ceil(getFilteredItems().length / ITEMS_PER_PAGE),
            CurrentPage: _page
        })));

        return _$pagination.html();
    }

    function bindEvents(): void {
        _searchInputField.ClearFilterListeners()
            .AddFilterListener(() => {
                rerenderWindowContent();
            });
        _$btnToggleVisibleEntities.on('click', onToggleEntityVisibilityClick);
        _$win.find('.btn-save').on('click.save', onSaveClick);
        _$win.find('.btn-abort').on('click', destroy);
        _$win.find('.btn-delete').on('click', onBtnDeleteClick);
        _$win.on('click', 'li[data-id]', onItemClick);
        _$win.on('hidden.bs.modal', destroy);
        _$win.on('click', '.pagination li', onPaginationItemClick);
        _$win.find('img').on('click', onImageClick).on('load', onImageLoaded);

        if (Session.IsSmartDeviceApplication) {
            _$scanCode.on('click', onScanCodeClick);
        }

        CodeScanner.RemoveFromCallbackStack(DATAWEDGE_CALLBACK_ID);
        CodeScanner.AddToCallbackStack(onAfterGotDataWedgeScan, true, DATAWEDGE_CALLBACK_ID)
    }

    function onScanCodeClick(): void {
        Utils.StartScanner((result: { format: string, text: string }) => {
            _searchInputField.SetFilter({ SearchText: result.text })
        });
    }

    function render(filterText?: string, showDeleteButton?: boolean): void {
        _$overlay = Utils.Overlay.Generate('olIndividualDataSelection', 1051);
        _$win = $(Templates.Selections.IndividualData.Window({
            Title: _schema.NamePlural,
            ListMarkup: getListMarkup(true),
            PaginationMarkup: getPaginationMarkup(),
            ShowDeleteButton: showDeleteButton
        }));

        _$btnToggleVisibleEntities = _$win.find('.btn-toggle-visible-entities');

        _$searchRow = _$win.find('.search');

        _searchInputOptions = new Model.ClearableInput.Settings({
            ID: 'individual-data-search-input',
            InputType: 'text',
            ShowClearButton: true
        });

        _searchInputField = new Model.ClearableInput.Control(_searchInputOptions);
        _searchInputField.Build(_$searchRow);

        _searchInputField.SetPlaceholderText(
            i18next.t('SearchfieldPlaceholders.IndividualData',
                { SchemaTitle: _schema.NamePlural }
            )
        );

        _$scanCode = _$win.find('.btn-scan-code');

        _$pagination = _$win.find('.pagination');

        $('body').append(_$win);

        _$win.modal({
            show: true,
            keyboard: false,
            backdrop: false
        });

        RecalculateHeight();

        if (filterText) {
            _searchInputField.SetFilter({ SearchText: filterText });
        }
    }

    export function RecalculateHeight() {
        Utils.RepositionModalWindow(_$win);

        const $searchRow = _$win.find('.search');
        const $pagination = _$win.find('.pagination-wrapper');
        const $modalBody = _$win.find('.modal-body');
        let maxHeight = parseInt($modalBody.css('max-height'), 10);

        if ($searchRow.length) {
            maxHeight -= $searchRow.outerHeight(true);
        }

        if ($pagination.length) {
            maxHeight -= $pagination.outerHeight(true);
        }

        $modalBody.css('max-height', maxHeight);

        const windowHeight = _$win.find('.modal-dialog').innerHeight();

        _$win.find('.modal-dialog').css('margin-top', windowHeight / 2 * -1);
    }

    export function Show(element: Model.Elements.Element, callback: CallbackFunc, previousValue: string | number, filterText?: string, showDeleteButton?: boolean): void {
        if (!element) {
            return;
        }

        if (!Utils.HasProperties(element.AdditionalSettings)) {
            return;
        }

        if (!(callback instanceof Function)) {
            return;
        }

        _showSelectedEntitiesOnly = false;
        _selectedValues = [];
        _onAfterBtnSaveClick = callback;
        _type = element.AdditionalSettings.Types[0];
        _schema = DAL.Schemas.GetByType(_type);

        if (!_schema) {
            Utils.Message.Show(i18next.t('RecorditemEditor.IndividualData.SchemaNotFound.MessageHeader'),
                i18next.t('RecorditemEditor.IndividualData.SchemaNotFound.MessageBody', { Type: _type }),
                {
                    Close: true
                }, null, 1051);

            return;
        }

        _items = DAL.IndividualData.GetByType(_type);

        if (!!element.AdditionalSettings.IndividualdataFilterFormula) {
            try {
                const formulaResolver: FormulaResolver = new window.Formula({ functions: window.Formula.Functions });
                const tokens = window.Formula.tokenize(element.AdditionalSettings.IndividualdataFilterFormula);

                _items = _items.filter((item) => {
                    return formulaResolver.evaluate(tokens, item);
                });
            }
            catch (e) {
                Utils.Toaster.Show(
                    i18next.t('RecorditemEditor.Formula.FilterError'),
                    2,
                    Enums.Toaster.Icon.Warning
                );
            }
        }

        if (!(_items || []).length) {
            return;
        }

        _items.sort(Utils.SortByTitle);

        _maximumOfSelectableItems = element.AdditionalSettings.MaximumSelectionCount || 0;
        _loadedImagesCounter = 0;

        _page = 1;
        _previouslySelectedValues = null;

        if (!!previousValue) {
            if (typeof previousValue !== 'string') {
                previousValue = previousValue.toString();
            }

            if (!!previousValue) {
                _previouslySelectedValues = previousValue.split('|');

                if (!(_previouslySelectedValues instanceof Array)) {
                    _previouslySelectedValues = [_previouslySelectedValues];
                }
            }
        }

        if ((_previouslySelectedValues || []).length) {
            _selectedValues = $.extend(true, [], _previouslySelectedValues);
        }

        render(filterText, showDeleteButton);
        bindEvents();
    }

    export function IsVisible(): boolean {
        return _$win && _$win.is(':visible');
    }

    export function GetIndividualData(element: Model.Elements.Element, filter?: string): Array<any> {
        const type = Utils.GetIndividualDataTypeOfElement(element);

        if (!type) {
            return null;
        }

        const schema = DAL.Schemas.GetByType(type);

        if (!schema) {
            return null;
        }

        const items = DAL.IndividualData.GetByType(type) || [];
        items.sort(Utils.SortByTitle);

        if (filter == null) {
            return items;
        }

        return filterItems(items, schema, filter)
    }
}
