//imports-start
/// <reference path="./schemas.ts"  />
/// <reference path="./individual-data.ts"  />
/// <reference path="./files.ts"  />
/// <reference path="./elements.ts"  />
/// <reference path="./users.ts"  />
/// <reference path="./sync.ts"  />
/// <reference path="./scancodeinfos.ts"  />
//// <reference path="../model/model.resubmissionitem-collection.ts"  />
//// <reference path="../model/elements/tree-meta-data.ts" />
/// <reference path="../utils/utils.synchronisation.download.ts"  />
//// <reference path="../model/model.logger.ts"  />
//imports-end

module DAL {
    export function GetCurrentUser(): Deferred {
        if (Session.IsSmartDeviceApplication) {
            return GetSmartDeviceUser();
        } else {
            return GetWebUser();
        }
    }

    export function GetSmartDeviceUser(): Deferred {
        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.SystemData)
            .then(function(systemData: Array<any>) {
                if (!systemData || !systemData.length) {
                    return $.Deferred().reject().promise();
                }

                // Ersten Account mit gültiger ClientOID übernehmen
                // Vorgekommen ist es, dass weitere Einträge mit ClientOID=null existieren können
                for (const storedAccount of systemData) {
                    if (!storedAccount || !storedAccount.Client ||
                        !storedAccount.Client.OID) {
                        continue;
                    }

                    Session.RestoreSystemData(storedAccount);
                    return $.Deferred().resolve(storedAccount, storedAccount.LastKnownAPIVersion || 1);
                }

                return $.Deferred().reject().promise();
            });
    }

    export function GetWebUser(): Deferred {
        if (Utils.Cookies.GetActiveSessionUser() == null) {
            // not logged in
            return $.Deferred().reject();
        }

        // Beim Login den Heartbeat-Request erzwingen, um ggf, geänderte CSRF-Einstellungen festzustellen
        return Session.RefreshServerSession(true)
            .then(() => {
                return Session.Start()
                    .then((data, result, xhr) => {
                        const apiVersion: number = parseInt(Utils.Http.GetResponseHeader(xhr, 'API-Version', '1'), 10);

                        Utils.SetApiVersion(apiVersion);

                        return $.Deferred().resolve(data, apiVersion);
                    }, (xhr: XMLHttpRequest) => {
                        if (!xhr) {
                            return;
                        }

                        Utils.OnLoginError(xhr, $.noop);
                    });
            });
    }

    export function Clear(): void {
        DAL.Elements.Clear();
        DAL.Issues.Clear();
        DAL.TreeCache.Global.Clear();
        DAL.Files.Clear();
        DAL.Users.Clear();
        DAL.Teams.Clear();
        DAL.Roles.Clear();
        DAL.Properties.Clear();
        DAL.Scheduling.Clear();
        DAL.Contacts.Clear();
        DAL.ContactGroups.Clear();
        DAL.Schemas.Clear();
        DAL.IndividualData.Clear();
        DAL.Cache.Clear();
        Menu.Reset();
        DAL.ScancodeInfos.Clear();
    };
}
