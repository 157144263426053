//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../utils/utils.spinner.ts"  />
/// <reference path="./app.session.ts"  />
/// <reference path="../enums.ts"  />
/// <reference path="./app.parameter-list.ts"  />
/// <reference path="./app.menu.ts"  />
/// <reference path="../model/model.clearable-input.ts"  />
/// <reference path="../utils/utils.password-editor.ts"  />
/// <reference path="../utils/utils.cookies.ts"  />
/// <reference path="../utils/utils.scan.ts"  />
/// <reference path="./app.sync-center.ts"  />
/// <reference path="../utils/utils.analytics.ts"  />
/// <reference path="../model/model.settings.ts" />
/// <reference path="../new/login/login.app.ts" />
/// <reference path="../new/login/login.web.ts" />
//imports-end

module Login {
    let _rootElementMissing: boolean = false;
    let _syncFromServer: boolean;
    let _isNewUser: boolean = false;

    function clearCurrentAccount() {
        let deferred = $.Deferred();
        Utils.Spinner.UpdateText(i18next.t('Login.DeletingOldData.MessageBody'));

        setTimeout(function() {
            window.Database
                .ClearCorporateStructureRelatedData()
                .then(function() {
                    Session.SynchronisationInformation.Clear();

                    Menu.Reset();

                    _isNewUser = true;
                    _syncFromServer = true;

                    Utils.Spinner.UpdateText(i18next.t('Spinner.DefaultText'));
                })
                .then(deferred.resolve, deferred.reject);
        }, 10);
        return deferred.promise();
    }

    export function ApplyWebAccount(account: UserAccount, syncFromServer: boolean, apiVersion: number): Deferred {
        updateSessionData(account, syncFromServer, apiVersion);

        // preserve analytics settings
        const useAnalyticsFromLogin = Session.Settings.UseGoogleAnalytics;
        const useAdvancedAnalyticsFromLogin = Session.Settings.UseAdvancedAnalyticsInformation;

        // Diese Prüfung auf Cookies kann zum Juli 2023 entfernt werden
        const areSettingsCookiesCleaned = localStorage && Boolean(localStorage.getItem('legacySettingsCookiesRemoved'));
        if (!areSettingsCookiesCleaned) {
            // Legacy: persönliche Einstellungen werden vom Server geladen und müssen
            // (auf Grund der Größe) nicht mehr in Cookies gesichert werden.
            const allCookies = Utils.Cookies.GetAll();
            for (const key in allCookies) {
                // nach Cookies mit GUID als key filtern (=> UserOID)
                if (allCookies.hasOwnProperty(key) && Utils.IsValidGuid(key)) {
                    // bestehende Session Cookies aus alten Versionen für alle Benutzer entfernen
                    Utils.Cookies.Delete(key);
                }
            }

            // Bereinigung der Cookies merken
            if (localStorage) {
                localStorage.setItem('legacySettingsCookiesRemoved', String(true));
            }
        }

        // Standard Settings Objekt erzeugen
        // (wird bei Sync mit persönliche Einstellungen vom Server gefüllt)
        Session.Settings = new Model.Settings();

        if (useAnalyticsFromLogin != null) {
            Session.Settings.UseGoogleAnalytics = useAnalyticsFromLogin;
            Settings.MarkChanged(Enums.UserSettings.UseGoogleAnalytics);
        }
        if (useAdvancedAnalyticsFromLogin != null) {
            Session.Settings.UseAdvancedAnalyticsInformation = useAdvancedAnalyticsFromLogin;
            Settings.MarkChanged(Enums.UserSettings.UseAdvancedAnalyticsInformation)
        }

        return updateLanguage();
    }

    export function ApplyAppAccount(account: UserAccount, syncFromServer: boolean, apiVersion: number, needResync: boolean): Deferred {
        if (Session.Client && Session.Client.OID !== account.Client.OID) {
            return $.Deferred().reject().promise();
        }

        updateSessionData(account, syncFromServer, apiVersion);
        let progressDeferred = Session.SaveSystemData();

        // remove data from oder user if required
        if (needResync) {
            SetIsNewUser(true);
            progressDeferred = progressDeferred.then($.proxy(clearCurrentAccount, this))
        }

        return progressDeferred
            .then(() => {
                let useAnalyticsFromLogin = Session.Settings.UseGoogleAnalytics;
                let useAdvancedAnalyticsFromLogin = Session.Settings.UseAdvancedAnalyticsInformation;
                Session.Settings = new Model.Settings(account.Settings);

                if (useAnalyticsFromLogin != null) {
                    Session.Settings.UseGoogleAnalytics = useAnalyticsFromLogin;
                    Settings.MarkChanged(Enums.UserSettings.UseGoogleAnalytics);
                }
                if (useAdvancedAnalyticsFromLogin != null) {
                    Session.Settings.UseAdvancedAnalyticsInformation = useAdvancedAnalyticsFromLogin;
                    Settings.MarkChanged(Enums.UserSettings.UseAdvancedAnalyticsInformation)
                }

                New.Analytics.ToggleGoogleAnalytics(Session.Settings.UseGoogleAnalytics);

                CodeScanner.Init();

                // TODO add to login class
                account.User.AuthHash = Session.AuthHash;
                account.Settings = Session.Settings;

                // jegliche andere SystemData vorher entfernen um fehlerhaft, mehrfache Einträge zu vermeiden
                return window.Database.ClearStorage(Enums.DatabaseStorage.SystemData)
                    .then(null, () => { })
                    .then(() => window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.SystemData, account))
                    .then(() => {
                        Utils.PushNotifications.Init();
                        return updateLanguage();
                    });
            })
            .then(LoadSynchronisationInformation);
    }

    function updateSessionData(account: UserAccount, syncFromServer: boolean, apiVersion: number) {
        _syncFromServer = syncFromServer;
        Session.User = account.User;
        Session.Client = account.Client;
        Session.IsFirstSyncFinished = account.IsFirstSyncFinished || false;

        Utils.SetApiVersion(apiVersion);
    }

    function updateLanguage() {
        const deferred = $.Deferred();
        i18next.changeLanguage(Session.User.Language, deferred.resolve);
        return deferred.then(() => {
            App.LabelPage();
            // set moment.js locale for format
            moment.locale(Session.User.Language);
        }).promise();
    }

    function onAfterSyncFinished(): Deferred {
        Session.User = new Model.Users.User(Session.User);

        //So früh wie möglich überprüfen! Ansonsten kommt es zum crash, wenn auf Session.CurrentLocation zugegriffen wird
        if (!Utils.Router.GetCurrentFragment().contains('about') && DAL.Elements.Root == null) {
            const title = i18next.t('Login.RootElementMissing.MessageHeader');
            const message: string = Session.IsSmartDeviceApplication ?
                i18next.t('Login.RootElementMissing.MessageBodyApp') :
                i18next.t('Login.RootElementMissing.MessageBodyWeb');

            return Utils.Message.Show(title,
                message,
                {
                    Close: true,
                    OnHide: false
                })
                .then(() => onRetrySynchronisation(true));
        }

        _rootElementMissing = false;
        DAL.Elements.PreparePrioritizedFiles();

        if (!Session.CurrentLocation) {
            Session.CurrentLocation = !!Session.User.RootElementOID ?
                DAL.Elements.GetByOID(Session.User.RootElementOID) :
                DAL.Elements.Root;
        }

        IssueReport.InitUserFilter();
        IssueReport.InitDeadlineFilter();
        IssueReport.UpdateTeamsAndUsersFilter();
        Utils.Router.Watch();

        if (Session.IsSmartDeviceApplication) {
            if (Session.Settings.SyncAtApplicationStart) {
                SyncCenter.StartSynchronisationImmediately();
            }

            // save first sync finished state
            Session.IsFirstSyncFinished = true;
            Session.SaveSystemData(true);

            App.BindNfcEvents();
        }

        return $.Deferred().resolve().promise();
    }

    function onRetrySynchronisation(clearSyncInfo: boolean): Deferred {
        App.Init.setProgress(0);

        let deferred = $.Deferred().resolve();
        _rootElementMissing = clearSyncInfo;

        // Sync Fortschritt zurücksetzen bei fehlenden Daten
        if (clearSyncInfo) {
            if (Session.IsSmartDeviceApplication) {
                deferred = deferred
                    .then(() => window.Database.ClearStorage(Enums.DatabaseStorage.SynchronisationInformation));
            }

            deferred = deferred
                .then(() => Session.SynchronisationInformation.Clear())
        }

        // verhindert das Neuladen der Daten aus der Datenbank, welche bereits im Speicher sind
        _syncFromServer = true;

        return deferred
            .then(InitData);
    }

    export function InitUser(): Deferred {
        let initDeferred = App.Init.moveSpinner(App.Init.Enums.SpinnerPosition.Center);

        if (!Session.IsSmartDeviceApplication) {
            initDeferred = initDeferred
                .then(Login.ShowCookieMessage)
                .then(() => new WebLogin().Start())
                .then($.proxy(Login.ApplyWebAccount, Login));
        } else {
            initDeferred = initDeferred
                .then(() => new AppLogin().Start())
                .then($.proxy(Login.ApplyAppAccount, Login));
        }

        return initDeferred
            .then(App.Init.enableLogin).promise();
    }

    export function InitData(): Deferred {
        return App.Init.moveSpinner(App.Init.Enums.SpinnerPosition.Right)
            .then(() => Login.beginSync())
            .then(App.Init.enableDownload);
    }

    function onSyncError(error: Model.Errors.HttpError): Deferred {
        let resyncDeferred: Deferred = Session.IsSmartDeviceApplication ?
            handleSmartDeviceLoginError(error) :
            handleWebLoginError(error);

        return resyncDeferred
            .then((clearSyncInfo: boolean) => onRetrySynchronisation(clearSyncInfo));
    }

    function handleSmartDeviceLoginError(error: Model.Errors.HttpError): Deferred {
        let lastKnownHttpError: Model.Synchronisation.HttpError;

        if (error) {
            // Fehlermeldung loggen
            SyncCenter.SaveError(error);

            if ((<Model.Errors.HttpError>error).httpResponse) {
                const response = (<Model.Errors.HttpError>error).httpResponse;
                const httpStatusCode = response.status;

                const requestType = response.type ? <Enums.HttpMethod>response.type.toUpperCase() : null;

                lastKnownHttpError = new Model.Synchronisation.HttpError(requestType,
                    <number>httpStatusCode,
                    error ? error.message : null,
                    null, null);
            }
        }

        const deferred: Deferred = $.Deferred();

        let isHttpConnectionError = false;
        let extraErrorInfo: string;
        if (error && error.hasOwnProperty('httpResponse') &&
            error.httpResponse.hasOwnProperty('status') &&
            error.httpResponse.status <= 0) {
            extraErrorInfo = SyncCenter.GetStatusCodeMessage(error.httpResponse.status);
            isHttpConnectionError = true;
        }

        const buttonSettings: Utils.Message.OptionButtons = {
            OK: {
                Fn: () => { deferred.resolve(!isHttpConnectionError) },
                Caption: isHttpConnectionError ?
                    i18next.t('Synchronization.Retry') :
                    i18next.t('Synchronization.ReSynchronize')
            },
            SendEMail: function() {
                Utils.SupportMail.Show(lastKnownHttpError)
                    .always(function() {
                        // Nach dem Support-Mail Dialog zurück zum Fehler Dialog zurückkehren
                        handleSmartDeviceLoginError(error)
                            .then(deferred.resolve, deferred.reject);
                    });
            },
            OnHide: false
        };

        let title: string = null;
        let message: string = null;

        if (error && error.httpResponse.status === Enums.HttpStatusCode.Forbidden) {
            title = i18next.t('HttpError.403.Title');
            message = i18next.t('HttpError.403.Message');
        } else {
            title = i18next.t('Synchronization.ErrorHeader');
            message = i18next.t('Synchronization.ErrorMessage');

            if (extraErrorInfo) {
                message += '<br/>' + extraErrorInfo;
            }
        }

        Utils.Message.Show(title,
            message,
            buttonSettings
        );

        return deferred;
    }

    function handleWebLoginError(error: Model.Errors.HttpError): Deferred {
        let title: string = null;
        let message: string = null;

        if (error && error.httpResponse.status === Enums.HttpStatusCode.Forbidden) {
            title = i18next.t('HttpError.403.Title');
            message = i18next.t('HttpError.403.Message');
        } else {
            title = i18next.t('WebRequests.GenericError.ErrorHeader');
            message = i18next.t('WebRequests.GenericError.ErrorMessage');
        }

        return Utils.Message.Show(title,
            message, {
                OK: {
                    Caption: i18next.t('WebRequests.GenericError.Reload'),
                },
                OnHide: false
            });
    }

    export function LoadSynchronisationInformation(updateSession: boolean = true): Deferred {
        /*
            load all SyncInfo data
        */
        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.SynchronisationInformation)
            .then(function(syncInfos: any[]) {
                const responseList = new Model.Synchronisation.ResponseInformationList();
                if ((syncInfos || []).length) {
                    for (let iCnt = 0, iLen = syncInfos.length; iCnt < iLen; iCnt++) {
                        const syncInfo = syncInfos[iCnt];
                        const info = Model.Synchronisation.ResponseInformationFactory.Create(syncInfo);
                        responseList.AddInfo(info, false);
                    }

                    if (updateSession) {
                        for (let type in responseList.Information) {
                            const lastInfo = Session.SynchronisationInformation.GetByType(type);
                            const syncInfo = responseList.Information[type];
                            if (!lastInfo || lastInfo.ResponseDate < syncInfo.ResponseDate) {
                                Session.SynchronisationInformation.AddInfo(syncInfo);
                            }
                        }
                    }
                }

                return $.Deferred().resolve(responseList.Information);
            });
    }

    export function beginSync(): Deferred {
        const deferred = $.Deferred();
        App.Init.enableProgress(true, 0);
        DAL.Sync.Start(deferred.resolve, deferred.reject, true, _syncFromServer || _rootElementMissing, true);
        return deferred.then(onAfterSyncFinished, onSyncError);
    }

    export function ShowCookieMessage(): Deferred {
        const cookieDeferred = $.Deferred();

        if (!(Session.IsSmartDeviceApplication || window.cordova) && Utils.Cookies.Get('cookieaccepted') != 'true') {
            Utils.Message.Show(
                i18next.t('Login.CookieNote.Title'),
                i18next.t('Login.CookieNote.Description'),
                {
                    OK: function() {
                        Utils.Cookies.Set('cookieaccepted', true);
                        cookieDeferred.resolve();
                    },
                    OnHide: false
                }
            );
        } else {
            cookieDeferred.resolve();
        }

        return cookieDeferred.promise();
    }

    export function GetIsNewUser(): boolean {
        return _isNewUser;
    }

    export function SetIsNewUser(flag: boolean): void {
        _isNewUser = flag;
    }
}
