//imports-start
//// <reference path="../definitions.d.ts"  />
/// <reference path="../model/roles/role.ts"  />
//imports-end

module DAL.Roles {
    let _roles: Dictionary<Model.Roles.Role>;

    function prepareRole(role: Model.Roles.Role): Model.Roles.Role {
        role.Title = Utils.EscapeHTMLEntities(role.Title);

        if (!!role.Description) {
            role.Description = Utils.EscapeHTMLEntities(role.Description);
        }

        return role;
    }

    export function Store(syncedRoles: Array<Model.Roles.Role>): void {
        if (!_roles) {
            _roles = {};
        }

        if (!(syncedRoles || []).length) {
            return;
        }

        for (let rCnt = 0, rLen = syncedRoles.length; rCnt < rLen; rCnt++) {
            const role = prepareRole(syncedRoles[rCnt]);

            if (!role.Deleted) {
                _roles[role.OID] = role;
            } else {
                delete _roles[role.OID];
            }
        }
    }

    export function Exists(oid: string): boolean {
        return _roles && !!oid && _roles.hasOwnProperty(oid);
    }

    export function GetByOID(oid: string): Model.Roles.Role {
        if (!Exists(oid)) {
            return;
        }

        return _roles[oid];
    }

    export function GetRightsByUserOID(userIdentifier: string, location?: any): Array<Model.Roles.Right> {
        let teams = DAL.Teams.GetByUserOID(userIdentifier);

        if (location) {
            teams = filterTeamsByLocation(teams, location);
        }

        if (!(teams || []).length) {
            return null;
        }

        const rights = getRightsFromTeams(teams, userIdentifier);

        return rights.length ? rights : null;
    }

    function filterTeamsByLocation(teams: Array<any>, location: any): Array<any> {
        if (!(teams || []).length || !location) {
            return;
        }

        const tmpTeams = [];

        while (location) {
            (location.Teams || []).forEach(team => {
                if (Utils.InArray(tmpTeams, team.OID)) {
                    return;
                }

                tmpTeams.push(team.OID);
            });

            location = location.Parent;
        }

        if (!tmpTeams.length) {
            return;
        }

        for (let tCnt = teams.length - 1; tCnt >= 0; tCnt--) {
            if (!Utils.InArray(tmpTeams, teams[tCnt].OID)) {
                teams.splice(tCnt, 1);
            }
        }

        return teams.length ? teams : null;
    }

    function getRightsFromTeams(teams: Array<any>, userIdentifier: string): Array<Model.Roles.Right> {
        if (!(teams || []).length ||
            !userIdentifier) {
            return;
        }

        const rights = [];

        teams.forEach(team => {
            if (!(team.Users || []).length) {
                return;
            }

            team.Users.forEach(teamMember => {
                if (teamMember.OID !== userIdentifier ||
                    !(teamMember.Roles || []).length) {
                    return;
                }

                teamMember.Roles.forEach(identifier => {
                    const role = GetByOID(identifier);

                    if (!role || !(role.Rights || []).length) {
                        return;
                    }

                    role.Rights.forEach(right => {
                        if (Utils.Where(rights, 'ID', '===', right.ID)) {
                            return;
                        }

                        rights.push(right);
                    });
                });
            });
        });

        return rights;
    }

    export function Clear(): void {
        _roles = null;
    }
}