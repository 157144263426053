//imports-start
/// <reference path="../issues/issue.ts"  />
//imports-end

module Model.Calendar {
    export class Request {
        LoadCounters: boolean = true;
        IgnoreSchedules: boolean;
        LocationOID: string;
        SelectedDate: string;
        DeadlinePeriod: { PeriodStart: string, PeriodEnd: string };

        SetIgnoreSchedules(flag: boolean): this {
            this.IgnoreSchedules = flag || false;

            return this;
        }

        SetLoadCounters(flag: boolean): this {
            this.LoadCounters = flag || false;

            return this;
        }

        SetLocationOID(identifier: string): this {
            if (!identifier) {
                identifier = null;
            }

            this.LocationOID = identifier;

            return this;
        }

        SetSelectedDate(date: string | Date): this {
            this.SelectedDate = Utils.DateTime.ToGMTString(date);

            return this;
        }

        SetDeadlinePeriod(start: string | Date, end: string | Date): this {
            this.DeadlinePeriod = {
                PeriodStart: Utils.DateTime.ToGMTString(start),
                PeriodEnd: Utils.DateTime.ToGMTString(end)
            };

            return this;
        }
    }
}
