//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../app/app.session.ts"  />
/// <reference path="../templates.d.ts"  />
//imports-end

module Utils.InputWindow {
    let _$win, _$overlay;
    let _$body = $('body');
    let _$input;

    let _$btnCustom, _$btnAbort, _$btnOK;
    let _isRequired: boolean = false;

    export type InputButtonSetting = {
        Custom?: {
            Fn?: Function,
            Caption?: string,
            Classes?: string[]
        },
        Abort?: {
            Fn?: Function,
            Caption?: string,
            Classes?: string[]
        },
        OK?: {
            Fn?: Function,
            Caption?: string,
            Classes?: string[]
        }
    };

    function prepareButtons(buttonSettings: InputButtonSetting): void {
        if (buttonSettings.Custom) {
            _$btnCustom.removeClass('hidden');

            if (buttonSettings.Custom.Fn) {
                _$btnCustom.on('click.closeWindow', InputWindow.Hide).on('click', buttonSettings.Custom.Fn);
            }

            if (!!buttonSettings.Custom.Caption) {
                _$btnCustom.html(buttonSettings.Custom.Caption);
            }

            if ((buttonSettings.Custom.Classes || []).length) {
                _$btnCustom.attr('class', buttonSettings.Custom.Classes.join(' '));
            }
        }

        if (buttonSettings.Abort) {
            _$btnAbort.removeClass('hidden');

            if (buttonSettings.Abort.Fn) {
                _$btnAbort.on('click.closeWindow', InputWindow.Hide).on('click', buttonSettings.Abort.Fn);
            } else {
                _$btnAbort.on('click', InputWindow.Hide);
            }

            if (!!buttonSettings.Abort.Caption) {
                _$btnAbort.text(buttonSettings.Abort.Caption);
            }

            if ((buttonSettings.Abort.Classes || []).length) {
                _$btnAbort.attr('class', buttonSettings.Abort.Classes.join(' '));
            }
        }

        if (buttonSettings.OK) {
            _$btnOK.removeClass('hidden');

            if (buttonSettings.OK.Fn) {
                if (_isRequired && !$.trim(_$input.val())) {
                    _$btnOK.addClass('disabled');
                }

                _$btnOK
                    .on('click', function() {
                        const value = $.trim(_$input.val());

                        if (_isRequired && !value) {
                            _$input.focus();
                            _$input.addClass('error');
                            return;
                        }

                        const inputFields = _$input.parent().find('input'); // optional
                        buttonSettings.OK.Fn(value, inputFields);

                        InputWindow.Hide();
                    });

                if (_isRequired) {
                    _$input.on('keyup change', function() {
                        const value = $.trim(_$input.val());

                        $(this).toggleClass('error', _isRequired && !value);

                        if (_$btnOK) {
                            _$btnOK.toggleClass('disabled', !value);
                        }
                    });
                }

                _$input.on('keypress', function(evt) {
                    const value = $.trim(_$input.val());

                    if (!$(this).hasClass('textarea') &&
                        evt.keyCode === Enums.KeyCode.RETURN) {

                        if (_isRequired && !value) {
                            _$input.focus();
                            _$input.addClass('error');
                            return;
                        }

                        buttonSettings.OK.Fn($.trim(_$input.val()));
                        InputWindow.Hide();
                    }
                });
            } else {
                _$btnOK.on('click', InputWindow.Hide);
            }

            if (!!buttonSettings.OK.Caption) {
                _$btnOK.text(buttonSettings.OK.Caption);
            }

            if ((buttonSettings.OK.Classes || []).length) {
                _$btnOK.attr('class', buttonSettings.OK.Classes.join(' '));
            }
        }
    }

    function getInput(type: string, defaultValue: string, paramDefaults?: { Param: boolean, Group: boolean }) {
        if (type === 'textarea') {
            return new Handlebars.SafeString(Templates.InputWindow.Textarea({ DefaultValue: defaultValue }));
        } else if (type === 'parameter') {
            return new Handlebars.SafeString(Templates.InputWindow.InputTypeSelect({
                Type: type,
                DefaultValue: defaultValue,
                ParamChecked: (paramDefaults || { Param: true }).Param,
                GroupChecked: (paramDefaults || { Group: false }).Group
            }));
        }

        return new Handlebars.SafeString(Templates.InputWindow.Input({
            Type: type,
            DefaultValue: Utils.EscapeHTMLEntities(defaultValue)
        }));
    }

    export function Show(
        title: string,
        message: string,
        buttonSettings: InputButtonSetting,
        defaultValue: string,
        inputType: string,
        customOverlayZIndex?: number,
        paramDefaults?: { Param: boolean, Group: boolean },
        isRequired: boolean = false,
        maxInputLength?: number): void {
        _$body.addClass('modal-open');

        if ($('#message').length) {
            if (Utils.Message.IsVisible()) {
                // Falls das Message Fenster offen ist, das Eingabefeld in die Ausführung einreihen
                Utils.Message.GetQueueDeferred()
                    .then(function() {
                        Show(title, message, buttonSettings, defaultValue, inputType, customOverlayZIndex, paramDefaults, isRequired, maxInputLength);
                    });
            }
            return;
        }

        initWindow(title, message, defaultValue, inputType, customOverlayZIndex, paramDefaults, isRequired);

        _$win.find('.footer .btn:not(.btn-close)').addClass('hidden');

        if (buttonSettings) {
            prepareButtons(buttonSettings);
        }

        _$body.append(_$win);

        if (customOverlayZIndex) {
            _$win.css('z-index', customOverlayZIndex + 1);
        }

        _$win.on('keydown', function(evt: Event) {
            // verhindert, dass Cursor Events bei Texteingabe auf darüberliegende Elemente
            // weitergereicht werden und so ungewolltes verhalten produzieren
            evt.stopPropagation();
        });

        _$input.attr('autofocus', 'autofocus').focus();

        if (defaultValue && _$input.get(0).setSelectionRange) {
            _$input.get(0).setSelectionRange(defaultValue.length, defaultValue.length);
        }

        if (maxInputLength) {
            _$input.attr('maxlength', maxInputLength);
        }

        _$input
            .attr('autofocus', 'autofocus')
            .css({
                'height': _$input.get(0).scrollHeight,
                'overflow-y': 'hidden'
            })
            .on('input', ResizeTextArea);

        Utils.RepositionNewModalWindow(_$win);

        setTimeout(() => {
            _$input.focus();
        }, 10);
    }

    function initWindow(title: string, message: string, defaultValue: string,
        inputType: string, customOverlayZIndex?: number, paramDefaults?: { Param: boolean, Group: boolean },
        isRequired: boolean = false): void {
        _$overlay = Utils.Overlay.Generate('olInputOverlay', customOverlayZIndex || 1050);
        _$win = $(Templates.InputWindow.Window({
            Header: title || i18next.t('Misc.DefaultMessageWindowTitle'),
            Message: message,
            InputField: getInput(inputType, Utils.UnescapeHTMLEntities(defaultValue), paramDefaults)
        }));

        _isRequired = isRequired;
        _$input = _$win.find('.input');
        _$btnCustom = _$win.find('.btn-custom');
        _$btnAbort = _$win.find('.btn-abort');
        _$btnOK = _$win.find('.btn-ok');
    }

    export function ResizeTextArea(): void {
        const $textarea = _$win.find('textarea');

        if (!$textarea.length) {
            return;
        }

        const $mainContent = _$win.find('.main-content');
        const winContentMaxHeight = parseInt($mainContent.css('max-height'), 10);
        const usedSpace = getMainContentHeightWithoutTextarea();

        $textarea.css('height', 'auto');
        $textarea.css({
            height: $textarea.get(0).scrollHeight,
            'max-height': winContentMaxHeight - usedSpace
        });

        Utils.RepositionNewModalWindow(_$win);
    }

    function getMainContentHeightWithoutTextarea() {
        const $mainContent = _$win.find('.main-content');
        let height = parseInt($mainContent.css('padding-top'), 10) +
            parseInt($mainContent.css('padding-bottom'), 10);

        $mainContent.children(':not(textarea)').each(function(_idx: number, child: HTMLElement) {
            var $child = $(child);

            height += $child.outerHeight(true);
        });

        return height;
    }

    export function IsVisible(): boolean {
        return _$win && _$win.css('display') !== 'none';
    }

    export function Hide(): void {
        if (_$win) {
            _$win.remove();
            _$win = null;
        }

        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$btnAbort = null;
        }

        _$btnCustom = null;
        _$btnOK = null;
        _$input = null;

        if (!Utils.ActionWindow.IsVisible() &&
            !Utils.RecorditemEditor.IsVisible() &&
            !Utils.IssueViewer.IsVisible()) {
            _$body.removeClass('modal-open');
        }
    }
}