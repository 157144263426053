//imports-start
/// <reference path="./utils.base-filter-window.ts"  />
/// <reference path="../utils.element-picker-popup.ts"  />
/// <reference path="../../model/filter-window/group.ts"  />
/// <reference path="../../model/filter-window/button-item.ts"  />
/// <reference path="../../model/selection-window/filter.ts"  />
//imports-end

module Utils.FilterWindow {
    export class FormFilterWindow extends BaseFilterWindow<Model.SelectionWindow.Filter>{
        constructor(predefinedFilter?: Model.SelectionWindow.Filter, onSave?: Function, zIndex?: number) {
            super(predefinedFilter, onSave, zIndex);
        }

        protected getAvailableFilterItems(): Array<Model.FilterWindow.Group> {
            return [new Model.FilterWindow.Group(
                i18next.t('FilterWindow.Base.Properties'),
                1,
                [
                    new Model.FilterWindow.ButtonItem(
                        Enums.FilterWindow.Property.Keywords,
                        i18next.t('Misc.Keyword_plural'),
                        ((this.filters || { Keywords: [] }).Keywords || []).length ? 'red' : null,
                        this.onBtnKeywordFilterClick,
                        'price-tags')
                ]
            )];
        }

        private onBtnKeywordFilterClick(e: Event): void {
            const $btn = $(e.currentTarget);
            Utils.ElementPickerPopup.Show({
                RootItem: DAL.Properties.GetRootByType(Enums.PropertyType.Keyword),
                Items: DAL.Properties.GetByType(Enums.PropertyType.Keyword),
                VisibleObjectTypes: [Enums.PropertyType.Keyword],
                IsNotALocationTree: true,
                ForceExpand: true,
                MultiSelectionAllowed: true,
                SelectedItemIdentifiers: Utils.CloneArray(this.filters.Keywords),
                CannotCollapseRoot: true,
                ShowResetButton: true,
                SearchFieldPlaceholder: i18next.t('SearchfieldPlaceholders.Keywords'),
                WindowTitle: i18next.t('ElementPicker.SelectKeywordFilter'),
                ZIndex: this.zIndex + 50,
                OnConfirmSelection: (result) => {
                    this.filters.Keywords = result.SelectedItems;
                    $btn.toggleClass('red', (this.filters.Keywords || []).length > 0);
                    this.onAfterFilterChanged();
                },
                OnResetButtonClick: () => {
                    this.filters.Keywords = null;
                    $btn.removeClass('red');
                    this.onAfterFilterChanged();
                }
            });
        };
    }
}
