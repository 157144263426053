//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../enums.ts"  />
/// <reference path="./requirement.ts"  />
/// <reference path="../model.recorditem.ts"  />
/// <reference path="../properties/property.ts"  />
/// <reference path="../files/file.ts"  />
/// <reference path="./evaluation.ts"  />
//imports-end

module Model.Elements {
    export class NotificationSettings {
        LocationOID: string;
        RecipientTeams: Array<string>;
        RecipientUsers: Array<string>;
    }
}