//imports-start
/// <reference path="../definitions.d.ts"  />
//// <reference path="../utils/utils.spinner.ts"  />
//// <reference path="./app.session.ts"  />
//// <reference path="./app.individual-data.ts"  />
//// <reference path="../enums.ts"  />
//// <reference path="./app.parameter-list.ts"  />
//// <reference path="./app.menu.ts"  />
//// <reference path="../model/model.clearable-input.ts"  />
/// <reference path="../model/model.recorditem.ts"  />
//// <reference path="../utils/utils.password-editor.ts"  />
/// <reference path="../utils/utils.voice-recorder.ts"  />
//// <reference path="./app.sync-center.ts"  />
//// <reference path="./app.login.ts"  />
/// <reference path="../dal/roles.ts"  />
/// <reference path="../dal/issues.ts"  />
/// <reference path="../model/model.button-group.ts"  />
/// <reference path="../utils/utils.location-information.ts"  />
/// <reference path="../utils/utils.set.ts"  />
//imports-end

module IssueView {
    export let CodeScannerMode: Enums.ScannerMode = Enums.ScannerMode.Recording;

    let $locationPicker = $('.location-picker');
    let $toolbar, $contentHeader;
    let $btnShowCoveringPage, $btnShowIssues, $requiredParametersCounter, $filteredParametersCounter;

    let currentIssue: Model.Issues.Issue;
    let previouslySelectedLocationOID: string;
    let resubSubsampleItems: Array<Model.Issues.ResubmissionItem>;
    let requiredParameters: Array<Model.Elements.Element>;
    let nonRequiredParameters: Array<Model.Elements.Element>;
    let dontAskAgainForFinishing: boolean;
    let recordedParametersCount: Model.Parameters.IParameterCounter;
    let coveringPageElements: Array<Model.Elements.Element>;
    let locallyExistingElements: Array<Model.Elements.Element>;
    let viewUpdateInformation: { Mode: any; Location: any; };

    let $issueInfoSidebar;

    let closeDeferred: Deferred;

    export let OnRequiredParameterCountChanged: (count: number, ignoreElementChange: boolean) => void;
    export let OnBeforeAutosetFollowerState: (states: Array<Model.Properties.Property>, force: boolean) => boolean;
    export let OnBeforeUpdateState: (followState: Model.Properties.Property) => boolean;

    let toolbarQuickFollowStateButtons: Model.ButtonGroup;

    export function GetCloseDeferred(): Deferred {
        return closeDeferred;
    }

    export function UpdateIssue(updatedIssue: Model.Issues.Issue): void {
        const state = DAL.Properties.GetByOID(updatedIssue.StateOID);
        const precedingState = currentIssue ? currentIssue.StateOID : updatedIssue.StateOID;
        const recalculateCheckpoints = updatedIssue.AssignedElementOID !== currentIssue.AssignedElementOID;

        if (!(updatedIssue instanceof Model.Issues.Issue)) {
            updatedIssue = new Model.Issues.Issue(updatedIssue);
        }

        if (state.ClosedState || state.IsDeletedState) {
            Utils.Spinner.Show();

            setTimeout(navigateBackToPreviousMenu, 100);
            return;
        }

        if (!currentIssue.ID && updatedIssue.ID) {
            currentIssue.ID = updatedIssue.ID;
        }

        if (currentIssue.Revision < updatedIssue.Revision) {
            if ((currentIssue.Recorditems || []).length) {
                updatedIssue.Recorditems = currentIssue.Recorditems;
            }

            if (View.CurrentView === Enums.View.Inspection) {
                updatedIssue.Ancestors = currentIssue.Ancestors;
                updatedIssue.Descendants = currentIssue.Descendants;
            }

            currentIssue = updatedIssue;

            if (precedingState !== currentIssue.StateOID) {
                UpdateStatusQuickSelection();
            }
        }

        UpdateIssueInfo(updatedIssue);
        setFooterToolbarButtonsVisibility();
        updateUrlFragmentIfNecessary();

        ParameterList.UpdateCheckpointToolbarsVisibility();

        if (recalculateCheckpoints) {
            ParameterList.Recalculate();
        }
    }

    export function UpdateIssueInfo(updatedIssue: Model.Issues.Issue): void {
        if ($contentHeader) {
            if (!updatedIssue.ID) {
                $contentHeader.find('a').attr('href', `#issue/${updatedIssue.OID}`);

                if ($issueInfoSidebar) {
                    $issueInfoSidebar
                        .find('.edit-issue')
                        .attr('href', `#issue/${updatedIssue.OID}`);
                }
            }

            const abrevForm = i18next.t('Misc.IssueType.Abbreviation.Form');
            const issueID = updatedIssue.ID || 0;
            const issueRevision = updatedIssue.Revision;
            const assignedLocation = DAL.Elements.GetByOID(updatedIssue.AssignedElementOID);
            const locationTitle = (assignedLocation || { Title: i18next.t('Misc.Unknown') }).Title || i18next.t('Misc.Untitled');
            let issueTitle = Utils.UnescapeHTMLEntities(updatedIssue.Title) || i18next.t('Misc.Untitled');

            if (issueTitle.length > 100) {
                issueTitle = issueTitle.substr(0, 100);
            }

            $contentHeader.find('.issue-info').text(`${abrevForm}${issueID}.${issueRevision} - ${issueTitle}`);
            $contentHeader.find('.secondary-row').html(`@ ${locationTitle}`);
        }

        const parameterGroups = currentIssue.Type !== Enums.IssueType.Scheduling ?
            Session.CurrentLocation.Parametergroups :
            null;

        const templateContext = getSidebarTemplateContext(parameterGroups);
        const $issueInformation = $(Templates.IssueInformationSidebar.Information(templateContext));
        const $issueFiles = $(Templates.IssueInformationSidebar.Files(templateContext));

        if (!($issueInfoSidebar instanceof $)) {
            return;
        }

        $issueInfoSidebar.find('.issue-information .content')
            .replaceWith($issueInformation.find('.content'));

        const $existingIssueFiles = $issueInfoSidebar.find('.issue-files');

        $existingIssueFiles.find('.header .title')
            .text($issueFiles.find('.header .title').text());

        $existingIssueFiles.find('.content')
            .replaceWith($issueFiles.find('.content'));

        bindSidebarEvents(templateContext.Rights, templateContext.Issue.Files);

        if (View.CurrentView == Enums.View.Form ||
            View.CurrentView == Enums.View.FormBatchEdit ||
            View.CurrentView == Enums.View.Inspection) {
            showReferenceFormButton();
        }
    }

    export function onIssueStateUpdated(updatedIssue: Model.Issues.Issue): void {
        if ((currentIssue.Recorditems || []).length) {
            updatedIssue.Recorditems = currentIssue.Recorditems;
        }

        if (View.CurrentView === Enums.View.Inspection) {
            updatedIssue.Ancestors = currentIssue.Ancestors;
            updatedIssue.Descendants = currentIssue.Descendants;
        }

        if (!(updatedIssue instanceof Model.Issues.Issue)) {
            updatedIssue = new Model.Issues.Issue(updatedIssue);
        }

        currentIssue = updatedIssue;

        UpdateIssue(updatedIssue);
        UpdateStatusQuickSelection();
    }

    function onStatusQuickSelectionClick(evt: Event): void {
        const $this = $(evt.currentTarget);
        const identifier = $this.data('identifier');

        if (!identifier) {
            return;
        }

        if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            Utils.ShowAppIsReadOnlyDuringSynchronisationMessage();
            return;
        }

        const state = DAL.Properties.GetByOID(identifier);

        // Prüfen ob alles erfasst ist, ansonsten zum 1. nicht erfassten PP scrollen und blinken
        const allRequiredRecorded = !currentIssue.RequiredParameterCount || (currentIssue.CollectedRequiredParameterCount >= currentIssue.RequiredParameterCount);
        const userRoles = Utils.GetUserRoles(currentIssue.AssignedElementOID);
        const mayChangeAllIssueStates = Utils.UserHasRight(Session.User.OID, Enums.Rights.ChangeOrSetIncompleteIssueState, true, currentIssue.AssignedElementOID);

        let mayChangeState = DAL.Properties.IsUserAllowedToChangeState(userRoles, currentIssue.StateOID, allRequiredRecorded, mayChangeAllIssueStates);
        if (mayChangeState) {
            // wenn aktueller Status geändert werden darf, prüfen ob auch der Folgestatus gesezt werden darf
            mayChangeState = DAL.Properties.IsUserAllowedToSetState(userRoles, currentIssue, currentIssue.StateOID, identifier)
        }

        function setState() {
            updateState(currentIssue, state, onIssueStateUpdated);
        }

        if (!mayChangeState) {
            let deferred: Deferred;

            // prüfen welche View aktiv ist und ob Prüfpunkte angezeigt werden
            if (View.CurrentView == Enums.View.Scheduling && Session.Mode !== Enums.Mode.Parameters ||
                View.CurrentView == Enums.View.Inspection && Session.Mode !== Enums.Mode.CoveringPage) {
                // Hinweismeldung anzeigen
                deferred = $.Deferred().reject();
            } else {
                // Nach nicht erfassten, sichtbaren Pflicht-Prüfpunkten in Ansicht suchen
                const unrecordedParams: Model.Elements.Element[] = [];

                for (let i = 0; i < currentIssue.Resubmissionitems.length; i++) {
                    const rsub = currentIssue.Resubmissionitems[i];
                    const element = ParameterList.GetParameter(rsub.ElementOID, rsub.Row);

                    // Notwendigkeit & Sichtbarkeit des PP prüfen
                    if (element && !element.MissingRequirements &&
                        element.Enabled && element.Required &&
                        !element.IsHidden && !element.IsFiltered &&
                        element.Type >= Enums.ElementType.Checkbox &&
                        (!element.LastRecorditem || element.LastRecorditem.Type === Enums.RecorditemType.UNRECORDED || element.LastRecorditem.IsDummy)) {
                        // Element erfassen
                        unrecordedParams.push(element);
                    }
                }

                // Sortieren nach Position
                unrecordedParams.sort(function(a: Model.Elements.Element, b: Model.Elements.Element) {
                    if (a.Parent && b.Parent) {
                        const groupSort = (a.Parent.Position || 0) - (b.Parent.Position || 0);

                        if (groupSort !== 0) {
                            return groupSort;
                        }
                    }

                    const rowSort = (a.Row || 0) - (b.Row || 0);

                    if (rowSort !== 0) {
                        return rowSort;
                    }

                    return (a.Position || 0) - (b.Position || 0);
                });


                if (!unrecordedParams.length) {
                    // Keine sichtbaren, nicht erfassten Prüfpunkte gefunden
                    deferred = $.Deferred().reject();
                } else if (currentIssue.Type == Enums.IssueType.Scheduling) {
                    // ersten, nicht erfassten Prüfpunkt an der aktuellen OE finden
                    const currentLocationOID = Session.CurrentLocation.OID;
                    const firstUnrecordedAtLocation = Utils.FindByPredicate(unrecordedParams,
                        function(element: Model.Elements.Element) {
                            const locationOID = element.Parent ?
                                element.Parent.ParentOID :
                                ParameterList.GetParameter(element.ParentOID, element.Row).ParentOID;

                            return currentLocationOID === locationOID;
                        });

                    // Prüfe nicht erfassten Prüfpunkt am aktuellen Raum
                    if (firstUnrecordedAtLocation) {
                        deferred = $.Deferred().resolve(firstUnrecordedAtLocation);
                    } else {
                        // den ersten Raum wählen mit fehlender Erfassung
                        const firstUnrecorded = unrecordedParams[0];
                        const locationOID = firstUnrecorded.Parent ?
                            firstUnrecorded.Parent.ParentOID :
                            ParameterList.GetParameter(firstUnrecorded.ParentOID, firstUnrecorded.Row).ParentOID;

                        // Prüfen, ob an der aktuellen OE Prüfpunkte fehlen
                        deferred = $.Deferred().resolve(locationOID)
                            .then(App.GetAndSelectLocation)
                            .then(function() {
                                // ParameterList aktualisieren
                                return ParameterList.Show([
                                    Enums.ElementType.FormFooter,
                                    Enums.ElementType.FormHeader,
                                    Enums.ElementType.FormRow,
                                    Enums.ElementType.Parametergroup,
                                    Enums.ElementType.SingletonFormRow
                                ]);
                            })
                            .then(function() {
                                return firstUnrecorded;
                            });
                    }
                } else {
                    // Ersten, nicht erfassten PP im Formular wählen
                    const firstElement = unrecordedParams[0];
                    deferred = $.Deferred().resolve(firstElement);
                }
            }

            deferred.then(function(firstElement: Model.Elements.Element) {
                if (!firstElement) {
                    return $.Deferred().reject();
                }

                const $firstUnrecorded = ParameterList.GetParameterRowFromCache(firstElement.OID, firstElement.Row).$el;

                // evtl. zugeklappte Prüfgruppe zuerst aufklappen
                const plist = $firstUnrecorded.closest('.parameterList')
                const groupRow = plist.prev();
                groupRow.removeClass('collapsed');

                // Hinweis anzeigen
                Utils.Toaster.Show(i18next.t('IssueViewer.IncompleteRecording.Hint'), 1.5, Enums.Toaster.Icon.Info);

                // zum ersten nicht erfassten Pflicht-PP scrollen und hervorheben
                Extensions.ScrollElementInView($firstUnrecorded[0], '#content')
                    .then(() => Extensions.HighlightElement($firstUnrecorded));
            }).then(null, function() {
                if (allRequiredRecorded || mayChangeAllIssueStates) {
                    // Hinweis auf fehlende Berechtigung
                    Utils.Message.Show(i18next.t('IssueViewer.MissingEditRights.MessageHeader'),
                        i18next.t('IssueViewer.MissingEditRights.MessageBody', { NewStatusTitle: state.Title }), {
                            OK: true
                        }, null, 1103);
                } else {
                    // Hinweis, dass unerfassten PP existieren und evtl. gefiltert werden
                    Utils.Message.Show(i18next.t('IssueViewer.IncompleteRecording.MessageHeader'),
                        i18next.t('IssueViewer.IncompleteRecording.MessageBody'), {
                            OK: true
                        }, null, 1103);
                }
            });
        } else if (state.IsDeletedState) {
            Utils.Message.Show(i18next.t('IssueViewer.SetDeletedState.MessageHeader'),
                i18next.t('IssueViewer.SetDeletedState.MessageBody'), {
                    Yes: setState,
                    No: true
                }, null, 1103);
        } else if (state.ClosedState) {
            Utils.Message.Show(i18next.t('IssueViewer.SetClosedState.MessageHeader'),
                i18next.t('IssueViewer.SetClosedState.MessageBody'), {
                    Yes: setState,
                    No: true
                }, null, 1103);
        } else if (state.IsLockedState) {
            Utils.Message.Show(i18next.t('IssueViewer.SetLockedState.MessageHeader'),
                i18next.t('IssueViewer.SetLockedState.MessageBody'),
                {
                    Yes: setState,
                    No: true
                }, null, 1103);
        } else {
            setState();
        }
    }

    function onAfterIssueSaved(tmpRecorditems: Array<Model.Recorditem>, newSubsamples: Array<{ GroupOID: string, Row: number }>): void {
        const resubitemOIDs = $.map(resubSubsampleItems, function(resubItem: Model.Issues.ResubmissionItem) {
            return resubItem.ElementOID;
        });

        currentIssue = DAL.Issues.PrepareIssue(currentIssue);
        currentIssue.Recorditems = tmpRecorditems;

        ParameterList.CreateSubsample(newSubsamples);

        selectRequiredParameters(ParameterList.GetResubmissionTree());

        const subsampleDictionary = {};
        for (let subsample of newSubsamples) {
            if (!subsampleDictionary[subsample.GroupOID]) {
                subsampleDictionary[subsample.GroupOID] = {};
            }

            subsampleDictionary[subsample.GroupOID][subsample.Row] = true;
        }

        const requiredParametersCount = $.map(requiredParameters, (param: Model.Elements.Element) => {
            if (subsampleDictionary[param.ParentOID] && subsampleDictionary[param.ParentOID][param.Row] &&
                Utils.InArray(resubitemOIDs, param.OID)) {
                currentIssue.ParameterCount++;

                if (param.Required) {
                    return param;
                }
            }
        }).length;

        updateParameterCounter(requiredParametersCount);
        UpdateCurrentIssueParameterCounter();
        UpdateStatusQuickSelection();

        Utils.Spinner.Hide();
    }

    function initParameterCounter(elements: Array<Model.Elements.Element>): void {
        if (!(elements || []).length || !(currentIssue.Resubmissionitems || []).length) {
            return;
        }

        const elementsByRevisionOID: Dictionary<Model.Elements.Element> = {};
        const recorditemsByElementOID: Dictionary<Array<Model.Recorditem>> = {};
        const elementsByOID: Dictionary<Model.Elements.Element> = {};

        elements.forEach((element: Model.Elements.Element) => {
            elementsByRevisionOID[element.RevisionOID] = element;
            elementsByOID[element.OID] = element;
        });

        (currentIssue.Recorditems || []).forEach((recorditem: Model.Recorditem) => {
            if (!recorditem || recorditem.IsDummy || !recorditem.ElementOID) {
                return;
            }

            if (recorditemsByElementOID[recorditem.ElementOID]) {
                recorditemsByElementOID[recorditem.ElementOID].push(recorditem);
            } else {
                recorditemsByElementOID[recorditem.ElementOID] = [recorditem];
            }
        });

        currentIssue.ParameterCount = 0;
        currentIssue.RequiredParameterCount = 0;
        currentIssue.CollectedParameterCount = 0;
        currentIssue.CollectedRequiredParameterCount = 0;

        currentIssue.Resubmissionitems.forEach((resubitem: Model.Issues.ResubmissionItem) => {
            const element = elementsByRevisionOID[resubitem.ElementRevisionOID];

            if (!element || element.Type < 100 || !checkRequirements(element, recorditemsByElementOID)) {
                return;
            }

            const parent = elementsByOID[element.ParentOID];

            if (!parent || !checkRequirements(parent, recorditemsByElementOID)) {
                return;
            }

            currentIssue.ParameterCount++;

            if (element.Required) {
                currentIssue.RequiredParameterCount++;
            }

            const recorditem = Utils.Where(currentIssue.Recorditems, 'ResubmissionitemOID', '===', resubitem.OID);

            if (recorditem && !recorditem.IsDummy) {
                currentIssue.CollectedParameterCount++;

                if (element.Required) {
                    currentIssue.CollectedRequiredParameterCount++;
                }
            }
        });
    }

    function checkRequirements(element: Model.Elements.Element, recorditemsByElementOID: Dictionary<Model.Recorditem[]>): boolean {
        if (!element) {
            return false;
        }

        if (!Utils.IsSet(element.Requirements) || !Utils.HasProperties(element.Requirements)) {
            return true;
        }

        switch (element.Requirements.Type) {
            case Enums.ElementRequirementsType.All:
                return element.Requirements.Criteria.every((criterion) =>
                    checkCriterion(recorditemsByElementOID[criterion.ElementOID], criterion.ElementOID));
            case Enums.ElementRequirementsType.Any:
                return element.Requirements.Criteria.some((criterion) =>
                    checkCriterion(recorditemsByElementOID[criterion.ElementOID], criterion.ElementOID));
            case Enums.ElementRequirementsType.None:
                return element.Requirements.Criteria.every((criterion) =>
                    !checkCriterion(recorditemsByElementOID[criterion.ElementOID], criterion.ElementOID));
        }

        return false;
    }

    function checkCriterion(recorditems: Array<Model.Recorditem>, categoryOID: string): boolean {
        if (!(recorditems || []).length) {
            return false;
        }

        return recorditems.some(recorditem => recorditem.CategoryOID === categoryOID);
    }

    function initFormContentHeader(): void {
        const $content = $('#content');
        const $oldHeader = $content.siblings('.content-header');

        $contentHeader = $(Templates.Menus.FormContentHeader({
            Issue: currentIssue
        }));

        $content.before($contentHeader);
        $oldHeader.remove();
    }

    function onAfterResubRevisionsPrepared(rootLocation: Model.Elements.Element, elements?: Dictionary<Model.Elements.Element>): void {
        if (Utils.InArray([Enums.IssueType.Scheduling, Enums.IssueType.Inspection], currentIssue.Type) &&
            !rootLocation) {
            Close(true);

            Utils.Message.Show(i18next.t('IssueView.RootNotFound.MessageHeader'),
                i18next.t('IssueView.RootNotFound.MessageBody'),
                {
                    Close: true
                });

            Utils.Router.PushState('#main');

            return;
        }

        $filteredParametersCounter.parent()
            .off('click')
            .on('click', ParameterList.ShowParameterFilterSelection);

        if (currentIssue.Type === Enums.IssueType.Form ||
            currentIssue.Type === Enums.IssueType.Inspection ||
            currentIssue.Type === Enums.IssueType.Investigation ||
            currentIssue.Type === Enums.IssueType.Survey ||
            View.CurrentView === Enums.View.FormBatchEdit) {
            initFormContentHeader();
        }

        UpdateCurrentIssueParameterCounter();
        initFooterToolbar();

        if (Utils.InArray([Enums.IssueType.Form, Enums.IssueType.Inspection, Enums.IssueType.Investigation, Enums.IssueType.Survey], currentIssue.Type)) {
            const unrecordedRequiredParameterCount = GetUnfinishedParametersCount();
            if (unrecordedRequiredParameterCount) {
                $requiredParametersCounter.data('value', unrecordedRequiredParameterCount).text(i18next.t('IssueView.UnrecordedParametersLeft', {
                    count: unrecordedRequiredParameterCount
                }));

                if (OnRequiredParameterCountChanged) {
                    OnRequiredParameterCountChanged(unrecordedRequiredParameterCount, true);
                }
            } else {
                $requiredParametersCounter.parent().addClass('hidden');
                $requiredParametersCounter.data('value', 0).text('');

                if (OnRequiredParameterCountChanged) {
                    OnRequiredParameterCountChanged(0, true);
                }
            }

            if (Utils.InArray([Enums.IssueType.Form, Enums.IssueType.Investigation, Enums.IssueType.Survey], currentIssue.Type)) {
                Menu.HideBackButton();
                Utils.Spinner.HideWithTimeout();
                return;
            }
        }

        Session.CurrentLocation = rootLocation;
        let selectedLocationOID: string = Session.CurrentLocation.OID;

        if (viewUpdateInformation) {
            const locationOID = viewUpdateInformation.Location.OID;
            Session.CurrentLocation = ParameterList.GetElementRevisionByOID(locationOID);

            if (!Session.CurrentLocation) {
                Session.CurrentLocation = DAL.Elements.GetByOID(locationOID);
            }

            selectedLocationOID = locationOID;

            const issueOID = `${currentIssue.ID || currentIssue.OID}`;
            const mode = viewUpdateInformation.Mode;
            const issueType = currentIssue.Type;
            Utils.Router.PushState(`#issue/${issueOID}/location/${locationOID}/${mode}?type=${issueType}`);
        }

        if (View.CurrentView === Enums.View.Scheduling) {
            ParameterList.PrepareResubmissionTree(currentIssue.Recorditems);
            const unrecordedRequiredParameterCount = checkRequiredParametersForTree();

            if (!viewUpdateInformation && rootLocation) {
                // finde nächsten Raum mit Prüfpunkten
                (function traverse(elem: Model.Elements.Element): boolean {
                    if ((elem.Parametergroups || []).length) {
                        selectedLocationOID = elem.OID;
                        const issueOID = currentIssue.ID || currentIssue.OID;
                        const issueType = currentIssue.Type;
                        Utils.Router.ReplaceState(`issue/${issueOID}/location/${selectedLocationOID}?type=${issueType}`);
                        return false;
                    }

                    if ((elem.Children || []).length) {
                        for (let cCnt = 0, cLen = elem.Children.length; cCnt < cLen; cCnt++) {
                            if (!traverse(elem.Children[cCnt])) {
                                return false;
                            }
                        }
                    }

                    return true;
                })(rootLocation);
            }

            if (unrecordedRequiredParameterCount) {
                $requiredParametersCounter.parent().removeClass('hidden');
                $requiredParametersCounter.data('value', unrecordedRequiredParameterCount).text(i18next.t('IssueView.UnrecordedParametersLeft', {
                    count: unrecordedRequiredParameterCount
                }));
            } else {
                $requiredParametersCounter.parent().addClass('hidden');
                $requiredParametersCounter.data('value', 0).text('');
            }

            UpdateFilteredParameterCounter(true);

            Utils.Spinner.Hide();
        } else if (View.CurrentView === Enums.View.Inspection && !viewUpdateInformation) {
            const issueOID = currentIssue.ID || currentIssue.OID;
            const assignedElementOID = currentIssue.AssignedElementOID;
            Utils.Router.ReplaceState(`issue/${issueOID}/location/${assignedElementOID}/covering-page?type=${currentIssue.Type}`);
        }

        viewUpdateInformation = null;

        if (currentIssue.Type !== Enums.IssueType.Inspection) {
            Menu.ShowBackButton();
        } else {
            Menu.HideBackButton();
        }

        if (Utils.InArray([Enums.View.Inspection, Enums.View.Scheduling], View.CurrentView) && Session.Settings.ShowTreeView) {
            const issueOID = currentIssue.ID || currentIssue.OID;
            if (View.CurrentView === Enums.View.Inspection && $locationPicker.find('.root').length) {
                App.GetTree()
                    .SetRouteBase(`issue/${issueOID}/location`)
                    .SetAnchorSuffix(`?type=${currentIssue.Type}`)
                    .UpdateRoutesByPattern(`issue/${issueOID}/location/{Identifier}?type=${currentIssue.Type}`)
                    .Build();
            } else {
                if (!elements || !Utils.HasProperties(elements)) {
                    elements = DAL.Elements.GetAll();
                    rootLocation = DAL.Elements.Root;
                }

                const tree = new Model.Tree()
                    .SetIdentifier('location-picker')
                    .SetRouteBase(`issue/${issueOID}/location`)
                    .SetAnchorSuffix(`?type=${currentIssue.Type}`)
                    .SetRootCollapsable(false)
                    .SetShowParentTitle(false)
                    .SetShowExpanders(true)
                    .SetEnableAnchors(true)
                    .SetForceExpand(View.CurrentView === Enums.View.Scheduling)
                    .SetKeyProperty('OID')
                    .SetFilter((item: Model.Elements.Element) => item.Type == Enums.ElementType.Root || item.Type == Enums.ElementType.Location)
                    .SetAdditionalClasses(GetClassesForTreeView())
                    .SetItems(elements, rootLocation.OID)
                    .SetActiveItem(selectedLocationOID)
                    .SetRenderingContainer($locationPicker.find('.tree-wrapper'))
                    .SetOnNodeClick(onNavigateToLocation)
                    .SetSearchField(App.CreateOrRecycleSearchInputField(), i18next.t('SearchfieldPlaceholders.MainTree'))
                    .Build()
                    .SetForceExpand(false);

                App.SetTree(tree);
            }

            App.CreateContentHeader();
        }

        Utils.Spinner.Unlock();
        Utils.Spinner.Hide();

        if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            Utils.ShowAppIsReadOnlyDuringSynchronisationMessage();
        }
    }

    function onAfterResubRevisionsLoaded(elements: Array<Model.Elements.Element>, tabularTable: Dictionary<boolean> = {}): Deferred {
        if ((locallyExistingElements || []).length) {
            elements = mergeElementArrays(elements);
        }

        if (!currentIssue) {
            return;
        }

        if ((currentIssue.AssignedFormOID || currentIssue.AssignedSchedulingOID) &&
            !Utils.IsSet(currentIssue.ParameterCount)) {
            initParameterCounter(elements);
        }

        let parameterlistDeferred: Deferred;

        if (currentIssue.Type === Enums.IssueType.Inspection) {
            coveringPageElements = $.extend(true, [], elements);
            onAfterResubRevisionsPrepared(DAL.Elements.Root);
            parameterlistDeferred = $.Deferred().resolve().promise();
        } else {
            parameterlistDeferred = ParameterList.Show(
                [
                    Enums.ElementType.SingletonFormRow,
                    Enums.ElementType.MasterdataGroup,
                    Enums.ElementType.FormHeader,
                    Enums.ElementType.FormFooter,
                    Enums.ElementType.FormRow,
                    Enums.ElementType.Parametergroup
                ],
                elements,
                onAfterResubRevisionsPrepared,
                false,
                tabularTable
            );
        }

        const response = $.Deferred();

        parameterlistDeferred
            .then(() => {
                if (View.CurrentView === Enums.View.Scheduling) {
                    renderIssueInformationSidebar(Session.CurrentLocation.Parametergroups);
                } else {
                    renderIssueInformationSidebar(elements);
                }

                UpdateStatusQuickSelection();

                response.resolve();
            });

        return response.promise();
    }

    function mergeElementArrays(elements: Array<Model.Elements.Element>): Array<Model.Elements.Element> {
        if (!(elements instanceof Array) || elements.length === 0) {
            return locallyExistingElements;
        }

        if (!(locallyExistingElements instanceof Array) || locallyExistingElements.length === 0) {
            return elements;
        }

        const elemDict: Dictionary<Model.Elements.Element> = {};

        locallyExistingElements.forEach(e => elemDict[e.OID] = e);
        elements.forEach(e => {
            if (!elemDict.hasOwnProperty(e.OID)) {
                elemDict[e.OID] = e;
            }
        });

        return Object
            .keys(elemDict)
            .map(k => elemDict[k]);
    }

    function renderIssueInformationSidebar(elements: Array<Model.Elements.Element>): void {
        const templateContext = getSidebarTemplateContext(elements);
        const issueInformation = Templates.IssueInformationSidebar.Sidebar(templateContext);

        if ($issueInfoSidebar instanceof $ && $issueInfoSidebar.length) {
            $issueInfoSidebar.remove();
        }

        const $main = $('#content');

        $main.before(issueInformation);

        $issueInfoSidebar = $('.issue-information-sidebar');

        // LocationPicker swipe für Sidebar einbinden
        if (View.CurrentView == Enums.View.FormBatchEdit ||
            View.CurrentView == Enums.View.Scheduling ||
            View.CurrentView == Enums.View.Inspection) {
            App.EnableSidebarSwipe($issueInfoSidebar.get(0));
        }

        if (View.CurrentView == Enums.View.Scheduling) {
            View.UpdateBackButton();
        }

        updateContentWidth($main);

        templateContext.Chapters.forEach(c => UpdateSidebarIndex(c.OID));

        const $tools = $issueInfoSidebar.find('.tools');

        if ($tools.find('li:not(.hidden)').length > 0) {
            $tools.removeClass('hidden');
        }

        UpdateStatusQuickSelection();

        if (View.CurrentView == Enums.View.Form ||
            View.CurrentView == Enums.View.FormBatchEdit ||
            View.CurrentView == Enums.View.Inspection) {
            showReferenceFormButton();
            showActionPlanPdfButton();
        }

        bindSidebarEvents(templateContext.Rights, templateContext.Issue.Files);
    }

    function updateContentWidth($main) {
        if (!$issueInfoSidebar) {
            return;
        }

        const issueBarWidth = $issueInfoSidebar.outerWidth(true);

        // eq screen-max-sm'
        if ($(document).width() >= 768) {
            $main.css('left', issueBarWidth);
        } else {
            $main.css('left', '');
        }
    }

    function shouldSidebarBeInline(): boolean {
        if (!Session.Settings.SidebarDefaultVisibility) {
            // per Einstellung ausgeblendet
            return false;
        }

        // Sidebar Sichtbarkeit in Vorgängen mit OE-Struktur
        if (Session.Settings.ShowTreeView) {
            if (View.CurrentView == Enums.View.FormBatchEdit ||
                View.CurrentView == Enums.View.Scheduling ||
                View.CurrentView == Enums.View.Inspection) {
                // Sidebar ausblenden
                return false;
            }
        }

        // immer sichtbar anzeigen
        return true;
    }

    function getSidebarTemplateContext(elements: Model.Elements.Element[]): any {
        const organisationUnit = DAL.Elements.GetByOID(currentIssue.AssignedElementOID);
        const responsibilityAssignments = currentIssue.ResponsibilityAssignments || {};
        const users = Object.keys(responsibilityAssignments.Users || {});
        const teams = Object.keys(responsibilityAssignments.Teams || {});
        const chapters = (elements || [])
            .filter(e => e.Type >= 93 && e.Type <= 99)
            .sort(Utils.SortByPosition)
            .map(c => {
                const cachedElement = ParameterList.GetElementsIndependentOfRow(c.OID)[0];

                if (!cachedElement || !(cachedElement.Parameters || []).length) {
                    return null;
                }

                c.MissingRequirements = cachedElement.MissingRequirements;

                return c;
            })
            .filter(e => !!e);

        const now = new Date();
        const issueIsExpired = currentIssue.DeadlineTimestamp &&
            currentIssue.DeadlineTimestamp.getTime() < now.getTime();

        let userCanEditCurrentIssue = Utils.CanUserModifyIssue(currentIssue);
        let userCanEditFiles = userCanEditCurrentIssue &&
            Utils.UserHasIssueRight(currentIssue, Enums.Rights.IssueProperties_CreateOrModifyFiles);

        const files = (currentIssue.Files || []).sort(Utils.SortByPosition);

        if (View.CurrentView === Enums.View.FormBatchEdit) {
            // In FormBatchEdit keine Bearbeitung über Sidebar erlauben
            // In FormBatchEdit sollen Vorgangsinformationen allgemein nicht bearbeitbar sein
            userCanEditCurrentIssue = false;
            userCanEditFiles = false;
        }

        files.forEach(f => {
            f.IsImage = Utils.IsImage(f.MimeType);
            f.IsVideo = Utils.IsVideo(f.MimeType);
            f.IsAudio = Utils.IsAudio(f.MimeType);
        });

        // Standard Sichtbarkeit der Sidebar ermitteln
        const sidebarInlineMode = shouldSidebarBeInline();

        const context: any = {
            BaseUri: Session.BaseURI,
            Issue: {
                ID: currentIssue.ID,
                OID: currentIssue.OID,
                Title: currentIssue.Title,
                Type: currentIssue.Type,
                DeadlineTimestamp: currentIssue.DeadlineTimestamp,
                CreationTimestamp: currentIssue.CreationTimestamp,
                Creator: (DAL.Users.GetByOID(currentIssue.CreatorOID) || { Title: '-/-' }).Title,
                IsExpired: issueIsExpired,
                Users: users,
                Teams: teams,
                Files: files,
            },
            ShowInlineMode: sidebarInlineMode,
            OrganisationUnit: {
                OID: organisationUnit ? organisationUnit.OID : null,
                Title: organisationUnit ? organisationUnit.Title : i18next.t('Misc.Unknown')
            },
            Chapters: chapters,
            Rights: {
                CanEditIssue: userCanEditCurrentIssue,
                CanEditFiles: userCanEditFiles,
                CanSeeOUInformation: organisationUnit ? Utils.IsMenuItemEnabled(Enums.MenuItemID.Information, organisationUnit.OID) : false
            }
        };

        if (!!currentIssue.AssignedFormOID) {
            const form = ParameterList.GetElementRevisionByOID(currentIssue.AssignedFormOID);

            if (form) {
                context.Form = {
                    OID: form.OID,
                    Title: form.Title
                };
            }
        }

        return context;
    }

    function showActionPlanPdfButton() {
        if (!$issueInfoSidebar.length || !$issueInfoSidebar.is(':visible')) {
            return;
        }

        const $actionPlanPdf = $issueInfoSidebar.find('.create-action-plan');

        $actionPlanPdf
            .addClass('hidden');

        if (!Session.Client.Settings.ShowTUEVAuditAnalysis) {
            return;
        }

        const currentIssue = IssueView.GetCurrentIssue();

        if (!Utils.IsSet(currentIssue)) {
            return;
        }

        const form = ParameterList.GetElementRevisionByRevisionOID(currentIssue.AssignedFormRevisionOID);

        if (!Utils.IsSet(form)) {
            return;
        }

        const issueHasKeyword = Utils.InArray(currentIssue.Keywords, PdfActionPlan.AuditKeywordOID) ||
                                Utils.InArray(currentIssue.Keywords, PdfActionPlan.HashKeywordOID) ||
                                Utils.InArray(currentIssue.Keywords, HygieneInspectionActionPlan.HygieneInspectionKeywordOID) ||
                                Utils.InArray(currentIssue.Keywords, TSLActionPlan.TSLKeywordOID);

        const formHasKeyword = Utils.InArray(form.Properties, PdfActionPlan.AuditKeywordOID) ||
                               Utils.InArray(form.Properties, PdfActionPlan.HashKeywordOID) ||
                               Utils.InArray(form.Properties, HygieneInspectionActionPlan.HygieneInspectionKeywordOID) ||
                               Utils.InArray(form.Properties, TSLActionPlan.TSLKeywordOID);

        if (!issueHasKeyword && !formHasKeyword) {
            return;
        }

        $issueInfoSidebar
            .find('.tools')
            .removeClass('hidden');

        $actionPlanPdf
            .removeClass('hidden');
    }

    function showReferenceFormButton() {
        const currentIssue = IssueView.GetCurrentIssue();
        const formRevisionOID = currentIssue.AssignedFormRevisionOID;
        const currentForm = ParameterList.GetElementRevisionByRevisionOID(formRevisionOID);
        const referenceFormOID = (currentForm || { ReferenceFormOID: null }).ReferenceFormOID;

        if (referenceFormOID == null || !Utils.CheckIfDeviceIsOnline()) {
            return;
        }

        // load available data from server (online)
        const issueFilter = new Model.Issues.Filter();
        issueFilter.Types = [Enums.IssueType.Form, Enums.IssueType.Inspection];
        issueFilter.LocationOID = currentIssue.AssignedElementOID;
        issueFilter.Forms = [referenceFormOID];
        issueFilter.WithChildLocations = false;
        issueFilter.Skip = 0;
        issueFilter.Take = 1;
        issueFilter.Sorting = Enums.SortingBy.ID;
        issueFilter.SortingOrder = Enums.SortingOrder.DESC;
        issueFilter.IsArchived = true;
        issueFilter.LoadIssues = true;

        IssueReport.GetReferenceFormIssue(issueFilter)
            .then((data: Array<Model.Issues.Issue>) => {
                if (!data || !data.length) {
                    return;
                }

                // letzten archivierten Vorgang zum Formular nehmen
                const lastIssue = data[0];

                // Prüfen ob Formular stimmt
                if (lastIssue.AssignedFormOID != referenceFormOID &&
                    lastIssue.AssignedElementOID != currentIssue.AssignedElementOID) {
                    return;
                }

                const $formReference = $issueInfoSidebar.find('.form-reference');
                const lastIssueTitle = Utils.UnescapeHTMLEntities(lastIssue.Title);

                $formReference
                    .data('id', lastIssue.ID)
                    .removeClass('hidden')
                    .find('span')
                    .text(`${lastIssue.GetAbbreviation()}${lastIssue.ID} - ${lastIssueTitle}`);
            });
    }

    function bindSidebarEvents(contextRights: { CanEditIssue: boolean, CanEditFiles: boolean, CanSeeOUInformation: boolean }, files: Model.Issues.File[]): void {
        const userCanEditFiles = contextRights.CanEditFiles;
        const userCanEditIssue = contextRights.CanEditIssue;

        $issueInfoSidebar
            .find('.header')
            .off('click')
            .on('click', (evt: Event) => $(evt.currentTarget).parent().toggleClass('collapsed'));

        $issueInfoSidebar
            .find('.organisation-unit')
            .off('click.showOUInformation')
            .on('click.showOUInformation', onShowOrganisationUnitInformationClick);

        $issueInfoSidebar
            .find('.index li[data-chapter]')
            .off('click.scrollToChapter')
            .on('click.scrollToChapter', onSidebarScrollToChapterClick);

        $issueInfoSidebar
            .find('.issue-files li[data-filename]')
            .off('click')
            .on('click', (evt: Event) => {
                evt.stopPropagation();

                const $this = $(evt.currentTarget);
                const filename = $this.data('filename');
                const file = files.filter(f => f.Filename === filename)[0];

                if (!file) {
                    return;
                }

                if (Utils.IsImage(file.MimeType)) {
                    const images = (currentIssue.Files || []).filter(f => f.IsImage);

                    Utils.OpenImages(images, filename);

                    return;
                }

                Utils.OpenFile(filename,
                    false,
                    Utils.InArray(DAL.Files.GetVideoFileExtensions(), Utils.GetFileExtension(filename)),
                    file.Title,
                    file.MimeType
                );
            });

        if (userCanEditFiles) {
            if (Session.IsSmartDeviceApplication) {
                $issueInfoSidebar.find('.add-attachment-file')
                    .off()
                    .on('click', (evt: Event) => evt.stopPropagation())
                    .press(onFileButtonPress, onFileButtonClick, 500);
            } else {
                $issueInfoSidebar.find('input[type="file"]')
                    .off('click')
                    .off('change')
                    .on('click', (evt: Event) => evt.stopPropagation())
                    .on('change', onFileInput);
            }
        }

        $issueInfoSidebar
            .off('click.closeSidebar')
            .on('click.closeSidebar', onSidebarClick);

        $issueInfoSidebar.find('.content, .action-button')
            .off('click.stopPropagation')
            .on('click.stopPropagation', (evt: Event) => evt.stopPropagation());

        if (Session.IsSmartDeviceApplication) {
            if (Session.NfcEnabled && Session.IsRunningOnIOS) {
                // NFC Scan-Button nur für iOS benötigt
                $issueInfoSidebar.find('.scan-nfc')
                    .off('click.scan-nfc')
                    .on('click.scan-nfc', () => App.StartNfcScanner());
            }

            $issueInfoSidebar.find('.scan-barcode')
                .off('click.scan-barcode')
                .on('click.scan-barcode', () => ParameterList.StartCodeScan(Enums.ScannerMode.Recording));

            $issueInfoSidebar.find('.search-barcode')
                .off('click.search-barcode')
                .on('click.search-barcode', () => ParameterList.StartCodeScan(Enums.ScannerMode.Search));
        }

        $issueInfoSidebar.find('.create-action-plan')
            .off('click')
            .on('click', createActionPlanPdf);

        $issueInfoSidebar.find('.form-reference')
            .off('click')
            .on('click', onShowReferenceFormClick);

        $issueInfoSidebar.find('.status-quick-selection .content')
            .off('click.selectStatus')
            .on('click.selectStatus', '.select-status', onStatusQuickSelectionClick);

        if (userCanEditIssue) {
            $issueInfoSidebar
                .find('.users, .teams')
                .off('click.showResponsibilityPicker')
                .on('click.showResponsibilityPicker', onResponsibilitiesInformationClick);
        }
    }

    function onShowReferenceFormClick() {
        onSidebarClick(<any>{ currentTarget: $issueInfoSidebar });
        ParameterList.ReferenceForm.ShowReferenceForm.call(this);
    }

    function onResponsibilitiesInformationClick(evt: Event): void {
        if (evt) {
            evt.stopPropagation();
        }

        const editResponsibilities = Utils.UserHasIssueRight(currentIssue, Enums.Rights.IssueProperties_Responsibilities);
        const editContacts = Utils.IsLicenseAvailable('Contacts', false) && Utils.UserHasIssueRight(currentIssue, Enums.Rights.IssueProperties_Contacts);
        const editContactGroups = Utils.IsLicenseAvailable('Contacts', false) && Utils.UserHasIssueRight(currentIssue, Enums.Rights.IssueProperties_ContactGroups)
            && Session.LastKnownAPIVersion >= 14;

        Utils.ResponsibilitiesPicker.Show({
            AllowUsers: editResponsibilities,
            AllowTeams: editResponsibilities,
            AllowContacts: editContacts,
            AllowContactGroups: editContactGroups,
            FilterIssueResponsibilities: true,
            Issue: currentIssue,
            ShowSystemUsers: false,
            ShowLockedUsers: false,
            ResponsibilityAssignments: currentIssue.ResponsibilityAssignments
        }).then((newResponsibilityAssignments: Model.Issues.ResponsibilityAssignments) => {
            Utils.IssueViewer.SaveNewIssueResponsibilities(currentIssue, newResponsibilityAssignments, (issue: Model.Issues.Issue) => {
                UpdateIssue(issue);
            });
        });
    }

    function onShowOrganisationUnitInformationClick(evt: Event): void {
        if (evt) {
            evt.stopPropagation();
        }

        const identifier = $(evt.currentTarget).data('ou-identifier');

        if (!identifier || Utils.LocationInformation.IsVisible() ||
            !Utils.IsMenuItemEnabled(Enums.MenuItemID.Information, identifier)) {
            return;
        }

        Utils.LocationInformation.Show(identifier);
    }

    function getActionPlanPdf(formProperties: Array<string>, issueKeywords: Array<string>): TSLActionPlan | HygieneInspectionActionPlan | PdfActionPlan {
        const keywords = new Utils.HashSet();

        keywords.putRange(formProperties);
        keywords.putRange(issueKeywords);

        if (keywords.has(TSLActionPlan.TSLKeywordOID)) {
            return new TSLActionPlan(currentIssue, ParameterList.GetResubmissionTree());
        }

        if (keywords.has(HygieneInspectionActionPlan.HygieneInspectionKeywordOID)) {
            return new HygieneInspectionActionPlan(currentIssue, ParameterList.GetResubmissionTree());
        }

        return new PdfActionPlan(currentIssue, ParameterList.GetResubmissionTree());
    }

    function createActionPlanPdf(): void {
        const currentIssue = IssueView.GetCurrentIssue();
        const form = ParameterList.GetElementRevisionByRevisionOID(currentIssue.AssignedFormRevisionOID);
        const actionPlanPdf = getActionPlanPdf(form.Properties, currentIssue.Keywords);

        if (!actionPlanPdf.IsCompleted()) {
            Utils.Message.Show(i18next.t('customer:TUEV.ActionPlan.RecordingNotCompleted.Header'),
                i18next.t('customer:TUEV.ActionPlan.RecordingNotCompleted.Message'),
                { OK: true });
            return;
        }

        if (!actionPlanPdf.ContainsData()) {
            Utils.Message.Show(i18next.t('customer:TUEV.ActionPlan.ActionPlanEmpty.Header'),
                i18next.t('customer:TUEV.ActionPlan.ActionPlanEmpty.Message', { ValidValues: actionPlanPdf.GetValidValues().join(', ') }),
                { OK: true });
            return;
        }

        actionPlanPdf.Create()
            .then(() => {
                actionPlanPdf.SaveAndOpen();
            });
    }

    function onSidebarScrollToChapterClick(evt: Event): void {
        const $listItem = $(evt.currentTarget);
        const id = $listItem.data('chapter');

        if (!id) {
            return;
        }

        const chapter = document.querySelector(`#content .groupRow[data-groupoid="${id}"]`);

        if (!chapter) {
            return;
        }

        $issueInfoSidebar.removeClass('open');

        chapter.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }

    function onSidebarClick(evt: Event): void {
        const $this = $(evt.currentTarget);
        const winWidth = $(window).outerWidth(true);

        if (winWidth >= 992 && $this.hasClass('inline')) {
            return;
        }

        $issueInfoSidebar.removeClass('open');
    }

    export function GetIssueSidebarWidth(): number {
        if (!($issueInfoSidebar instanceof $)) {
            return 0;
        }

        if (!$issueInfoSidebar.is(':visible')) {
            return 0;
        }

        const winWidth = $(window).outerWidth(true);

        if (winWidth <= 991 || !$issueInfoSidebar.hasClass('inline')) {
            // Sidebar liegt als Overlay über dem Content; keine Beeinflussung somit
            return 0;
        }

        return $issueInfoSidebar.outerWidth(true);
    }

    function onNavigateToLocation(): void {
        const locationIdentifier = Session.CurrentLocation.OID;

        if (!locationIdentifier) {
            return;
        }

        const issueOID = currentIssue.ID || currentIssue.OID;
        const currentLocationLink = `#issue/${issueOID}/location/${locationIdentifier}/`;

        if ($btnShowCoveringPage) {
            $btnShowCoveringPage.find('a')
                .attr('href', currentLocationLink + 'covering-page?type=' + Enums.IssueType.Inspection);
        }

        if ($btnShowIssues) {
            $btnShowIssues.find('a')
                .attr('href', currentLocationLink + 'issue-report?type=' + Enums.IssueType.Inspection);
        }

        setFooterToolbarButtonsVisibility();
    }

    function onAfterGotImageForFileNote(filePath: string): void {
        if (Session.Settings.ShowIssueViewerAfterTakingPhotoDuringInspection) {
            const params = {
                ParentIssue: currentIssue,
                ImagePath: filePath,
                InheritResponsibilities: false
            };

            let locationIdentifier = currentIssue.AssignedElementOID;

            if (currentIssue.Type !== Enums.IssueType.Form) {
                locationIdentifier = Session.CurrentLocation.OID;
            }

            if (!Utils.IsUserAbleToSetInitialState(locationIdentifier, Session.Client.Settings.TicketOpened)) {
                Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                    i18next.t('Misc.RightError.DefaultState.MessageBody'),
                    {
                        Close: true
                    });

                return;
            }

            if (currentIssue && currentIssue.AssignedFormRevisionOID) {
                const form = ParameterList.GetElementRevisionByRevisionOID(currentIssue.AssignedFormRevisionOID);
                params.InheritResponsibilities = form && form.AdditionalSettings && form.AdditionalSettings.BequeathResponsibilities;
            }

            Utils.IssueViewer.CreateIssue(
                Enums.IssueType.Note,
                onAfterIssueCreated,
                params
            );
        } else {
            const params = {
                ParentIssue: currentIssue,
                ImagePath: filePath,
                InheritResponsibilities: false
            };

            if (currentIssue && currentIssue.AssignedFormRevisionOID) {
                const form = ParameterList.GetElementRevisionByRevisionOID(currentIssue.AssignedFormRevisionOID);
                params.InheritResponsibilities = form && form.AdditionalSettings && form.AdditionalSettings.BequeathResponsibilities;
            }

            Utils.IssueViewer.CreateFileNote(params, onAfterIssueCreated);
        }
    }

    function onAfterGotImagesForFileNote(filePaths: Array<string>): void {
        if (!(filePaths || []).length) {
            return;
        }

        onAfterGotImageForFileNote(filePaths[0]);
    }

    function onBtnAddVoiceMailClick(): void {
        if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            Utils.ShowAppIsReadOnlyDuringSynchronisationMessage();
            return;
        }

        Utils.VoiceRecorder.Show(onAfterVoiceMailRecorded);
    }

    function onAfterVoiceMailRecorded(voiceMailMetadata: { Filename: string; MimeType: any; }): void {
        const params = {
            ParentIssue: currentIssue,
            VoiceMail: {
                Path: Utils.GetResourcesPath() + voiceMailMetadata.Filename,
                Filename: voiceMailMetadata.Filename,
                MimeType: voiceMailMetadata.MimeType
            },
            InheritResponsibilities: false
        };

        let locationIdentifier = currentIssue.AssignedElementOID;

        if (currentIssue.Type !== Enums.IssueType.Form) {
            locationIdentifier = Session.CurrentLocation.OID;
        }

        if (!Utils.IsUserAbleToSetInitialState(locationIdentifier, Session.Client.Settings.TicketOpened)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.DefaultState.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        if (currentIssue && currentIssue.AssignedFormRevisionOID) {
            const form = ParameterList.GetElementRevisionByRevisionOID(currentIssue.AssignedFormRevisionOID);
            params.InheritResponsibilities = form && form.AdditionalSettings && form.AdditionalSettings.BequeathResponsibilities;
        }

        Utils.IssueViewer.CreateFileNote(params, onAfterIssueCreated);
    }

    function onBtnAddTextClick(): void {
        if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            Utils.ShowAppIsReadOnlyDuringSynchronisationMessage();
            return;
        }

        const params = {
            ParentIssue: currentIssue
        };

        Utils.IssueViewer.CreateIssue(Enums.IssueType.Note, onAfterIssueCreated, params);
    }

    function onAfterIssueCreated(issue: Model.Issues.Issue): void {
        Utils.Spinner.Lock();
        Utils.IssueViewer.IncrementSubIssueCount(GetCurrentIssue())
            .then(function(subIssueCounter: number) {
                UpdateSubIssues(subIssueCounter, [issue]);

                if (Session.Mode === Enums.Mode.IssueReport) {
                    IssueReport.Show('issues', IssueReport.GetDescendantsOfIssue());
                }

                App.UpdateContentHeaderIssueProcessingIndicator();
            })
            .always(() => {
                Utils.Spinner.Unlock();
                Utils.Spinner.Hide();
            });
    }

    function onBtnCloseViewClick(): void {
        Utils.Message.Show(i18next.t('IssueView.CloseView.MessageHeader'),
            i18next.t('IssueView.CloseView.MessageBody'),
            {
                Yes: function() {
                    Utils.ActionWindow.ClearActionWindowQueue();
                    Utils.ActionWindow.CloseActionWindows();

                    navigateBackToPreviousMenu();
                },
                No: true
            });
    }

    export function OnAfterActionWindowClosed(element: Model.Elements.Element): void {
        if (!element) {
            TrySettingStandardFollowState();
            return;
        }

        const stateOfGoingToNextRoom = currentIssue &&
            !Utils.InArray([Enums.IssueType.Form, Enums.IssueType.Inspection], currentIssue.Type) ?
            CheckRequiredParametersAtLocation(Session.CurrentLocation, false, true) :
            GetUnfinishedParametersCount(false) === 0 ?
                Enums.StateOfGoingToNextRoom.AllRequiredCheckpointsAreRecorded :
                Enums.StateOfGoingToNextRoom.GoToNextCheckpoint;

        let elementIdentifier = element.OID;

        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling], View.CurrentView)) {
            elementIdentifier = element.RevisionOID;
        }

        if (stateOfGoingToNextRoom === Enums.StateOfGoingToNextRoom.GoToNextCheckpoint && element) {
            if (Settings.IsAutomaticallyOpenNextParameterSettingActive()) {
                ParameterList.OpenNextAvailableCheckpoint(elementIdentifier, element.Row);
            }
        } else if (stateOfGoingToNextRoom === Enums.StateOfGoingToNextRoom.AllRequiredCheckpointsAreRecorded) {
            if (Session.Settings.AutomaticallyOpenNextCheckpoint === Enums.AutomaticallyOpenNextCheckpoint.Always) {
                ParameterList.OpenNextAvailableCheckpoint(elementIdentifier, element.Row);
            } else {
                TrySettingStandardFollowState();
            }
        }
    }

    function onFileInput(): void {
        if (typeof FileReader === 'undefined') {
            this.value = null;
            return;
        }

        if (!(this.files || []).length) {
            this.value = null;
            return;
        }

        const files = this.files;
        const fileInformation: Array<Utils.FileInformation> = [];

        Utils.LoopDeferredActions(files, (file: Utils.ExtendedFile) => {
            file.Title = Utils.GetFileTitle(file.name, '');

            return Utils.ReadFile(file, uuid())
                .then((fileInfo: Utils.FileInformation) => {
                    if (Utils.IsSet(fileInfo)) {
                        fileInformation.push(fileInfo);
                    }
                });
        })
            .then(() => onAfterFileRead(fileInformation))
            .always(() => { this.value = null; });   // input Dateinamen zurücksetzen, damit die gleichen Dateien erneut ausgewählt werden können
    }

    function onAfterFileRead(fileInformation: Array<Utils.FileInformation>): Deferred {
        if (!Utils.IsSet(fileInformation) || !fileInformation.length) {
            return $.Deferred().resolve().promise();
        }

        let filePosition = -1;
        const newFileInfos = {
            Properties: [],
            Definition: {}
        };

        (currentIssue.Files || []).forEach((file) => {
            if (+file.Position > filePosition) {
                filePosition = +file.Position;
            }
        });

        const deferred = $.Deferred();

        function onAfterFilesPrepared() {
            if (!newFileInfos.Properties.length) {
                return $.Deferred().resolve().promise();
            }

            Utils.Spinner.Show();

            Utils.IssueViewer.AddFile(currentIssue, newFileInfos, (issue: Model.Issues.Issue) => {
                Utils.Spinner.Hide();
                UpdateIssue(issue);
                deferred.resolve();
            }).then(null, deferred.reject);
        }

        (function iterateOverFiles(fileIndex) {
            const fileInfo = fileInformation[fileIndex];

            if (!Utils.IsSet(fileInfo) ||
                !Utils.IsSet(fileInfo.File) ||
                !Utils.IsSet(fileInfo.Identifier)) {
                onAfterFilesPrepared();
                return;
            }
            const fileExtension = Utils.GetFileExtension(fileInfo.File.name, '');

            const fileProperties = {
                Filename: fileInfo.Identifier + fileExtension,
                Title: fileInfo.File.Title,
                Description: fileInfo.File.Description,
                OID: fileInfo.Identifier,
                MimeType: fileInfo.File.type,
                Position: ++filePosition,
                IsImage: Utils.IsImage(fileInfo.File.type),
                IsAudio: Utils.IsAudio(fileInfo.File.type),
                IsVideo: Utils.IsVideo(fileInfo.File.type),
                IsTemporary: true
            };

            Utils.FileEditor.Show({
                Title: fileProperties.Title || fileProperties.Filename,
                Description: fileProperties.Description,
                Readonly: false,
                HideCloseButton: true,
                AllowImmediateSave: true,
                File: fileInfo.File,
                OnSave: (title: string, description: string) => {
                    Utils.UpdateFileTitleAndDescription(fileProperties, title, description);

                    fileInfo.File.Title = fileProperties.Title;
                    fileInfo.File.Filename = fileProperties.Filename;
                    fileInfo.File.Position = fileProperties.Position;

                    newFileInfos.Properties.push(fileProperties);
                    newFileInfos.Definition[fileInfo.Identifier] = fileInfo.File;

                    iterateOverFiles(++fileIndex);
                }
            });
        })(0);

        return deferred.promise();
    }

    function onFileButtonClick(evt: Event): void {
        evt.preventDefault();

        if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            Utils.ShowAppIsReadOnlyDuringSynchronisationMessage();
            return;
        }

        const $this = $(this);
        if ($this.attr('disabled') === 'disabled') {
            return;
        }

        let handler: Function;
        if ($this.data('action') === 'take-photo') {
            handler = onAfterGotImageForFileNote;
        } else if ($this.hasClass('add-attachment-file')) {
            handler = onAfterGotSingleFilePath;
        } else {
            return;
        }

        Utils.RequestCamera().then(handler, Utils.Spinner.Hide);
    }

    function onFileButtonPress(evt: Event): void {
        evt.preventDefault();

        if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            Utils.ShowAppIsReadOnlyDuringSynchronisationMessage();
            return;
        }

        const $this = $(this);
        if ($this.attr('disabled') === 'disabled') {
            return;
        }

        let handler: Function;
        let imageCounter = 10;
        if ($this.data('action') === 'take-photo') {
            handler = onAfterGotImagesForFileNote;
            imageCounter = 1;
        } else if ($this.hasClass('add-attachment-file')) {
            handler = onAfterImagesSelectedFromGallery;
        }

        Utils.GetImagesFromGallery({ MaximumImagesCount: imageCounter })
            .then((images: string[]) => handler(images));
    }

    function onAfterGotSingleFilePath(imageUri: string): void {
        onAfterImagesSelectedFromGallery([imageUri]);
    }

    function onAfterImagesSelectedFromGallery(imageURIs: Array<string>): void {
        if (!(imageURIs || []).length) {
            return;
        }

        const fileProperties = [];
        const fileDefintions = {};

        (function iterateOverFiles(fileIdx: number) {
            const imageUri = imageURIs[fileIdx];

            if (!imageUri) {
                Utils.IssueViewer.AddFile(
                    currentIssue,
                    { Properties: fileProperties, Definition: fileDefintions },
                    UpdateIssue
                );

                return;
            }

            const fileInfo = createFileInformation(imageUri);
            const filePath = Session.IsRunningOnIOS ?
                Utils.FixIOSFilepath(fileInfo.Properties.FileURI) :
                fileInfo.Properties.FileURI;

            Utils.FileEditor.Show({
                Title: fileInfo.Properties.Title,
                Description: '',
                Readonly: false,
                HideCloseButton: true,
                AllowImmediateSave: true,
                File: { MimeType: fileInfo.Properties.MimeType, Filename: fileInfo.Properties.Filename, FilePath: filePath },
                OnSave: (title: string, description: string) => {
                    Utils.UpdateFileTitleAndDescription(fileInfo.Properties, title, description);

                    fileProperties.push(fileInfo.Properties);
                    fileDefintions[fileInfo.Identifier] = fileInfo.Definition;

                    iterateOverFiles(++fileIdx);
                }
            });
        })(0);
    }

    function createFileInformation(imageURI: string): {
        Properties,
        Definition,
        Identifier: string
    } {
        const identifier = uuid();
        const fileExtension = Utils.GetFileExtension(imageURI);
        const newFilename = identifier + fileExtension;
        const mimeType = fileExtension === '.png' ? 'image/png' : 'image/jpeg';

        let filePosition = 0;
        (currentIssue.Files || []).forEach(function(file) {
            if (+file.Position > filePosition) {
                filePosition = +file.Position;
            }
        });

        const fileProperties = {
            Filename: newFilename,
            Title: newFilename,
            OID: identifier,
            Position: ++filePosition,
            MimeType: mimeType,
            FileURI: imageURI,
            IsImage: Utils.IsImage(mimeType),
            IsAudio: Utils.IsAudio(mimeType)
        };

        const definition = {
            FileURI: imageURI,
            Filename: newFilename,
            MimeType: mimeType
        };

        return {
            Properties: fileProperties,
            Definition: definition,
            Identifier: identifier
        };
    }

    function initFooterToolbar(): void {
        const issueOID: number | string = currentIssue.ID || currentIssue.OID;
        const assignedElementOID: string = currentIssue.AssignedElementOID;
        const currentLocationLink: string = `#issue/${issueOID}/location/${assignedElementOID}/`;

        const userCanEditCurrentIssue: boolean = Utils.CanUserModifyIssue(currentIssue);
        const stateInfo: Model.Properties.StateInfo = DAL.Properties.GetStateModificationInfo(currentIssue);
        const currentState: Model.Properties.Property = DAL.Properties.GetByOID(currentIssue.StateOID);

        const showFollowStateButton: boolean = userCanEditCurrentIssue &&
            stateInfo &&
            (stateInfo.StateChangeAllowed || Session.Settings.ShowNextStateOnUnrecorded) &&
            stateInfo.FollowerStates.length &&
            !(currentState && currentState.ClosedState) &&
            !Session.Settings.SidebarDefaultVisibility;

        $toolbar.find('.quick-select-state').parent()
            .toggleClass('hidden', !showFollowStateButton);

        $toolbar.find('.close-view')
            .off('click')
            .on('click', onBtnCloseViewClick);

        $toolbar.find('.show-issue-sidebar')
            .off('click.toggleIssueSidebar')
            .on('click.toggleIssueSidebar', onToggleIssueSidebar);

        if (currentIssue.Type === Enums.IssueType.Inspection && !viewUpdateInformation) {
            $toolbar.find('.issue-actions li[data-action="take-photo"]')
                .off()
                .press(onFileButtonPress, onFileButtonClick, 500);

            $toolbar.find('.issue-actions')
                .off('click.doAction')
                .on('click.doAction', 'li[data-action]', onFooterToolbarIssueActionsClick);

            $toolbar.find('.drop-up')
                .off('click.toggle')
                .on('click.toggle', onToggleFooterToolbarDropUpVisibility);

            $toolbar.find('.switch-view')
                .find('li[data-view="covering-page"] a')
                .attr('href', currentLocationLink + 'covering-page?type=' + Enums.IssueType.Inspection);

            $toolbar.find('.switch-view')
                .find('li[data-view="issues"] a')
                .attr('href', currentLocationLink + 'issue-report?type=' + Enums.IssueType.Inspection);


            if ($btnShowCoveringPage) {
                $btnShowCoveringPage.find('a')
                    .attr('href', currentLocationLink + 'covering-page?type=' + Enums.IssueType.Inspection);
            }

            if ($btnShowIssues) {
                $btnShowIssues.find('a')
                    .attr('href', currentLocationLink + 'issue-report?type=' + Enums.IssueType.Inspection);
            }
        }

        setFooterToolbarButtonsVisibility();
    }

    function onToggleIssueSidebar(): void {
        if (!($issueInfoSidebar instanceof $)) {
            return;
        }

        $issueInfoSidebar.toggleClass('open');
    }

    function onToggleFooterToolbarDropUpVisibility(evt: Event): void {
        const $btn = $(evt.currentTarget);
        const dropUpIsVisible = $btn.hasClass('drop-up-visible');

        if (dropUpIsVisible) {
            $btn.removeClass('drop-up-visible');
            return;
        }

        const $dropUp = $btn.find('.drop-up-content');

        if (!$dropUp.length) {
            return;
        }

        const btnOffset = $btn.offset();
        let dropUpOffset = $dropUp.offset();
        let offset = btnOffset.left + dropUpOffset.left;

        if (offset < 10) {
            const diff = 10 - offset.left;
            const left = parseInt($dropUp.css('left'), 10);

            $dropUp.css('left', left + Math.abs(diff));

            offset = $dropUp.offset();
        }

        $btn.parents('.scroller')
            .find('.drop-up-visible')
            .removeClass('drop-up-visible');


        const maxWidth = $(document).outerWidth(true) - offset.left - 10;

        $dropUp.css({
            'max-width': maxWidth
        });

        $btn.addClass('drop-up-visible');

        $('body').one('click.closeDropUp', () => $btn.removeClass('drop-up-visible'));

        evt.stopPropagation();
    }

    function onFooterToolbarIssueActionsClick(evt: Event): void {
        const $li = $(evt.currentTarget);

        switch ($li.data('action')) {
            case 'record-voice-mail':
                onBtnAddVoiceMailClick();
                break;
            case 'create-task':
                onBtnAddTextClick();
                break;
        }
    }

    function setFooterToolbarButtonsVisibility(): void {
        if (!($toolbar instanceof $) ||
            !$toolbar.length) {
            return;
        }

        // hier wird die Sichtbarkeit des Sidebar Info Icons festgelegt
        const inlineSidebar = shouldSidebarBeInline();

        // Info-Icon für Sidebar wird angezeigt, wenn Bildschirm kleiner als 768px oder sidebar nicht sichtbar
        $toolbar
            .find('.show-issue-sidebar')
            .parents('.item-wrapper')
            .toggleClass('screen-max-sm', inlineSidebar);


        if (View.CurrentView === Enums.View.Form ||
            View.CurrentView === Enums.View.Inspection ||
            View.CurrentView === Enums.View.Scheduling ||
            View.CurrentView === Enums.View.FormBatchEdit) {
            // Statusbuttons ausblenden, wenn Sidebar sichtbar
            // Statusbuttons einblenden, wenn Sidebar unsichtbar oder Bildschirm kleiner 768px
            $toolbar
                .find('.state-buttons')
                .toggleClass('screen-max-sm', inlineSidebar);

            if (View.CurrentView === Enums.View.Inspection) {
                const location = Session.CurrentLocation;
                const canEditOrCreateTasks = Utils.CanUserCreateIssueType(Enums.IssueType.Task, location);
                const canEditOrCreateNotes = Utils.CanUserCreateIssueType(Enums.IssueType.Note, location);
                const canEditOrCreateDisturbances = Utils.CanUserCreateIssueType(Enums.IssueType.Disturbance, location);
                const showBtnAddIssue = canEditOrCreateTasks || canEditOrCreateNotes || canEditOrCreateDisturbances;

                $toolbar.find('.add-issue, .issue-actions')
                    .toggleClass('hidden', !showBtnAddIssue);

                $toolbar
                    .find('.issue-actions')
                    .find('li[data-action="take-photo"], li[data-action="record-voice-mail"]')
                    .toggleClass('hidden', !canEditOrCreateNotes);
            }
        }
    }

    function updateUrlFragmentIfNecessary(): void {
        const currentFragment = Utils.Router.GetCurrentFragment();

        if (!/issue\/[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}/ig.test(currentFragment)) {
            return;
        }

        const oid = /[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}/ig.exec(currentFragment)[0];

        if (currentIssue.ID || currentIssue.OID !== oid) {
            Utils.Router.ReplaceState(currentFragment.replace(oid, <any>currentIssue.ID || currentIssue.OID), true);

            if (!currentIssue.ID && ($issueInfoSidebar instanceof $)) {
                $issueInfoSidebar.find('.edit-issue').attr('href', `#issue/${currentIssue.OID}`);
            }

            replaceIssueOIDInToolbar(oid);

            const tree = App.GetTree();

            if (tree) {
                const issueOID = currentIssue.ID || currentIssue.OID;
                tree.SetRouteBase(`issue/${issueOID}/location`)
                    .SetAnchorSuffix(`?type=${currentIssue.Type}`)
                    .UpdateRoutesByPattern(`issue/${issueOID}/location/{Identifier}?type=${currentIssue.Type}`);
            }
        }
    }

    function replaceIssueOIDInToolbar(oid: string): void {
        const $showCoveringPage = $toolbar.find('.show-covering-page a');

        if ($showCoveringPage.length) {
            $showCoveringPage
                .attr('href', $showCoveringPage.attr('href').replace(oid, currentIssue.ID || currentIssue.OID));
        }
    }

    export function Close(forceClear: boolean) {
        if (View.CurrentView !== Enums.View.Main || forceClear) {
            Clear();

            if (!Session.IsSmartDeviceApplication) {
                $('nav.navbar')
                    .find('li > a[href="#sync"]')
                    .parent()
                    .addClass('hidden');
            }
        }
    }

    function getShowFollowStateButton(): boolean {
        // allgemeines Vorhandensein von Rechten prüfen
        const rights = Utils.GetActiveUserRights(
            Session.User.OID,
            true,
            currentIssue.AssignedElementOID
        );

        if (!rights) {
            return false;
        }

        // Berechtigung zum Bearbeiten des Vorgangs prüfen
        const mayEditIssue = Utils.CanUserModifyIssue(currentIssue, rights);

        if (!mayEditIssue) {
            return false;
        }

        // Berechtigung für Statusänderung
        const userCanChangeStateProperty = Utils.UserHasIssueRight(
            currentIssue,
            Enums.Rights.IssueProperties_State,
            rights
        );

        if (!userCanChangeStateProperty) {
            return false;
        }

        // prüfe Berechtigung unvollständige Vorgänge abschließen
        const userRoles = Utils.GetUserRoles(currentIssue.AssignedElementOID);
        const allRequiredRecorded = !currentIssue.RequiredParameterCount ||
            (currentIssue.CollectedRequiredParameterCount >= currentIssue.RequiredParameterCount);

        const userCanChangeStateOfIncompleteIssues = Utils.UserHasRight(
            Session.User.OID,
            Enums.Rights.ChangeOrSetIncompleteIssueState,
            true,
            currentIssue.AssignedElementOID
        );

        return DAL.Properties.IsUserAllowedToSeeNextState(
            userRoles,
            currentIssue.StateOID,
            null,
            allRequiredRecorded,
            userCanChangeStateOfIncompleteIssues
        );
    }

    function isIssueSidebarVisible(): boolean {
        return $issueInfoSidebar &&
            $issueInfoSidebar instanceof $ &&
            $issueInfoSidebar.hasClass('inline') &&
            $issueInfoSidebar.width() > 0;
    }

    export function UpdateStatusQuickSelection(): void {
        const stateInfo = DAL.Properties.GetStateModificationInfo(currentIssue);

        if (stateInfo == null) {
            return;
        }

        const currentState = DAL.Properties.GetByOID(currentIssue.StateOID);

        updateStatusQuickSelectionForSidebar(stateInfo, currentState);

        if (!isIssueSidebarVisible()) {
            updateStatusQuickSelectionForToolbar(stateInfo, currentState);
        }
    }

    function updateStatusQuickSelectionForSidebar(stateInfo: Model.Properties.StateInfo, currentState: Model.Properties.Property) {
        if (!$issueInfoSidebar) {
            return;
        }

        const $statusSelection = $issueInfoSidebar.find('.status-quick-selection');

        if (!($statusSelection instanceof $ && $statusSelection.length)) {
            return;
        }

        $statusSelection.find('ul').empty();

        if (toolbarQuickFollowStateButtons != null && !$issueInfoSidebar.hasClass('open')) {
            toolbarQuickFollowStateButtons.Destroy();
            toolbarQuickFollowStateButtons = null;
        }

        if (!getShowFollowStateButton()) {
            $statusSelection.addClass('hidden');
            return;
        }

        if (!(stateInfo &&
            (stateInfo.StateChangeAllowed || Session.Settings.ShowNextStateOnUnrecorded) &&
            stateInfo.FollowerStates.length &&
            !(currentState && currentState.ClosedState))) {
            $statusSelection.addClass('hidden');

            return;
        }

        $statusSelection.removeClass('hidden');

        const html = stateInfo.FollowerStates.map((state: Model.Properties.Property) => {
            return [
                '<li class="select-status" data-identifier="', state.OID, '">',
                '<span class="color" style="background-color:', state.Color, '"></span>',
                state.ActivationDescription || state.Title,
                '</li>'
            ].join('');
        }).join('');

        $statusSelection.find('ul').html(html);
    }

    function updateStatusQuickSelectionForToolbar(stateInfo: Model.Properties.StateInfo, currentState: Model.Properties.Property) {
        const $statusSelection = $toolbar.find('.quick-select-state');

        if (!($statusSelection instanceof $ && $statusSelection.length)) {
            return;
        }

        if (toolbarQuickFollowStateButtons != null) {
            toolbarQuickFollowStateButtons.Destroy();
            toolbarQuickFollowStateButtons = null;
        }

        if (!getShowFollowStateButton()) {
            $statusSelection.parent().addClass('hidden');
            return;
        }

        if (!(stateInfo &&
            (stateInfo.StateChangeAllowed || Session.Settings.ShowNextStateOnUnrecorded) &&
            stateInfo.FollowerStates.length &&
            !(currentState && currentState.ClosedState))) {
            $statusSelection.parent().addClass('hidden');
            return;
        }

        $statusSelection
            .parent()
            .removeClass('hidden');

        $statusSelection
            .off('click')
            .children()
            .remove();

        const buttons = stateInfo.FollowerStates.map((state: Model.Properties.Property) => {
            return {
                Color: state.Color,
                Title: state.ActivationDescription || state.Title,
                IsLockedState: state.IsLockedState,
                OID: state.OID
            }
        });

        toolbarQuickFollowStateButtons = new Model.ButtonGroup({
            $Container: $statusSelection,
            OverflowAsDropDown: true,
            ButtonEntities: buttons,
            ForceFirstItem: false,
            $DropDownIcon: $('<img src="./img/status.svg" width="26px" height="26px" />'),
            OnItemClick: (evt: Event) => {
                onStatusQuickSelectionClick(evt);
            },
            GetRemainingWidth: () => {
                if ($toolbar == null) {
                    return 0;
                }

                const $scroller = $toolbar.children('.scroller');
                let remainingWidth = $scroller.width();

                // nur das Dropdown-Icon anzeigen falls die Breite unter 400 ist
                if (remainingWidth < 400) {
                    return 0;
                }

                $scroller.children('.item-wrapper:visible').each((i, item) => {
                    const $elem = $(item);

                    if ($elem.is($statusSelection.parent())) {
                        //$elem.outerWidth() is wrong if the buttons are wrapped into multiple lines
                        $statusSelection.children('.btn-group').children().each((j, btn) => {
                            remainingWidth -= $(btn).outerWidth(true);
                        });

                        //subtract margin and border
                        remainingWidth -= $elem.outerWidth(true) - $elem.innerWidth();
                    } else {
                        remainingWidth -= $elem.outerWidth(true);
                    }
                });

                return remainingWidth;
            }
        });
    }

    function navigateBackToPreviousMenu(): void {
        if (!Session.Settings.RedirectToMainMenuAfterClosingIssueView) {
            // Letztes #location Fragment aus der Router History finden
            const prevFragments = Utils.Router.GetFragmentHistory();

            for (let i = prevFragments.length - 1; i >= 0; i--) {
                const fragment = prevFragments[i];
                // finde letztes location fragment
                if (/^location\//ig.test(fragment)) {
                    Utils.Router.PushState(fragment);
                    return;
                };
            }
        }

        const locationIdentifier = previouslySelectedLocationOID || Session.User.RootElementOID || DAL.Elements.Root.OID;
        // zurück ins Hauptmenü kehren
        const uri = `location/${locationIdentifier}/menu`;
        Utils.Router.PushState(uri);
    }

    export function GetRecorditemsForRow(groupOID: string, rowID: number): Array<Model.Recorditem> {
        if (currentIssue == null) {
            return [];
        }

        const tree = ParameterList.GetResubmissionTree();
        const parameters = [];

        if ((tree.Parametergroups || []).length) {
            for (let gCnt = 0, gLen = tree.Parametergroups.length; gCnt < gLen; gCnt++) {
                const group = tree.Parametergroups[gCnt];

                if (group.OID === groupOID && (group.Parameters || []).length) {
                    for (let pCnt = 0, pLen = group.Parameters.length; pCnt < pLen; pCnt++) {
                        parameters.push(group.Parameters[pCnt].OID);
                    }
                }
            }
        }

        return (currentIssue.Recorditems || []).filter(function(recorditem) {
            if (Utils.InArray(parameters, recorditem.ElementOID) && recorditem.Row === rowID) {
                return recorditem;
            }
        });
    }

    export function GetRecorditemForResubmissionItem(resubitemOID: string): Model.Recorditem {
        const currentIssueRecorditems = currentIssue.Recorditems || [];
        if (currentIssueRecorditems.length) {
            let recorditems = [];

            for (let rCnt = 0, rLen = currentIssueRecorditems.length; rCnt < rLen; rCnt++) {
                const recorditem = currentIssueRecorditems[rCnt];

                if (recorditem.ResubmissionitemOID === resubitemOID &&
                    !recorditem.IsDummy) {

                    if (recorditem.ID && ParameterList.CorrectiveActionsDictionary[recorditem.ID]) {
                        recorditem.CorrectiveActions = [].concat(ParameterList.CorrectiveActionsDictionary[recorditem.ID]);
                    } else if (ParameterList.CorrectiveActionsDictByOID[recorditem.OID]) {
                        recorditem.CorrectiveActions = [].concat(ParameterList.CorrectiveActionsDictByOID[recorditem.OID]);
                    }

                    if (!recorditem.ElementType && !recorditem.Element && recorditem.ElementRevisionOID) {
                        const element = DAL.Elements.GetByRevisionOID(recorditem.ElementRevisionOID);
                        recorditem.ElementType = element ? element.Type : null;
                    }

                    recorditems.push(recorditem);
                }
            }

            if (recorditems.length) {
                recorditems.sort(function(a, b) {
                    let timestampA = a.ModificationTimestamp;
                    let timestampB = b.ModificationTimestamp;

                    if (typeof timestampA === 'string') {
                        timestampA = new Date(timestampA);
                    }

                    if (typeof timestampB === 'string') {
                        timestampB = new Date(timestampB);
                    }

                    return timestampB.getTime() - timestampA.getTime();
                });

                return recorditems[0];
            }
        }

        return null;
    }

    export function GetRecorditemForElement(element: Model.Elements.Element): Model.Recorditem {
        if (!element) {
            return null;
        }

        const resubmissionitemOID = GetResubmissionitemOID(element.OID, element.Row);

        return GetRecorditemForResubmissionItem(resubmissionitemOID);
    }

    //Dreckiger Workaround Raumwechsel-Dialog wird mehrfach angezeigt...
    //Damit die CheckRequiredParametersAtLocation weiter so funktioniert wie erwartet
    export function TryGoToNextRoom(onlyCheck: boolean = false): Enums.StateOfGoingToNextRoom {
        if (!currentIssue) {
            return Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
        }

        if (Utils.ActionWindow.IsVisible()) {
            return Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
        }

        const roomOrder = currentIssue.GetResubmissionRoomOrder();

        if (!(roomOrder || []).length) {
            return Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
        }

        const locationFinishedDictionary = {};
        for (let currentRoomIndex = 0; currentRoomIndex < roomOrder.length; currentRoomIndex++) {
            const roomIdentifier = roomOrder[currentRoomIndex];
            const room = ParameterList.GetElementRevisionByOID(roomIdentifier);
            const roomParameterCountInformation = CheckRequiredParametersAtLocation(room, true);
            const roomRequiredCount = roomParameterCountInformation.RequiredParameterCount > roomParameterCountInformation.RecordedParametersCount;
            const roomNotRequiredCount = roomParameterCountInformation.NotRequiredParameterCount > roomParameterCountInformation.RecordedNotRequiredParametersCount;
            locationFinishedDictionary[roomIdentifier] = !!(Session.Settings.AutomaticallyOpenNextCheckpoint === Enums.AutomaticallyOpenNextCheckpoint.Always ?
                roomNotRequiredCount || roomRequiredCount :
                roomRequiredCount);
        }

        let allLocationsAreFinished = true;
        for (let locationOID in locationFinishedDictionary) {
            let locationIsUnfinished = locationFinishedDictionary[locationOID];

            if (locationIsUnfinished) {
                allLocationsAreFinished = false;
                break;
            }
        }

        if (allLocationsAreFinished) {
            return Enums.StateOfGoingToNextRoom.AllRequiredCheckpointsAreRecorded;
        }

        let currentRoomIndex = Utils.GetIndex(roomOrder, Session.CurrentLocation.OID);

        if (locationFinishedDictionary[roomOrder[currentRoomIndex]]) {
            return Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
        }

        if (currentRoomIndex === roomOrder.length - 1) {
            currentRoomIndex = 0;
        }

        // get next Room + identifier
        let nextRoomIdentifier: string;
        let nextRoom: Model.Elements.Element;
        for (let i = currentRoomIndex; i < roomOrder.length; i++) {
            nextRoomIdentifier = roomOrder[i];
            nextRoom = ParameterList.GetElementRevisionByOID(nextRoomIdentifier);
            const parameterCountInformation = CheckRequiredParametersAtLocation(nextRoom, true);
            const requiredCount = parameterCountInformation.RequiredParameterCount > parameterCountInformation.RecordedParametersCount;
            const notRequiredCount = parameterCountInformation.NotRequiredParameterCount > parameterCountInformation.RecordedNotRequiredParametersCount;
            const unfinishedRoomFound =
                !!(Session.Settings.AutomaticallyOpenNextCheckpoint === Enums.AutomaticallyOpenNextCheckpoint.Always ?
                    notRequiredCount || requiredCount :
                    requiredCount);

            if (unfinishedRoomFound) {
                break;
            }
        }

        if (nextRoomIdentifier === Session.CurrentLocation.OID) {
            return Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
        }

        if (onlyCheck) {
            switch (Session.Settings.AutomaticallyChangeRoomAfterRecording) {
                case Enums.AutomaticallyChangeRoomAfterRecordingType.Always:
                case Enums.AutomaticallyChangeRoomAfterRecordingType.Ask:
                    return Enums.StateOfGoingToNextRoom.GoToNextRoom;
                case Enums.AutomaticallyChangeRoomAfterRecordingType.Never:
                    return Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
            }
        } else {
            switch (Session.Settings.AutomaticallyChangeRoomAfterRecording) {
                case Enums.AutomaticallyChangeRoomAfterRecordingType.Always:
                    if (Settings.IsAutomaticallyOpenNextParameterSettingActive()) {
                        ParameterList.SetOpenEditorAfterRoomChanged(true);
                    }

                    Utils.Router.PushState(`#issue/${currentIssue.Identifier}/location/${nextRoomIdentifier}/parameters?type=${currentIssue.Type}`);

                    return Enums.StateOfGoingToNextRoom.GoToNextRoom;
                case Enums.AutomaticallyChangeRoomAfterRecordingType.Ask:
                    if (Utils.Message.IsVisible()) {
                        return Enums.StateOfGoingToNextRoom.GoToNextRoom;
                    }

                    Utils.Message.Show(i18next.t('IssueView.GoToNextRoom.MessageHeader'),
                        i18next.t('IssueView.GoToNextRoom.MessageBody', { RoomTitle: nextRoom.Title }),
                        {
                            Yes: function() {
                                if (Settings.IsAutomaticallyOpenNextParameterSettingActive()) {
                                    ParameterList.SetOpenEditorAfterRoomChanged(true);
                                }

                                Utils.Router.PushState(`#issue/${currentIssue.Identifier}/location/${nextRoomIdentifier}/parameters?type=${currentIssue.Type}`);
                            },
                            No: function() {
                                ParameterList.SetOpenEditorAfterRoomChanged(false);
                            }
                        });
                    return Enums.StateOfGoingToNextRoom.GoToNextRoom;
                case Enums.AutomaticallyChangeRoomAfterRecordingType.Never:
                    return Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
            }
        }

        return Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
    }

    export function CheckRequiredParametersAtLocation(loc: Model.Elements.Element, dontColorTree?: boolean, changeLocation: boolean = false): Model.Parameters.IParameterCounter {
        let requiredParameters = 0;
        let notRequiredParameters = 0;
        let recordedRequiredParameters = 0;
        let recordedNotRequiredParameters = 0;
        let stateOfGoingToNextRoom = Enums.StateOfGoingToNextRoom.GoToNextCheckpoint;

        const parameterGroups = loc && loc.Parametergroups ? loc.Parametergroups : [];
        if (loc && parameterGroups.length) {
            for (let i = 0; i < parameterGroups.length; i++) {
                const group = parameterGroups[i];
                if (!currentIssue.AssignedMeasureOID && group.Attribute === 4 || group.Attribute === 5) {
                    const groupParameters = group.Parameters || [];
                    for (let i = 0; i < groupParameters.length; i++) {
                        const param = groupParameters[i];

                        if (!param.IsHidden && !param.MissingRequirements) {
                            const recItem = GetRecorditemForElement(param);
                            const isRecorded = Model.Recorditem.IsRecorditemRecorded(recItem);

                            if (param.Required) {
                                requiredParameters++;

                                if (isRecorded) {
                                    recordedRequiredParameters++;
                                }

                                continue;
                            }

                            notRequiredParameters++;
                            if (isRecorded) {
                                recordedNotRequiredParameters++;
                            }
                        }
                    }
                }
            }

            if (requiredParameters && !dontColorTree) {
                const $li = $locationPicker.find(`.item[data-identifier="${loc.OID}"]`);

                if (($li || []).length) {
                    $li.attr({
                        'data-required': requiredParameters,
                        'data-requiredrecorded': recordedRequiredParameters
                    }).data('required', requiredParameters)
                        .data('requiredrecorded', recordedRequiredParameters);

                    if (requiredParameters > recordedRequiredParameters) {
                        App.GetTree()
                            .UpdateAdditionalClassesAtNode(loc.OID, ['incomplete']);
                    } else {
                        App.GetTree()
                            .UpdateAdditionalClassesAtNode(loc.OID, ['complete']);

                        if (loc.OID === Session.CurrentLocation.OID) {
                            if (Session.Settings.AutomaticallyOpenNextCheckpoint === Enums.AutomaticallyOpenNextCheckpoint.Always
                                && notRequiredParameters > recordedNotRequiredParameters) {
                                stateOfGoingToNextRoom = Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
                            } else {
                                stateOfGoingToNextRoom = TryGoToNextRoom(!changeLocation);
                            }
                        }
                    }
                } else if (requiredParameters <= recordedRequiredParameters &&
                    loc.OID === Session.CurrentLocation.OID) {
                    stateOfGoingToNextRoom = TryGoToNextRoom(!changeLocation);
                }
            } else if (notRequiredParameters > recordedNotRequiredParameters) {
                stateOfGoingToNextRoom = Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
            }
        }

        return {
            RecordedParametersCount: recordedRequiredParameters,
            RequiredParameterCount: requiredParameters,
            StateOfGoingToNextRoom: stateOfGoingToNextRoom,
            NotRequiredParameterCount: notRequiredParameters,
            RecordedNotRequiredParametersCount: recordedNotRequiredParameters
        };
    }

    export function GoToNextLocation(loc: Model.Elements.Element, param: Model.Elements.Element): Enums.StateOfGoingToNextRoom {
        const parameterGroups = loc.Parametergroups || [];

        if (parameterGroups.length <= 0) {
            return Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
        }

        let requiredParameters = 0;
        let recordedRequiredParameters = 0;
        for (let i = 0; i < parameterGroups.length; i++) {
            const group = parameterGroups[i];
            if (!currentIssue.AssignedMeasureOID && group.Attribute === 4 || group.Attribute === 5) {

                let groupParameters = group.Parameters || [];
                for (let i = 0; i < groupParameters.length; i++) {
                    const param = groupParameters[i];
                    if (param.Required && !param.IsHidden && !param.MissingRequirements) {
                        requiredParameters++;

                        const recItem = GetRecorditemForElement(param);
                        const isRecorded = Model.Recorditem.IsRecorditemRecorded(recItem);
                        if (isRecorded) {
                            recordedRequiredParameters++;
                        }
                    }
                }
            }
        }

        if (requiredParameters === 0 && Session.Settings.AutomaticallyOpenNextCheckpoint !== Enums.AutomaticallyOpenNextCheckpoint.Always) {
            return Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
        }

        if (requiredParameters <= recordedRequiredParameters && loc.OID === Session.CurrentLocation.OID) {
            if (Session.Settings.AutomaticallyOpenNextCheckpoint === Enums.AutomaticallyOpenNextCheckpoint.Always || !param || !param.Required) {
                return Enums.StateOfGoingToNextRoom.StayInCurrentRoom;
            }

            return TryGoToNextRoom(true);
        }
    }

    function checkRequiredParametersForTree(): number {
        let overallCounter = 0;

        (function traverse(loc: Model.Elements.Element) {
            const counter = CheckRequiredParametersAtLocation(loc, View.CurrentView === Enums.View.Inspection);

            if (counter.RequiredParameterCount) {
                recordedParametersCount[loc.OID] = counter;
            } else {
                delete recordedParametersCount[loc.OID];
            }

            if ((loc.Children || []).length) {
                loc.Children.forEach(traverse);
            }

            overallCounter += counter.RequiredParameterCount - counter.RecordedParametersCount;
        })(ParameterList.GetResubmissionTree());

        return overallCounter;
    }

    export function GetFilteredParametersCount(): {
        Overall: number,
        Locations: Dictionary<number>,
    } {
        const counter = {
            Overall: 0,
            Locations: {},
        };

        (function traverse(loc: Model.Elements.Element) {
            if ((loc.Parametergroups || []).length) {
                if (!counter.Locations.hasOwnProperty(loc.OID)) {
                    counter.Locations[loc.OID] = 0;
                }

                loc.Parametergroups.forEach(function(group) {
                    if (!group.Enabled || group.IsHidden || group.MissingRequirements || group.IsFiltered || !(group.Parameters || []).length) {
                        return;
                    }

                    group.Parameters.forEach(function(parameter) {
                        if (parameter.Enabled && !parameter.IsHidden && !parameter.MissingRequirements && !parameter.IsFiltered) {
                            counter.Locations[loc.OID]++;
                            counter.Overall++;
                        }
                    });
                });
            }

            if ((loc.Children || []).length) {
                loc.Children.forEach(traverse);
            }
        })(ParameterList.GetResubmissionTree());

        return counter;
    }

    export function GetClassesForTreeView(): Dictionary<Array<string>> {
        const locations = {};

        (function traverse(loc: Model.Elements.Element) {
            const counter = CheckRequiredParametersAtLocation(loc, true);

            if (counter.RequiredParameterCount) {
                if (counter.RequiredParameterCount === counter.RecordedParametersCount) {
                    locations[loc.OID] = ['complete'];
                } else {
                    locations[loc.OID] = ['incomplete'];
                }

                recordedParametersCount[loc.OID] = counter;
            } else {
                delete recordedParametersCount[loc.OID];
            }

            if ((loc.Children || []).length) {
                loc.Children.forEach(traverse);
            }
        })(ParameterList.GetResubmissionTree());

        return locations;
    }

    function selectRequiredParameters(elementTree: Model.Elements.Element): void {
        requiredParameters = [];
        nonRequiredParameters = [];

        if (!elementTree) {
            return;
        }

        (function traverse(elem: Model.Elements.Element) {
            if ((elem.Parametergroups || []).length) {
                for (let gCnt = 0, gLen = elem.Parametergroups.length; gCnt < gLen; gCnt++) {
                    const group = elem.Parametergroups[gCnt];

                    if ((group.Parameters || []).length) {
                        for (let pCnt = 0, pLen = group.Parameters.length; pCnt < pLen; pCnt++) {
                            const param = group.Parameters[pCnt];

                            if (param.MissingRequirements) {
                                continue;
                            }

                            if (param.Required) {
                                requiredParameters.push(param);
                            } else {
                                nonRequiredParameters.push(param);
                            }
                        }
                    }
                }
            }

            if ((elem.Children || []).length) {
                for (let cCnt = 0, cLen = elem.Children.length; cCnt < cLen; cCnt++) {
                    const child = elem.Children[cCnt];

                    if (child.Type === Enums.ElementType.Location) {
                        traverse(child);
                    }
                }
            }
        })(elementTree);
    }

    export function GetUnfinishedParametersCount(requiredOnly: boolean = true): number {
        if (!currentIssue) {
            return 0;
        }

        let resubTree = ParameterList.GetResubmissionTree();
        if (!Utils.HasProperties(resubTree) &&
            currentIssue.Type === Enums.IssueType.Inspection) {
            resubTree = ParameterList.PrepareElementRevisions(coveringPageElements, true);
        }

        selectRequiredParameters(resubTree);

        if (!(requiredParameters || []).length) {
            return 0;
        }

        let unrecordedParameters = 0;
        let unrecordedNonRequiredParameters = 0;
        for (let pCnt = 0, pLen = requiredParameters.length; pCnt < pLen; pCnt++) {
            const param = requiredParameters[pCnt];

            if (param.Parent &&
                (param.Parent.IsHidden || param.Parent.MissingRequirements)) {
                continue;
            }

            if (param.IsHidden || param.MissingRequirements) {
                continue;
            }

            const recorditem = GetRecorditemForElement(param);
            if (!Model.Recorditem.IsRecorditemRecorded(recorditem)) {
                unrecordedParameters++;
            }
        }

        if (Session.Settings.AutomaticallyOpenNextCheckpoint === Enums.AutomaticallyOpenNextCheckpoint.Always &&
            !requiredOnly && (nonRequiredParameters || []).length) {
            for (let pCnt = 0, pLen = nonRequiredParameters.length; pCnt < pLen; pCnt++) {
                const param = nonRequiredParameters[pCnt];

                if (param.Parent &&
                    (param.Parent.IsHidden || param.Parent.MissingRequirements)) {
                    continue;
                }

                if (param.IsHidden || param.MissingRequirements) {
                    continue;
                }

                const recorditem = GetRecorditemForElement(param);
                if (!Model.Recorditem.IsRecorditemRecorded(recorditem)) {
                    unrecordedNonRequiredParameters++;
                }
            }

            return unrecordedParameters + unrecordedNonRequiredParameters;
        }

        return unrecordedParameters;
    }

    export function UpdateCurrentIssueParameterCounter(skipResubTreeUpdate: boolean = false): void {
        if (!currentIssue ||
            !Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Inspection, Enums.View.Scheduling], View.CurrentView)) {
            return;
        }

        currentIssue.ParameterCount = 0;
        currentIssue.RequiredParameterCount = 0
        currentIssue.CollectedParameterCount = 0;
        currentIssue.CollectedRequiredParameterCount = 0;

        if (!skipResubTreeUpdate) {
            let resubTree = ParameterList.GetResubmissionTree();
            if (!Utils.HasProperties(resubTree) && currentIssue.Type === Enums.IssueType.Inspection) {
                resubTree = ParameterList.PrepareElementRevisions(coveringPageElements, true);
            }

            selectRequiredParameters(resubTree);
        }

        const groups: Utils.HashSet = new Utils.HashSet();

        for (let parameter of requiredParameters) {
            if (parameter.MissingRequirements ||
                parameter.IsHidden ||
                (parameter.Parent && (parameter.Parent.IsHidden || parameter.Parent.MissingRequirements))) {
                continue;
            }

            groups.put(parameter.Row);

            currentIssue.ParameterCount++;
            currentIssue.RequiredParameterCount++;

            const recorditem = GetRecorditemForElement(parameter);

            if (recorditem && recorditem.Type !== Enums.RecorditemType.UNRECORDED) {
                currentIssue.CollectedParameterCount++;
                currentIssue.CollectedRequiredParameterCount++;
            }
        }

        for (let parameter of nonRequiredParameters) {
            if (parameter.MissingRequirements ||
                parameter.IsHidden ||
                (parameter.Parent && (parameter.Parent.IsHidden || parameter.Parent.MissingRequirements))) {
                continue;
            }

            currentIssue.ParameterCount++;

            const recorditem = GetRecorditemForElement(parameter);

            if (recorditem && recorditem.Type !== Enums.RecorditemType.UNRECORDED) {
                currentIssue.CollectedParameterCount++;
            }
        }

        groups
            .toArray()
            .forEach(UpdateSidebarIndex);
    }

    export function GetUnfinishedNonRequiredParametersCount(): number {
        if (!currentIssue) {
            return 0;
        }

        if (!(nonRequiredParameters || []).length) {
            return 0;
        }

        let unrecordedParameters = 0;
        for (let pCnt = 0, pLen = nonRequiredParameters.length; pCnt < pLen; pCnt++) {
            const param = nonRequiredParameters[pCnt];

            if (param.IsHidden || param.MissingRequirements) {
                continue;
            }

            const recorditem = GetRecorditemForElement(param);
            if (!Model.Recorditem.IsRecorditemRecorded(recorditem)) {
                unrecordedParameters++;
            }
        }

        return unrecordedParameters;
    }

    export function TrySettingStandardFollowState(force: boolean = false): void {
        if (!Session.Settings.AskForFollowStateAfterCompletion) {
            return;
        }

        if (!Utils.CanUserModifyIssue(currentIssue)) {
            return;
        }

        if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            return;
        }

        if (!currentIssue) {
            return;
        }

        if (dontAskAgainForFinishing) {
            return;
        }

        if (Utils.RecorditemEditor.IsVisible()) {
            return;
        }

        if (Utils.CustomDataPicker.IsVisible()) {
            return;
        }

        if ($('#message').length) {
            return;
        }

        if (!currentIssue.RequiredParameterCount) {
            return;
        }

        const allRequiredRecorded = !currentIssue.RequiredParameterCount ||
            (currentIssue.CollectedRequiredParameterCount >= currentIssue.RequiredParameterCount);
        const mayChangeAllIssueStates = Utils.UserHasRight(Session.User.OID, Enums.Rights.ChangeOrSetIncompleteIssueState, true, currentIssue.AssignedElementOID);

        const userRoles = Utils.GetUserRoles(currentIssue.AssignedElementOID);
        if (!DAL.Properties.IsUserAllowedToChangeState(userRoles, currentIssue.StateOID, allRequiredRecorded, mayChangeAllIssueStates)) {
            return;
        }

        const stateInfo = DAL.Properties.GetStateModificationInfo(currentIssue);
        if (stateInfo == null || !stateInfo.FollowerStates.length) {
            return;
        }

        // onbefore set followerstate, able to cancel
        if (OnBeforeAutosetFollowerState && !OnBeforeAutosetFollowerState(stateInfo.FollowerStates, force)) {
            return;
        }

        if (stateInfo.FollowerStates.length <= 1) {
            setStandardFollowState(stateInfo.FollowerStates[0]);
            return;
        }

        const preparedStates = stateInfo.FollowerStates.map((state) => {
            if (state.IsLockedState) {
                state.FontIcon = 'lock';
            }

            return state;
        });

        Utils.CustomDataPicker.Show(preparedStates, {
            Title: i18next.t('IssueView.SelectStandardFollowState'),
            Width: 330,
            HideResetButton: true,
            HideConfirmationButtons: true,
            UseSystemProperties: true,
            ShowPropertyColors: true,
            OnItemClick: function($btn) {
                const oid = $btn.data('oid');

                if (!oid) {
                    return;
                }

                setStandardFollowState(DAL.Properties.GetByOID(oid), true);
                Utils.CustomDataPicker.Destroy();
            },
            Callback: $.noop,
            OnResetButtonClick: () => {
                dontAskAgainForFinishing = true;
            }
        });
    }

    function setStandardFollowState(followState: Model.Properties.Property, skipCompletelyRecordingPrompt: boolean = false): void {
        const rights = Utils.GetActiveUserRights(
            Session.User.OID,
            true,
            currentIssue.AssignedElementOID
        );

        const userCanEditCurrentIssue = Utils.CanUserModifyIssue(currentIssue, rights);

        const userCanChangeStateProperty = Utils.UserHasIssueRight(
            currentIssue,
            Enums.Rights.IssueProperties_State,
            rights
        );

        if (!(userCanEditCurrentIssue && userCanChangeStateProperty)) {
            dontAskAgainForFinishing = true;
            return;
        }

        if (GetUnfinishedParametersCount()) {
            if (followState.ClosedState && !Utils.UserHasRight(Session.User.OID, Enums.Rights.ChangeOrSetIncompleteIssueState,
                true, DAL.Elements.GetByOID(currentIssue.AssignedElementOID))) {
                Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                    i18next.t('Misc.RightError.CloseIncompleteRecording.MessageBody'),
                    {
                        Close: true
                    }, null, 1201);
            } else {
                showIncompleteRecordingStateChangePrompt(function() {
                    updateState(currentIssue, followState, navigateBackToPreviousMenu);
                }, $.noop, Utils.CloneObject(followState));
            }
        } else {
            if (skipCompletelyRecordingPrompt) {
                updateState(currentIssue, followState, navigateBackToPreviousMenu);
                return;
            }

            showCompletelyRecordingStateChangePrompt(function() {
                updateState(currentIssue, followState, navigateBackToPreviousMenu);
            }, function() {
                dontAskAgainForFinishing = true;
            }, Utils.CloneObject(followState));
        }
    }

    function updateState(issue: Model.Issues.Issue | Model.Issues.RawIssue, followState: Model.Properties.Property, callback: Function): void {
        if (OnBeforeUpdateState && !OnBeforeUpdateState(followState)) {
            return;
        }

        Utils.ActionWindow.ClearActionWindowQueue();
        Utils.ActionWindow.CloseActionWindows();

        Utils.IssueViewer.UpdateState(issue, followState, callback);
    }

    function showCompletelyRecordingStateChangePrompt(onYes: () => void, onNo: () => void, state: Model.Properties.Property): void {
        const header = i18next.t('IssueView.CompletelyRecorded.MessageHeader');
        const message = i18next.t('IssueView.CompletelyRecorded.MessageBody', state);

        Utils.Message.Show(header, message, { Yes: onYes, No: onNo }, null, 1201);
    }

    function showIncompleteRecordingStateChangePrompt(onYes: () => void, onNo: () => void, state: Model.Properties.Property): void {
        const incompleteBodyKey = !!state.ActivationDescription
            ? 'IssueView.IncompleteRecording.StatusWithActivationDescription.MessageBody'
            : 'IssueView.IncompleteRecording.StatusWithoutActivationDescription.MessageBody';

        const header = i18next.t('IssueView.IncompleteRecording.MessageHeader');
        const message = i18next.t(incompleteBodyKey, state);

        Utils.Message.Show(header, message, { Yes: onYes, No: onNo }, null, 1201);
    }

    function toggleBarcodeScannerIcons(enable: boolean = true): void {
        if (Session.IsSmartDeviceApplication) {
            $toolbar.find('.switch-scanner-mode').parent()
                .toggleClass('hidden', !enable);

            $toolbar.find('.start-code-scanner').parent()
                .toggleClass('hidden', !enable);
        }
    }

    export function RenderInspectionIssues(): void {
        // hide barcode scanner buttons
        toggleBarcodeScannerIcons(false);

        onNavigateToLocation();
        IssueReport.Show('issues', IssueReport.GetDescendantsOfIssue());
    }

    function getDeferredForRevision(revisionOID: string): Deferred {
        return Utils.Http.Get(`elements/?revisionoid=${revisionOID}`)
            .fail(function(_response, _state, _error) {
                throw new Model.Errors.HttpError(_error, _response);
            });
    }

    function getDeferredForRevisions(revisionOIDs: string[]): Deferred {
        if (!revisionOIDs || !revisionOIDs.length) {
            return $.Deferred().resolve([]);
        }

        // Prüfe API version, welche Methoden genutzt werden könnten
        // Ab API 30 können mehrere Anfragen als ein Request geschickt werden
        if (Session.LastKnownAPIVersion >= 30) {
            const revisionFilter = [];
            for (const revOID of revisionOIDs) {
                revisionFilter.push({
                    RevisionOID: revOID
                });
            }

            return Utils.Http.Post('elements', revisionFilter)
                .fail(function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        } else {
            // Fallback bei früheren API Versionen (<30), Revisionen werden einzeln geladen
            const deferreds: Deferred[] = [];
            for (const revOID of revisionOIDs) {
                deferreds.push(getDeferredForRevision(revOID));
            }

            return $.when.apply($, deferreds)
                .then(function() {
                    // return prepared parameter revisions
                    const results = arguments;

                    if (!results || !results.length) {
                        return [];
                    }

                    const elements: Model.Elements.Element[] = [];

                    for (let aCnt = 0, aLen = results.length; aCnt < aLen; aCnt++) {
                        const curResponse = results[aCnt];
                        if ($.isArray(curResponse)) {
                            // Ergebnis wurde als Array ausgegeben, nur das erste Element ist relevant
                            elements.push(curResponse[0]);
                        } else {
                            // Ergebnis ist kein Array, das Ergebnis sollte somit direkt das Ziel-Element sein
                            elements.push(curResponse);
                            break;
                        }
                    }

                    return elements;
                });
        }
    }

    export function getRevisionsFromService(overrideIssue?: Model.Issues.Issue): Deferred {
        const tmpIssue = overrideIssue || currentIssue;
        if (!tmpIssue.Resubmissionitems || !tmpIssue.Resubmissionitems.length) {
            return $.Deferred().resolve([]);
        }

        Utils.Spinner.Show(i18next.t('Forms.LoadingFormElements.MessageBody'));

        // alle nötigen Element Revisionen ermitteln
        const requiredRevisionOIDs = new Utils.HashSet();
        for (const resubItem of tmpIssue.Resubmissionitems) {
            requiredRevisionOIDs.put(resubItem.ElementRevisionOID);
        }

        const revisionOIDsArr = requiredRevisionOIDs.toArray();
        const revisionOIDsToLoad = [];
        const selectedRevisions = {};
        const localElements = [];

        for (let riCnt = 0, riLen = revisionOIDsArr.length; riCnt < riLen; riCnt++) {
            const elementRevisionOID = revisionOIDsArr[riCnt];

            const tmpElement = DAL.Elements.GetByRevisionOID(elementRevisionOID)
            if (tmpElement != null) {
                localElements.push(Utils.CloneElement(tmpElement));
                selectedRevisions[elementRevisionOID] = true;
                continue;
            }

            if (!selectedRevisions[elementRevisionOID]) {
                revisionOIDsToLoad.push(elementRevisionOID);
                selectedRevisions[elementRevisionOID] = true;
            }
        }

        if (!overrideIssue) {
            locallyExistingElements = localElements;
        }

        if (!revisionOIDsToLoad.length) {
            const elements = !!localElements ? localElements.slice() : [];
            return $.Deferred().resolve(elements);
        }

        // fehlende Elemente vom Server laden
        return getDeferredForRevisions(revisionOIDsToLoad)
            .then(function(serviceElements: Model.Elements.Element[]) {
                if (!serviceElements || !serviceElements.length) {
                    return localElements;
                }

                if (!localElements || !localElements.length) {
                    return serviceElements;
                }

                return localElements.concat(serviceElements);
            });
    }

    export function getRevisionsFromDatabase(overrideIssue?: Model.Issues.Issue): Deferred {
        const defer = $.Deferred();
        const tmpIssue = overrideIssue || currentIssue;

        if (!tmpIssue.Resubmissionitems || !tmpIssue.Resubmissionitems.length) {
            return defer.resolve([]);
        }

        // alle nötigen Element Revisionen ermitteln
        const requiredRevisionOIDs = new Utils.HashSet();
        for (const resubItem of tmpIssue.Resubmissionitems) {
            requiredRevisionOIDs.put(resubItem.ElementRevisionOID);
        }

        const revisionOIDsArr = requiredRevisionOIDs.toArray();
        const resultElements = DAL.Elements.GetByRevisionOIDs(revisionOIDsArr);

        if (resultElements.length < revisionOIDsArr.length) {
            for (let eCnt = 0, eLen = resultElements.length; eCnt < eLen; eCnt++) {
                const existingRevisionOID = resultElements[eCnt].RevisionOID;
                if (requiredRevisionOIDs.has(existingRevisionOID)) {
                    requiredRevisionOIDs.delete(existingRevisionOID);
                }
            }

            const missingRevisionOIDsArr = requiredRevisionOIDs.toArray();
            if (missingRevisionOIDsArr.length) {
                // try load missing elements
                return window.Database.GetManyByKeys(Enums.DatabaseStorage.HistoricalElements, missingRevisionOIDsArr, 'IDX_RevisionOID')
                    .then(function(elementsRevisions: Array<Model.Elements.Element>) {
                        for (let i = 0; i < (elementsRevisions || []).length; i++) {
                            const curElement = elementsRevisions[i];
                            resultElements.push(curElement);

                            // uncheck local existing elements
                            requiredRevisionOIDs.delete(curElement.RevisionOID);
                        }

                        // weiterhin fehlende Elemente aus Datenbank lasen
                        if (requiredRevisionOIDs.size() > 0) {
                            const stillLeftRevionsOIDs = requiredRevisionOIDs.toArray();
                            return window.Database.GetManyByKeys(Enums.DatabaseStorage.Elements, stillLeftRevionsOIDs, 'IDX_RevisionOID')
                        }
                    })
                    .then(function(elementsRevisions: Array<Model.Elements.Element>) {
                        for (let i = 0; i < (elementsRevisions || []).length; i++) {
                            const curElement = elementsRevisions[i];
                            resultElements.push(curElement);

                            // uncheck local existing elements
                            requiredRevisionOIDs.delete(curElement.RevisionOID);
                        }

                        // save still missing elements for next sync
                        const stillLeftRevionsOIDs = requiredRevisionOIDs.toArray();
                        const stillMissingElements = [];
                        for (let i = 0; i < stillLeftRevionsOIDs.length; i++) {
                            const revisionOID = stillLeftRevionsOIDs[i];
                            stillMissingElements.push({
                                OID: revisionOID
                            });
                        }

                        // save required element revisions for next sync
                        if (stillMissingElements.length) {
                            window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.RequiredElementOIDs, stillMissingElements);
                        }

                        return resultElements;
                    });
            }
        }

        return defer.resolve(resultElements).promise();
    }

    function updateParameterCounter(incrementBy: number): void {
        if (!$requiredParametersCounter) {
            return;
        }

        if (incrementBy > 0 || $requiredParametersCounter.data('value')) {
            const overallCounter = $requiredParametersCounter.data('value') + incrementBy;

            if (OnRequiredParameterCountChanged) {
                OnRequiredParameterCountChanged(overallCounter, false);
            }

            if (overallCounter > 0) {
                $requiredParametersCounter.parent().removeClass('hidden');
                $requiredParametersCounter.data('value', overallCounter).text(i18next.t('IssueView.UnrecordedParametersLeft', {
                    count: overallCounter
                }));
            } else {
                $requiredParametersCounter.parent().addClass('hidden');
                $requiredParametersCounter.data('value', overallCounter || 0).text('');
            }
        }
    }

    export function UpdateFilteredParameterCounter(resubmissionTreeAlreadyUpdated: boolean = false): void {
        if (!Utils.InArray([Enums.View.Scheduling, Enums.View.Form, Enums.View.FormBatchEdit], View.CurrentView) &&
            !(Session.Mode === Enums.Mode.CoveringPage && View.CurrentView === Enums.View.Inspection)) {
            return;
        }

        if (!currentIssue) {
            return;
        }

        let $footerItem;
        if (($filteredParametersCounter || []).length) {
            $footerItem = $filteredParametersCounter.parent().parent();
        }

        if (!resubmissionTreeAlreadyUpdated) {
            ParameterList.PrepareResubmissionTree(currentIssue.Recorditems);
        }

        if ($footerItem) {
            $footerItem.addClass('hidden');
        }

        const counter = GetFilteredParametersCount();

        if ($footerItem) {
            if (ParameterList.AreFiltersActive()) {
                $footerItem.removeClass('hidden');
                $filteredParametersCounter.data('value', counter.Overall).text(i18next.t('IssueView.UnrecordedParametersLeft', {
                    count: counter.Overall
                }));
            } else {
                $footerItem.addClass('hidden');
                $filteredParametersCounter.data('value', 0).text('');
            }
        }

        if (!Session.Settings.ShowTreeView ||
            View.CurrentView === Enums.View.Form ||
            View.CurrentView === Enums.View.FormBatchEdit) {
            return;
        }

        const tree = App.GetTree();

        if (!tree) {
            return;
        }

        for (let key in counter.Locations) {
            if (!counter.Locations.hasOwnProperty(key)) {
                continue;
            }

            const locationCount = ParameterList.AreFiltersActive() ? counter.Locations[key] : 0;
            const additionalText = locationCount ? ` (${locationCount} <span class="icon-filter"></span>)` : '';

            tree.UpdateAdditionalTextAtNode(key, additionalText);
        }
    }

    function addRecorditemToIssue(recorditem: Model.Recorditem): Deferred {
        if (!recorditem) {
            return $.Deferred().reject();
        }

        const elem = ParameterList.GetElementRevisionByRevisionOID(recorditem.ElementRevisionOID);

        if (recorditem instanceof Model.Recorditem) {
            recorditem = recorditem.GetRawEntity();
        }

        if (!(currentIssue.Recorditems || []).length) {
            currentIssue.Recorditems = [];
        }

        currentIssue.CollectedParameterCount++;
        currentIssue.Recorditems.push(recorditem);

        if (elem && elem.Required) {
            currentIssue.CollectedRequiredParameterCount++;
        }

        if (Session.IsSmartDeviceApplication) {
            return DAL.Issues.AddRecorditems([recorditem], [currentIssue]);
        } else {
            return $.Deferred().resolve();
        }
    }

    function updateRecorditem(recorditem: Model.Recorditem): boolean {
        if (!recorditem) {
            return false;
        }

        if (recorditem instanceof Model.Recorditem) {
            recorditem = recorditem.GetRawEntity();
        }

        // TODO deep compare for changes (another issue)

        // find & remove old revisions
        for (let rCnt = currentIssue.Recorditems.length - 1; rCnt >= 0; rCnt--) {
            const oldRecordItem = currentIssue.Recorditems[rCnt];

            if ((oldRecordItem.ElementOID === recorditem.ElementOID ||
                oldRecordItem.ElementRevisionOID === recorditem.ElementRevisionOID) &&
                (oldRecordItem.Row || 0) === (recorditem.Row || 0)) {
                currentIssue.Recorditems.splice(rCnt, 1);
            }
        }

        currentIssue.Recorditems.push(recorditem);

        return true;
    }

    function removeRecorditemFromIssue(recorditem: Model.Recorditem): Deferred {
        if (!recorditem || !currentIssue ||
            !(currentIssue.Recorditems || []).length) {
            return $.Deferred().reject();
        }

        currentIssue.CollectedParameterCount--;

        const elem = ParameterList.GetElementRevisionByRevisionOID(recorditem.ElementRevisionOID);
        if (elem && elem.Required) {
            currentIssue.CollectedRequiredParameterCount--;
        }

        UpdateStatusQuickSelection();

        return DAL.Issues.RemoveRecorditem(recorditem, currentIssue);
    }

    function updateTree(recorditem: Model.Recorditem, updateIssueParamCount: boolean): Deferred {
        return ParameterList.PrepareResubmissionTree(
            currentIssue.Recorditems,
            View.CurrentView === Enums.View.Scheduling
                ? Session.CurrentLocation.OID
                : null,
            recorditem)
            .then(() => {
                UpdateRequiredParametersCounter();

                if (updateIssueParamCount) {
                    UpdateCurrentIssueParameterCounter(true); // skipResubTreeUpdate kann auf true gesetzt werden, da dies bereits in UpdateRequiredParametersCounter passiert
                }

                const counter = CheckRequiredParametersAtLocation(Session.CurrentLocation, View.CurrentView === Enums.View.Inspection);

                if (counter.RequiredParameterCount) {
                    recordedParametersCount[Session.CurrentLocation.OID] = counter;
                } else if (recordedParametersCount.hasOwnProperty(Session.CurrentLocation.OID)) {
                    delete recordedParametersCount[Session.CurrentLocation.OID];
                }
            });
    }

    function hasRecorditemBeenUpdated(oldRecorditem: Model.Recorditem, newRecorditem: Model.Recorditem): boolean {
        if (!oldRecorditem || !newRecorditem) {
            return false;
        }

        if (oldRecorditem.OID === newRecorditem.PrecedingOID) {
            return true;
        }

        if (oldRecorditem.OID === newRecorditem.OID) {
            const modTimestampOld = new Date(oldRecorditem.ModificationTimestamp);
            const modTimestampNew = new Date(newRecorditem.ModificationTimestamp);

            // TODO maybe use a change counter
            return modTimestampOld.getTime() <= modTimestampNew.getTime();
        }

        return false;
    }

    function setSubsampleResubmissionitems(): void {
        resubSubsampleItems = [];

        for (let riCnt = 0, riLen = currentIssue.Resubmissionitems.length; riCnt < riLen; riCnt++) {
            const resubmissionItem = currentIssue.Resubmissionitems[riCnt];

            if (resubmissionItem.Row != null && resubmissionItem.Row === 1) {
                if (resubmissionItem.Row
                    && !Utils.Where(resubSubsampleItems, 'ElementOID', '===', resubmissionItem.ElementOID)) {
                    resubSubsampleItems.push(resubmissionItem);
                }
            }
        }
    }

    function getCurrentIssueRevisionFromDatabase(): Deferred {
        const deferred = $.Deferred();

        function onAfterIssueRevisionsLoaded(revisions: Model.Issues.RawIssue[]) {
            if (!(revisions || []).length) {
                deferred.reject();
                return;
            }

            revisions.sort(Utils.SortDescendingByRevision);

            deferred.resolve(revisions[0]);
        }

        if (!currentIssue) {
            return deferred.reject().promise();
        }

        if (currentIssue.ID) {
            window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, [currentIssue.ID], 'IDX_ID')
                .then(onAfterIssueRevisionsLoaded)
                .fail(deferred.reject);
        } else {
            window.Database.GetSingleByKey(Enums.DatabaseStorage.Issues, currentIssue.OID)
                .then(function(issue: Model.Issues.RawIssue) {
                    if (!issue || !issue.ID) {
                        deferred.reject();
                        return;
                    }

                    window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, [issue.ID], 'IDX_ID')
                        .then(onAfterIssueRevisionsLoaded)
                        .fail(deferred.reject);
                })
                .fail(deferred.reject);
        }

        return deferred.promise();
    }

    function getCountOfSubIssuesIssues(): number {
        currentIssue.Descendants = IssueReport.GetDescendantsOfIssue();

        if (!(currentIssue.Descendants || []).length) {
            return 0;
        }

        const enabledIssueTypes = [];
        if (Utils.IsMenuItemEnabled(Enums.MenuItemID.TaskReport)) {
            enabledIssueTypes.push(Enums.IssueType.Task,
                Enums.IssueType.Scheduling,
                Enums.IssueType.Form,
                Enums.IssueType.Inspection);
        }

        if (Utils.IsMenuItemEnabled(Enums.MenuItemID.NoteReport)) {
            enabledIssueTypes.push(Enums.IssueType.Note);
        }

        if (Utils.IsMenuItemEnabled(Enums.MenuItemID.DisturbanceReport)) {
            enabledIssueTypes.push(Enums.IssueType.Disturbance);
        }

        if (!enabledIssueTypes.length) {
            return (currentIssue.Descendants || []).length;
        }

        let subIssueCounter = 0;
        for (let iCnt = 0, iLen = currentIssue.Descendants.length; iCnt < iLen; iCnt++) {
            const issue = currentIssue.Descendants[iCnt];

            if (Utils.InArray(enabledIssueTypes, issue.Type) && !issue.FollowerOID) {
                subIssueCounter++;
            }
        }

        return subIssueCounter;
    }

    function getChildIssues(): Array<Model.Issues.Issue> {
        if (+currentIssue.ID) {
            return DAL.Issues.GetChildrenOfID(currentIssue.ID);
        }

        let identifiers = [currentIssue.OID];

        if ((currentIssue.PreviousRevisionIdentifiers || []).length) {
            identifiers = identifiers.concat(currentIssue.PreviousRevisionIdentifiers);
        }

        return DAL.Issues.GetChildrenOfOIDs(identifiers);
    }

    function setRecordingLockState(checkGroupElement: Model.Elements.Element, newStateIsLocked: boolean): Deferred {
        if (!checkGroupElement || !checkGroupElement.IsRecordingLockable || !currentIssue || !(currentIssue.Resubmissionitems || []).length) {
            return $.Deferred().resolve();
        }

        if (checkGroupElement.Type >= 100 && checkGroupElement.Parent) {
            checkGroupElement = checkGroupElement.Parent;
        }

        if (!checkGroupElement.IsRecordingLockable) {
            return $.Deferred().resolve();
        }

        Utils.Spinner.Show();

        const elementOIDs = [];
        elementOIDs.push(checkGroupElement.RevisionOID);

        (checkGroupElement.Parameters || []).forEach(function(param) {
            elementOIDs.push(param.RevisionOID);
        });

        checkGroupElement.DontAskForUnlockRecording = false;

        const tmpRecorditems = currentIssue.Recorditems;
        const tmpResubmissionitems = currentIssue.Resubmissionitems;
        currentIssue.Resubmissionitems = [];

        for (let rCnt = 0, rLen = tmpResubmissionitems.length; rCnt < rLen; rCnt++) {
            const resubItem = tmpResubmissionitems[rCnt];

            if ((resubItem.Row || null) !== (checkGroupElement.Row || null)) {
                continue;
            }

            if (Utils.InArray(elementOIDs, resubItem.ElementRevisionOID)) {
                resubItem.IsRecordingLocked = newStateIsLocked;

                currentIssue.Resubmissionitems.push(resubItem);
            }
        }

        if (Session.IsSmartDeviceApplication) {
            currentIssue.Resubmissionitems = tmpResubmissionitems;

            return DAL.Issues.SaveToDatabase(<any>(currentIssue.CopyRaw ? currentIssue.CopyRaw() : currentIssue), !!currentIssue.CopyRaw)
                .then(function() {
                    if (newStateIsLocked) {
                        // alle nicht synchronisierten Recorditems in der Prüfgruppe als Abhängigkeit hinzufügen
                        const probablyUnsyncedRecorditems = [];
                        for (let i = 0; i < (currentIssue.Recorditems || []).length; i++) {
                            const rec = currentIssue.Recorditems[i];

                            if (!checkGroupElement.Parameters.some((param) => param.OID == rec.ElementOID && (param.Row || 0) == (rec.Row || 0))) {
                                continue;
                            }

                            // potentielle Abhängigkeiten
                            probablyUnsyncedRecorditems.push(rec.OID);
                        }

                        return window.Database.GetManyByKeys(Enums.DatabaseStorage.SyncEntities, probablyUnsyncedRecorditems)
                            .then((entities: any[]) => {
                                if (!entities || !entities.length) {
                                    return null;
                                }

                                return entities.map((x) => x.OID);
                            });

                        // TODO evtl neue, unsynchronisierte Teilproben beachten
                    }
                    // TODO Recorditems müssen Abhängigkeit zum Entsperren der Prüfgruppe aufbauen

                    return null;
                })
                .then(function(dependencies: string[]) {
                    // Abhängigkeit zu evtl. Ent-/Sperren der Prüfgruppe
                    return GetLastLockStateSyncEntity(checkGroupElement, currentIssue.OID, !newStateIsLocked)
                        .then((entity: string) => {
                            if (entity) {
                                dependencies = dependencies || [];
                                dependencies.push(entity);
                            }

                            return dependencies;
                        });
                })
                .then(function(dependencies: string[]) {
                    const syncEntity = new Model.Synchronisation.UnLockEntityDescription(currentIssue, checkGroupElement, newStateIsLocked, dependencies);

                    return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, syncEntity)
                        .then(function() {
                            Utils.Spinner.Hide();

                            ParameterList.RecordingLockStateToggled();
                            ParameterList.ScrollTo(checkGroupElement.OID, checkGroupElement.Row);
                        });
                });
        } else {
            return Utils.Http.Put('issues/' + currentIssue.OID, DAL.Issues.PrepareForSync(currentIssue))
                .then(function() {
                    currentIssue = DAL.Issues.PrepareIssue(currentIssue);
                    currentIssue.Recorditems = tmpRecorditems;
                    currentIssue.Resubmissionitems = tmpResubmissionitems;

                    Utils.Spinner.Hide();

                    ParameterList.RecordingLockStateToggled();
                    ParameterList.ScrollTo(checkGroupElement.OID, checkGroupElement.Row);
                });
        }
    }

    export function GetLastLockStateSyncEntity(checkGroupElement: Model.Elements.Element, issueOID: string, lockstate: boolean): Deferred {
        return window.Database.GetManyByKeys(Enums.DatabaseStorage.SyncEntities, [Enums.SyncEntityType.RecordingLockState], 'IDX_Type')
            .then((entities: Model.Synchronisation.IUnLockEntityDescription[]) => {
                if (!entities || !entities.length) {
                    return null;
                }

                // Sortierung absteigend nach Timestamp
                entities.sort((a: Model.Synchronisation.IUnLockEntityDescription, b: Model.Synchronisation.IUnLockEntityDescription) => {
                    if (a.Timestamp == b.Timestamp) {
                        return b.Type - a.Type;
                    }

                    return a.Timestamp > b.Timestamp ? -1 : 1;
                });

                // Aktuellstes SyncEntity steht weiter oben, erster Treffer sollte Voraussetzung ergeben
                for (let i = 0; i < entities.length; i++) {
                    const entity = entities[i];

                    if (entity.IssueOID == issueOID &&
                        entity.ElementOID == checkGroupElement.OID &&
                        (entity.ElementRow || 0) == (checkGroupElement.Row || 0) &&
                        entity.IsLocked == lockstate) {
                        return entity.OID;
                    }
                }

                return null;
            });
    }

    export function IsRecordingLocked(element: Model.Elements.Element): boolean {
        if (!element || !currentIssue || !(currentIssue.Resubmissionitems || []).length) {
            return false;
        }

        if (element.Type >= 100 && element.Parent) {
            element = element.Parent;
        }

        if (!element.IsRecordingLockable) {
            return false;
        }

        for (let rCnt = 0, rLen = currentIssue.Resubmissionitems.length; rCnt < rLen; rCnt++) {
            const resubItem = currentIssue.Resubmissionitems[rCnt];

            if (resubItem.ElementRevisionOID === element.RevisionOID && (resubItem.Row || null) === (element.Row || null)) {
                return resubItem.IsRecordingLocked || false;
            }
        }

        return false;
    }

    function userCanUnlockRecording(element: Model.Elements.Element): boolean {
        if (!element) {
            return false;
        }

        const locationIdentifier = Utils.InArray([Enums.IssueType.Form, Enums.IssueType.Inspection], currentIssue.Type) ?
            currentIssue.AssignedElementOID :
            Session.CurrentLocation.OID;

        const userHasRightToUnlockAtLocation = Utils.UserHasRight(
            Session.User.OID,
            Enums.Rights.UnlockRecordings,
            true,
            DAL.Elements.GetByOID(locationIdentifier)
        );

        if (!element.AdditionalSettings ||
            !(element.AdditionalSettings.RolesThatMayUnlockRecording || []).length) {
            return userHasRightToUnlockAtLocation;
        }

        const rolesThatMayUnlock = element.AdditionalSettings.RolesThatMayUnlockRecording;
        const userRolesAtLocation = Utils.GetUserRoles(locationIdentifier);

        return userHasRightToUnlockAtLocation && Utils.HasIntersection(rolesThatMayUnlock, userRolesAtLocation);
    }

    export function ToggleRecordingLockState(element: Model.Elements.Element, callback?: Function, onLockCallback?: Function): void {
        if (!element ||
            element.Type < 93 ||
            !currentIssue ||
            !(currentIssue.Resubmissionitems || []).length) {
            return;
        }

        if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            Utils.ShowAppIsReadOnlyDuringSynchronisationMessage();
            return;
        }

        if (!callback) {
            callback = $.noop;
        }

        if (!IsRecordingLocked(element)) {
            Utils.Message.Show(i18next.t('IssueViewer.LockRecording.MessageHeader'),
                i18next.t('IssueViewer.LockRecording.MessageBody'),
                {
                    Yes: function() {
                        element.DontAskForUnlockRecording = false;

                        setRecordingLockState(element, true)
                            .then(() => {
                                if (element.AdditionalSettings && element.AdditionalSettings.SubsampleCanBeDeleted) {
                                    element.AdditionalSettings.SubsampleCanBeDeleted = false;
                                    onLockCallback();
                                }

                                callback();
                            }, () => {
                                throw new Error('Error while locking recording!');
                            });
                    },
                    No: callback
                });
        } else if (userCanUnlockRecording(element)) {
            Utils.Message.Show(i18next.t('IssueViewer.UnlockRecording.MessageHeader'),
                i18next.t('IssueViewer.UnlockRecording.MessageBody'),
                {
                    Yes: function() {
                        setRecordingLockState(element, false)
                            .then(() => {
                                callback()
                            })
                            .fail(function(_response, _state, _error) {
                                if (Utils.Spinner.IsVisible()) {
                                    Utils.Spinner.Hide();
                                }

                                throw new Model.Errors.HttpError(_error, _response);
                            });
                    },
                    No: function() {
                        element.DontAskForUnlockRecording = true;

                        callback();
                    }
                }, null, 15001);
        } else {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.UnlockRecording.MessageBody'),
                {
                    Close: function() {
                        element.DontAskForUnlockRecording = true;

                        callback();
                    }
                });
        }
    }

    export function Update(): void {
        Utils.Spinner.Show();

        viewUpdateInformation = {
            Mode: Session.Mode,
            Location: Session.CurrentLocation
        };

        function onFail(): void {
            if (View.CurrentView !== Enums.View.Main) {
                Utils.Router.OpenDefaultRoute();
            }
        }

        getCurrentIssueRevisionFromDatabase()
            .then(Init)
            .fail(function() {
                Utils.Spinner.Hide();
                Utils.Message.Show(
                    i18next.t('Misc.IssueNotFound.MessageHeader'),
                    i18next.t('Misc.IssueNotFound.IssueArchivedOrDeleted'),
                    {
                        Close: onFail
                    }
                );

                Clear();
                onFail();
            });
    }

    export function Init(rawIssue: Model.Issues.RawIssue, onBeforeInit?: (issue: Model.Issues.Issue) => void): Deferred {
        if (!rawIssue) {
            Utils.Spinner.Hide();
            return $.Deferred().reject();
        }

        const showSpinner = !!currentIssue && currentIssue.ID == rawIssue.ID;
        let issueLock: Utils.Spinner.SpinnerLock;
        if (showSpinner) {
            // blockiere bearbeiten/hinzufügen von Teilproben bis Aktualisierung abgeschlossen
            Utils.Spinner.Show();
            issueLock = Utils.Spinner.Lock("issUpd");
        }

        OnRequiredParameterCountChanged = null;
        OnBeforeAutosetFollowerState = null;
        OnBeforeUpdateState = null;

        const issue = new Model.Issues.Issue(rawIssue);

        Clear(true, true);

        recordedParametersCount = {};

        if (!issue.ID) {
            issue.ID = 0;
        }

        currentIssue = issue;

        // closeDeferred nur bei neuen Vorgängen neu erstellen
        if (!showSpinner || !closeDeferred || closeDeferred.state() === 'pending') {
            closeDeferred = $.Deferred();
        }

        if (Utils.InArray([Enums.ElementType.Root, Enums.ElementType.Location], Session.CurrentLocation.Type)) {
            previouslySelectedLocationOID = Session.CurrentLocation.OID;
        }

        if (!previouslySelectedLocationOID) {
            previouslySelectedLocationOID = currentIssue.AssignedElementOID;
        }

        if (Session.IsSmartDeviceApplication &&
            currentIssue.Type === Enums.IssueType.Inspection) {
            currentIssue.Descendants = getChildIssues();
        }

        ParameterList.ResetFilters();

        if (currentIssue.Type === Enums.IssueType.Inspection) {
            IssueReport.ResetFilters(true);
        }

        if (onBeforeInit) {
            onBeforeInit.call(IssueView, currentIssue);
        }

        if ($requiredParametersCounter) {
            $requiredParametersCounter.off();
        }

        $toolbar = $('.footer-toolbar');
        $requiredParametersCounter = $toolbar.find('.parameter-counter');
        $filteredParametersCounter = $toolbar.find('.filtered-parameter .counter');

        if (currentIssue.Type === Enums.IssueType.Inspection) {
            if (Utils.IsIssueLocked(currentIssue)) {
                $toolbar.find('.add-photo-note, .add-voice-mail, .add-issue').addClass('hidden');
            } else {
                $toolbar.find('.add-photo-note, .add-voice-mail, .add-issue').removeClass('hidden');
            }

            $btnShowCoveringPage = $toolbar.find('.show-covering-page');
            $btnShowIssues = $toolbar.find('.show-issues');

            const subIssueCount = getCountOfSubIssuesIssues();

            $btnShowIssues.find('.badge').text(subIssueCount);

            const $switchInspectionView = $toolbar.find('.switch-inspection-view');

            if (Session.Mode === Enums.Mode.IssueReport) {
                $switchInspectionView
                    .find('> .badge')
                    .removeClass('hidden')
                    .text(subIssueCount);
            } else {
                $switchInspectionView
                    .find('> .badge')
                    .addClass('hidden')
                    .text(subIssueCount);
            }

            $switchInspectionView
                .find('li[data-view="issues"] .badge')
                .text(subIssueCount);
        }

        $requiredParametersCounter.on('click', () => {
            ParameterList.QuickFilterUnrecorded();
        });

        let getRevisionsDeferred: Deferred;
        if ((currentIssue.Resubmissionitems || []).length) {
            if (Utils.InArray([Enums.IssueType.Form, Enums.IssueType.Inspection, Enums.IssueType.Investigation, Enums.IssueType.Survey], currentIssue.Type)) {
                if (currentIssue.Type !== Enums.IssueType.Inspection) {
                    Session.CurrentLocation = DAL.Elements.GetByOID(currentIssue.AssignedFormOID) ||
                        <Model.Elements.Element>{ OID: currentIssue.AssignedFormOID, Type: Enums.ElementType.Form };
                }

                setSubsampleResubmissionitems();
            }

            if ((currentIssue.Recorditems || []).length) {
                currentIssue.Recorditems = Utils.PrepareRecorditems(currentIssue.Recorditems);
            }

            getRevisionsDeferred = Session.IsSmartDeviceApplication ?
                getRevisionsFromDatabase() : getRevisionsFromService();

            getRevisionsDeferred = getRevisionsDeferred
                .then(onAfterResubRevisionsLoaded);
        } else {
            getRevisionsDeferred = $.Deferred().resolve();
        }

        if (showSpinner) {
            // Spinner wieder ausblenden
            getRevisionsDeferred = getRevisionsDeferred
                .always(() => {
                    issueLock.Unlock();
                    Utils.Spinner.HideWithTimeout();
                });
        }

        return getRevisionsDeferred;
    }

    export function AddSubsamples(subsampleInformations: Array<{ GroupOID: string, MaxRow: number }>, ignoreEmptyRows: boolean): Deferred {
        const deferred = $.Deferred();

        if (!(subsampleInformations || []).length) {
            return deferred.resolve().promise();
        }

        if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            Utils.ShowAppIsReadOnlyDuringSynchronisationMessage();
            return deferred.reject().promise();
        }

        const tmpRecorditems = $.extend(true, [], currentIssue.Recorditems);
        const newResubmissionitems = [];
        const syncEntities = [];
        const newSubsampleRows = [];

        for (let information of subsampleInformations) {
            const lastExistingRow = getMaxGroupRow(information.GroupOID);
            const group = ParameterList.GetElement(information.GroupOID, lastExistingRow, true);

            if (IsRecordingLocked(group)) {
                continue;
            }

            if (!ignoreEmptyRows && !GetRecorditemsForRow(information.GroupOID, lastExistingRow).length) {
                continue;
            }

            for (let newSubsampleRow = lastExistingRow + 1; newSubsampleRow <= information.MaxRow; newSubsampleRow++) {
                if (group.MaxSubsampleCount != null && newSubsampleRow > group.MaxSubsampleCount) {
                    break;
                }

                newResubmissionitems.push(...getNewResubmissionitems(information.GroupOID, newSubsampleRow));
                newSubsampleRows.push({ GroupOID: information.GroupOID, Row: newSubsampleRow });

                if (Session.IsSmartDeviceApplication) {
                    syncEntities.push(
                        new Model.Synchronisation.SubSampleUpdateEntityDescription(
                            `${currentIssue.OID}-${information.GroupOID}-${newSubsampleRow}`,
                            currentIssue
                        )
                    );
                }
            }
        }

        if (!newResubmissionitems.length) {
            return deferred.resolve().promise();
        }

        Utils.Spinner.Show();

        dontAskAgainForFinishing = false;
        currentIssue.Resubmissionitems = currentIssue.Resubmissionitems.concat(newResubmissionitems);

        if (Session.IsSmartDeviceApplication) {
            DAL.Issues.SaveToDatabase(<any>(currentIssue.CopyRaw ? currentIssue.CopyRaw() : currentIssue), !!currentIssue.CopyRaw)
                .then(() => {
                    window.Database
                        .SetInStorage(Enums.DatabaseStorage.SyncEntities, syncEntities)
                        .then(() => {
                            onAfterIssueSaved(tmpRecorditems, newSubsampleRows);
                            deferred.resolve();
                        }, deferred.reject);
                }, deferred.reject);
        } else {
            Utils.Http
                .Put('issues/' + currentIssue.OID, DAL.Issues.PrepareForSync(currentIssue))
                .then(() => {
                    onAfterIssueSaved(tmpRecorditems, newSubsampleRows);
                    deferred.resolve();
                }, deferred.reject);
        }

        return deferred.promise();
    }

    export function AddSubsample(groupOID: string): Deferred {
        const deferred = $.Deferred();

        if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            Utils.ShowAppIsReadOnlyDuringSynchronisationMessage();
            return deferred.reject().promise();
        }

        if (currentIssue && currentIssue.IsLocked) {
            Utils.Message.Show(i18next.t('IssueView.IssueIsLocked.MessageHeader'),
                i18next.t('IssueView.IssueIsLocked.MessageBody'),
                {
                    Close: true
                });

            return deferred.reject().promise();
        }

        const newSubsampleRow = getMaxGroupRow(groupOID) + 1;
        const group = ParameterList.GetElement(groupOID, newSubsampleRow - 1, true);

        if (!group) {
            return deferred.reject().promise();
        }

        if (IsRecordingLocked(group)) {
            Utils.Message.Show(i18next.t('Forms.SubSampleLocked.MessageHeader'),
                i18next.t('Forms.SubSampleLocked.MessageBody'),
                { Close: true }
            );

            return deferred.reject().promise();
        }

        if (group.MaxSubsampleCount != null && newSubsampleRow > group.MaxSubsampleCount) {
            Utils.Message.Show(i18next.t('Forms.SubsampleLimitReached.MessageHeader'),
                i18next.t('Forms.SubsampleLimitReached.MessageBody'),
                { Close: true }
            );

            return deferred.reject().promise();
        }

        const tmpRecorditems = $.extend(true, [], currentIssue.Recorditems);
        const newResubmissionitems = getNewResubmissionitems(groupOID, newSubsampleRow);

        if (!newResubmissionitems.length) {
            return deferred.resolve().promise();
        }

        Utils.Spinner.Show();
        currentIssue.Resubmissionitems = currentIssue.Resubmissionitems.concat(newResubmissionitems);
        dontAskAgainForFinishing = false;

        function afterSave() {
            onAfterIssueSaved(tmpRecorditems, [{ GroupOID: groupOID, Row: newSubsampleRow }]);
            ParameterList.ScrollTo(groupOID, newSubsampleRow);
            deferred.resolve(group);
        }

        if (Session.IsSmartDeviceApplication) {
            DAL.Issues
                .SaveToDatabase(<any>(currentIssue.CopyRaw ? currentIssue.CopyRaw() : currentIssue), !!currentIssue.CopyRaw)
                .then(function() {
                    const syncEntity = new Model.Synchronisation.SubSampleUpdateEntityDescription(
                        `${currentIssue.OID}-${groupOID}-${newSubsampleRow}`, currentIssue
                    );

                    window.Database
                        .SetInStorage(Enums.DatabaseStorage.SyncEntities, syncEntity)
                        .then(afterSave, deferred.reject);
                }, function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                }, deferred.reject);
        } else {
            Utils.Http
                .Put('issues/' + currentIssue.OID, DAL.Issues.PrepareForSync(currentIssue))
                .then(afterSave, deferred.reject)
                .fail(function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        }

        return deferred.promise();
    }

    function getMaxGroupRow(groupOID: string): number {
        let row = 0;
        const rLen = (currentIssue.Resubmissionitems || []).length;

        for (let rCnt = 0; rCnt < rLen; rCnt++) {
            const resubItem = currentIssue.Resubmissionitems[rCnt];

            if (resubItem.ElementOID === groupOID && resubItem.Row > row) {
                row = resubItem.Row;
            }
        }

        return row;
    }

    function getNewResubmissionitems(groupOID: string, row: number): Model.Issues.ResubmissionItem[] {
        const resubmissionitemTemplates: Model.Issues.ResubmissionItem[] = Utils.Clone(resubSubsampleItems);
        const resubGroup = resubmissionitemTemplates.filter(resubItem => resubItem.ElementOID === groupOID)[0];

        if (!resubGroup) {
            return [];
        }

        const newResubmissionitems = [];
        const resubGroupOID = uuid();
        const parameterResubmissionitems = resubmissionitemTemplates.filter(resubItem => resubItem.ParentOID === resubGroup.OID);

        if (!parameterResubmissionitems.length) {
            return [];
        }

        resubGroup.OID = resubGroupOID;
        resubGroup.Row = row;
        resubGroup.IsRecordingLocked = false;
        newResubmissionitems.push(resubGroup);

        for (let parameterResubmissionitem of parameterResubmissionitems) {
            parameterResubmissionitem.ParentOID = resubGroupOID;
            parameterResubmissionitem.OID = uuid();
            parameterResubmissionitem.Row = row;
            parameterResubmissionitem.IsRecordingLocked = false;

            newResubmissionitems.push(parameterResubmissionitem);
        }

        return newResubmissionitems;
    }

    export function DeleteSubsample(resubGroupOID: any, updateRowState: Function): Deferred {
        const deferred = $.Deferred();

        if (currentIssue == null) {
            return deferred.reject();
        }

        if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.DeleteSubsample)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.DeleteSubsample.MessageBody'),
                {
                    Close: true
                });

            return deferred.reject();
        }

        if (currentIssue.IsLocked) {
            Utils.Message.Show(i18next.t('IssueView.IssueIsLocked.MessageHeader'),
                i18next.t('IssueView.IssueIsLocked.MessageBody'),
                {
                    Close: true
                });

            return deferred.reject();
        }

        const getRevisionFunction = Session.IsSmartDeviceApplication ? getRevisionsFromDatabase : getRevisionsFromService;

        if (Session.IsSmartDeviceApplication) {
            deferred.resolve();
        } else {
            Utils.Http.Delete(`issues/resubmissionitems/${resubGroupOID}`, [])
                .then(deferred.resolve, deferred.reject);
        }

        return deferred
            .then(() => deleteSubsampleResubmissionItems(resubGroupOID, updateRowState))
            .then((subSampleRowToggleStates: Dictionary<boolean>, groupResubmission: Model.Issues.ResubmissionItem) => {
                return onAfterResubmissionitemsDeleted(groupResubmission)
                    .then(() => getRevisionFunction(currentIssue))
                    .then((elements: Array<Model.Elements.Element>) => {
                        onAfterResubRevisionsLoaded(elements, subSampleRowToggleStates);
                    });
            });
    }

    function deleteSubsampleResubmissionItems(resubGroupOID: string, updateRowState: Function): Deferred {
        const tmpResubmissionitems = $.extend(true, [], currentIssue.Resubmissionitems);
        const groupResubmissionItem = tmpResubmissionitems.find((item: Model.Issues.ResubmissionItem) => item.OID === resubGroupOID);

        if (groupResubmissionItem == null) {
            return $.Deferred().reject('Resubmissionitem does not exist');
        }

        const groupResubmissionitemIndex = Utils.GetIndex(tmpResubmissionitems, groupResubmissionItem.OID, 'OID');
        const groupRow = groupResubmissionItem.Row;
        const resubmissionitemOIDs = [];
        tmpResubmissionitems.splice(groupResubmissionitemIndex, 1);

        for (let i = tmpResubmissionitems.length - 1; i >= 0; i--) {
            const resubItem = tmpResubmissionitems[i];

            if (!resubItem || resubItem.Row !== groupRow || resubItem.ParentOID !== groupResubmissionItem.OID) {
                continue;
            }

            resubmissionitemOIDs.push(resubItem.OID);
            tmpResubmissionitems.splice(i, 1);
            i++;
        }

        currentIssue.Resubmissionitems = $.extend(true, [], tmpResubmissionitems);
        currentIssue.ResubmissionitemCollection = new Model.ResubmissionitemCollection(currentIssue.Resubmissionitems);

        const deferred = $.Deferred();

        // subSampleRowToggleStates is needed to show the correct table view after the view update
        const subSampleRowToggleStates = updateRowState();
        locallyExistingElements = null;

        DAL.ScancodeInfos.DeleteFromDatabaseByResubmissionitemOID(resubmissionitemOIDs)
            .then(() => deferred.resolve(subSampleRowToggleStates, groupResubmissionItem));

        return deferred.promise();
    }

    function onAfterResubmissionitemsDeleted(groupResubmissionItem: Model.Issues.ResubmissionItem): Deferred {
        Utils.Spinner.Show();

        UpdateCurrentIssueParameterCounter();
        if (!Session.IsSmartDeviceApplication) {

            return Utils.Http.Put(`issues/${currentIssue.OID}`, DAL.Issues.PrepareForSync(currentIssue))
                .fail(function(_response, _state, _error) {
                    throw new Model.Errors.HttpError(_error, _response);
                });
        }

        const resubSyncIdentifier = `${currentIssue.OID}_${groupResubmissionItem.OID}_${groupResubmissionItem.Row}`;
        const issueSyncIdentifier = `${currentIssue.OID}-${groupResubmissionItem.ElementOID}-${groupResubmissionItem.Row}`;
        const deletedSubsampleSyncIdentifier = resubSyncIdentifier + '_SUBSAMPLEDELETED';
        const subsampleSyncIdentifier = issueSyncIdentifier + '_SUBSAMPLE';

        return DAL.Issues.SaveToDatabase(<any>(currentIssue.CopyRaw ? currentIssue.CopyRaw() : currentIssue), !!currentIssue.CopyRaw)
            .then(() => window.Database.GetSingleByKey(Enums.DatabaseStorage.SyncEntities, subsampleSyncIdentifier))
            .then(function(item: Model.Synchronisation.IEntityDescription) {
                if (item != null) {
                    // if subsample is created on device => delete syncEntity
                    return window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, subsampleSyncIdentifier);
                }

                // do not create SyncEntity if the issue is not synced
                if (currentIssue.ID === 0) {
                    return;
                }

                const syncEntity = new Model.Synchronisation.SubSampleDeleteEntityDescription(
                    deletedSubsampleSyncIdentifier,
                    currentIssue
                );

                return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, syncEntity);
            });
    }

    export function ShowCoveringPage(): void {
        if (currentIssue.Type !== Enums.IssueType.Inspection) {
            return;
        }

        // show barcode scanner buttons
        toggleBarcodeScannerIcons(true);

        onNavigateToLocation();
        ParameterList.Show([
            Enums.ElementType.SingletonFormRow,
            Enums.ElementType.MasterdataGroup,
            Enums.ElementType.FormHeader,
            Enums.ElementType.FormFooter,
            Enums.ElementType.FormRow,
            Enums.ElementType.Parametergroup], coveringPageElements);
    }

    export function GetCurrentIssue(): Model.Issues.Issue {
        return currentIssue || null;
    }

    export function UpdateSubIssues(counter: number, additionalSubIssues: Array<Model.Issues.Issue>): Array<Model.Issues.Issue> {
        currentIssue.AdditionalData = currentIssue.AdditionalData || {
            SubIssueCounter: 0
        };

        if (!(additionalSubIssues || []).length) {
            return currentIssue.Descendants;
        }

        currentIssue.AdditionalData.SubIssueCounter = counter;
        currentIssue.Descendants = currentIssue.Descendants || [];

        for (let iCnt = 0, iLen = additionalSubIssues.length; iCnt < iLen; iCnt++) {
            const issue = additionalSubIssues[iCnt];

            if (Utils.FindByPredicate(currentIssue.Descendants, function(descendant) {
                return descendant.OID === issue.OID;
            })) {
                continue;
            }

            currentIssue.Descendants.push(issue);
        }

        if (View.CurrentView === Enums.View.Inspection) {
            const subIssueCount = getCountOfSubIssuesIssues();

            $btnShowIssues.find('.badge').text(subIssueCount);

            const $switchInspectionView = $toolbar.find('.switch-inspection-view');

            if (Session.Mode === Enums.Mode.IssueReport) {
                $switchInspectionView
                    .find('> .badge')
                    .removeClass('hidden')
                    .text(subIssueCount);
            } else {
                $switchInspectionView
                    .find('> .badge')
                    .addClass('hidden')
                    .text(subIssueCount);
            }

            $switchInspectionView
                .find('li[data-view="issues"] .badge')
                .text(subIssueCount);
        }

        return currentIssue.Descendants;
    }

    export function ReplaceSubIssue(issue: Model.Issues.Issue): void {
        if (!issue.PrecedingOID || !(currentIssue.Descendants || []).length) {
            return;
        }

        let idx = -1;
        for (let iCnt = 0, iLen = currentIssue.Descendants.length; iCnt < iLen; iCnt++) {
            const i = currentIssue.Descendants[iCnt];

            if (i.OID === issue.PrecedingOID) {
                idx = iCnt;
                break;
            }
        }

        if (idx > -1) {
            currentIssue.Descendants.splice(idx, 1);
            currentIssue.Descendants.push(issue);
        }
    }

    export function GetResubmissionItems(): Array<string> {
        if (!currentIssue || !(currentIssue.Resubmissionitems || []).length) {
            return null;
        }

        const resubmissionitems = [];

        for (const resubItem of currentIssue.Resubmissionitems) {
            resubmissionitems.push(resubItem.ElementOID);
        }

        return resubmissionitems;
    }

    export function SetRecorditem(recorditem: Model.Recorditem, skipUpdateTree?: boolean): Deferred {
        let updateIssueParamCount = false;
        let deferredQueue: Deferred = $.Deferred().resolve();

        if (recorditem.IsDeleted) {
            // recordItem aus dem Issue lösen
            updateIssueParamCount = true;
            deferredQueue = deferredQueue
                .then(() => removeRecorditemFromIssue(recorditem));
        } else {
            let recItemUpdated: boolean = false,
                rCnt: number,
                rLen: number;
            if ((currentIssue.Recorditems || []).length) {
                for (rCnt = 0, rLen = currentIssue.Recorditems.length; rCnt < rLen; rCnt++) {
                    const oldRecordItem = currentIssue.Recorditems[rCnt];

                    if (hasRecorditemBeenUpdated(oldRecordItem, recorditem)) {
                        recItemUpdated = true;

                        break;
                    }
                }
            }

            // check if changes exist. before, storing to database failed
            if (!recItemUpdated) {
                updateIssueParamCount = true;
                deferredQueue = deferredQueue
                    .then(() => addRecorditemToIssue(recorditem));
            } else if (!updateRecorditem(recorditem)) {
                return deferredQueue;
            }
        }

        // update tree parameter count
        if (!skipUpdateTree) {
            deferredQueue = deferredQueue
                .then(() => updateTree(recorditem, updateIssueParamCount));
        }

        if (Session.IsSmartDeviceApplication) {
            deferredQueue = deferredQueue
                .then(() => DAL.Issues.SaveToDatabase(<any>(currentIssue.CopyRaw ? currentIssue.CopyRaw() : currentIssue), !!currentIssue.CopyRaw));
        }

        return deferredQueue;
    }

    export function UpdateRequiredParametersCounter(): void {
        if (View.CurrentView == Enums.View.Form || View.CurrentView == Enums.View.FormBatchEdit || View.CurrentView == Enums.View.Inspection) {
            const requiredParametersCount = GetUnfinishedParametersCount();
            updateParameterCounter(requiredParametersCount - $requiredParametersCounter.data('value'));
        } else if (View.CurrentView === Enums.View.Scheduling) {
            const newRequiredParametersInformation = CheckRequiredParametersAtLocation(Session.CurrentLocation, false);
            const requiredParametersCount = recordedParametersCount.hasOwnProperty(Session.CurrentLocation.OID)
                ? recordedParametersCount[Session.CurrentLocation.OID]
                : { RecordedParametersCount: 0, RequiredParameterCount: 0 };

            let incrementBy = requiredParametersCount.RecordedParametersCount - newRequiredParametersInformation.RecordedParametersCount;

            if (requiredParametersCount.RequiredParameterCount !== newRequiredParametersInformation.RequiredParameterCount) {
                incrementBy += newRequiredParametersInformation.RequiredParameterCount - requiredParametersCount.RequiredParameterCount;
            }

            updateParameterCounter(incrementBy);

            recordedParametersCount[Session.CurrentLocation.OID] = newRequiredParametersInformation;
        }
    }

    export function RecreateSidebarIndex(parameterGroups: Array<Model.Elements.Element>): void {
        if (!($issueInfoSidebar instanceof $)) {
            return;
        }

        const templateContext = getSidebarTemplateContext(parameterGroups);
        const $index = $(Templates.IssueInformationSidebar.Index(templateContext));
        const $existingIndex = $issueInfoSidebar.find('.index');

        $existingIndex.find('.content')
            .replaceWith($index.find('.content'));

        bindSidebarEvents(templateContext.Rights, templateContext.Issue.Files);

        parameterGroups.forEach(pg => UpdateSidebarIndex(pg.OID));
    }

    export function UpdateSidebar(): void {
        if (!($issueInfoSidebar instanceof $)) {
            return;
        }

        const sidebarInlineMode = shouldSidebarBeInline();
        $issueInfoSidebar.toggleClass('inline', sidebarInlineMode);

        setFooterToolbarButtonsVisibility();
        updateContentWidth($('#content'));
    }

    export function UpdateSidebarIndex(groupOID: string): void {
        if (!($issueInfoSidebar instanceof $)) {
            return;
        }

        // Update visible chapters
        const $chapters = $issueInfoSidebar.find('.chapter');

        $chapters.toArray().forEach((elChapter: Model.Elements.Element) => {
            const $chapter = $(elChapter);
            const chapterIdent = $chapter.data('chapter');
            const chapter = ParameterList.GetElementsIndependentOfRow(chapterIdent)[0];

            if (!chapter) {
                return;
            }

            $chapter.toggleClass('hidden', chapter.MissingRequirements);
        });

        // TODO: Counter für Teilproben

        const $entry = $issueInfoSidebar.find(`[data-chapter="${groupOID}"]`);

        if (!$entry.length) {
            return;
        }

        const groupElements = ParameterList.GetElementsIndependentOfRow(groupOID);

        if (!groupElements.length) {
            return;
        }

        let text = groupElements[0].Title;

        let recordedCounter = 0;
        let requiredCounter = 0;

        groupElements.forEach(group => {
            if (group.MissingRequirements) {
                return;
            }

            (group.Parameters || []).forEach(param => {
                if (!param.Required || param.MissingRequirements) {
                    return;
                }

                requiredCounter += 1;

                if (param.LastRecorditem != null && !param.LastRecorditem.IsDummy) {
                    recordedCounter += 1;
                }
            });
        });

        if (requiredCounter > 0 && requiredCounter !== recordedCounter) {
            text += Utils.EscapeHTMLEntities(` (${recordedCounter} / ${requiredCounter})`);
        }

        $entry.html(text);

        $entry.removeClass('pending partially complete');

        if (recordedCounter > 0) {
            if (recordedCounter === requiredCounter) {
                $entry.addClass('complete');
            } else {
                $entry.addClass('partially');
            }
        } else if (requiredCounter > 0) {
            $entry.addClass('pending');
        } else {
            $entry.addClass('complete');
        }
    }

    export function Clear(rememberLocation?: boolean, holdViewUpdateInformation?: boolean): void {
        if (!currentIssue) {
            return;
        }

        if (!rememberLocation) {
            App.GetAndSelectLocation(!DAL.Elements.Exists(previouslySelectedLocationOID) ? Session.CurrentLocation.OID : previouslySelectedLocationOID);
        }

        $toolbar = null;
        $contentHeader = null;
        $btnShowCoveringPage = null;
        $btnShowIssues = null;

        currentIssue = null;
        previouslySelectedLocationOID = null;
        resubSubsampleItems = null;
        requiredParameters = null;
        dontAskAgainForFinishing = false;
        recordedParametersCount = null;
        coveringPageElements = null;
        locallyExistingElements = null;

        if (!holdViewUpdateInformation) {
            viewUpdateInformation = null;
        }

        ParameterList.ClearElementRevisions();
        ParameterList.ClearIssueFormulaDependicies();

        // clear close deferred
        if (closeDeferred) {
            closeDeferred.resolve();
            closeDeferred = null;
        }
    }

    export function GetResubmissionitemOID(elementOID: string, row?: number): string {
        if (currentIssue && currentIssue.Resubmissionitems != null) {
            for (let rCnt = 0, rLen = currentIssue.Resubmissionitems.length; rCnt < rLen; rCnt++) {
                const resubmissionItem = currentIssue.Resubmissionitems[rCnt];

                if (resubmissionItem.ElementOID === elementOID
                    && (typeof resubmissionItem.Row === 'undefined' || resubmissionItem.Row === null
                        || resubmissionItem.Row === row)) {
                    return resubmissionItem.OID;
                }
            }
        }

        return null;
    }

    export function GetRecordedParametersCount(): Model.Parameters.IParameterCounter {
        return recordedParametersCount;
    }

    export function GetCoveringPageParameterCount(): number {
        if ((coveringPageElements || []).length) {
            return coveringPageElements
                .filter(e => e.Type >= 100 && e.Enabled)
                .length;
        }

        return 0;
    }

    export function AskForRecordingGroupUnlock(element: Model.Elements.Element, callback: Function): void {
        if (!Utils.IsIssueEditableDuringSync(currentIssue)) {
            Utils.ShowAppIsReadOnlyDuringSynchronisationMessage();
            return;
        }

        if (element.Type >= 100 && element.Parent) {
            element = element.Parent;
        }

        if (element.DontAskForUnlockRecording) {
            callback();
        } else {
            ToggleRecordingLockState(element, callback);
        }
    }

    export function Resize(): void {
        if ($toolbar == null) {
            return;
        }

        const issueSidebarIsVisible = isIssueSidebarVisible();

        if (issueSidebarIsVisible) {
            $issueInfoSidebar.removeClass('open'); // falls die Sidebar über den Button eingeblendet wurde
            const $statusSelection = $issueInfoSidebar.find('.status-quick-selection');

            if ($statusSelection != null) {
                $statusSelection.removeClass('hidden');

                if (($statusSelection.find('ul').children() || []).length === 0) {
                    UpdateStatusQuickSelection();
                }
            }
        }

        if (toolbarQuickFollowStateButtons != null) {
            // toolbarQuickFollowStateButtons nicht entfernen, im Falle dass
            // die Sidebar durch ausblenden des Trees wieder eingeblendet wird
            toolbarQuickFollowStateButtons.Resize();

            setFooterToolbarButtonsVisibility();
        } else if (View.CurrentView === Enums.View.Inspection || View.CurrentView === Enums.View.Scheduling ||
            !issueSidebarIsVisible) {
            UpdateStatusQuickSelection();
        }

        updateContentWidth($('#content'));
    }
}
