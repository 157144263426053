//imports-start
/// <reference path="./schema-property.ts"  />
//imports-end

module Model.IndividualData {
    export class Schema {
        Deleted: boolean;
        NameSingular: string;
        NamePlural: string;
        Type: string;
        URI: string;
        Display: string;
        SelectionPattern: string;
        Print: string;
        Commit: boolean;
        IsReadonly: boolean;
        Properties: Array<SchemaProperty>;
        SearchFields: Array<string>;
    }
}