module Model.Issues {
    export class ResubmissionItem {
        OID: string;
        ParentOID?: string;
        ElementOID: string;
        ElementRevisionOID: string;
        Row?: number;
        IsRecordingLocked: boolean;
        Parent?: ResubmissionItem;
    }
}