//imports-start
/// <reference path="./raw-issue.ts"  />
/// <reference path="./issue-type.ts"  />
/// <reference path="./raw-file.ts"  />
/// <reference path="./resubmission-item.ts"  />
/// <reference path="./file.ts"  />
/// <reference path="./location-marker.ts" />
/// <reference path="./processing-information.ts"  />
/// <reference path="../model.errors.ts"  />
/// <reference path="../model.temporary-issue.ts"  />
/// <reference path="../properties/property.ts"  />
/// <reference path="../scheduling/scheduling.ts"  />
/// <reference path="../../app/app.parameter-list.ts"  />
/// <reference path="../../app/app.session.ts"  />
/// <reference path="../../dal/scheduling.ts"  />
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../enums.ts"  />
//imports-end

module Model.Issues {
    export class Issue implements IssueType {
        OID: string;
        Type: number;
        Revision: number;
        Identifier: any;
        CreatorOID: string;
        EditorOID: string;
        CreationTimestamp: Date;
        ModificationTimestamp: Date;
        AssignedElementOID: string;
        IsArchived: boolean;
        ProgressState: any;
        HasNotBeenSynced: boolean;

        ID?: number;
        ParentID?: number;
        ParentOID?: string;
        PrecedingOID?: string;
        FollowerOID?: string;
        CustomID?: string;
        IsTemplate?: boolean;
        TemplateID?: string;
        Title?: string;
        Description?: string;
        RemindingTimestamp?: Date;
        DeadlineTimestamp?: Date;
        TerminationTimestamp?: Date;
        StateOID?: string;
        StateRevisionOID?: string;
        PriorityOID?: string;
        PriorityRevisionOID?: string;
        AssignedActionOID?: string;
        AssignedElementRevisionOID?: string;
        AssignedFormOID?: string;
        AssignedFormRevisionOID?: string;
        AssignedMeasureOID?: string;
        AssignedMeasureRevisionOID?: string;
        AssignedSchedulingOID?: string;
        AssignedSchedulingRevisionOID?: string;
        AssignedRecorditemOID?: string;
        AssignedRecorditemID?: number;
        /** available since RestService API v14 */
        ResponsibilityAssignments?: ResponsibilityAssignments;
        /** @deprecated since RestService API v14 */
        Teams?: Array<string>;
        /** @deprecated since RestService API v14 */
        Users?: Array<string>;
        /** @deprecated since RestService API v14 */
        Contacts?: Array<string>;
        Classifications?: Array<string>;
        Keywords?: Array<string>;
        Files?: Array<File>;
        TemporaryFilesMarkup: Array<string>;
        LocationMarkers?: Array<LocationMarker>;
        ChangeComment?: string;
        Ancestors?: Array<Issue>;
        Descendants?: Array<Issue>;
        Revisions?: Array<Issue>;
        Resubmissionitems?: Array<ResubmissionItem>;
        Recorditems?: Array<Model.Recorditem>;
        ParameterCount?: number;
        RequiredParameterCount?: number;
        CollectedParameterCount?: number;
        CollectedRequiredParameterCount?: number;
        AdditionalData?: {
            CheckpointPreviewImages?: Dictionary<any>,
            SubIssueCounter?: number,
            Associations?: Dictionary<any>,
            IsIssueTitleOverwritten?: boolean
        };
        IsDeleted?: boolean;
        EstimatedEffort?: number;
        IsTemporary: boolean;

        Room?: any;
        Form?: any;
        State?: Model.Properties.Property;
        Priority?: Model.Properties.Property;
        Scheduling?: Model.Scheduling.Scheduling;
        ResubmissionitemCollection?: any;
        Comments?: Array<any>
        IssueCellMarkerClass?: string;
        ProcessingStatus?: Enums.IssueProcessingStatus;
        IsExpired?: boolean;
        IsLocked?: boolean;
        // TODO generate on the fly - used only in issue-viewer
        //Responsibilities?: Array<any>;
        Images?: Array<File>;

        ShowFiles: boolean;
        HasAudioFiles: boolean;
        IsModified: boolean;
        PreviousRevisionIdentifiers: Array<string>;
        Abbreviation?: string;
        IsActive?: boolean;

        constructor(issue?: RawIssue | Issue) {
            if (!issue) {
                return;
            }

            this.initProperties(issue);
        }

        private initProperties(issue: RawIssue | Issue): void {
            this.OID = issue.OID;
            this.Type = issue.Type;
            this.Revision = issue.Revision;
            this.CreatorOID = issue.CreatorOID;
            this.EditorOID = issue.EditorOID;
            this.CreationTimestamp = new Date(<any>issue.CreationTimestamp);
            this.ModificationTimestamp = new Date(<any>issue.ModificationTimestamp);
            this.AssignedElementOID = issue.AssignedElementOID;
            this.IsArchived = issue.IsArchived;
            this.IsDeleted = issue.IsDeleted;

            this.ID = issue.ID || 0;
            this.Identifier = this.ID || this.OID;
            this.ParentID = issue.ParentID;
            this.ParentOID = issue.ParentOID;
            this.PrecedingOID = issue.PrecedingOID;
            this.FollowerOID = issue.FollowerOID;
            this.CustomID = issue.CustomID;
            this.IsTemplate = issue.IsTemplate;
            this.TemplateID = issue.TemplateID;
            this.Title = issue.Title;
            this.Description = issue.Description;

            if (!!issue.RemindingTimestamp) {
                this.RemindingTimestamp = new Date(<any>issue.RemindingTimestamp);
            }

            if (!!issue.DeadlineTimestamp) {
                this.DeadlineTimestamp = new Date(<any>issue.DeadlineTimestamp);
            }

            if (!!issue.TerminationTimestamp) {
                this.TerminationTimestamp = new Date(<any>issue.TerminationTimestamp);
                this.IsExpired = this.TerminationTimestamp.getTime() < new Date().getTime();
            }

            this.StateOID = issue.StateOID;
            this.StateRevisionOID = issue.StateRevisionOID;
            this.PriorityOID = issue.PriorityOID;
            this.PriorityRevisionOID = issue.PriorityRevisionOID;
            this.AssignedActionOID = issue.AssignedActionOID;
            this.AssignedElementRevisionOID = issue.AssignedElementRevisionOID;
            this.AssignedFormOID = issue.AssignedFormOID;
            this.AssignedFormRevisionOID = issue.AssignedFormRevisionOID;
            this.AssignedMeasureOID = issue.AssignedMeasureOID;
            this.AssignedMeasureRevisionOID = issue.AssignedMeasureRevisionOID;
            this.AssignedSchedulingOID = issue.AssignedSchedulingOID;
            this.AssignedSchedulingRevisionOID = issue.AssignedSchedulingRevisionOID;
            this.AssignedRecorditemOID = issue.AssignedRecorditemOID;
            this.AssignedRecorditemID = issue.AssignedRecorditemID;

            this.ResponsibilityAssignments = issue.ResponsibilityAssignments;
            this.Teams = issue.Teams;
            this.Users = issue.Users;
            this.Contacts = issue.Contacts;
            this.Classifications = issue.Classifications;
            this.Keywords = issue.Keywords;

            this.TemporaryFilesMarkup = issue.TemporaryFilesMarkup;
            this.ChangeComment = issue.ChangeComment;
            this.Comments = issue.Comments;
            this.Revisions = issue.Revisions;
            this.Recorditems = issue.Recorditems;
            this.ParameterCount = issue.ParameterCount;
            this.RequiredParameterCount = issue.RequiredParameterCount;
            this.CollectedParameterCount = issue.CollectedParameterCount;
            this.CollectedRequiredParameterCount = issue.CollectedRequiredParameterCount;
            this.AdditionalData = issue.AdditionalData;
            this.EstimatedEffort = issue.EstimatedEffort;
            this.IsTemporary = issue.IsTemporary;

            this.Room = DAL.Elements.GetByOID(this.AssignedElementOID);

            if ((issue.Ancestors || []).length) {
                this.Ancestors = [];
                issue.Ancestors.forEach((issue: RawIssue | Issue) => this.Ancestors.push(new Issue(issue)));
            }

            if ((issue.Descendants || []).length) {
                this.Descendants = [];
                issue.Descendants.forEach((issue: RawIssue | Issue) => this.Descendants.push(new Issue(issue)));
            }

            if ((issue.Files || []).length) {
                this.Files = [];
                this.Images = [];

                issue.Files.sort(Utils.SortByPosition);
                issue.Files.forEach(f => {
                    const file = new File(f);

                    this.Files.push(file);

                    if (file.IsImage) {
                        this.Images.push(file);
                    }
                });
            }

            if ((issue.Resubmissionitems || []).length) {
                this.Resubmissionitems = issue.Resubmissionitems;
                this.ResubmissionitemCollection = new Model.ResubmissionitemCollection(this.Resubmissionitems);
            }

            if ((issue.LocationMarkers || []).length) {
                this.LocationMarkers = [];

                const lLen = issue.LocationMarkers.length;
                for (let lCnt = 0; lCnt < lLen; lCnt++) {
                    const rawLocationMarker = issue.LocationMarkers[lCnt];

                    this.LocationMarkers.push(new Model.Issues.LocationMarker(rawLocationMarker.Type, rawLocationMarker.X, rawLocationMarker.Y));
                }
            }

            if (!!this.StateOID) {
                this.State = this.getPropertyByOID(this.StateOID);
                this.IsLocked = DAL.Properties.IsLockedState(issue.StateOID);
            }

            if (!!this.PriorityOID) {
                this.Priority = this.getPropertyByOID(this.PriorityOID);
            }

            if (!!this.AssignedFormOID) {
                this.Form = this.getFormTemplateByOID(this.AssignedFormOID);
            }

            if (!!this.AssignedSchedulingOID) {
                this.Scheduling = this.getSchedulingByOID(this.AssignedSchedulingOID);
            }

            this.setIssueProcessingInformation();
        }

        private getFormTemplateByOID(formOID: string): any {
            if (!formOID) {
                return;
            }

            const form = DAL.Elements.GetByOID(formOID);

            return form ? Utils.CloneElement(form) : null;
        }

        private getPropertyByOID(propertyOID: string): any {
            if (!propertyOID) {
                return;
            }

            const property = DAL.Properties.GetByOID(propertyOID);

            return property ? Utils.CloneObject(property) : null;
        }

        private getSchedulingByOID(schedulingOID: string): any {
            if (!schedulingOID) {
                return;
            }

            const scheduling = DAL.Scheduling.GetByOID(schedulingOID);

            return scheduling ? Utils.CloneObject(scheduling) : null;
        }

        private setIssueProcessingInformation(): void {
            const state = DAL.Properties.GetByOID(this.StateOID);

            if (state && state.ClosedState || this.IsArchived) {
                this.ProcessingStatus = Enums.IssueProcessingStatus.OK;
                this.IssueCellMarkerClass = ProcessingInformation.GetStatusCssClass(Enums.IssueProcessingStatus.OK);

                return;
            }

            if (!(this.DeadlineTimestamp instanceof Date) ||
                isNaN(this.DeadlineTimestamp.getTime())) {
                this.ProcessingStatus = Enums.IssueProcessingStatus.Warning;
                this.IssueCellMarkerClass = ProcessingInformation.GetStatusCssClass(Enums.IssueProcessingStatus.Warning);

                return;
            }

            const now = new Date();
            const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

            let processingInformation: ProcessingInformation;

            if (!this.DeadlineTimestamp) {
                processingInformation = new ProcessingInformation(Enums.IssueProcessingStatus.Warning);
            } else if (this.DeadlineTimestamp.getTime() < now.getTime()) {
                processingInformation = new ProcessingInformation(Enums.IssueProcessingStatus.Overdue);
            } else if (this.DeadlineTimestamp.getTime() < tomorrow.getTime()) {
                processingInformation = new ProcessingInformation(Enums.IssueProcessingStatus.Warning);
            } else {
                processingInformation = new ProcessingInformation(Enums.IssueProcessingStatus.OK);
            }

            this.ProcessingStatus = processingInformation.ProcessingStatus;
            this.IssueCellMarkerClass = processingInformation.IssueCellMarkerClass;
        }

        public GetResubmissionRoomOrder(): Array<string> {
            const resubTreeRoot = ParameterList.GetResubmissionTree();

            if (this.Type !== Enums.IssueType.Scheduling ||
                !this.Scheduling ||
                !resubTreeRoot) {
                return null;
            }

            const order = [];

            (function traverse(location) {
                if ((location.Parametergroups || []).length) {
                    order.push(location.OID);
                }

                if ((location.Children || []).length) {
                    for (let cCnt = 0, cLen = location.Children.length; cCnt < cLen; cCnt++) {
                        traverse(location.Children[cCnt]);
                    }
                }
            })(resubTreeRoot);

            return order;
        }

        public Copy(): Issue {
            const attributesToIgnore = [
                'Form',
                'Scheduling',
                'Room',
                'State',
                'Ancestors',
                'Descendants',
                'ResubmissionitemCollection'
            ];

            return new Issue(<any>Utils.CloneObject(this, attributesToIgnore));
        }

        public CopyRaw(): Issue {
            const attributesToIgnore = [
                'Form',
                'Scheduling',
                'Room',
                'State',
                'Ancestors',
                'Descendants',
                'ResubmissionitemCollection'
            ];

            return <any>Utils.CloneObject(this, attributesToIgnore);
        }

        public GetAbbreviation(): string {
            return Utils.GetIssueAbbreviation(this.Type);
        }

        public CreateNewRevision(): Issue {
            const newRevision = this.CopyRaw();
            const previousIdentifier = newRevision.OID;

            newRevision.Revision += 1;
            newRevision.PrecedingOID = previousIdentifier;
            newRevision.OID = uuid();
            newRevision.EditorOID = Session.User.OID;
            newRevision.ModificationTimestamp = new Date();

            return newRevision;
        }

        public AddFiles(fileDescriptions: Array<any>, newFiles: any): Deferred {
            const newRevision = this.CreateNewRevision();

            if (!(fileDescriptions || []).length) {
                throw new Model.Errors.ArgumentError('fileDescriptions must be given');
            }

            if (!Utils.HasProperties(newFiles)) {
                throw new Model.Errors.ArgumentError('newFiles must be given');
            }

            newRevision.Files = newRevision.Files || [];
            newRevision.Files = newRevision.Files.concat(fileDescriptions);

            const issue = new Model.TemporaryIssue(
                newRevision,
                newFiles,
                newRevision.PrecedingOID
            );

            return issue.Save();
        }

        private static mergeResponsibilityEntities(a: Dictionary<RACI>, b: Dictionary<RACI>): Dictionary<RACI> | null {
            if (!(a || b)) {
                return null;
            }

            if (!(a && b)) {
                return a ? Utils.Clone(a) : Utils.Clone(b);
            }

            const mergedDict = Utils.Clone(a);

            for (const identifier in b) {
                const def = b[identifier];

                if (!mergedDict.hasOwnProperty(identifier)) {
                    mergedDict[identifier] = Utils.Clone(def);
                } else {
                    const prevDef = mergedDict[identifier];
                    // jeweils den positiven Wert der ..defs übernehmen

                    if (def.IsResponsible) {
                        prevDef.IsResponsible = true;
                    }
                    if (def.IsAccountable) {
                        prevDef.IsAccountable = true;
                    }
                    if (def.IsConsulted) {
                        prevDef.IsConsulted = true;
                    }
                    if (def.IsInformed) {
                        prevDef.IsInformed = true;
                    }
                }
            }

            return mergedDict;
        }

        public MergeResponsibilities(responsibilities: Model.Issues.ResponsibilityAssignments): this {
            if (responsibilities == null) {
                return this;
            }

            this.ResponsibilityAssignments = this.ResponsibilityAssignments || {};

            if (responsibilities.Users != null) {
                this.ResponsibilityAssignments.Users = Model.Issues.Issue.mergeResponsibilityEntities(
                    this.ResponsibilityAssignments.Users,
                    responsibilities.Users
                );
            }

            if (responsibilities.Teams != null) {
                this.ResponsibilityAssignments.Teams = Model.Issues.Issue.mergeResponsibilityEntities(
                    this.ResponsibilityAssignments.Teams,
                    responsibilities.Teams
                );
            }

            if (responsibilities.Contacts != null) {
                this.ResponsibilityAssignments.Contacts = Model.Issues.Issue.mergeResponsibilityEntities(
                    this.ResponsibilityAssignments.Contacts,
                    responsibilities.Contacts
                );
            }

            if (responsibilities.ContactGroups != null) {
                this.ResponsibilityAssignments.ContactGroups = Model.Issues.Issue.mergeResponsibilityEntities(
                    this.ResponsibilityAssignments.ContactGroups,
                    responsibilities.ContactGroups
                );
            }

            return this;
        }
    }
}