//imports-start
/// <reference path="../utils/utils.set.ts"  />
/// <reference path="../utils/image-viewer/utils.image-viewer.ts"  />
//// <reference path="../definitions.d.ts"  />
//// <reference path="../utils/utils.spinner.ts"  />
//// <reference path="../utils/utils.spinner.ts"  />
/// <reference path="../model/model.tree.ts"  />
//// <reference path="./app.session.ts"  />
/// <reference path="./app.individual-data.ts"  />
//// <reference path="../enums.ts"  />
//// <reference path="./app.parameter-list.ts"  />
//// <reference path="./app.menu.ts"  />
/// <reference path="../model/database/model.database-autoselect.ts"  />
/// <reference path="../model/model.clearable-input.ts"  />
/// <reference path="../utils/utils.password-editor.ts"  />
//// <reference path="./app.sync-center.ts"  />
/// <reference path="./app.login.ts"  />
/// <reference path="../dal/dal.ts" />
/// <reference path="./app.init.ts" />
//// <reference path="../dal.ts"  />
/// <reference path="../utils/utils.analytics.ts"  />
/// <reference path="../utils/utils.scan.ts"  />
/// <reference path="../model/nfc/tag.ts"  />
/// <reference path="../utils/nfc/nhs3100-decoder.ts"  />
/// <reference path="../utils/nfc/decoder-factory.ts"  />
/// <reference path="../utils/nfc/nav-tag-decoder.ts"  />
/// <reference path="../utils/utils.recorditem-editor.individual.ts"  />
/// <reference path="../templates.d.ts"  />
/// <reference path="../config.ts" />
//imports-end

module App {
    let _tree: Model.Tree;

    export const DEBUG_ENABLED = false;
    export const DEBUG_ENABLED_PUSH: boolean = DEBUG_ENABLED && false;
    export const DEBUG_ENABLED_DATAWEDGE: boolean = DEBUG_ENABLED && false;
    export const DEBUG_ENABLED_NEWLAND: boolean = DEBUG_ENABLED && false;
    export const DEBUG_ENABLED_SPEEDATA: boolean = DEBUG_ENABLED && false;

    export const InitializationDeferred: Deferred = $.Deferred();

    const $doc = $(document);
    const $navbar = $('.navbar');
    const $locationPicker = $('.location-picker');
    const $content = $('#content');
    const $locationPickerResizer = $('.location-picker-resizer');

    let $userImage;
    let $userImageHeader;
    let $issueProcessingIndicator;
    let $homeButton, $issueFilterButton, $syncButton;
    let _navigationInitialized = false;
    let _$expandableNavigation;
    let $navbarHelpLink;

    const versionString: string = '3.39.2';
    const buildNumber: number = 22200;

    let expandableNavigationVisible: boolean = false;
    let renderTree: boolean = true;
    let searchInputField: Model.ClearableInput.Control;

    let prePauseConnectionType: string;

    let hammerManagerSidebar;
    let resizeTimeout: number;

    let elementPickerPopup: Utils.ElementPickerPopup;

    function onWindowResize(): void {
        const $modals = $('.modal');
        const $materialModals = $('.modal-material');

        resetUserPreferedTextZoom();

        if ($modals.length) {
            for (let mCnt = 0, mLen = $modals.length; mCnt < mLen; mCnt++) {
                const $modal = $modals.eq([mCnt]);

                Utils.ResizeModalWindow($modal);
            }
        }

        if ($materialModals.length) {
            for (let mCnt = 0, mLen = $materialModals.length; mCnt < mLen; mCnt++) {
                const $modal = $materialModals.eq([mCnt]);

                Utils.RepositionNewModalWindow($modal);
            }
        }

        if (Utils.RecorditemEditor.Individual.IsVisible()) {
            Utils.RecorditemEditor.Individual.RecalculateHeight();
        }

        if (Utils.IssueViewer.IsVisible()) {
            Utils.IssueViewer.Resize();
        }

        if (Utils.DateTimePicker.IsVisible()) {
            Utils.DateTimePicker.Resize();
        }

        if (Utils.ImageViewerManager.GetInstance()) {
            Utils.ImageViewerManager.GetInstance().Resize();
        }

        if (Utils.CustomDataPicker.IsVisible()) {
            Utils.CustomDataPicker.Resize();
        }

        if (Utils.InputWindow.IsVisible()) {
            Utils.InputWindow.ResizeTextArea();
        }

        IssueView.Resize();

        if (Utils.InArray([
            Enums.Mode.DisturbanceReport,
            Enums.Mode.IssueProgressReport,
            Enums.Mode.IssueReport,
            Enums.Mode.NoteReport,
            Enums.Mode.TaskReport], Session.Mode)) {
            IssueReport.RepositionMarks();
        } else if (Utils.InArray([Enums.Mode.Parameters, Enums.Mode.CoveringPage], Session.Mode)) {
            // TODO: prüfen ob Enums.Mode.ParameterList ein gültiger typ ist oder Enums.Mode.Parameters verwenden
            ParameterList.RepositionMarks();
        }

        setVisibleNavbarIcons();

        if (expandableNavigationVisible) {
            HideExpandableNavigation();
        }

        if (resizeTimeout) {
            clearTimeout(resizeTimeout);
        }

        resizeTimeout = setTimeout(() => {
            if (Session.IsRunningOnIOS) {
                ResizeLocationPicker();
            }

            if (Enums.Mode.IndividualData != Session.Mode) {
                // Tree erst 500ms nach dem letzten Resize Event aktualisieren
                if (_tree && _tree.$RenderingContainer.is(':visible')) {
                    _tree.Build();
                    App.UpdateTreeCounters();
                } else {
                    UpdateTree();
                }
            }
        }, 500);

        ResizeLocationPicker();
    }

    function onAfterDatabaseWritingProcess(): void {
        if ($syncButton && $syncButton.hasClass('success')) {
            $syncButton.removeClass('success').addClass('default');
        }
    }

    function onAfterLocalizationInitialized(): void {
        LabelPage();

        bindEvents();
        registerTemplatePartials();

        if (typeof FastClick !== 'undefined') {
            FastClick.attach(document.body);
        }

        if (sessionStorage.ReloadDueToSecurityException) {
            Utils.Message.Show(i18next.t('Misc.AutomaticReload.MessageHeader'),
                i18next.t('Misc.AutomaticReload.MessageBody'),
                {
                    Close: true
                });

            delete sessionStorage.ReloadDueToSecurityException;
        }
    }

    function initI18Next(): Deferred {
        const finishedDefer = $.Deferred();

        const i18nextOptions = {
            ns: ['locale', 'customer'],
            defaultNS: 'locale',
            load: 'currentOnly',
            fallbackLng: 'de-DE',
            returnObjects: true,
            backend: {
                loadPath: 'locales/{{lng}}/{{ns}}.lang'
            },
            interpolation: {
                escapeValue: false
            }
        };

        if (window['cordova'] && cordova.platformId == 'ios') {
            i18nextOptions.backend['ajax'] = iOSFixedI18NextAjaxMethod;
        }

        i18next
            .use(i18nextXHRBackend)
            .init(i18nextOptions, finishedDefer.resolve);

        return finishedDefer.promise();
    }

    function iOSFixedI18NextAjaxMethod(url: string, options: any, callback: Function, data: any): void {
        try {
            // iOS wkwebview-xhr-plugin fix
            const request = new (window['_XMLHttpRequest'] || XMLHttpRequest)();

            request.open('GET', url);
            request.onreadystatechange = function() {
                if (request.readyState === 4 && callback instanceof Function) {
                    callback(request.responseText, request);
                }
            };

            request.send(data);
        } catch (e) {
            if (console) {
                console.log(e);
            }
        }
    }

    function onDocumentReady(): void {
        $(window).resize(onWindowResize);

        // init support button
        App.Init.initSupportButton();

        const cookieConfig = Utils.Cookies.Get('cookie-config', 'object');

        if (cookieConfig) {
            Session.CookieConfig = { ...Config.DefaultCookieConfig, ...cookieConfig }; //Standard Config mit gespeicherten Config mergen;
        }

        initI18Next()
            .then(() => {
                Utils.SetMode(Enums.Mode.Login);

                if (DEBUG_ENABLED) {
                    enableDebugMode();
                }
            })
            .then(onAfterLocalizationInitialized)
            .then(initSmartDeviceApp)
            .then(setViewport)
            .then(App.Init.enableInit)
            .then(App.Init.InitWindowFocusEvent)
            .then(Login.InitUser)
            .then(Login.InitData)
            .progress((percent: number) => {
                Init.setProgress(percent);
            })
            .then(App.Init.hide)
            .then(setViewport)
            .then(() => {
                window.addEventListener('resize', Menu.FitResizeMenu, false);

                const currentFragment = Utils.Router.GetCurrentFragment();
                if (currentFragment.contains('settings') ||
                    currentFragment.contains('about') ||
                    currentFragment.contains('sync-center') ||
                    !Utils.Router.IsValidRoute(currentFragment)) {
                    // auf Hauptroute aktualisieren
                    Utils.Router.ReplaceState(Utils.Router.GetDefaultRoute());
                } else {
                    // Laden der aktuellen Ansicht triggern
                    Utils.Router.RefreshState();
                }

                if (Session.IsSmartDeviceApplication) {
                    SetBluetoothNavbar();
                    SetScaleNavbar();
                }
                setVisibleNavbarIcons();
            })
            .then(InitializationDeferred.resolve, InitializationDeferred.reject);
    }

    function onAfterDatabaseInitialised(): Deferred {
        let prepareDeferred: Deferred;

        // Rechte für Bilderauswahl auf Android abfragen
        if (Session.IsRunningOnAndroid) {
            prepareDeferred = Utils.RequestPermission(cordova.plugins.permissions.WRITE_EXTERNAL_STORAGE)
                .then(null, () => Utils.RequestPermission(cordova.plugins.permissions.READ_MEDIA_IMAGES))
                .then(null, () => { /* positives Ergebnis zurückgeben auch bei Ablehnung */ });
        } else {
            prepareDeferred = $.Deferred().resolve();
        }

        return prepareDeferred.then(() => {
            const deferred = $.Deferred();

            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, deferred.resolve, deferred.reject);

            return deferred.then(onAfterGotResourcesFolder);
        });
    }

    function onAfterSetApplicationFolderMetaData(): Deferred {
        if (Session.IsRunningOnAndroid && Session.ResourcesFolder) {
            Session.ResourcesFolder.getFile('.nomedia', { create: true });
        }

        return $.Deferred().resolve().promise();
    }

    function onAfterGotResourcesFolder(folder: any): Deferred {
        Session.ResourcesFolder = folder;

        // TODO process login in initUser()
        // Login.OnAfterDeviceReady();

        return setApplicationFolderMetaData()
            .then(onAfterSetApplicationFolderMetaData);
    }

    function onBackbuttonPressed(evt: Event): void {
        if ($('.fullscreen, .overlay, .modal, .spinner').length) {
            return;
        }

        history.back();
    }

    function initSmartDeviceApp(): Deferred {
        if (window.cordova == null) {
            return $.Deferred().resolve().promise();
        }

        Session.Settings.loadViewportSetting();

        const deferSmart = $.Deferred();

        // deviceready event abwarten
        document.addEventListener('deviceready', deferSmart.resolve, false);

        return deferSmart.then(function() {
            // set this instance as smart device app
            Session.IsSmartDeviceApplication = true;

            // http-Plugin initialisieren
            cordova.plugin.http.setDataSerializer('json');
            cordova.plugin.http.setRequestTimeout(Utils.Http.TIMEOUT_MS_DEFAULT / 1000);
            cordova.plugin.http.setFollowRedirect(true);
            cordova.plugin.http.setHeader('User-Agent', navigator.userAgent);

            // init database
            Session.IndexedDB = indexedDB = window._indexedDB || window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

            if (!Session.IndexedDB && shimIndexedDB) {
                Session.IndexedDB = shimIndexedDB;
            }

            // remove help link in smart dev app
            $navbar.find('.navbar-help-link').remove();

            // show qr-code scanner // TODO remove?
            $locationPicker.find('.buttons .qr-code').removeClass('hidden');

            // iOS only required
            if (Session.NfcEnabled && Session.IsRunningOnIOS) {
                $locationPicker.find('.buttons .nfc').removeClass('hidden');
            }

            // set default service url
            Session.BaseURI = Config.AppServiceURI;

            // check for device OS
            if (navigator.userAgent.contains('Android')) {
                Session.IsRunningOnAndroid = true;

                // register device back button
                $(document).on('backbutton', onBackbuttonPressed);
            } else if (navigator.userAgent.contains('iOS')) {
                Session.IsRunningOnIOS = true;
            }

            // catch bluetooth state change
            if (window.ble) {
                window.ble.startStateNotifications((state: 'on' | 'off') => {
                    switch (state) {
                        case 'on':
                            Utils.BluetoothDeviceManager.BluetoothEnabled();
                            BluetoothConfiguration.OnAfterEnabled();
                            break;
                        case 'off':
                            Utils.BluetoothDeviceManager.BluetoothDisabled();
                            BluetoothConfiguration.OnAfterDisabled();
                            break;
                    }
                });
            }

            // bind view changed events
            const viewportChangeEvent = window.hasOwnProperty('onorientationchange') ? 'orientationchange' : 'resize';
            document.addEventListener('resume', onResumeApp, false);
            document.addEventListener('pause', onPauseApp, false);
            window.addEventListener(viewportChangeEvent, onViewportChange, false);

            // start auto sync and connect known bluetooth devices, after initialization finished

            InitializationDeferred.then(function() {
                SyncCenter.StartAutoSync();

                Utils.BluetoothDeviceManager.AutoConnect();
                Utils.ScaleDeviceManager.AutoConnect();
            });

            if (cordova && cordova.plugins &&
                cordova.plugins.notification &&
                cordova.plugins.notification.badge) {
                cordova.plugins.notification.badge.configure({ autoClear: true });
            }

            // set permissions object to default, if not available
            if (!cordova.plugins.permissions) {
                cordova.plugins.permissions = {};
            }
            // init database
            const databaseDefer = $.Deferred();
            window.Database = Model.DatabaseAutoselect.Get(databaseDefer.resolve, onAfterDatabaseWritingProcess);
            return databaseDefer.then(onAfterDatabaseInitialised);
        });
    }

    function initNavigation(): void {
        if (_navigationInitialized) {
            return;
        }

        $navbar.empty();
        $navbar.append(Templates.Navbar.Sidebar());
        $navbar.append(Templates.Navbar.Topbar());
        $navbar.append(Templates.Navbar.ExpandableNavigation());

        $homeButton = $('.btn-home');
        $issueFilterButton = $('.btn-issue-filters');
        $issueProcessingIndicator = $('.issue-processing-indicator');
        $syncButton = $navbar.find('.synchronisation');
        $syncButton.removeClass('hidden');
        $navbarHelpLink = $('.navbar .navbar-help-link');

        $navbar
            .on('click', 'a[href]:not(.btn-home, .navbar-help-link)', onNavbarClick);
        $navbar.find('.toggle-expandable-navigation')
            .on('click', onToggleExpandableNavigation);

        $syncButton.find('a').press(onBtnSyncPress, onBtnSyncTap, 500);
        $issueFilterButton.on('click', onFilterButtonClick);

        _$expandableNavigation = $('body').find('.expandable-navigation');

        _navigationInitialized = true;
    }

    function setVisibleNavbarIcons() {
        if (!_$expandableNavigation) {
            return;
        }

        const documentHeight = $('html').height();
        const documentWidth = $('html').width();

        if (!Session.IsSmartDeviceApplication) {
            if ((documentHeight <= 400 && documentWidth >= 768) || documentWidth <= 400) {
                if (!_$expandableNavigation.find('.navigation .navbar-help-link').length) {
                    _$expandableNavigation.find('.navigation .main-link').after(Templates.Navbar.ExpandableNavigationIcons.HelpLink());
                }

                $navbarHelpLink.addClass('hide');
            } else {
                _$expandableNavigation.find('.navigation .navbar-help-link').parent().remove();
                $navbarHelpLink.removeClass('hide');
            }
        }

        if ((documentWidth >= 768 && documentHeight <= 300) || documentWidth <= 300) {
            if (!_$expandableNavigation.find('.navigation .issue-processing-indicator').length) {
                _$expandableNavigation.find('.navigation .main-link')
                    .after(Templates.Navbar.ExpandableNavigationIcons.ProcessingButton());
                _$expandableNavigation.find('.navigation .issue-processing-indicator a')
                    .attr('href', $issueProcessingIndicator.find('a').attr('href'));
            }

            $issueProcessingIndicator.addClass('hide');
        } else {
            _$expandableNavigation.find('.issue-processing-indicator').parent().remove();
            $issueProcessingIndicator.toggleClass('hide', !!Session.Settings.DisableProcessingStatusSmiley);
        }
    }

    export function UpdateExpandableNavigationPosition() {
        if (!$navbar) {
            return;
        }

        if ($(document).width() < 768) {
            _$expandableNavigation.css('top', $navbar.innerHeight());
        } else {
            const $expandButton = $navbar.find('.toggle-expandable-navigation');
            const documentHeight = $('html').height();
            const navigationHeight = _$expandableNavigation.height();
            let expandButtonOffset = $expandButton.offset().top;

            if (navigationHeight + expandButtonOffset > documentHeight) {
                expandButtonOffset = documentHeight - navigationHeight - 5;
            }

            _$expandableNavigation.css('top', expandButtonOffset);
        }
    }

    function onModeChange(evt): void {
        let params = evt.detail;
        const $content = $('#content');
        const $footerToolbar = $content.parent().find('.footer-toolbar');

        if (!params) {
            return;
        }

        $('#content').off('scroll').scrollTop(0);

        Utils.Analytics.TrackView(params.NewMode);

        if (Session.IsSmartDeviceApplication && window["BluetoothConfiguration"]) {
            Utils.BluetoothDeviceManager.RemoveDeviceConnectedCallback('ParameterListTemperatureDevice');
            Utils.BluetoothDeviceManager.RemoveDeviceConnectedCallback('RecorditemEditor');

            if (params.PreviousMode === Enums.Mode.Parameters &&
                Utils.BluetoothDeviceManager.IsBluetoothThermometerConnected()) {
                const connectedDevices = Utils.BluetoothDeviceManager.GetConnectedDevices();

                for (let key in connectedDevices) {
                    if (connectedDevices[key] instanceof Model.Bluetooth.Thermometers.BluetoothThermometer) {
                        connectedDevices[key].DeregisterAllNotifier();
                    }
                }

                CodeScanner.RemoveFromCallbackStack('ParameterList_Scan');
            }
        }

        // disable all scale events to view
        if (Session.IsSmartDeviceApplication && Utils.ScaleDeviceManager) {
            Utils.ScaleDeviceManager.ClearDeviceListenerCallbacks();
        }

        const allowLocationPicker = Utils.InArray([Enums.Mode.About, Enums.Mode.SyncCenter], params.PreviousMode) &&
            !Utils.InArray([Enums.Mode.About, Enums.Mode.SyncCenter, Enums.Mode.Parameters, Enums.Mode.Login], params.NewMode);

        if (params.PreviousMode === Enums.Mode.Login) {
            if (params.NewMode !== Enums.Mode.Login) {
                $('body').css('padding', '');

                if (params.NewMode !== Enums.Mode.IndividualData) {
                    $navbar.find('.navbar-brand').html('<span class="icon-home3"></span>');
                }

                if ($navbar.hasClass('hidden')) {
                    $navbar.removeClass('hidden');
                }

                initNavigation();
                PrepareUserImageInHeader();

                if ($navbar.find('.navbar-nav').hasClass('hidden')) {
                    $navbar.find('.navbar-nav').removeClass('hidden');
                }

                if ($navbar.find('.navbar-toggle').hasClass('hidden')) {
                    $navbar.find('.navbar-toggle').removeClass('hidden');
                }
            }
        } else if (allowLocationPicker && Session.Settings.ShowTreeView) {
            $locationPicker.removeClass('hidden');
        }

        if (params.PreviousMode === Enums.Mode.Calendar) {
            $content.removeClass('absolute-pos-content');
        }

        if (params.NewMode !== Enums.Mode.Login) {
            setTopMenu();

            if (params.NewMode === Enums.Mode.Settings &&
                params.PreviousMode === Enums.Mode.Login) {
                Utils.Spinner.Hide();
            }

            if (params.NewMode === Enums.Mode.Calendar) {
                $content.addClass('absolute-pos-content');
            }
        }

        if (View.CurrentView === Enums.View.Inspection) {
            $footerToolbar.find('.switch-inspection-view span:first')
                .removeClass('icon-file-text2 icon-drawer');

            $footerToolbar.find('.switch-inspection-view .active').removeClass('active');

            if (params.NewMode === Enums.Mode.IssueReport) {
                $footerToolbar.find('.switch-inspection-view span:first').addClass('icon-drawer');
                $footerToolbar.find('.active').removeClass('active');
                $footerToolbar.find('.show-issues').addClass('active');
                $footerToolbar.find('.switch-inspection-view li[data-view="issues"]').addClass('active');
                $footerToolbar.find('.separator, .issue-related').removeClass('hidden');
                $footerToolbar.find('.cp-counter-item').addClass('hidden');
                $footerToolbar.find('.cp-filtered-item').addClass('hidden');
                $footerToolbar.find('.switch-inspection-view > .badge').removeClass('hidden');
            } else if (params.NewMode === Enums.Mode.CoveringPage) {
                $footerToolbar.find('.switch-inspection-view span:first').addClass('icon-file-text2');
                $footerToolbar.find('.active').removeClass('active');
                $footerToolbar.find('.show-covering-page').addClass('active');
                $footerToolbar.find('.switch-inspection-view li[data-view="covering-page"]').addClass('active');
                $footerToolbar.find('.separator, .issue-related').addClass('hidden');
                $footerToolbar.find('.cp-counter-item').removeClass('hidden');
                $footerToolbar.find('.cp-filtered-item').toggleClass('hidden', !ParameterList.AreFiltersActive());
                $footerToolbar.find('.switch-inspection-view > .badge').addClass('hidden');
            }

            IssueView.Resize();
        } else if (/-report/.test(params.NewMode) &&
            params.NewMode !== Enums.Mode.IssueProgressReport &&
            View.CurrentView === Enums.View.Main) {
            $content.before(Templates.Menus.FooterToolbar({
                IsIssueView: false,
                IsInspection: false,
                ShowIssueReportButtons: true,
                AlignToContentContainer: Session.Settings.ShowTreeView,
                IssueType: Utils.GetIssueTypeByMode(params.NewMode),
                LocationOID: Session.CurrentLocation.OID,
                ShowCreateIssue: Utils.CanUserCreateIssueType(Utils.GetIssueTypeByMode(params.NewMode), Session.CurrentLocation),
                CanModifyIssue: false
            }));

            IssueReport.BindFooterToolbarEvents();
        }

        if (View.CurrentView === Enums.View.Main && /-report/.test(params.PreviousMode)) {
            $footerToolbar.remove();
        }

        if (params.PreviousMode === Enums.Mode.IssueProgressReport &&
            !sessionStorage.DoNotResetIssueProcessingFilters) {
            IssueReport.ResetProcessingFilter();
        }

        if (params.NewMode === Enums.Mode.Calendar) {
            $issueFilterButton.addClass('invisible');
        } else if (params.PreviousMode === Enums.Mode.Calendar) {
            $issueFilterButton.removeClass('invisible');
        }

        if ($userImage && $userImage.length) {
            if (params.PreviousMode === Enums.Mode.Settings) {
                $userImage.find('> a').attr('href', '#settings');
            } else if (params.NewMode === Enums.Mode.Settings) {
                $userImage.find('> a').attr('href', '#main');
            }
        }

        const isFullWidthMode = params.NewMode === Enums.Mode.Calendar ||
            params.NewMode === Enums.Mode.Menu ||
            params.NewMode === Enums.Mode.FloorPlan;

        $content.toggleClass('full-width', isFullWidthMode);

        delete sessionStorage.DoNotResetIssueProcessingFilters;
        ResizeLocationPicker();
    }

    function onAPIVersionChange(evt): void {
        if (!evt || !evt.detail) {
            return;
        }

        if (Utils.PushNotifications) {
            if (evt.detail.PreviousApiVersion < 2 && evt.detail.NewApiVersion >= 2) {
                Utils.PushNotifications.Init();
            }
        }

        if (evt.detail.PreviousApiVersion < 8 && evt.detail.NewApiVersion >= 8) {
            if (Session && Session.SynchronisationInformation) {
                Session.SynchronisationInformation.Remove(Enums.EntityType.ROLES);
            }

            if (Session.IsSmartDeviceApplication && window.Database) {
                window.Database.DeleteFromStorage(Enums.DatabaseStorage.SynchronisationInformation, Enums.EntityType.ROLES);
            }
        }

        if (evt.detail.PreviousApiVersion < 17 && evt.detail.NewApiVersion >= 17) {
            if (Session && Session.SynchronisationInformation) {
                Session.SynchronisationInformation.Remove(Enums.EntityType.PROPERTIES);
            }

            if (Session.IsSmartDeviceApplication && window.Database) {
                window.Database.DeleteFromStorage(Enums.DatabaseStorage.SynchronisationInformation, Enums.EntityType.PROPERTIES);
            }
        }
    }

    function onLocationScannerResult(result: { format: string, text: string }): void {
        Utils.Spinner.Hide();

        const qrCode = result.text;

        if (!qrCode) {
            return;
        }

        const elem = Utils.InArray([Enums.View.Main, Enums.View.Inspection], View.CurrentView) ?
            DAL.Elements.GetByQRCode(qrCode) :
            ParameterList.GetElementRevisionByQRCode(qrCode);

        goToLocation(elem);
    }

    function onNfcLocationTagScanned(identifier: string): void {
        if (!identifier) {
            return;
        }

        if (elementPickerPopup && elementPickerPopup.IsVisible()) {
            if (!elementPickerPopup.SelectElement(identifier, true)) {
                const toastTiming = Session.IsRunningOnIOS ? 4 : 0.2;
                Utils.Toaster.Show(i18next.t('Misc.ScanError.ElementDoesNotExist.MessageHeader'), toastTiming);
            }
            return;
        }

        if (Utils.RecorditemEditor.IsVisible()) {
            if (!Utils.RecorditemEditor.ApplyScannedValue(identifier)) {
                Utils.Toaster.Show(i18next.t('NFC.NoActionAvailable'), 1, Enums.Toaster.Icon.Info);
            }
            return;
        }

        if (Utils.CustomDataPicker.IsVisible() ||
            Utils.IssueViewer.IsVisible() ||
            Utils.InputWindow.IsVisible() ||
            Utils.ActionWindow.IsVisible() ||
            Utils.ElementInformation.IsVisible() ||
            Utils.ParameterHistory.IsVisible() ||
            Utils.DateTimePicker.IsVisible() ||
            Utils.RecorditemEditor.Individual.IsVisible() ||
            Utils.ParameterSelection.BaseParameterSelectionWindow.IsVisible()) {
            return;
        }

        let elem = Utils.InArray([Enums.View.Main, Enums.View.Inspection, Enums.View.Form], View.CurrentView) ?
            DAL.Elements.GetByOID(identifier) :
            ParameterList.GetElementRevisionByOID(identifier);

        const allowNavigation = View.CurrentView !== Enums.View.Form &&
            elem != null &&
            Utils.InArray([Enums.ElementType.Root, Enums.ElementType.Location], elem.Type) &&
            elem.OID !== Session.CurrentLocation.OID;

        if (allowNavigation && Session.Mode !== Enums.Mode.Parameters) {
            goToLocation(elem);
            return;
        }

        // für Locationcode aktuelle Revision unabhängig des Resubtrees bestimmen
        elem = DAL.Elements.GetByOID(identifier);

        let elligableCheckpoints: string[] = [];

        if (elem) {
            const issue = IssueView.GetCurrentIssue();
            const cpSet = new Utils.HashSet();

            (Session.CurrentLocation.Parametergroups || []).forEach(group => {
                (group.Parameters || []).forEach(param => {
                    if (param.Type !== Enums.ElementType.LocationCode) {
                        return;
                    }

                    const additionalSettings = param.AdditionalSettings || {};

                    if (!additionalSettings.RestrictScannableLocations) {
                        cpSet.put(param.OID);
                        return;
                    }

                    const allowedLocations = additionalSettings.SelectedScannableLocations || [];

                    if (!allowedLocations.length) {
                        if (!issue) {
                            return;
                        }

                        if (issue.Type === Enums.IssueType.Form ||
                            issue.Type === Enums.IssueType.Inspection) {
                            allowedLocations.push(issue.AssignedElementOID);
                        }

                        if (issue.Type === Enums.IssueType.Scheduling) {
                            allowedLocations.push(Session.CurrentLocation.OID);
                        }
                    }

                    if (allowedLocations.indexOf(elem.OID) > -1) {
                        cpSet.put(param.OID);
                    }
                });
            });

            elligableCheckpoints = cpSet.toArray();
        }

        if (allowNavigation && elligableCheckpoints.length > 0) {
            Utils.CustomDataPicker.Show(
                [
                    { Title: i18next.t('NFC.ActionSelection.Actions.GoToOu'), OID: 'go-to-ou' },
                    { Title: i18next.t('NFC.ActionSelection.Actions.AssignValue'), OID: 'assign-value' },
                ],
                {
                    Title: i18next.t('NFC.ActionSelection.Header'),
                    InfoText: i18next.t('NFC.ActionSelection.SelectedOu', { ouTitle: elem.Title }),
                    HideResetButton: true,
                    HideConfirmationButtons: true,
                    Width: 330,
                    OnItemClick: ($btn) => {
                        const actionId = $btn.data('oid');

                        Utils.CustomDataPicker.Destroy();

                        if (actionId === 'go-to-ou') {
                            goToLocation(elem);
                        } else if (actionId === 'assign-value') {
                            ParameterList.OnAfterGotLocationCode(elem, elligableCheckpoints);
                        }
                    },
                    Callback: $.noop
                }
            );

            return;
        }

        if (allowNavigation) {
            goToLocation(elem);
        } else if (elligableCheckpoints.length > 0) {
            ParameterList.OnAfterGotLocationCode(elem, elligableCheckpoints);
        } else {
            Utils.Toaster.Show(i18next.t('NFC.NoActionAvailable'), 1, Enums.Toaster.Icon.Info);
        }
    }

    function goToLocation(elem: Model.Elements.Element): void {
        if (!elem || !Utils.InArray([Enums.ElementType.Root, Enums.ElementType.Location], elem.Type)) {
            Utils.Message.Show(i18next.t('Navigation.ElementUndefined.MessageHeader'),
                i18next.t('Navigation.ElementUndefined.MessageBody'),
                {
                    Close: true
                });
            return;
        }

        const issue = IssueView.GetCurrentIssue();

        if (View.CurrentView === Enums.View.Main || !issue) {
            Utils.Router.PushState('location/' + elem.OID);
        } else if (Utils.InArray([Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            Utils.Router.PushState('issue/{0}/location/{1}?type={2}'.format(issue.ID || issue.OID, elem.OID, issue.Type));
        }
    }

    function onNavbarClick(evt: Event): void {
        const $this = $(this);
        const $li = $this.parent();

        if ($li.hasClass('active') || $li.hasClass('disabled')) {
            evt.preventDefault();
            return;
        }

        if (expandableNavigationVisible) {
            HideExpandableNavigation();
        }

        if (!Utils.InArray([Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView) &&
            $li.hasClass('btn-home')) {
            IssueView.Clear();
        }

        if (!Utils.InArray(['#logout', '#settings'], $this.attr('href'))) {
            $li.addClass('active').siblings().removeClass('active');
        }

        switch ($this.attr('href')) {
            case '#main':
                $homeButton.removeClass('invisible');
                $issueFilterButton.removeClass('invisible');
                break;
            case '#settings':
            case '#bluetooth-configuration':
            case '#scale-configuration':
                if (!Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
                    $issueFilterButton.addClass('invisible');
                }
                break;
            case '#logout':
                evt.preventDefault();

                askForLogout();
                break;
        }
    }

    function onHideModalWindow(): void {
        if (Utils.IssueViewer.IsVisible()
            || Utils.RecorditemEditor.IsVisible()) {
            $('body').addClass('modal-open');
        }
    }

    function onToggleExpandableNavigation(): void {
        const $this = $(this);
        const deviceWidth = $(document).width();

        if (!expandableNavigationVisible) {
            showExpandableNavigation();
            UpdateExpandableNavigationPosition();
            $this.find('span').attr('class', deviceWidth >= 768 ? 'icon-cheveron-left' : 'icon-cheveron-down');
        } else {
            HideExpandableNavigation();
            $this.find('span').attr('class', 'icon-dots-three-vertical');
        }
    }

    function onBtnToggleSearchRowClick(): void {
        const $row = $locationPicker.find('div.search');

        if ($row.hasClass('hidden')) {
            // Suchfeld vor Anzeige aktualisieren
            CreateOrRecycleSearchInputField();
        }

        $row.toggleClass('hidden');

        if (!Session.IsSmartDeviceApplication || Session.IsRunningOnAndroid) {
            $row.find('input').focus();
        }
    }

    function onBtnSyncTap(): void {
        SyncCenter.StartSynchronisation();
    }

    function onBtnSyncPress(): void {
        $navbar.find('.active').removeClass('active');
        $syncButton.addClass('active');

        Utils.Router.PushState('sync-center');
    }

    function onFilterButtonClick(evt: Event): void {
        evt.preventDefault();

        if (Session.Mode === Enums.Mode.Parameters ||
            Session.Mode === Enums.Mode.CoveringPage ||
            Session.Mode === Enums.Mode.Menu && View.CurrentView === Enums.View.Scheduling) {
            ParameterList.ShowParameterFilterSelection();
        } else {
            IssueReport.ShowIssueFilterSelection();
        }
    }

    function onUserImageLoadingError(): void {
        $(this).attr('src', './img/placeholder_header.svg');
    }

    function onPauseApp() {
        // remember connection type
        prePauseConnectionType = navigator.connection.type;
        // pause auto sync
        SyncCenter.StopAutoSync();
        // disconnect scale
        Utils.ScaleDeviceManager.Shutdown();
    }

    function onResumeApp() {
        // check connection type to previous state,
        if (prePauseConnectionType != navigator.connection.type &&
            typeof cordova != 'undefined') {
            // trigger event if device is off-/online
            if (prePauseConnectionType == 'none' &&
                navigator.connection.type !== 'none') {
                cordova.fireDocumentEvent('online');
            } else if (prePauseConnectionType !== 'none' &&
                navigator.connection.type == 'none') {
                cordova.fireDocumentEvent('offline');
            }
        }
        // resume  auto sync
        SyncCenter.StartAutoSync();
        // reconnect scale
        Utils.ScaleDeviceManager.AutoConnect();

        // ios workaround: Keine nativen calls direkt im resume event callback erlaubt (siehe cordova docs)
        setTimeout(function() {
            onViewportChange();
        }, 10);
    }

    function onViewportChange(): void {
        if (View.CurrentView === Enums.View.Main) {
            UpdateTree();
        }

        if (Session.IsRunningOnIOS) {
            setViewport();
        } else {
            resetUserPreferedTextZoom();
        }
    }

    function onLocationPickerResizeMove(evt): void {
        if (!evt) {
            return;
        }

        const documentWidth = $(document).width();
        let locationPickerWidth = 100 / documentWidth;

        if (evt.type === 'mousemove') {
            locationPickerWidth *= evt.x;
        } else if (evt.type === 'touchmove' && (evt.touches || []).length) {
            locationPickerWidth *= evt.touches[0].pageX;
        } else {
            return;
        }

        if (locationPickerWidth < 5) {
            if (!$locationPicker.find('.hide-info').length) {
                $locationPicker.append(Templates.Tree.HideInformation());
            }

            locationPickerWidth = 5;
        } else {
            $locationPicker.find('.hide-info').remove();
        }

        if (locationPickerWidth > 65) {
            locationPickerWidth = 65;
        }

        if (Session.Settings.LocationPickerWidth === locationPickerWidth) {
            return;
        }

        Session.Settings.LocationPickerWidth = locationPickerWidth;

        $('#content').trigger('resize');

        ResizeLocationPicker();
    }

    function onLocationPickerResizeStop(evt: Event): void {
        document.removeEventListener('mousemove', onLocationPickerResizeMove, false);
        document.removeEventListener('mouseup', onLocationPickerResizeStop, false);

        document.removeEventListener('touchmove', onLocationPickerResizeMove, false);
        document.removeEventListener('touchend', onLocationPickerResizeStop, false);

        if ($locationPicker.find('.hide-info').length) {
            Session.Settings.ShowTreeView = false;
            Session.Settings.LocationPickerWidth = 25;
            $locationPicker.find('.hide-info').remove();
        }

        Settings.Save();

        if (!Session.Settings.ShowTreeView) {
            updateTreeVisibility();
        }
    }

    function onLocationPickerResizeStart(evt: Event): void {
        document.addEventListener('mousemove', onLocationPickerResizeMove, false);
        document.addEventListener('mouseup', onLocationPickerResizeStop, false);

        document.addEventListener('touchmove', onLocationPickerResizeMove, false);
        document.addEventListener('touchend', onLocationPickerResizeStop, false);
    }

    function enableDebugMode(): void {
        $navbar.addClass('debug-enabled');
    }

    function registerTemplatePartials(): void {
        Handlebars.registerPartial('RoundedImage', Templates.RoundedImage);
        Handlebars.registerPartial('ParameterCell', Templates.Parameters.Cell);
        Handlebars.registerPartial('ParameterTitleAndDescription', Templates.Parameters.TitleAndDescription);
        Handlebars.registerPartial('PrioritizedParameterFile', Templates.Parameters.Files.Wrapper);
        Handlebars.registerPartial('PrioritizedImageFile', Templates.Parameters.Files.Image);
        Handlebars.registerPartial('PrioritizedLink', Templates.Parameters.Files.Link);
        Handlebars.registerPartial('PrioritizedOtherFile', Templates.Parameters.Files.Default);
        Handlebars.registerPartial('PrioritizedVideoFile', Templates.Parameters.Files.Video);
        Handlebars.registerPartial('DummyParameter', Templates.Parameters.Dummy);
        Handlebars.registerPartial('RecorditemComment', Templates.Parameters.Comment);
        Handlebars.registerPartial('ParameterGroup', Templates.Parameters.ParameterGroup);
        Handlebars.registerPartial('ParameterCellToolbar', Templates.Parameters.ParameterCellToolbar);
        Handlebars.registerPartial('TabularSubsample', Templates.Parameters.TabularSubsample);
        Handlebars.registerPartial('TabularSubsampleRow', Templates.Parameters.TabularSubsampleRow);
        Handlebars.registerPartial('TabularParameterCell', Templates.Parameters.TabularCell);
        Handlebars.registerPartial('IssueCell', Templates.Reports.IssueCell);
        Handlebars.registerPartial('IssuesNoIssuesAvailable', Templates.Reports.NoIssuesAvailable);
        Handlebars.registerPartial('FormIssuesPool', Templates.FormIssuePool.Pool);
        Handlebars.registerPartial('CorrectiveActionListItem', Templates.CorrectiveActions.ListItem);
        Handlebars.registerPartial('CorrectiveActionAutomatedIssueItem', Templates.CorrectiveActions.AutomatedIssueItem);
        Handlebars.registerPartial('FormSelectionParameterList', Templates.SelectionWindow.Form.Parameterlist);
        Handlebars.registerPartial('RecorditemAdditionalInformation', Templates.RecorditemEditor.AdditionalInformation);
        Handlebars.registerPartial('RecorditemAdditionalInformationSmall', Templates.RecorditemEditor.AdditionalInformationSmall);
        Handlebars.registerPartial('RecorditemAdditionalFile', Templates.RecorditemEditor.AdditionalFile);
        Handlebars.registerPartial('RecorditemCorrectiveAction', Templates.RecorditemEditor.CorrectiveAction);
        Handlebars.registerPartial('AdditionalFileRow', Templates.RecorditemEditor.AdditionalFileRow);
        Handlebars.registerPartial('ImageViewerWindow', Templates.ImageViewer.Window);
        Handlebars.registerPartial('IssueViewerHeaderContent', Templates.IssueViewer.WindowHeaderContent);
        Handlebars.registerPartial('IssueLocation', Templates.IssueViewer.Location);
        Handlebars.registerPartial('IssueViewerStateText', Templates.IssueViewer.StateText);
        Handlebars.registerPartial('IssueExistingFile', Templates.IssueViewer.ExistingFile);
        Handlebars.registerPartial('IssueResponsibility', Templates.IssueViewer.Responsibility);
        Handlebars.registerPartial('IssueResponsibilityList', Templates.IssueViewer.ResponsibilityList);
        Handlebars.registerPartial('IssueInformationSidebarInformation', Templates.IssueInformationSidebar.Information);
        Handlebars.registerPartial('IssueInformationSidebarIndex', Templates.IssueInformationSidebar.Index);
        Handlebars.registerPartial('IssueInformationSidebarStatusQuickSelection', Templates.IssueInformationSidebar.StatusQuickSelection);
        Handlebars.registerPartial('IssueInformationSidebarTools', Templates.IssueInformationSidebar.Tools);
        Handlebars.registerPartial('IssueInformationSidebarFiles', Templates.IssueInformationSidebar.Files);
        Handlebars.registerPartial('ChatWindowHeaderContent', Templates.ChatWindow.WindowHeaderContent);
        Handlebars.registerPartial('ChatWindowMessage', Templates.ChatWindow.Message);
        Handlebars.registerPartial('ChatWindowInfoMessage', Templates.ChatWindow.InfoMessage);
        Handlebars.registerPartial('CalendarDayView', Templates.Calendar.DayView);
        Handlebars.registerPartial('CalendarDayViewIssueItem', Templates.Calendar.DayViewIssueItem);
        Handlebars.registerPartial('SettingsSimpleItem', Templates.Settings.SimpleItem);
        Handlebars.registerPartial('SettingsDeadlinePresetItem', Templates.Settings.DeadlinePreset);
        Handlebars.registerPartial('SettingsItemPreset', Templates.Settings.ItemPreset);
        Handlebars.registerPartial('SettingsViewPanel', Templates.Settings.ViewPanel);
        Handlebars.registerPartial('SettingsIssueReportPanel', Templates.Settings.IssueReportPanel);
        Handlebars.registerPartial('SettingsRecordingPanel', Templates.Settings.RecordingPanel);
        Handlebars.registerPartial('SettingsSyncPanel', Templates.Settings.SyncPanel);
        Handlebars.registerPartial('SettingsMiscPanel', Templates.Settings.MiscPanel);
        Handlebars.registerPartial('SettingsApiInformationPanel', Templates.Settings.ApiInformationPanel);
        Handlebars.registerPartial('SettingsNumberItem', Templates.Settings.NumberItem);
        Handlebars.registerPartial('SettingsTextItem', Templates.Settings.TextItem);
        Handlebars.registerPartial('SettingsNfcPanel', Templates.Settings.NfcPanel);
        Handlebars.registerPartial('ParameterHistoryWindowRecorditem', Templates.ParameterHistoryWindow.Recorditem);
        Handlebars.registerPartial('BluetoothConfigurationDevice', Templates.Bluetooth.Device);
        Handlebars.registerPartial('ScaleDeviceList', Templates.Scale.ScaleDeviceList);
        Handlebars.registerPartial('UserPickerUnselectedUser', Templates.UserPicker.UnselectedUser);
        Handlebars.registerPartial('UserPickerUnselectedTeam', Templates.UserPicker.UnselectedTeam);
        Handlebars.registerPartial('UserPickerSelectedUser', Templates.UserPicker.SelectedUser);
        Handlebars.registerPartial('ResponsibilityPickerSelectedUser', Templates.ResponsibilityPicker.SelectedUser);
        Handlebars.registerPartial('ResponsibilityPickerSelectedTeam', Templates.ResponsibilityPicker.SelectedTeam);
        Handlebars.registerPartial('ResponsibilityPickerSelectedContact', Templates.ResponsibilityPicker.SelectedContact);
        Handlebars.registerPartial('ResponsibilityPickerSelectedContactGroup', Templates.ResponsibilityPicker.SelectedContactGroup);
        Handlebars.registerPartial('ResponsibilityPickerUnselectedUser', Templates.ResponsibilityPicker.UnselectedUser);
        Handlebars.registerPartial('ResponsibilityPickerUnselectedTeam', Templates.ResponsibilityPicker.UnselectedTeam);
        Handlebars.registerPartial('ResponsibilityPickerUnselectedContact', Templates.ResponsibilityPicker.UnselectedContact);
        Handlebars.registerPartial('ResponsibilityPickerUnselectedContactGroup', Templates.ResponsibilityPicker.UnselectedContactGroup);
        Handlebars.registerPartial('ResponsibilityPickerSelectableItemsList', Templates.ResponsibilityPicker.SelectableItemsList);
        Handlebars.registerPartial('ResponsibleList', Templates.ResponsibilityPicker.List);
        Handlebars.registerPartial('RaciTags', Templates.ResponsibilityPicker.RaciTags);
        Handlebars.registerPartial('UserPickerSelectedTeam', Templates.UserPicker.SelectedTeam);
        Handlebars.registerPartial('UserPickerItemList', Templates.UserPicker.SelectableItemsList);
        Handlebars.registerPartial('IndividualDataContentHeader', Templates.Menus.IndividualDataContentHeader);
        Handlebars.registerPartial('Tag', Templates.Tag);
        Handlebars.registerPartial('FileSortPlaceholder', Templates.Files.SortPlaceholder);
        Handlebars.registerPartial('ChartjsCustomTooltipTimestampRow', Templates.ChartjsCustomTooltip.TimestampRow);
        Handlebars.registerPartial('FullScreenIssueViewerComments', Templates.FullscreenIssueViewer.Comments);
        Handlebars.registerPartial('FullScreenIssueViewerFiles', Templates.FullscreenIssueViewer.Files);
        Handlebars.registerPartial('FullScreenIssueViewerIssues', Templates.FullscreenIssueViewer.Issues);
    }

    export function loadIssue(issueIdentifier: string | number, additionalParameters: Array<any>): Deferred {
        return typeof issueIdentifier === 'string' ?
            DAL.Issues.GetByOID(issueIdentifier, true) :
            DAL.Issues.GetByID(issueIdentifier, additionalParameters);
    }

    function setTopMenu(): void {
        let href;
        let $listItem;

        switch (Session.Mode) {
            case Enums.Mode.Calendar:
            case Enums.Mode.TaskReport:
            case Enums.Mode.NoteReport:
            case Enums.Mode.DisturbanceReport:
            case Enums.Mode.IssueProgressReport:
            case Enums.Mode.IssueReport:
            case Enums.Mode.Menu:
            case Enums.Mode.Information:
            case Enums.Mode.Parameters:
            case Enums.Mode.Settings:
                href = 'main';
                break;
            case Enums.Mode.About:
                href = 'about';
                break;
            case Enums.Mode.BluetoothConfiguration:
                href = 'bluetooth-configuration';
                break;
            case Enums.Mode.ScaleConfiguration:
                href = 'scale-configuration';
                break;
        }

        if (!!href) {
            $navbar.find('.active').removeClass('active');

            if (href === 'main') {
                $listItem = $navbar.find('.main-link');
            } else {
                $listItem = $navbar.find('a[href="#{0}"]'.format(href)).parent();
            }

            $listItem.addClass('active');
        }
    }

    function setApplicationFolderMetaData(): Deferred {
        const deferred = $.Deferred();

        if (Session.IsRunningOnIOS) {
            Session.ResourcesFolder.setMetadata(deferred.resolve, null, { 'com.apple.MobileBackup': 1 });
        } else {
            deferred.resolve();
        }

        return deferred.promise();
    }

    function askForLogout(evt?: Event): void {
        if (evt) {
            evt.preventDefault();
        }

        let deferred: Deferred;

        if (Session.IsSmartDeviceApplication) {
            deferred = DAL.Sync.GetSyncEntitiesCount()
                .then((count: number) => count > 0);
        } else {
            deferred = $.Deferred().resolve(false);
        }

        deferred
            .then((requestSync: boolean) => {
                // Meldung anhand vorhandener SyncEntities anzeigen
                const messageHeader = i18next.t('Logout.MessageHeader')
                const messageBody = requestSync ? Templates.WarnMessage({
                    Image: 'warn.svg',
                    Message: new Handlebars.SafeString(i18next.t('Logout.UnsynchronizedDataBody'))
                }) :
                    i18next.t('Logout.MessageBody');

                const promptDeferred = $.Deferred();

                const buttonOptions: Utils.Message.OptionButtons = {
                    Abort: {
                        Fn: () => promptDeferred.reject(),          // abort
                        Classes: ['btn', 'flat', 'btn-success'],
                        OneLine: requestSync,
                    },
                    Yes: {
                        Caption: i18next.t('Logout.ConfirmationButtonCaption'),
                        Fn: () => promptDeferred.resolve(false),    // false => skip synchronisation
                        Classes: ['btn', 'flat', 'btn-danger', 'btn-yes'],
                        OneLine: requestSync,
                        Timeout: requestSync ? 3 : 0
                    },
                    OnHide: () => promptDeferred.reject(),          // abort
                };

                // Direkte Synchronisation anbieten
                if (requestSync) {
                    buttonOptions.Save = {
                        Caption: `${i18next.t('Logout.SynchronizeButtonCaption')} & ${i18next.t('Logout.ConfirmationButtonCaption')}`,
                        OneLine: requestSync,
                        Fn: () => promptDeferred.resolve(true),     // true => perform synchronisation
                    };
                }

                Utils.Message.Show(messageHeader, messageBody, buttonOptions);

                return promptDeferred;
            })
            .then(function(performSync: boolean) {
                if (performSync) {
                    // Synchronisation vor dem Abmelden durchführen
                    return DAL.Sync.UploadUnsyncedData();
                }
            })
            .then(() => {
                // Abmeldung durchführen
                Logout();
            });
    }

    function navigateToMenu(href: string, $li?: any, evt?: any): void {
        let preventDefault: boolean = false;

        if (Utils.InArray([Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            const tmp = IssueView.GetCurrentIssue();

            switch (href) {
                case '#issue-report':
                    Utils.Router.PushState('issue/' + (tmp.ID || tmp.OID) + '/location/' + Session.CurrentLocation.OID + '/issue-report?type=' + tmp.Type);

                    preventDefault = true;
                    break;
                case '#covering-page':
                    Utils.Router.PushState('issue/' + (tmp.ID || tmp.OID) + '/location/' + Session.CurrentLocation.OID + '/covering-page?type=' + tmp.Type);

                    preventDefault = true;
            }

            if (View.CurrentView === Enums.View.Scheduling
                && !preventDefault
                && href.contains('/parameters')) {
                Utils.Router.PushState('issue/' + (tmp.ID || tmp.OID) + '/location/' + Session.CurrentLocation.OID + '/parameters?type=' + tmp.Type);

                preventDefault = true;
            }

            if (!preventDefault) {
                if (href.endsWith('/information')) {
                    Utils.Router.PushState('location/' + Session.CurrentLocation.OID + '/information');

                    preventDefault = true;
                } else if (href.endsWith('/parameters')) {
                    Utils.Router.PushState('location/' + Session.CurrentLocation.OID + '/parameters');

                    preventDefault = true;
                } else if (href.endsWith('/issue-report')) {
                    Utils.Router.PushState('location/' + Session.CurrentLocation.OID + '/issue-report');

                    preventDefault = true;
                }
            }
        }

        if (evt && preventDefault) {
            evt.preventDefault();
        }
    }

    function selectLocation(element: Model.Elements.Element, onAfterSelection: Function, createHeader: boolean = true): Deferred {
        if (!element) {
            return $.Deferred().reject();
        }

        if (Session.CurrentLocation.OID === element.OID) {
            Session.CurrentLocation = element;

            UpdateTreeCounters();

            if (onAfterSelection instanceof Function) {
                onAfterSelection();
                ResizeLocationPicker();
            }

            return $.Deferred().resolve();
        }

        Utils.Spinner.Show();
        const spinLock = Utils.Spinner.Lock('selLocation');

        Session.CurrentLocation = element;

        IssueReport.UpdateTeamsAndUsersFilter();

        if (createHeader) {
            CreateContentHeader();
        }

        if (!(onAfterSelection instanceof Function)) {
            navigateToMenu(Session.Mode);
        } else {
            onAfterSelection();
        }

        const $li = $navbar.find('.main-link').parent();

        $li.siblings().removeClass('active');
        $li.addClass('active');

        Utils.GetContentContainer().css('padding-bottom', '');

        if ((Session.CurrentLocation.Parametergroups || []).length > 1) {
            Session.CurrentLocation.Parametergroups.forEach(function(group) {
                group.IsCollapsed = true;
            });
        }

        if (View.CurrentView === Enums.View.Main) {
            setVisibleNavbarIcons();
            UpdateContentHeaderIssueProcessingIndicator();
        }

        if (_tree) {
            _tree
                .SetPreventAnchorActionOnce(_tree.ActiveItemIdentifier === element.OID)
                .SetActiveItem(element.OID)
                .SetScrollToActiveNode(true)
                .Build();

            UpdateTreeCounters();
        }

        ResizeLocationPicker();

        spinLock.Unlock();
        Utils.Spinner.HideWithTimeout();

        return $.Deferred().resolve();
    }

    function showExpandableNavigation(): void {
        expandableNavigationVisible = true;

        if ($(document).width() < 768) {
            _$expandableNavigation.css({
                height: _$expandableNavigation.find('.navigation').outerHeight(true),
                width: ''
            });
        } else {
            _$expandableNavigation.css({
                width: 200,
                height: ''
            });
        }
    }

    export function HideExpandableNavigation(): void {
        if (!expandableNavigationVisible) {
            return;
        }

        expandableNavigationVisible = false;

        if (!Utils.InArray([
            Enums.Mode.About,
            Enums.Mode.BluetoothConfiguration,
            Enums.Mode.ScaleConfiguration,
            Enums.Mode.SyncCenter,
            Enums.Mode.Login,
            Enums.Mode.IndividualData,
            Enums.Mode.Settings], Session.Mode)) {
            $issueFilterButton.removeClass('hidden');
        }

        $navbar.find('.icon-cheveron-left, .icon-cheveron-down')
            .attr('class', 'icon-dots-three-vertical');

        if ($(document).width() < 768) {
            _$expandableNavigation.css({
                height: 0,
                width: ''
            });
        } else {
            _$expandableNavigation.css({
                width: 0,
                height: ''
            });
        }
    }

    function bindEvents(): void {
        document.addEventListener('modechange', onModeChange, false);
        document.addEventListener('apiVersionChange', onAPIVersionChange, false);

        $locationPicker.on('click', 'li.search', onBtnToggleSearchRowClick);
        $locationPicker.on('click', '.qr-code', StartQrCodeScanner);
        $locationPicker.on('click', '.nfc', StartNfcScanner);
        $doc.on('hidden.bs.modal', '.modal', onHideModalWindow);

        $locationPickerResizer.on('mousedown', onLocationPickerResizeStart);
        $locationPickerResizer.on('touchstart', onLocationPickerResizeStart);

        enableLocationPickerSwipe();
    }

    function getSwipeConfig() {
        return new Hammer.Swipe({
            direction: Hammer.DIRECTION_RIGHT,
            threshold: 1,
            velocity: 0.4
        });
    }

    export function EnableSidebarSwipe(sidebarElement: HTMLElement) {
        if (hammerManagerSidebar) {
            hammerManagerSidebar.destroy();
            hammerManagerSidebar = null;
        }

        hammerManagerSidebar = createHammerManager(sidebarElement);

        if (hammerManagerSidebar) {
            const swipe = getSwipeConfig();

            hammerManagerSidebar.add(swipe);
            hammerManagerSidebar.on('swiperight', onHandleMainSwipeEvent);
        }
    }

    function enableLocationPickerSwipe() {
        const contentElement = $content.get(0);
        const hammerManagerContent = createHammerManager(contentElement);

        if (hammerManagerContent) {
            const swipe = getSwipeConfig();

            hammerManagerContent.add(swipe);
            hammerManagerContent.on('swiperight', onHandleMainSwipeEvent);
        }
    }

    function createHammerManager(contentElement: HTMLElement | string) {
        if (!contentElement) {
            return;
        }

        if (typeof contentElement === 'string') {
            contentElement = document.getElementById(contentElement);

            if (!contentElement) {
                return;
            }
        }

        return new Hammer.Manager(contentElement, {
            touchAction: 'auto',
            recognizers: [
                [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
            ]
        });
    }

    function onHandleMainSwipeEvent(event: Event) {
        const allowedViews = [
            Enums.View.Main,
            Enums.View.Inspection,
            Enums.View.FormBatchEdit,
            Enums.View.Scheduling,
            Enums.View.IndividualData
        ];

        if (!Utils.InArray(allowedViews, View.CurrentView)) {
            return;
        }

        if (Session.Mode === Enums.Mode.Calendar ||
            Session.Mode === Enums.Mode.Settings) {
            return;
        }

        if ($locationPicker.is(':visible')) {
            return;
        }

        const target = event.target as HTMLElement;

        if ($(target).closest('.sticky-table').length) {
            return;
        }

        const width = $(document).width();

        if (width >= 768) {
            Session.Settings.ShowTreeView = true;

            Settings.Save();

            updateTreeVisibility();
        } else {
            showLocationPickerWindow();
        }
    }

    function updateTreeVisibility() {
        View.Refresh();

        if (Session.Mode === <string>Enums.Mode.Settings) {
            Settings.Show();
        }

        if (View.CurrentView === Enums.View.Inspection ||
            View.CurrentView === Enums.View.Scheduling ||
            View.CurrentView === Enums.View.FormBatchEdit) {
            // Sidebar sichtbar machen
            IssueView.UpdateSidebar();

            // Zurück Button aktualisieren
            View.UpdateBackButton();
        }
    }

    export function setViewport(): Deferred {
        const setDeferred = $.Deferred();

        if (!Session.IsSmartDeviceApplication || !window["MobileAccessibility"]) {
            return setDeferred.resolve().promise();
        }

        MobileAccessibility.updateTextZoom(function(textZoom) {
            MobileAccessibility.usePreferredTextZoom(false);

            let viewport = document.querySelector("meta[name=viewport]");
            let currentScale = Math.max(Math.min(parseInt(textZoom, 10) / 100, 2), 1);
            let scaleWidth;

            if (Session.IsRunningOnIOS) {
                scaleWidth = Math.abs(+orientation) === 90 ? screen.height : screen.width;
                if (!Session.Settings.DisableDynamicTextSize) {
                    scaleWidth -= scaleWidth * currentScale - scaleWidth;
                }
            } else {
                scaleWidth = 'device-width';
            }

            if (!viewport) {
                viewport = document.createElement('META');
                viewport.setAttribute('name', 'viewport');
            }

            if (Session.Settings.DisableDynamicTextSize) {
                currentScale = 1;
            }

            viewport.setAttribute('content', `user-scalable=no, width=${scaleWidth}, minimum-scale=1.0, maximum-scale=2.0, initial-scale=${currentScale}`);
            setDeferred.resolve();
        });

        return setDeferred.promise();
    }

    export function ResetViewport() {
        Session.Settings.DisableDynamicTextSize = false;
        const viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute('content', `user-scalable=no, width=device-width, minimum-scale=1.0, maximum-scale=2.0, initial-scale=1`);
    }

    function resetUserPreferedTextZoom(): void {
        if (!Session.IsSmartDeviceApplication ||
            !window["MobileAccessibility"]) {
            return;
        }

        MobileAccessibility.usePreferredTextZoom(false);
    }

    export function OnNavigateToLocation(evt: Event): void {
        const identifier = $(evt.currentTarget).data('identifier');

        if (_tree.ActiveItemIdentifier !== identifier) {
            _tree
                .SetActiveItem(identifier)
                .SetScrollToActiveNode(true)
                .SetPreventAnchorActionOnce(false);
        } else {
            _tree
                .ToggleNodeState(identifier)
                .SetPreventAnchorActionOnce(true);
        }

        _tree
            .Build();
    }

    export function StartQrCodeScanner(additionalSuccessFunction?: Function): void {
        Utils.Spinner.Show();
        Utils.StartScanner(function(result) {
            onLocationScannerResult(result);

            if (additionalSuccessFunction instanceof Function) {
                additionalSuccessFunction(result);
            }
        });
    }

    export function StartNfcScanner(): Deferred {
        /*
        * Nur bei iOS erforderlich um das Scannen von Nfc zu initialisieren
        * (TO TEST auf Android)
        */
        const resultDeferred = $.Deferred();

        window.nfc.scanTag()
            .then((tag) => {
                if (!OnNdefTagRead({ tag })) {
                    resultDeferred.reject();
                    return;
                }

                resultDeferred.resolve();
            }, (err) => {
                resultDeferred.reject();
            })
            .catch((err) => {
                // Unnötige Fehlermeldungen vermeiden
                resultDeferred.reject();
            });

        return resultDeferred;
    }

    export function PrepareUserImageInHeader(): void {
        if (!$userImage) {
            $userImage = $navbar.find('.user-image');
        }

        if (!$userImageHeader) {
            $userImageHeader = $navbar.find('.navbar-content .user-image');
        }

        if ($userImage.length) {
            if (Session.User && !!Session.User.ImagePath) {
                $userImage.find('img').attr('src', Session.User.ImagePath);

                if (Session.User.ImagePath.contains('male.svg')) {
                    $userImage.find('img').addClass('placeholder');
                } else {
                    $userImage.find('img').removeClass('placeholder');
                }

                $userImage.find('img')
                    .on('error', onUserImageLoadingError);
            } else {
                $userImage.find('img').attr('src', './img/placeholder_header.svg');
            }
        }
    }

    export function HandleIssueHashChange(issueIdentifier: string | number, issueType: Enums.IssueType, isBulletin: boolean = false, onAfterSave?: Function): void {
        if (!Session.Client.Licenses.EnableInfoBoard) {
            isBulletin = false;
        }

        const params = ['withrecorditems=true'];
        const currentIssue = IssueView.GetCurrentIssue();

        issueType = issueType || Enums.IssueType.Task;

        function getOnAfterSave(): Function {
            if (!onAfterSave) {
                if (Utils.InArray([Enums.View.Main, Enums.View.Inspection], View.CurrentView)) {
                    if (View.CurrentView === Enums.View.Inspection &&
                        issueIdentifier === (currentIssue.ID || currentIssue.OID)) {
                        return IssueView.UpdateIssue;
                    } else {
                        switch (Session.Mode) {
                            case Enums.Mode.Calendar:
                                return Calendar.UpdateIssueInDayAndWeekView;
                            case Enums.Mode.DisturbanceReport:
                            case Enums.Mode.TaskReport:
                            case Enums.Mode.IssueReport:
                            case Enums.Mode.NoteReport:
                            case Enums.Mode.IssueProgressReport:
                                return IssueReport.OnIssueModified;
                            case Enums.Mode.Menu:
                                return Menu.OnIssueModified;
                        }
                    }
                } else if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
                    return IssueView.UpdateIssue;
                } else if (View.CurrentView === Enums.View.SyncCenter) {
                    return SyncCenter.Show;
                }
            }

            return onAfterSave;
        }

        function showMessage(headerText: string, bodyText: string): void {
            Utils.Message.Show(headerText,
                bodyText,
                {
                    Close: onMessageClosed
                });

            Utils.Spinner.Hide();
            Utils.Router.RestoreFragment();
        }

        function onMessageClosed(): void {
            if (View.CurrentView !== Enums.View.Main) {
                Utils.Router.OpenDefaultRoute();
            }
        }

        function onLoadingFailed(): void {
            showMessage(i18next.t('Misc.IssueNotFound.MessageHeader'), i18next.t('Misc.IssueNotFound.IssueArchivedOrDeleted'));

            if (View.CurrentView !== Enums.View.Main) {
                Utils.Router.OpenDefaultRoute();
            }
        }

        Utils.Spinner.Show();

        if (Utils.InArray(
            [
                Enums.IssueType.Form,
                Enums.IssueType.Inspection,
                Enums.IssueType.Investigation,
                Enums.IssueType.Survey
            ], issueType)) {
            if (issueType === Enums.IssueType.Inspection) {
                params.push('withancestors=true');
                params.push('withdescendants=true');
            }

            loadIssue(issueIdentifier, params)
                .then(function(issue: Model.Issues.Issue) {
                    // Stammdaten vorladen (z.B. für Formelverwendung) bevor der Vorgang geöffnet wird
                    return DAL.Sync.PreloadElementCheckpoints(issue.AssignedElementOID)
                        .then(() => issue)
                })
                .then(function(issue: Model.Issues.Issue) {
                    let viewType = Enums.View.Form;

                    if (!issue) {
                        Utils.Spinner.Hide();
                        onLoadingFailed();
                        return;
                    }

                    if (issue.IsArchived) {
                        showMessage(
                            i18next.t('IssueView.IssueArchived.MessageHeader'),
                            i18next.t('IssueView.IssueArchived.MessageBody'));

                        return;
                    }

                    if (issue.IsDeleted) {
                        showMessage(
                            i18next.t('IssueView.IssueDeleted.MessageHeader'),
                            i18next.t('IssueView.IssueDeleted.MessageBody'));

                        return;
                    }

                    if (issueType !== issue.Type) {
                        Utils.Analytics.TrackException(Enums.AnalyticsExceptionType.Other,
                            new Error(`Wrong issue type. Expected: ${issueType}, got: ${issue.Type}. Identifier: ${issueIdentifier}`));

                        showMessage(
                            i18next.t('Forms.WrongIssueType.MessageHeader'),
                            i18next.t('Forms.WrongIssueType.MessageBody'));

                        return;
                    }

                    if (!(issue.Resubmissionitems || []).length) {
                        showMessage(
                            i18next.t('Forms.RecordingImpossible.MessageHeader'),
                            i18next.t('Forms.NoResubitems.MessageBody'));

                        return;
                    }

                    if (issueType === Enums.IssueType.Inspection) {
                        viewType = Enums.View.Inspection;

                        if ((issue.Descendants || []).length) {
                            issue.Descendants = Utils.PrepareIssues(issue.Descendants);
                        }
                    }

                    View.Open(viewType, issue);
                }).fail(onLoadingFailed);
        } else if (issueType === Enums.IssueType.Scheduling) {
            loadIssue(issueIdentifier, params)
                .then(function(issue: Model.Issues.Issue) {
                    // Stammdaten vorladen (z.B. für Formelverwendung) bevor der Vorgang geöffnet wird
                    return DAL.Sync.PreloadElementCheckpoints(issue.AssignedElementOID)
                        .then(() => issue)
                })
                .then(function(issue: Model.Issues.Issue) {
                    if (!issue) {
                        Utils.Spinner.Hide();
                        onLoadingFailed();
                        return;
                    }

                    if (issue.IsArchived) {
                        showMessage(
                            i18next.t('IssueView.IssueArchived.MessageHeader'),
                            i18next.t('IssueView.IssueArchived.MessageBody'));

                        return;
                    }

                    if (issue.IsDeleted) {
                        showMessage(
                            i18next.t('IssueView.IssueDeleted.MessageHeader'),
                            i18next.t('IssueView.IssueDeleted.MessageBody'));

                        return;
                    }

                    if (issueType !== issue.Type) {
                        Utils.Analytics.TrackException(Enums.AnalyticsExceptionType.Other,
                            new Error(`Wrong issue type. Expected: ${issueType}, got: ${issue.Type}. Identifier: ${issueIdentifier}`));

                        showMessage(
                            i18next.t('Forms.WrongIssueType.MessageHeader'),
                            i18next.t('Forms.WrongIssueType.MessageBody'));

                        return;
                    }

                    if (!(issue.Resubmissionitems || []).length) {
                        showMessage(
                            i18next.t('Resubmissions.RecordingImpossible.MessageHeader'),
                            i18next.t('Resubmissions.NoResubitems.MessageBody'));

                        return;
                    }

                    View.Open(Enums.View.Scheduling, issue);
                }).fail(onLoadingFailed);
        } else {
            if (isBulletin) {
                params.push('withdescendants=true');

                loadIssue(issueIdentifier, params)
                    .then(function(issue: Model.Issues.Issue) {
                        if (!issue) {
                            Utils.Spinner.Hide();
                            onLoadingFailed();
                            return;
                        }

                        if (issue.IsArchived) {
                            showMessage(
                                i18next.t('IssueView.IssueArchived.MessageHeader'),
                                i18next.t('IssueView.IssueArchived.MessageBody')
                            );

                            return;
                        }

                        if (issue.IsDeleted) {
                            showMessage(
                                i18next.t('IssueView.IssueDeleted.MessageHeader'),
                                i18next.t('IssueView.IssueDeleted.MessageBody')
                            );

                            return;
                        }

                        View.Open(Enums.View.IssueFullscreen, issue);
                        Utils.Spinner.Hide();
                    }).fail(onLoadingFailed);
            } else {
                const isIssueReport = Session.Mode === Enums.Mode.TaskReport ||
                    Session.Mode === Enums.Mode.NoteReport ||
                    Session.Mode === Enums.Mode.DisturbanceReport ||
                    Session.Mode === Enums.Mode.IssueReport ||
                    Session.Mode === Enums.Mode.IssueProgressReport;

                const onCommentSaved = isIssueReport ? IssueReport.OnCommentSaved : null;
                const onCommentDeleted = isIssueReport ? IssueReport.OnCommentDeleted : null;

                Utils.IssueViewer.OpenIssue(issueIdentifier, getOnAfterSave(), onCommentSaved, onCommentDeleted);
                Utils.Spinner.HideWithTimeout();
            }
        }
    }

    export function LabelPage(): void {
        $('nav.navbar .btn-home').html('<img src="./img/menu/house.svg" height="24" width="24" />');
        $('head > title').text(i18next.t('Misc.PageTitle'));
    }

    export function GetBadgeCounters(): Deferred {
        const deferred = $.Deferred();

        Utils.Spinner.Show();
        const spinLock = Utils.Spinner.Lock('gBadgeCnt');

        deferred.always(() => {
            spinLock.Unlock();
            Utils.Spinner.HideWithTimeout();
        })

        setTimeout(function() {
            if (View.CurrentView === Enums.View.Main) {
                const filter = IssueReport.GetFilters();

                if (filter != null) {
                    filter.Types = [];

                    if (IssueReport.IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Task)) {
                        filter.Types.push(Enums.IssueType.Task);
                    }

                    if (IssueReport.IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Scheduling)) {
                        filter.Types.push(Enums.IssueType.Scheduling);
                    }

                    if (IssueReport.IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Form)) {
                        filter.Types.push(Enums.IssueType.Form);
                    }

                    if (IssueReport.IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Inspection) &&
                        Session.Client.Licenses.Inspections) {
                        filter.Types.push(Enums.IssueType.Inspection);
                    }

                    if (IssueReport.IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Survey) &&
                        Session.Client.Settings.SyncOpenSurveys) {
                        filter.Types.push(Enums.IssueType.Survey);
                    }

                    if (IssueReport.IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Investigation) &&
                        Session.Client.Settings.SyncOpenInvestigations) {
                        filter.Types.push(Enums.IssueType.Investigation);
                    }

                    if (IssueReport.IsTypeFilterAllowed(Enums.MenuItemID.NoteReport, Enums.IssueType.Note)) {
                        filter.Types.push(Enums.IssueType.Note);
                    }

                    if (IssueReport.IsTypeFilterAllowed(Enums.MenuItemID.DisturbanceReport, Enums.IssueType.Disturbance)) {
                        filter.Types.push(Enums.IssueType.Disturbance);
                    }
                }

                IssueReport.GetCounter(filter)
                    .then((counters: Model.Menu.Counters) => onAfterMenuCountersLoaded(counters))
                    .then(deferred.resolve, deferred.reject);
            } else if (View.CurrentView === Enums.View.Inspection) {
                IssueReport.GetCounter()
                    .then(function(response: { IssueCount: any; }) {
                        const counters = {
                            SubIssues: response.IssueCount,
                            CoveringPageParameters: IssueView.GetCoveringPageParameterCount()
                        };

                        deferred.resolve(counters);
                    }, deferred.reject);
            } else {
                onAfterMenuCountersLoaded()
                    .then(deferred.resolve, deferred.reject);
            }
        }, 10);

        return deferred;
    }

    function onAfterMenuCountersLoaded(counter?: Model.Menu.Counters): Deferred {
        const counters = {
            Tasks: null,
            Notes: null,
            Disturbances: null,
            IssueInformation: null,
            Custom: {},
            Visits: 0,
            Issues: 0,
            SubIssues: 0,
            Parameters: 0,
            CoveringPageParameters: 0,
            FormTemplates: 0,
            Schedulings: 0,
            LocationIssueProceedingStatus: Enums.IssueProcessingStatus.OK
        };

        if (counter) {
            counters.Schedulings = counter.SchedulingsCount;

            if (!Session.Settings.ShowAllIssuesInOneReport) {
                counters.Tasks = counter.IssueCounters.Tasks;
                counters.Notes = counter.IssueCounters.Notes;
                counters.Disturbances = counter.IssueCounters.Disturbances;

                if (counters.Tasks) {
                    counters.Issues += counters.Tasks.IssueCount;
                    counters.LocationIssueProceedingStatus = Math.max(
                        counters.LocationIssueProceedingStatus,
                        counters.Tasks.ProcessingStatus);
                }

                if (counters.Notes) {
                    counters.Issues += counters.Notes.IssueCount;
                    counters.LocationIssueProceedingStatus = Math.max(
                        counters.LocationIssueProceedingStatus,
                        counters.Notes.ProcessingStatus);
                }

                if (counters.Disturbances) {
                    counters.Issues += counters.Disturbances.IssueCount;
                    counters.LocationIssueProceedingStatus = Math.max(
                        counters.LocationIssueProceedingStatus,
                        counters.Disturbances.ProcessingStatus);
                }
            } else {
                counters.IssueInformation = counter.IssueCounters.Issues;

                if (counters.IssueInformation) {
                    counters.Issues += counters.IssueInformation.IssueCount;
                    counters.LocationIssueProceedingStatus = Math.max(
                        counters.LocationIssueProceedingStatus,
                        counters.IssueInformation.ProcessingStatus);
                }
            }

            if (counter.IssueCounters.Custom) {
                for (let key in counter.IssueCounters.Custom) {
                    const custCounter = counter.IssueCounters.Custom[key];
                    counters.Custom[key] = custCounter;
                }
            }
        }

        if ((Session.CurrentLocation.Forms || []).length) {
            const allowRegularForms = Utils.CanUserCreateIssueType(Enums.IssueType.Form, Session.CurrentLocation);
            const allowInspections = Session.Client.Licenses.Inspections &&
                Utils.CanUserCreateIssueType(Enums.IssueType.Inspection, Session.CurrentLocation);

            counters.FormTemplates = $.map(Session.CurrentLocation.Forms, function(oid: string) {
                const form = DAL.Elements.GetByOID(oid);

                if (!form ||
                    form.IsSurvey ||
                    form.IsInvestigation) {
                    return;
                }

                if (!(form.IsInspection && allowInspections) &&
                    !(!form.IsInspection && allowRegularForms)) {
                    return;
                }

                return form;
            }).length;
        }

        if (!Session.IsSmartDeviceApplication) {
            counters.Schedulings = DAL.Scheduling.GetCountOfSchedulingsAtLocation(Session.CurrentLocation.OID);
        }

        return $.Deferred().resolve(counters).promise();
    }

    export function CreateOrRecycleSearchInputField(id?: string, toggleOpen?: boolean): Model.ClearableInput.Control {
        const $locationSearchRow = $locationPicker.find('div.search');

        id = id || 'location-search-input';
        const searchPresentation = Session.UserDeviceSettings.SearchResultPresentation;

        if (!$locationSearchRow.find('input').length) {
            const searchInputOptions = new Model.ClearableInput.Settings({
                ID: id,
                InputType: 'text',
                ShowClearButton: true,
                ShowPresentationButton: true,
                ShowFilterButton: true,
                DefaultPresentation: searchPresentation
            });

            searchInputField = new Model.ClearableInput.Control(searchInputOptions);
            searchInputField.Build($locationSearchRow);
        } else {
            searchInputField.SetSearchPresentation(true, searchPresentation);
            searchInputField.Clear(true);
        }

        // Suchefeld bei Erstellung bereits aufklappen
        if (toggleOpen && $locationSearchRow.hasClass('hidden')) {
            $locationSearchRow.toggleClass('hidden');

            if (!Session.IsSmartDeviceApplication || Session.IsRunningOnAndroid) {
                $locationSearchRow.find('input').focus();
            }
        }

        return searchInputField;
    }

    export function ShowMainMode(callback: Function): void {
        if (!Session.User) {
            Logout();

            return;
        }

        if (Session.IsSmartDeviceApplication) {
            $locationPicker.find('.buttons .qr-code').removeClass('hidden');

            // iOS only required
            if (Session.NfcEnabled && Session.IsRunningOnIOS) {
                $locationPicker.find('.buttons .nfc').removeClass('hidden');
            }
        }

        const searchField = CreateOrRecycleSearchInputField();
        if (searchField) {
            searchField.SetFilterButtonVisible(true);
        }

        if (!Session.CurrentLocation) {
            if (!!Session.User.RootElementOID && DAL.Elements.Exists(Session.User.RootElementOID)) {
                Session.CurrentLocation = DAL.Elements.GetByOID(Session.User.RootElementOID);
            } else {
                Session.CurrentLocation = DAL.Elements.Root;
            }
        }

        initNavigation();
        UpdateContentHeaderIssueProcessingIndicator();

        $homeButton.removeClass('hidden');
        $issueFilterButton.removeClass('hidden');
        $issueProcessingIndicator.toggleClass('hide', !!Session.Settings.DisableProcessingStatusSmiley);

        if ($syncButton) {
            $syncButton.removeClass('hidden');

            if (SyncCenter.IsSyncing()) {
                View.SetSyncIconClass('default animated');
            }
        }

        Utils.Spinner.Show();
        setTimeout(function() {
            Utils.GetContentContainer().empty();

            View.Open(Enums.View.Main, function() {
                if (renderTree && $locationPicker.css('display') !== 'none' && Session.Settings.ShowTreeView) {
                    UpdateTree();

                    renderTree = false;
                }

                if (Session.IsSmartDeviceApplication) {
                    $locationPicker.find('input[type="search"]').removeClass('web');
                }

                setTopMenu();
                CreateContentHeader();
                setVisibleNavbarIcons();

                if (callback) {
                    callback();
                }
            }, true);
        }, 10);
    }

    export function Logout(options?: { ClientDataHasBeenWiped: boolean }): Deferred {
        const deferred = $.Deferred();

        IssueView.Close(false);
        $('.row .content-header').remove();
        $navbar.addClass('hidden');
        $navbar.find('a[href^="#individual-data"]').parent().remove();
        $('body').css('padding', 10);
        renderTree = true;
        $locationPicker.find('.tree-wrapper > .tree').empty();
        $locationPicker.find('.tree-wrapper').scrollTop(0);
        _tree = null;

        Session.Destroy()
            .then(function() {
                Utils.Http.ReAuthDeferred = [];
                IssueReport.ResetFilters();
                ParameterList.ResetFilters();
                Utils.Spinner.ClearLocksOnLogout();
                Utils.Spinner.Hide();

                if (Session.IsSmartDeviceApplication) {
                    Utils.ScaleDeviceManager.Shutdown();
                }

                View.StopRefreshTimeout();
                View.SetView(Enums.View.Login);

                App.Init.show();

                if (options && options.ClientDataHasBeenWiped) {
                    // show clearing animation
                    App.Init.showIcons([App.Init.Enums.Icon.ClearApp], false)
                        .then(() => {
                            return App.Init.moveSpinner(App.Init.Enums.SpinnerPosition.Center);
                        })
                        .then(deferred.resolve, deferred.reject);
                } else {
                    App.Init.showIcons([
                        Init.Enums.Icon.Init,
                        Init.Enums.Icon.Login,
                        Session.IsSmartDeviceApplication ? Init.Enums.Icon.LoadMemory : Init.Enums.Icon.Download
                    ], false)
                        .then(() => {
                            deferred.resolve();
                            return App.Init.enableInit();
                        })
                        .then(() => {
                            return App.Init.moveSpinner(App.Init.Enums.SpinnerPosition.Center);
                        })
                        .then(Login.InitUser)
                        .then(Login.InitData)
                        .progress((percent: number) => {
                            Init.setProgress(percent);
                        })
                        .then(App.Init.hide)
                        .then(setViewport)
                        .then(() => {
                            const currentFragment = Utils.Router.GetCurrentFragment();
                            if (currentFragment.contains('settings') ||
                                currentFragment.contains('about') ||
                                currentFragment.contains('sync-center')) {
                                Utils.Router.ReplaceState(Utils.Router.GetDefaultRoute());
                            } else {
                                // Goto capture view
                                Utils.Router.RefreshState();
                            }
                        });
                }
                Utils.Router.ResetState();
            });

        return deferred.promise();
    }

    export function ShowAbout(): void {
        Utils.SetMode(Enums.Mode.About);

        View.Open(Enums.View.About);

        setVisibleNavbarIcons();

        Utils.GetContentContainer().html(Templates.About({
            CurrentYear: new Date().getFullYear(),
            VersionString: versionString,
            BuildNumber: buildNumber,
            PrivacyLinkText: i18next.t('About.PrivacyLinkText'),
            Imprint: i18next.t('About.Imprint')
        }));
    }

    export function GetAndSelectLocation(oid: string, onAfterSelection?: Function, createHeader: boolean = true): Deferred {
        let element = DAL.Elements.GetByOID(oid);

        if (Utils.InArray([Enums.View.Form, Enums.View.Scheduling], View.CurrentView)) {
            element = ParameterList.GetElementRevisionByOID(oid);
        }

        if (!element || !Utils.InArray([Enums.ElementType.Root, Enums.ElementType.Location], element.Type)) {
            element = DAL.Elements.Root;

            if (!element) {
                return $.Deferred().reject();
            }

            if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling], View.CurrentView)) {
                const currentFragment = Utils.Router.GetCurrentFragment();
                Utils.Router.ReplaceState(currentFragment.replace(oid, element.RevisionOID), currentFragment !== 'about');
            } else {
                Utils.Router.ReplaceState(Utils.Router.GetCurrentFragment().replace(oid, element.OID));
            }
        }

        if (element) {
            // Prüfgruppen & Prüfpunkte zur OE vorladen
            return DAL.Sync.PreloadElementCheckpoints(element.OID)
                .then(() => selectLocation(element, onAfterSelection, createHeader));
        }

        return $.Deferred().reject();
    }

    export function CreateContentHeader(): void {
        const $content = $('#content');
        const $contentHeader = View.CurrentView === Enums.View.Inspection
            ? Utils.GetInspectionContentHeader(Session.CurrentLocation)
            : Utils.GetDefaultContentHeader(Session.CurrentLocation);

        if ($content.siblings('.content-header').length) {
            $content.siblings('.content-header').replaceWith($contentHeader);
        } else {
            $content.before($contentHeader);
        }

        $contentHeader.on('click', OnContentHeaderClick);
        App.ResizeLocationPicker();
    }

    export function UpdateContentHeaderIssueProcessingIndicator(): Deferred {
        if (!Session.CurrentLocation) {
            return $.Deferred().resolve();
        }

        if (!Utils.HasAnyMenuItemOfActionType(Enums.Menu.Action.Issues, Session.CurrentLocation.OID)) {
            $issueProcessingIndicator.addClass('hide');
            return $.Deferred().resolve();
        }

        if (Session.Settings.DisableProcessingStatusSmiley) {
            // ProcessingInfo braucht nicht ermittelt zu werden
            $issueProcessingIndicator.addClass('hide');
            return $.Deferred().resolve();
        }

        return IssueReport.GetIssueProcessingInfo()
            .then(function(processingStatus: Enums.IssueProcessingStatus) {
                const anchorHref = `#issue-report/progress/${processingStatus}`;

                $issueProcessingIndicator.removeClass('hide')
                    .find('a').attr('href', anchorHref);

                switch (processingStatus) {
                    case Enums.IssueProcessingStatus.Warning:
                        $issueProcessingIndicator.find('img').attr('src', './img/smiley_yellow.svg');
                        break;
                    case Enums.IssueProcessingStatus.Overdue:
                        $issueProcessingIndicator.find('img').attr('src', './img/smiley_red.svg');
                        break;
                    default:
                        $issueProcessingIndicator.find('img').attr('src', './img/smiley_green.svg');
                        break;
                }
            });
    }

    export function SetRenderTree(value: boolean): void {
        renderTree = value;
    }

    export function UpdateTree(): void {
        if (!Session.Settings.ShowTreeView) {
            return;
        }

        if (View.CurrentView === Enums.View.IndividualData) {
            IndividualData.UpdateTree();
            return;
        }

        if (!Session.CurrentLocation || !DAL.Elements.Root || !DAL.Elements.GetAll()) {
            return;
        }

        let oldCounters = {};

        if (_tree) {
            oldCounters = _tree.GetCounters();
        }

        _tree = new Model.Tree()
            .SetCounters(oldCounters)
            .SetIdentifier('location-picker')
            .SetRouteBase('location')
            .SetAnchorSuffix(null)
            .SetRootCollapsable(false)
            .SetShowParentTitle(false)
            .SetShowExpanders(true)
            .SetEnableAnchors(true)
            .SetForceExpand(false)
            .SetRenderAsListView(false)
            .SetKeyProperty('OID')
            .SetFilter((item: Model.Elements.Element) => item.Type == Enums.ElementType.Root || item.Type == Enums.ElementType.Location)
            .SetItems(DAL.Elements.GetAll(), DAL.Elements.Root.OID)
            .SetActiveItem(Session.CurrentLocation.OID)
            .SetRenderingContainer($locationPicker.find('.tree-wrapper'))
            .SetOnNodeClick(OnNavigateToLocation)
            .SetSearchField(CreateOrRecycleSearchInputField('main-tree-view-search-input'), i18next.t('SearchfieldPlaceholders.MainTree'))
            .Build();
    }

    export function UpdateTreeRoute(): void {
        if (_tree) {
            _tree.UpdateRoute('location', null);
        }
    }

    export function UpdateTreeItems(): void {
        if (_tree && View.CurrentView !== Enums.View.FormBatchEdit) {
            if (View.CurrentView === Enums.View.IndividualData) {
                IndividualData.UpdateItems()
            } else {
                _tree.SetItems(DAL.Elements.GetAll())
                    .Build();
            }
        }
    }

    export function UpdateTreeCounters(): void {
        if (!Session.Settings.ShowTreeView || (!Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion < 5) || !_tree) {
            return;
        }

        // TODO update
        if (View.CurrentView == Enums.View.FormBatchEdit) {
            return
        }

        if (!Session.Settings.ShowTreeCounters || Utils.InArray([Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            _tree.UpdateCounters({});
            return;
        }

        IssueReport.GetTreeCounters()
            .done($.proxy(_tree.UpdateCounters, _tree));
    }

    export function GetVersionString(): string {
        return versionString;
    }

    export function ResizeLocationPicker(): void {
        const $contentHeader = $locationPicker.siblings('.content-header');
        const $contentFooter = $locationPicker.siblings('.footer-toolbar.align-to-content-container');
        const $viewFooter = $locationPicker.siblings('.footer-toolbar:not(.align-to-content-container)');
        const $backButton = $locationPicker.siblings('.back-button');

        $locationPicker.css('bottom', $viewFooter.is(':visible') ? $viewFooter.outerHeight() || null : null);

        const issueSidebarWidth = IssueView.GetIssueSidebarWidth();

        if (Session.Settings.LocationPickerWidth && $locationPicker.is(':visible')) {
            const percentageString = `${Session.Settings.LocationPickerWidth}%`;
            const footerPercentageString = `calc(${Session.Settings.LocationPickerWidth}% - 15px)`;
            const contentPercentageString = `calc(${Session.Settings.LocationPickerWidth}% - 13px)`;

            $locationPicker.css('width', percentageString, 'important');
            $contentHeader.css('left', percentageString, 'important');
            $backButton.css('left', percentageString, 'important');
            $contentFooter.css('left', footerPercentageString, 'important');
            $content.css('margin-left', contentPercentageString, 'important');

            $content.css({
                left: issueSidebarWidth
            });

            $locationPickerResizer.removeClass('hidden');
            $locationPickerResizer.css('top', $locationPicker.offset().top);
            $locationPickerResizer.css('left', `${$locationPicker.width() - $locationPickerResizer.width() / 2}px`);
            $locationPickerResizer.css('height', `${$locationPicker.height()}px`);
        } else {
            $locationPicker.css('width', '');
            $contentHeader.css('left', '');
            $content.css('margin-left', '');

            View.UpdateBackButton();

            const clientWidth = $(document).width();

            if (clientWidth >= 768) {
                if ($content.attr('class') === 'col-xs-12') {
                    $content.css({
                        left: issueSidebarWidth
                    });
                }
            } else {
                $content.css({
                    width: '',
                    left: ''
                });
            }

            Session.Settings.ShowTreeView ? $contentFooter.css('left', '') : $contentFooter.css('left', 0);

            $locationPickerResizer.addClass('hidden');
        }
    }

    export function GetIsDebugEnabled(): boolean {
        return DEBUG_ENABLED;
    }

    export function GetIsDebugEnabledForPush(): boolean {
        return DEBUG_ENABLED_PUSH;
    }

    export function SetTree(tree: Model.Tree): void {
        _tree = tree;
    }

    export function GetTree(): Model.Tree {
        return _tree;
    }

    export function GetCustomLocationPickerWidth(): string {
        return $('section.location-picker').is(':visible') ?
            Session.Settings.LocationPickerWidth + '%' :
            null;
    }

    export function OnContentHeaderClick(): void {
        showLocationPickerWindow();
    }

    function showLocationPickerWindow(): void {
        let additionalClasses: Dictionary<string[]>;
        let additionalTexts: Dictionary<string>;
        let rootItem = DAL.Elements.Root;
        let items = DAL.Elements.GetAll();

        if (View.CurrentView === Enums.View.Scheduling) {
            additionalClasses = IssueView.GetClassesForTreeView();

            if (ParameterList.AreFiltersActive()) {
                const counter = IssueView.GetFilteredParametersCount();

                additionalTexts = {};

                for (let key in counter.Locations) {
                    if (!counter.Locations.hasOwnProperty(key)) {
                        continue;
                    }

                    const locationCount = counter.Locations[key];

                    additionalTexts[key] = ' ({0} <span class="icon-filter"></span>)'.format(locationCount);
                }
            }

            rootItem = ParameterList.GetResubmissionTree();
            items = ParameterList.GetAllElementRevisions();
        }

        elementPickerPopup = Utils.ElementPickerPopup.Show({
            RootItem: rootItem,
            Items: items,
            EnableKeywordFilter: true,
            SelectedItemIdentifier: Session.CurrentLocation.OID,
            FloorPlanID: 'content-header-location-picker-floor-plan',
            AdditionalClasses: additionalClasses,
            AdditionalTexts: additionalTexts,
            ShowFloorPlan: true,
            ShowQRCodeScannerButton: true,
            ShowNfcScannerButton: true,
            CannotCollapseRoot: true,
            OnConfirmSelection: function(result) {
                if (!result || !result.ElementOID) {
                    return;
                }

                if (View.CurrentView === Enums.View.Main) {
                    Utils.Router.PushState('location/{0}/{1}'.format(
                        result.ElementOID,
                        Session.Mode === Enums.Mode.IssueProgressReport ? Enums.Mode.Menu : Session.Mode));
                } else {
                    const issue = IssueView.GetCurrentIssue();

                    if (!issue) {
                        return;
                    }

                    Utils.Router.PushState('issue/{0}/location/{1}?type={2}'.format(
                        issue.ID || issue.OID,
                        result.ElementOID,
                        issue.Type));
                }
            },
            OnDestroy: (picker: Utils.ElementPickerPopup) => {
                if (picker === elementPickerPopup) {
                    elementPickerPopup = null;
                }
            }
        });
    }

    export function SetBluetoothNavbar(): void {
        if (!$navbar) {
            return;
        }

        $navbar.find('a[href="#bluetooth-configuration"]').parent()
            .toggleClass('hidden', (Utils.GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Settings) || []).indexOf(Enums.MenuItemID.BluetoothConfiguration) === -1);
    }

    export function SetScaleNavbar(): void {
        if (!$navbar) {
            return;
        }

        $navbar.find('a[href="#scale-configuration"]').parent()
            .toggleClass('hidden', (Utils.GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Settings) || []).indexOf(Enums.MenuItemID.ScaleConfiguration) === -1);
    }

    export function BindNfcEvents(): void {
        Utils.CheckIfNfcIsAvailable()
            .then(onNfcIsAvailable, onNfcIsNotAvailable);
    }

    function onNfcIsAvailable(): void {
        Session.NfcEnabled = true;

        Utils.IsPermissionGranted(cordova.plugins.permissions.NFC)
            .then(null, () => Utils.RequestPermission(cordova.plugins.permissions.NFC))
            .then(onAfterGotNfcPermission);
    }

    function onNfcIsNotAvailable(): void {
        Session.NfcEnabled = false;
    }

    function onAfterGotNfcPermission(): void {
        window.nfc.addNdefListener(OnNdefTagRead);
    }

    export function OnNdefTagRead(nfcEvent): boolean {
        const factory = new Utils.Nfc.DecoderFactory(nfcEvent.tag);
        const tagDecoder = factory.create();
        const toastTiming = Session.IsRunningOnIOS ? 4 : 0.2; // längere Anzeigedauer auf iOS, weil Toast-Meldung durch NFC Dialog überlagert wird

        navigator.vibrate([200, 100, 200]);

        if (!tagDecoder) {
            Utils.Toaster.Show(i18next.t('NFC.UnknownTagScanned'), toastTiming, Enums.Toaster.Icon.Warning);
            return false;
        }

        Utils.Toaster.Show(i18next.t('NFC.TagScanned'), toastTiming);

        if (tagDecoder instanceof Utils.Nfc.Nhs3100Decoder) {
            const element = DAL.Elements.GetByIdentCode(tagDecoder.GetDecodedId());

            ParameterList.OnAfterGotMeasuredTemperature(tagDecoder.GetTemperatureValue(), element);
        } else if (tagDecoder instanceof Utils.Nfc.NavTagDecoder) {
            const locationIdentifier = tagDecoder.GetLocationIdentifier();

            if (!locationIdentifier) {
                return false;
            }

            onNfcLocationTagScanned(locationIdentifier);
        }

        return true;
    }

    setTimeout(() => {
        App.Init.showIcons([
            Init.Enums.Icon.Init,
            Init.Enums.Icon.Login,
            typeof cordova !== 'undefined' ? Init.Enums.Icon.LoadMemory : Init.Enums.Icon.Download  // prüft ob SmartDevice bevor die Session initialisiert wurde
        ]);
    }, 10);

    $doc.ready(onDocumentReady);
}
