///<reference path="./i-response-base.ts"/>
///<reference path="../workflow-base.ts"/>

module New.Checkpoints.Workflow.Response {
    export abstract class ResponseBase implements IResponseBase {
        public readonly Workflow: WorkflowBase;

        protected constructor(workflow: WorkflowBase) {
            if (!workflow) {
                throw new Error('no workflow provided');
            }

            this.Workflow = workflow;
        }
    }
}