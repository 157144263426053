//imports-start
/// <reference path="../core/revision.ts"  />
//imports-end

module Model.ContactGroups {
    export class ContactGroup extends Model.Core.Revision {
        Title: string;
        Contacts?: Array<string>;
        Locations?: Array<string>;
        Statuses?: Array<string>;
    }
}