//imports-start
//// <reference path="../../definitions.d.ts"  />
/// <reference path="../core/revision.ts"  />
/// <reference path="../roles/right.ts"  />
/// <reference path="../elements/element-rights.ts"  />
/// <reference path="./synchronization.ts" />
/// <reference path="../individual-data/schema.ts" />
//// <reference path="../../utils/utils.analytics.js"  />
//imports-end

module Model.Users {
    export class RawUser extends Model.Core.Revision {
        Username: string;
        EnforcePasswordPolicy: boolean;
        ChangePassword: boolean;
        EmailAddress: string;
        Firstname: string;
        Lastname: string;
        ImageOID?: string;
        Language: string;
        IsSystemUser: boolean;
        IsTrustUser: boolean;
        IsInvestigationUser: boolean;
        IsExternalUser: boolean;
        IsLocked: boolean;
        RootElementOID?: string;
        Settings: Dictionary<any>;
        Rights: Array<Model.Roles.Right>;
        ElementRights: Array<Model.Elements.ElementRights>;
        Teams: Array<string>;
        LastSynchronization: Model.Users.Synchronization;
    }
}