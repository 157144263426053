///<reference path="response-base.ts"/>
///<reference path="../workflow-base.ts"/>


module New.Checkpoints.Workflow.Response {
    export class ModifyParentIssueWorkflow extends IssueResponseBase {
        constructor(workflow: WorkflowBase, issue?: Model.Issues.Issue) {
            super(workflow, issue);
        }

        createWorkflowText(): string {
            return i18next.t('CorrectiveActions.ActionState.ModificationsApplied');
        }
    }
}
