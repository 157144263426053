///<reference path="issue-response-base.ts"/>
///<reference path="../workflow-base.ts"/>
///<reference path="../../../../model/issues/issue.ts"/>

module New.Checkpoints.Workflow.Response {
    export class FormWorkflow extends IssueResponseBase {
        readonly Issue: Model.Issues.Issue;

        constructor(workflow: WorkflowBase, issue: Model.Issues.Issue) {
            super(workflow);

            if (!issue) {
                throw new Error('no issue provided');
            }

            this.Issue = issue;
        }

        createWorkflowText(): string {
            let text = `${i18next.t('CorrectiveActions.ActionState.Created')}: `;

            if (this.Issue.ID) {
                text += this.Issue.ID;

                if (!!this.Issue.Title) {
                    text += ` - ${this.Issue.Title}`;
                }
            } else if (!!this.Issue.Title) {
                text += this.Issue.Title;
            }

            return text;
        }
    }
}