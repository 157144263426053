//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="./model.ts"  />
//imports-end

module Model {
    export class Color extends Serializable {
        private r: number = 0;
        private g: number = 0;
        private b: number = 0;
        private a: number = 1;

        constructor(val: string | Color | boolean) {
            super();

            if (val && typeof val === 'string' && val.length >= 4) {
                val = val.toLowerCase();

                if (val[0] === '#') {
                    this._resolveHex(val);
                } else if (val.substr(0, 4) === 'rgb(' || val.substr(0, 5) === 'rgba(') {
                    this._resolveRGBX(val);
                }
            } else if (val instanceof Color) {
                this.r = val.r;
                this.g = val.g;
                this.b = val.b;
                this.a = val.a;
            } else if (typeof val === 'boolean') {
                this.r = this.g = this.b = val ? 255 : 0;
            }
        }

        private _resolveHex(val: string): void {
            this.a = 1.0;

            if (val.length === 9) {
                this.a = parseInt(val.substr(1, 2), 16) / 256.0;
                this.r = parseInt(val.substr(3, 2), 16);
                this.g = parseInt(val.substr(5, 2), 16);
                this.b = parseInt(val.substr(7, 2), 16);
            }
            else if (val.length === 7) {
                this.r = parseInt(val.substr(1, 2), 16);
                this.g = parseInt(val.substr(3, 2), 16);
                this.b = parseInt(val.substr(5, 2), 16);
            } else if (val.length === 4) {
                let tmp = parseInt(val[1], 16);
                this.r = (tmp << 4) + tmp;

                tmp = parseInt(val[2], 16);
                this.g = (tmp << 4) + tmp;

                tmp = parseInt(val[3], 16);
                this.b = (tmp << 4) + tmp;
            }
        }

        private _resolveRGBX(val: string): void {
            const start = val.indexOf('(');
            const end = val.lastIndexOf(')');
            const tmp = val.substr(start + 1, (end - start) - 1).split(',');

            if (tmp.length >= 3) {
                this.r = parseInt(tmp[0], 10);
                this.g = parseInt(tmp[1], 10);
                this.b = parseInt(tmp[2], 10);

                if (tmp.length === 4) {
                    this.a = parseFloat(tmp[3]);
                }
            }
        }

        public GetContrastColor(): Color {
            const brightness = this.r * 0.299 + this.g * 0.587 + this.b * 0.114;
            return new Color(brightness < 186);
        }

        getHex(): string {
            const rs = this.r.toString(16).padleft('0', 2);
            const gs = this.g.toString(16).padleft('0', 2);
            const bs = this.b.toString(16).padleft('0', 2);

            return `#${rs}${gs}${bs}`;
        }

        getRGB(): string {
            return `rgb(${this.r}, ${this.g}, ${this.b})`;
        }

        getRGBA(): string {
            return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`;
        }

        getRed(): number {
            return this.r;
        }

        getGreen(): number {
            return this.g;
        }

        getBlue(): number {
            return this.b;
        }

        getAlpha(): number {
            return this.a;
        }

        setRed(val: number): Color {
            this.r = Math.min(Math.max(val || 0, 0), 255);
            return this;
        }

        setGreen(val: number): Color {
            this.g = Math.min(Math.max(val || 0, 0), 255);
            return this;
        }

        setBlue(val: number): Color {
            this.b = Math.min(Math.max(val || 0, 0), 255);
            return this;
        }

        setAlpha(val: number): Color {
            this.a = Math.min(Math.max(val || 1.0, 0), 1);
            return this;
        }

        GetJSON(): string {
            return JSON.stringify({
                red: this.r,
                green: this.g,
                blue: this.b,
                alpha: this.a
            });
        }
    }
}
