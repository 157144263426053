//imports-start
/// <reference path="../definitions.d.ts" />
//imports-end

module Utils.LocationInformation {
    let _$win, _$overlay;
    let _location;

    let _imageCounter, _imagesTotal;
    let _onAfterRendered: ($win: any) => void;

    function destroy(): void {
        _location = null;
        _imageCounter = 0;
        _imagesTotal = 0;

        if (_$win) {
            _$win.remove();
            _$win = null;
        }

        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }
    }

    function onImageLoaded(): void {
        _imageCounter++;

        if (_imageCounter === _imagesTotal) {
            onAllImagesLoaded();
        }
    }

    function onImageError(): void {
        _imageCounter++;

        if (_imageCounter === _imagesTotal) {
            onAllImagesLoaded();
        }
    }

    function onAllImagesLoaded() {
        if (_onAfterRendered) {
            Utils.RepositionModalWindow(_$win);
            _onAfterRendered(_$win);
        }
    }

    function onFileClick(): void {
        let $this = $(this);
        let $a = $this.find('a');
        let isImage = $a.hasClass('image');
        let isVideo = $a.hasClass('video');

        if (isImage) {
            Utils.OpenFiles($.map(_location.Files, function (elementFile) {
                if (DAL.Files.Exists(elementFile.OID)) {
                    return DAL.Files.GetByOID(elementFile.OID);
                }
            }), $this.data('filename'), null, _location.Title);
        } else if (isVideo) {
            let file = DAL.Files.GetByFilename($this.data('filename'));

            if (!file) {
                return;
            }

            Utils.OpenFile($this.data('filename'), false, true, _location.Title);
        } else {
            Utils.OpenFile($this.data('filename'), false, false, _location.Title);
        }
    }

    function onAdditionalPropertyGroupHeaderClick(): void {
        const $this = $(this);

        $this.toggleClass('collapsed');
    }

    function onParameterGroupHeaderClick(): void {
        const $this = $(this);

        $this.toggleClass('collapsed');

        if (!$this.hasClass('collapsed') && $this.hasClass('tabular')) {
            $this.next().find('.sticky-enabled').trigger('resize');
        }
    }

    function renderWindow(isOnline: boolean): void {
        const configuration = Information.GetDefaultInformationFilters();

        ParameterList.Create([Enums.ElementType.MasterdataGroup], (renderedMasterdata) => {
            const categorizedFiles = getCategorizedFiles();
            const costCenter = determineCostCenter();
            const measures = (_location.Parametergroups || []).filter((group) => {
                return group.Attribute === 5;
            });

            if ((_location.Forms || []).length) {
                _location.Forms.sort((a, b) => {
                    const formA = DAL.Elements.GetByOID(a);
                    const formB = DAL.Elements.GetByOID(b);

                    return Utils.SortByTitle(formA, formB);
                });
            }

            if ((_location.Teams || []).length) {
                _location.Teams.sort((a, b) => {
                    return Utils.SortByTitle(a, b);
                });
            }

            const additionalPropertyGroups = Information.GetAdditionalPropertyGroups(
                configuration,
                _location
            );

            const hasVisibleInformation = Information.GetHasVisibleInformation(
                configuration,
                _location,
                additionalPropertyGroups,
                measures,
                costCenter,
                renderedMasterdata
            );

            const content = Templates.Information({
                Element: _location,
                MasterData: renderedMasterdata,
                AdditionalPropertyGroups: additionalPropertyGroups,
                Measures: measures,
                Images: categorizedFiles.Images,
                Files: categorizedFiles.Files,
                EmbeddedVideos: categorizedFiles.EmbeddedVideos,
                CostCenter: costCenter,
                UserIsOnline: isOnline,
                Configuration: configuration,
                HasVisibleInformation: hasVisibleInformation
            });

            _$win = $(Templates.LocationInformation({
                WindowTitle: _location.Title,
                WindowContent: content
            }));

            _imagesTotal = _$win.find('img').length;
            _imageCounter = 0;

            if (!_imagesTotal) {
                Utils.RepositionModalWindow(_$win);
            }

            _$overlay = Utils.Overlay.Generate('olElementInformation', 9999, destroy);

            $('body').append(_$win);
            _$win.find('.modal-content').css('max-height', $(window).height() - 60);

            Utils.RepositionModalWindow(_$win);

            _$win.modal({
                show: true,
                keyboard: false,
                backdrop: false
            });

            bindEvents();

            if (_imagesTotal === 0 && _onAfterRendered) {
                _onAfterRendered(_$win);
            }
        }, _location, configuration.MasterDataConfiguration);
    }

    function determineCostCenter(): string {
        let costCenter: string;
        let location = _location;

        while (!costCenter && location) {
            if (!costCenter && !!location.CostCenter) {
                costCenter = location.CostCenter;
            }

            location = location.Parent;
        }
        return costCenter;
    }

    function getCategorizedFiles(): { Images: Model.Files.File[], Files: Model.Files.File[], EmbeddedVideos: Model.Files.File[] } {
        const images = [];
        const files = [];
        const embeddedVideos = [];

        (_location.Files || []).forEach(function (ef) {
            const file = DAL.Files.GetByOID(ef.OID);

            if (!file) {
                return;
            }

            switch (file.Type) {
                case Enums.FileType.File:
                    if (Utils.IsImage(file.MimeType)) {
                        images.push(file);
                    } else {
                        files.push(file);
                    }

                    break;
                case Enums.FileType.Link:
                    files.push(file);
                    break;
                case Enums.FileType.Youtube:
                case Enums.FileType.VimeoPrivate:
                case Enums.FileType.VimeoPublic:
                    embeddedVideos.push(file);
                    break;
            }
        });

        return {
            EmbeddedVideos: embeddedVideos,
            Files: files,
            Images: images
        };
    }

    function bindEvents(): void {
        _$win.on('hidden.bs.modal', destroy);
        _$win.find('div[data-filename]').on('click', onFileClick);
        _$win.find('img').on('load', onImageLoaded).on('error', onImageError);
        _$win.find('iframe.info-text').on('load', Utils.OnIframeLoaded);
        _$win.find('.parameter-list > p').on('click.toggleListView', onParameterGroupHeaderClick);
        _$win.find('.additional-property-group-header').on('click.toggleCollapsedState', onAdditionalPropertyGroupHeaderClick);
    }

    export function Show(elementIdentifier: string, onAfterRendered?: ($win: any) => void): void {
        _location = DAL.Elements.GetByOID(elementIdentifier);

        if (!_location) {
            return;
        }

        _onAfterRendered = onAfterRendered;

        checkIfUserIsOnline()
            .then(renderWindow);
    }

    function checkIfUserIsOnline(): Deferred {
        const deferred = $.Deferred();

        Utils.CheckIfDeviceIsOnline()
            .then(deferred.resolve, deferred.resolve);

        return deferred.promise();
    }

    export function IsVisible(): boolean {
        return _$win && _$win.css('display') !== 'none';
    }
}