//imports-start
/// <reference path="./processing-information.ts"  />
//imports-end

module Model.Issues {
    export class Counter extends ProcessingInformation {
        IssueCount: number;

        constructor(count: number = 0, processingStatus: Enums.IssueProcessingStatus = Enums.IssueProcessingStatus.OK) {
            super(processingStatus);
            this.IssueCount = count;
        }

        public merge(counter: Counter): Counter {
            this.IssueCount += counter.IssueCount;

            const newProcessingStatus = Math.max(this.ProcessingStatus, counter.ProcessingStatus);

            this.SetStatus(newProcessingStatus);
            return this;
        }

        public clone(): Counter {
            return new Counter(this.IssueCount, this.ProcessingStatus);
        }
    }
}