//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../lib/jquery.min.js"  />
//imports-end

module DAL.ScancodeInfos {
    let _scanCodeInfos: Dictionary<Model.Scancodes.ScancodeInfo[]> = {};
    let _propertyCache: Dictionary<Dictionary<string[]>> = {}; // Cached die Codes abhängig von dem jeweiligen Key

    export function Store(existingScancodeInfos: Array<Model.Scancodes.ScancodeInfo>): void {
        if (!_scanCodeInfos) {
            _scanCodeInfos = {};
            _propertyCache = {};
        }

        if (!(existingScancodeInfos || []).length) {
            return;
        }

        for (let scanCodeInfo of existingScancodeInfos) {
            if (!_scanCodeInfos[scanCodeInfo.Code]) {
                _scanCodeInfos[scanCodeInfo.Code] = [];
            }

            const iOf = Utils.Where(_scanCodeInfos[scanCodeInfo.Code], 'ID', '===', scanCodeInfo.ID, true);
            if (iOf === -1) {
                _scanCodeInfos[scanCodeInfo.Code].push(scanCodeInfo);
            } else {
                _scanCodeInfos[scanCodeInfo.Code][iOf] = scanCodeInfo;
            }

            if (scanCodeInfo.IssueID) {
                AddToCacheIfNotExists('IssueID', scanCodeInfo);
            }
            if (scanCodeInfo.IssueOID) {
                AddToCacheIfNotExists('IssueOID', scanCodeInfo);
            }
            if (scanCodeInfo.ResubmissionitemOID) {
                AddToCacheIfNotExists('ResubmissionitemOID', scanCodeInfo);
            }
            if (scanCodeInfo.ElementOID) {
                AddToCacheIfNotExists('ElementOID', scanCodeInfo);
            }
        }
    }

    export function Exists(code: string): boolean {
        return !!code && _scanCodeInfos.hasOwnProperty(code);
    }

    export function GetAll(): Array<Model.Scancodes.ScancodeInfo> {
        return $.map(_scanCodeInfos, (scanCodeInfo: Model.Scancodes.ScancodeInfo[]) => scanCodeInfo);
    }

    export function DeleteFromDatabaseByIssueID(issueIDs: number|number[]): Deferred {
        const identifierArray = issueIDs instanceof Array ? issueIDs : [issueIDs];

        return DeleteFromDatabase(identifierArray, 'IDX_IssueID', 'IssueID');
    }

    export function DeleteFromDatabaseByIssueOID(issueOIDs: string|string[]): Deferred {
        const identifierArray = issueOIDs instanceof Array ? issueOIDs : [issueOIDs];

        return DeleteFromDatabase(identifierArray, 'IDX_IssueOID', 'IssueOID');
    }

    export function DeleteFromDatabaseByResubmissionitemOID(resubmissionitemOIDs: string|string[]): Deferred {
        const identifierArray = resubmissionitemOIDs instanceof Array ? resubmissionitemOIDs : [resubmissionitemOIDs];

        return DeleteFromDatabase(identifierArray, 'IDX_ResubmissionitemOID', 'ResubmissionitemOID');
    }

    export function DeleteFromDatabaseByElementOID(elementOIDs: string|string[]): Deferred {
        const identifierArray = elementOIDs instanceof Array ? elementOIDs : [elementOIDs];

        return DeleteFromDatabase(identifierArray, 'IDX_ElementOID', 'ElementOID');
    }

    export function Clear(): void {
        _scanCodeInfos = null;
        _propertyCache = {};
    }

    function AddToCacheIfNotExists(property: string, scanCodeInfo: Model.Scancodes.ScancodeInfo) {
        if (!_propertyCache[property]) {
            _propertyCache[property] = {};
        }

        const cache = _propertyCache[property];
        const identifier = scanCodeInfo[property];
        const code = scanCodeInfo.Code;

        if (!cache[identifier]) {
            cache[identifier] = [code];
        } else {
            if(cache[identifier].indexOf(code) === -1) {
                cache[identifier].push(code);
            }
        }
    }

    function DeleteFromDatabase(identifiers: number[] | string[], indexName: string, propertyName: string) {
        if (!identifiers.length || !Session.IsSmartDeviceApplication) {
            return $.Deferred().resolve().promise();
        }

        return window.Database.DeleteFromStorageByIndex(Enums.DatabaseStorage.ScanCodes, indexName, identifiers)
                     .then(() => RemoveFromCache(identifiers, propertyName));
    }

    function RemoveFromCache(identifiers: number[] | string[], propertyName: string) {
        const subcache = _propertyCache[propertyName];

        if (!subcache) {
            return;
        }

        for (const identifier of identifiers) {
            const scanCodes = subcache[identifier];

            if (!(scanCodes || []).length) {
                return;
            }

            for (const scanCode of scanCodes) {
                if (!_scanCodeInfos.hasOwnProperty(scanCode)) {
                    continue;
                }

                _scanCodeInfos[scanCode] = _scanCodeInfos[scanCode].filter((item) => {
                    return !Utils.IsSet(item[propertyName]) || item[propertyName] !== identifier;
                });

                if (!_scanCodeInfos[scanCode].length) {
                    delete _scanCodeInfos[scanCode];
                }
            }

            delete subcache[identifier];
        }
    }
}