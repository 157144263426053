//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../model/issues/filter.ts"  />
/// <reference path="../model/issues/counter.ts"  />
/// <reference path="../model/issues/counters.ts"  />
/// <reference path="../model/tree-cache/cache-meta-node.ts"  />
/// <reference path="../model/menu/counters.ts"  />
/// <reference path="../dal/teams.ts"  />
/// <reference path="../utils/utils.user-picker.ts"  />
/// <reference path="../utils/utils.ts"  />
/// <reference path="../utils/filter-window/utils.issue-filter-window.ts"/>
/// <reference path="./app.menu.ts"/>
/// <reference path="./app.form-issues-pool.ts"/>
//imports-end

module IssueReport {

    interface ReportResponse {
        IssueCount: number;
        SelectedLocationProcessingStatus: Dictionary<number>;
        AllLocationsProcessingStatus: Dictionary<number>;
        SelectedLocationProcessingStatusByType: Dictionary<Array<{ IssueType: number, ProcessingStatus: number }>>;
        AllLocationsProcessingStatusByType: Dictionary<Array<{ IssueType: number, ProcessingStatus: number }>>;
        Issues: Array<Model.Issues.Issue>;
    };

    export let CustomMenuID = null;

    let $container, $content, $issueReport, $footerToolbar;
    let allIssuesLoaded = false;
    let isLoading = false;
    let showTypeIcons = true;
    let issues: Dictionary<Model.Issues.Issue>;
    let currentMode: string;
    let activeCacheNode: Model.TreeCache.CacheMetaNode;
    let userFilterChanged: boolean = false;
    let filters = new Model.Issues.Filter({
        WithChildLocations: true,
        WithOpenIssues: true,
        WithArchivedIssues: false,
        WithProcessingStatusInformation: true,
        LoadIssues: true,
        IncludeDerivations: true,
        Skip: 0,
        Take: 30,
        Sorting: 'modificationtimestamp',
        SortingOrder: 'desc'
    });

    function setReportSortingOrder(): void {
        if (View.CurrentView !== Enums.View.Inspection) {
            Session.Settings.SaveReportSorting.Property = filters.Sorting;
            Session.Settings.SaveReportSorting.Order = filters.SortingOrder;

            Settings.Save();
        }
    }

    function onSelectSorting($selectedSorting): void {
        const $this = $(this);
        const value = $selectedSorting.data('propertyvalue');
        const property = value.split('_')[0];
        const order = value.split('_')[1];

        if (!order) {
            $this
                .data('sortingorder', 'asc')
                .attr('data-sortingorder', 'asc')
                .siblings()
                .data('sortingorder', '')
                .removeAttr('data-sortingorder');
        } else if (order === 'asc') {
            $this
                .data('sortingorder', 'desc')
                .attr('data-sortingorder', 'desc');
        } else {
            $this
                .data('sortingorder', 'asc')
                .attr('data-sortingorder', 'asc');
        }

        filters.Sorting = property;
        filters.SortingOrder = order;

        setReportSortingOrder();

        if (View.CurrentView === Enums.View.Inspection) {
            Show(currentMode, GetDescendantsOfIssue());
        } else if (CustomMenuID) {
            ShowByMenu(CustomMenuID);
        } else {
            Show(currentMode);
        }
    }

    function onBtnSelectSortingClick(): void {
        const listItems = [{
            Title: i18next.t('IssueReport.ID'),
            PropertyValue: 'id_asc',
            FontIcon: 'arrow-up'
        }, {
            Title: i18next.t('IssueReport.ID'),
            PropertyValue: 'id_desc',
            FontIcon: 'arrow-down'
        }, {
            Title: i18next.t('IssueReport.LastModification'),
            PropertyValue: 'modificationtimestamp_asc',
            FontIcon: 'arrow-up'
        }, {
            Title: i18next.t('IssueReport.LastModification'),
            PropertyValue: 'modificationtimestamp_desc',
            FontIcon: 'arrow-down'
        }, {
            Title: i18next.t('IssueReport.Deadline'),
            PropertyValue: 'deadlinetimestamp_asc',
            FontIcon: 'arrow-up'
        }, {
            Title: i18next.t('IssueReport.Deadline'),
            PropertyValue: 'deadlinetimestamp_desc',
            FontIcon: 'arrow-down'
        }, {
            Title: i18next.t('IssueReport.State'),
            PropertyValue: 'state_asc',
            FontIcon: 'arrow-up'
        }, {
            Title: i18next.t('IssueReport.State'),
            PropertyValue: 'state_desc',
            FontIcon: 'arrow-down'
        }, {
            Title: i18next.t('IssueReport.Priority'),
            PropertyValue: 'priority_asc',
            FontIcon: 'arrow-up'
        }, {
            Title: i18next.t('IssueReport.Priority'),
            PropertyValue: 'priority_desc',
            FontIcon: 'arrow-down'
        }, {
            Title: i18next.t('IssueReport.EstimatedEffort'),
            PropertyValue: 'estimatedeffort_asc',
            FontIcon: 'arrow-up'
        }, {
            Title: i18next.t('IssueReport.EstimatedEffort'),
            PropertyValue: 'estimatedeffort_desc',
            FontIcon: 'arrow-down'
        }];

        if (View.CurrentView === Enums.View.Inspection) {
            listItems.push({
                Title: i18next.t('IssueReport.CustomIdent'),
                PropertyValue: 'customident_asc',
                FontIcon: 'arrow-up'
            }, {
                    Title: i18next.t('IssueReport.CustomIdent'),
                    PropertyValue: 'customident_desc',
                    FontIcon: 'arrow-down'
                });
        }

        Utils.CustomDataPicker.Show(listItems, {
            Title: i18next.t('IssueReport.Sorting'),
            Width: 300,
            UseSystemProperties: false,
            Callback: onSelectSorting,
            SelectedProperties: [`${filters.Sorting}_${filters.SortingOrder}`],
            IsMultiSelectionAllowed: false,
            HideResetButton: true
        });
    }

    function onIssueCreated(issue: Model.Issues.Issue): void {
        if (Session.IsSmartDeviceApplication) {
            if (View.CurrentView === Enums.View.Inspection && (issue.ParentID || issue.ParentOID)) {
                Utils.IssueViewer.IncrementSubIssueCount(IssueView.GetCurrentIssue())
                    .then(function(subIssueCounter) {
                        IssueView.UpdateSubIssues(subIssueCounter, [issue]);

                        if (Session.Mode === Enums.Mode.IssueReport) {
                            Show(currentMode, GetDescendantsOfIssue());
                        }
                    });
            } else if (CustomMenuID) {
                ShowByMenu(CustomMenuID);
            } else {
                Show(currentMode);
            }

            App.UpdateContentHeaderIssueProcessingIndicator();
        } else {
            if (View.CurrentView === Enums.View.Inspection && issue.ParentID) {
                Utils.IssueViewer.IncrementSubIssueCount(IssueView.GetCurrentIssue())
                    .then(function(subIssueCounter) {
                        const subIssues = IssueView.UpdateSubIssues(subIssueCounter, [issue]);

                        if (Session.Mode === Enums.Mode.IssueReport) {
                            IssueReport.Show('issues', subIssues || []);
                        }

                        App.UpdateContentHeaderIssueProcessingIndicator();
                        App.UpdateTreeCounters();
                    });
            } else if (CustomMenuID) {
                ShowByMenu(CustomMenuID);
            } else {
                Show(currentMode);
            }
        }
    }

    function onCounterCallback(results: any | Array<any>): Model.Menu.Counters {
        const returnResult = new Model.Menu.Counters();

        if (typeof results[0] === 'undefined') {
            return returnResult;
        }

        if ($.isPlainObject(results[0])) {
            results = [results];
        }

        if (!results.length) {
            return returnResult;
        }

        for (let rCnt = 0, rLen = results.length; rCnt < rLen; rCnt++) {
            const result = results[rCnt];
            const resultBody: ReportResponse = result[0];
            const xhrInformation = result[2];
            const menuID: string = xhrInformation.RequestInformation.MenuItemID || null;
            const processingStatusInformation = getProcessingStatus(resultBody.SelectedLocationProcessingStatusByType);
            const issueTypeInformation = new Model.Issues.Counter(resultBody.IssueCount, processingStatusInformation.Raw);

            if (menuID == 'Issues') {
                returnResult.IssueCounters.Issues = issueTypeInformation;
            } else if (menuID == 'Tasks') {
                returnResult.IssueCounters.Tasks = issueTypeInformation;
            } else if (menuID == 'Notes') {
                returnResult.IssueCounters.Notes = issueTypeInformation;
            } else if (menuID == 'Disturbances') {
                returnResult.IssueCounters.Disturbances = issueTypeInformation;
            } else if (menuID) {
                returnResult.IssueCounters.Custom[menuID] = issueTypeInformation;
            }
        }

        return returnResult;
    }

    function onScrollPage(): void {
        const $lastChild = $content.find('.issue-report .issue-cell:last-child');

        if (!allIssuesLoaded && !isLoading && $lastChild.length && $lastChild.isElementInViewport(200)) {
            Utils.Spinner.Show(i18next.t('IssueReport.LoadingIssues'));
            isLoading = true;

            setTimeout(function() {
                if (activeCacheNode) {
                    const allIssues = activeCacheNode.getTotalIssues();
                    // sort by filters
                    DAL.Issues.SortIssuesList(<any>allIssues, filters);
                    appendIssues(<any>allIssues.slice(filters.Skip, filters.Skip + filters.Take));
                } else {
                    loadIssues(filters)
                        .then(appendIssues);
                }
            }, 100);
        }
    }

    function onAfterIssueImageLoaded(): void {
        setTimeout(() => {
            const $this = $(this);
            const $marks = $this.siblings('div');

            if ($marks.length) {
                $marks.css({
                    top: $this.position().top,
                    left: $this.position().left,
                    width: $this.width(),
                    height: $this.height()
                });

                $marks.find('> svg')
                    .attr({
                        width: '100%',
                        height: '100%'
                    });
            }
        }, Math.random() * 45 + 20);

    }

    function onIssueImageLoadingError(): void {
        const $this = $(this);
        const $marks = $this.siblings('div');

        if ($marks.length) {
            $marks.remove();
        }

        Utils.OnImageNotFound.call($this);
    }

    function onIssueImageClick(evt: Event): void {
        const $this = $(this);
        const issueOID = $this.parents('li').data('oid');
        const issue = issues[issueOID];
        const filename = $this.data('filename');

        if (!issue) {
            return;
        }

        evt.preventDefault();

        let images = [];

        // Sonder-Handling um Bilder aus Erfassung in Vorschau anzuzeigen
        const checkpointPreviewImages = (issue.AdditionalData || {}).CheckpointPreviewImages;
        if (checkpointPreviewImages) {
            for (const key in checkpointPreviewImages) {
                if (!checkpointPreviewImages.hasOwnProperty(key)) {
                    continue;
                }

                const previewImage = checkpointPreviewImages[key];

                if (previewImage && previewImage.Filename === filename) {
                    images.push(previewImage);
                    break;
                }
            }
        }

        if (issue.Files && issue.Files.length) {
            images = issue.Files.filter(issueFile => Utils.IsImage(issueFile.MimeType));
        }

        Utils.OpenImages(images
            , filename
            , false
            , null
            , null
            , getImageViewerTitle(issue)
        );
    }

    function onIssueImageMarksClick(evt: Event): void {
        onIssueImageClick.call($(this).parents('.image-container').find('img'), evt);
    }

    function onBtnIssueChatHistoryClick(evt: Event): void {
        const $this = $(this);
        const issueIdentifier = $this.parents('li').data('oid');
        const issue = issues[issueIdentifier];

        if (!issue) {
            return;
        }

        (issue.Comments || []).forEach(comment => comment.AssignmentID = issue.ID);

        evt.preventDefault();

        const windowOptions = {
            Type: Enums.CommentEntity.Issue,
            Issue: new Model.Issues.Issue(issue)
        };

        const canWriteComments = Utils.UserHasRight(
            Session.User.OID,
            Enums.Rights.Comments_CreateAndModifyOnCheckpoints,
            true,
            issue.AssignedElementOID
        );

        Utils.ChatWindow.Show(
            windowOptions,
            issue.Comments,
            !canWriteComments,
            false,
            (comment: Model.Comment) => OnCommentSaved(comment),
            (comment: Model.Comment) => OnCommentDeleted(comment));
    }

    export function OnCommentSaved(comment: Model.Comment): void {
        if (comment == null) {
            return;
        }

        let issue = null;
        if (issues.hasOwnProperty(comment.AssignmentOID)) {
            issue = issues[comment.AssignmentOID];

            if (issue) {
                issue.Comments = issue.Comments || [];
                issue = new Model.Issues.Issue(issue);
            }
        }

        if (!issue) {
            if (comment.AssignmentID != null) {
                for (let identifier in issues) {
                    if (!issues.hasOwnProperty(identifier)) {
                        continue;
                    }

                    issue = issues[identifier];

                    if (issue.ID === comment.AssignmentID) {
                        break;
                    }

                    issue = null;
                }
            }

            if (!issue) {
                return;
            }
        }

        const commentIndex = Utils.Where(issue.Comments, 'OID', '===', comment.OID, true);

        if (commentIndex === -1) {
            issue.Comments.push(comment);
        } else {
            issue.Comments[commentIndex] = comment;
        }

        if (Session.IsSmartDeviceApplication) {
            window.Database.GetSingleByKey(Enums.DatabaseStorage.Issues, issue.OID)
                .then(function(dbEntity: Model.Issues.RawIssue) {
                    if (!dbEntity) {
                        return;
                    }

                    dbEntity.Comments = issue.Comments;

                    DAL.Issues.SaveToDatabase(dbEntity, true);
                });
        }

        updateIssueCommentCounter(issue.OID);
    }

    export function OnCommentDeleted(comment: Model.Comment): void {
        if (comment == null) {
            return;
        }

        let issue = issues.hasOwnProperty(comment.AssignmentOID) ?
            new Model.Issues.Issue(issues[comment.AssignmentOID]) :
            null;

        if (!issue) {
            if (comment.AssignmentID != null) {
                for (let identifier in issues) {
                    if (!issues.hasOwnProperty(identifier)) {
                        continue;
                    }

                    issue = issues[identifier];

                    if (issue.ID === comment.AssignmentID) {
                        break;
                    }

                    issue = null;
                }
            }

            if (!issue) {
                return;
            }
        }

        issue.Comments = issue.Comments || [];

        const commentIndex = Utils.Where(issue.Comments, 'OID', '===', comment.OID, true);

        if (commentIndex > -1) {
            issue.Comments.splice(commentIndex, 1);
        }

        if (Session.IsSmartDeviceApplication) {
            window.Database.GetSingleByKey(Enums.DatabaseStorage.Issues, issue.OID)
                .then(function(dbEntity: Model.Issues.RawIssue) {
                    if (!dbEntity) {
                        return;
                    }

                    dbEntity.Comments = issue.Comments;

                    DAL.Issues.SaveToDatabase(dbEntity, true);
                });
        }

        updateIssueCommentCounter(issue.OID);
    }

    function updateIssueCommentCounter(issueIdentifier: string): void {
        if (!issueIdentifier) {
            return;
        }

        const issue = issues.hasOwnProperty(issueIdentifier) ?
            new Model.Issues.Issue(issues[issueIdentifier]) :
            null;

        if (!issue) {
            return;
        }

        const $issueCell = $content.find(`li[data-oid="${issueIdentifier}"]`);

        if (!$issueCell.length) {
            return;
        }

        const commentCount = (issue.Comments || []).length;
        const $badge = $issueCell.find('.chat-history .badge');

        $badge
            .toggleClass('hidden', commentCount === 0)
            .text(commentCount);
    }

    function onBtnCreateIssueClick(evt: Event): void {
        const $this = $(this);
        let defaultStateOID = Session.Client.Settings.TicketOpened;
        let issueType = Enums.IssueType.Task;
        let params;

        evt.preventDefault();

        if (!Utils.IsUserAbleToSetInitialState(Session.CurrentLocation.OID, defaultStateOID)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.DefaultState.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        if ($this.data('issuetype')) {
            issueType = $this.data('issuetype');
        }

        if (currentMode === 'notes') {
            issueType = Enums.IssueType.Note;
        } else if (currentMode === 'disturbances') {
            issueType = Enums.IssueType.Disturbance;
            defaultStateOID = Session.Client.Settings.DisturbanceOpened;
        }

        if (View.CurrentView === Enums.View.Inspection ||
            Session.Mode === Enums.Mode.IssueReport) {
            if (!Utils.CanUserCreateIssueType(issueType, Session.CurrentLocation)) {
                issueType = Enums.IssueType.Note;

                if (!Utils.CanUserCreateIssueType(issueType, Session.CurrentLocation)) {
                    issueType = Enums.IssueType.Disturbance;

                    if (!Utils.CanUserCreateIssueType(issueType, Session.CurrentLocation)) {
                        return;
                    }
                }
            }

            const parentIssue = IssueView.GetCurrentIssue();

            params = {
                ParentIssue: parentIssue
            };

            if (parentIssue && parentIssue.AssignedFormRevisionOID) {
                const form = ParameterList.GetElementRevisionByRevisionOID(parentIssue.AssignedFormRevisionOID);
                params.InheritResponsibilities = form && form.AdditionalSettings && form.AdditionalSettings.BequeathResponsibilities;
            }
        }

        Utils.IssueViewer.CreateIssue(issueType, onIssueCreated, params);
    }

    function getImageViewerTitle(issue: Model.Issues.Issue): string {
        const revision = issue.Revision || 1;
        const title = issue.Title || i18next.t('Misc.Untitled');

        return `${issue.ID || 0}.${revision} - ${title}`;
    }

    function filterLocationIssueProcessingInformationByCurrentLocation(locationIssueProcessingInformation): Array<any> {
        if (!Utils.HasProperties(locationIssueProcessingInformation)) {
            return;
        }

        let currentLocation: Model.Elements.Element;
        if (Utils.InArray([Enums.View.Form, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            let issue = IssueView.GetCurrentIssue();

            if (issue) {
                currentLocation = DAL.Elements.GetByOID(issue.AssignedElementOID);
            }

            if (!currentLocation) {
                currentLocation = DAL.Elements.Root;
            }
        } else {
            currentLocation = Session.CurrentLocation;
        }

        const filteredResults = [];

        (function traverse(location) {
            let processingInformation = locationIssueProcessingInformation[location.OID];

            if (processingInformation) {
                filteredResults.push(processingInformation);
            }

            if ((location.Children || []).length) {
                if (Utils.InArray([Enums.ElementType.Root, Enums.ElementType.Location], location.Type)) {
                    for (let cCnt = 0, cLen = location.Children.length; cCnt < cLen; cCnt++) {
                        traverse(location.Children[cCnt]);
                    }
                }
            }
        })(currentLocation);

        return filteredResults;
    }

    function getProcessingStatus(locationIssueProcessingInformation): {
        Raw: number,
        Class: string
    } {
        const status = {
            Raw: 0,
            Class: null
        };

        locationIssueProcessingInformation = filterLocationIssueProcessingInformationByCurrentLocation(locationIssueProcessingInformation);

        if (locationIssueProcessingInformation) {
            for (let locationOID in locationIssueProcessingInformation) {
                const location = locationIssueProcessingInformation[locationOID];

                if ((location || []).length) {
                    for (let sCnt = 0, sLen = location.length; sCnt < sLen; sCnt++) {
                        let issueTypeStatus = location[sCnt];

                        if (issueTypeStatus.ProcessingStatus > status.Raw) {
                            status.Raw = issueTypeStatus.ProcessingStatus;

                            if (status.Raw === Enums.IssueProcessingStatus.Overdue) {
                                break;
                            }
                        }
                    }
                }

                if (status.Raw === Enums.IssueProcessingStatus.Overdue) {
                    break;
                }
            }
        }

        status.Class = Model.Issues.ProcessingInformation.GetStatusCssClass(status.Raw);

        return status;
    }

    function createFilterObject(issueFilters: Model.Issues.IFilter): Model.Issues.IFilter {
        const filterObj = new Model.Issues.Filter();

        if (issueFilters.LocationOID) {
            filterObj.SetLocationOID(issueFilters.LocationOID);
        } else {
            if (currentMode !== 'issue-progress' ||
                !Utils.Router.GetCurrentFragment().contains('issue-report/progress/') ||
                (currentMode == 'issue-progress' &&
                    Utils.Router.GetCurrentFragment().contains('from=calendar'))) {
                filterObj.SetLocationOID(Session.CurrentLocation.OID);
            } else {
                filterObj.SetLocationOID(DAL.Elements.Root.OID);
            }
        }

        if ((issueFilters.UserSelectedTypes || []).length) {
            filterObj.SetTypes(issueFilters.UserSelectedTypes);
            filterObj.UserSelectedTypes = issueFilters.UserSelectedTypes;
        }
        else if ((issueFilters.Types || []).length) {
            filterObj.SetTypes(issueFilters.Types);
        }

        if (issueFilters.WithChildLocations != null) {
            filterObj.SetWithChildLocations(issueFilters.WithChildLocations);
        }

        if (issueFilters.IsArchived != null) {
            filterObj.IsArchived = issueFilters.IsArchived;
        }

        if (issueFilters.Forms) {
            filterObj.Forms = issueFilters.Forms;
        }

        if ((issueFilters.Teams || []).length) {
            filterObj.SetTeams(issueFilters.Teams);
        }

        if ((issueFilters.Users || []).length) {
            filterObj.SetUsers(issueFilters.Users);
        }

        if ((issueFilters.Contacts || []).length) {
            filterObj.SetContacts(issueFilters.Contacts);
        }

        if ((issueFilters.ContactGroups || []).length) {
            filterObj.SetContactGroups(issueFilters.ContactGroups);
        }

        if ((issueFilters.States || []).length) {
            filterObj.SetStates(issueFilters.States);
        }

        if ((issueFilters.Classifications || []).length) {
            filterObj.SetClassifications(issueFilters.Classifications);
        }

        if ((issueFilters.Priorities || []).length) {
            filterObj.SetPriorities(issueFilters.Priorities);
        }

        if ((issueFilters.Keywords || []).length) {
            filterObj.SetKeywords(issueFilters.Keywords);
        }

        if (issueFilters.ModificationPeriod instanceof Model.DateTime.Period) {
            filterObj.SetModificationPeriod(issueFilters.ModificationPeriod.PeriodStart, issueFilters.ModificationPeriod.PeriodEnd);
        }

        if (issueFilters.DeadlinePeriod instanceof Model.DateTime.Period) {
            filterObj.SetDeadlinePeriod(issueFilters.DeadlinePeriod.PeriodStart, issueFilters.DeadlinePeriod.PeriodEnd);
        }

        if (!!issueFilters.SearchText) {
            filterObj.SetSearchText(issueFilters.SearchText);
        }

        if (issueFilters.WithOpenIssues != null) {
            filterObj.SetWithOpenIssues(issueFilters.WithOpenIssues);
        }

        if (issueFilters.WithArchivedIssues != null) {
            filterObj.SetWithArchivedIssues(issueFilters.WithArchivedIssues);
        }

        if (issueFilters.LoadIssues != null) {
            filterObj.SetLoadIssues(issueFilters.LoadIssues);

            if (issueFilters.Skip > 0) {
                filterObj.SetSkip(issueFilters.Skip);
            }
        }

        if (issueFilters.Take > 0) {
            filterObj.SetTake(issueFilters.Take);
        }

        if (!!issueFilters.Sorting) {
            filterObj.SetSorting(issueFilters.Sorting);
        }

        if (!!issueFilters.SortingOrder) {
            filterObj.SetSortingOrder(issueFilters.SortingOrder);
        }

        if (typeof issueFilters.ProcessingStatus === 'number' ||
            issueFilters.ProcessingStatus instanceof Array) {
            filterObj.SetProcessingStatus(issueFilters.ProcessingStatus);
        }

        if (!!issueFilters.OnlyCurrentLocationAndNextChildren) {
            filterObj.SetOnlyCurrentLocationAndNextChildren(issueFilters.OnlyCurrentLocationAndNextChildren);
        }

        if ((issueFilters.Forms || []).length) {
            filterObj.SetForms(issueFilters.Forms);
        }

        if ((issueFilters.Schedulings || []).length) {
            filterObj.SetSchedulings(issueFilters.Schedulings);
        }

        if (issueFilters.IncludeDerivations) {
            filterObj.SetIncludeDerivations(issueFilters.IncludeDerivations);
        }

        filterObj.SetHideNonResponsibleIssues(issueFilters.HideNonResponsibleIssues);

        return filterObj;
    }

    function getDefaultDeadlineFilterByPreset(): Model.DateTime.Period {
        if (!Session.Settings.DeadlineFilterPreset) {
            return;
        }

        const lowerDeadlinePeriodBoundary = new Date();
        lowerDeadlinePeriodBoundary.setHours(0, 0, 0, 0);

        const upperDeadlinePeriodBoundary = new Date();
        upperDeadlinePeriodBoundary.setDate(upperDeadlinePeriodBoundary.getDate() + Session.Settings.DeadlineFilterPreset);
        upperDeadlinePeriodBoundary.setHours(23, 59, 59, 999);

        return new Model.DateTime.Period(lowerDeadlinePeriodBoundary, upperDeadlinePeriodBoundary);
    }

    function createCounterDeferred(mainFilter: Model.Issues.IFilter, optionalFilter: Model.Issues.IFilter, headerID?: string): Deferred {
        const firstFilter = $.extend(true, {}, mainFilter);
        const secondFilter = $.extend(true, {}, optionalFilter);

        if (!Session.Client.Licenses.Inspections && Utils.InArray(secondFilter.Types, Enums.IssueType.Inspection)) {
            secondFilter.Types.splice(Utils.GetIndex(secondFilter.Types, Enums.IssueType[Enums.IssueType.Inspection]), 1);

            if (!secondFilter.Types.length) {
                return;
            }
        }

        const filterArray = [
            createFilterObject(firstFilter),
            createFilterObject(secondFilter)
        ];

        return Utils.Http.Post('reports/issues', filterArray, { MenuItemID: headerID })
            .fail(function(_response, _state, _error) {
                throw new Model.Errors.HttpError(_error, _response);
            });
    }

    function getAllAllowedIssueTypes(locationOID: string): Array<Enums.IssueType> {
        const types: Array<Enums.IssueType> = [];

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Task, locationOID)) {
            types.push(Enums.IssueType.Task);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Scheduling, locationOID)) {
            types.push(Enums.IssueType.Scheduling);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Form, locationOID)) {
            types.push(Enums.IssueType.Form);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Inspection, locationOID)
            && Session.Client.Licenses.Inspections) {
            types.push(Enums.IssueType.Inspection);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Survey, locationOID)
            && Session.Client.Settings.SyncOpenSurveys) {
            types.push(Enums.IssueType.Survey);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Investigation, locationOID)
            && Session.Client.Settings.SyncOpenInvestigations) {
            types.push(Enums.IssueType.Investigation);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.NoteReport, Enums.IssueType.Note, locationOID)) {
            types.push(Enums.IssueType.Note);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.DisturbanceReport, Enums.IssueType.Disturbance, locationOID)) {
            types.push(Enums.IssueType.Disturbance);
        }

        return types;
    }

    function getDeferredsForCounters(issueFilters: Model.Issues.IFilter): Array<Deferred> {
        const locationOID = issueFilters.LocationOID || Session.CurrentLocation.OID;
        const enabledMenuItems = Utils.GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Content, locationOID);
        const deferreds: Array<Deferred> = [];
        let issueReportTypes: Array<Enums.IssueType> = [];

        const tmpTypes = issueFilters.Types;
        issueFilters.Types = issueFilters.UserSelectedTypes;

        if (Session.Settings.ShowAllIssuesInOneReport) {
            deferreds.push(createCounterDeferred(issueFilters, { Types: getAllAllowedIssueTypes(locationOID) }, "Issues"));
        } else {
            issueReportTypes = getAllowedTaskTypes(locationOID);

            if (issueReportTypes.length) {
                deferreds.push(createCounterDeferred(issueFilters, { Types: issueReportTypes }, "Tasks"));
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.NoteReport, Enums.IssueType.Note, locationOID)) {
                deferreds.push(createCounterDeferred(issueFilters, { Types: [Enums.IssueType.Note] }, "Notes"));
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.DisturbanceReport, Enums.IssueType.Disturbance, locationOID)) {
                deferreds.push(createCounterDeferred(issueFilters, { Types: [Enums.IssueType.Disturbance] }, "Disturbances"));
            }
        }

        // create custom menu deferreds
        const cmConfig = Menu.GetCustomMenuItemsConfig();
        for (let i = 0; i < cmConfig.length; i++) {
            const mItem = cmConfig[i];
            if (!mItem || mItem.ActionType != Enums.Menu.Action.Issues) {
                continue;
            }

            // available since API 17
            if (typeof mItem.IsAvailableInRecordingApp === 'boolean' ? !mItem.IsAvailableInRecordingApp : false) {
                continue;
            }

            // check if menu item is visible
            if (Utils.InArray(enabledMenuItems, `${Enums.MenuSection.Content}_${mItem.ID}`, )) {
                deferreds.push(createCounterDeferred(issueFilters, Menu.GetFilter(mItem), mItem.ID));
            }
        }

        issueFilters.Types = tmpTypes;

        return deferreds;
    }

    function getCountersFromDAL(issueFilters: Model.Issues.IFilter): Deferred {
        // load from (filtered) Tree cache
        const locationOID = issueFilters.LocationOID || Session.CurrentLocation.OID;
        const counters: Model.Issues.Counters = new Model.Issues.Counters();

        const issueReportTypes = getAllowedTaskTypes(locationOID);

        // Tasks
        if (issueReportTypes && issueReportTypes.length) {
            // recreate TreeCache if allowed types change
            if (DAL.TreeCache.SubTaskFilter.Types.length != issueReportTypes.length || !DAL.TreeCache.SubTasks) {
                if (DAL.TreeCache.SubTasks) {
                    DAL.TreeCache.SubTasks.destroy();
                    DAL.TreeCache.SubTasks = null;
                }
                DAL.TreeCache.SubTaskFilter.Types = issueReportTypes;
                DAL.TreeCache.SubTasks = DAL.TreeCache.Tasks.createSubInstance(DAL.TreeCache.SubTaskFilter, "SubTaskReport");
            }

            const tasksCounterNode = DAL.TreeCache.SubTasks.getNode(locationOID);
            counters.Tasks = tasksCounterNode.getTotalStateCounter();
        }

        // Notes
        if (IsTypeFilterAllowed(Enums.MenuItemID.NoteReport, Enums.IssueType.Note, locationOID)) {
            const notesCounterNode = DAL.TreeCache.Notes.getNode(locationOID);
            counters.Notes = notesCounterNode.getTotalStateCounter();
        }

        // Disturbances
        if (IsTypeFilterAllowed(Enums.MenuItemID.DisturbanceReport, Enums.IssueType.Disturbance, locationOID)) {
            const disturbanceCounterNode = DAL.TreeCache.Disturbances.getNode(locationOID);
            counters.Disturbances = disturbanceCounterNode.getTotalStateCounter();
        }

        // Schedulings
        const schedulingsCounterNode = DAL.TreeCache.Global.getNode(locationOID, issueFilters.Types);
        counters.Schedulings = schedulingsCounterNode.getTotalSchedulings().size();

        // All Issues
        counters.Issues = counters.Tasks.clone().merge(counters.Notes).merge(counters.Disturbances);

        // TODO custom menu items

        return $.Deferred().resolve({
            IssueCounters: counters,
            SchedulingsCount: counters.Schedulings
        });
    }

    function loadIssues(issueFilter: Model.Issues.IFilter, forceOnline?: boolean): Deferred {
        if (currentMode === 'issue-progress' &&
            !Utils.Router.GetCurrentFragment().contains('from=calendar')) {
            issueFilter.LocationOID = DAL.Elements.Root.OID;
        }

        if (!Session.Client.Licenses.EnableInfoBoard) {
            filters.IncludeDerivations = false;
        }

        if (!Session.IsSmartDeviceApplication || forceOnline) {
            if (!issueFilter.LoadIssues) {
                return $.when.apply($, getDeferredsForCounters(issueFilter)).then(function() {
                    const counterResults = onCounterCallback(arguments);
                    return counterResults;
                });
            } else {
                // add menu item filter
                const filterArray = [createFilterObject(issueFilter)];

                if (filterArray[0] != null) {
                    filterArray[0].Types = [];
                }

                if (CustomMenuID) {
                    const menuItemDefinition = Menu.GetMenuItemConfig(CustomMenuID);
                    const optionalFilter = Menu.GetFilter(menuItemDefinition);
                    if (optionalFilter) {
                        filterArray.push(optionalFilter);
                    }
                } else {
                    const locationOID = issueFilter.LocationOID || Session.CurrentLocation.OID;

                    switch (currentMode) {
                        case 'tasks':
                            filterArray.push({ Types: getAllowedTaskTypes(locationOID) });
                            break;

                        case 'notes':
                            filterArray.push({ Types: [Enums.IssueType.Note] });
                            break;

                        case 'disturbances':
                            filterArray.push({ Types: [Enums.IssueType.Disturbance] });
                            break;

                        case 'issues':
                        default:
                            filterArray.push({ Types: getAllAllowedIssueTypes(locationOID) });
                            break;
                    }
                }

                return getSortedIssuesFromService(filterArray, issueFilter);
            }
        } else {
            const filterObject = createFilterObject(issueFilter);

            if (Session.Mode === Enums.Mode.Menu && filterObject != null) {
                filterObject.Types = [];
            }

            if (!issueFilter.LoadIssues) {
                return getCountersFromDAL(filterObject);
            } else {
                let tCache: Model.TreeCache.ITreeCache;
                switch (currentMode) {
                    case 'tasks':
                        tCache = DAL.TreeCache.Tasks;
                        break;

                    case 'notes':
                        tCache = DAL.TreeCache.Notes;
                        break;

                    case 'disturbances':
                        tCache = DAL.TreeCache.Disturbances;
                        break;

                    case 'issues':
                    default:
                        tCache = DAL.TreeCache.Global;
                        break;
                }
                activeCacheNode = tCache.getNode(Session.CurrentLocation.OID);
                const allIssues = activeCacheNode.getTotalIssues();
                // Vorgänge dem Filter nach sortieren
                DAL.Issues.SortIssuesList(<any>allIssues, filters);
                return $.Deferred().resolve(allIssues.slice(filters.Skip, filters.Skip + filters.Take));
            }
        }
    }

    function getSortedIssuesFromService(filterArray, issueFilter): Deferred {
        return Utils.Http.Post('reports/issues', filterArray)
            .then(function(response: ReportResponse) {
                const issues: Array<Model.Issues.RawIssue> = [];

                (response.Issues).forEach(issue => {
                    issues.push(new Model.Issues.RawIssue(issue));
                });

                DAL.Issues.SortIssuesList(issues, issueFilter);

                return Utils.PrepareIssues(issues);
            }, function(_response, _state, _error) {
                throw new Model.Errors.HttpError(_error, _response);
            });
    }

    function appendIssues(issuesToAppend: Array<Model.Issues.RawIssue>): void {
        const $tempContent = $('<ul></ul>');

        if ((issuesToAppend || []).length) {
            filters.Skip += issuesToAppend.length;

            if ((filters.Skip % filters.Take)) {
                allIssuesLoaded = true;
            }

            issuesToAppend.forEach(function(issue: any) {
                issue = prepareAndCacheIssue(issue);

                $tempContent.append(Templates.Reports.IssueCell({
                    Issue: issue,
                    Settings: Session.Settings
                }));
            });

            $content.find('.issue-report .issues').append($tempContent.html());
        } else {
            allIssuesLoaded = true;
        }

        unbindEvents();
        bindEvents();

        isLoading = false;
        Utils.Spinner.Hide();
    }

    function prepareAndCacheIssue(newIssue: Model.Issues.RawIssue): Model.Issues.Issue {
        if (!newIssue) {
            return null;
        }

        const issue = new Model.Issues.Issue(newIssue);

        if (!issue.ID) {
            issue.ID = 0;
        }

        issue.ShowFiles = Session.Settings.ShowPicturesInIssueReports;

        if ((issue.Files || []).length) {
            issue.HasAudioFiles = issue.Files.some(function(file) {
                return file.IsAudio;
            });
        }

        issues[issue.OID] = issue;

        return issue;
    }

    function getTitleOfSelectedSorting(): string {
        let html = '';

        switch (filters.Sorting) {
            case Enums.SortingBy.ID:
                html = i18next.t('IssueReport.ID');
                break;
            case Enums.SortingBy.MODIFICATIONTIMESTAMP:
                html = i18next.t('IssueReport.LastModification');
                break;
            case Enums.SortingBy.DEADLINETIMESTAMP:
                html = i18next.t('IssueReport.Deadline');
                break;
            case Enums.SortingBy.STATE:
                html = i18next.t('IssueReport.State');
                break;
            case Enums.SortingBy.PRIORITY:
                html = i18next.t('IssueReport.Priority');
                break;
            case Enums.SortingBy.CUSTOMIDENT:
                html = i18next.t('IssueReport.CustomIdent');
                break;
            case Enums.SortingBy.ESTIMATEDEFFORT:
                html = i18next.t('IssueReport.EstimatedEffort');
                break;
        }

        html += `&nbsp;<span class="${getSortingOrderClass()}"></span>`;

        return html;
    }

    function getSortingOrderClass(): string {
        return 'icon-arrow-' + (filters.SortingOrder === 'desc' ? 'down' : 'up');
    }

    function bindEvents(): void {
        unbindEvents();

        const $issueFiles = $content.find('.image img');

        $container = $container || $('#content');
        $container.on('scroll', onScrollPage);

        $issueFiles
            .on('load', onAfterIssueImageLoaded)
            .on('error', onIssueImageLoadingError)
            .on('click', onIssueImageClick);

        $issueFiles.siblings('div').find('> svg')
            .on('click', onIssueImageMarksClick);

        $content
            .on('click.openChatHistory', '.chat-history', onBtnIssueChatHistoryClick)
    }

    function unbindEvents(): void {
        const $issueFiles = $content.find('.image img');

        $container = $container || $('#content');
        $container.off('scroll');

        $issueFiles
            .off('load')
            .off('error')
            .off('click');

        $issueFiles.siblings('div').find('> svg')
            .off('click');

        $content.off('click.openChatHistory')
    }

    function getIssuePlural(): string {
        if (currentMode === 'notes') {
            return i18next.t('Misc.IssueType.Note_plural');
        } else if (currentMode === 'disturbances') {
            return i18next.t('Misc.IssueType.Disturbance_plural');
        } else if (currentMode === 'issues') {
            return i18next.t('Misc.Issue_plural');
        } else {
            return i18next.t('Misc.IssueType.Task_plural');
        }
    }

    function renderIssueReport(loadedIssues: Array<Model.Issues.Issue>): void {
        let btnCreateIssueTitle = '+ ' + i18next.t('Misc.IssueType.Task');
        let issuePlural = getIssuePlural();

        if (currentMode === 'notes') {
            btnCreateIssueTitle = '+ ' + i18next.t('Misc.IssueType.Note');
        } else if (currentMode === 'disturbances') {
            btnCreateIssueTitle = '+ ' + i18next.t('Misc.IssueType.Disturbance');
        } else if (currentMode === 'issues') {
            btnCreateIssueTitle = '+ ' + i18next.t('Misc.Issue');
        }

        filters.Skip += (loadedIssues || []).length;

        if ((filters.Skip % filters.Take)) {
            allIssuesLoaded = true;
        }

        loadedIssues = (loadedIssues || []).map(<any>prepareAndCacheIssue);

        if (currentMode !== 'issue-progress') {
            const location = Session.CurrentLocation;
            const canEditOrCreateTasks = Utils.CanUserCreateIssueType(Enums.IssueType.Task, location);
            const canEditOrCreateNotes = Utils.CanUserCreateIssueType(Enums.IssueType.Note, location);
            const canEditOrCreateDisturbances = Utils.CanUserCreateIssueType(Enums.IssueType.Disturbance, location);
            const menuItem = Menu.GetMenuItemConfig(CustomMenuID);

            $content.html(Templates.Reports.Issues({
                Issues: loadedIssues,
                SortingOrder: filters.SortingOrder,
                BtnCreateIssueTitle: btnCreateIssueTitle,
                IssuePlural: issuePlural,
                Sorting: filters.Sorting,
                ShowCustomIdent: View.CurrentView === Enums.View.Inspection,
                ShowIssueCreationButton: canEditOrCreateTasks || canEditOrCreateNotes || canEditOrCreateDisturbances,
                TitleOfSelectedSorting: getTitleOfSelectedSorting(),
                SortingOrderClass: getSortingOrderClass(),
                Settings: Session.Settings,
                WithTypeIcons: showTypeIcons,
                IsInfoBoard: menuItem && menuItem.Configuration && menuItem.Configuration.DisplayAsInfoBoard
            }));
        } else {
            App.UpdateTreeCounters();

            $content.html(Templates.Reports.IssueProgress({
                Issues: loadedIssues,
                IssuePlural: i18next.t('Misc.Issue_plural'),
                Settings: Session.Settings
            }));
        }

        $issueReport = $content.find('.issue-report');

        bindEvents();

        Utils.Spinner.HideWithTimeout();
    }

    export function IsTypeFilterAllowed(menuItem, issueType: Enums.IssueType, locationOID?: string): boolean {
        if (!Utils.CanUserSeeLocation(Session.User.OID, Session.CurrentLocation)) {
            return true;
        }

        if (!Utils.IsMenuItemEnabled(menuItem, locationOID)) {
            return false;
        }

        if (!(filters.UserSelectedTypes || []).length) {
            return true;
        }

        return Utils.InArray(filters.UserSelectedTypes, issueType);
    }

    function getAllowedTaskTypes(locationOID: string): Array<Enums.IssueType> {
        const types = [];

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Task, locationOID)) {
            types.push(Enums.IssueType.Task);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Scheduling, locationOID)) {
            types.push(Enums.IssueType.Scheduling);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Form, locationOID)) {
            types.push(Enums.IssueType.Form);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Inspection, locationOID) &&
            Session.Client.Licenses.Inspections) {
            types.push(Enums.IssueType.Inspection);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Survey, locationOID) &&
            Session.Client.Settings.SyncOpenSurveys) {
            types.push(Enums.IssueType.Survey);
        }

        if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Investigation, locationOID) &&
            Session.Client.Settings.SyncOpenInvestigations) {
            types.push(Enums.IssueType.Investigation);
        }

        return types;
    }

    function setFiltersForReport(processingStatus: Enums.IssueProcessingStatus): void {
        filters.Types = [];

        if (currentMode === 'notes') {
            filters.WithArchivedIssues = false;

            if (IsTypeFilterAllowed(Enums.MenuItemID.NoteReport, Enums.IssueType.Note)) {
                filters.Types = [Enums.IssueType.Note];
            }
        } else if (currentMode === 'disturbances') {
            filters.WithArchivedIssues = false;

            if (IsTypeFilterAllowed(Enums.MenuItemID.DisturbanceReport, Enums.IssueType.Disturbance)) {
                filters.Types = [Enums.IssueType.Disturbance];
            }
        } else if (currentMode === 'issues') {
            filters.WithArchivedIssues = View.CurrentView === Enums.View.Inspection;
            filters.Types = [];

            if (View.CurrentView === Enums.View.Inspection) {
                allIssuesLoaded = true;
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Task)) {
                filters.Types.push(Enums.IssueType.Task);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Scheduling)) {
                filters.Types.push(Enums.IssueType.Scheduling);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Form)) {
                filters.Types.push(Enums.IssueType.Form);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Inspection)
                && Session.Client.Licenses.Inspections) {
                filters.Types.push(Enums.IssueType.Inspection);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.NoteReport, Enums.IssueType.Note)) {
                filters.Types.push(Enums.IssueType.Note);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.DisturbanceReport, Enums.IssueType.Disturbance)) {
                filters.Types.push(Enums.IssueType.Disturbance);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Survey) &&
                Session.Client.Settings.SyncOpenSurveys) {
                filters.Types.push(Enums.IssueType.Survey);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Investigation) &&
                Session.Client.Settings.SyncOpenInvestigations) {
                filters.Types.push(Enums.IssueType.Investigation);
            }
        } else if (currentMode === 'issue-progress') {
            filters.Types = [];

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Task)) {
                filters.Types.push(Enums.IssueType.Task);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Scheduling)) {
                filters.Types.push(Enums.IssueType.Scheduling);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Form)) {
                filters.Types.push(Enums.IssueType.Form);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Inspection)
                && Session.Client.Licenses.Inspections) {
                filters.Types.push(Enums.IssueType.Inspection);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Survey) &&
                Session.Client.Settings.SyncOpenSurveys) {
                filters.Types.push(Enums.IssueType.Survey);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Investigation) &&
                Session.Client.Settings.SyncOpenInvestigations) {
                filters.Types.push(Enums.IssueType.Investigation);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.NoteReport, Enums.IssueType.Note)) {
                filters.Types.push(Enums.IssueType.Note);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.DisturbanceReport, Enums.IssueType.Disturbance)) {
                filters.Types.push(Enums.IssueType.Disturbance);
            }

            filters.LoadIssues = true;
            filters.LocationOID = DAL.Elements.Root.OID;
            filters.ProcessingStatus = processingStatus;
            filters.WithChildLocations = true;

            SetFilterState();
        } else {
            filters.WithArchivedIssues = false;

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Task)) {
                filters.Types.push(Enums.IssueType.Task);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Scheduling)) {
                filters.Types.push(Enums.IssueType.Scheduling);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Form)) {
                filters.Types.push(Enums.IssueType.Form);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Inspection)
                && Session.Client.Licenses.Inspections) {
                filters.Types.push(Enums.IssueType.Inspection);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Survey) &&
                Session.Client.Settings.SyncOpenSurveys) {
                filters.Types.push(Enums.IssueType.Survey);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Investigation) &&
                Session.Client.Settings.SyncOpenInvestigations) {
                filters.Types.push(Enums.IssueType.Investigation);
            }
        }
    }

    function updateFooterToolbar(): void {
        if (!$footerToolbar || !$footerToolbar.length) {
            return;
        }

        $footerToolbar.find('.sorting .item:not(.placeholder)')
            .html(getTitleOfSelectedSorting());

        if (View.CurrentView !== Enums.View.Inspection) {
            if (Session.Mode === Enums.Mode.IssueReport) {
                const location = Session.CurrentLocation;
                const canEditOrCreateTasks = Utils.CanUserCreateIssueType(Enums.IssueType.Task, location);
                const canEditOrCreateNotes = Utils.CanUserCreateIssueType(Enums.IssueType.Note, location);
                const canEditOrCreateDisturbances = Utils.CanUserCreateIssueType(Enums.IssueType.Disturbance, location);

                $footerToolbar.find('.add-issue')
                    .toggleClass('hidden', (!(canEditOrCreateTasks || canEditOrCreateNotes || canEditOrCreateDisturbances)));
            } else {
                if (Utils.CanUserCreateIssueType(Utils.GetIssueTypeByMode(Session.Mode), Session.CurrentLocation)) {
                    if (!Utils.IsIssueLocked(IssueView.GetCurrentIssue())) {
                        $footerToolbar.find('.add-issue').removeClass('hidden');
                    }
                } else {
                    $footerToolbar.find('.add-issue').addClass('hidden');
                }
            }
        }
    }

    function loadIssueCounters(issueFilters: Model.Issues.IFilter): Deferred {
        const deferred = $.Deferred();

        // extend location-filter to root
        issueFilters.LocationOID = DAL.Elements.Root.OID || issueFilters.LocationOID;
        const enabledMenuItems = Utils.GetAvailableContentMenuItemsByRoles(Enums.MenuSection.Content, Session.CurrentLocation.OID);
        const menuItemFilters = [];
        const menuItemIDs = [];

        // create custom menu deferreds
        if (enabledMenuItems) {
            for (let i = 0; i < enabledMenuItems.length; i++) {
                const mItemID = enabledMenuItems[i].substr(Enums.MenuSection.Content.length + 1);
                const mItemConf = Menu.GetMenuItemConfig(mItemID);

                if (!mItemConf || mItemConf.ActionType != Enums.Menu.Action.Issues) {
                    continue;
                }

                // available since API 17
                const isAvailableInRecordingApp = typeof mItemConf.IsAvailableInRecordingApp === 'boolean' ? mItemConf.IsAvailableInRecordingApp : true;
                if (!isAvailableInRecordingApp) {
                    continue;
                }

                menuItemIDs.push(mItemID);
                menuItemFilters.push(Menu.GetFilter(mItemConf));
            }
        }

        if (!menuItemFilters.length) {
            menuItemFilters.push({
                Types: [
                    Enums.IssueType.Task,
                    Enums.IssueType.Scheduling,
                    Enums.IssueType.Form,
                    Enums.IssueType.Note,
                    Enums.IssueType.Disturbance
                ]
            });
            if (Session.Client.Licenses.Inspections) {
                menuItemFilters[0].Types.push(Enums.IssueType.Inspection);
            }

            if (Session.Client.Settings.SyncOpenSurveys) {
                menuItemFilters[0].Types.push(Enums.IssueType.Survey);
            }

            if (Session.Client.Settings.SyncOpenInvestigations) {
                menuItemFilters[0].Types.push(Enums.IssueType.Investigation);
            }
        }

        if (Session.IsSmartDeviceApplication) {
            let counterNode: Model.TreeCache.CacheMetaNode;

            if (menuItemIDs.length) {
                const tmpCounterTree = DAL.TreeCache.Global.createSubInstance(null, '02_temp_counter_tree', false)
                for (let i = 0; i < menuItemIDs.length; i++) {
                    const tmpMenuID = menuItemIDs[i];
                    const tmpTreeCache = DAL.TreeCache.Global.getNode(null, tmpMenuID);
                    if (!tmpTreeCache) {
                        continue;
                    }

                    const tmpIssues = tmpTreeCache.getTotalIssues() || [];
                    for (let i = 0; i < tmpIssues.length; i++) {
                        tmpCounterTree.setIssue(<any>tmpIssues[i]);
                    }
                }
                counterNode = tmpCounterTree.getNode();
                tmpCounterTree.destroy();
            } else {
                const tempTreeCache = DAL.TreeCache.Global.createSubInstance(<Model.Issues.IFilter>menuItemFilters[0],
                    '02_temp_counter_tree');
                counterNode = tempTreeCache.getNode();
                tempTreeCache.destroy();
            }

            const resultCounters = {};
            const stateResults = {};

            function getCounter(node: Model.TreeCache.CacheMetaNode) {
                if (!node) {
                    return;
                }

                let currentCounters: Model.Issues.Counter = node.getTotalStateCounter();
                if (currentCounters && currentCounters.IssueCount > 0) {
                    resultCounters[node.oid] = currentCounters.IssueCount;
                    stateResults[node.oid] = currentCounters.ProcessingStatus;

                    // Counter für Children nur ermitteln, wenn der aktuelle Node Vorgänge vorweist
                    // (Vorgänge werden am aktuellen Node summiert, sind hier keine vorhanden, haben Children auch keine)
                    if (node.children) {
                        for (let i = 0; i < node.children.length; i++) {
                            getCounter(node.children[i]);
                        }
                    }
                }
            }

            getCounter(counterNode);

            deferred.resolve(resultCounters, stateResults);
        } else {
            const userFilter = createFilterObject(issueFilters);
            userFilter.Types = issueFilters.UserSelectedTypes;

            Utils.Http.Post('reports/issuecounters', { MenuItems: menuItemFilters, UserFilter: userFilter })
                .then((report) => {
                    const counters = {};
                    const stateResults = {};

                    if (!report || !report.Counters) {
                        deferred.resolve(counters);
                        return;
                    }

                    for (let elementOID in report.Counters) {
                        if (!report.Counters.hasOwnProperty(elementOID)) {
                            continue;
                        }

                        if (report.Counters[elementOID].Count === 0) {
                            continue;
                        }

                        counters[elementOID] = report.Counters[elementOID].Count;
                        stateResults[elementOID] = report.Counters[elementOID].ProcessingStatus;
                    }

                    deferred.resolve(counters, stateResults);
                }).fail(() => {
                    // Nur rejecten keine Exception werfen, da der Counter nur informativ ist und der Benutzer auch ohne weiterarbeiten kann
                    deferred.reject();
                });
        }
        return deferred.promise();
    }

    export function OnIssueModified(issue: Model.Issues.Issue): void {
        if (!issue.ID) {
            issue.ID = 0;
        }

        issue.ShowFiles = true;
        issues[issue.OID] = issue;

        if (View.CurrentView === Enums.View.Inspection) {
            IssueView.ReplaceSubIssue(issue);

            Show(currentMode, GetDescendantsOfIssue());
        } else {
            if (!!issue.PrecedingOID) {
                const $li = $issueReport.find(`li[data-oid="${issue.PrecedingOID}"]`);

                let wrongModeForIssue: boolean;
                switch (Session.Mode) {
                    case Enums.Mode.TaskReport:
                        wrongModeForIssue = !Utils.InArray([
                            Enums.IssueType.Task,
                            Enums.IssueType.Form,
                            Enums.IssueType.Scheduling,
                            Enums.IssueType.Inspection,
                            Enums.IssueType.Investigation
                        ], issue.Type);
                        break;
                    case Enums.Mode.NoteReport:
                        wrongModeForIssue = issue.Type !== Enums.IssueType.Note;
                        break;
                    case Enums.Mode.DisturbanceReport:
                        wrongModeForIssue = issue.Type !== Enums.IssueType.Disturbance;
                        break;
                    case Enums.Mode.IssueReport:
                    default:
                        wrongModeForIssue = false;
                        break;
                }

                const menuItemConfig = Menu.GetMenuItemConfig(CustomMenuID);
                const filter = Menu.GetFilter(menuItemConfig);

                if (issue.IsArchived ||
                    issue.IsDeleted ||
                    wrongModeForIssue ||
                    DAL.Issues.FilterIssue(filter, new Model.Issues.RawIssue(issue))) {
                    $li.remove();

                    if (!$issueReport.find('li').length) {
                        $issueReport.html(Templates.Reports.NoIssuesAvailable({
                            IssuePlural: getIssuePlural()
                        }));
                    }
                } else if (!issue.IsModified) {
                    const $newLi = $(Templates.Reports.IssueCell({
                        Issue: issue,
                        Settings: Session.Settings
                    }));

                    $newLi.find('.image img')
                        .on('load', onAfterIssueImageLoaded)
                        .on('error', onIssueImageLoadingError)
                        .on('click', onIssueImageClick);

                    $newLi.find('.image img').siblings('div').find('> svg')
                        .on('click', onIssueImageMarksClick);

                    $li.replaceWith($newLi);
                    $li.find('img').trigger('load');
                } else {
                    $li.remove();

                    if (!$issueReport.find('li').length) {
                        $issueReport.html(Templates.Reports.NoIssuesAvailable({
                            IssuePlural: getIssuePlural()
                        }));
                    }
                }
            } else {
                const $newLi = $(Templates.Reports.IssueCell({
                    Issue: issue,
                    Settings: Session.Settings
                }));

                $newLi.find('.image img')
                    .on('load', onAfterIssueImageLoaded)
                    .on('error', onIssueImageLoadingError)
                    .on('click', onIssueImageClick);

                $newLi.find('.image img').siblings('div').find('> svg')
                    .on('click', onIssueImageMarksClick);

                $issueReport.find('ul').prepend(Templates.Reports.IssueCell({
                    Issue: issue,
                    Settings: Session.Settings
                }));
            }
        }

        if (Session.IsSmartDeviceApplication) {
            App.UpdateContentHeaderIssueProcessingIndicator();
        }
    }

    export function GetDescendantsOfIssue() {
        if (!Session.IsSmartDeviceApplication) {
            return IssueView.GetCurrentIssue().Descendants || [];
        }

        const issuesFilter = createFilterObject(filters);
        issuesFilter.Types = [];

        const targetNode = DAL.TreeCache.Global.getNode(filters.LocationOID || Session.CurrentLocation.OID, []);
        const issues = targetNode.getTotalIssues(issuesFilter);
        const descendants: Array<Model.Issues.Issue> = issues.filter((i: Model.Issues.Issue) => {
            return i.ParentID === IssueView.GetCurrentIssue().ID || i.ParentOID === IssueView.GetCurrentIssue().OID;
        });

        return descendants || [];
    }

    export function RefreshView(): void {
        if (Session.Mode === Enums.Mode.IssueProgressReport) {
            sessionStorage.DoNotResetIssueProcessingFilters = true;
            Utils.Router.GoToSectionAtLocation(Session.CurrentLocation.OID, Enums.Mode.Menu);
        } else if (Session.Mode.contains('-report')) {
            if (View.CurrentView === Enums.View.Inspection) {
                Show(currentMode, GetDescendantsOfIssue());
            } else if (CustomMenuID) {
                ShowByMenu(CustomMenuID);
            } else {
                Show(currentMode);
            }
        } else if (Session.Mode === Enums.Mode.Menu) {
            Utils.Router.RefreshState();
        } else if (Session.Mode === Enums.Mode.IssuePools) {
            App.FormIssuesPool.Show(App.FormIssuesPool.MenuItemFilterID);
        }

        SetFilterState();
        App.UpdateContentHeaderIssueProcessingIndicator();
        App.UpdateTreeCounters();
    }

    export function GetIssueProcessingInfo(): Deferred {
        const issueFilters = GetFilters();

        return loadIssueCounters(issueFilters)
            .then((counter, processingState: Dictionary<Enums.IssueProcessingStatus>) => {
                if (processingState && processingState[Session.CurrentLocation.OID]) {
                    return processingState[Session.CurrentLocation.OID];
                }

                return Enums.IssueProcessingStatus.OK;
            });
    }

    export function SetFilterState(): void {
        const $filterButton = $('.btn-issue-filters');

        if (AreFiltersActive()) {
            $filterButton.addClass('is-active');
            const tmpFilter = GetFilters();
            tmpFilter.Types = tmpFilter.UserSelectedTypes;
            DAL.TreeCache.Global.setFilter(tmpFilter);
        } else {
            $filterButton.removeClass('is-active');
            DAL.TreeCache.Global.setFilter(null);
        }
    }

    export function BindFooterToolbarEvents(): void {
        $footerToolbar = $('.footer-toolbar');

        if ($footerToolbar.length) {
            $footerToolbar.find('.sorting').on('click', onBtnSelectSortingClick);
            $footerToolbar.find('.add-issue').on('click', onBtnCreateIssueClick);
        }
    }

    export function GetReferenceFormIssue(filter: Model.Issues.IFilter) {
        const filterObject = createFilterObject(filter);

        filterObject.Types = [Enums.IssueType.Form];

        if (Session.Client.Licenses.Inspections) {
            filterObject.Types.push(Enums.IssueType.Inspection);
        }

        if (Session.Client.Settings.SyncOpenSurveys) {
            filterObject.Types.push(Enums.IssueType.Survey);
        }

        if (Session.Client.Settings.SyncOpenInvestigations) {
            filterObject.Types.push(Enums.IssueType.Investigation);
        }

        const filterArray = [filterObject];

        return getSortedIssuesFromService(filterArray, filter);
    }

    export function GetCounter(alternativeFilters?: Model.Issues.IFilter): Deferred {
        if (View.CurrentView === Enums.View.Inspection) {
            alternativeFilters = GetFilters();
            alternativeFilters.WithArchivedIssues = true;

            filters.Types = [];

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Task)) {
                filters.Types.push(Enums.IssueType.Task);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Scheduling)) {
                filters.Types.push(Enums.IssueType.Scheduling);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Form)) {
                filters.Types.push(Enums.IssueType.Form);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Inspection) &&
                Session.Client.Licenses.Inspections) {
                filters.Types.push(Enums.IssueType.Inspection);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Survey) &&
                Session.Client.Settings.SyncOpenSurveys) {
                filters.Types.push(Enums.IssueType.Survey);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.TaskReport, Enums.IssueType.Investigation) &&
                Session.Client.Settings.SyncOpenInvestigations) {
                filters.Types.push(Enums.IssueType.Investigation);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.NoteReport, Enums.IssueType.Note)) {
                filters.Types.push(Enums.IssueType.Note);
            }

            if (IsTypeFilterAllowed(Enums.MenuItemID.DisturbanceReport, Enums.IssueType.Disturbance)) {
                filters.Types.push(Enums.IssueType.Disturbance);
            }

            return DAL.Issues.GetByFilters(
                $.extend({}, alternativeFilters || filters, { LoadIssues: false }),
                false,
                GetDescendantsOfIssue());
        } else {
            if (!!filters.LocationOID &&
                View.CurrentView === Enums.View.Main &&
                filters.LocationOID !== Session.CurrentLocation.OID) {
                filters.LocationOID = null;
            }

            filters.WithArchivedIssues = false;
            return loadIssues($.extend({}, alternativeFilters || filters, { LoadIssues: false }));
        }
    }

    export function GetFilters(): Model.Issues.Filter {
        filters.IncludeDerivations = Session.Client.Licenses.EnableInfoBoard;

        return new Model.Issues.Filter(filters);
    }

    export function Show(mode: string, preloadedIssues?: Array<Model.Issues.Issue>, processingStatus?: Enums.IssueProcessingStatus): void {
        const selectedFilterLocationOID = filters.LocationOID;
        CustomMenuID = null;
        activeCacheNode = null;

        if (View.CurrentView === Enums.View.Inspection) {
            mode = 'issues';
        }

        const appMode = mode !== 'issue-progress' ? mode.substr(0, mode.length - 1) : 'issue-progress';

        // Footer wird hier gesetzt
        Utils.SetMode(<Enums.Mode>(appMode + '-report'));

        if (currentMode !== 'issues' &&
            mode === 'issues' &&
            View.CurrentView === Enums.View.Inspection) {
            filters.Sorting = Enums.SortingBy.CUSTOMIDENT;
            filters.SortingOrder = Enums.SortingOrder.ASC;
        }

        showTypeIcons = true;
        currentMode = mode;
        $container = $('#content');
        $content = Utils.GetContentContainer();
        $footerToolbar = $('.footer-toolbar');

        if (currentMode !== 'issues' || View.CurrentView !== Enums.View.Inspection) {
            filters.Sorting = Session.Settings.SaveReportSorting.Property;
            filters.SortingOrder = Session.Settings.SaveReportSorting.Order;
        }

        issues = {};
        filters.Skip = 0;
        allIssuesLoaded = false;

        setFiltersForReport(processingStatus);
        updateFooterToolbar();

        if (!!filters.LocationOID &&
            View.CurrentView === Enums.View.Main &&
            filters.LocationOID !== Session.CurrentLocation.OID) {
            filters.LocationOID = null;
        }

        Utils.Spinner.Show();

        setTimeout(function() {
            if (!preloadedIssues) {
                loadIssues(filters)
                    .then(renderIssueReport);
            } else {
                DAL.Issues.GetByFilters(
                    <Model.Issues.Filter>filters,
                    false,
                    preloadedIssues).then(response => {
                        renderIssueReport(response.Issues);
                    });
            }

            filters.LocationOID = selectedFilterLocationOID;

            SetFilterState();
        }, 100);

        View.SetRefreshMethod(RefreshView);
    }

    export function ShowByMenu(menuID: string, $alternativeContainer?: any): void {
        const selectedFilterLocationOID = filters.LocationOID;
        CustomMenuID = menuID;

        if (!$alternativeContainer) {
            // Footer wird hier gesetzt, wenn Standard Container verwendet wird
            Utils.SetMode(Enums.Mode.IssueReport);
            showTypeIcons = true;
        } else {
            showTypeIcons = false;
        }

        if (currentMode !== 'issues' &&
            View.CurrentView === Enums.View.Inspection) {
            filters.Sorting = Enums.SortingBy.CUSTOMIDENT;
            filters.SortingOrder = Enums.SortingOrder.ASC;
        }

        switch (menuID) {
            case Enums.Menu.SystemID.TaskReport:
                currentMode = 'tasks';
                break;
            case Enums.Menu.SystemID.NoteReport:
                currentMode = 'notes';
                break;
            case Enums.Menu.SystemID.DisturbanceReport:
                currentMode = 'disturbances';
                break;
            default:
                currentMode = 'issues';
                break;
        }

        if ($alternativeContainer) {
            $container = $alternativeContainer;
            $content = $alternativeContainer;
            $footerToolbar = null;
        } else {
            $container = $('#content');
            $content = Utils.GetContentContainer();
            $footerToolbar = $('.footer-toolbar');
        }

        if (currentMode !== 'issues' || View.CurrentView !== Enums.View.Inspection) {
            filters.Sorting = Session.Settings.SaveReportSorting.Property;
            filters.SortingOrder = Session.Settings.SaveReportSorting.Order;
        }

        issues = {};
        filters.Skip = 0;
        allIssuesLoaded = false;

        setFiltersForReport(null);
        updateFooterToolbar();

        if (!!filters.LocationOID &&
            View.CurrentView === Enums.View.Main &&
            filters.LocationOID !== Session.CurrentLocation.OID) {
            filters.LocationOID = null;
        }

        Utils.Spinner.Show();

        setTimeout(function() {
            if (Session.IsSmartDeviceApplication) {
                const tCache = DAL.TreeCache.Custom[menuID];
                if (!tCache) {
                    // Standard-Menüpunkte haben keine Einträge im TreeCache
                    // Verwende die Standardmethoden um Vorgänge zu laden
                    DAL.Issues.GetByFilters(<Model.Issues.Filter>filters, false, null)
                        .then(response => {
                            renderIssueReport(response.Issues);
                        });
                    return;
                }

                activeCacheNode = tCache.getNode(Session.CurrentLocation.OID);
                const allIssues = activeCacheNode.getTotalIssues();
                // Vorgänge nach Filter sortieren
                DAL.Issues.SortIssuesList(<any>allIssues, filters);
                renderIssueReport(allIssues.slice(filters.Skip, filters.Skip + filters.Take));
            } else {
                loadIssues(filters)
                    .then(renderIssueReport);
            }

            filters.LocationOID = selectedFilterLocationOID;
        }, 100);

        View.SetRefreshMethod(RefreshView);
    };

    export function UpdateTeamsAndUsersFilter(settingChanged: boolean = false) {
        if (settingChanged) {
            userFilterChanged = false;
        }

        if (!Utils.IsSet(Session)) {
            return;
        }

        if (!Utils.IsSet(Session.User)) {
            return;
        }

        if (!Utils.CanUserSeeLocation(Session.User.OID, Session.CurrentLocation) &&
            !Session.Settings.ShowOnlyMyIssuesPerDefault) {

            if (settingChanged) {
                filters.Teams = [];
                filters.Users = [];
            }

            return;
        }

        if (userFilterChanged) {
            if (Session.IsSmartDeviceApplication) {
                setGlobalTreeCacheFilter();
            }

            return;
        }

        if (Session.Settings.ShowOnlyMyIssuesPerDefault) {
            if (!Utils.IsSet(filters.Users)) {
                filters.Users = [];
            }

            if (!Utils.IsSet(filters.Teams)) {
                filters.Teams = [];
            }

            if (!Utils.InArray(filters.Users, Session.User.OID)) {
                filters.Users.push(Session.User.OID);
            }

            for (let ti = 0; ti < (Session.User.Teams || []).length; ti++) {
                const team = Session.User.Teams[ti];

                if (!Utils.InArray(filters.Teams, team)) {
                    filters.Teams.push(team);
                }
            }
        } else {
            const userIndex = Utils.GetIndex(filters.Users, Session.User.OID);

            if (userIndex > -1) {
                filters.Users.splice(userIndex, 1);
            }

            if ((filters.Teams || []).length) {
                for (let ti = 0; ti < (Session.User.Teams || []).length; ti++) {
                    const team = Session.User.Teams[ti];
                    const teamIndex = Utils.GetIndex(filters.Teams, team);

                    if (teamIndex > -1) {
                        filters.Teams.splice(teamIndex, 1);
                    }
                }
            }
        }

        if (Session.IsSmartDeviceApplication) {
            setGlobalTreeCacheFilter();
        }
    }

    function setGlobalTreeCacheFilter() {
        const tmpFilter = GetFilters();
        tmpFilter.Types = tmpFilter.UserSelectedTypes;
        DAL.TreeCache.Global.setFilter(tmpFilter);
    }

    export function AreFiltersActive(): boolean {
        return filters.IsSet();
    }

    export function ResetProcessingFilter(): void {
        filters.ProcessingStatus = null;

        currentMode = 'issues';

        SetFilterState();
        App.UpdateContentHeaderIssueProcessingIndicator();
        App.UpdateTreeCounters();
    }

    export function ResetFilters(simpleReset?: boolean): void {
        filters.Reset();

        if (currentMode === 'issue-progress' && !simpleReset) {
            currentMode = 'issues';
        }

        userFilterChanged = false;
        SetFilterState();

        if (Session.User && !simpleReset) {
            App.UpdateContentHeaderIssueProcessingIndicator();
            App.UpdateTreeCounters();
        }
    }

    export function ShowIssueFilterSelection(): void {
        new Utils.IssueFilterWindow(filters, onFiltersSaved).Show();
    }

    function onFiltersSaved(newFilters: Model.Issues.Filter) {
        filters = newFilters;
        userFilterChanged = newFilters.IsSet();

        filters.IncludeDerivations = Session.Client.Licenses.EnableInfoBoard;

        UpdateTeamsAndUsersFilter();

        const tmpFilter = GetFilters();
        tmpFilter.Types = tmpFilter.UserSelectedTypes;
        DAL.TreeCache.Global.setFilter(tmpFilter);

        RefreshView();
    }

    export function InitUserFilter(): void {
        if (!Session.User ||
            !Utils.CanUserSeeLocation(Session.User.OID, Session.CurrentLocation) ||
            !Session.Settings.ShowOnlyMyIssuesPerDefault) {
            return;
        }

        filters.Teams = DAL.Teams.GetByUserOID(Session.User.OID).map(function(team) { return team.OID; });
        filters.Users = [Session.User.OID];

        SetFilterState();
    }

    export function InitDeadlineFilter(): void {
        filters.DeadlinePeriod = getDefaultDeadlineFilterByPreset();

        SetFilterState();
    }

    export function RepositionMarks(): void {
        const $images = $content.find('.image img');

        if ($images.length) {
            for (let iCnt = 0, iLen = $images.length; iCnt < iLen; iCnt++) {
                const $img = $images.eq(iCnt);
                const $marks = $img.siblings('div');

                if ($marks.length) {
                    $marks.css({
                        top: $img.position().top,
                        // left: $img.position().left,
                        width: $img.width(),
                        height: $img.height(),
                        'margin-left': 'auto',
                        'margin-right': 'auto'
                    });

                    $marks.find('> svg')
                        .attr({
                            width: '100%',
                            height: '100%'
                        });
                }
            }
        }
    }

    export function SetStatusFilter(statusIdentifier: string): void {
        if (!statusIdentifier) {
            return;
        }

        ResetFilters();

        filters.States = [statusIdentifier];

        SetFilterState();
    }

    export function GetTreeCounters(): Deferred {
        if (!Session.CurrentLocation || (!Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion < 5) || !Session.Settings.ShowTreeCounters) {
            return $.Deferred().reject().promise();
        }

        const filter = GetFilters();
        const currentLocationOID = Session.CurrentLocation.OID;

        filter
            .SetTypes(getAllAllowedIssueTypes(currentLocationOID))
            .SetWithChildLocations(true)
            .SetLocationOID(currentLocationOID);

        return loadIssueCounters(filter);
    }
}