module Model.Files {
    export class FileDimensions {
        Height: number;
        Width: number;

        constructor(height: number, width: number) {
            this.Height = height;
            this.Width = width;
        }

        SetHeight(height: number): FileDimensions {
            this.Height = height;

            return this;
        }

        SetWidth(width: number): FileDimensions {
            this.Width = width;

            return this;
        }
    }
}