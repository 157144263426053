//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../model/contact-groups/contact-group.ts"  />
//imports-end

module DAL.ContactGroups {
    let _contactGroups: Dictionary<Model.ContactGroups.ContactGroup>;

    function prepareContact(contactGroup: Model.ContactGroups.ContactGroup): Model.ContactGroups.ContactGroup {
        contactGroup.Title = Utils.EscapeHTMLEntities(contactGroup.Title);

        return contactGroup;
    }

    export function Store(syncedContactGroups: Array<Model.ContactGroups.ContactGroup>): void {
        if (!_contactGroups) {
            _contactGroups = {};
        }

        if (!(syncedContactGroups || []).length) {
            return;
        }

        for (let sCnt = 0, sLen = syncedContactGroups.length; sCnt < sLen; sCnt++) {
            const contactGroup = syncedContactGroups[sCnt];

            if (!contactGroup.Deleted) {
                _contactGroups[contactGroup.OID] = prepareContact(contactGroup);
            } else {
                delete _contactGroups[contactGroup.OID];
            }
        }
    }

    export function GetByOID(oid: string): Model.ContactGroups.ContactGroup {
        if (!_contactGroups) {
            return null;
        }

        if (!Exists(oid)) {
            return;
        }

        return _contactGroups[oid];
    }

    export function Exists(oid: string): boolean {
        if (!_contactGroups) {
            return false;
        }

        return _contactGroups.hasOwnProperty(oid);
    }

    export function GetAll(): Array<Model.ContactGroups.ContactGroup>|null {
        if (!_contactGroups) {
            return null;
        }

        return $.map(_contactGroups, (contactGroup: Model.ContactGroups.ContactGroup) => contactGroup)
            .sort(Utils.SortByTitle);
    }

    export function Clear(): void {
        _contactGroups = {};
    }
}