module Model {
    export class KeyValueStorage {
        private Dictionary: Object = {};

        Insert(key: string, value: any): KeyValueStorage {
            if (this.Dictionary.hasOwnProperty(key)) {
                throw new Error(`key ${key} already exists`);
            }

            return this.Set(key, value);
        }

        Set(key: string, value: any): KeyValueStorage {
            this.Dictionary[key] = value;

            return this;
        };

        ContainsKey(key: string): Boolean {
            return this.hasOwnProperty(key);
        };

        FindByKey(key: string): any {
            if (this.Dictionary.hasOwnProperty(key)) {
                return this.Dictionary[key];
            }
        }

        Remove(key: string): void {
            delete this.Dictionary[key];
        }

        GetSize(): number {            
            return Object.keys(this.Dictionary).length;
        }
    }
}