//imports-start
/// <reference path="../../utils/utils.date-time.ts"  />
/// <reference path="../core/revision.ts"  />
/// <reference path="./file.ts"  />
//imports-end

module Model.Files {
    export class RawFile extends Model.Core.Revision {
        Title: string;
        Filename?: string;
        Tag: number;
        IsAvailableOffline: boolean;
        FolderOID?: string;
        Description?: string;
        MimeType?: string;
        SizeOriginal?: string;
        Type: Enums.FileType;
        URL?: string;
        VideoID?: string;
        SizeS?: string;
        SizeM?: string;
        SizeL?: string;
        ChangeID?: string;

        constructor(file?: File) {
            super();

            if (!file) {
                return;
            }

            this.initProperties(file);
        }

        initProperties(file: File): void {
            this.Title = file.Title;
            this.Filename = file.Filename;
            this.Tag = file.Tag;
            this.IsAvailableOffline = file.IsAvailableOffline;
            this.FolderOID = file.FolderOID;
            this.Description = file.Description;
            this.MimeType = file.MimeType;
            this.Type = file.Type;
            this.URL = file.URL;
            this.VideoID = file.VideoID;

            this.SizeOriginal = file.SizeOriginal;
            this.SizeS = file.SizeS;
            this.SizeM = file.SizeM;
            this.SizeL = file.SizeL;
        }
    }
}