//imports-start
/// <reference path="../../definitions.d.ts"  />
//imports-end

module Utils {
    export class ImageViewerOptions {
        constructor(
            public Files?: Array<any>,
            public Images?: Array<any>,
            public ImageFilename?: string,
            public StartFilename?: string,
            public ForceLoadFromServer?: boolean,
            public FooterInformation?: { Title: any; },
            public FileSeenCallback?: (image: any) => void,
            public ImageEditedCallback?: (identifier: any, marks: string) => void,
            public FullPathGiven?: boolean,
            public CanWriteComments?: boolean,
            public closeFn?: (hasDownloadUpdate: boolean) => void,
            public ComparisonImages?: Array<Model.Files.File>,
            public ComparisonOnStart?: boolean
        ) { }
    }
}