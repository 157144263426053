//imports-start
/// <reference path="../issues/counters.ts"  />
//imports-end

module Model.Menu {
    export class MenuItem {
        readonly ID: string | Enums.Menu.SystemID;
        ItemMarker: Enums.IssueCellMarker;
        Icon: Enums.Menu.Icon | string;
        BackgrountColor?: string;
        TextColor?: string;
        BadgeCounterAutoWidth: boolean;
        CounterText: string | number;
        Title: string;
        Description?: string;
        ShowAddButton: boolean;
        HasFilterActive: boolean;
        ActionAttribute: Enums.Menu.Action;
        IsVisible: boolean = true;
        IsAvailableInRecordingApp: boolean;
        EnablePreview?: boolean;

        OnSelect: Function;
        OnAdd: Function;

        constructor(id: string | Enums.Menu.SystemID, title?: string) {
            this.ID = id;

            if (typeof title != 'undefined') {
                this.Title = title;
            }

            if (Session.LastKnownAPIVersion < 17) {
                this.IsAvailableInRecordingApp = true;
            }

            this.BadgeCounterAutoWidth = false;
        }

        clone(newID?: string | Enums.Menu.SystemID): MenuItem {
            const newItem = new MenuItem(newID || this.ID);

            for (let key in this) {
                if (this.hasOwnProperty(key)) {
                    (<any>newItem)[key] = this[key];
                }
            }

            return newItem;
        }
    }
}