//imports-start
/// <reference path="../../model/nfc/tag.ts"  />
//imports-end

module Utils.Nfc {
    export class Decoder implements Model.Nfc.IDecoder {
        private readonly Tag: Model.Nfc.Tag;
        private readonly DecodedMessage: string[];

        constructor(tag: Model.Nfc.Tag) {
            this.Tag = tag;
            this.DecodedMessage = this.decodeMessages();
        }

        public GetTag(): Model.Nfc.Tag {
            return this.Tag;
        }

        public GetDecodedId(): string {
            return window.nfc.bytesToHexString(this.Tag.id);
        }

        public GetDecodedMessage(): string[] {
            return this.DecodedMessage;
        }

        private decodeMessages(): string[] {
            if (!(this.Tag.ndefMessage || []).length) {
                return [];
            }

            const messages = [];

            this.Tag.ndefMessage.forEach(message => {
                messages.push(this.decode(message));
            });

            return messages;
        }

        private decode(message: Model.Nfc.NdefMessage): string {
            const nfc = window.nfc;
            const recordType = nfc.bytesToString(message.type);
            let payload;

            if (recordType === 'T') {
                const langCodeLength = message.payload[0];
                const text = message.payload.slice((1 + langCodeLength), message.payload.length);

                payload = nfc.bytesToString(text);

            } else {
                payload = nfc.bytesToString(message.payload);
            }

            return payload;
        }
    }
}