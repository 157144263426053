///<reference path="i-workflow.ts"/>
///<reference path="workflow-base.ts"/>
///<reference path="workflow-settings.ts"/>
/// <reference path="../../../model/model.recorditem.ts"/>
/// <reference path="../../../model/elements/element.ts"/>
/// <reference path="../../../utils/utils.file-input-window.ts"/>

module New.Checkpoints.Workflow {
    export class PhotoWorkflow extends WorkflowBase {
        constructor(workflow: IWorkflow, workflowSettings: WorkflowSettingsBase) {
            super(workflow, workflowSettings);
        }

        public Execute(): any {
            const deferred = $.Deferred();

            if (Session.LastKnownAPIVersion >= 7 &&
                !Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyPhotoOnCheckpoint, true, this.LocationIdentifier)) {
                return deferred.resolve().promise();
            }

            if (Session.IsSmartDeviceApplication) {
                this.startCamera(deferred);
            } else {
                const title = this.Recorditem.Element ?
                    this.Recorditem.Element.Title :
                    i18next.t('CorrectiveActions.PhotoWorkflowHeader');

                const text = i18next.t('CorrectiveActions.SelectDocumentationImage');

                new Utils.FileInputWindow(title, text).Show()
                    .then((fileInformation: any) => {
                        this.onAfterFileSelected(fileInformation, deferred);
                    });
            }

            return deferred.promise();
        }

        private startCamera(deferred: Deferred): void {
            Utils.RequestCamera().then((filePath: string) => {
                this.onAfterGotImagePath(filePath, deferred);
            }, (msg: string) => {
                if (msg === 'No Image Selected') {
                    this.startCamera(deferred);
                    return;
                }

                deferred.reject();
            });
        }

        private onAfterGotImagePath = (filePath: string, deferred: Deferred): void => {
            const fileIdentifier = uuid();
            const filename = `${fileIdentifier}.${filePath.split('.').pop()}`;
            const file = {
                Filename: filename,
                OID: fileIdentifier,
                MimeType: Enums.MimeType.Image,
                IsImage: true
            };

            Utils.MoveFileToResources(filePath, filename)
                .then(() => {
                    const syncEntity = new Model.Synchronisation.RecordItemFileEntityDescription(
                        filename, Enums.SyncEntityType.RecorditemAdditionalFile, this.Recorditem
                    );

                    return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, syncEntity);
                })
                .then(() => {
                    const response = new Response.PhotoWorkflow(this, file);

                    deferred.resolve(response);
                }, deferred.reject);
        };

        private onAfterFileSelected = (fileInformation: any, deferred: Deferred): void => {
            if (!fileInformation ||
                !fileInformation.File ||
                !fileInformation.Identifier) {
                deferred.reject();
            }

            const file = fileInformation.File;
            const filename = `${fileInformation.Identifier}.${file.name.split('.').pop()}`;
            const uri = `${Session.BaseURI}records/images/${filename}${Utils.GetAuthQueryParameter('?')}`;
            const fileProperties = {
                Filename: filename,
                OID: fileInformation.Identifier,
                MimeType: file.type || Enums.MimeType.Image,
                IsImage: Utils.IsImage(file.type),
                Content: fileInformation.Event.target.result
            };

            Utils.Spinner.Show();
            Utils.UploadFile(uri, file)
                .then(() => {
                    const response = new Response.PhotoWorkflow(this, fileProperties);

                    deferred.resolve(response);
                })
                .always(Utils.Spinner.Hide);
        };
    }
}
