/// <reference path="../../../enums.ts"/>
/// <reference path="../../../model/model.recorditem.ts"/>
/// <reference path="../../../model/elements/element.ts"/>
/// <reference path="./i-workflow.ts"/>
/// <reference path="workflow-base.ts"/>
/// <reference path="form-workflow.ts"/>
/// <reference path="task-workflow.ts"/>
/// <reference path="comment-workflow.ts"/>
/// <reference path="photo-workflow.ts"/>
/// <reference path="null-workflow.ts"/>
///<reference path="workflow-settings.ts"/>


module New.Checkpoints.Workflow {
    export class WorkflowFactory {
        public static Create(workflow: IWorkflow, workflowSettings: WorkflowSettingsBase): WorkflowBase {
            switch (workflow.Type) {
                case Enums.CheckpointWorkflowType.Form:
                    return new FormWorkflow(workflow, <IssueWorkflowSettings>workflowSettings);
                case Enums.CheckpointWorkflowType.CorrectiveAction:
                    return new TaskWorkflow(workflow, <IssueWorkflowSettings>workflowSettings);
                case Enums.CheckpointWorkflowType.Comment:
                    return new CommentWorkflow(workflow, workflowSettings);
                case Enums.CheckpointWorkflowType.Photo:
                    return new PhotoWorkflow(workflow, workflowSettings);
                case Enums.CheckpointWorkflowType.ModifyParentIssue:
                    return new ModifyParentIssueWorkflow(workflow, <IssueWorkflowSettings>workflowSettings);
            }

            return new NullWorkflow(workflow, workflowSettings);
        }
    }
}