//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="./utils.custom-chart-tooltip.ts"  />
//imports-end

module Utils.ParameterHistory {
    let _$win, _$btnHome, _$timestampDropdownMenu, _$btnCreationTime, _$btnDeadlineTime, _$deadlineTable, _$overlay;
    let _parameter: Model.Elements.Element;
    let _parameters: Model.Elements.Element[];
    let _unit: Model.Properties.Property;
    let _recorditems: Model.Recorditem[];
    let _recorditemLookup: Dictionary<Model.Recorditem>;
    let _isTabularSubsampleParameter: boolean;
    let _showChart: boolean = false;
    let _showDeadlineTimestamp: boolean = false;
    let _historyChart: any;
    let _tooltip: Utils.CustomChartTooltip;

    enum OperatorType {
        Equal = 0,
        Unequal = 1,
        Less = 2,
        LessOrEqual = 3,
        Greater = 4,
        GreaterOrEqual = 5
    }

    function destroy(): void {
        if (_$win) {
            _$win.remove();
            Utils.Overlay.DestroyWithTimeout(_$overlay);

            _$win = null;
            _$overlay = null;
            _$btnHome = null;
            _$btnCreationTime = null;
            _$btnDeadlineTime = null;
            _$deadlineTable = null;
        }

        _parameter = null;
        _unit = null;
        _recorditems = null;
        _recorditemLookup = null;
        _showChart = null;
        _showDeadlineTimestamp = false;
        _historyChart = null;
        _tooltip = null;
    }

    function onAfterRecorditemsLoaded(recorditems: Model.Recorditem[]): void {
        if (!(recorditems || []).length) {
            _recorditems = null;

            Utils.Toaster.Show(
                i18next.t('ParameterHistory.NoRecorditems.MessageBody'),
                2,
                Enums.Toaster.Icon.Info);

            Utils.Spinner.Hide();

            return;
        }

        recorditems = prepareRecorditems(recorditems);
        recorditems.sort(function(a: Model.Recorditem, b: Model.Recorditem) {
            return b.CreationTimestamp.getTime() - a.CreationTimestamp.getTime();
        });

        _recorditems = recorditems;

        _recorditemLookup = {};
        _recorditems.forEach(function(ri: Model.Recorditem): void {
            _recorditemLookup[ri.OID] = ri;
        });

        _showChart = _parameter.Type == 101 && hasAnyValues(_recorditems);

        Utils.Spinner.Hide();
    }

    function hasAnyValues(recorditems: Model.Recorditem[]) {
        return !(!(recorditems || []).length || !recorditems.some(recorditem => !!recorditem.Value));
    }

    function onLoadingError(): void {
        Utils.Spinner.Hide();
    }

    function onFileInfoClick(): void {
        const $this = $(this);
        const recorditemOID = $this.parents('.recorditem').data('recorditemoid');

        if (!recorditemOID) {
            return;
        }

        const recorditem = _recorditemLookup[recorditemOID];

        if (!recorditem ||
            !(recorditem.Images || []).length) {
            return;
        }

        Utils.OpenImages(
            recorditem.Images,
            recorditem.Images[0].Filename,
            true,
            null,
            null,
            _parameter.Title,
            false);
    }

    function onFileClick(): void {
        const $this = $(this);
        const $li = $this.parents('li');
        const recorditem = _recorditemLookup[$li.data('recorditemoid')];

        if (!recorditem) {
            return;
        }

        const isImage = $this.hasClass('image') || $this.prop('tagName') === 'IMG';

        if (isImage) {
            if (!$this.hasClass('image') && !$this.parent().hasClass('additional-file')) {
                Utils.OpenImages([$this.data('filename')], $this.data('filename'), true, null, null, _parameter.Title);
            } else {
                Utils.OpenImages(recorditem.Images, $this.data('filename'), true, null, null, _parameter.Title);
            }
        } else {
            Utils.OpenFile($this.data('filename'), false, false);
        }
    }

    function onImageLoaded(): void {
        const $this = $(this);
        const $marks = $this.siblings('div');

        if (($marks || []).length) {
            $marks.css({
                top: $this.position().top,
                left: $this.position().left,
                width: $this.attr('width'),
                height: $this.height()
            });

            $marks.find('> svg')
                .attr({
                    width: '100%',
                    height: '100%'
                });
        }
    }

    function onTabItemClick(): void {
        const $this = $(this);

        if ($this.hasClass('selected')) {
            return;
        }

        const tab = $this.data('tab');

        if (!tab) {
            return;
        }

        $this
            .addClass('selected')
            .siblings()
            .removeClass('selected');

        _$win.find(`.content [data-tab="${tab}"]`)
            .removeClass('hidden')
            .siblings()
            .addClass('hidden');

        if (_tooltip && !_tooltip.isModelHidden()) {
            _tooltip.hide()
        }

        if (tab === 'chart' && Session.IsSmartDeviceApplication) {
            _$win.on('click', hideTooltipIfOutsideChart);
        } else {
            _$win.off('click', hideTooltipIfOutsideChart);
        }
    }

    function onBtnHomeClick(): void {
        destroy();
        Utils.Router.PushState('#main');
    }

    function onToggleTimeButtonClick(): void {
        const $this = $(this).closest('.chart-mode-item');

        if (!$this || !(_recorditems || []).length) {
            return;
        }

        const timestamp = $this.data('timestamp');

        if (!timestamp) {
            return;
        }

        _$timestampDropdownMenu.find('.hidden')
            .removeClass('hidden')
            .siblings(`[data-timestamp]`).addClass('hidden');

        _showDeadlineTimestamp = (timestamp === 'deadline-time');

        let recorditemsByTimestamp: Model.Recorditem[] = _recorditems;
        if (_showDeadlineTimestamp) {
            // Um die ursprüngliche Sortierung nicht zu überschreiben, wird eine Shallow Copy erstellt
            recorditemsByTimestamp = sortRecorditemsByDeadline(recorditemsByTimestamp.map((ri) => ri));
        }

        if (!_$deadlineTable) {
            loadDeadlineTable(recorditemsByTimestamp);
        }

        if (_showChart) {
            recorditemsByTimestamp = filterRecorditemsByValue(recorditemsByTimestamp);
            reloadChart(getChartData(recorditemsByTimestamp));
        }

        _$win.find(`.content [data-timestamp="${timestamp}"]`)
            .removeClass('hidden')
            .siblings()
            .addClass('hidden');
    }

    function reloadChart(chartData: {x: number | Date, y: number}[]): void {
        if (!(chartData || []).length || !_historyChart) {
            return;
        }

        _historyChart.data.datasets[0].data = chartData;
        _historyChart.update();
    }

    function loadDeadlineTable(recorditemsByDeadline: Model.Recorditem[]): void {
        _$deadlineTable = _$win.find(`.content [data-tab="values"] [data-timestamp="deadline-time"] ul`);
        _$deadlineTable.empty();

        if (!(recorditemsByDeadline || []).length) {
            return;
        }

        recorditemsByDeadline.forEach((recorditem: Model.Recorditem) => {
            const data = {
                ShowFiles: Session.Settings.ShowPicturesInIssueReports,
                IsTabularSubsample: _isTabularSubsampleParameter,
                ShowDeadlineTimestamp: _showDeadlineTimestamp
            };

            $.extend(true, data, recorditem);

            const $recorditem = $(Templates.ParameterHistoryWindow.Recorditem(data));

            _$deadlineTable.append($recorditem);
        });
    }

    function prepareRecorditems(recorditems: Model.Recorditem[]): Model.Recorditem[] {
        (recorditems || []).forEach(Utils.PrepareRecorditem);

        return recorditems;
    }

    function loadRecorditems(): Deferred {
        Utils.Spinner.Show();

        return Utils.Http.Get(`recorditems?elementoid=${_parameter.OID}&count=500&withdeadline=true`)
    }

    function bindEvents(): void {
        _$btnHome.on('click', onBtnHomeClick);
        _$btnCreationTime.on('click', onToggleTimeButtonClick);
        _$btnDeadlineTime.on('click', onToggleTimeButtonClick);
        _$win.find('.tab-control li').on('click', onTabItemClick);
        _$win.on('click', 'div[data-filename], img[data-filename]', onFileClick);
        _$win.on('click', '.file-info', onFileInfoClick);
        _$win.find('img').on('load', onImageLoaded).on('error', Utils.OnImageNotFound);
        _$win.find('iframe.info-text').on('load', Utils.OnIframeLoaded);

        if (Session.IsSmartDeviceApplication) {
            _$win.on('click', hideTooltipIfOutsideChart);
        }
    }

    function sortRecorditemsByDeadline(recorditems: Model.Recorditem[]) : Model.Recorditem[] {
        if (!(recorditems || []).length) {
            return;
        }

        return recorditems.sort(function (a: Model.Recorditem, b: Model.Recorditem) {
            const timestampA: Date = (a.DeadlineTimestamp) ? a.DeadlineTimestamp : a.CreationTimestamp;
            const timestampB: Date = (b.DeadlineTimestamp) ? b.DeadlineTimestamp : b.CreationTimestamp;
            return timestampB.getTime() - timestampA.getTime();
        });
    }
    function filterRecorditemsByValue(recorditems: Model.Recorditem[]) : Model.Recorditem[] {
        if (!(recorditems || []).length) {
            return;
        }

        return recorditems.filter(ri => ri.Value != null);
    }

    function hideTooltipIfOutsideChart(event: Event): void {
        if (!_tooltip || _tooltip.isModelHidden()) {
            return;
        }

        const target: EventTarget = event.target;
        const isToolbox: boolean = $(target).closest('#chartjs-tooltip').length != 0;
        const isChart: boolean = $(target).closest('canvas').length != 0;

        if (!isToolbox && !isChart) {
            _tooltip.hide();
        }
    }

    function loadImagesManually(): void {
        if (!Session.IsSmartDeviceApplication) {
            return;
        }

        const $images = _$win.find('img[data-filename]');

        $images.toArray().forEach(img => {
            const $img = $(img);

            if (!!$img.attr('src')) {
                return;
            }

            const filename = $img.data('filename');

            Utils.Http.LoadImage(`${Session.BaseURI}images/l/${filename}`)
                .then(function(base64: string): void {
                    $img.attr('src', base64);
                }, function(): void {
                    Utils.OnImageNotFound.call($img);
                });
        });
    }

    function renderChartTab(): void {
        const recorditemsForChart: Model.Recorditem[] = filterRecorditemsByValue(_recorditems);

        if (_parameter.Type !== Enums.ElementType.Number ||
            !(recorditemsForChart || []).length) {
            return;
        }

        if (!_tooltip) {
            _tooltip = new Utils.CustomChartTooltip();
        }

        const config = {
            type: 'line',
            data: {
                datasets: [{
                    backgroundColor: '#f00',
                    borderColor: '#f00',
                    data: getChartData(recorditemsForChart),
                    fill: false,
                    cubicInterpolationMode: 'monotone'
                }]
            },
            plugins: [{
                beforeEvent(chart, args) {
                    const event = args.event;
                    if (event.type === 'mouseout') {
                        const target = event.native.relatedTarget;
                        _tooltip._isTarget = target ? $(target).closest('#chartjs-tooltip').length != 0 : false;
                    }
                }
            }],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    annotation: getChartAnnotations(),
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false, // Standard-Tooltip deaktivieren
                        mode: 'nearest',
                        intersect: false,
                        displayColors: false,
                        callbacks: {
                            label: formatTooltipLabels,
                            title: formatTooltipTitle
                        },
                        external: function(context): void {
                            _tooltip.handleTooltip(context, this);
                        }
                    },
                },
                scales: {
                    x: {
                        type: _isTabularSubsampleParameter ? 'linear' : 'time',
                        display: true,
                        min: _isTabularSubsampleParameter ? 1 : undefined,
                        time: {
                            displayFormats: {
                                // Zur Formatierung siehe https://momentjs.com/docs/#/displaying/format/
                                'millisecond': i18next.t('DateTime.TimeFormatSecondsMoment'),
                                'second': i18next.t('DateTime.TimeFormatSecondsMoment'),
                                'minute': i18next.t('DateTime.TimeFormatMoment'),
                                'hour': i18next.t('DateTime.TimeFormatMoment'),
                                'day': i18next.t('DateTime.DayAndMonthFormatMoment'),
                                'week': i18next.t('DateTime.DayAndMonthFormatMoment'),
                                'month': i18next.t('DateTime.MonthAndYearFormatMoment'),
                                'quarter': i18next.t('DateTime.MonthAndYearFormatMoment'),
                                'year': 'YYYY',
                            }
                        },
                        ticks: {
                            major: {
                                enabled: true,
                            },
                            maxTicksLimit: 10,
                            stepSize: _isTabularSubsampleParameter ? 1 : undefined,
                        },
                        title: {
                            display: _isTabularSubsampleParameter,
                            text: _isTabularSubsampleParameter ? i18next.t('Misc.Subsample') : undefined
                        }
                    },
                    y: {
                        display: true,
                        title: {
                            display: true,
                            text: _unit ? Utils.UnescapeHTMLEntities(_unit.Title) : ''
                        }
                    }
                }
            }
        }

        const ctx = _$win.find(`.content [data-tab=chart] canvas`)[0].getContext("2d");

        _historyChart = new Chart(ctx, config);
    }

    function formatTooltipTitle(tooltipItem: { label: any, raw: { OID: string } }[]): string | ({
        icon: string;
        innerIcon?: string;
        timestamp: string;
        title: string;
    })[] {
        if (!tooltipItem || !tooltipItem[0] || !tooltipItem[0].label || !tooltipItem[0].raw || !_recorditemLookup[tooltipItem[0].raw.OID]) {
            return '---';
        }

        const recorditem: Model.Recorditem = _recorditemLookup[tooltipItem[0].raw.OID];
        const creator: Model.Users.User = recorditem ? DAL.Users.GetByOID(recorditem.CreatorOID) : null;
        const editor: Model.Users.User = recorditem ? DAL.Users.GetByOID(recorditem.EditorOID) : null;
        const creatorTitle: string = Utils.UnescapeHTMLEntities((creator ? creator.Title : i18next.t('Misc.Unknown')));

        if (_isTabularSubsampleParameter) { // TODO: Es gibt doch gar keine PP-Historie in Formularen
            return `${i18next.t('Misc.Subsample')}: ${tooltipItem[0].label}${creatorTitle}`;
        } else {
            let icon: string = 'icon-clock';
            let innerIcon: string;
            let displayedTimestamp = recorditem.CreationTimestamp;
            if (recorditem.DeadlineTimestamp && _showDeadlineTimestamp) {
                icon = 'icon-calendar';
                innerIcon = 'icon-clock'
                displayedTimestamp = recorditem.DeadlineTimestamp;
            }
            const timestamp: string = Utils.DateTime.ToString(displayedTimestamp, true);
            const modificationTimestamp: string = Utils.DateTime.ToString(recorditem.ModificationTimestamp, true);
            const editorTitle: string = Utils.UnescapeHTMLEntities((editor ? editor.Title : i18next.t('Misc.Unknown')));

            if ((modificationTimestamp !== timestamp || (recorditem.CreatorOID !== recorditem.EditorOID)) && !_showDeadlineTimestamp) {
                return [{
                    icon: icon,
                    innerIcon: innerIcon,
                    timestamp: timestamp,
                    title: creatorTitle
                }, {
                    icon: 'icon-pen-angled',
                    timestamp: modificationTimestamp,
                    title: editorTitle
                }];
            }

            return [{
                icon: icon,
                innerIcon: innerIcon,
                timestamp: timestamp,
                title: creatorTitle
            }];
        }
    }

    function formatTooltipLabels(tooltipItem: { raw: { OID: string } }): string {
        if (!tooltipItem || !tooltipItem.raw || !_recorditemLookup[tooltipItem.raw.OID]) {
            return '---';
        }

        let value: number | string = _recorditemLookup[tooltipItem.raw.OID].Value;

        if (!value || isNaN(Number(value))) {
            return '---';
        }

        let formatOptions = !isNaN(_parameter.Decimals) ? {
            minimumFractionDigits: _parameter.Decimals,
            maximumFractionDigits: _parameter.Decimals
        } : {};

        value = Intl.NumberFormat(moment.locale(), formatOptions).format(Number(value));

        if (_unit) {
            value += ' ' + _unit.Title;
        }

        return Utils.UnescapeHTMLEntities(String(value));
    }

    function getChartData(recorditems: Model.Recorditem[]): { x: number | Date, y: number }[] {
        if (!(recorditems || []).length) {
            return null;
        }

        return recorditems.map(function(ri: Model.Recorditem) {
            if (!ri.Value) {
                return null;
            }

            let x;
            if (_isTabularSubsampleParameter) {
                x = ri.Row;
            } else if (_showDeadlineTimestamp && ri.DeadlineTimestamp) {
                x = ri.DeadlineTimestamp;
            } else {
                x = ri.CreationTimestamp;
            }

            return {
                x,
                y: ri.Value,
                OID: ri.OID
            };
        }).filter(ri => ri != null);
    }

    function getChartAnnotations() {
        const evaluation: Model.Elements.Evaluation[] = (_parameter.Evaluation || []).filter(function(e) {
            return e.Type === _parameter.Type;
        });

        if (!(evaluation || []).length) {
            return null;
        }

        const annotations = {
            common: {
                drawTime: 'afterDatasetsDraw'
            },
            annotations: []
        };

        evaluation.forEach(function(evaluation: Model.Elements.Evaluation) {
            const category: Model.Properties.Property = DAL.Properties.GetByOID(evaluation.CategoryOID);
            const color: Model.Color = new Model.Color(category ? category.Color : '#fff');

            color.setAlpha(0.5);

            let annotation: any = {
                drawTime: 'beforeDatasetsDraw',
                borderColor: color.getRGBA(),
                backgroundColor: color.getRGBA(),
            };

            let lowerOperator: Utils.Evaluation.OperatorType,
                lowerValue: any;
            let higherOperator: Utils.Evaluation.OperatorType,
                higherValue: any;

            if (isNaN(evaluation.SecondOperator) || evaluation.FirstValue < evaluation.SecondValue) {
                lowerOperator = evaluation.FirstOperator;
                lowerValue = evaluation.FirstValue;

                if (!isNaN(evaluation.SecondOperator)) {
                    higherOperator = evaluation.SecondOperator;
                    higherValue = evaluation.SecondValue;
                }
            } else if (!isNaN(evaluation.SecondValue) && evaluation.SecondValue < evaluation.FirstValue) {
                lowerOperator = evaluation.SecondOperator;
                lowerValue = evaluation.SecondValue;
                higherOperator = evaluation.FirstOperator;
                higherValue = evaluation.FirstValue;
            }

            switch (lowerOperator) {
                case Utils.Evaluation.OperatorType.Equal:
                    annotation.type = 'line';
                    annotation.borderWidth = 2;
                    annotation.yMin = lowerValue;
                    annotation.yMax = lowerValue;
                    break;
                case Utils.Evaluation.OperatorType.Unequal:
                    annotation.borderWidth = 0;
                    break;
                case Utils.Evaluation.OperatorType.Less:
                case Utils.Evaluation.OperatorType.LessOrEqual:
                case Utils.Evaluation.OperatorType.Greater:
                case Utils.Evaluation.OperatorType.GreaterOrEqual:
                    annotation.type = 'box';
                    annotation.borderWidth = 1;

                    if (higherOperator && evaluation.Linking === Evaluation.LinkingType.Or) {
                        annotation.yMax = lowerValue;
                        annotations.annotations.push(annotation);

                        annotation = {
                            type: 'box',
                            drawTime: 'beforeDatasetsDraw',
                            borderColor: color.getRGBA(),
                            backgroundColor: color.getRGBA(),
                            borderWidth: 1
                        };

                        annotation.yMin = higherValue;
                    } else {
                        if (!higherOperator &&
                            (lowerOperator === OperatorType.Less || lowerOperator === OperatorType.LessOrEqual)) {
                            annotation.yMax = lowerValue;
                        } else {
                            annotation.yMin = lowerValue;
                        }

                        if (higherOperator) {
                            annotation.yMax = higherValue;
                        }
                    }

                    break;
            }

            annotations.annotations.push(annotation);
        });

        return annotations;
    }

    function renderWindow(): void {
        if (!(_recorditems || []).length) {
            return;
        }

        _$win = $(Templates.ParameterHistoryWindow.Window({
            Element: _parameter,
            Recorditems: _recorditems,
            ShowChart: _showChart,
            ShowFiles: Session.Settings.ShowPicturesInIssueReports,
            IsTabularSubsample: _isTabularSubsampleParameter
        }));

        _$btnHome = _$win.find('.btn-home');
        _$btnCreationTime = _$win.find('.chart-mode-list [data-timestamp="creation-time"]');
        _$btnDeadlineTime = _$win.find('.chart-mode-list [data-timestamp="deadline-time"]');
        _$timestampDropdownMenu = _$win.find('.dropdown');

        _$overlay = Utils.Overlay.Generate('olParameterHistory', 1051);

        $('body').append(_$win);

        _$win.on('hidden.bs.modal', destroy);

        Utils.RepositionModalWindow(_$win);

        _$win.find('.modal-body').css('height', _$win.find('.modal-body').css('max-height'));
        _$win.find('.modal-dialog').css({
            'max-height': '',
            'margin-top': 0,
            'margin-left': 0
        });

        _$win.modal({
            show: true,
            keyboard: false,
            backdrop: false
        });

        if (_$timestampDropdownMenu.length) {
            const dropdownWidth = _$btnCreationTime.parent().outerWidth() + 10;
            const dropdownMenuElements = [_$timestampDropdownMenu.children().first(), _$btnDeadlineTime, _$btnCreationTime];

            dropdownMenuElements.forEach(element => {
                element.css('width', dropdownWidth)
            });
            _$btnCreationTime.addClass('hidden')
            _$timestampDropdownMenu.removeClass('open');
        }

        bindEvents();
        loadImagesManually();
    }

    export function Show(oid: string, isTabularSubsampleParameter: boolean = false): Deferred {
        _parameters = Utils.InArray([Enums.View.Form, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView) ?
            ParameterList.GetElementsIndependentOfRow(oid) :
            [DAL.Elements.GetByOID(oid)];

        if ((_parameters || []).length) {
            _parameter = _parameters[0];
        } else {
            _parameter = null;
        }

        if (!_parameter) {
            return $.Deferred().reject();
        }

        _isTabularSubsampleParameter = isTabularSubsampleParameter;

        _unit = !!_parameter.UnitOID ?
            DAL.Properties.GetByOID(_parameter.UnitOID) :
            null;

        if (_isTabularSubsampleParameter) {
            _recorditems = [];
            _recorditemLookup = {};
            for (const param of _parameters) {
                let ri: Model.Recorditem = param.LastRecorditem;

                if (ri == null || ri.IsDummy === true) {
                    continue;
                }

                _recorditemLookup[ri.OID] = ri;
                _recorditems.push(ri);
            }

            _recorditems.sort(function(a: Model.Recorditem, b: Model.Recorditem) {
                return a.Row - b.Row;
            });

            renderWindow();
            renderChartTab();
        } else {
            if (!Session.IsSmartDeviceApplication) {
                return loadRecorditems()
                    .then(onAfterRecorditemsLoaded, onLoadingError)
                    .then(renderWindow)
                    .then(renderChartTab)
                    .fail(function(_response, _state, _error) {
                        throw new Model.Errors.HttpError(_error, _response);
                    });
            } else {
                return Utils.CheckIfDeviceIsOnline()
                    .then(function() {
                        loadRecorditems()
                            .then(onAfterRecorditemsLoaded, onLoadingError)
                            .then(renderWindow)
                            .then(renderChartTab)
                    }, function(_response, _state, _error) {
                        Utils.Toaster.Show(i18next.t('Misc.NoInternetConnection.MessageBody'), 2, Enums.Toaster.Icon.Warning);
                        throw new Model.Errors.HttpError(_error, _response);
                    });
            }
        }

        return $.Deferred().resolve();
    }

    export function IsVisible(): boolean {

        return _$win && _$win.css('display') !== 'none';
    }
}
