//imports-start
/// <reference path="utils.base-parameter-selection-window.ts"/>
//imports-end

module Utils.ParameterSelection {
    export class WeightParameterSelectionWindow extends BaseParameterSelectionWindow {
        constructor(options: Options) {
            super(options);
        }

        protected createListItems(): ListItemGroup[] {
            let items: ListItemGroup[] = [];

            (this.groups || [])
                .filter((element) => this.groupFilterExpression(element))
                .map((group: Model.Elements.Element) => {
                    const params = (group.Parameters || []).filter(this.paramFilterExpression);
                    const groupTitle = group.Row ? `${group.Title} (${group.Row})` : group.Title;
                    const listItemGroup = <ListItemGroup>{
                        Title: groupTitle,
                        Parameters: []
                    };

                    listItemGroup.Parameters = params.map((param: Model.Elements.Element): ListItemParameter => {
                        const identifier = group.Row ? `${param.OID}%${group.Row}` : param.OID;

                        this.parameters[identifier] = param;

                        const markupContext = {
                            Title: param.Title,
                            Type: Utils.GetElementTypeString(param.Type),
                            Identifier: identifier
                        };

                        return <ListItemParameter>{
                            Title: param.Title,
                            Type: param.Type,
                            Identifier: identifier,
                            Markup: this.parseTemplate(markupContext)
                        };
                    });

                    if (listItemGroup.Parameters.length) {
                        items.push(listItemGroup);
                    }
                });

            return items;
        }
    }
}
