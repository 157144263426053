//imports-start
/// <reference path="../../app/app.session.ts"/>
/// <reference path="../../app/app.settings.ts"/>
//imports-end

module Model {
    export class UserDeviceSettings {
        private readonly _userOID: string | null = null;
        private _searchResultPresentation?: Model.ClearableInput.PresentationType = null;

        public get UserOID() {
            return this._userOID;
        }

        public get SearchResultPresentation() {
            return this._searchResultPresentation;
        }

        public set SearchResultPresentation(presentation: Model.ClearableInput.PresentationType) {
            if (this._searchResultPresentation == presentation) {
                return;
            }

            this._searchResultPresentation = presentation;
            this.saveSettings();
        }

        constructor(userOID?: string) {
            if (userOID) {
                this._userOID = userOID;
                this.loadSettings();
            }
        }

        private loadSettings() {
            if (!localStorage && this._userOID) {
                return;
            }

            // Einstellungen aus localStorage auslesen
            const userKey = 'user-settings-' + this._userOID;
            const localUserSettings = localStorage.getItem(userKey) || '';
            const values = this.readValues(localUserSettings);

            // SearchResultPresentation
            const searchResultPresentationValue = this.getFromCloud("UDS_SearchResultPresentation") || (values.length ? values[0] : null);
            switch (searchResultPresentationValue) {
                case 'tree':
                    this._searchResultPresentation = 'tree';
                    break;
                case 'list':
                    this._searchResultPresentation = 'list';
                    break;
                default:
                    this._searchResultPresentation = null;
            }
        }

        private readValues(settingsString: string): string[] {
            const results: string[] = [];
            let lastStop = 0;

            // durch ; getrennte Werte auslesen
            for (let i = 0; i < settingsString.length; i++) {
                const ch = settingsString[i];

                if (ch === ';') {
                    let prevCharIdx = i;
                    let slashCharCnt = 0;

                    while (--prevCharIdx >= 0) {
                        if (settingsString[prevCharIdx] !== '\\') {
                            break;
                        }
                        slashCharCnt++;
                    }

                    // bei gerader Anzahl \ ist das ; als Trenner zu sehen
                    if (slashCharCnt % 2 === 0) {
                        results.push(settingsString.slice(lastStop, i));
                        lastStop = i + 1;
                    }
                } else if (ch === '\\' && i > 0 && settingsString[i - 1] === '\\') {
                    settingsString = settingsString.substring(0, i) + settingsString.substring(i + 1, settingsString.length);
                    i--;
                }
            }

            if (lastStop < settingsString.length) {
                results.push(settingsString.slice(lastStop, settingsString.length));
            }

            return results;
        }

        private saveSettings(): Deferred {
            if (!localStorage && this._userOID) {
                return;
            }

            const localUserSettings = [];

            // SearchResultPresentation
            const searchResultPresentationValue = this.writeValue(this._searchResultPresentation);
            localUserSettings.push(searchResultPresentationValue);
            this.markForUpload("UDS_SearchResultPresentation", searchResultPresentationValue);

            // Einstellungen in localStorage ablegen
            const userKey = 'user-settings-' + this._userOID;
            localStorage.setItem(userKey, localUserSettings.join(';'));

            // Einstellungen zum Server hochladen
            return this.saveToCloud();
        }

        private writeValue(value: any): string {
            let stringValue: string = null;

            switch (typeof value) {
                case 'string':
                    stringValue = value;
                    break;
                case 'number':
                case 'boolean':
                    stringValue = String(value);
                    break;
                default:
                    if (value === null) {
                        stringValue = '';
                    } else {
                        stringValue = JSON.stringify(value);
                    }
            }

            // Werte sicher schreiben
            const resultStack: string[] = [];
            let lastStop = 0;

            for (let i = 0; i < stringValue.length; i++) {
                const ch = stringValue[i];

                if (ch === ';' || ch === '\\') {
                    const str = stringValue.slice(lastStop, i);

                    resultStack.push(str);
                    resultStack.push('\\');

                    lastStop = i;
                }
            }

            if (!resultStack.length) {
                return stringValue;
            }

            const str = stringValue.slice(lastStop, stringValue.length);
            resultStack.push(str);

            return resultStack.join('');
        }

        private markForUpload(name: string, value: string): void {
            Session.Settings[name] = value;
            window.Settings.MarkChanged(name);
        }

        private saveToCloud(): Deferred {
            return window.Settings.Save();
        }

        private getFromCloud(name: string): string {
            return Session.Settings[name];
        }
    }
}
