//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Model {
    export class Serializable {
       GetJSON(): string {
           return JSON.stringify(this);
       }
   }
}