//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../templates.d.ts"  />
/// <reference path="../enums.ts"  />
/// <reference path="../utils/utils.spinner.ts"  />
/// <reference path="../utils/utils.message.ts"  />
/// <reference path="../model/selection-window/form-options.ts"  />
/// <reference path="../model/menu/imenu-item-config.ts"  />
/// <reference path="../utils/selection-window/utils.form-selection-window.ts" />
//imports-end

module Information {
    let $content: any;
    let $parameterList: any;

    let menuItemID: string;

    declare type FileList = {
        Files: Model.Files.File[],
        Images: Model.Files.File[],
        EmbeddedVideos: Model.Files.File[]
    };

    export function GetMenuItemID(): string {
        return menuItemID;
    }

    export function Show($alternativeContainer?: any, menuID?: string) {
        Utils.Spinner.Show();

        menuItemID = menuID;

        const menuItem = !!menuItemID ? Menu.GetMenuItemConfig(menuItemID) : null;
        const configuration = menuItem && menuItem.Configuration && menuItem.Configuration.Information ?
            menuItem.Configuration.Information :
            GetDefaultInformationFilters();

        setTimeout(function() {
            $content = $alternativeContainer || Utils.GetContentContainer();

            ParameterList.Create([Enums.ElementType.MasterdataGroup], function (renderedMasterData: string) {
                const element = Session.CurrentLocation;
                const measures = configuration.ShowMeasures ?
                    (element.Parametergroups || []).filter(function(group) {
                        return group.Attribute === 5;
                    }) : null;

                if (!renderedMasterData || renderedMasterData.indexOf(i18next.t('Parametergroups.NoContent')) > -1) {
                    renderedMasterData = null;
                }

                if (!$alternativeContainer) {
                    Utils.SetMode(Enums.Mode.Information);
                }

                let files: FileList;
                if ((element.Files || []).length && configuration.ShowFiles) {
                    files = getFileList(element);
                }

                if ((element.Forms || []).length) {
                    element.Forms.sort((a, b) => {
                        const formA = DAL.Elements.GetByOID(a);
                        const formB = DAL.Elements.GetByOID(b);

                        return Utils.SortByTitle(formA, formB);
                    });
                }

                if ((element.Teams || []).length) {
                    element.Teams.sort((a, b) => {
                        return Utils.SortByTitle(a, b);
                    });
                }

                let costCenter: string;
                if (configuration.ShowCostCenter) {
                    costCenter = getCostCenter(element);
                }

                checkIfUserIsOnline()
                    .then(function (isOnline: boolean) {
                        const additionalPropertyGroups = GetAdditionalPropertyGroups(
                            configuration,
                            element
                        );

                        const hasActiveInformation = getHasActiveInformation(configuration);
                        const hasVisibleInformation = hasActiveInformation ?
                            GetHasVisibleInformation(configuration, element, additionalPropertyGroups, measures, costCenter, renderedMasterData) :
                            false;

                        $content.html(Templates.Information({
                            Element: element,
                            MasterData: renderedMasterData,
                            AdditionalPropertyGroups: additionalPropertyGroups,
                            Measures: measures,
                            Images: files ? files.Images : null,
                            Files: files ? files.Files : null,
                            EmbeddedVideos: files ? files.EmbeddedVideos : null,
                            CostCenter: costCenter,
                            UserIsOnline: isOnline,
                            Configuration: configuration,
                            HasVisibleInformation: hasVisibleInformation
                        }));

                        $parameterList = $content.find('.information > .parameter-list');

                        if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Issues_CreateOrModifyIssues, true) &&
                            $content.find('.forms li').length) {
                            $content.find('.forms li').css('cursor', 'default');
                        }

                        if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.ModifyMasterdata, true) &&
                            $parameterList.find('li').length) {
                            $parameterList.find('li .value').css('cursor', 'default');
                        }

                        bindEvents();
                        Utils.Spinner.Hide();
                    });
            }, null, configuration.MasterDataConfiguration);
        }, 100);
    }

    export function GetDefaultInformationFilters(): Model.Menu.IInformationConfiguration {
        return <Model.Menu.IInformationConfiguration> {
            ShowAddress: true,
            ShowCostCenter: true,
            ShowDescription: true,
            AdditionalPropertiesConfiguration: { ShowAdditionalProperties: true },
            MasterDataConfiguration: { ShowMasterData: true },
            ShowKeywords: true,
            ShowTeams: true,
            ShowFiles: true,
            ShowInfoText: true,
            ShowForms: true,
            ShowMeasures: true
        };
    }

    function getHasActiveInformation(configuration: Model.Menu.IInformationConfiguration): boolean {
        const showAdditionalProperties = configuration.AdditionalPropertiesConfiguration && configuration.AdditionalPropertiesConfiguration.ShowAdditionalProperties;
        const showMasterData = configuration.MasterDataConfiguration && configuration.MasterDataConfiguration.ShowMasterData;

        return configuration.ShowAddress || configuration.ShowCostCenter || configuration.ShowDescription || configuration.ShowKeywords ||
            configuration.ShowTeams || configuration.ShowFiles || configuration.ShowInfoText || configuration.ShowForms || configuration.ShowMeasures ||
            showAdditionalProperties || showMasterData;
    }

    export function GetHasVisibleInformation(configuration: Model.Menu.IInformationConfiguration, element: Model.Elements.Element,
                                      additionalProperties?: Model.Elements.AdditionalProperty[], measures?: Model.Elements.Element[],
                                      costCenter?: string, renderedMasterData?: string): boolean {
        return !!((configuration.ShowAddress && element.Address) ||
            (configuration.ShowCostCenter && !!costCenter) ||
            (configuration.ShowDescription && !!element.Description) ||
            (configuration.ShowKeywords && (element.Properties || []).length) ||
            (configuration.ShowTeams && (element.Teams || []).length) ||
            (configuration.ShowFiles && (element.Files || []).length) ||
            (configuration.ShowInfoText && !!element.InfoText) ||
            (configuration.ShowForms && (element.Forms || []).length) ||
            (configuration.ShowMeasures && (measures || []).length) ||
            (configuration.MasterDataConfiguration.ShowMasterData && !!renderedMasterData) ||
            (configuration.AdditionalPropertiesConfiguration.ShowAdditionalProperties && (additionalProperties || []).length));
    }

    function getFileList(element: Model.Elements.Element): FileList {
        const result: FileList = { Files: [], Images: [], EmbeddedVideos: [] };

        for (let fCnt = 0, fLen = element.Files.length; fCnt < fLen; fCnt++) {
            const file = DAL.Files.GetByOID(element.Files[fCnt].OID);

            if (!file) {
                continue;
            }

            switch (file.Type) {
                case Enums.FileType.File:
                    if (Utils.IsImage(file.MimeType)) {
                        result.Images.push(file);
                    } else {
                        result.Files.push(file);
                    }

                    break;
                case Enums.FileType.Link:
                    result.Files.push(file);
                    break;
                case Enums.FileType.Youtube:
                case Enums.FileType.VimeoPrivate:
                case Enums.FileType.VimeoPublic:
                    result.EmbeddedVideos.push(file);
                    break;
            }
        }

        return result;
    }

    export function GetAdditionalPropertyGroups(configuration: Model.Menu.IInformationConfiguration, element: Model.Elements.Element): Model.Elements.AdditionalProperty[]|null {
        if (!(element.AdditionalProperties || []).length) {
            return null;
        }

        const groups = element
            .AdditionalProperties
            .filter(elem => elem.Type == Enums.AdditionalPropertyType.Group);

        if (!(configuration.AdditionalPropertiesConfiguration.Keywords || []).length) {
            return groups;
        }

        if (!groups.length) {
            return null;
        }

        const filteredGroups: Model.Elements.AdditionalProperty[] = [];
        const applyFilter = (configuration.AdditionalPropertiesConfiguration.Keywords || []).length > 0;
        const excludeMatchedProperties = applyFilter && configuration.AdditionalPropertiesConfiguration.ExcludeMatchedEntities;

        for (let group of groups) {
            const groupHasKeywordIntersection = (configuration.AdditionalPropertiesConfiguration.Keywords || []).length ?
                Utils.HasIntersection(group.Keywords, configuration.AdditionalPropertiesConfiguration.Keywords) :
                true;

            if (excludeMatchedProperties && groupHasKeywordIntersection) {
                continue;
            }

            var props = groupHasKeywordIntersection ? group.Children : group.Children.filter(
                child => Utils.HasIntersection(
                    child.Keywords,
                    configuration.AdditionalPropertiesConfiguration.Keywords) !== excludeMatchedProperties
            )   ;

            if (props.length === 0) {
                continue;
            }

            const adaptedGroup = Utils.Clone(group, ['Children', 'Parent']);
            adaptedGroup.Children = Utils.CloneArray(props, ['Children', 'Parent']);

            filteredGroups.push(adaptedGroup);
        }

        return filteredGroups;
    }

    function getCostCenter(element: Model.Elements.Element): string|null {
        let location = element;
        let costCenter: string;

        while (!costCenter && location) {
            if (!costCenter && !!location.CostCenter) {
                return location.CostCenter;
            }

            location = location.Parent;
        }

        return null;
    }

    function onFileClick() {
        var $this = $(this);
        var $li = $(this).parent();

        if ($this.hasClass('image')) {
            let files = $.map(Session.CurrentLocation.Files, function(locFile) {
                return DAL.Files.GetByOID(locFile.OID);
            });

            Utils.OpenFiles(files, $li.data('filename'), null, Session.CurrentLocation.Title);
        } else {
            Utils.OpenFile($li.data('filename'), false, $this.hasClass('video'));
        }
    }

    function onAdditionalPropertyImageClick() {
        Utils.OpenFile($(this).data('filename'), true, false, Session.CurrentLocation.Title);
    }

    function onFormClick(): void {
        const form = DAL.Elements.GetByOID($(this).data('formoid'));

        if (!form) {
            Utils.Message.Show(i18next.t('Forms.UnknownForm.MessageHeader'),
                i18next.t('Forms.UnknownForm.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        if (Utils.CanUserCreateIssueType(Enums.IssueType.Form, Session.CurrentLocation)) {
            const options: Model.SelectionWindow.FormOptions = <Model.SelectionWindow.FormOptions>{
                SelectedItems: [form.OID],
                ShowRegularForms: true,
                ShowInspections: Session.Client.Licenses.Inspections,
                ShowLocationAssignedFormsOnly: Session.Settings.ShowOnlyAssignedForms || !Utils.UserHasRight(Session.User.OID, Enums.Rights.SeeAllForms, true),
                OnAfterItemsSelected: (selectedIdentifiers: string[]): void => {
                    if (!selectedIdentifiers.length) {
                        return;
                    }

                    const formIdentifier = selectedIdentifiers[0];
                    const form = DAL.Elements.GetByOID(formIdentifier);

                    if (!form) {
                        return;
                    }

                    const defaultStateIdentifier = form.InitialStateOID || Session.Client.Settings.FormOpened;

                    if (!Utils.IsUserAbleToSetInitialState(Session.CurrentLocation.OID, defaultStateIdentifier)) {
                        Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                            i18next.t('Misc.RightError.DefaultState.MessageBody'),
                            {
                                Close: {}
                            });

                        return;
                    }

                    Utils.IssueViewer.CreateFormIssue(formIdentifier, (formIssue: Model.Issues.Issue) => {
                        Utils.Spinner.Show();
                        Utils.Router.PushState(`issue/${formIssue.ID || formIssue.OID}?type=${formIssue.Type}`);
                    });
                }
            }


            const formSelectionWindow = new Utils.FormSelectionWindow(options);
            formSelectionWindow.Show();
            formSelectionWindow.SetApplyButtonState();
        } else {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                {
                    Close: true
                });
        }
    }

    function onMeasureClick() {
        const oid: string = $(this).data('measure-oid');

        if (!Utils.CanUserCreateIssueType(Enums.IssueType.Scheduling, Session.CurrentLocation)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        if (!Utils.IsUserAbleToSetInitialState(Session.CurrentLocation.OID, Session.Client.Settings.TicketOpened)) {
            Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                i18next.t('Misc.RightError.DefaultState.MessageBody'),
                {
                    Close: true
                });

            return;
        }

        Utils.IssueViewer.CreateMeasure(oid, function(issue) {
            Utils.Router.PushState('issue/' + (issue.ID || issue.OID) + '?type=' + issue.Type);
        });
    }

    function onAfterRecorditemSaved(recorditem: Model.Recorditem) {
        const element = DAL.Elements.GetByOID(recorditem.ElementOID);
        const $parent = $content.find('p[data-groupoid="{0}"]'.format(element.Parent.OID));
        let $cell = $content.find('li[data-oid="{0}"]'.format(element.OID));
        let currentRecordedCount = +$parent.data('recordedparametercount');

        element.LastRecorditem = recorditem;
        element.IsRecorded = recorditem && !recorditem.IsDummy && recorditem.Type !== Enums.RecorditemType.UNRECORDED;

        if ($cell.data('isrequired') && !$cell.data('isrecorded')) {
            $parent
                .data('recordedparametercount', ++currentRecordedCount)
                .attr('data-recordedparametercount', currentRecordedCount);
            $cell
                .data('isrecorded', true)
                .attr('data-isrecorded', true);
        }

        if (+$parent.data('requiredparametercount')
            && +$parent.data('requiredparametercount') === +$parent.data('recordedparametercount')) {
            $parent
                .data('requiredparametercount', '')
                .data('recordedparametercount', '')
                .removeAttr('data-requiredparametercount')
                .removeAttr('data-recordedparametercount');
        }

        $cell = updateElementCell($cell, element);

        if (element.Parent && element.Parent.Type === Enums.ElementType.MasterdataGroup &&
            element.AdditionalSettings && element.AdditionalSettings.RequireCommentOnValueChange) {
            requestComment(recorditem)
                .then((comment: Model.Comment) => {
                    recorditem.Comments = recorditem.Comments || [];
                    recorditem.Comments.push(comment);

                    element.Recorditem = recorditem;

                    updateElementCell($cell, element);
                });
        }
    }

    function requestComment(recorditem: Model.Recorditem): Deferred {
        const deferred = $.Deferred();

        if (!recorditem) {
            return deferred.reject().promise();
        }

        Utils.InputWindow.Show(
            i18next.t('Information.ChangeComment.Window.MessageHeader'),
            i18next.t('Information.ChangeComment.Window.MessageBody'),
            {
                OK: {
                    Fn: (text) => {
                        text = $.trim(text);

                        const now = new Date();
                        const newComment = new Model.Comment(
                            uuid(),
                            now, now,
                            Session.User.OID, Session.User.OID,
                            text,
                            recorditem.OID,
                            Enums.CommentType.RecorditemChangeComment
                        );

                        newComment.Save()
                            .then((comment: Model.Comment) => deferred.resolve(comment))
                            .fail((_response, _state, _error) => {
                                throw new Model.Errors.HttpError(_error, _response);
                            });
                    }
                }
            },
            null, 'textarea', null, null, true
        );

        return deferred.promise();
    }

    function updateElementCell($cell: any, element: Model.Elements.Element): any {
        if (!($cell instanceof $) || !element) {
            return;
        }

        const $newCell = $(Templates.Parameters.Cell(element));
        $cell.replaceWith($newCell);

        // ContextMenu an neue Zelle binden
        bindContextMenu($newCell);

        return $newCell;
    }

    function preventBubbling(evt: Event) {
        evt.stopPropagation();
    }

    function modifyParameter(oid: string) {
        let element = DAL.Elements.GetByOID(oid);

        if (Utils.InArray([Enums.ElementType.Checkbox, Enums.ElementType.Info], element.Type)) {
            return;
        }

        Utils.RecorditemEditor.ShowSimplified({
            Element: element,
            PreviousRecorditem: element.LastRecorditem,
            DisableDeleteOption: true,
            DisableHistoryOption: true,
            OnAfterRecorditemSaved: onAfterRecorditemSaved
        });
    }

    /**
     * Ist für die Prüfung der Rollenrechte "Sichtbar als" und "Erfassbar als" gedacht.
     * Sind keine Rollen hinterlegt, gilt die Bedingung als erfüllt. Ansonsten muss es mindestens eine Übereinstimmung zwischen den Benutzerrollen und den hinterlegten Rollen geben.
     * @param elementIdentifier
     * @param additionalPropertyKey
     * @private
     */
    function userHasRoleRightForElement(elementIdentifier: string, additionalPropertyKey: string): boolean {
        if (!additionalPropertyKey) {
            return false;
        }

        const element = DAL.Elements.GetByOID(elementIdentifier);
        const parent = element && !!element.ParentOID ?
            DAL.Elements.GetByOID(element.ParentOID) :
            null;

        if (!element && !parent) {
            return false;
        }

        let rolesSet = element && element.AdditionalSettings && element.AdditionalSettings[additionalPropertyKey] instanceof Array ?
            (element.AdditionalSettings[additionalPropertyKey] || []) :
            [];

        if (parent) {
            if (parent &&
                parent.AdditionalSettings &&
                parent.AdditionalSettings[additionalPropertyKey] instanceof Array) {
                rolesSet = rolesSet.concat(parent.AdditionalSettings[additionalPropertyKey]);
            }
        }

        rolesSet = rolesSet.filter(DAL.Roles.Exists);

        if (!rolesSet.length) {
            return true;
        }

        const userRoles = Utils.GetUserRoles(Session.CurrentLocation.OID);

        return rolesSet.some(roleIdentifier => userRoles.indexOf(roleIdentifier) > -1);
    }

    function onMasterdataParameterClick(evt) {
        const $this = $(this);

        const userHasModificationRight = Utils.UserHasRight(
            Session.User.OID,
            Enums.Rights.ModifyMasterdata,
            true
        );

        if (!userHasModificationRight) {
            return;
        }

        evt.stopPropagation();

        const checkpointIdentifier: string = $this.data('oid');

        if (!userHasRoleRightForElement(checkpointIdentifier, 'RolesThatMayRecordValues')) {
            return;
        }

        modifyParameter(checkpointIdentifier);
    }

    function onBooleanValueClick() {
        const $this = $(this);
        const $li = $this.parents('li');
        const checkpointIdentifier = $li.data('oid');
        const element = DAL.Elements.GetByOID(checkpointIdentifier);
        const value = $this.data('value');

        const userHasModificationRight = Utils.UserHasRight(
            Session.User.OID,
            Enums.Rights.ModifyMasterdata,
            true
        );

        if (!userHasModificationRight) {
            return;
        }

        if (!userHasRoleRightForElement(checkpointIdentifier, 'RolesThatMayRecordValues')) {
            return;
        }

        Utils.Spinner.Show();

        Utils.RecorditemEditor.SaveSelectedValue({
            Element: element,
            PreviousRecorditem: element.LastRecorditem,
            Value: value,
            OnAfterRecorditemSaved: onAfterRecorditemSaved
        });
    }

    function unbindEvents() {
        // deaktiviere evtl. verbliebene toggleListView-Events aus der ParameterList
        $content.off('click.toggleListView');
    }

    function bindEvents() {
        unbindEvents();

        $content.find('.information')
            .on('click.openFile', 'div[data-filename] .file', onFileClick)
            .on('click.openAdditionalPropertyFile', '.additional-property-value', onAdditionalPropertyImageClick)
            .on('click.createForm', 'div[data-formoid]', onFormClick)
            .on('click.createMeasure', 'div[data-measure-oid]', onMeasureClick);

        $content.find('.additional-property-group-header')
            .on('click.toggleCollapsedState', onAdditionalPropertyGroupHeaderClick);

        $content.find('.parameter-list .checkpoint .parameter-description .info-button').on('click', onElementInfoButtonClick);
        $content.find('.parameter-list .groupRow .info-button').on('click', onGroupInfoButtonClick);
        $content.find('.parameter-list > p').on('click.toggleListView', onParameterGroupHeaderClick);

        $content.find('img').on('error', Utils.OnImageNotFound);
        $content.find('iframe.info-text').on('load', Utils.OnIframeLoaded);

        $parameterList
            .on('click.openPrioritizedFile', '.prioritized-file', onPrioritizedFileClick)
            .on('click.modifyMasterdata', 'li[data-oid]', onMasterdataParameterClick)
            .on('click.changeBooleanValue', '.desaturate', onBooleanValueClick);

        $parameterList.on('click.telephoneNumber', 'li[data-type="115"] a[href^="tel:"]', preventBubbling);

        // Prüfgruppe ContextMenu binden
        bindContextMenu($content.find('p[data-groupoid]'), 'groupoid');

        // Prüfpunkt ContextMenu binden
        bindContextMenu($content.find('li[data-oid]'));
    }

    function bindContextMenu($target, identifier: string = 'oid'): void {
        $target.contextMenu([{
            Title: i18next.t('Misc.ContextMenu.ShowInformation'),
            BackgroundImage: 'info-outline.svg',
            Type: 1,
            FnClick: function($element) {
                const oid = $element.data(identifier || 'oid');

                showElementInformation($element, oid);
            },
            FnDisabled: function($element) {
                return !$element.data('hasinformation');
            }
        }], $content);
    }

    function onAdditionalPropertyGroupHeaderClick(): void {
        const $this = $(this);

        $this.toggleClass('collapsed');
    }

    function onParameterGroupHeaderClick(): void {
        const $this = $(this);

        $this.toggleClass('collapsed');

        if (!$this.hasClass('collapsed') && $this.hasClass('tabular')) {
            $this.next().find('.sticky-enabled').trigger('resize');
        }
    }

    function onPrioritizedFileClick(evt: Event): void {
        const $this = $(this);
        const filename = <string>$this.data('filename');
        const identifier = <string>$this.data('identifier');
        const file = !!filename ? DAL.Files.GetByFilename(filename) : DAL.Files.GetByOID(identifier);

        evt.stopPropagation();

        if (!file) {
            return;
        }

        if (file.IsEmbeddedVideo) {
            const $li = $this.parents('li');
            const elementIdentifier = Utils.InArray([Enums.View.Form, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView) ?
                $li.data('revisionoid') :
                $li.data('oid');

            if (!!elementIdentifier) {
                Utils.ElementInformation.Show(elementIdentifier, function($win) {
                    const $video = $win.find(`.video-container[data-identifier="${file.OID}"]`);

                    if (!$video.length) {
                        return;
                    }

                    $win.find('.modal-body').scrollTop($video[0].offsetTop);
                });
            }

            return;
        }

        Utils.OpenFile(file.Filename, file.IsImage, file.IsVideo, file.Title);
    }

    function checkIfUserIsOnline(): Deferred {
        const deferred = $.Deferred();

        Utils.CheckIfDeviceIsOnline()
            .then(deferred.resolve, deferred.resolve);

        return deferred.promise();
    }

    function onElementInfoButtonClick(evt) {
        evt.stopPropagation();

        const $element = $(evt.target).parents('.checkpoint');
        const identifier = $element.data('oid');

        showElementInformation($element, identifier)
    }

    function onGroupInfoButtonClick(evt) {
        evt.stopPropagation();

        const $group = $(evt.target).parents('.groupRow');
        const oid = $group.data('groupoid');

        showElementInformation($group, oid)
    }

    function showElementInformation($element, identifier) {
        if (Utils.InArray([Enums.View.Form, Enums.View.FormBatchEdit, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            identifier = $element.data('revisionoid');
        }

        Utils.ElementInformation.Show(identifier);
    }
}