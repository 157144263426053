//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Utils.ChangeImageOrder {
    let $document = $(document);
    let OnAfterPositionChanged;
    let $container;
    let containerClass;
    let evtNamespace;
    let itemClass;
    let dragActive = false;
    let $dragSrcImage = null;
    let $dragImage;
    let $placeholder;
    let startX;
    let dragTimeOut;
    let scrollTimeOut;

    let preventMouseDown = false;
    let preventMouseDownTimeout = null;

    let touchStartEvent: string;
    let touchMoveEvent: string;
    let touchEndEvent: string;
    let mouseDownEvent: string;
    let mouseMoveEvent: string;
    let mouseUpEvent: string;
    let itemClassSelector: string;

    function onCancelDragAndDropImage() {
        dragActive = false;

        if (dragTimeOut != null) {
            clearTimeout(dragTimeOut);
        }

        if (scrollTimeOut != null) {
            clearInterval(scrollTimeOut);
            scrollTimeOut = null;
        }

        if ($dragImage != null) {
            $dragImage.remove();
        }

        if ($container != null) {
            $container
                .find('.placeholder')
                .css('width', '0');

            $container
                .find('.placeholder')
                .attr('data-placeholder', false);

            $container
                .find('li')
                .removeClass('dragging');
        }

        resetDraggedImageVisibility();

        $dragSrcImage = null;
    }

    function onImagesMouseDown(evt) {
        if (preventMouseDown) {
            return;
        }

        if (!Session.IsSmartDeviceApplication) {
            evt.preventDefault();
        }

        //removes focus of active element to prevent keyboard to open
        if (document.activeElement) {
            document.activeElement.blur();
        }

        const that = this;
        let containerBoundary = $container[0].getBoundingClientRect();

        $container.find('img').css('user-drag', 'none');
        $container.find('img').css('user-select', 'none');

        startX = evt.clientX || evt.originalEvent.touches[0].clientX;

        if (Session.IsSmartDeviceApplication) {
            $container.on(touchEndEvent, 'li', onImagesMouseUp);

            $document.on(touchEndEvent, function () {
                onCancelDragAndDropImage();

                $container.off(touchEndEvent);
            });

            $document.on(touchMoveEvent, function () {
                dragActive = false;

                if (dragTimeOut != null) {
                    clearTimeout(dragTimeOut);
                }

                $document.off(touchMoveEvent);
            });
        } else {
            $container.on(mouseUpEvent, `li, .${containerClass}`, onImagesMouseUp);

            $document.on(mouseUpEvent, function () {
                onCancelDragAndDropImage();

                $container.off(mouseUpEvent);
            });
        }

        dragTimeOut = setTimeout(function () {
            if (preventMouseDown) {
                return;
            }

            let start = 0;

            if (Session.IsSmartDeviceApplication) {
                start = evt.originalEvent.touches[0].clientX;
            } else {
                start = evt.clientX;
            }

            dragActive = true;
            $dragSrcImage = $(that);

            $dragSrcImage.find('img').css('-webkit-user-drag', 'none');

            $dragImage = $dragSrcImage.find('img').clone();

            if ($dragImage.length === 0) {
                $dragImage = $('<img src="./img/file.svg" />');
            }

            $container.append($dragImage);

            $dragSrcImage.addClass('dragging');
            $dragSrcImage.css('transition', 'all 0.5s');
            $dragSrcImage.css('width', '0');
            $dragSrcImage.css('opacity', '0');

            if ($dragImage != null) {
                $dragImage.css('position', 'absolute');
                $dragImage.css('opacity', 0.7);
                $dragImage.css('pointer-events', 'none');
                $dragImage.css('width', '170px');
                $dragImage.css('left',
                    start - containerBoundary.left - ($dragImage[0].getBoundingClientRect().width / 2) + $container[0].scrollLeft + 'px');
            }

            if (Session.IsSmartDeviceApplication) {
                $container.on(touchMoveEvent, 'li', onImagesMouseMove);
            } else {
                $container.on(mouseMoveEvent, 'li', onImagesMouseMove);
                $container.on(mouseMoveEvent, function (e) {
                    let moveCoordX = Session.IsSmartDeviceApplication
                                     ? e.originalEvent.touches[0].clientX
                                     : e.clientX;

                    if ($dragImage != null) {
                        $dragImage.css('left',
                            moveCoordX - containerBoundary.left - ($dragImage[0].getBoundingClientRect().width / 2) + $container[0].scrollLeft + 'px');
                    }
                });
            }
        }, 250);
    }

    function onImagesMouseMove(e) {
        if (!dragActive) {
            return;
        }

        e.stopPropagation();
        e.preventDefault();
        const $this = $(this);
        let images = $container[0];
        let dragImage;
        let dragImageBoundary: any = {};
        let clientX = 0;
        let clientY = 0;
        let scroll;
        const containerBoundary = images.getBoundingClientRect();
        const stepSize = 15;

        if ($dragImage != null) {
            dragImage = $dragImage[0];
            dragImageBoundary = dragImage.getBoundingClientRect();
        }

        if (Session.IsSmartDeviceApplication) {
            const touches = e.originalEvent.touches[0];
            clientX = touches.clientX;
            clientY = touches.clientY;
        } else {
            clientX = e.clientX;
            clientY = e.clientY;
        }

        let $elem = $(document.elementFromPoint(clientX, clientY));

        if (dragImage != null) {
            $dragImage.css('left',
                clientX - containerBoundary.left - (dragImageBoundary.width / 2) + images.scrollLeft + 'px');
            dragImageBoundary = dragImage.getBoundingClientRect();
        }

        const scrollImages = () => {
            if ($container) {
                $container[0].scrollLeft += scroll;
            }

            if ($dragImage != null) {
                $dragImage.css('left',
                    clientX - containerBoundary.left - (dragImageBoundary.width / 2) + images.scrollLeft + 'px');
                dragImageBoundary = dragImage.getBoundingClientRect();
            }
        };

        if (dragImageBoundary.right > containerBoundary.right) {
            scroll = stepSize;

            if (scrollTimeOut == null) {
                scrollTimeOut = setInterval(scrollImages, 30);
            }
        } else if (dragImageBoundary.left < containerBoundary.left) {
            scroll = -stepSize;

            if (scrollTimeOut == null) {
                scrollTimeOut = setInterval(scrollImages, 30);
            }
        } else {
            clearInterval(scrollTimeOut);
            scrollTimeOut = null;
        }

        if (!$elem.parents(itemClassSelector).length) {
            return;
        }

        $elem = $elem.parents(itemClassSelector);

        const imageBoundary = $elem[0].getBoundingClientRect();

        $container
            .find('.placeholder')
            .attr('data-placeholder', false);

        if ((imageBoundary.left + (imageBoundary.width / 2)) > clientX && !$this.hasClass('placeholder')) {
            $container
                .find('li')
                .eq($elem.index() - 1)
                .attr('data-placeholder', true);
        } else if (!$elem.hasClass('dragging')) {
            $container
                .find('li')
                .eq($elem.index() + 1)
                .attr('data-placeholder', true);
        }

        if ($elem.hasClass('placeholder')) {
            $elem.attr('data-placeholder', true);
        }
    }

    function onImagesMouseUp(e) {
        if (dragTimeOut != null) {
            window.clearTimeout(dragTimeOut);
        }

        preventMouseDown = true;
        preventMouseDownTimeout = window.setTimeout(() => {
            preventMouseDown = false;
            window.clearTimeout(preventMouseDownTimeout);
        }, 250);

        if (Session.IsSmartDeviceApplication) {
            $document.off(touchEndEvent);
            $document.off(touchMoveEvent);
            $container.off(touchMoveEvent);
        } else {
            $document.off(mouseUpEvent);
            $container.off(mouseMoveEvent);
        }

        dragActive = false;

        if ($dragImage != null) {
            $dragImage.remove();
        }

        let clientX = 0;
        let clientY = 0;

        if (Session.IsSmartDeviceApplication) {
            const touches = e.originalEvent.changedTouches[0];
            clientX = touches.clientX;
            clientY = touches.clientY;
        } else {
            clientX = e.clientX;
            clientY = e.clientY;
        }

        let $dropTarget = $(document.elementFromPoint(clientX, clientY));

        if (!$dropTarget.parents(itemClassSelector).length && !$dropTarget.hasClass(itemClass)) {
            $container.off(mouseUpEvent);
            $container.off(touchEndEvent);

            resetDraggedImageVisibility();

            if ($container != null) {
                $container.find('.placeholder[data-placeholdery="true"]').attr('data-placeholder', false);
            }

            return;
        }

        $dropTarget = $dropTarget.hasClass(itemClass) ? $dropTarget : $dropTarget.parents(itemClassSelector);

        if ($dragSrcImage == null) {
            onCancelDragAndDropImage();
            return;
        }

        resetDraggedImageVisibility();

        setToNewPosition($dropTarget);

        onAfterMouseUp();
    }

    function onAfterMouseUp() {
        if (scrollTimeOut != null) {
            clearInterval(scrollTimeOut);
            scrollTimeOut = null;
        }

        if (OnAfterPositionChanged instanceof Function) {
            OnAfterPositionChanged();
        }

        $dragSrcImage = null;

        $container
            .find('.placeholder')
            .css('width', '0');

        $container
            .find('.placeholder')
            .attr('data-placeholder', false);

        $container
            .find('li')
            .removeClass('dragging');
    }

    function resetDraggedImageVisibility() {
        if ($dragSrcImage == null) {
            return;
        }

        $dragSrcImage.removeClass('dragging');
        $dragSrcImage.css('opacity', '');
        $dragSrcImage.css('width', '');
        $dragSrcImage.css('transition', '');
    }

    function setToNewPosition($dropTarget) {
        $placeholder = $container
            .find('.placeholder')
            .eq(0)
            .clone(true);

        const placeholder = $placeholder.clone(true);

        if ($dropTarget.hasClass('placeholder')) {
            let $srcImageClone = $dragSrcImage.clone();

            $srcImageClone
                .insertAfter($dropTarget);

            placeholder
                .insertAfter($srcImageClone);

            $container
                .find('li')
                .eq($dragSrcImage.index() + 1)
                .remove();

            $dragSrcImage.remove();
        } else if ($dropTarget.hasClass(containerClass)) {
            $container
                .find('li')
                .eq($dragSrcImage.index() + 1)
                .remove();

            $container
                .append($dragSrcImage);

            $container
                .append(placeholder);
        } else {
            const $dropArea = $dropTarget;
            let placeholder;

            if ($dragSrcImage.index() > $dropArea.index()) {
                placeholder = $container
                    .find('.placeholder')
                    .eq(0)
                    .clone(true);

                $container
                    .find('li')
                    .eq($dragSrcImage.index() + 1)
                    .remove();

                $dragSrcImage
                    .insertBefore($dropArea);

                placeholder
                    .insertBefore($dropArea);
            } else {
                placeholder = $container
                    .find('.placeholder')
                    .eq(0)
                    .clone(true);

                $container
                    .find('li')
                    .eq($dragSrcImage.index() - 1)
                    .remove();

                $dragSrcImage
                    .insertAfter($dropArea);

                placeholder
                    .insertAfter($dropArea);
            }
        }

        //cleanup
        $container.find('.image.placeholder[data-placeholder="true"]').attr('data-placeholder', false);
    }

    export function UnBind() {
        if (touchMoveEvent) {
            $document.off(touchMoveEvent);
        }

        if (touchStartEvent) {
            $document.off(touchStartEvent);
        }

        if (touchEndEvent) {
            $document.off(touchEndEvent);
        }

        if (mouseDownEvent) {
            $document.off(mouseDownEvent);
        }

        if (mouseMoveEvent) {
            $document.off(mouseMoveEvent);
        }

        if (mouseUpEvent) {
            $document.off(mouseUpEvent);
        }

        if ($container != null) {
            if (touchMoveEvent) {
                $container.off(touchMoveEvent);
            }

            if (touchStartEvent) {
                $container.off(touchStartEvent);
            }

            if (touchEndEvent) {
                $container.off(touchEndEvent);
            }

            if (mouseDownEvent) {
                $container.off(mouseDownEvent);
            }

            if (mouseMoveEvent) {
                $container.off(mouseMoveEvent);
            }

            if (mouseUpEvent) {
                $container.off(mouseUpEvent);
            }
        }

        clearTimeout(dragTimeOut);
        dragTimeOut = null;
        clearInterval(scrollTimeOut);
        scrollTimeOut = null;
    }

    export function Init(container: any, containerClassName: string, itemClassName: string,
        onAfterPositionChanged: Function, eventNamespace: string = 'changeImageOrder') {
        $container = container;
        evtNamespace = eventNamespace;
        containerClass = containerClassName;
        itemClass = itemClassName;
        OnAfterPositionChanged = onAfterPositionChanged;

        touchStartEvent = `touchstart.${evtNamespace}`;
        touchMoveEvent = `touchmove.${evtNamespace}`;
        touchEndEvent = `touchend.${evtNamespace}`;
        mouseDownEvent = `mousedown.${evtNamespace}`;
        mouseMoveEvent = `mousemove.${evtNamespace}`;
        mouseUpEvent = `mouseup.${evtNamespace}`;
        itemClassSelector = `.${itemClass}`;

        if (Session.IsSmartDeviceApplication) {
            $container.on(touchStartEvent, 'li:not(.placeholder)', onImagesMouseDown);
        } else {
            $container.on(mouseDownEvent, 'li:not(.placeholder)', onImagesMouseDown);
        }
    }
}
