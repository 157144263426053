//imports-start
/// <reference path="./issue.ts"  />
/// <reference path="../../enums.ts"  />
//imports-end

module Model.Issues {
    export class InternalResponse {
        IssueCount: number;
        Issues: Array<RawIssue>;
        ProcessingStatus: Enums.IssueProcessingStatus;
        CssClass: string;

        SetIssueCount(count: number): InternalResponse {
            this.IssueCount = count;

            return this;
        }

        SetIssues(issues: Array<RawIssue>): InternalResponse {
            this.Issues = issues;

            return this;
        }

        SetProcessingStatus(processingStatus: Enums.IssueProcessingStatus): InternalResponse {
            this.ProcessingStatus = processingStatus;
            this.CssClass = ProcessingInformation.GetStatusCssClass(processingStatus);
            return this;
        }
    }
}
