/// <reference path="../../definitions.d.ts" />
/// <reference path="./PdfBase.ts"  />
/// <reference path="./PdfActionPlan.ts"  />

class TSLActionPlan extends PdfActionPlan {
    public static readonly TSLKeywordOID = '33e8d7b8-66d6-4c8b-b711-c3f83b919956';

    private auditorSignatureOID: string;
    private managerSignatureOID: string;

    public constructor(issue: Model.Issues.Issue, resubTree: Model.Elements.Element) {
        super(issue, resubTree);
    }

    public GetValidValues(): Array<string> {
        return ['LEICHTE ABWEICHUNG', 'SCHWERE ABWEICHUNG', 'KO'];
    }

    protected AddHeaderRow(table: PdfTable) {
        table.AddHeaderRow([
            {
                content: i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Information'),
                colSpan: 2,
                styles: { textColor: [0, 0, 0] }
            },
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Rating'),
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Remarks'),
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.CorrectiveActions'),
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Deadline'),
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.OK')
        ]);
    }

    protected CreateTableBody(table: PdfTable): void {
        const rLen = this.deviatingRecords.length;
        for (let rCnt = 0; rCnt < rLen; rCnt++) {
            const relevantRecord = this.deviatingRecords[rCnt];
            const parameter = relevantRecord.Element;
            const recorditem = relevantRecord.Recorditem;

            const structureEval = Utils.UnescapeHTMLEntities(parameter.Structure[recorditem.Value] || '');
            const comments = [];
            const workflowTexts = [];
            const workflowDeadlines = [];

            const cLen = (recorditem.Comments || []).length;
            for (let cCnt = 0; cCnt < cLen; cCnt++) {
                const comment = recorditem.Comments[cCnt];
                comments.push(Utils.UnescapeHTMLEntities(comment.Text) || '');
            }

            const wLen = (recorditem.CorrectiveActions || []).length;
            for (let wCnt = 0; wCnt < wLen; wCnt++) {
                const workflow = recorditem.CorrectiveActions[wCnt];
                let text = '';
                const title = (workflow.Title || '').trim();
                const description = (workflow.Description || '').trim();

                if (title.length) {
                    text += Utils.UnescapeHTMLEntities(title);

                    if (description.length)
                        text += '\r\n';
                }

                if (description.length) {
                    text += Utils.UnescapeHTMLEntities(description);
                }

                workflowTexts.push(text);

                const deadline = workflow.DeadlineTimestamp == null
                                 ? "nicht festgelegt"
                                 : workflow.DeadlineTimestamp instanceof Date
                                   ? workflow.DeadlineTimestamp.format('dd.mm.yyyy')
                                   : (new Date(workflow.DeadlineTimestamp)).format('dd.mm.yyyy');

                workflowDeadlines.push(deadline);
            }

            table.AddBodyRow([
                `${rCnt + 1}`,
                `${Utils.UnescapeHTMLEntities(parameter.Title || '')} ${Utils.UnescapeHTMLEntities(parameter.Description || '')}`.trim(),
                structureEval.replace(' ', '\r\n'),
                comments.join('\r\n\r\n'),
                workflowTexts.join('\r\n\r\n'),
                workflowDeadlines.join('\r\n\r\n'),
                ''
            ]);
        }
    }

    protected CreateFooterText(): void {
        const totalHeight = 60;
        const bottomPagePadding = 20;
        const margin = 10;

        if (this.currentY > this.document.internal.pageSize.getHeight() - totalHeight - bottomPagePadding) {
            this.AddPage('a4', 'landscape');
            this.PlaceLogo();
            this.CreateHeaderTable();
        }

        this.currentY += margin;
        this.document.setFontSize(9);

        const center = this.document.internal.pageSize.getWidth() / 2;
        const signatureWidth = 70;
        const signatureHeight = 35;
        const auditorImageBasePosition = center - margin - (signatureWidth / 2);
        const managerImageBasePosition = center + margin + (signatureWidth / 2);

        this.SetFontStyle('', 'normal');
        this.document.textEx(
            i18next.t('customer:TUEV.ActionPlan.Signing.TSL'),
            center, this.currentY, 'center', 'top');

        this.currentY += margin;

        const auditorImage = this.imageData[this.auditorSignatureOID];
        if (auditorImage) {
            const width = Math.min(PdfDocument.ConvertUnit('px', 'mm', auditorImage.Width), signatureWidth);
            this.document.addImage(auditorImage.Canvas, 'JPEG',
                auditorImageBasePosition - (width / 2), this.currentY,
                width, signatureHeight)
        }

        const managerImage = this.imageData[this.managerSignatureOID];
        if (managerImage) {
            const width = Math.min(PdfDocument.ConvertUnit('px', 'mm', managerImage.Width), signatureWidth);
            this.document.addImage(managerImage.Canvas, 'JPEG',
                managerImageBasePosition - (width / 2), this.currentY,
                width, signatureHeight)
        }

        this.currentY += signatureHeight + margin / 2;
        this.SetFontStyle('bold');
        this.document.textEx(i18next.t('customer:TUEV.ActionPlan.Signing.Auditor'), auditorImageBasePosition, this.currentY, 'center', 'top');
        this.document.textEx(i18next.t('customer:TUEV.ActionPlan.Signing.Manager'), managerImageBasePosition, this.currentY, 'center', 'top');
        this.SetFontStyle('', 'normal');
        this.currentY += margin;
    }

    protected OnSignatureGroupParameterFound(parameter: Model.Elements.Element, recorditem: Model.Recorditem): void {
        if (!parameter || !recorditem) {
            return;
        }

        if (parameter.Title === 'Unterschrift Auditor') {
            this.auditorSignatureOID = recorditem.Value;
        } else if (parameter.Title === 'Unterschrift Auskunftsperson') {
            this.managerSignatureOID = recorditem.Value;
        }
    }

    protected GetImageIdentifiers(): Array<string> {
        const imageOIDs = [];

        if (this.auditorSignatureOID) {
            imageOIDs.push(this.auditorSignatureOID);
        }

        if (this.managerSignatureOID) {
            imageOIDs.push(this.managerSignatureOID);
        }

        return super.GetImageIdentifiers().concat(imageOIDs);
    }
}