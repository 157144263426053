//imports-start
/// <reference path="../../dal/elements.ts"  />
/// <reference path="../../model/model.tree.ts"  />
/// <reference path="../../model/model.clearable-input.ts"  />
/// <reference path="../../model/elements/element.ts"  />
/// <reference path="../../model/scheduling/scheduling.ts"  />
/// <reference path="../../model/selection-window/tab.ts"  />
/// <reference path="../../model/selection-window/options.ts"  />
/// <reference path="../../model/selection-window/filter.ts"  />
/// <reference path="../utils.ts"  />
/// <reference path="../utils.element-picker-popup.ts"  />
/// <reference path="../utils.message.ts"  />
/// <reference path="./utils.base-selection-window.ts"  />
/// <reference path="../filter-window/utils.scheduling-filter-window.ts"  />
//imports-end

module Utils {
    export class SchedulingSelectionWindow extends BaseSelectionWindow<Model.Scheduling.Scheduling> {
        protected selectionID: string = 'scheduling-selection';
        protected keyProperty: string = 'OID';

        private readonly informationTab: Model.SelectionWindow.Tab;

        public constructor(options: Model.SelectionWindow.Options) {
            super(options);

            this.informationTab = new Model.SelectionWindow.Tab('information', 'screen-max-sm',
                i18next.t('SelectionWindow.Schedulings.Tab.Information'), this.onInformationTabClick.bind(this), false);
            this.tabs.push(this.informationTab);

            this.allowDisablingTabs = true;
        }

        public Show() {
            if (!Utils.IsSet(this.options)) {
                return false;
            }

            if (!Utils.CanUserCreateIssueType(Enums.IssueType.Scheduling, Session.CurrentLocation)) {
                Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader'),
                    i18next.t('Misc.RightError.IssueCreation.MessageBody'),
                    {
                        Close: true
                    }, null, this.options.ZIndex + 50);

                return;
            }

            super.Show();
        }

        protected prepareOptions(options: Model.SelectionWindow.Options): Model.SelectionWindow.Options {
            if (!Utils.IsSet(options.Location)) {
                options.Location = Session.CurrentLocation;
            }

            if (!Utils.IsSet(options.Title)) {
                options.Title = i18next.t('SelectionWindow.Schedulings.Title');
            }

            if (!Utils.IsSet(options.DefaultItemIdentifier)) {
                options.DefaultItemIdentifier = DAL.Scheduling.GetRoot().OID;
            }

            if (!Utils.IsSet(options.ShowLocationSelection)) {
                options.ShowLocationSelection = true;
            }

            if (!Utils.IsSet(options.ShowTreeFilter)) {
                options.ShowTreeFilter = true;
            }

            if (!Utils.IsSet(options.ZIndex)) {
                options.ZIndex = 1200;
            }

            if (!Utils.IsSet(options.TabControlCSSClass)) {
                options.TabControlCSSClass = 'screen-max-sm';
            }

            if (!Utils.IsSet(options.TreeTabTitle)) {
                options.TreeTabTitle = i18next.t('SelectionWindow.Schedulings.Tab.Tree');
            }

            if (!Utils.IsSet(options.NoItemAvailableMessageHeader)) {
                options.NoItemAvailableMessageHeader = i18next.t('SelectionWindow.Schedulings.NoSchedulingAvailable.MessageHeader');
            }

            if (!Utils.IsSet(options.NoItemAvailableMessageBody)) {
                options.NoItemAvailableMessageBody = i18next.t('SelectionWindow.Schedulings.NoSchedulingAvailable.MessageBody');
            }

            if (!Utils.IsSet(options.TreeSearchFieldPlaceholder)) {
                options.TreeSearchFieldPlaceholder = i18next.t('SearchfieldPlaceholders.SchedulingTree');
            }

            options.ExitIfNoItemIsAvailable = typeof options.ExitIfNoItemIsAvailable === 'boolean' ?
                options.ExitIfNoItemIsAvailable :
                true;

            options.ShowTabControl = typeof options.ShowTabControl === 'boolean' ?
                options.ShowTabControl :
                true;

            options.ShowDetails = typeof options.ShowDetails === 'boolean' ?
                options.ShowDetails :
                true;

            options.EnableMultiSelection = typeof options.EnableMultiSelection === 'boolean' ?
                options.EnableMultiSelection :
                false;

            options.ShowResetButton = typeof options.ShowResetButton === 'boolean' ?
                options.ShowResetButton :
                false;

            return options;
        }

        protected getAdditionalCssClasses(location: Model.Elements.Element): Dictionary<Array<string>> {
            const additionalClasses = {};

            if (!Utils.IsSet(this.visibleItems) || !Utils.IsSet(this.applicableItems)) {
                return additionalClasses;
            }

            for (const oid in this.visibleItems) {
                if (!this.visibleItems.hasOwnProperty(oid)) {
                    continue;
                }

                additionalClasses[oid] = additionalClasses[oid] || [];

                if (!this.applicableItems[oid]) {
                    additionalClasses[oid].push('grayedOut');
                    continue;
                }

                const scheduling = DAL.Scheduling.GetByOID(oid);

                if (!scheduling) {
                    continue;
                }

                if (scheduling.Elements && scheduling.Elements.length) {
                    additionalClasses[scheduling.OID].push('bold');
                }
            }

            return additionalClasses;
        }

        protected prepareItems(): void {
            this.visibleItems = {};
            this.applicableItems = {};
            this.availableItems = [];
            this.availableItemsDict = {};

            const nodeX = DAL.TreeCache.Global.getNode(this.options.Location.OID);

            if (!Utils.IsSet(nodeX)) {
                return;
            }

            const schedulings = nodeX.getTotalSchedulings().toArray();
            const len = schedulings.length;

            for (let i = 0; i < len; i++) {
                const currentOID = schedulings[i];
                const current = DAL.Scheduling.GetByOID(currentOID);

                if (!Utils.IsSet(current)) {
                    continue;
                }

                if (!this.filterScheduling(current)) {
                    continue;
                }

                this.applicableItems[currentOID] = true;
                this.visibleItems[currentOID] = true;
                this.availableItems.push(current);
                this.availableItemsDict[current[this.keyProperty]] = current;

                let parent = current;
                while (Utils.IsSet(parent = parent.Parent) && !this.visibleItems[parent.OID]) {
                    this.availableItems.push(parent);
                    this.visibleItems[parent.OID] = true;
                    this.availableItemsDict[parent[this.keyProperty]] = parent;
                }
            }
        }

        protected getDefaultTab(): Model.SelectionWindow.Tab {
            return this.informationTab;
        }

        protected getShowHierarchyFilter(): boolean {
            return false;
        }

        protected onSingleApplicableItem(): boolean {
            const needOID = Object.keys(this.applicableItems)[0];
            let applicableItem;
            for (let i = 0; i < this.availableItems.length; i++) {
                const curItem = this.availableItems[i];
                if (curItem.OID == needOID) {
                    applicableItem = curItem;
                    break;
                }
            }

            if (!applicableItem) {
                return false;
            }

            Utils.Message.Show(i18next.t('SelectionWindow.Schedulings.SingleApplicableItem.MessageHeader'),
                i18next.t('SelectionWindow.Schedulings.SingleApplicableItem.MessageBody', { ApplicableItemTitle: applicableItem.Title }),
                {
                    Yes: () => {
                        this.selectedItem = applicableItem;
                        this.onItemApplied();
                    },
                    No: true
                }, null, this.options.ZIndex + 50);

            return true;
        }

        protected onItemApplied(): void {
            const defaultStateIdentifier = this.selectedItem.StateOID || Session.Client.Settings.TicketOpened;

            if (!Utils.IsUserAbleToSetInitialState(Session.CurrentLocation.OID, defaultStateIdentifier)) {
                Utils.Message.Show(i18next.t('Misc.RightError.MessageHeader')
                    , i18next.t('Misc.RightError.DefaultState.MessageBody')
                    , {
                        Close: true
                    });

                return;
            }

            Utils.Spinner.Show();
            Utils.IssueViewer.ExecuteScheduling(this.selectedItem.OID, this.options.OnAfterIssueCreated);
        }

        protected onTreeItemFilterClick(): void {
            const filterWindow = new Utils.FilterWindow.SchedulingFilterWindow(this.options.Filter,
                this.onTreeItemFilterSaved.bind(this), this.options.ZIndex + 50);
            filterWindow.Show();
        }

        protected getTreeRootItemIdentifier(): string {
            const root = DAL.Scheduling.GetRoot();
            return Utils.IsSet(root) ? root.OID : null;
        }

        private filterScheduling(scheduling: Model.Scheduling.Scheduling): boolean {
            if (!Utils.IsSet(this.options.Filter)) {
                return true;
            }

            if (Utils.IsSet(this.options.Filter.Classifications) && this.options.Filter.Classifications.length > 0) {
                if (!Utils.IsSet(scheduling.Classifications) || scheduling.Classifications.length <= 0) {
                    return false;
                }

                if (!Utils.HasIntersection(scheduling.Classifications, this.options.Filter.Classifications)) {
                    return false;
                }
            }

            return true;
        }

        private onInformationTabClick(): void {
            const info = Utils.IsSet(this.currentlyHighlightedItem)
                ? Templates.SelectionWindow.Scheduling.Information(this.currentlyHighlightedItem)
                : Templates.SelectionWindow.NoEntrySelected();

            this.showContent($(`<div class="information">${info}</div>`));
        };
    }
}
