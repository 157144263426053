//imports-start
/// <reference path="../core/revision.ts"  />
//imports-end

module Model.Contacts {
    export class Contact extends Model.Core.Revision {
        Firstname: string;
        Lastname: string;
        Company: string;
        EmailAddress: string;
        Title: string;
        Statuses?: Array<string>;
    }
}