//imports-start
//// <reference path="../../../node_modules/cordova-plugin-file/types/index.d.ts" /> //TODO es sollte geprüft werden, wie man dies hinzufügen kann ohne das es zu Fehlermeldungen kommt.
/// <reference path="../definitions.d.ts"  />
/// <reference path="../utils/utils.cookies.ts"  />
/// <reference path="./app.settings.ts"  />
/// <reference path="../model/model.settings.ts" />
/// <reference path="../config.ts" />
/// <reference path="../utils/utils.outdated-version-window.ts" />
//imports-end

module Session {
    let outdatedVersionWindow: Utils.OutdatedVersionWindow;

    export const LatestDataModelVersion = 3;

    export let BaseURI: string = Config.BaseURI;
    export let ActiveSession: boolean = false;
    export let AuthHash: string = null;
    export let Client: any = null; // TODO Missing TS-Type
    export let User: Model.Users.User = null;
    export let IsSmartDeviceApplication: boolean = false;
    export let CurrentLocation: Model.Elements.Element = null;
    export let Mode: Enums.Mode = null;
    export let LastKnownAPIVersion: number = 1;
    export let Settings: Model.Settings = new Model.Settings();
    export let SettingsMetadata: Dictionary<Model.SettingsMetadata> = {};
    export let ViewType: boolean = null;
    export let IsRunningOnIOS: boolean;
    export let IsRunningOnAndroid: boolean;
    export let IndexedDB: any;
    export let ResourcesFolder: any; //:DirectoryEntry;
    export let NfcEnabled: boolean = false;
    export let IsAppUpdateRequired = false;
    export let IsFirstSyncFinished = false;
    export let RequiresFullUpdate = false;
    export let RequiresIssuesReload = false;
    export let LastSettingsSyncTimestamp: Date = null;
    export let LastKnownDataModelVersion: number = 1;
    export let UserDeviceSettings: Model.UserDeviceSettings = new Model.UserDeviceSettings();

    export let CookieConfig: {
        CsrfCookieName: string,
        RememberCookieName: string,
        SessionUserCookieName: string
        CsrfValidityMinutes: number
    } = Config.DefaultCookieConfig;

    export const SynchronisationInformation = new Model.Synchronisation.ResponseInformationList();

    export function SaveSystemData(withAuthenticatedUser?: boolean): Deferred {
        const data: {
            BaseURI: string,
            Client: any,
            Settings: any,
            LastLoggedInUserIdentifier: string,
            LastKnownAPIVersion: number,
            LastKnownDataModelVersion: number,
            LastSettingsSyncTimestamp: string,
            SettingsMetadata: Dictionary<Model.SettingsMetadata>,
            User?: Model.Users.User,
            IsFirstSyncFinished?: boolean,
            RequiresFullUpdate?: boolean,
            RequiresIssuesReload?: boolean,
            CookieConfig: { CsrfCookieName: string, RememberCookieName: string, SessionUserCookieName: string }
        } = {
            BaseURI: Session.BaseURI,
            Client: Session.Client,
            Settings: Session.Settings,
            LastLoggedInUserIdentifier: Session.User ? Session.User.OID : null,
            LastKnownAPIVersion: Session.LastKnownAPIVersion || 1,
            LastKnownDataModelVersion: Session.LastKnownDataModelVersion || 1,
            LastSettingsSyncTimestamp: Utils.DateTime.ToGMTString(Session.LastSettingsSyncTimestamp),
            SettingsMetadata: Session.SettingsMetadata,
            User: null,
            IsFirstSyncFinished: Session.IsFirstSyncFinished,
            RequiresFullUpdate: Session.RequiresFullUpdate,
            RequiresIssuesReload: Session.RequiresIssuesReload,
            CookieConfig: Session.CookieConfig
        };

        if (withAuthenticatedUser) {
            data.User = Session.User;

            if (data.User) {
                data.User.AuthHash = Session.AuthHash;
            }
        }

        return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.SystemData, data);
    }

    /**
    *   SmartDevice-Version der Initialisierung der Session
    */
    export function RestoreSystemData(storedAccount): void {
        Session.LastKnownAPIVersion = storedAccount.LastKnownAPIVersion || 1;
        Session.LastKnownDataModelVersion = storedAccount.LastKnownDataModelVersion || 1;
        Session.LastSettingsSyncTimestamp = storedAccount.LastSettingsSyncTimestamp ? new Date(storedAccount.LastSettingsSyncTimestamp) : null;
        Session.BaseURI = storedAccount.BaseURI;
        Session.RequiresFullUpdate = storedAccount.RequiresFullUpdate;
        Session.RequiresIssuesReload = storedAccount.RequiresIssuesReload;
        Session.SettingsMetadata = storedAccount.SettingsMetadata;

        if (storedAccount.CookieConfig) {
            Session.CookieConfig = { ...Config.DefaultCookieConfig, ...storedAccount.CookieConfig }; //Standard Config mit gespeicherten Config mergen
        }

        // mark previous settings for sync, if them did not exist before sync
        if (!Session.SettingsMetadata) {
            window.Settings.SetupMetadataAfterUpdate();
        }

        if (storedAccount.User) {
            Session.AuthHash = storedAccount.User.AuthHash;
            UserDeviceSettings = new Model.UserDeviceSettings(storedAccount.User.OID);
        }
    }

    export function CheckIfAppUpdateIsRequired(isNewLogin: boolean = false): boolean {
        if (!Session.IsSmartDeviceApplication) {
            HideOutdatedVersionWindow();
            IsAppUpdateRequired = false;
            return false;
        }

        if (!Client.Settings) {
            HideOutdatedVersionWindow();
            IsAppUpdateRequired = false;
            return false;
        }

        const minAppVersion = Session.IsRunningOnAndroid ? Client.Settings.MinAppVersionAndroid : Client.Settings.MinAppVersionIOS;

        if (!Utils.IsSet(minAppVersion) || minAppVersion === 'none') {
            HideOutdatedVersionWindow();
            IsAppUpdateRequired = false;
            return false;
        }

        const newerVersion = Utils.CompareVersion(App.GetVersionString(), minAppVersion);

        if (newerVersion !== -1) {
            HideOutdatedVersionWindow();
            IsAppUpdateRequired = false;
            return false;
        }

        //Dialog already exists
        if (IsAppUpdateRequired && Utils.IsSet(outdatedVersionWindow)) {
            outdatedVersionWindow.SetMinVersion(minAppVersion);

            if (!outdatedVersionWindow.IsVisible()) {
                outdatedVersionWindow.Show();
            }
            return false;
        }

        IsAppUpdateRequired = true;
        outdatedVersionWindow = new Utils.OutdatedVersionWindow(minAppVersion, isNewLogin);
        outdatedVersionWindow.Show();
        return true;
    }

    export function SetCookieConfig(xhr: any, saveConfig: boolean = true): Deferred {
        const apiVersion: number = parseInt(Utils.Http.GetResponseHeader(xhr, 'api-version', '1'), 10);

        Utils.SetApiVersion(apiVersion);

        if (apiVersion < 21) {
            return $.Deferred().resolve().promise();
        }

        const csrfValidity = parseInt(Utils.Http.GetResponseHeader(xhr, 'X-Csrf-Validity-Minutes', String(Session.CookieConfig.CsrfValidityMinutes)), 10);

        Session.CookieConfig = {
            CsrfCookieName: Utils.Http.GetResponseHeader(xhr, 'X-Csrf-Cookie-Name', Session.CookieConfig.CsrfCookieName),
            CsrfValidityMinutes: isNaN(csrfValidity) ? Session.CookieConfig.CsrfValidityMinutes : csrfValidity,
            RememberCookieName: Utils.Http.GetResponseHeader(xhr, 'X-Remember-Cookie-Name', Session.CookieConfig.RememberCookieName),
            SessionUserCookieName: Utils.Http.GetResponseHeader(xhr, 'X-Suid-Cookie-Name', Session.CookieConfig.SessionUserCookieName)
        };

        const deferred = $.Deferred();

        if (saveConfig) {
            if (Session.IsSmartDeviceApplication) {
                SaveSystemData(true)
                    .then(deferred.resolve, deferred.reject);
            } else {
                Utils.Cookies.Set('cookie-config', Session.CookieConfig);
                deferred.resolve();
            }
        } else {
            deferred.resolve();
        }

        return deferred.promise();
    }

    function HideOutdatedVersionWindow() {
        if (Utils.IsSet(outdatedVersionWindow)) {
            outdatedVersionWindow.Hide();
        }
    }

    /**
    *   Web-Version der Initialisierung der Session
    */
    export function Start(): Deferred {
        return Utils.CheckIfDeviceIsOnline()
            .then(function() {
                return Utils.Http.Get('accounts', null, true)
                    .done((acc) => {
                        if (acc && acc.User) {
                            UserDeviceSettings = new Model.UserDeviceSettings(acc.User.OID);
                        }
                    })
                    .fail((xhr: XMLHttpRequest) => {
                        if (!xhr) {
                            return;
                        }

                        if (xhr.status === Enums.HttpStatusCode.Forbidden) {
                            if (Utils.Message.IsVisible()) {
                                Utils.Message.Hide();
                            }

                            Utils.Message.Show(
                                i18next.t('Login.LoginFailed.MessageHeader'),
                                i18next.t('Login.LoginFailed.LoginNotAllowed'),
                                {
                                    Close: true
                                }
                            )
                        } else {
                            throw new Model.Errors.HttpError(xhr.responseText, xhr);
                        }
                    });
            }, function() {
                Utils.Message.Show(i18next.t('Synchronization.NoInternetConnection.MessageHeader'),
                    i18next.t('Misc.NoInternetConnection.MessageBody'),
                    {
                        Close: true
                    });

                return $.Deferred().reject();
            });
    }

    export function Destroy(): Deferred {
        // reset settings sync timestamp & metadata
        Session.LastSettingsSyncTimestamp = null;
        Session.SettingsMetadata = {};
        Session.Settings = new Model.Settings();
        Session.ActiveSession = false;

        let deferred = $.Deferred();

        if (IsSmartDeviceApplication) {
            // save settings without user authentication
            SaveSystemData(false)
                .then(function() {
                    User = null;
                    AuthHash = null;
                    CurrentLocation = null;
                    ViewType = null;
                    Session.CookieConfig = Config.DefaultCookieConfig;

                    cordova.plugin.http.clearCookies();
                    DAL.Clear();
                    Utils.UpdateAppBadge(0);
                    deferred.resolve();
                });
        } else {
            Utils.Http.Post('logout', {}, null, true)
                .then(null, Utils.Http.HandleLogoutError)
                .always(() => {
                    User = null;
                    AuthHash = null;
                    Client = null;
                    CurrentLocation = null;
                    ViewType = null;
                    Session.CookieConfig = Config.DefaultCookieConfig;

                    DAL.Clear();
                    deferred.resolve();
                });
        }

        return deferred.promise();
    }

    // Dies ist nur dazu da um auf Änderungen an den Model-Klassen zu reagieren beispielsweise wenn ein Attribut
    // vergessen wurde was aber schon vom Service geschickt wird.
    // Für Serverseitige Änderungen LastKnownAPIVersion verwenden
    export function CheckDataModelVersion() {
        if (LastKnownDataModelVersion >= LatestDataModelVersion) {
            return;
        }

        if (LastKnownDataModelVersion < 2) {
            // Zeitstempel auf 2010 zurücksetzen, um auch gelöschte Benutzer zu erhalten
            resetByEntityType(Enums.EntityType.USERS);
        }

        if (LastKnownDataModelVersion < 3 && LastKnownAPIVersion >= 17) {
            // Properties für ChangeStateOnlyIfAllRequiredCollected und SetStateOnlyIfAllRequiredCollected zurücksetzen
            resetByEntityType(Enums.EntityType.PROPERTIES);
        }
    }

    function resetByEntityType(type: Enums.EntityType) {
        const propertySyncInfo = SynchronisationInformation.GetByType(type);

        if (propertySyncInfo) {
            propertySyncInfo.ResponseDate = new Date(2010, 0, 1);
            SynchronisationInformation.AddInfo(propertySyncInfo);
        }
    }

    export function RefreshServerSession(force: boolean = false): Deferred {
        const deferred = $.Deferred();

        if (Session.IsSmartDeviceApplication && Session.LastKnownAPIVersion < 21) {
            return deferred.resolve().promise();
        }

        if (!force && Utils.Cookies.IsCsrfTokenValid()) {
            return deferred.resolve().promise();
        }

        return Utils.Http.Get('heartbeat', null, true)
            .then((result: string, status: string, xhr: any) => {
                Session.SetCookieConfig(xhr);
                deferred.resolve();
            }, deferred.reject);
    }
}
