//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end


module Base64Binary {

    const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

    function decode(input, arrayBuffer) {
        //get last chars to see if are valid
        let lkey1 = keyStr.indexOf(input.charAt(input.length - 1));
        let lkey2 = keyStr.indexOf(input.charAt(input.length - 2));
        let bytes = (input.length / 4) * 3;

        if (lkey1 === 64) {
            bytes--; //padding chars, so skip
        }

        if (lkey2 === 64) {
            bytes--; //padding chars, so skip
        }

        let uarray;
        let chr1, chr2, chr3;
        let enc1, enc2, enc3, enc4;
        let i = 0;
        let j = 0;

        if (arrayBuffer) {
            uarray = new Uint8Array(arrayBuffer);
        } else {
            uarray = new Uint8Array(bytes);
        }

        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');

        for (i = 0; i < bytes; i += 3) {
            //get the 3 octects in 4 ascii chars
            enc1 = keyStr.indexOf(input.charAt(j++));
            enc2 = keyStr.indexOf(input.charAt(j++));
            enc3 = keyStr.indexOf(input.charAt(j++));
            enc4 = keyStr.indexOf(input.charAt(j++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            uarray[i] = chr1;

            if (enc3 !== 64) {
                uarray[i + 1] = chr2;
            }

            if (enc4 !== 64) {
                uarray[i + 2] = chr3;
            }
        }

        return uarray;
    }

    export function decodeArrayBuffer(input) {
        let bytes = (input.length / 4) * 3;
        let ab = new ArrayBuffer(bytes);

        decode(input, ab);

        return ab;
    };
}