//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="./issues.ts"  />
/// <reference path="../model/elements/room-meta-data.ts"  />
/// <reference path="../model/elements/issue-meta-data.ts"  />
/// <reference path="../model/elements/resubmission-element-tree.ts"  />
/// <reference path="../model/elements/tree-meta-data.ts"  />
/// <reference path="../utils/utils.set.ts"  />
/// <reference path="../utils/utils.sorting.ts"  />
/// <reference path="../model/scheduling/scheduling-meta-data.ts" />
/// <reference path="../model/scheduling/scheduling-meta-data-element.ts" />
//imports-end


module DAL.Elements {
    export let List: Dictionary<Model.Elements.Element>;
    export let ListRevisions: Dictionary<Model.Elements.Element>;
    export let Root: Model.Elements.Element;
    export let FormRoot: Model.Elements.Element;
    export let UnfilteredList: Dictionary<Model.Elements.Element>;

    function prepareAdditionalProperties(properties: Array<Dictionary<any>>): Array<Dictionary<any>> {
        if (!properties || !properties.length) {
            return [];
        }

        const propertyList = {};
        const parents = [];

        for (let pCnt = 0, pLen = properties.length; pCnt < pLen; pCnt++) {
            const property = properties[pCnt];

            property.Title = Utils.EscapeHTMLEntities(property.Title);

            if (!!property.Description) {
                property.Description = Utils.EscapeHTMLEntities(property.Description);
            }

            delete property.Parent;
            delete property.Children;

            propertyList[property.OID] = property;
        }

        for (let oid in propertyList) {
            const property = propertyList[oid];

            if (!!property.ParentOID) {
                let parent = propertyList[property.ParentOID];

                if (!parent) {
                    continue;
                }

                parent.Children = parent.Children || [];
                parent.Children.push(property);
                property.Parent = parent;

                parent.Children.sort(Utils.SortByPosition);
            } else {
                parents.push(property);
                parents.sort(Utils.SortByPosition);
            }

            if (!property.hasOwnProperty('RawValue')) {
                property.RawValue = property.Value;

                const value = property.Value;

                if (property.Type !== Enums.AdditionalPropertyType.Checkbox &&
                    !property.Value) {
                    property.Value = '-/-';
                } else {
                    switch (property.Type) {
                        case Enums.AdditionalPropertyType.Checkbox:
                            if (property.Value === true) {
                                property.Value = '<img src="./img/checked.svg" width="50">';
                            } else if (property.Value === false) {
                                property.Value = '<img src="./img/crosschecked.svg" width="50">';
                            } else {
                                property.Value = '<img src="./img/unchecked.svg" width="50">';
                            }
                            break;
                        case Enums.AdditionalPropertyType.Date:
                            property.Value = Utils.DateTime.DateToString(new Date(property.Value));
                            break;
                        case Enums.AdditionalPropertyType.Time:
                            property.Value = Utils.DateTime.TimeToString(new Date(property.Value));
                            break;
                        case Enums.AdditionalPropertyType.TelephoneNumber:
                            const phoneNrEscaped = Utils.EscapeHTMLEntities(value);

                            property.Value = `<a href="tel:${Utils.FormatPhoneNumber(phoneNrEscaped)}">${phoneNrEscaped}</a>`;
                            break;
                        case Enums.AdditionalPropertyType.IndividualData:
                            const selectedEntities = typeof property.Value === 'object' ?
                                property.Value[property.SubType] || [] :
                                [];

                            const texts = $.map(selectedEntities, function(id) {
                                return IndividualData.GetEntityTitle(property.SubType, id);
                            }).filter(function(title: string) {
                                return !!title;
                            });

                            texts.sort(Utils.SortByString);

                            property.Value = texts.join('<br>') || '-/-';
                            break;
                        default:
                            property.Value = Utils.EscapeHTMLEntities(value);
                    }
                }
            }
        }

        return parents;
    }

    function prepareElement(element: Model.Elements.Element): Model.Elements.Element | null {
        if (!element) {
            return null;
        }

        if (!!element.InfoText && element.InfoText === '<br>') {
            element.InfoText = null;
        }

        if (!!element.Title) {
            element.Title = Utils.EscapeHTMLEntities(element.Title);
        }

        if (!!element.Description) {
            element.Description = Utils.EscapeHTMLEntities(element.Description)
                .replace(/&lt;br \/&gt;/gm, '<br />');
        }

        if (element.AdditionalSettings && element.AdditionalSettings.Filters != null) {
            if (!(element.AdditionalSettings.Filters.Roles || []).length) {
                delete element.AdditionalSettings.Filters.Roles;
            }

            if (!(element.AdditionalSettings.Filters.Teams || []).length) {
                delete element.AdditionalSettings.Filters.Teams;
            }

            if (!Utils.HasProperties(element.AdditionalSettings.Filters)) {
                delete element.AdditionalSettings.Filters;
            }
        }

        if ((element.AdditionalProperties || []).length) {
            element.AdditionalPropertyGroups = prepareAdditionalProperties(element.AdditionalProperties);
        }

        if ((element.Files || []).length) {
            element.Files.sort(Utils.SortAssignedFilesFromCatalogue);
        }

        if ((element.Evaluation || []).length) {
            element.Evaluation = element.Evaluation.filter(function(evaluation) {
                return evaluation.Type === element.Type;
            });
        }

        if ((element.Actions || []).length) {
            element.Actions.forEach(action => {
                if (!!action.Title) {
                    action.Title = Utils.EscapeHTMLEntities(action.Title);
                }

                if (!!action.Text) {
                    action.Text = Utils.EscapeHTMLEntities(action.Text)
                        .replace(/&lt;br \/&gt;/gm, '<br />');
                }
            });
        }

        if ((element.SuggestedValues || []).length) {
            element.SuggestedValues.sort(Utils.SortByPosition);
        }

        if (element.LastRecorditem) {
            element.LastRecorditem = Utils.PrepareRecorditem(element.LastRecorditem);
        }

        return element;
    }

    function prepareElementsObject(revisions: Array<Model.Elements.Element>): Dictionary<Model.Elements.Element> {
        if (!revisions || !revisions.length) {
            return {};
        }

        const result: Dictionary<Model.Elements.Element> = {};
        for (let eCnt = 0, eLen = revisions.length; eCnt < eLen; eCnt++) {
            result[revisions[eCnt].RevisionOID] = revisions[eCnt];
        }

        return result;
    }

    function updateModifiedElements(modifiedElements: Dictionary<any>): Deferred {
        return window.Database.GetManyByKeys(Enums.DatabaseStorage.Elements, Object.keys(modifiedElements))
            .then(function(data: Model.Elements.Element[]) {
                const updatedElements = [];
                for (let i = 0, len = data.length; i < len; i++) {
                    const element = data[i];
                    const modifiedElement = modifiedElements[element.OID];

                    if (!element || !modifiedElement) {
                        continue;
                    }

                    if (modifiedElement.LastRecorditem) {
                        element.LastRecorditem = modifiedElement.LastRecorditem;
                    }

                    updatedElements.push(element);
                }

                return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.Elements, updatedElements);
            });
    }

    function removeRelations(elements: Dictionary<Model.Elements.Element>): Dictionary<Model.Elements.Element> {
        for (let oid in elements) {
            const element = elements[oid];

            delete element.Children;
            delete element.Parametergroups;
            delete element.Parameters;
            delete element.Parent;

            if (element.Type >= 93 && element.Type <= 99) {
                element.IsCollapsed = true;
            } else {
                element.IsCollapsed = false;
            }
        }

        return elements;
    }

    function getFormRoot(elements: Dictionary<Model.Elements.Element>, rootOID: string): Model.Elements.Element | null {
        for (let oid in elements) {
            const element = elements[oid];

            if (element.Type === Enums.ElementType.Form &&
                element.ParentOID === rootOID) {
                return element;
            }
        }

        return null;
    }

    export function IsChildOfPersonalRoot(location: Model.Elements.Element): boolean {
        if (!location) {
            return false;
        }

        if (!Session.User.RootElementOID) {
            return true;
        }

        let parent = location;

        while (parent.Parent) {
            parent = parent.Parent;
        }

        let userRoot = Elements.GetByOID(Session.User.RootElementOID);

        if (!userRoot) {
            return false;
        }

        if (parent.OID === Session.User.RootElementOID) {
            return true;
        }

        return (function isChildOf(loc: Model.Elements.Element) {
            if (loc.OID === location.OID) {
                return true;
            }

            if ((loc.Children || []).length) {
                for (let cnt = 0, len = loc.Children.length; cnt < len; cnt++) {
                    const child = loc.Children[cnt];

                    if (child.Type !== Enums.ElementType.Location) {
                        continue;
                    }

                    if (isChildOf(loc.Children[cnt])) {
                        return true;
                    }
                }
            }

            return false;
        })(userRoot);
    }

    export function BuildTree(elements: Array<Model.Elements.Element>, ignoreExistingElements?: boolean, ignorableElementAttributes?: string[], cloneElements: boolean = true): Model.Elements.ResubmissionElementTree {
        /*
        * generates an element tree from element hierarchy
        */
        let elementList: Dictionary<Model.Elements.Element> = {};

        if (!ignoreExistingElements && UnfilteredList) {
            elementList = removeRelations(UnfilteredList);
        }

        if (elements && elements.length) {
            for (const rawElement of elements) {
                if (!rawElement) {
                    continue;
                }

                if (rawElement.Deleted) {
                    if (Session.CurrentLocation && Session.CurrentLocation.OID === rawElement.OID) {
                        Session.CurrentLocation.Deleted = true;
                    }

                    delete elementList[rawElement.OID];
                } else {
                    const element = prepareElement(cloneElements ? Utils.CloneObject(rawElement, ignorableElementAttributes) : rawElement);
                    elementList[element.OID] = element;

                    // update current location
                    if (Session.CurrentLocation && Session.CurrentLocation.OID === element.OID) {
                        Session.CurrentLocation = element;
                    }
                }
            }
        }

        if (!ignoreExistingElements) {
            UnfilteredList = elementList;
        }

        // Cleanup: für Benutzer nicht sichtbare Elemente entfernen
        const resultingElements = FilterBySessionUser(elementList, ignoreExistingElements);

        const listOfElements: Model.Elements.Element[] = $.map(resultingElements, (element: Model.Elements.Element) => element);
        const result = new Model.Elements.ResubmissionElementTree(listOfElements, Session.User.RootElementOID);
        result.UnfilteredElements = elementList;

        return result;
    }

    export function BuildForm(resubItems: Array<Model.Issues.ResubmissionItem>, revisionsArr: Array<Model.Elements.Element>): Model.Elements.ResubmissionElementTree {
        /*
        * generates an element tree from resubmission items
        */
        if (!resubItems || !resubItems.length) {
            return null;
        }

        let listIdx = 0;
        const revisions = prepareElementsObject(revisionsArr);
        const elementList: Model.Elements.Element[] = Array(resubItems.length);

        for (let eCnt = 0, eLen = resubItems.length; eCnt < eLen; eCnt++) {
            const resubItem = resubItems[eCnt];
            const element = prepareElement(Utils.CloneObject(revisions[resubItem.ElementRevisionOID], ['LastRecorditem']));

            if (!element) {
                continue;
            }

            if (Number(resubItem.Row)) {
                element.Row = Number(resubItem.Row);
            }

            elementList[listIdx++] = element;
        }

        return new Model.Elements.ResubmissionElementTree(elementList);
    };

    function FilterBySessionUser(elementList: Dictionary<Model.Elements.Element>, useUnfilteredList: boolean = false) {
        const userElements = new Utils.HashSet(Session.User.ElementRights.map(e => e.ElementOID));
        const userRootElement = Session.User.RootElementOID;

        // Wenn Root selber zu den Benutzerelementen gehört, keine weiteren Filter notwendig
        // weil damit alle darunterliegenden OEs zur Ansicht berechtigt sind
        if (userElements.has(userRootElement)) {
            return elementList;
        }

        if (useUnfilteredList && !UnfilteredList) {
            // Null Fehler vorbeugen
            UnfilteredList = {};
        }

        let resultingElements: Dictionary<Model.Elements.Element> = {};
        const rootPathElements: Dictionary<Model.Elements.Element> = {};

        // Benutzer Elemente und darüberliegende Elternelemente aus 'elementList' auswählen
        for (const oid of userElements.toArray()) {
            let element = elementList[oid];
            if (element) {
                resultingElements[oid] = elementList[oid];
            } else if (useUnfilteredList) {
                element = UnfilteredList[oid];
            }

            if (!element) {
                continue;
            }

            // Elemente bis zum Root finden
            let parent = element;
            while (parent && parent.OID !== userRootElement) {
                const nextParent = elementList[parent.ParentOID];

                if (nextParent) {
                    if (rootPathElements[nextParent.OID]) {
                        // weitere Suche unnötig, wenn Element bereist in der Liste vorhanden
                        break;
                    }

                    parent = nextParent;
                    rootPathElements[nextParent.OID] = nextParent;
                } else if (useUnfilteredList) {
                    parent = UnfilteredList[parent.ParentOID];
                } else {
                    parent = null;
                }
            }
        }

        // Alle notwendigen Unterelemente der Benutzerelemente anhängen
        for (const oid in elementList) {
            if (!elementList.hasOwnProperty(oid)) {
                continue
            }

            const element = elementList[oid];

            if (resultingElements[element.OID]) {
                // keine Aktion, falls Element bereits im Ergebnis vorhanden
                continue;
            }

            // Formular-Elemente und direkte Unterelemente
            // bestehender Ergebnis-Elemente pauschal immer hinzufügen
            if (element.Type === Enums.ElementType.Form ||
                resultingElements[element.ParentOID]) {
                resultingElements[oid] = element;
            } else {
                // Prüfen ob aktuelles Element einem Benutzer-Element untergeordnet ist
                let parent = element;
                let hasUserElementParent = false;
                let cachedPathElements: Model.Elements.Element[] = [element];

                while (parent && parent.OID !== userRootElement) {
                    if (resultingElements[parent.OID]) {
                        // abbrechen, wenn Eltern-Element bereits im Ergebnis existiert
                        // und alle gefunden Pfad-Elemente dem Ergebnis hinzufügen
                        hasUserElementParent = true;
                        break;
                    }

                    if (userElements.has(parent.OID)) {
                        // ist ein Benutzerelement gefunden worden,
                        // können alle gefunden Pfad-Elemente hinzugefügt werden
                        hasUserElementParent = true;
                        break;
                    }

                    const nextParent = elementList[parent.ParentOID];

                    if (nextParent) {
                        parent = nextParent;
                        cachedPathElements.push(nextParent);
                    } else if (useUnfilteredList) {
                        parent = UnfilteredList[parent.ParentOID];
                    } else {
                        parent = null;
                    }
                }

                if (hasUserElementParent && cachedPathElements.length) {
                    // alle Elemente auf dem Pfad zum Benutzer-Element hinzufügen
                    for (const tmpElem of cachedPathElements) {
                        resultingElements[tmpElem.OID] = tmpElem;
                    }
                }
            }
        }

        // Elemente über den UserElements bis zum Root anfügen
        for (const oid in rootPathElements) {
            if (rootPathElements.hasOwnProperty(oid)) {
                const element = rootPathElements[oid];
                resultingElements[oid] = element;
            }
        }

        return resultingElements;
    }

    export function AddRecorditems(recorditems: Array<Model.Recorditem>): Deferred {
        if (!Session.IsSmartDeviceApplication || !(recorditems || []).length) {
            return $.Deferred().resolve();
        }

        const modifiedElements = {};
        let triggerUpdate = false;

        for (let rCnt = 0, rLen = recorditems.length; rCnt < rLen; rCnt++) {
            const recorditem = recorditems[rCnt];

            if (!!recorditem.FollowerOID) {
                continue;
            }

            if (Elements.Exists(recorditem.ElementOID)) {
                const element = Elements.GetByOID(recorditem.ElementOID);

                if (element.Parent && element.Parent.Parent &&
                    element.Parent.Parent.Type !== Enums.ElementType.Form &&
                    Utils.CheckIfRecorditemIsNewOrUpdated(element.LastRecorditem, recorditem)) {
                    triggerUpdate = true;
                    element.LastRecorditem = recorditem;

                    modifiedElements[element.OID] = element;
                }
            }
        }

        if (triggerUpdate) {
            return updateModifiedElements(modifiedElements);
        }

        return $.Deferred().resolve();
    };

    export function Store(elements: Array<Model.Elements.Element>, cloneElements?: boolean, updateSchedulingElements: boolean = true): void {
        if (!elements || !elements.length) {
            return;
        }

        // Element Tree neu aufbauen
        const hierarchy = BuildTree(elements, false, null, cloneElements);

        Root = hierarchy.Root;
        FormRoot = hierarchy.FormRoot;
        List = !hierarchy.List ? null : hierarchy.List.reduce((acc, elem) => {
            acc[elem.OID] = elem;
            return acc;
        }, {});
        ListRevisions = hierarchy.DistinctRevisions;

        // SchedulingMetadata aktualisieren
        if (Session.IsSmartDeviceApplication && updateSchedulingElements) {
            const elementsDict = elements.reduce((acc, elem) => {
                acc[elem.OID] = elem;
                return acc;
            }, {});
            DAL.Scheduling.GenerateSchedulingMetadata(elementsDict);
        }

        // TreeCache (für Vorgänge) aus den Elementen generieren
        // nur Elemente vom Typ [Location] oder [Root] verwenden
        if (elements.some((e: Model.Elements.Element) => e.Type === Enums.ElementType.Root || e.Type === Enums.ElementType.Location)) {
            DAL.TreeCache.Global.setElementsTree(Root, (e: Model.Elements.Element) => e.Type === Enums.ElementType.Root || e.Type === Enums.ElementType.Location);
        }
    };

    export function ExtendCheckpointGroups(elements: Array<Model.Elements.Element>): void {
        Store(elements, false, false);
    };

    export function Update(element: Model.Elements.Element): void {
        element = prepareElement(element);

        AssignTeamsToElement(element);

        List[element.OID] = element;

        let parent: Model.Elements.Element;
        if ((parent = Elements.GetByOID(element.ParentOID))) {
            element.Parent = parent;
        }

        const children = $.map(List, function(elem: Model.Elements.Element) {
            if (elem.ParentOID === element.OID) {
                return elem;
            }
        });

        if (children && children.length) {
            element.Children = children;

            // Parent an den Children ersetzen
            for (const child of children) {
                if (child.Type < 90) {
                    child.Parent = element;
                }
            }
        }
    };

    export function Exists(oid: string): boolean {
        return oid && List && List[oid] != null;
    };

    export function GetAll(): Dictionary<Model.Elements.Element> {
        return List;
    };

    export function GetByOID(oid: string): Model.Elements.Element {
        return oid && List ? List[oid] : null;
    };

    export function GetByOIDs(oidArray: Array<string>): Array<Model.Elements.Element> {
        if (!List || !(oidArray || []).length) {
            return null;
        }

        const selectedOIDs: Dictionary<boolean> = {};
        const elements: Array<Model.Elements.Element> = [];

        for (let oCnt = 0, oLen = oidArray.length; oCnt < oLen; oCnt++) {
            const oid = oidArray[oCnt];
            const element = List[oid];

            if (element && !selectedOIDs[oid]) {
                elements.push(element);
                selectedOIDs[oid];
            }
        }
        return elements;
    };

    export function GetByRevisionOID(revisionOID: string): Model.Elements.Element {
        if (!revisionOID || !List || !ListRevisions) {
            return null;
        }

        return ListRevisions[revisionOID];
    };

    export function GetByRevisionOIDs(revisionOIDArray: Array<string>): Array<Model.Elements.Element> {
        if (!(revisionOIDArray || []).length || !List) {
            return [];
        }

        const result = [];
        const revOIDs = new Utils.HashSet();
        revOIDs.putRange(revisionOIDArray);

        for (let key in List) {
            const element = List[key];
            if (element && revOIDs.has(element.RevisionOID)) {
                result.push(Utils.CloneElement(element));
            }
        }
        return result;
    };

    export function GetByQRCode(qrCode: string): Model.Elements.Element {
        if (!qrCode || !List) {
            return null;
        }

        for (const key in List) {
            const element = List[key];
            if (element && (element.QRCode === qrCode || element.OID === qrCode)) {
                return element;
            }
        }
        return null;
    };

    export function GetByIdentCode(identCode: string): Model.Elements.Element {
        if (!identCode || !List) {
            return null;
        }

        for (const key in List) {
            const element = List[key];
            if (element && element.Identcode === identCode) {
                return element;
            }
        }
        return null;
    }

    export function Clear(): void {
        Root = null;
        List = null;
        ListRevisions = null;
        FormRoot = null;
    };

    export function PreparePrioritizedFiles(elements?: Array<Model.Elements.Element>): void {
        if (Session.LastKnownAPIVersion < 2) {
            return;
        }

        if (!(elements || []).length) {
            elements = [];

            for (let oid in List) {
                const element = List[oid];

                if ((element.Files || []).length) {
                    elements.push(List[oid]);
                }
            }
        }

        elements.forEach(function(element) {
            if (!(element.Files || []).length) {
                return;
            }

            element.PrioritizedFile = Utils.GetPrioritizedFile(element);
        });
    };

    export function AssignTeamsToElement(element: Model.Elements.Element): void {
        if (!element || !(element.Teams || []).length) {
            return;
        }

        if (!element.hasOwnProperty('AssignedTeams')) {
            element.AssignedTeams = $.extend(true, [], element.Teams);
        }

        for (let tCnt = 0, tLen = element.Teams.length; tCnt < tLen; tCnt++) {
            const team = DAL.Teams.GetByOID(element.Teams[tCnt].OID);
            if (typeof element.Teams[tCnt].OID === 'string' && team) {
                element.Teams[tCnt] = team;
            }
        }
    };

    export function CreateRoomMetadata(identifier: string, parentOID: string, issues: Array<any>, schedulings: Array<string>): Deferred {
        const deferred = $.Deferred();

        const meta = DAL.Issues.GetIssueMetaDataForRoom(issues);
        deferred.resolve(new Model.Elements.RoomMetadata(identifier, parentOID, meta, schedulings));

        return deferred.promise();
    }

    export function MergeRoomMetadata(baseMetadata: Model.Elements.RoomMetadata, metadataToMerge: Array<Model.Elements.RoomMetadata>): Model.Elements.RoomMetadata {
        if (!baseMetadata) {
            return;
        }

        // collect all keys
        const allKeys = new Utils.HashSet();

        allKeys.putObjectKeys(baseMetadata.Issues);

        if (metadataToMerge) {
            metadataToMerge.forEach(m => {
                allKeys.putObjectKeys(m.Issues);
            });
        }

        let newIssueMetadata: { [key: number]: Model.Elements.IssueMetadata } = null;

        // go through all keys and collect data
        const keysArray = allKeys.toArray();

        keysArray.forEach(key => {
            let identifiers = new Utils.HashSet();

            if (baseMetadata.Issues && baseMetadata.Issues[key]) {
                identifiers.putRange(baseMetadata.Issues[key].Identifiers);
            }

            let processingStatus = Enums.IssueProcessingStatus.OK;

            if (metadataToMerge) {
                metadataToMerge.forEach(m => {
                    if (m.Issues) {
                        const issueMeta: Model.Elements.IssueMetadata = m.Issues[key];

                        if (!issueMeta) {
                            return;
                        }

                        identifiers.putRange(issueMeta.Identifiers);

                        if (issueMeta.ProcessingStatus > processingStatus) {
                            processingStatus = issueMeta.ProcessingStatus;
                        }
                    }
                });
            }

            let identArray = identifiers.toArray();

            if (identArray.length) {
                newIssueMetadata = newIssueMetadata || {};
                newIssueMetadata[key] = new Model.Elements.IssueMetadata(identArray.length, identArray, processingStatus);
            }
        });

        return new Model.Elements.RoomMetadata(baseMetadata.Identifier, baseMetadata.ParentOID, newIssueMetadata, baseMetadata.Schedulings);
    }

    export function GetGroup(element: Model.Elements.Element): Model.Elements.Element
    export function GetGroup(elementOID: string): Model.Elements.Element
    export function GetGroup(elementOID: string, revisionOID: string): Model.Elements.Element
    export function GetGroup(element_OID: Model.Elements.Element | string, revisionOID?: string): Model.Elements.Element {
        if (!element_OID) {
            return null;
        }

        let element: Model.Elements.Element;
        if (typeof element_OID == 'string') {
            if (revisionOID && typeof revisionOID == 'string') {
                element = GetByRevisionOID(revisionOID);
            } else {
                element = GetByOID(element_OID);
            }
        } else {
            element = element_OID;
        }

        const parametersGroupTypes = Enums.ElementType.Parametergroups.split(',').map(type => parseInt(type, 10));

        if (Utils.InArray(parametersGroupTypes, <number>element.Type)) {
            return element;
        }

        if (element.Type === Enums.ElementType.Root ||
            element.Type === Enums.ElementType.Location) {
            return null;
        }

        return element.Parent;
    }

    export function GetLocation(element: Model.Elements.Element): Model.Elements.Element {
        if (!element) {
            return null;
        }

        if (element.Type === Enums.ElementType.Root ||
            element.Type === Enums.ElementType.Location) {
            return element;
        }

        if (!element.Parent) {
            return null;
        }

        const parametersGroupTypes = Enums.ElementType.Parametergroups.split(',').map(type => parseInt(type, 10));

        if (Utils.InArray(parametersGroupTypes, <number>element.Type)) {
            return element.Parent;
        }

        return element.Parent.Parent;
    }
}
