//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../lib/jquery.min.js"  />
/// <reference path="./files.ts" />
/// <reference path="../model/users/user.ts"  />
/// <reference path="../model/model.logger.ts"  />
/// <reference path="./tree-cache.ts"  />
/// <reference path="../utils/utils.synchronisation.upload.ts" />
//imports-end

module DAL.Sync {

    let _onSyncSuccess: Utils.Synchronisation.Download.OnSyncSuccessFunc;
    let _onSyncError: Utils.Synchronisation.Download.OnSyncErrorFunc;
    let _syncErrors: any[];
    let _isInitialSynchronisation: boolean;
    let _logger: Model.ILogger;

    function onAfterLoadingCompleted(): void {
        Utils.Spinner.Hide();

        if (_onSyncSuccess instanceof Function) {
            _onSyncSuccess();
        }
    }

    function onAfterDownloadCompleted(): void {
        if (Session.IsSmartDeviceApplication && Session.Settings.EnableSyncLog) {
            try {
                window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.SyncLog, _logger);
            }
            catch (ex) {
                console.warn('Failed to store SyncLog in DB:', ex);
            }
        }

        if (!_syncErrors && _onSyncSuccess instanceof Function) {
            _onSyncSuccess();
        }

        Utils.Spinner.Hide();
    }

    function maintainDatabase(): Deferred {
        if (!Session.IsSmartDeviceApplication) {
            return $.Deferred().resolve();
        }

        try {
            Utils.Spinner.Show();
            Utils.Spinner.Lock('DBmaintain');

            return window.Database.GetCount(Enums.DatabaseStorage.SyncEntities)
                .then((syncEntitiesCount: number) => {
                    if (syncEntitiesCount > 0) {
                        // Keine Wartung durchführen wenn unsynchronsierte Daten vorhanden
                        return;
                    }

                    return window.Database.MaintainDatabase()
                        .then(null, () => { /*abgelehnte Wartung soll Synchronisation nicht beeinflussen*/ });
                })
                .always(() => {
                    Utils.Spinner.Unlock('DBmaintain');
                    Utils.Spinner.HideWithTimeout();
                });
        }
        catch (ex) {
            Utils.Spinner.Unlock('DBmaintain');
            Utils.Spinner.HideWithTimeout();

            console.warn('Failed to maintain database:', ex);

            return $.Deferred().resolve();
        }
    }

    function onAfterUploadCompleted(onProgress?: Function): Deferred {
        if (Login.GetIsNewUser()) {
            return clearRecordingStorages()
                .then(() => {
                    return startDownload(onProgress)
                });
        } else {
            return startDownload(onProgress);
        }
    }

    function clearRecordingStorages(): Deferred {
        return clearIssueStorage()
            .then(clearCommentsStorage)
            .then(clearRecorditemStorage)
            .then(clearSyncEntitiesStorage)
    }

    function clearIssueStorage(): Deferred {
        return window.Database.ClearStorage(Enums.DatabaseStorage.Issues)
            .then(() => window.Database.ClearStorage(Enums.DatabaseStorage.ReducedIssues));
    }

    function clearRecorditemStorage(): Deferred {
        return window.Database.ClearStorage(Enums.DatabaseStorage.Recorditems);
    }

    function clearCommentsStorage(): Deferred {
        return window.Database.ClearStorage(Enums.DatabaseStorage.Comments);
    }

    function clearSyncEntitiesStorage(): Deferred {
        return window.Database.ClearStorage(Enums.DatabaseStorage.SyncEntities);
    }

    function loadElements(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.Elements') }));

        // nur für App-Start benötigte Element-Typen laden
        return window.Database.GetManyByKeys(Enums.DatabaseStorage.Elements, [Enums.ElementType.Root, Enums.ElementType.Location, Enums.ElementType.Form], 'IDX_Type')
            .then(function(elements: Model.Elements.Element[]) {
                const def = $.Deferred();
                def.notify(50);
                // enable progress notification with timeout
                setTimeout(() => { def.resolve(elements); }, 10)
                return def;
            })
            .then(function(elements: Model.Elements.Element[]) {
                DAL.Elements.Store(elements);

                // Prüfen wie viele Prüfpunkte jeweils vorhanden sind am Formular
                return (function traverse(form: Model.Elements.Element) {
                    if (!form) {
                        return $.Deferred().resolve();
                    }

                    // Prüfgruppen & Prüfpunkte des Formulars laden
                    // => Elemente die nicht Type [Form] enthalten
                    const typeFilter: Model.Database.SelectFilter = {
                        Operator: 'not',
                        'IDX_Type': [
                            Enums.ElementType.Form
                        ]
                    };

                    // Prüfpunkte für gewählte OE nachladen
                    return window.Database.GetCountWithChainSelect(Enums.DatabaseStorage.Elements, {
                        'IDX_ParentOID': form.OID,
                        typeFilter
                    }, [{
                        targetColumn: 'IDX_ParentOID',
                        filter: typeFilter,
                        name: 'param'
                    }]).then((count: { total: number, param: number }) => {
                        // Formular-Element aus DAL laden, damit evtl. geclonte Elemente korrekt aktualisiert werden
                        if (count.param > 0) {
                            const formFromDAL = DAL.Elements.GetByOID(form.OID);
                            formFromDAL.HasCheckpoints = true;
                        }

                        const subDeferreds: Deferred[] = (form.Children || []).map(traverse);

                        if (subDeferreds && subDeferreds.length) {
                            return $.when.apply($, subDeferreds);
                        }
                    });
                })(DAL.Elements.FormRoot);
            });
    }

    function loadIssues(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.Issues') }));
        const resultDeferred = $.Deferred();
        window.Database.GetAllFromStorage(Enums.DatabaseStorage.ReducedIssues)
            .then((lightWeightIssues: Array<any>) => {
                return window.Database.GetCount(Enums.DatabaseStorage.Issues)
                    .then((shouldBeCount: number) => {
                        if (lightWeightIssues.length != shouldBeCount) {
                            return fixReducedIssues(lightWeightIssues, shouldBeCount);
                        }

                        return lightWeightIssues;
                    });
            })
            .then(function(issues: Array<any>) {
                resultDeferred.notify(30);
                if (!issues.length) {
                    // generate reduced issues, if no exist
                    return window.Database.GetAllFromStorage(Enums.DatabaseStorage.Issues)
                        .then(function() {
                            resultDeferred.notify(50);
                            return this;
                        })
                        .then((issues: Model.Issues.RawIssue[]) => DAL.Issues.GenerateReducedIssues(issues, true));
                }

                return issues;
            })
            .then(function(reducedIssues: Array<any>) {
                resultDeferred.notify(70);
                DAL.Issues.Store(reducedIssues);
                resultDeferred.notify(100);
            })
            .then(resultDeferred.resolve, resultDeferred.reject);

        return resultDeferred;
    }

    function fixReducedIssues(lightWeightIssues: Array<any>, shouldBeCount: number): Deferred {
        if (lightWeightIssues.length > shouldBeCount) {
            // unnötige ReducedIssues löschen
            return window.Database.GetAllIdentifiers(Enums.DatabaseStorage.Issues)
                .then((oids: string[]) => {
                    const oidsDict = new Utils.HashSet(oids).toDictionary();
                    const reducedIssuesToDelete = [];
                    const reducedIssuesToReturn = [];

                    for (let i = 0; i < lightWeightIssues.length; i++) {
                        const issue = lightWeightIssues[i];
                        if (oidsDict[issue.OID]) {
                            reducedIssuesToReturn.push(issue);
                            continue;
                        }

                        reducedIssuesToDelete.push(issue.OID);
                    }

                    return window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.ReducedIssues, reducedIssuesToDelete)
                        .then(
                            () => reducedIssuesToReturn,
                            () => $.Deferred().resolve(reducedIssuesToReturn)
                        );
                });
        } else if (lightWeightIssues.length < shouldBeCount) {
            // ReducedIssues neu generieren
            return SyncCenter.ResetReducedIssues();
        }

        return $.Deferred().resolve(lightWeightIssues);
    }

    function loadMenuItems(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.MenuItems') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.CustomMenuItems)
            .then(function(menuItems: Model.Menu.IMenuItemConfig[]) {
                // create tree cache
                DAL.TreeCache.GenerateTreeCacheFromMenuItems(menuItems);
                // set menuItems
                Menu.GenerateMenuItems(menuItems);
            });
    }

    function loadFiles(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.Files') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.Files)
            .then(function(files: Model.Files.RawFile[]) {
                DAL.Files.Store(files);
            });
    }

    function loadUsers(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.Users') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.Persons)
            .then(function(users: Model.Users.RawUser[]) {
                DAL.Users.Store(users);
            });
    }

    function loadTeams(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.Teams') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.Teams)
            .then(function(teams: Model.Teams.RawTeam[]) {
                DAL.Teams.Store(teams);
            });
    }

    function loadRoles(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.Roles') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.Roles)
            .then(function(roles: Model.Roles.Role[]) {
                DAL.Roles.Store(roles);
            });
    }

    function loadProperties(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.Properties') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.Properties)
            .then(function(properties: Model.Properties.Property[]) {
                DAL.Properties.Store(properties);
            });
    }

    function loadScheduling(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.Scheduling') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.Scheduling)
            .then(function(scheduling: Model.Scheduling.RawScheduling[]) {
                DAL.Scheduling.Store(scheduling);
            });
    }

    function loadSchedulingElements(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.Scheduling') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.SchedulingElements)
            .then(function(schedulingElements: Model.Scheduling.Relation[]) {
                DAL.Scheduling.FillSchedulingRelations(schedulingElements);
            });
    }

    function loadContacts(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.Contacts') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.Contacts)
            .then(function(contacts: Model.Contacts.Contact[]) {
                DAL.Contacts.Store(contacts);
            });
    }

    function loadContactGroups(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.ContactGroups') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.ContactGroups)
            .then(function(contactGroups: Model.ContactGroups.ContactGroup[]) {
                DAL.ContactGroups.Store(contactGroups);
            });
    }

    function loadSchemas(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.Schemas') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.Schemas)
            .then(function(schemas: Model.IndividualData.Schema[]) {
                DAL.Schemas.Store(schemas);
            });
    }

    function loadIndividualData() {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.IndividualData') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.IndividualData)
            .then(function(individualData: any[]) {
                DAL.IndividualData.Store(individualData);
            });
    }

    export function GetSyncEntitiesCount(): Deferred {
        return window.Database.GetCount(Enums.DatabaseStorage.SyncEntities);
    }

    function assignTeamsToElements(): void {
        let elements = DAL.Elements.GetAll();

        if (elements) {
            $.map(elements, DAL.Elements.AssignTeamsToElement);
        }
    }

    function loadScanCodeInfos(): Deferred {
        Utils.Spinner.UpdateText(i18next.t('Misc.LoadingData', { DataType: i18next.t('Synchronization.EntityTypes.IndividualData') }));

        return window.Database.GetAllFromStorage(Enums.DatabaseStorage.ScanCodes)
            .then(function(scanCodeInfos: Model.Scancodes.ScancodeInfo[]) {
                DAL.ScancodeInfos.Store(scanCodeInfos);
            });
    }

    function startSynchronisation(onProgress?: Function): Deferred {
        if (Session.Settings.EnableSyncLog) {
            createLogger();
        }

        _syncErrors = null;

        return cleanupLocalData()
            .then(() => Utils.Synchronisation.Upload.Start(_logger))
            .then(null, (...err: any[]) => {
                // Fehler merken, Synchronization fortsetzen
                _syncErrors = err;
                return $.Deferred().resolve();
            })
            .then(() => onAfterUploadCompleted(onProgress))
            .always(() => {
                if (_syncErrors && _syncErrors.length && _onSyncError) {
                    _onSyncError.apply(_onSyncError, _syncErrors);
                }
            });
    }

    function cleanupLocalData(): Deferred {
        return window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, [true, 1], 'IDX_IsDeleted')
            .then((issues: Model.Issues.RawIssue[]) => {
                if (!issues || !issues.length) {
                    return $.Deferred().resolve();
                }

                // Issue SyncEntities zur Prüfung laden
                return window.Database.GetManyByKeys(Enums.DatabaseStorage.SyncEntities, [Enums.SyncEntityType.Issue], 'IDX_Type')
                    .then((syncEntities: Model.Synchronisation.IEntityDescription[]) => {
                        if (!syncEntities || !syncEntities.length) {
                            return issues;
                        }

                        // noch zu synchronisierende Vorgänge erhalten
                        const fastFilter = {};
                        for (let i = 0; i < syncEntities.length; i++) {
                            const entity = syncEntities[i];
                            fastFilter[entity.OID] = true;
                        }

                        // filtern und Vorgänge entfernen die in SyncEntities enthalten sind
                        return issues.filter((issue: Model.Issues.RawIssue) => !fastFilter[issue.OID]);
                    });
            })
            .then((issues: Model.Issues.RawIssue[]) => {
                if (!issues || !issues.length) {
                    return $.Deferred().resolve();
                }

                // gelöschte und lokal noch vorhandene Vorgänge entfernen
                const deletedIssues = issues
                    .filter((issue: Model.Issues.RawIssue) => issue.IsDeleted === true && issue.ID > 0)
                    .reduce((collector: { IDs: Dictionary<boolean>, OIDs: Dictionary<boolean> }, issue: Model.Issues.RawIssue) => {
                        collector.IDs[issue.ID] = true;
                        collector.OIDs[issue.OID] = true;
                        return collector;
                    }, { IDs: {}, OIDs: {} });

                // Offene Vorgänge aktuell in Bearbeitung schließen?
                const issueInEditA = Utils.IssueViewer.GetCurrentIssue();
                const issueInEditB = IssueView.GetCurrentIssue();
                let issueLock: Utils.Spinner.SpinnerLock;
                let refreshView = false;
                if (issueInEditA && issueInEditA.ID > 0 && deletedIssues.IDs[issueInEditA.ID]) {
                    deletedIssues.OIDs[issueInEditA.OID] = true;
                    // Ansicht anschließend aktualisieren
                    refreshView = true;
                }
                if (issueInEditB && issueInEditB.ID > 0 && deletedIssues.IDs[issueInEditB.ID]) {
                    deletedIssues.OIDs[issueInEditB.OID] = true;
                    // Ansicht anschließend aktualisieren
                    refreshView = true;
                }

                if (refreshView) {
                    Utils.Spinner.Show();
                    issueLock = Utils.Spinner.Lock("issueUpdSync");
                }

                const deletedIssuesIDs = Object.keys(deletedIssues.IDs).map(id => Number(id));
                const deletedIssuesOIDs = Object.keys(deletedIssues.OIDs);

                if (!deletedIssuesIDs.length && !deletedIssuesOIDs.length) {
                    return $.Deferred().resolve();
                }

                return window.Database.DeleteFromStorageByIndex(Enums.DatabaseStorage.Issues, 'IDX_ID', deletedIssuesIDs)
                    .then(() => window.Database.DeleteFromStorageByIndex(Enums.DatabaseStorage.ReducedIssues, 'IDX_ID', deletedIssuesIDs))
                    .then(() => window.Database.DeleteFromStorageByIndex(Enums.DatabaseStorage.Recorditems, 'IDX_IssueID', deletedIssuesIDs))
                    .then(() => window.Database.DeleteFromStorageByIndex(Enums.DatabaseStorage.Comments, 'IDX_IssueID', deletedIssuesIDs))
                    .then(() => DAL.ScancodeInfos.DeleteFromDatabaseByIssueID(deletedIssuesIDs))
                    .then(() => {
                        for (let i = 0; i < deletedIssuesOIDs.length; i++) {
                            DAL.TreeCache.Global.removeIssue(<any>{ OID: deletedIssuesOIDs[i] });
                        }
                    })
                    .always(() => {
                        if (refreshView) {
                            Utils.Router.RefreshState();

                            if (issueLock) {
                                issueLock.Unlock();
                                Utils.Spinner.Hide();
                            }
                        }
                    });
            });
    }

    function startDownload(onProgress?: Function): Deferred {
        Login.SetIsNewUser(false);
        SyncCenter.SetIsSyncing(true);

        // kein Download während Erfassung, wenn kritische Änderungen bevorstehen
        if (SyncCenter.PreventSyncDownload()) {
            return cancelSync();
        }

        let preworkDeferred: Deferred = null;

        if (SyncCenter.GetHasTreeStructureChanged()) {
            // prüfe auf unsynchronisierte Daten vor dem bereinigen
            preworkDeferred = checkAndConfirmUnsyncedDataRemove()
                .then(() => Utils.ResetResponseTimestamps(true))
                .then(Utils.Spinner.Show)
                .then(() => window.Database.ClearStoragesForCleanSync())
                .then(DAL.Clear)
                .then(() => {
                    Session.RequiresFullUpdate = false;
                    Session.RequiresIssuesReload = false;
                    Session.SaveSystemData(true);
                });
        } else if (SyncCenter.GetReloadIssuesFromServer()) {
            // prüfe auf unsynchronisierte Daten vor dem bereinigen
            preworkDeferred = checkAndConfirmUnsyncedDataRemove()
                .then(() => Utils.ResetResponseTimestamps(true, [Enums.EntityType.ISSUES]))
                .then(Utils.Spinner.Show)
                .then(() => window.Database.ClearStorage(Enums.DatabaseStorage.ReducedIssues))
                .then(() => window.Database.ClearStorage(Enums.DatabaseStorage.Issues))
                .then(DAL.Issues.Clear)
                .then(() => DAL.TreeCache.Global.Purge())
                .then(() => {
                    Session.RequiresIssuesReload = false;
                    Session.SaveSystemData(true);
                });
        } else {
            preworkDeferred = $.Deferred().resolve();
        }

        return preworkDeferred
            .then(() => {
                return Utils.Synchronisation.Download.Start(_logger, _isInitialSynchronisation)
                    .progress(onProgress)
                    .then(maintainDatabase)
                    .then(onAfterDownloadCompleted, _onSyncError);
            }, (err, r, e) => {
                if (err.status === -100) {
                    // upload neu starten
                    return startSynchronisation(onProgress);
                } if (err.status === -10) {
                    // download abbrechen
                    return cancelSync();
                } else {
                    _onSyncError(err, r, e);
                }
            });
    }

    function cancelSync(): Deferred {
        View.EnableLogoutButton();
        SyncCenter.SetIsSyncing(false);
        View.SetSyncIconClass('default');
        SyncCenter.RemoveFileDownloadInfos(true, false);
        Utils.Spinner.Hide();

        return $.Deferred().reject();
    }

    function checkAndConfirmUnsyncedDataRemove(): Deferred {
        return GetSyncEntitiesCount()
            .then((count: number) => {
                if (!count) {
                    return; // proceed
                }

                // Sicherheitsabfrage vorm Löschen
                // Meldung anhand vorhandene SyncEntities anzeigen
                const messageHeader = i18next.t("Synchronization.CleanupConfirmation.MessageHeader");
                const messageBody = Templates.WarnMessage({
                    Image: 'alert.svg',
                    Message: new Handlebars.SafeString(i18next.t("Synchronization.CleanupConfirmation.MessageBody"))
                });

                const defer = $.Deferred();
                Utils.Message.Show(messageHeader, messageBody,
                    {
                        Abort: {
                            Fn: () => { defer.reject({ status: -10 }); },
                            Classes: ['btn', 'flat', 'btn-success'],
                            OneLine: true
                        },
                        Clear: {
                            Fn: () => { defer.resolve(); },
                            Timeout: 5,
                            OneLine: true
                        },
                        OK: {
                            Fn: () => { defer.reject({ status: -100 }); },
                            Caption: i18next.t("Synchronization.CleanupConfirmation.Retry"),
                            OneLine: true
                        },
                        OnHide: () => { defer.reject({ status: -10 }); }
                    });
                return defer;
            })
    }

    function loadDataFromDatabase(): Deferred {
        const resultDeferred = $.Deferred();

        const onProgress = function(percent: number) {
            if (percent == null || isNaN(percent)) {
                return;
            }
            resultDeferred.notify(percent);
            // prevent progress propagation
            return NaN;
        };

        loadElements().then(() => onProgress(10), null, (x: number) => onProgress(x / 10))
            .then(loadMenuItems).then(() => onProgress(15))
            .then(loadFiles).then(() => onProgress(20))
            .then(loadUsers).then(() => onProgress(25))
            .then(loadTeams).then(() => onProgress(30))
            .then(loadRoles).then(() => onProgress(35))
            .then(loadProperties).then(() => onProgress(45))
            .then(loadScheduling).then(() => onProgress(50))
            .then(loadSchedulingElements).then(() => onProgress(53))
            .then(loadContacts).then(() => onProgress(55))
            .then(loadContactGroups).then(() => onProgress(60))
            .then(loadSchemas).then(() => onProgress(65))
            .then(loadIssues).then(() => onProgress(75), null, (x: number) => onProgress(65 + (x / 10)))
            .then(loadIndividualData).then(() => onProgress(80))
            .then(assignTeamsToElements).then(() => onProgress(90))
            .then(loadScanCodeInfos).then(() => onProgress(95))
            .then(resultDeferred.resolve, resultDeferred.reject);

        return resultDeferred.promise();
    }

    function createLogger(): Model.ILogger {
        if (Session.IsSmartDeviceApplication && Session.Settings.EnableSyncLog) {
            _logger = new Model.Logger();
        } else {
            _logger = new Model.NullLogger();
        }
        return _logger;
    }

    export function UploadUnsyncedData(): Deferred {
        // Verbindung prüfen
        return Utils.CheckIfDeviceIsOnline()
            .then(() => {
                // Upload unsynchronisierter Daten starten
                Utils.Spinner.Show(i18next.t('Synchronization.SyncToServer'));

                return Utils.Synchronisation.Upload.Start(new Model.NullLogger())
                    .always(() => {
                        Utils.Spinner.Hide();
                    })
                    .then(null, () => {
                        // Fehler beim Upload anzeigen
                        const closeDeferred = $.Deferred();

                        Utils.Message.Show(i18next.t('Synchronization.ErrorHeader'),
                            i18next.t('Synchronization.ErrorMessage'),
                            {
                                Close: () => closeDeferred.reject()
                            });

                        return closeDeferred;
                    });
            }, () => {
                const closeDeferred = $.Deferred();

                // Verbindung fehlt Meldung
                Utils.Message.Show(i18next.t('Synchronization.NoInternetConnection.MessageHeader'),
                    i18next.t('Misc.NoInternetConnection.MessageBody'),
                    {
                        Close: () => closeDeferred.reject()
                    });

                return closeDeferred;
            })
            .then(() => DAL.Sync.GetSyncEntitiesCount())
            .then((remainingUnsyncedCount: number) => {
                if (remainingUnsyncedCount > 0) {
                    const closeDeferred = $.Deferred();

                    // Synchronisation unvollständig
                    Utils.Message.Show(i18next.t('Synchronization.RemainingUnsyncedData.MessageHeader'),
                        i18next.t('Synchronization.RemainingUnsyncedData.MessageBody'),
                        {
                            Close: () => closeDeferred.reject()
                        });

                    return closeDeferred;
                }
            });
    }

    export function Start(
        onSyncSuccess: Utils.Synchronisation.Download.OnSyncSuccessFunc,
        onSyncError: Utils.Synchronisation.Download.OnSyncErrorFunc,
        isInitial: boolean,
        withSync: boolean,
        withoutLoadingSpinner?: boolean
    ): void {
        _onSyncSuccess = onSyncSuccess;
        _onSyncError = onSyncError;
        _isInitialSynchronisation = isInitial;

        createLogger();

        if (!Session.IsSmartDeviceApplication) {
            if (!withoutLoadingSpinner) {
                Utils.Spinner.Show(i18next.t('Login.LoadingFromServer'));
            }

            setTimeout(function() {
                Utils.Synchronisation.Download.Start(_logger, null)
                    .progress(App.Init.setProgress)
                    .then(onSyncSuccess, onSyncError);
            }, 10);
        } else {
            if (!withoutLoadingSpinner) {
                Utils.Spinner.Show(withSync ? i18next.t('Login.LoadingFromServer') : i18next.t('Login.LoadingFromDatabase'));
            }

            if (withSync) {
                startSynchronisation(App.Init.setProgress);
            } else {
                App.Init.replaceIcon(App.Init.Enums.Icon.Download, App.Init.Enums.Icon.LoadMemory, false);

                loadDataFromDatabase()
                    .progress(App.Init.setProgress)
                    .then(() => App.Init.enableLoadMemory())
                    .then(() => {
                        // check if FirstSync needs to be completed
                        if (Session.IsFirstSyncFinished) {
                            onAfterLoadingCompleted();
                            App.Init.setProgress(100);
                        } else {
                            App.Init.replaceIcon(App.Init.Enums.Icon.LoadMemory, App.Init.Enums.Icon.Download, false);

                            // TODO Fehler bei normaler synchronisation als Begrenzung zur App darstellen
                            startSynchronisation(App.Init.setProgress);
                        }
                    })
                    .fail(() => {
                        throw new Error('Failed load data from Database.');
                    });
            }
        }
    }

    export function LoadUsersAndTeams(): Deferred {
        return loadFiles()
            .then(loadUsers)
            .then(loadTeams);
    }

    export function PreloadElementCheckpoints(elementOID: string): Deferred {
        const targetElement = DAL.Elements.GetByOID(elementOID);

        if (targetElement.Type === Enums.ElementType.Root ||
            targetElement.Type === Enums.ElementType.Location) {
            if (!Utils.CanUserSeeLocation(Session.User.OID, targetElement)) {
                return $.Deferred().resolve(null);
            }
        }

        if (Session.IsSmartDeviceApplication) {
            // Filter für Prüfgruppen & Prüfpunkte der OE
            // => Elemente, welche nicht den Type [Root], [Location] oder [Form] enthalten
            const typeFilter: Model.Database.SelectFilter = {
                Operator: 'not',
                'IDX_Type': [
                    Enums.ElementType.Root,
                    Enums.ElementType.Location,
                    Enums.ElementType.Form
                ]
            };

            // prüfen ob Prüfgruppen & Prüfpunkte für gewählte OE erforderlich ist
            return $.Deferred().resolve()
                .then(() => {
                    if (!targetElement.Parametergroups) {
                        return true;
                    }

                    return window.Database.GetCountWithChainSelect(Enums.DatabaseStorage.Elements, {
                        'IDX_ParentOID': elementOID,
                        typeFilter
                    }, [{
                        targetColumn: 'IDX_ParentOID',
                        filter: typeFilter
                    }]).then((count: { total: number }) => {
                        function calculateElements(targetElement: Model.Elements.Element): number {
                            let count = 0;

                            if (!targetElement.Parametergroups || !targetElement.Parametergroups.length) {
                                return count;
                            }

                            for (const group of targetElement.Parametergroups) {
                                count++;

                                if (!group.Parameters || !group.Parameters.length) {
                                    continue;
                                }

                                for (const _param of group.Parameters) {
                                    count++;
                                }
                            }

                            return count;
                        }

                        // vergleichen ob Anzahl der Elemente in Datenbank mit der Anzahl im Cache übereinstimmt
                        const availableElements = calculateElements(targetElement);
                        return count.total !== availableElements;
                    })
                })
                .then((needUpdate: boolean) => {
                    if (!needUpdate) {
                        return targetElement;
                    }

                    // Prüfgruppen & Prüfpunkte für gewählte OE nachladen
                    return window.Database.GetWithChainSelect(Enums.DatabaseStorage.Elements, {
                        'IDX_ParentOID': elementOID,
                        typeFilter
                    }, [{
                        targetColumn: 'IDX_ParentOID',
                        filter: typeFilter
                    }]).then((elements: Model.Elements.Element[]) => {
                        if (!elements || !elements.length) {
                            return null;
                        }

                        // Beziehungen zwischen Elementen aktualisieren
                        DAL.Elements.ExtendCheckpointGroups(elements);

                        return targetElement;
                    });
                });
        } else {
            if (!targetElement || (targetElement.Parametergroups || []).length) {
                return $.Deferred().resolve(targetElement);
            }

            if (!targetElement.HasCheckpoints &&
                !targetElement.HasMasterdata &&
                !targetElement.HasMeasures) {
                // keine Daten zum nachladen vorhanden
                return $.Deferred().resolve();
            }

            Utils.Spinner.Show();

            // Element inkl. Prüfgruppen & Prüfpunkte vom Server laden
            return Utils.Http.Get(`elements/${elementOID}?withchildelements=true`)
                .then((elements: Model.Elements.Element[]) => {
                    if (!elements || !elements.length) {
                        return null;
                    }

                    // targetElement aus der Liste entfernen
                    elements = elements.filter(e => e.OID !== elementOID);

                    // Beziehungen zwischen Elementen aktualisieren
                    DAL.Elements.ExtendCheckpointGroups(elements);

                    return targetElement;
                }, (error) => {
                    if (error.status == Enums.HttpStatusCode.Not_Found ||
                        error.status == Enums.HttpStatusCode.Forbidden) {
                        return null;
                    }

                    throw new Model.Errors.HttpError(error.statusText, error);
                })
                .always(() => {
                    Utils.Spinner.HideWithTimeout();
                }, (xhr) => {
                    if (xhr.status === Enums.HttpStatusCode.Forbidden) {
                        return null;
                    }

                    return $.Deferred().reject(xhr);
                });
        }
    }

    export function PreloadElementAncestors(elementOIDs: string[]): Deferred {
        if (!elementOIDs || !elementOIDs.length) {
            return $.Deferred().resolve(null);
        }

        if (Session.IsSmartDeviceApplication) {
            return window.Database.GetManyByKeys(Enums.DatabaseStorage.Elements, elementOIDs)
                .then((elements: Model.Elements.Element[]) => {
                    const upperElements: Dictionary<boolean> = {};

                    for (let i = 0; i < elements.length; i++) {
                        const element = elements[i];
                        if (!element.ParentOID) {
                            continue;
                        }

                        upperElements[element.ParentOID] = true;
                    }

                    const upperElementOIDs = Object.keys(upperElements);

                    if (upperElementOIDs.length) {
                        return PreloadElementAncestors(upperElementOIDs)
                            .then((additionalElements: Model.Elements.Element[]) => {
                                return elements.concat(additionalElements);
                            });
                    }

                    return elements;
                });
        } else {
            Utils.Spinner.Show();

            const options = elementOIDs.map((oid: string) => {
                return {
                    OID: oid,
                    WithAncestors: true
                };
            });

            return Utils.Http.Post('elements', options)
                .always(() => {
                    Utils.Spinner.Hide();
                });
        }
    }
}
