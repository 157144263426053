//imports-start
/// <reference path="./issue-meta-data.ts"  />
//imports-end

module Model.Elements {
    export class RoomMetadata {
        Identifier: string;
        ParentOID: string;
        Issues: { [key: number]: IssueMetadata };
        Schedulings: Array<string>;

        constructor(identifier: string, parentOID?: string, issues?: { [key: number]: IssueMetadata }, schedulings?: Array<string>) {
            this.Identifier = identifier;
            this.ParentOID = parentOID;
            this.Issues = issues;
            this.Schedulings = schedulings;
        }
    }
}