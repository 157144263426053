//imports-start
/// <reference path="./scheduling-meta-data-element.ts"  />
/// <reference path="../../utils/utils.set.ts"  />
//imports-end

module Model.Scheduling {
    export class SchedulingMetadataFormElement extends SchedulingMetadataElement {
        LocationIdentifier: string;
        ExecutionCount: number = 1;
        IsParentIssueLocation: boolean = false;

        constructor(identifier: string, revisionIdentifier: string, type: Enums.ElementType, locationIdentifier: string,
            isParentIssueLocation: boolean = false, executionCount: number = 1, parentIdentifier?: string) {
            super(identifier, revisionIdentifier, type, parentIdentifier);

            this.LocationIdentifier = locationIdentifier;
            this.IsParentIssueLocation = isParentIssueLocation;

            if (executionCount > 10) {
                executionCount = 10;
            } else if (executionCount < 1) {
                executionCount = 1;
            }

            // Der übergeordnete Vorgang darf nur 1x erstellt werden.
            this.ExecutionCount = this.IsParentIssueLocation ? 1 : executionCount;
        }
    }
}
