//imports-start
/// <reference path="./definitions.d.ts"  />
/// <reference path="./utils/utils.analytics.ts"  />
/// <reference path="./utils/utils.support-mail.ts"  />
//imports-end

namespace Extensions {
    module ContextMenu {
        export interface MenuItem {
            Title: string;
            BackgroundImage: string;
            Type: number;
            FnClick?: ($element) => void;
            FnDisabled?: ($element) => boolean;
            visible?: ($parameter) => boolean;
        }

        let $menu;
        let $body = $('body');

        let cntxMenuFunctions: Dictionary<Function>;

        function setPosition(evt: MouseEvent) {
            const $doc = $(document);
            const clientX = evt.clientX;
            const clientY = evt.clientY + $doc.scrollTop();
            const contextWidth = $menu.width();
            const contextHeight = $menu.height();
            const docWidth = $doc.width();
            const docHeigth = $doc.height();

            if ((clientX + contextWidth < docWidth) &&
                (clientY + contextHeight < docHeigth)) {
                $menu.css({
                    top: clientY,
                    left: clientX
                });
            } else if ((clientX + contextWidth >= docWidth) &&
                (clientY + contextHeight < docHeigth)) {
                $menu.css({
                    top: clientY,
                    right: 0
                });
            } else if ((clientX + contextWidth < docWidth) &&
                (clientY + contextHeight >= docHeigth)) {
                $menu.css({
                    bottom: 0,
                    left: clientX
                });
            } else if ((clientX + contextWidth >= docWidth) &&
                (clientY + contextHeight >= docHeigth)) {
                $menu.css({
                    bottom: 0,
                    right: 0
                });
            } else {
                $menu.css({
                    top: '50%',
                    left: '50%',
                    'margin-left': contextWidth / 2,
                    'margin-top': contextHeight / 2
                });
            }
        }

        export function Close() {
            cntxMenuFunctions = null;

            if ($menu) {
                $menu.remove();
                $menu = null;
            }
        };

        export function Show($parent, $container, menuitems: MenuItem[], evt: MouseEvent) {
            if ($menu) {
                ContextMenu.Close();
            }

            cntxMenuFunctions = {};

            $menu = $(Templates.Menus.ContextMenu.Menu());

            $body.on('click.hideContextmenu', ContextMenu.Close);
            $body.on('contextmenu.hideContextmenu', ContextMenu.Close);

            (menuitems || []).forEach(function(item: MenuItem, idx: number) {
                const isVisible = item.visible ? item.visible($parent) : true;

                if (!isVisible) {
                    return;
                }

                if (item.Type === 1) {
                    cntxMenuFunctions[idx] = item.FnClick;

                    const isDisabled = item.FnDisabled instanceof Function ?
                        !!item.FnDisabled($parent) :
                        false;

                    const $item = $(Templates.Menus.ContextMenu.MenuItem({
                        Title: item.Title,
                        IsDisabled: isDisabled,
                        Counter: idx,
                        BackgroundImage: item.BackgroundImage
                    }));

                    if (!isDisabled) {
                        $item.on('click.e', function() {
                            const $this = $(this);
                            const cnt = parseInt($this.data('cnt'), 10);

                            if (cntxMenuFunctions[cnt]) {
                                cntxMenuFunctions[cnt]($parent);
                            }
                        });

                        $item.on('click.close', ContextMenu.Close);
                    } else {
                        $item.on('click.disabled', function(evt: Event) {
                            evt.stopPropagation();
                        });
                    }

                    $menu.find('ul')
                        .append($item);
                } else if (item.Type === 2) {
                    $menu.find('ul')
                        .append(Templates.Menus.ContextMenu.Separator());
                }
            });

            $body.append($menu);

            setPosition(evt);
        };


        (function() {
            (function($) {
                function getSelector(element) {
                    const pieces = [];

                    for (; element && element.tagName !== undefined; element = element.parentNode) {
                        if (element.className) {
                            const classes = element.className.split(' ');
                            for (let i in classes) {
                                if (classes.hasOwnProperty(i) && classes[i]) {
                                    pieces.unshift(classes[i]);
                                    pieces.unshift('.');
                                }
                            }
                        }

                        if (element.id && !/\s/.test(element.id)) {
                            pieces.unshift(element.id);
                            pieces.unshift('#');
                        }

                        pieces.unshift(element.tagName);
                        pieces.unshift(' > ');
                    }

                    return pieces.slice(1).join('');
                }

                $.fn.getSelector = function(onlyMe: boolean = false) {
                    return onlyMe ?
                        getSelector(this[0]) :
                        $.map(this, function(el) {
                            return getSelector(el);
                        });
                };

            })(window.jQuery);

            $.fn.isElementInViewport = function(threshold: number) {
                const $element = $(this);

                if (!$element.is(':visible')) {
                    return false;
                }

                const height = $(window).height();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const elemTop = $element.offset().top;
                threshold = threshold || 0;

                return (scrollTop + height + threshold) > elemTop;
            };

            $.fn.press = function(onPress: Function, onTap: Function, interval: number) {
                const $this = $(this);

                $this.hammer({ event: 'press', time: interval || 500 }).on('press', onPress);
                $this.hammer({ event: 'tap' }).on('tap', onTap);
            };

            $.fn.contextMenu = function(menuitems: MenuItem[], $container) {
                const $targets = $(this);
                const fn = function(evt: MouseEvent) {
                    ContextMenu.Show($(this), $container, menuitems, evt);
                    return false;
                };

                if (!$targets.length || !(menuitems || []).length) {
                    return;
                }

                $targets.on('contextmenu', fn);
            };

            $.fn.hasAnyClass = function() {
                const classes = arguments;

                if ((classes || []).length) {
                    for (let cCnt = 0, cLen = classes.length; cCnt < cLen; cCnt++) {
                        if (this.hasClass(classes[cCnt])) {
                            return true;
                        }
                    }
                }

                return false;
            };

            (function() {
                const getSelector = function(element) {
                    const pieces = [];
                    let classes: string[], clazz: string;
                    let hasClassOrID: boolean;

                    for (; element && element.tagName !== undefined; element = element.parentNode) {
                        hasClassOrID = false;

                        if (element.className) {
                            hasClassOrID = true;
                            classes = element.className.split(' ');

                            for (clazz in classes) {
                                if (classes.hasOwnProperty(clazz) && classes[clazz]) {
                                    pieces.unshift(classes[clazz]);
                                    pieces.unshift('.');
                                }
                            }
                        }

                        if (element.id && !/\s/.test(element.id)) {
                            hasClassOrID = true;
                            pieces.unshift(element.id);
                            pieces.unshift('#');
                        }

                        if (!hasClassOrID) {
                            pieces.unshift(element.tagName);
                        }

                        pieces.unshift(' > ');
                    }

                    return pieces.slice(1).join('');
                };

                $.fn.getSelector = function() {
                    if (this.length === 1) {
                        return getSelector(this[0]);
                    } else {
                        return $.map(this, function(element) {
                            return getSelector(element);
                        });
                    }
                };
            })();
        })();
    }

    namespace CustomEventClass {
        function CustomEvent(evt: string, params: { bubbles: boolean; cancelable: boolean; detail: any; }) {
            const customEvent = document.createEvent('CustomEvent');

            params = params || { bubbles: false, cancelable: false, detail: undefined };
            customEvent.initCustomEvent(evt, params.bubbles, params.cancelable, params.detail);

            return customEvent;
        }

        CustomEvent.prototype = Event.prototype;

        window.CustomEvent = CustomEvent;
    }

    (function() {
        if (!Math["round10"]) {
            Math["round10"] = function(value: number | (string | number)[], exp: number, type: string | number) {
                if (typeof exp === 'undefined' || +exp === 0) {
                    return Math[type](value);
                }

                value = +value;
                exp = +exp;

                if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
                    return NaN;
                }

                value = value.toString().split('e');
                value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
                value = value.toString().split('e');

                return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
            };
        }
    })();

    if (typeof window.HTMLCanvasElement !== 'undefined') {
        if (typeof window.HTMLCanvasElement.prototype.toBlob !== 'function') {
            var isBase64Regex = /\s*;\s*base64\s*(?:;|$)/i;
            var base64Ranks, decodeBase64;

            if (typeof Uint8Array != 'undefined') {
                base64Ranks = new Uint8Array([
                    62, -1, -1, -1, 63, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, -1,
                    -1, -1, 0, -1, -1, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
                    10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
                    -1, -1, -1, -1, -1, -1, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
                    36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51
                ]);

                decodeBase64 = function(base64) {
                    var len = base64.length;
                    var buffer = new Uint8Array(len / 4 * 3 | 0);
                    var i = 0;
                    var outptr = 0;
                    var last = [0, 0];
                    var state = 0;
                    var save = 0;
                    var rank, code, undef;

                    while (len--) {
                        code = base64.charCodeAt(i++);
                        rank = base64Ranks[code - 43];

                        if (rank !== 255 && rank !== undef) {
                            last[1] = last[0];
                            last[0] = code;
                            save = (save << 6) | rank;
                            state++;

                            if (state === 4) {
                                buffer[outptr++] = save >>> 16;

                                if (last[1] !== 61) { // padding character
                                    buffer[outptr++] = save >>> 8;
                                }
                                if (last[0] !== 61) { // padding character
                                    buffer[outptr++] = save;
                                }

                                state = 0;
                            }
                        }
                    }

                    return buffer;
                };
            }

            HTMLCanvasElement.prototype.toBlob = function(callback, type) {
                var args, dataURI, headerEndIdx, data, isBase64, blob;

                type = type || 'image/png';

                if (this.mozGetAsFile) {
                    callback(this.mozGetAsFile('canvas', type));
                    return;
                }

                args = Array.prototype.slice.call(arguments, 1);
                dataURI = this.toDataURL.apply(this, args);
                headerEndIdx = dataURI.indexOf(',');
                data = dataURI.substring(headerEndIdx + 1);
                isBase64 = isBase64Regex.test(dataURI.substring(0, headerEndIdx));

                if (Blob["fake"]) {
                    // no reason to decode a data: URI that's just going to become a data URI again
                    blob = new Blob();
                    blob.encoding = isBase64 ? 'base64' : 'URI';
                    blob.data = data;
                    blob.size = data.length;
                } else if (Uint8Array) {
                    blob = isBase64 ?
                        new Blob([decodeBase64(data)], { type: type }) :
                        new Blob([decodeURIComponent(data)], { type: type });

                }

                callback(blob);
            };
        }
    }

    namespace StringEntensions {
        String.prototype.contains = String.prototype.contains || function() {
            var contains = false;
            var value = this;

            if ((arguments || []).length) {
                for (var aCnt = 0, aLen = arguments.length; aCnt < aLen; aCnt++) {
                    if (value.indexOf(arguments[aCnt]) !== -1) {
                        contains = true;
                        break;
                    }
                }
            }

            return contains;
        };

        String.prototype.startsWith = String.prototype.startsWith || function(str) {
            if (!!str) {
                return this.indexOf(str) === 0;
            }

            return false;
        };

        String.prototype.endsWith = String.prototype.endsWith || function(str) {
            if (!!str) {
                return this.indexOf(str, this.length - str.length) !== -1;
            }

            return false;
        };
    }

    Object.keys = Object.keys || function(obj) {
        var keys = [];

        if (typeof obj === 'object') {
            for (var k in obj) {
                keys.push(k);
            }
        }

        return keys;
    };

    namespace DateExtensions {
        Date.prototype.addSeconds = Date.prototype.addSeconds || function(seconds) {
            this.setSeconds(this.getSeconds() + seconds);
            return this;
        };

        Date.prototype.addMinutes = Date.prototype.addMinutes || function(minutes) {
            this.setMinutes(this.getMinutes() + minutes);
            return this;
        };

        Date.prototype.addHours = Date.prototype.addHours || function(hours) {
            this.setHours(this.getHours() + hours);
            return this;
        };

        Date.prototype.addDays = Date.prototype.addDays || function(days) {
            this.setDate(this.getDate() + days);
            return this;
        };

        Date.prototype.addWeeks = Date.prototype.addWeeks || function(weeks) {
            this.addDays(weeks * 7);
            return this;
        };

        Date.prototype.addMonths = Date.prototype.addMonths || function(months) {
            var dt = this.getDate();
            var currDt;

            this.setMonth(this.getMonth() + months);
            currDt = this.getDate();

            if (dt !== currDt) {
                this.addDays(-currDt);
            }

            return this;
        };

        Date.prototype.addYears = Date.prototype.addYears || function(years) {
            var dt = this.getDate();
            var currDt;

            this.setFullYear(this.getFullYear() + years);

            currDt = this.getDate();

            if (dt !== currDt) {
                this.addDays(-currDt);
            }

            return this;
        };
    }
    namespace DOMExceptionHandling {
        if (!window.DOMException) {
            window.DOMException = (function() {
                var code_to_name = {};
                var legacy_constants = {
                    // Introduced in DOM Level 1:
                    INDEX_SIZE_ERR: 1,
                    DOMSTRING_SIZE_ERR: 2,
                    HIERARCHY_REQUEST_ERR: 3,
                    WRONG_DOCUMENT_ERR: 4,
                    INVALID_CHARACTER_ERR: 5,
                    NO_DATA_ALLOWED_ERR: 6,
                    NO_MODIFICATION_ALLOWED_ERR: 7,
                    NOT_FOUND_ERR: 8,
                    NOT_SUPPORTED_ERR: 9,
                    INUSE_ATTRIBUTE_ERR: 10,
                    // Introduced in DOM Level 2:
                    INVALID_STATE_ERR: 11,
                    SYNTAX_ERR: 12,
                    INVALID_MODIFICATION_ERR: 13,
                    NAMESPACE_ERR: 14,
                    INVALID_ACCESS_ERR: 15,
                    // Introduced in DOM Level 3:
                    VALIDATION_ERR: 16,
                    TYPE_MISMATCH_ERR: 17,
                    // From other specifications:
                    SECURITY_ERR: 18,
                    NETWORK_ERR: 19,
                    ABORT_ERR: 20,
                    URL_MISMATCH_ERR: 21,
                    QUOTA_EXCEEDED_ERR: 22,
                    TIMEOUT_ERR: 23,
                    INVALID_NODE_TYPE_ERR: 24,
                    DATA_CLONE_ERR: 25
                };

                function DOMException(legacyCode, message) {
                    var exception = create(legacyCode, message);

                    this.code = exception.code;
                    this.message = exception.message;
                }

                function create(legacyCode, message) {
                    legacyCode = Number(legacyCode);

                    if (Object.prototype.hasOwnProperty.call(funcs, legacyCode)) {
                        try {
                            funcs[legacyCode]();
                        } catch (e) {
                            if (e.code === legacyCode) {
                                e.name = e.name || code_to_name[legacyCode];
                                return e;
                            }
                        }
                    }

                    var name = Object.prototype.hasOwnProperty.call(code_to_name, legacyCode) ? code_to_name[legacyCode] : null;
                    var ex = new DOMExceptionShim(name);

                    ex.code = legacyCode;
                    ex.message = ex.name + ': DOM Exception ' + String(legacyCode);

                    if (!!message) {
                        ex.message += ' - ' + message;
                    }

                    return ex;
                }

                Object.keys(legacy_constants).forEach(function(n) {
                    code_to_name[legacy_constants[n]] = n;
                    DOMException[n] = legacy_constants[n];
                });

                var funcs = (function() {
                    var funcs = [], w = window, d = document, im = d.implementation, de = <any>DOMException;

                    funcs[de.INDEX_SIZE_ERR] = function() { d.createTextNode('').splitText(1); };

                    funcs[de.HIERARCHY_REQUEST_ERR] = function() { d.appendChild(d); };
                    funcs[de.WRONG_DOCUMENT_ERR] = function() { var dt = im.createDocumentType('x', null, null); im.createDocument('', '', dt); im.createDocument('', '', dt); };
                    funcs[de.INVALID_CHARACTER_ERR] = function() { document.createElement('!'); };

                    funcs[de.NOT_FOUND_ERR] = function() { d.removeChild(null); };
                    funcs[de.NOT_SUPPORTED_ERR] = function() { d.createCDATASection(null); };
                    funcs[de.INUSE_ATTRIBUTE_ERR] = function() { var a = d.createElement('a'), n = d.createAttribute('n'); a.setAttributeNode(n); d.createElement('b').setAttributeNode(n); };
                    funcs[de.INVALID_STATE_ERR] = function() { var r = d.createRange(); r.detach(); r.detach(); };
                    funcs[de.SYNTAX_ERR] = function() { d.createElement('a').contentEditable = 'bogus'; };

                    funcs[de.NAMESPACE_ERR] = function() { d.createAttributeNS('', 'a:b'); };

                    funcs[de.INVALID_NODE_TYPE_ERR] = function() { d.createRange().selectNode(d.createElement('a')); };
                    funcs[de.DATA_CLONE_ERR] = function() { w.postMessage(w, null); };

                    return funcs;
                }());

                var DOMExceptionShim = (function() {
                    function DOMException(name) {
                        this.name = name || 'UNKNOWN_ERR';
                    }

                    DOMException.prototype = new window.DOMException();

                    return DOMException;
                }());

                return DOMException;
            }());
        }
    }

    export function ScrollElementInView(element: HTMLElement, container: HTMLElement | string, options?): Deferred {
        const deferred = $.Deferred();

        function onFinishedScroll() {
            $('#content').off('scroll.inView');
            deferred.resolve();
        }

        // Standard Timeout wenn kein Scrollen nötig ist
        let scrollTimeout = setTimeout(onFinishedScroll, 100);
        $(container).on('scroll.inView', function(e) {
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(onFinishedScroll, 100);
        });

        element.scrollIntoView(options || {
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });

        return deferred;
    }

    export function HighlightElement(element: HTMLElement | string): Deferred {
        const deferred = $.Deferred();
        const $element = $(element);

        $element.removeClass('flash-animation orange-flash');
        $element.addClass('flash-animation orange-flash');

        window.setTimeout(() => {
            $element.removeClass('flash-animation orange-flash');

            deferred.resolve();
        }, 5000);

        return deferred;
    }

    namespace GlobalErrorsHandling {
        window.onerror = function(message: string, filename: string, row: number, column: number, error: Error) {

            if (filename.contains('jSignature')) {
                return;
            }

            if (message && message.toLowerCase().contains('dom exception 18')) {
                sessionStorage.ReloadDueToSecurityException = true;

                location.reload();
                return;
            }

            message = error ? error.message : message;

            StackTrace.fromError(error)
                .then(function(stackframes: Array<any>) {

                    var rightStackframesEntry = stackframes[0].fileName.includes("model.errors.ts") ? 1 : 0;
                    // update filename / line numbers
                    if (stackframes.length) {
                        filename = stackframes[rightStackframesEntry].fileName;
                        column = stackframes[rightStackframesEntry].columnNumber;
                        row = stackframes[rightStackframesEntry].lineNumber;
                    }

                    // fix filename
                    if (filename != null) {
                        var wwwIdx = filename.indexOf('../../');
                        if (wwwIdx >= 0) {
                            filename = filename.substring(wwwIdx + 5);
                        }
                    }

                    return $.Deferred().resolve([message, stackframes]).promise();
                }, function(err) {
                    // stackframes could not be resolved, log original error
                    return $.Deferred().resolve([message, [{ source: error ? error.stack : '' }]]);
                })
                .then(function(args: Array<any>) {
                    const message: string = args[0];
                    const stackFrames: New.Analytics.StackTraceLine[] = args[1];
                    // log to analytics
                    Utils.Analytics.TrackException(Enums.AnalyticsExceptionType.Javascript, error || message, stackFrames);

                    Utils.Spinner.Unlock();
                    Utils.Spinner.Hide();

                    // now prepare error message for modal dialog

                    const errorString = i18next.t('ErrorWindow.MessageBody', {
                        Filename: filename,
                        Row: `${row}:${column}`,
                        Description: message
                    }).join('');

                    const btnSettings: any = {
                        Close: true
                    };

                    if (Session.IsSmartDeviceApplication) {
                        // add support-mail button to dialog
                        btnSettings.SendEMail = {
                            Fn: function() {
                                Utils.SupportMail.Show(null, errorString);
                            }
                        };
                    }

                    if (error && error.hasOwnProperty("httpResponse") && error["httpResponse"].status === Enums.HttpStatusCode.Forbidden) {
                        let messageTitle = i18next.t("HttpError.403.Title");
                        let messageString = i18next.t('HttpError.403.Message');

                        if (typeof error["httpResponse"].response === 'string') {
                            let response = JSON.parse(error["httpResponse"].response);
                            if (response.ErrorCode === Enums.ForbiddenResponseErrorCode.FileType) {
                                // nicht erlaubter Dateityp
                                messageTitle = i18next.t('HttpError.403.Title_ErrorCode1');
                                messageString = i18next.t('HttpError.403.Message_ErrorCode1', { mimeType: response.MimeType });
                            }
                        }

                        Utils.Message.Show(messageTitle, messageString, btnSettings, null, 10001);
                    } else if (error && error.hasOwnProperty("httpResponse") && error["httpResponse"].status === Enums.HttpStatusCode.Request_Too_Long) {
                        Utils.Message.Show(i18next.t('Upload.Errors.FileTooBig.Header'),
                            i18next.t('Upload.Errors.FileTooBig.Message'),
                            {
                                Close: true
                            },
                            'error',
                            10001
                        );
                    } else {
                        Utils.Message.Show(i18next.t('ErrorWindow.MessageHeader'),
                            errorString,
                            btnSettings,
                            'error',
                            10001
                        );
                    }
                });
        };

        // Fix für iOS
        window.addEventListener('unhandledrejection', function(evt) {
            if (!Session.IsRunningOnIOS) {
                return;
            }

            if (evt['reason'] instanceof Error) {
                const err: any = evt['reason'];
                window.onerror(err.message, err.sourceURL, err.line, err.column, err);
            } else {
                window.onerror(evt['reason'], '..', -1, -1);
            }
        });
    }
}
