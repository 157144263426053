//imports-start
/// <reference path="utils.base-parameter-selection-window.ts"/>
//imports-end

module Utils.ParameterSelection {
    export class ScancodeParameterSelectionWindow extends BaseParameterSelectionWindow {
        private readonly scannedCode: string;

        constructor(options: Options, scannedCode: string) {
            super(options);

            this.scannedCode = scannedCode;
            this.listItemParameterTemplate = '<button class="btn btn-default parameter" data-identifier="{Identifier}">{Title}<p class="small">{Type}</p><p class="small pull-right">{DisplayValue}</p></button>';
        }

        protected createListItems(): ListItemGroup[] {
            const cachedIndividualData: Dictionary<any[]> = {};
            let items: ListItemGroup[] = [];

            (this.groups || [])
                .filter(this.groupFilterExpression)
                .forEach((group: Model.Elements.Element) => {
                    const groupTitle = group.Row ? `${group.Title} (${group.Row})` : group.Title;
                    const listItemGroup = <ListItemGroup>{
                        Title: groupTitle,
                        Parameters: []
                    };

                    (group.Parameters || []).map((param: Model.Elements.Element): ListItemParameter[] => {
                        const identifier = group.Row ? `${param.OID}%${group.Row}` : param.OID;
                        let displayValue: string = this.scannedCode;

                        switch (param.Type) {
                            case Enums.ElementType.Line:
                            case Enums.ElementType.Memo:
                            case Enums.ElementType.Scancode:
                                displayValue = this.scannedCode;
                                break;
                            case Enums.ElementType.LocationCode:
                                const tmpElem = DAL.Elements.GetByOID(this.scannedCode);

                                if (tmpElem == null) {
                                    return;
                                }

                                displayValue = tmpElem.Title;

                                if (tmpElem.Parent != null) {
                                    displayValue += ` @ ${tmpElem.Parent.Title}`;
                                }

                                break;
                            case Enums.ElementType.IndividualData:
                                const type = Utils.GetIndividualDataTypeOfElement(param);
                                let data = cachedIndividualData[type];

                                if (!data) {
                                    data = Utils.RecorditemEditor.Individual.GetIndividualData(param, this.scannedCode);
                                    cachedIndividualData[type] = data;
                                }

                                if (!data) {
                                    return;
                                }

                                if (data.length === 1) {
                                    displayValue = DAL.IndividualData.GetEntityTitle(data[0].Type, data[0].ID);
                                } else if (data.length > 1) {
                                    const schema = DAL.Schemas.GetByType(data[0].Type);
                                    displayValue = `${data.length} ${schema.NamePlural}`;
                                } else {
                                    return;
                                }

                                break;
                            default:
                                return;
                        }

                        this.parameters[identifier] = param;

                        const markupContext = {
                            Title: param.Title,
                            Type: Utils.GetElementTypeString(param.Type),
                            Identifier: identifier,
                            DisplayValue: displayValue
                        };

                        listItemGroup.Parameters.push(<ListItemParameter>{
                            Title: param.Title,
                            Type: param.Type,
                            Identifier: identifier,
                            Markup: this.parseTemplate(markupContext)
                        });
                    });

                    if (listItemGroup.Parameters.length) {
                        items.push(listItemGroup);
                    }
                });

            return items;
        }
    }
}