//imports-start
/// <reference path="../../definitions.d.ts" />
/// <reference path="../../dal/files.ts" />
/// <reference path="../svg-editor/utils.svg-editor.ts" />
/// <reference path="utils.image-viewer-options.ts" />
/// <reference path="utils.image-viewer-manager.ts" />
//imports-end

module Utils {

    type Scales = { Width: number, Height: number };

    export class ImageViewer {

        private options: ImageViewerOptions;
        private images: string[];
        private rawImages: Dictionary<Model.Files.File | Model.IFileProperties>;
        private currentImageIdx: number;
        private hasDownloadUpdate: boolean;
        private isModified: boolean;
        private $win: any;
        private $img: any;
        private $errorMessage: any;
        private $downloadButton: any;

        private svgEditor: SVGEditor.SVGEditor | undefined | null;
        private comparisonViewer: ImageViewer | undefined;

        private _swipeHandlerAttached: Boolean = false;
        private _hammerManager;

        private static _startsWithImageRegex: RegExp = /^image/;

        public get $Window() {
            return this.$win;
        }

        constructor(options: ImageViewerOptions) {
            this._swipeHandlerAttached = false;
            this.currentImageIdx = -1;
            this.hasDownloadUpdate = false;
            this.isModified = false;
            this.options = options;

            this.initImages();
            this.initTemplate();

            if (this.options.ComparisonOnStart &&
                this.options.ComparisonImages &&
                this.options.ComparisonImages.length) {
                this.startComparison();
            }
        }


        public Show(appendingFunction: (imageViewerElement: HTMLDivElement) => void): void {
            let isDataValid: boolean = !!(this.options.Files || this.options.Images || []).length || !!this.options.ImageFilename;
            if (!isDataValid) {
                return;
            }

            this.Render(appendingFunction);
            this.bindEvents();

            if (!$('body').hasClass('modal-open')) {
                $('body').addClass('modal-open');
            }
        }

        public Render(appendingFunction: (imageViewerElement: HTMLDivElement) => void) {
            const element: HTMLDivElement = this.$win[0];
            appendingFunction(element);

            this.DisplayNextImage();
        }

        public Resize(numberOfVisibleViewer: number) {
            const scales = this.calculateResizingScales(numberOfVisibleViewer);
            this.resizeWindow(scales)
            this.resizeImage(scales);
        }

        public IsVisible(): boolean {
            return this.$win && this.$win.css('display') !== 'none';
        }

        public Destroy(): void {
            this.resetImageviewer();

            if (this._hammerManager) {
                this._hammerManager.off('swipe');
                this._hammerManager.remove('swipe');

                this._hammerManager = null;
                this._swipeHandlerAttached = false;
            }

            if (this.options.closeFn instanceof Function) {
                this.options.closeFn(this.hasDownloadUpdate);
            }
        }

        private onClose(): Deferred {
            const manager = ImageViewerManager.GetInstance();
            return this.comparisonViewer
                ? manager.Remove(this.comparisonViewer, this)
                : manager.Remove(this);
        }

        private resetImageviewer(): void {
            this.unbindEvents();

            if (this.svgEditor) {
                this.svgEditor.Destroy();
            }

            this.svgEditor = null;
            this.images = null;
            this.rawImages = null;
            this.currentImageIdx = null;
            this.options.FooterInformation = null;
            this.isModified = false;

            if (this.$win) {
                this.$win.remove();
                this.$win = null;
            }

            this.$img = null;
            this.$errorMessage = null;
            this.$downloadButton = null;

            if (!IssueViewer.IsVisible()
                && !RecorditemEditor.IsVisible()
                && !ElementInformation.IsVisible()
                && !ParameterHistory.IsVisible()) {
                $('body').removeClass('modal-open');
            }
        }

        private initImages(): void {
            const hasFiles = !!(this.options.Files || []).length;
            const hasImages = !!(this.options.Images || []).length;
            const hasImageFilename = !!this.options.ImageFilename;

            if (hasFiles) {
                this.initImagesByFilesInOptions();
                return;
            }

            if (hasImages) {
                this.initImagesByImagesInOptions();
                return;
            }

            if (hasImageFilename) {
                this.initImagesByFilenameInOptions();
                return;
            }
        }

        private initImagesByFilesInOptions(): void {
            this.images = [];
            this.rawImages = {};

            for (const file of this.options.Files) {

                if (typeof file === 'string' || !ImageViewer._startsWithImageRegex.test(file.MimeType)) {
                    continue;
                }

                this.images.push(file.Filename);
                this.rawImages[file.Filename] = file;

                if (this.options.StartFilename === file.Filename) {
                    this.currentImageIdx = this.images.length - 2;
                }

            }
        }

        private initImagesByImagesInOptions(): void {
            this.images = [];
            this.rawImages = {};

            for (let i = 0; i < this.options.Images.length; i++) {
                const image = this.options.Images[i];

                const isFilename = typeof image === 'string';
                const isMimeTypeImage = ImageViewer._startsWithImageRegex.test(image.MimeType);

                if (isFilename) {
                    this.images.push(image);

                    if (this.options.StartFilename === image) {
                        this.currentImageIdx = this.images.length - 2;
                    }

                    continue;
                }

                if (isMimeTypeImage) {
                    this.images.push(image.Filename);

                    if (image.Filename.startsWith('data:image')) {
                        this.rawImages[i] = image;
                    } else {
                        this.rawImages[image.Filename] = Utils.Clone(image);
                    }

                    if (this.options.StartFilename === image.Filename) {
                        this.currentImageIdx = this.images.length - 2;
                    }
                }
            }
        }

        private initImagesByFilenameInOptions(): void {
            this.images = [this.options.ImageFilename];
        }

        private initTemplate(): void {
            const footerTitle = this.options.FooterInformation && this.options.FooterInformation.Title
                ? this.options.FooterInformation.Title
                : {};

            this.$win = $(Templates.ImageViewer.Window({
                CurrentImageIndex: this.currentImageIdx + 1,
                ImageCount: this.images.length,
                Title: footerTitle,
                ComparisonImages: this.options.ComparisonImages || []
            }));

            this.$win.find('svg').removeClass('hidden').svg();

            if (this.options.ComparisonImages) {
                this.$win.find('.image-comparison').removeClass('hidden');
            }

            this.$img = this.$win.find('.content .graphics img')
                .on('load', (event: Event) => this.onImageLoaded(event))
                .on('error', () => this.onImageError());

            this.$errorMessage = this.$win.find('p.error-message');
            this.$downloadButton = this.$win.find('.download-button');
        }

        public AskForModificationAction(): Deferred {
            const responseDeferred = $.Deferred();

            if (this.isFileInformationModified()) {
                Message.Show(i18next.t('ImageViewer.UnsavedMarks.QuestionHeader')
                    , i18next.t('ImageViewer.UnsavedMarks.QuestionBody')
                    , {
                        Yes: {
                            Fn: () => {
                                if (this.svgEditor) {
                                    this.svgEditor.SaveMarks();
                                }
                                responseDeferred.resolve();
                            },
                            Caption: i18next.t('Misc.Save')
                        },
                        No: {
                            Fn: () => responseDeferred.resolve(),
                            Caption: i18next.t('Misc.DiscardChanges')
                        },
                        Abort: () => responseDeferred.reject()
                    }, null, 99999);
            } else {
                responseDeferred.resolve();
            }

            return responseDeferred.promise();
        }

        public DisplayPreviousImage(): void {
            this.AskForModificationAction()
                .then(() => {
                    if ((--(this.currentImageIdx)) < 0) {
                        this.currentImageIdx = this.images.length - 1;
                    }

                    this.updateImage();
                });
        }

        public DisplayNextImage(): void {
            this.AskForModificationAction()
                .then(() => {
                    if ((++(this.currentImageIdx)) === this.images.length) {
                        this.currentImageIdx = 0;
                    }

                    this.updateImage();
                });
        }

        public DisplayError(msg: string): void {
            const $svg = this.$win.find('svg');
            const svg = $svg.svg('get');

            svg.clear();

            $svg.addClass('hidden');
            this.$img.addClass('hidden');
            this.$errorMessage.removeClass('hidden');

            this.$errorMessage.html(msg);
        }

        private updateImage(): void {
            let $title = this.$win.find('.title');
            let filename: string, file: Model.Files.File | Model.IFileProperties;
            let imgSrc: string;

            if (this.currentImageIdx > -1 && this.images.length > this.currentImageIdx) {
                this.$win.find('.content > svg').empty();

                filename = this.images[this.currentImageIdx];
                file = DAL.Files.GetByFilename(filename);

                if (file && Session.IsSmartDeviceApplication) {
                    this.options.ForceLoadFromServer = !file.IsAvailableOffline;
                }

                if (!file && this.rawImages) {
                    file = filename.startsWith('data:image') ? this.rawImages[this.currentImageIdx] : this.rawImages[filename];
                }

                this.$win.find('.current-index').text(this.currentImageIdx + 1);

                $.Deferred().resolve()
                    .then(() => {
                        if (this.options.ForceLoadFromServer) {
                            return Utils.CheckIfDeviceIsOnline();
                        }
                    })
                    .then(() => {
                        let loadFromServer = false;

                        if (!this.options.FullPathGiven && (this.options.ForceLoadFromServer || !Session.IsSmartDeviceApplication)) {
                            loadFromServer = true;
                            imgSrc = `${Session.BaseURI}images/l/${filename}${Utils.GetAuthQueryParameter('?')}`;
                        } else {
                            imgSrc = this.images[this.currentImageIdx];

                            if (!this.options.FullPathGiven) {
                                imgSrc = Utils.GetResourcesPath() + imgSrc;
                            }

                            if (imgSrc.contains('cdv_photo_')) {
                                imgSrc += '?' + new Date().getTime();
                            }

                            if (Session.IsRunningOnIOS) {
                                imgSrc = Utils.FixIOSFilepath(imgSrc);
                            }
                        }

                        if (Utils.IsSet(file) && Utils.IsSet(file.Title)) {
                            $title.text(file.Title);
                        } else if (Utils.IsSet(this.options.FooterInformation) && Utils.IsSet(this.options.FooterInformation.Title)) {
                            $title.text(this.options.FooterInformation.Title);
                        } else {
                            $title.text('');
                        }

                        if (Session.IsSmartDeviceApplication &&
                            Session.LastKnownAPIVersion >= 21 &&
                            loadFromServer) {
                            Utils.Http.LoadImage(imgSrc)
                                .then((base64: string) => {
                                    this.$img.attr('src', base64);
                                }, this.onImageError);

                            return;
                        }

                        this.$img.attr('src', imgSrc);

                        if (this.options.FileSeenCallback) {
                            this.options.FileSeenCallback(this.images[this.currentImageIdx]);
                        }
                    }, () => {
                        this.DisplayError(i18next.t('ImageViewer.Offline'));
                    });
            }
        }

        private unbindEvents(): void {
            this.$win.find('.previous-image').off('click');
            this.$win.find('.next-image').off('click');
            this.$win.find('.btn-close').off('click');
            this.$win.find('.image-comparison').off('click');
        }

        private bindEvents(): void {
            this.unbindEvents();

            this.$win.find('.previous-image').on('click', () => this.DisplayPreviousImage());
            this.$win.find('.next-image').on('click', () => this.DisplayNextImage());
            this.$win.find('.btn-close').on('click', () => this.onClose());
            this.$win.find('.image-comparison').on('click', () => this.startComparison())

            this.initSwipeHandler();
        }

        private isFileInformationModified(): boolean {
            return this.isModified || (!!this.svgEditor && this.svgEditor.IsModified);
        }

        private initSwipeHandler(): void {
            if (this._swipeHandlerAttached) {
                return;
            }

            this.createHammerManager();

            const swipe = new Hammer.Swipe({
                direction: Hammer.DIRECTION_HORIZONTAL,
                threshold: 1,
                velocity: 0.4
            });

            this._hammerManager.off('swipe');
            this._hammerManager.remove('swipe');

            this._hammerManager.add(swipe);
            this._hammerManager.on('swipe', (hammerInputEvent) => this.onHandleSwipeEvent(hammerInputEvent));

            this._swipeHandlerAttached = true;
        }

        private createHammerManager(): void {
            if (this._hammerManager) {
                return;
            }

            const contentElement = this.$win.get(0);

            this._hammerManager = new Hammer.Manager(contentElement, {
                touchAction: 'auto',
                recognizers: [
                    [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
                ]
            });
        }

        private onHandleSwipeEvent(hammerInputEvent): void {
            if (!this.$win || !this.$win.is(':visible') || this.svgEditor) {
                return;
            }

            if (hammerInputEvent.direction === Hammer.DIRECTION_RIGHT) {
                this.DisplayPreviousImage();
            } else if (hammerInputEvent.direction === Hammer.DIRECTION_LEFT) {
                this.DisplayNextImage();
            }
        }

        private calculateResizingScales(numberOfVisibleViewer: number): Scales {
            const scale = 1 / numberOfVisibleViewer;
            const fixed = 1;

            switch (Utils.GetOrientation()) {
                case 'portrait': return { Width: fixed, Height: scale };
                case 'landscape': return { Width: scale, Height: fixed };
            }
        }

        private resizeWindow(scales: Scales): void {
            const window: HTMLDivElement = this.$win[0];
            window.style.maxWidth = `calc(100vw * ${scales.Width})`;
            window.style.maxHeight = `calc(100vh * ${scales.Height})`;
        }

        private resizeImage(scales: Scales): void {
            const image: HTMLDivElement = this.$img[0];
            const footerHeight = this.$win.find('.footer').height() || 0;
            switch (Utils.GetOrientation()) {
                case 'portrait':
                    const markSettingsHeight = this.$win.find('.mark-settings').height() || 0;
                    image.style.maxWidth = `calc(100vw * ${scales.Width})`;
                    image.style.maxHeight = `calc(100vh * ${scales.Height} - ${footerHeight}px - ${markSettingsHeight}px)`;
                    break;
                case 'landscape':
                    const markSettingsWidth = this.$win.find('.mark-settings').width() || 0;
                    image.style.maxWidth = `calc(100vw * ${scales.Width} - ${markSettingsWidth}px)`;
                    image.style.maxHeight = `calc(100vh * ${scales.Height} - ${footerHeight}px)`;
                    break;
            }
        }

        private onAfterSaved(marks: string | null): void {
            let identifier = this.images[this.currentImageIdx];
            if (identifier.startsWith('data:image')) {
                identifier = this.rawImages[this.currentImageIdx].OID;
                this.rawImages[this.currentImageIdx].Marks = marks;
            } else if (!Utils.IsValidGuid(identifier) &&
                this.rawImages[identifier] && this.rawImages[identifier].OID) {
                const srcImage = this.rawImages[identifier];
                identifier = srcImage.OID;
                srcImage.Marks = marks;
            } else if (this.rawImages[identifier]) {
                this.rawImages[identifier].Marks = marks;
            }

            this.options.ImageEditedCallback(identifier, marks);
            this.isModified = false;
        }

        private onImageLoaded(event: Event): void {
            if (!(event.currentTarget instanceof HTMLImageElement)) {
                return;
            }

            const $imageContainer = this.$win.find('.content');
            const $svg = this.$win.find('svg');
            const svg = $svg.svg('get');
            const currentImage = this.images[this.currentImageIdx];
            let file: Model.IFileProperties | Model.Files.File = DAL.Files.GetByFilename(currentImage);
            let metrics;
            let $marks;

            if (!file && this.rawImages) {
                file = currentImage.startsWith('data:image') ? this.rawImages[this.currentImageIdx] : this.rawImages[currentImage];
            }

            this.$img.removeClass('hidden');
            this.$errorMessage.addClass('hidden');
            this.$downloadButton.addClass('hidden');

            $svg.removeClass('hidden');
            svg.clear();

            if (this.rawImages) {
                metrics = (this.rawImages[currentImage] || {}).Metrics;
            }

            if (file && !!file.Marks) {
                $marks = $(file.Marks);
            }

            if (!metrics || !metrics.hasOwnProperty('SizeO')) {
                const size = ($marks || []).length
                    ? { Width: $($marks[1]).attr('width'), Height: $($marks[1]).attr('height') }
                    : { Width: event.currentTarget.width, Height: event.currentTarget.height }
                metrics = { SizeO: size };
            }

            if (file && !!file.Marks) {
                $marks = $(file.Marks);

                const width = $($marks[1]).attr('width') || event.currentTarget.width;
                const height = $($marks[1]).attr('height') || event.currentTarget.height;

                svg.add($marks.children());
                svg.configure({ viewBox: `0 0 ${width} ${height}` });
            }

            const canOpenSvgEditor = !!this.options.ImageEditedCallback &&
                !!($imageContainer || []).length &&
                !!metrics

            if (canOpenSvgEditor) {
                if (this.svgEditor) {
                    this.svgEditor.Destroy();
                }

                this.svgEditor = new SVGEditor.SVGEditor(
                    this.$win,
                    $imageContainer,
                    metrics,
                    this.options.CanWriteComments,
                    (marks: string | null) => this.onAfterSaved(marks)
                );
            }

            ImageViewerManager.GetInstance().Resize();
        }

        private onImageError(): void {
            this.DisplayError(i18next.t('ImageViewer.NotFound'));

            // Datei Download anbieten auf SmartDevice
            if (!Session.IsSmartDeviceApplication) {
                return;
            }

            let filename = this.images[this.currentImageIdx];
            const file = DAL.Files.GetByFilename(filename);

            if (this.options.FullPathGiven) {
                const rawImage = this.rawImages[filename] || { OID: null };
                filename = rawImage.OID
            }

            if (!filename || file && !file.IsAvailableOffline) {
                return; // kein Download anbieten, wenn es eine Online Datei ist
            }

            Utils.CheckIfDeviceIsOnline()
                .then((isOnline: boolean) => {
                    if (!isOnline) {
                        this.$downloadButton.addClass('hidden');
                        return;
                    }

                    // Download Button anzeigen
                    this.$downloadButton.removeClass('hidden');
                    this.$downloadButton.off().on('click', () => {
                        Spinner.Show(i18next.t('Misc.LoadingData', { DataType: filename }));

                        Http.DownloadFile(filename, null)
                            .then(() => {
                                this.updateImage();

                                this.hasDownloadUpdate = true;
                            }, () => {
                                // Download fehlgeschlagen, Download Button nicht anzeigen
                                this.$downloadButton.addClass('hidden');
                            }).always(function() {
                                Spinner.Hide();
                            });
                    });
                });
        }

        public startComparison(): void {
            const images = this.options.ComparisonImages;
            if (!images || !images.length) {
                return;
            }

            Utils.SortFilesByPrioritization(images);

            const comparisonOptions: ImageViewerOptions = {
                Images: images,
                StartFilename: images[0].Filename,
                ForceLoadFromServer: false,
                FooterInformation: {
                    Title: images[0].Filename
                },
                closeFn: _ => {
                    this.comparisonViewer = null;

                    if (this.$win) {
                        this.$win.find('.image-comparison').removeClass('hidden')
                    }
                }
            }

            this.comparisonViewer = new ImageViewer(comparisonOptions);

            ImageViewerManager
                .GetInstance()
                .Prepend(this.comparisonViewer)
                .Show();

            this.$win.find('.image-comparison').addClass('hidden');
        }
    }
}
