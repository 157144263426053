//imports-start
/// <reference path="../definitions.d.ts"  />
/// <reference path="../app/app.session.ts"  />
/// <reference path="./utils.input-window.ts"  />
/// <reference path="../app/app.view.ts"  />
/// <reference path="../app/app.calendar.ts"  />
/// <reference path="../app/app.information.ts"  />
/// <reference path="../app/app.floor-plan.ts"  />
//imports-end

module Utils.Router {

    let _routes: Dictionary<Function> = {};

    let _isWatching: boolean = false;

    const MAX_HISTORY_ENTRIES = 10; // Begrenzung der Anzahl Einträge der Fragment History
    let _fragmentHistory: string[] = []; // Queue mit MAX_HISTORY_ENTRIES Einträgen
    let _previousFragment: string;
    let _currentFragment: string;

    const EXPRESSIONS: { [Title: string]: { Alias: RegExp, Expression: string } } = {
        GUID: {
            Alias: /:guid/ig,
            Expression: '([A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12})'
        },
        INT: {
            Alias: /(:int|:number)/ig,
            Expression: '(\\d+)'
        },
        STRING: {
            Alias: /(:string|:text)/ig,
            Expression: '([A-Z0-9\\-]+)'
        },
        BOOLEAN: {
            Alias: /:boolean/ig,
            Expression: '(true|false)'
        },
        QUESTION_MARK: {
            Alias: /\?/,
            Expression: '\\?'
        }
    };

    let _ignoreHashchange: boolean;

    export function getRoutes(): Dictionary<Function> {
        return _routes;
    }

    function onHashChange(): void {
        if (_ignoreHashchange) {
            _ignoreHashchange = false;
            return;
        }

        const fragment = GetCurrentFragment();
        let useDefaultRoute = true;

        if (!!window.location.href) {
            const newFragment = GetCurrentFragment(window.location.href);
            if (newFragment !== _currentFragment) {
                _previousFragment = _currentFragment;
                _currentFragment = newFragment;

                pushHistoryFragment(_previousFragment);
            }
        }

        for (let route in _routes) {
            const regEx = getRouteExpression(route);

            if (regEx.test(fragment)) {
                regEx.lastIndex = 0;

                const extractedValues = regEx.exec(fragment);
                const typesOfValues = getValueTypes(route);
                const params = prepareValues(extractedValues, typesOfValues);

                _routes[route].apply(fragment, params);

                useDefaultRoute = false;
                break;
            }
        }

        if (useDefaultRoute) {
            ReplaceState(GetDefaultRoute());
        }

        App.HideExpandableNavigation();
    }

    function getRouteExpression(str: string): RegExp {
        for (let key in EXPRESSIONS) {
            const exp = EXPRESSIONS[key];
            str = str.replace(exp.Alias, exp.Expression);
        }

        return new RegExp(`^${str}$`, 'ig');
    }

    function getValueTypes(route: string): Array<string> {
        const regEx = /:([\w]+)/ig;
        const types: Array<string> = [];

        if (regEx.test(route)) {
            regEx.lastIndex = 0;

            let match: string[];
            while (match = regEx.exec(route)) {
                types.push(match[1]);
            }
        }

        return types;
    }

    export function GetCurrentFragment(url?: string): string {
        let fragment: string;

        if (!!url) {
            fragment = url.split('#')[1];
        } else {
            fragment = window.location.hash.slice(1);
            if (!fragment && !!window.location.search) {
                fragment = window.location.search;
            }
        }

        return fragment || '/';
    }

    export function RestoreFragment(): void {
        /*
        * letztes Fragment wiederherstellen
        */
        if (!!_previousFragment) {
            PushState(_previousFragment, true);

            const tmp = _currentFragment;

            _currentFragment = _previousFragment;
            _previousFragment = tmp;

            pushHistoryFragment(_previousFragment);
        }
    }

    export function GetFragmentHistory(): string[] {
        return _fragmentHistory.slice();
    }

    export function Add(route: string, handler: Function): void {
        if (!_routes.hasOwnProperty(route) && route) {
            if (route[0] === '#') {
                route = route.substr(1);
            }
            _routes[route] = handler;
        }
    }

    export function AddRoutes(routes: Array<string>, handler: Function): void {
        for (let i = 0; i < routes.length; i++) {
            let route = routes[i];
            Add(route, handler);
        }
    }

    export function GetDefaultRoute(): string {
        return `location/${DAL.Elements.Root.OID}/${Enums.Mode.Menu}`;
    }

    function pushHistoryFragment(fragment: string) {
        if (!fragment) {
            return;
        }

        _fragmentHistory.push(fragment);

        if (_fragmentHistory.length > MAX_HISTORY_ENTRIES) {
            _fragmentHistory.shift();
        }
    }

    function prepareValues(extractedValues: Array<string>, types: Array<string>): Array<string | number | boolean> {
        let values: Array<string | number | boolean> = [];

        if (extractedValues) {
            extractedValues.splice(0, 1);

            for (let vCnt = 0, vLen = extractedValues.length; vCnt < vLen; vCnt++) {
                const type = types[vCnt];
                let value: any = extractedValues[vCnt];

                switch (type) {
                    case 'int':
                        value = parseInt(value, 10);
                        break;
                    case 'boolean':
                        value = value === 'true';
                        break;
                }

                values.push(value);
            }
        }

        return values;
    }

    export function Watch(): void {
        if (!_isWatching) {
            $(window).on('hashchange', onHashChange);

            _isWatching = true;
        }
    }

    export function PushState(fragment: string, preventTrigger?: boolean): void {
        if (!fragment) {
            return;
        }

        if (!fragment.startsWith('#')) {
            fragment = '#' + fragment;
        }

        history.pushState(null, navigator.title, fragment);

        _ignoreHashchange = preventTrigger;

        if (Session.IsSmartDeviceApplication && Utils.BluetoothDeviceManager) {
            // TODO Extract BT-Devices out of the router, i.e. by using events
            Utils.BluetoothDeviceManager.RemoveDeviceConnectedCallback('ParameterListTemperatureDevice');
            Utils.BluetoothDeviceManager.RemoveDeviceConnectedCallback('RecorditemEditor');

            let connectedDevices = Utils.BluetoothDeviceManager.GetConnectedDevices();

            for (let key in connectedDevices) {
                connectedDevices[key].DeregisterAllNotifier();
            }
        }

        // disable all scale events to view
        if (Utils.ScaleDeviceManager) {
            Utils.ScaleDeviceManager.ClearDeviceListenerCallbacks();
        }

        CodeScanner.RemoveFromCallbackStack('ParameterList_Scan');

        RefreshState();
    }

    export function ResetState(): void {
        history.replaceState(null, navigator, '#');
    }

    export function ReplaceState(fragment: string, preventTrigger?: boolean): void {
        if (!fragment) {
            return;
        }

        if (!fragment.startsWith('#')) {
            fragment = '#' + fragment;
        }

        const fragmentDiffers = window.location.hash != fragment;

        history.replaceState(null, navigator.title, fragment);

        _ignoreHashchange = preventTrigger;

        if (fragmentDiffers) {
            RefreshState();
        }
    }

    export function RefreshState(): void {
        $(window).trigger('hashchange');
    }

    export function IsValidRoute(fragment: string): boolean {
        for (let route in _routes) {
            const regEx = getRouteExpression(route);

            if (regEx.test(fragment)) {
                return true;
            }
        }

        return false;
    }

    export function GoToSectionAtLocation(locationOID: string, section: string): void {
        // TODO don't use DAL here, use extern validation method
        if (!DAL.Elements.Exists(locationOID) || !section) {
            return;
        }

        PushState(`#location/${locationOID}/${section}`);
    }

    export function OpenDefaultRoute(): void {
        ReplaceState(GetDefaultRoute());
    }
}
