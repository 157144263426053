///<reference path="i-workflow.ts"/>
///<reference path="workflow-base.ts"/>
///<reference path="workflow-settings.ts"/>
/// <reference path="../../../model/model.recorditem.ts"/>
///<reference path="response/comment-workflow.ts"/>
/// <reference path="../../../model/elements/element.ts"/>


module New.Checkpoints.Workflow {
    export class CommentWorkflow extends WorkflowBase {

        constructor(workflow: IWorkflow, workflowSettings: WorkflowSettingsBase) {
            super(workflow, workflowSettings);
        }

        public Execute(): any {
            const deferred = $.Deferred();

            if (!Utils.UserHasRight(Session.User.OID, Enums.Rights.Comments_CreateAndModifyCommentsOnCheckpoint, true, this.LocationIdentifier)) {
                return deferred.resolve().promise();
            }

            const windowTitle = this.Checkpoint != null ? i18next.t(
                'CorrectiveActions.CommentWorkflowWindowCaption',
                { CheckpointTitle: this.Checkpoint.Title }
            ) : i18next.t('CorrectiveActions.Buttons.ApplyComment');

            Utils.InputWindow.Show(windowTitle, null, {
                OK: {
                    Caption: i18next.t('Misc.Okay'),
                    Fn: (text: string) => {
                        this.saveComment(text)
                            .then((commentResponse: Response.CommentWorkflow) =>
                                ParameterList.AskWhetherToReUseCommentAndCorrectiveAction(this.Checkpoint, this.Recorditem, commentResponse.Comment)
                                    .then((issue: Model.Issues.Issue) => {
                                        commentResponse.Issue = issue;
                                        return commentResponse;
                                    }, () => commentResponse)
                            )
                            .then(deferred.resolve, deferred.reject);
                    }
                }
            }, this.Text, 'textarea', 1055, null, true);

            return deferred.promise();
        }

        private saveComment(text: string): Deferred {
            text = $.trim(text);

            const now = new Date();
            const comment = new Model.Comment(
                uuid(),
                now,
                now,
                Session.User.OID,
                Session.User.OID,
                text,
                this.Recorditem.OID,
                Enums.CommentType.RecorditemComment
            );

            // set issue as helper information
            if (Session.IsSmartDeviceApplication) {
                if (this.Recorditem.IssueID) {
                    comment.IssueID = this.Recorditem.IssueID;
                }
                if (this.Recorditem.IssueOID) {
                    comment.IssueOID = this.Recorditem.IssueOID;
                }
            }

            const response = new Response.CommentWorkflow(this, comment);

            return comment.Save()
                .then(() => response, () => {
                    Utils.Toaster.Show(i18next.t('CorrectiveActions.CouldNotSaveComment'), 2, Enums.Toaster.Icon.Warning);
                    return $.Deferred().reject(response);
                });
        }
    }
}
