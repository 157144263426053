module Utils {
    export type CloneOptions = {
        attributesToIgnore?: Array<string>,     // alle Properties des Objekten, die nicht geclont werden sollen, default => null
        keepEmptyProperties?: boolean,    // leere und null Properties behalten oder entfernen, default => false
        keepHierarchies?: boolean    // 'Parent' und 'Children' werden automatisch zu 'attributesToIgnore' hinzugefügt, default => false
    };

    type CloneInfo = { item: any, promise?: LightPromise };

    export function CloneElement(element: Model.Elements.Element): Model.Elements.Element
    export function CloneElement(element: Model.Elements.Element, options: CloneOptions): Model.Elements.Element
    export function CloneElement(element: Model.Elements.Element, options?: CloneOptions): Model.Elements.Element {
        if (element == null) {
            return element;
        }

        options = options || <CloneOptions>{};

        if (options.keepHierarchies !== true) {
            options.keepHierarchies = false;
        }

        if (!options.keepHierarchies) {
            options.attributesToIgnore = options.attributesToIgnore || [];
            options.attributesToIgnore.push('Parametergroups');
            options.attributesToIgnore.push('Parameters');
        }

        return <Model.Elements.Element>cloneEntity(element, options);
    }

    export function Clone<T>(toClone: T, options?: CloneOptions): T
    export function Clone<T>(toClone: T, attributesToIgnore?: Array<string>): T
    export function Clone<T>(toClone: T, attributesToIgnore_options?: Array<string> | CloneOptions): T {
        if (toClone == null) {
            return toClone;
        }

        if (attributesToIgnore_options instanceof Array) {
            return <T>cloneEntity(toClone, { attributesToIgnore: attributesToIgnore_options });
        } else {
            return <T>cloneEntity(toClone, attributesToIgnore_options);
        }
    }

    export function CloneArray<T>(toClone: Array<T>, options?: CloneOptions): Array<T>
    export function CloneArray<T>(toClone: Array<T>, attributesToIgnore?: Array<string>): Array<T>
    export function CloneArray<T>(toClone: Array<T>, attributesToIgnore_options?: Array<string> | CloneOptions): Array<T> {
        if (toClone == null) {
            return toClone;
        }

        if (!(toClone instanceof Array)) {
            console.warn('Possible wrong Clone method!');
        } else if (!toClone.length) {
            return [];
        }

        if (attributesToIgnore_options instanceof Array) {
            return <T[]>cloneEntity(toClone, { attributesToIgnore: attributesToIgnore_options });
        } else {
            return <T[]>cloneEntity(toClone, attributesToIgnore_options);
        }
    }

    export function CloneObject<T>(toClone: T, options?: CloneOptions): T
    export function CloneObject<T>(toClone: T, attributesToIgnore?: Array<string>): T
    export function CloneObject<T>(toClone: T, attributesToIgnore_options?: Array<string> | CloneOptions): T {
        if (toClone == null) {
            return toClone;
        }

        if (toClone instanceof Array) {
            console.warn('Possible wrong Clone method!');
        }

        if (attributesToIgnore_options instanceof Array) {
            return <T>cloneEntity(toClone, { attributesToIgnore: attributesToIgnore_options });
        } else {
            return <T>cloneEntity(toClone, attributesToIgnore_options);
        }
    }

    function cloneEntity<T>(toClone: T, options?: CloneOptions, cloneStack?: CloneInfo[]): T | { then: Function } {
        // einfache Typen clonen
        if (typeof toClone === 'undefined' || toClone === null) {
            return toClone;
        }

        if (typeof toClone === 'string' ||
            typeof toClone === 'boolean' ||
            typeof toClone === 'number') {
            return toClone;
        }

        if (toClone instanceof Date) {
            return <T><unknown>new Date(toClone.getTime());
        }

        if (toClone instanceof Function) {
            return toClone;
        }

        // Clone Stack für Arrays und Objekte initieren
        cloneStack = cloneStack || [];

        for (const stackItem of cloneStack) {
            if (stackItem.item === toClone) {
                // wird ein Objekt bereits rekursiv geclont, Ergebnis über Promise setzen
                stackItem.promise = stackItem.promise || LightPromise.Create();

                return stackItem.promise.promise();
            }
        }

        const cloneInfo = <CloneInfo>{
            item: toClone
        };
        cloneStack.push(cloneInfo);

        // Arrays clonen
        if (toClone instanceof Array) {
            const arr = [];
            for (let index = 0; index < toClone.length; index++) {
                const val = toClone[index];
                const clonedItem = cloneEntity(val, options, cloneStack);

                if (clonedItem && clonedItem.then instanceof Function) {
                    arr.push(null);
                    clonedItem.then((postClone) => {
                        arr[index] = postClone;
                    });
                } else {
                    arr.push(clonedItem);
                }
            }

            // rekursiv wiederholende Objekte aktualisieren
            if (cloneInfo.promise) {
                cloneInfo.promise.resolve(arr);
            }

            return <T><unknown>arr;
        }

        // Objekte clonen
        let ignore: Dictionary<boolean> = options && options.keepHierarchies ? {} : <any>{ 'Parent': true, 'Children': true };
        if (options && options.attributesToIgnore instanceof Array) {
            for (const attr of options.attributesToIgnore) {
                ignore[attr] = true;
            }
        }

        const copy = <T>{};
        for (let attr in toClone) {
            const val = toClone[attr];

            if (!toClone.hasOwnProperty(attr)) {
                continue;
            }

            if (ignore[attr]) {
                continue;
            }

            if (val == null && (!options || !options.keepEmptyProperties)) {
                continue;
            }

            const clonedItem = cloneEntity(val, options, cloneStack);
            if (clonedItem && clonedItem['then'] instanceof Function) {
                (<any>clonedItem).then((postClone) => {
                    copy[attr] = postClone;
                });
            } else {
                copy[attr] = <any>clonedItem;
            }
        }

        // rekursiv wiederholende Objekte aktualisieren
        if (cloneInfo.promise) {
            cloneInfo.promise.resolve(copy);
        }

        return copy;
    }

    export class LightPromise {
        private callers: Function[] = [];

        private constructor() {
        }

        public static Create() {
            return new LightPromise();
        }

        public resolve(data: any) {
            for (const callerFn of this.callers) {
                callerFn(data);
            }
        }

        public then(fn: Function) {
            if (fn instanceof Function) {
                this.callers.push(fn);
            }
        }

        public promise(): { then: Function } {
            return {
                then: (data: any) => this.then(data)
            };
        }
    }

    // Global Test Variable
    declare let __TEST__: { Utils: typeof Utils; };
    if (typeof __TEST__ != 'undefined') {
        __TEST__.Utils = Utils;
    }
}
