module Model.Core {
    export abstract class Unique {
        OID: string;

        constructor(oid?: string) {
            if (oid) {
                this.OID = oid;
            }
        }
    }
}
