//imports-start
/// <reference path="../core/revision.ts"  />
//imports-end

module Model.Teams {
    export class RawTeam extends Model.Core.Revision {
        ParentOID?: string;
        Title: string;
        Description?: string;
        Position: number;
        Color?: string;
        ImageOID?: string;
        Users: Array<any>;

        constructor(team?: Team) {
            super();

            if (team != null)
                this.initProperties(team);
        }

        private initProperties(team: Team): void {
            this.OID = team.OID;
            this.RevisionOID = team.RevisionOID;
            this.ParentOID = team.ParentOID;
            this.Title = team.Title;
            this.Description = team.Description;
            this.Position = team.Position;
            this.Color = team.Color;
            this.ImageOID = team.ImageOID;
            this.Users = team.Users;
            this.Deleted = team.Deleted;
        }
    }
}