//imports-start
/// <reference path="../../dal/elements.ts"  />
/// <reference path="../../model/scheduling/scheduling.ts"  />
/// <reference path="../utils.message.ts"  />
/// <reference path="../utils.file-helper.ts"  />
/// <reference path="./utils.base-info-window.ts"  />
//imports-end

module Utils.InfoWindow {
    export class SchedulingInfoWindow extends BaseInfoWindow<Model.Scheduling.Scheduling> {
        protected infoWindowID: string = 'scheduling-info';

        constructor(formOID: string, zIndex: number = 1200) {
            super(formOID, zIndex);
        }

        protected showNoItemFoundMessage(): void {
            Utils.Message.Show(i18next.t('SelectionWindow.Schedulings.UnknownScheduling.MessageHeader'),
                i18next.t('SelectionWindow.Schedulings.UnknownScheduling.MessageBody'),
                {
                    Close: true
                }, null, this.zIndex + 50);
        }

        protected getContent(): any {
            return $(`<div class="information">${this.createInformationMarkup()}</div>`);
        }

        protected getItemTitle(): string {
            return this.item.Title;
        }

        protected getItemByIdentifier(identifier: string): Model.Scheduling.Scheduling {
            return DAL.Scheduling.GetByOID(identifier);
        }

        private createInformationMarkup(): string {
            if (!Utils.IsSet(this.item)) {
                return '';
            }

            return Templates.SelectionWindow.Scheduling.Information(this.item);
        }
    }
}