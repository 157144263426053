///<reference path="./utils.file-helper.ts"/>


module Utils {
    export class FileInputWindow {
        private HeaderText: string;
        private Message: string;
        private Deferred: Deferred;

        private $overlay;
        private $win;
        private $fileInput;

        constructor(headerText?: string, message?: string) {
            this.HeaderText = headerText;
            this.Message = message;
        }

        Show(): Deferred {
            this.Deferred = $.Deferred();

            this.$overlay = Utils.Overlay.Generate('olInputOverlay', 1050);
            this.$win = $(Templates.FileInputWindow.Window({
                Header: this.HeaderText || i18next.t('Misc.DefaultMessageWindowTitle'),
                Message: this.Message || ''
            }));

            this.$win.css('z-index', 1051);

            this.$fileInput = this.$win.find('#file-input-window-input');

            $('body').append(this.$win);

            Utils.RepositionNewModalWindow(this.$win);

            this.bindEvents();

            return this.Deferred.promise();
        }

        Destroy(): void {
            this.$win.remove();
            Utils.Overlay.DestroyWithTimeout(this.$overlay);

            this.$win = null;
            this.$overlay = null;
            this.$fileInput = null;
        }

        private bindEvents(): void {
            this.$fileInput.on('change', this.onFileInput);
        }

        private onFileInput = (evt: Event): void => {
            if (typeof FileReader === 'undefined') {
                this.Deferred.reject();
                return;
            }

            const fileInput = evt.currentTarget as HTMLInputElement;

            if (!fileInput || !(fileInput.files || []).length) {
                return;
            }

            const file: File = fileInput.files[0];

            if (!file || !Utils.IsImage(file.type)) {
                return;
            }

            Utils.ReadFile(file, uuid())
                .then((fileInformation: any) => {
                    this.Destroy();
                    this.Deferred.resolve(fileInformation);
                });
        }
    }
}
