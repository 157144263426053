//imports-start
/// <reference path="../../definitions.d.ts"  />
/// <reference path="../../enums.ts"  />
/// <reference path="./requirement.ts"  />
/// <reference path="../model.recorditem.ts"  />
/// <reference path="../properties/property.ts"  />
/// <reference path="../files/file.ts"  />
/// <reference path="./evaluation.ts"  />
//imports-end

module Model.Elements {
    export class Scheduling extends Core.Unique {
        Locations: Array<string>; // deprecated - bis API Version 27
        LocationSettings: Array<Model.Scheduling.SchedulingLocationSettings>;   // Ab API Version 27
    }
}
