//imports-start
/// <reference path="../definitions.d.ts"  />
//imports-end

module Model {

    export class ResubmissionitemCollection {
        Collection: Model.Issues.ResubmissionItem[];
        Dictionary: Dictionary<Model.Issues.ResubmissionItem>;
        Root: Model.Issues.ResubmissionItem;

        constructor(collection: Model.Issues.ResubmissionItem[]) {
            this.Collection = $.extend(true, [], collection);
            this.Dictionary = this.getItemDictionary(this.Collection);
            this.Root = this.createHierarchy(this.Dictionary);
        }

        private getItemDictionary(collection: Model.Issues.ResubmissionItem[]): Dictionary<Model.Issues.ResubmissionItem> {
            let lookup: Dictionary<Model.Issues.ResubmissionItem> = {};

            if (!(collection instanceof Array) || !collection.length) {
                return lookup;
            }

            for (let iCnt = 0, iLen = collection.length; iCnt < iLen; iCnt++) {
                lookup[collection[iCnt].OID] = collection[iCnt];
            }

            return lookup;
        }

        private createHierarchy(dictionary: Dictionary<Model.Issues.ResubmissionItem>): Model.Issues.ResubmissionItem {
            let root: Model.Issues.ResubmissionItem;

            for (let oid in dictionary) {
                let item = dictionary[oid];

                if (!item.ParentOID) {
                    root = item;
                } else {
                    const parent = dictionary[item.ParentOID];

                    if (parent) {
                        item.Parent = parent;
                    }
                }
            }

            return root;
        }

        public IsEmpty(): boolean {
            return !this.Collection.length;
        }

        public GetByElementOID(elementIdentifier: string): Model.Issues.ResubmissionItem {
            for (let rCnt = 0, rLen = this.Collection.length; rCnt < rLen; rCnt++) {
                const resubItem = this.Collection[rCnt];

                if (resubItem.ElementOID === elementIdentifier) {
                    return resubItem;
                }
            }
        }

        public GetParentElementOIDByElementOID(elementIdentifier: string): string | null {
            const resubitem = this.GetByElementOID(elementIdentifier);

            if (!resubitem) {
                return;
            }

            const parentResubitem = this.Dictionary[resubitem.ParentOID];

            return parentResubitem ? parentResubitem.ElementOID : null;
        }
    }
}
