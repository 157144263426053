abstract class PdfDocument {
    protected static readonly totalPagesExp: string = "{tpc}";

    protected document: any;

    protected currentY: number;

    protected constructor(format: string, orientation: 'portrait' | 'landscape') {
        this.document = new window.jspdf.jsPDF({
            format: format,
            orientation: orientation,
            unit: 'mm'
        });

        this.document.page = 1; //First page is already created within constructor of jsPDF
        this.currentY = 10;
        this.CreateFooter();
    }

    public AddPage(size: string, orientation: 'portrait' | 'landscape', createFooter: boolean = true) {
        this.document.addPage(size, orientation);
        this.document.page++;
        this.currentY = 10;

        if (!createFooter) {
            return;
        }

        this.CreateFooter();
    }

    public Create(): void { };

    public SaveAndOpen(): void {
        if (typeof this.document.putTotalPages === 'function') {
            this.document.putTotalPages(PdfDocument.totalPagesExp);
        }

        const filename = Utils.GetValidFilename(this.GetFilename(), '.pdf');

        if (!Utils.IsSet(filename)) {
            Utils.Message.Show(
                i18next.t('PDF.Core.InvalidFilename.MessageHeader'),
                i18next.t('PDF.Core.InvalidFilename.MessageBody', {
                    Filename: Utils.EscapeHTMLEntities(this.GetFilename())
                }),
                { OK: true });
            return;
        }

        if (!Session.IsSmartDeviceApplication) {
            this.document.save(filename);
            return;
        }

        const pdfOutput = this.document.output('blob', { filename: filename });
        const filepath = Utils.GetResourcesPath() + 'pdf/' + encodeURIComponent(filename);

        window.resolveLocalFileSystemURL(cordova.file.dataDirectory, (resDir) => {
            resDir.getDirectory('pdf', { create: true }, (pdfDir) => {
                pdfDir.getFile(filename, { create: true, exclusive: false }, (fileEntry) => {
                    fileEntry.createWriter((writer) => {
                        writer.onwrite = (evt) => {
                            cordova.plugins.fileOpener2.open(
                                filepath,
                                'application/pdf',
                                { error: (error: any) => this.onOpenFileError(filepath, error) });
                        };

                        writer.onerror = (error) => this.onCreateFileError(filepath, error);

                        writer.write(pdfOutput);
                    }, (error: any) => this.onCreateFileError(filepath, error));
                }, (error: any) => this.onCreateFileError(filepath, error));
            });
        });
    }

    private onOpenFileError = (filepath: string, error: any): void => {
        Utils.Message.Show(
            i18next.t('PDF.Core.OpeningError.MessageHeader'),
            i18next.t('PDF.Core.OpeningError.MessageBody', {
                Filepath: filepath,
                Code: (error || {}).code,
                Message: (error || {}).message
            }),
            { OK: true });
    };

    private onCreateFileError = (filepath: string, error: any) => {
        Utils.Message.Show(
            i18next.t('PDF.Core.CreationError.MessageHeader'),
            i18next.t('PDF.Core.CreationError.MessageBody', {
                Filepath: filepath,
                Code: (error || {}).code,
                Message: (error || {}).message
            }), { OK: true });
    };

    protected abstract GetFilename(): string;

    protected CreateFooter() {
        this.document.setFontSize(9);
        this.SetFontStyle('', 'normal');
        this.document.textEx(this.getCreatedByText(), 10,
            this.document.internal.pageSize.getHeight() - 15,
            'left',
            'top');
        this.document.textEx(this.GetBusinessSecretText(),
            this.document.internal.pageSize.getWidth() / 2,
            this.document.internal.pageSize.getHeight() - 15,
            'center',
            'top');
        this.document.textEx(this.GetPageText(),
            this.document.internal.pageSize.getWidth() - 10,
            this.document.internal.pageSize.getHeight() - 15,
            'right',
            'top');
    }

    protected getCreatedByText(): string {
        const todayString = Utils.DateTime.DateToString(new Date(), false, true);

        return i18next.t('PDF.Core.CreatedByAwenko', { Date: todayString });
    }

    protected GetBusinessSecretText(): string {
        return i18next.t('PDF.Core.BusinessSecret');
    }

    protected GetPageText(): string {
        return i18next.t('PDF.Core.Page', { CurrentPage: this.document.page, MaxPage: PdfDocument.totalPagesExp });
    }

    protected FitTable(tableOptions: any) {
        this.document.autoTable(tableOptions);

        this.currentY = this.document.lastAutoTable.finalY;
    }

    protected LoadImage(identifier: string, url: string): Deferred {
        const deferred = $.Deferred();
        const image = new Image();
        image.crossOrigin = 'use-credentials';

        image.onload = function() {
            const canvas = document.createElement('canvas');
            canvas.width = (<HTMLImageElement>this).naturalWidth;
            canvas.height = (<HTMLImageElement>this).naturalHeight;

            //next three lines for white background in case png has a transparent background
            const ctx = canvas.getContext('2d');
            ctx.fillStyle = '#fff';  /// set white fill style
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            canvas.getContext('2d').drawImage(<HTMLImageElement>this, 0, 0);

            deferred.resolve({
                Identifier: identifier,
                DataURL: canvas.toDataURL('image/jpeg'),
                Canvas: canvas,
                Width: canvas.width,
                Height: canvas.height
            });
        };

        image.onerror = () => {
            // Trotz Fehler als erfolgreich auflösen, da das Bild dann nicht angezeigt werden soll
            deferred.resolve();
        };

        image.src = url;
        return deferred.promise();
    }

    protected SetFontStyle(style: '' | 'bold' | null, weight: 'normal' | null = null) {
        const currentFont = this.document.getFont();

        this.document.setFont(currentFont.fontName, style, weight);
    }

    protected static ToPointUnit(sourceUnit: PdfUnits, value: number) {
        return value * PdfDocument.getUnitFactor(sourceUnit);
    }

    protected static FromPointToTargetUnit(targetUnit: PdfUnits, value: number) {
        return value / PdfDocument.getUnitFactor(targetUnit);
    }

    protected static ConvertUnit(sourceUnit: PdfUnits, targetUnit: PdfUnits, value: number) {
        return PdfDocument.FromPointToTargetUnit(targetUnit, PdfDocument.ToPointUnit(sourceUnit, value));
    }

    private static getUnitFactor(unit: PdfUnits) {
        switch (unit) {
            case 'pt':
                return 1;
            case 'mm':
                return 72 / 25.4000508;
            case 'cm':
                return 72 / 2.54000508;
            case 'in':
                return 72;
            case 'px':
                return 72 / 96;
            case 'pc':
                return 12;
            case 'em':
                return 12;
            case 'ex':
                return 6;
        }
    }
}

class PdfTable {
    private readonly header: PdfCell[][];
    private readonly body: PdfCell[][];
    private readonly footer: PdfCell[][];
    private readonly columnStyles: {
        [Key: number]: PdfTableCellStyle;
    };

    constructor() {
        this.header = [];
        this.body = [];
        this.footer = [];
        this.columnStyles = {};
    }

    public AddHeaderRow(cells: PdfCell[]): void {
        this.header.push(cells);
    }

    public AddBodyRow(cells: PdfCell[]): void {
        this.body.push(cells);
    }

    public AddFooterRow(cells: PdfCell[]): void {
        this.footer.push(cells);
    }

    public SetColumnStyles(column: number, style: PdfTableCellStyle): void {
        this.columnStyles[column] = style;
    }

    public GetOptions(): any {
        const options: any = {};

        if (this.header.length) {
            options.head = this.prepareRows(Utils.CloneArray(this.header));
        }

        if (this.body.length) {
            options.body = this.prepareRows(Utils.CloneArray(this.body));
        }

        if (this.footer.length) {
            options.foot = this.prepareRows(Utils.CloneArray(this.footer));
        }

        return options;
    }

    private prepareRows(rows: PdfCell[][]): PdfCell[][] {
        const rowLen = rows.length;

        for (let rowCnt = 0; rowCnt < rowLen; rowCnt++) {
            const columns = rows[rowCnt];
            const columnLen = columns.length;

            let columnCorrection = 0;
            for (let columnCnt = 0; columnCnt < columnLen; columnCnt++) {
                let cell = columns[columnCnt];

                if (!cell) {
                    // Leere Zelle erstellen
                    cell = { content: '' };
                    columns[columnCnt] = cell;
                } else if (typeof cell === 'string') {
                    cell = { content: cell };
                    columns[columnCnt] = cell;
                }

                const mergedStyles = this.getCellStyle(columnCnt + columnCorrection, cell.styles);

                if (mergedStyles) {
                    cell.styles = mergedStyles;
                }

                if (cell.colSpan) {
                    columnCorrection += cell.colSpan - 1;
                }
            }
        }

        return rows;
    }

    private getCellStyle(column: number, cellStyles: PdfTableCellStyle): PdfTableCellStyle {
        const columnStyle = this.columnStyles[column];

        if (!Utils.IsSet(columnStyle)) {
            return cellStyles;
        }

        const style = Utils.CloneObject(columnStyle);

        if (!Utils.IsSet(cellStyles)) {
            return style;
        }

        for (let s in cellStyles) {
            style[s] = cellStyles[s];
        }

        return style;
    }
}

type PdfCell = string | {
    content: string,
    colSpan?: number,
    styles?: PdfTableCellStyle
}

type PdfTableCellStyle = {
    font?: 'helvetica' | 'times' | 'courier'
    fontStyle?: PdfTableFontStyle
    overflow?: PdfTableOverflow,
    fillColor?: PdfTableColor,
    textColor?: PdfTableColor,
    cellWidth?: PdfTableCellWidth,
    minCellWidth?: number,
    minCellHeight?: number,
    halign?: PdfTableHorizontalAlign,
    valign?: PdfTableVerticalAlign,
    fontSize?: number,
    cellPadding?: PdfTablePadding,
    lineColor?: PdfTableColor,
    lineWidth?: number,
};

type PdfTableFontStyle = 'normal' | 'bold' | 'italic' | 'bolditalic';
type PdfTablePadding = number | { top: number, right: number, bottom: number, left: number };
type PdfTableCellWidth = 'auto' | 'wrap' | number;
type PdfTableVerticalAlign = 'top' | 'middle' | 'bottom';
type PdfTableHorizontalAlign = 'left' | 'center' | 'right';
type PdfTableColor = false | number | number[];
type PdfTableOverflow = 'linebreak' | 'ellipsize' | 'visible' | 'hidden';

type PdfUnits = 'pt' | 'mm' | 'cm' | 'in' | 'px' | 'pc' | 'em' | 'ex';

type PdfImageData = {
    Identifier: string,
    DataURL: string,
    Canvas?: HTMLCanvasElement,
    Width: number,
    Height: number
};