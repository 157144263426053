/// <reference path="../definitions.d.ts"  />
/// <reference path="./utils.analytics.ts"  />
/// <reference path="../model/model.logger.ts"  />
/// <reference path="../model/model.synchronisation.ts"  />

module Utils.Synchronisation.Upload {
    export interface IDependency {
        ID: number;
        OID: string;
        StorageName: Enums.DatabaseStorage;
    }

    let entityCounter: number,
        dependencies: { [id: string]: IDependency },
        recordedEntities: Array<Model.Synchronisation.IEntityDescription>,
        updatedElements: Array<Model.Elements.Element>,
        uploadDeferred: Deferred,
        _logger: Model.ILogger,
        conflictsCounter: Dictionary<number> = {},
        ignoredSyncEntities: Dictionary<boolean>,
        invalidateIgnoredSyncEntities: Dictionary<boolean>, // TODO vorübegehende quick & dirty Lösung, um Abhängigkeiten aufzulösen
        erroneousSyncEntities: Dictionary<boolean>,
        firstSyncError: { response, request, syncEntity };

    function onAfterObjectUpload(obj: { OID: string }, storageName: Enums.DatabaseStorage, id: number, syncEntity: Model.Synchronisation.IEntityDescription): void {
        if (!obj) {
            determineUploadBehaviour(syncEntity);
            return;
        }

        logSyncData('Upload', 'Entity ' + id + ' uploaded', obj);

        const isRecorditem = storageName === Enums.DatabaseStorage.Recorditems;
        const isIssue = storageName === Enums.DatabaseStorage.Issues;

        if (!isRecorditem && !isIssue) {
            window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntity.OID)
                .always(() => { determineUploadBehaviour(syncEntity) });
            return;
        }

        let updateProcess = window.Database.GetSingleByKey(storageName, obj.OID)
            .then(function(dbEntity: any) {
                if (!dbEntity) {
                    return;
                }

                let writeIntoDatabase = false;

                if (dbEntity.HasNotBeenSynced) {
                    delete dbEntity.HasNotBeenSynced;
                    writeIntoDatabase = true;
                }

                // neue ID an Issues und Recorditem setzen
                if (!dbEntity.ID) {
                    dbEntity.ID = id;
                    writeIntoDatabase = true;
                    dependencies[dbEntity.OID] = {
                        ID: id,
                        OID: dbEntity.OID,
                        StorageName: storageName
                    };
                }

                // RecordItem aktualisieren
                if (isRecorditem) {
                    delete dbEntity.IsUnsynced;
                    dbEntity.IsHistorical = true;
                    writeIntoDatabase = true;
                }

                let updateDeferred: Deferred;
                if (writeIntoDatabase) {
                    // DB Eintrag aktualisieren, wenn erforderlich
                    if (isIssue) {
                        updateDeferred = DAL.Issues.SaveToDatabase(dbEntity, false);
                    } else if (isRecorditem) {
                        updateDeferred = window.Database.SetInStorageNoChecks(storageName, dbEntity);
                        updateElement(dbEntity);
                    }
                } else {
                    updateDeferred = $.Deferred().resolve();
                }

                if (isIssue && isIssueOpenedForRecording(dbEntity)) {
                    IssueView.UpdateIssue(dbEntity);
                }

                return updateDeferred;
            })
            .then(() => window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntity.OID));

        if (isIssue) {
            // wenn Issue, alle SyncEntities mit gleicher IssueOID laden und IssueID aktualisieren
            updateProcess = updateProcess.then(() => window.Database.GetManyByKeys(Enums.DatabaseStorage.SyncEntities, [syncEntity.OID], 'IDX_IssueOID'))
                .then((syncEntities: Model.Synchronisation.IEntityDescription[]) => {
                    if (!syncEntities || !syncEntities.length) {
                        return;
                    }

                    const entitiesToUpdate: Model.Synchronisation.IEntityDescription[] = [];
                    for (let i = 0; i < syncEntities.length; i++) {
                        const entity = syncEntities[i];
                        if (!entity.IssueID &&
                            entity.IssueOID == syncEntity.OID) {
                            entity.IssueID = id;
                            entitiesToUpdate.push(entity);
                        }
                    }

                    if (!entitiesToUpdate.length) {
                        return;
                    }

                    return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, entitiesToUpdate);
                });
        }

        updateProcess.always(() => { determineUploadBehaviour(syncEntity) });
    }

    function onAfterObjectDeleteComment(syncEntity: Model.Synchronisation.IEntityDescription): void {
        if (!syncEntity) {
            determineUploadBehaviour(syncEntity);
            return;
        }

        logSyncData('Delete', Enums.SyncEntityType[syncEntity.Type] + ' ' + syncEntity.OID + 'deleted');

        window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntity.OID)
            .always(() => { determineUploadBehaviour(syncEntity) });
    }

    function onAfterFileTransfer(syncEntity: Model.Synchronisation.IEntityDescription): void {
        if (syncEntity) {
            logSyncData('Upload', 'File for entity ' + syncEntity.OID + ' successfully uploaded.', syncEntity);
        }

        // SyncEntity bei Erfolg aus DB entfernen
        window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntity.OID)
            .always(() => { determineUploadBehaviour(syncEntity) });
    }

    function onAfterAdditionalDataUpload(serviceEntity: any, storageName: string, id: number, syncEntity: Model.Synchronisation.IEntityDescription): void {
        window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntity.OID)
            .then(() => { determineUploadBehaviour(syncEntity) });
    }

    function onAfterEntitiesLoaded(entityCollection: Array<Model.Synchronisation.IEntityDescription>): void {
        // early exit
        if (!entityCollection || !entityCollection.length) {
            finishUpload();
            return;
        }

        const preparedEntities = [];

        entityCounter = -1;
        dependencies = {};

        // Sync Entitäten aufbauen
        for (let eCnt = 0, eLen = entityCollection.length; eCnt < eLen; eCnt++) {
            const obj = entityCollection[eCnt];
            entityCollection[eCnt] = null;

            const entity = Model.Synchronisation.BuildEntityDescription(obj);
            if (entity) {
                preparedEntities.push(entity);
            } else {
                // unbekannte Entitäten werden entfernt
                window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, obj.OID);
            }
        }

        // nicht mehr benötigtes Array verwerfen
        entityCollection = null;

        // Abhängigkeiten und Upload-Reihenfolge aufbauen
        recordedEntities = new Model.Synchronisation.Tree(preparedEntities, true).Entities;

        if (!(recordedEntities || []).length) {
            finishUpload();
            return;
        }

        // beginne mit synchronisiert des ersten Entities in der Liste
        determineUploadBehaviour();
    }

    function onUploadError(xhr, status: string, errorText: string, serviceEntity: any, syncEntityDescription: Model.Synchronisation.IEntityDescription, request): void {
        if (xhr.status === Enums.HttpStatusCode.Conflict && serviceEntity && syncEntityDescription) {
            handleUploadConflicts(xhr.responseText, serviceEntity, syncEntityDescription)
                .then(() => window.Database.GetAllFromStorage(Enums.DatabaseStorage.SyncEntities))
                .then(onAfterEntitiesLoaded)
                .fail(() => skipUpload(xhr, request, syncEntityDescription));
            return;
        }

        if (xhr.status === Enums.HttpStatusCode.Bad_Request && serviceEntity && syncEntityDescription &&
            syncEntityDescription.Type === Enums.SyncEntityType.Issue && serviceEntity.IsDeleted &&
            typeof serviceEntity.Type === 'undefined' && serviceEntity.ID > 0) {
            handleDeletedIssueUpload(serviceEntity, syncEntityDescription)
                .then(() => window.Database.GetAllFromStorage(Enums.DatabaseStorage.SyncEntities))
                .then(onAfterEntitiesLoaded)
                .fail(() => skipUpload(xhr, request, syncEntityDescription));
            return;
        }

        if (syncEntityDescription &&
            Utils.InArray([Enums.SyncEntityType.RecorditemAdditionalFile, Enums.SyncEntityType.RecorditemValueFile, Enums.SyncEntityType.IssueFile], syncEntityDescription.Type)) {
            const filename = xhr.file;

            logSyncData('Upload', 'File upload failed with error code ' + xhr.status + '. Filename: ' + filename, syncEntityDescription);

            let exceptionText: string;
            let deleteDeferred: Deferred;

            if (xhr.status) {
                switch (xhr.status) {
                    case Enums.HttpStatusCode.Unauthorized:
                        // Abbruch des Uploads durch [entityCounter] im nächsten Schritt erzwingen (mit korrektem loggen des Fehlers)
                        entityCounter = recordedEntities.length;

                        Utils.Http.ShowErrorMessage(Enums.HttpMethod.Post,
                            xhr.url,
                            xhr.status,
                            'Unauthorized',
                            '<br /><br />' + i18next.t('SyncCenter.Error.Unauthorized'));
                        break;

                    case cordova.plugin.http.ErrorCode.GENERIC:
                        // Fehlermeldung primär auf Android Geräten
                        if (xhr.error && /No such file or directory/i.test(xhr.error)) {
                            // TODO vorübegehende Lösung, um fehlerhafte (nicht vorhanden) Bilder als Abhängigkeit rauszunehmen
                            invalidateIgnoredSyncEntities[syncEntityDescription.OID] = true;

                            deleteDeferred = Utils.CheckIfFileExists((<Model.Synchronisation.IFileEntityDescription>syncEntityDescription).Filename)
                                .then((fileExists: boolean) => {
                                    if (fileExists) {
                                        // Datei existiert, wird versucht bei nächster Synchronisation zu übertragen
                                        return $.Deferred().reject();
                                    }

                                    return window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntityDescription.OID);
                                });

                            exceptionText = `File '${filename}' missing`;
                        } else {
                            exceptionText = SyncCenter.GetStatusCodeMessage(xhr.status);
                        }
                        break;

                    case Enums.HttpStatusCode.Internal_Server_Error:
                        // iOS Sonderfall status 500 => prüfen ob Datei existiert, Bild als Abhängigkeit rausnehmen
                        if (xhr.error && /Could not add file to post body/i.test(xhr.error)) {
                            // TODO vorübegehende Lösung, um fehlerhafte (nicht vorhanden) Bilder als Abhängigkeit rauszunehmen
                            invalidateIgnoredSyncEntities[syncEntityDescription.OID] = true;
                            exceptionText = xhr.error;

                            deleteDeferred = Utils.CheckIfFileExists((<Model.Synchronisation.IFileEntityDescription>syncEntityDescription).Filename)
                                .then((fileExists: boolean) => {
                                    if (fileExists) {
                                        // Datei existiert, wird versucht bei nächster Synchronisation zu übertragen
                                        return $.Deferred().reject();
                                    }

                                    // Sync Entity aus Synchronisation entfernen
                                    return window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntityDescription.OID);
                                });
                        }
                        break;

                    case Enums.HttpStatusCode.Conflict:
                        if (xhr.error && /Recorditem not found/i.test(xhr.error)) {
                            // Fix zu v3.34.6: Beim Löschen der Erfassung auf iOS wurden nicht alle Einträge entfernt
                            // Es kann somit sein, dass Bilder übrig bleiben, für die es jedoch kein Recorditem gibt,
                            // da es bereits auf dem Gerät vor der Synchronisation gelöscht wurde.
                            // Existiert auf dem Gerät kein passendes RecordItem mehr, kann das SyncEntity entfernt werden.
                            // Mit zusätzlicher Ergänzung aus #9660 (3.37.4)
                            deleteDeferred = handleMissingRecorditemForFile(<Model.Synchronisation.RecordItemFileEntityDescription>syncEntityDescription)
                        }
                        break;
                }

                exceptionText = exceptionText || SyncCenter.GetStatusCodeMessage(xhr.status);
            }

            if (!!exceptionText) {
                Utils.Analytics.TrackException(Enums.AnalyticsExceptionType.Upload, new Model.Errors.SyncError(exceptionText));
            }

            if (deleteDeferred) {
                deleteDeferred.then(() => window.Database.GetAllFromStorage(Enums.DatabaseStorage.SyncEntities))
                    .then(onAfterEntitiesLoaded)
                    .fail(() => skipUpload(xhr, request, syncEntityDescription));
                return;
            }
        }

        if (xhr.status == -4) {
            // Timeout - Abbruch des Uploads erzwingen
            entityCounter = recordedEntities.length;
        }

        skipUpload(xhr, request, syncEntityDescription);
    }

    function skipUpload(xhr, request, entityDescription: Model.Synchronisation.IEntityDescription): void {
        if (xhr) {
            logSyncData('Upload', 'Upload failed with status ' + xhr.status, entityDescription);
        }

        ignoredSyncEntities[entityDescription.OID] = true;
        erroneousSyncEntities[entityDescription.OID] = true;
        if (!firstSyncError) {
            firstSyncError = {
                response: xhr,
                request: request,
                syncEntity: entityDescription
            };
        }

        determineUploadBehaviour(entityDescription);
    }

    function handleDeletedIssueUpload(serviceEntity: any, entityDescription: Model.Synchronisation.IEntityDescription): Deferred {
        return window.Database.GetManyByKeys(Enums.DatabaseStorage.Issues, [serviceEntity.ID], 'IDX_ID')
            .then((dbIssues: Model.Issues.RawIssue[]) => {
                if (!dbIssues || !dbIssues.length) {
                    return $.Deferred().reject();
                }

                const deletedIssuesOIDs = dbIssues
                    .filter((issue: Model.Issues.RawIssue) => issue.IsDeleted && typeof issue.Type === 'undefined' && issue.ID > 0 && issue.OID)
                    .map((deletedIssue: Model.Issues.RawIssue) => deletedIssue.OID);

                if (!deletedIssuesOIDs.length) {
                    return $.Deferred().reject();
                }

                return window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.Issues, deletedIssuesOIDs)
                    .then(() => window.Database.DeleteManyFromStorage(Enums.DatabaseStorage.ReducedIssues, deletedIssuesOIDs))
                    .then(() => window.Database.DeleteFromStorageByIndex(Enums.DatabaseStorage.Recorditems, 'IDX_IssueOID', deletedIssuesOIDs))
                    .then(() => window.Database.DeleteFromStorageByIndex(Enums.DatabaseStorage.Comments, 'IDX_IssueOID', deletedIssuesOIDs))
                    .then(() => window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, entityDescription.OID))
                    .then(() => DAL.ScancodeInfos.DeleteFromDatabaseByIssueOID(deletedIssuesOIDs))
                    .then(() => {
                        for (let i = 0; i < deletedIssuesOIDs.length; i++) {
                            DAL.TreeCache.Global.removeIssue(<any>{ OID: deletedIssuesOIDs[i] });
                        }
                    });
            });
    }

    function handleMissingRecorditemForFile(syncEntityDescription: Model.Synchronisation.RecordItemFileEntityDescription): Deferred {
        return window.Database.GetSingleByKey(Enums.DatabaseStorage.SyncEntities, syncEntityDescription.DependingOnOID)
            .then((dbSyncEntity: Model.Synchronisation.IEntityDescription) => {
                if (dbSyncEntity) {
                    // Recorditem vorhanden, korrekte Sync Reihenfolge abwarten
                    return $.Deferred().reject();
                }

                if (syncEntityDescription.Type !== Enums.SyncEntityType.RecorditemAdditionalFile) {
                    // Recorditem zum Wert-Bild existiert nicht mehr, SyncEntity aus Synchronisation entfernen
                    return window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntityDescription.OID);
                }

                // Finde Vorgang mit diesem Bild
                const possibleTargetIssues = DAL.TreeCache.Global.getNode()
                    .getTotalIssues()
                    .filter((x) => x.Files && x.Files.length &&
                        x.Files.some((i) => i.Filename == syncEntityDescription.Filename));

                if (!possibleTargetIssues || !possibleTargetIssues.length) {
                    // Kein Vorgang gefunden der das Bild weiterverwenden kann, Sync-Entity dazu kann gänzlich gelöscht werden
                    return window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntityDescription.OID);
                }

                // Sync-Entity für das Bild den Vorgang umschreiben
                const newTargetIssue = possibleTargetIssues[0];
                const newSyncEntity = new Model.Synchronisation.IssueFileEntityDescription(syncEntityDescription.Filename, Enums.SyncEntityType.IssueFile, newTargetIssue);

                return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, newSyncEntity)
                    .then(() => {
                        return window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntityDescription.OID);
                    });
            });
    }

    function handleUploadConflicts(responseText: string, serviceEntity: any, syncEntityDescription: Model.Synchronisation.IEntityDescription): Deferred {
        const conflictCounter: number = conflictsCounter[syncEntityDescription.OID];

        if (conflictCounter == null) {
            conflictsCounter[syncEntityDescription.OID] = 1;
        } else {
            conflictsCounter[syncEntityDescription.OID]++;
        }

        if (conflictCounter >= 3) {
            return $.Deferred().reject().promise();
        }

        logSyncData('Upload', 'Resolving Conflicts', syncEntityDescription);

        if (syncEntityDescription.Type === Enums.SyncEntityType.SubSampleDelete &&
            responseText !== 'Resubmissionitem does not exist') {
            return window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntityDescription.OID);
        } else if (responseText === 'Resubmissionitem does not exist' && serviceEntity.IssueID) {
            return DAL.Issues.GetByID(serviceEntity.IssueID)
                .then(function(issue: Model.Issues.Issue) {
                    if (issue == null) {
                        return window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntityDescription.OID);
                    }

                    // Prüfe ob ResubmissionItem für das RecordItem existiert
                    const resubItemExists = issue.ResubmissionitemCollection.Dictionary[serviceEntity.ResubmissionitemOID] || false;

                    let recreateDeferred: Deferred;
                    const recItem: Model.Recorditem = serviceEntity;

                    if (!resubItemExists) {
                        // Alle Recorditems zum Issue laden
                        recreateDeferred = getIssueRecorditems(recItem)
                            .then((recorditems: Model.Recorditem[]) => {
                                if (!recorditems || !recorditems.length) {
                                    return;
                                }

                                const newResubItemsByRow: Dictionary<{ resubItems: Model.Issues.ResubmissionItem[], addNewResubitems: boolean }> = {};

                                // Alle Recorditems prüfen
                                for (let i = 0; i < recorditems.length; i++) {
                                    const tmpRec = recorditems[i];

                                    // prüfe ob ResubmissionItem evtl. bereits vorhanden ist
                                    if (issue.ResubmissionitemCollection &&
                                        issue.ResubmissionitemCollection.Dictionary[tmpRec.ResubmissionitemOID] ||
                                        !tmpRec.Row) {
                                        continue;
                                    }

                                    // ResubmissionItems für Teilprobenwert holen/erstellen
                                    let newResubInfo = newResubItemsByRow[tmpRec.Row];
                                    if (!newResubInfo) {
                                        const tmpResubItems: Model.Issues.ResubmissionItem[] = recreateResubItems(tmpRec, issue.ResubmissionitemCollection);
                                        if (!tmpResubItems) {
                                            continue;
                                        }

                                        newResubItemsByRow[tmpRec.Row] = newResubInfo = {
                                            resubItems: tmpResubItems,
                                            addNewResubitems: false
                                        };
                                    }

                                    for (let ri = 0; ri < newResubInfo.resubItems.length; ri++) {
                                        const resub = newResubInfo.resubItems[ri];
                                        if (resub.ElementOID == tmpRec.ElementOID &&
                                            tmpRec.ResubmissionitemOID) {
                                            if (!tmpRec.ResubmissionitemOID) {
                                                continue;
                                            }
                                            // ResubmissionItem OID aktualisieren
                                            resub.OID = tmpRec.ResubmissionitemOID;
                                            resub.ElementRevisionOID = tmpRec.ElementRevisionOID || resub.ElementRevisionOID;
                                            newResubInfo.addNewResubitems = true;
                                            break;
                                        }
                                    }
                                }

                                // neue ResubmissionItems dem Issue hinzufügen
                                let saveIssue = false;
                                for (const key in newResubItemsByRow) {
                                    if (!newResubItemsByRow.hasOwnProperty(key)) {
                                        continue;
                                    }

                                    const resubInfo = newResubItemsByRow[key];
                                    if (resubInfo.addNewResubitems) {
                                        issue.Resubmissionitems.push.apply(issue.Resubmissionitems, resubInfo.resubItems);
                                        saveIssue = true;
                                    }
                                }

                                // Aktualisierungen vorhanden, Issue speichern
                                if (saveIssue) {
                                    return DAL.Issues.SaveToDatabase(<any>issue.CopyRaw(), true);
                                }
                            });
                    } else if (issue.Resubmissionitems) {
                        // prüfe ob Resubitems ohne OID existieren
                        let saveIssue = false;
                        for (let i = 0; i < issue.Resubmissionitems.length; i++) {
                            const resubItem = issue.Resubmissionitems[i];
                            if (!resubItem.OID) {
                                resubItem.OID = uuid();
                                saveIssue = true;
                            }
                        }

                        // Aktualisierungen vorhanden, Issue speichern
                        if (saveIssue) {
                            return DAL.Issues.SaveToDatabase(<any>issue.CopyRaw(), true);
                        }
                    }

                    if (!recreateDeferred) {
                        // Aktualisierung des Vorgangs durchführen,
                        // da gelöschte Teilproben vorhanden sein könnten
                        recreateDeferred = $.Deferred().resolve();
                    }

                    return recreateDeferred.then(() => {
                        // neues SyncEntity um das aktualisierte Issue zu synchronisieren
                        const newTimestamp = new Date(syncEntityDescription.Timestamp);
                        newTimestamp.setSeconds(newTimestamp.getSeconds() - 1);
                        issue.ModificationTimestamp = newTimestamp;

                        const newSyncEntity = new Model.Synchronisation.IssuesEntityDescription(
                            issue, null
                        );

                        return window.Database.SetInStorage(Enums.DatabaseStorage.SyncEntities, newSyncEntity);
                    });
                });
        } else if (syncEntityDescription.Type === Enums.SyncEntityType.Recorditem && responseText == 'ID does not exist') {
            // Remove ID, save Recorditem, repeat sync
            delete serviceEntity.ID;
            return window.Database.SetInStorage(Enums.DatabaseStorage.Recorditems, serviceEntity);
        } else if (syncEntityDescription.Type === Enums.SyncEntityType.Issue &&
            responseText == 'AssignedRecorditem does not exist') {
            // Fix zu v3.23.0: CorrectiveActions Errors an gelöschten Recorditems
            // bei fehlenden Recorditems für Issues, prüfen ob das Recorditem noch in den SyncEntities vorliegt
            // ansonsten das AssignedRecorditemOID zurücksetzen, da das zugehörige Recorditem höchstwahrscheinlich gelöscht wurde
            if (serviceEntity.AssignedRecorditemOID && !serviceEntity.AssignedRecorditemID) {
                return window.Database.GetSingleByKey(Enums.DatabaseStorage.SyncEntities, serviceEntity.AssignedRecorditemOID)
                    .then((recordItemEntity: Model.Synchronisation.IEntityDescription) => {
                        if (recordItemEntity) {
                            // Recorditem wird vermutlich noch in nächsten Schritten synchronisiert
                            return $.Deferred().reject();
                        }

                        // Recorditem existiert nicht mehr, Verbindung dazu auflösen
                        return DAL.Issues.GetByOID(serviceEntity.OID);
                    })
                    .then(function(issue: Model.Issues.RawIssue) {
                        issue.AssignedRecorditemOID = null;
                        return DAL.Issues.SaveToDatabase(issue, true);
                    });
            }
        } else if (syncEntityDescription.Type === Enums.SyncEntityType.RecorditemComment &&
            responseText == 'Recorditem does not exist' && serviceEntity.AssignmentOID) {
            // Fix zu v3.34.6: Beim Löschen der Erfassung auf iOS wurden nicht alle Einträge entfernt
            // Es kann somit sein, dass Kommentare übrig bleiben, für die es jedoch kein Recorditem gibt,
            // da es bereits auf dem Gerät vor der Synchronisation gelöscht wurde.
            // Existiert auf dem Gerät kein passendes RecordItem mehr, kann der Kommentar weg.

            return window.Database.GetSingleByKey(Enums.DatabaseStorage.SyncEntities, serviceEntity.AssignmentOID)
                .then((recordItemEntity: Model.Synchronisation.IEntityDescription) => {
                    if (recordItemEntity) {
                        // Recorditem wird vermutlich noch in nächsten Schritten synchronisiert
                        return $.Deferred().reject();
                    }

                    // Recorditem existiert nicht mehr, Kommentar aus Synchronisation entfernen
                    return window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntityDescription.OID)
                        .then(() => window.Database.DeleteFromStorage(Enums.DatabaseStorage.Comments, serviceEntity.OID));
                });
        }

        return $.Deferred().reject();
    }

    function onDeleteError(xhr, status, errorText: string, syncEntity: Model.Synchronisation.IEntityDescription): void {
        if (xhr) {
            logSyncData('Delete', 'Delete failed with status ' + xhr.status, syncEntity);
        }

        ignoredSyncEntities[syncEntity.OID] = true;
        erroneousSyncEntities[syncEntity.OID] = true;

        if (!firstSyncError) {
            firstSyncError = {
                response: xhr,
                request: this,
                syncEntity: syncEntity
            };
        }

        determineUploadBehaviour(syncEntity);
    }

    function onEntityNotFoundError(syncEntity: Model.Synchronisation.IEntityDescription): void {
        if (_logger && _logger.IsEnabled()) {
            _logger.LogMessage('Upload', 'Entity ' + syncEntity.OID + ' not found');
        }

        window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntity.OID)
            .always(() => { determineUploadBehaviour(syncEntity) });
    }

    function onCommentEntityNotFoundError(syncEntity: Model.Synchronisation.IEntityDescription): void {
        if (_logger && _logger.IsEnabled()) {
            _logger.LogMessage('Delete', 'Comment ' + syncEntity.OID + ' not found');
        }

        window.Database.DeleteFromStorage(Enums.DatabaseStorage.SyncEntities, syncEntity.OID)
            .always(() => { determineUploadBehaviour(syncEntity) });
    }

    function getIssueRecorditems(recordItem: Model.Recorditem): Deferred {
        return window.Database.GetManyByKeys(Enums.DatabaseStorage.Recorditems, [recordItem.IssueID], "IDX_IssueID");
    }

    function recreateResubItems(recorditem: Model.Recorditem, resubitemCollection: Model.ResubmissionitemCollection): Model.Issues.ResubmissionItem[] | null {
        if (!recorditem || !recorditem.ElementOID) {
            return null;
        }

        // find group
        const resubItem = resubitemCollection.Collection
            .filter(resubItem => resubItem.ElementOID === recorditem.ElementOID)
            .sort(Utils.SortByRow)[0];
        if (!resubItem) {
            return null;
        }

        const groupElement = resubitemCollection.Dictionary[resubItem.ParentOID];
        if (!groupElement) {
            return null;
        }

        const row = recorditem.Row;
        const newResubGroupOID = uuid();

        // create head resubitem
        const newResubItems: Model.Issues.ResubmissionItem[] = [{
            OID: newResubGroupOID,
            ParentOID: resubitemCollection.Root.OID,
            ElementOID: groupElement.ElementOID,
            ElementRevisionOID: groupElement.ElementRevisionOID,
            IsRecordingLocked: false,
            Row: row
        }];

        // get all parameter elements
        const subsampleParameters = resubitemCollection.Collection.filter(resubItem => resubItem.ParentOID === groupElement.OID);
        if (!subsampleParameters || !subsampleParameters.length) {
            return newResubItems;
        }

        // create parameters resubitems
        for (let i = 0; i < subsampleParameters.length; i++) {
            const param = subsampleParameters[i];

            newResubItems.push({
                ParentOID: newResubGroupOID,
                OID: uuid(),
                ElementOID: param.ElementOID,
                ElementRevisionOID: param.ElementRevisionOID,
                IsRecordingLocked: false,
                Row: row
            });
        }

        return newResubItems;
    }

    function isIssueOpenedForRecording(issue: Model.Issues.Issue): boolean {
        if (!Utils.InArray([Enums.View.Form, Enums.View.Scheduling, Enums.View.Inspection], View.CurrentView)) {
            return false;
        }

        const currentlyOpenedIssue = IssueView.GetCurrentIssue();

        if (!currentlyOpenedIssue) {
            return false;
        }

        if (currentlyOpenedIssue.ID && currentlyOpenedIssue.ID === issue.ID) {
            return true;
        }

        return issue.OID === currentlyOpenedIssue.OID || issue.PrecedingOID === currentlyOpenedIssue.OID;
    }

    function finishUpload(): Deferred {
        return updateElementRecorditems()
            .then(() => {
                if (Utils.HasProperties(erroneousSyncEntities)) {
                    if (firstSyncError) {
                        const error = new Model.Errors.HttpError(firstSyncError.response.error, firstSyncError.response);
                        return uploadDeferred.reject(error, firstSyncError.request, firstSyncError.syncEntity);
                    } else {
                        return uploadDeferred.reject();
                    }
                } else {
                    return uploadDeferred.resolve();
                }
            });
    }

    function updateElementRecorditems(): Deferred {
        if (!updatedElements || !updatedElements.length) {
            return $.Deferred().resolve();
        }

        const elementsToStore = updatedElements.map(function(element) {
            const recorditem = element.LastRecorditem;
            // removes Parent & Children property before saving
            const copy = Utils.CloneElement(element);

            (<any>copy).LastRecorditem = recorditem;

            return copy;
        });

        return window.Database.SetInStorageNoChecks(Enums.DatabaseStorage.Elements, elementsToStore);
    }

    function updateElement(recorditem: Model.Recorditem): void {
        let element = DAL.Elements.GetByOID(recorditem.ElementOID);

        if (element && element.Parent && element.Parent.Parent && element.Parent.Parent.Type !== Enums.ElementType.Form) {
            updatedElements = updatedElements || [];

            element = Utils.CloneElement(element);

            element.LastRecorditem = recorditem;

            DAL.Elements.Update(element);
            updatedElements.push(element);
        }
    }

    function determineUploadBehaviour(lastSyncEntity?: Model.Synchronisation.IEntityDescription): Deferred {
        if (lastSyncEntity &&
            (erroneousSyncEntities[lastSyncEntity.OID] || !ignoredSyncEntities[lastSyncEntity.OID])) {
            SyncCenter.OnAfterEntityUploaded(lastSyncEntity.OID, !erroneousSyncEntities[lastSyncEntity.OID]);
        }

        if (entityCounter < recordedEntities.length - 1) {
            return syncNextEntity();
        } else {
            return finishUpload();
        }
    }

    function syncNextEntity(): Deferred {
        const entity = recordedEntities[++entityCounter];

        if (!entity) {
            return entityCounter >= recordedEntities.length ?
                $.Deferred().resolve() :
                determineUploadBehaviour();
        }

        if (entity.Ignore) {
            ignoredSyncEntities[entity.OID] = true;
            return determineUploadBehaviour();
        }

        if (entity.Dependencies) {
            for (let i = 0, loopsCnt = entity.Dependencies.size(); i < loopsCnt; i++) {
                const dependingOnEntity = entity.Dependencies.get(i);
                // TODO vorübegehende Lösung mit invalidateIgnoredSyncEntities, um fehlerhafte Bilder als Abhängigkeit rauszunehmen
                if (ignoredSyncEntities[dependingOnEntity.OID] &&
                    !invalidateIgnoredSyncEntities[dependingOnEntity.OID]) {
                    ignoredSyncEntities[entity.OID] = true;
                    return determineUploadBehaviour();
                }
            }
        }

        if (Utils.InArray([
            Enums.SyncEntityType.Recorditem,
            Enums.SyncEntityType.Issue,
            Enums.SyncEntityType.SubSampleUpdate,
            Enums.SyncEntityType.SubSampleDelete,
            Enums.SyncEntityType.IssueComment,
            Enums.SyncEntityType.RecorditemComment,
            Enums.SyncEntityType.RecordingLockState,
            Enums.SyncEntityType.IssueDeleteComment,
            Enums.SyncEntityType.RecorditemDelete,
            Enums.SyncEntityType.RecorditemDeleteComment,
        ], entity.Type)) {
            entity
                .SetOnAfterUploadHandler(onAfterObjectUpload)
                .SetOnUploadErrorHandler(onUploadError)
                .SetOnEntityNotFoundErrorHandler(onEntityNotFoundError)
                .SetOnAfterDeleteCommentHandler(onAfterObjectDeleteComment)
                .SetOnDeleteCommentErrorHandler(onDeleteError)
                .SetOnCommentEntityNotFoundErrorHandler(onCommentEntityNotFoundError);
        } else if (Utils.InArray([Enums.SyncEntityType.RecorditemAdditionalFile, Enums.SyncEntityType.RecorditemValueFile, Enums.SyncEntityType.IssueFile], entity.Type)) {
            (<Model.Synchronisation.IFileEntityDescription>entity)
                .SetOnAfterFileUploadHandler(onAfterFileTransfer)
                .SetOnUploadErrorHandler(onUploadError)
                .SetOnEntityNotFoundErrorHandler(onEntityNotFoundError);
        } else if (Utils.InArray([Enums.SyncEntityType.InspectionCounter, Enums.SyncEntityType.SubIssueCounter], entity.Type)) {
            entity
                .SetOnAfterUploadHandler(onAfterAdditionalDataUpload)
                .SetOnUploadErrorHandler(onUploadError)
                .SetOnEntityNotFoundErrorHandler(onEntityNotFoundError);
        }

        logSyncData('Upload', 'Starting upload', entity);

        return entity.Upload();
    }

    function logSyncData(actionName: string, message: string, data?: any): void {
        if (!_logger || !_logger.IsEnabled()) {
            return;
        }

        try {
            _logger.LogData(actionName, message, data);
        }
        catch (ex) {
            console.warn('Failed log upload data:', ex);
        }
    }

    export function Start(logger: Model.ILogger): Deferred {
        _logger = logger;
        uploadDeferred = $.Deferred();
        conflictsCounter = {};
        ignoredSyncEntities = {};
        invalidateIgnoredSyncEntities = {};
        erroneousSyncEntities = {};
        firstSyncError = null;

        Utils.Spinner.UpdateText(i18next.t('Synchronization.SyncToServer'));

        // sync user settings to server (not on first sync)
        (Session.IsFirstSyncFinished ? UploadUserSettings() : $.Deferred().resolve(0))
            .then((numChanged: number) => {
                if (numChanged > 0) {
                    return Session.SaveSystemData(true);
                }
            })
            .always(() =>
                window.Database.GetAllFromStorage(Enums.DatabaseStorage.SyncEntities)
                    .then(onAfterEntitiesLoaded)
            );

        return uploadDeferred.promise();
    };

    export function UploadUserSettings(): Deferred {
        /*
        * Return Deferred with number ob changed settings
        */

        if (Session.LastKnownAPIVersion < 13) {
            return $.Deferred().resolve(0);
        }

        // upload changed user AppSettings
        const syncSettings = [];
        for (let key in Session.SettingsMetadata) {
            if (!Session.SettingsMetadata.hasOwnProperty(key)) {
                continue;
            }

            if (!Session.SettingsMetadata[key].HasUnsyncedChanges) {
                continue;
            }

            if (!Session.Settings.hasOwnProperty(key)) {
                continue;
            }

            syncSettings.push({
                Key: key,
                Value: Session.Settings[key]
            })
        }

        // early exit due to no settings to sync
        if (!syncSettings.length) {
            return $.Deferred().resolve(0);
        }

        return Utils.Http.Post('userappsettings', syncSettings)
            .then(() => {
                // reset HasUnsyncedChanges
                for (let key in Session.SettingsMetadata) {
                    if (!Session.SettingsMetadata.hasOwnProperty(key)) {
                        continue;
                    }
                    Session.SettingsMetadata[key].HasUnsyncedChanges = false;
                }

                return syncSettings.length;
            }, function(_response, _state, _error) {
                throw new Model.Errors.HttpError(_error, _response);
            });
    }

    export function GetDependency(oid: string): IDependency {
        if (dependencies.hasOwnProperty(oid)) {
            return dependencies[oid];
        }
    }
}
