//imports-start
/// <reference path="./dal/contacts.ts"  />
//// <reference path="./lib/handlebars.runtime.js"  />
/// <reference path="./model/model.color.ts"  />
/// <reference path="./templates.d.ts"  />
/// <reference path="./app/app.session.ts"  />
/// <reference path="./model/model.color.ts"  />
/// <reference path="./utils/utils.date-time.ts"  />
/// <reference path="./utils/utils.ts"  />
/// <reference path="./dal/roles.ts"  />
/// <reference path="./app/app.code-scanner.ts"  />
//imports-end

module HandlebarsHelpers {
    Handlebars.registerHelper('FixIOSFilepath', Utils.FixIOSFilepath);

    Handlebars.registerHelper('UserImage', function(userOID: string) {
        return Utils.GetUserImagePath(userOID);
    });

    Handlebars.registerHelper('TeamImage', function(teamOID: string) {
        const team = DAL.Teams.GetByOID(teamOID);

        if (!team || !team.ImagePath) {
            return './img/group.svg';
        }

        return team.ImagePath;
    });

    Handlebars.registerHelper('ImagePath', function(filename: string, resolution: string | null) {
        if (!arguments[0]) {
            return '';
        }

        if (!!arguments[1]) {
            resolution = arguments[1];
        }

        if (Utils.IsValidGuid(filename)) {
            // get filename from file object
            const file = DAL.Files.GetByOID(filename);
            filename = file ? file.Filename : null;
        } else if (!(/\w+\.[A-Za-z]{3,4}/ig).test(filename)) {
            // not a valid filename
            filename = null;
        }

        if (!filename) {
            return '';
        } else if (Session.IsSmartDeviceApplication) {
            if (Session.IsRunningOnIOS) {
                return Utils.FixIOSFilepath(Utils.GetResourcesPath() + filename);
            } else {
                return Utils.GetResourcesPath() + filename;
            }
        } else {
            if (!resolution || !Utils.InArray(['s', 'm', 'l', 'o'], resolution)) {
                resolution = 's';
            }

            return `${Session.BaseURI}images/${resolution}/${filename || ''}`;
        }
    });

    Handlebars.registerHelper('HistoricalImagePath', function() {
        let filename: string;

        if (!arguments[0]) {
            return '';
        }

        if ((/\w+.[A-Za-z]{3,4}/ig).test(arguments[0])) {
            filename = arguments[0];
        } else if (Utils.IsValidGuid(arguments[0])) {
            const file = DAL.Files.GetByOID(arguments[0]);
            filename = file.Filename;
        }

        return `${Session.BaseURI}images/s/${filename}`;
    });

    Handlebars.registerHelper('ElementHierarchy', function(argument: string | Model.Elements.Element, withoutSelectedElement: boolean, reverse: boolean, depth: number) {
        let hierarchy: string;

        if (!argument) {
            return;
        }

        if (typeof depth !== 'number') {
            depth = null;
        }

        if (typeof argument === 'string') {
            hierarchy = Utils.GetElementHierarchy(DAL.Elements.GetByOID(argument), withoutSelectedElement, reverse, depth);
        } else {
            hierarchy = Utils.GetElementHierarchy(argument, withoutSelectedElement, reverse, depth);
        }

        hierarchy = hierarchy || i18next.t('Misc.Unknown');

        return new Handlebars.SafeString(hierarchy);
    });

    Handlebars.registerHelper('Linebreaks', function(str: string) {
        return !!str ? new Handlebars.SafeString(str.replace(/\n/g, '<br />')) : '';
    });

    Handlebars.registerHelper('HTMLToNativeLinebreaks', function(str: string) {
        return !!str ? new Handlebars.SafeString(str.replace(/<br\s?\/?>/g, '\n')) : '';
    });

    Handlebars.registerHelper('ColoredProperty', function(propertyOID: string) {
        const property = DAL.Properties.GetByOID(propertyOID);
        const markup = Utils.RenderProperty(property);

        return new Handlebars.SafeString(markup);
    });

    Handlebars.registerHelper('ChangeDescription', function(propertyOID: string) {
        const property = DAL.Properties.GetByOID(propertyOID);

        if (property &&
            property.Type === Enums.PropertyType.Status &&
            !!property.ChangeDescription) {
            return property.ChangeDescription;
        }
    });

    Handlebars.registerHelper('PropertyBadge', function(propertyOID: string) {
        const property = DAL.Properties.GetByOID(propertyOID);
        const $property = $('<div></div>');

        if (!property) {
            return;
        }

        const contrastColor = new Model.Color(property.Color).GetContrastColor();

        $property.append(`<span class="property-badge">${property.Title}</span>`);
        $property.find('span').css({
            'background-color': property.Color,
            'color': contrastColor.getHex()
        });

        return new Handlebars.SafeString($property.html());
    });

    Handlebars.registerHelper('HighlightedProperty', function(propertyOID: string) {
        const property = DAL.Properties.GetByOID(propertyOID);
        const $property = $('<div></div>');

        if (!property || !property.Highlight) {
            return;
        }

        const contrastColor = new Model.Color(property.Color).GetContrastColor();

        $property.append(`<span class="property-badge">${property.Title}</span>`);
        $property.find('span').css({
            'background-color': property.Color,
            'color': contrastColor.getHex()
        });

        return new Handlebars.SafeString($property.html());
    });

    Handlebars.registerHelper('Evaluation', function(evaluation: Model.Elements.Evaluation, element: Model.Elements.Element) {
        if (!evaluation || !element) {
            return;
        }

        return Utils.Evaluation.ToString(evaluation, element);
    });

    Handlebars.registerHelper('Action', function(action) {
        if (!action) {
            return;
        }

        return Utils.ActionToString(action);
    });

    Handlebars.registerHelper('GetResponsibilities', function(issue: Model.Issues.IssueType, filter: string[], context) {
        let assignments = issue.ResponsibilityAssignments || {};
        if (filter && filter.length) {
            const newAssignments = {};
            for (let i = 0; i < filter.length; i++) {
                const name = filter[i];
                if (assignments[name]) {
                    newAssignments[name] = assignments[name];
                }
            }
            assignments = newAssignments;
        }
        return DAL.Issues.GetResponsibilitiesEx(assignments);
    });

    Handlebars.registerHelper('IssueTypeAbbreviation', function(type: Enums.IssueType) {
        return Utils.GetIssueAbbreviation(type);
    });

    Handlebars.registerHelper('HistoricalParameterValue', function(elementOID: string, revisionOID: string, value: any) {
        const preparedValue = Utils.GetPreparedParameterValue(elementOID, revisionOID, value, true);
        return new Handlebars.SafeString(preparedValue);
    });

    Handlebars.registerHelper('ParameterValue', function(elementOID: string, revisionOID: string, value: any) {
        const preparedValue = Utils.GetPreparedParameterValue(elementOID, revisionOID, value, false);
        return new Handlebars.SafeString(preparedValue);
    });

    Handlebars.registerHelper('ParameterCellValue', function(element: Model.Elements.Element, disabled: boolean, forceUsageOfCurrentElementRevision: boolean, forceHistorical_selfObject: boolean | object) {
        if (!element) {
            return;
        }

        if (element.Type == Enums.ElementType.Print) {
            // Prüfen ob Drucker vorhanden/eingerichtet sind
            // Button deaktivieren, wenn kein Drucker vorhanden ist
            const disableButton = disabled || !Session.IsSmartDeviceApplication ||
                (Utils.BluetoothDeviceManager && !Utils.BluetoothDeviceManager.IsBluetoothPrinterConnected());

            // 'Drucken'-Button anzeigen
            const $tmp = $(Templates.Parameters.PrintButton({
                Disabled: disableButton
            }));

            return new Handlebars.SafeString($tmp.html());
        } else if (element.LastRecorditem && !element.LastRecorditem.IsDummy) {
            if (element.Type === Enums.ElementType.Checkbox) {
                const $tmp = $(Templates.Parameters.BoolSelection({
                    PreviousValue: element.LastRecorditem.Value,
                    IsDummy: false
                }));

                return new Handlebars.SafeString($tmp.html());
            } else {
                const useHistorical = typeof forceHistorical_selfObject === 'boolean' ? forceHistorical_selfObject : false;
                const preparedParameterValue = Utils.GetPreparedParameterValue(
                    element.OID,
                    element.RevisionOID,
                    element.LastRecorditem.Value,
                    useHistorical,
                    forceUsageOfCurrentElementRevision,
                    (element.AdditionalSettings || {}).EnableQuickRecording || false,
                    element,
                    element.LastRecorditem);

                if (element.Type === Enums.ElementType.IndividualData) {
                    return preparedParameterValue;
                }

                return new Handlebars.SafeString(preparedParameterValue);
            }
        } else {
            if (element.Type === Enums.ElementType.Checkbox) {
                const $tmp = $(Templates.Parameters.BoolSelection({
                    Disabled: disabled,
                    IsDummy: true
                }));

                return new Handlebars.SafeString($tmp.html());
            } else if (
                element.Type === Enums.ElementType.ListBox ||
                element.Type === Enums.ElementType.MultiListBox) {
                const preparedParameterValue = Utils.GetPreparedParameterValue(
                    element.OID,
                    element.RevisionOID,
                    null,
                    false,
                    forceUsageOfCurrentElementRevision,
                    (element.AdditionalSettings || {}).EnableQuickRecording || false,
                    element);

                return new Handlebars.SafeString(preparedParameterValue);
            } else {
                let tmp = i18next.t('Misc.Unknown');

                if (element.Type !== Enums.ElementType.IndividualData) {
                    tmp = Utils.GetElementTypeString(element.Type);
                } else if (((element.AdditionalSettings || {}).Types || []).length) {
                    tmp = Utils.GetIndividualSchemaTitle(element.AdditionalSettings.Types[0]);
                }

                return new Handlebars.SafeString(`<span class="placeholder">${tmp}</span>`);
            }
        }
    });

    Handlebars.registerHelper('DateString', function(date: string | Date, forceLongDate: boolean) {
        if (!date) {
            return '';
        }

        return Utils.DateTime.ToString(date, forceLongDate || false);
    });

    Handlebars.registerHelper('TimeString', function(date: Date) {
        if (!date) {
            return '';
        }

        return Utils.DateTime.TimeToString(date);
    });

    Handlebars.registerHelper('DateTimestamp', function(date: Date | string, options: any) {
        if (!date) {
            return 0;
        }

        if (typeof date === 'string') {
            date = new Date(date);
        }

        return date.getTime();
    });

    Handlebars.registerHelper('Test', function(v1, op, v2, options) {
        let result: boolean;
        let moduloFactor: number;

        // Vorbereitung einer Modulo-Operation
        if (/%\d/.test(op)) {
            moduloFactor = parseInt(/\d/.exec(op)[0], 10);
            op = '%';
        }

        if (Utils.InArray(['<', '<=', '>', '>=', '===', '!=='], op)) {
            if (v1 instanceof Array) {
                v1 = (v1 || []).length;
            } else if ((typeof v1 === 'undefined' || v1 === null) && typeof v2 === 'number') {
                v1 = 0;
            }
        }

        switch (op) {
            case '!=':
                result = v1 != v2;/* jslint ignore:line */
                break;
            case '==':
                result = v1 == v2;/* jslint ignore:line */
                break;
            case '!==':
                result = v1 !== v2;
                break;
            case '===':
                result = v1 === v2;
                break;
            case '<':
                result = v1 < v2;
                break;
            case '<=':
                result = v1 <= v2;
                break;
            case '>':
                result = v1 > v2;
                break;
            case '>=':
                result = v1 >= v2;
                break;
            case '&&':
                result = v1 && v2;
                break;
            case '||':
                result = v1 || v2;
                break;
            case 'in':
                const tmpArray = !!v2 ? v2.split(',') : null;

                result = Utils.IsSet(v1) ? Utils.InArray(tmpArray, v1.toString()) : false;
                break;
            case '%':
                result = v1 % moduloFactor === v2;
                break;
            case 'length':
                result = !!(v1 || []).length;
                break;
        }

        if (options != null && typeof options.fn == 'function') {
            return result
                ? options.fn(this)
                : options.inverse(this);
        }

        return result;
    });

    Handlebars.registerHelper('IfContainsMany', function(values, requiredProperties, op, options) {
        const isBlockMethod = Utils.IsSet(options) &&
            options.fn instanceof Function &&
            options.inverse instanceof Function;
        let result;

        if (typeof requiredProperties !== 'string' &&
            !(requiredProperties instanceof requiredProperties)) {
            return isBlockMethod ? options.inverse(this) : false;
        }

        if (typeof values !== 'string' &&
            !(values instanceof values)) {
            return isBlockMethod ? options.fn(this) : true;
        }

        if (typeof requiredProperties === 'string') {
            requiredProperties = requiredProperties.split(',');
        }

        if (typeof values === 'string') {
            values = values.split(',');
        }

        if (op === 'and') {
            result = requiredProperties.all(function(value) {
                return Utils.InArray(values, value);
            });
        } else if (op === 'or') {
            result = requiredProperties.some(function(value) {
                return Utils.InArray(values, value);
            });
        }

        if (isBlockMethod) {
            return result ? options.fn(this) : options.inverse(this);
        }

        return result;
    });

    Handlebars.registerHelper('ElementTitle', function(oid: string) {
        const element = DAL.Elements.GetByOID(oid);

        if (element) {
            return new Handlebars.SafeString(element.Title);
        }

        return i18next.t('Misc.Unknown');
    });

    Handlebars.registerHelper('ElementExists', function(elementOID: string, options) {
        return DAL.Elements.Exists(elementOID) ?
            options.fn(this) :
            options.inverse(this);
    });

    Handlebars.registerHelper('ElementType', function(type: Enums.ElementType) {
        return Utils.GetElementTypeString(type);
    });

    Handlebars.registerHelper('TeamTitle', function(oid: string) {
        const team = DAL.Teams.GetByOID(oid);

        if (team) {
            return new Handlebars.SafeString(team.Title);
        }
    });

    Handlebars.registerHelper('ContactTitle', function(oid: string) {
        const contact = DAL.Contacts.GetByOID(oid);

        if (contact) {
            return new Handlebars.SafeString(contact.Title);
        }
    });

    Handlebars.registerHelper('ContactGroupTitle', function(oid: string) {
        const contactGroup = DAL.ContactGroups.GetByOID(oid);

        if (contactGroup) {
            return new Handlebars.SafeString(contactGroup.Title);
        }
    });

    Handlebars.registerHelper('UserTitleList', function(userIdentifiers: string[]) {
        if (!(userIdentifiers || []).length) {
            return '';
        }

        userIdentifiers = userIdentifiers.filter(function(identifier: string) {
            return DAL.Users.Exists(identifier);
        });

        return new Handlebars.SafeString(userIdentifiers.map(function(identifier: string) {
            return DAL.Users.GetByOID(identifier).Title;
        }).sort(Utils.SortByString).join(', '));
    });

    Handlebars.registerHelper('TeamTitleList', function(teamIdentifiers: string[]) {
        if (!(teamIdentifiers || []).length) {
            return '';
        }

        teamIdentifiers = teamIdentifiers.filter(function(identifier: string) {
            return DAL.Teams.Exists(identifier);
        });

        return new Handlebars.SafeString(teamIdentifiers.map(function(identifier: string) {
            return DAL.Teams.GetByOID(identifier).Title;
        }).sort(Utils.SortByString).join(', '));
    });

    Handlebars.registerHelper('RoleTitle', function(oid: string) {
        const role = DAL.Roles.GetByOID(oid);

        if (role) {
            return new Handlebars.SafeString(role.Title);
        }
    });

    Handlebars.registerHelper('UserTitle', function(oid: string) {
        const user = DAL.Users.GetByOID(oid);

        if (user) {
            return new Handlebars.SafeString(user.Title);
        }

        return i18next.t('Misc.Unknown');
    });

    Handlebars.registerHelper('FileTypeString', function(mimeType: string) {
        switch (mimeType) {
            case 'application/pdf':
                return i18next.t('FileType.PDF');
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.oasis.opendocument.text':
                return i18next.t('FileType.Text');
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.oasis.opendocument.spreadsheet':
                return i18next.t('FileType.Spreadsheet');
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.oasis.opendocument.presentation':
                return i18next.t('FileType.Presentation');
            case 'text/plain':
                return i18next.t('FileType.Plain');
            case 'application/x-zip-compressed':
            case 'application/x-7z-compressed':
            case 'application/gzip':
            case 'application/tar':
                return i18next.t('FileType.Archive');
            case 'video/avi':
            case 'video/mpeg':
            case 'video/mp4':
            case 'video/quicktime':
            case 'video/ogg':
                return i18next.t('FileType.Video');
            case 'audio/mpeg':
            case 'audio/mp4':
                return i18next.t('FileType.Audio');
            default:
                return mimeType;
        }
    });

    Handlebars.registerHelper('FileTypeClass', function(filename: string, mimeType: string) {
        if (!!filename && Utils.InArray(DAL.Files.GetVideoFileExtensions(), Utils.GetFileExtension(filename))) {
            return 'file-video';
        }

        switch (mimeType) {
            case 'application/pdf':
                return 'file-pdf';
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.oasis.opendocument.text':
                return 'file-word';
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.oasis.opendocument.spreadsheet':
                return 'file-excel';
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.oasis.opendocument.presentation':
                return 'file-powerpoint';
            case 'text/plain':
                return 'file-text';
            case 'application/x-zip-compressed':
            case 'application/x-7z-compressed':
            case 'application/gzip':
            case 'application/tar':
                return 'file-zip';
            case 'video/avi':
            case 'video/mpeg':
            case 'video/mp4':
            case 'video/quicktime':
            case 'video/ogg':
                return 'file-video';
            case 'audio/mpeg':
            case 'audio/mp4':
                return 'file-audio';
            default:

                return 'file-unknown';
        }
    });

    Handlebars.registerHelper('PropertyColor', function(oid: string) {
        const property = DAL.Properties.GetByOID(oid);

        return property ?
            property.Color :
            null;
    });

    Handlebars.registerHelper('PropertyTitle', function(oid: string) {
        const property = DAL.Properties.GetByOID(oid);

        return property ?
            new Handlebars.SafeString(property.Title || i18next.t('Misc.Untitled')) :
            i18next.t('Misc.Unknown');
    });

    Handlebars.registerHelper('EstimatedEffortWithUnit', function(estimatedEffort) {
        if (!Session || !Session.Client || !Session.Client.Settings || !Session.Client.Settings.EstimatedEffortSettings) {
            return;
        }

        let estimatedEffortSettings = Session.Client.Settings.EstimatedEffortSettings;

        if (typeof estimatedEffortSettings === 'string') {
            estimatedEffortSettings = JSON.parse(estimatedEffortSettings);
        }

        const unitOID = estimatedEffortSettings.UnitOID;
        const unit = DAL.Properties.GetByOID(unitOID);
        let unitTitle = '';

        if (Utils.IsSet(unit)) {
            unitTitle = (' ' + unit.Title) || '';
        }

        return Utils.IsSet(estimatedEffort) ?
            new Handlebars.SafeString(estimatedEffort.toString() + unitTitle) :
            i18next.t('Misc.Unknown');
    });

    Handlebars.registerHelper('IsSet', function(val, options) {
        return Utils.IsSet(val)
            ? options.fn(this)
            : options.inverse(this);
    });

    Handlebars.registerHelper('For', function(from, to, incrBy, options) {
        let returnValue = '';

        if (from === to || !incrBy) {
            return;
        }

        for (let cnt = from, len = to; cnt <= len; cnt += incrBy) {
            returnValue += options.fn(cnt);
        }

        return returnValue;
    });

    Handlebars.registerHelper('PropertyListString', function(properties: Array<any>, delimiter?: string) {
        const result = [];

        if (!(properties || []).length) {
            return;
        }

        if (typeof delimiter !== 'string') {
            delimiter = null;
        }

        for (let pCnt = 0, pLen = properties.length; pCnt < pLen; pCnt++) {
            const property = DAL.Properties.GetByOID(properties[pCnt]);
            if (property) {
                result.push(property.Title);
            }
        }

        result.sort(Utils.SortByString);

        delimiter = `${delimiter || ','} `;
        return new Handlebars.SafeString(result.join(delimiter));
    });

    Handlebars.registerHelper('PadLeft', function(value: string | number, n: number, str: string) {
        return Utils.PadLeft(value, n, str);
    });

    Handlebars.registerHelper('FormatPhoneNumber', Utils.FormatPhoneNumber);

    Handlebars.registerHelper('MakeUrl', function(url: string) {
        if (!/^http(s)?:\/\//.test(url)) {
            return 'http://' + url;
        }
        return url;
    });

    Handlebars.registerHelper('WebAddress', function(url: string, description: boolean, options) {
        if (!/^http(s)?:\/\//.test(url)) {
            url = 'http://' + url;
        }

        let extLinkClass = 'class="external-link"';
        if (description === false) {
            description = options.fn(this);
            extLinkClass = '';
        }

        url = Utils.EscapeHTMLEntities(url);

        const linkDescription = description || url;
        let aTag: string;

        if (!Session.IsSmartDeviceApplication) {
            aTag = `<a href="${url}" target="_blank" ${extLinkClass}>${linkDescription}</a>`;
        } else if (navigator.app && navigator.app.loadUrl instanceof Function) {
            aTag = `<a href="#" onclick="navigator.app.loadUrl(\'${url}\', { openExternal: true }); return false;" ${extLinkClass}>${linkDescription}</a>`;
        } else if (window.cordova && window.cordova.InAppBrowser) {
            aTag = `<a href="#" onclick="window.cordova.InAppBrowser.open('${url}', '_system'); return false;" ${extLinkClass}>${linkDescription}</a>`;
        } else {
            aTag = `<a href="#" onclick="window.open(\'${url}\', \'_system\'); return false;" ${extLinkClass}>${linkDescription}</a>`;
        }

        return new Handlebars.SafeString(aTag);
    });

    Handlebars.registerHelper('IssueTypeIndicator', function(issueType: Enums.IssueType) {
        const filename = Utils.GetIssueTypeIcon(issueType);

        return new Handlebars.SafeString(`<img src="./img/${filename}" />`);
    });

    Handlebars.registerHelper('UserHasRight', function(rightID: Enums.Rights, locationDependant: boolean, options) {
        return Session.User && Utils.UserHasRight(Session.User.OID, rightID, locationDependant) ?
            (options.fn ? options.fn(this) : true) :
            (options.inverse ? options.inverse(this) : false);
    });

    Handlebars.registerHelper('IsDateInFuture', function(date, options) {
        if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
            return options.inverse(this);
        }

        if (date.getTime() > new Date().getTime()) {
            return options.fn(this);
        }
    });

    Handlebars.registerHelper('IsToday', function(date, options) {
        const now = new Date();

        if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
            return options.inverse(this);
        }

        if (date.getDate() === now.getDate() &&
            date.getMonth() === now.getMonth() &&
            date.getFullYear() === now.getFullYear()) {
            return options.fn(this);
        }
    });

    Handlebars.registerHelper('IfShowDatePreposition', function(date, options) {
        const today = new Date().setHours(0, 0, 0, 0);
        const dayInMs = 1000 * 3600 * 24;
        const boundaries = {
            Left: new Date(today - dayInMs).getTime(),
            Right: new Date(today + dayInMs).getTime()
        };

        if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
            return options.inverse(this);
        }

        if (date.getTime() < boundaries.Left || date.getTime() >= boundaries.Right) {
            return options.fn(this);
        }

        return options.inverse(this);
    });

    Handlebars.registerHelper('GetAlternateImagePath', function(item) {
        // Guess possible object type
        if (!item) {
            return null;
        } else if (item instanceof Model.Users.User || item.hasOwnProperty('Teams') || item.hasOwnProperty('Rights')) {
            return 'img/user.svg';
        } else if (item instanceof Model.Teams.Team || item.hasOwnProperty('Users')) {
            return 'img/group.svg';
        } else if (item instanceof Model.Contacts.Contact || item.hasOwnProperty('Company') || item.hasOwnProperty('EmailAddress')) {
            return 'img/menu/kontakte.svg';
        } else if (item instanceof Model.ContactGroups.ContactGroup || item.hasOwnProperty('Contacts') || item.hasOwnProperty('Locations')) {
            return 'img/menu/kontaktgruppen.svg';
        }

        return 'img/user.svg';
    });

    Handlebars.registerHelper('NotNull', function() {
        // Returns first Value that is not 'null' or 'undefined'
        if (arguments.length > 1) {
            for (let aCnt = 0, aLen = arguments.length - 1; aCnt < aLen; aCnt++) {
                const value = arguments[aCnt];
                if (value !== null && typeof value != 'undefined') {
                    return value;
                }
            }
        }

        return null;
    });

    Handlebars.registerHelper('And', function() {
        let positiveResult = true;
        const options = arguments[arguments.length - 1];

        if (arguments.length > 1) {
            for (let aCnt = 0, aLen = arguments.length - 1; aCnt < aLen; aCnt++) {
                const value = arguments[aCnt];

                if (typeof value === 'undefined' || value === null || value === false) {
                    positiveResult = false;
                    break;
                }

                if (value instanceof Array && !value.length) {
                    positiveResult = false;
                    break;
                }
            }
        }

        if (typeof options.fn == 'function') {
            return positiveResult ? options.fn(this) : options.inverse(this);
        }

        return positiveResult;
    });

    Handlebars.registerHelper('Or', function() {
        let positiveResult = false;
        const options = arguments[arguments.length - 1];

        if (arguments.length > 1) {
            for (let aCnt = 0, aLen = arguments.length - 1; aCnt < aLen; aCnt++) {
                const value = arguments[aCnt];

                if (typeof value !== 'undefined' && value !== null && value !== false) {
                    if (value instanceof Array && !value.length) {
                        continue;
                    }

                    positiveResult = true;
                    break;
                }
            }
        }

        if (typeof options.fn == 'function') {
            return positiveResult ? options.fn(this) : options.inverse(this);
        }

        return positiveResult;
    });

    Handlebars.registerHelper('NOr', function() {
        let positiveResult = true;
        const options = arguments[arguments.length - 1];

        if (arguments.length > 1) {
            for (let aCnt = 0, aLen = arguments.length - 1; aCnt < aLen; aCnt++) {
                const argument = arguments[aCnt];

                if (argument instanceof Array) {
                    if (argument.length) {
                        positiveResult = false;
                        break;
                    }
                } else if (typeof argument !== 'undefined' && argument !== null && argument !== false) {
                    positiveResult = false;
                    break;
                }
            }
        }

        if (typeof options.fn == 'function') {
            return positiveResult ? options.fn(this) : options.inverse(this);
        }

        return positiveResult;
    });

    Handlebars.registerHelper('NAnd', function() {
        let positiveResult = false;
        const options = arguments[arguments.length - 1];

        if (arguments.length > 1) {
            for (let aCnt = 0, aLen = arguments.length - 1; aCnt < aLen; aCnt++) {
                if (!arguments[aCnt]) {
                    positiveResult = true;
                    break;
                }
            }
        }

        if (typeof options.fn == 'function') {
            return positiveResult ? options.fn(this) : options.inverse(this);
        }

        return positiveResult;
    });

    Handlebars.registerHelper('Not', function(value) {
        return !value;
    });

    Handlebars.registerHelper('Subtract', function(valueA: number, valueB: number) {
        if (!Utils.IsSet(valueA) || !Utils.IsSet(valueB) || isNaN(valueA) || isNaN(valueB)) {
            return;
        }

        return valueA - valueB;
    });

    Handlebars.registerHelper('GetImageWithMarks', function(image, additionalClasses: string, isHistorical: boolean, withoutStyles: boolean,
        isSelectable?: boolean, parentOID?: string) {
        if (!image) {
            return;
        }

        const settings: Utils.MarkSettings = {
            ImageSize: 's',
            File: image
        };

        if (withoutStyles) {
            settings.WithoutStyles = true;
        } else {
            settings.Width = '100%';
            settings.Height = '100%';
        }

        if (isHistorical && !!image.Filename) {
            image.HistoricalFilePath = `${Session.BaseURI}images/s/${image.Filename}`;
            settings.FilenameContainsPath = true;
        }

        if (image.IsBase64) {
            settings.IsBase64 = true;
            settings.FileContent = image.FileContent;
        }

        if (image.FilenameContainsPath) {
            settings.FilenameContainsPath = true;
        }

        if (typeof additionalClasses === 'string' && !!additionalClasses) {
            settings.Classes = additionalClasses.split('|');
        }


        if (isSelectable === true && !!image.Filename) {
            settings.Selectable = {
                IsSelectable: true,
                ParentOID: parentOID,
                Filename: image.Filename,
                Classname: 'selection',
                InputClassname: 'image-selection'
            }
        }

        return new Handlebars.SafeString($('<div></div>').append(Utils.GetImageWithMarks(settings)).html());
    });

    Handlebars.registerHelper('HasArrayItems', function(arr: any[]) {
        return !!(arr || []).length;
    });

    Handlebars.registerHelper('Log', function(value) {
        if (!console || !console.log) {
            return;
        }

        if (typeof value === 'undefined' ||
            value === null ||
            typeof value === 'string') {
            console.log(value);
        } else {
            console.log(JSON.stringify(value));
        }
    });

    Handlebars.registerHelper('Lang', function(context, withSilentWordBreaks: boolean) {
        if (!context) {
            return;
        }

        let text: string;
        let key: string;

        if (typeof context === 'string') {
            key = context;
            text = i18next.t(key);
        } else {
            key = context.hash.Key;
            const options = $.extend(true, {}, context.hash);

            for (let attr in context) {
                if (attr !== 'hash') {
                    options[attr] = context[attr];
                }
            }

            text = i18next.t(key, options);
        }

        if (!text) {
            text = key + ' not found';
        }

        if (typeof text === 'string') {
            return new Handlebars.SafeString(text);
        }

        return text;
    });

    Handlebars.registerHelper('FirstLetterOnly', function(text: string, upperCase: boolean) {
        if (!text) {
            return;
        }

        if (typeof text === 'string') {
            let firstLetter = text.charAt(0);

            return upperCase ? firstLetter.toUpperCase() : firstLetter;
        }
    });

    Handlebars.registerHelper('IsSmartDeviceApplication', function(options) {
        if (!Utils.IsSet(options) || !(options.fn instanceof Function)) {
            return Session.IsSmartDeviceApplication;
        }

        return Session.IsSmartDeviceApplication ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('IsNotRunningOnIOS', function(options) {
        if (!Utils.IsSet(options) || !(options.fn instanceof Function)) {
            return !Session.IsRunningOnIOS;
        }

        return !Session.IsRunningOnIOS ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('IsNotSmartDeviceApplication', function(options) {
        if (!Utils.IsSet(options) || !(options.fn instanceof Function)) {
            return !Session.IsSmartDeviceApplication;
        }

        return !Session.IsSmartDeviceApplication ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('IsNfcEnabled', function(options) {
        if (!Utils.IsSet(options) || !(options.fn instanceof Function)) {
            return Session.NfcEnabled;
        }

        return Session.NfcEnabled ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('IsImage', function(mimeType: string, options) {
        return (Utils.IsImage(mimeType)) ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('IsViewOpen', function(viewID: Enums.View, options) {
        return View.CurrentView === viewID ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('RecorditemCategoryColor', function(categoryOID: string, isDummy: boolean) {
        const category = DAL.Properties.GetByOID(categoryOID);

        if (category && category.Highlight) {
            const color = new Model.Color(category.Color);

            if (isDummy) {
                color.setAlpha(0.5);
            }

            return `border-color: ${color.getRGBA()};`;
        } else {
            return 'border-style: none;';
        }
    });

    Handlebars.registerHelper('GetFilenameByOID', function(fileOID: string) {
        const file = DAL.Files.GetByOID(fileOID);

        return file ? file.Filename : null;
    });

    Handlebars.registerHelper('GetCloseIssueText', function() {
        if (!!Session.Client.Settings.TicketCompleted) {
            const property = DAL.Properties.GetByOID(Session.Client.Settings.TicketCompleted);
            if (property) {
                return new Handlebars.SafeString(property.ActivationDescription || property.Title);
            }
        }

        return i18next.t('Misc.CompleteIssue');
    });

    Handlebars.registerHelper('IfFoldParametergroups', function(additionalSettings, options) {
        return Session.Settings.FoldParametergroups || (additionalSettings || {}).IsCollapsed
            ? options.fn(this)
            : options.inverse(this);
    });

    Handlebars.registerHelper('IndividualSchemaTitle', function(entityType: string) {
        return Utils.GetIndividualSchemaTitle(entityType);
    });

    Handlebars.registerHelper('IsNotNullOrUndefined', function(value, options) {
        return typeof value !== 'undefined' && value !== null ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('IsLockedState', function(stateOID: string, options) {
        return DAL.Properties.IsLockedState(stateOID) ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('StripFilenameTimestamp', function(filename: string) {
        if (!filename) {
            return;
        }

        if (!filename.contains('?')) {
            return filename;
        }

        const idx = filename.indexOf('?');

        return filename.substr(0, idx - 1);
    });

    Handlebars.registerHelper('GetContactsString', function(contactIdentifiers: string[]) {
        return new Handlebars.SafeString(Utils.ConcatManyContactsToString(contactIdentifiers));
    });

    Handlebars.registerHelper('GetContactGroupsString', function(contactIdentifiers: string[]) {
        return new Handlebars.SafeString(Utils.ConcatManyContactGroupsToString(contactIdentifiers));
    });

    Handlebars.registerHelper('GetCustomDataText', function(entity, schema: string) {
        if (!entity) {
            return;
        }

        if (!!schema) {
            let match: RegExpExecArray;
            let title = schema;

            while ((match = /\{\w+\}/g.exec(title))) {
                const text = Utils.EscapeHTMLEntities(entity[match[0].replace(/[\{\}]/g, '')] || '-/-');

                title = title.replace(match[0], text);
            }

            return new Handlebars.SafeString(title);
        } else if (!!entity.Title) {
            return entity.Title;
        } else if (!!entity.ImgPath) {
            return new Handlebars.SafeString(`<img src="${entity.ImgPath}" crossorigin="anonymous" />`);
        }
    });

    Handlebars.registerHelper('IfLicenseAvailable', function(license: string, isNullable: boolean, options) {
        const isLicenseAvailable = Utils.IsLicenseAvailable(license, isNullable);

        if (!Utils.IsSet(options) || !(options.fn instanceof Function)) {
            return isLicenseAvailable;
        }

        return isLicenseAvailable ?
            options.fn(this) :
            options.inverse(this);
    });

    Handlebars.registerHelper('UnlessLicenseAvailable', function(license: string, isNullable: boolean, options) {
        const isLicenseNotAvailable = !Utils.IsLicenseAvailable(license, isNullable);

        if (!Utils.IsSet(options) || !(options.fn instanceof Function)) {
            return isLicenseNotAvailable;
        }

        return isLicenseNotAvailable ?
            options.fn(this) :
            options.inverse(this);
    });

    Handlebars.registerHelper('IfAPIVersionIsGreaterOrEqual', function(apiVersion: number, options) {
        return Session.LastKnownAPIVersion >= (apiVersion || 1) ?
            (options.fn ? options.fn(this) : true) :
            (options.inverse ? options.inverse(this) : false);
    });

    Handlebars.registerHelper('Calculate', function(leftValue, operator: string, rightValue) {
        if (!operator) {
            return 0;
        }

        leftValue = parseFloat(leftValue || 0);
        rightValue = parseFloat(rightValue || 0);

        switch (operator) {
            case '+':
                return leftValue + rightValue;
            case '-':
                return leftValue - rightValue;
            case '*':
                return leftValue * rightValue;
            case '/':
                return leftValue / rightValue;
            case '%':
                return leftValue % rightValue;
        }
    });

    Handlebars.registerHelper('IfIsEnabled', function(settingIdentifier: string, options) {
        const isEnabled = Session.Settings[settingIdentifier] || false;

        if (options == null || !(options.fn instanceof Function) || !(options.inverse instanceof Function)) {
            return isEnabled;
        }

        return isEnabled ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('EachReverse', function(context) {
        const options = arguments[arguments.length - 1];
        let result = '';

        if (context && context.length > 0) {
            for (let i = context.length - 1; i >= 0; i--) {
                result += options.fn(context[i]);
            }
        } else {
            result = options.inverse(this);
        }

        return result;
    });

    Handlebars.registerHelper('IfNotNull', function(val, options) {
        let fnTrue = options.fn,
            fnFalse = options.inverse;
        return (val !== null && typeof val != 'undefined') ? fnTrue(this) : fnFalse(this);
    });

    Handlebars.registerHelper('debug', function(name: string, optionalValue) {
        console.log('');
        console.log('====================');
        console.log('Current Name: ', name);
        console.log('Value: ', optionalValue);
    });

    Handlebars.registerHelper('CodeScannerIsAvailable', function(options) {
        const result = CodeScanner.IsSupported();

        if (options != null && typeof options.fn == 'function') {
            return result
                ? options.fn(this)
                : options.inverse(this);
        }

        return result;
    });

    Handlebars.registerHelper('CodeScannerValues', function(type: string, options) {
        let result: string;

        switch (type) {
            case 'Name':
                result = CodeScanner.GetName();
                break;
        }

        if (options != null && typeof options.fn == 'function') {
            return result ? options.fn(this) : options.inverse(this);
        }

        return result;
    });

    Handlebars.registerHelper('GetIconUrl', function(icon: Enums.Menu.Icon | string) {
        switch (icon) {
            case Enums.Menu.Icon.Calendar:
                return './img/tile-icons/calendar.svg';
            case Enums.Menu.Icon.Issues:
                return './img/tile-icons/exclamation-outline.svg';
            case Enums.Menu.Icon.Tasks:
                return './img/tile-icons/tasks.svg';
            case Enums.Menu.Icon.Notes:
                return './img/tile-icons/post-it.svg';
            case Enums.Menu.Icon.Disturbances:
                return './img/tile-icons/disturbances.svg';
            case Enums.Menu.Icon.Scheduling:
                return './img/tile-icons/schedules.svg';
            case Enums.Menu.Icon.Parameters:
                return './img/tile-icons/checkpoints.svg';
            case Enums.Menu.Icon.Forms:
                return './img/tile-icons/forms.svg';
            case Enums.Menu.Icon.FloorPlan:
                return './img/tile-icons/floor-plan.svg';
            case Enums.Menu.Icon.Info:
                return './img/tile-icons/information.svg';
            case Enums.Menu.Icon.Link:
                return './img/tile-icons/link.svg';
            case Enums.Menu.Icon.Close:
                return './img/tile-icons/close.svg';
            case Enums.Menu.Icon.IndividualData:
                return './img/tile-icons/star.svg';
            default:
                return Handlebars.helpers['ImagePath'](icon, 'l');
        }
    });

    Handlebars.registerHelper('EmbeddedVideo', function(video: Model.Files.File) {
        let template: Function;
        let url: string;

        if (video.Type === Enums.FileType.Youtube) {
            url = `https://www.youtube.com/embed/${video.VideoID}`;
            template = Templates.EmbeddedVideo.Youtube;
        } else if (video.Type === Enums.FileType.VimeoPrivate) {
            url = `https://player.vimeo.com/video/${video.VideoID}?byline=0&portrait=0`;
            template = Templates.EmbeddedVideo.Vimeo;
        } else if (video.Type === Enums.FileType.VimeoPublic) {
            url = `https://player.vimeo.com/video/${video.VideoID}?byline=0&portrait=0`;
            template = Templates.EmbeddedVideo.Vimeo;
        }

        return !template ? '' : new Handlebars.SafeString(template({ URL: url, Identifier: video.OID }));
    });

    Handlebars.registerHelper('GroupAndAtLeastOneParameterEnabled', function(group: Model.Elements.Element) {
        if (!group.Enabled || !(group.Parameters || []).length) {
            return false;
        }

        return group.Parameters.some(p => p.Enabled);
    });

    Handlebars.registerHelper('EscapeHTMLEntities', function(str: string) {
        return new Handlebars.SafeString(Utils.EscapeHTMLEntities(str));
    });

    Handlebars.registerHelper('UnescapeHTMLEntities', function(str: string) {
        return Utils.UnescapeHTMLEntities(str);
    });

    Handlebars.registerHelper('TiledMainMenuIsEnabled', function(options) {
        return Session.Settings.ShowMenuItemsAsTiles ? options.fn(this) : options.inverse(this);
    });

    Handlebars.registerHelper('Equals', function(a, b, options) {
        if (options && options.fn) {
            if (a == b) {
                return options.fn(this);
            }
        } else {
            return a == b;
        }
    });

    Handlebars.registerHelper('IndividualValue', function(schema: Model.IndividualData.SchemaProperty, item: Dictionary<any>) {
        let value = item[schema.Name];

        if (!value) {
            return '-/-';
        }

        switch (schema.Type) {
            case Enums.IndividualDataType.Date: {
                const date = moment(new Date(value));
                return date.isValid() ? date.format('LL') : value;
            }

            case Enums.IndividualDataType.Time: {
                const date = moment(new Date(value));
                return date.isValid() ? date.format('HH:mm') : value;
            }

            case Enums.IndividualDataType.Bool: {

                return new Handlebars.SafeString(Templates.Parameters.BoolSelection({
                    PreviousValue: value,
                    IsDummy: false,
                    Disabled: true
                }));
            }

            case Enums.IndividualDataType.Image: {
                const image = DAL.Files.GetByOID(value);

                if (!image) {
                    return '-/-';
                }

                const fileSource = Session.IsSmartDeviceApplication && image.IsAvailableOffline
                    ? Utils.GetResourcesPath() + image.Filename
                    : `${Session.BaseURI}images/s/${image.Filename}`;

                return new Handlebars.SafeString(Templates.IndividualViews.Image({
                    FileSource: fileSource,
                    File: image
                }));
            }

            case Enums.IndividualDataType.File: {
                const file = DAL.Files.GetByOID(value);

                if (!file) {
                    return '-/-';
                }

                if (file.MimeType.indexOf('image/') == 0) {
                    // images
                    const fileSource = Session.IsSmartDeviceApplication && file.IsAvailableOffline
                        ? Utils.GetResourcesPath() + file.Filename
                        : `${Session.BaseURI}images/s/${file.Filename}`;

                    return new Handlebars.SafeString(Templates.IndividualViews.Image({
                        FileSource: fileSource,
                        File: file
                    }));
                }

                // other files
                return new Handlebars.SafeString(Templates.IndividualViews.File({
                    FileSource: null,
                    File: file
                }));
            }
            case Enums.IndividualDataType.RichText:
                value = DOMPurify.sanitize(value)
                    .replace('<script>', '&lt;script&gt;')
                    .replace('</script>', '&lt;/script&gt;');

                return !!value ? new Handlebars.SafeString(value) : '';
            default:
                return Utils.EscapeHTMLEntities(value);
        }

    });

    Handlebars.registerHelper('CreateHtmlDataBlob', function(htmlString: string) {
        if (!htmlString) {
            return;
        }

        // Erstellt ein Blob mit HTML Inhalt und stellt diesen über eine URL zur Verfügung
        return URL.createObjectURL(new Blob([htmlString], { type: 'text/html' }));
    });

    Handlebars.registerHelper('ResizeIframe', function() {

    });

    Handlebars.registerHelper('Array', function() {
        return Array.prototype.slice.call(arguments, 0, -1);
    });

    Handlebars.registerHelper('Count', function(arr: Array<any>) {
        return (arr || []).length;
    });

    Handlebars.registerHelper('ArrayContainsProperty', function(arr: Array<any>, propertyName: string, options) {
        let result = false;

        for (let i = 0; i < arr.length; i++) {
            if (propertyName in arr[i]) {
                result = true;
                break;
            }
        }

        if (options != null && typeof options.fn == 'function') {
            return result
                ? options.fn(this)
                : options.inverse(this);
        }

        return result;
    });

    Handlebars.registerHelper('RecordingIsLocked', function(element: Model.Elements.Element, options) {
        const recordingIsLocked = IssueView.IsRecordingLocked(element);

        if (options != null && typeof options.fn == 'function') {
            return recordingIsLocked ?
                options.fn(this) :
                options.inverse(this);
        }
    });

    // überprüft und legt fest, ob der Prüfpunkt in der Teilprobentabelle angzeigt werden soll, falls Prüfpunkte gefiltert wurden (durch Bedingungen oder Filter)
    Handlebars.registerHelper('GetTabularCellVisibilityClass', function(entity: Model.Elements.Element, subsamples: Array<Model.Elements.Element>) {
        let cellIsVisible = false;
        const hidden = ' hidden';
        const notVisible = ' not-visible';

        if (!entity || !subsamples) {
            return '';
        }

        function isElementHidden(element: Model.Elements.Element): boolean {
            return element.IsHidden || element.IsFiltered || element.MissingRequirements;
        }

        for (let i = 0; i < (subsamples || []).length; i++) {
            const parameter = subsamples[i];
            const parameterCount = (parameter.Parameters || []).length;

            if (parameter.OID !== entity.OID || parameterCount === 0) {
                continue;
            }

            // wenn der Prüfpunkt in keiner Teilprobe sichtbar ist => Prüfpunkt ausblenden
            if (isElementHidden(parameter)) {
                return hidden;
            }

            cellIsVisible = true;

            for (let j = 0; j < parameterCount; j++) {
                const param = parameter.Parameters[j];

                if (isElementHidden(param)) {
                    if (param.Row === entity.Row) {
                        // Der Prüfpunkt ist in dieser Teilprobenzeile ausgeblendet, aber in einer anderen Teilprobe sichtbar
                        // Damit die Spalten nicht verschoben werden, wird die Zelle ausblendet (leeres Feld)
                        return notVisible;
                    }
                }

            }
        }

        return cellIsVisible ? '' : notVisible;
    });

    Handlebars.registerHelper('GetPreviewImage', function(images: Dictionary<any>) {
        if (!images) {
            return null;
        }

        // Index Keys aufteigend sortieren, damit bei mehreren Vorschaubildern das mit dem niedrigsten Index verwendet wird
        const keysAsc = Object.keys(images).sort((a: string, b: string) => +a - +b);

        for (const key of keysAsc) {
            if (!images.hasOwnProperty(key)) {
                continue;
            }

            // erstes gültiges Bild aus der Liste zurückzugeben
            if (images[key]) {
                return images[key];
            }
        }

        return null;
    });

    Handlebars.registerHelper('HasElementInformation', function(element: Model.Elements.Element, options) {
        if (!element) {
            return false;
        }

        if (typeof element.HasInformation === 'undefined') {
            element.HasInformation = Utils.HasElementInformation(element);
        }

        return element.HasInformation;
    });

    Handlebars.registerHelper('DOMPurify', function(html: string) {
        return new Handlebars.SafeString(DOMPurify.sanitize(html));
    });

    Handlebars.registerHelper('ResolveFileLinkUrl', function(url: string) {
        if (Session.LastKnownAPIVersion < 28) {
            return url;
        }

        if (!url) {
            return null;
        }

        if (!url.startsWith('"') && !Formula.containsFormulaFunctions(url)) {
            return url;
        }

        const formulaResolver: FormulaResolver = new window.Formula({ functions: window.Formula.Functions });
        const tokens = window.Formula.tokenize(url);

        try {
            const str = formulaResolver.evaluate(tokens);

            return !!str ? new Handlebars.SafeString(str) : null;
        }
        catch { }

        return url;
    });

    Handlebars.registerHelper('IsRecorditemDeletionAllowed', function(isReadonly: boolean,
        disableDeleteOption: boolean, element: Model.Elements.Element, previousRecorditem: Model.Recorditem, options) {
        const reOptions = {
            IsReadonly: isReadonly,
            DisableDeleteOption: disableDeleteOption
        };

        const deletionAllowed = Utils.RecorditemEditor.IsDeletionAllowed(reOptions, element, previousRecorditem);

        return deletionAllowed ?
            (options.fn ? options.fn(this) : true) :
            (options.inverse ? options.inverse(this) : false);
    });

    Handlebars.registerHelper('IsNull', function(value) {
        return value == null;
    });
}
