module Model.Issues {
    export class LocationMarker {
        public Type: number;
        public X: number;
        public Y: number;

        constructor(type: number, x: number, y: number) {
            this.Type = type || 1;
            this.X = x || 0;
            this.Y = y || 0;
        }
    }
}