//imports-start
//// <reference path="../definitions.d.ts"  />
//// <reference path="../app/app.session.ts"  />
//// <reference path="./utils.input-window.ts"  />
/// <reference path="./utils.ts"  />
/// <reference path="../handlebars-helpers.ts"  />
//imports-end

module Utils.Message {

    export type ButtonSetting = {
        Fn?: Function,
        Caption?: string,
        RemoveCaption?: boolean,
        Classes?: Array<string>,    // CSS KLassen
        OneLine?: boolean,          // gibt an, dass der Button in einer Einzelzeile angezeigt wird
        Timeout?: number            // Timeout in sec, bis Button aktiviert wird
    };

    export type ButtonSettingParameters = boolean | Function | ButtonSetting;

    export type OptionButtons = {
        SendEMail?: ButtonSettingParameters,
        Clear?: ButtonSettingParameters,
        Abort?: ButtonSettingParameters,
        No?: ButtonSettingParameters,
        Close?: ButtonSettingParameters,
        Save?: ButtonSettingParameters,
        OK?: ButtonSettingParameters,
        Yes?: ButtonSettingParameters,
        OnHide?: boolean | (() => boolean) | (() => void)
    };

    interface QueueInfo {
        title: string;
        message: string;
        buttonSettings?: OptionButtons;
        additionalClasses?: Array<string> | string;
        customOverlayZIndex?: number;
    }

    let _$win, _$overlay;
    let _$body = $('body');
    let messageQueue: QueueInfo[] = null;
    let _closeDeferred: Deferred;
    let _queueDeferred: Deferred;
    let _intervalID: number;
    let _intervalRequired: boolean = false;
    let _intervalButtons: any[];
    let _$btnSendEMail, _$btnClear, _$btnAbort, _$btnNo, _$btnClose, _$btnSave, _$btnOK, _$btnYes;

    function initButton(setting: ButtonSettingParameters, $button): void {
        if (!setting || !$button) {
            return;
        }

        $button.removeClass('hidden');

        if (typeof setting == 'function') {
            $button.on('click.closeWindow', Message.Hide).on('click', setting);
            return;
        } else if (typeof setting == 'boolean') {
            $button.on('click', Message.Hide);
            return;
        } else if (setting.Fn) {
            $button.on('click.closeWindow', Message.Hide).on('click', setting.Fn);
        } else {
            $button.on('click', Message.Hide);
        }

        if (!!setting.Caption) {
            $button.text(setting.Caption);
        }

        if (setting.RemoveCaption) {
            $button.text('');
        }

        if ((setting.Classes || []).length) {
            $button.attr('class', setting.Classes.join(' '));
        }

        if (setting.OneLine) {
            $button.addClass('one-line');
        }

        if (!isNaN(setting.Timeout) && setting.Timeout > 0) {
            // Timeout Zähler vorbereiten
            _intervalRequired = true;

            $button.prop('disabled', true);
            $button.data('caption', $button.text());
            $button.data('clear-counter', setting.Timeout);
            $button.append(` (${setting.Timeout})`);

            _intervalButtons.push($button);
        }
    }

    function prepareButtons(buttonSettings: OptionButtons): void {
        _intervalButtons = [];

        initButton(buttonSettings.SendEMail, _$btnSendEMail);
        initButton(buttonSettings.Clear, _$btnClear);
        initButton(buttonSettings.Abort, _$btnAbort);
        initButton(buttonSettings.No, _$btnNo);
        initButton(buttonSettings.Close, _$btnClose);
        initButton(buttonSettings.Save, _$btnSave);
        initButton(buttonSettings.OK, _$btnOK);
        initButton(buttonSettings.Yes, _$btnYes);
    }

    function handleInterval() {
        // prüfen ob interval benötigt
        if (!_intervalButtons || !_intervalButtons.length) {
            if (!isNaN(_intervalID)) {
                // Intervall abschalten
                clearInterval(_intervalID);
                _intervalID = null;
            }

            return;
        }

        let proceedWithInterval = false;

        // Interval der Buttons runterzählen und Aktion aktivieren
        for (let i = 0; i < _intervalButtons.length; i++) {
            const $button = _intervalButtons[i];

            const caption = $button.data('caption');
            let clearCounter = $button.data('clear-counter') - 1;

            if (clearCounter > 0) {
                $button.text(`${caption} (${clearCounter})`);
                $button.data('clear-counter', clearCounter);
                proceedWithInterval = true;
            } else {
                $button.text(caption);
                $button.prop('disabled', false);
                $button.removeData('caption');
                $button.removeData('clear-counter');
            }
        }

        if (!proceedWithInterval && !isNaN(_intervalID)) {
            // Intervall abschalten, nicht mehr erforderlich
            clearInterval(_intervalID);
            _intervalID = null;
        }
    }

    export function Show(title: string, message: string, buttonSettings?: OptionButtons, additionalClasses?: Array<string> | string, customOverlayZIndex?: number): Deferred {
        _$body.addClass('modal-open');

        if (!buttonSettings) {
            // default Settings anwenden
            buttonSettings = { OK: true };
        }

        if ($('#message').length) {
            messageQueue = messageQueue || [];
            // queue max 3 messages
            if (messageQueue.length < 3) {
                // apply previous z-index if higher
                const prevZindex = _$win.css('z-index');
                customOverlayZIndex = Math.max(customOverlayZIndex || 0, prevZindex);

                messageQueue.push({
                    title: title,
                    message: message,
                    buttonSettings: buttonSettings,
                    additionalClasses: additionalClasses,
                    customOverlayZIndex: customOverlayZIndex
                });
            }
            return;
        }

        _closeDeferred = $.Deferred();

        let onHideCallback: Function = null;
        if (typeof buttonSettings.OnHide == 'function') {
            onHideCallback = function() {
                const result = (<Function>buttonSettings.OnHide)();
                if (typeof result !== 'undefined' && !result) {
                    // cancel close action
                    return;
                }
                // call close callback
                Utils.Message.Hide();
            };
        } else if (buttonSettings.OnHide !== false) {
            onHideCallback = Utils.Message.Hide;
        }

        _$overlay = Utils.Overlay.Generate('olMessageOverlay', customOverlayZIndex || 20015, onHideCallback);
        _$win = $(Templates.Messagebox());

        _$btnSendEMail = _$win.find('.btn-send-email');
        _$btnClear = _$win.find('.btn-clear');
        _$btnAbort = _$win.find('.btn-abort');
        _$btnNo = _$win.find('.btn-no');
        _$btnClose = _$win.find('.btn-close');
        _$btnSave = _$win.find('.btn-save');
        _$btnOK = _$win.find('.btn-ok');
        _$btnYes = _$win.find('.btn-yes');

        if (additionalClasses instanceof Array) {
            _$win.addClass(additionalClasses.join(' '));
        } else if (typeof additionalClasses === 'string') {
            _$win.addClass(additionalClasses);
        }

        _$win.find('.header').html(title || i18next.t('Misc.DefaultMessageWindowTitle'));
        _$win.find('.main-content').html(message);
        _$win.find('.footer .btn:not(.btn-close)').addClass('hidden');

        _intervalRequired = false;

        if (buttonSettings) {
            prepareButtons(buttonSettings);
        }

        _$body.append(_$win);

        if (customOverlayZIndex) {
            _$win.css('z-index', customOverlayZIndex + 1);
        } else {
            // get z-index from _$win
            const zIndexFromTemplate = +_$win.css('z-index');
            if (!isNaN(zIndexFromTemplate)) {
                _$overlay.css('z-index', zIndexFromTemplate);
            }
        }

        Utils.RepositionNewModalWindow(_$win);

        if (_intervalRequired) {
            _intervalID = setInterval(handleInterval, 1000);
            _intervalRequired = false;
        } else {
            _intervalID = null;
        }

        return _closeDeferred;
    };

    export function Hide(): void {
        if (!_$win) {
            return;
        }

        if (!isNaN(_intervalID)) {
            clearInterval(_intervalID);
            _intervalID = null;
        }
        _$win.remove();

        if (_$overlay) {
            Utils.Overlay.DestroyWithTimeout(_$overlay);
            _$overlay = null;
        }

        _$win = null;
        _$btnClear = null;
        _$btnAbort = null;
        _$btnNo = null;
        _$btnClose = null;
        _$btnSave = null;
        _$btnOK = null;
        _$btnYes = null;

        if (!Utils.ActionWindow.IsVisible() &&
            !Utils.RecorditemEditor.IsVisible() &&
            !Utils.IssueViewer.IsVisible()) {
            _$body.removeClass('modal-open');
        }

        if (_closeDeferred) {
            // Das closed deferred in eine temporäre Variable kopieren,
            // da zwischen resolve und dem zurücksetzen auf null bereits eine andere Stelle diese erneut setzen kann
            const tmpClosedDeferred = _closeDeferred;
            _closeDeferred = null;
            tmpClosedDeferred.resolve();
        }

        if (messageQueue && messageQueue.length > 0) {
            const msgInfo = messageQueue.shift();
            Message.Show(
                msgInfo.title,
                msgInfo.message,
                msgInfo.buttonSettings,
                msgInfo.additionalClasses,
                msgInfo.customOverlayZIndex
            );
        } else if (_queueDeferred) {
            // Das queue deferred in eine temporäre Variable kopieren,
            // da zwischen resolve und dem zurücksetzen auf null bereits eine andere Stelle diese erneut setzen kann
            const tmpDeferred = _queueDeferred;
            _queueDeferred = null;
            tmpDeferred.resolve();
        }
    };

    export function IsVisible(): boolean {
        return _$win && _$win.is(':visible');
    };

    export function GetQueueDeferred(): Deferred {
        if (!messageQueue || !messageQueue.length) {
            return _closeDeferred || $.Deferred().resolve();
        }

        if (_queueDeferred) {
            return _queueDeferred;
        }

        return _queueDeferred = $.Deferred();
    };
}
