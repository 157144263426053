module Utils.SVGEditor {

    export class StraightLineDrawHandler extends DrawHandler {
        private d3Line: any;

        constructor(editor: SVGEditor, svg: SVGSVGElement, onChangeListener?: Function) {
            super(editor, svg, onChangeListener);
        }

        public bindEvents(): void {
            this.d3SvgElement
                .on('mousedown', (event: MouseEvent) => this.onStartDrawing(event))
                .on('touchstart', (event: TouchEvent) => this.onStartDrawing(event))
                .on('touchend', (event: TouchEvent) => this.onFinishDrawing(event))
                .on('touchleave', (event: TouchEvent) => this.onFinishDrawing(event))
                .on('mouseup', (event: MouseEvent) => this.onFinishDrawing(event))
                .on('mouseleave', (event: MouseEvent) => this.onFinishDrawing(event));
        }

        private onStartDrawing(event: MouseEvent | TouchEvent): void {
            this.isUserDrawing = true;

            const color = new Model.Color(this.editor.DrawStyle.color);
            const requiredMarkerColor = color.getHex();
            const requiredMarkerId = `triangle-${requiredMarkerColor}`;

            // marker hinzufügen, wenn nicht vorhanden
            const svgElement = this.d3SvgElement.node();
            const requiredMarker = $(svgElement).find(`marker[id="${requiredMarkerId}"]`);

            if (!requiredMarker || !requiredMarker.length) {
                let d3Defs = this.d3SvgElement.select('defs');

                if (!d3Defs || !d3Defs.length || !d3Defs.node()) {
                    d3Defs = this.d3SvgElement.append('defs');
                }

                const d3Marker = d3Defs.append('marker')
                    .attr("id", requiredMarkerId)
                    .attr("viewBox", "0 0 3 3")
                    .attr("refX", "1")
                    .attr("refY", "1.5")
                    .attr("markerUnits", "strokeWidth")
                    .attr("markerWidth", "3")
                    .attr("markerHeight", "3")
                    .attr("orient", "auto");

                d3Marker.append('path')
                    .attr('d', 'M 0 0 L 3 1.5 L 0 3 z')
                    .style("fill", requiredMarkerColor);
            }

            const d3SvgGroup = this.d3SvgElement.select('g');

            this.d3Line = d3SvgGroup.append('line')
                .attr('class', 'line')
                .attr('marker-end', `url(#${requiredMarkerId})`);

            const pathStyle = this.d3Line.node().style;

            pathStyle.stroke = this.editor.DrawStyle.color;
            pathStyle.strokeWidth = this.editor.DrawStyle.strokeWidth;
            pathStyle.opacity = this.editor.DrawStyle.strokeOpacity;

            this.editor.IsModified = true;

            const point = this.getPoint(event);

            if (point) {
                this.d3Line
                    .attr('x1', point.x)
                    .attr('x2', point.x)
                    .attr('y1', point.y)
                    .attr('y2', point.y);
            }

            if (event.type === 'mousedown') {
                this.d3SvgElement.on('mousemove', (event: MouseEvent) => this.onMove(event));
            } else {
                this.d3SvgElement.on('touchmove', (event: TouchEvent) => this.onMove(event));

                const svgElement = this.d3SvgElement.node();
                svgElement.addEventListener('touchmove', (event: TouchEvent) => event.preventDefault(), false);
            }
        }

        private onFinishDrawing(event: MouseEvent | TouchEvent): void {
            this.d3SvgElement.on('mousemove', null);
            this.d3SvgElement.on('touchmove', null);

            if (!this.isUserDrawing) {
                return;
            }

            this.isUserDrawing = false;

            const point = this.getPoint(event);
            if (point) {
                this.d3Line
                    .attr('x2', point.x)
                    .attr('y2', point.y);
            }

            this.triggerChange();
        }

        private onMove(event: MouseEvent | TouchEvent): void {
            const point = this.getPoint(event);

            if (!point) {
                return;
            }

            this.d3Line
                .attr('x2', point.x)
                .attr('y2', point.y);
        }
    }
}
