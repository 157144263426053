
class HygieneInspectionActionPlan extends PdfActionPlan {
    public static readonly HygieneInspectionKeywordOID = '5bfff990-3d13-449c-8ed8-b164496e7d5f';

    public constructor(issue: Model.Issues.Issue, resubTree: Model.Elements.Element) {
        super(issue, resubTree);
    }

    public GetValidValues(): Array<string> {
        return ['II', 'III', 'IV', 'NEIN'];
    }

    protected AddHeaderRow(table: PdfTable) {
        table.AddHeaderRow([
            {
                content: i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Information'),
                colSpan: 2,
                styles: { textColor: [0, 0, 0] }
            },
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Rating'),
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Remarks'),
            i18next.t('customer:TUEV.ActionPlan.ContentTable.Header.Finished'),
        ]);
    }
}