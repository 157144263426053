//imports-start
/// <reference path="./decoder.ts"  />
//imports-end

module Utils.Nfc {
    export class Nhs3100Decoder extends Decoder {
        constructor(tag: Model.Nfc.Tag) {
            super(tag);
        }

        public GetTemperatureValue(): number {
            const message = this.GetDecodedMessage();

            if ((message || []).length < 2) {
                return null;
            }

            const tempMessage = message[1];
            const currentTemperatureStart = tempMessage.indexOf('Current temperature');
            const regex = new RegExp('[+-]?\\d+(\\.\\d+)?', 'ig');
            const matches = regex.exec(tempMessage.substr(currentTemperatureStart));

            if (!matches.length) {
                return null;
            }

            return parseFloat(matches[0]);
        }
    }
}