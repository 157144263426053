//imports-start
/// <reference path="../../definitions.d.ts"  />
//imports-end

module Utils {

    export class ImageViewerManager {

        private static instance: ImageViewerManager;
        private viewers: ImageViewer[] = [];
        private $overlay: any;
        private $container: any;

        private constructor() { }

        public static GetInstance(): ImageViewerManager {
            return !!ImageViewerManager.instance
                ? ImageViewerManager.instance
                : ImageViewerManager.instance = new ImageViewerManager();
        }

        public static Show(options: ImageViewerOptions): void {
            ImageViewerManager
                .GetInstance()
                .Add(new ImageViewer(options))
                .Show();
        }

        public Add(viewer: ImageViewer): this {
            if (!viewer || this.containsViewer(viewer)) {
                return this;
            }

            this.viewers.push(viewer);

            return this;
        }

        public Prepend(viewer: ImageViewer): this {
            if (!viewer || this.containsViewer(viewer)) {
                return this;
            }

            this.viewers.unshift(viewer);

            return this;
        }

        public Remove(...targetedViewers: Array<ImageViewer>): Deferred{
            const askForModifications = targetedViewers.map(viewer => viewer.AskForModificationAction());
            return $.when(...askForModifications)
                .then(() => {
                    const deferred: Deferred = $.Deferred();

                    this.viewers = this.viewers.filter(viewer => targetedViewers.indexOf(viewer) == -1);

                    requestAnimationFrame(() => {
                        for (const targetedViewer of targetedViewers) {
                            targetedViewer.Destroy();
                        }

                        if (!this.hasViewers()) {
                            this.Clear();
                        } else {
                            this.Resize();
                        }

                        deferred.resolve();
                    });

                    return deferred;
                });
        }

        public Clear(): this {
            if (this.$container) {
                this.$container.addClass('hidden');
            }

            this.unbindEvents();

            this.viewers.forEach(viewer => viewer.Destroy());
            this.viewers = [];

            if (this.$container) {
                this.$container.remove();
                this.$container = null;
            }

            if (this.$overlay) {
                Overlay.DestroyWithTimeout(this.$overlay);
                this.$overlay = null;
            }

            return this;
        }

        public Show(): this {
            if (!this.hasViewers()) {
                this.Clear();
                return this;
            }

            this.render($('body')!);
            this.Resize();
            this.bindEvents();

            return this;
        }

        public Resize(): void {
            const visibleViewers = this.getVisibleViewer();
            const numberOfVisibleViewers = visibleViewers.length;

            for (const viewer of visibleViewers) {
                viewer.Resize(numberOfVisibleViewers);
            }
        }

        private render($element: any): void {
            this.$container = this.$container || $(this.fillTemplate());
            this.$overlay = this.$overlay || this.generateOverlay();

            const container: HTMLDivElement = this.$container[0];

            for (let i = 0; i < this.viewers.length; i++) {
                const viewer = this.viewers[i];
                const viewerElement = viewer.$Window[0];
                const childElement = container.children[i];

                if (viewerElement == childElement) {
                    continue;
                }

                if (!childElement) {
                    viewer.Show(element => container.appendChild(element));
                    continue;
                }

                viewer.Show(element => container.insertBefore(element, childElement));
            }

            $element.append(this.$container);

            if (!$('body').hasClass('modal-open')) {
                $('body').addClass('modal-open');
            }
        }

        private bindEvents(): void {
            this.unbindEvents();
            $('body').on('keydown.imageViewerManagerEvents', (event: KeyboardEvent) => this.onKeyDown(event))
        }

        private unbindEvents(): void {
            $('body').off('keydown.imageViewerManagerEvents');
        }

        private fillTemplate(): string {
            return Templates.ImageViewer.Manager();
        }

        private generateOverlay(): any {
            return Overlay.Generate('olImageViewer', 10999);
        }

        private getVisibleViewer(): Array<ImageViewer> {
            return this.viewers.filter(viewer => viewer.IsVisible());
        }
        private hasViewers(): boolean {
            return !!this.viewers && !!this.viewers.length;
        }

        private containsViewer(viewer: ImageViewer): boolean {
            return viewer && this.viewers.indexOf(viewer) >= 0;
        }

        private onKeyDown(keyboardEvent: KeyboardEvent) {
            switch (keyboardEvent.keyCode) {
                case Enums.KeyCode.ESCAPE:
                    this.closeAllViewers();
                    break;
                case Enums.KeyCode.LEFT:
                    this.displayPreviousImage();
                    break;
                case Enums.KeyCode.RIGHT:
                    this.displayNextImage();
                    break;
            }
        }

        private closeAllViewers() {
            this.Remove(...this.viewers);
        }

        private displayPreviousImage() {
            if (!this.viewers || this.viewers.length != 1) {
                return;
            }

            this.viewers[0].DisplayPreviousImage();
        }

        private displayNextImage() {
            if (!this.viewers || this.viewers.length != 1) {
                return;
            }

            this.viewers[0].DisplayNextImage();
        }
    }
}
