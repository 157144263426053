//imports-start
/// <reference path="./itree-cache.ts"  />
/// <reference path="./filtered-tree-cache.ts"  />
//imports-end
module Model.TreeCache {
    export class TreeCache implements Model.TreeCache.ITreeCache {
        protected name: string;

        private rootNode: Model.TreeCache.CacheMetaNode = null;
        private nodeByKeyCache: Dictionary<Model.TreeCache.CacheMetaNode> = {};
        private issueByKeyCache: Dictionary<Model.TreeCache.CacheMetaNode> = {};
        private schedulingRelations: Model.Scheduling.IRelation[] = [];
        private instanceList: Dictionary<FilteredTreeCache> = {};

        private filter: Model.Issues.IFilter = null;
        private filteredRootNode: Model.TreeCache.CacheMetaNode = null;
        private filteredNodeByKeyCache: Dictionary<Model.TreeCache.CacheMetaNode> = null;

        constructor(name: string) {
            this.name = name;
        }

        public setElementsTree(root: Model.Elements.Element | Model.TreeCache.CacheMetaNode, filter?: (e: Model.Elements.Element | Model.TreeCache.CacheMetaNode) => boolean) {
            /*
            * create tree for cache
            */
            const oldNode = this.rootNode;
            this.rootNode = Model.TreeCache.CacheMetaNode.generateTree(root, [], null, filter);
            this.nodeByKeyCache = {};

            // neue Tree Struktur an andere Instanzen vererben
            for (let key in this.instanceList) {
                this.instanceList[key].setElementsTree(root);
            }

            if (!this.rootNode) {
                return;
            }

            this.rootNode.iterate((node: Model.TreeCache.CacheMetaNode) => {
                this.nodeByKeyCache[node.oid] = node;
            });

            if (oldNode) {
                // Daten aus vorherigem Tree übernehmen
                oldNode.iterate((item: Model.TreeCache.CacheMetaNode) => {
                    // Vorgänge übernehmen
                    const oldNodeIssues = item.getIssues();
                    for (let i = 0, len = oldNodeIssues.length; i < len; i++) {
                        this.setIssue(<any>oldNodeIssues[i]);
                    }
                });

                // reset filtered data
                this.filteredRootNode = null;
                this.filteredNodeByKeyCache = null;
            }

            // aktualisiere alle Scheduling Elemente
            if (this.schedulingRelations && this.schedulingRelations.length) {
                for (const relation of this.schedulingRelations) {
                    const locationNode = this.nodeByKeyCache[relation.LocationSettings.OID];
                    if (locationNode) {
                        locationNode.addScheduling(relation);
                    }
                }
            }
        }

        public setIssue(issue: Model.Issues.RawIssue): boolean {
            /*
            * save issues in cache
            */
            const item = this.nodeByKeyCache[issue.AssignedElementOID];
            let result = false;
            if (item) {
                if (!item.addIssue(<any>issue)) {
                    // Aktion vorzeitig abbrechen, wenn keine Änderungen vorliegen
                    return false;
                }

                this.filteredRootNode = null;
                this.filteredNodeByKeyCache = null;
                this.issueByKeyCache[issue.OID] = item;
                result = true;
            }

            let resultSubInstance = false;
            for (let key in this.instanceList) {
                if (this.instanceList[key].setIssue(issue)) {
                    resultSubInstance = true;
                }
            }

            return result || resultSubInstance;
        }

        public removeIssue(issue: Model.Issues.RawIssue | Model.Issues.Issue): boolean {
            /*
            * remove issue from cache
            */
            const item = this.issueByKeyCache[issue.OID];
            let result = false;
            if (item) {
                item.removeIssue(<any>issue);
                this.filteredRootNode = null;
                this.filteredNodeByKeyCache = null;
                delete this.issueByKeyCache[issue.OID];
                result = true;
            }

            let resultSubInstance = false;
            for (let key in this.instanceList) {
                if (this.instanceList[key].removeIssue(issue)) {
                    resultSubInstance = true;
                }
            }

            return result || resultSubInstance;
        }

        public setScheduling(relation: Model.Scheduling.IRelation) {
            /*
            * save scheduling in cache
            */
            if (!relation) {
                return;
            }

            const locationOID = relation.LocationSettings.OID;

            // Plan zum TreeNode hinzufügen
            const locationNode = this.nodeByKeyCache[locationOID];
            if (locationNode) {
                locationNode.addScheduling(relation);
                this.filteredRootNode = null;
                this.filteredNodeByKeyCache = null;
            }

            this.schedulingRelations.push(relation);

            // neuen Planzuweisung an Ableitungen des Trees weitergeben
            for (let key in this.instanceList) {
                this.instanceList[key].setScheduling(relation);
            }
        }

        public removeScheduling(relation: Model.Scheduling.IRelation) {
            const elementOID = relation.ElementOID;
            const scheduleOID = relation.SchedulingOID;
            const locationOID = relation.LocationSettings ? relation.LocationSettings.OID : null;

            if (!elementOID && !scheduleOID && !locationOID) {
                return;
            }

            // Prüft ob die angegebenen Vorgaben der Verknüpfung entsprechen
            const checkCondition = function(relationItem: Model.Scheduling.IRelation) {
                return (!elementOID || relationItem.ElementOID === elementOID) &&
                    (!scheduleOID || relationItem.SchedulingOID === scheduleOID) &&
                    (!locationOID || relationItem.LocationSettings!.OID === locationOID);
            }

            // Pläne anhand der elementOID, scheduleOID & locationOID entfernen
            this.schedulingRelations = this.schedulingRelations.filter((item: Model.Scheduling.IRelation) => {
                if (checkCondition(item)) {
                    const element = this.nodeByKeyCache[item.LocationSettings!.OID];
                    if (element) {
                        element.removeScheduling(item);
                    }

                    return false;
                }

                return true;
            });

            // Entfernen der Plan Beziehungen an Ableitungen des Trees weitergeben
            for (let key in this.instanceList) {
                this.instanceList[key].removeScheduling(relation);
            }
        }

        public createSubInstance(filter: Model.Issues.IFilter, name: string, copyIssues: boolean = true): Model.TreeCache.IFilteredTreeCache {
            if (this.instanceList[name]) {
                throw new Error(`TreeCache sub-instance with same name "${name}" exist already!`);
            }

            const newInstance = new FilteredTreeCache(name, this, filter);
            this.instanceList[name] = newInstance;
            if (this.rootNode) {
                newInstance.setElementsTree(this.rootNode);
                if (copyIssues) {
                    const root = this.getNode();
                    const issues = root.getTotalIssues();
                    for (let i = 0; i < issues.length; i++) {
                        newInstance.setIssue(<any>issues[i]);
                    }
                }
            }

            return newInstance;
        }

        public removeSubInstance(instanceName: string): boolean
        public removeSubInstance(instance: Model.TreeCache.IFilteredTreeCache): boolean
        public removeSubInstance(instance: Model.TreeCache.IFilteredTreeCache | string): boolean {
            if (typeof instance != 'string') {
                instance = instance.getName();
            }

            if (!this.instanceList[instance]) {
                return false;
            }

            delete this.instanceList[instance];

            return true;
        }

        public getInstance(instanceName: string): Model.TreeCache.IFilteredTreeCache | null {
            return this.instanceList[instanceName] || null;
        }

        public setFilter(newFilter: Model.Issues.IFilter) {
            /*
            * set new issue filter
            */
            if (!this.isFilterChangeRelevant(newFilter)) {
                return;
            }

            this.filter = newFilter ? new Model.Issues.Filter(newFilter) : null;
            this.filteredRootNode = null;
            this.filteredNodeByKeyCache = null;

            for (let key in this.instanceList) {
                this.instanceList[key].setFilter(newFilter);
            }
        }

        protected isFilterChangeRelevant(newFilter: Model.Issues.IFilter): boolean {
            // check Filter changes
            if (newFilter && this.filter) {
                return !Model.Issues.Filter.Equals(newFilter, this.filter);
            } else if (!newFilter && !!this.filter) {
                return true;
            }

            return Model.Issues.Filter.IsRelevant(newFilter) && !this.filter;
        }

        public getNodesCount(): number {
            return Object.keys(this.nodeByKeyCache).length;
        }

        public getNode(oid?: string, types?: Array<Enums.IssueType>): Model.TreeCache.CacheMetaNode | null
        public getNode(oid?: string, instanceName?: string): Model.TreeCache.CacheMetaNode | null
        public getNode(oid?: string, types?: Array<Enums.IssueType> | string): Model.TreeCache.CacheMetaNode | null {

            // Node nach Instanz zurückgeben
            if (types && typeof types == 'string') {
                let subInstance = this.getInstance(<string>types);
                return !subInstance ? null : subInstance.getNode(oid);
            }

            let filterTypes: Enums.IssueType[];

            // TODO remove view type check from here, apply by external filters!
            if (Session.IsSmartDeviceApplication || View.CurrentView === Enums.View.Inspection) {
                if (types != null) {
                    if (!this.filter) {
                        this.filter = {};
                    }
                    filterTypes = this.filter.Types;
                    this.filter.Types = <Array<Enums.IssueType>>types;
                    this.filteredRootNode = null;
                    this.filteredNodeByKeyCache = null;
                }
            }

            if (this.filter != null && this.filteredRootNode == null) {
                this.filteredRootNode = this.rootNode.clone(this.filter);
                this.filteredNodeByKeyCache = {};
                this.filteredRootNode.iterate((item: Model.TreeCache.CacheMetaNode) => {
                    this.filteredNodeByKeyCache[item.oid] = item;
                });

                if ((Session.IsSmartDeviceApplication || View.CurrentView === Enums.View.Inspection) && types != null) {
                    if (filterTypes) {
                        this.filter.Types = filterTypes;
                    } else {
                        delete this.filter['Types'];
                    }
                }
            }

            if (oid) {
                return (this.filteredNodeByKeyCache || this.nodeByKeyCache)[oid];
            } else {
                return this.filteredRootNode || this.rootNode;
            }
        }

        public Clear(): void {
            this.rootNode = null;
            this.nodeByKeyCache = {};
            this.issueByKeyCache = {};
            this.schedulingRelations = [];

            this.filter = null;
            this.filteredRootNode = null;
            this.filteredNodeByKeyCache = null;

            for (let key in this.instanceList) {
                this.instanceList[key].Clear();
            }
        };

        public Purge(): void {
            this.rootNode.purge();
            this.issueByKeyCache = {};

            for (let key in this.instanceList) {
                this.instanceList[key].Purge();
            }
        }
    }
}
