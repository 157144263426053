//imports-start
/// <reference path="../core/revision.ts"  />
/// <reference path="../../enums.ts"  />
//imports-end

module Model.Properties {
    export class Property extends Model.Core.Revision {
        ParentOID?: string;
        Type: Enums.PropertyType;
        Title: string;
        Description: string;
        Position: number;
        Color: string;
        ChangeDescription: string;
        ActivationDescription: string;
        ClosedState: boolean;
        IsLockedState: boolean;
        IsDeletedState: boolean;
        ResponsibleUser: boolean;
        Highlight: boolean;
        PredefinedEvaluationCategory: number;
        AutomaticallyCreateDisturbance: boolean;
        IgnoreInStatistics: boolean;
        DontCreateDisturbances: boolean;
        EvaluatePeriodically: boolean;
        ChangeStateOnlyIfAllRequiredCollected: boolean;
        SetStateOnlyIfAllRequiredCollected: boolean;
        RolesAllowSet: Array<string>;
        RolesAllowChange: Array<string>;
        FollowStates: Array<string>;
        StandardFollowStates: Array<string>;
        Identcode: string;
        ChangeCommentRequired: Enums.ChangeCommentRequiredType;

        Parent: Property;
        Children: Array<Property>;

        //App only
        FontIcon: string;
    }
}