//imports-start
/// <reference path="../definitions.d.ts" />
//imports-end

module Utils {
    export type Operation = '!==' | '===' | '!=' | '==' | '<' | '<=' | '>' | '>=';
    export type TestFunc<T, U> = (a: T, b: U) => boolean;
    export type PredicateFunc<T> = (o: T) => boolean;

    export function Where<T, U>(arr: Array<T>, attr: string, op: Operation, value: U): T | null;
    export function Where<T, U>(arr: Array<T>, attr: string, op: Operation, value: U, returnIdx: boolean): number;
    export function Where<T, U>(arr: Array<T>, attr: string, op: Operation, value: U, returnIdx?: boolean): T | number | null {
        if (arr instanceof Array) {
            for (let aCnt = 0, aLen = arr.length; aCnt < aLen; aCnt++) {
                const obj = <any>arr[aCnt];

                if (!obj) {
                    continue;
                }

                const invValue: U = obj[attr];
                let isHit: boolean = false;

                switch (op) {
                    case '!=':
                        isHit = invValue != value;
                        break;
                    case '!==':
                        isHit = invValue !== value;
                        break;
                    case '===':
                        isHit = invValue === value;
                        break;
                    case '==':
                        isHit = invValue == value;/* jslint ignore:line */
                        break;
                    case '<':
                        isHit = invValue < value;
                        break;
                    case '<=':
                        isHit = invValue <= value;
                        break;
                    case '>':
                        isHit = invValue > value;
                        break;
                    case '>=':
                        isHit = invValue >= value;
                        break;
                }

                if (isHit) {
                    return returnIdx ? aCnt : obj;
                }
            }
        }

        return returnIdx ? -1 : null;
    }

    export function FindByPredicate<T>(arr: Array<T>, predicate: PredicateFunc<T>): T | null {
        if (arr instanceof Array) {
            for (let aCnt = 0, aLen = arr.length; aCnt < aLen; aCnt++) {
                const obj = arr[aCnt];
                if (obj != null && predicate(obj)) {
                    return obj;
                }
            }
        }
        return null;
    }

    export function All<T, U>(arr: Array<T>, attr: string, op: Operation | 'in' | 'not in', value: Array<U> | U): Array<T> | null {
        if ((op === 'in' || op === 'not in') && !(value instanceof Array)) {
            throw new Model.Errors.ArgumentError('value must be an array');
        }

        if (arr instanceof Array) {
            let result: Array<T> = [];
            for (let aCnt = 0, aLen = arr.length; aCnt < aLen; aCnt++) {
                const obj = <any>arr[aCnt];
                const invValue: U = obj[attr];
                let isHit: boolean = false;

                switch (op) {
                    case '!=':
                        isHit = invValue != value;
                        break;
                    case '!==':
                        isHit = invValue !== value;
                        break;
                    case '==':
                        isHit = invValue == value;
                        break;
                    case '===':
                        isHit = invValue === value;
                        break;
                    case '<':
                        isHit = invValue < value;
                        break;
                    case '<=':
                        isHit = invValue <= value;
                        break;
                    case '>':
                        isHit = invValue > value;
                        break;
                    case '>=':
                        isHit = invValue >= value;
                        break;
                    case 'in':
                        isHit = InArray(<Array<U>>value, invValue);
                        break;
                    case 'not in':
                        isHit = !InArray(<Array<U>>value, invValue);
                        break;
                }

                if (isHit) {
                    result.push(obj);
                }
            }

            return result;
        }

        return null;
    }

    export function InArray(array: Array<string>, value: string, ignoreCase?: boolean): boolean
    export function InArray<T>(array: Array<T>, value: T): boolean
    export function InArray<T>(array: Array<T | string> | string, value: T | string, ignoreCase?: boolean): boolean {
        if (!(array instanceof Array) || !array.length) {
            return false;
        }

        if (ignoreCase) {
            const testVal = (<string>value).toLowerCase();
            for (let i = 0; i < array.length; i++) {
                const tmpVal = array[i];
                if ((<string>tmpVal).toLowerCase() === testVal) {
                    return true;
                }
            }
            return false;
        } else {
            return array.indexOf(<T>value) !== -1;
        }
    }

    export function GetIndex<U>(array: Array<U>, value: U): number;
    export function GetIndex<T, U>(array: Array<T>, value: U, keyOrFunction?: string): number;
    export function GetIndex<T, U>(array: Array<T>, value: U, keyOrFunction?: TestFunc<T | U, U>): number;
    export function GetIndex<T, U>(array: Array<U> | Array<T>, value: U, keyOrFunction?: string | TestFunc<T | U, U>): number {
        if (!(array instanceof Array) || !array.length) {
            return -1;
        }

        for (let idx = 0, len = array.length; idx < len; idx++) {
            const item = <any>array[idx];

            if (typeof keyOrFunction === 'string') {
                if (item.hasOwnProperty(keyOrFunction)) {
                    if (item[keyOrFunction] === value) {
                        return idx;
                    }
                }
            } else if (keyOrFunction instanceof Function) {
                if (keyOrFunction(item, value)) {
                    return idx;
                }
            } else {
                if (array[idx] === value) {
                    return idx;
                }
            }
        }

        return -1;
    }

    // Global Test Variable
    declare var __TEST__;
    if (typeof __TEST__ != 'undefined') {
        __TEST__.Utils = Utils;
    }
}